import React from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import AddressBlock from '../AddressBlock';
import DateBlock from '../DateBlock';
import OrderDetails from '../OrderDetails';
import StatusCode from '../../StatusCode';
import MobileOrderCardContainer from './MobileOrderCard.styled';
import {useAppDispatch} from '../../../../hooks/store';
import {routeToOrderDetail} from '../../../../features/customer/service/customer.service';
import {formatOrderCurrency} from '../../../../Framework/Services/formatCurrency';

const MobileOrderCard = ({
  date,
  shipToName,
  address1,
  address2,
  address3,
  city,
  state,
  postalCode,
  createdBy,
  orderNumber,
  purchaseOrderNumber,
  orderType,
  amount,
  status,
  customerId,
  jobNumber,
  favorite,
}: {
  date: Date;
  shipToName: string;
  address1: string;
  address2: string;
  address3: string;
  city: string;
  state: string;
  postalCode: number;
  createdBy: string;
  orderNumber: number;
  purchaseOrderNumber: number;
  orderType: string;
  amount: number;
  status: string;
  customerId: number;
  jobNumber: string | null;
  favorite: string | null;
}) => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useAppDispatch();

  return (
    <MobileOrderCardContainer>
      <div className="mobile-card-body">
        <div className="row-1">
          <DateBlock date={date} />
          <div className="address-status-col">
            <AddressBlock
              shipToName={shipToName}
              address1={address1}
              address2={address2}
              address3={address3}
              city={city}
              stateProvinceCode={state}
              postalCode={postalCode ? postalCode.toString() : ''}
            />
            <StatusCode status={status} />
          </div>
        </div>
        <div className="row-2">
          <OrderDetails
            createdBy={createdBy}
            orderNumber={orderNumber}
            purchaseOrderNumber={purchaseOrderNumber}
            orderType={orderType}
            jobNumber={jobNumber}
            favorite={favorite}
          />
        </div>
      </div>
      <div className="amount-details-footer">
        <div className="amount">${formatOrderCurrency(amount)}</div>
        <div
          className="details"
          onClick={() => routeToOrderDetail(orderNumber, customerId, location.pathname, history, dispatch)}>
          DETAILS
        </div>
      </div>
    </MobileOrderCardContainer>
  );
};
export default MobileOrderCard;
