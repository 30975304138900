import {useLocation} from 'react-router-dom';
import COMMERCE_CORE_CONSTANTS from '../../../../Core/constants';
import {useTypedSelector} from '../../../../hooks/store';
import {selectEnableCartPreview} from '../../../environment/slice/environment.slice';
import {isFabricatedCart, isSurfacesCart} from '../../../productType/service/productType.service';
import {selectActiveCart} from '../../slice/cart.slice';
import useWindowDimensions from '../../../../hooks/getWindowDimensions';

/**
 * Hooks that returns wether to show or hide the cart preview
 */
export const useShowCartPreview = (): boolean => {
  const location = useLocation();
  const enabled = useTypedSelector(selectEnableCartPreview);
  const cart = useTypedSelector(selectActiveCart);
  const hasItems = !!cart?.cartItems?.length;
  const isFab = hasItems && isFabricatedCart(cart);
  const isSurfaces = hasItems && isSurfacesCart(cart);
  const {width} = useWindowDimensions();

  const cartPreviewAllowedPaths = [
    COMMERCE_CORE_CONSTANTS.PATHNAMES.productDetails,
    '/product/slabs',
    '/product/samples',
    '/product/tile',
    '/product/store',
    COMMERCE_CORE_CONSTANTS.PATHNAMES.searchResults,
  ];

  return !!(
    enabled &&
    width >= 960 &&
    hasItems &&
    !isFab &&
    !isSurfaces &&
    cartPreviewAllowedPaths.some((path) => location?.pathname?.startsWith(path))
  );
};
