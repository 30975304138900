import styled from 'styled-components';
import {$gray30, $gray50, $gray78, $gray97, $newprimary, $newwhite} from '../../../../Styled/variables';

export const PaymentMethodContainer = styled.div`
  .card {
    background-color: ${$gray97} !important;
    border: none;
    box-shadow: 0px 1px 3px 0px rgba(101, 101, 101, 0.35);
  }

  .payment-type-header {
    background: rgba(0, 0, 0, 0.04) !important;
  }

  .payment-type-name {
    font-size: 20px;
  }

  .prop-name {
    font-weight: 700;
    color: ${$gray30};
  }

  .payment-type-description {
    font-weight: 500;
    color: ${$gray50};
  }

  .cart-total {
    box-shadow: 0px 1px 3px 0px rgb(101 101 101 / 35%);
    padding: 20px;
    border-radius: 3px;
    font-weight: 700;
    background-color: ${$gray30};
    font-size: 18px;

    .total-label {
      color: ${$gray78};
    }

    .total-value {
      color: ${$newwhite};

      .currency {
        font-size: 12px;
        font-weight: 600;
      }
    }
  }

  .btn-primary.disabled {
    margin: 0px;
    background-color: white !important;
    height: 25px;
    width: 25px;
    border-color: white;
    border-radius: 25px;
    opacity: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid ${$gray50};

    input {
      width: 13px;
      height: 13px;
      padding: 0px;
      border: none;
      background: transparent;
      pointer-events: none;
      border-radius: 15px;
    }
  }

  button {
    z-index: 0;
    position: relative;
  }

  .btn-primary.disabled.active {
    input {
      background-color: ${$newprimary};
    }
  }

  @media (max-width: 960px) {
    .payment-type-description {
      margin-left: 0px !important;
    }
  }
`;
