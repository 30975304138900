import React from 'react';
import {useHistory} from 'react-router-dom';
import {selectCurrentCustomer} from '../../../../features/customer/slice/customer.slice';
import {useTypedSelector} from '../../../../hooks/store';
import StatusCode from '../../StatusCode';
import DateBlock from '../DateBlock';
import QuoteDetails from '../QuoteDetails';
import MobileQuoteCardContainer from './MobileQuoteCard.styled';
import {formatQuoteCurrency} from '../../../../Framework/Services/formatCurrency';

const MobileQuoteCard = ({
  date,
  projectName,
  companyName,
  city,
  stateProvince,
  crmQuoteNumber,
  amount,
  status,
  quoteId,
  expirationDate,
}: {
  date: Date;
  projectName: string;
  companyName: string;
  city: string;
  stateProvince: string;
  crmQuoteNumber: number;
  amount: number;
  status: string;
  quoteId: number;
  expirationDate: string;
}) => {
  const history = useHistory();

  const currentCustomer = useTypedSelector(selectCurrentCustomer);

  const routeToQuoteDetail = (id: number) => {
    history.push(`/account/quotes/details/${id}?erpCustomerId=${currentCustomer.erpCustomerId}`);
  };
  return (
    <MobileQuoteCardContainer>
      <div className="mobile-card-body">
        <div className="row-1">
          <DateBlock date={date} />
          <div className="address-status-col">
            <StatusCode status={status} />
          </div>
        </div>
        <div className="row-2">
          <QuoteDetails
            projectName={projectName}
            quoteNumber={crmQuoteNumber}
            subcontractorName={companyName}
            location={`${city} ${stateProvince ? `,${stateProvince}` : ''}`}
            expirationDate={expirationDate}
          />
        </div>
      </div>
      <div className="amount-details-footer">
        <div className="amount">{amount ? `$${formatQuoteCurrency(amount)}` : `$0.00`}</div>
        <div className="details" onClick={() => routeToQuoteDetail(quoteId)}>
          DETAILS
        </div>
      </div>
    </MobileQuoteCardContainer>
  );
};
export default MobileQuoteCard;
