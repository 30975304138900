import styled from 'styled-components';
import {$newprimary, $newwhite} from '../../../../../Styled/variables';

export const AddCreditCardFormContainer = styled.div`
  width: 100%;

  #cc-name,
  #postal-code,
  #cvv,
  #card-number,
  #expiration-date {
    height: 35px;
    position: relative;
    padding-bottom: 17px;
    margin-bottom: 2px;
    border-radius: 3px;
    padding: 5px;
    border: 1px solid #f1f1f4;
    width: 100%;
    box-shadow: rgb(0 0 0 / 20%) 0px 1px 1px;
    color: rgb(0, 0, 0);
    transition: all 0.5s ease 0s;
    background-color: rgb(255, 255, 255) !important;
    padding: 8px 18px !important;
    font-size: 14px !important;
    line-height: 17px !important;
    font-weight: 600 !important;

    ::placeholder {
      font-size: 14px !important;
      font-weight: 500 !important;
    }
  }

  .cvv-popup {
    color: ${$newwhite};
    margin: 2px 0px 0px 10px;
    border: none;
    background: ${$newprimary};
    border-radius: 10px;
    font-size: 10px;
    font-weight: 700;
    height: fit-content;
    width: fit-content;
  }

  .input-tooltip-wrapper {
    width: 17px;
    height: 17px;
    margin-top: 2px;
    margin-left: 10px;

    .input-tooltip {
      font-size: 12px !important;
      width: 300px;
      top: 10px !important;
      left: 7px !important;
      position: relative !important;
    }
  }

  .error-message {
    color: red;
    font-size: 12px;
  }

  @media screen and (max-width: 960px) {
    .input-tooltip {
      width: 200px !important;
    }
  }
`;

export const AddCreditCardButton = styled.input`
  background: ${$newwhite} !important;
  color: ${$newprimary};
  height: 44.59px;
  box-shadow: rgb(0 0 0 / 20%) 0px 1px 1px;
  border-radius: 3px;
  font-weight: 600;

  @media (max-width: 960px) {
    width: 100%;
  }
`;
