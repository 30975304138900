import {baseApiCallWithReauth} from '../../../Framework/api-utils/api-utils';
import COMMERCE_CORE_CONSTANTS from '../../../Core/constants';
import qs from 'qs';

export const getSingleItemBatchRequest = async (params: any) => {
  try {
    return await baseApiCallWithReauth(
      'GET',
      COMMERCE_CORE_CONSTANTS.API_SERVICES.PRICING.singleItemBatch,
      qs.stringify(params),
      true
    );
  } catch (error: any) {
    throw error;
  }
};

export const getPricingSingleItemPricingRequest = async (params: any) => {
  try {
    return await baseApiCallWithReauth(
      'GET',
      `${COMMERCE_CORE_CONSTANTS.API_SERVICES.PRICING.singleItem}`,
      qs.stringify(params),
      true
    );
  } catch (error: any) {
    throw error;
  }
};

export const postPricingDiscountsRequest = async (params: any) => {
  try {
    return await baseApiCallWithReauth(
      'POST',
      COMMERCE_CORE_CONSTANTS.API_SERVICES.PRICING.discounts,
      JSON.stringify(params),
      true
    );
  } catch (error: any) {
    throw error;
  }
};
export const getPricingDiscountsRequest = async (params: URLSearchParams) => {
  try {
    return await baseApiCallWithReauth('GET', COMMERCE_CORE_CONSTANTS.API_SERVICES.PRICING.discounts, params);
  } catch (error: any) {
    throw error;
  }
};

export const getHeaderAdjustmentRequest = async () => {
  try {
    return await baseApiCallWithReauth('GET', COMMERCE_CORE_CONSTANTS.API_SERVICES.PRICING.headerAdjustment);
  } catch (error: any) {
    throw error;
  }
};

export const postCartVoucherCodesRequest = async (params: any) => {
  try {
    return await baseApiCallWithReauth(
      'POST',
      COMMERCE_CORE_CONSTANTS.API_SERVICES.PRICING.cartVoucherCodes,
      JSON.stringify(params),
      true
    );
  } catch (error: any) {
    throw error;
  }
};

export const deleteCartVoucherCodesRequest = async (validationId: string, cartId: number | string) => {
  try {
    return await baseApiCallWithReauth(
      'DELETE',
      `${COMMERCE_CORE_CONSTANTS.API_SERVICES.PRICING.cartVoucherCodes}/${validationId}`,
      qs.stringify({cartId}),
      true
    );
  } catch (error: any) {
    throw error;
  }
};

export const postCartHeaderAdjustmentRequest = async (params: any) => {
  try {
    return await baseApiCallWithReauth(
      'POST',
      COMMERCE_CORE_CONSTANTS.API_SERVICES.PRICING.cartHeaderAdjustment,
      JSON.stringify(params),
      true
    );
  } catch (error: any) {
    throw error;
  }
};

export const deleteCartHeaderAdjustmentRequest = async (headerAdjustmentId: any) => {
  try {
    return await baseApiCallWithReauth(
      'DELETE',
      `${COMMERCE_CORE_CONSTANTS.API_SERVICES.PRICING.cartHeaderAdjustment}/${headerAdjustmentId}`,
      null,
      true
    );
  } catch (error: any) {
    throw error;
  }
};
