import React, {useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';
import {selectCurrentCustomer} from '../../../../features/customer/slice/customer.slice';
import {CustomerFullProfile} from '../../../../features/customer/ICustomerState';
import {selectUserActions} from '../../../../features/permission/slice/permission.slice';
import Navigation from '../../../../Framework/Components/Navigation';
import {useTypedSelector} from '../../../../hooks/store';
import {hasPermission} from '../../../../store/permission/permission.service';
import {HeaderDescription} from './AccountNavigation.styles';

interface NavItem {
  href: string;
  name: string;
  urn: string | null;
  description?: string;
}

const AccountNavigation = () => {
  const defaultAccountNav: Array<NavItem> = [
    {
      href: '/account/orders',
      name: 'Orders',
      urn: null,
    },
    {
      href: '/account/quotes',
      name: 'Quotes',
      urn: 'urn:csa:commerceui:quoteAccountTab',
    },
    {
      href: '/account/basic-information',
      name: 'Basic Information',
      urn: null,
      description: 'Contact your market representative to make changes to your account information.',
    },
    {
      href: '/account/payment-preferences',
      name: 'Payment Preferences',
      urn: null,
    },
    {
      href: '/account/order-preferences',
      name: 'Order Preferences',
      urn: 'urn:csa:commerceui:orderPreferences',
    },
    {
      href: '/account/reports',
      name: 'Reports',
      urn: 'urn:csa:commerceui:account:viewReports',
    },
  ];
  const currentCustomer: CustomerFullProfile | null = useTypedSelector(selectCurrentCustomer);
  const userActions = useTypedSelector(selectUserActions);
  const [accountNav, setAccountNav] = useState<Array<NavItem>>([]);
  const [activeTab, setActiveTab] = useState<NavItem | null>(null);
  let location = useLocation();

  useEffect(() => {
    let hasView: boolean;
    if (userActions && userActions.length > 0 && accountNav.length === 0) {
      for (const nav of defaultAccountNav) {
        if (nav.urn) {
          hasView = hasPermission(nav.urn, userActions);
          if (hasView) {
            if (nav.name === 'Order Preferences') {
              let orderPreferences: Array<any> = [];

              for (const preference of orderPreferences) {
                let hasPreferencePermission = hasPermission(preference.urn, userActions);

                if (hasPreferencePermission) {
                  orderPreferences.push(preference);
                }
              }

              if (orderPreferences.length === 1) {
                nav.name = orderPreferences[0].name;
              }
            }

            setAccountNav((accountNav) => [...accountNav, nav]);
          }
        } else {
          setAccountNav((accountNav) => [...accountNav, nav]);
        }
      }
    }
  }, [defaultAccountNav, userActions, accountNav.length]);

  if (location.pathname.includes('/orders/details/') || location.pathname.includes('/quotes/details')) {
    return <></>;
  }
  return (
    <>
      <h1 className="account-navigation-header">
        {activeTab ? activeTab.name.toUpperCase() : ''} - {currentCustomer?.name}
      </h1>
      <HeaderDescription>{activeTab?.description ? activeTab.description : ''}</HeaderDescription>
      <div className="p-t-sm">
        <Navigation asNavigation={true} navItems={accountNav} onTabChange={setActiveTab}></Navigation>
      </div>
    </>
  );
};

export default AccountNavigation;
