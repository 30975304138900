import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {toast} from 'react-toastify';
import COMMERCE_CORE_CONSTANTS from '../../../Core/constants';
import {baseApiCallWithReauth} from '../../../Framework/api-utils/api-utils';
import {getProducts, pushViewItemListInformationToDataLayer} from '../service/productCatalog.service';
import {RootState} from '../../../store/store';
import {getBundleProgramRules} from '../controller/productCatalog.controller';
import {ProductCatalogState} from '../IProductCatalogState';

export const initialState: ProductCatalogState = {
  products: [],
  product: null,
  offset: 6,
  productId: null,
  filters: {
    displayCategories: [],
    subProductCategories: [],
    searchResultsCategories: null,
  },
  savedAttrs: {
    slabs: {},
    tile: {},
    store: {},
    samples: {},
  },
  tempCartItemArray: [],
  tempCartItemArrayTotal: 0,
  availableItemsToAdd: [],
  totalProducts: 0,
  bundleProgramRules: {
    entities: [],
    status: 'idle',
    error: null,
  },
};

export const setProductByIdAsync = createAsyncThunk(
  'productCatalog/setProductByIdAsync',
  async ({productId, data}: {productId: number | string; data: string}) => {
    let endpoint = `${COMMERCE_CORE_CONSTANTS.API_SERVICES.PRODUCT.products}/${productId}`;
    return await baseApiCallWithReauth('GET', endpoint, data);
  }
);

export const getBundleProgramRulesAsync = createAsyncThunk('productCatalog/getBundleProgramRulesAsync', async () => {
  return await getBundleProgramRules();
});

export const setProductGroupItemsByCodeAsync = createAsyncThunk(
  'productCatalog/setProductGroupItemsByCodeAsync',
  async ({product, data}: {product: any; data: any}, {rejectWithValue}) => {
    const endpoint = `${COMMERCE_CORE_CONSTANTS.API_SERVICES.PRODUCT.productGroups}/${product.productGroupCode}/items`;
    const result = await baseApiCallWithReauth('GET', endpoint, data);
    if (
      result &&
      (!result.productGroupCodes || result.productGroupCodes.length === 0) &&
      (!result.items || result.items.length === 0)
    ) {
      toast.error('The product has no item(s).');
      rejectWithValue(null);
    } else {
      return result;
    }
  }
);

export const setProductsAsync = createAsyncThunk(
  'productCatalog/setProductsAsync',
  async ({
    data,
    customer,
    currentProgram,
    productType,
    warehouseCode,
    shipToSites,
  }: {
    data: URLSearchParams;
    customer: any;
    currentProgram: any;
    productType: any;
    warehouseCode: any;
    shipToSites: any;
  }) => {
    const products = await getProducts(data, customer, currentProgram, productType, warehouseCode, shipToSites);
    pushViewItemListInformationToDataLayer(products?.results);

    return products;
  }
);

export const setLoadMoreAsync = createAsyncThunk(
  'productCatalog/setLoadMoreAsync',
  async ({
    customer,
    currentProgram,
    productType,
    warehouseCode,
    data,
  }: {
    customer: any;
    currentProgram: any;
    productType: any;
    warehouseCode: any;
    data: URLSearchParams;
  }) => {
    const products = await getProducts(data, customer, currentProgram, productType, warehouseCode);
    pushViewItemListInformationToDataLayer(products?.results);
    return products;
  }
);

export const productCatalogSlice = createSlice({
  name: 'productCatalog',
  initialState,
  reducers: {
    setProductId: (state, action) => {
      state.productId = action.payload;
    },
    setUpdatedProduct: (state, action) => {
      state.product = action.payload;
    },
    setUpdatedProducts: (state, action) => {
      state.products = action.payload;
    },
    setOffset: (state, action) => {
      state.offset = action.payload;
    },
    setUpdatedDisplayCategoriesFilters: (state, action) => {
      state.filters.displayCategories = action.payload;
    },
    setUpdatedSubProductCategoriesFilters: (state, action) => {
      state.filters.subProductCategories = action.payload;
    },
    setUpdatedSearchResultsCategoriesFilters: (state, action) => {
      state.filters.searchResultsCategories = action.payload;
    },
    setSavedAttrs: (state, action) => {
      state.savedAttrs = action.payload;
    },
    setTempCartItemArray: (state, action) => {
      state.tempCartItemArray = action.payload;
    },
    updateTempCartItemArrayItem: (state: any, action: any) => {
      state.tempCartItemArray.push(action.payload);
    },
    setAvailableItemsToAdd: (state, action) => {
      state.availableItemsToAdd = action.payload;
    },
    updateAvailableItemsToAddItem: (state: any, action: any) => {
      state.availableItemsToAdd[action.payload.index] = action.payload.item;
    },
    setTempCartItemArrayTotal: (state: any, action: any) => {
      state.tempCartItemArrayTotal = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setProductsAsync.pending, (state: any) => {
      state.products = initialState.products;
      state.totalProducts = initialState.totalProducts;
    });
    builder.addCase(setProductsAsync.fulfilled, (state: any, action: any) => {
      state.products = action.payload.results;
      state.totalProducts = action.payload.totalResults;
    });
    builder.addCase(setLoadMoreAsync.fulfilled, (state: any, action: any) => {
      let tempProductsArray = JSON.parse(JSON.stringify(state.products));
      for (const product of action.payload.results) {
        tempProductsArray.push(product);
      }
      state.products = tempProductsArray;
    });
    builder.addCase(setProductByIdAsync.fulfilled, (state: any, action: any) => {
      state.product = action.payload;
    });
    builder.addCase(setProductByIdAsync.rejected, (state: any, action: any) => {
      state.product = action.payload;
    });
    builder.addCase(setProductGroupItemsByCodeAsync.fulfilled, (state: any, action: any) => {
      state.product = action.payload;
    });
    builder.addCase(setProductGroupItemsByCodeAsync.rejected, (state: any, action: any) => {
      state.product = action.payload;
    });
    builder.addCase(getBundleProgramRulesAsync.fulfilled, (state: any, action: any) => {
      state.bundleProgramRules.entities = action.payload;
      state.bundleProgramRules.status = 'succeeded';
    });
    builder.addCase(getBundleProgramRulesAsync.pending, (state: any, action: any) => {
      state.bundleProgramRules.status = 'loading';
    });
  },
});

export const {
  setProductId,
  setUpdatedProduct,
  setUpdatedProducts,
  setOffset,
  setUpdatedDisplayCategoriesFilters,
  setUpdatedSubProductCategoriesFilters,
  setUpdatedSearchResultsCategoriesFilters,
  setSavedAttrs,
  setTempCartItemArray,
  updateTempCartItemArrayItem,
  setAvailableItemsToAdd,
  updateAvailableItemsToAddItem,
  setTempCartItemArrayTotal,
} = productCatalogSlice.actions;

export const selectProductId = (state: RootState) => state.productCatalog.productId;
export const selectProducts = (state: RootState) => state.productCatalog.products;
export const selectOffset = (state: RootState) => state.productCatalog.offset;
export const selectTotalProducts = (state: RootState) => state.productCatalog.totalProducts;
export const selectProduct = (state: RootState) => state.productCatalog.product;
export const selectDisplayCategoriesFilters = (state: RootState) => state.productCatalog.filters.displayCategories;
export const selectSubProductCategoriesFilters = (state: RootState) =>
  state.productCatalog.filters.subProductCategories;
export const selectSearchResultsCategoriesFilters = (state: RootState) =>
  state.productCatalog.filters.searchResultsCategories;
export const selectSavedAttrs = (state: RootState) => state.productCatalog.savedAttrs;
export const selectTempCartItemArray = (state: RootState) => state.productCatalog.tempCartItemArray;
export const selectAvailableItemsToAdd = (state: RootState) => state.productCatalog.availableItemsToAdd;
export const selectTempCartItemArrayTotal = (state: RootState) => state.productCatalog.tempCartItemArrayTotal;
export const selectBundleProgramRules = (state: RootState) => state.productCatalog.bundleProgramRules?.entities;
export const selectBundleProgramRulesStatus = (state: RootState) => state.productCatalog.bundleProgramRules?.status;

export default productCatalogSlice.reducer;
