import {ICambriaProductLineItemsData, IQuoteDetails} from '../../IQuoteState';
import {UiSettings} from '../../../environment/IEnvironmentState';
import {
  postQuoteWithParamsRequest,
  putCancelQuoteRequest,
  putQuoteCartRequest,
  putQuoteExtensionRequest,
  putQuoteWithParamsRequest,
} from '../controller/quoteDetails.controller';
import {callback} from '../../../callbacks/service/callbacks.service';
import {LineItem} from '../../../../App/AuthenticatedPages/Account/OrderHistory/OrderDetails/IOrderDetails';
import {getItems} from '../../../productCatalog/controller/productCatalog.controller';
import {processWidenLink} from '../../../order/orderDetails/service/orderDetails.service';
import {VoucherCode} from '../../../cart/ICartState';

export const getProductLineItemsData = (quote: any, isFabOrder: boolean): ICambriaProductLineItemsData => {
  const lineItems = quote.lines;
  const isPendingOrder = quote.statusCode === 'Pending';
  if (lineItems) {
    for (const lineItem of lineItems) {
      lineItem.deliveries = [{exptectedOrderDate: quote.expectedOrderDate}];
      if (isPendingOrder) {
        const voucherCodes =
          quote.orderMetadata && quote.orderMetadata.voucherCodes
            ? quote.orderMetadata.voucherCodes.filter(
                (voucher: any) =>
                  lineItem.itemNumber === voucher.itemNumber &&
                  (!lineItem.cartItemId || lineItem.cartItemId.toString() === voucher.cartItemId)
              )
            : [];
        lineItem.extendedNetPrice = lineItem.unitPrice * lineItem.uomLineQuantity;
        if (voucherCodes.length > 0) {
          lineItem.discountAmount = voucherCodes.reduce((a: any, b: any) => {
            return a + b.adjustmentAmount;
          }, 0);
          lineItem.adjustedExtendedNetPrice = lineItem.extendedNetPrice - lineItem.discountAmount;
          lineItem.adjustedUnitPrice = Math.round(lineItem.adjustedExtendedNetPrice / lineItem.uomLineQuantity);
        } else {
          lineItem.discountAmount = 0;
          lineItem.adjustedExtendedNetPrice = lineItem.extendedNetPrice - lineItem.discountAmount;
          lineItem.adjustedUnitPrice = lineItem.unitPrice;
        }
      } else {
        //adjusted means price with discounts

        if (lineItem.unitPrice < lineItem.basePrice) {
          lineItem.adjustedExtendedNetPrice = lineItem.unitPriceExtended;
        }
        lineItem.extendedNetPrice = lineItem.unitPriceExtended ?? lineItem.basePriceExtended;
        lineItem.adjustedUnitPrice = lineItem.unitPrice;
        lineItem.unitPrice = lineItem.unitPrice ?? lineItem.basePrice;
        lineItem.currency = quote.lines[0].currency;
      }
    }
  }

  return {
    lineItems,
    lineItemColumns: [],
    status: quote.statusDisplayName,
    discounts: [],
    isFabOrder: isFabOrder,
    shouldShowShipDate: false,
    shouldShowExpectedOrderDate: true,
    isShippedOrder: false,
    subtotal: 0,
    totalNetAmount: 0,
    originalCartId: '',
    lastCartId: '',
    isQuoteDetailsPage: true,
  };
};

export const getShouldShowExtend = (quote: IQuoteDetails, uiSettings: UiSettings): boolean => {
  if (!uiSettings?.enableQuoteExpirationButton) return false;
  const timeBeforeExpireInMinutes = uiSettings.timeBeforeQuoteExpirationToRequestExtensionInMinutes;
  const nowPlusTimeToExpireInLocalTime = new Date(Date.now() + timeBeforeExpireInMinutes * 60000);
  // the expireDate should be in Zulu so it will be autamatically calculated to a localTime
  const expireInLocalTime = new Date(quote.expirationDate);
  if (nowPlusTimeToExpireInLocalTime > expireInLocalTime) {
    return quote.statusCanRequestExtension;
  } else {
    return false;
  }
};

export const requestQuoteExtension = async (quoteId: string, erpCustomerId: number) => {
  if (!quoteId || !erpCustomerId) {
    return Promise.reject('Invalid/missing parameter.');
  }

  const result = await putQuoteExtensionRequest(quoteId, erpCustomerId);
  return await result.text();
};

export const editQuoteCart = async (quoteId: number, customer: any): Promise<any> => {
  try {
    const result = await putQuoteCartRequest(quoteId, customer);
    return await result.text();
  } catch (error: any) {
    console.error(error);
    throw error;
  }
};

export const putQuote = async (params: any, dispatch: any): Promise<any> => {
  let callbackId = await putQuoteWithParamsRequest(params);
  callbackId = await callbackId.text();

  while (callbackId.includes('"')) {
    callbackId = callbackId.replace('"', '');
  }

  try {
    const result = await callback(callbackId, 60, dispatch);
    return result.Id;
  } catch (error: any) {
    throw error;
  }
};

export const postQuoteToCart = async (params: any): Promise<any> => {
  const result = await postQuoteWithParamsRequest(params);
  return await result.text();
};

export const cancelQuote = async (quoteId: string, erpCustomerId: number) => {
  if (!quoteId || !erpCustomerId) {
    return Promise.reject('Invalid/missing parameter.');
  }
  const results = await putCancelQuoteRequest(quoteId, erpCustomerId);
  return await results.text();
};

/**
 * Mapper function to show quote detail lines in ProductTable
 * @param args
 */
export const getLineItemsForQuoteProductTable = async (args: {quoteDetails: IQuoteDetails}): Promise<LineItem[]> => {
  const {quoteDetails} = args;

  const lines = quoteDetails.lines;
  if (!lines?.length) {
    return [];
  }

  const itemInformation = await getItems(lines.map((l) => l.itemNumber?.toString()));

  let quoteItemsList: any[] = quoteDetails.lines;
  let quoteItemListToSave: LineItem[] = [];
  quoteItemsList.forEach((item: any) => {
    let newItem: LineItem = {
      designName: item.design,
      description: `${item.design} ${item.thickness} ${item.uomLineQuantity / item.quantity} ${item.polish} Slab`,
      quantity: item.quantity,
      unitPrice: item.unitPrice ? item.unitPrice : item.originalBasePrice,
      linePrice: item.basePriceExtended,
      itemNumber: parseInt(item.itemNumber),
      productType: item.productType,
      widenLink: '',
      status: quoteDetails.statusDisplayName,
      extendedQuantity: item.extendedQuantity,
      productId: item.productId,
      requestedDelivery: null,
      scheduleDelivery: null,
      expectedOrderDate: quoteDetails.expectedOrderDate ? new Date(quoteDetails.expectedOrderDate) : null,
      currency: quoteDetails.lines[0]?.currency,
      adjustedUnitPrice: 0,
      adjustedExtendedNetPrice: 0,
      discountAmount: 0,
      extendedNetPrice: 0,
      basePrice: item.basePrice,
      netPrice: item.netPrice,
      extendedBasePrice: item.extendedBasePrice,
    };
    let filteredList = itemInformation.filter((x) => x.id === item.itemNumber);
    let widenLink = '';
    try {
      widenLink = filteredList[0].attachments[0].location;
    } catch (e: any) {
      console.log(e.message);
    }
    let linkToSave = processWidenLink(widenLink, item.productType, 100);
    newItem.widenLink = linkToSave;
    const isPendingOrder = quoteDetails.statusCode === 'Pending';
    if (isPendingOrder) {
      const voucherCodes = quoteDetails.orderMetadata?.voucherCodes
        ? quoteDetails.orderMetadata.voucherCodes.filter(
            (voucher: VoucherCode) =>
              item.itemNumber === voucher.itemNumber &&
              (!item.cartItemId || item.cartItemId.toString() === voucher.cartItemId)
          )
        : [];
      newItem.extendedNetPrice = item.unitPrice * item.uomLineQuantity;
      if (voucherCodes.length > 0) {
        newItem.discountAmount = voucherCodes.reduce((a: any, b: any) => {
          return a + b.adjustmentAmount;
        }, 0);
        newItem.adjustedExtendedNetPrice = newItem.extendedNetPrice - newItem.discountAmount;
        newItem.adjustedUnitPrice = Math.round(newItem.adjustedExtendedNetPrice / item.uomLineQuantity);
      } else {
        newItem.discountAmount = 0;
        newItem.adjustedExtendedNetPrice = newItem.extendedNetPrice - newItem.discountAmount;
        newItem.adjustedUnitPrice = item.unitPrice;
      }
    } else {
      //adjusted means price with discounts

      if (item.unitPrice < item.basePrice) {
        newItem.adjustedExtendedNetPrice = item.unitPriceExtended;
      }
      newItem.extendedNetPrice = item.unitPriceExtended ?? item.basePriceExtended;
      newItem.adjustedUnitPrice = item.unitPrice;
      newItem.unitPrice = item.unitPrice ?? item.basePrice;
      newItem.currency = quoteDetails.lines[0].currency;
    }
    quoteItemListToSave.push(newItem);
  });
  return quoteItemListToSave;
};
