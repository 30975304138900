import styled from 'styled-components/macro';
import {$newprimary, $newprimaryfont} from '../../../Styled/variables';

export const FullscreenLoaderContainer = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2000;
  background-color: rgba(0, 0, 0, 0.5);

  .message {
    color: black;
    opacity: 1;
    background: ${$newprimary};
    position: absolute;
    top: 25%;
    left: 20%;
    width: 60%;
    padding: 10px;
    font-family: ${$newprimaryfont};
    font-size: 18px;
    border: 1px solid black;
    margin-bottom: 10px;
  }

  .loader {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -130px;
    margin-left: -160px;
    text-align: center;
    width: 320px;
    .shopping-cart-icon {
      background: url('/app/assets/images/cart-black.svg') no-repeat center center;
      background-size: 170px;
      height: 170px;
      width: 170px;
      display: inline-block;
    }
    .spinner {
      position: absolute;
      top: 42px;
      left: calc(50% - 25px);
      font-size: 60px;
      color: ${$newprimary};
    }
  }
`;
