import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import type {RootState} from '../../../store/store';

export const initialState: any = {};

export const reactpath = (path: any) => {
  return {
    type: 'REACTPATH',
    payload: path,
  };
};

export const reactPathSlice = createSlice({
  name: 'reactPath',
  initialState,
  reducers: {
    REACTPATH: (state, action: PayloadAction<any>) => {
      state.route = action.payload;
    },
  },
  extraReducers: {},
});

export const selectRoute = (state: RootState) => state.reactPath.route;

export default reactPathSlice.reducer;
