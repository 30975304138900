import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {baseApiCallWithReauth} from '../../../../Framework/api-utils/api-utils';
import {endpoint, initialOrderParams} from '../IOrderSearchState';
import {OrderSearch} from '../../ISearchState';

export const initialState: OrderSearch = {
  suggestions: [],
  orderUrlSearchParams: initialOrderParams,
  activeFilter: 'Details',
  orderActiveParams: [],
  loading: 'idle',
  currentRequestId: undefined,
};

export const orderSearchAsync = createAsyncThunk('orderSearch/orderSearchAsync', async (data: URLSearchParams) => {
  return await baseApiCallWithReauth('GET', endpoint, data);
});
export const loadMoreOrdersAsync = createAsyncThunk(
  'orderSearch/loadMoreOrdersAsync',
  async (data: URLSearchParams) => {
    return await baseApiCallWithReauth('GET', endpoint, data);
  }
);

export const orderSearchSlice = createSlice({
  name: 'orderSearch',
  initialState,
  reducers: {
    setActiveOrderFilter: (state, action) => {
      state.activeFilter = action.payload;
    },
    setOrderUrlSearchParams: (state, action) => {
      state.orderUrlSearchParams = action.payload;
    },
    setOrderActiveParams: (state, action) => {
      state.orderActiveParams = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(orderSearchAsync.pending, (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending';
      }
      state.currentRequestId = action.meta.requestId;
      state.suggestions = [];
    });
    builder.addCase(orderSearchAsync.fulfilled, (state, action) => {
      const {requestId} = action.meta;
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle';
        state.suggestions = action.payload.results;
        state.currentRequestId = undefined;
      }
    });
    builder.addCase(loadMoreOrdersAsync.fulfilled, (state, action) => {
      state.suggestions = action.payload.results;
    });
  },
});

export const {setActiveOrderFilter, setOrderUrlSearchParams, setOrderActiveParams} = orderSearchSlice.actions;
export default orderSearchSlice.reducer;
