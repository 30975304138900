import React from 'react';
import CambriaCarouselCard from './Card';
import Carousel from 'react-bootstrap/Carousel';
import useWindowDimensions from '../../../hooks/getWindowDimensions';
import {CarouselContainer} from './Carousel.styled';

interface CambriaCarouselItem {
  imageUrl: string;
  subHeading: string;
  description: string;
  callToActionLabel: string;
  callToActionUrl: string;
}

interface CambriaCarouselProps {
  items: CambriaCarouselItem[];
  title?: string;
  isOnAcademyPage?: boolean;
}

const CambriaCarousel = ({items, title, isOnAcademyPage}: CambriaCarouselProps) => {
  const {width} = useWindowDimensions();

  const renderSlide = (slideData: JSX.Element[]) => {
    return (
      <Carousel.Item className="carousel-card-group carousel-item">
        <div className="d-flex justify-content-center">{slideData}</div>
      </Carousel.Item>
    );
  };

  const renderCards = (cardData: CambriaCarouselItem[]) => {
    return cardData.map((x: CambriaCarouselItem) => {
      return (
        <CambriaCarouselCard
          key={x.imageUrl}
          image={x.imageUrl}
          description={x.description}
          callToActionLabel={x.callToActionLabel}
          subHeading={x.subHeading}
          callToActionUrl={x.callToActionUrl}></CambriaCarouselCard>
      );
    });
  };

  const prevArrowIcon = <i className="icon icons-cambria-Ui-Left-Arrow-No-Tail" />;
  const nextArrowIcon = <i className="icon icons-cambria-Ui-Right-Arrow-No-Tail" />;

  const renderCarouselByWindowSize = (carouselCardInfo: CambriaCarouselItem[]) => {
    if (width < 480) {
      return (
        <Carousel prevIcon={prevArrowIcon} nextIcon={nextArrowIcon} touch={true} interval={null}>
          {renderSlide(renderCards(carouselCardInfo.slice(0, 1)))}
          {renderSlide(renderCards(carouselCardInfo.slice(1, 2)))}
          {renderSlide(renderCards(carouselCardInfo.slice(2, 3)))}
          {!isOnAcademyPage && renderSlide(renderCards(carouselCardInfo.slice(3, 4)))}
          {!isOnAcademyPage && renderSlide(renderCards(carouselCardInfo.slice(4, 5)))}
          {!isOnAcademyPage && renderSlide(renderCards(carouselCardInfo.slice(5, 6)))}
        </Carousel>
      );
    } else if (width < 768) {
      return (
        <Carousel prevIcon={prevArrowIcon} nextIcon={nextArrowIcon} touch={true} interval={null}>
          {renderSlide(renderCards(carouselCardInfo.slice(0, 2)))}
          {isOnAcademyPage
            ? renderSlide(renderCards(carouselCardInfo.slice(2, 3)))
            : renderSlide(renderCards(carouselCardInfo.slice(2, 4)))}
          {!isOnAcademyPage && renderSlide(renderCards(carouselCardInfo.slice(4, 6)))}
        </Carousel>
      );
    }
    return (
      <Carousel prevIcon={prevArrowIcon} nextIcon={nextArrowIcon} touch={true} interval={null}>
        {renderSlide(renderCards(carouselCardInfo.slice(0, 3)))}
        {!isOnAcademyPage && renderSlide(renderCards(carouselCardInfo.slice(3, 6)))}
      </Carousel>
    );
  };

  return (
    <div className="carousel-section new-style">
      <CarouselContainer hideIndicators={isOnAcademyPage && width > 768} className="react-bootstrap-hack">
        <div className="carousel slide cambria-carousel">
          {title && <div className="text-center carousel-heading">{title}</div>}
          {renderCarouselByWindowSize(items)}
        </div>
      </CarouselContainer>
    </div>
  );
};

export default CambriaCarousel;
