import {useField, useFormikContext} from 'formik';
import React, {useEffect, useState} from 'react';
import ReactSelect, {StylesConfig, components} from 'react-select';
import {$gray78, $gray93} from '../../../Styled/variables';
import {CambriaSelectContainer} from './CambriaSelect.styled';
import Icon from '../Icon';

const CambriaSelect = ({
  items,
  name,
  placeholder,
  displayValue = 'label',
  label,
  required,
  defaultValue,
  onChange,
  disabled,
  tabIndex,
  errorMessage = 'This field is required',
  formikFormProps,
  selectReference,
  infoText,
  disableTextInput,
  multiSelect,
  addOnTemplate,
  outlined = false,
}: {
  items: any[];
  name: string;
  placeholder: string;
  displayValue?: string;
  label?: string | JSX.Element;
  required?: boolean;
  defaultValue?: any;
  onChange?: Function;
  disabled?: boolean;
  tabIndex?: number;
  errorMessage?: string;
  formikFormProps?: any;
  selectReference?: any;
  infoText?: string;
  disableTextInput?: boolean;
  multiSelect?: boolean;
  addOnTemplate?: string;
  outlined?: boolean;
}) => {
  const {setFieldValue} = useFormikContext();

  const [value, setValue] = useState<any>();
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    if (defaultValue) {
      if (typeof defaultValue === 'string') {
        setValue({[displayValue]: defaultValue});
      } else {
        setValue(defaultValue);
      }
      if (setFieldValue) {
        setFieldValue(name, defaultValue);
      }
    } else if (!defaultValue) {
      setValue(null);
    }
  }, [defaultValue, displayValue, name, setFieldValue]);

  const colourStyles: StylesConfig = {
    menu: (base) => {
      return {
        ...base,
        marginTop: 1,
      };
    },
    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        color: `${$gray78}`,
        fontWeight: 400,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      };
    },
    control: (styles, {isFocused}) => ({
      ...styles,
      backgroundColor: 'white',
      border: isFocused ? '1px solid #c59617' : showError ? '1px solid #c40f0f' : '0px solid black',
      height: '30px',
      minHeight: '35px',
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      padding: '0 20px 0 12px',
    }),
    input: (provided, state) => ({
      ...provided,
      margin: '0',
      boxShadow: '0 0 0 0',
    }),
    option: (styles, {isDisabled, isFocused, isSelected}) => {
      return {
        ...styles,
        backgroundColor: isDisabled ? undefined : isSelected ? `${$gray93}` : isFocused ? 'white' : undefined,
        color: isDisabled ? '#ccc' : isSelected ? (isFocused ? '#c59617' : 'black') : isFocused ? '#c59617' : 'black',
      };
    },
  };

  const DropdownIndicator = (props: any) => {
    const {menuIsOpen} = props.selectProps;

    return (
      <components.DropdownIndicator {...props}>
        {addOnTemplate && <div className="cambria-select-add-on-template-text">{addOnTemplate}</div>}
        <Icon
          size="18"
          weight="bold"
          className={`${menuIsOpen ? 'is-open' : ''}`}
          name={name + '-select-icon'}
          icon="icons-cambria-Ui-Down-Arrow-No-Tail"
        />
      </components.DropdownIndicator>
    );
  };

  const Option = (props: any) => (
    <components.Option
      {...props}
      key={props.innerProps.key}
      innerProps={Object.assign({}, props.innerProps, {'data-testid': props.label + '-select'})}>
      {props.label}
    </components.Option>
  );

  const ReactSelectWithFormik = () => {
    const [field, meta, helpers] = useField(name);
    const {value} = meta;
    const {setValue} = helpers;

    return (
      <div data-testid={name + '-select-toggle'}>
        <ReactSelect
          {...field}
          ref={selectReference}
          onBlur={() => formikFormProps.setFieldTouched && formikFormProps.setFieldTouched(name, true)}
          aria-label={name}
          theme={(theme) => ({
            ...theme,
            border: 'transparent',
            borderRadius: 3,
            colors: {
              ...theme.colors,
              primary25: '#fdfdfd0',
              primary: '#c5971796',
            },
          })}
          tabIndex={tabIndex}
          styles={colourStyles}
          className={`react-select ${meta.error && meta.touched ? 'error' : ''} ${outlined ? 'outlined' : ''}`}
          defaultValue={value}
          placeholder={placeholder}
          options={items}
          name={name}
          id={name}
          isSearchable={!disableTextInput}
          getOptionLabel={(option) => option?.[displayValue]}
          getOptionValue={(option) => option}
          onChange={(selection) => {
            onChange && onChange(selection);

            if (selection[displayValue].type === 'div') {
              formikFormProps.handleChange && formikFormProps.handleChange(name)(selection.code);
            } else {
              formikFormProps.handleChange && formikFormProps.handleChange(name)(selection[displayValue]);
            }

            if (multiSelect) {
              setValue(null);
            } else {
              setValue(selection);
            }
          }}
          components={{
            IndicatorSeparator: () => null,
            DropdownIndicator,
            Option,
          }}
          isDisabled={disabled}
        />
        {meta.error && meta.touched && <p className="error-messaging">{meta.error}</p>}
      </div>
    );
  };

  return (
    <>
      <CambriaSelectContainer>
        {label && typeof label === 'string' && (
          <span>
            {label && <label htmlFor={name}>{label}</label>}
            {required && <strong> *</strong>}
            {label && infoText && (
              <em className="select-tooltip-wrapper tooltip-wrapper fa fa-question-circle">
                <span className="select-tooltip tooltip">{infoText}</span>
              </em>
            )}
          </span>
        )}
        {label && typeof label !== 'string' && React.isValidElement(label) && label}
        {formikFormProps ? (
          <ReactSelectWithFormik />
        ) : (
          <div data-testid={name + '-select-toggle'}>
            <ReactSelect
              ref={selectReference}
              onBlur={() => required && !value && setShowError(true)}
              theme={(theme) => ({
                ...theme,
                border: 'transparent',
                borderRadius: 3,
                colors: {
                  ...theme.colors,
                  primary25: '#fdfdfd0',
                  primary: '#c5971796',
                },
              })}
              aria-label={name}
              tabIndex={tabIndex}
              styles={colourStyles}
              className={`react-select ${showError ? 'error' : ''} ${outlined ? 'outlined' : ''}`}
              value={value}
              defaultValue={defaultValue}
              placeholder={placeholder}
              options={items}
              name={name}
              id={name}
              isSearchable={!disableTextInput}
              getOptionLabel={(option) => option?.[displayValue]}
              getOptionValue={(option) => option}
              onChange={(selection) => {
                onChange && onChange(selection);
                if (multiSelect) {
                  setValue(null);
                } else {
                  setValue(selection);
                }
                setShowError(false);
              }}
              components={{
                IndicatorSeparator: () => null,
                DropdownIndicator,
                Option,
              }}
              isDisabled={disabled}
            />
            {showError && <p className="error-messaging">{errorMessage}</p>}
          </div>
        )}
      </CambriaSelectContainer>
    </>
  );
};

export default CambriaSelect;
