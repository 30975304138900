import React, {FC} from 'react';
import CambriaSelect from '../../../../../../../Framework/Components/CambriaSelect';
import {useTypedSelector} from '../../../../../../../hooks/store';
import {selectUiSettings} from '../../../../../../../features/environment/slice/environment.slice';

interface FabricationPieceThicknessSelectProps {
  selectedDesign?: string | null;
  selectedProductType?: string | null;
  selectedProductApplication?: string | null;
  onChange?: (option: {name: string}) => void;
  defaultValue?: string | null;
}

const FabricationPieceThicknessSelect: FC<FabricationPieceThicknessSelectProps> = ({
  selectedDesign,
  selectedProductType,
  selectedProductApplication,
  defaultValue,
  onChange,
}) => {
  const uiSettings = useTypedSelector(selectUiSettings);
  const thicknesConfig = uiSettings?.fabricationOrderConfugurations?.thickness;

  const thicknessOptions = [{name: '2CM'}, {name: '3CM'}, {name: '4CM'}, {name: '6CM'}, {name: '9CM'}];

  let availableThicknessOptions = [...thicknessOptions];

  if (thicknesConfig?.rules && (selectedProductType || selectedProductApplication)) {
    // if productTypes or productApplications is empty/null, the rule applies for every
    // type or application
    const disabledThicknessOptions = thicknesConfig.rules
      .filter(
        (rule) =>
          (!rule.productTypes ||
            rule.productTypes.length === 0 ||
            rule.productTypes.includes(selectedProductType as string)) &&
          (!rule.productApplications ||
            rule.productApplications.length === 0 ||
            rule.productApplications.includes(selectedProductApplication as string))
      )
      .map((rule) => rule.disabledThicknessOptions)
      .flat();
    availableThicknessOptions = availableThicknessOptions.filter((opt) => !disabledThicknessOptions.includes(opt.name));
  }

  if (selectedDesign) {
    if (thicknesConfig?.designsWith1cmThickness.includes(selectedDesign)) {
      availableThicknessOptions.splice(0, 0, {name: '1CM'});
    }
    if (thicknesConfig?.designsWith6mmThickness?.includes(selectedDesign)) {
      availableThicknessOptions.splice(0, 0, {name: '6MM'});
    }
  }

  if (defaultValue && !availableThicknessOptions.map((o) => o.name).includes(defaultValue) && onChange) {
    onChange({name: ''});
  }

  return (
    <CambriaSelect
      required
      name="pieceThickness"
      items={availableThicknessOptions}
      displayValue="name"
      defaultValue={defaultValue}
      label="FABRICATED PIECE THICKNESS"
      placeholder="Piece Thickness"
      onChange={onChange}
    />
  );
};

export default FabricationPieceThicknessSelect;
