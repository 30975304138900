import React from 'react';
import SlabOrderDetails from './SlabOrderDetails/SlabOrderDetails.component';
import SamplesOrderDetails from './SamplesOrderDetails/SamplesOrderDetails.component';
import {useTypedSelector} from '../../../../hooks/store';
import {selectActiveCart} from '../../../../features/cart/slice/cart.slice';
import {Cart} from '../../../../features/cart/ICartState';
import FabricationOrderDetails from './FabricationOrderDetails';
import {selectUiSettings} from '../../../../features/environment/slice/environment.slice';

const OrderDetails = () => {
  const activeCart: Cart | null = useTypedSelector(selectActiveCart);
  const uiSettings = useTypedSelector(selectUiSettings);

  // Changing the use of a state into a derived value saves us 2 re-renders
  const productType = activeCart?.cartItems?.[0]?.productType;

  if (productType === 'Slab') {
    return <SlabOrderDetails orderDetails={activeCart?.orderDetails[0]}></SlabOrderDetails>;
  }

  if (productType === 'Samples' || productType === 'Store' || productType === 'Tile') {
    return <SamplesOrderDetails orderDetails={activeCart?.orderDetails[0]}></SamplesOrderDetails>;
  }

  if (
    uiSettings?.fabricationOrderDetailsCheckoutIsActive &&
    (productType === 'Fabricated' ||
      productType === 'Fabrication' ||
      productType === 'FabricatedService' ||
      productType === 'Surfaces')
  ) {
    return <FabricationOrderDetails orderDetails={activeCart?.orderDetails[0]}></FabricationOrderDetails>;
  }

  return <div></div>;
};

export default OrderDetails;
