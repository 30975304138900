import React, {FC, ReactNode} from 'react';
import {CambriaTooltipContainer} from './CambriaTooltip.styled';

interface CambriaTooltipProps {
  children: ReactNode;
  tooltip: string;
  position?: 'up' | 'left' | 'right' | 'down' | 'up-left' | 'up-right' | 'down-left' | 'down-right';
  size?: 'small' | 'medium' | 'large' | 'fit';
}

const CambriaTooltip: FC<CambriaTooltipProps> = ({children, tooltip, position = 'down', size}) => {
  if (!tooltip || tooltip.length === 0) return <>{children}</>;

  return (
    <CambriaTooltipContainer aria-label={tooltip} data-balloon-pos={position} data-balloon-length={size}>
      {children}
    </CambriaTooltipContainer>
  );
};

export default CambriaTooltip;
