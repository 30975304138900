import React from 'react';
import AddressBlockContainer from './AddressBlock.styled';

const AddressBlock = ({
  shipToName,
  address1,
  address2,
  address3,
  city,
  stateProvinceCode,
  postalCode,
}: {
  shipToName: string;
  address1: string;
  address2: string;
  address3: string;
  city?: string;
  stateProvinceCode?: string;
  postalCode?: string;
}) => {
  const determineAddressLine = (line1: string, line2?: string, line3?: string) => {
    if (line2 && line3) {
      return `${line1} ${line2} ${line3}`;
    } else if (line2) {
      return `${line1} ${line2}`;
    }
    return line1;
  };

  return (
    <AddressBlockContainer>
      <span className="order-address-name">{shipToName}</span> <br />
      {determineAddressLine(address1, address2, address3)}
      <br /> {city ? `${city}, ${stateProvinceCode ? stateProvinceCode : ''} ${postalCode ? postalCode : ''}` : ''}
    </AddressBlockContainer>
  );
};
export default AddressBlock;
