import styled from 'styled-components';
import {$gray30, $gray50, $gray60, $gray93, $newprimary, $newwhite} from '../../../../Styled/variables';

export const QuoteDetailsContainer = styled.div`
  font-size: 12px;
  font-weight: 600;
  margin: 0px 0px 40px 0px;

  .main-header {
    font-weight: 500;
    color: ${$gray50};
    margin: 10px 0px;
  }

  .mobile-subheader {
    font-weight: 500;
    color: ${$gray50};
  }

  .icons-header {
    display: flex;
    justify-content: flex-end;
    justify-content: flex-end;
    flex-direction: row;
    align-items: flex-end;

    .icon {
      margin-left: 5px;
    }

    .icons-container {
      display: flex;
      align-items: center;
    }
  }

  .back-button-row {
    margin: 0px;

    .back-button {
      display: flex;
      color: ${$newprimary};
      width: 180px;
      font-size: 12px;
      align-items: center;
      justify-content: space-evenly;

      :hover {
        cursor: pointer;
        color: rgb(151, 115, 18) !important;
      }
    }
  }

  .quote-detail-table-row-mobile {
    background-color: ${$newwhite};
    padding: 10px 10px;
    border-radius: 3px;
    box-shadow: 0px 1px 3px 0px rgba(101, 101, 101, 0.35);
    color: ${$gray60};

    .quote-date-container {
      margin-right: 10px;
      padding: 0px 15px;
    }

    .quote-status-container {
      max-width: 75px;
    }
  }

  .status-container {
    display: flex;
    justify-content: flex-start;
  }

  .quote-row-mobile {
    margin: 10px 0px 0px 0px;
  }

  .mobile-header-row {
    margin-top: 5px;
    display: flex;
    align-items: center;
  }

  .quote-details-value {
    color: ${$gray30};
  }

  .quote-detail-table-row {
    display: flex;
    justify-content: space-between;
    background-color: ${$newwhite};
    padding: 20px 20px;
    border-radius: 3px;
    box-shadow: 0px 1px 3px 0px rgba(101, 101, 101, 0.35);
    color: ${$gray60};

    .favorite-name {
      display: flex;
      align-items: center;
      color: ${$gray30};
    }
  }

  .quote-date-info-container {
    display: flex;
    flex-direction: row;
    width: fit-content;

    .quote-details-subcontainer {
      display: flex;
      width: 100%;
      flex-direction: column;
      justify-content: space-evenly;
      margin-left: 10px;
      color: ${$gray60};
      font-size: 12px;
      font-weight: 600;
    }
  }

  .align-right {
    display: flex;
    justify-content: flex-end;
  }

  .quantity-box-container {
    display: flex;
    align-items: center;
    justify-content: start;
    padding: 0px;

    .quantity-box {
      font-size: 16px;
      width: 40px;
      height: 40px;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-align-items: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      border: 2px solid #d8d8d8b0;
      border-radius: 3px;
    }
  }

  .design-name {
    font-size: 18px;
  }

  .row {
    margin: 5px 0px;
  }

  .line-price-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 0px;

    .line-price-label,
    .usd-label {
      font-size: 10px;
      margin-top: -5px;
    }

    .line-price {
      font-size: 18px;
    }
  }

  .delivery-date-mobile {
    color: ${$gray60};

    .delivery-date-mobile-quantity {
      float: right;
    }

    .date {
      color: ${$gray30};
    }
  }

  .delivery-date-mobile-expected-requested {
    margin-top: 15px;
  }

  .order-summary-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .order-summary-heading {
      font-weight: 600 !important;
      color: ${$gray30} !important;
      font-size: 22px;
    }

    .item-number {
      font-weight: 600;
      color: ${$gray50};
    }

    .before-tax-text {
      color: ${$gray60};
      font-size: 12px;
      font-weight: 600;
    }

    .before-tax-total {
      color: ${$gray30};
      font-weight: 700;
    }

    .shipping-and-handling-msg {
      font-size: 10px;
      color: ${$gray30};
      background-color: ${$gray93};
      padding: 12px;
      border-radius: 3px;
    }

    .order-total-container {
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding: 15px;
      background: ${$gray30};
      color: white;
      margin-top: 12px;
      font-weight: 500;
      border-radius: 3px;

      .total {
        font-weight: 700;
      }
    }

    .back-button {
      width: 100%;
      margin-top: 12px;
      background-color: ${$newwhite};
      border: 2px solid #d8d8d8b0;
      color: ${$gray60};
      padding: 6px;
      font-size: 12px;

      :hover {
        background-color: #e6e6e6;
      }

      i {
        margin-right: 5px;
      }
    }

    .btn.btn-primary {
      background-color: ${$newprimary};
      padding: 6px;
      width: 100%;
      font-weight: 600;
      border: none;
      font-size: 12px;
      margin-top: 12px;

      :hover {
        background-color: rgb(151, 115, 18);
      }
    }

    .remove-order {
      color: red;
      text-decoration: underline;
      width: 100%;
      font-weight: 600;
      margin-top: 12px;
      display: flex;

      justify-content: center;

      :hover {
        cursor: pointer;
      }
    }
  }

  .product-table-container {
    margin: 10px 0px 20px 0px;

    tbody {
      box-shadow: 0px 1px 3px 0px rgba(101, 101, 101, 0.35);

      tr {
        :nth-of-type(even) {
          background-color: ${$gray93};
        }

        :nth-of-type(odd) {
          background-color: ${$newwhite};
        }
      }
    }

    th {
      border-top: none;
      border-bottom: none !important;
      color: ${$gray30} !important;
      font-weight: 700;
      text-align: center;
    }

    td {
      border: none;
      text-align: center;

      .usd-label {
        font-size: 10px;
      }

      .quantity-box {
        width: fit-content;
        height: fit-content;
        min-width: 30px;
        min-height: 30px;
        padding: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 2px solid #d8d8d8b0;
        border-radius: 3px;
      }
    }
  }

  button {
    font-size: 12px !important;
    font-weight: 600 !important;
    padding: 10px !important;
    margin-top: 10px;
  }

  .btn.btn-primary {
    background-color: ${$newprimary};
    padding: 6px;
    width: 100%;
    font-weight: 600;
    border: none;
    font-size: 12px;
    margin-top: 12px;

    :hover {
      background-color: rgb(151, 115, 18);
    }
  }

  .remove-order {
    color: red;
    text-decoration: underline;
    width: 100%;
    font-weight: 600;
    margin-top: 12px;
    display: flex;

    justify-content: center;

    :hover {
      cursor: pointer;
    }
  }

  .resubmit-reorder-buttons {
    display: flex;
    justify-content: center;
    align-items: center;

    .resubmit-reorder-spinner {
      margin-left: 5px;
    }
  }

  .discounts-section {
    text-align: right;
    width: 100%;
    padding: 7px 0px;
    font-size: 14px;
    font-weight: 700;

    .discount-code-text {
      font-size: 12px;
      color: ${$gray60};
      font-weight: 600;
    }

    .discount-amount {
      color: ${$gray30};
      font-size: 12px;
    }
  }

  .price-per-sqft-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    font-weight: 700;
  }

  .price-per-sqft {
    font-size: 14px;
  }

  .mobile-quantity-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    font-size: 16px;
  }

  .fa-print {
    margin-right: 5px;
  }

  @media print {
    .noprint {
      visibility: hidden;
    }
  }
  @media (max-width: 960px) {
    margin-top: 20px;
    .main-header {
      margin: 0px;
    }
  }
`;
