export const camelCaseFilter = (input: any) => {
  if (!input) {
    return input;
  }
  return input
    .replace(/[^A-Za-z0-9]+(\w|$)/g, (_: any, letter: any) => {
      return letter.toUpperCase();
    })
    .replace(/^(.)/, (_: any, letter: any) => {
      return letter.toLowerCase();
    });
};
