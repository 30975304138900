import styled from 'styled-components';
import {$gray30, $gray78, $newprimary} from '../../../../Styled/variables';

export const CambriaDropzone = styled.div`
  border-radius: 3px;
  font-weight: 700 !important;
  color: ${$gray30};
  border: 2px dashed ${$gray78};
  height: 100px;
  background-color: #e4e4e4;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 1px 3px 0px rgba(101, 101, 101, 0.35);
  font-size: 14px;

  p {
    font-weight: 700 !important;
  }

  .choose-file-btn {
    background-color: white;
    margin-left: 3px;
    box-shadow: 0px 1px 3px 0px rgba(101, 101, 101, 0.35);
    border-radius: 3px;
    padding: 10px;
    color: ${$newprimary};

    :hover {
      cursor: pointer;
    }
  }
`;
