import React, {useEffect, useState} from 'react';
import {Cart, NewCart} from '../../../../../../features/cart/ICartState';
import {IFullPieces} from '../../../../../../features/fabrication/IFabricationState';
import {CambriaSavedUploadFile, FabricationFile, IGetFilesRequest} from '../../../../../../features/file/IFileState';
import {IOrderDetails} from '../../../../../../features/order/orderDetails/IOrderDetailsState';
import Rejections from '../Rejections';
import TotalPrice from '../TotalPrice';
import CambriaFabricationPieceCartInfo from './CambriaFabricationPieceCartInfo';
import CambriaFileUpload from './CambriaFileUpload';
import EditItem from './EditItem/EditItem.component';
import FabCartContainer from './FabricationCart.styled';
import {getFilesRequest} from '../../../../../../features/file/controller/file.controller';
import {IQuoteDetails} from '../../../../../../features/quote/IQuoteState';
import {
  checkForEdgeProfiles,
  getParentChildCartView,
  getParentChildCartViewFromQuote,
  onPageLoad,
} from '../../../../../../features/fabrication/service/fabrication.service';

const FabricationCart = ({
  order,
  shouldUseOrderMetadata,
  cart,
  fabOrderPage,
  quote,
}: {
  order?: IOrderDetails;
  shouldUseOrderMetadata: boolean;
  cart?: Cart;
  fabOrderPage?: boolean;
  quote?: IQuoteDetails;
}) => {
  const [uploadedFiles, setUploadedFiles] = useState<FabricationFile[] | CambriaSavedUploadFile[]>([]);
  const [getFilesHasBeenCalled, setGetFilesHasBeenCalled] = useState(false);
  const [parentChildView, setParentChildView] = useState<Cart>(NewCart());
  const [cartItemsArray, setCartItemsArray] = useState<Array<IFullPieces>>([]);
  const [cartItemsInitialLength, setCartItemsInitialLength] = useState<number>();
  const [rerunCartItemsArray, setRerunCartItemsArray] = useState<boolean>(false);
  const [reloadFabricationCart, setReloadFabricationCart] = useState<boolean>(false);

  useEffect(() => {
    if (cart) {
      setReloadFabricationCart(true);
    }
  }, [cart]);

  // Map order details data if needed
  useEffect(() => {
    if (
      reloadFabricationCart ||
      (parentChildView.cartItems.length === 0 && cartItemsInitialLength === undefined) ||
      cartItemsInitialLength !== cart?.cartItems.length ||
      (!fabOrderPage && cart && parentChildView.totalNetAmount !== cart?.totalNetAmount)
    ) {
      if (!cart && order && order.orderLineItems.length > 0) {
        let mappedCart = getParentChildCartView(shouldUseOrderMetadata, order);
        setParentChildView(mappedCart);
      } else if (cart) {
        setParentChildView(cart);
      } else if (quote) {
        let mappedCart = getParentChildCartViewFromQuote(quote);
        setParentChildView(mappedCart);
      }
      setCartItemsInitialLength(cart?.cartItems.length);
      setRerunCartItemsArray(true);
      setReloadFabricationCart(false);
    }
  }, [
    order,
    shouldUseOrderMetadata,
    parentChildView,
    cart,
    cartItemsInitialLength,
    reloadFabricationCart,
    fabOrderPage,
    quote,
  ]);

  useEffect(() => {
    if (uploadedFiles.length === 0 && order && order.files.length > 0 && !fabOrderPage) {
      setUploadedFiles(order.files);
    }
  }, [order, uploadedFiles, fabOrderPage]);

  useEffect(() => {
    const getUploadedFiles = async () => {
      const params: IGetFilesRequest = {
        externalId: cart?.id,
        erpCustomerId: cart?.erpCustomerId,
        groupName: 'Fabrication',
        limit: 1,
        sort: ['createdAt'],
      };
      const files = await getFilesRequest(params);
      setUploadedFiles(files.results);
    };

    if (!getFilesHasBeenCalled && uploadedFiles.length === 0 && cart && !fabOrderPage) {
      getUploadedFiles();
      setGetFilesHasBeenCalled(true);
    }
  }, [cart, fabOrderPage, uploadedFiles, getFilesHasBeenCalled]);

  useEffect(() => {
    if ((cartItemsArray.length === 0 && parentChildView.id !== '') || rerunCartItemsArray) {
      const cartItems = onPageLoad(parentChildView);
      setCartItemsArray(cartItems);
      checkForEdgeProfiles(cartItems);
      setRerunCartItemsArray(false);
    }
  }, [parentChildView, cartItemsArray, rerunCartItemsArray]);

  return (
    <FabCartContainer>
      <div className={`fab-piece-table ${!!cart ? 'no-shadow' : ''}`}>
        {cartItemsArray.length !== 0 && (
          <div className="fab-piece-information-section">
            {cartItemsArray.map((cartItem, index) => {
              return (
                <div key={index}>
                  <CambriaFabricationPieceCartInfo
                    key={index}
                    cart={parentChildView}
                    piece={cartItem}
                    fabOrderPage={fabOrderPage}
                  />
                  {fabOrderPage && parentChildView.transactionType !== 'QuoteCart' && (
                    <EditItem parentItem={cartItem} />
                  )}
                </div>
              );
            })}
          </div>
        )}
        <div>
          <TotalPrice orderDetails={parentChildView} fabOrderPage={fabOrderPage}></TotalPrice>
        </div>
      </div>
      {!fabOrderPage && <CambriaFileUpload files={uploadedFiles} />}

      {order && <Rejections order={order} />}
    </FabCartContainer>
  );
};
export default FabricationCart;
