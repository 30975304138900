import {toast} from 'react-toastify';
import {callback} from '../../callbacks/service/callbacks.service';
import {Cart} from '../../cart/ICartState';
import {getBillToSiteByOperatingUnitCode, initializeCart} from '../../cart/service/cart.service';
import {updateCartItem} from '../../cart/slice/cart.slice';
import {CustomerFullProfile, ShipToSite, Site} from '../../customer/ICustomerState';
import {hideFullscreenLoader, showFullscreenLoader} from '../../fullscreenLoader/slice/fullscreenLoader.slice';
import {QuoteSearchParams} from '../../search/ISearchState';
import {postQuoteRequest} from '../controller/quote.controller';
import {putQuote} from '../quoteDetails/service/quoteDetails.service';
import {setQuoteOperationInProgress} from '../slice/quote.slice';
import {User} from '../../../store/auth/auth.service';
import {ISalesforceAvailablePrograms} from '../../salesforce/ISalesforceState';

const savingQuoteMessage: string =
  'This quote is in the process of being updated. This may take several seconds. ' +
  'Do not refresh the screen or hit the back button until this is complete and  ' +
  'you have been redirected to the quote details. ';

export const postQuote = async (params: any, dispatch: any): Promise<any> => {
  let result = await postQuoteRequest(params);
  result = await result.text();
  result = JSON.parse(result);

  try {
    await callback(result.callbackId, 60, dispatch);
  } catch (error: any) {
    throw error;
  }
  return result;
};

export const addQuoteStringInput = (currentFilter: string, quoteFilters: QuoteSearchParams, inputValue: string) => {
  let searchParamsToSave = {...quoteFilters};
  if (currentFilter === 'Details') {
    searchParamsToSave['q'] = inputValue;
  } else if (currentFilter === 'Status') {
    searchParamsToSave['status'] = inputValue;
  }
  return searchParamsToSave;
};

/**
 * Calculates if the requested date qualifies for quantity incentive discount
 */
export const calculateRequestedDateQualifiesForDiscount = (requestedDate: Date): boolean => {
  const daysDifference = (requestedDate.getTime() - Date.now()) / (1000 * 60 * 60 * 24);

  return daysDifference >= 30;
};

export const applyQuoteDiscounts = (cart: Cart, dispatch: any) => {
  if (!cart || !cart.transactionType || !cart.cartItems) {
    return;
  }
  if (cart.transactionType !== 'QuoteEditCart') {
    return;
  }

  let tempCart = JSON.parse(JSON.stringify(cart));

  tempCart.cartItems.forEach((cartItem: any) => {
    const itemDiscounts = tempCart.quoteDiscounts.filter(
      (quoteDiscount: any) => quoteDiscount.itemNumber === cartItem.itemNumber
    );

    let unitPrice = cartItem.unitPrice;
    itemDiscounts.forEach((itemDiscount: any) => {
      unitPrice -= itemDiscount.value;
    });
    cartItem.adjustedUnitPrice = unitPrice;
    cartItem.hasAdjustedUnitPrice = true;
  });

  if (
    tempCart &&
    tempCart.cartItems &&
    tempCart.cartItems.length > 0 &&
    cart &&
    cart.cartItems &&
    cart.cartItems.length > 0 &&
    tempCart.cartItems[0].adjustedUnitPrice !== cart.cartItems[0].adjustedUnitPrice
  ) {
    dispatch(updateCartItem(tempCart));
  }
};

export const saveQuote = async (
  cart: Cart,
  customer: CustomerFullProfile,
  billToSites: Site[],
  shipToSites: Site[],
  currentUser: User | null,
  availablePrograms: ISalesforceAvailablePrograms | null,
  activeProgram: any,
  selectedWarehouse: ShipToSite | null,
  dispatch: any,
  history: any,
  isFabOrSurfaceCart: boolean,
  cartCalculationsComplete: boolean = true,
  quoteOperationInProgress: boolean = false
) => {
  if (!cartCalculationsComplete || quoteOperationInProgress) {
    return false;
  }

  try {
    dispatch(setQuoteOperationInProgress(true));
    dispatch(showFullscreenLoader({message: savingQuoteMessage}));
    const lines = [];
    for (const cartItem of cart.cartItems) {
      const billToSite = await getBillToSiteByOperatingUnitCode(cartItem.operatingUnitCode, billToSites);
      const line: any = {
        warehouseCode: cartItem.shipFromWarehouseId,
        programCode: cartItem.programCode,
        itemNumber: cartItem.itemNumber,
        priceListCode: cartItem.priceListCode,
        siteUseId: billToSite.siteUseId,
        quantity: cartItem.quantity,
        pricingUom: cartItem.pricingUom,
        unitPrice: cartItem.unitPrice,
        currency: cartItem.currency,
        imageUrl: cartItem.imageUrl,
      };

      if (isFabOrSurfaceCart) {
        line.productType = cartItem.productType;
        line.cartItemId = cartItem.cartItemId;
        line.cartItemType = cartItem.cartItemType;
        line.parentCartItemId = cartItem.parentCartItemId;
        line.pieceLabel = cartItem.pieceLabel;
        line.productGroupCode = cartItem.productGroupCode;
        line.productApplication = cartItem.productApplication;
        line.designName = cartItem.designName;
        line.designCode = cartItem.designCode;
        line.thickness = cartItem.thickness;
        line.finish = cartItem.finish;
        line.match = cartItem.match;
        line.dimension1 = cartItem.dimension1;
        line.dimension2 = cartItem.dimension2;
        line.dimension3 = cartItem.dimension3;
        line.dimension4 = cartItem.dimension4;
        line.description = cartItem.description;
        line.edgeProfileCode = cartItem.edgeProfileCode;
        line.edgeProfileName = cartItem.edgeProfileName;
        line.uomLineQuantity = cartItem.uomLineQuantity;
      }
      lines.push(line);
    }

    const params = {
      customerName: customer.name,
      customerClass: customer.class,
      erpCustomerId: customer.erpCustomerId,
      cartId: cart.id,
      lines: lines,
      previousQuoteId: cart.quoteId,
    };

    const quoteId = await putQuote(params, dispatch);
    applyQuoteDiscounts(cart, dispatch);

    toast.success('Your quote has been edited successfully !');
    history.push(`/account/quotes/details/${quoteId}`);
  } catch (e: any) {
    toast.error(e.message);
  } finally {
    dispatch(setQuoteOperationInProgress(false));
  }

  await initializeCart(
    'editQuoteComplete',
    customer,
    currentUser?.userId,
    availablePrograms,
    activeProgram,
    selectedWarehouse?.warehouseCode ? selectedWarehouse.warehouseCode?.toString() : '',
    shipToSites,
    billToSites,
    dispatch
  );
  dispatch(hideFullscreenLoader());
};
