import React, {FC} from 'react';

import * as colors from '../styles/colors';
import {IconSpan} from './Icon.styled';

interface IconProps {
  icon: string;
  color?: string;
  colorOnHover?: string;
  weight?: string;
  size?: string;
  className?: string;
  onClick?: Function;
  disabled?: boolean;
  name?: string;
}

const Icon: FC<IconProps> = ({
  icon,
  color = colors.link,
  colorOnHover = colors.primary,
  weight = '500',
  size = '22',
  className = '',
  onClick,
  disabled = false,
  name,
}) => {
  return (
    <IconSpan
      data-testid={name}
      color={color}
      colorOnHover={colorOnHover}
      weight={weight}
      size={size}
      onClick={() => (onClick && !disabled ? onClick() : null)}
      className={`icon ${icon} ${className} ${disabled ? 'disabled' : ''}`}
    />
  );
};

export default Icon;
