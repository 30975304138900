import React, {useState} from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import CambriaButton from '../../../../../../../Framework/Components/CambriaButton';
import ContactCard from '../../../../../../../Framework/Components/Card/ContactCard';
import {useAppDispatch, useTypedSelector} from '../../../../../../../hooks/store';
import {
  getEndConsumersAsync,
  selectEndConsumers,
} from '../../../../../../../features/fabrication/slice/fabrication.slice';
import {toast} from 'react-toastify';
import {selectActiveCart} from '../../../../../../../features/cart/slice/cart.slice';
import {selectCurrentCustomer} from '../../../../../../../features/customer/slice/customer.slice';
import {EndConsumer, PurchasingCustomer} from '../../../../../../../features/cart/ICartState';
import AddConsumerModal from '../../../../../../../Core/Components/Modals/AddConsumerModal';
import Icon from '../../../../../../../Framework/Components/Icon';
import {deleteEndConsumersRequest} from '../../../../../../../features/fabrication/controller/fabrication.controller';

interface ConsumersBlockProps {
  customer: PurchasingCustomer;
}

const ConsumersBlock = ({customer}: ConsumersBlockProps) => {
  const dispatch = useAppDispatch();

  const [showConsumerModal, setShowConsumerModal] = useState<boolean>(false);
  const [editEndUserInformation, setEditEndUserInformation] = useState<any>(null);

  const activeCart = useTypedSelector(selectActiveCart);
  const currentCustomer = useTypedSelector(selectCurrentCustomer);
  const endConsumers = useTypedSelector(selectEndConsumers);

  const toggleShowConsumerModal = () => setShowConsumerModal((p) => !p);

  const deleteContact = async (id: number) => {
    try {
      await deleteEndConsumersRequest(id);
      toast.success('End consumer removed successfully');
      dispatch(
        getEndConsumersAsync({
          erpCustomerId: currentCustomer.erpCustomerId,
          cartId: activeCart ? activeCart.id : '',
        })
      );
    } catch (error: any) {
      toast.error('Unable to remove end consumer');
      throw new Error(error);
    }
  };

  const editContact = (consumer: EndConsumer) => {
    const editInfo = {
      firstName: consumer.contactFirstName,
      lastName: consumer.contactLastName,
      businessName: consumer.companyName,
      country: consumer.countryCode,
      address1: consumer.address1,
      address2: consumer.address2,
      address3: consumer.address3,
      city: consumer.city,
      state: consumer.stateProvinceCode,
      zipCode: consumer.postalCode,
      endUserPhone: consumer.contactPhoneNumber,
      endUserEmail: consumer.contactEmail,
      id: consumer.id,
    };

    setEditEndUserInformation(() => editInfo);
    toggleShowConsumerModal();
  };

  const renderEndConsumers = (id: number) => {
    if (id && endConsumers && endConsumers.length > 0) {
      const customerEndConsumers = endConsumers.filter((consumer: any) => {
        return consumer.retailSiteId === id;
      });

      return customerEndConsumers.map((consumer: EndConsumer, i: number) => {
        return (
          <Col key={i} xl={4} lg={6} md={12} className="m-t-sm">
            <ContactCard contact={consumer} onDeleteCallback={deleteContact} onEditCallback={editContact}></ContactCard>
          </Col>
        );
      });
    } else {
      return <></>;
    }
  };

  return (
    <>
      <Container>
        <Row>{renderEndConsumers(customer.id)}</Row>
        <Row>
          <Col lg={4} md={6} sm={12} className="m-t-sm">
            <CambriaButton
              onClick={() => {
                setEditEndUserInformation(null);
                toggleShowConsumerModal();
              }}
              variant="secondary">
              <Icon icon="glyph glyphicon-plus glyphicon--plus" color="#c59617" size="22" weight="500" />
              ADD CONSUMER
            </CambriaButton>
          </Col>
        </Row>
      </Container>
      {showConsumerModal && (
        <AddConsumerModal
          show={showConsumerModal}
          toggleShow={toggleShowConsumerModal}
          editInformation={editEndUserInformation}
          editMode={!!editEndUserInformation}
          retailSiteId={customer.id || 0}
          notFabOrder={true}></AddConsumerModal>
      )}
    </>
  );
};

export default ConsumersBlock;
