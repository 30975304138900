import React, {useEffect, useState} from 'react';
import {PDFViewerContainer} from './PDFViewer.styled';
import {useParams} from 'react-router-dom';
import {getProofOfDeliveryByDeliveryNumber} from '../../../features/lastMile/controller/lastmile.controller';

function PDFViewer() {
  let [param]: any = useState(useParams());
  let [pdfUrl, setPdfUrl]: any = useState('');

  useEffect(() => {
    const loadPdfUrl = async () => {
      const result = await getProofOfDeliveryByDeliveryNumber({deliveryNumbers: [param.pdfId]});
      setPdfUrl(result[0].documentUrl);
    };

    if (param.pdfOrigin === 'proofOfDelivery') {
      loadPdfUrl();
    }
  }, [param]);
  return (
    <PDFViewerContainer>
      {pdfUrl === '' ? (
        <div className="pdf-viewer-placeholder"></div>
      ) : (
        <iframe
          title={'pdfViewer'}
          src={`${pdfUrl}${param.useToolbar ? '' : '#toolbar=0'}`}
          width="100%"
          height="100%"></iframe>
      )}
    </PDFViewerContainer>
  );
}

export default PDFViewer;
