import {Cart, CartItem} from '../../cart/ICartState';
import {CustomerFullProfile, ShipToSite, Site} from '../../customer/ICustomerState';
import COMMERCE_CORE_CONSTANTS from '../../../Core/constants';
import {GetWarehouseResult, WarehouseFullData} from '../IWarehouseState';
import {ProductType} from '../../order/orderDetails/IOrderDetailsState';
import {getCartItemsProductType} from '../../productType/service/productType.service';
import {getShippingWarehousesRequest} from '../controller/warehouse.controller';

export const getItemWarehouseCode = async (
  cartItemToAdd: CartItem,
  cart: Cart,
  currentCustomer: CustomerFullProfile,
  shipToSites: any
): Promise<GetWarehouseResult> => {
  const cartItems = cart.cartItems;
  const productType = cartItemToAdd.productType;
  const customer = currentCustomer;
  const warehouse = await getWarehouseData(productType, currentCustomer, shipToSites);

  const canUseDefaultWarehouse = cartItemToAdd.organizations
    ? cartItemToAdd.organizations.find((org) => org.erpOrganizationCode === warehouse.warehouseCode)
    : null;

  const isHazardous: boolean = getIsHazardous(cartItemToAdd);

  const canUseFallbackWarehouse = cartItemToAdd.organizations
    ? cartItemToAdd.organizations.find(
        (org) => !!warehouse.fallbackWarehouseCode && org.erpOrganizationCode === warehouse.fallbackWarehouseCode
      )
    : null;

  return getItemWarehouseResult(
    cartItems,
    warehouse,
    canUseDefaultWarehouse,
    canUseFallbackWarehouse,
    isHazardous,
    customer.defaultWarehouseCode
  );
};

export const getOperatingUnitCodeByProductType = (
  productType: string,
  customer: any,
  shipToSites: any,
  warehouseCode?: string
) => {
  let result = null;
  if (customer && customer.class && customer.class.toLowerCase() === 'enterprise') {
    const selectedWarehouse = getUserSelectedWarehouse();
    const firstShipToSiteWithSelectedWarehouse = shipToSites.find((x: any) => {
      return x.warehouseCode === selectedWarehouse && !!x.operatingUnitCode;
    });
    result = firstShipToSiteWithSelectedWarehouse ? firstShipToSiteWithSelectedWarehouse.operatingUnitCode : null;
  } else if (productType) {
    const productTypeWarehouse = customer.productTypeWarehouseCodes.filter((f: any) =>
      f.productType.toLowerCase().startsWith(productType.toLowerCase())
    );

    if (
      warehouseCode &&
      warehouseCode === '231' &&
      productTypeWarehouse.length !== 0 &&
      productTypeWarehouse[0].operatingUnitCode &&
      productTypeWarehouse[0].operatingUnitCode.includes('_CAN_') &&
      productTypeWarehouse[0].fallbackWarehouseCode === warehouseCode
    ) {
      // for 231 we should use CAMOU for CAN customers in some cases!
      result = 'CAMOU';
    } else {
      result = productTypeWarehouse.length === 0 ? null : productTypeWarehouse[0].operatingUnitCode;
    }
  }

  return result;
};

export const getShipFromWarehouseId = (
  productType: string,
  customer: any,
  shipToSites: any,
  cart: any = null,
  allowFallback: boolean = true
) => {
  // only here we dont need  to get the fallback warehouse operating unit code
  // we need the productType warehouse operating unit
  const operatingUnitCode = getOperatingUnitCodeByProductType(productType, customer, shipToSites);
  let shipFromWarehouseId = customer.defaultWarehouseCode;
  if (customer && customer.class && customer.class.toLowerCase() !== 'enterprise') {
    const surfacesWarehouseCodeOverride = getSurfacesWarehouseCodeOverride(cart);
    const isItFabOrder = isFabOperatingUnitCode(operatingUnitCode);
    const isSurfacesOrder = isSurfacesOperatingUnitCode(operatingUnitCode);
    // when is surfaces order and add fab item to the cart it should be added from fab warehouse
    if (
      (surfacesWarehouseCodeOverride && isItFabOrder) ||
      (surfacesWarehouseCodeOverride && isSurfacesOrder && productType === 'Surfaces')
    ) {
      shipFromWarehouseId = surfacesWarehouseCodeOverride;
    } // fix for CDMP-8749. A customer defaulted to 302 will need to go to 306 if they override to 504. Palm Desert surfaces fullfil out of 306
    else if (isSurfacesOrder && surfacesWarehouseCodeOverride === '504') {
      shipFromWarehouseId = '306';
    } else if (productType) {
      const matchedWarehouse = customer.productTypeWarehouseCodes.find((w: any) =>
        w.productType.toLowerCase().startsWith(productType.toLowerCase())
      );
      const firstCartItemShipFromWarehouseId =
        cart && cart.cartItems && cart.cartItems.length > 0 && cart.cartItems[0].shipFromWarehouseId;

      if (matchedWarehouse) {
        if (
          allowFallback &&
          matchedWarehouse.warehouseCode !== firstCartItemShipFromWarehouseId &&
          matchedWarehouse.fallbackWarehouseCode &&
          firstCartItemShipFromWarehouseId &&
          matchedWarehouse.fallbackWarehouseCode === firstCartItemShipFromWarehouseId
        ) {
          shipFromWarehouseId = matchedWarehouse.fallbackWarehouseCode;
        } else {
          shipFromWarehouseId = matchedWarehouse.warehouseCode;
        }
      }
    }
  } else {
    const {warehouseCodeToUse} = getWarehouseCodeForEnterpriseCustomer(productType as any, customer, operatingUnitCode);
    shipFromWarehouseId = warehouseCodeToUse;
  }

  return shipFromWarehouseId;
};
export const getWarehouseData = async (
  productType: ProductType,
  currentCustomer: CustomerFullProfile,
  shipToSites: any
): Promise<WarehouseFullData> => {
  const customer = currentCustomer;
  // we want the primaryProductType warehouse not the fallback
  const warehouseCode = await getShipFromWarehouseId(productType, customer, shipToSites, undefined, false);
  const operatingUnitCode = await getOperatingUnitCodeByProductType(
    productType,
    currentCustomer,
    shipToSites,
    warehouseCode
  );

  return await getWarehouseFullData(warehouseCode, customer, operatingUnitCode, productType);
};

export const getWarehouses = async (warehouseCodes: string[]) => {
  let warehouses: ShipToSite[] = [];
  await Promise.all(
    warehouseCodes.map(async (code: string) => {
      try {
        const warehouse = await getShippingWarehousesRequest(code);
        warehouse.results[0].displayName = warehouse.results[0].warehouseCode + ' - ' + warehouse.results[0].name;
        warehouses.push(warehouse.results[0]);
      } catch (error: any) {
        throw error;
      }
    })
  );
  return warehouses;
};

export const getWarehouseFullData = async (
  warehouseCode: string,
  customer: CustomerFullProfile,
  operatingUnitCode: string,
  productType: ProductType
) => {
  let warehouse: WarehouseFullData;
  const results = await getShippingWarehousesRequest(warehouseCode);
  const result = results.results && results.results.length > 0 ? results.results[0] : null;
  const productTypeWarehouseData = customer.productTypeWarehouseCodes.find(
    (prodWarehouseCode) =>
      prodWarehouseCode.warehouseCode === warehouseCode &&
      prodWarehouseCode.operatingUnitCode === operatingUnitCode &&
      prodWarehouseCode.productType === productType
  );
  const fallbackWarehouseData = customer.productTypeWarehouseCodes.find(
    (prodWarehouseCode) =>
      prodWarehouseCode.fallbackWarehouseCode === warehouseCode &&
      (prodWarehouseCode.operatingUnitCode === operatingUnitCode || warehouseCode === '231') &&
      prodWarehouseCode.productType === productType
  );

  warehouse = productTypeWarehouseData ? {...result, ...productTypeWarehouseData} : result;
  // if no productTypeWarehouseData but fallback data it means this is fallback warehouse for the productType
  // then get the fallbackWarehouseData.crossdockWarehouseCode
  if (
    !productTypeWarehouseData &&
    warehouse &&
    !warehouse.crossDockWarehouseCode &&
    fallbackWarehouseData &&
    fallbackWarehouseData.crossDockWarehouseCode
  ) {
    warehouse.crossDockWarehouseCode = fallbackWarehouseData.crossDockWarehouseCode;
  }
  warehouse.operatingUnitCode = operatingUnitCode;
  return warehouse;
};

export const getShippingWarehouse = async (
  cart: Cart | null,
  currentCustomer: CustomerFullProfile,
  currentCustomerShipToSites: Site[]
) => {
  if (!cart) {
    return null;
  }

  let warehouse = await getShippingWarehouseData(cart, currentCustomer, currentCustomerShipToSites);
  if (warehouse && warehouse.name) {
    warehouse.name = `${warehouse.warehouseCode} - ${warehouse.name.replace('DC - ', '')}`;
  } else if (warehouse && warehouse.warehouseCode) {
    warehouse.name = warehouse.warehouseCode;
  }
  return warehouse;
};

export const getShippingWarehouseData = async (
  selectedCart: Cart,
  currentCustomer: CustomerFullProfile,
  currentCustomerShipToSites: Site[]
) => {
  const productType = await getCartItemsProductType(selectedCart);
  // we can have fab and surfaces items which have different warehouseCode
  const warehouseCode = await getShipFromWarehouseId(
    productType,
    currentCustomer,
    currentCustomerShipToSites,
    selectedCart
  );
  // OU for all items in the cart is always the same
  const operatingUnitCode = selectedCart.cartItems[0].operatingUnitCode;
  return getWarehouseFullData(warehouseCode, currentCustomer, operatingUnitCode, productType);
};

export const getIsHazardous = (cartItem: CartItem): boolean => {
  if (!cartItem || !cartItem.shippingClassifications || cartItem.shippingClassifications.length === 0) {
    return false;
  }
  return cartItem.shippingClassifications.includes('Hazardous');
};

export const getItemWarehouseResult = (
  cartItems: Array<CartItem>,
  warehouse: WarehouseFullData,
  canUseDefaultWarehouse: boolean,
  canUseFallbackWarehouse: boolean,
  isHazardous: boolean,
  customerWarehouseCode: string
): GetWarehouseResult => {
  let getWarehouseResult: any = {};
  getWarehouseResult['message'] = '';
  getWarehouseResult['saveItemForLater'] = false;
  getWarehouseResult['pricingFallbackWarehouseCode'] = '';
  getWarehouseResult['mainWarehouseCode'] = warehouse.warehouseCode;
  getWarehouseResult['warehouseCode'] = '';

  const errorMessage = `This item is not available in the selected warehouse. Please contact the finance department for assistance.`;

  if (cartItems && cartItems.length > 0) {
    let currentWarehouseCode = cartItems[0].shipFromWarehouseId;
    getWarehouseResult['warehouseCode'] = currentWarehouseCode;
    if (currentWarehouseCode === warehouse.warehouseCode && !canUseDefaultWarehouse && canUseFallbackWarehouse) {
      getWarehouseResult['saveItemForLater'] = true;
      getWarehouseResult['pricingFallbackWarehouseCode'] = warehouse.fallbackWarehouseCode;
    } else if (
      currentWarehouseCode === warehouse.fallbackWarehouseCode &&
      canUseFallbackWarehouse &&
      isHazardous &&
      customerWarehouseCode === '304'
    ) {
      getWarehouseResult['saveItemForLater'] = true;
      getWarehouseResult['pricingFallbackWarehouseCode'] = warehouse.fallbackWarehouseCode;
    } else if (!canUseDefaultWarehouse && !canUseFallbackWarehouse) {
      getWarehouseResult['message'] = errorMessage;
    }
  } else {
    if (canUseDefaultWarehouse) {
      getWarehouseResult['warehouseCode'] = warehouse.warehouseCode;
    } else if (canUseFallbackWarehouse) {
      getWarehouseResult['message'] = `The current item must ship from MN Samples.`;
      getWarehouseResult['warehouseCode'] = warehouse.fallbackWarehouseCode;
      getWarehouseResult['pricingFallbackWarehouseCode'] = warehouse.fallbackWarehouseCode;
    } else {
      getWarehouseResult['message'] = errorMessage;
    }
  }

  getWarehouseResult['hasError'] = getWarehouseResult.message === errorMessage;
  return getWarehouseResult;
};

export const getOrderShippingWarehouseName = async (warehouseCode: string): Promise<string> => {
  const {results} = await getShippingWarehousesRequest(warehouseCode);
  if (results.length > 0) {
    return `${warehouseCode} - ${results[0].name.replace('DC - ', '')}`;
  }

  return warehouseCode;
};

export const ListOfSampleWarehouses = () => {
  return [
    {
      name: 'Atlanta',
      warehouseCode: '262',
      phone: '(770) 814-3073',
    },
    {
      name: 'Boston',
      warehouseCode: '269',
      phone: '(978) 935-7016',
    },
    {
      name: 'Charlotte',
      warehouseCode: '271',
      phone: '(704) 859-7047',
    },
    {
      name: 'Chicago',
      warehouseCode: '260',
      phone: '(847) 635-0994',
    },
    {
      name: 'Dallas',
      warehouseCode: '258',
      phone: '(972) 462-0132',
    },
    {
      name: 'Denver',
      warehouseCode: '265',
      phone: '(303) 227-4935',
    },
    {
      name: 'Fort Lauderdale',
      warehouseCode: '255',
      phone: '(305) 468-1653',
    },
    {
      name: 'Houston',
      warehouseCode: '257',
      phone: '(281) 591-7062',
    },
    {
      name: 'MN Samples Fulfillment ',
      warehouseCode: '231',
      phone: '(866) 226-2742',
    },
    {
      name: 'Mobile',
      warehouseCode: '263',
      phone: '(251) 679-5900',
    },
    {
      name: 'Nashville',
      warehouseCode: '261',
      phone: '(615) 331-8395',
    },
    {
      name: 'New York',
      warehouseCode: '259',
      phone: '(516) 349-3070',
    },
    {
      name: 'Orlando',
      warehouseCode: '254',
      phone: '(407) 888-8585',
    },
    {
      name: 'Philadelphia',
      warehouseCode: '266',
      phone: '(610) 308-4553',
    },
    {
      name: 'Phoenix',
      warehouseCode: '256',
      phone: '(623) 932-1482',
    },
    {
      name: 'Salt Lake City',
      warehouseCode: '268',
      phone: '(810) 652-7000',
    },
    {
      name: 'San Francisco',
      warehouseCode: '253',
      phone: '(510) 477-6741',
    },
    {
      name: 'Savannah',
      warehouseCode: '264',
      phone: '(912) 298-0085',
    },
    {
      name: 'Seattle',
      warehouseCode: '267',
      phone: '(206) 822-6500',
    },
    {
      name: 'Southern California',
      warehouseCode: '252',
      phone: '(909) 581-1110',
    },
    {
      name: 'Washington DC',
      warehouseCode: '270',
      phone: '(703) 520-8256',
    },
    {
      name: 'Indianapolis',
      warehouseCode: '232',
      phone: '(317) 894-2388',
    },
    {
      name: 'Toronto',
      warehouseCode: '233',
      phone: '(905) 951-1011',
    },
    {
      name: 'Cleveland',
      warehouseCode: '234',
      phone: '(330) 667-4010',
    },
  ];
};

export const getSurfacesWarehouseCodeOverride = (selectedCart: Cart | null) => {
  return (
    selectedCart !== null &&
    selectedCart.orderDetails != null &&
    selectedCart.orderDetails.length > 0 &&
    selectedCart.orderDetails[0].surfacesWarehouseCodeOverride
  );
};

export const isFabOperatingUnitCode = (operatingUnitCode: string): boolean => {
  return !!(operatingUnitCode && operatingUnitCode.toLocaleLowerCase().startsWith('fab_'));
};

export const isSurfacesOperatingUnitCode = (operatingUnitCode: string): boolean => {
  return !!(operatingUnitCode && operatingUnitCode.toLocaleLowerCase().startsWith('sur_'));
};

export const getWarehouseCodeForEnterpriseCustomer = (
  productType: ProductType,
  customer: CustomerFullProfile,
  operatingUnitCode: any
) => {
  const selectedWarehouseCode = getUserSelectedWarehouse();
  const isSurfacesOrder = isSurfacesOperatingUnitCode(operatingUnitCode);

  let warehouseCodeToUse: string | undefined | null;
  let mustShipFromMNSamples: boolean = false;
  if (isSurfacesOrder && productType !== 'Surfaces') {
    const productTypeWarehouse = customer.productTypeWarehouseCodes.find(
      (prodWarehouseCode: any) =>
        prodWarehouseCode.productType.toLowerCase().startsWith(productType.toLowerCase()) &&
        prodWarehouseCode.operatingUnitCode === operatingUnitCode
    );
    warehouseCodeToUse = productTypeWarehouse ? productTypeWarehouse.warehouseCode : null;
  } else {
    mustShipFromMNSamples = selectedWarehouseCode === '211' && (productType === 'Samples' || productType === 'Store');
    warehouseCodeToUse = mustShipFromMNSamples ? '231' : selectedWarehouseCode;
  }
  return {warehouseCodeToUse, mustShipFromMNSamples};
};

export const getUserSelectedWarehouse = (): string | null => {
  return window.sessionStorage.getItem(COMMERCE_CORE_CONSTANTS.WINDOW_SESSION.selectedWarehouse);
};
