export interface SingleItemPriceRequest {
  erpCustomerId: string;
  warehouseCode: string;
  programCode: string;
  itemNumber: string;
  siteUseId: number | null;
  customerClass: string;
}

export interface SingleItemPriceResponse {
  CurrencyCode: string;
  ListPrice: number;
  PriceListName: string;
  PricingCacheHit: number;
  PricingUom: string;
  SellingPrice: number;
  ItemNumber: number;
  CustomerClass: string;
  DiscountAmount: number;
}

export const NewSingleItemPriceResponse = (): SingleItemPriceResponse => {
  return {
    CurrencyCode: '',
    ListPrice: 0,
    PriceListName: '',
    PricingCacheHit: 0,
    PricingUom: '',
    SellingPrice: 0,
    ItemNumber: 0,
    CustomerClass: '',
    DiscountAmount: 0,
  };
};

export const NewSingleItemPriceRequest = (): SingleItemPriceRequest => {
  let singleItemPriceRequest: any = {};
  singleItemPriceRequest['erpCustomerId'] = '';
  singleItemPriceRequest['warehouseCode'] = '';
  singleItemPriceRequest['programCode'] = '';
  singleItemPriceRequest['itemNumber'] = '';
  singleItemPriceRequest['siteUseId'] = 0;
  singleItemPriceRequest['customerClass'] = '';

  return singleItemPriceRequest;
};
