import styled from 'styled-components';
import {$gray30, $gray93, $newwhite} from '../../../Styled/variables';

const CambriaProductTableContainer = styled.div`
  .product-table-container {
    margin-top: 10px;

    tbody {
      box-shadow: 0px 1px 3px 0px rgba(101, 101, 101, 0.35);

      tr {
        :nth-of-type(even) {
          background-color: ${$gray93};
        }

        :nth-of-type(odd) {
          background-color: ${$newwhite};
        }
      }
    }

    th {
      border-top: none;
      border-bottom: none !important;
      color: ${$gray30} !important;
      font-weight: 700;
      text-align: center;
    }

    td {
      border: none;
      text-align: center;

      .usd-label {
        font-size: 10px;
      }

      .quantity-box {
        width: fit-content;
        height: fit-content;
        min-width: 30px;
        min-height: 30px;
        padding: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 2px solid #d8d8d8b0;
        border-radius: 3px;
      }
    }
  }
}

.line-items-table-mobile {
  color: ${$gray30} !important;
  font-weight: 700 !important;
  font-size: 12px;

  .second-row {
    margin: 15px 0px 10px 0px !important;
  }

  img {
    width: 100px;
  }

  .usd-label {
    font-size: 10px;
    white-space: nowrap;
  }

  .price-per-sqft {
    font-size: 14px;
  }

  .price-per-sqft-strikethrough {
    font-size: 10px;
    text-decoration: line-through;
    white-space: nowrap;
  }

  .usd-label-strikethrough {
    font-size: 8px;
  }

  .quantity-box-container {
    display: flex;
    align-items: center;
    justify-content: start;

    .quantity-box {
      font-size: 16px;
      width: 40px;
      height: 40px;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-align-items: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      border: 2px solid #d8d8d8b0;
      border-radius: 3px;
    }
  }

  .design-name {
    font-size: 18px;
  }

  .row {
    margin: 5px 0px;
  }

  .line-price-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 0px;

    .line-price-label,
    .usd-label {
      font-size: 10px;
    }

    .usd-label {
      margin-top: -5px;
    }

    .line-price {
      font-size: 18px;
    }
  }
}

.status-container {
  display: flex;
  justify-content: flex-start;
}

.quantity-box-container {
  display: flex;
  align-items: center;
  justify-content: start;
  padding: 0px;

  .quantity-box {
    font-size: 16px;
    width: 40px;
    height: 40px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border: 2px solid #d8d8d8b0;
    border-radius: 3px;
  }
}

.line-price-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 0px;

  .line-price-label,
  .usd-label {
    font-size: 10px;
    margin-top: -5px;
  }

  .line-price {
    font-size: 18px;
  }
}
`;

export default CambriaProductTableContainer;
