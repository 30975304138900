import React, {useEffect, useState} from 'react';
import {PurchasingCustomerBlock} from './PurchasingCustomersBlock.styled';
import {useAppDispatch, useTypedSelector} from '../../../../../../hooks/store';
import {selectActiveCart, setActiveCart} from '../../../../../../features/cart/slice/cart.slice';
import {hasPermission} from '../../../../../../store/permission/permission.service';
import {selectUserActions} from '../../../../../../features/permission/slice/permission.slice';
import {formatPhoneNumber} from '../../../../../../features/customer/service/customer.service';
import {
  selectCurrentCustomer,
  selectCurrentCustomerBillToSites,
  selectCurrentCustomerShipToSites,
} from '../../../../../../features/customer/slice/customer.slice';
import {
  selectActiveProgram,
  selectAvailablePrograms,
  selectSalesforceCustomerAccount,
} from '../../../../../../features/salesforce/slice/salesforce.slice';
import {toast} from 'react-toastify';
import AddPurchasingCustomerModal from '../../../../../../Core/Components/Modals/AddPurchasingCustomerModal';
import {getEndConsumersAsync, selectEndConsumers} from '../../../../../../features/fabrication/slice/fabrication.slice';
import ConsumersBlock from './ConsumersBlock';
import {deleteEndConsumersRequest} from '../../../../../../features/fabrication/controller/fabrication.controller';
import {getRetailAccountsForACustomer} from '../../../../../../features/salesforce/service/salesforce.service';
import {PurchasingCustomer} from '../../../../../../features/cart/ICartState';
import {ISalesforceAvailablePrograms} from '../../../../../../features/salesforce/ISalesforceState';
import {getCart} from '../../../../../../features/cart/service/cart.service';
import {getCanEditPurchasingCustomers} from '../../../../../../features/order/orderDetails/service/orderDetails.service';
import {deleteCustomerRetailSitesRequest} from '../../../../../../features/customer/controller/customer.controller';

interface PurchasingCustomersBlockProps {
  customers: Array<PurchasingCustomer>;
  canEdit: boolean;
}

const PurchasingCustomersBlock = ({customers, canEdit}: PurchasingCustomersBlockProps) => {
  const dispatch = useAppDispatch();

  const activeCart = useTypedSelector(selectActiveCart);
  const userActions = useTypedSelector(selectUserActions);
  const activeProgram = useTypedSelector(selectActiveProgram);
  const endConsumers = useTypedSelector(selectEndConsumers);
  const currentCustomer = useTypedSelector(selectCurrentCustomer);
  const currentCustomerShipToSites = useTypedSelector(selectCurrentCustomerShipToSites);
  const currentCustomerBillToSites = useTypedSelector(selectCurrentCustomerBillToSites);
  const salesforceCustomerAccount = useTypedSelector(selectSalesforceCustomerAccount);
  const availablePrograms: ISalesforceAvailablePrograms | null = useTypedSelector(selectAvailablePrograms);

  const [showPurchasingCustomerModal, setShowPurchasingCustomerModal] = useState<boolean>(false);
  const [availableAddresses, setAvailableAddresses] = useState<any[]>([]);
  const [customerToEdit, setCustomerToEdit] = useState<PurchasingCustomer | null>(null);

  const hasOrderEditPermission = hasPermission('urn:csa:commerceui:order:orderedits', userActions);

  const toggleShowPurchasingCustomerModal = () => setShowPurchasingCustomerModal((p) => !p);

  const deleteRelatedContactsIfNeeded = (id: number) => {
    const contactsToDelete = endConsumers && endConsumers.filter((contact: any) => contact.retailSiteId === id);

    if (contactsToDelete && contactsToDelete.length > 0) {
      contactsToDelete.forEach((contact: any) => {
        deleteEndConsumersRequest(contact.id);
      });
    }
  };

  const deletePurchasingCustomer = async (id: number) => {
    deleteRelatedContactsIfNeeded(id);

    try {
      await deleteCustomerRetailSitesRequest(id);
      const cart = await getCart(
        activeCart?.id || '',
        currentCustomer,
        currentCustomer?.id?.toString() || '0',
        activeProgram,
        currentCustomerShipToSites || [],
        currentCustomerBillToSites || [],
        dispatch,
        availablePrograms,
        true
      );
      dispatch(setActiveCart(cart));
      toast.success('Purchasing customer was successfully removed');
    } catch (error: any) {
      toast.error('Failed to delete purchasing customer');
      throw new Error(error);
    }
  };

  useEffect(() => {
    if (customers.length > 0 && activeCart && currentCustomer.id && (!endConsumers || endConsumers.length < 0)) {
      dispatch(
        getEndConsumersAsync({
          erpCustomerId: currentCustomer.erpCustomerId,
          cartId: activeCart.id,
        })
      );
    }
  }, [dispatch, currentCustomer, customers, activeCart, endConsumers]);

  useEffect(() => {
    const getSitesForDropdown = async () => {
      try {
        const result = await getRetailAccountsForACustomer(currentCustomer);
        let organizedArray: any[] = [];
        result.forEach((item: any) => {
          organizedArray.push(organizeAddress(item));
        });
        setAvailableAddresses(organizedArray);
      } catch (e) {
        throw e;
      }
    };

    const organizeAddress = (site: any) => {
      return {
        displayName: site.address,
        value: `${site.siteName}`,
        site: site,
      };
    };

    if (salesforceCustomerAccount && Object.keys(salesforceCustomerAccount).length > 0 && currentCustomer) {
      getSitesForDropdown();
    }
  }, [salesforceCustomerAccount, currentCustomer, dispatch]);

  return (
    <>
      {customers.map((customer: PurchasingCustomer, index) => {
        return (
          <PurchasingCustomerBlock key={index}>
            {activeCart && getCanEditPurchasingCustomers(activeCart?.cartItems[0] as any) && hasOrderEditPermission && (
              <div className="purchasing-customer-actions">
                {canEdit && (
                  <>
                    <em
                      title="Edit Purchasing Customer"
                      onClick={() => {
                        setCustomerToEdit(() => customer);
                        setShowPurchasingCustomerModal(() => true);
                      }}
                      className="fa fa-pencil-alt"></em>
                    <span>&nbsp;|&nbsp;</span>
                  </>
                )}
                <em
                  title="Remove Purchasing Customer"
                  onClick={() => deletePurchasingCustomer(customer.id)}
                  className="fa fa-times"></em>
              </div>
            )}
            <h3>{customer.siteName}</h3>
            <div className="d-flex flex-wrap justify-content-center customer-info">
              {customer.contactFirstName || customer.contactLastName ? (
                <span className="customer-info-overflow">
                  {customer.contactFirstName} {customer.contactLastName}
                </span>
              ) : (
                <span></span>
              )}
              <span className="customer-info-overflow">
                {customer.contactFirstName || customer.contactLastName ? (
                  <span>&nbsp;&nbsp;&bull;&nbsp;&nbsp;</span>
                ) : (
                  <span></span>
                )}
                {customer.address1}
                {customer.address2 && <span>,&nbsp;</span>}
                {customer.address2}
                {customer.address3 && <span>,&nbsp;</span>}
                {customer.address3}
              </span>
              <span className="customer-info-overflow">
                &nbsp;&nbsp;&bull;&nbsp;&nbsp;
                {customer.city}, {customer.stateProvinceCode}
                {customer.postalCode}
              </span>
              {customer.contactEmail && (
                <span className="customer-info-overflow">
                  &nbsp;&nbsp;&bull;&nbsp;&nbsp;
                  <a href={`mailto:${customer.contactEmail}`}>{customer.contactEmail}</a>
                </span>
              )}
              {customer.contactPhoneNumber && (
                <>
                  <span>&nbsp;&nbsp;&bull;&nbsp;&nbsp;</span>
                  <a href={`tel:${customer.contactPhoneNumber}`}>{formatPhoneNumber(customer.contactPhoneNumber)}</a>
                </>
              )}
            </div>
            <ConsumersBlock customer={customer} />
          </PurchasingCustomerBlock>
        );
      })}
      {showPurchasingCustomerModal && (
        <AddPurchasingCustomerModal
          availableAddresses={availableAddresses}
          show={showPurchasingCustomerModal}
          toggleShow={toggleShowPurchasingCustomerModal}
          customerToEdit={customerToEdit}
          canAddNewPurchasingCustomer={true}></AddPurchasingCustomerModal>
      )}
    </>
  );
};

export default PurchasingCustomersBlock;
