import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {BillToSite, CustomerState, initialCustomer} from '../ICustomerState';
import {baseApiCallWithReauth} from '../../../Framework/api-utils/api-utils';
import COMMERCE_CORE_CONSTANTS from '../../../Core/constants';
import qs from 'qs';
import {RootState} from '../../../store/store';
import currentCustomerReducer, {
  setCustomerClassGroups,
  setDefaultWarehouseCode,
  setProductTypeWarehouseCodes,
  setProductTypeWarehouses,
} from '../currentCustomer/slice/currentCustomer.slice';

const initialState: CustomerState = {
  currentCustomer: initialCustomer,
  currentCustomerBillToSite: null,
  currentCustomerBillToSites: null,
  currentCustomerShipToSites: null,
  siteInfo: null,
  siteInfoStatus: 'idle',
  preferredShippingContact: [],
};

export const setSiteInfo = createAsyncThunk(
  'customer/setSiteInfo',
  async ({currentCustomer}: {currentCustomer: any}) => {
    const endpoint = `${COMMERCE_CORE_CONSTANTS.API_SERVICES.CUSTOMER.customers}/${currentCustomer.id}${COMMERCE_CORE_CONSTANTS.API_SERVICES.CUSTOMER.sites}`;
    return await baseApiCallWithReauth('GET', endpoint);
  }
);

export const getCustomerFullProfileAsync = createAsyncThunk(
  'customer/getCustomerFullProfileAsync',
  async ({customerId}: {customerId: string}) => {
    const endpoint = `${COMMERCE_CORE_CONSTANTS.API_SERVICES.CUSTOMER.customers}/${customerId}/FullProfile`;
    return await baseApiCallWithReauth('GET', endpoint);
  }
);

export const getCustomerShipToSitesAsync = createAsyncThunk(
  'customer/getCustomerShipToSitesAsync',
  async ({customerId}: {customerId: string}) => {
    const endpoint = `${COMMERCE_CORE_CONSTANTS.API_SERVICES.CUSTOMER.customers}/${customerId}${COMMERCE_CORE_CONSTANTS.API_SERVICES.CUSTOMER.sites}`;
    return await baseApiCallWithReauth('GET', endpoint, qs.stringify({siteUse: 'SHIP_TO'}));
  }
);

export const getCustomerBillToSitesAsync = createAsyncThunk(
  'customer/getCustomerBillToSitesAsync',
  async ({customerId}: {customerId: string}) => {
    const endpoint = `${COMMERCE_CORE_CONSTANTS.API_SERVICES.CUSTOMER.customers}/${customerId}${COMMERCE_CORE_CONSTANTS.API_SERVICES.CUSTOMER.sites}`;
    return await baseApiCallWithReauth('GET', endpoint, qs.stringify({siteUse: 'BILL_TO'}));
  }
);

export const getPreferredShippingContactAsync = createAsyncThunk(
  'customer/getPreferredShippingContactAsync',
  async ({erpCustomerId}: {erpCustomerId: string}) => {
    const endpoint = `${COMMERCE_CORE_CONSTANTS.API_SERVICES.CUSTOMER.preferredShippingContact}/${erpCustomerId}`;
    return await baseApiCallWithReauth('GET', endpoint);
  }
);

export const customerSlice = createSlice({
  name: 'customer',
  initialState,
  reducers: {
    setCurrentCustomer: (state, action: PayloadAction<any>) => {
      state.currentCustomer = action.payload;
    },
    setCurrentCustomerBillToSites: (state, action: PayloadAction<any>) => {
      state.currentCustomerBillToSites = action.payload;
      state.currentCustomerBillToSite = action.payload[0];
    },
    setCurrentCustomerShipToSites: (state, action: PayloadAction<any>) => {
      state.currentCustomerShipToSites = action.payload;
    },
    removeCurrentCustomer: (state) => {
      state.currentCustomer = initialCustomer;
      state.siteInfoStatus = 'idle';
    },
    removeCurrentCustomerBillToSites: (state) => {
      state.currentCustomerBillToSites = null;
    },
    removeCurrentCustomerShipToSites: (state) => {
      state.currentCustomerShipToSites = null;
    },
    setCustomerInitialState: (state: any) => {
      for (const key in initialState) {
        state[key] = (initialState as any)[key];
      }
    },
    setCustomerBillToSite: (state, action: PayloadAction<BillToSite>) => {
      state.currentCustomerBillToSite = action.payload;
    },
    setPreferredShippingContact: (state, action: PayloadAction<any>) => {
      state.preferredShippingContact = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setCustomerClassGroups.fulfilled, (state: any, action: any) => {
      state.currentCustomer = currentCustomerReducer(state.currentCustomer, action);
    });
    builder.addCase(setProductTypeWarehouseCodes.fulfilled, (state: any, action: any) => {
      state.currentCustomer = currentCustomerReducer(state.currentCustomer, action);
    });
    builder.addCase(setProductTypeWarehouses, (state: any, action: any) => {
      state.currentCustomer = currentCustomerReducer(state.currentCustomer, action);
    });
    builder.addCase(setDefaultWarehouseCode, (state: any, action: any) => {
      state.currentCustomer = currentCustomerReducer(state.currentCustomer, action);
    });
    builder.addCase(setSiteInfo.fulfilled, (state, action) => {
      state.siteInfo = action.payload;
      state.siteInfoStatus = 'succeeded';
    });
    builder.addCase(setSiteInfo.pending, (state) => {
      state.siteInfoStatus = 'loading';
    });
    builder.addCase(getCustomerFullProfileAsync.fulfilled, (state: any, action: any) => {
      state.currentCustomer = action.payload;
    });
    builder.addCase(getCustomerShipToSitesAsync.fulfilled, (state: any, action: any) => {
      state.currentCustomerShipToSites = action.payload;
    });
    builder.addCase(getCustomerBillToSitesAsync.fulfilled, (state: any, action: any) => {
      state.currentCustomerBillToSites = action.payload;
    });
    builder.addCase(getPreferredShippingContactAsync.fulfilled, (state: any, action: any) => {
      state.preferredShippingContact = action.payload;
    });
  },
});

export const {
  setCurrentCustomer,
  setCurrentCustomerBillToSites,
  setCurrentCustomerShipToSites,
  removeCurrentCustomer,
  removeCurrentCustomerBillToSites,
  removeCurrentCustomerShipToSites,
  setCustomerInitialState,
  setCustomerBillToSite,
  setPreferredShippingContact,
} = customerSlice.actions;

export const selectCurrentCustomer = (state: RootState) => state.customer.currentCustomer;
export const selectCurrentCustomerBillToSite = (state: RootState) => state.customer.currentCustomerBillToSite;
export const selectCurrentCustomerBillToSites = (state: RootState) => state.customer.currentCustomerBillToSites;
export const selectCurrentCustomerShipToSites = (state: RootState) => state.customer.currentCustomerShipToSites;
export const selectSiteInfo = (state: RootState) => state.customer.siteInfo;
export const selectSiteInfoStatus = (state: RootState) => state.customer.siteInfoStatus;
export const selectPreferredShippingContact = (state: RootState) => state.customer.preferredShippingContact;

export default customerSlice.reducer;
