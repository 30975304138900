import {IOpenFabOrderReportJob, IOpenFabOrdersReportData} from '../IReportsState';

export const mapOpenFabOrdersReportData = (report: IOpenFabOrdersReportData) => {
  const mappedReport = JSON.parse(JSON.stringify(report));

  mappedReport.jobs.sort(function (a: IOpenFabOrderReportJob, b: IOpenFabOrderReportJob) {
    const aDate: any = new Date(a.availablePickupDate);
    const bDate: any = new Date(b.availablePickupDate);
    return aDate - bDate;
  });

  for (const job of mappedReport.jobs) {
    job.designThicknessFinish = [];
    job.jobProfileCodes = [];
    for (const line of job.reportLines) {
      let designThicknessFinish = `${line.designName} - ${line.thickness} (${line.finish})`;
      if (
        !job.designThicknessFinish.includes(designThicknessFinish) &&
        line.itemType !== 'Fab Profile' &&
        line.itemType !== 'Service' &&
        line.itemType !== 'Other' &&
        line.itemType !== 'Return'
      ) {
        job.designThicknessFinish.push(designThicknessFinish);
      }
    }

    if (job.availablePickupDate.includes('0001-01-01T00:00:00')) {
      job.availablePickupDate = null;
    }
  }

  return mappedReport;
};
