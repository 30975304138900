import styled from 'styled-components/macro';
import {$gray60, $gray97, $screenmd} from '../../../../../Styled/variables';

export interface CustomerSearchContainerProps {}

export const CustomerSearchContainer = styled.div<CustomerSearchContainerProps>`
  background: ${$gray97};
  .autocomplete {
    width: 100%;
  }
  .search-container {
    justify-content: center;
    align-items: center;
  }
  .btn-primary {
    color: #fff !important;
    background-color: #c59617 !important;
    border-color: #979797 !important;
    :hover {
      background-color: #977312 !important; //linear-gradient(0deg, rgba(0, 0, 0, 0.295), rgba(0, 0, 0, 0.259)), #c59617 !important;
    }
  }

  .gray-header-background {
    position: relative;
    background-color: rgb(204, 204, 204);
    height: 100px;
  }
  .search-button {
    border: 1px solid ${$gray60};
  }

  .rbt-input-main,
  .search-button {
    height: 100% !important;
  }
  .search-bar {
    width: 100%;
  }

  .rbt-input-main {
    width: 100% !important;
    display: block !important;
    padding: 6px 12px !important;
    font-size: 16px !important;
    line-height: 1.428571429 !important;
    color: #4c4c4c !important;
    background-color: #fff !important;
    background-image: none !important;
    border: 1px solid #979797 !important;
    border-radius: 0 !important;
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%) !important;
    transition:
      border-color ease-in-out 0.15s,
      box-shadow ease-in-out 0.15s !important;
    :focus {
      box-shadow:
        inset 0 1px 1px rgb(198 150 25 / 8%),
        0 0 8px rgb(198 150 25 / 60%) !important;
    }
    ::placeholder {
      color: #cfcfd8 !important;
    }
  }
  .search-items-container {
    display: flex;

    height: 46px;

    margin: auto;
    margin-top: 45px;

    padding-right: 47px !important;
    padding-left: 47px !important;
  }

  .rbt-loader {
    display: none !important;
  }
  .icons-cambria-Ui-Triangle {
    font-size: 7px !important;
    padding: 0px !important;
    margin: 0 0 2px 6px !important;
    color: #979797;
  }
  .search-button {
    width: 161px;
    font-family: Montserrat;
    font-weight: 400;
    font-size: 16px !important;
    border: none;
  }
  button {
    border-radius: 0px !important;
  }
  .autosuggest-option {
    border-left: 3px solid transparent !important;
    line-height: 2.5 !important;
    color: #282828 !important;
    display: block !important;
    width: 100%;
    clear: both !important;
    font-weight: 400;
    white-space: normal !important;
    font-size: 14px;
    display: flex !important;
    :hover {
      text-decoration: none;
      border-left: 3px solid #c59617;
      background-color: #f8f8f8;
      overflow: auto;
    }
  }
  .dropdown-item {
    border-left: 3px solid transparent !important;
    :hover,
    :active {
      text-decoration: none !important;
      border-left: 3px solid #c59617 !important;
      background-color: #f8f8f8 !important;
      overflow: auto !important;
    }
    :active {
      text-decoration: none !important;
      border-left: 3px solid #c59617 !important;
      background-color: #f8f8f8 !important;
      overflow: auto !important;
    }
  }
  .location {
    color: #999;
    font-size: 87%;
  }
  .suggestion-in {
    color: #979797;
  }

  .rbt-menu {
    max-height: 418px !important;
  }
  .rbt-input-hint {
    display: none !important;
  }
  .dropdown-menu {
    margin: 0px !important;
  }

  .customer-name {
    width: 35%;
  }
  @media screen and (max-width: ${$screenmd}) {
    height: 100px;
    .search-button {
      width: 100px;
    }
    .gray-header-background {
      height: 0px;
    }
    .search-items-container {
      margin-top: 0px;
      margin-bottom: 0px !important;
    }
    .search-container {
      max-width: 961px !important;
    }
  }
`;
