import React from 'react';
import ContentLoader from 'react-content-loader';

export const CustomerStatementRowPlaceholder = () => {
  const cell = (width: number) => {
    return (
      <td>
        <ContentLoader
          width={width}
          height={30}
          animate={false}
          foregroundColor={'#dfdfdf'}
          backgroundColor={'#dfdfdf'}>
          <rect x="0" y="0" width="300" height="10" />
        </ContentLoader>
      </td>
    );
  };

  return (
    <tr>
      {cell(70)}
      {cell(60)}
      {cell(70)}
      {cell(50)}
      {cell(100)}
      {cell(50)}
      {cell(25)}
      {cell(25)}
      {cell(25)}
      {cell(25)}
      {cell(25)}
      {cell(25)}
      {cell(50)}
    </tr>
  );
};

const CustomerStatementTablePlaceholder = () => {
  return (
    <tbody>
      {CustomerStatementRowPlaceholder()}
      {CustomerStatementRowPlaceholder()}
      {CustomerStatementRowPlaceholder()}
      {CustomerStatementRowPlaceholder()}
      {CustomerStatementRowPlaceholder()}
      {CustomerStatementRowPlaceholder()}
    </tbody>
  );
};

export default CustomerStatementTablePlaceholder;
