import {toast} from 'react-toastify';
import {getVerifyTokenRequest, postPasswordResetsRequest} from '../controller/password.controller';

export const requestPasswordResetAsyncFunction = async (email: string) => {
  const response = await postPasswordResetsRequest(email);

  if (response.status >= 200 && response.status < 300) {
    const successResponse = await response.text();
    toast.info('We sent a message with a link to reset your password. Please check your email.');
    return successResponse;
  } else if (response.status >= 400 && response.status < 600) {
    const failedResponse = JSON.parse(await response.text());
    toast.error(failedResponse ? failedResponse.Message : response.statusText);
    return failedResponse ? failedResponse.Message : response.statusText;
  } else {
    toast.error('Encountered an unexpected error.');
  }
};

export const verifyTokenAsyncFunction = async ({userName, resetToken}: {userName: string; resetToken: string}) => {
  const data = new URLSearchParams();
  data.append('request.directoryName', 'local');
  data.append('request.userName', userName);
  data.append('request.resetToken', resetToken);
  return await getVerifyTokenRequest(data);
};
