import styled from 'styled-components/macro';
import {medium, large, xlarge} from '../../../../../Framework/Components/styles/media-queries';
import {$gray97} from '../../../../../Styled/variables';

export const SearchBarContainer = styled.div.attrs((props) => ({
  backgroundColor: props.color || `${$gray97}`,
}))`
  .autocomplete {
    width: 100%;
  }

  .search-container {
    justify-content: center;
    align-items: center;
    z-index: 1;
  }

  ${medium} {
    .global-search {
      height: 70px;
    }
  }

  .btn-primary {
    color: #fff !important;
    background-color: #c59617 !important;
    border-color: #979797 !important;
    :hover {
      background-color: #977312 !important; //linear-gradient(0deg, rgba(0, 0, 0, 0.295), rgba(0, 0, 0, 0.259)), #c59617 !important;
    }
  }

  .rbt-input-main,
  .dropdown-button,
  .search-button {
    height: 46px !important;
  }

  .rbt-input-main {
    width: 100% !important;
    display: block !important;
    padding: 6px 12px !important;
    font-size: 16px !important;
    line-height: 1.428571429 !important;
    color: #4c4c4c !important;
    background-color: #fff !important;
    background-image: none !important;
    border: 1px solid #979797 !important;
    border-radius: 0 !important;
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%) !important;
    transition:
      border-color ease-in-out 0.15s,
      box-shadow ease-in-out 0.15s !important;
    :focus {
      box-shadow:
        inset 0 1px 1px rgb(198 150 25 / 8%),
        0 0 8px rgb(198 150 25 / 60%) !important;
    }
    ::placeholder {
      color: #cfcfd8 !important;
    }
  }

  .search-items-container {
    display: flex;
    //justify-content: center;
    //align-items:center;
    height: 46px;
    //border: 1px solid;
    margin: auto;
    margin-top: 45px;
    //max-width: 1125px;
    //width: 100%;
    padding-right: 47px !important;
    padding-left: 47px !important;
  }

  ${medium} {
    .search-items-container,
    .search-form {
      margin-top: 0;
    }
  }

  .dropdown-body {
    position: absolute;
    position: absolute;
    border-radius: 0 3px 3px 3px;
    box-shadow: 0 2px 2px rgb(0 0 0 / 25%);
    padding: 10px 30px;
    background-color: #fff;
    width: max-content;
  }
  .dropdown-button {
    background-color: #efefef !important;

    /* width: -moz-fit-content !important;
        width: fit-content !important; */
    height: 46px !important;
    padding: 13px 11px !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-align: center !important;
    align-items: center !important;
    border: 1px solid #979797 !important;
    border-right: none !important;
    white-space: nowrap !important;
    cursor: pointer !important;
  }
  .bg-gray {
    background-color: ${(props) => props.color};
    height: 140px;
  }

  ${medium} {
    .bg-gray {
      height: 70px !important;
    }
  }

  .bg-gray-collapsed {
    height: 0px;
  }
  .rbt-loader {
    display: none !important;
  }
  .dropdown-text {
    font-family: Montserrat, sans-serif;
    font-weight: 500;
    font-size: 16px;
    color: #979797;
  }
  .dropdown-text-selected {
    color: #c59617 !important;
  }
  .icons-cambria-Ui-Triangle {
    font-size: 7px !important;
    padding: 0px !important;
    margin: 0 0 2px 6px !important;
    color: #979797;
  }
  .dropdown-body-selected {
    color: #c59617 !important;
    padding: 10px 16px !important;
    border-radius: 3px 3px 0 0;
    border: none !important;
    background-color: #fff !important;
    box-sizing: content-box;
    margin: 0;
    margin-top: -8px;
    box-shadow: 0 2px 2px rgb(0 0 0 / 25%);
  }
  .search-button {
    width: 161px;
    font-family: Montserrat;
    font-weight: 600;
    font-size: 14px !important;
    border: none;
  }
  .item-type-option {
    cursor: pointer;
    color: #333;
    font-family: Montserrat;
    font-weight: 500;
    margin-top: 15px;
    margin-bottom: 15px;
    :hover {
      color: #c59617;
    }
  }
  button {
    border-radius: 0px !important;
  }
  .autosuggest-option {
    border-left: 3px solid transparent !important;
    line-height: 2.5 !important;
    color: #282828 !important;
    display: block !important;
    //padding: 3px 20px !important;
    clear: both !important;
    font-weight: 400;
    white-space: normal !important;
    font-size: 14px;
    :hover {
      text-decoration: none;
      border-left: 3px solid #c59617;
      background-color: #f8f8f8;
      overflow: auto;
    }
  }

  .product-type {
    color: #c59617;
    font-weight: 700;
  }
  .suggestion-in {
    color: #979797;
  }
  .dropdown-item {
    border-left: 3px solid transparent !important;
    :hover,
    :active {
      text-decoration: none !important;
      border-left: 3px solid #c59617 !important;
      background-color: #f8f8f8 !important;
      overflow: auto !important;
    }
    :active {
      text-decoration: none !important;
      border-left: 3px solid #c59617 !important;
      background-color: #f8f8f8 !important;
      overflow: auto !important;
    }
  }
  .dropdown-item.active {
    text-decoration: none !important;
    border-left: 3px solid #c59617 !important;
    background-color: #f8f8f8 !important;
    overflow: auto !important;
  }
  .rbt-menu {
    max-height: 418px !important;
  }
  .rbt-input-hint {
    display: none !important;
  }
  .dropdown-menu {
    margin: 0px !important;
  }
  ${xlarge} {
    .container {
      max-width: 1170px !important;
    }
  }
  ${large} {
    .container {
      max-width: 970px !important;
    }
  }
  ${medium} {
  }
  @media print {
    visibility: hidden;
  }
`;
