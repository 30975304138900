import {Form, Formik} from 'formik';
import React, {useCallback, useEffect, useState} from 'react';
import {Col, Row, Spinner} from 'react-bootstrap';
import {
  IEdgeProfile,
  IFabricatedApplications,
  IFullPieces,
  IPieceModel,
  IProductDesignResponse,
  NewPieceModel,
} from '../../../../../../features/fabrication/IFabricationState';
import {checkIfAllSelectsOnFormCompleted} from '../../../../../../features/fabrication/service/fabrication.service';
import CambriaInput from '../../../../../../Framework/Components/CambriaInput';
import * as Yup from 'yup';
import {
  IProductCatalogCartItemRelatedItems,
  NewProductCatalogCartItemRelatedItems,
  NewProductCatalogCartItemRequest,
  NewProductCatalogCartItemUpdateRequest,
  NewProductDesignResponse,
} from '../../../../../../features/productCatalog/IProductCatalogState';
import {getAllActiveProductDesigns} from '../../../../../../features/product/service/product.service';
import {pushAddToCartInformationToDataLayer} from '../../../../../../features/analytics/service/analytics.service';
import {pushRemoveFabPieceFromCartEventToDataLayer} from '../../../../../../features/analytics/service/analytics.service';
import {useAppDispatch, useTypedSelector} from '../../../../../../hooks/store';
import {
  selectCurrentCustomer,
  selectCurrentCustomerBillToSites,
  selectCurrentCustomerShipToSites,
} from '../../../../../../features/customer/slice/customer.slice';
import {
  selectActiveProgram,
  selectAvailablePrograms,
} from '../../../../../../features/salesforce/slice/salesforce.slice';
import {getAllFabricatedProductGroups} from '../../../../../../features/productType/service/productType.service';
import {selectActiveCart, setActiveCart} from '../../../../../../features/cart/slice/cart.slice';
import CambriaButton from '../../../../../../Framework/Components/CambriaButton';
import EdgeProfileForm from './EdgeProfileForm';
import {toast} from 'react-toastify';
import {getBillToSiteByOperatingUnitCode, getCart} from '../../../../../../features/cart/service/cart.service';
import {SingleItemPriceRequest, SingleItemPriceResponse} from '../../../../../../features/pricing/IPricingState';
import {getSingleItemPrice} from '../../../../../../features/pricing/service/pricing.service';
import {selectCurrentUser} from '../../../../../../features/auth/slice/authentication.slice';
import {Cart, CartItem, NewCart} from '../../../../../../features/cart/ICartState';
import {NewCustomerFullProfile} from '../../../../../../features/customer/ICustomerState';
import {
  deleteCartItem,
  fabricationProductAddCartItem,
  updateCartItem,
} from '../../../../../../features/productCatalog/service/productCatalog.service';
import CutoutsForm from './Cutouts';
import AdditionalProductsForm from '../AdditionalProductsForm';
import {
  selectEdgeProfileBeingAdded,
  setDuplicateBeingEditted,
  setDuplicatePieceModel,
  setParentPieceLabelOfDuplicate,
} from '../../../../../../features/fabrication/slice/fabrication.slice';
import Icon from '../../../../../../Framework/Components/Icon';
import CambriaSelect from '../../../../../../Framework/Components/CambriaSelect';
import {
  hideFullscreenLoader,
  showFullscreenLoader,
} from '../../../../../../features/fullscreenLoader/slice/fullscreenLoader.slice';
import {
  selectFabLineItemsLimit,
  selectUiSettings,
} from '../../../../../../features/environment/slice/environment.slice';
import {ISalesforceAvailablePrograms} from '../../../../../../features/salesforce/ISalesforceState';
import {getFabricatedDesignRequest} from '../../../../../../features/fabrication/controller/fabrication.controller';
import {
  getFabricatedProductsByCodeRequest,
  getProductGroupItemsRequest,
} from '../../../../../../features/productType/controller/productType.controller';
import {ProductTypeItems} from '../../../../../../features/order/orderDetails/IOrderDetailsState';
import {
  getOperatingUnitCodeByProductType,
  getShipFromWarehouseId,
} from '../../../../../../features/warehouse/service/warehouse.service';
import FabricationPieceFinishSelect from './FabricationPieceFinishSelect';
import FabricationPieceThicknessSelect from './FabricationPieceThicknessSelect';
import COMMERCE_CORE_CONSTANTS from '../../../../../../Core/constants';

export const isCartItemTypeStandard = (activeCart: Cart): boolean => {
  return !!(
    activeCart?.cartItems &&
    activeCart.cartItems.length > 0 &&
    activeCart.cartItems[0].cartItemType === 'Standard'
  );
};

const FabricationPieceForm = ({
  productGroupCode,
  editItem,
  parentItemCartItemId,
  initialFormValues,
  parentItem,
  cancelEdit,
  isDuplicateItem,
  duplicatePieceModel,
  resetOnDuplicateFunction,
  editingExistingPiece,
}: {
  productGroupCode: string;
  editItem?: IFullPieces;
  parentItemCartItemId?: any;
  parentItem?: IFullPieces;
  initialFormValues?: IFullPieces | null;
  cancelEdit?: any;
  isDuplicateItem?: boolean;
  duplicatePieceModel?: IPieceModel | null;
  resetOnDuplicateFunction?: Function;
  editingExistingPiece?: boolean;
}) => {
  const dispatch = useAppDispatch();

  const [squareFootage, setSquareFootage] = useState<number>(0);
  const [includesL, setIncludesL] = useState<boolean>(
    (editItem && editItem.mainPiece && editItem.mainPiece.dimension3 && editItem.mainPiece.dimension4) ||
      (initialFormValues &&
        initialFormValues.mainPiece &&
        initialFormValues.mainPiece.dimension3 &&
        initialFormValues.mainPiece.dimension4) ||
      (duplicatePieceModel && duplicatePieceModel.dimension3 && duplicatePieceModel.dimension4)
      ? true
      : false
  );
  const [seamlessLSquareFootage, setSeamlessLSquareFootage] = useState<number>(0);
  const [pieceModel, setPieceModel] = useState<IPieceModel>(NewPieceModel());
  const [formIsLoading, setFormIsLoading] = useState<boolean>(true);
  const [formIsSavingOrUpdating, setFormIsSavingOrUpdating] = useState<boolean>(false);
  const [saveAndDuplicateLoading, setSaveAndDuplicateLoading] = useState<boolean>(false);
  const [selectFieldsCompleted, setSelectFieldsCompleted] = useState<boolean>(false);
  const [productApplicationOptions, setProductApplicationOptions] = useState<IFabricatedApplications[]>([]);
  const [availableDesigns, setAvailableDesigns] = useState<IProductDesignResponse[]>([]);
  const [allEdgeProfiles, setAllEdgeProfiles] = useState<ProductTypeItems[]>([]);
  const [filteredEdgeProfiles, setFilteredEdgeProfiles] = useState<ProductTypeItems[]>([]);
  const [addedEdgeProfiles, setAddedEdgeProfiles] = useState<IEdgeProfile[]>([]);
  const [currentThickness, setCurrentThickness] = useState<string | null>(null);
  const [designHasBeenChanged, setDesignHasBeenChanged] = useState<boolean>(false);
  const [recalculatingArea, setRecalculatingArea] = useState<boolean>(false);

  const currentCustomer = useTypedSelector(selectCurrentCustomer);
  const activeProgramInfo = useTypedSelector(selectActiveProgram);
  const activeCart = useTypedSelector(selectActiveCart);
  const currentCustomerShipToSites: any = useTypedSelector(selectCurrentCustomerShipToSites);
  const currentCustomerBillToSites: any = useTypedSelector(selectCurrentCustomerBillToSites);
  const userInfo = useTypedSelector(selectCurrentUser);
  const edgeProfileIsBeingAdded = useTypedSelector(selectEdgeProfileBeingAdded);
  const uiSettings = useTypedSelector(selectUiSettings);
  const availablePrograms: ISalesforceAvailablePrograms | null = useTypedSelector(selectAvailablePrograms);
  const lineItemsLimit = useTypedSelector(selectFabLineItemsLimit);

  const isEdit: boolean = !!parentItemCartItemId;
  const edgeProfilesProductGroupCode = 'EdgeProfiles';
  const cartItemType = 'FabAndInstallStandard';
  const noPriceMesage = `Current item does not have a price configured.
		Please contact the Cambria Business Office to have this corrected.`;
  const pieceLabelAlreadyUsedErrorMsg =
    'The piece label chosen is already in use by another piece, please select a new piece label.';

  const sortArrayAlphabeticallyByProperty = (array: any[], property: string) => {
    array.sort((a, b) => a[property].localeCompare(b[property]));
  };

  const handlePieceModelChange = useCallback(
    (attribute: string, value: any) => {
      setPieceModel({...pieceModel, [attribute]: value});
    },
    [pieceModel]
  );

  const addEdgeProfileToPieceModel = (edgeProfile: IEdgeProfile) => {
    let profiles: IEdgeProfile[] = [...pieceModel.edgeProfiles];
    profiles.push(edgeProfile);
    setPieceModel({...pieceModel, edgeProfiles: profiles});
  };

  const onCancel = useCallback(async () => {
    if (!!cancelEdit) {
      cancelEdit();
    }
    if (!!isDuplicateItem) {
      dispatch(setDuplicateBeingEditted(null));
      dispatch(setParentPieceLabelOfDuplicate(null));
    }
    const updatedCart = await getCart(
      activeCart ? activeCart.id : '',
      currentCustomer,
      userInfo ? userInfo.userId : '',
      activeProgramInfo,
      currentCustomerShipToSites ? currentCustomerShipToSites : [],
      currentCustomerBillToSites ? currentCustomerBillToSites : [],
      dispatch,
      availablePrograms,
      false,
      false,
      false
    );
    dispatch(setActiveCart(updatedCart));
  }, [
    activeCart,
    currentCustomer,
    userInfo,
    activeProgramInfo,
    currentCustomerShipToSites,
    currentCustomerBillToSites,
    dispatch,
    cancelEdit,
    isDuplicateItem,
    availablePrograms,
  ]);

  const removeAddedEdgeProfiles = useCallback(
    (pieceModelArg: IPieceModel, filteredEdgeProfilesArg: ProductTypeItems[], edgeProfileToAdd?: ProductTypeItems) => {
      const currentFilteredEdgeProfiles = [...filteredEdgeProfilesArg];
      let updatedEdgeProfiles: ProductTypeItems[] = [];

      currentFilteredEdgeProfiles.forEach((availableEdgeProfile: ProductTypeItems) => {
        let addToFilteredEdges = true;

        pieceModelArg.edgeProfiles.forEach((selectedEdgeProfile: IEdgeProfile) => {
          if (availableEdgeProfile.description === selectedEdgeProfile.selectedEdgeProfile?.description) {
            addToFilteredEdges = false;
          }
        });

        if (addToFilteredEdges) {
          updatedEdgeProfiles.push(availableEdgeProfile);
        }
      });

      if (edgeProfileToAdd) {
        updatedEdgeProfiles.push(edgeProfileToAdd);
      }

      sortArrayAlphabeticallyByProperty(updatedEdgeProfiles, 'description');
      setFilteredEdgeProfiles(updatedEdgeProfiles);
    },
    []
  );

  const filterEdgeProfileItems = useCallback(
    (items: Array<ProductTypeItems>, thickness: string, pieceModelArg: IPieceModel) => {
      const filteredItemsArray: Array<ProductTypeItems> = [];
      if (items) {
        items.forEach((item) => {
          item.attributes.forEach((attribute) => {
            if (attribute.name === 'Base Product Type' && attribute.value === productGroupCode) {
              item.attributes.forEach((attr) => {
                if (attr.name === 'Thickness' && attr.value === thickness) {
                  let addItem = true;
                  pieceModelArg.edgeProfiles.forEach((edgeProfile) => {
                    if (edgeProfile.selectedEdgeProfile?.description === item.description) {
                      addItem = false;
                    }
                  });
                  if (addItem) {
                    filteredItemsArray.push(item);
                  }
                }
              });
            }
          });
        });
      }

      sortArrayAlphabeticallyByProperty(filteredItemsArray, 'description');
      setFilteredEdgeProfiles(filteredItemsArray);
    },
    [productGroupCode]
  );

  const setEdgeProfileDropdown = useCallback(
    (
      pieceModelArg: IPieceModel,
      filteredEdgeProfilesArg: ProductTypeItems[],
      addedEdgeProfilesArg: IEdgeProfile[],
      allEdgeProfilesArg: ProductTypeItems[],
      currentThicknessArg: string | null
    ) => {
      if (
        pieceModelArg.edgeProfiles.length !== addedEdgeProfilesArg.length &&
        filteredEdgeProfilesArg?.length > 0 &&
        (currentThicknessArg ||
          (!currentThicknessArg && pieceModelArg.productGroupCode === 'CutoutsAndInlays') ||
          (!currentThicknessArg && pieceModelArg.productGroupCode === 'GeneralItems'))
      ) {
        let missingEntry = addedEdgeProfilesArg.filter((i) => {
          return (
            pieceModelArg.edgeProfiles.indexOf(i) < 0 &&
            i.selectedEdgeProfile?.attributes?.find((attribute) => attribute.name === 'Thickness')?.value ===
              currentThicknessArg
          );
        });

        setAddedEdgeProfiles(pieceModelArg.edgeProfiles);
        removeAddedEdgeProfiles(
          pieceModelArg,
          filteredEdgeProfilesArg,
          !!missingEntry[0]?.selectedEdgeProfile ? missingEntry[0].selectedEdgeProfile : undefined
        );
      } else if (
        pieceModelArg.edgeProfiles.length === 0 &&
        filteredEdgeProfilesArg?.length === 0 &&
        pieceModelArg.thickness &&
        currentThicknessArg
      ) {
        filterEdgeProfileItems(allEdgeProfilesArg, pieceModelArg.thickness || '', pieceModelArg);
      }
    },
    [filterEdgeProfileItems, removeAddedEdgeProfiles]
  );

  const mapCartItemsToEdgeProfileForm = useCallback(
    (cartItems: CartItem[]) => {
      let edgeProfiles: IEdgeProfile[] = [];
      cartItems.forEach((item) => {
        const selectedProfile = allEdgeProfiles.find((x) => x.description === item.description);
        let edgeProfile: IEdgeProfile = {
          priceCode: item.priceListCode,
          initialLinearInches: item.dimension1,
          linearFeet: item.unitSize,
          unitPrice: item.unitPrice,
          linePrice: item.itemQuantityPrice.toString(),
          selectedEdgeProfile: selectedProfile ? selectedProfile : null,
          withoutLinearMeasure: false,
          price: null,
          cartItem: item,
        };
        edgeProfiles.push(edgeProfile);
      });
      return edgeProfiles;
    },
    [allEdgeProfiles]
  );

  const calculateSquareFootage = useCallback(
    (width: number, length: number) => {
      let squareFootage: number;

      if (productGroupCode === 'Backsplash') {
        squareFootage = Number(length) / 12;
      } else if (productGroupCode === 'MiteredEdge' && Number(width) < 4) {
        squareFootage = (Number(length) * 4) / 144;
      } else {
        squareFootage = (Number(length) * Number(width)) / 144;
      }

      return squareFootage;
    },
    [productGroupCode]
  );

  const numberChanged = useCallback(
    (length: number, width: number, minimumUomQuantity: number | undefined, seamlessL?: boolean) => {
      setRecalculatingArea(true);
      try {
        if (length && width) {
          const tempResult = calculateSquareFootage(width, length);
          const meaningfulDigits = 8;
          const truncatedNumber = Math.trunc(tempResult * Math.pow(10, meaningfulDigits));
          const decimalToRoundOff = truncatedNumber / Math.pow(10, meaningfulDigits - 2);
          const result: number = Math.ceil(decimalToRoundOff) / Math.pow(10, 2);
          const minimumAreaValue = minimumUomQuantity ? minimumUomQuantity : 2.0;

          if (typeof result !== 'undefined') {
            if (
              result !== 0 &&
              result < minimumAreaValue &&
              productGroupCode !== 'MiteredEdge' &&
              productGroupCode !== 'Backsplash'
            ) {
              if (seamlessL) {
                setSeamlessLSquareFootage(minimumAreaValue);
                return minimumAreaValue;
              } else {
                setSquareFootage(minimumAreaValue);
                return minimumAreaValue;
              }
            } else {
              if (seamlessL) {
                setSeamlessLSquareFootage(result);
                return result;
              } else {
                setSquareFootage(result);
                return result;
              }
            }
          }
        } else {
          if (seamlessL) {
            setSeamlessLSquareFootage(0.0);
            return 0.0;
          } else {
            setSquareFootage(0.0);
            return 0.0;
          }
        }
      } finally {
        setRecalculatingArea(false);
      }
    },
    [productGroupCode, calculateSquareFootage]
  );

  const handlePassedInFormValues = useCallback(
    (fullPiece?: IFullPieces | null, duplicatePieceModel?: IPieceModel | null) => {
      if (fullPiece) {
        const productApplication = productApplicationOptions.find(
          (x) => x.name === fullPiece.mainPiece.productApplication
        );
        const selectedDesign = availableDesigns.find((x) => x.designCode === fullPiece.mainPiece.designCode);
        const areaOfDimension12 = numberChanged(
          fullPiece.mainPiece.dimension2 || 0,
          fullPiece.mainPiece.dimension1 || 0,
          productApplication?.minimumUomQuantity
        );
        const areaOfDimension34 = numberChanged(
          fullPiece.mainPiece.dimension4 || 0,
          fullPiece.mainPiece.dimension3 || 0,
          productApplication?.minimumUomQuantity,
          true
        );
        let newPieceModel: IPieceModel = {
          dimension1: fullPiece.mainPiece.dimension1,
          dimension2: fullPiece.mainPiece.dimension2,
          dimension3: fullPiece.mainPiece.dimension3,
          dimension4: fullPiece.mainPiece.dimension4,
          areaOfDimension12: areaOfDimension12 ? areaOfDimension12 : 0,
          areaOfDimension34: areaOfDimension34 ? areaOfDimension34 : 0,
          thickness: fullPiece.mainPiece.thickness,
          finish: fullPiece.mainPiece.finish,
          match: fullPiece.mainPiece.match,
          selectedEdgeProfile: fullPiece.mainEdgeProfile.length > 0 ? fullPiece.mainEdgeProfile[0].description : '',
          selectedFabricatedApplication: productApplication ? productApplication : null,
          selectedDesign: selectedDesign ? selectedDesign : NewProductDesignResponse(),
          pieceLabel: isDuplicateItem ? '' : fullPiece.mainPiece.pieceLabel,
          productGroupCode: fullPiece.mainPiece.productGroupCode,
          edgeProfiles: mapCartItemsToEdgeProfileForm(fullPiece.mainEdgeProfile),
          // This gets set in the GeneralItems form
          selectedServiceType: '',
          quantity: fullPiece.mainPiece.quantity,
          itemDescription: fullPiece.mainPiece.description,
        };
        setPieceModel(newPieceModel);
      } else if (duplicatePieceModel) {
        let tempPieceModel = {...duplicatePieceModel};
        tempPieceModel.pieceLabel = '';
        setPieceModel(tempPieceModel);
        numberChanged(
          duplicatePieceModel.dimension2 || 0,
          duplicatePieceModel.dimension1 || 0,
          duplicatePieceModel.selectedFabricatedApplication?.minimumUomQuantity
        );
        if (duplicatePieceModel.dimension3 && duplicatePieceModel.dimension4) {
          numberChanged(
            duplicatePieceModel.dimension4 || 0,
            duplicatePieceModel.dimension3 || 0,
            duplicatePieceModel.selectedFabricatedApplication?.minimumUomQuantity,
            true
          );
        }
      }
      setFormIsLoading(false);
    },
    [productApplicationOptions, availableDesigns, mapCartItemsToEdgeProfileForm, isDuplicateItem, numberChanged]
  );

  const getEdgeProfileItems = useCallback(
    async (productGroupCode: string, cartItemType: string) => {
      if (activeCart) {
        const results = await getFabricatedProductsByCodeRequest(
          productGroupCode,
          cartItemType,
          activeCart.erpCustomerId,
          activeCart.customerClass,
          activeCart.customerClassGroups,
          activeProgramInfo
        );
        setAllEdgeProfiles(results.items);
      }
    },
    [activeCart, activeProgramInfo]
  );

  const loadApplicationOptions = useCallback(async () => {
    const options = await getFabricatedDesignRequest();
    setProductApplicationOptions(options.results);
  }, []);

  const loadDesignOptions = useCallback(async () => {
    let options = await getAllActiveProductDesigns(currentCustomer, activeProgramInfo.code);
    options = options.sort((a, b) => a.designDescription.localeCompare(b.designDescription));
    setAvailableDesigns(options);
  }, [currentCustomer, activeProgramInfo]);

  const setDataForProductGroupCodeGeneralItems = async (
    productGroupCode: string,
    relatedItems: Array<IProductCatalogCartItemRelatedItems>
  ) => {
    if (productGroupCode !== 'GeneralItems') {
      for (let index = 0; index < pieceModel.edgeProfiles.length; index++) {
        const edgeProfile = pieceModel.edgeProfiles[index];

        if (edgeProfile.selectedEdgeProfile) {
          const shipFromWarehouseId = await getShipFromWarehouseId(
            edgeProfile.selectedEdgeProfile.productType,
            currentCustomer ? currentCustomer : NewCustomerFullProfile(),
            currentCustomerShipToSites ? currentCustomerShipToSites : [],
            activeCart ? activeCart : NewCart()
          );

          const edgeProfileOperatingUnitCode = await getOperatingUnitCodeByProductType(
            edgeProfile.selectedEdgeProfile.productType,
            currentCustomer,
            currentCustomerShipToSites ? currentCustomerShipToSites : [],
            shipFromWarehouseId
          );

          const edgeProfileSiteUseId = await getSiteUseId(
            edgeProfileOperatingUnitCode ? edgeProfileOperatingUnitCode : ''
          );

          let relatedItem: IProductCatalogCartItemRelatedItems = NewProductCatalogCartItemRelatedItems();

          relatedItem.itemNumber = edgeProfile.selectedEdgeProfile.itemNumber;
          relatedItem.dimension1 = edgeProfile.initialLinearInches;
          relatedItem.quantity = 1;

          if (!edgeProfile.initialLinearInches) {
            relatedItem.uomQuantity = 1;
          } else {
            relatedItem.uomQuantity = edgeProfile.linearFeet;
          }
          relatedItem.cartItemType = 'FabAndInstallStandard';
          relatedItem.siteUseId = edgeProfileSiteUseId;

          relatedItems.push(relatedItem);
        }
      }
    }
  };

  const isDuplicatePieceError = (errorMessage: string): boolean => {
    return /^(.* PieceLabel .*already added.*)$/.test(errorMessage);
  };

  const doesPieceLableAlreadyExist = (): boolean => {
    const pieceLabelArray: any[] = [];
    if (
      activeCart &&
      activeCart.cartItems.length > 0 &&
      (!editItem || (editItem && pieceModel.pieceLabel && pieceModel.pieceLabel !== editItem.mainPiece.pieceLabel))
    ) {
      activeCart.cartItems.forEach((x: CartItem) => {
        pieceLabelArray.push(x.pieceLabel);
      });
      if (pieceModel.pieceLabel !== null && pieceLabelArray.includes(pieceModel.pieceLabel)) {
        return true;
      }
    }
    return false;
  };

  const ItemHasAttribute = (item: ProductTypeItems, attributeName: string, attributeValue?: string | null): boolean => {
    if (!attributeValue) {
      return false;
    }
    let hasAttribute: boolean = false;
    for (let index = 0; index < item.attributes.length; index++) {
      const attr = item.attributes[index];

      if (attr.name === attributeName && attr.value === attributeValue) {
        hasAttribute = true;
      }
    }
    return hasAttribute;
  };

  const filterItems = (items: Array<ProductTypeItems>, productGroup: string | null): any => {
    let returnValue: ProductTypeItems | null = null;
    if (productGroup === 'GeneralItems') {
      items.forEach((item) => {
        if (
          item.productDisplayName === pieceModel.selectedServiceType &&
          item.description === pieceModel.itemDescription
        ) {
          returnValue = item;
        }
      });
    } else if (productGroup === 'Backsplash') {
      items.forEach((item) => {
        if (
          ItemHasAttribute(item, 'Thickness', pieceModel.thickness) &&
          ItemHasAttribute(item, 'Finish', pieceModel.finish) &&
          ItemHasAttribute(item, 'Design Name', pieceModel.selectedDesign?.designSeries) &&
          ItemHasAttribute(item, 'Match', pieceModel.match)
        ) {
          returnValue = item;
        }
      });
    } else if (productGroup === 'CutoutsAndInlays') {
      items.forEach((item) => {
        if (ItemHasAttribute(item, 'ItemDescription', pieceModel.itemDescription)) {
          returnValue = item;
        }
      });
    } else {
      items.forEach((item) => {
        if (
          ItemHasAttribute(item, 'Thickness', pieceModel.thickness) &&
          ItemHasAttribute(item, 'Finish', pieceModel.finish) &&
          ItemHasAttribute(item, 'Design Name', pieceModel.selectedDesign?.designSeries)
        ) {
          returnValue = item;
        }
      });
    }
    return returnValue;
  };

  const getSiteUseId = async (operatingUnitCode: string) => {
    const billtoSite = await getBillToSiteByOperatingUnitCode(
      operatingUnitCode,
      currentCustomerBillToSites ? currentCustomerBillToSites : []
    );

    return billtoSite.siteUseId;
  };

  const getDesignCode = (productGroupCode: string | null) => {
    return productGroupCode === 'GeneralItems' || productGroupCode === 'CutoutsAndInlays'
      ? null
      : pieceModel.selectedDesign?.designCode
      ? pieceModel.selectedDesign.designCode
      : '';
  };

  const callGetSingleItemPrice = async (
    getPriceRequest: SingleItemPriceRequest
  ): Promise<SingleItemPriceResponse | null> => {
    let price: SingleItemPriceResponse | null;
    try {
      price = await getSingleItemPrice(
        getPriceRequest.erpCustomerId,
        getPriceRequest.warehouseCode,
        getPriceRequest.programCode,
        getPriceRequest.itemNumber,
        getPriceRequest.siteUseId ? getPriceRequest.siteUseId : 0,
        getPriceRequest.customerClass,
        dispatch
      );
    } catch (exception: any) {
      if (exception.status === 404 || exception.status === 'Failure') {
        toast.error(noPriceMesage);
        price = null;
      } else {
        throw exception;
      }
    }
    return price;
  };

  const getUomQuantity = (pieceModel: IPieceModel, productGroupCode: string) => {
    let returnValue = null;

    if (
      productGroupCode === 'CutoutsAndInlays' ||
      (pieceModel.quantity && !pieceModel.areaOfDimension12 && !pieceModel.areaOfDimension34)
    ) {
      returnValue = pieceModel.quantity;
      return returnValue;
    }

    if (pieceModel.areaOfDimension12) {
      if (pieceModel.areaOfDimension34 && includesL) {
        returnValue = pieceModel.areaOfDimension34 + pieceModel.areaOfDimension12;
        return returnValue;
      } else {
        returnValue = pieceModel.areaOfDimension12;
        return returnValue;
      }
    }

    return returnValue;
  };

  const canUseMainPiece = (): boolean => {
    return (
      (editItem?.mainPiece.productGroupCode === 'CutoutsAndInlays' &&
        editItem?.mainPiece.description === pieceModel.itemDescription) ||
      (editItem?.mainPiece.productGroupCode === 'Fabrication' &&
        editItem?.mainPiece.designCode === pieceModel.selectedDesign?.designCode &&
        editItem?.mainPiece.thickness === pieceModel.thickness &&
        editItem?.mainPiece.finish === pieceModel.finish) ||
      (editItem?.mainPiece.productGroupCode === 'Backsplash' &&
        editItem?.mainPiece.designCode === pieceModel.selectedDesign?.designCode &&
        editItem?.mainPiece.thickness === pieceModel.thickness &&
        editItem?.mainPiece.finish === pieceModel.finish &&
        editItem?.mainPiece.match === pieceModel.match) ||
      (editItem?.mainPiece.productGroupCode === 'GeneralItems' &&
        editItem?.mainPiece.description === pieceModel.itemDescription)
    );
  };

  const getItemPropertiesDependingOnItemNumber = async (): Promise<any> => {
    let result: any = {
      itemNumber: null,
      currency: null,
      cartItemType: null,
      shipFromWarehouseId: null,
      priceListCode: null,
      operatingUnitCode: null,
      siteUseId: null,
    };

    if (canUseMainPiece()) {
      result.itemNumber = editItem?.mainPiece.itemNumber;
      result.currency = editItem?.mainPiece.currency;
      result.cartItemType = editItem?.mainPiece.cartItemType;
      result.shipFromWarehouseId = editItem?.mainPiece.shipFromWarehouseId;
      result.priceListCode = editItem?.mainPiece.priceListCode;
      result.operatingUnitCode = editItem?.mainPiece.operatingUnitCode;
    } else {
      const productGroupItems = await getProductGroupItemsRequest(
        productGroupCode,
        activeCart?.erpCustomerId || '',
        activeCart?.customerClassGroups || [],
        activeProgramInfo
      );
      const item: ProductTypeItems = filterItems(productGroupItems.items, productGroupItems.productGroupCode);

      if (!item) {
        toast.error('This Item was not found.');
        return false;
      }
      result.itemNumber = item.itemNumber;

      const shipFromWarehouseId = await getShipFromWarehouseId(
        item.productType,
        currentCustomer,
        currentCustomerShipToSites,
        activeCart
      );

      result.shipFromWarehouseId = shipFromWarehouseId;

      //for fab item no need to pass in the warehouse
      const cartItemOperatingUnitCode = await getOperatingUnitCodeByProductType(
        item.productType,
        currentCustomer,
        currentCustomerShipToSites
      );

      if (!cartItemOperatingUnitCode) {
        toast.error(noPriceMesage);
        return false;
      }

      result.operatingUnitCode = cartItemOperatingUnitCode;

      const siteUseId = await getSiteUseId(result.operatingUnitCode);

      const getPriceRequest = {
        erpCustomerId: activeCart?.erpCustomerId || '',
        warehouseCode: shipFromWarehouseId,
        programCode: activeProgramInfo.code,
        itemNumber: item.itemNumber,
        siteUseId: siteUseId,
        customerClass: activeCart?.customerClass || '',
      };

      const pricing = await getSingleItemPrice(
        getPriceRequest.erpCustomerId,
        getPriceRequest.warehouseCode,
        getPriceRequest.programCode,
        getPriceRequest.itemNumber,
        getPriceRequest.siteUseId,
        getPriceRequest.customerClass,
        dispatch
      );

      if (pricing) {
        result.siteUseId = siteUseId;
        result.currency = pricing.CurrencyCode;
        result.cartItemType = productGroupItems.cartItemType;
      } else {
        result = null;
      }
    }

    return result;
  };

  const updatePiece = async (saveAndDuplicate: boolean) => {
    if (!saveAndDuplicate) {
      setFormIsSavingOrUpdating(true);
    }
    const propertiesThatChangeWhenItemNumberChanges = await getItemPropertiesDependingOnItemNumber();
    if (!propertiesThatChangeWhenItemNumberChanges) {
      setFormIsSavingOrUpdating(false);
      return;
    }
    if (
      productGroupCode !== 'GeneralItems' &&
      productGroupCode !== 'CutoutsAndInlays' &&
      doesPieceLableAlreadyExist()
    ) {
      toast.error(pieceLabelAlreadyUsedErrorMsg);
      setFormIsSavingOrUpdating(false);
      return false;
    }

    const productGroupItems = await getProductGroupItemsRequest(
      productGroupCode,
      activeCart ? activeCart.erpCustomerId : '',
      activeCart ? activeCart.customerClassGroups : [],
      activeProgramInfo
    );
    const item: ProductTypeItems | null = filterItems(productGroupItems.items, pieceModel.productGroupCode);

    const uomQuantity = getUomQuantity(pieceModel, productGroupCode);
    const cartItem = NewProductCatalogCartItemUpdateRequest();
    cartItem.cartId = editItem?.cartId || '';
    cartItem.itemNumber = propertiesThatChangeWhenItemNumberChanges.itemNumber;
    cartItem.quantity = 1;
    cartItem.shipFromWarehouseId = propertiesThatChangeWhenItemNumberChanges.shipFromWarehouseId;
    cartItem.cartItemType = propertiesThatChangeWhenItemNumberChanges.cartItemType;
    cartItem.designCode =
      productGroupCode === 'GeneralItems' || productGroupCode === 'CutoutsAndInlays'
        ? null
        : pieceModel.selectedDesign?.designCode
        ? pieceModel.selectedDesign.designCode
        : null;
    cartItem.productApplication = pieceModel.selectedFabricatedApplication
      ? pieceModel.selectedFabricatedApplication.name
      : null;
    cartItem.productGroupCode = productGroupCode;
    cartItem.fabOrderingViewType = editItem?.mainPiece.fabOrderingViewType || '';
    cartItem.programCode = editItem?.mainPiece.programCode || '';
    cartItem.programName = editItem?.mainPiece.programName || '';
    cartItem.operatingUnitCode = propertiesThatChangeWhenItemNumberChanges.operatingUnitCode;
    cartItem.priceListCode = propertiesThatChangeWhenItemNumberChanges.priceListCode;
    cartItem.currency = propertiesThatChangeWhenItemNumberChanges.currency;
    cartItem.uomQuantity = uomQuantity;
    cartItem.dimension1 = pieceModel.dimension1 ? pieceModel.dimension1 : 0;
    cartItem.dimension2 = pieceModel.dimension2 ? pieceModel.dimension2 : 0;
    cartItem.dimension3 = includesL ? pieceModel.dimension3 : null;
    cartItem.dimension4 = includesL ? pieceModel.dimension4 : null;
    cartItem.parentCartItemId = parentItemCartItemId;
    cartItem.siteUseId = editItem?.mainPiece.siteUseId || 0;

    if (productGroupCode !== 'GeneralItems') {
      cartItem.pieceLabel = pieceModel.pieceLabel || '';
    }

    try {
      if (editItem?.mainPiece.cartItemId) {
        await updateCartItem(cartItem, editItem.mainPiece.cartItemId, dispatch);
        toast.success('Item was successfully updated.');
        if (initialFormValues) {
          const initialFormValueCopy: IFullPieces = JSON.parse(JSON.stringify(initialFormValues));
          initialFormValueCopy.mainPieceChildItems = [];

          pushRemoveFabPieceFromCartEventToDataLayer([
            {
              pieceModel: initialFormValueCopy,
              quantity: initialFormValueCopy.mainPiece.uomLineQuantity,
            },
          ]);
        }
        // TODO check if the price is correct
        // is using the price previous to updating
        pushAddToCartInformationToDataLayer(
          false,
          pieceModel,
          cartItem.currency,
          item?.productType ?? '',
          editItem?.mainPiece?.adjustedUnitPrice ?? '',
          uomQuantity as any,
          item?.productId ?? '',
          item?.itemNumber ?? '',
          typeof pieceModel.selectedFabricatedApplication === 'string'
            ? pieceModel.selectedFabricatedApplication
            : item?.description ?? '',
          item?.productDisplayName ?? ''
        );
        const updatedCart = await getCart(
          activeCart?.id || '',
          currentCustomer,
          userInfo?.userId || '',
          activeProgramInfo,
          currentCustomerShipToSites,
          currentCustomerBillToSites,
          dispatch,
          availablePrograms
        );
        dispatch(setActiveCart(updatedCart));
      }
      !!cancelEdit && cancelEdit();
      if (!!isDuplicateItem) {
        dispatch(setDuplicateBeingEditted(null));
        dispatch(setParentPieceLabelOfDuplicate(null));
      }
    } catch (e: any) {
      if (isDuplicatePieceError(e.failureReason)) {
        toast.error('Please refresh the page. ' + pieceLabelAlreadyUsedErrorMsg);
      } else {
        toast.error(e);
      }
    } finally {
      setFormIsSavingOrUpdating(false);
      return true;
    }
  };

  const savePiece = async (shouldTriggerAddNewPiece: boolean, saveAndDuplicate: boolean) => {
    if (saveAndDuplicate) {
      dispatch(showFullscreenLoader({}));
    } else {
      setFormIsSavingOrUpdating(true);
    }
    if (
      productGroupCode !== 'GeneralItems' &&
      productGroupCode !== 'CutoutsAndInlays' &&
      doesPieceLableAlreadyExist()
    ) {
      toast.error(pieceLabelAlreadyUsedErrorMsg);
      setFormIsSavingOrUpdating(false);
      dispatch(hideFullscreenLoader());
      return false;
    }
    if (
      activeCart?.cartItems &&
      activeCart.cartItems.length > 0 &&
      activeCart.cartItems[0].cartItemType === 'Standard'
    ) {
      toast.error(
        'Your shopping cart currently contains ' +
          activeCart.cartItems[0].productType +
          ' products that cannot be combined with Fabrication products.' +
          ' Fabrication products can be purchased separately once the ' +
          activeCart.cartItems[0].productType +
          ' request is completed.'
      );
      setFormIsSavingOrUpdating(false);
      return false;
    }
    if (shouldTriggerAddNewPiece) {
      pieceModel.dimension3 = includesL ? pieceModel.dimension3 : 0;
      pieceModel.dimension4 = includesL ? pieceModel.dimension4 : 0;
    }
    const productGroupItems = await getProductGroupItemsRequest(
      productGroupCode,
      activeCart ? activeCart.erpCustomerId : '',
      activeCart ? activeCart.customerClassGroups : [],
      activeProgramInfo
    );
    const item: ProductTypeItems | null = filterItems(productGroupItems.items, pieceModel.productGroupCode);
    if (!item) {
      setFormIsSavingOrUpdating(false);
      dispatch(hideFullscreenLoader());
      toast.error('This Item was not found.');
      return false;
    }
    const shipFromWarehouseId = await getShipFromWarehouseId(
      item ? item.productType : '',
      currentCustomer,
      currentCustomerShipToSites,
      activeCart
    );
    //for fab item no need to pass in the warehouse
    const cartItemOperatingUnitCode = await getOperatingUnitCodeByProductType(
      item ? item.productType : '',
      currentCustomer,
      currentCustomerShipToSites
    );
    if (!cartItemOperatingUnitCode) {
      toast.error(noPriceMesage);
      dispatch(hideFullscreenLoader());
      setFormIsSavingOrUpdating(false);
      return false;
    }
    const siteUseId = await getSiteUseId(cartItemOperatingUnitCode);
    const getPriceRequest = {
      erpCustomerId: activeCart ? activeCart.erpCustomerId : '',
      warehouseCode: shipFromWarehouseId,
      programCode: activeProgramInfo.code,
      itemNumber: item.itemNumber,
      siteUseId: siteUseId,
      customerClass: activeCart ? activeCart.customerClass : '',
    };
    const pricing = await callGetSingleItemPrice(getPriceRequest);
    if (!pricing) {
      dispatch(hideFullscreenLoader());
      setFormIsSavingOrUpdating(false);
      return false;
    }
    const billToSite = await getBillToSiteByOperatingUnitCode(
      cartItemOperatingUnitCode,
      currentCustomerBillToSites ? currentCustomerBillToSites : []
    );
    const uomQuantity = getUomQuantity(pieceModel, productGroupItems.productGroupCode);
    const cartItem = NewProductCatalogCartItemRequest();
    const relatedItems: IProductCatalogCartItemRelatedItems[] = [];
    cartItem.cartId = activeCart ? activeCart.id : '';
    cartItem.itemNumber = item.itemNumber;
    cartItem.quantity = 1;
    cartItem.shipFromWarehouseId = shipFromWarehouseId;
    cartItem.cartItemType = productGroupItems.cartItemType;
    cartItem.designCode = getDesignCode(pieceModel.productGroupCode);
    cartItem.productApplication = pieceModel.selectedFabricatedApplication
      ? pieceModel.selectedFabricatedApplication.name
      : null;
    cartItem.productGroupCode = productGroupItems.productGroupCode;
    cartItem.fabOrderingViewType = productGroupItems.fabOrderingViewType[0];
    cartItem.programCode = activeProgramInfo.code;
    cartItem.programName = activeProgramInfo.name;
    cartItem.relatedItems = relatedItems;
    cartItem.operatingUnitCode = cartItemOperatingUnitCode;
    cartItem.currency = pricing.CurrencyCode;
    cartItem.uomQuantity = uomQuantity;
    cartItem.dimension1 = pieceModel.dimension1;
    cartItem.dimension2 = pieceModel.dimension2;
    cartItem.dimension3 = includesL ? pieceModel.dimension3 : null;
    cartItem.dimension4 = includesL ? pieceModel.dimension4 : null;
    cartItem.parentCartItemId = parentItemCartItemId;
    cartItem.siteUseId = billToSite.siteUseId;
    cartItem.qualifiesforFreeShippingForHomeDepot = item.qualifiesforFreeShippingForHomeDepot;
    if (productGroupCode !== 'GeneralItems') {
      cartItem.pieceLabel = pieceModel.pieceLabel || '';
    }
    await setDataForProductGroupCodeGeneralItems(cartItem.productGroupCode, relatedItems);
    try {
      await fabricationProductAddCartItem(
        cartItem,
        activeCart ? activeCart?.id : '',
        shipFromWarehouseId,
        siteUseId,
        dispatch
      );
      toast.success('Item was successfully added to cart.');

      pushAddToCartInformationToDataLayer(
        false,
        pieceModel,
        pricing.CurrencyCode,
        item.productType,
        pricing.SellingPrice,
        uomQuantity as any,
        item.productId,
        item.itemNumber,
        typeof pieceModel.selectedFabricatedApplication === 'string'
          ? pieceModel.selectedFabricatedApplication
          : item.description,
        item.productDisplayName
      );

      const updatedCart = await getCart(
        activeCart ? activeCart.id : '',
        currentCustomer,
        userInfo ? userInfo.userId : '',
        activeProgramInfo,
        currentCustomerShipToSites ? currentCustomerShipToSites : [],
        currentCustomerBillToSites ? currentCustomerBillToSites : [],
        dispatch,
        availablePrograms,
        false,
        false,
        false
      );
      dispatch(setActiveCart(updatedCart));
      !!cancelEdit && (parentItem || !saveAndDuplicate) && cancelEdit();

      if (isDuplicateItem) {
        dispatch(setDuplicateBeingEditted(null));
        dispatch(setParentPieceLabelOfDuplicate(null));
      }
    } catch (error: any) {
      console.error(error);
      if (isDuplicatePieceError(error.failureReason)) {
        toast.error('Please refresh the page. ' + pieceLabelAlreadyUsedErrorMsg);
      } else {
        toast.error('There was an issue when adding an item to the cart');
      }
      if (error.failureReason === 'Failed in ProductCatalog.Domain.Consumers.PricingItemConsumer') {
        const errorCart = await getCart(
          activeCart ? activeCart.id : '',
          currentCustomer,
          userInfo ? userInfo.userId : '',
          activeProgramInfo,
          currentCustomerShipToSites,
          currentCustomerBillToSites,
          dispatch,
          availablePrograms
        );
        if (errorCart && errorCart.id) {
          const errorItem = errorCart.cartItems.length - 1;
          await deleteCartItem(
            errorCart.cartItems[errorItem].itemNumber,
            errorCart.id,
            errorCart.cartItems[errorItem].cartItemId,
            dispatch
          );
          const updatedCart = await getCart(
            activeCart?.id || '',
            currentCustomer,
            userInfo?.userId || '',
            activeProgramInfo,
            currentCustomerShipToSites || [],
            currentCustomerBillToSites,
            dispatch,
            availablePrograms
          );
          dispatch(setActiveCart(updatedCart));
        }
      }
    } finally {
      setFormIsSavingOrUpdating(false);
      if (saveAndDuplicate) {
        dispatch(hideFullscreenLoader());
      }
      return true;
    }
  };

  const removeEdgePieceFunction = (index: number) => {
    let pieceModelCopy = JSON.parse(JSON.stringify(pieceModel));
    pieceModelCopy.edgeProfiles.splice(index, 1);
    handlePieceModelChange('edgeProfiles', pieceModelCopy.edgeProfiles);
    filterEdgeProfileItems(allEdgeProfiles, pieceModelCopy.thickness || '', pieceModelCopy);
  };

  const RenderEdgeProfiles = ({edgeProfiles}: {edgeProfiles: IEdgeProfile[]}) => {
    if (edgeProfiles?.length > 0) {
      return (
        <>
          {edgeProfiles.map((profile, index) => {
            return (
              <EdgeProfileForm
                editItem={editItem}
                key={index}
                filteredEdgeProfiles={filteredEdgeProfiles}
                index={index}
                handleChangeToPieceModelFunction={addEdgeProfileToPieceModel}
                removeEdgePieceFunction={removeEdgePieceFunction}
                initialState={profile || undefined}
                canRemoveEdgeProfile
              />
            );
          })}
        </>
      );
    } else {
      return <></>;
    }
  };

  const saveAndDuplicatePiece = async () => {
    if (lineItemsLimit && activeCart?.cartItems && activeCart.cartItems.length >= lineItemsLimit) {
      toast.error(COMMERCE_CORE_CONSTANTS.ERROR_MESSAGES.FAB_LINE_ITEMS_LIMIT_REACHED);
      return;
    }

    setSaveAndDuplicateLoading(true);
    if (
      productGroupCode !== 'GeneralItems' &&
      productGroupCode !== 'CutoutsAndInlays' &&
      doesPieceLableAlreadyExist()
    ) {
      toast.error(pieceLabelAlreadyUsedErrorMsg);
      setSaveAndDuplicateLoading(false);
      return;
    }

    const result = editItem ? await updatePiece(true) : await savePiece(false, true);

    if (result) {
      resetOnDuplicateFunction && resetOnDuplicateFunction();
      let pieceModelCopy = pieceModel;
      if (parentItemCartItemId) {
        pieceModelCopy.parentCartItemId = parentItemCartItemId;
      }
      await dispatch(setDuplicatePieceModel(pieceModelCopy));
    }
    setSaveAndDuplicateLoading(false);
  };

  const callGetAllFabricatedProductGroups = useCallback(async () => {
    const productGroups = await getAllFabricatedProductGroups(currentCustomer);
    const groupCode = productGroups.find((x) => x.productGroupCode === productGroupCode)?.productGroupCode;
    if (groupCode) {
      handlePieceModelChange('productGroupCode', groupCode);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCustomer, productGroupCode]);

  const resetEdgeProfiles = useCallback(async () => {
    let pieceModelCopy = {...pieceModel};
    pieceModelCopy.edgeProfiles = [];
    setPieceModel(pieceModelCopy);
    setAddedEdgeProfiles([]);

    if (editItem) {
      editItem.mainEdgeProfile = [];
    }

    if (pieceModel.edgeProfiles && pieceModel.edgeProfiles.length > 0) {
      const updatedCart = await getCart(
        activeCart ? activeCart.id : '',
        currentCustomer,
        userInfo ? userInfo.userId : '',
        activeProgramInfo,
        currentCustomerShipToSites ? currentCustomerShipToSites : [],
        currentCustomerBillToSites ? currentCustomerBillToSites : [],
        dispatch,
        availablePrograms,
        false,
        false,
        false
      );
      const parentCartItemId = updatedCart.cartItems.find((x: any) => x.pieceLabel === pieceModel.pieceLabel)
        ?.cartItemId;
      const edgeProfilesToRemove = updatedCart.cartItems.filter(
        (x: any) => x.parentCartItemId === parentCartItemId && x.productType === 'FabricatedService'
      );
      await Promise.all(
        edgeProfilesToRemove.map(async (cartItem: any) => {
          await deleteCartItem(cartItem.itemNumber, updatedCart.id, cartItem.cartItemId, dispatch);
        })
      );
      updatedCart.cartItems = updatedCart.cartItems.filter(
        (x: any) => x.productType !== 'FabricatedService' || x.parentCartItemId !== parentCartItemId
      );
      dispatch(setActiveCart(updatedCart));
    }
  }, [
    pieceModel,
    editItem,
    activeCart,
    activeProgramInfo,
    currentCustomer,
    currentCustomerBillToSites,
    currentCustomerShipToSites,
    dispatch,
    userInfo,
    availablePrograms,
  ]);

  useEffect(() => {
    if (pieceModel.selectedDesign && designHasBeenChanged && uiSettings) {
      setDesignHasBeenChanged(false);
    }
  }, [pieceModel, uiSettings, designHasBeenChanged]);

  useEffect(() => {
    if ((duplicatePieceModel || initialFormValues) && !formIsLoading && !editingExistingPiece) {
      let duplicateForm = document.getElementById('fab-duplicate-form');
      duplicateForm?.scrollIntoView({behavior: 'smooth'});
      dispatch(hideFullscreenLoader());
    }
  }, [formIsLoading, dispatch, duplicatePieceModel, initialFormValues, editingExistingPiece]);

  useEffect(() => {
    handlePieceModelChange('areaOfDimension12', squareFootage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [squareFootage]);

  useEffect(() => {
    handlePieceModelChange('areaOfDimension34', seamlessLSquareFootage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seamlessLSquareFootage]);

  useEffect(() => {
    setSelectFieldsCompleted(checkIfAllSelectsOnFormCompleted(pieceModel, productGroupCode));
  }, [pieceModel, productGroupCode]);

  useEffect(() => {
    if (initialFormValues || duplicatePieceModel) {
      if (productApplicationOptions && allEdgeProfiles.length > 0 && availableDesigns.length > 0 && formIsLoading) {
        handlePassedInFormValues(initialFormValues, duplicatePieceModel);
      }
    } else {
      setFormIsLoading(false);
    }
  }, [
    initialFormValues,
    handlePassedInFormValues,
    productApplicationOptions,
    allEdgeProfiles,
    availableDesigns,
    formIsLoading,
    duplicatePieceModel,
  ]);

  useEffect(() => {
    getEdgeProfileItems(edgeProfilesProductGroupCode, cartItemType);
  }, [getEdgeProfileItems]);

  useEffect(() => {
    if (productApplicationOptions.length === 0) {
      loadApplicationOptions();
    }
  }, [productApplicationOptions, loadApplicationOptions]);

  useEffect(() => {
    if (availableDesigns.length === 0) {
      loadDesignOptions();
    }
  }, [availableDesigns, loadDesignOptions]);

  useEffect(() => {
    if (initialFormValues && !squareFootage) {
      numberChanged(
        pieceModel.dimension2 || 0,
        pieceModel.dimension1 || 0,
        pieceModel.selectedFabricatedApplication?.minimumUomQuantity
      );
    }
    if (
      (initialFormValues && editItem && pieceModel.dimension3 && pieceModel.dimension4 && !seamlessLSquareFootage) ||
      (initialFormValues &&
        isDuplicateItem &&
        pieceModel.dimension3 &&
        pieceModel.dimension4 &&
        !seamlessLSquareFootage) ||
      (initialFormValues &&
        duplicatePieceModel &&
        pieceModel.dimension3 &&
        pieceModel.dimension4 &&
        !seamlessLSquareFootage)
    ) {
      numberChanged(
        pieceModel.dimension4 || 0,
        pieceModel.dimension3 || 0,
        pieceModel.selectedFabricatedApplication?.minimumUomQuantity,
        true
      );
    }
  }, [
    pieceModel,
    numberChanged,
    initialFormValues,
    squareFootage,
    seamlessLSquareFootage,
    editItem,
    isDuplicateItem,
    duplicatePieceModel,
  ]);

  useEffect(() => {
    if (currentCustomer.id) {
      callGetAllFabricatedProductGroups();
    }
  }, [currentCustomer, callGetAllFabricatedProductGroups]);

  useEffect(() => {
    if (productGroupCode === 'CutoutsAndInlays' && parentItem && allEdgeProfiles.length > 0) {
      filterEdgeProfileItems(allEdgeProfiles, parentItem.mainPiece.thickness, pieceModel);
    }
  }, [productGroupCode, parentItem, allEdgeProfiles, filterEdgeProfileItems, pieceModel]);

  useEffect(() => {
    if ((editItem || initialFormValues) && allEdgeProfiles?.length > 0) {
      let editItemThickness;

      if (currentThickness) {
        editItemThickness = currentThickness;
      } else if (editItem?.mainPiece?.thickness) {
        editItemThickness = editItem.mainPiece.thickness;
      } else if (initialFormValues?.mainPiece?.thickness) {
        editItemThickness = initialFormValues.mainPiece.thickness;
      } else if (initialFormValues?.mainEdgeProfile[0]?.thickness) {
        editItemThickness = initialFormValues.mainEdgeProfile[0].thickness;
      }

      if (editItemThickness) {
        filterEdgeProfileItems(allEdgeProfiles, editItemThickness, pieceModel);
      }
    }
  }, [editItem, allEdgeProfiles, filterEdgeProfileItems, initialFormValues, currentThickness, pieceModel]);

  useEffect(() => {
    if (pieceModel.thickness !== currentThickness) {
      if (currentThickness && pieceModel.thickness) {
        resetEdgeProfiles();
      }
      setCurrentThickness(pieceModel.thickness);
      filterEdgeProfileItems(allEdgeProfiles, pieceModel.thickness || '', pieceModel);
    }
  }, [pieceModel, currentThickness, allEdgeProfiles, filterEdgeProfileItems, resetEdgeProfiles]);

  useEffect(() => {
    setEdgeProfileDropdown(pieceModel, filteredEdgeProfiles, addedEdgeProfiles, allEdgeProfiles, currentThickness);
  }, [pieceModel, addedEdgeProfiles, filteredEdgeProfiles, currentThickness, allEdgeProfiles, setEdgeProfileDropdown]);

  if (formIsLoading) {
    return <Spinner animation="border"></Spinner>;
  } else if (productGroupCode.includes('Cutouts')) {
    return (
      <>
        <CutoutsForm
          handleChange={handlePieceModelChange}
          currentCustomer={currentCustomer}
          activeProgramInfo={activeProgramInfo}
          pieceLabel={parentItem?.mainPiece.pieceLabel}
          productGroupCode={productGroupCode}
          requirePieceLabel={
            !!parentItem && !parentItem?.mainPiece.pieceLabel && parentItem?.mainPiece.pieceLabel !== ''
          }
          itemDescription={editItem?.mainPiece.description || initialFormValues?.mainPiece.description}
          quantity={
            editItem?.mainPiece.quantity
              ? editItem?.mainPiece.quantity
              : initialFormValues?.mainPiece.quantity
              ? initialFormValues?.mainPiece.quantity
              : undefined
          }
        />
        <Row>
          <Col>
            <RenderEdgeProfiles edgeProfiles={addedEdgeProfiles} />
            {addedEdgeProfiles.length < 8 && (
              <EdgeProfileForm
                editItem={editItem}
                key={pieceModel.edgeProfiles.length}
                filteredEdgeProfiles={filteredEdgeProfiles}
                index={pieceModel.edgeProfiles.length}
                handleChangeToPieceModelFunction={addEdgeProfileToPieceModel}
                removeEdgePieceFunction={undefined}
                canRemoveEdgeProfile={false}
              />
            )}
          </Col>
        </Row>
        <Row>
          <Col className="d-flex justify-content-end">
            <CambriaButton variant="transparent" onClick={() => !!cancelEdit && cancelEdit()}>
              CANCEL
            </CambriaButton>
            <CambriaButton
              disabled={
                !pieceModel.itemDescription ||
                !pieceModel.quantity ||
                pieceModel.edgeProfiles.length === 0 ||
                formIsSavingOrUpdating
              }
              className="ml-2"
              variant="primary"
              type="submit"
              onClick={() => (editItem ? updatePiece(false) : savePiece(false, false))}>
              {formIsSavingOrUpdating && (
                <Icon className="save-piece-spinner" size="20" weight="600" icon="fa fa-spinner fa-spin" disabled />
              )}
              {parentItem?.mainPiece.pieceLabel
                ? `SAVE ITEM TO PIECE ${parentItem.mainPiece.pieceLabel}`
                : 'SAVE ITEM TO PIECE'}
            </CambriaButton>
          </Col>
        </Row>
      </>
    );
  } else if (productGroupCode === 'GeneralItems') {
    return (
      <AdditionalProductsForm
        parentPieceLabel={parentItem ? parentItem.mainPiece.pieceLabel : undefined}
        savePieceFunction={savePiece}
        saveAndDuplicatePieceFunction={saveAndDuplicatePiece}
        handleSelectionFunction={handlePieceModelChange}
        clearFunction={cancelEdit}
        editItem={editItem ? editItem : initialFormValues ? initialFormValues : undefined}
        pieceIsSaving={formIsSavingOrUpdating}
        duplicateBtnClicked={saveAndDuplicateLoading}
        updateItemFunction={updatePiece}
        isDuplicate={!!initialFormValues && !editItem}
      />
    );
  } else {
    return (
      <Formik
        onSubmit={() => {}}
        initialValues={
          (editItem && editItem.mainPiece && editItem.mainPiece.dimension3 && editItem.mainPiece.dimension4) ||
          (isDuplicateItem && pieceModel.dimension3 && pieceModel.dimension4) ||
          (duplicatePieceModel && pieceModel.dimension3 && pieceModel.dimension4)
            ? {
                productApplication: {name: pieceModel.selectedFabricatedApplication?.name},
                pieceLabel: pieceModel.pieceLabel || '',
                designName: {designDescription: pieceModel.selectedDesign?.designDescription},
                pieceThickness: {name: pieceModel.thickness},
                pieceFinish: {name: pieceModel.finish},
                length: pieceModel.dimension2,
                width: pieceModel.dimension1,
                seamlessLLength: pieceModel.dimension4,
                seamlessLWidth: pieceModel.dimension3,
              }
            : {
                productApplication: {name: pieceModel.selectedFabricatedApplication?.name},
                pieceLabel: pieceModel.pieceLabel || '',
                designName: {designDescription: pieceModel.selectedDesign?.designDescription},
                pieceThickness: {name: pieceModel.thickness},
                pieceFinish: {name: pieceModel.finish},
                length: pieceModel.dimension2,
                width: pieceModel.dimension1,
              }
        }
        validationSchema={Yup.object().shape({
          productApplication: Yup.mixed().when('pieceLabel', {
            is: () => !isEdit || productGroupCode === 'Backsplash' || productGroupCode === 'MiteredEdge',
            then: Yup.mixed(),
            otherwise: Yup.mixed().required('This field is required'),
          }),
          pieceLabel: Yup.string().when('length', {
            is: () => productGroupCode === 'Backsplash',
            then: Yup.string()
              .max(4, 'Maximum character limit exceeded. (4 characters)')
              .matches(/^[0-9]*$/, 'Piece Label Should Be A Numeric Value')
              .nullable()
              .required('This field is required'),
            otherwise: Yup.string().when('length', {
              is: () => productGroupCode === 'MiteredEdge',
              then: Yup.string()
                .matches(/^[A-Z][A-Z0-9]*$/, 'Piece Label Should Be An Alpha-Numerical Character')
                .max(4, 'Maximum character limit exceeded. (4 characters)')
                .nullable()
                .required('This field is required'),
              otherwise: Yup.string()
                .matches(/^[A-Z]*$/, 'Piece Label Should Be An Alphabetical Character')
                .max(4, 'Maximum character limit exceeded. (4 characters)')
                .nullable()
                .required('This field is required'),
            }),
          }),
          designName: Yup.mixed().required('This field is required'),
          pieceThickness: Yup.mixed().required('This field is required'),
          pieceFinish: Yup.mixed().required('This field is required'),
          length: Yup.number().nullable().required('This field is required'),
          width: Yup.number().when('pieceLabel', {
            is: () => productGroupCode === 'Backsplash',
            then: Yup.number().max(6.0, 'Maximum value is 6.0').required('This field is required'),
            otherwise: Yup.number().nullable().required('This field is required'),
          }),
          seamlessLLength: Yup.string().when('length', {
            is: () => includesL,
            then: Yup.string().required('This field is required'),
            otherwise: Yup.string(),
          }),
          seamlessLWidth: Yup.string().when('length', {
            is: () => includesL,
            then: Yup.string().required('This field is required'),
            otherwise: Yup.string(),
          }),
        })}>
        {(props) => {
          return (
            <Form>
              {editItem && (
                <Row className="w-100">
                  <Col xs={4}>
                    <CambriaSelect
                      label="PRODUCT TYPE"
                      name="productType"
                      disabled={true}
                      items={[]}
                      defaultValue={editItem.mainPiece}
                      displayValue="productGroupCode"
                      placeholder="Product Type"
                    />
                  </Col>
                </Row>
              )}
              <Row className="w-100">
                {isEdit || productGroupCode === 'Backsplash' || productGroupCode === 'MiteredEdge' ? (
                  <></>
                ) : (
                  <Col xs={4}>
                    <CambriaSelect
                      required
                      defaultValue={pieceModel.selectedFabricatedApplication}
                      name="productApplication"
                      label="PRODUCT APPLICATIONS"
                      items={productApplicationOptions}
                      displayValue="name"
                      placeholder="Product Applications"
                      onChange={(value: {name: string; minimumUomQuantity: number}) => {
                        handlePieceModelChange('selectedFabricatedApplication', value);
                        numberChanged(pieceModel.dimension2 || 0, pieceModel.dimension1 || 0, value.minimumUomQuantity);
                        numberChanged(
                          pieceModel.dimension4 || 0,
                          pieceModel.dimension3 || 0,
                          value.minimumUomQuantity,
                          true
                        );
                      }}
                    />
                  </Col>
                )}
                <Col xs={4} className={`${isDuplicateItem && !pieceModel.pieceLabel && 'highlight-red'}`}>
                  <CambriaInput
                    required
                    uppercase
                    data-testid="piece-label-input"
                    name="pieceLabel"
                    label="PIECE LABEL"
                    placeholder="Piece Label"
                    onChange={(e: any) => handlePieceModelChange('pieceLabel', e.target.value)}
                  />
                </Col>
              </Row>
              <Row className="w-100">
                <Col xs={productGroupCode === 'Backsplash' ? 3 : 4}>
                  <CambriaSelect
                    required
                    name="designName"
                    label="DESIGN NAME"
                    defaultValue={pieceModel.selectedDesign}
                    items={availableDesigns}
                    displayValue="designDescription"
                    onChange={(value: any) => {
                      let pieceModelCopy = {...pieceModel};
                      pieceModelCopy.edgeProfiles = [];
                      pieceModelCopy.finish = null;
                      pieceModelCopy.thickness = null;
                      pieceModelCopy.selectedDesign = value;
                      setPieceModel(pieceModelCopy);
                      setAddedEdgeProfiles([]);
                      setDesignHasBeenChanged(true);
                    }}
                    placeholder="Design Name"
                  />
                </Col>
                {productGroupCode === 'Backsplash' && (
                  <Col xs={3}>
                    <CambriaSelect
                      required
                      name="thicknessMatch"
                      defaultValue={initialFormValues?.mainPiece.match || duplicatePieceModel?.match}
                      label="FABRICATED THICKNESS MATCH"
                      displayValue="name"
                      items={[{name: 'Unmatch'}, {name: 'Match'}]}
                      placeholder="Fabricated Thickness Match"
                      onChange={(item: any) => handlePieceModelChange('match', item.name)}
                    />
                  </Col>
                )}
                <Col xs={productGroupCode === 'Backsplash' ? 3 : 4}>
                  <FabricationPieceThicknessSelect
                    selectedDesign={pieceModel.selectedDesign?.designDescription}
                    selectedProductApplication={pieceModel.selectedFabricatedApplication?.name}
                    selectedProductType={pieceModel.productGroupCode}
                    defaultValue={pieceModel.thickness}
                    onChange={(option: {name: string}) => {
                      handlePieceModelChange('thickness', option.name);
                      setAddedEdgeProfiles([]);
                      filterEdgeProfileItems(allEdgeProfiles, option.name, pieceModel);
                    }}
                  />
                </Col>
                <Col xs={productGroupCode === 'Backsplash' ? 3 : 4}>
                  <FabricationPieceFinishSelect
                    selectedDesign={pieceModel.selectedDesign?.designDescription}
                    defaultValue={pieceModel.finish}
                    onChange={(finish) => handlePieceModelChange('finish', finish)}
                  />
                </Col>
              </Row>
              <Row className="w-100">
                <Col xs={3}>
                  <CambriaInput
                    type="number"
                    required
                    roundNumber={true}
                    data-testid="piece-length-input"
                    name="length"
                    label="LENGTH"
                    onChange={(e: any) => {
                      numberChanged(
                        e.target.value,
                        pieceModel.dimension1 || 0,
                        pieceModel.selectedFabricatedApplication?.minimumUomQuantity
                      );
                      handlePieceModelChange('dimension2', parseFloat(e.target.value));
                    }}
                    placeholder="Length in Inches"
                    disabled={recalculatingArea}
                  />
                </Col>
                <Col xs={3}>
                  <CambriaInput
                    type="number"
                    required
                    roundNumber={true}
                    name="width"
                    label={productGroupCode === 'Backsplash' ? 'HEIGHT' : 'WIDTH'}
                    onChange={(e: any) => {
                      numberChanged(
                        pieceModel.dimension2 || 0,
                        e.target.value,
                        pieceModel.selectedFabricatedApplication?.minimumUomQuantity
                      );
                      handlePieceModelChange('dimension1', parseFloat(e.target.value));
                    }}
                    placeholder={`${productGroupCode === 'Backsplash' ? 'Height' : 'Width'} in Inches`}
                    disabled={recalculatingArea}
                  />
                </Col>
                <Col xs={3}>
                  <CambriaInput
                    disabled
                    name="squareFootage"
                    label={productGroupCode === 'Backsplash' ? 'LINEAR FEET' : 'SQUARE FOOTAGE'}
                    defaultValue={`${squareFootage ? squareFootage.toFixed(2) : '0.00'} ${
                      productGroupCode === 'Backsplash' ? 'LF' : 'sqft'
                    }`}
                    placeholder="L x W"
                  />
                </Col>
                {productGroupCode !== 'Backsplash' && (
                  <Col xs={3} className="d-flex align-items-center">
                    <div className="checkbox">
                      <input
                        id="includes-l-checkbox"
                        type="checkbox"
                        checked={includesL}
                        onChange={() => {
                          let tempPieceModel = {...pieceModel};
                          tempPieceModel.dimension3 = null;
                          tempPieceModel.dimension4 = null;
                          setPieceModel(tempPieceModel);
                          setIncludesL(!includesL);
                        }}
                      />
                      <label
                        className="checkbox-label d-flex flex-column align-items-start justify-content-center"
                        htmlFor="includes-l-checkbox">
                        Product include a Seamless L
                      </label>
                    </div>
                  </Col>
                )}
              </Row>
              {includesL && (
                <>
                  <Row className="w-100">
                    <Col xs={12} className="mb-1 seamlessL-header">
                      Seamless L Dimensions
                    </Col>
                  </Row>
                  <Row className="w-100 seamlessL-dimensions-container">
                    <Col xs={3}>
                      <CambriaInput
                        type="number"
                        required
                        roundNumber={true}
                        name="seamlessLLength"
                        label="LENGTH"
                        onChange={(e: any) => {
                          numberChanged(
                            e.target.value,
                            pieceModel.dimension3 || 0,
                            pieceModel.selectedFabricatedApplication?.minimumUomQuantity,
                            true
                          );
                          handlePieceModelChange('dimension4', parseFloat(e.target.value));
                        }}
                        placeholder="Length in Inches"
                        disabled={recalculatingArea}
                      />
                    </Col>
                    <Col xs={3}>
                      <CambriaInput
                        type="number"
                        required
                        roundNumber={true}
                        name="seamlessLWidth"
                        label="WIDTH"
                        onChange={(e: any) => {
                          numberChanged(
                            pieceModel.dimension4 || 0,
                            e.target.value,
                            pieceModel.selectedFabricatedApplication?.minimumUomQuantity,
                            true
                          );
                          handlePieceModelChange('dimension3', parseFloat(e.target.value));
                        }}
                        placeholder="Width in Inches"
                        disabled={recalculatingArea}
                      />
                    </Col>
                    <Col xs={3}>
                      <CambriaInput
                        disabled
                        name="seamlessLSquareFootage"
                        label="SQUARE FOOTAGE"
                        defaultValue={`${(Math.round(seamlessLSquareFootage * 100) / 100).toString()} sqft`}
                      />
                    </Col>
                  </Row>
                </>
              )}
              <RenderEdgeProfiles edgeProfiles={addedEdgeProfiles} />
              {addedEdgeProfiles.length < 8 && (
                <EdgeProfileForm
                  editItem={editItem}
                  key={pieceModel.edgeProfiles.length}
                  filteredEdgeProfiles={filteredEdgeProfiles}
                  index={pieceModel.edgeProfiles.length}
                  handleChangeToPieceModelFunction={addEdgeProfileToPieceModel}
                  removeEdgePieceFunction={undefined}
                  canRemoveEdgeProfile={false}
                />
              )}

              <Row className="w-100 d-flex justify-content-end mt-4 mr-0">
                <Col xs={6} className="d-flex justify-content-around pr-0">
                  <CambriaButton disabled={formIsSavingOrUpdating} variant="transparent" onClick={onCancel}>
                    CANCEL
                  </CambriaButton>
                  <CambriaButton
                    disabled={
                      pieceModel.edgeProfiles.length === 0 ||
                      formIsSavingOrUpdating ||
                      !props.isValid ||
                      !selectFieldsCompleted ||
                      edgeProfileIsBeingAdded ||
                      recalculatingArea
                    }
                    variant="primary"
                    type="submit"
                    data-testid="submit-piece-button"
                    onClick={() => {
                      editItem ? updatePiece(false) : savePiece(false, false);
                    }}>
                    {formIsSavingOrUpdating && (
                      <Icon
                        className="save-piece-spinner"
                        size="20"
                        weight="600"
                        icon="fa fa-spinner fa-spin"
                        disabled
                      />
                    )}
                    SAVE PIECE
                  </CambriaButton>
                  <CambriaButton
                    disabled={
                      pieceModel.edgeProfiles.length === 0 ||
                      formIsSavingOrUpdating ||
                      !props.isValid ||
                      !selectFieldsCompleted ||
                      edgeProfileIsBeingAdded ||
                      recalculatingArea
                    }
                    className="save-and-duplicate-btn"
                    variant="primary"
                    onClick={saveAndDuplicatePiece}>
                    {saveAndDuplicateLoading && (
                      <Icon
                        className="save-piece-spinner"
                        size="20"
                        weight="600"
                        icon="fa fa-spinner fa-spin"
                        disabled
                      />
                    )}
                    SAVE AND DUPLICATE
                  </CambriaButton>
                </Col>
              </Row>
            </Form>
          );
        }}
      </Formik>
    );
  }
};

export default FabricationPieceForm;
