import styled from 'styled-components';

const PriceAdjustmentsContainer = styled.div`
  .priceAdjustments-box {
    background-color: #e9e9e9 !important;
    border: none !important;
    padding: 30px;

    .price-adjustment-header-text {
      font-weight: 300 !important;
    }

    .priceAdjustment-item {
      padding: 20px 0px 0px 0px !important;
      position: relative;
      border: none !important;
      margin-bottom: 20px;

      .remove-price-adjustment-button {
        color: #8c8c8c;
        border: 1px solid #c8c8c8;
        background-color: #f8f8f8;
        transition: all ease-in-out 0.5s;

        &:hover {
          color: #c8c8c8;
        }
        span {
          color: #8c8c8c;
        }
      }

      @media (max-width: 960px) {
        .remove-price-adjustment-button {
          margin-top: 20px;
        }
      }

      .price-adjustment-name-text {
        font-weight: bolder;
      }
    }
  }
`;

export default PriceAdjustmentsContainer;
