import {Formik} from 'formik';
import * as Yup from 'yup';
import React, {useCallback, useEffect, useState} from 'react';
import {Col, Form, Row} from 'react-bootstrap';
import {toast} from 'react-toastify';
import {selectCurrentUser} from '../../../../../features/auth/slice/authentication.slice';
import {addBatchCartItems, getBillToSiteByOperatingUnitCode} from '../../../../../features/cart/service/cart.service';
import {selectActiveCart} from '../../../../../features/cart/slice/cart.slice';
import {
  selectCurrentCustomer,
  selectCurrentCustomerBillToSites,
  selectCurrentCustomerShipToSites,
} from '../../../../../features/customer/slice/customer.slice';
import {
  hideFullscreenLoader,
  showFullscreenLoader,
} from '../../../../../features/fullscreenLoader/slice/fullscreenLoader.slice';
import {selectUserActions} from '../../../../../features/permission/slice/permission.slice';
import {getSingleItemPrice} from '../../../../../features/pricing/service/pricing.service';
import {Item} from '../../../../../features/productCatalog/IProductCatalogState';
import {getStoreSizeSort} from '../../../../../features/productCatalog/service/productCatalog.service';
import {
  selectAvailableItemsToAdd,
  selectSavedAttrs,
  selectTempCartItemArray,
  selectTempCartItemArrayTotal,
  setAvailableItemsToAdd,
  setSavedAttrs,
  setTempCartItemArray,
  setTempCartItemArrayTotal,
  updateAvailableItemsToAddItem,
  updateTempCartItemArrayItem,
} from '../../../../../features/productCatalog/slice/productCatalog.slice';
import {getCartItemsProductType} from '../../../../../features/productType/service/productType.service';
import {selectActiveProgram, selectAvailablePrograms} from '../../../../../features/salesforce/slice/salesforce.slice';
import {
  getShipFromWarehouseId,
  ListOfSampleWarehouses,
} from '../../../../../features/warehouse/service/warehouse.service';
import {getOperatingUnitCodeByProductType} from '../../../../../features/warehouse/service/warehouse.service';
import CambriaButton from '../../../../../Framework/Components/CambriaButton';
import {camelCaseFilter} from '../../../../../Framework/Services/filters.service';
import {getForage, setForage} from '../../../../../Framework/Services/localForage';
import {useAppDispatch, useTypedSelector} from '../../../../../hooks/store';
import {hasPermission} from '../../../../../store/permission/permission.service';
import {QuickPage} from './Quick.styled';
import {ISalesforceAvailablePrograms} from '../../../../../features/salesforce/ISalesforceState';
import {getCartItemByItemNumber} from '../../../../../features/cart/cartItem/service/cartItem.service';
import CambriaSelect from '../../../../../Framework/Components/CambriaSelect/CambriaSelect.component';
import {pushAddToCartInformationToDataLayer} from '../../../../../features/analytics/service/analytics.service';
import {selectCalculateCartDiscountsInBackend} from '../../../../../features/environment/slice/environment.slice';
import {useCalculateStandardDiscounts} from '../../../../../features/cart/hooks/useCalculateStandardDiscounts';

export default function Quick({product}: any) {
  const dispatch = useAppDispatch();

  const getSlug = (productType: string): string => {
    let slug = camelCaseFilter(productType);
    if (slug === 'slab') {
      slug = 'slabs';
    }
    return slug;
  };

  const getLocalForageKeyByProductType = (productType: string): string => {
    if (productType === 'Samples') {
      return 'quickSampleItems';
    }

    if (productType === 'Slab') {
      return 'quickSlabItems';
    }

    return '';
  };

  const buildFormObject = (formAttributes: any) => {
    let newForm: any = {
      initialValues: {},
      validationSchema: {},
    };

    for (const attrs of formAttributes) {
      newForm.initialValues[attrs.name] = '';
      newForm.validationSchema[attrs.name] = Yup.mixed().required('This field is required');
    }

    return newForm;
  };

  let customer = useTypedSelector(selectCurrentCustomer);
  let savedAttrs: any = useTypedSelector(selectSavedAttrs);
  let cart: any = useTypedSelector(selectActiveCart);
  let shipToSites: any = useTypedSelector(selectCurrentCustomerShipToSites);
  let billToSites: any = useTypedSelector(selectCurrentCustomerBillToSites);
  let program = useTypedSelector(selectActiveProgram);
  let user: any = useTypedSelector(selectCurrentUser);
  let actions = useTypedSelector(selectUserActions);
  let availableItemsToAdd: any = useTypedSelector(selectAvailableItemsToAdd);
  let tempCartItemArray: any = useTypedSelector(selectTempCartItemArray);
  let tempCartItemArrayTotal: any = useTypedSelector(selectTempCartItemArrayTotal);
  const availablePrograms: ISalesforceAvailablePrograms | null = useTypedSelector(selectAvailablePrograms);
  const calculateDiscountsInBackend = useTypedSelector(selectCalculateCartDiscountsInBackend);
  const calculateStandardDiscounts = useCalculateStandardDiscounts();

  let [slug] = useState(getSlug(product.productType));
  let [localForageKey] = useState(getLocalForageKeyByProductType(product.productType));
  let [formikFormValues]: any = useState(buildFormObject(product.quickOrderAttributes));
  let [hasAlreadyInitialized, setHasAlreadyInitialized] = useState(false);
  let [isInitializing, setIsInitializing] = useState(false);
  let [erpCustomerId]: any = useState(customer && customer.erpCustomerId ? customer.erpCustomerId.toString() : null);
  let [customerClass]: any = useState(customer && customer.class ? customer.class : null);
  let [operatingUnitCode, setOperatingUnitCode] = useState(null);
  let [shipFromWarehouseId, setShipFromWarehouseId]: any = useState(null);
  let [siteUseId, setSiteUseId]: any = useState(null);
  let [allAttributes, setAllAttributes] = useState([]);
  let [attributesDataDictionary, setAttributesDataDictionary]: any = useState({});
  let [sortingAttributes] = useState([{name: 'Design'}]);
  let [warehouseList] = useState(ListOfSampleWarehouses());
  let [warehousePhoneNumber, setWarehousePhoneNumber]: any = useState(null);
  let [warehouseIs231, setWarehouseIs231] = useState(false);

  const sortItemsByAttributes = useCallback(
    (items: Item[], additionalSortingAttributes: {name: string}[]) => {
      const attributesToSortBy = JSON.parse(JSON.stringify(sortingAttributes));
      attributesToSortBy.push(...additionalSortingAttributes);
      return items.sort((item1, item2) => {
        const attrCount = attributesToSortBy.length;

        for (let indx = 0; indx < attrCount; indx++) {
          const attr = attributesToSortBy[indx];
          const item1Attr: any = item1.attributes.find((x) => x.name.indexOf(attr.name) >= 0);
          const item2Attr: any = item2.attributes.find((x) => x.name.indexOf(attr.name) >= 0);

          if (!item1Attr && !item2Attr) {
          } else if (!item1Attr && item2Attr) {
            return -1;
          } else if (item1Attr && !item2Attr) {
            return 1;
          } else if (item1Attr.value < item2Attr.value) {
            return -1;
          } else if (item1Attr.value > item2Attr.value) {
            return 1;
          }
        }

        return 0;
      });
    },
    [sortingAttributes]
  );

  const setAttributes = (product: any): any => {
    const attributes: Array<any> = [];
    let tempProduct = JSON.parse(JSON.stringify(product));
    if (tempProduct.quickOrderAttributes) {
      tempProduct.quickOrderAttributes.sort(function (a: any, b: any) {
        if (a.sequence < b.sequence) {
          return -1;
        }
        if (a.sequence > b.sequence) {
          return 1;
        }
        return 0;
      });
      for (const prodAttr of tempProduct.quickOrderAttributes) {
        if (prodAttr && prodAttr.usageType === 'select') {
          attributes.push(prodAttr);
        }
      }
    }
    return attributes;
  };

  const checkAttributes = useCallback(
    (initialAttributesDataDictionary: any): boolean => {
      let result = true;
      if (product.items) {
        for (const key of Object.keys(initialAttributesDataDictionary)) {
          if (
            !initialAttributesDataDictionary[key].selectedOption ||
            initialAttributesDataDictionary[key].selectedOption.value === ''
          ) {
            result = false;
            break;
          }
        }
      }

      return result;
    },
    [product]
  );

  const sortIndexDbItems = (initialAvailableItemsToAdd: any, initialTempCartItemArray: any) => {
    for (const cartItem of initialTempCartItemArray) {
      const itemIndex = initialAvailableItemsToAdd.findIndex(function (availableItem: any) {
        return availableItem.itemNumber === cartItem.itemNumber;
      });
      if (itemIndex !== -1) {
        initialAvailableItemsToAdd[itemIndex].selectedItems++;
      }
    }
  };

  const checkReservableQuantity = useCallback(
    (quantities: any, warehouse: any) => {
      let reservableAmount = 0;

      if (quantities && warehouse) {
        const reservableQuantityArray = quantities.filter((x: any) => x.organizationCode === warehouse);
        const selectedWarehouse = warehouseList.find((x) => x.warehouseCode === warehouse);
        setWarehousePhoneNumber(selectedWarehouse ? selectedWarehouse.phone : '');

        if (reservableQuantityArray.length > 0 && reservableQuantityArray[0].organizationCode === '231') {
          setWarehouseIs231(true);
          for (const quantity of reservableQuantityArray) {
            if (quantity.subInventory !== 'SMP') {
              reservableAmount += quantity.qtyReservable;
            }
          }
        } else {
          setWarehouseIs231(false);
          for (const quantity of reservableQuantityArray) {
            if (quantity.subInventory === 'SMP') {
              reservableAmount = quantity.qtyReservable;
            }
          }
        }
      }

      return reservableAmount;
    },
    [warehouseList]
  );

  const getAllItemsToAddToCart = useCallback(
    (
      initialAttributesDataDictionary: any,
      initialAllAttributes: any,
      initialShipFromWarehouseId: any
    ): {
      itemNumber: string;
      description: string;
      selectedItems: number;
      hasCorrectWarehouse: boolean;
    }[] => {
      let itemsToShow = product.items;
      Object.keys(initialAttributesDataDictionary).forEach((key) => {
        const selectedOption = initialAttributesDataDictionary[key].selectedOption;
        itemsToShow = itemsToShow.filter((x: any) =>
          x.attributes.some((y: any) => y.name === selectedOption.name && y.value === selectedOption.value)
        );
      });
      itemsToShow = sortItemsByAttributes(itemsToShow, initialAllAttributes);
      return itemsToShow.map((x: any) => {
        const hasCorrectWarehouse = x.organizations
          ? x.organizations.some((org: any) => org.erpOrganizationCode === initialShipFromWarehouseId)
          : false;

        const reservableAmount = checkReservableQuantity(x.quantities, initialShipFromWarehouseId);

        return {
          itemNumber: x.itemNumber,
          description: x.description,
          selectedItems: 0,
          hasCorrectWarehouse: hasCorrectWarehouse,
          reservableAmount: reservableAmount,
        };
      });
    },
    [checkReservableQuantity, product, sortItemsByAttributes]
  );

  const checkCompleteAttributes = useCallback(
    (
      initialAttributesDataDictionary: any,
      initialTempCartItemArray: any,
      initialAllAttributes: any,
      initialShipFromWarehouseId: any
    ) => {
      let tempAvailableItemsToAdd: any = [];
      if (checkAttributes(initialAttributesDataDictionary)) {
        tempAvailableItemsToAdd = getAllItemsToAddToCart(
          initialAttributesDataDictionary,
          initialAllAttributes,
          initialShipFromWarehouseId
        );
        sortIndexDbItems(tempAvailableItemsToAdd, initialTempCartItemArray);
      }

      dispatch(setAvailableItemsToAdd(tempAvailableItemsToAdd));
    },
    [checkAttributes, getAllItemsToAddToCart, dispatch]
  );

  const getAddonTemplate = useCallback(
    (attr: any, item: any) => {
      let addOnTemplate;
      if (attr.name === 'Size' && slug === 'slabs') {
        addOnTemplate = 'Sq Ft';
      }
      if (
        attr.name === 'Size' &&
        (slug === 'tile' || (slug === 'samples' && item.description.indexOf('Tile') !== -1))
      ) {
        addOnTemplate = 'in.';
      }
      return addOnTemplate;
    },
    [slug]
  );

  const extractAttributeData = useCallback(
    (
      attr: any
    ): {
      addOnTemplate: string;
      sortBy: string;
      attrSelections: any[];
      attrOptions: any[];
    } => {
      const attrOptions: any[] = [];
      const attrSelections: any[] = [];
      let addOnTemplate: any = '';
      let sortBy = '';
      let tempProduct = JSON.parse(JSON.stringify(product));
      for (const item of tempProduct.items) {
        if (!item.attributes) {
          return {addOnTemplate, sortBy, attrSelections, attrOptions};
        }
        for (const itemAttr of item.attributes) {
          if (attr.name === itemAttr.name && itemAttr.value !== null) {
            fixTrueValues(itemAttr);
            itemAttr.usageType = attr.usageType;
            const currentIndex = attrOptions.indexOf(itemAttr.value);
            if (currentIndex === -1) {
              attrOptions.push(itemAttr.value);
              attrSelections.push({
                name: attr.name,
                value: itemAttr.value,
                items: [Object.assign({}, ...item.attributes.map((x: any) => ({[x.name]: x.value})))],
              });
            } else {
              attrSelections[currentIndex].items.push(
                Object.assign({}, ...item.attributes.map((x: any) => ({[x.name]: x.value})))
              );
            }
          }
          addOnTemplate = getAddonTemplate(attr, item);
          if (attr.name === 'Size' && slug === 'store') {
            sortBy = getStoreSizeSort(attrSelections);
          }
        }
      }
      return {addOnTemplate, sortBy, attrSelections, attrOptions};
    },
    [getAddonTemplate, product, slug]
  );

  const fixTrueValues = (itemAttr: any) => {
    if (itemAttr.value === 'TRUE') {
      itemAttr.value = 'True';
    }
  };

  const filterAttributesOptions = useCallback(
    (
      selectedAttribute: any,
      initialAllAttributes: any,
      initialAttributesDataDictionary: any,
      initialTempCartItemArray: any,
      initialShipFromWarehouseId: any
    ) => {
      let tempAttributesDataDictionary = JSON.parse(JSON.stringify(initialAttributesDataDictionary));
      if (!selectedAttribute) {
        setAttributesDataDictionary(tempAttributesDataDictionary);
        return tempAttributesDataDictionary;
      }

      tempAttributesDataDictionary[selectedAttribute.name].selectedOption = selectedAttribute;

      const currentAttribute = initialAllAttributes.filter(isNameEqual(selectedAttribute.name))[0];
      let currentAttributeIndex = initialAllAttributes.indexOf(currentAttribute);
      for (
        let nextAttributeIndex = ++currentAttributeIndex;
        nextAttributeIndex < initialAllAttributes.length;
        nextAttributeIndex++
      ) {
        const nextAttributeData = tempAttributesDataDictionary[initialAllAttributes[nextAttributeIndex].name];
        nextAttributeData.currentAvailableOptions = nextAttributeData.allOptions;
        if (!nextAttributeData.disabled) {
          if (savedAttrs && savedAttrs[slug]) {
            Object.keys(savedAttrs[slug]).forEach((key) => {
              if (
                tempAttributesDataDictionary[key] &&
                tempAttributesDataDictionary[key].sequence <= currentAttribute.sequence
              ) {
                const selected = tempAttributesDataDictionary[key].selectedOption;
                if (selected) {
                  nextAttributeData.currentAvailableOptions = nextAttributeData.currentAvailableOptions.filter(
                    optionsHasAttribute(selected)
                  );
                }
              }
            });
          }

          if (
            nextAttributeData.selectedOption !== undefined &&
            nextAttributeData.selectedOption !== null &&
            nextAttributeData.currentAvailableOptions.filter(isValueEqual(nextAttributeData.selectedOption.value))
              .length <= 0
          ) {
            nextAttributeData.selectedOption = undefined;
            checkCompleteAttributes(
              initialAttributesDataDictionary,
              initialTempCartItemArray,
              initialAllAttributes,
              initialShipFromWarehouseId
            );
          }
        }
      }
      setAttributesDataDictionary(tempAttributesDataDictionary);
      return tempAttributesDataDictionary;
    },
    [checkCompleteAttributes, savedAttrs, slug]
  );

  const optionsHasAttribute = (attribute: any) => {
    return (element: any) => element.items.some((item: any) => item[attribute.name] === attribute.value);
  };

  const isNameEqual = (name: any) => {
    return (element: any) => element.name === name;
  };

  const isValueEqual = (value: any) => {
    return (element: any) => element.value === value;
  };

  const removeFromCartItemArray = async (itemNumber: string, index: number) => {
    if (availableItemsToAdd[index].selectedItems !== 0) {
      const cartItemIndex = tempCartItemArray.findIndex(function (cartItem: any) {
        return cartItem.itemNumber === itemNumber;
      });
      let tempTempCartItemArray = JSON.parse(JSON.stringify(tempCartItemArray));
      tempTempCartItemArray.splice(cartItemIndex, 1);
      dispatch(setTempCartItemArray(tempTempCartItemArray));

      await setForage(localForageKey, {
        cartId: cart.id,
        quickSampleOrderCartItemArray: tempTempCartItemArray,
      });

      let tempAvailableItemsToAdd = JSON.parse(JSON.stringify(availableItemsToAdd));
      tempAvailableItemsToAdd[index].selectedItems--;
      dispatch(setAvailableItemsToAdd(tempAvailableItemsToAdd));
      dispatch(setTempCartItemArrayTotal(tempCartItemArrayTotal - 1));
    }
  };

  const addToCartItemArray = async (itemNumber: string, index: number) => {
    if (!availableItemsToAdd[index].hasCorrectWarehouse) {
      return;
    }
    const selectedItem = product.items.find((x: any) => x.itemNumber === itemNumber);
    if (!selectedItem) {
      toast.error('The attributes selected are not a valid combination');
    }

    let singleItemPrice: any;
    try {
      singleItemPrice = await getSingleItemPrice(
        erpCustomerId,
        shipFromWarehouseId,
        program.code,
        itemNumber,
        siteUseId,
        customerClass,
        dispatch
      );
    } catch (e: any) {
      if (e.status === 'Failure') {
        let tempAvailableItemsToAdd = JSON.parse(JSON.stringify(availableItemsToAdd));
        tempAvailableItemsToAdd[index].selectedItems--;
        dispatch(updateAvailableItemsToAddItem({index: index, item: tempAvailableItemsToAdd[index]}));
        dispatch(setTempCartItemArrayTotal(tempCartItemArrayTotal - 1));

        toast.error(
          'Current item does not have a price configured. Please contact the Cambria Business Office to have this corrected.'
        );
      }
    }

    const quantity = 1;

    const cartItem: any = getCartItemByItemNumber(
      itemNumber,
      product,
      shipFromWarehouseId,
      singleItemPrice,
      quantity,
      program.code,
      program.name
    );
    cartItem.operatingUnitCode = operatingUnitCode;
    cartItem.siteUseId = siteUseId;

    dispatch(updateTempCartItemArrayItem(cartItem));
  };

  const addToCart = async () => {
    dispatch(showFullscreenLoader({message: ''}));
    try {
      let tempTempCartItemArray = JSON.parse(JSON.stringify(tempCartItemArray));
      const response = await addBatchCartItems(
        tempTempCartItemArray,
        cart,
        customer,
        shipToSites,
        billToSites,
        user,
        program,
        dispatch,
        availablePrograms,
        calculateDiscountsInBackend
      );

      try {
        await calculateStandardDiscounts();
      } catch (e) {
        console.error('Error updating cart', e);
      }

      let msg = `Your list of items was added to cart.`;
      if (response === 'Some of the items must ship from MN Samples.') {
        msg += response;
      }

      for (let cartItem of tempTempCartItemArray) {
        const item = product.items.find((x: any) => x.itemNumber === cartItem.itemNumber);
        (cartItem as any).designName =
          item.attributes.find((attr: any) => attr.name === 'Design Name' || attr.name === 'Color/Design')?.value || '';
        (cartItem as any).thickness = item.attributes.find((attr: any) => attr.name === 'Thickness')?.value || '';
        (cartItem as any).finish = item.attributes.find((attr: any) => attr.name === 'Finish')?.value || '';
        (cartItem as any).size = item.attributes.find((attr: any) => attr.name === 'Size')?.value || '';
        (cartItem as any).displayName = item.productDisplayName;
      }

      pushAddToCartInformationToDataLayer(
        true,
        tempTempCartItemArray,
        tempTempCartItemArray[0].shipFromWarehouseId && tempTempCartItemArray[0].shipFromWarehouseId.CurrencyCode
          ? tempTempCartItemArray[0].shipFromWarehouseId.CurrencyCode
          : '',
        tempTempCartItemArray[0].productType
      );

      toast.success(msg);
      dispatch(setTempCartItemArrayTotal(0));
      dispatch(setTempCartItemArray([]));
      setForage(localForageKey, {
        cartId: cart.id,
        quickSampleOrderCartItemArray: [],
      });
      let tempAvailableItemsToAdd = JSON.parse(JSON.stringify(availableItemsToAdd));
      for (let item of tempAvailableItemsToAdd) {
        item.selectedItems = 0;
      }
      dispatch(setAvailableItemsToAdd(tempAvailableItemsToAdd));
      dispatch(hideFullscreenLoader());
    } catch (e: any) {
      console.log(e);
      if (e === 'Cart already contains items of different product type.') {
        const productType = getCartItemsProductType(cart);
        toast.error(
          `Your shopping cart currently contains ${productType}` +
            ` products that cannot be combined with ${tempCartItemArray[0].productType}` +
            ` products. The items can be purchased separately once the ${productType}` +
            ` request is completed.`
        );
      } else {
        toast.error(e);
      }
      dispatch(hideFullscreenLoader());
    }
  };

  const onSelectAttribute = useCallback(
    (
      attribute: any,
      shouldCheckCompleteness: any = true,
      initialAllAttributes: any = allAttributes,
      initialAttributesDataDictionary: any = attributesDataDictionary,
      initialTempCartItemArray: any = tempCartItemArray,
      initialShipFromWarehouseId: any = shipFromWarehouseId
    ): void => {
      let tempSavedAttrs = JSON.parse(JSON.stringify(savedAttrs));
      tempSavedAttrs[slug][attribute.name] = attribute.value;
      dispatch(setSavedAttrs(tempSavedAttrs));

      const tempAttributesDataDictionary = filterAttributesOptions(
        attribute,
        initialAllAttributes,
        initialAttributesDataDictionary,
        initialTempCartItemArray,
        initialShipFromWarehouseId
      );
      if (shouldCheckCompleteness) {
        checkCompleteAttributes(
          tempAttributesDataDictionary,
          initialTempCartItemArray,
          initialAllAttributes,
          initialShipFromWarehouseId
        );
      }

      return tempAttributesDataDictionary;
    },
    [
      allAttributes,
      attributesDataDictionary,
      checkCompleteAttributes,
      filterAttributesOptions,
      shipFromWarehouseId,
      slug,
      tempCartItemArray,
      dispatch,
      savedAttrs,
    ]
  );

  const setInitialDefaultAttributes = useCallback(
    (
      initialAllAttributes: any,
      tempAttributesDataDictionary: any,
      initialTempCartItemArray: any,
      initialShipFromWarehouseId: any
    ) => {
      for (const finish of tempAttributesDataDictionary.Finish.currentAvailableOptions) {
        if (finish.value === 'Polished') {
          tempAttributesDataDictionary.Finish.selectedOption = finish;
          tempAttributesDataDictionary = onSelectAttribute(
            finish,
            undefined,
            initialAllAttributes,
            tempAttributesDataDictionary,
            initialTempCartItemArray,
            initialShipFromWarehouseId
          );
        }
      }

      for (const size of tempAttributesDataDictionary.Size.currentAvailableOptions) {
        if (size.value === '3x3') {
          tempAttributesDataDictionary.Size.selectedOption = size;
          tempAttributesDataDictionary = onSelectAttribute(
            size,
            undefined,
            initialAllAttributes,
            tempAttributesDataDictionary,
            initialTempCartItemArray,
            initialShipFromWarehouseId
          );
        }
      }

      for (const thickness of tempAttributesDataDictionary.Thickness.currentAvailableOptions) {
        if (thickness.value === '1CM') {
          tempAttributesDataDictionary.Thickness.selectedOption = thickness;
          tempAttributesDataDictionary = onSelectAttribute(
            thickness,
            undefined,
            initialAllAttributes,
            tempAttributesDataDictionary,
            initialTempCartItemArray,
            initialShipFromWarehouseId
          );
        }
      }

      setAttributesDataDictionary(tempAttributesDataDictionary);
    },
    [onSelectAttribute]
  );

  const setUpAttributesData = useCallback(
    (
      attributes: any[],
      slug: string,
      product: any,
      initialTempCartItemArray: any,
      intialShipFromWarehouseId: any
    ): void => {
      let tempAttributesDataDictionary: any = {};
      if (attributes) {
        const savedAttributeSelections = [];
        for (const attr of attributes) {
          let attrOptions = [];
          let attrSelections = [];
          let selectedAttr;
          let sortBy = '';
          let addOnTemplate = '';
          const savedAttr = savedAttrs[slug][attr.name] || '';
          if (product.items) {
            ({addOnTemplate, sortBy, attrSelections, attrOptions} = extractAttributeData(attr));
          }
          if (attrSelections.length > 1) {
            attrSelections.sort((a, b) => {
              return a.value.localeCompare(b.value);
            });
          }

          if (attrSelections.length === 1) {
            selectedAttr = attrSelections[0];
            savedAttrs[slug][attr.name] = selectedAttr.value;
          } else {
            if (attrSelections) {
              for (const attrSelection of attrSelections) {
                if (attrSelection.value === savedAttr) {
                  selectedAttr = attrSelection;
                }
              }
            }
          }
          if (selectedAttr) {
            savedAttributeSelections.push(selectedAttr);
          }

          tempAttributesDataDictionary[attr.name] = {
            name: attr.name,
            currentAvailableOptions: attrSelections,
            allOptions: attrSelections,
            allOptionsValues: attrOptions,
            selectedOption: selectedAttr,
            disabled: attrSelections.length === 1,
            addOnTemplate,
            sortBy,
            error: false,
            errorMsg: '',
            sequence: attr.sequence,
          };
        }
        for (let index = 0; index < savedAttributeSelections.length; index++) {
          const shouldCheckCompleteness = index === savedAttributeSelections.length - 1;
          const element = savedAttributeSelections[index];
          onSelectAttribute(
            element,
            shouldCheckCompleteness,
            attributes,
            tempAttributesDataDictionary,
            initialTempCartItemArray,
            intialShipFromWarehouseId
          );
        }
      }
      return tempAttributesDataDictionary;
    },
    [extractAttributeData, onSelectAttribute, savedAttrs]
  );

  const init = useCallback(async (): Promise<void> => {
    setIsInitializing(true);

    const indexDbCartItemArray = await getForage(localForageKey);
    let initialTempCartItemArray: any = [];
    if (indexDbCartItemArray && cart && indexDbCartItemArray.cartId === cart.id) {
      initialTempCartItemArray = indexDbCartItemArray.quickSampleOrderCartItemArray || [];
    } else {
      setForage(localForageKey, {
        cartId: cart.id,
        quickSampleOrderCartItemArray: initialTempCartItemArray,
      });
    }

    if (!product || !product.items || product.items.length === 0) {
      toast.error('The product has no item(s).');
      return;
    }

    // only use primary no need to fallback so no shipFromWarehouseId
    const tempOperatingUnitCode = await getOperatingUnitCodeByProductType(product.productType, customer, shipToSites);
    setOperatingUnitCode(tempOperatingUnitCode);

    const tempShipFromWarehouseId = await getShipFromWarehouseId(
      product.productType,
      customer,
      shipToSites,
      cart,
      false
    );
    setShipFromWarehouseId(tempShipFromWarehouseId);

    const billToSite = await getBillToSiteByOperatingUnitCode(tempOperatingUnitCode, billToSites);

    setSiteUseId(billToSite.siteUseId);

    const tempAllAttributes = setAttributes(product);
    setAllAttributes(tempAllAttributes);
    const tempAttributesDataDictionary = setUpAttributesData(
      tempAllAttributes,
      slug,
      product,
      initialTempCartItemArray,
      tempShipFromWarehouseId
    );
    setInitialDefaultAttributes(
      tempAllAttributes,
      tempAttributesDataDictionary,
      initialTempCartItemArray,
      tempShipFromWarehouseId
    );
    dispatch(setTempCartItemArrayTotal(initialTempCartItemArray.length));
    dispatch(setTempCartItemArray(initialTempCartItemArray));
    setIsInitializing(false);
  }, [
    billToSites,
    cart,
    customer,
    product,
    setInitialDefaultAttributes,
    setUpAttributesData,
    shipToSites,
    slug,
    dispatch,
    localForageKey,
  ]);

  useEffect(() => {
    setForage(localForageKey, {
      cartId: cart.id,
      quickSampleOrderCartItemArray: tempCartItemArray,
    });
  }, [tempCartItemArray, cart.id, localForageKey]);

  useEffect(() => {
    if (!hasAlreadyInitialized) {
      setHasAlreadyInitialized(true);
      init();
    }
  }, [init, hasAlreadyInitialized]);

  return (
    <QuickPage>
      <section className="quick-product-details container">
        <div className=" product">
          {!isInitializing ? (
            <Formik
              enableReinitialize
              initialValues={formikFormValues.initialValues}
              validationSchema={Yup.object(formikFormValues.validationSchema)}
              onSubmit={() => {}}>
              {(props) => {
                return (
                  <Form id="productForm" noValidate>
                    <Col md={8} xs={12} className="col-md-offset-2  product-details">
                      <Row className="product-details-quick-attribute-select-row">
                        {allAttributes.map((attr: any, index: number) => {
                          return (
                            <Col
                              md={6}
                              xs={12}
                              key={attr.name + index + '-quick-select'}
                              className="quick-attribute-select-containers">
                              <CambriaSelect
                                name={attr.name}
                                placeholder={'Select ' + attr.description}
                                addOnTemplate={attributesDataDictionary[attr.name].addOnTemplate}
                                defaultValue={
                                  attributesDataDictionary &&
                                  attributesDataDictionary[attr.name] &&
                                  attributesDataDictionary[attr.name].selectedOption
                                    ? attributesDataDictionary[attr.name].selectedOption
                                    : ''
                                }
                                label={attr.description}
                                items={attributesDataDictionary[attr.name].currentAvailableOptions}
                                displayValue={'value'}
                                disabled={attributesDataDictionary[attr.name].disabled}
                                required={true}
                                onChange={onSelectAttribute}
                              />
                            </Col>
                          );
                        })}
                      </Row>

                      <div className="quick-batch-add-button-container">
                        <CambriaButton
                          className="btn btn-primary batch-add-button"
                          type="button"
                          onClick={() => {
                            addToCart();
                          }}
                          disabled={
                            tempCartItemArray.length === 0 || tempCartItemArrayTotal !== tempCartItemArray.length
                          }
                          variant="primary"
                          id="commerce-quick-add-to-cart">
                          {'ADD TOTAL ITEMS TO CART (' + tempCartItemArray.length + ')'}
                        </CambriaButton>
                      </div>
                      <div>
                        {availableItemsToAdd && availableItemsToAdd.length > 0 ? (
                          <Col xs={12}>
                            {availableItemsToAdd.map((item: any, index: number) => {
                              return (
                                <Row
                                  className={`item-row ${index % 2 === 0 ? 'alternating-grid-color-gray' : ''}`}
                                  key={item.description + index + '-quick-counters'}>
                                  <Col sm={5} xs={5} className=" quick-order-description-container">
                                    <span
                                      className={`quick-order-description ${
                                        !item.hasCorrectWarehouse ? 'disabled' : ''
                                      }`}>
                                      {item.description.replace('Sample Tile - ', '').toUpperCase()}{' '}
                                    </span>
                                  </Col>
                                  <Col sm={2} xs={2}>
                                    <span className="quick-order-availability-container">
                                      {' '}
                                      {hasPermission(
                                        'urn:csa:commerceui:productCatalog:viewReservalbeQuantities',
                                        actions
                                      ) &&
                                      product.productType !== 'Slab' &&
                                      item.reservableAmount > 10 &&
                                      item.selectedItems <= 20 &&
                                      item.selectedItems > 0 ? (
                                        <span className="quick-in-stock-message">
                                          <div className="tooltip-wrapper"></div>
                                          <div className="in-stock-message">AVAILABLE</div>
                                        </span>
                                      ) : (
                                        <div></div>
                                      )}
                                      {hasPermission(
                                        'urn:csa:commerceui:productCatalog:viewReservalbeQuantities',
                                        actions
                                      ) &&
                                      product.productType !== 'Slab' &&
                                      item.selectedItems > 20 ? (
                                        <span className="quick-limited-stock-message">
                                          <div className="tooltip-wrapper">
                                            <span className="tooltip">
                                              Due to quantity being ordered, please contact{' '}
                                              {warehouseIs231 ? 'CC' : 'your D'}C at
                                              {warehousePhoneNumber}
                                            </span>
                                          </div>
                                          <div className="limited-stock-message">
                                            CONTACT {warehouseIs231 ? 'CC' : 'D'}C{' '}
                                          </div>
                                        </span>
                                      ) : (
                                        <div></div>
                                      )}
                                      {hasPermission(
                                        'urn:csa:commerceui:productCatalog:viewReservalbeQuantities',
                                        actions
                                      ) &&
                                      product.productType !== 'Slab' &&
                                      item.reservableAmount <= 10 &&
                                      item.selectedItems > 0 &&
                                      item.selectedItems <= 20 ? (
                                        <div className="quick-limited-stock-message">
                                          <div className="tooltip-wrapper">
                                            <span className="tooltip">
                                              If the need for this item is urgent, call Samples Contact Center at (866)
                                              661-2580
                                            </span>
                                          </div>
                                          <div className="limited-stock-message">LOW STOCK</div>
                                        </div>
                                      ) : (
                                        <div></div>
                                      )}
                                    </span>
                                  </Col>
                                  <Col sm={5} xs={5}>
                                    <div className="quick-select-button-containers">
                                      <div
                                        className={`add-cart-item-btn ${!item.hasCorrectWarehouse ? 'disabled' : ''}`}
                                        data-testid={`quick-add-cart-item-btn-${index}`}
                                        onClick={async () => {
                                          if (item.hasCorrectWarehouse) {
                                            const tempItem = JSON.parse(JSON.stringify(item));
                                            tempItem.selectedItems++;
                                            dispatch(updateAvailableItemsToAddItem({index: index, item: tempItem}));
                                            dispatch(setTempCartItemArrayTotal(tempCartItemArrayTotal + 1));
                                            await addToCartItemArray(item.itemNumber, index);
                                          }
                                        }}
                                        id="commerce-cart-item-selector-plus-button">
                                        <span className="center-button-icons">+</span>
                                      </div>
                                    </div>
                                    <div className="quick-select-button-containers">
                                      <div className="number-of-items-selected-container">
                                        <span className="number-of-items-selected">{item.selectedItems}</span>
                                      </div>
                                    </div>
                                    <div className="quick-select-button-containers">
                                      <div
                                        className={`remove-cart-item-btn ${item.selectedItems === 0 ? 'disabled' : ''}`}
                                        data-testid={`quick-remove-cart-item-btn-${index}`}
                                        onClick={() => {
                                          if (item.selectedItems !== 0) {
                                            removeFromCartItemArray(item.itemNumber, index);
                                          }
                                        }}>
                                        <div>
                                          <span className="center-button-icons">-</span>
                                        </div>
                                      </div>
                                    </div>
                                  </Col>
                                </Row>
                              );
                            })}
                          </Col>
                        ) : (
                          <div></div>
                        )}
                      </div>
                    </Col>
                  </Form>
                );
              }}
            </Formik>
          ) : (
            <div></div>
          )}
        </div>
      </section>
    </QuickPage>
  );
}
