import React, {useEffect, useState} from 'react';
import {Col, Row} from 'react-bootstrap';
import {IOrderDetails, IWorkflowRejection} from '../../../../../../features/order/orderDetails/IOrderDetailsState';

const Rejections = ({order}: {order: IOrderDetails}) => {
  const [reasonsForRejection, setReasonsForRejection] = useState<Array<IWorkflowRejection>>([]);

  const isCadRejected = (rejection: IWorkflowRejection): boolean => {
    return rejection.statusCode === 'RejectedCAD';
  };

  const utcDateToLocalDate = (date: Date) => {
    return new Date(date).toLocaleDateString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
    });
  };

  useEffect(() => {
    if (reasonsForRejection.length === 0) {
      setReasonsForRejection(order.rejections);
    }
  }, [order, reasonsForRejection]);

  const renderReasons = (reasons: Array<string>) => {
    return reasons.map((reason, index) => {
      return (
        <div key={index}>
          {index + 1}. {reason}
        </div>
      );
    });
  };

  if (reasonsForRejection.length === 0) {
    return <></>;
  } else {
    return (
      <Col className="rejection-reasons-container">
        <Col>
          <Row className="rejection-reasons-header-row">
            <Col xs={3}>REJECTION</Col>
            <Col xs={3}>DATE</Col>
            <Col xs={3}>MESSAGE</Col>
            <Col xs={3}>REASONS</Col>
          </Row>
        </Col>
        <Col className="rejection-reasons-rows">
          {reasonsForRejection.map((rejection, index) => {
            return (
              <Col key={index} className="rejection-row">
                <Col xs={3}>{isCadRejected(rejection) ? 'CAD Revisions Needed' : 'Order Revisions Needed'}</Col>
                <Col xs={3}>{utcDateToLocalDate(rejection.createdAt)}</Col>
                <Col xs={3}>{isCadRejected(rejection) ? rejection.cadRejectMessage : rejection.poRejectMessage}</Col>
                <Col xs={3} className="reasons-col">
                  {isCadRejected(rejection)
                    ? renderReasons(rejection.cadRejectReasons)
                    : renderReasons(rejection.poRejectReasons)}
                </Col>
              </Col>
            );
          })}
        </Col>
      </Col>
    );
  }
};

export default Rejections;
