import styled from 'styled-components';
import {$gray30, $gray50} from '../../../../../Styled/variables';

export const AvailableCreditCardsContainer = styled.div`
  .table-headers {
    font-weight: 700;
    color: ${$gray30};
  }

  .credit-card-container {
    background-color: #e5e5e5;
    border-radius: 3px;
    font-weight: 600;
  }

  .credit-card-form-container {
    background: rgba(0, 0, 0, 0.04);
    border-radius: 3px;
    padding: 34px;
  }

  .add-card-button {
    color: ${$gray50};
    font-weight: 600;

    :hover {
      color: ${$gray30} !important;
      cursor: pointer;
    }
  }
`;
