import {createSlice} from '@reduxjs/toolkit';
import {CartState} from '../ICartState';
import {RootState} from '../../../store/store';

const initialState: CartState = {
  activeCart: null,
  savedItems: [],
  cartCalculationsComplete: true,
  disableRemoveButtonWhileRemoving: false,
  isMovingItemToSaveForLater: false,
  isMovingItemToCart: false,
  disableQuantityButtons: false,
};

export const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    setActiveCart: (state, action) => {
      state.activeCart = action.payload;
    },
    setCartDiscounts: (state, action) => {
      state.activeCart = action.payload;
    },
    updateCartItem: (state, action: any) => {
      state.activeCart = action.payload;
    },
    removeCartItem: (state, action: any) => {
      state.activeCart = action.payload;
    },
    addingCartItem: (state, action: any) => {
      state.activeCart = action.payload;
    },
    saveOrderDetailsToCart: (state, action: any) => {
      state.activeCart = action.payload;
    },
    applyDiscount: (state, action: any) => {
      state.activeCart = action.payload;
    },
    applyHeaderAdjustment: (state, action: any) => {
      state.activeCart = action.payload;
    },
    removeDiscount: (state, action: any) => {
      state.activeCart = action.payload;
    },
    removeHeaderAdjustment: (state, action: any) => {
      state.activeCart = action.payload;
    },
    addSavedItems: (state, action: any) => {
      state.savedItems = action.payload;
    },
    setSavedItems: (state, action: any) => {
      state.savedItems = action.payload;
    },
    setCartCalculationsComplete: (state, action: any) => {
      state.cartCalculationsComplete = action.payload;
    },
    setDisableRemoveButtonWhileRemoving: (state, action: any) => {
      state.disableRemoveButtonWhileRemoving = action.payload;
    },
    setIsMovingItemToSaveForLater: (state, action: any) => {
      state.isMovingItemToSaveForLater = action.payload;
    },
    setIsMovingItemToCart: (state, action: any) => {
      state.isMovingItemToCart = action.payload;
    },
    setDisableQuantityButtons: (state, action: any) => {
      state.disableQuantityButtons = action.payload;
    },
    setCartInitialState: (state: any) => {
      for (const key in initialState) {
        state[key] = (initialState as any)[key];
      }
    },
    removeActiveCart: (state) => {
      state.activeCart = null;
    },
  },
  extraReducers: {},
});

export const {
  setActiveCart,
  setCartDiscounts,
  updateCartItem,
  removeCartItem,
  addSavedItems,
  setSavedItems,
  addingCartItem,
  saveOrderDetailsToCart,
  applyDiscount,
  applyHeaderAdjustment,
  removeDiscount,
  removeHeaderAdjustment,
  setCartCalculationsComplete,
  setDisableRemoveButtonWhileRemoving,
  setIsMovingItemToSaveForLater,
  setIsMovingItemToCart,
  setDisableQuantityButtons,
  setCartInitialState,
  removeActiveCart,
} = cartSlice.actions;

export const selectActiveCart = (state: RootState) => state.cart.activeCart;
export const selectSavedItems = (state: RootState) => state.cart.savedItems;
export const selectCartCalculationsComplete = (state: RootState) => state.cart.cartCalculationsComplete;
export const selectDisableRemoveButtonWhileRemoving = (state: RootState) => state.cart.disableRemoveButtonWhileRemoving;
export const selectIsMovingItemToSaveForLater = (state: RootState) => state.cart.isMovingItemToSaveForLater;
export const selectIsMovingItemToCart = (state: RootState) => state.cart.isMovingItemToCart;
export const selectDisableQuantityButtons = (state: RootState) => state.cart.disableQuantityButtons;

export default cartSlice.reducer;
