import React from 'react';
import {formatOrderDate} from '../../../../../../Framework/Services/formatDate';
import {DisplayField} from '../../../../../../features/customer/ICustomerState';

export interface ReportRowProps {
  entry: any;
  tableColumns: DisplayField[];
  index: number;
}

const ReportRow = ({entry, tableColumns, index}: ReportRowProps) => {
  return (
    <tr key={index}>
      {tableColumns.map((column: DisplayField) => {
        if (column.code.toLowerCase().includes('date')) {
          return (
            <td key={`${entry.orderNumber ? entry.orderNumber : entry.jobNumber ? entry.jobNumber : ''}${column.code}`}>
              {entry[column.code] && formatOrderDate(new Date(entry[column.code]))}
            </td>
          );
        } else if (Array.isArray(entry[column.code])) {
          return (
            <td key={`${entry.orderNumber ? entry.orderNumber : entry.jobNumber ? entry.jobNumber : ''}${column.code}`}>
              {entry[column.code].map((item: any, arrayIndex: number) => {
                return (
                  <div
                    className="w-100"
                    key={`${index}-array-item-${arrayIndex}-${
                      entry.orderNumber ? entry.orderNumber : entry.jobNumber ? entry.jobNumber : ''
                    }${column.code}`}>
                    {item}
                  </div>
                );
              })}
            </td>
          );
        } else {
          return (
            <td key={`${entry.orderNumber ? entry.orderNumber : entry.jobNumber ? entry.jobNumber : ''}${column.code}`}>
              {entry[column.code]}
            </td>
          );
        }
      })}
    </tr>
  );
};

export default ReportRow;
