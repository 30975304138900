import React from 'react';
import {FullscreenLoaderContainer} from './FullscreenLoader.styled';

interface FullscreenLoaderProps {
  message?: string;
  shoppingCart?: boolean;
}

const FullscreenLoader = ({message, shoppingCart}: FullscreenLoaderProps) => {
  return (
    <FullscreenLoaderContainer>
      {message && (
        <div className="message">
          <span>{message}</span>
        </div>
      )}
      <div className="loader">
        <div className={shoppingCart ? `shopping-cart-icon` : ''}>
          <em className="spinner fas fa-sync fa-spin fa-fw"></em>
        </div>
      </div>
    </FullscreenLoaderContainer>
  );
};

export default FullscreenLoader;
