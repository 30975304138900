import {useTypedSelector} from '../../../../hooks/store';
import {selectUiSettings} from '../../../environment/slice/environment.slice';
import {selectActiveCart} from '../../slice/cart.slice';

export const useCartTotalValues = (): {
  totalLinePrice: number;
  subTotalPrice: number;
  isDiscountOrPriceAdjustment: boolean;
} => {
  let uiSettings = useTypedSelector(selectUiSettings);
  let cart = useTypedSelector(selectActiveCart);
  const totalLinePrice = cart?.totalNetAmount ?? 0;
  const subTotalPrice = cart?.subtotalAmount ?? 0;
  const isDiscountOrPriceAdjustment = uiSettings?.calculateCartDiscountsInBackend
    ? subTotalPrice > totalLinePrice
    : cart?.totalProductDiscountAndGiftCodeAmount !== 0 ||
      cart?.totalHeaderAdjustmentAmount !== 0 ||
      cart?.standardDiscountAmount !== 0;

  return {totalLinePrice, subTotalPrice, isDiscountOrPriceAdjustment};
};
