import {useTypedSelector} from '../../../../hooks/store';
import {CartItem} from '../../ICartState';
import {cartItemsAlphabetized} from '../../service/cart.service';
import {selectActiveCart} from '../../slice/cart.slice';

export interface BundleCartItems {
  bundleId: string;
  cartItems: CartItem[];
}

/**
 * Returns the active cart's item grouped by bundle id
 * @returns
 */
export const useBundleCartItems: () => BundleCartItems[] | null = () => {
  const cart = useTypedSelector(selectActiveCart);
  return cart?.cartItems
    ? Object.entries(
        // Group the cart items by bundle id
        cart?.cartItems.reduce(
          (acc, item) => {
            const bundleId = item.bundleId ?? ''; // Non bundle carts would get grouped into a single array
            if (!acc[bundleId]) {
              acc[bundleId] = [];
            }
            acc[bundleId].push(item);
            return acc;
          },
          {} as {[bundleId: string]: CartItem[]}
        )
        // convert it to array
      ).map(
        (keyValue) =>
          ({bundleId: keyValue[0], cartItems: cartItemsAlphabetized(keyValue[1] as CartItem[])}) as BundleCartItems
      )
    : null;
};
