import {Form, Formik} from 'formik';
import React, {Dispatch, SetStateAction, useCallback, useEffect, useRef, useState} from 'react';
import {Col, Row} from 'react-bootstrap';
import {isEmail, ReactMultiEmail} from 'react-multi-email';
import {useAppDispatch, useTypedSelector} from '../../../../../hooks/store';
import * as Yup from 'yup';
import COMMERCE_CORE_CONSTANTS from '../../../../../Core/constants';
import {baseApiCallWithReauth} from '../../../../../Framework/api-utils/api-utils';
import {selectCurrentCustomer} from '../../../../../features/customer/slice/customer.slice';
import {
  selectCadRevisionEmails,
  selectOrderApprovedEmails,
  selectPoRevisionEmails,
  selectSelectedDrafter,
  setCadRevisionEmails,
  setOrderApprovedEmails,
  setPoRevisionEmails,
  setSelectedDrafter,
} from '../../../../../features/fabrication/slice/fabrication.slice';
import {selectActiveCart} from '../../../../../features/cart/slice/cart.slice';
import {OrderDetail} from '../../../../../features/cart/ICartState';
import {hasPermission} from '../../../../../store/permission/permission.service';
import {selectUserActions} from '../../../../../features/permission/slice/permission.slice';
import {toast} from 'react-toastify';
import CambriaSelect from '../../../../../Framework/Components/CambriaSelect';
import {IFabricationEmailResponse} from '../../../../../features/fabrication/IFabricationState';
import {EmailListContainer} from './EmailList.styled';

export const processDraftersForSelect = (list: Array<string>): Array<{name: string}> => {
  const newList: Array<any> = [];
  list.forEach((item: string) => {
    newList.push({name: item});
  });
  return newList;
};

const EmailList = () => {
  const dispatch = useAppDispatch();
  const orderDrafterRef: any = useRef();

  const [orderApprovedEmails, setOrderApprovedEmailsLocal] = useState<Array<string>>([]);
  const [cadRevisionEmails, setCadRevisionEmailsLocal] = useState<Array<string>>([]);
  const [poRevisionEmails, setPoRevisionEmailsLocal] = useState<Array<string>>([]);
  const [availableDrafters, setAvailableDrafters] = useState<Array<{name: string}>>([]);
  const [selectedDrafter, setSelectedDrafterLocal] = useState<string>('');
  const [requireEmailDrafter, setRequireEmailDrafter] = useState<boolean>(true);
  const [requireReviewEmails, setRequireReviewEmails] = useState<boolean>(true);
  const [initialEmailsLoaded, setInitialEmailsLoaded] = useState(false);

  const currentCustomer = useTypedSelector(selectCurrentCustomer);
  const orderApprovedEmailsRedux = useTypedSelector(selectOrderApprovedEmails);
  const cadRevisionEmailsRedux = useTypedSelector(selectCadRevisionEmails);
  const poRevisionEmailsRedux = useTypedSelector(selectPoRevisionEmails);
  const selectedDrafterRedux = useTypedSelector(selectSelectedDrafter);
  const activeCart = useTypedSelector(selectActiveCart);
  const userActions = useTypedSelector(selectUserActions);

  const setEmailListFromCartOrderDetails = useCallback((orderDetails: OrderDetail) => {
    setSelectedDrafterLocal(orderDetails.fabricationOrderDrafter ? orderDetails.fabricationOrderDrafter : '');
    setOrderApprovedEmailsLocal(
      orderDetails.fabricationOrderOrderApprovedEmailList
        ? orderDetails.fabricationOrderOrderApprovedEmailList.split(', ')
        : []
    );
    setCadRevisionEmailsLocal(
      orderDetails.fabricationOrderCadRevisionsNeededEmailList
        ? orderDetails.fabricationOrderCadRevisionsNeededEmailList.split(', ')
        : []
    );
    setPoRevisionEmailsLocal(
      orderDetails.fabricationOrderPoRevisionsNeededEmailList
        ? orderDetails.fabricationOrderPoRevisionsNeededEmailList.split(', ')
        : []
    );
  }, []);

  const handleTab = (event: any, firstInputInRow?: boolean) => {
    const form = event.target.form;
    const index = [...form].indexOf(event.target);
    event.shiftKey
      ? firstInputInRow
        ? orderDrafterRef.current.focus()
        : form.elements[index - 1]?.focus()
      : form.elements[index + 1]?.focus();
    event.preventDefault();
  };

  useEffect(() => {
    const getInitialEmailLists = async (erpCustomerId: number) => {
      const results: IFabricationEmailResponse = await baseApiCallWithReauth(
        'GET',
        `${COMMERCE_CORE_CONSTANTS.API_SERVICES.CUSTOMER.fabricationOrderPreferences}/${erpCustomerId}`
      );
      if (
        activeCart &&
        activeCart.orderDetails &&
        activeCart.orderDetails.length > 0 &&
        (activeCart.orderDetails[0].fabricationOrderDrafter ||
          activeCart.orderDetails[0].fabricationOrderOrderApprovedEmailList)
      ) {
        setEmailListFromCartOrderDetails(activeCart.orderDetails[0]);
        setAvailableDrafters(results.drafters ? processDraftersForSelect(results.drafters.split(', ')) : []);
      } else {
        setOrderApprovedEmailsLocal(results.orderApprovedEmailList ? results.orderApprovedEmailList.split(', ') : []);
        setCadRevisionEmailsLocal(
          results.cadRevisionsNeededEmailList ? results.cadRevisionsNeededEmailList.split(', ') : []
        );
        setPoRevisionEmailsLocal(
          results.poRevisionsNeededEmailList ? results.poRevisionsNeededEmailList.split(', ') : []
        );
        setAvailableDrafters(results.drafters ? processDraftersForSelect(results.drafters.split(', ')) : []);
      }
    };
    if (currentCustomer.erpCustomerId && activeCart && !initialEmailsLoaded) {
      getInitialEmailLists(currentCustomer.erpCustomerId);
      setInitialEmailsLoaded(true);
    }
  }, [currentCustomer, activeCart, setEmailListFromCartOrderDetails, initialEmailsLoaded]);

  useEffect(() => {
    const current = orderApprovedEmails.join(', ');
    if (current !== orderApprovedEmailsRedux) {
      dispatch(setOrderApprovedEmails(current));
    }
  }, [orderApprovedEmails, orderApprovedEmailsRedux, dispatch]);

  useEffect(() => {
    const current = cadRevisionEmails.join(', ');
    if (current !== cadRevisionEmailsRedux) {
      dispatch(setCadRevisionEmails(current));
    }
  }, [cadRevisionEmails, cadRevisionEmailsRedux, dispatch]);

  useEffect(() => {
    const current = poRevisionEmails.join(', ');
    if (current !== poRevisionEmailsRedux) {
      dispatch(setPoRevisionEmails(current));
    }
  }, [poRevisionEmails, poRevisionEmailsRedux, dispatch]);

  useEffect(() => {
    if (selectedDrafter !== selectedDrafterRedux) {
      dispatch(setSelectedDrafter(selectedDrafter));
    }
  }, [selectedDrafter, selectedDrafterRedux, dispatch]);

  useEffect(() => {
    if (userActions && userActions.length > 0) {
      setRequireEmailDrafter(
        !hasPermission('urn:csa:commerceui:order:fab:dontrequireorderapprovedemaillist', userActions)
      );
      setRequireReviewEmails(!hasPermission('urn:csa:commerceui:disableRequiredReviewEmails', userActions));
    }
  }, [userActions]);

  const EmailListInput = ({
    listName,
    emailList,
    setListFunction,
    placeholder,
    firstInputInRow,
  }: {
    listName: string;
    emailList: string[];
    setListFunction: Dispatch<SetStateAction<string[]>>;
    placeholder?: string;
    firstInputInRow?: boolean;
  }) => {
    return (
      <div className="email-list-container" onKeyDown={(e) => e.key === 'Tab' && handleTab(e, firstInputInRow)}>
        <label>
          {listName} {requireReviewEmails ? '*' : undefined}
        </label>
        <ReactMultiEmail
          data-testid={`${listName}-email-input`}
          emails={emailList}
          onChange={(_emailList: string[]) => setListFunction(_emailList)}
          placeholder={placeholder}
          validateEmail={(email) => {
            if (!isEmail(email)) {
              toast.error(`'${email}' is not a valid email address`);
            }
            return isEmail(email);
          }}
          getLabel={(email: string, index: number, removeEmail: (index: number) => void) => {
            return (
              <div className="active-email" key={email}>
                <span className="email-text">{email}</span>
                <i className="fa fa-times" onClick={() => removeEmail(index)} />
              </div>
            );
          }}
        />
      </div>
    );
  };

  return (
    <EmailListContainer>
      <Formik
        initialValues={{}}
        validationSchema={Yup.object().shape({
          orderDrafter: Yup.mixed(),
        })}
        onSubmit={() => {}}>
        <Form>
          <Row className="mb-4">
            <Col xs={4}>
              <CambriaSelect
                selectReference={orderDrafterRef}
                required={requireEmailDrafter}
                items={availableDrafters}
                displayValue="name"
                name="orderDrafter"
                label="ORDER DRAFTER"
                placeholder="Order Drafter"
                defaultValue={selectedDrafter}
                onChange={(selectedItem: any) => setSelectedDrafterLocal(selectedItem.name)}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={4}>
              <EmailListInput
                firstInputInRow
                listName="ORDER APPROVED"
                emailList={orderApprovedEmails}
                setListFunction={setOrderApprovedEmailsLocal}
                placeholder="Order Approved Email"
              />
            </Col>
            <Col xs={4}>
              <EmailListInput
                listName="CAD REVISION NEEDED"
                emailList={cadRevisionEmails}
                setListFunction={setCadRevisionEmailsLocal}
                placeholder="CAD Revision Needed Email"
              />
            </Col>
            <Col xs={4}>
              <EmailListInput
                listName={'PO REVISION NEEDED'}
                emailList={poRevisionEmails}
                setListFunction={setPoRevisionEmailsLocal}
                placeholder="PO Revision Needed Email"
              />
            </Col>
          </Row>
        </Form>
      </Formik>
    </EmailListContainer>
  );
};

export default EmailList;
