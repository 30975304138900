import {Form, Formik} from 'formik';
import React, {useCallback, useEffect, useState} from 'react';
import * as Yup from 'yup';
import {useHistory} from 'react-router-dom';
import {toast} from 'react-toastify';
import {selectCurrentUser} from '../../../../../features/auth/slice/authentication.slice';
import {addSavedItem, getBillToSiteByOperatingUnitCode} from '../../../../../features/cart/service/cart.service';
import {selectActiveCart, selectSavedItems} from '../../../../../features/cart/slice/cart.slice';
import {NewCartItem} from '../../../../../features/cart/ICartState';
import {
  selectCurrentCustomer,
  selectCurrentCustomerBillToSites,
  selectCurrentCustomerShipToSites,
} from '../../../../../features/customer/slice/customer.slice';
import {selectUserActions} from '../../../../../features/permission/slice/permission.slice';
import {NewSingleItemPriceResponse, SingleItemPriceResponse} from '../../../../../features/pricing/IPricingState';
import {getSingleItemPrice} from '../../../../../features/pricing/service/pricing.service';
import {Item, Product} from '../../../../../features/productCatalog/IProductCatalogState';
import {
  calculateTileSquareFootage,
  calculateTileUnitSize,
  getProductGroupByCode,
  getSelectAttributes,
  getStoreSizeSort,
  isCustomStoreItem,
} from '../../../../../features/productCatalog/service/productCatalog.service';
import {
  selectBundleProgramRulesStatus,
  selectSavedAttrs,
  setProductId,
  setSavedAttrs,
  setUpdatedProduct,
} from '../../../../../features/productCatalog/slice/productCatalog.slice';
import {getCartItemsProductType} from '../../../../../features/productType/service/productType.service';
import {selectActiveProgram} from '../../../../../features/salesforce/slice/salesforce.slice';
import {
  getItemWarehouseCode,
  ListOfSampleWarehouses,
  getOperatingUnitCodeByProductType,
} from '../../../../../features/warehouse/service/warehouse.service';
import CambriaButton from '../../../../../Framework/Components/CambriaButton';
import CambriaInput from '../../../../../Framework/Components/CambriaInput';
import {camelCaseFilter} from '../../../../../Framework/Services/filters.service';
import {useAppDispatch, useTypedSelector} from '../../../../../hooks/store';
import {hasPermission} from '../../../../../store/permission/permission.service';
import {StandardPage} from './Standard.styled';
import Icon from '../../../../../Framework/Components/Icon';
import ProductImageZoom from './ProductImageZoom';
import {formatPrice} from '../../../../../Framework/Services/formatPrice.service';
import CambriaAlerts from '../../../../../Framework/Components/CambriaAlerts';
import {Col, Row} from 'react-bootstrap';
import {
  hideFullscreenLoader,
  showFullscreenLoader,
} from '../../../../../features/fullscreenLoader/slice/fullscreenLoader.slice';
import {StandardTotalAndAttributesPlaceholders} from './StandardPlaceholders/StandardPlaceholders.component';
import CambriaSelect from '../../../../../Framework/Components/CambriaSelect';
import {getCartItemByItemNumber} from '../../../../../features/cart/cartItem/service/cartItem.service';
import SingleItemPrice from './SingleItemPrice';
import {pushAddToCartInformationToDataLayer} from '../../../../../features/analytics/service/analytics.service';
import {useBundleValidationForProduct} from '../../../../../features/product/hooks/useBundleValidationForProduct';
import {useAddCartItem} from '../../../../../features/cart/hooks/useAddCartItem';
import BundleValidationsAlerts from './BundleValidationsAlerts';
import {selectIsRevH, selectIsRevI} from '../../../../../features/environment/slice/environment.slice';
import {useCalculateStandardDiscounts} from '../../../../../features/cart/hooks/useCalculateStandardDiscounts';

interface StandardProps {
  product: any;
}

const Standard = ({product}: StandardProps) => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const getSlug = (productType: string): string => {
    let slug = camelCaseFilter(productType);
    if (slug === 'slab') {
      slug = 'slabs';
    }
    return slug;
  };

  const buildFormObject = (formAttributes: any) => {
    let newForm: any = {
      initialValues: {
        quantity: product.requireOrderingInQuantitiesOfTwo ? 2 : 1,
      },
      validationSchema: {},
    };

    if (product.requireOrderingInQuantitiesOfTwo) {
      newForm.validationSchema['quantity'] = Yup.number()
        .required('This field is required')
        .test(
          'test-required-even-number',
          'This product must be ordered in quantities of two.',
          (value: any) => value && value % 2 === 0
        );
    } else {
      newForm.validationSchema['quantity'] = Yup.number().required('This field is required');
    }

    for (const attrs of formAttributes) {
      newForm.initialValues[attrs.name] = '';
      newForm.validationSchema[attrs.name] = Yup.mixed().required('This field is required');
    }

    return newForm;
  };

  let cart: any = useTypedSelector(selectActiveCart);
  let program = useTypedSelector(selectActiveProgram);
  let customer = useTypedSelector(selectCurrentCustomer);
  let shipToSites: any = useTypedSelector(selectCurrentCustomerShipToSites);
  let billToSites: any = useTypedSelector(selectCurrentCustomerBillToSites);
  let user: any = useTypedSelector(selectCurrentUser);
  let savedItems = useTypedSelector(selectSavedItems);
  let userActions = useTypedSelector(selectUserActions);
  let savedAttrs: any = useTypedSelector(selectSavedAttrs);
  const bundleProgramRulesStatus = useTypedSelector(selectBundleProgramRulesStatus);
  const calculateStandardDiscounts = useCalculateStandardDiscounts();

  let [slug] = useState(getSlug(product.productType));
  let [formikFormValues]: any = useState(buildFormObject(product.attributes));
  let [alertData, setAlertData]: any = useState([]);
  let [items, setItems] = useState(product.items);
  let [isInitializing, setIsInitializing] = useState(false);
  let [hasAlreadyInitialized, setHasAlreadyInitialized] = useState<boolean>(false);
  let [isInEditMode, setIsInEditMode] = useState(false);
  let [attributes] = useState(getSelectAttributes(product.attributes));
  let [attribute, setAttribute]: any = useState(null);
  let [selectedItem, setUseStateSelectedItem]: any = useState(null);
  let [selectedItemNumber]: any = useState(null);
  let [quantity, setQuantity] = useState<number>(1);
  let [productGroups, setUseStateProductGroups]: any = useState([]);
  let [singleItemPrice, setSingleItemPrice] = useState<SingleItemPriceResponse>(NewSingleItemPriceResponse());
  let [productShipFromWarehouse, setProductShipFromWarehouse]: any = useState(null);
  let [billToSite, setBillToSite]: any = useState(null);
  let [images, setImages]: any = useState([]);
  let [displayImage, setDisplayImage] = useState(null);
  let [hideCarousel, setHideCarousel] = useState(false);
  let [disableAddToCartButton, setDisableAddToCartButton] = useState<boolean>(true);
  let [quantityIsValid, setQuantityIsValid] = useState<boolean>(true);
  let [reservableAmount, setReservableAmount] = useState<number | null>(null);
  let [warehouseList] = useState(ListOfSampleWarehouses());
  let [warehousePhoneNumber, setWarehousePhoneNumber]: any = useState(null);
  let [warehouseIs231, setWarehouseIs231] = useState(false);
  let [storeStockLoaded, setStoreStockLoaded] = useState<boolean>(false);
  let [exceedsStock, setExceedsStock] = useState<boolean>(false);
  let [isMultipleRecords, setIsMultipleRecords]: any = useState(false);
  let [hasItemPricing, setHasItemPricing] = useState(false);
  let [disableSaveForLaterButton, setDisableSaveForLaterButton] = useState<boolean>(true);
  let [isQuartz] = useState(product.productType === 'Slab' || product.productType === 'Tile');
  let [isCustom, setIsCustom] = useState<boolean>(false);
  const addCartItem = useAddCartItem();
  const isRevH = useTypedSelector(selectIsRevH);
  const isRevI = useTypedSelector(selectIsRevI);

  const {
    isBundleProgram,
    inputSteps: quantityStepsForBundleProgram,
    helpText: bundleHelpText,
    quantityNeeded,
    disableAddToCart: disableAddToCartForBundle,
    bundleValidation,
    halfBundleQuantity,
    maxQuantity: bundleMaxQuantity,
  } = useBundleValidationForProduct({
    itemNumber: selectedItem?.itemNumber,
    size: attribute?.Size?.selected?.value,
    thickness: attribute?.Thickness?.selected?.value,
    designCode: selectedItem?.attributes.find((x: any) => x.name === 'Design Code')?.value,
  });

  const quantityDefaultValue =
    quantityNeeded || quantityStepsForBundleProgram || (product.requireOrderingInQuantitiesOfTwo ? 2 : 1);

  const setAlertDataFunction = useCallback((value: any) => {
    setAlertData(value);
  }, []);

  const setBillTo = useCallback(async (): Promise<any> => {
    const operatingUnitCode = getOperatingUnitCodeByProductType(product.productType, customer, shipToSites);

    const billToSiteResult = await getBillToSiteByOperatingUnitCode(operatingUnitCode, billToSites);

    setBillToSite(billToSiteResult);
    return billToSiteResult;
  }, [billToSites, customer, product.productType, shipToSites]);

  const setProductGroups = async (product: Product): Promise<any> => {
    const productGroupsInProgress = [];
    if (product.productGroupCodes) {
      for (let index = 0; index < product.productGroupCodes.length; index++) {
        const productGroupCode = product.productGroupCodes[index];
        const productGroup = await getProductGroupByCode(productGroupCode);
        productGroupsInProgress.push(productGroup);
      }
    }
    return productGroupsInProgress;
  };

  const goToPdp = (productId: string): void => {
    dispatch(setUpdatedProduct(null));
    dispatch(setProductId(null));
    history.push(`/product/details/${productId}`);
  };

  const setSelectedItemAttributes = useCallback((selectedItem: Item, attributesIn: any): any => {
    let isCompleteAttributes = true;
    let isAttributesValid = false;

    setSelectedAttributeOnAttributes(selectedItem, attributesIn);
    isCompleteAttributes = areAllAttributesSelected(selectedItem, attributesIn, isCompleteAttributes);
    if (isCompleteAttributes) {
      isAttributesValid = true;
    }

    return {
      isCompleteAttributes,
      isAttributesValid,
    };
  }, []);

  const areAllAttributesSelected = (selectedItem: Item, attributesIn: any, isCompleteAttributes: boolean): boolean => {
    for (const selectedItemAttribute of selectedItem.attributes) {
      if (selectedItemAttribute) {
        const nameToSelect = selectedItemAttribute.name;
        const attributeForSelectOption = attributesIn[nameToSelect];
        if (!attributeForSelectOption || !attributeForSelectOption.selected) {
          isCompleteAttributes = false;
        }
      }
    }
    return isCompleteAttributes;
  };

  const setSelectedAttributeOnAttributes = (selectedItem: Item, attributesIn: any) => {
    for (const selectedItemAttribute of selectedItem.attributes) {
      if (selectedItemAttribute) {
        const nameToSelect = selectedItemAttribute.name;
        const valueToSelect = selectedItemAttribute.value;
        const attributeForSelectOption = attributesIn[nameToSelect];
        if (attributeForSelectOption) {
          const attributeToSelect = attributeForSelectOption.selections.find(
            (attr: any) => attr.value === valueToSelect
          );
          attributeForSelectOption.selected = attributeToSelect;
        }
      }
    }
  };

  const setSavedAttributes = useCallback(() => {
    let tempSavedAttrs = JSON.parse(JSON.stringify(savedAttrs));
    if (!tempSavedAttrs.hasOwnProperty(slug)) {
      tempSavedAttrs['slabs'] = {};
      tempSavedAttrs['tile'] = {
        'Nominal or True': '',
      };
      tempSavedAttrs['store'] = {
        'Color/Design/Scent/Shape': '',
        'Type/Gender': '',
      };
      tempSavedAttrs['samples'] = {
        'Color/Design': '',
      };
    }

    dispatch(setSavedAttrs(tempSavedAttrs));
  }, [savedAttrs, slug, dispatch]);

  const setCollectionOnProducts = useCallback(
    (product: any): void => {
      if (
        ['Slab', 'Tile'].indexOf(product.productType) !== -1 &&
        product.items &&
        product.items[0] &&
        product.items[0].attributes
      ) {
        for (const attribute in product.items[0].attributes) {
          if (product.items[0].attributes[attribute].name === 'Collection') {
            let tempProduct = JSON.parse(JSON.stringify(product));
            tempProduct.collection = `${product.items[0].attributes[attribute].value} Collection`;
            dispatch(setUpdatedProduct(tempProduct));
            return;
          }
        }
      }
    },
    [dispatch]
  );

  const onQuantityChange = (value: any) => {
    let testValue = value;
    if (typeof value === 'object') {
      testValue = value.target.value;
    }
    const regex = new RegExp(/^[1-9]+[0-9]*$/);
    const result = regex.test(testValue);
    const divisibleByTwo: boolean = testValue % 2 === 0;

    if (!result) {
      setQuantityIsValid(false);
      setDisableAddToCartButton(true);
    } else if (product.requireOrderingInQuantitiesOfTwo && !divisibleByTwo) {
      setQuantityIsValid(false);
      setDisableAddToCartButton(true);
    }

    if (result) {
      setQuantity(parseInt(testValue));
      setQuantityIsValid(true);
      setDisableAddToCartButton(false);
      setExceedsStock(false);
    }

    if (product.productType === 'Store' && reservableAmount && testValue > reservableAmount && !isCustom) {
      setQuantityIsValid(false);
      setExceedsStock(true);
      setDisableAddToCartButton(true);
    }
  };

  const isInProductAttributes = (attr: any, attributes: any[]): boolean => {
    if (!attributes) {
      return false;
    }
    const result = attributes.findIndex((prodAttr) => prodAttr.name === attr.name);

    return result !== -1;
  };

  const setCambriaZoomImage = useCallback(
    (image: any): any => {
      const defaultDimensions = slug === 'tile' || slug === 'slabs' ? '540x260px' : '540x420px';
      const zoomDimensions = slug === 'tile' || slug === 'slabs' ? '1350x650px' : '1350x1050px';

      return {
        thumbnail: `${image.location
          .replace('{size}px@{scale}x', defaultDimensions)
          .replace('q={quality}', 'q=100')}&c=0`,
        default: `${image.location
          .replace('{size}px@{scale}x', defaultDimensions)
          .replace('q={quality}', 'q=100')}&c=0`,
        zoomed: `${image.location.replace('{size}px@{scale}x', zoomDimensions).replace('q={quality}', 'q=100')}&c=0`,
        type: image.type,
      };
    },
    [slug]
  );

  const setSelectedItem = useCallback(
    (item: any, isAttributesValid: any, isCompleteAttributes: any): void => {
      let currentSelectedItem = null;
      if (isCompleteAttributes && isAttributesValid) {
        currentSelectedItem = item;
        setUseStateSelectedItem(item);
        if (item.attachments) {
          let mainImage = item.attachments.find((attachment: any) => attachment.type === 'main');
          if (mainImage) {
            mainImage = setCambriaZoomImage(mainImage);
          }
          setDisplayImage(mainImage);
          setHideCarousel(true);
        }
      }
      return currentSelectedItem;
    },
    [setCambriaZoomImage]
  );

  const checkAttributes = useCallback(
    (initialAttribute: any, initialItems: any) => {
      let isAttributesValid = false;
      let isCompleteAttributes = true;
      let currentSelectedItem = null;
      if (!initialItems) {
        return {
          isAttributesValid,
          isCompleteAttributes,
        };
      }
      for (const item of initialItems) {
        let goodCombination = true;
        for (const attr of item.attributes) {
          if (!isInProductAttributes(attr, attributes)) {
            continue;
          }
          if (initialAttribute[attr.name].selected === undefined || initialAttribute[attr.name].selected.value === '') {
            isCompleteAttributes = false;
            goodCombination = false;
            break;
          }
          if (attr.value === 'TRUE') {
            attr.value = 'True';
          }
          if (initialAttribute[attr.name].selected.value !== attr.value) {
            goodCombination = false;
            break;
          }
        }
        if (goodCombination) {
          isAttributesValid = true;
          currentSelectedItem = setSelectedItem(item, isAttributesValid, isCompleteAttributes);
          break;
        }
      }
      return {
        isAttributesValid,
        isCompleteAttributes,
        currentSelectedItem,
      };
    },
    [attributes, setSelectedItem]
  );

  const generateImages = useCallback(
    (source: any): void => {
      if (images.length > 0) {
        images.splice(0, images.length);
      }

      if (source) {
        let newSource = source;
        const mainArrayType = 'main';
        const mainImages = source.filter((attachment: any) => attachment.type === mainArrayType);
        const defaultArrayType = 'default';
        const defaultImage = source.find((attachment: any) => attachment.type === defaultArrayType);
        newSource = newSource.filter((attachment: any) => attachment.type !== mainArrayType);
        if (mainImages) {
          for (const mainImage of mainImages) {
            newSource.unshift(mainImage);
          }
        }
        newSource = newSource.filter((attachment: any) => attachment.type !== defaultArrayType);
        if (defaultImage) {
          newSource.unshift(defaultImage);
        }
        let tempImages = JSON.parse(JSON.stringify(images));
        for (const img of newSource) {
          tempImages.push(setCambriaZoomImage(img));
        }
        setImages(tempImages);
      }
    },
    [images, setCambriaZoomImage]
  );

  const checkForProductItems = useCallback(() => {
    if (!product.items || product.items.length === 0) {
      toast.error('The product has no item(s).');
      return;
    }
    if (alertData) {
      alertData.splice(0);
    }
    generateImages(product.attachments);
  }, [alertData, product.items, generateImages, product.attachments]);

  const checkReservableQuantity = useCallback(
    async (quantities: any, warehouse: any, id: string) => {
      if (quantities && warehouse) {
        if (product.productType === 'Store') {
          let productIsCustom: boolean = false;
          try {
            productIsCustom = await isCustomStoreItem(id);
            setIsCustom(productIsCustom);
          } catch (error: any) {
            toast.error('Unable to determine if store item is of "Custom" subType');
          }
          if (!productIsCustom) {
            setWarehouseIs231(true);
            const storeItemArray = quantities.find((x: any) => x.subInventory === 'STR');
            setReservableAmount(storeItemArray.qtyReservable);
            if (!storeItemArray.qtyReservable) {
              setDisableAddToCartButton(true);
              setDisableSaveForLaterButton(true);
            } else {
              setDisableAddToCartButton(false);
              setDisableSaveForLaterButton(false);
            }
            setStoreStockLoaded(true);
          }
        }
        const reservableQuantityArray = quantities.filter((x: any) => x.organizationCode === warehouse);
        const selectedWarehouse = warehouseList.find((x) => x.warehouseCode === warehouse);
        setWarehousePhoneNumber(selectedWarehouse ? selectedWarehouse.phone : '');

        if (product.productType !== 'Store') {
          if (reservableQuantityArray.length > 0 && reservableQuantityArray[0].organizationCode === '231') {
            setWarehouseIs231(true);
            for (const quantity of reservableQuantityArray) {
              if (quantity.subInventory !== 'SMP') {
                setReservableAmount(quantity.qtyReservable);
              }
            }
          } else {
            setWarehouseIs231(false);
            for (const quantity of reservableQuantityArray) {
              if (quantity.subInventory === 'SMP') {
                setReservableAmount(quantity.qtyReservable);
              }
            }
          }
        }
      }
    },
    [warehouseList, product.productType]
  );

  const disableAddButtons = useCallback(
    (disable: boolean): void => {
      if (!cart || !quantity || quantity < 1) {
        setDisableAddToCartButton(true);
        setDisableSaveForLaterButton(true);
      } else {
        setDisableAddToCartButton(disable);
        setDisableSaveForLaterButton(disable);
      }
    },
    [cart, quantity]
  );

  const setWarehouse = useCallback(
    async (currentSelectedItem: any, initialSingleItemPrice: any): Promise<any> => {
      const cartItem = getCartItemByItemNumber(
        currentSelectedItem.itemNumber,
        product,
        initialSingleItemPrice,
        null,
        quantity,
        program.code,
        program.name
      );

      const warehouse = await getItemWarehouseCode(cartItem, cart, customer, shipToSites);
      if (warehouse.hasError) {
        disableAddButtons(true);
        toast.error(warehouse.message);
        setIsInitializing(false);
        return Promise.reject(warehouse.message);
      }
      setProductShipFromWarehouse(warehouse);
      return warehouse;
    },
    [shipToSites, cart, customer, program.code, program.name, product, quantity, disableAddButtons]
  );

  const standardGetSingleItemPrice = useCallback(
    async (
      erpCustomerId: string,
      warehouseCode: string,
      currentBillToSite: any,
      currentProductShipFromWarehouse: any,
      currentSelectedItem: any
    ) => {
      try {
        const result = await getSingleItemPrice(
          erpCustomerId,
          warehouseCode,
          program.code,
          currentSelectedItem.itemNumber,
          currentBillToSite.siteUseId,
          customer.class,
          dispatch
        );
        return result;
      } catch (e: any) {
        if (!!currentProductShipFromWarehouse.mainWarehouseCode) {
          try {
            const result = await getSingleItemPrice(
              erpCustomerId,
              currentProductShipFromWarehouse.mainWarehouseCode,
              program.code,
              currentSelectedItem.itemNumber,
              currentBillToSite.siteUseId,
              customer.class,
              dispatch
            );
            return result;
          } catch (e: any) {
            toast.error(
              'Current item does not have a price configured. Please contact the Cambria Business Office to have this corrected.'
            );
            throw e;
          }
        } else {
          if (e.status === 'Failure') {
            toast.error(
              'Current item does not have a price configured. Please contact the Cambria Business Office to have this corrected.'
            );
            throw e;
          }
        }
        throw e;
      }
    },
    [customer.class, program.code, dispatch]
  );

  const getConsiderSavedAttributes = (currentSelectedItemNumber: string, items: any[]) => {
    let considerSavedAttributes = true;
    if (currentSelectedItemNumber) {
      if (items.find((item) => item.itemNumber === currentSelectedItemNumber)) {
        considerSavedAttributes = false;
      }
    }
    return considerSavedAttributes;
  };

  const getAttributeDetails = useCallback(
    (
      {
        attr,
        attrOptions,
        attrSelections,
      }: {
        attr: any;
        attrOptions: any[];
        attrSelections: any[];
      },
      items: any[],
      slug: string
    ) => {
      let addOnTemplate = '';
      let sortBy = '';
      let tempItems = [];
      for (const item of items) {
        if (!item.attributes) {
          return {addOnTemplate, sortBy};
        }
        for (const itemAttr of item.attributes) {
          if (attr.name === itemAttr.name && itemAttr.value !== null) {
            fixTrueValues(itemAttr);
            // itemAttr.usageType = attr.usageType;
            const currentIndex = attrOptions.indexOf(itemAttr.value);
            if (currentIndex === -1) {
              attrOptions.push(itemAttr.value);
              attrSelections.push({
                name: attr.name,
                value: itemAttr.value,
                items: [item.attributes],
                quantities: [item.quantities],
              });
            } else {
              attrSelections[currentIndex].items.push(item.attributes);
            }
          }
          addOnTemplate = getAddonTemplate(attr, item, slug);
          if (attr.name === 'Size' && slug === 'store') {
            sortBy = getStoreSizeSort(attrSelections);
          }
        }
      }
      tempItems = items;
      return {addOnTemplate, sortBy, tempItems};
    },
    []
  );

  const getAddonTemplate = (attr: any, item: any, slug: string): string => {
    let addOnTemplate: string = '';
    if (attr.name === 'Size' && slug === 'slabs') {
      addOnTemplate = 'Sq Ft';
    }
    if (attr.name === 'Size' && (slug === 'tile' || (slug === 'samples' && item.description.indexOf('Tile') !== -1))) {
      addOnTemplate = 'in.';
    }
    return addOnTemplate;
  };

  const fixTrueValues = (itemAttr: any) => {
    if (itemAttr.value === 'TRUE') {
      itemAttr.value = 'True';
    }
  };

  const hasAttribute = useCallback((item: any) => {
    return (element: any) => {
      if (!item) {
        return undefined;
      }
      return element.name === item.name && element.value === item.value;
    };
  }, []);

  const hasItem = useCallback(
    (item: any) => {
      return (element: any) => element.filter(hasAttribute(item)).length > 0;
    },
    [hasAttribute]
  );

  const selectionHasAttribute = useCallback(
    (item: any) => {
      return (element: any) => element.items.filter(hasItem(item)).length > 0;
    },
    [hasItem]
  );

  const isNameEqual = (name: any) => {
    return (element: any) => element.name === name;
  };

  const isValueEqual = (value: any) => {
    return (element: any) => element.value === value;
  };

  const getPrice = () => {
    const currentSingleItemPrice: any = singleItemPrice ? singleItemPrice : NewSingleItemPriceResponse();

    let price = currentSingleItemPrice.SellingPrice * quantity;

    if (selectedItem) {
      if (slug === 'slabs') {
        price = price * (selectedItem.unitSize || 0);
      } else if (slug === 'tile') {
        price = currentSingleItemPrice.SellingPrice * getTileSquareFootage();
      }
    }

    return price;
  };

  const getTileSquareFootage = () => {
    if (!attribute['Size'] || !attribute['Size'].selected) {
      return 0;
    }

    const unitSize = calculateTileUnitSize(attribute['Size'].selected.value);
    return calculateTileSquareFootage(unitSize, quantity) || 0;
  };

  const addToCart = async (addToCart: boolean, selectedItem: Item): Promise<void> => {
    dispatch(showFullscreenLoader({message: ''}));
    if (areErrors()) {
      handleAddToCartErrors();
      dispatch(hideFullscreenLoader());
      return;
    }

    if (!selectedItem.itemNumber) {
      toast.error('The attributes selected are not a valid combination');
    }
    if (!addToCart) {
      saveForLater({
        type: 'success',
        timeout: 20000,
        msg: `${selectedItem.description} was saved for later.`,
        viewCart: true,
      });
      return;
    }

    const cartItem = getCartItemByItemNumber(
      selectedItem.itemNumber,
      product,
      singleItemPrice,
      productShipFromWarehouse.warehouseCode,
      quantityNeeded ?? quantity,
      program.code,
      program.name
    );

    cartItem.programCode = program.code;
    cartItem.programName = program.name;
    cartItem.requireOrderingInQuantitiesOfTwo = product.requireOrderingInQuantitiesOfTwo;
    cartItem.currency = singleItemPrice.CurrencyCode;
    cartItem.operatingUnitCode = await getOperatingUnitCodeByProductType(cartItem.productType, customer, shipToSites);
    cartItem.siteUseId = billToSite.siteUseId;

    try {
      const response = await addCartItem(cartItem);
      if (response === 'This item will be placed in your Save for Later items.') {
        saveForLater({
          type: 'warning',
          timeout: 20000,
          msg:
            `The current item must ship from a different location than items in the cart.` +
            ` This item will be placed in your Save for Later items and can be ordered separately` +
            ` once the current order is completed.`,
          viewCart: true,
        });
      } else {
        try {
          await calculateStandardDiscounts();
        } catch (e) {
          console.error('Error updating cart', e);
        }

        let msg = `${selectedItem.description} was added to cart.`;
        if (response === 'The current item must ship from MN Samples.') {
          msg += ` ${response} Any additional items included with this order will also ship from MN Samples.`;
        }
        let tempAlertData = JSON.parse(JSON.stringify(alertData));
        tempAlertData.push({
          type: 'success',
          msg,
          timeout: 20000,
          viewCart: true,
        });

        pushAddToCartInformationToDataLayer(
          false,
          selectedItem,
          singleItemPrice.CurrencyCode,
          product.productType,
          singleItemPrice.SellingPrice,
          quantityNeeded ?? quantity,
          product.id,
          selectedItem.itemNumber,
          selectedItem.description,
          product.displayName
        );

        setAlertData(tempAlertData);
        dispatch(hideFullscreenLoader());
      }
    } catch (e: any) {
      if (e === 'Cart already contains items of different product type.') {
        const productType = getCartItemsProductType(cart);
        saveForLater({
          type: 'warning',
          timeout: 20000,
          msg:
            `Your shopping cart currently contains ${productType}` +
            ` products that cannot be combined with ${cartItem.productType}` +
            ` products. The item has been added to your Save for Later items and` +
            ` can be purchased separately once the ${productType}` +
            ` request is completed.`,
          viewCart: true,
        });
      } else {
        if (e.data && e.data.message) {
          toast.error(e.data.message);
        } else if (e.data) {
          toast.error(e.data);
        } else {
          toast.error(e);
        }
        dispatch(hideFullscreenLoader());
      }
    }
  };

  const areErrors = (): boolean => {
    return isMultipleRecords || !hasItemPricing || exceedsStock;
  };

  const handleAddToCartErrors = (): void => {
    if (isMultipleRecords) {
      toast.error('Multiple pricing records exists. Unable to add to cart.');
      return;
    }

    if (!hasItemPricing) {
      toast.error('No item pricing. Unable to add to cart.');
      return;
    }

    if (exceedsStock) {
      toast.error('The reservable amount of items has been exceeded.');
      return;
    }
  };

  const saveForLater = async (message: any): Promise<any> => {
    const cartItem = getCartItemByItemNumber(
      selectedItem.itemNumber,
      product,
      singleItemPrice,
      productShipFromWarehouse.warehouseCode,
      undefined,
      program.code,
      program.name
    );
    cartItem.programCode = program.code;
    cartItem.programName = program.name;

    try {
      await addSavedItem(
        cartItem,
        savedItems,
        cart,
        customer,
        program,
        shipToSites,
        user?.userId,
        billToSites,
        dispatch
      );
      let tempAlertData = JSON.parse(JSON.stringify(alertData));
      tempAlertData.push(message);
      setAlertData(tempAlertData);
      dispatch(hideFullscreenLoader());
    } catch (e: any) {
      if (message.msg.includes('Your shopping cart currently contains') && e === 'Item is already saved.') {
        let tempAlertData = JSON.parse(JSON.stringify(alertData));
        tempAlertData.push(message);
        setAlertData(tempAlertData);
      }
      toast.error(e);
      dispatch(hideFullscreenLoader());
    }
  };

  const filterOutByPastSelections = useCallback(
    (currentAttributeIndex: number, index: number, filteringAttribute: any, initialAttribute: any) => {
      for (let position = currentAttributeIndex - 2; position >= 0; position--) {
        const pastAttribute = initialAttribute[attributes[position].name];
        if (!filteringAttribute.disabled) {
          filteringAttribute.selections = filteringAttribute.selections.filter(
            selectionHasAttribute(pastAttribute.selected)
          );
        }
      }
    },
    [attributes, selectionHasAttribute]
  );

  const filterSequentialAttributes = useCallback(
    (item: any, initialAttribute: any, formikProps?: any) => {
      if (!item) {
        return initialAttribute;
      }
      const currentAttribute = attributes.filter(isNameEqual(item.name));
      let currentAttributeIndex = attributes.indexOf(currentAttribute[0]);
      let tempAttribute = JSON.parse(JSON.stringify(initialAttribute));
      for (let index = ++currentAttributeIndex; index < attributes.length; index++) {
        const filteringAttribute = JSON.parse(JSON.stringify(initialAttribute[attributes[index].name]));
        if (!filteringAttribute.disabled) {
          filteringAttribute.selections = filteringAttribute.allSelections.filter(selectionHasAttribute(item));
          filterOutByPastSelections(currentAttributeIndex, index, filteringAttribute, initialAttribute);
          if (
            filteringAttribute.selected !== undefined &&
            filteringAttribute.selections.filter(isValueEqual(filteringAttribute.selected.value)).length <= 0
          ) {
            filteringAttribute.selected = undefined;
            if (formikProps) {
              (formikProps as any).setFieldValue(attributes[index].name, '');
            }
          }
        }
        tempAttribute[attributes[index].name] = filteringAttribute;
      }
      setAttribute(tempAttribute);
      return tempAttribute;
    },
    [attributes, filterOutByPastSelections, selectionHasAttribute]
  );

  const setProductPriceAsync = useCallback(
    async (
      initialBillToSite: any,
      initialAttribute: any,
      currentProductShipFromWarehouse: any,
      currentSelectedItem: any
    ): Promise<any> => {
      setDisableAddToCartButton(true);
      const validProduct = isValidProduct(attributes, initialAttribute);
      let shouldEnableAddButton = true;
      if (validProduct) {
        try {
          const erpCustomerId: any =
            customer.erpCustomerId === null || customer.erpCustomerId === undefined
              ? null
              : customer.erpCustomerId.toString();

          let warehouseCode = currentProductShipFromWarehouse.warehouseCode;

          if (
            !!currentProductShipFromWarehouse.pricingFallbackWarehouseCode &&
            (currentProductShipFromWarehouse.saveItemForLater ||
              currentProductShipFromWarehouse.message === `The current item must ship from MN Samples.`)
          ) {
            warehouseCode = currentProductShipFromWarehouse.pricingFallbackWarehouseCode;
          }
          setSingleItemPrice(
            await standardGetSingleItemPrice(
              erpCustomerId,
              warehouseCode,
              initialBillToSite,
              currentProductShipFromWarehouse,
              currentSelectedItem
            )
          );
          setIsMultipleRecords(false);
          setHasItemPricing(true);
        } catch (e: any) {
          shouldEnableAddButton = false;
          setHasItemPricing(false);
          setSingleItemPrice(NewSingleItemPriceResponse());
        }
      }
      if (shouldEnableAddButton) {
        disableAddButtons(false);
      }
    },
    [attributes, customer.erpCustomerId, disableAddButtons, standardGetSingleItemPrice]
  );

  const onCompleteAttributes = useCallback(
    async (
      isAttributesValid: any,
      isCompleteAttributes: any,
      currentSelectedItem: any,
      initialAttribute: any,
      initialSingleItemPrice: any,
      initialBillToSite: any
    ): Promise<void> => {
      let tempAttribute = JSON.parse(JSON.stringify(initialAttribute));
      if (isAttributesValid) {
        for (const attr of attributes) {
          tempAttribute[attr.name].error = false;
          tempAttribute[attr.name].errorMsg = '';
        }
        setAttribute(tempAttribute);
        const currentProductShipFromWarehouse: any = await setWarehouse(currentSelectedItem, initialSingleItemPrice);
        await setProductPriceAsync(
          initialBillToSite,
          initialAttribute,
          currentProductShipFromWarehouse,
          currentSelectedItem
        );
        checkReservableQuantity(
          currentSelectedItem.quantities,
          currentProductShipFromWarehouse.warehouseCode,
          currentSelectedItem.id
        );
      } else {
        if (attributes) {
          for (const attr of attributes) {
            tempAttribute[attr.name].error = true;
            tempAttribute[attr.name].errorMsg = 'Product combination not available.';
          }
        }
        setAttribute(tempAttribute);
        let newCartItem = NewCartItem();
        setSelectedItem(newCartItem, isAttributesValid, isCompleteAttributes);
        generateImages(product.attachments);
        setSingleItemPrice(NewSingleItemPriceResponse());
        setProductShipFromWarehouse(null);
      }
    },
    [
      attributes,
      checkReservableQuantity,
      generateImages,
      product.attachments,
      setProductPriceAsync,
      setSelectedItem,
      setWarehouse,
    ]
  );

  const isValidProduct = (attributesIn: any[], attributeIn: any) => {
    let validProduct = true;
    if (attributesIn) {
      for (const attr of attributesIn) {
        if (attr.name !== 'Finish') {
          const attribute = attributeIn[attr.name];
          if (validProduct && attribute.error) {
            validProduct = false;
          }
        }
      }
    }
    return validProduct;
  };

  const checkCompleteAttributes = useCallback(
    async (initialAttribute: any, initialSingleItemPrice: any, initialBillToSite: any, initialItems: any) => {
      const result = checkAttributes(initialAttribute, initialItems);
      if (result.isCompleteAttributes) {
        await onCompleteAttributes(
          result.isAttributesValid,
          result.isCompleteAttributes,
          result.currentSelectedItem,
          initialAttribute,
          initialSingleItemPrice,
          initialBillToSite
        );
      }
    },
    [checkAttributes, onCompleteAttributes]
  );

  const onSelectAttribute = useCallback(
    async (
      item: any,
      formikProps: any = null,
      shouldCheckCompleteness = true,
      tempAttribute: any = attribute,
      initialSingleItemPrice: any = singleItemPrice ? singleItemPrice : NewSingleItemPriceResponse(),
      initialBillToSite: any = billToSite,
      initialItems: any = items,
      userSelected: boolean = true
    ): Promise<void> => {
      let tempSavedAttrs = JSON.parse(JSON.stringify(savedAttrs));
      tempSavedAttrs[slug][item.name] = item.value;
      dispatch(setSavedAttrs(tempSavedAttrs));
      if (userSelected) {
        tempAttribute[item.name].selected = item;
        setAttribute(tempAttribute);
      }

      const filteredSequentialAttributes = filterSequentialAttributes(item, tempAttribute, formikProps);
      if (shouldCheckCompleteness) {
        await checkCompleteAttributes(
          filteredSequentialAttributes,
          initialSingleItemPrice,
          initialBillToSite,
          initialItems
        );
      }
    },
    [
      attribute,
      checkCompleteAttributes,
      filterSequentialAttributes,
      savedAttrs,
      slug,
      billToSite,
      dispatch,
      items,
      singleItemPrice,
    ]
  );

  const fillAttributes = useCallback(
    async (
      attributes: any[],
      items: any[],
      slug: string,
      selectedItemNumber: string,
      initialBillToSite: any
    ): Promise<void> => {
      if (attributes) {
        const savedAttributeSelections = [];
        let tempAttribute: any = {};
        let tempItems = JSON.parse(JSON.stringify(items));
        for (const attr of attributes) {
          const attrOptions: any = [];
          const attrSelections: any = [];
          const considerSavedAttributes = getConsiderSavedAttributes(selectedItemNumber, tempItems);
          let selectedAttr;
          let sortBy = '';
          let addOnTemplate = '';
          if (items) {
            ({addOnTemplate, sortBy, tempItems} = getAttributeDetails(
              {attr, attrOptions, attrSelections},
              tempItems,
              slug
            ));
          }
          if (attrSelections.length > 1) {
            attrSelections.sort((a: any, b: any) => {
              return a.value.localeCompare(b.value);
            });
          }
          if (considerSavedAttributes) {
            if (attrSelections.length === 1) {
              let tempSavedAttrs = JSON.parse(JSON.stringify(savedAttrs));
              selectedAttr = attrSelections[0];
              tempSavedAttrs[slug][attr.name] = selectedAttr.value;
              dispatch(setSavedAttrs(tempSavedAttrs));
            } else {
              if (attrSelections && attrSelections.length > 0) {
                const savedAttr = savedAttrs[slug][attr.name] || '';
                for (const attrSelection of attrSelections) {
                  if (attrSelection.value === savedAttr) {
                    selectedAttr = attrSelection;
                  }
                }
              }
            }
            if (selectedAttr) {
              savedAttributeSelections.push(selectedAttr);
            }
          }

          tempAttribute[attr.name] = {
            name: attr.name,
            selections: attrSelections,
            allSelections: attrSelections,
            options: attrOptions,
            selected: selectedAttr,
            disabled: attrSelections.length === 1,
            addOnTemplate,
            sortBy,
            error: false,
            errorMsg: '',
            sequence: attr.sequence,
          };
        }
        for (let index = 0; index < savedAttributeSelections.length; index++) {
          const shouldCheckCompleteness = index === savedAttributeSelections.length - 1;
          const element = savedAttributeSelections[index];
          await onSelectAttribute(
            element,
            undefined,
            shouldCheckCompleteness,
            tempAttribute,
            undefined,
            initialBillToSite,
            tempItems,
            false
          );
        }
        setAttribute(tempAttribute);
        setItems(tempItems);
        return tempAttribute;
      }
    },
    [getAttributeDetails, onSelectAttribute, savedAttrs, dispatch]
  );

  const init = useCallback(async () => {
    setIsInitializing(true);
    checkForProductItems();
    setIsInEditMode(cart.transactionType === 'OrderEdit');
    setCollectionOnProducts(product);
    setSavedAttributes();
    const initialBillToSite = await setBillTo();
    const initialAttribute = await fillAttributes(
      attributes,
      product.items,
      slug,
      selectedItemNumber,
      initialBillToSite
    );
    let currentSelectedItem: any;
    let currentProductShipFromWarehouse: any;

    if (product.items && product.items.length === 1) {
      currentSelectedItem = product.items[0];
      setUseStateSelectedItem(currentSelectedItem);
      setSelectedItemAttributes(currentSelectedItem, initialAttribute);
      currentProductShipFromWarehouse = await setWarehouse(currentSelectedItem, singleItemPrice);

      // Sets reservableAmount for store item if present
      const currentStoreItem = product.items[0].quantities.find((x: any) => x.subInventory === 'STR');
      currentStoreItem ? setReservableAmount(currentStoreItem.qtyReservable) : setReservableAmount(0);

      await setProductPriceAsync(
        initialBillToSite,
        initialAttribute,
        currentProductShipFromWarehouse,
        currentSelectedItem
      );
    } else if (selectedItemNumber) {
      // this case covers if passed itemNumber through stateParams by internal routing
      currentSelectedItem = product.items.find((item: any) => item.itemNumber === selectedItemNumber);
      setUseStateSelectedItem(currentSelectedItem);
      if (currentSelectedItem) {
        const result = setSelectedItemAttributes(currentSelectedItem, initialAttribute);
        let tempAttribute = JSON.parse(JSON.stringify(initialAttribute));
        for (const attr of attributes) {
          const selectedItemAttr = currentSelectedItem.attributes.find((resAttr: any) => resAttr.name === attr.name);
          tempAttribute = filterSequentialAttributes(selectedItemAttr, initialAttribute);
        }
        currentProductShipFromWarehouse = await setWarehouse(currentSelectedItem, singleItemPrice);
        await setProductPriceAsync(
          initialBillToSite,
          tempAttribute,
          currentProductShipFromWarehouse,
          currentSelectedItem
        );

        setSelectedItem(currentSelectedItem, result.isAttributeValid, result.isCompleteAtrribute);
      }
    }

    if (attributes && attributes.length === 0) {
      checkReservableQuantity(
        currentSelectedItem.quantities,
        currentProductShipFromWarehouse.warehouseCode,
        currentSelectedItem.id
      );
    }

    if (product.requireOrderingInQuantitiesOfTwo) {
      setQuantity(2);
    }

    const isNonMarketRep = await hasPermission('urn:csa:commerceui:addToCart:Store', userActions);

    if (!isNonMarketRep && product.productType === 'Store') {
      let tempAlertData = JSON.parse(JSON.stringify(alertData));
      tempAlertData.push({
        type: 'warning',
        msg: 'Oh no! You are a Market Rep trying to order store items. Please contact your DM about purchasing this item.',
        timeout: null,
        viewCart: false,
      });
      setAlertData(tempAlertData);
    }
    const results = await setProductGroups(product);
    setUseStateProductGroups(results);
    setIsInitializing(false);
  }, [
    cart,
    singleItemPrice,
    alertData,
    attributes,
    checkForProductItems,
    fillAttributes,
    filterSequentialAttributes,
    product,
    selectedItemNumber,
    setBillTo,
    setCollectionOnProducts,
    setProductPriceAsync,
    setSavedAttributes,
    setSelectedItem,
    setSelectedItemAttributes,
    setWarehouse,
    checkReservableQuantity,
    slug,
    userActions,
  ]);

  useEffect(() => {
    if (!hasAlreadyInitialized) {
      setHasAlreadyInitialized(true);
      init();
    }
  }, [init, hasAlreadyInitialized]);

  return (
    <StandardPage>
      <section className="container">
        {alertData && alertData.length > 0 ? (
          alertData.map((alert: any, index: number) => {
            return (
              <CambriaAlerts
                key={`${index}${alert.msg}`}
                type={alert.type}
                msg={alert.msg}
                timeout={alert.timeout}
                viewCart={alert.viewCart}
                setAlertData={setAlertDataFunction}></CambriaAlerts>
            );
          })
        ) : (
          <div></div>
        )}

        <BundleValidationsAlerts bundleValidation={bundleValidation} />

        <div className="row product product-section">
          <Col xs={12} className={`product-header `}>
            <h1 className="design-header col-xs-12">
              {product.isNew ? <div className="new-design">New </div> : <div></div>}
              {product.displayName}
              {product.isTrademarked ? <span>&trade;</span> : <div></div>}
            </h1>
            <Col xs={12} className=" product-details-page-design-series-container">
              <br />
              {product.luxuryDesign ? (
                <img
                  className="luxury-series"
                  src="https://embed.widencdn.net/img/cambriausa/uem2bbifz9/exact/luxury-series-logo-1000x176.png"
                  alt="Luxury Design"
                />
              ) : (
                <div></div>
              )}
              {product.designSeries === 'Signature' ? (
                <img
                  className="signature-series"
                  src="https://embed.widencdn.net/img/cambriausa/2f02ebfbjh/exact/Signature Series_Final_outline.png"
                  alt="Signature"
                />
              ) : (
                <div></div>
              )}
              {product.designSeries === 'Grandeur' ? (
                <img
                  className="grandeur-series"
                  src="https://cambriausa.widen.net/content/wqbhjrqexx/png/Grandeur-logo_2022.png?crop=false&position=c&color=ffffff00&u=jjx44y&w=931&h=217"
                  alt="Grandeur"
                />
              ) : (
                <div></div>
              )}
            </Col>
            <Row>
              {isQuartz ? (
                <Col xs={12} lg={2} className=" product-details-item-number-text-container">
                  <div className="clearfix"></div>
                  {selectedItem && selectedItem.itemNumber ? (
                    <span className="item-number">
                      Item Number:
                      {selectedItem.itemNumber}
                    </span>
                  ) : (
                    <div></div>
                  )}
                </Col>
              ) : (
                <Col xs={12} lg={2} className=" product-details-item-number-text-container">
                  <div className="patent-information">
                    {selectedItem && selectedItem.itemNumber ? (
                      <span className="item-number">
                        Item Number:
                        {selectedItem.itemNumber}
                      </span>
                    ) : (
                      <div></div>
                    )}
                  </div>
                </Col>
              )}
              {productGroups && productGroups.length > 0 && !productGroups[0].Message ? (
                <Col xs={12} lg={3} className=" product-group">
                  {productGroups.map((product: any, index: number) => {
                    return (
                      <span className="product-details-additional-designs-button-container" key={product.id + index}>
                        <CambriaButton
                          className="btn product-details-additional-designs-button"
                          onClick={() => goToPdp(product.id)}>
                          ADDITIONAL DESIGNS
                        </CambriaButton>
                      </span>
                    );
                  })}
                </Col>
              ) : (
                <div></div>
              )}
            </Row>
          </Col>
          <Col xs={12} lg={5} className="product-details-image-zoom-component-container">
            {images.length > 0 ? (
              <ProductImageZoom
                hideCarousel={hideCarousel}
                displayImage={displayImage}
                images={images}
                imagesPerSlide={5}></ProductImageZoom>
            ) : (
              <div></div>
            )}
            {attributes.length > 0 ? (
              <div>
                {!isQuartz ? (
                  <h3 className="collection-header">
                    {product.collection ? (
                      <span dangerouslySetInnerHTML={{__html: product.collection}}></span>
                    ) : (
                      <div></div>
                    )}
                  </h3>
                ) : (
                  <div></div>
                )}
                <div
                  className="market-description m-b-sm"
                  dangerouslySetInnerHTML={{__html: product.description}}></div>
              </div>
            ) : (
              <div></div>
            )}
          </Col>
          {!isInitializing ? (
            <Col xs={12} lg={7} className=" product-details">
              <Formik
                enableReinitialize
                initialValues={formikFormValues.initialValues}
                validationSchema={Yup.object(formikFormValues.validationSchema)}
                onSubmit={() => {}}>
                {(props) => {
                  return (
                    <Form id="productAttributesForm" noValidate>
                      <input id="multipleRecords" name="multipleRecords" type="hidden" value={isMultipleRecords} />
                      <Row>
                        <Col xs={12} md={5} className="">
                          {attributes.map((attr: any, index: number) => {
                            return (
                              <div key={attr.name + index}>
                                <CambriaSelect
                                  formikFormProps={props}
                                  name={attr.name}
                                  placeholder={'Select ' + attr.description}
                                  addOnTemplate={attr.name === 'Size' ? 'Sq Ft' : undefined}
                                  defaultValue={
                                    attribute && attribute[attr.name] && attribute[attr.name].selected
                                      ? attribute[attr.name].selected
                                      : ''
                                  }
                                  label={attr.description}
                                  items={attribute ? attribute[attr.name].selections : []}
                                  displayValue={'value'}
                                  disabled={
                                    attribute && attribute[attr.name].selections.length > 0
                                      ? attribute[attr.name].disabled
                                      : true
                                  }
                                  required={true}
                                  onChange={(item: any) => {
                                    props.setFieldValue('quantity', quantityDefaultValue);
                                    onQuantityChange(quantityDefaultValue);
                                    onSelectAttribute(item, props);
                                  }}></CambriaSelect>
                                {attribute && attribute[attr.name] && attribute[attr.name].error ? (
                                  <span className="attribute-select-errorMessaging">
                                    {attribute[attr.name].errorMsg}
                                  </span>
                                ) : (
                                  <div></div>
                                )}
                              </div>
                            );
                          })}

                          {attributes.length === 0 ? (
                            <div>
                              <h3 className="collection-header">
                                {product.collection ? (
                                  <span dangerouslySetInnerHTML={{__html: product.collection}}></span>
                                ) : (
                                  <div></div>
                                )}
                              </h3>
                              <div
                                className="market-description m-b-sm"
                                dangerouslySetInnerHTML={{__html: product.description}}></div>
                            </div>
                          ) : (
                            <div></div>
                          )}
                        </Col>
                        <Col xs={12} md={7}>
                          <div className="price-summary-container">
                            {Math.abs(singleItemPrice?.DiscountAmount) > 0 && (
                              <div className="discounted">
                                <SingleItemPrice
                                  price={singleItemPrice.SellingPrice + Math.abs(singleItemPrice.DiscountAmount)}
                                  currencyCode={singleItemPrice.CurrencyCode}
                                  pricingUom={singleItemPrice.PricingUom}
                                />
                              </div>
                            )}
                            {singleItemPrice && (
                              <SingleItemPrice
                                price={singleItemPrice.SellingPrice}
                                currencyCode={singleItemPrice.CurrencyCode}
                                pricingUom={singleItemPrice.PricingUom}
                              />
                            )}

                            {bundleHelpText && <div className="bundle-program-qty-alert">{bundleHelpText}</div>}

                            {isBundleProgram &&
                              !halfBundleQuantity &&
                              attribute?.Size?.selected?.value &&
                              attribute?.Thickness?.selected?.value && (
                                <div className="bundle-program-qty-alert no-bundle-rule">
                                  There is no bundle program rules set for items of thickness{' '}
                                  {attribute?.Thickness?.selected?.value} and size {attribute?.Size?.selected?.value}
                                </div>
                              )}

                            {hasPermission('urn:csa:commerceui:addToCart:'.concat(product.productType), userActions) ? (
                              <div className="price-summary p-t-sm p-b-sm">
                                <div className="quantity-row m-a-0">
                                  <div className="product-details-page-quantity-text">QUANTITY</div>
                                  <div className="product-details-page-counter-container">
                                    <CambriaInput
                                      name="quantity"
                                      defaultValue={quantityDefaultValue}
                                      max={bundleMaxQuantity}
                                      type="counter"
                                      step={quantityDefaultValue}
                                      required={true}
                                      allowEmptyValue={false}
                                      disabled={
                                        (product.productType === 'Store' && !reservableAmount && !isCustom) ||
                                        (bundleProgramRulesStatus === 'loading' && isBundleProgram) ||
                                        !!quantityNeeded ||
                                        (bundleMaxQuantity !== null && bundleMaxQuantity === 0)
                                      }
                                      min={quantityDefaultValue}
                                      onChange={(value: number) => {
                                        onQuantityChange(value);
                                      }}></CambriaInput>
                                  </div>
                                </div>
                                {singleItemPrice && singleItemPrice.PricingUom === 'SF' ? (
                                  <Row>
                                    <Col xs={5}>
                                      <label className="control-label m-b-0">Total: </label>
                                    </Col>
                                    <Col xs={7} className="price-data text-right">
                                      {slug === 'slabs' && selectedItem && selectedItem.unitSize ? (
                                        <span className="control-label">
                                          {quantity * (selectedItem.unitSize || 0)}
                                          {' sq.ft.'}
                                        </span>
                                      ) : (
                                        <div></div>
                                      )}
                                      {slug === 'tile' && selectedItem ? (
                                        <span className="control-label">
                                          {getTileSquareFootage()} {' sq.ft.'}
                                        </span>
                                      ) : (
                                        <div></div>
                                      )}
                                    </Col>
                                    <Col xs={5}>
                                      <label className="control-label m-b-0">Total: </label>
                                    </Col>
                                    <Col xs={7} className="price-data text-right">
                                      <span className="control-label">
                                        {formatPrice(getPrice())}
                                        {singleItemPrice ? ' ' + singleItemPrice.CurrencyCode : ''}
                                      </span>
                                    </Col>
                                  </Row>
                                ) : (
                                  <div></div>
                                )}
                              </div>
                            ) : (
                              <div></div>
                            )}
                            {hasPermission('urn:csa:commerceui:addToCart:'.concat(product.productType), userActions) &&
                            !isInEditMode ? (
                              <div className="product-actions">
                                {hasPermission(
                                  'urn:csa:commerceui:productCatalog:viewReservalbeQuantities',
                                  userActions
                                ) && product.productType === 'Samples' ? (
                                  <div>
                                    {selectedItem &&
                                    selectedItem.quantities &&
                                    reservableAmount &&
                                    reservableAmount > 10 &&
                                    quantity <= 20 ? (
                                      <div>
                                        <div className="in-stock-container row">
                                          <div className="in-stock-circle-container">
                                            <div className="in-stock-circle"></div>
                                          </div>
                                          <h3 className="in-stock-message">In Stock</h3>
                                        </div>
                                      </div>
                                    ) : (
                                      <div></div>
                                    )}
                                    {selectedItem && selectedItem.quantities && quantity > 20 ? (
                                      <div>
                                        <div className="in-stock-over-20-container row">
                                          <div className="in-stock-circle-over-20-container">
                                            <div className="in-stock-over-20-circle"></div>
                                          </div>
                                          <h3 className="in-stock-over-20-message">
                                            Contact {warehouseIs231 ? 'CC' : 'D'}C
                                          </h3>
                                        </div>
                                        <span className="in-stock-over-20-description">
                                          Due to quantity being ordered, please contact{' '}
                                          {warehouseIs231 ? 'CC' : 'your D'}C at
                                          {warehousePhoneNumber}
                                        </span>
                                      </div>
                                    ) : (
                                      <div></div>
                                    )}
                                    {selectedItem &&
                                    selectedItem.quantities &&
                                    reservableAmount &&
                                    reservableAmount <= 10 &&
                                    quantity <= 20 ? (
                                      <div ng-if="" className="limited-stock-container">
                                        <div className="limited-stock-title row">
                                          <div className="limited-stock-circle-container">
                                            <div className="limited-stock-circle"></div>
                                          </div>
                                          <h3 className="limited-stock-message">LOW INVENTORY ITEM</h3>
                                        </div>
                                        <span className="limited-stock-description">
                                          If the need for this item is urgent, call Samples Contact Center at (866)
                                          661-2580
                                        </span>
                                      </div>
                                    ) : (
                                      <div></div>
                                    )}
                                  </div>
                                ) : (
                                  <div></div>
                                )}

                                {/* Store Items Inventory */}
                                {hasPermission(
                                  'urn:csa:commerceui:productCatalog:viewReservalbeQuantities',
                                  userActions
                                ) && product.productType === 'Store' ? (
                                  <div>
                                    {/* No stock */}
                                    {storeStockLoaded && !reservableAmount ? (
                                      <div ng-if="" className="no-stock-container">
                                        <div className="no-stock-title row">
                                          <div className="no-stock-circle-container">
                                            <div className="no-stock-circle"></div>
                                          </div>
                                          <h3 className="no-stock-message">ITEM OUT OF STOCK</h3>
                                        </div>
                                        <span className="no-stock-description">
                                          This item is currently out of stock. Please email thestore@cambriausa.com with
                                          questions.
                                        </span>
                                      </div>
                                    ) : (
                                      <div></div>
                                    )}
                                  </div>
                                ) : (
                                  <div></div>
                                )}

                                <span>
                                  {exceedsStock && product.productType === 'Store' && !isCustom && (
                                    <p style={{color: '#c40f0f'}}>There are only {reservableAmount} items available.</p>
                                  )}

                                  <CambriaButton
                                    className="btn btn-type-a btn-primary product-details-add-to-cart-button"
                                    type="submit"
                                    onClick={() => {
                                      addToCart(true, selectedItem);
                                    }}
                                    disabled={
                                      disableAddToCartButton ||
                                      !quantityIsValid ||
                                      (product.productType === 'Store' && !reservableAmount && !isCustom) ||
                                      disableAddToCartForBundle ||
                                      (isBundleProgram &&
                                        isRevI &&
                                        bundleMaxQuantity != null &&
                                        quantity > bundleMaxQuantity)
                                    }
                                    variant="primary"
                                    id="commerce-add-to-cart">
                                    <Icon icon="icons-cambria-Ui-Store" color="#eee" size="15" weight="bold" />
                                    Add to Cart
                                  </CambriaButton>
                                  <div className="clearfix"></div>
                                </span>
                                {!(isBundleProgram && (isRevH || isRevI)) && (
                                  <span>
                                    <CambriaButton
                                      className="btn product-details-save-for-later-button"
                                      type="submit"
                                      onClick={() => {
                                        addToCart(false, selectedItem);
                                      }}
                                      disabled={
                                        disableSaveForLaterButton || !quantityIsValid || disableAddToCartForBundle
                                      }>
                                      Save for Later
                                    </CambriaButton>
                                  </span>
                                )}
                              </div>
                            ) : (
                              <div></div>
                            )}
                          </div>
                        </Col>
                      </Row>
                    </Form>
                  );
                }}
              </Formik>
            </Col>
          ) : (
            <StandardTotalAndAttributesPlaceholders></StandardTotalAndAttributesPlaceholders>
          )}
        </div>
      </section>
    </StandardPage>
  );
};

export default Standard;
