import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {selectCurrentUser} from '../../../features/auth/slice/authentication.slice';
import {initializeCart} from '../../../features/cart/service/cart.service';
import {useAppDispatch, useTypedSelector} from '../../../hooks/store';
import {
  selectCurrentCustomer,
  selectCurrentCustomerBillToSites,
  selectCurrentCustomerShipToSites,
} from '../../../features/customer/slice/customer.slice';
import {selectActiveProgram, selectAvailablePrograms} from '../../../features/salesforce/slice/salesforce.slice';
import CheckoutRoutes from './Checkout.routing';
import CheckoutNavigation from './CheckoutNavigation';
import CheckoutPlaceholders from './CheckoutPlaceholders';
import {
  hideFullscreenLoader,
  showFullscreenLoader,
} from '../../../features/fullscreenLoader/slice/fullscreenLoader.slice';
import {Spinner} from 'react-bootstrap';
import {selectSelectedWarehouse} from '../../../features/warehouse/slice/warehouse.slice';
import COMMERCE_CORE_CONSTANTS from '../../../Core/constants';
import {selectActiveCart} from '../../../features/cart/slice/cart.slice';

const Checkout = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const activeCart = useTypedSelector(selectActiveCart);
  const activeProgram = useTypedSelector(selectActiveProgram);
  const availablePrograms = useTypedSelector(selectAvailablePrograms);
  const currentUser = useTypedSelector(selectCurrentUser);
  const currentCustomer = useTypedSelector(selectCurrentCustomer);
  const customerShipToSites = useTypedSelector(selectCurrentCustomerShipToSites);
  const customerBillToSites = useTypedSelector(selectCurrentCustomerBillToSites);
  const selectedWarehouse = useTypedSelector(selectSelectedWarehouse);

  const [isThereAnyCartItems, setIsThereAnyCartItems] = useState<boolean>(false);

  useEffect(() => {
    const initCart = async () => {
      dispatch(showFullscreenLoader({showCartIcon: false}));

      if (
        currentCustomer &&
        currentUser &&
        activeProgram &&
        availablePrograms &&
        customerBillToSites &&
        customerShipToSites &&
        !activeCart
      ) {
        try {
          const currentCart = await initializeCart(
            'cdmpComponentResolve',
            currentCustomer,
            parseInt(currentUser?.userId || '0'),
            availablePrograms,
            activeProgram,
            selectedWarehouse?.warehouseCode || null,
            customerShipToSites,
            customerBillToSites,
            dispatch
          );

          if (!currentCart.cartItems || currentCart.cartItems.length === 0) {
            setIsThereAnyCartItems(false);
            history.push(COMMERCE_CORE_CONSTANTS.PATHNAMES.home);
          } else {
            setIsThereAnyCartItems(true);
          }
          dispatch(hideFullscreenLoader());
        } catch (error) {
          dispatch(hideFullscreenLoader());
        }
      } else {
        dispatch(hideFullscreenLoader());
      }
    };
    initCart();
  }, [
    currentCustomer,
    currentUser,
    activeProgram,
    availablePrograms,
    customerBillToSites,
    customerShipToSites,
    history,
    dispatch,
    selectedWarehouse,
    activeCart,
  ]);

  return (
    <div className="react-bootstrap-hack cambria-main-content p-t-lg">
      {(isThereAnyCartItems || activeCart) && currentCustomer ? (
        <CheckoutNavigation>
          <CheckoutRoutes />
        </CheckoutNavigation>
      ) : (
        <>
          <CheckoutPlaceholders />
          <Spinner animation="border"></Spinner>
        </>
      )}
    </div>
  );
};

export default Checkout;
