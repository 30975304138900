import styled from 'styled-components';
import {xlarge, large} from '../../../../Framework/Components/styles/media-queries';
import * as colors from '../../../../Framework/Components/styles/colors';

export const HeaderCDMPContainer = styled.div`
  height: 100%;

  .customer-header-container {
    padding: 0 !important;
  }

  .profile-icon-container {
    padding: 0 !important;
  }

  ${xlarge} {
    .customer-header-container {
      margin-left: -15px;
    }
  }

  ${large} {
    .customer-header-container {
      margin-left: -4px;
    }
  }
`;

export const AccountInfoHeader = styled.div`
  padding: 0 10px;

  .customer {
    color: ${colors.base};
    display: flex;
    align-items: center;

    .customer-name {
      font-weight: bolder;
      text-transform: uppercase;
      font-size: 14px;
      line-height: 14px;
      margin-right: 10px;
    }
  }

  .ordering-through {
    white-space: nowrap;
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;

    .title {
      color: #ccc;
    }

    .description {
      color: ${colors.primary};
      font-weight: bolder;
    }
  }
`;

export const AccountInfoBody = styled.div`
  padding: 0 10px;
`;

export const AccountInfoDropdown = styled.div`
  height: 100%;
  &:hover {
    ${AccountInfoHeader} {
      .customer,
      .ordering-through .title,
      .ordering-through .description,
      .icon {
        color: #333;
      }
    }
  }
`;

export const CartItemsQuantity = styled.span.attrs((props) => ({className: props.className}))`
  &.invalid-cart {
    background: ${colors.danger};
  }

  display: block;
  position: absolute;
  top: 16px;
  margin-left: 30px;
  font-size: 10px;
  line-height: 1.7;
  font-weight: 500;
  background: ${colors.primary};
  color: ${colors.base};
  text-align: center;
  width: 16px;
  height: 16px;
  border-radius: 35px;
`;
