import styled from 'styled-components/macro';
import {$gray30, $newprimaryfont, $screensm} from '../../../Styled/variables';

export const AcademyContainer = styled.div`
  .header {
    font-family: ${$newprimaryfont};
    color: ${$gray30};
    text-align: center;

    h1 {
      font-weight: 400;
      font-size: 36px;
      margin-bottom: 12px;
      text-transform: uppercase;
    }

    h2 {
      font-weight: 400;
      font-size: 24px;
      margin-bottom: 18px;
      text-transform: uppercase;
    }

    p {
      max-width: 60%;
      margin: 0 auto;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
    }
  }

  @media screen and (max-width: ${$screensm}) {
    p {
      max-width: 90%;
    }
  }
`;
