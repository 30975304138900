import styled from 'styled-components/macro';
import {$gray97, $gray30} from '../../../../Styled/variables';

export const AddressCardContainer = styled.div`
  font-size: 14px;
  line-height: 22px;

  p {
    margin-bottom: 0 !important;
    color: ${$gray30};

    &.site-name {
      font-weight: 700;
    }
  }

  .site-info {
    position: relative;
    font-weight: 700;
    background-color: ${$gray97};
    padding: 16px 12px;
    margin: 10px 0;

    &:first-of-type {
      margin-top: 30px;
    }

    .icon {
      margin-right: 10px;
    }

    .site-info-text {
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .far.fa-clone {
      position: absolute;
      top: 6px;
      right: 6px;
      cursor: pointer;
    }
  }
`;
