import React, {FC} from 'react';
import {PaymentTermsInfoContainer} from './PaymentTermsInfo.styled';
import {PaymentSelection} from '../../../../../features/payment/IPaymentState';

interface PaymentTermsInfoProps {
  paymentSelections: PaymentSelection;
}

const PaymentTermsInfo: FC<PaymentTermsInfoProps> = ({paymentSelections}) => {
  return (
    <PaymentTermsInfoContainer>
      <h3>{paymentSelections.name}</h3>
      <p>{paymentSelections.description}</p>
      <ul>
        {!paymentSelections.prepaymentRequired && <li>Prepayment is not required</li>}
        {+paymentSelections.prepaymentMinimumPercent < 100 && (
          <li>Prepayment minimum percent is {paymentSelections.prepaymentMinimumPercent}%</li>
        )}
      </ul>
    </PaymentTermsInfoContainer>
  );
};

export default PaymentTermsInfo;
