import {baseApiCallWithReauth} from '../../../Framework/api-utils/api-utils';
import COMMERCE_CORE_CONSTANTS from '../../../Core/constants';
import qs from 'qs';

export const getCallbacksRequest = async (id: string) => {
  try {
    return await baseApiCallWithReauth(
      'GET',
      `${COMMERCE_CORE_CONSTANTS.API_SERVICES.CALLBACK.callbacks}/${id}`,
      undefined,
      true
    );
  } catch (error: any) {
    throw error;
  }
};

export const getCallbackWithParams = async (params: any) => {
  try {
    return await baseApiCallWithReauth(
      'GET',
      COMMERCE_CORE_CONSTANTS.API_SERVICES.CALLBACK.callbacks,
      qs.stringify(params)
    );
  } catch (error: any) {
    throw error;
  }
};

export const getRelatedStatusesById = async (id: string, params: any) => {
  try {
    return await baseApiCallWithReauth(
      'GET',
      `${COMMERCE_CORE_CONSTANTS.API_SERVICES.CALLBACK.callbacks}/relatedStatuses/${id}`,
      qs.stringify(params),
      true
    );
  } catch (error: any) {
    throw error;
  }
};
