import * as braintree from 'braintree-web';
import {toast} from 'react-toastify';
import COMMERCE_CORE_CONSTANTS from '../../../Core/constants';
import {baseApiCallWithReauth} from '../../../Framework/api-utils/api-utils';

export const addCardInBraintree = async (body: any) => {
  const endpoint = `${COMMERCE_CORE_CONSTANTS.API_SERVICES.PAYMENT.clientToken}`;
  const token = await baseApiCallWithReauth('POST', endpoint, null, true);
  const client = await braintree.client.create({
    authorization: await token.text(),
  });
  return new Promise((resolve, reject) => {
    client.request(
      {
        endpoint: COMMERCE_CORE_CONSTANTS.API_SERVICES.PAYMENT.braintreeCreditCards,
        method: 'post',
        data: {
          creditCard: body,
        },
      },
      async (err: any, res: any) => {
        if (err) {
          toast.error(err.details.originalError.error.message);
          reject(err.details.originalError.error.message);
        } else {
          resolve(res.creditCards[0].nonce);
        }
      }
    );
  });
};
