import styled from 'styled-components/macro';
import {$gray97, $screensm} from '../../../Styled/variables';

export const SearchBodyWrapper = styled.div`
  background: ${$gray97};
  .section {
    width: 100%;
    min-height: fit-content !important;
    padding-bottom: 20px;
    background: ${$gray97};
  }
  .container {
    margin-right: auto;
    margin-left: auto;
    padding-right: 0px;
    padding-left: 0px;
  }
  .tbody,
  .order-table > table > tbody > tr > td,
  .order-table > table > tbody > tr > th {
    border-top: none;
  }
  @media screen and (max-width: ${$screensm}) {
    .container {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
`;
