import React, {useCallback, useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {selectCurrentUser} from '../../../features/auth/slice/authentication.slice';
import {initializeCart} from '../../../features/cart/service/cart.service';
import {useAppDispatch, useTypedSelector} from '../../../hooks/store';
import {
  selectCurrentCustomer,
  selectCurrentCustomerBillToSites,
  selectCurrentCustomerShipToSites,
} from '../../../features/customer/slice/customer.slice';
import {selectCartCalculationsComplete} from '../../../features/cart/slice/cart.slice';
import {selectActiveProgram, selectAvailablePrograms} from '../../../features/salesforce/slice/salesforce.slice';
import PurchaseAgreementPage from './PurchaseAgreementPage';
import PurchaseAgreementPlaceholder from './PurchaseAgreementPlaceholder';
import {
  hideFullscreenLoader,
  showFullscreenLoader,
} from '../../../features/fullscreenLoader/slice/fullscreenLoader.slice';
import {isSurfacesCart} from '../../../features/productType/service/productType.service';
import {toast} from 'react-toastify';
import {hasPermission} from '../../../store/permission/permission.service';
import {selectUserActions} from '../../../features/permission/slice/permission.slice';
import {selectSelectedWarehouse} from '../../../features/warehouse/slice/warehouse.slice';
import {mapCartItems} from '../../../features/itemMapper/service/itemMapper.service';
import COMMERCE_CORE_CONSTANTS from '../../../Core/constants';

const PurchaseAgreement = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const activeProgram = useTypedSelector(selectActiveProgram);
  const availablePrograms = useTypedSelector(selectAvailablePrograms);
  const currentUser = useTypedSelector(selectCurrentUser);
  const currentCustomer = useTypedSelector(selectCurrentCustomer);
  const customerShipToSites = useTypedSelector(selectCurrentCustomerShipToSites);
  const customerBillToSites = useTypedSelector(selectCurrentCustomerBillToSites);
  const cartCalculationsComplete = useTypedSelector(selectCartCalculationsComplete);
  const userActions = useTypedSelector(selectUserActions);
  const selectedWarehouse = useTypedSelector(selectSelectedWarehouse);

  const [showPage, setShowPage] = useState<boolean>(false);
  const [missingEdgeProfile, setMissingEdgeProfile] = useState(false);

  const checkForEdgeProfiles = useCallback((cartItemsArray: any) => {
    for (const cartItem of cartItemsArray) {
      if (cartItem.mainPiece.productGroupCode !== 'GeneralItems') {
        if (cartItem.mainEdgeProfile.length === 0) {
          setMissingEdgeProfile(true);
          toast.error(
            `Piece '${cartItem.mainPiece.pieceLabel}' requires an edge profile. Please add at least 1 edge profile before proceeding to checkout.`
          );
          if (cartItem.mainPieceChildItems.length > 0) {
            checkForEdgeProfiles(cartItem.mainPieceChildItems);
          }
        }
      }
    }
  }, []);

  useEffect(() => {
    const initCart = async () => {
      if (
        currentCustomer &&
        currentUser &&
        activeProgram &&
        availablePrograms &&
        customerBillToSites &&
        customerShipToSites
      ) {
        try {
          dispatch(showFullscreenLoader({showCartIcon: false}));
          const currentCart = await initializeCart(
            'cdmpComponentResolve',
            currentCustomer,
            parseInt(currentUser?.userId || '0'),
            availablePrograms,
            activeProgram,
            selectedWarehouse && selectedWarehouse?.warehouseCode ? selectedWarehouse?.warehouseCode : null,
            customerShipToSites,
            customerBillToSites,
            dispatch
          );

          if (!currentCart.cartItems || currentCart.cartItems.length === 0) {
            setShowPage(false);
            history.push(COMMERCE_CORE_CONSTANTS.PATHNAMES.home);
          }

          const fabItems = currentCart.cartItems.filter(
            (x: any) => x.cartItemType === 'FabAndInstallStandard' || x.cartItemType === 'FabAndInstallDiscrete'
          );
          const cartItemsArray = mapCartItems(currentCart, fabItems);
          checkForEdgeProfiles(cartItemsArray);

          const hasPermissionsToSeePage = await hasPermission(
            'urn:csa:commerceui:fabrication:purchaseagreement:create',
            userActions
          );

          const shouldShowPage =
            currentCart.transactionType !== 'QuoteEditCart' &&
            currentCustomer.classGroups.some((x) => x === 'Surfaces') &&
            isSurfacesCart(currentCart) &&
            (currentCart.cartItems[0].cartItemType === 'FabAndInstallDiscrete' ||
              currentCart.cartItems[0].cartItemType === 'FabAndInstallStandard') &&
            cartCalculationsComplete &&
            !missingEdgeProfile &&
            hasPermissionsToSeePage;

          if (shouldShowPage) {
            setShowPage(true);
          } else {
            setShowPage(false);
            history.push(COMMERCE_CORE_CONSTANTS.PATHNAMES.home);
          }

          dispatch(hideFullscreenLoader());
        } catch (error) {
          dispatch(hideFullscreenLoader());
        }
      }
    };

    initCart();
  }, [
    userActions,
    currentCustomer,
    currentUser,
    activeProgram,
    selectedWarehouse,
    availablePrograms,
    customerBillToSites,
    customerShipToSites,
    history,
    dispatch,
    cartCalculationsComplete,
    checkForEdgeProfiles,
    missingEdgeProfile,
  ]);

  return (
    <div className="react-bootstrap-hack cambria-main-content">
      {showPage && currentCustomer ? <PurchaseAgreementPage /> : <PurchaseAgreementPlaceholder />}
    </div>
  );
};

export default PurchaseAgreement;
