import styled from 'styled-components/macro';
import {$gray50, $newprimary, $newwhite} from '../../../../../Styled/variables';

const MobileQuoteCardPlaceholderContainer = styled.div`
  margin: 10px;
  width: 340px;
  display: grid;
  .mobile-card-body {
    box-shadow: 0px 0px 3px 0px rgba(101, 101, 101, 0.35);
    background: ${$newwhite};
    border-radius: 3px;
    padding: 10px;
    height: 193px;
    .row-1 {
      display: flex;
      justify-content: space-between;
      .rs-placeholder-paragraph {
        margin-left: 15px;
        width: 80% !important;
      }
    }
    .row-2 {
      margin-top: 10px;
    }

    .date-block {
      margin: 0px 15px 15px 0px;
    }
  }
  .amount-details-footer {
    display: flex;
    justify-content: space-between;
    border-radius: 0px 0px 3px 3px;
    background: ${$newwhite};
    font-size: 12px;
    font-weight: 600;
    .amount {
      width: 50%;
      display: flex;
      justify-content: center;
      box-shadow: 0px 0px 3px 0px rgba(101, 101, 101, 0.35);
      padding: 10px;
    }
    .details {
      box-shadow: 0px 0px 3px 0px rgba(101, 101, 101, 0.35);
      width: 50%;
      display: flex;
      justify-content: center;
      padding: 10px;
      color: ${$newprimary};
      :hover {
        cursor: pointer;
      }
    }
  }
  .rs-placeholder {
    width: 100%;
    p {
      background-color: ${$gray50};
    }
  }
`;

export default MobileQuoteCardPlaceholderContainer;
