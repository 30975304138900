import React, {FC, useState} from 'react';
import {useField, useFormikContext} from 'formik';
import {
  CambriaCheckboxContainer,
  CambriaCheckboxField,
  CambriaCheckboxLabel,
  CambriaCounterContainer,
  CambriaCounterError,
  CambriaInputContainer,
  CambriaInputError,
  CambriaInputField,
  CambriaInputLabel,
  CambriaRadioContainer,
  CambriaRadioField,
  CambriaRadioLabel,
  CambriaTextareaError,
  CambriaTextareaField,
} from './CambriaInput.styled';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

interface CambriaInputProps {
  name: string;
  className?: string;
  defaultValue?: any;
  label?: string;
  placeholder?: string;
  checked?: boolean;
  ref?: any;
  type?: 'text' | 'number' | 'radio' | 'checkbox' | 'counter' | 'phoneNumber' | 'textarea';
  disabled?: boolean;
  disableApply?: boolean;
  required?: boolean;
  onChange?: any;
  onBlur?: any;
  onApply?: any;
  min?: number;
  max?: number | null;
  step?: string | number;
  valid?: boolean;
  invalidMessage?: string;
  uppercase?: boolean;
  infoText?: string;
  vertical?: boolean;
  tabIndex?: number;
  handleEmptyValue?: any;
  allowEmptyValue?: boolean;
  roundNumber?: boolean;
}

const CambriaInput: FC<CambriaInputProps> = ({
  name,
  className,
  defaultValue = null,
  label,
  placeholder,
  checked,
  ref,
  type = 'text',
  disabled,
  disableApply,
  required,
  onChange,
  onBlur,
  onApply,
  min,
  max,
  step,
  valid,
  invalidMessage,
  uppercase,
  infoText,
  vertical = false,
  tabIndex,
  handleEmptyValue,
  allowEmptyValue,
  roundNumber,
}) => {
  const [field, meta] = useField(name);
  const {value} = meta;
  const {setFieldValue, handleBlur} = useFormikContext();

  const [prevDefaultValue, setPrevDefaultValue] = useState(null);

  if (prevDefaultValue !== defaultValue) {
    setPrevDefaultValue(defaultValue);
    if (typeof defaultValue !== 'undefined' && defaultValue !== null) {
      setFieldValue(name, defaultValue);
      if (type === 'counter' && onChange) {
        onChange(defaultValue);
      }
    }
  }

  if (type === 'text' || type === 'number') {
    return (
      <CambriaInputContainer className={className}>
        {label && <CambriaInputLabel htmlFor={name}>{label}</CambriaInputLabel>}
        {label && required && <strong> *</strong>}
        {label && infoText && (
          <em className="input-tooltip-wrapper tooltip-wrapper fa fa-question-circle">
            <span className="input-tooltip tooltip">{infoText}</span>
          </em>
        )}
        <div>
          <CambriaInputField
            tabIndex={tabIndex}
            {...field}
            id={name}
            placeholder={placeholder}
            type={type}
            className={(meta.error && meta.touched) || valid === false ? 'input-error' : null}
            ref={ref ? ref : null}
            disabled={disabled}
            min={min ? min : null}
            max={max ? max : null}
            step={step}
            onKeyUp={(e: any) => {
              if (onChange) {
                onChange(e, field);
              }
            }}
            onBlur={(e: any) => {
              field.onBlur(e);
              if (roundNumber) {
                const decimalPlaces =
                  e.target.value.toString().split('.').length > 1 ? e.target.value.toString().split('.')[1].length : 0;
                const int = parseFloat(e.target.value);
                const parsedNumber = decimalPlaces > 2 ? int.toFixed(2).toString() : e.target.value;
                setFieldValue(name, parsedNumber);
                if (onChange) {
                  onChange({target: {value: parsedNumber}}, field);
                }
              }
            }}
            autoComplete="off"
            onChange={(e: any) => {
              if (uppercase) {
                setFieldValue(name, e.target.value.toUpperCase());
              } else {
                setFieldValue(name, e.target.value);
              }
            }}
          />
          {onApply && (
            <button
              disabled={disableApply}
              data-testid={`${name}-apply-button`}
              type="button"
              className="btn btn-link sub-text p-x-0 on-apply-button pull-right"
              onClick={() => {
                onApply();
              }}>
              APPLY
            </button>
          )}
        </div>
        {meta.error && meta.touched && <CambriaInputError>{meta.error}</CambriaInputError>}
        {valid === false && <CambriaInputError>{invalidMessage}</CambriaInputError>}
      </CambriaInputContainer>
    );
  }

  if (type === 'counter') {
    return (
      <CambriaCounterContainer className={className}>
        {label && <CambriaInputLabel htmlFor={name}>{label}</CambriaInputLabel>}
        {label && required && <strong> *</strong>}
        <div className="cambria-counter col-xs-12">
          <div className="cambria-counter-plus-minus-container col-xs-12">
            <button
              data-testid={name + '-cambria-counter-minus'}
              className="col-xs-6 counter-minus-icon-container"
              type="button"
              disabled={disabled}
              onClick={() => {
                const numValue = parseInt(field.value);
                const delta = step ? Number(step) : 1;
                const valueToSet = min === null || min === undefined || numValue > min ? numValue - delta : min;
                onChange(valueToSet);
                setFieldValue(name, valueToSet);
              }}>
              <span className="icons-cambria-Symbols-Minus counter-minus-icon"></span>
            </button>
            <CambriaInputField
              data-testid={name + '-cambria-counter-input-field'}
              {...field}
              id={name}
              placeholder={placeholder}
              type={'text'}
              className={'cambria-counter-input col-xs-12 ' + (meta.error && meta.touched ? 'input-error' : null)}
              onBlur={() => {
                let numValue = parseInt(field.value);
                if (onChange) {
                  if (step && numValue % Number(step) !== 0) {
                    numValue = Math.floor(numValue / Number(step)) * Number(step);
                  }

                  if (max !== null && max !== undefined && numValue > max) {
                    onChange(max);
                    setFieldValue(name, max);
                  } else if (numValue < (min || 0)) {
                    onChange(min);
                    setFieldValue(name, min);
                  } else {
                    onChange(numValue);
                    setFieldValue(name, numValue);
                  }
                } else if (handleEmptyValue && field.value === '') {
                  handleEmptyValue();
                }
              }}
              onKeyUp={(e: any) => {
                if (e.key === 'Enter') {
                  if (field.value === '0' || field.value === '') {
                    handleEmptyValue ? handleEmptyValue() : setFieldValue(name, min);
                  }
                  onChange(field.value);
                } else {
                  let counterValue = field.value;
                  const regex = new RegExp(/^[1-9]+[0-9]*$/);
                  const isValid = regex.test(counterValue);
                  if (!isValid) {
                    const validValue = counterValue.replace(/\D/g, '');
                    counterValue = validValue;
                  }
                  if (counterValue === '' && !allowEmptyValue) {
                    const filledValue = counterValue.replace('', min);
                    counterValue = filledValue;
                  }
                  setFieldValue(name, counterValue);
                }
              }}
              ref={ref ? ref : null}
              disabled={disabled}
              min={min ? min : null}
              max={max ? max : null}
            />
            <button
              data-testid={name + '-cambria-counter-plus'}
              className="col-xs-6 counter-plus-icon-container"
              type="button"
              disabled={disabled}
              onClick={() => {
                const numValue = parseInt(field.value);
                const delta = step ? Number(step) : 1;
                const valueToSet = max === null || max === undefined || numValue < max ? numValue + delta : max;
                onChange(valueToSet);
                setFieldValue(name, valueToSet);
              }}>
              <span className="icons-cambria-Symbols-Plus counter-plus-icon"></span>
            </button>
          </div>
        </div>
        {meta.error && meta.touched && <CambriaCounterError>{meta.error}</CambriaCounterError>}
      </CambriaCounterContainer>
    );
  }

  if (type === 'radio') {
    return (
      <CambriaRadioContainer className={className}>
        <CambriaRadioField
          {...field}
          id={name}
          placeholder={placeholder}
          type={type}
          checked={checked}
          onChange={onChange}
          className={meta.error && meta.touched ? 'input-error' : null}
        />
        <CambriaRadioLabel htmlFor={name}>
          {label} {label && required && <strong> *</strong>}
        </CambriaRadioLabel>
      </CambriaRadioContainer>
    );
  }

  if (type === 'checkbox') {
    const handleKeyDown = (event: {key: string; preventDefault: () => void}) => {
      if (event.key === 'Enter' || event.key === ' ') {
        event.preventDefault();
        if (onChange) {
          onChange(!field.value);
        }
        setFieldValue(name, !field.value);
      }
    };

    return (
      <CambriaCheckboxContainer className={vertical ? 'vertical' : ''}>
        <CambriaCheckboxField
          tabIndex={0}
          {...field}
          id={name}
          type="checkbox"
          checked={field.value}
          disabled={disabled}
          value={field.value}
          onChange={() => {
            if (onChange) {
              onChange(!field.value);
            }
            setFieldValue(name, !field.value);
          }}
          onKeyDown={handleKeyDown}
          className={meta.error && meta.touched ? 'input-error' : null}
        />
        <CambriaCheckboxLabel htmlFor={name}>
          {label} {label && required && <strong> *</strong>}
        </CambriaCheckboxLabel>
        {meta.error && meta.touched && (
          <div className="m-a-sm">
            <CambriaInputError>{meta.error}</CambriaInputError>
          </div>
        )}
      </CambriaCheckboxContainer>
    );
  }

  if (type === 'phoneNumber') {
    return (
      <CambriaInputContainer className={className}>
        {label && <CambriaInputLabel htmlFor={name}>{label}</CambriaInputLabel>}
        {label && required && <strong> *</strong>}
        <PhoneInput
          country="us"
          value={value}
          onChange={(phoneNumber) => {
            if (onChange) {
              onChange(field);
            }
            setFieldValue(name, phoneNumber);
          }}
          onBlur={handleBlur}
          inputProps={{name}}
          inputClass={(meta.error && meta.touched) || valid === false ? 'input-error' : ''}
          disabled={disabled}
          disableCountryCode={true}
          disableDropdown={true}
          placeholder={placeholder}
          onlyCountries={['us', 'can']}
        />
        {meta.error && meta.touched && <CambriaInputError>{meta.error}</CambriaInputError>}
        {valid === false && <CambriaInputError>{invalidMessage}</CambriaInputError>}
      </CambriaInputContainer>
    );
  }

  if (type === 'textarea') {
    return (
      <CambriaInputContainer>
        {label && <CambriaInputLabel htmlFor={name}>{label}</CambriaInputLabel>}
        {label && required && <strong> *</strong>}
        <CambriaTextareaField
          {...field}
          value={field.value}
          id={name}
          placeholder={placeholder}
          component="textarea"
          rows="4"
          disabled={disabled}
          onBlur={(e: any) => {
            if (onBlur) {
              onBlur(e, field);
            }
          }}
          className={
            className
              ? className + 'text-area '
              : '' + ((meta.error && meta.touched) || valid === false ? 'input-error' : null)
          }
          ref={ref ? ref : null}
          onKeyUp={(e: any) => {
            if (onChange) {
              onChange(e, field);
            }
          }}
          autoComplete="off"
        />
        {meta.error && meta.touched && <CambriaTextareaError>{meta.error}</CambriaTextareaError>}
        {valid === false && <CambriaTextareaError>{invalidMessage}</CambriaTextareaError>}
      </CambriaInputContainer>
    );
  }

  return <>wrong type</>;
};

export default CambriaInput;
