import React, {FormEvent, useState} from 'react';
import {LoginFormContainer} from './LoginForm.styled';
import {loginAsync} from '../../../../features/auth/slice/authentication.slice';
import {useAppDispatch, useTypedSelector} from '../../../../hooks/store';
import {Link} from 'react-router-dom';
import {
  selectPreviousUser,
  setIsPostLoginStateActive,
  setPreviousUser,
} from '../../../../features/postLoginState/slice/postLoginState.slice';

const LoginForm = () => {
  const dispatch = useAppDispatch();

  const previousUser = useTypedSelector(selectPreviousUser);

  const [errorMessage, setErrorMessage] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!email && !password) {
      setErrorMessage('Please input your email and password');
      return;
    }

    if (!email) {
      setErrorMessage('Please input your email');
      return;
    }

    if (!password) {
      setErrorMessage('Please input your password');
      return;
    }

    if (email === previousUser) {
      dispatch(setIsPostLoginStateActive(true));
    } else {
      dispatch(setIsPostLoginStateActive(false));
    }

    dispatch(setPreviousUser(email));

    const result: any = await dispatch(
      loginAsync({
        email: email,
        password: password,
        rememberMe: rememberMe,
      })
    );

    if (result.payload.error) {
      if (result.payload.overrideErrorMessage) {
        setErrorMessage(result.payload.error);
      } else {
        setErrorMessage('Could not verify the username or password. Use your email address as a username.');
      }
    }
  };

  return (
    <LoginFormContainer onSubmit={handleSubmit}>
      <h3 className="title">User Login</h3>
      {errorMessage && (
        <div className="error-container">
          <p>
            <span>{errorMessage}</span>
          </p>
        </div>
      )}
      <div className="form-group has-feedback">
        <input
          type="email"
          name="username"
          placeholder="Username"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>
      <div className="form-group has-feedback">
        <input
          type="password"
          name="password"
          value={password}
          placeholder="Password"
          onChange={(e) => setPassword(e.target.value)}
        />
      </div>
      <div className="checkbox">
        <input id="checkbox-2" type="checkbox" checked={rememberMe} onChange={(e) => setRememberMe(e.target.checked)} />
        <label htmlFor="checkbox-2">Remember Me</label>
      </div>
      <div className="privacy-terms">
        By clicking “SIGN IN” below, you agree to Cambria's{' '}
        <a href="https://www.cambriausa.com/product-supply/" target="_blank" rel="noopener noreferrer">
          Terms and Conditions
        </a>{' '}
        and{' '}
        <a href="https://www.cambriausa.com/privacy/" target="_blank" rel="noopener noreferrer">
          Privacy Notice
        </a>
        .
      </div>
      <button id="LoginSignIn" type="submit" className="submit-button">
        Sign In
      </button>
      <div className="forgot-password">
        <Link className="link-forgot col-xs-12" to="/passwordReset">
          <em>Forgot your password?</em>
        </Link>
      </div>
    </LoginFormContainer>
  );
};

export default LoginForm;
