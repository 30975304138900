import styled from 'styled-components/macro';
import {Field} from 'formik';
import {$newdanger, $newprimary, $newwhite, $gray78, $newblack, $gray30} from '../../../Styled/variables';

export const CambriaInputContainer = styled.div`
  position: relative;
  font-size: 16px;
  line-height: 22px;
  padding-bottom: 17px;
  margin-bottom: 2px;

  .input-tooltip-wrapper {
    position: absolute !important;
    margin-left: 10px !important;
    margin-top: 2px !important;
    width: 17px;
    height: 17px;
    .input-tooltip {
      top: 10px !important;
      left: 7px !important;
      position: relative !important;
    }
  }

  .react-tel-input {
    .flag-dropdown {
      display: none;
    }
    .form-control {
      padding-left: 18px;
      border: 1px solid #f1f1f4;
      box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
      width: 100%;
      font-family: Montserrat;
      font-weight: 500;
      background-color: ${$newwhite} !important;
      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:active,
      &:-webkit-autofill:focus {
        transition: background-color 5000s ease-in-out 0s;
      }

      &::placeholder {
        font-weight: 400 !important;
        color: ${$gray78};
        color: ${$gray78};
      }

      &:disabled {
        -webkit-text-fill-color: ${$gray78} !important;
        color: ${$gray78} !important;
        cursor: not-allowed !important;
      }

      &.input-error {
        border-color: ${$newdanger} !important;
      }
    }
  }

  .on-apply-button {
    position: absolute;
    right: 15px;
    bottom: 19px;
    text-decoration: underline;
  }
`;

export const CambriaTextArea = styled.div`
  textarea {
    /* width */
    &::-webkit-scrollbar {
      width: 10px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: white;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #c59617;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #977312;
    }

    width: 100%;
    max-width: 100%;
    min-height: 100px;
    overflow-y: scroll;
    resize: none;
    box-shadow: 0px 1px 1px rgb(0 0 0 / 20%);
  }
`;

export const CambriaCounterContainer = styled.div`
  .cambria-counter {
    padding: 0px;
    width: 100%;
    margin-bottom: 20px;
    .cambria-counter-input {
      text-align: center;
      float: left;
    }
    .cambria-counter-plus-minus-container {
      padding: 0;
      position: relative;
      align-content: center;
      .counter-plus-icon-container,
      .counter-minus-icon-container {
        float: left;
        border: none;
        background: transparent;
        margin: 0;
        padding: 0;
        position: absolute;
        z-index: 4;
        height: 35px;
        width: 30px;
      }
      .counter-minus-icon-container {
        left: 0px;
        .counter-minus-icon {
          float: right;
          color: #c59617;
          font-weight: bolder;
        }
      }
      .counter-plus-icon-container {
        right: 0px;
        .counter-plus-icon {
          float: left;
          color: #c59617;
          font-weight: bolder;
        }
      }
    }
  }
`;

export const CambriaCheckboxContainer = styled.div`
  position: relative;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  padding-bottom: 10px;
  display: flex;
  align-items: flex-start;

  &.vertical {
    display: flex;
    flex-flow: column-reverse;
    align-items: center;

    label {
      margin: 1px 0 0 0;
      padding-bottom: 17px;
      font-weight: 700;
      font-size: 16px;
    }
  }

  &:last-child {
    padding-bottom: 0;
  }
`;

export const CambriaRadioContainer = styled.div`
  position: relative;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  padding-bottom: 10px;
  display: flex;
  align-items: center;

  &:last-child {
    padding-bottom: 0;
  }
`;

export const CambriaInputField = styled(Field)`
  width: 100%;
  background-color: ${$newwhite} !important;
  padding: 8px 18px !important;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  font-size: 14px !important;
  line-height: 17px !important;
  color: ${$newblack};
  font-weight: 500 !important;
  transition: 0.5s all;
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:active,
  &:-webkit-autofill:focus {
    transition: background-color 5000s ease-in-out 0s;
  }

  &::placeholder {
    font-weight: 400 !important;
    color: ${$gray78};
  }

  &:disabled {
    -webkit-text-fill-color: ${$gray78} !important;
    color: ${$gray78} !important;
    cursor: not-allowed !important;
  }

  &.input-error {
    border-color: ${$newdanger} !important;
  }
`;

export const CambriaTextareaField = styled(Field)`
  width: 100%;
  background-color: ${$newwhite} !important;
  padding: 8px 18px !important;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  font-size: 14px !important;
  line-height: 17px !important;
  color: ${$newblack};
  font-weight: 500 !important;
  transition: 0.5s all;
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:active,
  &:-webkit-autofill:focus {
    transition: background-color 5000s ease-in-out 0s;
  }

  &::placeholder {
    font-weight: 400 !important;
    color: ${$gray78};
  }

  &:disabled {
    -webkit-text-fill-color: ${$gray78} !important;
    color: ${$gray78} !important;
    cursor: not-allowed !important;
  }

  &.input-error {
    border-color: ${$newdanger} !important;
  }
`;

export const CambriaRadioField = styled(Field)`
  cursor: pointer;
  width: 24px;
  height: 24px;
  position: relative;
  background-color: ${$newwhite};
  border: 1px solid #979797;
  &:checked {
    visibility: hidden;
  }

  &:after {
    margin: 6px;
    width: 12px;
    height: 12px;
    border-radius: 12px;
    content: '';
    display: inline-block;
    visibility: visible;
    cursor: pointer;
  }

  &:checked:before {
    width: 24px;
    height: 24px;
    content: '';
    position: absolute;
    border-radius: 24px;
    display: inline-block;
    visibility: visible;
    border: 1px solid #979797;
  }

  &:checked:after {
    margin: 4px;
    width: 16px;
    height: 16px;
    border-radius: 12px;
    position: relative;
    background-color: ${$newprimary};
    content: '';
    display: inline-block;
    visibility: visible;
    cursor: pointer;
  }
`;

export const CambriaCheckboxField = styled(Field)`
  margin-top: 2px !important;
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
  width: 20px;
  height: 20px;
  border: 2px solid ${$gray30} !important;
  border-radius: 2px;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
  cursor: pointer;

  &:before {
    vertical-align: middle;
    color: #c59617;
    font-family: icomoon;
    font-size: 24px;
    line-height: 16px;
    content: '\\e906';
    transform: scale(0);
    transform-origin: center;
    transition: 60ms transform ease-in-out;
  }
  &:checked::before {
    transform: scale(1);
  }
  &:focus {
    outline: 3px solid #009688;
    outline-offset: 2px;
    box-shadow: 0 0 0px 3px rgba(21, 156, 228, 0.4);
  }
`;

export const CambriaInputLabel = styled.label`
  font-weight: 700;
`;

export const CambriaRadioLabel = styled.label`
  position: absolute;
  cursor: pointer;
  font-weight: 700;
  margin-bottom: 0 !important;
  margin-top: 2px;
  margin-left: 20px;
`;

export const CambriaCheckboxLabel = styled.label`
  cursor: pointer;
  font-weight: 600;
  margin-bottom: 0 !important;
  margin-top: 2px;
  margin-left: 30px;
`;

export const CambriaInputError = styled.span`
  position: absolute;
  left: 0;
  line-height: 1.2;
  margin-top: 4px;
  font-size: 12px;
  font-weight: 500;
  color: ${$newdanger};
`;

export const CambriaTextareaError = styled.span`
  position: absolute;
  left: 0;
  bottom: 2px;
  font-size: 12px;
  font-weight: 500;
  color: ${$newdanger};
`;

export const CambriaCounterError = styled.span`
  position: relative;
  left: 0;
  bottom: 15px;
  font-size: 12px;
  font-weight: 500;
  color: ${$newdanger};
`;
