import {Form, Formik} from 'formik';
import React, {useCallback, useEffect, useState} from 'react';
import {Col, Row} from 'react-bootstrap';
import {CustomerFullProfile} from '../../../../../../../features/customer/ICustomerState';
import {Program} from '../../../../../../../features/salesforce/program/IProgramState';
import CambriaInput from '../../../../../../../Framework/Components/CambriaInput';
import * as Yup from 'yup';
import CambriaSelect from '../../../../../../../Framework/Components/CambriaSelect';
import {useTypedSelector} from '../../../../../../../hooks/store';
import {selectParentPieceLabel} from '../../../../../../../features/fabrication/slice/fabrication.slice';
import {getProductGroupItemsRequest} from '../../../../../../../features/productType/controller/productType.controller';
import {
  ProductTypeAttributes,
  ProductTypeItems,
  ProductTypeResponse,
} from '../../../../../../../features/order/orderDetails/IOrderDetailsState';

export const setAttributes = (productType: ProductTypeResponse): ProductTypeAttributes[] => {
  const attributes = [];
  if (productType.attributes) {
    productType.attributes.sort((a, b) => {
      return a.sequence < b.sequence ? -1 : 1;
    });
    for (const prodAttr of productType.attributes) {
      if (prodAttr && prodAttr.usageType === 'select' && prodAttr.name !== 'Design Name') {
        attributes.push(prodAttr);
      }
    }
  }
  return attributes;
};

const CutoutsForm = ({
  handleChange,
  pieceLabel,
  productGroupCode,
  currentCustomer,
  activeProgramInfo,
  requirePieceLabel = true,
  itemDescription,
  quantity,
}: {
  handleChange: any;
  pieceLabel?: string;
  productGroupCode: string;
  currentCustomer: CustomerFullProfile;
  activeProgramInfo: Program;
  requirePieceLabel?: boolean;
  itemDescription?: string;
  quantity?: number;
}) => {
  const [availableDescriptions, setAvailableDescriptions] = useState<ProductTypeItems[]>([]);
  const [itemDescriptionHasBeenSet, setItemDescriptionHasBeenSet] = useState(false);

  const parentPieceLabel = useTypedSelector(selectParentPieceLabel);

  const getProductTypeOptions = useCallback(async () => {
    let productTypes = await getProductGroupItemsRequest(
      productGroupCode,
      currentCustomer.erpCustomerId.toString(),
      currentCustomer.classGroups,
      activeProgramInfo
    );
    setAvailableDescriptions(productTypes.items);
  }, [productGroupCode, currentCustomer, activeProgramInfo]);

  useEffect(() => {
    if (currentCustomer && activeProgramInfo) {
      getProductTypeOptions();
    }
  }, [currentCustomer, activeProgramInfo, getProductTypeOptions]);

  useEffect(() => {
    if (itemDescription) {
      handleChange('itemDescription', itemDescription);
      setItemDescriptionHasBeenSet(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemDescription]);

  useEffect(() => {
    if (quantity && itemDescriptionHasBeenSet) {
      handleChange('quantity', quantity);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quantity, itemDescriptionHasBeenSet]);

  return (
    <Formik
      validationSchema={Yup.object().shape({
        itemDescription: Yup.mixed().required('This field is required'),
        quantity: Yup.string().required('This field is required'),
        pieceLabel: Yup.string().when('itemDescription', {
          is: () => requirePieceLabel,
          then: Yup.string().required('This field is required'),
          otherwise: Yup.string(),
        }),
      })}
      onSubmit={() => {}}
      initialValues={{
        pieceLabel: pieceLabel
          ? pieceLabel
          : parentPieceLabel
          ? parentPieceLabel
          : !requirePieceLabel
          ? 'NA'
          : undefined,
        quantity: quantity,
      }}>
      <Form>
        <Row>
          <Col>
            <CambriaSelect
              label="ITEM DESCRIPTION"
              required
              defaultValue={itemDescription}
              onChange={(item: ProductTypeItems) => handleChange('itemDescription', item.description)}
              name="itemDescription"
              items={availableDescriptions}
              displayValue="description"
              placeholder="Item Description"
            />
          </Col>
          <Col>
            <CambriaInput
              onChange={(e: any) => handleChange('quantity', e.target.value)}
              required
              name="quantity"
              label="QUANTITY"
              placeholder="Quanity"
            />
          </Col>
          <Col>
            <CambriaInput disabled={!requirePieceLabel} required name="pieceLabel" label="PIECE LABEL" />
          </Col>
        </Row>
      </Form>
    </Formik>
  );
};

export default CutoutsForm;
