import qs from 'qs';
import COMMERCE_CORE_CONSTANTS from '../../../Core/constants';
import {baseApiCallWithReauth} from '../../../Framework/api-utils/api-utils';
import {IBolShipmentReportData, IOpenSampleOrdersReportData, IOpenFabOrdersReportData} from '../IReportsState';

/**
 * Gets Open Samples Order report data
 * @param erpCustomerId SF Account number of the customer
 */
export const getOpenSampleOrdersReportData = (erpCustomerId: number): Promise<IOpenSampleOrdersReportData> => {
  return baseApiCallWithReauth(
    'GET',
    `${COMMERCE_CORE_CONSTANTS.API_SERVICES.CUSTOMER.customers}/${erpCustomerId}${COMMERCE_CORE_CONSTANTS.API_SERVICES.CUSTOMER.openSampleOrdersReport}`
  );
};

/**
 * Downloads Open Samples Order report as xlsx
 * @param erpCustomerId SF Account number of the customer
 */
export const downloadOpenSampleOrdersReportData = async (erpCustomerId: number): Promise<Blob> => {
  const endpoint = `${COMMERCE_CORE_CONSTANTS.API_SERVICES.CUSTOMER.customers}/${erpCustomerId}${COMMERCE_CORE_CONSTANTS.API_SERVICES.CUSTOMER.downloadOpenSampleOrdersReport}`;
  const result = await baseApiCallWithReauth('GET', endpoint, null, true);
  const resultArrayBuffer = await result.arrayBuffer();
  return new Blob([resultArrayBuffer], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  });
};

export const getOpenSampleOrdersReportSubscriptionData = async (erpCustomerId: number, userId: string) => {
  const endpoint = `${COMMERCE_CORE_CONSTANTS.API_SERVICES.CUSTOMER.customers}/${erpCustomerId}/v2/report/subscribe`;
  return await baseApiCallWithReauth(
    'GET',
    endpoint,
    qs.stringify({userId, type: COMMERCE_CORE_CONSTANTS.REPORTS.openSampleOrders})
  );
};

/**
 * Gets Open Fab Order report data
 * @param erpCustomerId SF Account number of the customer
 */
export const getOpenFabOrdersReportData = (erpCustomerId: number): Promise<IOpenFabOrdersReportData> => {
  return baseApiCallWithReauth(
    'GET',
    `${COMMERCE_CORE_CONSTANTS.API_SERVICES.CUSTOMER.customers}/${erpCustomerId}${COMMERCE_CORE_CONSTANTS.API_SERVICES.CUSTOMER.openFabOrdersReport}`
  );
};

/**
 * Downloads Open Fab Order report as pdf
 * @param erpCustomerId SF Account number of the customer
 */
export const downloadOpenFabOrdersReportData = async (erpCustomerId: number): Promise<Blob> => {
  const endpoint = `${COMMERCE_CORE_CONSTANTS.API_SERVICES.CUSTOMER.customers}/${erpCustomerId}${COMMERCE_CORE_CONSTANTS.API_SERVICES.CUSTOMER.downloadOpenFabOrdersReport}`;
  const result = await baseApiCallWithReauth('GET', endpoint, null, true);
  const resultArrayBuffer = await result.arrayBuffer();
  return new Blob([resultArrayBuffer], {
    type: 'application/pdf',
  });
};

export const getOpenFabOrdersReportSubscriptionData = async (erpCustomerId: number, userId: string) => {
  const endpoint = `${COMMERCE_CORE_CONSTANTS.API_SERVICES.CUSTOMER.customers}/${erpCustomerId}/v2/report/subscribe`;
  return await baseApiCallWithReauth(
    'GET',
    endpoint,
    qs.stringify({userId, type: COMMERCE_CORE_CONSTANTS.REPORTS.openFabOrders})
  );
};

export const subscribeToReport = async ({
  email,
  firstName,
  lastName,
  userId,
  type,
  erpCustomerId,
}: {
  email: string;
  firstName: string;
  lastName: string;
  userId: string;
  type: string;
  erpCustomerId: number;
}) => {
  await baseApiCallWithReauth(
    'POST',
    `${COMMERCE_CORE_CONSTANTS.API_SERVICES.CUSTOMER.customers}/${erpCustomerId}/v2/report/subscribe`,
    JSON.stringify({
      email,
      firstName,
      lastName,
      userId,
      type,
    })
  );
};

export const unsubscribeFromReport = async ({
  subscriptionId,
  email,
  erpCustomerId,
}: {
  subscriptionId: number;
  email: string;
  erpCustomerId: number;
}) => {
  await baseApiCallWithReauth(
    'PUT',
    `${COMMERCE_CORE_CONSTANTS.API_SERVICES.CUSTOMER.customers}/${erpCustomerId}/v2/report/subscribe/${subscriptionId}`,
    JSON.stringify({email, isActive: false})
  );
};

/**
 * Gets BOL Shipment report data
 * @param erpCustomerId SF Account number of the customer
 */
export const getBolShipmentReportData = (erpCustomerId: number): Promise<IBolShipmentReportData> => {
  return baseApiCallWithReauth(
    'GET',
    `${COMMERCE_CORE_CONSTANTS.API_SERVICES.CUSTOMER.customers}/${erpCustomerId}${COMMERCE_CORE_CONSTANTS.API_SERVICES.CUSTOMER.bolShipmentReport}`
  );
};

/**
 * Downloads BOL Shipment Order report as xlsx
 * @param erpCustomerId SF Account number of the customer
 */
export const downloadBolShipmentReportData = async (erpCustomerId: number): Promise<Blob> => {
  const endpoint = `${COMMERCE_CORE_CONSTANTS.API_SERVICES.CUSTOMER.customers}/${erpCustomerId}${COMMERCE_CORE_CONSTANTS.API_SERVICES.CUSTOMER.downloadBolShipmentReport}`;
  const result = await baseApiCallWithReauth('GET', endpoint, null, true);
  const resultArrayBuffer = await result.arrayBuffer();
  return new Blob([resultArrayBuffer], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  });
};

export const getBolShipmentReportSubscriptionData = async (erpCustomerId: number, userId: string) => {
  const endpoint = `${COMMERCE_CORE_CONSTANTS.API_SERVICES.CUSTOMER.customers}/${erpCustomerId}/v2/report/subscribe`;
  return await baseApiCallWithReauth(
    'GET',
    endpoint,
    qs.stringify({userId, type: COMMERCE_CORE_CONSTANTS.REPORTS.bolShipment})
  );
};
