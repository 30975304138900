import styled from 'styled-components/macro';
import {medium} from '../../../Framework/Components/styles/media-queries';

export interface HeroCarouselContainerProps {}

export const HomeContainer = styled.div`
  background-color: #f7f8f9 !important;
  position: relative;
  z-index: 0;
`;

export const CarouselContainer = styled.div`
  width: 73vw;
  margin: 0 auto;

  @media (max-width: 960px) {
    width: 100%;
  }
`;

export const HeroCarouselContainer = styled.div<HeroCarouselContainerProps>`
  background-color: #fff;
  padding-top: 100px;

  .home-page-hero-carousel {
    padding: 0px;
  }

  ${medium} {
    padding-top: 30px;
    padding-left: 10px;
    padding-right: 10px;
  }
`;
