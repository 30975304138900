import styled from 'styled-components';
import {$gray30, $gray60, $gray93} from '../../../../../Styled/variables';

export const CustomerStatementsStyledContainer = styled.div`
  .heading {
    display: flex;
    justify-content: space-between;

    .fa-print {
      margin-top: 5px;
    }
  }

  .no-content-found {
    display: flex;
    justify-content: center;
    padding-top: 150px;
  }

  .total-value {
    border-top: 1px solid ${$gray60};
    font-weight: 1000;
  }

  .total-label {
    font-weight: 1000;
  }

  .days-past-due {
    border-bottom: 1px solid ${$gray60};
  }

  .account-name-header {
    padding-bottom: 0px;
    font-size: 28px !important;
  }

  .statement-date-paragraph {
    font-size: 14px;
    font-weight: 600;
    color: ${$gray60};
  }

  .contact-footer {
    margin: 30px 0 60px 0;
  }

  .statement-date {
    font-weight: 600;
    color: ${$gray30};
  }

  thead > tr > th {
    color: ${$gray30} !important;
    font-weight: 600;
    font-size: 14px;
    text-align: center;
  }

  tbody > tr {
    height: 60px;
    vertical-align: middle;

    :nth-of-type(even) {
      background-color: ${$gray93};
    }
  }

  tbody > tr > td {
    font-size: 14px;
    color: #4c4c4c;
    vertical-align: middle !important;
    text-align: center;
  }
`;
