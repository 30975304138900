import {CustomerFullProfile} from '../../customer/ICustomerState';
import {User} from '../../../store/auth/auth.service';
import {baseApiCallWithReauth} from '../../../Framework/api-utils/api-utils';
import COMMERCE_CORE_CONSTANTS from '../../../Core/constants';
import qs from 'qs';
import {Cart} from '../ICartState';

export interface ActiveCartsResponse {
  results: Cart[];
  totalResults: number | null;
}

export const getActiveCarts = async (
  currentCustomer: CustomerFullProfile,
  currentUser: User
): Promise<ActiveCartsResponse> => {
  try {
    return await baseApiCallWithReauth(
      'GET',
      COMMERCE_CORE_CONSTANTS.API_SERVICES.CART.carts,
      qs.stringify({
        customerClass: currentCustomer?.class,
        erpCustomerId: currentCustomer?.erpCustomerId,
        selectedBy: currentUser?.userId,
        userId: currentUser?.userId,
        pager: {
          sort: ['-createdAt'],
        },
      })
    );
  } catch (error: any) {
    throw error;
  }
};

export const getOriginalCart = async (originalCartId: string) => {
  try {
    return await baseApiCallWithReauth(
      'GET',
      `${COMMERCE_CORE_CONSTANTS.API_SERVICES.CART.carts}/${originalCartId}?=true`
    );
  } catch (error: any) {
    throw error;
  }
};
export const getCartsIncludeInactiveRequest = async (cartId: string, includeInactive: boolean) => {
  try {
    return await baseApiCallWithReauth(
      'GET',
      `${COMMERCE_CORE_CONSTANTS.API_SERVICES.CART.carts}/${cartId}?includeInactive=${includeInactive}`
    );
  } catch (error: any) {
    throw error;
  }
};

export const getCartsRequest = async (params: any) => {
  try {
    return await baseApiCallWithReauth(
      'GET',
      `${COMMERCE_CORE_CONSTANTS.API_SERVICES.CART.carts}`,
      qs.stringify(params)
    );
  } catch (error: any) {
    throw error;
  }
};

export const getMultipleCartsRequest = async (data: URLSearchParams) => {
  try {
    return await baseApiCallWithReauth('GET', COMMERCE_CORE_CONSTANTS.API_SERVICES.CART.carts, data);
  } catch (error: any) {
    throw error;
  }
};

export const putCartsRequest = async (cartId: string, params: any) => {
  try {
    return await baseApiCallWithReauth(
      'PUT',
      `${COMMERCE_CORE_CONSTANTS.API_SERVICES.CART.carts}/${cartId}/select`,
      JSON.stringify(params),
      true
    );
  } catch (error: any) {
    throw error;
  }
};

export interface CreateCartRequest {
  userId: string;
  erpCustomerId: number;
  customerClass: string;
  customerClassGroups: string[];
  userSelectedWarehouseCode: string | null;
  oracleSalesRegionName?: string;
  cartTransactionType?: string;
}

export const postCarts = async (params: CreateCartRequest): Promise<Response> => {
  try {
    return await baseApiCallWithReauth(
      'POST',
      COMMERCE_CORE_CONSTANTS.API_SERVICES.CART.carts,
      JSON.stringify(params),
      true
    );
  } catch (error: any) {
    throw error;
  }
};

export const deleteCartsByIdRequest = async (cartId: string) => {
  try {
    return await baseApiCallWithReauth(
      'DELETE',
      `${COMMERCE_CORE_CONSTANTS.API_SERVICES.CART.carts}/${cartId}`,
      null,
      true
    );
  } catch (error: any) {
    throw error;
  }
};

export const postCartResubmissionOrReorder = async (endpoint: string, params: any) => {
  try {
    return await baseApiCallWithReauth('POST', endpoint, params, true);
  } catch (error: any) {
    throw error;
  }
};

/**
 * Call to API endpoint for deleting bundles
 * @param cartId
 * @param bundleId
 * @returns Callback ID
 */
export const deleteCartBundleById = async (cartId: string, bundleId: string): Promise<string> => {
  const response = await baseApiCallWithReauth(
    'DELETE',
    `${COMMERCE_CORE_CONSTANTS.API_SERVICES.PRODUCT.cartItems}/${cartId}/bundle/${bundleId}`,
    null,
    true,
    true
  );
  const callbackId = await response.text();
  return callbackId.replaceAll('"', '');
};
