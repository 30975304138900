import {toast} from 'react-toastify';
import {Product} from '../../../productCatalog/IProductCatalogState';
import {useAppDispatch, useTypedSelector} from '../../../../hooks/store';
import {selectActiveCart} from '../../slice/cart.slice';
import {hideFullscreenLoader, showFullscreenLoader} from '../../../fullscreenLoader/slice/fullscreenLoader.slice';
import {getProduct} from '../../../productCatalog/service/productCatalog.service';
import {
  selectCurrentCustomer,
  selectCurrentCustomerBillToSites,
  selectCurrentCustomerShipToSites,
} from '../../../customer/slice/customer.slice';
import {selectCurrentUser} from '../../../auth/slice/authentication.slice';
import {selectActiveProgram, selectAvailablePrograms} from '../../../salesforce/slice/salesforce.slice';
import {removeCart} from '../../service/cart.service';
import {
  pushRemoveFabPieceFromCartEventToDataLayer,
  pushRemoveFromCartEventToDataLayer,
} from '../../../analytics/service/analytics.service';
import {IFullPieces} from '../../../fabrication/IFabricationState';
import {isFabricatedCart} from '../../../productType/service/productType.service';

export const useRemoveCartButton = () => {
  const dispatch = useAppDispatch();
  const cart = useTypedSelector(selectActiveCart);
  const customer = useTypedSelector(selectCurrentCustomer);
  const shipToSites = useTypedSelector(selectCurrentCustomerShipToSites);
  const billToSites = useTypedSelector(selectCurrentCustomerBillToSites);
  const currentUser = useTypedSelector(selectCurrentUser);
  const activeProgram = useTypedSelector(selectActiveProgram);
  const availablePrograms = useTypedSelector(selectAvailablePrograms);
  const isItAFabricatedCart = cart && isFabricatedCart(cart);

  const removeCartButton = async (): Promise<void> => {
    if (!cart || !shipToSites || !currentUser || !billToSites) return;
    try {
      dispatch(showFullscreenLoader({showCartIcon: true}));
      let getProductsPromise: Promise<Array<Product>> = Promise.resolve([]);
      if (!isItAFabricatedCart) {
        if (cart.cartItems?.length > 0) {
          getProductsPromise = Promise.all(cart.cartItems.map((ci) => getProduct(ci.productId, customer, shipToSites)));
        }
      }
      await removeCart(
        cart.id,
        undefined,
        undefined,
        customer,
        currentUser?.userId,
        activeProgram,
        shipToSites,
        billToSites,
        dispatch,
        availablePrograms
      );

      if (cart?.cartItems?.length > 0) {
        if (!isItAFabricatedCart) {
          const products: Array<Product> = await getProductsPromise;
          pushRemoveFromCartEventToDataLayer(
            cart.cartItems.map((ci) => ({
              cartItem: ci,
              product: products.find((p) => p.id === ci.productId),
              quantity: ci.quantity ?? 1,
            }))
          );
        } else {
          pushRemoveFabPieceFromCartEventToDataLayer(
            cart.cartItems.map((ci) => ({
              pieceModel: {
                mainPiece: ci,
              } as IFullPieces,
              quantity: ci.uomLineQuantity ?? 1,
            }))
          );
        }
      }

      toast.success('All items are removed from the cart.');
    } catch (error: any) {
      toast.error(error);
    } finally {
      dispatch(hideFullscreenLoader());
    }
  };

  return removeCartButton;
};
