import styled from 'styled-components';
import {$screenmd} from '../../../../Styled/variables';

export const MeetTheTeamContainer = styled.div`
  h2 {
    font-weight: 400;
    font-size: 24px;
    margin-bottom: 18px;
    text-transform: uppercase;
    margin-bottom: 30px;
  }

  .image-row {
    margin-bottom: 35px;
  }

  @media screen and (max-width: ${$screenmd}) {
    .image-row {
      margin-bottom: 1rem;
    }
  }
`;
