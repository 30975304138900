import React, {useEffect, useState} from 'react';
import {Col, Row} from 'react-bootstrap';
import {teamMemberImage} from '../../../../features/environment/IEnvironmentState';
import useWindowDimensions from '../../../../hooks/getWindowDimensions';
import {MeetTheTeamContainer} from './MeetTheTeam.styles';

const MeetTheTeam = ({teamMemberImages}: {teamMemberImages: teamMemberImage[]}) => {
  const {width} = useWindowDimensions();

  const [teamMemberPairs, setTeamMemeberPairs] = useState([]);

  useEffect(() => {
    const createPairs = (teamMembers: teamMemberImage[]) => {
      const pairs: any = [];
      for (let i = 0; i < teamMembers.length; i += 2) {
        pairs.push([teamMembers[i], teamMembers[i + 1]]);
      }
      setTeamMemeberPairs(pairs);
    };

    if (teamMemberImages.length > 0 && !teamMemberPairs.length) {
      createPairs(teamMemberImages);
    }
  });

  const RenderTeamMemberImageRow = (teamMemberPair: any) => {
    return (
      <Row className="image-row">
        <Col lg={6} className={`d-flex justify-content-center ${width < 961 ? 'mb-3' : ''}`}>
          <RenderTeamMemberImage image={teamMemberPair.teamMemberPair[0]} />
        </Col>
        {teamMemberPair.teamMemberPair[1] && (
          <Col lg={6} className="d-flex justify-content-center">
            <RenderTeamMemberImage image={teamMemberPair.teamMemberPair[1]} />
          </Col>
        )}
      </Row>
    );
  };

  const RenderTeamMemberImage = ({image}: {image: teamMemberImage}) => {
    return (
      <>
        {width > 640 ? (
          <img width="600" alt={image?.alt} src={image?.link} />
        ) : (
          <img width={width} alt={image?.alt} src={image?.link} />
        )}
      </>
    );
  };

  return (
    <MeetTheTeamContainer>
      <Row>
        <Col className="d-flex justify-content-center">
          <h2>MEET THE TEAM</h2>
        </Col>
      </Row>
      {teamMemberPairs && teamMemberPairs.length > 0 && (
        <>
          {teamMemberPairs.map((teamMemberPair, index) => {
            return <RenderTeamMemberImageRow key={index} teamMemberPair={teamMemberPair} />;
          })}
        </>
      )}
    </MeetTheTeamContainer>
  );
};

export default MeetTheTeam;
