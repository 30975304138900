import styled from 'styled-components/macro';

export const CambriaAlertContainer = styled.div`
  margin: -20px 0px 20px 0px;
  padding: 0px 0px;
  .alert-data-type-success {
    padding: 20px;
    background-color: #dff0d8;
    border-color: #d6e9c6;
    .alert-data-msg-container {
      color: #5cb85c;
    }
  }

  .alert-data-type-warning {
    padding: 20px;
    background-color: #fcf8e3;
    border-color: #faebcc;
    .alert-data-msg-container {
      color: #8a6d3b;
    }
  }

  .alert-data-type-info {
    padding: 20px;
    background-color: #d9edf7;
    border-color: #bce8f1;
    .alert-data-msg-container {
      color: #31708f;
    }
  }

  .alert-data-view-cart-button {
    color: #c59617;
    cursor: pointer;
  }
`;
