import styled from 'styled-components/macro';
import {small} from '../../../../Framework/Components/styles/media-queries';

export interface HeroCarouselContainerProps {}

export const HeroCarouselContainer = styled.div<HeroCarouselContainerProps>`
  margin-top: 15px;
  margin-bottom: 50px;

  .cambria-carousel {
    width: 100% !important;

    .carousel-item {
      .description {
        display: block;
        float: left;
        font-weight: 300 !important;
        text-transform: initial;
        margin-bottom: 20px;
        padding: 0 40px;
      }

      .subheading {
        display: block;
        float: left;
        font-weight: 400 !important;
        font-size: 12px !important;
        margin-bottom: 5px !important;
        padding: 0 40px;

        .subheading-text {
          display: block;
          float: left;
        }

        .subheading-line {
          margin-left: 10px;
          margin-top: 9px;
          width: 10vw;
          border: 1px solid #c59617;
          display: block;
          float: left;
        }
      }
    }
  }

  .carousel-container {
    justify-content: center;
  }

  .carousel-section {
    padding: 0px;
  }

  .carousel-card-group {
    justify-content: center !important;
  }

  .card {
    margin: 0px 15px;
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;
    box-shadow: 0 1px 1px rgb(0 0 0 / 15%);
  }

  .hero-carousel-item-wrapper {
    margin: 0px 15px;
    box-shadow: 0 1px 1px rgb(0 0 0 / 15%);
    background-color: #fff;
  }

  .hero-carousel-text-container {
    margin: auto;
  }

  .hero-carousel-image {
    margin: 12px 0px;
    width: 100%;
  }

  .hero-carousel-item-row {
    background-color: #e9e9e9;
    border-radius: 3px;
  }

  .cta {
    font-weight: 500 !important;
    color: #c59617 !important;
    width: fit-content;
    text-decoration: underline;
    text-underline-offset: 5px;
    text-decoration-thickness: 2px;

    :hover {
      color: rgb(197, 150, 23, 0.5);
    }
  }

  .carousel-heading {
    font-family: Montserrat;
    font-weight: 300;
    font-size: 36px;
    line-height: 44px;
    text-transform: uppercase;
    color: #4c4c4c;
    margin: 50px 0 50px 0;
  }

  .card-title {
    margin-bottom: 20px !important;
  }

  .card-text {
    margin-bottom: 20px !important;
  }

  .card-img-top {
    margin-bottom: 30px;
  }

  .carousel-control-prev {
    width: unset !important;
    left: 60px;
  }

  .carousel-control-next {
    width: unset !important;
  }

  .carousel-dot {
    background-color: black !important;
  }

  .carousel-indicators {
    width: 100%;
    position: absolute;
    bottom: 15px !important;
    right: 0px !important;
    left: 0px !important;
    z-index: 15;
    display: flex;
    justify-content: end;
    margin-left: -30px !important;
    margin-bottom: 0px !important;
    list-style: none;

    .active {
      background-color: #c59617 !important;
    }
  }

  .carousel-indicators li {
    background-color: black !important;
    opacity: 1 !important;
    width: 50px !important;
    height: 5px !important;
    margin: unset !important;
    margin-left: 3px !important;
  }

  .icon {
    color: black !important;
    font-weight: 700 !important;
    top: 190px !important;
  }

  .icons-cambria-Ui-Right-Arrow-No-Tail {
    left: 10px;
  }

  .icons-cambria-Ui-Left-Arrow-No-Tail {
    right: 70px;
  }

  .card-body {
    padding: 0.25rem !important;
    display: contents;
  }

  ${small} {
    .subheading-line {
      max-width: 25% !important;
      width: 50vw !important;
    }

    .cambria-carousel {
      width: 100% !important;

      .carousel-item {
        .description {
          font-size: 20px;
        }
      }
    }

    .cta {
      font-size: 15px;
    }

    .hero-carousel-text-container {
      height: 250px;
    }
  }
`;
