import React from 'react';
import {Col, Row} from 'react-bootstrap';
import DateBlock from '../../../../../../Core/Components/OrderGrid/DateBlock';
import StatusCode from '../../../../../../Core/Components/StatusCode';
import {IQuoteHeaderData} from '../../../../../../features/quote/IQuoteState';
import {formatQuoteDate} from '../../../../../../Framework/Services/formatDate';

const QuoteDetailsHeaderMobile = ({headerData}: {headerData: IQuoteHeaderData}) => {
  return (
    <div className="quote-detail-table-row-mobile">
      <Row className="quote-row-mobile">
        <div className="quote-date-container">
          <DateBlock date={headerData.dateOrdered ? new Date(headerData.dateOrdered) : null} />
        </div>
        <div className="quote-status-container">
          <StatusCode status={headerData.status} />
        </div>
      </Row>
      <Row className="quote-row-mobile">
        <Col>
          <div>
            Created By: <span className="quote-details-value created-by">{headerData.createdBy}</span>
          </div>
          <div className="mobile-header-row">
            Project Name:&nbsp; <span className="quote-details-value"> {headerData.projectName}</span>
          </div>
          <div className="mobile-header-row">
            Quote Number: &nbsp;<span className="quote-details-value"> {headerData.quoteNumber}</span>
          </div>
          <div className="mobile-header-row">
            Subcontractor Name: &nbsp;<span className="quote-details-value"> {headerData.subcontractorName}</span>
          </div>
          {headerData.location && headerData.location.trim().length > 0 && (
            <div className="mobile-header-row">
              Location: &nbsp;<span className="quote-details-value"> {headerData.location}</span>
            </div>
          )}
          <div>
            Expiration Date:{' '}
            <span className="quote-details-value expiration-date">
              {headerData.expirationDate ? formatQuoteDate(new Date(headerData.expirationDate), true) : ''}
            </span>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default QuoteDetailsHeaderMobile;
