import React from 'react';
import {Col, Row} from 'react-bootstrap';
import ContentLoader from 'react-content-loader';
import {StandardPage} from '../Standard.styled';

export function StandardHeaderTextPlaceholders() {
  return (
    <Col xs={12} className={`product-header`}>
      <ContentLoader
        className="pdp-standard-content-loader"
        width={300}
        height={50}
        animate={true}
        foregroundColor={'#dfdfdf'}
        backgroundColor={'#dfdfdf'}>
        <rect x="0" y="0" width="300" height="50" />
      </ContentLoader>
    </Col>
  );
}

export function StandardProductZoomPlaceholders() {
  return (
    <Col xs={12} lg={5} className="placeholder-overflow-class">
      <ContentLoader
        className="pdp-standard-content-loader"
        width={500}
        height={240}
        animate={true}
        foregroundColor={'#dfdfdf'}
        backgroundColor={'#dfdfdf'}>
        <rect x="0" y="0" width="500" height="330" />
      </ContentLoader>
      <br></br>
      <br></br>
      <div className="col-xs-12">
        <ContentLoader
          className="pdp-standard-content-loader"
          width={500}
          height={20}
          animate={true}
          foregroundColor={'#dfdfdf'}
          backgroundColor={'#dfdfdf'}>
          <rect x="0" y="0" width="500" height="20" />
        </ContentLoader>
        <br></br>
        <ContentLoader
          className="pdp-standard-content-loader"
          width={500}
          height={20}
          animate={true}
          foregroundColor={'#dfdfdf'}
          backgroundColor={'#dfdfdf'}>
          <rect x="0" y="0" width="500" height="20" />
        </ContentLoader>
        <br></br>
        <ContentLoader
          className="pdp-standard-content-loader"
          width={500}
          height={20}
          animate={true}
          foregroundColor={'#dfdfdf'}
          backgroundColor={'#dfdfdf'}>
          <rect x="0" y="0" width="500" height="20" />
        </ContentLoader>
        <br></br>
        <ContentLoader
          className="pdp-standard-content-loader"
          width={500}
          height={20}
          animate={true}
          foregroundColor={'#dfdfdf'}
          backgroundColor={'#dfdfdf'}>
          <rect x="0" y="0" width="500" height="20" />
        </ContentLoader>
      </div>
    </Col>
  );
}

export function StandardTotalAndAttributesPlaceholders() {
  return (
    <Col xs={12} lg={7} className="product-details">
      <Row>
        <Col xs={12} md={5} className="placeholder-overflow-class">
          <ContentLoader width={200} height={20} animate={true} foregroundColor={'#dfdfdf'} backgroundColor={'#dfdfdf'}>
            <rect x="0" y="0" width="200" height="20" />
          </ContentLoader>
          <br></br>
          <br></br>
          <ContentLoader width={250} height={30} animate={true} foregroundColor={'#dfdfdf'} backgroundColor={'#dfdfdf'}>
            <rect x="0" y="0" width="250" height="30" />
          </ContentLoader>
          <br></br>
          <br></br>
          <br></br>
          <ContentLoader width={200} height={20} animate={true} foregroundColor={'#dfdfdf'} backgroundColor={'#dfdfdf'}>
            <rect x="0" y="0" width="200" height="20" />
          </ContentLoader>
          <br></br>
          <br></br>
          <ContentLoader width={250} height={30} animate={true} foregroundColor={'#dfdfdf'} backgroundColor={'#dfdfdf'}>
            <rect x="0" y="0" width="250" height="30" />
          </ContentLoader>
          <br></br>
          <br></br>
          <br></br>
          <ContentLoader width={200} height={20} animate={true} foregroundColor={'#dfdfdf'} backgroundColor={'#dfdfdf'}>
            <rect x="0" y="0" width="200" height="20" />
          </ContentLoader>
          <br></br>
          <br></br>
          <ContentLoader width={250} height={30} animate={true} foregroundColor={'#dfdfdf'} backgroundColor={'#dfdfdf'}>
            <rect x="0" y="0" width="250" height="30" />
          </ContentLoader>
          <br></br>
          <br></br>
          <br></br>
        </Col>
        <br></br>
        <br></br>
        <br></br>
        <Col xs={12} md={7}>
          <div className="price-summary-container">
            <div className="price-container">
              <div className="price">
                <ContentLoader
                  width={180}
                  height={40}
                  animate={true}
                  foregroundColor={'#dfdfdf'}
                  backgroundColor={'#dfdfdf'}>
                  <rect x="0" y="0" width="180" height="40" />
                </ContentLoader>
              </div>
            </div>
            <div className="price-summary p-t-sm p-b-sm">
              <Row>
                <Col xs={5} md={6} className="product-details-page-quantity-text">
                  <ContentLoader
                    width={110}
                    height={30}
                    animate={true}
                    foregroundColor={'#dfdfdf'}
                    backgroundColor={'#dfdfdf'}>
                    <rect x="0" y="0" width="110" height="30" />
                  </ContentLoader>
                </Col>
                <Col xs={1}></Col>
                <Col xs={6} md={5} className="product-details-page-counter-container">
                  <div className="counter-placeholder">
                    <ContentLoader
                      width={90}
                      height={30}
                      animate={true}
                      foregroundColor={'#dfdfdf'}
                      backgroundColor={'#dfdfdf'}>
                      <rect x="0" y="0" width="90" height="30" />
                    </ContentLoader>
                  </div>
                </Col>
              </Row>
            </div>
            <span className="add-to-cart-button-placeholder">
              <ContentLoader
                width={200}
                height={30}
                animate={true}
                foregroundColor={'#dfdfdf'}
                backgroundColor={'#dfdfdf'}>
                <rect x="0" y="0" width="200" height="30" />
              </ContentLoader>
            </span>
            <br></br>
            <span className="save-for-later-button-placeholder">
              <ContentLoader
                width={140}
                height={20}
                animate={true}
                foregroundColor={'#dfdfdf'}
                backgroundColor={'#dfdfdf'}>
                <rect x="0" y="0" width="140" height="20" />
              </ContentLoader>
            </span>
          </div>
        </Col>
      </Row>
    </Col>
  );
}

export default function StandardPlaceholders() {
  return (
    <StandardPage>
      <section className="container">
        <div className="row product product-section">
          <StandardHeaderTextPlaceholders></StandardHeaderTextPlaceholders>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <StandardProductZoomPlaceholders></StandardProductZoomPlaceholders>
          <br></br>
          <br></br>
          <br></br>
          <StandardTotalAndAttributesPlaceholders></StandardTotalAndAttributesPlaceholders>
        </div>
      </section>
    </StandardPage>
  );
}
