import React, {FC, useEffect, useState} from 'react';
import {CardInfo, CreditCardRow, CreditCardSelectContainer} from './CreditCardSelect.styled';
import Icon from '../../../../../Framework/Components/Icon';
import {Accordion, Col, Row, useAccordionToggle} from 'react-bootstrap';
import CambriaButton from '../../../../../Framework/Components/CambriaButton';
import {CreditCard} from '../../../../../features/payment/IPaymentState';
import DeleteCreditCardModal from '../../../../../Core/Components/Modals/DeleteCreditCardModal/DeleteCreditCardModal.component';
import EditCreditCardModal from '../../../../../Core/Components/Modals/EditCreditCardModal/EditCreditCardModal.component';

interface CreditCardProps {
  creditCards: CreditCard[];
}

const EditCreditCardToggle: FC<any> = ({children, eventKey}) => {
  const onSelect = useAccordionToggle(eventKey, () => {});

  return <div onClick={onSelect}>{children}</div>;
};

const CreditCardSelect: FC<CreditCardProps> = ({creditCards}) => {
  const [isEditCardModalOpen, setIsEditCardModalOpen] = useState<boolean>(false);
  const [isDeleteCardModalOpen, setIsDeleteCardModalOpen] = useState<boolean>(false);
  const [selectedCreditCard, setSelectedCreditCard] = useState<CreditCard>();
  const [formatedCreditCards, setFormatedCreditCards] = useState<Array<any> | null>(null);

  const toggleEditCardModal = () => {
    setIsEditCardModalOpen((p) => !p);
  };
  const toggleDeleteCardModal = () => {
    setIsDeleteCardModalOpen((p) => !p);
  };

  useEffect(() => {
    if (creditCards && creditCards.length > 0) {
      const tempCreditCards = JSON.parse(JSON.stringify(creditCards));

      tempCreditCards.map((card: any) => {
        if (card.cardType === 'MASTER_CARD') {
          card.displayName = 'MASTER';
        } else {
          card.displayName = card.cardType;
        }
        return card;
      });

      setFormatedCreditCards(tempCreditCards);
    }

    return () => {
      setSelectedCreditCard(undefined);
      setFormatedCreditCards(null);
    };
  }, [creditCards]);

  return (
    <>
      <CreditCardSelectContainer>
        {formatedCreditCards &&
          formatedCreditCards.length > 0 &&
          formatedCreditCards.map((card: any) => (
            <CreditCardRow key={card.createdAt}>
              <Accordion className="credit-card-accordion">
                <EditCreditCardToggle eventKey={card.createdAt}>
                  <Row>
                    <Col xl={6} className="d-flex align-items-center">
                      <Icon
                        icon={`icons-cambria-General-${card.cardType.toLowerCase()}`}
                        color="#000"
                        colorOnHover='color="#000"'
                        size="18"
                        weight="bold"
                        className="m-r-sm"
                      />
                      <CardInfo>
                        {card.displayName} card ending in {card.lastFour}
                      </CardInfo>
                      <Icon
                        icon="icons-cambria-Ui-Down-Arrow-No-Tail"
                        color="#c59617"
                        colorOnHover='color="#c59617"'
                        size="22"
                        weight="bold"
                        className="m-l-sm"
                      />
                    </Col>
                    <Col xl={6}>
                      <CardInfo>{card.expirationDate}</CardInfo>
                    </Col>
                  </Row>
                </EditCreditCardToggle>
                <Accordion.Collapse eventKey={card.createdAt}>
                  <Row className="m-t-sm">
                    <Col xl={4}>
                      <CardInfo>Name on card</CardInfo>
                      <div className="card-accordion-info">{card.cardholderName}</div>
                    </Col>
                    <Col xl={4}>
                      <CardInfo>Postal Code</CardInfo>
                      <div className="card-accordion-info">{card.billingAddress.postalCode}</div>
                    </Col>
                    <Col xl={4} className="d-flex justify-content-end align-items-center">
                      <CambriaButton
                        className="m-r-sm"
                        variant="secondary"
                        onClick={() => {
                          setSelectedCreditCard(() => card);
                          toggleDeleteCardModal();
                        }}>
                        DELETE
                      </CambriaButton>
                      <CambriaButton
                        variant="primary"
                        onClick={() => {
                          setSelectedCreditCard(() => card);
                          toggleEditCardModal();
                        }}>
                        EDIT
                      </CambriaButton>
                    </Col>
                  </Row>
                </Accordion.Collapse>
              </Accordion>
            </CreditCardRow>
          ))}
        {!formatedCreditCards && <p>No Credit/Debit Cards Yet</p>}
      </CreditCardSelectContainer>
      <DeleteCreditCardModal
        show={isDeleteCardModalOpen}
        toggleShow={toggleDeleteCardModal}
        card={selectedCreditCard}
      />
      <EditCreditCardModal
        show={isEditCardModalOpen}
        toggleShow={() => setIsEditCardModalOpen(false)}
        card={selectedCreditCard}
      />
    </>
  );
};

export default CreditCardSelect;
