import styled from 'styled-components';

const DiscountContainer = styled.div`
  .discounts-box {
    background-color: #e9e9e9 !important;
    border: none !important;
    padding: 30px;

    .discount-header-text {
      font-weight: 300 !important;
    }
    .discount-item {
      padding-top: 20px;
      position: relative;
      margin-bottom: 20px;

      .remove-discount-button {
        color: #8c8c8c;
        border: 1px solid #c8c8c8;
        background-color: #f8f8f8;
        transition: all ease-in-out 0.5s;

        &:hover {
          color: #c8c8c8;
        }
        span {
          color: #8c8c8c;
        }
      }

      @media (max-width: 960px) {
        .remove-discount-button {
          margin-top: 20px;
        }
      }

      .dicount-description-text {
        font-weight: bolder;
      }
    }
  }
`;

export default DiscountContainer;
