import styled from 'styled-components/macro';

export const PasswordResetPageContainter = styled.div`
  flex: 1;

  .main-content {
    .transparent-box.customer-care {
      > div {
        max-width: 530px;
      }
    }
  }
`;
