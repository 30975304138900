import React, {useEffect, useState} from 'react';
import {DeliveryScheduleContainer, DeliveryScheduleTable} from './AdvancedDeliverySchedule.styled';
import EditRequestedDeliveryScheduleModal from '../../../../../Core/Components/Modals/EditRequestedDeliveryScheduleModal/EditRequestedDeliveryScheduleModal.component';
import {useAppDispatch, useTypedSelector} from '../../../../../hooks/store';
import {selectActiveCart} from '../../../../../features/cart/slice/cart.slice';
import {getDeliverySchedule} from '../../../../../features/product/service/product.service';
import {selectActiveProgram} from '../../../../../features/salesforce/slice/salesforce.slice';
import {isBundleProgram} from '../../../../../features/salesforce/service/salesforce.service';

interface AdvancedDeliveryScheduleProps {
  scheduledItems: Array<any>;
  dateFieldOptions: any;
}

const AdvancedDeliverySchedule = ({scheduledItems, dateFieldOptions}: AdvancedDeliveryScheduleProps) => {
  const dispatch = useAppDispatch();

  const [itemToEdit, setItemToEdit] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [tableData, setTableData] = useState<Array<any> | null>(null);

  const activeCart = useTypedSelector(selectActiveCart);
  const activeProgram = useTypedSelector(selectActiveProgram);

  const toggleEditModalShow = () => setShowEditModal((p) => !p);

  const renderUpdatedSchedule = async () => {
    if (activeCart) {
      setShowEditModal(false);
      const deliveryDateScheduleData = [];
      const updatedScheduledItems = await getDeliverySchedule(scheduledItems[0].cartId, dispatch);

      updatedScheduledItems.forEach((schedule: any) => {
        activeCart.cartItems.forEach((item: any) => {
          if (item.cartItemId === schedule.cartItemId && item.imageUrl && item.description) {
            schedule.imageUrl = item.imageUrl;
            schedule.description = item.description;
            deliveryDateScheduleData.push(schedule);
          }
        });
      });
      setTableData(() => {
        return updatedScheduledItems
          .map((item: any) => {
            item.requestedDeliveryDate = new Date(item.requestedDeliveryDate).toLocaleDateString('en-US', {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
            });
            return item;
          })
          .sort(
            (a: any, b: any) =>
              new Date(a.requestedDeliveryDate).getTime() - new Date(b.requestedDeliveryDate).getTime()
          );
      });
    }
  };

  useEffect(() => {
    if (scheduledItems && scheduledItems.length > 0) {
      setTableData(() => {
        return scheduledItems
          .map((item: any) => {
            item.requestedDeliveryDate = new Date(item.requestedDeliveryDate).toLocaleDateString('en-US', {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
            });
            return item;
          })
          .sort((a, b) => new Date(a.requestedDeliveryDate).getTime() - new Date(b.requestedDeliveryDate).getTime());
      });
    }
  }, [scheduledItems]);

  if (tableData) {
    return (
      <>
        <DeliveryScheduleContainer className="hidden-after-gfb">
          {tableData.map((item: any, i: any) => (
            <div key={i}>
              <h2>{item.requestedDeliveryDate}</h2>
              <DeliveryScheduleTable className="mobile-table">
                <thead>
                  <tr>
                    <th>Product</th>
                    <th>Quantity</th>
                    <th>Seamed</th>
                    {!isBundleProgram(activeProgram) && <th>Edit</th>}
                  </tr>
                </thead>
                <tbody>
                  <tr key={i}>
                    <td className="description">
                      <img src={item.imageUrl} alt={item.description} />
                      <span className="description-title">{item.description}</span>
                    </td>
                    <td className="text-center">{item.quantity}</td>
                    <td className="text-center" ng-if="requestedDeliverySchedule.shouldShowSeamedColumn">
                      {item.seamed ? <span>Yes</span> : <span>No</span>}
                    </td>
                    {!isBundleProgram(activeProgram) && (
                      <td
                        className="text-center edit"
                        onClick={() => {
                          setItemToEdit(item);
                          toggleEditModalShow();
                        }}>
                        Edit
                      </td>
                    )}
                  </tr>
                </tbody>
              </DeliveryScheduleTable>
            </div>
          ))}
        </DeliveryScheduleContainer>
        <DeliveryScheduleContainer className="hidden-before-gfb">
          <DeliveryScheduleTable>
            <thead>
              <tr>
                <th className="p-a-sm">Requested Date</th>
                <th className="p-a-sm">Product</th>
                <th className="p-a-sm">Quantity</th>
                <th className="p-a-sm">Seamed</th>
                {!isBundleProgram(activeProgram) && <th className="p-a-sm">Edit</th>}
              </tr>
            </thead>
            <tbody>
              {tableData.map((item: any, i: any) => (
                <tr key={i}>
                  <td className="p-a-sm requested-date">{item.requestedDeliveryDate}</td>
                  <td className="description">
                    <img src={item.imageUrl} alt={item.description} />
                    <span className="description-title">{item.description}</span>
                  </td>
                  <td className="p-a-sm">{item.quantity}</td>
                  <td className="p-a-sm" ng-if="requestedDeliverySchedule.shouldShowSeamedColumn">
                    {item.seamed ? <span>Yes</span> : <span>No</span>}
                  </td>
                  {!isBundleProgram(activeProgram) && (
                    <td
                      className="p-a-sm edit"
                      onClick={() => {
                        setItemToEdit(item);
                        toggleEditModalShow();
                      }}>
                      Edit
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </DeliveryScheduleTable>
        </DeliveryScheduleContainer>
        {itemToEdit && (
          <EditRequestedDeliveryScheduleModal
            show={showEditModal}
            toggleShow={toggleEditModalShow}
            itemToEdit={itemToEdit}
            scheduledItems={tableData}
            dateFieldOptions={dateFieldOptions}
            onSubmitCallback={renderUpdatedSchedule}
          />
        )}
      </>
    );
  }

  return <div></div>;
};

export default AdvancedDeliverySchedule;
