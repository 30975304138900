import React, {FC} from 'react';
import CambriaModal from '../../../../Framework/Components/CambriaModal';

interface ConfirmationModalProps {
  show?: boolean;
  toggleShow?: any;
  modalOptions: any;
  onSubmitCallback?: any;
}

const ConfirmationModal: FC<ConfirmationModalProps> = ({show, toggleShow, modalOptions, onSubmitCallback}) => {
  return (
    <CambriaModal
      show={show}
      toggleShow={() => {
        if (toggleShow) {
          toggleShow();
        }
      }}
      hideCancelButton={modalOptions.hideCancelButton}
      hideExitIcon={modalOptions.hideExitIcon}
      disableClickOutsideToClose={modalOptions.disableClickOutsideToClose}
      cancelButton={modalOptions.cancelButton}
      confirmButton={modalOptions.confirmButton}
      heading={modalOptions.heading}
      formName="confirmModal"
      onConfirm={() => {
        if (onSubmitCallback) {
          onSubmitCallback();
        }
      }}>
      <h3 className="m-5 text-center">{modalOptions.body1}</h3>
      <h3 className="m-5 text-center">{modalOptions.body2}</h3>
    </CambriaModal>
  );
};

export default ConfirmationModal;
