import React, {useEffect, useState} from 'react';
import {InterestFormContainer} from './InterestForm.styles';

const InterestForm = () => {
  const [scriptLoaded, setScriptLoaded] = useState(false);

  const loadMarketoScript = (callback: any) => {
    const alreadyExists = document.getElementById('mktoScript');
    if (!alreadyExists) {
      const script = document.createElement('script');
      script.id = 'mktScript';
      script.type = 'text/javascript';
      script.async = true;
      script.src = 'https://landing.go.cambriausa.com/js/forms2/js/forms2.min.js';
      document.body.appendChild(script);
      script.onload = () => {
        callback && callback();
      };
      alreadyExists && callback && callback();
    }
  };

  useEffect(() => {
    loadMarketoScript(() => {
      setScriptLoaded(true);
    });
  }, []);

  useEffect(() => {
    if (scriptLoaded) {
      // Including the Marketo form via Script tag
      // It is not a module, and don't want it loaded unless used
      // Therefore need to ignore this reference for check-types
      // @ts-ignore
      // eslint-disable-next-line no-undef
      MktoForms2.loadForm('https://landing.go.cambriausa.com', '423-TGU-525', 1145, function (form: any) {
        form.onSuccess(function (values: any) {
          form.getFormElem().hide();
          const confirmForm = document.getElementById('confirmform');
          if (confirmForm) {
            confirmForm.style.visibility = 'visible';
          }
          // Returning false prevents form from navigating away
          return false;
        });
      });
    }
  }, [scriptLoaded]);

  return (
    <InterestFormContainer>
      <h2>Interest Form</h2>
      <div className="marketo-form-component">
        <form id="mktoForm_1145"></form>
        <div id="confirmform">
          <p>Thank you for your submission.</p>
        </div>
      </div>
    </InterestFormContainer>
  );
};

export default InterestForm;
