import {Form, Formik} from 'formik';
import React, {useEffect, useState} from 'react';
import CambriaInput from '../../../../../../Framework/Components/CambriaInput';
import * as Yup from 'yup';
import Icon from '../../../../../../Framework/Components/Icon';
import {useAppDispatch, useTypedSelector} from '../../../../../../hooks/store';
import {
  endConsumerSearchAsync,
  getEndConsumersAsync,
  resetEndConsumer,
  resetEndConsumerSearchResults,
  selectEndConsumers,
  selectEndConsumerSearchResults,
  selectFabRetailSiteId,
  selectPurchasingCustomer,
} from '../../../../../../features/fabrication/slice/fabrication.slice';
import EndUserSearchResultsTable from './EndUserSearchResults';
import CambriaButton from '../../../../../../Framework/Components/CambriaButton';
import AddEndUserForm from './AddEndUserForm';
import {selectCurrentUser} from '../../../../../../features/auth/slice/authentication.slice';
import {selectCurrentCustomer} from '../../../../../../features/customer/slice/customer.slice';
import {selectActiveCart} from '../../../../../../features/cart/slice/cart.slice';
import {Col, Row} from 'react-bootstrap';
import {EndConsumer, IEditConsumerData} from '../../../../../../features/customer/ICustomerState';
import {EndUserAddressSearchContainer} from './EndUserAddressSearch.styled';
import {toast} from 'react-toastify';
import {deleteEndConsumersRequest} from '../../../../../../features/fabrication/controller/fabrication.controller';
import {selectUserActions} from '../../../../../../features/permission/slice/permission.slice';
import {hasPermission} from '../../../../../../store/permission/permission.service';
import {formatPhoneNumber} from '../../../../../../features/customer/service/customer.service';

const EndUserAddressSearch = ({isEditDisabled}: {isEditDisabled?: boolean}) => {
  const dispatch = useAppDispatch();

  const endUserSearchResults = useTypedSelector(selectEndConsumerSearchResults);
  const currentUser = useTypedSelector(selectCurrentUser);
  const currentCustomer = useTypedSelector(selectCurrentCustomer);
  const activeCart = useTypedSelector(selectActiveCart);
  const endConsumers = useTypedSelector(selectEndConsumers);
  const purchasingCustomers = useTypedSelector(selectPurchasingCustomer);
  const retailSiteId = useTypedSelector(selectFabRetailSiteId);
  const userActions = useTypedSelector(selectUserActions);

  const [searchString, setSearchString] = useState<string>('');
  const [showEndUserForm, setShowEndUserForm] = useState<boolean>(false);
  const [editEndUserInformation, setEditEndUserInformation] = useState<IEditConsumerData | undefined>();
  const [canSeeSearch, setCanSeeSearch] = useState<boolean>(false);
  const [endConsumerInitialized, setEndConsumerInitialized] = useState<boolean>(false);

  const toggleShow = () => {
    setShowEndUserForm(!showEndUserForm);
    if (showEndUserForm) {
      document.body.classList.remove('disable-scroll');
    }
  };

  const endUserSearch = async (searchQuery: string) => {
    // This needs to be double checked
    await dispatch(
      endConsumerSearchAsync({searchString: searchQuery, userIsAdmin: !!(currentUser && currentUser?.isSysAdmin)})
    );
  };

  const removeEndConsumer = async (id: number) => {
    try {
      await deleteEndConsumersRequest(id);
      toast.success('End consumer removed successfully');
      await dispatch(
        getEndConsumersAsync({
          erpCustomerId: currentCustomer.erpCustomerId,
          cartId: activeCart ? activeCart.id : '',
        })
      );
      await dispatch(resetEndConsumer());
      await dispatch(resetEndConsumerSearchResults());
      setSearchString('');
    } catch (e) {
      console.log(e);
      toast.error('Unable to remove end consumer');
    }
  };

  const setEditInformation = (consumer: EndConsumer) => {
    const editInfo: IEditConsumerData = {
      firstName: consumer.contactFirstName,
      lastName: consumer.contactLastName,
      businessName: consumer.companyName,
      country: consumer.countryCode,
      address1: consumer.address1,
      address2: consumer.address2,
      address3: consumer.address3,
      city: consumer.city,
      state: consumer.stateProvinceCode,
      zipCode: consumer.postalCode,
      endUserPhone: consumer.contactPhoneNumber,
      endUserEmail: consumer.contactEmail,
      jobNumber: consumer.jobNumber,
      isForFabOrder: consumer.isForFabOrder,
      id: consumer.id,
      estimatedInstall: consumer.estimatedInstallDate ? new Date(consumer.estimatedInstallDate) : null,
    };
    setEditEndUserInformation(editInfo);
  };

  useEffect(() => {
    if (
      !endConsumerInitialized &&
      purchasingCustomers &&
      purchasingCustomers.length > 0 &&
      activeCart &&
      activeCart.id &&
      currentCustomer.id &&
      (!endConsumers || endConsumers.length === 0)
    ) {
      setEndConsumerInitialized(true);
      dispatch(
        getEndConsumersAsync({
          erpCustomerId: currentCustomer.erpCustomerId,
          cartId: activeCart.id,
        })
      );
    } else if (endConsumers && endConsumers.length > 0 && (!purchasingCustomers || purchasingCustomers.length === 0)) {
      setEndConsumerInitialized(false);
      dispatch(resetEndConsumer());
    }
  }, [dispatch, currentCustomer, purchasingCustomers, activeCart, endConsumers, endConsumerInitialized]);

  useEffect(() => {
    if (userActions && !canSeeSearch) {
      setCanSeeSearch(hasPermission('urn:csa:commerceui:order:fab:requireEndUserAddressLookup', userActions));
    }
  }, [userActions, canSeeSearch]);

  return (
    <EndUserAddressSearchContainer>
      {!endConsumers || (endConsumers && endConsumers.length === 0) ? (
        <Formik
          initialValues={{}}
          onSubmit={() => {}}
          validationSchema={Yup.object().shape({
            endUserAddressSearch: Yup.string().when('fakeField', {
              is: () => canSeeSearch,
              then: Yup.string().required('This field is required.'),
            }),
          })}>
          <Form className="d-flex align-items-center">
            {canSeeSearch ? (
              <Col xs={8} className="d-flex address-search-bar-container">
                <CambriaInput
                  name="endUserAddressSearch"
                  label="END USER ADDRESS LOOKUP *"
                  placeholder={'End User Address Lookup'}
                  disabled={!retailSiteId || isEditDisabled}
                  onChange={(e: any) => {
                    if (e.which === 13) {
                      endUserSearch(searchString);
                    } else {
                      setSearchString(e.target.value);
                    }
                  }}
                />

                <button className="border-0 bg-transparent pt-3">
                  <Icon
                    icon="fas fa-search"
                    color="#c59617"
                    size="16"
                    weight="600"
                    disabled={!retailSiteId}
                    onClick={() => {
                      if (searchString) {
                        endUserSearch(searchString);
                      }
                    }}
                  />
                </button>
              </Col>
            ) : (
              <></>
            )}
            <Col xs={4} className={`d-flex ${canSeeSearch ? 'align-items-center' : 'flex-column'}`}>
              {canSeeSearch ? (
                <></>
              ) : (
                <>
                  <label>END USER ADDRESS LOOKUP *</label>
                </>
              )}
              <CambriaButton
                className="add-new-address-button"
                variant="secondary"
                disabled={!retailSiteId || isEditDisabled}
                onClick={() => {
                  setEditEndUserInformation(undefined);
                  setShowEndUserForm(true);
                }}>
                <Icon icon="fas fa-plus" color="#c59617" weight="600" size="12" />
                ADD NEW ADDRESS
              </CambriaButton>
            </Col>
          </Form>
        </Formik>
      ) : (
        <Col xs={12} className="bg-transparent">
          <label>END USER ADDRESS LOOKUP</label>
          {endConsumers &&
            endConsumers.length > 0 &&
            endConsumers.map((consumer: EndConsumer, index: number) => {
              return (
                <Col xs={6} key={index} className="consumer-card p-3 bg-white">
                  <Row>
                    <Col className="d-flex align-items-center justify-content-between">
                      <span className="consumer-name">
                        {consumer.contactFirstName} {consumer.contactLastName}
                      </span>
                      {consumer.isUpdatePending ? (
                        <div className="isUpdatePending">
                          <Icon icon="fas fa-exclamation-triangle" color="red" size="16" weight="600" />
                        </div>
                      ) : !isEditDisabled ? (
                        <div className="d-flex align-items-center">
                          <Icon
                            name="editEndUserBtn"
                            className="customer-edit-icon mr-2 mb-1"
                            icon="fas fa-pencil-alt"
                            size="17"
                            weight="600"
                            color="#bcc0c6"
                            colorOnHover="#c59617"
                            onClick={() => {
                              setEditInformation(consumer);
                              toggleShow();
                            }}
                          />
                          <Icon
                            name="removeEndUserBtn"
                            className="float-right"
                            icon="fa fa-times"
                            size="18"
                            weight="600"
                            color="#bcc0c6"
                            colorOnHover="#c59617"
                            onClick={() => removeEndConsumer(consumer.id)}
                          />
                        </div>
                      ) : (
                        <></>
                      )}
                    </Col>
                  </Row>
                  <Row className="consumer-address-row mt-2 mb-2">
                    <Col>
                      <div>
                        {consumer.address1} {consumer.address2} {consumer.address3}
                      </div>

                      <div>
                        {consumer.city}, {consumer.stateProvinceCode} {consumer.postalCode}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="mt-2">
                      <div className={`contact-line-item d-flex align-items-center`}>
                        <Icon
                          icon="icons-cambria-Symbols-Mail"
                          size="28"
                          weight="900"
                          color="black"
                          colorOnHover="black"
                        />
                        <span className="ml-3 contact-line-item-text-overflow">{consumer.contactEmail}</span>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="mt-2">
                      <div className={`contact-line-item d-flex align-items-center`}>
                        <Icon icon="icons-cambria-Ui-Phone" size="28" weight="900" color="black" colorOnHover="black" />
                        <span className="ml-3">{formatPhoneNumber(consumer.contactPhoneNumber)}</span>
                      </div>
                    </Col>
                  </Row>
                </Col>
              );
            })}
        </Col>
      )}
      {showEndUserForm && (
        <AddEndUserForm
          toggleShow={toggleShow}
          show={showEndUserForm}
          retailSiteId={retailSiteId ? retailSiteId : 0}
          editInformation={editEndUserInformation}
          editMode={!!editEndUserInformation}
        />
      )}
      {endUserSearchResults && endUserSearchResults.length > 0 && endConsumers && endConsumers.length === 0 && (
        <EndUserSearchResultsTable endUserResults={endUserSearchResults} />
      )}
    </EndUserAddressSearchContainer>
  );
};

export default EndUserAddressSearch;
