import React, {FC, useState} from 'react';
import CambriaAutosuggest from '../../../../../Framework/Components/CambriaAutosuggest';
import {getSalesfoceAddresses} from '../../../../../features/salesforce/service/salesforce.service';
import {IBillingAddress} from '../../../../../features/salesforce/ISalesforceState';

export interface SearchAllAddressesInputProps {
  onExistingAddressChange: (address: IBillingAddress) => void;
  disabled: boolean;
}

interface AddressOption {
  displayName: string;
  address: IBillingAddress;
}

const SearchAllAddressesInput: FC<SearchAllAddressesInputProps> = ({onExistingAddressChange, disabled = false}) => {
  const [loading, setLoading] = useState(false);
  const [availableAddresses, setAvailableAddresses] = useState<AddressOption[]>([]);

  const searchSalesforceAddresses = async (keyword: string) => {
    setLoading(true);
    const addresses = await getSalesfoceAddresses(keyword, true);
    setAvailableAddresses(
      addresses.map((x) => ({
        displayName: `${x.name ?? ''} ${x.street?.replace(/(\r\n)|\n/g, ' ')} ${x.city} ${x.state} ${x.postalCode} ${
          x.country
        }`,
        address: x,
      }))
    );
    setLoading(false);
  };

  return (
    <CambriaAutosuggest
      name={'selectExistingAddress'}
      placeholder={'Search Ship To Address...'}
      options={availableAddresses}
      onSearch={(keyword: string) => searchSalesforceAddresses(keyword)}
      onSelect={async (address: AddressOption) => {
        onExistingAddressChange(address.address);
      }}
      shouldLockOnSelectValue={true}
      displayValue={'displayName'}
      disabled={disabled || loading}></CambriaAutosuggest>
  );
};

export default SearchAllAddressesInput;
