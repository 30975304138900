import React from 'react';
import QuoteDetailsContainer from './QuoteDetails.styled';
import {formatQuoteDate} from '../../../../Framework/Services/formatDate';

const QuoteDetails = ({
  projectName,
  quoteNumber,
  subcontractorName,
  location,
  expirationDate,
}: {
  projectName: string;
  quoteNumber: number;
  subcontractorName: string;
  location: string | null;
  expirationDate: string;
}) => {
  return (
    <QuoteDetailsContainer>
      Project Name:<span className="quote-details-value"> {projectName}</span>
      <br />
      Quote #:<span className="quote-details-value"> {quoteNumber}</span>
      <br />
      Subcontractor Name:<span className="quote-details-value"> {subcontractorName}</span>
      <br />
      {location && location.trim().length > 0 && (
        <>
          Location:<span className="quote-details-value"> {location}</span>
          <br />
        </>
      )}
      Expiration Date:{' '}
      <span className="quote-details-value quote-expiration-date">
        {expirationDate ? formatQuoteDate(new Date(expirationDate), true) : ''}
      </span>
    </QuoteDetailsContainer>
  );
};
export default QuoteDetails;
