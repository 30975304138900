import {CartItem, NewCartItem} from '../cart/ICartState';
import {SingleItemPriceResponse} from '../pricing/IPricingState';
import {EndConsumer, IEndConsumerSearchResult, PurchasingCustomer} from '../customer/ICustomerState';
import {ProductTypeItems} from '../order/orderDetails/IOrderDetailsState';

export interface fabSliceInitialState {
  orderApprovedEmails: null | string;
  cadRevisionEmails: null | string;
  poRevisionEmails: null | string;
  selectedDrafter: null | string;
  purchasingCustomers: null | PurchasingCustomer[];
  purchasingCustomersStatus: 'idle' | 'loading' | 'succeeded' | 'failed';
  endConsumer: null | EndConsumer[];
  endConsumerStatus: 'idle' | 'loading' | 'succeeded' | 'failed';
  endConsumerSearchResults: null | IEndConsumerSearchResult[];
  retailSiteId: number | null;
  duplicateBeingEditted: null | IFullPieces;
  duplicatePieceModel: null | IPieceModel;
  parentOfEditPieceLabel: string | null;
  edgeProfileBeingAdded: boolean;
}

export interface IFullPieces {
  mainPiece: CartItem;
  mainEdgeProfile: Array<CartItem>;
  mainPieceChildItems: Array<IFullPieces>;
  cartId: string;
  duplicatedChildPiece: IDuplicatedPieceModel | null;
}

export interface IDuplicatedPieceModel extends IPieceModel {
  designCode: string;
  productGroupCode: string;
  productApplication: string;
  cartItemId: number;
  itemNumber: string;
  uomLineQuantity: number;
}

export interface IProductDesignResponse {
  designCode: string;
  designDescription: string;
  isLuxury: boolean;
  collection: string;
  designSeries: string;
  hdPricingSeries: undefined | null | string;
  pricingSeries: string;
  availabilities: undefined | null | any[];
  programCodes: undefined | null | string[];
}

export interface IEdgeProfile {
  priceCode: string;
  initialLinearInches: number;
  linearFeet: number;
  unitPrice: string | number;
  linePrice: string;
  selectedEdgeProfile: ProductTypeItems | null;
  withoutLinearMeasure: boolean;
  price: SingleItemPriceResponse | null;
  cartItem: CartItem;
}

export const NewEdgeProfile = (): IEdgeProfile => {
  return {
    priceCode: '',
    initialLinearInches: 0,
    linearFeet: 0,
    unitPrice: '',
    linePrice: '',
    selectedEdgeProfile: null,
    withoutLinearMeasure: false,
    price: null,
    cartItem: NewCartItem(),
  };
};

export interface IEmailListsResponse {
  cadApprovedEmailList: string;
  cadRevisionsNeededEmailList: string;
  drafters: string;
  id: number;
  orderApprovedEmailList: string;
  poRevisionsNeededEmailList: string;
}

export interface IFabricatedApplications {
  name: string;
  minimumUomQuantity: number;
}

export interface IServiceType {
  name: string;
  selections: any;
  selected: string;
  disabled: boolean;
  sortBy: any;
  error: boolean;
  errorMsg: string;
}

export interface IPieceModel {
  dimension1: number | null;
  dimension2: number | null;
  dimension3: number | null;
  dimension4: number | null;
  areaOfDimension12: number | null;
  areaOfDimension34: number | null;
  thickness: string | null;
  finish: string | null;
  match: string | null;
  selectedEdgeProfile: string | null;
  selectedFabricatedApplication: IFabricatedApplications | null;
  selectedDesign: IProductDesignResponse | null;
  pieceLabel: string | null;
  productGroupCode: string | null;
  edgeProfiles: IEdgeProfile[];
  selectedServiceType: string | null;
  quantity: number | null;
  itemDescription: string | null;
  parentCartItemId?: string;
}

export const NewProductDesignResponse = (): IProductDesignResponse => {
  return {
    availabilities: undefined,
    hdPricingSeries: undefined,
    pricingSeries: '',
    programCodes: undefined,
    designCode: '',
    designDescription: '',
    isLuxury: false,
    designSeries: '',
    collection: '',
  };
};

export const NewFabricatedApplications = (): IFabricatedApplications => {
  return {name: '', minimumUomQuantity: 0};
};

export const NewPieceModel = (): IPieceModel => {
  return {
    dimension1: null,
    dimension2: null,
    dimension3: null,
    dimension4: null,
    areaOfDimension12: null,
    areaOfDimension34: null,
    thickness: null,
    finish: null,
    match: null,
    selectedEdgeProfile: null,
    selectedFabricatedApplication: null,
    selectedDesign: null,
    pieceLabel: null,
    productGroupCode: null,
    edgeProfiles: [],
    selectedServiceType: null,
    quantity: null,
    itemDescription: null,
  };
};

export interface IFabricationEmailResponse {
  cadApprovedEmailList: string;
  cadRevisionsNeededEmailList: string;
  drafters: string;
  id: number;
  orderApprovedEmailList: string;
  poRevisionsNeededEmailList: string;
}
