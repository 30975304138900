import styled from 'styled-components/macro';
import {$gray50, $newblack} from '../../../../Styled/variables';

export const AddNewCardToggleBtn = styled.span`
  display: inline-block;
  margin: 30px 0;
  font-weight: 600;
  color: ${$gray50};
  cursor: pointer;
`;

export const PaymentPreferencesLabel = styled.p`
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.7px;
  text-transform: uppercase;
  color: ${$newblack};
`;
