import {QuoteSearchParams, QuoteSearchParamsInitialState} from '../../../../../../features/search/ISearchState';
import {formatQuoteCurrency} from '../../../../../../Framework/Services/formatCurrency';
import {formatQuoteDate} from '../../../../../../Framework/Services/formatDate';
import {validUrlParams} from '../filterOptions';

// Iterates through url params and adds them to active filter, search params
export const processQuoteParamsFromUrl = (queryString: string) => {
  let quoteSearchCopy: QuoteSearchParams = QuoteSearchParamsInitialState;
  let params = new URLSearchParams(queryString);
  let paramsList: any[] = [];
  let urlParamsJson: any = {};
  params.forEach((value: string, key: string) => {
    if (isValidFilter(key)) {
      urlParamsJson[key] = value;
      let displayParam = mapToUrlToActiveDisplay(key, value, params);
      if (displayParam) {
        paramsList.push(displayParam);
      }
    }
  });
  quoteSearchCopy = {...quoteSearchCopy, ...urlParamsJson};
  return {paramsList, quoteSearchCopy};
};

export const isValidFilter = (name: string) => {
  let isValid = false;
  validUrlParams.forEach((validFilter: string) => {
    if (name === validFilter) {
      isValid = true;
    }
  });
  return isValid;
};

export const mapToUrlToActiveDisplay = (name: string, value: string, searchQuery: URLSearchParams) => {
  if (name === 'q') {
    return {name: 'Details', value: value};
  } else if (name === 'status') {
    return {name: 'Status', value: value};
  } else if (name === 'quoteId') {
    return {name: 'Quote Id', value: value};
  } else if (name === 'amountRange.amountFrom') {
    let amountTo: any = searchQuery.get('amountRange.amountTo');
    return {
      name: 'Amount',
      value: `$${formatQuoteCurrency(parseInt(value))} to $${formatQuoteCurrency(parseInt(amountTo))}`,
    };
  } else if (name === 'dateRange.dateFrom') {
    let dateRangeTo: any = searchQuery.get('dateRange.dateTo');
    return {
      name: 'Date',
      value: `${formatQuoteDate(new Date(value))} to ${formatQuoteDate(new Date(dateRangeTo))}`,
    };
  }
};
