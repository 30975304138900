import styled from 'styled-components';
import {$gray16, $gray60, $newprimaryfont} from '../../../Styled/variables';

export const CambriaTooltipContainer = styled.div`
  --balloon-color: white;
  --balloon-text-color: ${$gray16};
  --balloon-font-size: 12px;
  &[aria-label][data-balloon-pos]:after {
    border: 1px solid ${$gray60};
    border-radius: 3px;
    font-family: ${$newprimaryfont};
    font-weight: 500;
    line-height: 1.25;
    box-shadow: 0 1px 1px ${$gray60};
  }
`;
