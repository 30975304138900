import React, {useCallback, useEffect, useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {Container} from 'react-bootstrap';
import {QuoteConfirmationPage} from './QuoteConfirmation.styled';
import {useTypedSelector} from '../../../hooks/store';
import {selectCurrentCustomer} from '../../../features/customer/slice/customer.slice';
import CambriaAlerts from '../../../Framework/Components/CambriaAlerts';
import {getQuoteRequest} from '../../../features/quote/quoteDetails/controller/quoteDetails.controller';
import COMMERCE_CORE_CONSTANTS from '../../../Core/constants';

export default function QuoteConfirmation() {
  let [history] = useState(useHistory());

  const customer = useTypedSelector(selectCurrentCustomer);

  let [hasInitialized, setHasInitialized] = useState(false);
  let [param]: any = useState(useParams());
  let [quote, setQuote]: any = useState(null);
  let [alertData, setAlertData]: any = useState([]);

  const init = useCallback(async () => {
    const tempQuote = await getQuoteRequest(param.quoteId, customer.erpCustomerId);
    setQuote(tempQuote);
    setAlertData([
      {
        type: 'info',
        msg: tempQuote.sopTimingMessage,
      },
    ]);
  }, [customer, param]);

  const setAlertDataFunction = useCallback((value: any) => {
    setAlertData(value);
  }, []);

  useEffect(() => {
    if (!hasInitialized) {
      setHasInitialized(true);
      init();
    }
  }, [hasInitialized, init]);

  if (quote && alertData && alertData.length > 0) {
    return (
      <div className="react-bootstrap-hack cambria-main-content">
        <QuoteConfirmationPage>
          <Container className="quote-confirmation">
            <h1 className="quote-confirmation-header">Thank you, your quote has been requested.</h1>
            <div className="step">
              <div className="quote-confirmation-project-name-text">
                PROJECT NAME -{' '}
                <button
                  type="button"
                  className="btn btn-link sub-text p-x-0 quote-confirmation-project-text-button"
                  onClick={() => {
                    history.push(`/account/quotes/details/${param.quoteId}`);
                  }}>
                  {quote.name}
                </button>
              </div>

              <span className="quote-confirmation-continue-browsing-text">
                Continue to the
                <button
                  type="button"
                  className="btn btn-link sub-text p-x-0 quote-confirmation-project-text-button"
                  onClick={() => {
                    history.push(COMMERCE_CORE_CONSTANTS.PATHNAMES.home);
                  }}>
                  home page
                </button>
                to browse additional products.
              </span>
            </div>
            <br />
            {alertData && alertData.length > 0 ? (
              <CambriaAlerts
                className="quote-confirmation-alerts-container"
                type={alertData[0].type}
                msg={alertData[0].msg}
                viewCart={false}
                setAlertData={setAlertDataFunction}></CambriaAlerts>
            ) : (
              <div></div>
            )}
          </Container>
        </QuoteConfirmationPage>
      </div>
    );
  } else {
    return <div></div>;
  }
}
