import {useAppDispatch, useTypedSelector} from '../../../hooks/store';
import {selectCurrentUser} from '../../auth/slice/authentication.slice';
import {
  selectCurrentCustomer,
  selectCurrentCustomerBillToSites,
  selectCurrentCustomerShipToSites,
} from '../../customer/slice/customer.slice';
import {selectCalculateCartDiscountsInBackend} from '../../environment/slice/environment.slice';
import {selectActiveProgram, selectAvailablePrograms} from '../../salesforce/slice/salesforce.slice';
import {calculateStandartDiscounts} from '../service/cart.service';
import {selectActiveCart} from '../slice/cart.slice';

export const useCalculateStandardDiscounts = () => {
  const dispatch = useAppDispatch();
  const cart = useTypedSelector(selectActiveCart);
  const customer = useTypedSelector(selectCurrentCustomer);
  const shipToSites = useTypedSelector(selectCurrentCustomerShipToSites);
  const billToSites = useTypedSelector(selectCurrentCustomerBillToSites);
  const activeProgram = useTypedSelector(selectActiveProgram);
  const availablePrograms = useTypedSelector(selectAvailablePrograms);
  const currentUser = useTypedSelector(selectCurrentUser);
  const calculateCartDiscountsInBackend = useTypedSelector(selectCalculateCartDiscountsInBackend);

  return async () => {
    if (cart && currentUser && shipToSites && billToSites) {
      await calculateStandartDiscounts(
        cart,
        customer,
        currentUser.userId,
        activeProgram,
        shipToSites,
        billToSites,
        dispatch,
        availablePrograms,
        calculateCartDiscountsInBackend
      );
    }
  };
};
