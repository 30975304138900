import styled from 'styled-components/macro';

export const MaintenancePage = styled.div`
  margin-top: 40vh;

  .maintenance-mode-text {
    color: white;
    text-align: center;
  }
`;
