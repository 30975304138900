export function detectBrowser(): string {
  const match: any = window.navigator.userAgent.toLowerCase().match(/(trident|edge|chrome|safari|firefox|ipad|iphone)/);
  if (match) {
    return match[0];
  } else {
    return '';
  }
}

export function detectMobileBrowser(): boolean {
  // Smartphones usually support 'window.orientation' and desktop browsers don't.
  return typeof window.orientation !== 'undefined';
}

export function getClientWidth(): number {
  return window.innerWidth;
}

export function getClientHeight(): number {
  return window.innerHeight;
}

export function detectMobileOrientation(): string | number {
  return window.orientation;
}