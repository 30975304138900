import React, {useEffect, useState} from 'react';
import {useRouteMatch} from 'react-router-dom';
import QuoteSearch from '../../../../Core/Components/Header/Search/QuoteSearch';
import {quoteHistoryQuoteFilterOptions} from '../../../../Core/Components/Header/Search/QuoteSearch/filterOptions';
import QuoteTable from '../../../../Core/Components/SearchPages/QuoteSearchPage/QuoteTable';
import QuotePageContainer from '../../../../Core/Components/SearchPages/QuoteSearchPage/QuoteSearchPage.styled';
import {selectCurrentCustomer} from '../../../../features/customer/slice/customer.slice';
import {selectQuoteSearch} from '../../../../features/search/slice/search.slice';
import {useAppDispatch, useTypedSelector} from '../../../../hooks/store';
import QuoteHistoryStyled from './QuoteHistory.styled';
import {useScrollPercentage} from 'react-scroll-percentage';
import {loadMoreQuotesAsync} from '../../../../features/search/quote/slice/quote-search.slice';
import {cleanSearchParams} from '../../../../features/order/service/order.service';

const QuoteHistory = () => {
  const dispatch = useAppDispatch();
  const {url} = useRouteMatch();
  const [ref, percentageScrolled] = useScrollPercentage();

  const [isLoading, setIsLoading] = useState(true);
  const [scrolledFarEnough, setScrolledFarEnough] = useState(false);
  const [resultDisplaySize, setResultDisplaySize] = useState(50);

  const suggestions = useTypedSelector(selectQuoteSearch).suggestions;
  let loadingStatus = useTypedSelector(selectQuoteSearch).loading;
  let erpCustomerId = useTypedSelector(selectCurrentCustomer)?.erpCustomerId;
  let urlSearchParams = useTypedSelector(selectQuoteSearch).quoteUrlSearchParams;

  useEffect(() => {
    const scrollToTopOnLoad = () => {
      window.scrollTo({top: 0, left: 0});
    };
    setTimeout(() => {
      scrollToTopOnLoad();
    }, 500);
  }, []);

  useEffect(() => {
    const loadMoreDataOnScroll = (numResultsToShow: number) => {
      let filters: any = urlSearchParams;
      let data = new URLSearchParams(filters);
      data = cleanSearchParams(data);
      if (erpCustomerId) {
        data.append('erpCustomerId', erpCustomerId.toString());
      }
      data.append('pager[expand]', 'totalResults');
      data.append('pager[offset]', '0');
      data.append('pager[limit]', numResultsToShow.toString());
      data.append('pager[sort]', '-createdAt');
      dispatch(loadMoreQuotesAsync(data));
      setScrolledFarEnough(false);
    };
    const determineIfMoreDataShouldBeLoaded = () => {
      if (scrolledFarEnough && resultDisplaySize <= suggestions.length) {
        loadMoreDataOnScroll(resultDisplaySize + 50);
        setResultDisplaySize((r) => r + 50);
      }
    };
    determineIfMoreDataShouldBeLoaded();
  }, [scrolledFarEnough, dispatch, suggestions, resultDisplaySize, urlSearchParams, erpCustomerId]);

  useEffect(() => {
    if (percentageScrolled > 0.75) {
      setScrolledFarEnough(true);
    } else {
      setScrolledFarEnough(false);
    }
  }, [percentageScrolled]);

  useEffect(() => {
    if (loadingStatus === 'pending') {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [loadingStatus]);

  return (
    <QuoteHistoryStyled>
      <QuotePageContainer>
        <QuoteSearch
          erpCustomerId={erpCustomerId}
          relativeUrl={url}
          quoteFilterOptions={quoteHistoryQuoteFilterOptions}
        />
        <div ref={ref}>
          <QuoteTable quoteSuggestions={suggestions} isLoading={isLoading} />
        </div>
      </QuotePageContainer>
    </QuoteHistoryStyled>
  );
};

export default QuoteHistory;
