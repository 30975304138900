import styled from 'styled-components/macro';
import {$newblack} from '../../../../../Styled/variables';

export const CreditCardSelectContainer = styled.div`
  background: rgba(0, 0, 0, 0.04);
  border-radius: 3px;
  padding: 15px;
`;

export const CreditCardRow = styled.div`
  margin-bottom: 15px;
  cursor: pointer;

  &:last-child {
    margin-bottom: 0;
  }

  .card-accordion-info {
    font-weight: 500;
  }

  .credit-card-accordion {
    padding: 15px;
    border-radius: 3px;
    background-color: #dfdfdf;
  }
`;

export const CardInfo = styled.span`
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.7px;
  text-transform: uppercase;
  color: ${$newblack};
`;
