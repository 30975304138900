import {toast} from 'react-toastify';
import {useAppDispatch, useTypedSelector} from '../../../../hooks/store';
import {pushRemoveFromCartEventToDataLayer} from '../../../analytics/service/analytics.service';
import {selectCurrentUser} from '../../../auth/slice/authentication.slice';
import {
  selectCurrentCustomer,
  selectCurrentCustomerBillToSites,
  selectCurrentCustomerShipToSites,
} from '../../../customer/slice/customer.slice';
import {hideFullscreenLoader, showFullscreenLoader} from '../../../fullscreenLoader/slice/fullscreenLoader.slice';
import {getProduct} from '../../../productCatalog/service/productCatalog.service';
import {setQuoteOperationInProgress} from '../../../quote/slice/quote.slice';
import {selectActiveProgram, selectAvailablePrograms} from '../../../salesforce/slice/salesforce.slice';
import {CartItem} from '../../ICartState';
import {cartServiceRemoveCartItem} from '../../service/cart.service';
import {
  selectActiveCart,
  setCartCalculationsComplete,
  setDisableRemoveButtonWhileRemoving,
} from '../../slice/cart.slice';
import {useRemoveCartButton} from '../useRemoveCartButton/useRemoveCartButton';
import {selectCalculateCartDiscountsInBackend} from '../../../environment/slice/environment.slice';

export const useRemoveCartItem = () => {
  const dispatch = useAppDispatch();
  const cart = useTypedSelector(selectActiveCart);
  const customer = useTypedSelector(selectCurrentCustomer);
  const shipToSites = useTypedSelector(selectCurrentCustomerShipToSites);
  const billToSites = useTypedSelector(selectCurrentCustomerBillToSites);
  const currentUser = useTypedSelector(selectCurrentUser);
  const activeProgram = useTypedSelector(selectActiveProgram);
  const availablePrograms = useTypedSelector(selectAvailablePrograms);
  const removeCartButton = useRemoveCartButton();
  const calculateDiscountsInBackend = useTypedSelector(selectCalculateCartDiscountsInBackend);

  const removeCartItemButton = async (cartItem: CartItem): Promise<void> => {
    if (!cart || !shipToSites || !currentUser || !billToSites) return;

    try {
      dispatch(setDisableRemoveButtonWhileRemoving(true));
      dispatch(showFullscreenLoader({showCartIcon: true}));
      if (cart.cartItems.length > 1 && cart.voucherCodes.length > 0) {
        dispatch(setCartCalculationsComplete(false));
      } else {
        dispatch(setQuoteOperationInProgress(true));
      }

      const getProductPromise = getProduct(cartItem.productId, customer, shipToSites);
      const updatedCart = await cartServiceRemoveCartItem(
        cartItem,
        cart,
        customer,
        currentUser.userId,
        activeProgram,
        shipToSites,
        billToSites,
        dispatch,
        availablePrograms,
        calculateDiscountsInBackend
      );
      const product = await getProductPromise;
      pushRemoveFromCartEventToDataLayer([
        {
          cartItem,
          product,
          quantity: cartItem.quantity ?? 1,
        },
      ]);
      if (updatedCart.cartItems.length === 0) {
        await removeCartButton();
      }
      toast.success(`${cartItem.description} was removed from the cart.`);
    } catch (error: any) {
      let callbackError = '';
      if (error && error.text) {
        callbackError = await error.text();
      }
      if (error.status === 400 && callbackError.includes('Your cart is locked into quote')) {
        toast.error(callbackError);
      } else {
        toast.error(error);
      }
    } finally {
      dispatch(setDisableRemoveButtonWhileRemoving(false));
      dispatch(setCartCalculationsComplete(true));
      dispatch(hideFullscreenLoader());
      dispatch(setQuoteOperationInProgress(false));
    }

    if (cart && cart.cartItems && cart.cartItems.length === 0 && cart.transactionType !== 'QuoteEditCart') {
      await removeCartButton();
    }
  };
  return removeCartItemButton;
};
