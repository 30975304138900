import styled from 'styled-components';
import {$gray55, $gray67, $newprimary} from '../../../../../Styled/variables';

export const LoadReportContainer = styled.div`
  padding: 15px 0px;

  .statement-name-header {
    padding-bottom: 0px;
    font-size: 28px !important;
  }

  .heading {
    display: flex;
    justify-content: space-between;
    padding: 10px 0px;
  }

  .no-results-page {
    display: flex;
    justify-content: center;
    padding: 150px;
    flex-direction: column;
    align-items: center;

    button {
      margin-top: 15px;
    }
  }

  .load-report-action-buttons {
    display: flex;
    width: 25%;
    justify-content: space-evenly;
    align-items: center;
  }

  .subscription-btn {
    padding: 6px;
    font-weight: 600;
    border: none;
    font-size: 14px;
    padding: 8px;
    color: white;
  }

  .subscribe {
    background-color: ${$newprimary};

    :hover {
      background-color: rgb(151, 115, 18);
    }

    :active {
      background-color: rgb(151, 115, 18);
    }
  }

  .unsubscribe {
    background-color: ${$gray67};

    :hover {
      background-color: ${$gray55};
    }
  }
`;
