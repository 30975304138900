import {Formik} from 'formik';
import qs from 'qs';
import React, {useState} from 'react';
import {Accordion, Col, Row} from 'react-bootstrap';
import {useLocation} from 'react-router-dom';
import {
  selectDisplayCategoriesFilters,
  selectSearchResultsCategoriesFilters,
  selectSubProductCategoriesFilters,
  setProductsAsync,
  setUpdatedDisplayCategoriesFilters,
  setUpdatedSearchResultsCategoriesFilters,
  setUpdatedSubProductCategoriesFilters,
} from '../../../../../features/productCatalog/slice/productCatalog.slice';
import {setShipFromWarehouseId} from '../../../../../features/warehouse/slice/warehouse.slice';
import {useAppDispatch, useTypedSelector} from '../../../../../hooks/store';
import COMMERCE_CORE_CONSTANTS from '../../../../constants';
import CambriaSelect from '../../../../../Framework/Components/CambriaSelect';

const MobileFilters = (props: {
  productType: any;
  getDisplayCategories: any;
  getSubProductCategories: any;
  getSearchResultsCategories: any;
  createProductRequestData: any;
  clearProductFilters: any;
  subProductCategoryTitle: any;
  displayCategoryTitle: any;
  searchResultsCategoryTitle: any;
  isSearchResults: boolean;
  customer: any;
  cart: any;
  shipToSites: any;
}) => {
  const dispatch = useAppDispatch();
  const location = useLocation();

  let [mobileDisplayCategories] = useState(props.getDisplayCategories(props.productType));
  let [mobileSubProductCategories] = useState(props.getSubProductCategories(props.productType));
  let [mobileSearchResultsCategories] = useState(
    props.getSearchResultsCategories(qs.parse(location.search, {ignoreQueryPrefix: true}).productType)
  );
  let [isOpen, setIsOpen] = useState(false);

  let mobileSubProductCategoryFilter: any = useTypedSelector(selectSubProductCategoriesFilters);
  let mobileDisplayCategoryFilter: any = useTypedSelector(selectDisplayCategoriesFilters);
  let mobileSearchResultsCategoryFilter: any = useTypedSelector(selectSearchResultsCategoriesFilters);

  let searchParams: any = {
    displayCategories: '',
    subProductCategory: '',
  };

  const setMobileDisplayCategoryChangedFilters = (category: any) => {
    if (!mobileDisplayCategoryFilter.includes(category.name)) {
      let tempMobileDisplayCategoryFilter = JSON.parse(JSON.stringify(mobileDisplayCategoryFilter));
      tempMobileDisplayCategoryFilter.push(category.name);
      searchParams.displayCategories = tempMobileDisplayCategoryFilter.join();
      searchParams.subProductCategory = mobileSubProductCategoryFilter.join();
      dispatch(setUpdatedDisplayCategoriesFilters(tempMobileDisplayCategoryFilter));
      onMobileCategoryFilterChanged();
    }
  };

  const setMobileSubProductCategoriesChangedFilters = (category: any) => {
    if (!mobileSubProductCategoryFilter.includes(category.name)) {
      let tempMobileSubProductCategoryFilter = JSON.parse(JSON.stringify(mobileSubProductCategoryFilter));
      tempMobileSubProductCategoryFilter.push(category.name);
      searchParams.displayCategories = mobileDisplayCategoryFilter.join();
      searchParams.subProductCategory = tempMobileSubProductCategoryFilter.join();
      dispatch(setUpdatedSubProductCategoriesFilters(tempMobileSubProductCategoryFilter));
      onMobileCategoryFilterChanged();
    }
  };

  const setMobileSearchResultsCategoriesChangedFilters = (category: any) => {
    const selectedProductType = category.name === 'Branded Merchandise' ? 'Store' : category.name;
    const keyword = qs.parse(location.search, {ignoreQueryPrefix: true}).q;
    window.history.replaceState(
      null,
      'newProductTypeFilter',
      `${COMMERCE_CORE_CONSTANTS.PATHNAMES.searchResults}?${qs.stringify({
        q: keyword,
        productType: selectedProductType,
      })}`
    );

    if (mobileSearchResultsCategoryFilter === selectedProductType) {
      return;
    }

    dispatch(setUpdatedSearchResultsCategoriesFilters(selectedProductType));

    if (selectedProductType === 'All') {
      dispatch(setProductsAsync(props.createProductRequestData(selectedProductType)));
      return;
    }

    dispatch(
      setShipFromWarehouseId({
        productType: selectedProductType,
        customer: props.customer,
        shipToSites: props.shipToSites,
        cart: props.cart,
      })
    );
  };

  const onMobileCategoryFilterChanged = () => {
    let productRequestData = props.createProductRequestData(props.productType);
    productRequestData.data.append('displayCategories', searchParams.displayCategories);
    productRequestData.data.append('subProductCategory', searchParams.subProductCategory);
    dispatch(setProductsAsync(productRequestData));
  };

  const clearSingleMobileFilter = (filterItem: any, type: any) => {
    if (type === 'display') {
      let tempMobileDisplayCategoryFilter = mobileDisplayCategoryFilter.filter((item: any) => item !== filterItem);
      searchParams.displayCategories = tempMobileDisplayCategoryFilter.join();
      searchParams.subProductCategory = mobileSubProductCategoryFilter.join();
      dispatch(setUpdatedDisplayCategoriesFilters(tempMobileDisplayCategoryFilter));
    } else if (type === 'subProduct') {
      let tempMobileSubProductCategoryFilter = mobileSubProductCategoryFilter.filter(
        (item: any) => item !== filterItem
      );
      searchParams.displayCategories = mobileDisplayCategoryFilter.join();
      searchParams.subProductCategory = tempMobileSubProductCategoryFilter.join();
      dispatch(setUpdatedSubProductCategoriesFilters(tempMobileSubProductCategoryFilter));
    }

    onMobileCategoryFilterChanged();
  };

  const determineListTitle = () => {
    if (props.isSearchResults) {
      return 'Search Results';
    } else {
      return props.productType === 'Slab' ? props.productType.toUpperCase() + 'S' : props.productType.toUpperCase();
    }
  };

  return (
    <div className="hidden-sm hidden-lg hidden-md">
      <div className="col-xs-12 mobile-filter-product-type-header-container">
        <h1 className="product-list-product-type-header">{determineListTitle()}</h1>
      </div>
      <Accordion>
        <Accordion.Collapse eventKey="0">
          <div>
            <div className="row  mobile-filter-filter-text-and-reset-button-container">
              <div className="col-xs-8">
                <h3 className="pull-left h3-default m-b-0">Filter</h3>
              </div>
              <div className="col-xs-4">
                {props.isSearchResults ? (
                  <div></div>
                ) : (
                  <button
                    className="btn btn-link p-a-0 text-capitalize pull-right mobile-filter-reset-button"
                    type="button"
                    onClick={() => props.clearProductFilters()}>
                    Reset
                  </button>
                )}
              </div>
            </div>
            {props.isSearchResults ? (
              <Row>
                {/* searchResultsCategoryFilter */}
                <Col xs={12}>
                  <Formik enableReinitialize initialValues={{searchResultsCategoriesFilter: null}} onSubmit={() => {}}>
                    <CambriaSelect
                      placeholder={'Select a ' + props.searchResultsCategoryTitle + '...'}
                      name="searchResultsCategoriesFilter"
                      items={mobileSearchResultsCategories}
                      displayValue="name"
                      onChange={setMobileSearchResultsCategoriesChangedFilters}
                    />
                  </Formik>
                </Col>
              </Row>
            ) : (
              <div>
                <Row>
                  {/* subProductCategoryFilter */}
                  <Col xs={12}>
                    <Formik enableReinitialize initialValues={{subProductCategoriesFilter: null}} onSubmit={() => {}}>
                      <CambriaSelect
                        placeholder={'Select a ' + props.subProductCategoryTitle + '...'}
                        name="subProductCategoriesFilter"
                        items={mobileSubProductCategories}
                        displayValue="name"
                        onChange={setMobileSubProductCategoriesChangedFilters}
                        multiSelect={true}
                      />
                    </Formik>
                    <Row>
                      <Col xs={12}>
                        {mobileSubProductCategoryFilter.map((filterItem: any, $index: number) => {
                          return (
                            <div className="mobile-filter-pill" key={filterItem + $index}>
                              <h3 className="mobile-filter-pill-text">
                                {filterItem}
                                <span
                                  className="mobile-filter-pill-clear-button"
                                  onClick={() => clearSingleMobileFilter(filterItem, 'subProduct')}>
                                  X
                                </span>
                              </h3>
                            </div>
                          );
                        })}
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  {/* displayCategoryFilter */}
                  <Col xs={12}>
                    <Formik enableReinitialize initialValues={{displayCategoriesFilter: null}} onSubmit={() => {}}>
                      <CambriaSelect
                        placeholder={'Select a ' + props.displayCategoryTitle + '...'}
                        name="displayCategoriesFilter"
                        items={mobileDisplayCategories}
                        displayValue="name"
                        onChange={setMobileDisplayCategoryChangedFilters}
                        multiSelect
                      />
                    </Formik>
                    <Row>
                      <Col xs={12}>
                        {mobileDisplayCategoryFilter.map((filterItem: any, $index: number) => {
                          return (
                            <div className="mobile-filter-pill" key={filterItem + $index}>
                              <h3 className="mobile-filter-pill-text">
                                {filterItem}
                                <span
                                  className="mobile-filter-pill-clear-button"
                                  onClick={() => clearSingleMobileFilter(filterItem, 'display')}>
                                  X
                                </span>
                              </h3>
                            </div>
                          );
                        })}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            )}
          </div>
        </Accordion.Collapse>
        <Accordion.Toggle
          className="btn btn-lg btn-primary btn-block hidden-sm hidden-md hidden-lg m-b-sm mobile-filter-done-button"
          eventKey="0"
          onClick={() => setIsOpen(!isOpen)}>
          <span className="text-capitalize mobile-filter-done-button-text">FILTERS</span>
        </Accordion.Toggle>
      </Accordion>
    </div>
  );
};

export default MobileFilters;
