import React, {FC} from 'react';
import {ButtonContainer} from './CambriaButton.styled';

interface CambriaButtonProps {
  children: React.ReactNode;
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  variant?: 'primary' | 'secondary' | 'transparent';
  round?: boolean;
  shadow?: boolean;
  type?: 'button' | 'submit' | 'reset' | undefined;
  form?: string;
  disabled?: boolean;
  className?: string;
  id?: string;
  isLoading?: boolean;
  tabIndex?: number;
}

const CambriaButton: FC<CambriaButtonProps> = ({
  children,
  onClick,
  variant,
  round = true,
  shadow = true,
  type = 'button',
  form,
  disabled = false,
  className,
  id,
  isLoading = false,
  tabIndex,
}) => {
  return (
    <ButtonContainer
      tabIndex={tabIndex}
      data-testid={id}
      variant={variant}
      round={round}
      shadow={shadow}
      onClick={onClick}
      type={type}
      form={form}
      disabled={disabled}
      className={className}
      id={id}>
      {children}
    </ButtonContainer>
  );
};

export default CambriaButton;
