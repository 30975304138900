import styled from 'styled-components/macro';
import {$newinactivebackground, $screenmd} from '../../../../Styled/variables';

export const OrderConfirmationContainer = styled.div`
  padding-bottom: 100px;

  .order-confirmation {
    margin-bottom: 0;

    .order-confirmation-header {
      font-weight: 300;
      margin-bottom: 20px;
    }

    .order-confirmation-project-name-text {
      font-weight: 600;
      margin-bottom: 10px;
    }

    .order-confirmation-project-text-button {
      font-size: 16px;
      margin: 0px 5px 1px;
    }

    .order-confirmation-alerts-container {
      margin-top: 20px;
    }

    .order-confirmation-email-form {
      padding: 0px 15px !important;

      .order-confirmation-email-input {
        padding-bottom: 5px !important;
      }

      .order-confirmation-email-button {
        margin-top: 2px;
      }
    }

    .step {
      background-color: ${$newinactivebackground};
      border: none !important;
      padding: 30px;
    }

    .row {
      padding: 15px;
    }
  }

  .email-input {
    width: 300px;
  }

  .container.checkout.confirm {
    position: relative;
    top: -100px;

    @media (max-width: ${$screenmd}) {
      top: -80px;
    }
  }

  .new-style {
    .container.checkout.confirm {
      top: 0;

      @media (max-width: ${$screenmd}) {
        top: 80px;
      }
    }
  }

  .qr-code-container {
    margin-bottom: 50px;
    margin-top: 20px;

    .qr-code-img {
      margin-left: auto;
      margin-right: auto;
      width: 50%;
      display: flex;
    }

    .qr-code-text {
      text-align: center;
    }
  }
`;
