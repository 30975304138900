import React, {FC, useEffect, useMemo, useState} from 'react';
import * as Yup from 'yup';
import CambriaModal from '../../../../Framework/Components/CambriaModal';
import {Form, Formik} from 'formik';
import {Container, Row} from 'react-bootstrap';
import CambriaInput from '../../../../Framework/Components/CambriaInput';
import CambriaDateInput from '../../../../Framework/Components/CambriaDateInput/CambriaDateInput.component';
import {
  CurrentDeliveryScheduleTable,
  EditRequestedDeliveryScheduleContainer,
} from './EditRequestedDeliveryScheduleModal.styled';
import CambriaButton from '../../../../Framework/Components/CambriaButton';
import {addDeliverySchedule} from '../../../../features/product/service/product.service';
import {toast} from 'react-toastify';
import {useAppDispatch} from '../../../../hooks/store';
import {deleteDeliverySchedulesRequest} from '../../../../features/product/controller/product.controller';

interface ItemToEdit {
  cartItemId: any;
  requestedDeliveryDate: any;
  quantity: number;
  cartId: any;
  description?: any;
  imageUrl?: string;
}

interface DateFieldOptions {
  minDate: any;
  holidaysDiabled: any;
  daysDisabled: any;
}

interface EditRequestedDeliveryScheduleModalProps {
  itemToEdit: ItemToEdit;
  scheduledItems: any;
  dateFieldOptions: DateFieldOptions;
  show?: boolean;
  toggleShow?: any;
  onSubmitCallback?: any;
}

const EditRequestedDeliveryScheduleModal: FC<EditRequestedDeliveryScheduleModalProps> = ({
  itemToEdit,
  scheduledItems,
  dateFieldOptions,
  show,
  toggleShow,
  onSubmitCallback,
}) => {
  const dispatch = useAppDispatch();

  const [advancedScheduleForm, setAdvancedScheduleForm] = useState<any>();
  const [updatedItemsSchedule, setUpdatedItemsSchedule] = useState<Array<any>>([]);
  const [restOfTheItems, setRestOfTheItems] = useState<Array<any>>([]);
  const [itemsAdded, setItemsAdded] = useState<number>(0);
  const [quantityToAdd, setQuantityToAdd] = useState<number>(0);
  const [seamedToAdd, setSeamedToAdd] = useState<boolean>(false);
  const [dateToAdd, setDateToAdd] = useState<any>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const advancedScheduleFormTemplate = useMemo(() => {
    const formTemplate: any = {
      quantity: {
        label: 'Quantity',
        initialValue: 0,
        type: 'counter',
        onChange: (quantity: number | string) => {
          setQuantityToAdd(() => Number(quantity));
        },
        validation: Yup.string().required('This field is required'),
      },
      seamed: {
        label: 'Seamed?',
        initialValue: false,
        type: 'checkbox',
        onChange: (shouldBeSeamed: boolean) => setSeamedToAdd(() => shouldBeSeamed),
        isDisabled: false,
        validation: Yup.boolean(),
      },
      requestedDeliveryDate: {
        label: 'Requested Date',
        placeholder: 'Requested Date',
        initialValue: '',
        type: 'date',
        minDate: dateFieldOptions.minDate || null,
        disabledDates: dateFieldOptions.holidaysDiabled || null,
        disabledDays: dateFieldOptions.daysDisabled || null,
        isEnabled: true,
        disabledWhileLoading: false,
        onChange: (date: any) => setDateToAdd(() => date),
        validation: Yup.mixed().required('This field is required'),
      },
    };
    return formTemplate;
  }, [dateFieldOptions.minDate, dateFieldOptions.holidaysDiabled, dateFieldOptions.daysDisabled]);

  const modalOptions = {
    title: '',
    confirmButtonText: 'SAVE REQUESTED DATE',
    formName: 'editCardForm',
  };

  const deleteScheduledItem = (item: any) => {
    const updatedSchedule = updatedItemsSchedule.filter((existingItem: any) => existingItem.id !== item.id);
    setUpdatedItemsSchedule(updatedSchedule);
    setItemsAdded((current) => current - item.quantity);
  };

  const saveRequestedDeliveryDate = (values: any) => {
    if (values.quantity > 0 && values.requestedDeliveryDate) {
      setUpdatedItemsSchedule((prev) => [
        ...prev,
        {
          quantity: values.quantity,
          seamed: values.seamed,
          requestedDeliveryDate: new Date(values.requestedDeliveryDate).toLocaleDateString('en-US'),
          cartId: itemToEdit.cartId,
          cartItemId: itemToEdit.cartItemId,
          description: itemToEdit.description,
          _imageUrl: itemToEdit.imageUrl,
          id: new Date().getTime(),
        },
      ]);
      setQuantityToAdd(0);
      setSeamedToAdd(false);
      setDateToAdd('');
      setItemsAdded((current) => current + values.quantity);
    }
  };

  const resetForm = () => {
    setAdvancedScheduleForm(advancedScheduleFormTemplate);
    setDateToAdd(null);
    setItemsAdded(0);
    setQuantityToAdd(0);
    setSeamedToAdd(false);
    setUpdatedItemsSchedule([]);
    setRestOfTheItems([]);
  };

  const submitEditedSchedule = async () => {
    try {
      setIsLoading(true);
      await deleteDeliverySchedulesRequest(itemToEdit.cartId);
      const result = await addDeliverySchedule([...updatedItemsSchedule, ...restOfTheItems], dispatch);

      if (result) {
        toast.success('Delivery schedule was updated.');
        onSubmitCallback();
        resetForm();
      } else {
        toast.success('Something went wrong.');
      }
    } catch (error: any) {
      toast.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setAdvancedScheduleForm(advancedScheduleFormTemplate);
  }, [advancedScheduleFormTemplate]);

  useEffect(() => {
    if (itemToEdit && scheduledItems?.length > 0 && itemsAdded === 0) {
      let allScheduledItems = JSON.parse(JSON.stringify(scheduledItems));

      scheduledItems.forEach((item: any) => {
        if (item.cartItemId === itemToEdit.cartItemId) {
          allScheduledItems = allScheduledItems.filter(
            (i: any) =>
              i.cartItemId !== itemToEdit.cartItemId || i.requestedDeliveryDate !== itemToEdit.requestedDeliveryDate
          );
        }
      });
      setRestOfTheItems(allScheduledItems);
    }
  }, [itemToEdit, scheduledItems, itemsAdded]);

  useEffect(() => {
    if (advancedScheduleForm) {
      const updatedForm = advancedScheduleForm;
      updatedForm.quantity.initialValue = quantityToAdd;
      updatedForm.seamed.initialValue = seamedToAdd;
      updatedForm.requestedDeliveryDate.initialValue = dateToAdd;

      if (updatedForm.quantity.initialValue === 0 || updatedForm.quantity.initialValue === 1) {
        updatedForm.seamed.initialValue = false;
        setSeamedToAdd(false);
        updatedForm.seamed.isDisabled = true;
      } else if (updatedForm.quantity.initialValue === 2 || updatedForm.quantity.initialValue === 3) {
        updatedForm.seamed.initialValue = true;
        setSeamedToAdd(true);
        updatedForm.seamed.isDisabled = false;
      } else {
        updatedForm.seamed.initialValue = false;
        setSeamedToAdd(false);
        updatedForm.seamed.isDisabled = false;
      }

      setAdvancedScheduleForm(updatedForm);
    }
  }, [quantityToAdd, seamedToAdd, dateToAdd, advancedScheduleForm]);

  const renderItemsTable = (field: any) => {
    if (advancedScheduleForm[field].type === 'counter') {
      return (
        <CambriaInput
          min={0}
          max={itemToEdit.quantity - itemsAdded}
          name={field}
          placeholder={advancedScheduleForm[field].placeholder}
          defaultValue={advancedScheduleForm[field].initialValue}
          label={advancedScheduleForm[field].label}
          type={advancedScheduleForm[field].type}
          disabled={itemsAdded === itemToEdit.quantity}
          onChange={advancedScheduleForm[field].onChange}
          required={advancedScheduleForm[field].validation.exclusiveTests.required || false}></CambriaInput>
      );
    } else if (advancedScheduleForm[field].type === 'checkbox') {
      return (
        <CambriaInput
          name={field}
          vertical={true}
          placeholder={advancedScheduleForm[field].placeholder}
          defaultValue={advancedScheduleForm[field].initialValue}
          label={advancedScheduleForm[field].label}
          type={advancedScheduleForm[field].type}
          disabled={advancedScheduleForm[field].isDisabled || itemsAdded === itemToEdit.quantity}
          onChange={advancedScheduleForm[field].onChange}
          required={advancedScheduleForm[field].validation.exclusiveTests.required || false}></CambriaInput>
      );
    } else if (advancedScheduleForm[field].type === 'date') {
      return (
        <CambriaDateInput
          name={field}
          defaultValue={advancedScheduleForm[field].initialValue}
          label={advancedScheduleForm[field].label}
          minDate={advancedScheduleForm[field].minDate}
          excludeDates={advancedScheduleForm[field].disabledDates}
          filterDate={advancedScheduleForm[field].disabledDays}
          placeholder={advancedScheduleForm[field].placeholder}
          onChange={advancedScheduleForm[field].onChange}
          disabled={itemsAdded === itemToEdit.quantity}
          required={advancedScheduleForm[field].validation.exclusiveTests.required || false}></CambriaDateInput>
      );
    }
  };

  const renderUpdatedDeliveryScheduleTable = () => {
    return updatedItemsSchedule.map((line: any, i: number) => {
      return (
        <tr key={i}>
          <td className="p-r-sm p-l-sm description">
            <img src={itemToEdit.imageUrl} alt={itemToEdit.description} />
            <span>{itemToEdit.description}</span>
          </td>
          <td className="p-r-sm p-l-sm text-center">{line.quantity}</td>
          <td className="p-r-sm p-l-sm text-center">
            {line.seamed && <span>Yes</span>}
            {!line.seamed && <span>No</span>}
          </td>
          <td className="p-r-sm p-l-sm text-center">
            {new Date(line.requestedDeliveryDate).toLocaleDateString('en-US', {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
            })}
          </td>
          <td className="p-r-sm p-l-sm text-center" onClick={() => deleteScheduledItem(line)}>
            <em className="fa fa-trash-alt"></em>
          </td>
        </tr>
      );
    });
  };

  const renderUpdatedDeliveryScheduleTableOnMobile = () => {
    return (
      <>
        <thead>
          <tr>
            <th className="text-center">Quantity</th>
            <th className="text-center">Seamed</th>
            <th className="text-center">Requested Date</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {updatedItemsSchedule.map((line: any, i: number) => {
            return (
              <tr key={i}>
                <td className="p-r-sm p-l-sm description flex-fill w-100">
                  <img src={itemToEdit.imageUrl} alt={itemToEdit.description} />
                  <span>{itemToEdit.description}</span>
                </td>
                <td className="p-r-sm p-l-sm">{line.quantity}</td>
                <td className="p-r-sm p-l-sm" ng-if="advancedDeliverySchedule.options.shouldShowSeamedColumn">
                  {line.seamed && <span>Yes</span>}
                  {!line.seamed && <span>No</span>}
                </td>
                <td className="p-r-sm p-l-sm">
                  {new Date(line.requestedDeliveryDate).toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                  })}
                </td>
                <td className="p-r-sm p-l-sm" onClick={() => deleteScheduledItem(line)}>
                  <em className="fa fa-trash-alt"></em>
                </td>
              </tr>
            );
          })}
        </tbody>
      </>
    );
  };

  if (advancedScheduleForm && itemToEdit) {
    return (
      <EditRequestedDeliveryScheduleContainer>
        <Formik
          enableReinitialize
          initialValues={{
            quantity: advancedScheduleForm.quantity.initialValue,
            seamed: advancedScheduleForm.seamed.initialValue,
            requestedDeliveryDate: advancedScheduleForm.requestedDeliveryDate.initialValue,
          }}
          validationSchema={Yup.object({
            quantity: advancedScheduleForm.quantity.validation,
            seamed: advancedScheduleForm.seamed.validation,
            requestedDeliveryDate: advancedScheduleForm.requestedDeliveryDate.validation,
          })}
          onSubmit={(values) => {
            saveRequestedDeliveryDate(values);
          }}>
          {(props: any) => {
            return (
              <CambriaModal
                show={show}
                toggleShow={() => {
                  if (toggleShow) {
                    toggleShow();
                  }
                }}
                hideCancelButton={true}
                hideSubmitButton={itemsAdded === itemToEdit.quantity}
                confirmButton={modalOptions.confirmButtonText}
                heading={modalOptions.title}
                formName={modalOptions.formName}
                size="xl"
                onCancel={resetForm}
                disableSubmitBtn={!props.values.requestedDeliveryDate || props.values.quantity < 1}>
                <Form id={modalOptions.formName} onSubmit={props.handleSubmit} onReset={props.handleReset} noValidate>
                  <Container className="p-0">
                    {itemsAdded < itemToEdit.quantity && (
                      <Row>
                        <CurrentDeliveryScheduleTable>
                          <tbody>
                            <tr>
                              <td className="description table-cell">
                                <img src={itemToEdit.imageUrl} alt={itemToEdit.description} />
                                <div className="description__text">
                                  <span className="description__text__row">{itemToEdit.description}</span>
                                  <span className="description__text__row">
                                    <span>Scheduled Quantity:</span>
                                    <strong data-testid="scheduled-quantity">
                                      {quantityToAdd + itemsAdded} / {itemToEdit.quantity}
                                    </strong>
                                  </span>
                                </div>
                              </td>
                              {Object.keys(advancedScheduleForm).map((field, i) => {
                                return (
                                  <td key={i} className="table-cell">
                                    {renderItemsTable(field)}
                                  </td>
                                );
                              })}
                            </tr>
                          </tbody>
                        </CurrentDeliveryScheduleTable>
                      </Row>
                    )}
                    {updatedItemsSchedule?.length > 0 && (
                      <Row>
                        <CurrentDeliveryScheduleTable className="hidden-before-gfb">
                          <thead>
                            <tr>
                              <th className="text-center">Product</th>
                              <th className="text-center">Quantity</th>
                              <th className="text-center">Seamed</th>
                              <th className="text-center">Requested Date</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>{renderUpdatedDeliveryScheduleTable()}</tbody>
                        </CurrentDeliveryScheduleTable>
                        <CurrentDeliveryScheduleTable className="hidden-after-gfb updated-delivery-schedule-on-mobile">
                          {renderUpdatedDeliveryScheduleTableOnMobile()}
                        </CurrentDeliveryScheduleTable>
                      </Row>
                    )}
                  </Container>
                </Form>
                {itemsAdded === itemToEdit.quantity && (
                  <div className="d-flex w-100 justify-content-end">
                    <CambriaButton onClick={submitEditedSchedule} disabled={isLoading}>
                      CONFIRM REQUESTED DATE(S)
                    </CambriaButton>
                  </div>
                )}
              </CambriaModal>
            );
          }}
        </Formik>
      </EditRequestedDeliveryScheduleContainer>
    );
  }

  return <div></div>;
};

export default EditRequestedDeliveryScheduleModal;
