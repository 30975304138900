import {postAcknowledgeExpiringQuoteNotification} from '../controller/quote.controller';

/**
 * Saves acknowledge of close to expire quote notification
 * @param quoteId
 * @returns Callback
 */
export const useAcknowledgeQuoteNotification = ({quoteId}: {quoteId: number}) => {
  return async () => {
    await postAcknowledgeExpiringQuoteNotification(quoteId);
  };
};
