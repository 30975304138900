import styled from 'styled-components/macro';
import {
  $brandprimary,
  $gray30,
  $gray50,
  $gray93,
  $gray97,
  $newprimary,
  $newwhite,
  $screenlg,
  $screenmd,
} from '../../../../Styled/variables';

const CustomerPageContainer = styled.div`
  .customer-table {
    tbody {
      box-shadow: inset 0px 2px 5px 0px rgba(101, 101, 101, 0.35);
    }
    .customer-table-header-cell {
      font-weight: 700;
      font-size: 14px;
      padding: 20px 0px 15px 2px;
      color: ${$gray30};
      background: ${$gray97};
    }

    .customer-name {
      font-weight: 700;
      font-size: 14px !important;
    }
    .address {
      color: ${$gray50};
    }
    .class {
      font-weight: 700;
    }
    .customer-table-header {
      position: sticky;
      z-index: 2;
      top: 0;
      padding-left: 25px;
    }
    th {
      border: none !important;
    }
    td {
      border-color: white;
      font-weight: 600;
    }
    .customer-table-row {
      box-shadow: 0px 1px 3px 0px rgba(101, 101, 101, 0.35);
      :nth-of-type(odd) {
        background-color: ${$newwhite};
      }
      :nth-of-type(even) {
        background-color: ${$gray93};
      }
      :hover {
        background-color: #f9ecc9;
        cursor: pointer;
      }
    }
  }
  .results-text {
    color: ${$gray50};
    font-weight: 700;
    font-size: 12px;
    .number {
      color: ${$gray30};
    }
  }

  .customer-list-header {
    //display: flex;
    width: 100%;
    //justify-content: space-between;
    padding-bottom: 20px;
    display: grid;
    justify-items: start;
    justify-content: flex-start;
    align-content: center;
    .h2 {
      margin-bottom: 0px;
    }
  }
  .number {
    float: right;
  }

  .accordion-toggle-mobile {
    display: flex;
    width: 100%;
    height: 70px;
  }
  .rs-panel-in {
    border-radius: 0px !important;
    box-shadow: 0px 0px 3px 0px rgba(101, 101, 101, 0.35);
  }
  .rs-panel-group {
    box-shadow: 0px 0px 3px 0px rgba(101, 101, 101, 0.35);
    margin-bottom: 20px;
  }
  .rs-panel {
    box-shadow: 0px 0px 3px 0px rgba(101, 101, 101, 0.35);
    border-radius: 3px;
    background-color: ${$newwhite};
    .rs-panel-body {
      padding: 0px !important;
    }

    .rs-panel-header {
      font-size: 16px;
      padding: 25px;
    }
    .accordion-info-table {
      display: flex;
      padding: 0px 20px 20px 20px;
      flex-direction: column;
      font-size: 16px;
      color: ${$gray30};
      .accordion-info-line {
        display: flex;
        padding-left: 5px;
        .accordion-info-line-value {
          font-weight: 500;
        }
      }
      .accordion-info-line.address {
        padding-bottom: 20px;
      }
      .accordion-info-line.class {
        .accordion-info-line-value {
          font-weight: 700;
        }
      }
    }
    .accordion-info-line.select-account {
      text-align: center;
      display: flex;
      flex-direction: column;
      padding-bottom: 0px;
      color: ${$newprimary};
      box-shadow: 0px 0px 3px 0px rgba(101, 101, 101, 0.35);
      height: 45px;
      span {
        line-height: 45px;
      }
      :hover {
        cursor: pointer;
      }
    }
  }
  .load-more-button {
    box-shadow: 0px 1px 3px 0px rgba(135, 135, 135, 0.35);
    display: flex;
    align-content: center;
    justify-content: center;
    color: ${$brandprimary};
    font-weight: 600;
    padding: 10px;
    background: ${$newwhite};
    :hover {
      cursor: pointer;
    }
    .fa-plus {
      margin: 4px 8px 0px 0px;
      font-size: 12px;
    }
  }

  .no-results-table {
    display: flex;
    justify-content: center;
    .no-results-row {
      margin-top: 30%;

      .no-results-text {
        font-weight: 600;
        font-size: 16px;
        color: ${$gray30};
        border-top: none !important;
      }
    }
  }
  .no-customers-found {
    width: 100%;
    height: 400px;
    display: flex;
    justify-content: center;
    margin-top: 200px;
    font-weight: 700;
    font-size: 16px;
  }
  .accordion {
    box-shadow: 0px 1px 3px 0px rgba(135, 135, 135, 0.35);
    margin: 15px;
    background: white;
    min-height: 70px;
    padding: 15px;
    border-radius: 5px;
    .card-header {
      .accordion-header {
        background: white;
        border: none;
        width: 100%;
        margin-top: 10px;
        display: flex;
        font-size: 16px !important;
        font-weight: 700;
        justify-content: space-between;
        align-items: center;
        i {
          font-size: 18px;
          //font-weight: 500;
          color: ${$gray50};
        }
      }
    }
    .collapse,
    .collapsing {
      margin-top: 15px;
      .accordion-info-table {
        margin-bottom: 15px;
      }
      .accordion-info-line {
        display: flex;
        padding-left: 6px;
        .accordion-info-line-value {
          font-weight: 500;
        }
      }
      .accordion-info-line.address {
        padding-bottom: 15px;
      }
      .accordion-info-line.class {
        .accordion-info-line-value {
          font-weight: 700;
        }
      }
      .accordion-info-line.select-account {
        text-align: center;
        display: flex;
        flex-direction: column;
        padding-bottom: 0px;
        color: ${$newprimary};

        height: 30px;
        span {
          font-size: 14px !important;
          line-height: 45px;
          font-weight: 700;
        }
        :hover {
          cursor: pointer;
        }
      }
      hr {
        margin-top: 15px;
        margin-bottom: 0px;
        border: 0;
        border-top: 1.5px solid #e1e1e1;
      }
    }
  }
  @media screen and (max-width: 800px) {
    .customer-list-header {
      display: grid;
      justify-content: flex-start;
      align-content: center;
    }
    .customer-table-header-cell {
      padding: 20px 0px 0px 0px !important;
    }
  }
  @media screen and (max-width: ${$screenmd}) {
    .search-items-container {
      margin-bottom: 45px;
    }
    table {
      margin-bottom: 0px;
    }
  }
  @media screen and (max-width: ${$screenlg}) {
    .mobile-hidden {
      display: none;
    }
  }
`;

export default CustomerPageContainer;
