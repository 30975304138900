import React, {useEffect, useState} from 'react';
import {selectCurrentUser} from '../../../../features/auth/slice/authentication.slice';
import {
  calculateStandartDiscounts,
  initializeCart,
  initializeSavedItems,
} from '../../../../features/cart/service/cart.service';
import {selectActiveCart, setActiveCart, setCartCalculationsComplete} from '../../../../features/cart/slice/cart.slice';
import {Cart} from '../../../../features/cart/ICartState';
import {
  selectCurrentCustomer,
  selectCurrentCustomerBillToSites,
  selectCurrentCustomerShipToSites,
} from '../../../../features/customer/slice/customer.slice';
import {CustomerFullProfile, Site} from '../../../../features/customer/ICustomerState';
import {
  selectCartLoaded,
  selectCustomerValidationStatus,
  setCartLoaded,
} from '../../../../features/resolves/slice/resolves.slice';
import {selectActiveProgram, selectAvailablePrograms} from '../../../../features/salesforce/slice/salesforce.slice';
import {selectSelectedWarehouse} from '../../../../features/warehouse/slice/warehouse.slice';
import {useAppDispatch, useTypedSelector} from '../../../../hooks/store';
import {useLocation} from 'react-router-dom';
import {getActiveCarts} from '../../../../features/cart/controller/cart.controller';
import COMMERCE_CORE_CONSTANTS from '../../../../Core/constants';
import {selectUiSettings} from '../../../../features/environment/slice/environment.slice';

const CartResolves = ({children}: {children: any}) => {
  const dispatch = useAppDispatch();
  const location = useLocation();

  const currentUser = useTypedSelector(selectCurrentUser);
  const currentCustomer: CustomerFullProfile | null = useTypedSelector(selectCurrentCustomer);
  const cart: Cart | null = useTypedSelector(selectActiveCart);
  const cartLoaded = useTypedSelector(selectCartLoaded);
  const customerIsValid = useTypedSelector(selectCustomerValidationStatus);
  const availablePrograms = useTypedSelector(selectAvailablePrograms);
  const activeProgram = useTypedSelector(selectActiveProgram);
  const customerShipToSites = useTypedSelector(selectCurrentCustomerShipToSites);
  const customerBillToSites = useTypedSelector(selectCurrentCustomerBillToSites);
  const selectedWarehouse = useTypedSelector(selectSelectedWarehouse);
  const uiSettings: any = useTypedSelector(selectUiSettings);

  const [cartLoadProcessRan, setCartLoadProcessRan] = useState(false);
  const [cartDiscountsInitialized, setCartDiscountsInitialized] = useState(false);

  const programCode = window.sessionStorage.getItem(`selectedProgramCode${availablePrograms?.accountId}`);

  useEffect(() => {
    if (location.pathname === COMMERCE_CORE_CONSTANTS.PATHNAMES.customerSearch) {
      setCartLoadProcessRan(false);
    }
  }, [location]);

  useEffect(() => {
    const loadActiveCarts = async () => {
      if (
        !cartLoaded &&
        !cartLoadProcessRan &&
        customerIsValid &&
        programCode &&
        currentUser?.userId &&
        customerShipToSites &&
        customerBillToSites &&
        currentCustomer &&
        (selectedWarehouse || currentCustomer.class !== 'ENTERPRISE')
      ) {
        setCartLoadProcessRan(true);

        const activeCartsResponse = await getActiveCarts(currentCustomer, currentUser);

        let activeCart: Cart | undefined;
        if (currentCustomer.class === 'ENTERPRISE') {
          // First we check if there's a cart that's been worked on
          // this selected warehouse before
          activeCart = activeCartsResponse.results.find(
            (x) =>
              x.cartItems[0]?.programCode === programCode &&
              x.userSelectedWarehouseCode &&
              x.userSelectedWarehouseCode === selectedWarehouse?.warehouseCode
          );
          if (!activeCart) {
            // If not, we check by the warehouse from the items
            // in carts that doesn't belong to a specific warehouse
            // For warehouse 211 we have a conditional to save the items
            // with warehouse 231 and vice-versa
            activeCart = activeCartsResponse.results.find(
              (x) =>
                !x.userSelectedWarehouseCode &&
                ((x.cartItems[0]?.programCode === programCode &&
                  x.cartItems[0]?.shipFromWarehouseId === selectedWarehouse?.warehouseCode) ||
                  (x.cartItems[0]?.programCode === programCode &&
                    selectedWarehouse?.warehouseCode === '211' &&
                    x.cartItems[0]?.shipFromWarehouseId === '231') ||
                  (x.cartItems[0]?.programCode === programCode &&
                    selectedWarehouse?.warehouseCode === '231' &&
                    x.cartItems[0]?.shipFromWarehouseId === '211'))
            );
          }
        } else {
          activeCart = activeCartsResponse.results.find((x: any) => x.cartItems[0]?.programCode === programCode);
          if (!activeCart) {
            activeCart = activeCartsResponse.results.find((x: any) => x.cartItems.length === 0);
          }
        }
        if (!activeCart) {
          await initializeCart(
            'cdmpComponentResolve',
            currentCustomer,
            parseInt(currentUser.userId),
            availablePrograms,
            activeProgram,
            selectedWarehouse && selectedWarehouse?.warehouseCode ? selectedWarehouse?.warehouseCode : null,
            customerShipToSites,
            customerBillToSites,
            dispatch
          );
        } else {
          dispatch(setActiveCart(activeCart));
        }
      }
    };
    loadActiveCarts();
  }, [
    dispatch,
    cart,
    customerIsValid,
    cartLoaded,
    programCode,
    activeProgram,
    availablePrograms,
    currentCustomer,
    currentUser,
    customerBillToSites,
    customerShipToSites,
    selectedWarehouse,
    cartLoadProcessRan,
  ]);

  useEffect(() => {
    if (
      currentCustomer &&
      currentUser &&
      currentUser.userId &&
      customerShipToSites &&
      customerBillToSites &&
      activeProgram &&
      cart !== null &&
      cart !== undefined &&
      !cartLoaded
    ) {
      initializeSavedItems(
        currentCustomer,
        cart,
        true,
        activeProgram,
        customerShipToSites,
        currentUser.userId,
        customerBillToSites,
        dispatch
      );
      dispatch(setCartLoaded(true));
    }
  }, [
    cart,
    cartLoaded,
    dispatch,
    activeProgram,
    currentUser,
    customerBillToSites,
    customerShipToSites,
    currentCustomer,
  ]);

  useEffect(() => {
    const initializeCartDiscounts = async () => {
      dispatch(setCartCalculationsComplete(false));
      await calculateStandartDiscounts(
        cart as Cart,
        currentCustomer as CustomerFullProfile,
        currentUser?.userId as string,
        activeProgram,
        customerShipToSites as Site[],
        customerBillToSites as Site[],
        dispatch,
        availablePrograms,
        uiSettings?.calculateCartDiscountsInBackend
      );
      dispatch(setCartCalculationsComplete(true));
    };
    if (
      cart &&
      currentCustomer &&
      currentUser?.userId &&
      currentCustomer?.productTypeWarehouseCodes &&
      customerShipToSites &&
      customerBillToSites &&
      activeProgram &&
      availablePrograms &&
      !cartDiscountsInitialized &&
      uiSettings
    ) {
      setCartDiscountsInitialized(true);
      initializeCartDiscounts();
    }
  }, [
    cart,
    currentCustomer,
    currentCustomer?.productTypeWarehouseCodes,
    currentUser?.userId,
    activeProgram,
    customerShipToSites,
    customerBillToSites,
    dispatch,
    availablePrograms,
    cartDiscountsInitialized,
    uiSettings,
  ]);

  return <div>{children}</div>;
};

export default CartResolves;
