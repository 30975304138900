import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import COMMERCE_CORE_CONSTANTS from '../../../../Core/constants';
import {baseApiCallWithReauth} from '../../../../Framework/api-utils/api-utils';
import {QuoteSearch} from '../../ISearchState';
import {initialQuoteParams} from '../ISearchQuoteState';

const endpoint = COMMERCE_CORE_CONSTANTS.API_SERVICES.QUOTE.quotes;

export const initialState: QuoteSearch = {
  suggestions: [],
  quoteUrlSearchParams: initialQuoteParams,
  activeFilter: 'Details',
  quoteActiveParams: [],
  loading: 'idle',
  currentRequestId: undefined,
};

export const quoteSearchAsync = createAsyncThunk('quoteSearch/quoteSearchAsync', async (data: URLSearchParams) => {
  return await baseApiCallWithReauth('GET', endpoint, data);
});
export const loadMoreQuotesAsync = createAsyncThunk(
  'quoteSearch/loadMoreOquotesAsync',
  async (data: URLSearchParams) => {
    return await baseApiCallWithReauth('GET', endpoint, data);
  }
);

export const quoteSearchSlice = createSlice({
  name: 'quoteSearch',
  initialState,
  reducers: {
    setActiveQuoteFilter: (state, action) => {
      state.activeFilter = action.payload;
    },
    setQuoteUrlSearchParams: (state, action) => {
      state.quoteUrlSearchParams = action.payload;
    },
    setQuoteActiveParams: (state, action) => {
      state.quoteActiveParams = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(quoteSearchAsync.pending, (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending';
      }
      state.currentRequestId = action.meta.requestId;
      state.suggestions = [];
    });
    builder.addCase(quoteSearchAsync.fulfilled, (state, action) => {
      const {requestId} = action.meta;
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle';
        state.suggestions = action.payload.results;
        state.currentRequestId = undefined;
      }
    });
    builder.addCase(loadMoreQuotesAsync.fulfilled, (state, action) => {
      state.suggestions = action.payload.results;
    });
  },
});

export const {setActiveQuoteFilter, setQuoteUrlSearchParams, setQuoteActiveParams} = quoteSearchSlice.actions;
export default quoteSearchSlice.reducer;
