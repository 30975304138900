import {createSlice} from '@reduxjs/toolkit';
import {filters, initialOrderParams, initialQuoteParams} from '../ISearchState';
import productSearchReducer, {
  setProductKeyword,
  setProductFilter,
  setProductRecommendations,
} from '../product/slice/product-search.slice';
import customerSearchReducer, {
  customerSearchAsync,
  getDefaultMarketRepCustomer,
  setCustomerKeyword,
  loadMoreCustomersAsync,
} from '../customer/slice/customer-search.slice';
import orderSearchReducer, {
  setActiveOrderFilter,
  orderSearchAsync,
  setOrderUrlSearchParams,
  setOrderActiveParams,
  loadMoreOrdersAsync,
} from '../order/slice/order-search.slice';
import quoteSearchReducer, {
  setActiveQuoteFilter,
  quoteSearchAsync,
  setQuoteUrlSearchParams,
  setQuoteActiveParams,
  loadMoreQuotesAsync,
} from '../quote/slice/quote-search.slice';
import {RootState} from '../../../store/store';

export const initialState = {
  product: {
    keyword: '',
    activeFilter: {
      name: 'All',
      param: 'All',
      mobileName: 'All',
      urn: '',
    },
    suggestions: [],
    availableFilters: filters,
  },
  order: {
    suggestions: [],
    orderUrlSearchParams: initialOrderParams,
    activeFilter: 'Details',
    orderActiveParams: [],
    loading: 'idle',
    currentRequestId: undefined,
  },
  quote: {
    suggestions: [],
    quoteUrlSearchParams: initialQuoteParams,
    activeFilter: 'Details',
    quoteActiveParams: [],
    loading: 'idle',
    currentRequestId: undefined,
  },
  customer: {
    keyword: '',
    suggestions: [],
    numberOfResults: 0,
    loading: 'idle',
    currentRequestId: undefined,
    marketRepCustomer: undefined,
  },
};

export const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //Product
    builder.addCase(setProductKeyword, (state: any, action: any) => {
      state.product = productSearchReducer(state.product, action);
    });
    builder.addCase(setProductFilter, (state: any, action: any) => {
      state.product = productSearchReducer(state.product, action);
    });
    builder.addCase(setProductRecommendations, (state: any, action: any) => {
      state.product = productSearchReducer(state.product, action);
    });
    //Customer
    builder.addCase(setCustomerKeyword, (state: any, action: any) => {
      state.customer = customerSearchReducer(state.customer, action);
    });
    builder.addCase(customerSearchAsync.pending, (state: any, action: any) => {
      state.customer = customerSearchReducer(state.customer, action);
    });
    builder.addCase(customerSearchAsync.fulfilled, (state: any, action: any) => {
      state.customer = customerSearchReducer(state.customer, action);
    });
    builder.addCase(getDefaultMarketRepCustomer.fulfilled, (state: any, action: any) => {
      state.customer = customerSearchReducer(state.customer, action);
    });
    builder.addCase(loadMoreCustomersAsync.fulfilled, (state: any, action: any) => {
      state.customer = customerSearchReducer(state.customer, action);
    });
    //Order
    builder.addCase(setActiveOrderFilter, (state: any, action: any) => {
      state.order = orderSearchReducer(state.order, action);
    });
    builder.addCase(orderSearchAsync.pending, (state: any, action: any) => {
      state.order = orderSearchReducer(state.order, action);
    });
    builder.addCase(orderSearchAsync.fulfilled, (state: any, action: any) => {
      state.order = orderSearchReducer(state.order, action);
    });
    builder.addCase(setOrderUrlSearchParams, (state: any, action: any) => {
      state.order = orderSearchReducer(state.order, action);
    });
    builder.addCase(setOrderActiveParams, (state: any, action: any) => {
      state.order = orderSearchReducer(state.order, action);
    });
    builder.addCase(loadMoreOrdersAsync.fulfilled, (state: any, action: any) => {
      state.order = orderSearchReducer(state.order, action);
    });
    //Quote
    builder.addCase(setActiveQuoteFilter, (state: any, action: any) => {
      state.quote = quoteSearchReducer(state.quote, action);
    });
    builder.addCase(quoteSearchAsync.pending, (state: any, action: any) => {
      state.quote = quoteSearchReducer(state.quote, action);
    });
    builder.addCase(quoteSearchAsync.fulfilled, (state: any, action: any) => {
      state.quote = quoteSearchReducer(state.quote, action);
    });
    builder.addCase(setQuoteUrlSearchParams, (state: any, action: any) => {
      state.quote = quoteSearchReducer(state.quote, action);
    });
    builder.addCase(setQuoteActiveParams, (state: any, action: any) => {
      state.quote = quoteSearchReducer(state.quote, action);
    });
    builder.addCase(loadMoreQuotesAsync.fulfilled, (state: any, action: any) => {
      state.quote = quoteSearchReducer(state.quote, action);
    });
  },
});

export const selectProductSearch = (state: RootState) => state.search.product;
export const selectCustomerSearch = (state: RootState) => state.search.customer;
export const selectOrderSearch = (state: RootState) => state.search.order;
export const selectQuoteSearch = (state: RootState) => state.search.quote;

export default searchSlice.reducer;
