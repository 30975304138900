import React, {FormEvent, useCallback, useEffect, useState} from 'react';

import {ConfirmResetPageFormContainer} from './ConfirmResetPageForm.styled';
import {
  resetPasswordAsync,
  selectVerfiedResponse,
  verifyTokenAsync,
} from '../../../../features/password/slice/password.slice';
import {useAppDispatch, useTypedSelector} from '../../../../hooks/store';
import {useHistory} from 'react-router-dom';
import {toast} from 'react-toastify';
import COMMERCE_CORE_CONSTANTS from '../../../../Core/constants';

const ConfirmResetForm = (props: {location: any}) => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const [newEmail, setNewEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isFetchingData, setIsFetchingData] = useState(false);
  const [passwordLengthErrorMessage, setPasswordLengthErrorMessage] = useState('');
  const [confirmPasswordLengthErrorMessage, setconfirmPasswordLengthErrorMessage] = useState('');
  const [passwordsDontMatchErrorMessage, setPasswordsDontMatchErrorMessage] = useState('');

  const verifiedResponse = useTypedSelector(selectVerfiedResponse);

  const lengthErrorMessage = 'Minimum character limit not reached. (8 characters)';
  const matchErrorMessage = 'Confirm Password does not match.';

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsFetchingData(true);

    const resetToken = props.location.search.replace('?resetToken=', '');
    const email = newEmail;

    dispatch(
      verifyTokenAsync({
        userName: email,
        resetToken: resetToken,
      })
    );
  };

  const finishSubmit = useCallback(() => {
    const resetToken = props.location.search.replace('?resetToken=', '');
    const newPassword = password;
    const id = verifiedResponse && (verifiedResponse as any).id ? (verifiedResponse as any).id : '';
    try {
      dispatch(
        resetPasswordAsync({
          id: id,
          resetToken: resetToken,
          newPassword: newPassword,
        })
      );
      toast.success('We set your password to the new value you provided. Please login.');
      toast.success('Password Reset');
      history.push(COMMERCE_CORE_CONSTANTS.PATHNAMES.login);
    } catch (e) {}
    setIsFetchingData(false);
  }, [dispatch, history, password, props, verifiedResponse]);

  useEffect(() => {
    if (password.length < 8 && password.length !== 0) {
      setPasswordLengthErrorMessage(lengthErrorMessage);
    } else {
      setPasswordLengthErrorMessage('');
    }

    if (confirmPassword.length < 8 && confirmPassword.length !== 0) {
      setconfirmPasswordLengthErrorMessage(lengthErrorMessage);
    } else {
      setconfirmPasswordLengthErrorMessage('');
    }

    if (confirmPassword.length > 7 && confirmPassword.length !== 0 && password !== confirmPassword) {
      setPasswordsDontMatchErrorMessage(matchErrorMessage);
    } else {
      setPasswordsDontMatchErrorMessage('');
    }
  }, [password, confirmPassword]);

  useEffect(() => {
    if (verifiedResponse && (verifiedResponse as any).id) {
      finishSubmit();
    }

    if (verifiedResponse && (verifiedResponse as any).message) {
      toast.error((verifiedResponse as any).message);
      setIsFetchingData(false);
    }
  }, [verifiedResponse, finishSubmit]);

  return (
    <ConfirmResetPageFormContainer onSubmit={handleSubmit}>
      <h3 className="title">Confirm Reset</h3>
      <div className="privacy-terms">You are about to reset your password.</div>
      <div className="form-group has-feedback">
        <input
          type="email"
          name="username"
          placeholder="Email"
          value={newEmail}
          onChange={(e) => {
            setNewEmail(e.target.value);
          }}
        />
        <br />
        <input
          type="password"
          name="password"
          value={password}
          placeholder="Password"
          onChange={(e) => setPassword(e.target.value)}
        />
        <span className="error-message">{passwordLengthErrorMessage}</span>
        <br />
        <input
          type="password"
          name="confirmPassword"
          value={confirmPassword}
          placeholder="Confirm Password"
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
        <span className="error-message">{confirmPasswordLengthErrorMessage + passwordsDontMatchErrorMessage}</span>
      </div>
      <button
        id="LoginSignIn"
        type="submit"
        className="submit-button"
        disabled={
          isFetchingData ||
          !newEmail ||
          !password ||
          !confirmPassword ||
          passwordLengthErrorMessage !== '' ||
          confirmPasswordLengthErrorMessage !== '' ||
          passwordsDontMatchErrorMessage !== ''
        }>
        Reset
      </button>
    </ConfirmResetPageFormContainer>
  );
};

export default ConfirmResetForm;
