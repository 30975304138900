import {baseApiCallWithReauth} from '../../../Framework/api-utils/api-utils';
import COMMERCE_CORE_CONSTANTS from '../../../Core/constants';
import qs from 'qs';
import {IBillingAddress} from '../ISalesforceState';

export const getSalesforceContactsRequest = async (id: string) => {
  try {
    return await baseApiCallWithReauth(
      'GET',
      COMMERCE_CORE_CONSTANTS.API_SERVICES.SALESFORCE.contacts + `?accountId=${encodeURIComponent(id)}`
    );
  } catch (error: any) {
    throw error;
  }
};

export const getSalesforceAccountsRequest = async (name: string) => {
  try {
    return await baseApiCallWithReauth(
      'GET',
      COMMERCE_CORE_CONSTANTS.API_SERVICES.SALESFORCE.accounts + `?name=${encodeURIComponent(name)}`
    );
  } catch (error: any) {
    throw error;
  }
};

export const getSalesforceAccountByErpIdRequest = async (erpCustomerId: number) => {
  try {
    return await baseApiCallWithReauth(
      'GET',
      COMMERCE_CORE_CONSTANTS.API_SERVICES.SALESFORCE.customerAccount + `/${erpCustomerId}`
    );
  } catch (error: any) {
    throw error;
  }
};

export const getSalesforceQuotesRequest = async (id: string) => {
  try {
    return await baseApiCallWithReauth(
      'GET',
      COMMERCE_CORE_CONSTANTS.API_SERVICES.SALESFORCE.quotes,
      qs.stringify({id})
    );
  } catch (error: any) {
    throw error;
  }
};

export const getSalesforceAccountByIdRequest = async (id: string) => {
  try {
    return await baseApiCallWithReauth('GET', COMMERCE_CORE_CONSTANTS.API_SERVICES.SALESFORCE.accounts + `/${id}`);
  } catch (error: any) {
    throw error;
  }
};

export const getRetailAccountsRequest = async (erpCustomerId: number) => {
  try {
    return await baseApiCallWithReauth(
      'GET',
      COMMERCE_CORE_CONSTANTS.API_SERVICES.SALESFORCE.retailAccounts + `/${erpCustomerId}`
    );
  } catch (error: any) {
    throw error;
  }
};

export const getRetailAccountsWithParamsRequest = async (params: string) => {
  try {
    return await baseApiCallWithReauth(
      'GET',
      COMMERCE_CORE_CONSTANTS.API_SERVICES.SALESFORCE.retailAccounts,
      qs.stringify({accountId: params})
    );
  } catch (error: any) {
    throw error;
  }
};

export const getSalesforceAddressesRequest = async (
  query: string,
  inexactQuery = false
): Promise<Array<IBillingAddress>> => {
  return await baseApiCallWithReauth(
    'GET',
    COMMERCE_CORE_CONSTANTS.API_SERVICES.SALESFORCE.addresses,
    qs.stringify({addressQuery: query, includeInexact: inexactQuery})
  );
};
