import styled from 'styled-components';
import {$gray93, $newprimary, $newwhite, $screenmd} from '../../../../Styled/variables';

const NavContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  height: 65px;
  margin: -30px 0px 30px 0px;
  .container {
    margin-right: auto;
    margin-left: auto;
    padding-left: 15px;
    padding-right: 15px;
  }
  .link {
    background-color: ${$gray93};
    width: 100%;
    font-size: 14px;
    font-weight: 600;
    color: black !important;
    box-shadow: 0px 1px 3px 0px rgba(135, 135, 135, 0.35);
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
    padding: 5px;
    :hover {
      text-decoration: none !important;
      cursor: pointer;
    }
  }
  .active-link {
    background-color: ${$newwhite};
    color: ${$newprimary} !important;
  }
  .dropdown-toggle {
    height: 100% !important;
    background: white !important;
    color: black !important;
    border: 1px solid gray !important;
    font-size: 16px !important;
    display: flex !important;
    align-content: flex-start !important;
    justify-content: space-between !important;
    width: 100%;
    :hover {
      color: black !important;
      background: white !important;
    }
    ::after {
      margin-left: 20px !important;
      float: right;
      margin-top: 10px;
    }
  }
  .dropdown-menu {
    width: 100%;
    font-size: 16px !important;
    color: black !important;
  }
  .mobile-nav {
    width: 100%;
  }
  @media screen and (max-width: ${$screenmd}) {
    margin: 25px 0px 25px 0px;
  }
`;
export default NavContainer;
