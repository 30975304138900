import {Container} from 'react-bootstrap';
import styled from 'styled-components';
import {
  $gray30,
  $gray67,
  $gray78,
  $gray93,
  $gray97,
  $newinactivebackground,
  $newprimary,
} from '../../../Styled/variables';

export const FabricationOrderEntryContainer = styled(Container)`
  label {
    font-size: 14px !important;
  }

  .card,
  .card-header,
  .card-body {
    background: ${$gray93};
    border: none;
    overflow: visible !important;
  }

  .fab-header-text-container {
    padding: 20px;
    background-color: ${$newinactivebackground};
    margin-bottom: 20px;

    .fab-header-text {
      font-weight: 600;
    }
  }

  .cancel-quote-edit-button {
    color: #8c8c8c;
    border: 1px solid #c8c8c8;
    transition: all ease-in-out 0.5s;

    &:hover {
      color: #c8c8c8;
    }

    span {
      color: #8c8c8c;
    }
  }

  .fab-proceed-to-cart-button {
    width: 100%;
    justify-content: center;
  }

  .number-box {
    width: 40px;
    height: 40px;
    border: 1px solid ${$gray67} !important;
    font-size: 20px;
    color: ${$gray30};
    background-color: ${$gray97};
  }

  .step-heading {
    font-size: 20px;
    color: ${$gray30};
  }

  .order-details-accordion {
    box-shadow: 0px 1px 1px rgb(0 0 0 / 20%);
    margin-bottom: 20px;
  }

  .seamlessL-header {
    font-weight: 700;
    font-size: 14px;
  }

  .seamlessL-dimensions-container {
    box-shadow: 0px 1px 1px rgb(0 0 0 / 20%);
    background-color: #e5e5e5;
    padding-top: 10px;
    margin: 5px 5px 10px 0px;
    border-radius: 3px;
  }

  .disabled-accordion-toggle {
    color: ${$gray78};
  }

  .margin-left-15 {
    margin-left: 15px;
  }

  .highlight-red {
    background-color: #fb72725e;
    border-radius: 3px;
  }

  .save-piece-spinner {
    color: #ffffff !important;
  }

  .proceed-to-cart-container {
    margin-bottom: 20px;
  }

  .btn-quotes {
    font-size: 15px;
    color: #8c8c8c;
    font-weight: 500;
    text-decoration: underline;
    transition: all ease-in-out 0.5s;

    &:hover {
      color: #c8c8c8;
    }

    &:focus {
      box-shadow: none;
    }
  }
`;

export const EdgeProfileContainer = styled.div`
  background-color: #e5e5e5;
  box-shadow: 0px 1px 1px rgb(0 0 0 / 20%);
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 5px;

  .circular-button {
    height: 25px;
    width: 25px;
    box-shadow: 0px 1px 1px rgb(0 0 0 / 20%);
    color: ${$newprimary};
  }

  .clear-button {
    height: 25px;
    font-size: 12px !important;
  }

  .edge-profile-label {
    font-size: 14px;
    font-weight: 700;
  }

  .add-profile-spinner {
    color: ${$newprimary};
  }
`;
