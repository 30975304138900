import {callback} from '../../callbacks/service/callbacks.service';
import {CartItem} from '../../cart/ICartState';
import {CustomerFullProfile, Site} from '../../customer/ICustomerState';
import {getShipFromWarehouseId} from '../../warehouse/service/warehouse.service';
import {NewProductDesignResponse} from '../../productCatalog/IProductCatalogState';
import {toast} from 'react-toastify';
import {
  deleteCartItemsRequest,
  getDeliverySchedulesRequest,
  getItemAvailabilityRequest,
  getProductDesignsRequest,
  getProductsInCartRequest,
  getSavedItemsRequest,
  postRequestedDeliverySchedules,
} from '../controller/product.controller';
import search_constants from '../../../Core/Components/Header/Search/searchConstants';
import constants from '../../../Core/constants';

export const getProductsInCart = async (cartId: string, customer: CustomerFullProfile, shipToSites: Site[]) => {
  const classGroups: string[] = customer.classGroups;
  let params = new URLSearchParams();
  params.append('cartId', cartId);
  params.append('erpCustomerId', customer.erpCustomerId.toString());
  params.append('customerClass', customer.class);
  classGroups.forEach((item) => {
    params.append('customerClassGroups', item);
  });

  let slabWarehouse = getShipFromWarehouseId('Slab', customer, shipToSites);
  let tileWarehouse = getShipFromWarehouseId('Tile', customer, shipToSites);
  let samplesWarehouse = getShipFromWarehouseId('Samples', customer, shipToSites);
  let storeWarehouse = getShipFromWarehouseId('Store', customer, shipToSites);

  params.append('slabWarehouseCode', slabWarehouse);
  params.append('tileWarehouseCode', tileWarehouse);
  params.append('samplesWarehouseCode', samplesWarehouse);
  params.append('storeWarehouseCode', storeWarehouse);
  return getProductsInCartRequest(params);
};

export const removeCartItem = async (cartItem: CartItem, cartId: string, dispatch: any) => {
  const callbackId = await deleteCartItemsRequest(cartItem, cartId);
  const stringId = await callbackId.text();
  return await callback(stringId, 90, dispatch);
};

export const calculateTileSquareFootage = (unitSize: number, quantity: number): number => {
  const totalTileSquareFootage = quantity * unitSize;
  return Math.ceil(totalTileSquareFootage);
};

export const calculateTileUnitSize = (dimensions: string): number => {
  const dimensionsArray = dimensions.split('x');
  return (parseInt(dimensionsArray[0], 10) / 12) * (parseInt(dimensionsArray[1], 10) / 12);
};

export const getSavedItems = async (currentCustomer: CustomerFullProfile, userId: string): Promise<any> => {
  const customer = currentCustomer;

  let params = new URLSearchParams();
  params.append('erpCustomerId', customer.erpCustomerId.toString());
  params.append('userId', userId);
  params.append('pager.limit', '100');
  params.append('pager.sort', '-id');
  return await getSavedItemsRequest(params);
};

export const getItemAvailability = async (params: any) => {
  let data = new URLSearchParams(params);
  return getItemAvailabilityRequest(data);
};

export const getAllActiveProductDesigns = async (
  currentCustomer: CustomerFullProfile,
  programCode: string
): Promise<any[]> => {
  let results = await getProductDesignsRequest(currentCustomer, programCode);
  results = results ? results.results : [NewProductDesignResponse];
  return results;
};

export const getDeliverySchedule = async (cartId: string, dispatch: any) => {
  if (!cartId) {
    return Promise.reject('Invalid/missing parameter');
  }
  try {
    return await getDeliverySchedulesRequest(cartId);
  } catch (error: any) {
    toast.error(error.data.message);
  }
};

export const addDeliverySchedule = async (scheduledItems: any, dispatch: any) => {
  let sucessfullyAddedSchedule: boolean;

  if (!scheduledItems) {
    return Promise.reject('Invalid/missing parameter');
  }

  try {
    const callbackId = await postRequestedDeliverySchedules(scheduledItems);
    await callback(callbackId, 90, dispatch);
    sucessfullyAddedSchedule = true;
  } catch (error: any) {
    sucessfullyAddedSchedule = false;
    throw error;
  }

  return sucessfullyAddedSchedule;
};

export const setUrlForNav = (keyword: string, filterParam: string): string => {
  let searchUrl = search_constants.product.searchResults.baseUrl + keyword;

  if (!keyword) {
    searchUrl = search_constants.homePage;
  }
  if (filterParam) {
    searchUrl += search_constants.product.searchResults.productTypeFilter + filterParam;
  }
  return searchUrl;
};

export const assignEndpoint = (filter: string): string => {
  let endpoint = constants.API_SERVICES.PRODUCT.products;
  if (filter === 'Slab') {
    endpoint = constants.API_SERVICES.PRODUCT.slabs;
  } else if (filter === 'Samples') {
    endpoint = constants.API_SERVICES.PRODUCT.samples;
  } else if (filter === 'Tile') {
    endpoint = constants.API_SERVICES.PRODUCT.tiles;
  } else if (filter === 'Store') {
    endpoint = constants.API_SERVICES.PRODUCT.store;
  }
  return endpoint;
};
