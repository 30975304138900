import React from 'react';
import {Col, Row} from 'react-bootstrap';
import {CartTotalContainer} from './CartTotal.styled';
import {Cart} from '../../../../features/cart/ICartState';
import {formatOrderCurrency} from '../../../../Framework/Services/formatCurrency';
import {useCartTotalValues} from '../../../../features/cart/hooks/useCartTotalValues/useCartTotalValues';

const CartTotal = ({cart}: {cart: Cart}) => {
  const items = cart?.cartItems;
  const {totalLinePrice, subTotalPrice, isDiscountOrPriceAdjustment} = useCartTotalValues();

  return (
    <CartTotalContainer>
      <Col className="totals-container">
        <Row className="header-row totals">
          <Col xs={6} md={10} className="cart-total-text">
            CART TOTAL
          </Col>
          <Col xs={6} md={2} className="cart-price-text">
            <span className="table-bold-value">${formatOrderCurrency(totalLinePrice)} </span>
            <span className="table-secondary-value">{items && items.length > 0 ? items[0].currency : 'USD'}</span>
            {isDiscountOrPriceAdjustment && subTotalPrice ? (
              <div>
                <br />
                <span className="original-price-strike-through">
                  ${formatOrderCurrency(subTotalPrice)}&nbsp;
                  <span className="table-secondary-value">{items && items.length > 0 ? items[0].currency : 'USD'}</span>
                </span>
              </div>
            ) : (
              <div></div>
            )}
          </Col>
        </Row>
      </Col>
    </CartTotalContainer>
  );
};

export default CartTotal;
