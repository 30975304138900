import {CORE_CONSTANTS} from '../../../Framework/constant';
import Cookies from 'universal-cookie';
import {AuthenticationState} from '../IAuthenticationState';

export async function sendAuthRequest(
  data: URLSearchParams,
  rejectWithValue: any,
  isAnonymous?: boolean,
  rememberMe?: boolean
) {
  let anonymousAuthResponse: any = null;
  let anonymousResponse: any = null;
  const authEndpoint: string = isAnonymous
    ? CORE_CONSTANTS.API_SERVICES.anonymousToken
    : CORE_CONSTANTS.API_SERVICES.authToken;

  const endpoint = windowObj.SERVER_DATA.apiUrl + authEndpoint;

  const response = await fetch(endpoint, {
    method: 'POST',
    body: data,
  });
  if (response.status !== 200) {
    const data = new URLSearchParams();
    data.append('grant_type', 'password');
    const anonEndpoint = windowObj.SERVER_DATA.apiUrl + CORE_CONSTANTS.API_SERVICES.anonymousToken;
    anonymousResponse = await fetch(anonEndpoint, {
      method: 'POST',
      body: data,
    });
    anonymousAuthResponse = await anonymousResponse.json();
  }
  const authResponse = await response.json();
  let auth: AuthenticationState = {
    isAuthenticated: response.status === 200 || (anonymousResponse && anonymousResponse.status === 200),
    isAuthenticating: false,
    isRefreshing: false,
    isRefreshNeeded: false,
    response: response.status === 200 ? authResponse : anonymousAuthResponse,
    error: response.status === 200 ? '' : 'Error Logging In',
  };
  if (auth.isAuthenticated && auth.response && auth.response.access_token) {
    auth.user = JSON.parse(atob(auth.response.access_token.split('.')[1]));

    if (auth.user && auth.user.email === 'csfanonymous@cambriausa.com') {
      auth.isAuthenticated = false;
      windowObj.localStorage.setItem(CORE_CONSTANTS.COOKIES.cambriaAnonymousRefresh, JSON.stringify(auth));
    } else {
      let userGroupIdsAsString = !!auth.user ? auth.user.userGroupIds : '';
      let userGroupIds = JSON.parse(userGroupIdsAsString);
      if (!!userGroupIds && userGroupIds.filter((x: any) => x !== 6).length === 0) {
        return rejectWithValue({
          overrideErrorMessage: true,
          error: 'Your account set up is not complete. Please contact 866-CAMBRIA for assistance',
        });
      }
      const authEvent = new CustomEvent('csf:login', {
        detail: auth.user,
      });

      window.dispatchEvent(authEvent);
      setRefreshToken(auth.response?.refresh_token as string);
    }
  }
  windowObj.localStorage.setItem(CORE_CONSTANTS.COOKIES.ecommerceToken, JSON.stringify(auth));
  return auth;
}

export const windowObj = window as any;

//ToDo: is there a better way;
export function getSavedAuth(): AuthenticationState {
  let savedAuth = windowObj.localStorage.getItem(CORE_CONSTANTS.COOKIES.ecommerceToken);
  const savedAngularAuth = windowObj.localStorage.getItem(CORE_CONSTANTS.COOKIES.cambriaRefreshToken);
  if (savedAuth) {
    let auth = JSON.parse(savedAuth) as AuthenticationState;
    if (auth.response && auth.response.refresh_token) {
      if (!savedAngularAuth) {
        windowObj.localStorage.setItem(CORE_CONSTANTS.COOKIES.cambriaRefreshToken, auth.response.refresh_token);
      }
      auth.isRefreshNeeded = true;
    }

    if (auth.user) {
      const authEvent = new CustomEvent('csf:login', {
        detail: auth.user,
      });

      window.dispatchEvent(authEvent);
    }

    return auth;
  } else {
    return {
      isRefreshing: false,
      isAuthenticating: false,
      isAuthenticated: false,
      isRefreshNeeded: false,
      error: '',
    };
  }
}

export function setRefreshToken(newToken: string) {
  try {
    windowObj.localStorage.setItem(CORE_CONSTANTS.COOKIES.cambriaRefreshToken, newToken);
  } catch (e) {
    console.log(e);
  }
}

function removeRefreshToken() {
  windowObj.localStorage.removeItem(CORE_CONSTANTS.COOKIES.cambriaRefreshToken);
  windowObj.sessionStorage.clear();

  const cookies = new Cookies();
  cookies.remove(CORE_CONSTANTS.COOKIES.cambriaRefreshToken);
}

export const forceLogout = () => {
  windowObj.localStorage.removeItem(CORE_CONSTANTS.COOKIES.ecommerceToken);
  removeRefreshToken();
  windowObj.location.reload();
};
