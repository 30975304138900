import React, {useEffect, useState} from 'react';
import {
  getBolShipmentReportEmailDataAsync,
  selectBolShipmentReport,
  selectBolShipmentReportEmailData,
} from '../../../../../features/reports/slice/reports.slice';
import {useAppDispatch, useTypedSelector} from '../../../../../hooks/store';
import {BolShipmentReportContainer} from './BolShipmentReport.styled';
import {DisplayField} from '../../../../../features/customer/ICustomerState';
import TableHeader from '../ReportTables/TableHeader';
import TableItems from '../ReportTables/TableItems';
import {
  downloadBolShipmentReportData,
  subscribeToReport,
  unsubscribeFromReport,
} from '../../../../../features/reports/controllers/reports.controller';
import {selectCurrentCustomer} from '../../../../../features/customer/slice/customer.slice';
import RequestAccessPage from '../Placeholders/RequestAccessPage';
import COMMERCE_CORE_CONSTANTS from '../../../../../Core/constants';
import {toast} from 'react-toastify';
import {selectCurrentUser} from '../../../../../features/auth/slice/authentication.slice';
import {getQRCodeByCustomer} from '../../../../../features/lastMile/controller/lastmile.controller';
import {selectUiSettings} from '../../../../../features/environment/slice/environment.slice';
import {Col} from 'react-bootstrap';

const defaultTableColumns: DisplayField[] = [
  {
    code: 'deliveryID',
    name: 'Delivery ID',
  },
  {
    code: 'orderDate',
    name: 'Order Date',
  },
  {
    code: 'customerAccountName',
    name: 'Customer Account Name',
  },
  {
    code: 'shipToName',
    name: 'Ship To Name',
  },
  {
    code: 'orderNumber',
    name: 'Order #',
  },
  {
    code: 'pONumber',
    name: 'PO #',
  },
  {
    code: 'cambriaFulfillmentDC',
    name: 'Cambria Fulfillment DC',
  },
  {
    code: 'design',
    name: 'Design',
  },
  {
    code: 'size',
    name: 'Size',
  },
  {
    code: 'thickness',
    name: 'Thickness',
  },
  {
    code: 'finish',
    name: 'Finish',
  },
  {
    code: 'orderedQuantity',
    name: 'Ordered Quantity',
  },
  {
    code: 'invoicedDate',
    name: 'Invoiced Date',
  },
  {
    code: 'confirmedDockDate',
    name: 'Confirmed Dock Date',
  },
];

const BolShipmentReport = ({hasPermission}: {hasPermission: boolean}) => {
  const dispatch = useAppDispatch();

  const {entity: reportData, status} = useTypedSelector(selectBolShipmentReport);
  const erpCustomerId = useTypedSelector(selectCurrentCustomer)?.erpCustomerId;
  const [subscriptionLoading, setSubscriptionLoading] = useState<boolean>(false);
  const activeUser = useTypedSelector(selectCurrentUser);
  const emailSubscriptionData = useTypedSelector(selectBolShipmentReportEmailData);
  const uiSettings = useTypedSelector(selectUiSettings);

  let [qrCode, setQrCode] = useState('');

  const isLoading = status === 'loading';

  const handleEmailSubscription = async () => {
    setSubscriptionLoading(true);
    if (emailSubscriptionData?.length === 0) {
      try {
        const userNames = activeUser ? activeUser?.given_name.split(' ') : [];
        await subscribeToReport({
          email: activeUser?.email ?? '',
          firstName: userNames[0],
          lastName: userNames[1],
          userId: activeUser?.userId ?? '',
          type: COMMERCE_CORE_CONSTANTS.REPORTS.bolShipment,
          erpCustomerId,
        });
        toast.success(
          'Your user email has been successfully subscribed to the daily Recently Shipped Slab Orders Report'
        );
      } catch (e) {
        console.log(e);
      }
    } else if (emailSubscriptionData && emailSubscriptionData.length > 0) {
      try {
        await unsubscribeFromReport({
          erpCustomerId,
          email: emailSubscriptionData[0].email,
          subscriptionId: emailSubscriptionData[0].id,
        });
        toast.success(
          'Your user email has been successfully unsubscribed from the daily Recently Shipped Slab Orders Report'
        );
      } catch (e) {
        console.log(e);
      }
    }
    if (erpCustomerId && activeUser?.userId) {
      dispatch(getBolShipmentReportEmailDataAsync({erpCustomerId: erpCustomerId, userId: activeUser.userId}));
    }
  };

  useEffect(() => {
    setSubscriptionLoading(false);
  }, [emailSubscriptionData]);

  useEffect(() => {
    if (erpCustomerId && !emailSubscriptionData && activeUser?.userId) {
      dispatch(getBolShipmentReportEmailDataAsync({erpCustomerId: erpCustomerId, userId: activeUser.userId}));
    }
  }, [erpCustomerId, emailSubscriptionData, activeUser, dispatch]);

  useEffect(() => {
    const loadQrCode = async () => {
      const qrCode = await getQRCodeByCustomer(erpCustomerId.toString());
      setQrCode(qrCode);
    };

    if (!qrCode && erpCustomerId && uiSettings?.enableDetrack) {
      loadQrCode();
    }
  }, [erpCustomerId, qrCode, uiSettings]);

  const exportToExcel = async () => {
    const file = await downloadBolShipmentReportData(erpCustomerId);
    const url = window.URL.createObjectURL(file);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `Recently Shipped Orders Report - ${new Date().toISOString()}.xlsx`);
    document.body.appendChild(link);
    link.click();
  };

  if (!hasPermission) {
    return (
      <RequestAccessPage
        endpoint={`${COMMERCE_CORE_CONSTANTS.API_SERVICES.CUSTOMER.customers}/${erpCustomerId}${COMMERCE_CORE_CONSTANTS.API_SERVICES.CUSTOMER.bolShipmentReport}`}
      />
    );
  }

  return (
    <BolShipmentReportContainer>
      {isLoading && <h1>Loading...</h1>}
      {!reportData?.reportLines && !isLoading ? (
        <div className="no-results-page">
          There is no current activity to display at this time.
          {emailSubscriptionData && (
            <button
              className={`subscription-btn ${
                emailSubscriptionData && emailSubscriptionData.length > 0 ? 'unsubscribe' : 'subscribe'
              }`}
              data-testid="subscribe-to-emails"
              onClick={() => handleEmailSubscription()}
              disabled={subscriptionLoading}>
              <>
                <i className="fa fa-envelope" />
                &nbsp; {emailSubscriptionData?.length > 0 ? 'Unsubscribe' : 'Subscribe'}
              </>
            </button>
          )}
        </div>
      ) : (
        <>
          <div className="load-report-action-buttons">
            {emailSubscriptionData && (
              <button
                className={`subscription-btn ${
                  emailSubscriptionData && emailSubscriptionData.length > 0 ? 'unsubscribe' : 'subscribe'
                }`}
                data-testid="subscribe-to-emails"
                onClick={() => handleEmailSubscription()}
                disabled={subscriptionLoading}>
                <>
                  <i className="fa fa-envelope" />
                  &nbsp; {emailSubscriptionData?.length > 0 ? 'Unsubscribe' : 'Subscribe'}
                </>
              </button>
            )}
            <button className="print-icon-button" onClick={exportToExcel}>
              <i className="fa fa-print" />
            </button>
          </div>
          <table className="table-condensed">
            <TableHeader tableColumns={reportData?.displayFields ?? defaultTableColumns} />
            <TableItems
              reportLines={reportData?.reportLines ?? []}
              tableColumns={reportData?.displayFields ?? defaultTableColumns}
              isLoading={isLoading}
            />
          </table>
        </>
      )}
      {uiSettings?.enableDetrack ? (
        <Col sm={12} md={6} lg={4} className="qr-code-container">
          <img className={'qr-code-img'} alt={'qrCode'} src={qrCode}></img>
          <br />
          <h2 className={'qr-code-text'}>Scan QR code for automated estimated delivery times on all orders.</h2>
        </Col>
      ) : (
        <Col sm={12} md={6} lg={4}></Col>
      )}
    </BolShipmentReportContainer>
  );
};

export default BolShipmentReport;
