import COMMERCE_CORE_CONSTANTS from '../../../Core/constants';
import {CustomerFullProfile, NewSite, Site} from '../../customer/ICustomerState';
import {
  getRetailAccountsRequest,
  getRetailAccountsWithParamsRequest,
  getSalesforceAccountByIdRequest,
  getSalesforceAccountsRequest,
  getSalesforceAddressesRequest,
  getSalesforceContactsRequest,
  getSalesforceQuotesRequest,
} from '../controller/salesforce.controller';
import {toast} from 'react-toastify';
import {Program} from '../program/IProgramState';
import {IBillingAddress} from '../ISalesforceState';

export const convertSalesforceContactToCheckoutContact = (contact: any) => {
  return {
    firstName: contact.firstName,
    lastName: contact.lastName,
    phoneNumber: [contact.phone],
    email: [contact.email],
    mobileNumber: [contact.mobilePhone],
    displayName: contact.firstName ? `${contact.firstName} ${contact.lastName}` : `${contact.lastName}`,
  };
};

export const setSalesforceAccountIdInSessionStorage = (salesforceAccountId: string, id?: number) => {
  if (id) {
    window.sessionStorage.setItem(
      COMMERCE_CORE_CONSTANTS.WINDOW_SESSION.selectedSalesforceAccountId,
      salesforceAccountId
    );
  }
};

export const removeSalesforceAccountIdFromSessionStorage = () => {
  window.sessionStorage.removeItem(COMMERCE_CORE_CONSTANTS.WINDOW_SESSION.selectedSalesforceAccountId);
};

export const getCurrentSalesforceAccountId = (): string | null => {
  return window.sessionStorage.getItem(COMMERCE_CORE_CONSTANTS.WINDOW_SESSION.selectedSalesforceAccountId);
};

export const getRetailAccountsByErpCustomerId = async (erpCustomerId: number): Promise<Site[]> => {
  if (!erpCustomerId) {
    return [];
  }

  try {
    let retailAccounts = await getRetailAccountsRequest(erpCustomerId);
    retailAccounts = retailAccounts.results.map((retailAccount: any) => {
      return convertAccountToSite(retailAccount);
    });

    return retailAccounts;
  } catch (error) {
    toast.error('There was a problem showing the requested addresses. Please contact Customer Care for assistance.');
    return [];
  }
};

export const getRetailAccounts = async (params: string): Promise<Site[]> => {
  if (!params) {
    return [];
  }

  let retailAccounts = await getRetailAccountsWithParamsRequest(params);

  retailAccounts = retailAccounts.results.map((retailAccount: any) => {
    return convertAccountToSite(retailAccount);
  });

  return retailAccounts;
};

export const getRetailAccountsForACustomer = async (customer: CustomerFullProfile): Promise<Site[]> => {
  let result: Site[] = [];
  try {
    result = await getRetailAccountsByErpCustomerId(customer.erpCustomerId);
  } catch (error: any) {
    if (error.status !== 404) {
      throw error;
    }
  }

  try {
    if (!!result && result.length > 0) {
      const additionalResults = await getRetailAccounts(result[0]?.retailCrmAccountId || '');
      result = [...result, ...additionalResults];
    } else {
      const additionalResults = await getRetailAccounts(customer?.crmAccountId || '');
      result = [...result, ...additionalResults];
    }
  } catch (error: any) {
    if (error.status !== 404) {
      throw error;
    }
  }
  return result;
};

export const convertAccountToSite = (retailAccount: any): Site => {
  const result: Site = NewSite();
  if (retailAccount.billingAddress) {
    // tslint:disable-next-line: max-line-length
    result.address = `${retailAccount.name} ${retailAccount.billingAddress.street} ${retailAccount.billingAddress.state} ${retailAccount.billingAddress.postalCode}`;
    result.address1 = retailAccount.billingAddress.street;
    result.city = retailAccount.billingAddress.city;
    result.country = retailAccount.billingAddress.country;
    result.postalCode = retailAccount.billingAddress.postalCode;
    result.stateProvince = retailAccount.billingAddress.state;
    result.missingAddressDetails = false;
    result.segment = retailAccount.segment;
  } else {
    result.missingAddressDetails = true;
    result.address = retailAccount.name;
  }
  if (retailAccount.contacts) {
    result.contacts = retailAccount.contacts.map((contact: any) => {
      return convertSalesforceContactToCheckoutContact(contact);
    });
  }
  if (retailAccount.additional_Addresses) {
    result.additional_Addresses = retailAccount.additional_Addresses.map((address: any) => {
      return address;
    });
  }
  result.siteName = retailAccount.name;
  result.siteType = 'SHIP_TO';
  result.retailCrmAccountId = retailAccount.id;

  return result;
};

export const concatAddress = (salesforceAccounts: Array<any>) => {
  return salesforceAccounts.map((acc) => {
    acc.name = `${acc.name} ${acc.billingAddress.street?.replace(/(\r\n)|\n/g, ' ')} ${acc.billingAddress.city} ${
      acc.billingAddress.state
    } ${acc.billingAddress.postalCode} ${acc.billingAddress.country}`;
    return acc;
  });
};

export const getSalesforceContacts = async (id: string) => {
  try {
    const contacts = await getSalesforceContactsRequest(id);
    return contacts.results.map((contact: any) => {
      return convertSalesforceContactToCheckoutContact(contact);
    });
  } catch (error: any) {
    toast.error(error.data ? error.data.message : error);
    return [];
  }
};

export const getSalesforceAccounts = async (name: string) => {
  const accounts = await getSalesforceAccountsRequest(name);
  return concatAddress(accounts.results);
};

export const getSalesfoceAddresses = async (query: string, inexactQuery = false): Promise<Array<IBillingAddress>> => {
  return await getSalesforceAddressesRequest(query, inexactQuery);
};

export const getQuotesBySalesforceAccountId = async (salesforceAccountId: string): Promise<any> => {
  const result = await getSalesforceQuotesRequest(salesforceAccountId);
  return result.quotes;
};

export const getSalesforceAccountById = async (id: string) => {
  const result = await getSalesforceAccountByIdRequest(id);
  return concatAddress([result])[0];
};

export const getAccountByAccountId = async (purchaseForHouseholdAccountId: any) => {
  let result = null;

  if (!!purchaseForHouseholdAccountId) {
    result = await getSalesforceAccountByIdRequest(purchaseForHouseholdAccountId);
  }
  return result;
};

export const isBundleProgram = (program: Program): boolean =>
  program?.code?.startsWith(COMMERCE_CORE_CONSTANTS.PROGRAMS.BUNDLE_PROGRAM_CODE_PREFIX);
