import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {selectCurrentCustomer} from '../../../../../features/customer/slice/customer.slice';
import {CustomerFullProfile} from '../../../../../features/customer/ICustomerState';
import {selectUiSettings} from '../../../../../features/environment/slice/environment.slice';
import {UiSettings} from '../../../../../features/environment/IEnvironmentState';
import {selectUserActions} from '../../../../../features/permission/slice/permission.slice';
import Icon from '../../../../../Framework/Components/Icon';
import {useTypedSelector} from '../../../../../hooks/store';
import {DropdownLink, InternalDropdownLink, NavItem} from '../../HeaderNavigation/HeaderNavigation.styled';
import CustomerHeaderDropdown from '../CustomerHeaderDropdown';
import {MobileSidebarContainer} from './MobileNavigation.styled';
import COMMERCE_CORE_CONSTANTS from '../../../../constants';
import {DropdownItem} from '../../HeaderNavigation/IDropdownItem';

interface IMobileSidebarProps {
  sidebarOn: boolean;
  toggleSidebar: Function;
  closeSidebar: Function;
}
export interface INavItem {
  name: string;
  type: string;
  urn: string;
  show: boolean;
  href: string;
  isDisabled: boolean;
}

const MobileSidebar = ({sidebarOn, toggleSidebar, closeSidebar}: IMobileSidebarProps) => {
  const history = useHistory();

  const uiSettings: UiSettings | null = useTypedSelector(selectUiSettings);
  const currentCustomer: CustomerFullProfile | null = useTypedSelector(selectCurrentCustomer);
  const userActions = useTypedSelector(selectUserActions);

  const [customerDetailsExapnded, setCustomerDetailsExapnded] = useState(false);
  const [technicalInfoExapnded, setTechnicalInfoExapnded] = useState(false);
  const [toolsAndResourcesExapnded, setToolsAndResourcesExapnded] = useState(false);
  const [navItems, setNavItems] = useState<Array<INavItem>>([
    {
      name: 'Slabs',
      type: 'slabs',
      urn: 'urn:csa:commerceui:productCatalog:viewSlabs',
      show: false,
      href: '/product/slabs/',
      isDisabled: false,
    },
    {
      name: 'Samples',
      type: 'samples',
      urn: 'urn:csa:commerceui:productCatalog:viewSamples',
      show: true,
      href: '/product/samples/',
      isDisabled: false,
    },
    {
      name: 'Fabrication',
      type: 'fabrication',
      urn: 'urn:csa:commerceui:productcatalog:viewfabrication',
      show: true,
      href: COMMERCE_CORE_CONSTANTS.PATHNAMES.fabrication,
      isDisabled: false,
    },
  ]);

  useEffect(() => {
    if (userActions) {
      setNavItems((prevState: Array<INavItem>) => {
        prevState.forEach((navItem: INavItem) => {
          userActions.forEach((userAction: string) => {
            if (userAction.match(/([A-z0-9:]+:\*)/g) && navItem.urn.indexOf(userAction.replace(':*', '')) >= 0) {
              navItem.isDisabled = false;
            }
            if (navItem.urn === userAction) {
              navItem.isDisabled = false;
            }
          });
        });

        return prevState;
      });
    }
  }, [userActions]);

  const renderNavItems = () => {
    return navItems.map((item: INavItem) => {
      if (!item.isDisabled) {
        return (
          <div className="mobile-link" key={item.urn} onClick={() => (toggleSidebar ? toggleSidebar() : null)}>
            <div className="mobile-link-content">
              <NavItem className="internal-link" to={item.href}>
                {item.name}
              </NavItem>
            </div>
          </div>
        );
      }
      return null;
    });
  };

  const renderDropdownContent = (menu: any) => {
    return menu.map((item: DropdownItem) => {
      if (!item.openInNewTab) {
        return (
          <InternalDropdownLink
            onClick={() => {
              if (toggleSidebar) {
                toggleSidebar();
              }
              setTechnicalInfoExapnded(false);
              setToolsAndResourcesExapnded(false);
              history.push(item.url);
            }}
            key={item.order}>
            <div className="dropdown-content-section-title">
              {item.title}
              <Icon icon="icons-cambria-icon_external" color="#333" colorOnHover="#c59617" size="10" weight="800" />
            </div>
            <div className="dropdown-content-section-description">{item.description}</div>
          </InternalDropdownLink>
        );
      } else {
        return (
          <DropdownLink
            onClick={() => {
              if (toggleSidebar) {
                toggleSidebar();
              }
              setTechnicalInfoExapnded(false);
              setToolsAndResourcesExapnded(false);
            }}
            to={{pathname: item.url}}
            key={item.order}
            target="_blank">
            <div className="dropdown-content-section-title">
              {item.title}
              <Icon icon="icons-cambria-icon_external" color="#333" colorOnHover="#c59617" size="10" weight="800" />
            </div>
            <div className="dropdown-content-section-description">{item.description}</div>
          </DropdownLink>
        );
      }
    });
  };

  return (
    <MobileSidebarContainer className={sidebarOn ? 'on' : ''}>
      {!customerDetailsExapnded && (
        <div className="customer-toggle collapsed">
          <div
            className="d-flex align-items-center"
            onClick={() => setCustomerDetailsExapnded(!customerDetailsExapnded)}>
            <div className="customer-name">{currentCustomer?.name}</div>
            <Icon className="m-3" color="#fff" size="8" weight="600" icon="icons-cambria-Ui-Triangle" />
          </div>
        </div>
      )}
      {customerDetailsExapnded && (
        <div className="customer-toggle expanded">
          <div
            className="d-flex align-items-center m-b-sm"
            onClick={() => setCustomerDetailsExapnded(!customerDetailsExapnded)}>
            <div className="customer-name">{currentCustomer?.name}</div>
            <Icon className="m-3" color="#000" size="8" weight="600" icon="icons-cambria-Ui-Triangle" />
          </div>
          <CustomerHeaderDropdown closeSidebar={closeSidebar}></CustomerHeaderDropdown>
        </div>
      )}
      <div className="mobile-links">
        {renderNavItems()}
        <div className={`mobile-link technical-info ${technicalInfoExapnded ? 'open' : ''}`}>
          <div className="mobile-link-content">
            <span className="mobile-link-title" onClick={() => setTechnicalInfoExapnded(!technicalInfoExapnded)}>
              Technical Info
              <Icon className="m-3" color="#000" size="6" weight="600" icon="icons-cambria-Ui-Triangle" />
            </span>
            {technicalInfoExapnded && <div className="p-t-sm">{renderDropdownContent(uiSettings?.technicalInfo)}</div>}
          </div>
        </div>
        <div className={`mobile-link tools-and-resources ${toolsAndResourcesExapnded ? 'open' : ''}`}>
          <div className="mobile-link-content">
            <span
              className="mobile-link-title"
              onClick={() => setToolsAndResourcesExapnded(!toolsAndResourcesExapnded)}>
              Tools & Resources
              <Icon className="m-3" color="#000" size="6" weight="600" icon="icons-cambria-Ui-Triangle" />
            </span>
            {toolsAndResourcesExapnded && (
              <div className="p-t-sm">
                <div>
                  <div className="section-title">
                    {uiSettings && uiSettings.toolsAndResources ? uiSettings.toolsAndResources[0].columnName : null}
                  </div>
                  {uiSettings && uiSettings.toolsAndResources
                    ? renderDropdownContent(uiSettings.toolsAndResources[0].items)
                    : null}
                </div>
                <div className="section-title">
                  {uiSettings && uiSettings.toolsAndResources ? uiSettings.toolsAndResources[1].columnName : null}
                </div>
                <div>
                  {uiSettings && uiSettings.toolsAndResources
                    ? renderDropdownContent(uiSettings.toolsAndResources[1].items)
                    : null}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </MobileSidebarContainer>
  );
};

export default MobileSidebar;
