import React from 'react';
import {formatPrice} from '../../../../../../Framework/Services/formatPrice.service';

interface SingleItemPriceProps {
  price: number;
  currencyCode?: string;
  pricingUom?: string;
}

const SingleItemPrice = ({price, currencyCode, pricingUom}: SingleItemPriceProps) => {
  return (
    <div className="price-container">
      <div className="price">
        <h2>
          <span className="actual-price">
            {price !== 0 ? formatPrice(price) : '$0.00'}
            {currencyCode ? ' ' + currencyCode : ''}
          </span>
          {pricingUom === 'SF' && <sup className="price-uom">{' / sq.ft.'}</sup>}
          {pricingUom === 'EA' && <sup className="price-uom">each</sup>}
          {pricingUom === 'PK' && <sup className="price-uom">pack</sup>}
        </h2>
      </div>
    </div>
  );
};

export default SingleItemPrice;
