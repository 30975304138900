import {baseApiCallWithReauth} from '../../../Framework/api-utils/api-utils';
import COMMERCE_CORE_CONSTANTS from '../../../Core/constants';
import qs from 'qs';
import {IPlaceOrderRequest} from '../IOrderState';
import {IWorkflowRejection} from "../orderDetails/IOrderDetailsState";

export const postOrderFavoriteRequest = async (orderNumber: string, favoriteName: string): Promise<any> => {
  try {
    return await baseApiCallWithReauth(
      'POST',
      `${COMMERCE_CORE_CONSTANTS.API_SERVICES.ORDER.favorite}`,
      JSON.stringify({orderNumber: orderNumber, favoriteName: favoriteName}),
      true
    );
  } catch (error: any) {
    throw error;
  }
};

export const deleteOrderFavoriteByIdRequest = async (id: number) => {
  if (!id || id === 0) {
    throw new Error('Missing id parameter.');
  }
  try {
    return await baseApiCallWithReauth(
      'DELETE',
      `${COMMERCE_CORE_CONSTANTS.API_SERVICES.ORDER.favorite}?id=${id}`,
      null,
      true
    );
  } catch (error: any) {
    throw error;
  }
};

export const postOrderEmailRequest = async (
  orderNumber: string,
  recipient: string,
  erpCustomerId: string,
  orderUrl: string
) => {
  try {
    return await baseApiCallWithReauth(
      'POST',
      `${COMMERCE_CORE_CONSTANTS.API_SERVICES.ORDER.email}`,
      JSON.stringify({
        orderNumber: orderNumber,
        template: 'OrderSummary',
        orderDetailsUrl: orderUrl,
        recipients: [recipient],
      })
    );
  } catch (error: any) {
    throw error;
  }
};

export const putOrderDetailsRequest = async (cartId: string, params: any): Promise<any> => {
  try {
    return await baseApiCallWithReauth(
      'PUT',
      `${COMMERCE_CORE_CONSTANTS.API_SERVICES.ORDER.orderDetails}/${cartId}`,
      JSON.stringify(params),
      true
    );
  } catch (error: any) {
    throw error;
  }
};

export const getOrderNotificationContactsRequest = async (params: any) => {
  try {
    return await baseApiCallWithReauth(
      'GET',
      COMMERCE_CORE_CONSTANTS.API_SERVICES.ORDER.orderNotificationContacts,
      qs.stringify({
        cartId: params,
      })
    );
  } catch (error: any) {
    throw error;
  }
};

export const postOrderNotificationContactsRequest = async (cartId: string, phoneNumber: string, emails: Array<any>) => {
  try {
    return await baseApiCallWithReauth(
      'POST',
      COMMERCE_CORE_CONSTANTS.API_SERVICES.ORDER.orderNotificationContacts,
      JSON.stringify({
        cartId: cartId,
        orderSmsNotificationContacts: phoneNumber
          ? [
              {
                salesforceContactId: '',
                salesforceAccountId: '',
                mobilePhone: phoneNumber,
                phone: '',
                email: '',
                firstName: '',
                lastName: '',
              },
            ]
          : [],
        orderEmailNotificationContacts: emails,
      }),
      true
    );
  } catch (error: any) {
    throw error;
  }
};

export const getOrderRejectionsRequest = async (orderNumber: string): Promise<IWorkflowRejection[]> => {
  try {
    return await baseApiCallWithReauth(
      'GET',
      `${COMMERCE_CORE_CONSTANTS.API_SERVICES.ORDER.ordersRejections}/${orderNumber}`
    );
  } catch (error: any) {
    throw error;
  }
};

export const postResubmitOrderRequest = async (params: any) => {
  try {
    return await baseApiCallWithReauth(
      'POST',
      COMMERCE_CORE_CONSTANTS.API_SERVICES.ORDER.resubmitOrder,
      JSON.stringify(params),
      true
    );
  } catch (error: any) {
    throw error;
  }
};

export const putOrdersRequest = async (orderNumber: string, params: any) => {
  try {
    return await baseApiCallWithReauth(
      'PUT',
      COMMERCE_CORE_CONSTANTS.API_SERVICES.ORDER.orders + '/' + orderNumber,
      JSON.stringify(params),
      true
    );
  } catch (error: any) {
    throw error;
  }
};

export const postOrdersRequest = async (params: IPlaceOrderRequest) => {
  try {
    return await baseApiCallWithReauth(
      'POST',
      COMMERCE_CORE_CONSTANTS.API_SERVICES.ORDER.orders,
      JSON.stringify(params),
      true
    );
  } catch (error: any) {
    throw error;
  }
};
