import {fabricationJobTypes, jobTypes} from '../IJobTypeState';

export const getFabricationJobTypeCode = (jobType: string): string => {
  return getJobTypeCodeDependingOnTheType(jobType, true);
};

export const getJobTypeCodeDependingOnTheType = (jobType: string, isItFabrication: boolean): string => {
  let selectedJobTypeDisplayCode = '';

  if (isItFabrication) {
    fabricationJobTypes.forEach((item) => {
      if (item.displayName === jobType) {
        selectedJobTypeDisplayCode = item.code;
      }
    });
  } else {
    jobTypes.forEach((item) => {
      if (item.displayName === jobType) {
        selectedJobTypeDisplayCode = item.code;
      }
    });
  }

  return selectedJobTypeDisplayCode;
};

export const isReworkOrRemake = (orderType: string) => {
  return orderType === 'Remake' || orderType === 'Rework';
};

export const isOrderOfTypeModification = (selectedOrderType: string) => {
  return selectedOrderType && selectedOrderType.indexOf('New') === -1;
};
