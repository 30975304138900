import React, {useEffect, useState} from 'react';
import COMMERCE_CORE_CONSTANTS from '../../../../Core/constants';
import {
  selectCurrentCustomer,
  selectCurrentCustomerShipToSites,
} from '../../../../features/customer/slice/customer.slice';
import {CustomerFullProfile, ShipToSite} from '../../../../features/customer/ICustomerState';
import {setAvailableWarehouses, setSelectedWarehouse} from '../../../../features/warehouse/slice/warehouse.slice';
import {useAppDispatch, useTypedSelector} from '../../../../hooks/store';
import {useSessionStorage} from '../../../../hooks/useStorage';
import {useLocation} from 'react-router-dom';
import {getWarehouses} from '../../../../features/warehouse/service/warehouse.service';

const WarehouseResolves = ({children}: {children: any}) => {
  const dispatch = useAppDispatch();
  const location = useLocation();

  const shipToSites: any = useTypedSelector(selectCurrentCustomerShipToSites);
  const currentCustomer: CustomerFullProfile | null = useTypedSelector(selectCurrentCustomer);

  const [warehouseCodes, setWarehouseCodes] = useState<string[]>([]);
  const [activeWarehouse, setActiveWarehouse] = useState<ShipToSite | null>(null);
  const [warehouseCodesHaveBeenLoaded, setWarehouseCodesHaveBeenLoaded] = useState(false);

  const [sessionStorageWarehouseCode, setSessionStorageWarehouseCode, removeSessionStorageWarehouseCode] =
    useSessionStorage(COMMERCE_CORE_CONSTANTS.WINDOW_SESSION.selectedWarehouse);

  const getAllAvailableWarehousesCodes = (sites: Array<ShipToSite>): Array<string> => {
    let warehouseCodes: Array<string> = [];

    sites.forEach((site: ShipToSite) => {
      if (site.warehouseCode) {
        warehouseCodes.push(site.warehouseCode);
      }
    });
    let warehouseCodesWithoutNull = warehouseCodes.filter((code) => code !== null);
    return warehouseCodesWithoutNull.filter((code, pos) => warehouseCodesWithoutNull.indexOf(code) === pos);
  };

  useEffect(() => {
    if (location.pathname === COMMERCE_CORE_CONSTANTS.PATHNAMES.customerSearch) {
      setWarehouseCodes([]);
      setActiveWarehouse(null);
      setWarehouseCodesHaveBeenLoaded(false);
    }
  }, [location]);

  useEffect(() => {
    if (currentCustomer && currentCustomer.class && currentCustomer.class.toLowerCase() === 'enterprise') {
      if (shipToSites && shipToSites.length > 0) {
        setWarehouseCodes(() => getAllAvailableWarehousesCodes(shipToSites));
      }
    }
  }, [currentCustomer, shipToSites, removeSessionStorageWarehouseCode, sessionStorageWarehouseCode]);

  useEffect(() => {
    const getAvailableWarehouses = async () => {
      const availableWarehouses = await getWarehouses(warehouseCodes);
      dispatch(setAvailableWarehouses(availableWarehouses));
      let selectedWarehouse = availableWarehouses.find(
        (warehouse: ShipToSite) =>
          Number(warehouse.warehouseCode) === (sessionStorageWarehouseCode ? sessionStorageWarehouseCode : 211)
      );

      if (!selectedWarehouse) {
        selectedWarehouse = availableWarehouses[0];
      }

      setActiveWarehouse(selectedWarehouse || null);
      dispatch(setSelectedWarehouse(selectedWarehouse));
      setSessionStorageWarehouseCode(Number(selectedWarehouse.warehouseCode));
    };
    if (warehouseCodes && warehouseCodes.length > 0 && !activeWarehouse && !warehouseCodesHaveBeenLoaded) {
      getAvailableWarehouses();
      setWarehouseCodesHaveBeenLoaded(true);
    }
  }, [
    activeWarehouse,
    sessionStorageWarehouseCode,
    setSessionStorageWarehouseCode,
    dispatch,
    warehouseCodes,
    warehouseCodesHaveBeenLoaded,
  ]);

  return <>{children}</>;
};

export default WarehouseResolves;
