import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {selectAvailablePrograms} from '../../../../../features/salesforce/slice/salesforce.slice';
import {selectCurrentUser} from '../../../../../features/auth/slice/authentication.slice';
import {useTypedSelector} from '../../../../../hooks/store';
import {CustomerHeaderDropdownContent} from './CustomerHeaderDropdown.styled';
import {
  selectCurrentCustomer,
  selectCurrentCustomerBillToSite,
} from '../../../../../features/customer/slice/customer.slice';
import WarehouseSelector from '../WarehouseSelector';
import {CustomerFullProfile} from '../../../../../features/customer/ICustomerState';
import {selectDefaultCustomerStatus} from '../../../../../features/resolves/slice/resolves.slice';
import {User} from '../../../../../store/auth/auth.service';
import AvailableProgramCheckbox from './AvailableProgramCheckbox';
import {useCustomerLogout} from '../../../../../hooks/useCustomerLogout';

interface ICustomerHeaderContentProps {
  closeSidebar?: Function;
}

const CustomerHeaderContent = ({closeSidebar}: ICustomerHeaderContentProps) => {
  const history = useHistory();
  const customerLogout = useCustomerLogout();

  const [isInternalUser, setIsInternalUser] = useState(false);
  const [emailDomain, setEmailDomain] = useState('');
  const [addressLine1, setAddressLine1] = useState('');
  const [addressLine2, setAddressLine2] = useState('');
  const [availablePrograms, setAvailablePrograms] = useState({programs: []});
  const [showWarehouseSelector, setShowWarehouseSelector] = useState(false);

  const currentUser: User | undefined = useTypedSelector(selectCurrentUser);
  const defaultCustomerNotFound = useTypedSelector(selectDefaultCustomerStatus);
  const currentCustomer: CustomerFullProfile | null = useTypedSelector(selectCurrentCustomer);
  const currentCustomerBillToSite: any = useTypedSelector(selectCurrentCustomerBillToSite);
  const sfAvailablePrograms: any = useTypedSelector(selectAvailablePrograms);

  const customerCanChangeUsers = defaultCustomerNotFound === null || defaultCustomerNotFound;

  useEffect(() => {
    setIsInternalUser(currentUser?.uniquename.split(':')[0] === 'dfh');
    currentUser && setEmailDomain(currentUser?.email.split('@')[1]);
    setAddressLine1(`${currentCustomerBillToSite?.address1 || ''} ${currentCustomerBillToSite?.address2 || ''}`);
    setAddressLine2(
      `${currentCustomerBillToSite?.city}, ${currentCustomerBillToSite?.stateProvince} ${currentCustomerBillToSite?.postalCode} ${currentCustomerBillToSite?.country}`
    );
    if (availablePrograms.programs.length === 0) {
      setAvailablePrograms(sfAvailablePrograms);
    }

    if (currentCustomer && currentCustomer.class && currentCustomer.class.toLowerCase() === 'enterprise') {
      setShowWarehouseSelector(true);
    }
  }, [currentUser, currentCustomer, currentCustomerBillToSite, sfAvailablePrograms, availablePrograms]);

  const routeAndToggleMenu = () => {
    if (closeSidebar) {
      closeSidebar();
    }
    history.push('/account/orders');
  };

  const handleCustomerLogout = () => {
    if (closeSidebar) {
      closeSidebar();
    }
    customerLogout();
    // Link changed to div, this added - RemoveOnCutoverComplete
  };

  if (!currentUser || !currentCustomer) {
    return <></>;
  }

  return (
    <CustomerHeaderDropdownContent>
      <div className="dropdown-content-section">
        {!isInternalUser && <span className="dropdown-content-section-title">{currentUser.given_name}</span>}
        <span className="dropdown-content-section-content">{addressLine1}</span>
        <span className="dropdown-content-section-content">{addressLine2}</span>
      </div>
      <div className="dropdown-content-section">
        <button className="primary-button">
          <div data-testid="viewAccountDetailsLink" onClick={() => routeAndToggleMenu()}>
            View Account Details
          </div>
        </button>
      </div>
      <div className="dropdown-content-section">
        <span className="dropdown-content-section-title">
          {currentCustomer.class}
          {isInternalUser && <span> ({currentCustomer.erpCustomerId})</span>}
        </span>
        <span className="dropdown-content-section-content">{currentUser.email.toLowerCase()}</span>
        <span className="dropdown-content-section-content">{emailDomain.toLowerCase()}</span>
      </div>
      {availablePrograms?.programs?.length > 1 ? (
        <div className="dropdown-content-section">
          <span className="dropdown-content-section-checkbox-title">Ordering through:</span>
          <div className="dropdown-content-section-checkbox">
            <AvailableProgramCheckbox programs={availablePrograms?.programs} />
          </div>
        </div>
      ) : (
        ''
      )}
      {showWarehouseSelector && (
        <div className="dropdown-content-section">
          <WarehouseSelector />
        </div>
      )}
      {customerCanChangeUsers && (
        <div className="dropdown-content-section">
          <button className="secondary-button">
            <div data-testid="logoutButton" onClick={() => handleCustomerLogout()}>
              LOGOUT OF THIS CLIENT
            </div>
          </button>
        </div>
      )}
    </CustomerHeaderDropdownContent>
  );
};

export default CustomerHeaderContent;
