import React from 'react';
import COMMERCE_CORE_CONSTANTS from '../../../../../Core/constants';
import DeliveryDayPreferences from '../DeliveryDaysComponent/DeliveryDays.component';
import PreferredShippingContactPreferences from '../PreferredShippingContactComponent';
import {hasPermission} from '../../../../../store/permission/permission.service';
import {useTypedSelector} from '../../../../../hooks/store';
import {selectUserActions} from '../../../../../features/permission/slice/permission.slice';
import {selectUiSettings} from '../../../../../features/environment/slice/environment.slice';

const SlabPreferences = ({erpCustomerId}: {erpCustomerId: number}) => {
  let slabEndpoint = `${COMMERCE_CORE_CONSTANTS.API_SERVICES.CUSTOMER.slabOrderPreferences}`;

  let userActions = useTypedSelector(selectUserActions);
  let uiSettings = useTypedSelector(selectUiSettings);

  let showDeliveryDays = hasPermission('urn:csa:commerceui:slabDeliveryDays', userActions);
  let showPreferredShippingContact = hasPermission('urn:csa:commerceui:preferredShippingContact', userActions);

  return (
    <div className="order-preference-body">
      <div className="order-preference-page-header">
        <h1 className="preference-page-title">SLAB ORDER PREFERENCES</h1>
      </div>
      {showDeliveryDays ? <DeliveryDayPreferences endpoint={slabEndpoint} erpCustomerId={erpCustomerId} /> : <></>}
      {showPreferredShippingContact && uiSettings?.enableDetrack ? (
        <PreferredShippingContactPreferences erpCustomerId={erpCustomerId} />
      ) : (
        <></>
      )}
    </div>
  );
};

export default SlabPreferences;
