import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {selectUserActions} from '../../../../../../features/permission/slice/permission.slice';
import {selectProductSearch} from '../../../../../../features/search/slice/search.slice';
import useWindowDimensions from '../../../../../../hooks/getWindowDimensions';
import {useTypedSelector} from '../../../../../../hooks/store';
import {IProductSearchFilter} from '../../../../../../features/search/product/IProductSearchState';
import {setProductFilter} from '../../../../../../features/search/product/slice/product-search.slice';
import {hasPermission} from '../../../../../../store/permission/permission.service';
import {findFilterMatch, setMenuOptions} from '../../../../../../features/productType/service/productType.service';

interface ProductTypeDropdownProps {
  clearInput: Function;
}

const ProductTypeDropdown = ({clearInput}: ProductTypeDropdownProps) => {
  const dispatch = useDispatch();
  const {width} = useWindowDimensions();

  let [selectedItem, setSelectedItem] = useState('All');
  let [isHidden, showHideDropdown] = useState(true);

  let searchParams = useTypedSelector(selectProductSearch);
  let userActions = useTypedSelector(selectUserActions);

  const checkPermissions = (filters: IProductSearchFilter[]) => {
    let viewableFilters = [];
    for (const filter of filters) {
      if (hasPermission(filter.urn, userActions) || filter.name === 'All') {
        viewableFilters.push(filter);
      }
    }
    return viewableFilters;
  };

  let selectedFilter = searchParams.activeFilter;
  let availableFilters: IProductSearchFilter[] = checkPermissions(searchParams.availableFilters);
  const dropdownBodyRef = React.createRef<any>();

  const onClickOutside = useCallback(() => {
    showHideDropdown(true);
  }, []);

  const handleShowHide = () => {
    showHideDropdown(!isHidden);
  };

  const selectionHandler = (itemName: string, filters: IProductSearchFilter[]) => {
    setSelectedItem(itemName);
    showHideDropdown(true);
    dispatch(setProductFilter(findFilterMatch(filters, itemName)));
    clearInput();
  };

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (dropdownBodyRef.current && !dropdownBodyRef.current.contains(event.target)) {
        onClickOutside && onClickOutside();
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [onClickOutside, dropdownBodyRef]);

  const MenuOptions = () => {
    let filterDropdownOptions = setMenuOptions(selectedItem, availableFilters);
    return (
      <div>
        {filterDropdownOptions.map((item: IProductSearchFilter) => (
          <div
            className="item-type-option"
            key={item.order}
            onClick={() => selectionHandler(item.name, availableFilters)}>
            {item.name}
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="dropdown">
      <div
        data-testid="product-type-dropdown"
        className={'dropdown-button ' + (isHidden ? '' : 'dropdown-body-selected')}
        onClick={() => handleShowHide()}>
        <span className={'dropdown-text ' + (isHidden ? '' : 'dropdown-text-selected')}>
          {width < 920 ? selectedFilter.mobileName : selectedFilter.name}
        </span>
        <span className={'icon icons-cambria-Ui-Triangle ' + (isHidden ? '' : 'dropdown-text-selected')}></span>
      </div>
      <div className="dropdown-body" hidden={isHidden} ref={dropdownBodyRef}>
        <MenuOptions></MenuOptions>
      </div>
    </div>
  );
};

export default ProductTypeDropdown;
