import React from 'react';
import useWindowDimensions from '../../../../../../hooks/getWindowDimensions';
import OrderDetailsHeaderDesktop from './desktop';
import {IOrderHeaderData} from './IOrderHeader';
import OrderDetailsHeaderMobile from './mobile';

const OrderDetailsHeader = ({
  headerData,
  canSeeJobNumber,
}: {
  headerData: IOrderHeaderData;
  canSeeJobNumber: boolean;
}) => {
  const {width} = useWindowDimensions();

  if (width > 960) {
    return <OrderDetailsHeaderDesktop headerData={headerData} canSeeJobNumber={canSeeJobNumber} />;
  } else {
    return <OrderDetailsHeaderMobile headerData={headerData} canSeeJobNumber={canSeeJobNumber} />;
  }
};

export default OrderDetailsHeader;
