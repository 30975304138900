import React, {FC, useCallback, useEffect, useState} from 'react';
import {Carousel} from 'react-bootstrap';
import InnerImageZoom from 'react-inner-image-zoom';
import {ProductImageZoomContainer} from './ProductImageZoom.styled';

export const ProductImageZoom: FC<any> = ({hideCarousel, displayImage, images, imagesPerSlide}) => {
  const prevArrowIcon = <i className="icon icons-cambria-Ui-Left-Arrow-No-Tail"></i>;
  const nextArrowIcon = <i className="icon icons-cambria-Ui-Right-Arrow-No-Tail"></i>;

  let [carouselImageArray, setCarouselImageArray] = useState([]);
  let [mainDisplayImage, setMainDisplayImage]: any = useState(null);
  let [initializeCarouselImages, setInitializeCarouselImages] = useState(true);

  const buildCarouselImageArray = useCallback(
    (images: any) => {
      let array = [];

      for (const image of images) {
        if (image.type !== 'default') {
          array.push(image);
        }
      }

      let numberOfArraysNeeded = array.length / imagesPerSlide;

      let tempCarouselImageArray: any = [];
      for (let i = 0; i < numberOfArraysNeeded; i++) {
        tempCarouselImageArray.push([]);
        for (let x = 0; x < imagesPerSlide; x++) {
          let arrayIndex = x + i * imagesPerSlide;
          if (arrayIndex < array.length) {
            tempCarouselImageArray[i].push(array[arrayIndex]);
          } else {
            tempCarouselImageArray[i].push({type: 'blank'});
          }
        }
      }

      if (tempCarouselImageArray !== carouselImageArray) {
        setCarouselImageArray(tempCarouselImageArray);
      }
    },
    [carouselImageArray, imagesPerSlide]
  );

  const renderSlides = (imageArrayArray: any) => {
    return imageArrayArray.map((imageArray: any, index: number) => {
      return (
        <Carousel.Item className="carousel-card-group carousel-item" key={'carouselItem' + index}>
          <div className="d-flex justify-content-center">{renderImages(imageArray)}</div>
        </Carousel.Item>
      );
    });
  };

  const renderImages = (imageArray: any) => {
    return imageArray.map((image: any, index: number) => {
      return image.type !== 'blank' ? (
        <div
          key={image.thumbnail}
          className="carousel-item-wrapper"
          onClick={() => {
            setMainDisplayImage(image);
          }}>
          <img className="card-img-top" src={image.thumbnail} alt="Card top"></img>
        </div>
      ) : (
        <div key={image.type + index} className="carousel-item-wrapper"></div>
      );
    });
  };

  useEffect(() => {
    if (initializeCarouselImages) {
      setInitializeCarouselImages(false);
      buildCarouselImageArray(images);
    }
  }, [images, buildCarouselImageArray, initializeCarouselImages]);

  useEffect(() => {
    if (displayImage) {
      setMainDisplayImage(displayImage);
    }
  }, [displayImage]);

  return (
    <ProductImageZoomContainer className="row">
      <InnerImageZoom
        className="product-inner-image-zoom"
        src={mainDisplayImage ? mainDisplayImage.default : (images as any)[0].default}
        zoomSrc={mainDisplayImage ? mainDisplayImage.zoomed : (images as any)[0].zoomed}
        hideHint={false}
      />
      {!hideCarousel && carouselImageArray.length > 1 ? (
        <Carousel
          prevIcon={prevArrowIcon}
          nextIcon={nextArrowIcon}
          touch={true}
          indicators={false}
          interval={null}
          className="col-xs-12">
          {renderSlides(carouselImageArray)}
        </Carousel>
      ) : (
        <div></div>
      )}
    </ProductImageZoomContainer>
  );
};

export default ProductImageZoom;
