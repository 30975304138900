import React, {useState, useEffect} from 'react';
import {ReactMultiEmail} from 'react-multi-email';
import 'react-multi-email/style.css';
import {toast} from 'react-toastify';
import useWindowDimensions from '../../../hooks/getWindowDimensions';
import {CambriaInputLabel} from '../CambriaInput/CambriaInput.styled';
import {CambriaMultiInputContainer} from './CambriaMultiInput.styled';

const CambriaMultiInput = ({
  name,
  label,
  required,
  placeholder,
  onChange,
  type,
  showRequiredError,
  fieldName,
  values,
}: {
  label?: string;
  name: string;
  required?: boolean;
  placeholder?: string;
  onChange?: Function; // Pass in useState setter function
  type?: string;
  showRequiredError?: boolean;
  fieldName?: string;
  values?: string[];
}) => {
  const [entries, setEntries] = useState<string[]>(values || []);
  const {width} = useWindowDimensions();

  const validateInput = (input: string) => {
    if (type?.toLowerCase() === 'number') {
      const isValid = /^\d+$/.test(input);
      !isValid &&
        fieldName &&
        toast.error(`${fieldName} must only contain numbers.`, {toastId: 'homeDepotOrderNumbers'});
      return isValid;
    }

    return true;
  };

  useEffect(() => {
    onChange && onChange(entries);
  }, [entries, onChange]);

  return (
    <CambriaMultiInputContainer>
      {label && <CambriaInputLabel htmlFor={name}>{label}</CambriaInputLabel>}
      {label && required && <strong> *</strong>}
      {width < 960 && <br />}
      <span className="multi-input-direction">Press enter to save number</span>
      <ReactMultiEmail
        placeholder={placeholder}
        validateEmail={(entry) => {
          return validateInput(entry);
        }}
        emails={entries}
        onChange={(_numberList: string[]) => {
          setEntries(_numberList);
        }}
        getLabel={(entry: string, index: number, removeEmail: (index: number) => void) => {
          return (
            <div className="active-email" key={entry}>
              <span className="email-text">{entry}</span>
              <i className="fa fa-times" onClick={() => removeEmail(index)} />
            </div>
          );
        }}
      />
      <span className="error-messaging">
        {showRequiredError && 'This field is required. Press Enter key after typing in a number'}
      </span>
    </CambriaMultiInputContainer>
  );
};

export default CambriaMultiInput;
