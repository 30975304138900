import styled from 'styled-components/macro';

export const QuickPage = styled.div`
  .quick-product-details {
    .alternating-grid-color-gray {
      background-color: #edeeef;
    }

    .item-row {
      margin-top: 0px !important;
      border-bottom: none !important;
      @media (min-width: 480px) {
        margin-left: 0;
        margin-right: 0px;
        height: 60px;
        padding-top: 10px;
      }
    }

    .low-res-padding {
      @media (max-width: 480px) {
        padding-left: 5px;
        padding-right: 5px;
      }
    }

    .quick-order-description {
      float: left;
      font-weight: 600;

      @media (min-width: 961px) {
        font-size: 16px;
      }
      @media (max-width: 960px) {
        font-size: 11px;
      }
      @media (max-width: 480px) {
        font-size: 9px;
      }

      &.disabled {
        opacity: 0.4;
      }
    }

    .quick-order-availability-container {
      white-space: nowrap;
      display: flex;
      justify-content: start;
    }

    .quick-order-description-container {
      display: flex;
      justify-content: center;
      align-content: center;
      flex-direction: column;

      @media (min-width: 961px) {
        margin-top: -7px;
      }
      @media (max-width: 960px) {
        margin-top: -6px;
      }
      @media (max-width: 480px) {
        margin-top: 4px;
      }
    }

    .number-of-items-selected-container {
      width: 32px;
      height: 32px;
      border: solid 1px #ccc;
      text-align: center;
      padding-top: 5px;
      margin-top: 4px;

      @media (max-width: 480px) {
        width: 25px;
        height: 25px;
        padding-top: 2px;
        margin-top: 13px;
        font-size: 13px;
      }
    }

    .product-details-quick-attribute-select-row {
      padding: 0px 15px;
    }

    .quick-attribute-select-containers {
      width: 50%;

      @media (max-width: 480px) {
        padding: 0px;
      }
    }

    .quick-batch-add-button-container {
      padding: 15px;

      @media (max-width: 480px) {
        padding: 0px;
      }
    }

    .mobile-separator {
      display: none;
      @media (max-height: 700px) {
        display: block;
        margin-bottom: 150px;
      }
    }

    .batch-add-button {
      margin: 20px 0px;
      width: 100%;
      margin-bottom: 15px;
    }

    .center-button-icons {
      position: initial !important;
    }

    .quick-select-button-containers {
      display: block;
      float: right;
      margin: 0px 3px;
    }

    .remove-cart-item-btn,
    .add-cart-item-btn {
      background-color: #fff;
      text-align: center;
      border-radius: 8%;
      width: 32px;
      height: 32px;
      padding-top: 6.65px;
      border: 1px solid #ccc;
      box-shadow: 1px 1px 1px #ccc;
      margin-top: 4px;

      @media (max-width: 480px) {
        width: 25px;
        height: 25px;
        margin-top: 13px;
        padding-top: 3.65px;
      }

      cursor: pointer;

      &.disabled {
        background-color: #edeeef;
        cursor: not-allowed;
      }

      span {
        color: #c59617;
        font-size: 24px;
        line-height: 0;
      }
    }

    .remove-cart-item-btn:hover,
    .add-cart-item-btn:hover {
      background-color: #ccc;

      &.disabled {
        background-color: #edeeef;
        cursor: not-allowed;
      }
    }

    .remove-cart-item-btn:focus,
    .add-cart-item-btn:focus {
      background-color: #ccc;
      border: 1px solid black;

      &.disabled {
        background-color: #edeeef;
        cursor: not-allowed;
      }
    }

    .number-of-items-selected {
      margin: 5px;
      font-weight: 600;
    }

    .quick-in-stock-message {
      color: #7bc258;
      font-weight: bolder;
      margin-top: 10px;

      .tooltip-wrapper {
        background-color: #7bc258;
        width: 15px;
        height: 15px;
        border-radius: 50%;
        float: left;
        margin-top: 3px;
      }

      .in-stock-message {
        margin-left: 25px;

        @media (max-width: 480px) {
          display: none;
        }
      }

      @media (min-width: 961px) {
        font-size: 16px;
      }
      @media (max-width: 960px) {
        font-size: 13px;
      }
    }

    .quick-attribute-select {
      margin-bottom: 0px !important;
    }

    .quick-limited-stock-message {
      color: #c7aa00;
      font-weight: bolder;
      margin-top: 10px;

      .tooltip-wrapper {
        background-color: #c7aa00;
        width: 15px;
        height: 15px;
        border-radius: 50%;
        float: left;
        margin-top: 3px;
      }

      .limited-stock-message {
        margin-left: 25px;

        @media (max-width: 480px) {
          display: none;
        }
      }

      @media (min-width: 961px) {
        font-size: 16px;
      }
      @media (max-width: 960px) {
        font-size: 13px;
      }
    }
  }

  .quick-select-button-containers-placeholders {
    margin-top: 5px !important;

    @media (max-width: 480px) {
      margin-top: 10px !important;
    }
  }

  .placeholder-overflow-class {
    overflow: hidden;
    margin-bottom: 40px;
  }

  .pdp-quick-content-loader {
    width: inherit;
  }
`;
