import styled from 'styled-components';
import {$gray55, $gray93, $gray97, $newwhite} from '../../../../../../Styled/variables';

const FabCartContainer = styled.div`
  font-size: 12px;
  font-weight: 600;
  color: #4c4c4c;

  .no-shadow {
    box-shadow: none !important;
  }

  .fab-piece-table {
    background-color: ${$gray93};
    border-radius: 3px;
    box-shadow: 0px 1px 3px 0px rgba(101, 101, 101, 0.35);
    padding: 10px;

    .fab-piece-information-section {
      margin-bottom: 30px;
    }

    .fab-piece-info-display {
      margin: 10px 0px;
    }

    .remove-col-padding {
      padding-left: 0px;
      padding-right: 0px;
    }

    .fab-piece-info-container {
      background-color: ${$newwhite};
      padding: 12px;
      border-radius: 3px;
    }

    .main-label-row {
      margin: 5px 0px;

      .main-label-text {
        font-size: 14px;
        font-weight: 700;
        padding-left: 0px;
      }
    }

    .main-piece-design-information-row {
      margin: 5px 0px;
    }

    .sub-table {
      border-radius: 3px;
      background-color: ${$gray97};
      padding: 5px;

      .cart-page-tooltip {
        text-align: center;
      }

      .original-price-strike-through {
        text-decoration: line-through;
      }

      .main-piece-information-col {
        text-align: start;

        .dimension-text {
          text-align: start;
          padding: 0px 0px 0px 5px;
        }
      }

      .main-piece-information-col.center {
        text-align: center;
      }
    }
  }

  .upload-file-divider {
    //border-bottom: 1px solid ${$gray55};
  }

  .menu-dropdown-toggle {
    background: transparent !important;
    border: none;

    ::after {
      display: none;
    }

    :hover,
    :focus,
    :active,
    :active:focus {
      background: transparent !important;
      border: none !important;
      box-shadow: none;
    }
  }

  .dropdown-menu-container {
    font-size: 14px;
    font-weight: 500;
  }

  .edit-piece-container {
    margin-top: 10px;
    padding: 15px;
    background-color: ${$gray97};
    border-radius: 3px;
  }

  @media screen and (max-width: 600px) {
    .fab-piece-info-display {
      padding: 0px;
    }

    .total-spacing-container {
      padding: 0px;
    }
  }
`;

export default FabCartContainer;
