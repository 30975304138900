import styled from 'styled-components';

export const PDFViewerContainer = styled.div`
  display: block;
  position: fixed;
  z-index: 99999999;
  margin-top: -370px;
  width: 100%;
  height: 100%;

  .pdf-viewer-placeholder {
    background-color: black;
    width: 100%;
    height: 100%;
  }
`;
