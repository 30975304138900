import {useAppDispatch, useTypedSelector} from '../../../hooks/store';
import {selectCurrentUser} from '../../auth/slice/authentication.slice';
import {
  selectCurrentCustomer,
  selectCurrentCustomerBillToSites,
  selectCurrentCustomerShipToSites,
} from '../../customer/slice/customer.slice';
import {selectUiSettings} from '../../environment/slice/environment.slice';
import {selectActiveProgram, selectAvailablePrograms} from '../../salesforce/slice/salesforce.slice';
import {Cart} from '../ICartState';
import {getCart} from '../service/cart.service';

/**
 * Calls to get cart from cart service with data from the redux store
 * @returns A function to call getCart
 */
export const useGetCart = (): ((cartId: string) => Promise<Cart>) => {
  const dispatch = useAppDispatch();
  const customer = useTypedSelector(selectCurrentCustomer);
  const currentUser = useTypedSelector(selectCurrentUser);
  const activeProgram = useTypedSelector(selectActiveProgram);
  const shipToSites = useTypedSelector(selectCurrentCustomerShipToSites);
  const billToSites = useTypedSelector(selectCurrentCustomerBillToSites);
  const availablePrograms = useTypedSelector(selectAvailablePrograms);
  const uiSettings = useTypedSelector(selectUiSettings);
  return (cartId: string) => {
    return getCart(
      cartId,
      customer,
      currentUser?.userId ?? '',
      activeProgram,
      shipToSites ?? [],
      billToSites ?? [],
      dispatch,
      availablePrograms,
      false,
      true,
      true,
      uiSettings?.calculateCartDiscountsInBackend
    );
  };
};
