import React, {useCallback, useEffect, useState} from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import {useHistory, useLocation} from 'react-router-dom';
import CambriaButton from '../../../../Framework/Components/CambriaButton';
import Icon from '../../../../Framework/Components/Icon';
import useWindowDimensions from '../../../../hooks/getWindowDimensions';
import {ActiveStepHeading, CheckoutButtonsContainer, CheckoutNavigationContainer} from './CheckoutNavigation.styled';
import {
  disableCheckoutStepsAfterActiveStep,
  enableCheckoutStep,
  hideCheckoutStep,
  selectActiveStep,
  selectCheckoutSteps,
  selectIsSubmitting,
  selectNextButtonToastErrorMessages,
  selectStep1Completed,
  selectStep2Completed,
  selectStep3Completed,
  setActiveStep,
} from '../../../../features/checkout/slice/checkout.slice';
import {useAppDispatch, useTypedSelector} from '../../../../hooks/store';
import {CheckoutStep} from '../../../../features/checkout/ICheckoutState';
import {selectActiveCart, setActiveCart} from '../../../../features/cart/slice/cart.slice';
import {selectUserActions} from '../../../../features/permission/slice/permission.slice';
import {selectCurrentUser} from '../../../../features/auth/slice/authentication.slice';
import {
  permissionPlaceOrderBySelectedOrganizationResolved,
  permissionPlaceOrderResolved,
} from '../ReviewAndPlaceOrder/ReviewAndPlaceOrder.component';
import {getCart} from '../../../../features/cart/service/cart.service';
import {
  selectCurrentCustomer,
  selectCurrentCustomerBillToSite,
  selectCurrentCustomerShipToSites,
} from '../../../../features/customer/slice/customer.slice';
import {selectActiveProgram, selectAvailablePrograms} from '../../../../features/salesforce/slice/salesforce.slice';
import {Cart} from '../../../../features/cart/ICartState';
import {hideFullscreenLoader} from '../../../../features/fullscreenLoader/slice/fullscreenLoader.slice';
import {ISalesforceAvailablePrograms} from '../../../../features/salesforce/ISalesforceState';
import COMMERCE_CORE_CONSTANTS from '../../../../Core/constants';
import {toast} from 'react-toastify';
import {selectUiSettings} from '../../../../features/environment/slice/environment.slice';
import {pushBeginCheckoutEventToDataLayer} from '../../../../features/analytics/service/analytics.service';

interface CheckoutNavigationProps {
  children: React.ReactNode;
}

const CheckoutNavigation = ({children}: CheckoutNavigationProps) => {
  const {width} = useWindowDimensions();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useAppDispatch();

  const [steps, setSteps] = useState<Array<CheckoutStep>>([]);
  const [movingToNextStep, setMovingToNextStep] = useState<boolean>(false);
  const [canPlaceOrder, setCanPlaceOrder] = useState<boolean>();
  const [hasTriggeredGTMEvent, setHasTriggeredGTMEvent] = useState<boolean>(false);

  const customer = useTypedSelector(selectCurrentCustomer);
  const shipToSites = useTypedSelector(selectCurrentCustomerShipToSites);
  const billToSites = useTypedSelector(selectCurrentCustomerBillToSite);
  const activeProgram = useTypedSelector(selectActiveProgram);
  const checkoutSteps = useTypedSelector(selectCheckoutSteps);
  const activeStep = useTypedSelector(selectActiveStep);
  const step1Completed = useTypedSelector(selectStep1Completed);
  const step2Completed = useTypedSelector(selectStep2Completed);
  const step3Completed = useTypedSelector(selectStep3Completed);
  const isSubmitting = useTypedSelector(selectIsSubmitting);
  const nextButtonToastErrorMessages = useTypedSelector(selectNextButtonToastErrorMessages);
  const activeCart = useTypedSelector(selectActiveCart);
  const userActions = useTypedSelector(selectUserActions);
  const userInfo = useTypedSelector(selectCurrentUser);
  const availablePrograms: ISalesforceAvailablePrograms | null = useTypedSelector(selectAvailablePrograms);
  const uiSettings = useTypedSelector(selectUiSettings);

  const goNext = useCallback(
    async (index: number) => {
      if (!activeCart) {
        const currentCart: Cart = await getCart(
          (activeCart as any)?.id,
          customer,
          (userInfo as any)?.userId,
          activeProgram,
          shipToSites as any,
          billToSites as any,
          dispatch,
          availablePrograms
        );
        dispatch(setActiveCart(currentCart));
      }

      if (activeCart && activeCart.cartItems && activeCart.cartItems.length > 0) {
        dispatch(enableCheckoutStep(steps[index].step));
        await dispatch(setActiveStep(steps[index]));
        history.push(steps[index].url);
      } else {
        history.push(COMMERCE_CORE_CONSTANTS.PATHNAMES.home);
      }

      dispatch(hideFullscreenLoader());
      setMovingToNextStep(false);
    },
    [
      activeCart,
      activeProgram,
      billToSites,
      customer,
      shipToSites,
      userInfo,
      dispatch,
      history,
      steps,
      availablePrograms,
    ]
  );

  const onNavItemClick = (selectedItem: CheckoutStep, index: number) => {
    setMovingToNextStep(() => false);

    steps.forEach((item: CheckoutStep) => {
      if (selectedItem.name === item.name) {
        dispatch(setActiveStep(item));
      }
    });
    dispatch(disableCheckoutStepsAfterActiveStep(index));

    history.push(selectedItem.url);
  };

  const renderNavItemName = (item: CheckoutStep, index: number) => {
    if (width > 960) {
      return item.name;
    } else {
      return activeStep?.step === item.step ? item.name : `0${index + 1}`;
    }
  };

  const renderNavItems = () => {
    return steps.map((item: CheckoutStep, index) => (
      <div
        key={item.step}
        className={`${item.isActive ? 'active' : ''} navigation-item ${item.isDisabled ? 'isDisabled' : ''}`}
        onClick={() => {
          if (item.isDisabled) {
            return;
          }
          onNavItemClick(item, index);
        }}>
        {renderNavItemName(item, index)}
      </div>
    ));
  };

  const handleBackButtonClick = (activeStep: CheckoutStep) => {
    document.getElementById('checkout-back-button')?.blur();
    if (activeStep.step === steps[0].step) {
      exitCheckout();
    } else {
      goBack();
    }
  };

  const handleInvalidForm = () => {
    for (const error of nextButtonToastErrorMessages) {
      toast.error(error);
    }
    return;
  };

  const goBack = () => {
    setMovingToNextStep(() => false);
    const currentActiveIndex = checkoutSteps.findIndex((item: CheckoutStep) => item.isActive);
    if (checkoutSteps[currentActiveIndex - 1].isEnabled) {
      dispatch(setActiveStep(checkoutSteps[currentActiveIndex - 1]));
      dispatch(disableCheckoutStepsAfterActiveStep(currentActiveIndex - 1));
      history.push(checkoutSteps[currentActiveIndex - 1].url);
    } else {
      dispatch(setActiveStep(checkoutSteps[currentActiveIndex - 2]));
      dispatch(disableCheckoutStepsAfterActiveStep(currentActiveIndex - 2));
      history.push(checkoutSteps[currentActiveIndex - 2].url);
    }
  };

  const exitCheckout = () => {
    history.push('/cart');
    dispatch(setActiveStep(null));
  };

  useEffect(() => {
    const checkIfUserCanPlaceOrder = async () => {
      const permissionPlaceOrderBySelectedOrganization = await permissionPlaceOrderBySelectedOrganizationResolved(
        userActions
      );
      const permissionPlaceOrder = await permissionPlaceOrderResolved(userActions);

      setCanPlaceOrder(
        (permissionPlaceOrder && activeCart?.erpCustomerId === userInfo?.erpcustomerId) ||
          permissionPlaceOrderBySelectedOrganization
      );
    };
    if (canPlaceOrder === undefined && userActions && activeCart && userInfo) {
      checkIfUserCanPlaceOrder();
    }
  }, [userActions, canPlaceOrder, activeCart, userInfo]);

  useEffect(() => {
    if (activeStep && steps && steps.length > 0 && activeStep.step === steps[0].step && !hasTriggeredGTMEvent) {
      setHasTriggeredGTMEvent(true);
      pushBeginCheckoutEventToDataLayer(activeCart);
    }
  }, [activeStep, steps, hasTriggeredGTMEvent, activeCart]);

  useEffect(() => {
    const setEnabledSteps = async () => {
      if (!activeStep && activeCart && checkoutSteps) {
        if (!uiSettings?.fabricationOrderDetailsCheckoutIsActive) {
          const orderType = activeCart.cartItems[0].productType;
          if (orderType === 'Fabricated') {
            await dispatch(hideCheckoutStep(checkoutSteps[0]));
            await dispatch(setActiveStep(checkoutSteps[1]));
          } else {
            await dispatch(setActiveStep(checkoutSteps[0]));
          }
        }
        await dispatch(setActiveStep(checkoutSteps[0]));
      }

      if (
        activeStep &&
        checkoutSteps &&
        !location.pathname.includes(COMMERCE_CORE_CONSTANTS.PATHNAMES.orderConfirmation)
      ) {
        setSteps(() => checkoutSteps.filter((step: CheckoutStep) => step.isEnabled));
      }
    };

    if (!location.pathname.includes(COMMERCE_CORE_CONSTANTS.PATHNAMES.orderConfirmation)) {
      setEnabledSteps();
    }
  }, [activeStep, activeCart, history, checkoutSteps, dispatch, location, uiSettings]);

  useEffect(() => {
    if (
      activeStep &&
      steps.length > 0 &&
      !location.pathname.includes(COMMERCE_CORE_CONSTANTS.PATHNAMES.orderConfirmation)
    ) {
      if (location.pathname === '/checkout' || location.pathname === COMMERCE_CORE_CONSTANTS.PATHNAMES.baseCheckout) {
        history.push('checkout/' + activeStep.url);
      } else {
        const selectedStep = steps.find((step: CheckoutStep) => location.pathname.includes(step.url));
        if (selectedStep) {
          dispatch(setActiveStep(selectedStep));
        } else {
          history.push('checkout/' + activeStep.url);
        }
      }
    }
  }, [activeStep, history, location, steps, dispatch]);

  useEffect(() => {
    const isCurrentStepCompleted = () => {
      if (
        (activeStep && activeStep.step === '01' && step1Completed) ||
        (activeStep && activeStep.step === '02' && step2Completed) ||
        (activeStep && activeStep.step === '03' && step3Completed)
      ) {
        return true;
      }
      return false;
    };

    if (
      movingToNextStep &&
      activeStep &&
      isCurrentStepCompleted() &&
      !location.pathname.includes(COMMERCE_CORE_CONSTANTS.PATHNAMES.orderConfirmation)
    ) {
      const activeIndex = steps.findIndex((step: any) => step.name === activeStep.name);
      goNext(activeIndex + 1);
    }
  }, [movingToNextStep, steps, activeStep, step1Completed, step2Completed, step3Completed, location, goNext, dispatch]);

  if (activeStep && steps.length > 0) {
    return (
      <Container>
        <Row>
          <Col>
            <CheckoutNavigationContainer>
              <span className="background-line"></span>
              <div className="navigation-items">{activeStep && renderNavItems()}</div>
            </CheckoutNavigationContainer>
          </Col>
        </Row>
        <Row>
          <Col>
            <ActiveStepHeading>{activeStep?.name}</ActiveStepHeading>
          </Col>
        </Row>
        <Row>
          <Col>{children}</Col>
        </Row>
        <Row>
          <Col>
            <CheckoutButtonsContainer>
              {activeStep?.step && steps[0].step && (
                <CambriaButton
                  onClick={() => handleBackButtonClick(activeStep)}
                  id="checkout-back-button"
                  disabled={isSubmitting}
                  variant="transparent"
                  shadow={false}
                  className="m-t-sm m-b-sm m-r-sm back-btn">
                  <Icon
                    size="16"
                    weight="900"
                    icon="icons-cambria-Ui-Left-Arrow-No-Tail"
                    color="#7f7f7f"
                    colorOnHover="#7f7f7f"
                  />
                  BACK
                </CambriaButton>
              )}
              {activeStep?.step !== steps[steps.length - 1].step ? (
                <CambriaButton
                  onClick={() => {
                    if (nextButtonToastErrorMessages.length > 0) {
                      handleInvalidForm();
                    }
                    setMovingToNextStep(() => true);
                  }}
                  id="checkout-next-button"
                  disabled={isSubmitting}
                  shadow={false}
                  type="submit"
                  form={activeStep?.step}
                  className="m-t-sm m-b-sm next-btn">
                  {isSubmitting ? (
                    <Icon className="next-step-spinner" size="20" weight="600" icon="fa fa-spinner fa-spin" disabled />
                  ) : (
                    <Icon
                      size="16"
                      weight="900"
                      icon="icons-cambria-Ui-Right-Arrow-No-Tail"
                      color="#fff"
                      colorOnHover="#fff"
                    />
                  )}
                  NEXT
                </CambriaButton>
              ) : (
                <>
                  {canPlaceOrder && (
                    <CambriaButton
                      disabled={isSubmitting}
                      type="submit"
                      form="04"
                      className="m-t-sm m-b-sm place-order-btn"
                      id="checkout-place-order-button"
                      onClick={() => {}}>
                      {isSubmitting && (
                        <Icon
                          className="next-step-spinner"
                          size="20"
                          weight="600"
                          icon="fa fa-spinner fa-spin"
                          disabled
                        />
                      )}
                      PLACE ORDER
                    </CambriaButton>
                  )}
                </>
              )}
            </CheckoutButtonsContainer>
          </Col>
        </Row>
      </Container>
    );
  } else if (!activeStep && location.pathname.includes(COMMERCE_CORE_CONSTANTS.PATHNAMES.orderConfirmation)) {
    return <Container>{children}</Container>;
  }

  return <div></div>;
};

export default CheckoutNavigation;
