import qs from 'qs';
import React, {useCallback, useEffect, useState} from 'react';
import {Col} from 'react-bootstrap';
import {useLocation} from 'react-router-dom';
import {selectUserActions} from '../../../../features/permission/slice/permission.slice';
import {
  selectDisplayCategoriesFilters,
  selectSubProductCategoriesFilters,
  setProductsAsync,
  setUpdatedDisplayCategoriesFilters,
  setUpdatedSubProductCategoriesFilters,
  setUpdatedSearchResultsCategoriesFilters,
  setOffset,
} from '../../../../features/productCatalog/slice/productCatalog.slice';
import {setShipFromWarehouseId} from '../../../../features/warehouse/slice/warehouse.slice';
import {useAppDispatch, useTypedSelector} from '../../../../hooks/store';
import {hasPermission} from '../../../../store/permission/permission.service';
import MobileFilters from './MobileFilters';
import {CustomerFullProfile, Site} from '../../../../features/customer/ICustomerState';
import {Cart} from '../../../../features/cart/ICartState';
import COMMERCE_CORE_CONSTANTS from '../../../constants';

export default function ProductListFilters(props: {
  productType: string | null;
  createProductRequestData: any;
  isSearchResults: boolean;
  customer: CustomerFullProfile;
  cart: Cart | null;
  shipToSites: Site[];
}) {
  const dispatch = useAppDispatch();
  const location = useLocation();

  let userActions = useTypedSelector(selectUserActions);

  const getDisplayCategories = useCallback(
    (productType: string) => {
      let displayCategories: any;
      if (props.isSearchResults) {
        displayCategories = [];
      } else {
        switch (productType) {
          case 'Slab':
          case 'Tile': {
            // Tonality
            displayCategories = [
              {name: 'Light', checked: false},
              {name: 'Medium', checked: false},
              {name: 'Dark', checked: false},
            ];
            break;
          }
          case 'Store': {
            // Category
            displayCategories = [
              {name: 'Apparel and Merchandise', checked: false},
              {name: "Men's Apparel", checked: false},
              {name: "Women's Apparel", checked: false},
              {name: 'Unisex Apparel', checked: false},
              {name: 'Uniform Approved Apparel', checked: false},
              {name: 'Hats & Beanies', checked: false},
              {name: 'Gifting', checked: false},
              {name: 'Giveaway Items', checked: false},
              {name: 'Drinkware', checked: false},
              {name: 'Office', checked: false},
              {name: 'Youth', checked: false},
              {name: 'Supplies', checked: false},
              {name: 'Custom', checked: false},
            ];
            break;
          }
          case 'Samples': {
            // Category
            displayCategories = [
              {name: 'Brochures & Inserts', checked: false},
              {name: 'Cambria Style Magazine', checked: false},
              {name: 'Designs', checked: false},
              {name: 'Display', checked: false},
              {name: 'Edge Profile', checked: false},
              {name: 'Floor Covering', checked: false},
              {name: 'French Canadian', checked: false},
              {name: 'Gallery', checked: false},
              {name: 'Installation', checked: false},
              {name: 'Kits', checked: false},
              {name: 'Maps', checked: false},
              {name: 'Premium Dealer', checked: false},
              {name: 'Returns', checked: false},
              {name: 'Sample Boxes', checked: false},
              {name: 'Sell Sheets', checked: false},
              {name: 'Supplies & Cards', checked: false},
              {name: 'Tools', checked: false},
              {name: 'Warranty & Care', checked: false},
            ];
            break;
          }
          default: {
            displayCategories = [];
            break;
          }
        }
      }
      return displayCategories;
    },
    [props]
  );

  const getSubProductCategories = useCallback(
    (productType: string): {name: string; checked: boolean}[] => {
      let subProductCategories: any;
      if (props.isSearchResults) {
        subProductCategories = [];
      } else {
        switch (productType) {
          case 'Slab':
          case 'Tile': {
            // Collection
            subProductCategories = [
              {name: 'Black Marble', checked: false},
              {name: 'Cambrian', checked: false},
              {name: 'Classic', checked: false},
              {name: 'Coastal', checked: false},
              {name: 'Desert', checked: false},
              {name: 'Jewel', checked: false},
              {name: 'Marble', checked: false},
              {name: 'Oceanic', checked: false},
              {name: 'Quarry', checked: false},
              {name: 'Waterstone', checked: false},
              {name: 'Woodstone', checked: false},
            ];
            break;
          }
          case 'Store': {
            // Lifestyle
            subProductCategories = [];
            break;
          }
          case 'Samples': {
            // Item Type
            subProductCategories = [
              {name: 'Adhesives', checked: false},
              {name: 'Brand Medallion', checked: false},
              {name: 'Finance by Cambria', checked: false},
              {name: 'Installation Items', checked: false},
              {name: 'International', checked: false},
              {name: 'Labels', checked: false},
              {name: 'Literature', checked: false},
              {name: 'Miscellaneous', checked: false},
              {name: 'POS Tools & Displays', checked: false},
              {name: 'Sample Tiles', checked: false},
              {name: 'Signs & Banners', checked: false},
            ];
            break;
          }
          default: {
            subProductCategories = [];
            break;
          }
        }
      }
      return subProductCategories;
    },
    [props]
  );

  const getSearchResultsCategories = (defaultProductType: any) => {
    let searchResultsCategories: any = [];
    if (props.isSearchResults) {
      let tempSearchResultsCategories = [
        {name: 'All', canViewFilter: true, checked: defaultProductType === 'All' ? true : false},
        {
          name: 'Slab',
          canViewFilter: hasPermission('urn:csa:commerceui:productCatalog:viewSlabs', userActions),
          checked: defaultProductType === 'Slab' ? true : false,
        },
        {
          name: 'Tile',
          canViewFilter: hasPermission('urn:csa:commerceui:productCatalog:viewTiles', userActions),
          checked: defaultProductType === 'Tile' ? true : false,
        },
        {
          name: 'Samples',
          canViewFilter: hasPermission('urn:csa:commerceui:productCatalog:viewSamples', userActions),
          checked: defaultProductType === 'Samples' ? true : false,
        },
        {
          name: 'Branded Merchandise',
          canViewFilter: hasPermission('urn:csa:commerceui:productCatalog:viewStore', userActions),
          checked: defaultProductType === 'Store' ? true : false,
        },
      ];
      for (const filter of tempSearchResultsCategories) {
        if (filter.canViewFilter) {
          searchResultsCategories.push(filter);
        }
      }
    }
    return searchResultsCategories;
  };

  const loadFilterTitles = (type: any) => {
    let title: any = '';
    if (props.isSearchResults) {
      if (type === 'searchResults') {
        title = 'Product Type';
      }
    } else {
      switch (props.productType) {
        case 'Slab': {
          if (type === 'display') {
            title = 'Tonality';
          } else if (type === 'sub') {
            title = 'Collection';
          }
          break;
        }
        case 'Tile': {
          if (type === 'display') {
            title = 'Tonality';
          } else if (type === 'sub') {
            title = 'Collection';
          }
          break;
        }
        case 'Store': {
          if (type === 'display') {
            title = 'Category';
          }
          break;
        }
        case 'Samples': {
          if (type === 'display') {
            title = 'Category';
          } else if (type === 'sub') {
            title = 'Item Type';
          }
          break;
        }
        default: {
          break;
        }
      }
    }

    return title;
  };

  let [displayCategories, setDisplayCategories] = useState<{name: string; checked: boolean}[]>(
    getDisplayCategories(props.productType || '')
  );
  let [subProductCategories, setSubProductCategories] = useState(getSubProductCategories(props.productType || ''));
  let [searchResultsCategories, setSearchResultsCategories] = useState(
    getSearchResultsCategories(qs.parse(location.search, {ignoreQueryPrefix: true}).productType)
  );

  const displayCategoryTitle = loadFilterTitles('display');
  const subProductCategoryTitle = loadFilterTitles('sub');
  const searchResultsCategoryTitle = loadFilterTitles('searchResults');

  let subProductCategoryFilter: any = useTypedSelector(selectSubProductCategoriesFilters);
  let displayCategoryFilter: any = useTypedSelector(selectDisplayCategoriesFilters);

  let searchParams: any = {
    displayCategories: '',
    subProductCategory: '',
  };

  const setChangedFilters = (category?: any, type?: any) => {
    if (type === 'display') {
      setDisplayCategoryChangedFilters(category);
    } else if (type === 'subProduct') {
      setSubProductCategoriesChangedFilters(category);
    } else if (type === 'searchResults') {
      setSearchResultsCategoriesChangedFilters(category);
    }
  };

  const setDisplayCategoryChangedFilters = (category: any) => {
    let categoryIsChecked = false;
    let tempDisplayCategories = JSON.parse(JSON.stringify(displayCategories));
    for (const displayCategory of tempDisplayCategories) {
      if (displayCategory.name === category.name) {
        displayCategory.checked = !displayCategory.checked;
        categoryIsChecked = displayCategory.checked;
      }
    }
    setDisplayCategories(tempDisplayCategories);
    let tempDisplayCategoryFilter = JSON.parse(JSON.stringify(displayCategoryFilter));
    if (categoryIsChecked) {
      tempDisplayCategoryFilter.push(category.name);
      searchParams.displayCategories = tempDisplayCategoryFilter.join();
      searchParams.subProductCategory = subProductCategoryFilter.join();
      dispatch(setUpdatedDisplayCategoriesFilters(tempDisplayCategoryFilter));
    } else {
      const filteredArray = tempDisplayCategoryFilter.filter((item: any) => item !== category.name);
      searchParams.displayCategories = filteredArray.join();
      searchParams.subProductCategory = subProductCategoryFilter.join();
      dispatch(setUpdatedDisplayCategoriesFilters(filteredArray));
    }

    onCategoryFilterChanged();
  };

  const setSubProductCategoriesChangedFilters = (category: any) => {
    let categoryIsChecked = false;
    let tempSubProductCategories = JSON.parse(JSON.stringify(subProductCategories));
    for (const displayCategory of tempSubProductCategories) {
      if (displayCategory.name === category.name) {
        displayCategory.checked = !displayCategory.checked;
        categoryIsChecked = displayCategory.checked;
      }
    }
    setSubProductCategories(tempSubProductCategories);
    let tempSubProductCategoryFilter = JSON.parse(JSON.stringify(subProductCategoryFilter));
    if (categoryIsChecked) {
      tempSubProductCategoryFilter.push(category.name);
      searchParams.displayCategories = displayCategoryFilter.join();
      searchParams.subProductCategory = tempSubProductCategoryFilter.join();
      dispatch(setUpdatedSubProductCategoriesFilters(tempSubProductCategoryFilter));
    } else {
      const filteredArray = tempSubProductCategoryFilter.filter((item: any) => item !== category.name);
      searchParams.displayCategories = displayCategoryFilter.join();
      searchParams.subProductCategory = filteredArray.join();
      dispatch(setUpdatedSubProductCategoriesFilters(filteredArray));
    }

    onCategoryFilterChanged();
  };

  const setSearchResultsCategoriesChangedFilters = (category: any) => {
    const selectedProductType = category.name === 'Branded Merchandise' ? 'Store' : category.name;
    const keyword = qs.parse(location.search, {ignoreQueryPrefix: true}).q;
    window.history.replaceState(
      null,
      'newProductTypeFilter',
      `${COMMERCE_CORE_CONSTANTS.PATHNAMES.searchResults}?${qs.stringify({
        q: keyword,
        productType: selectedProductType,
      })}`
    );

    let tempSearchResultsCategories = JSON.parse(JSON.stringify(searchResultsCategories));
    for (const displayCategory of tempSearchResultsCategories) {
      if (displayCategory.name === category.name) {
        displayCategory.checked = true;
      } else {
        displayCategory.checked = false;
      }
    }
    setSearchResultsCategories(tempSearchResultsCategories);
    dispatch(setUpdatedSearchResultsCategoriesFilters(selectedProductType));

    if (selectedProductType !== 'All') {
      dispatch(
        setShipFromWarehouseId({
          productType: selectedProductType,
          customer: props.customer,
          shipToSites: props.shipToSites,
          cart: props.cart,
        })
      );
    } else {
      dispatch(setProductsAsync(props.createProductRequestData(selectedProductType)));
    }
  };

  const onCategoryFilterChanged = (): void => {
    let productRequestData = props.createProductRequestData(props.productType);
    productRequestData.data.append('displayCategories', searchParams.displayCategories);
    productRequestData.data.append('subProductCategory', searchParams.subProductCategory);
    dispatch(setProductsAsync(productRequestData));
    dispatch(setOffset(6));
  };

  const clearProductFilters = (): void => {
    searchParams.displayCategories = null;
    searchParams.subProductCategory = null;
    setDisplayCategories(getDisplayCategories(props.productType || ''));
    setSubProductCategories(getSubProductCategories(props.productType || ''));
    dispatch(setUpdatedSubProductCategoriesFilters([]));
    dispatch(setUpdatedDisplayCategoriesFilters([]));
    dispatch(setProductsAsync(props.createProductRequestData(props.productType)));
    dispatch(setOffset(6));
  };

  useEffect(() => {
    setDisplayCategories(getDisplayCategories(props.productType || ''));
    setSubProductCategories(getSubProductCategories(props.productType || ''));
  }, [props.productType, getDisplayCategories, getSubProductCategories]);

  return (
    <Col md={3} xs={12} className="product-list-filter-container ">
      {/* Mobile Filters */}
      <MobileFilters
        productType={props.productType}
        getDisplayCategories={getDisplayCategories}
        getSubProductCategories={getSubProductCategories}
        getSearchResultsCategories={getSearchResultsCategories}
        createProductRequestData={props.createProductRequestData}
        clearProductFilters={clearProductFilters}
        subProductCategoryTitle={subProductCategoryTitle}
        displayCategoryTitle={displayCategoryTitle}
        searchResultsCategoryTitle={searchResultsCategoryTitle}
        isSearchResults={props.isSearchResults}
        customer={props.customer}
        cart={props.cart}
        shipToSites={props.shipToSites}></MobileFilters>
      <div className="product-list-filter-row-container hidden-xs">
        <div className="row">
          <div className="col-xs-8">
            <h3 className="pull-left h3-default m-b-0 product-filter-header">Filters</h3>
          </div>
          <div className="col-xs-4">
            {props.isSearchResults ? (
              <div></div>
            ) : (
              <button
                data-testid={'desktop-filter-reset-button'}
                className="btn btn-link p-a-0 text-capitalize pull-right product-filter-reset-button"
                type="button"
                onClick={() => {
                  clearProductFilters();
                }}>
                RESET
              </button>
            )}
          </div>
        </div>

        {props.isSearchResults ? (
          <div>
            {/* searchResultsCategoryFilter */}
            <div className="category-box">
              <label className="category-title">{searchResultsCategoryTitle}</label>
              <form className="form-filter">
                {searchResultsCategories.map((category: any, $index: number) => {
                  return (
                    <div key={'searchResultscategory' + $index} className="checkbox">
                      <input
                        data-testid={category.name}
                        type="checkbox"
                        id={'searchResultscategory' + $index}
                        checked={category.checked}
                        onChange={() => setChangedFilters(category, 'searchResults')}
                      />
                      <label htmlFor={'searchResultscategory' + $index}>{category.name}</label>
                    </div>
                  );
                })}
              </form>
            </div>
          </div>
        ) : (
          <div>
            {/* subProductCategoryFilter */}
            <div className="category-box">
              <label className="category-title">{subProductCategoryTitle}</label>
              <form className="form-filter">
                {subProductCategories.map((category: {name: string; checked: boolean}, $index: number) => {
                  return (
                    <div key={'subcategory' + $index} className="checkbox">
                      <input
                        data-testid={category.name}
                        type="checkbox"
                        id={'subcategory' + $index}
                        checked={category.checked}
                        onChange={() => setChangedFilters(category, 'subProduct')}
                      />
                      <label htmlFor={'subcategory' + $index}>{category.name}</label>
                    </div>
                  );
                })}
              </form>
            </div>
            {/* displayCategoryFilter */}
            <div className="category-box">
              <label className="category-title">{displayCategoryTitle}</label>
              <form className="form-filter">
                {displayCategories.map((category: {name: string; checked: boolean}, $index: number) => {
                  return (
                    <div key={'displaycategory' + $index} className="checkbox">
                      <input
                        data-testid={category.name}
                        type="checkbox"
                        id={'displaycategory' + $index}
                        checked={category.checked}
                        onChange={() => setChangedFilters(category, 'display')}
                      />
                      <label htmlFor={'displaycategory' + $index}>{category.name}</label>
                    </div>
                  );
                })}
              </form>
            </div>
          </div>
        )}
      </div>
    </Col>
  );
}
