import React from 'react';
import {Form, Formik} from 'formik';
import * as Yup from 'yup';
import {Col, Row} from 'react-bootstrap';
import {
  selectActiveCart,
  selectCartCalculationsComplete,
  selectDisableQuantityButtons,
} from '../../../../features/cart/slice/cart.slice';
import {selectUserActions} from '../../../../features/permission/slice/permission.slice';
import {useTypedSelector} from '../../../../hooks/store';
import {hasPermission} from '../../../../store/permission/permission.service';
import CambriaInput from '../../../../Framework/Components/CambriaInput';
import {selectQuoteOperationInProgress} from '../../../../features/quote/slice/quote.slice';
import {CartItem} from '../../../../features/cart/ICartState';
import {CartItemsContainer} from './CartItems.styled';
import {selectActiveProgram} from '../../../../features/salesforce/slice/salesforce.slice';
import {IBundleProgramRule} from '../../../../features/productCatalog/IProductCatalogState';
import {
  selectBundleProgramRules,
  selectBundleProgramRulesStatus,
} from '../../../../features/productCatalog/slice/productCatalog.slice';
import COMMERCE_CORE_CONSTANTS from '../../../../Core/constants';
import {isBundleProgram} from '../../../../features/salesforce/service/salesforce.service';
import {selectIsRevH, selectIsRevI} from '../../../../features/environment/slice/environment.slice';
import {cropImagesBasedOnProductType, unitAndLinePriceFormat} from '../../../../features/cart/service/cart.service';

const CartItems = ({
  cartItems,
  isRevisionMode,
  isMovingItemToSaveForLater,
  disableRemoveButtonWhileRemoving,
  removeCartItemButton,
  saveForLater,
  updateQuantity,
}: {
  cartItems: CartItem[];
  isRevisionMode: boolean;
  isMovingItemToSaveForLater: boolean;
  disableRemoveButtonWhileRemoving: boolean;
  removeCartItemButton: Function;
  saveForLater: Function;
  updateQuantity: Function;
}) => {
  let cart: any = useTypedSelector(selectActiveCart);
  let cartCalculationsComplete = useTypedSelector(selectCartCalculationsComplete);
  let quoteOperationInProgress = useTypedSelector(selectQuoteOperationInProgress);
  let userActions = useTypedSelector(selectUserActions);
  let disableQuantityButtons = useTypedSelector(selectDisableQuantityButtons);
  const bundleProgramRules: IBundleProgramRule[] = useTypedSelector(selectBundleProgramRules);
  const bundleProgramRulesStatus = useTypedSelector(selectBundleProgramRulesStatus);
  let activeProgram = useTypedSelector(selectActiveProgram);
  const isRevH = useTypedSelector(selectIsRevH);
  const isRevI = useTypedSelector(selectIsRevI);
  const isRev = isRevH || isRevI;

  const quantityInputSteps = activeProgram?.code?.startsWith(
    COMMERCE_CORE_CONSTANTS.PROGRAMS.BUNDLE_PROGRAM_CODE_PREFIX
  )
    ? cartItems.map(
        (item) =>
          bundleProgramRules?.find((r) => r.size === item.unitSize?.toString() && r.thickness === item.thickness)
            ?.halfBundleQuantity
      )
    : [];

  const quantityInputDisabled =
    isRevisionMode ||
    disableQuantityButtons ||
    (quoteOperationInProgress && cart.transactionType === 'QuoteEditCart') ||
    disableRemoveButtonWhileRemoving ||
    (isBundleProgram(activeProgram) && (bundleProgramRulesStatus === 'loading' || isRev));

  const buildQuantityInitialValuesObject = (cartItem: CartItem, index: number) => {
    let initialValueSchema: any = {};

    initialValueSchema[`cartItemQuantity${index}`] = cartItem.tempQuantity;

    return initialValueSchema;
  };

  const buildQuantityValidationObject = (cartItem: CartItem, index: number) => {
    let validationSchema: any = {};

    if (cartItem.requireOrderingInQuantitiesOfTwo) {
      validationSchema[`cartItemQuantity${index}`] = Yup.number()
        .required('This field is required')
        .test(
          'test-required-even-number',
          'This product must be ordered in quantities of two.',
          (value: any) => value && value % 2 === 0
        );
    } else {
      validationSchema[`cartItemQuantity${index}`] = Yup.number();
    }

    return validationSchema;
  };
  if (cartItems.length > 0) {
    return (
      <CartItemsContainer className="items-container">
        {cartItems.map((cartItem: CartItem, index: number) => {
          return (
            <div key={`cartItems${index}`}>
              <Row className={`hidden-sm hidden-xs item m-x-0 ${index % 2 === 0 ? 'white-item' : ''}`}>
                <Col xs={4} sm={5} md={2} className="p-x-0">
                  <div className="item-image">
                    <div className="image-link">
                      <img
                        className="item-image-class"
                        src={cartItem.imageUrl ? cropImagesBasedOnProductType(cartItem) : ''}
                        alt={cartItem.description}
                      />
                    </div>
                  </div>
                </Col>
                <Col xs={8} sm={7} md={10} className="p-x-0">
                  <Col md={6} className="item-field">
                    <div className="item-name">
                      <strong>{cartItem.description}</strong>
                      <div>
                        {(!isBundleProgram(activeProgram) || !isRev) && (
                          <>
                            <button
                              type="button"
                              className="btn btn-link sub-text col-12 p-x-0"
                              disabled={!cartCalculationsComplete || isRevisionMode || disableRemoveButtonWhileRemoving}
                              onClick={() => {
                                removeCartItemButton(cartItem);
                              }}>
                              <small className="item-field-remove-save-buttons pull-left">REMOVE FROM CART</small>
                            </button>
                            {hasPermission('urn:csa:commerceui:saveForLater', userActions) ? (
                              <button
                                type="button"
                                className="btn btn-link sub-text col-12 p-x-0"
                                disabled={!cartCalculationsComplete || isRevisionMode || isMovingItemToSaveForLater}
                                onClick={() => {
                                  saveForLater(cartItem);
                                }}>
                                <small className="item-field-remove-save-buttons pull-left">SAVE FOR LATER</small>
                              </button>
                            ) : (
                              <div></div>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </Col>
                  <Col md={1} className="item-field p-x-0 text-center">
                    <div className="item-qty">
                      <Formik
                        enableReinitialize
                        initialValues={buildQuantityInitialValuesObject(cartItem, index)}
                        validationSchema={Yup.object(buildQuantityValidationObject(cartItem, index))}
                        onSubmit={() => {}}>
                        {(props) => {
                          return (
                            <Form id="cartItemQuantityForm" noValidate>
                              <CambriaInput
                                className="cart-item-qty-input"
                                name={`cartItemQuantity${index}`}
                                type="counter"
                                onChange={(value: number) => {
                                  if (value !== cartItem.quantity) {
                                    updateQuantity(cartItem, value);
                                  }
                                }}
                                handleEmptyValue={() => {
                                  removeCartItemButton(cartItem);
                                }}
                                step={quantityInputSteps?.[index]}
                                allowEmptyValue={true}
                                required={true}
                                min={quantityInputSteps?.[index] ?? (cartItem.requireOrderingInQuantitiesOfTwo ? 2 : 1)}
                                disabled={quantityInputDisabled}
                              />
                            </Form>
                          );
                        }}
                      </Formik>
                    </div>
                  </Col>
                  <Col md={1} className="item-field pull-left col-md-offset-2 p-x-0">
                    <strong className="hidden-md hidden-lg">UNIT PRICE:</strong>
                    {cartItem.hasAdjustedUnitPrice ? (
                      <span>
                        <span className="row">
                          <span className="col-12 item-field-price-text">
                            {unitAndLinePriceFormat(cartItem.adjustedUnitPrice)}
                          </span>
                          <span className="col-12 item-field-currency-text">
                            {cartItem.pricingUom === 'SF' ? '/ sq.ft.' : ''}
                            {cartItem.currency}
                          </span>
                        </span>
                        <br />
                        <span className="original-price row">
                          <span className="col-12 item-field-price-text">
                            {unitAndLinePriceFormat(cartItem.unitPrice)}
                          </span>
                          <span className="col-12 item-field-currency-text">
                            {cartItem.pricingUom === 'SF' ? '/ sq.ft.' : ''}
                            {cartItem.currency}
                          </span>
                        </span>
                      </span>
                    ) : (
                      <div></div>
                    )}
                    {!cartItem.hasAdjustedUnitPrice ? (
                      <span className="row">
                        <span className="col-12 item-field-price-text">
                          {unitAndLinePriceFormat(cartItem.unitPrice)}
                        </span>
                        <span className="col-12 item-field-currency-text">
                          {cartItem.currency} {cartItem.pricingUom === 'SF' ? '/ sqft' : ''}
                        </span>
                      </span>
                    ) : (
                      <div></div>
                    )}
                  </Col>
                  <Col md={2} className="item-field pull-left">
                    <strong className="hidden-md hidden-lg">LINE PRICE:</strong>
                    {cartItem.hasAdjustedExtendedNetPrice ? (
                      <span>
                        <span className="row">
                          <span className="col-12 item-field-price-text">
                            {unitAndLinePriceFormat(cartItem.adjustedExtendedNetPrice)}
                          </span>
                          <span className="col-12 item-field-currency-text">{cartItem.currency}</span>
                        </span>
                        <br />
                        <span className="original-price row">
                          <span className="col-12 item-field-price-text">
                            {unitAndLinePriceFormat(cartItem.extendedNetPrice)}
                          </span>
                          <span className="col-12 item-field-currency-text">{cartItem.currency}</span>
                        </span>
                      </span>
                    ) : (
                      <div></div>
                    )}
                    {!cartItem.hasAdjustedExtendedNetPrice ? (
                      <span className="row">
                        <span className="col-12 item-field-price-text">
                          {unitAndLinePriceFormat(cartItem.extendedNetPrice)}
                        </span>
                        <span className="col-12 item-field-currency-text">{cartItem.currency}</span>
                      </span>
                    ) : (
                      <div></div>
                    )}
                  </Col>
                </Col>
              </Row>
              <Row className={`hidden-md hidden-lg m-x-0 mobile-cart-items`}>
                <Col xs={12} className={'mobile-item-primary-container'}>
                  <div>
                    <Col xs={7} className="pull-left">
                      <strong>{cartItem.description}</strong>
                    </Col>
                    <Col xs={5} className="pull-left mobile-unit-price-container">
                      {cartItem.hasAdjustedUnitPrice ? (
                        <span>
                          <span className="row pull-right">
                            <span className="col-12 item-field-price-text mobile-unit-price">
                              {unitAndLinePriceFormat(cartItem.adjustedUnitPrice)}
                            </span>
                            <span className="col-12 item-field-currency-text mobile-unit-price">
                              {cartItem.pricingUom === 'SF' ? '/ sq.ft.' : ''}
                              {cartItem.currency}
                            </span>
                          </span>
                          <br />
                          <span className="original-price row">
                            <span className="col-12 item-field-price-text mobile-unit-price">
                              {unitAndLinePriceFormat(cartItem.unitPrice)}
                            </span>
                            <span className="col-12 item-field-currency-text mobile-unit-price">
                              {cartItem.pricingUom === 'SF' ? '/ sq.ft.' : ''}
                              {cartItem.currency}
                            </span>
                          </span>
                        </span>
                      ) : (
                        <div></div>
                      )}
                      {!cartItem.hasAdjustedUnitPrice ? (
                        <span className="row pull-right">
                          <span className="col-12 item-field-price-text mobile-unit-price">
                            {unitAndLinePriceFormat(cartItem.unitPrice)}
                          </span>
                          <span className="col-12 item-field-currency-text mobile-unit-price">
                            {cartItem.currency} {cartItem.pricingUom === 'SF' ? '/ sqft' : ''}
                          </span>
                        </span>
                      ) : (
                        <div></div>
                      )}
                    </Col>
                  </div>
                  <div>
                    <Col xs={7} className="pull-left mobile-item-image-container">
                      <div className="item-image">
                        <div className="image-link">
                          <img
                            className="item-image-class"
                            src={cartItem.imageUrl ? cropImagesBasedOnProductType(cartItem) : ''}
                            alt={cartItem.description}
                          />
                        </div>
                      </div>
                    </Col>
                    <Col xs={5} className="pull-left"></Col>
                  </div>
                  <div>
                    {(!isBundleProgram(activeProgram) || !isRev) && (
                      <>
                        <Col xs={6} className="pull-left mobile-remove-button-container">
                          <button
                            type="button"
                            className="btn btn-link sub-text col-12 p-x-0 mobile-remove-button"
                            disabled={!cartCalculationsComplete || isRevisionMode || disableRemoveButtonWhileRemoving}
                            onClick={() => {
                              removeCartItemButton(cartItem);
                            }}>
                            <small className="item-field-remove-save-buttons pull-left">REMOVE FROM CART</small>
                          </button>
                        </Col>
                        <Col xs={6} className="pull-left mobile-save-button-container">
                          {hasPermission('urn:csa:commerceui:saveForLater', userActions) ? (
                            <button
                              type="button"
                              className="btn btn-link sub-text col-12 p-x-0 mobile-save-button"
                              disabled={!cartCalculationsComplete || isRevisionMode || isMovingItemToSaveForLater}
                              onClick={() => {
                                saveForLater(cartItem);
                              }}>
                              <small className="item-field-remove-save-buttons pull-left">SAVE FOR LATER</small>
                            </button>
                          ) : (
                            <div></div>
                          )}
                        </Col>
                      </>
                    )}
                  </div>
                </Col>
                <Col xs={12} className={'mobile-item-secondary-container'}>
                  <Col xs={5} className="pull-left">
                    <div className="item-qty">
                      <Formik
                        enableReinitialize
                        initialValues={buildQuantityInitialValuesObject(cartItem, index)}
                        validationSchema={Yup.object(buildQuantityValidationObject(cartItem, index))}
                        onSubmit={() => {}}>
                        {(props) => {
                          return (
                            <Form id="cartItemQuantityForm" noValidate>
                              <CambriaInput
                                className="cart-item-qty-input"
                                name={`cartItemQuantity${index}`}
                                type="counter"
                                onChange={(value: any) => {
                                  updateQuantity(cartItem, value);
                                }}
                                step={quantityInputSteps?.[index]}
                                required={true}
                                min={quantityInputSteps?.[index] ?? (cartItem.requireOrderingInQuantitiesOfTwo ? 2 : 1)}
                                disabled={quantityInputDisabled}
                              />
                            </Form>
                          );
                        }}
                      </Formik>
                    </div>
                  </Col>
                  <Col xs={7} className="pull-left">
                    {cartItem.hasAdjustedExtendedNetPrice ? (
                      <span>
                        <span className="row">
                          <span className="col-12 item-field-price-text">
                            <span className="mobile-line-price-text">LINE PRICE:</span>
                            {unitAndLinePriceFormat(cartItem.adjustedExtendedNetPrice)}
                          </span>
                          <span className="col-12 item-field-currency-text">{cartItem.currency}</span>
                        </span>
                        <br />
                        <span className="original-price row">
                          <span className="col-12 item-field-price-text">
                            <span className="mobile-line-price-text">LINE PRICE:</span>
                            {unitAndLinePriceFormat(cartItem.extendedNetPrice)}
                          </span>
                          <span className="col-12 item-field-currency-text">{cartItem.currency}</span>
                        </span>
                      </span>
                    ) : (
                      <div></div>
                    )}
                    {!cartItem.hasAdjustedExtendedNetPrice ? (
                      <span className="row">
                        <span className="col-12 item-field-price-text">
                          <span className="mobile-line-price-text">LINE PRICE:</span>
                          {unitAndLinePriceFormat(cartItem.extendedNetPrice)}
                        </span>
                        <span className="col-12 item-field-currency-text">{cartItem.currency}</span>
                      </span>
                    ) : (
                      <div></div>
                    )}
                  </Col>
                </Col>
              </Row>
            </div>
          );
        })}
      </CartItemsContainer>
    );
  } else {
    return <div></div>;
  }
};

export default CartItems;
