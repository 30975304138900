import {toast} from 'react-toastify';
import {selectActiveCart} from '../../../../../features/cart/slice/cart.slice';
import {
  convertAccountToSite,
  getCustomerShipToSitesFilteredByWarehouse,
  getMatchingShipTo,
  getOperatingUnitFromCart,
} from '../../../../../features/customer/service/customer.service';
import {
  getCustomerShipToSitesAsync,
  selectCurrentCustomer,
  selectCurrentCustomerShipToSites,
  setSiteInfo,
} from '../../../../../features/customer/slice/customer.slice';
import {selectUserActions} from '../../../../../features/permission/slice/permission.slice';
import {
  getAccountByAccountId,
  getCurrentSalesforceAccountId,
} from '../../../../../features/salesforce/service/salesforce.service';
import {
  selectCurrentCustomerSfAccount,
  selectIsUpdatedSalesforceAssetAccount,
} from '../../../../../features/salesforce/slice/salesforce.slice';
import {useAppDispatch, useTypedSelector} from '../../../../../hooks/store';
import {hasPermission} from '../../../../../store/permission/permission.service';
import {
  hideFullscreenLoader,
  showFullscreenLoader,
} from '../../../../../features/fullscreenLoader/slice/fullscreenLoader.slice';
import {saveNewShipToSite} from '../../../../../features/checkout/service/checkout.service';
import COMMERCE_CORE_CONSTANTS from '../../../../../Core/constants';
import {selectSelectedWarehouse} from '../../../../../features/warehouse/slice/warehouse.slice';
import {WarehouseFullData} from '../../../../../features/warehouse/IWarehouseState';
import {Site} from '../../../../../features/customer/ICustomerState';

interface UseSalesforceAccountInitProps {
  currentWarehouse: WarehouseFullData | null;
}

/**
 * Returns an async function to initialize the ship-to sites
 * from the salesforce account
 *
 * @returns an object with a new site if it was created and/or
 *  the default site to use
 */
export const useSalesforceAccountInit = ({currentWarehouse}: UseSalesforceAccountInitProps) => {
  const dispatch = useAppDispatch();
  const activeCart = useTypedSelector(selectActiveCart);
  const isUpdatedSalesforceAssetAccount = useTypedSelector(selectIsUpdatedSalesforceAssetAccount);
  const currentCustomer = useTypedSelector(selectCurrentCustomer);
  const currentCustomerShipToSites = useTypedSelector(selectCurrentCustomerShipToSites);
  const userActions = useTypedSelector(selectUserActions);
  const selectedWarehouse = useTypedSelector(selectSelectedWarehouse);
  const currentCustomerSfAccount = useTypedSelector(selectCurrentCustomerSfAccount);

  return async () => {
    let newShipToSite: Site | null = null;
    let existingOrDefaultShippingAddress = null;

    if (activeCart) {
      // For MARKETREP when we select a salesforce account in previous step
      const userSelectedSalesforceAccountId = getCurrentSalesforceAccountId();

      if (activeCart.shippingMethods[0]?.shipToSiteId && !isUpdatedSalesforceAssetAccount) {
        const allSites = getCustomerShipToSitesFilteredByWarehouse(
          activeCart as any,
          currentCustomer,
          currentCustomerShipToSites as Site[]
        );
        existingOrDefaultShippingAddress = allSites.find(
          (address: any) => address.siteNumber === activeCart.shippingMethods[0].shipToSiteId
        ) as any;
      } else if (
        userSelectedSalesforceAccountId &&
        hasPermission('urn:csa:commerceui:checkout:defaultShipToToSalesforceAsset', userActions)
      ) {
        // Market rep
        const userSelectedSalesforceAccount: any = await getAccountByAccountId(userSelectedSalesforceAccountId);
        const convertedShippingSite = convertAccountToSite(
          userSelectedSalesforceAccount,
          currentCustomer.class !== 'MARKETREP' ? currentWarehouse : null
        );
        if (!convertedShippingSite) {
          toast.error(`Billing Address for SalesForce Account '${userSelectedSalesforceAccountId}' was not valid.`);
        } else {
          existingOrDefaultShippingAddress = getMatchingShipTo(
            getCustomerShipToSitesFilteredByWarehouse(
              activeCart as any,
              currentCustomer,
              currentCustomerShipToSites as Site[]
            ),
            convertedShippingSite
          );

          if (!existingOrDefaultShippingAddress) {
            dispatch(showFullscreenLoader({showCartIcon: false}));
            toast.warning(
              'The shipping address associated with the selected Salesforce asset account does not exist in our records. Please wait as we add this address and update our list.'
            );

            if (currentCustomer.class === 'ENTERPRISE' && selectedWarehouse && selectedWarehouse.warehouseCode) {
              convertedShippingSite.warehouseCode = selectedWarehouse.warehouseCode;
            }

            if (currentCustomer.class !== 'MARKETREP' && currentCustomer.class !== 'CIA') {
              convertedShippingSite.operatingUnitCode = getOperatingUnitFromCart(activeCart) as string;
            }

            await saveNewShipToSite(convertedShippingSite, dispatch);
            const sessionStorageCustomerId = window.sessionStorage.getItem(
              COMMERCE_CORE_CONSTANTS.WINDOW_SESSION.selectedCustomerId
            );

            if (sessionStorageCustomerId) {
              await dispatch(getCustomerShipToSitesAsync({customerId: sessionStorageCustomerId}));
              await dispatch(setSiteInfo({currentCustomer: currentCustomer}));
              newShipToSite = convertedShippingSite;
            }
            dispatch(hideFullscreenLoader());
          }
        }
      } else if (currentCustomerSfAccount && userActions) {
        if (hasPermission('urn:csa:commerceui:checkout:defaultShipToToSalesforceAsset', userActions)) {
          const sfAddress = convertAccountToSite(
            currentCustomerSfAccount,
            currentCustomer.class !== 'MARKETREP' ? currentWarehouse : null
          );
          if (!sfAddress) {
            toast.error(`Billing Address for SalesForce Account '${currentCustomerSfAccount.id}' was not valid.`);
          } else {
            existingOrDefaultShippingAddress = getMatchingShipTo(
              getCustomerShipToSitesFilteredByWarehouse(
                activeCart as any,
                currentCustomer,
                currentCustomerShipToSites as Site[]
              ),
              sfAddress
            );

            if (!existingOrDefaultShippingAddress) {
              // First time that this customer will place an order, and there is no shipping address
              // with the same info as its salesforce account address
              dispatch(showFullscreenLoader({showCartIcon: false}));
              toast.warning(
                'The shipping address associated with the selected Salesforce asset account does not exist in our records. Please wait as we add this address and update our list.'
              );

              if (currentCustomer.class === 'ENTERPRISE' && selectedWarehouse && selectedWarehouse.warehouseCode) {
                sfAddress.warehouseCode = selectedWarehouse.warehouseCode;
              }

              if (currentCustomer.class !== 'MARKETREP' && currentCustomer.class !== 'CIA') {
                sfAddress.operatingUnitCode = getOperatingUnitFromCart(activeCart) as string;
              }

              await saveNewShipToSite(sfAddress, dispatch);

              const sessionStorageCustomerId = window.sessionStorage.getItem(
                COMMERCE_CORE_CONSTANTS.WINDOW_SESSION.selectedCustomerId
              );

              if (sessionStorageCustomerId) {
                await dispatch(getCustomerShipToSitesAsync({customerId: sessionStorageCustomerId}));
                await dispatch(setSiteInfo({currentCustomer: currentCustomer}));
                newShipToSite = sfAddress;
              }
              dispatch(hideFullscreenLoader());
            }
          }
        }
      }
    }
    return {newShipToSite, existingOrDefaultShippingAddress} as const;
  };
};
