import React, {useEffect, useState} from 'react';
import {Form, Formik} from 'formik';
import {IFullPieces} from '../../../../../../../features/fabrication/IFabricationState';
import {getAllFabricatedProductGroups} from '../../../../../../../features/productType/service/productType.service';
import {IFabricatedProductGroupsResponse} from '../../../../../../../features/productType/IProductTypeState';
import {useTypedSelector} from '../../../../../../../hooks/store';
import {selectCurrentCustomer} from '../../../../../../../features/customer/slice/customer.slice';
import {Col} from 'react-bootstrap';
import FabricationPieceForm from '../../../../../FabricationOrderEntry/OrderPieces/PieceInfoForms/FabricationForm';
import CambriaSelect from '../../../../../../../Framework/Components/CambriaSelect';
import {selectFabLineItemsLimit} from '../../../../../../../features/environment/slice/environment.slice';
import {selectActiveCart} from '../../../../../../../features/cart/slice/cart.slice';
import {toast} from 'react-toastify';
import COMMERCE_CORE_CONSTANTS from '../../../../../../../Core/constants';

const EditItem = ({parentItem}: {parentItem: IFullPieces}) => {
  const [productTypeOptions, setProductTypeOptions] = useState<IFabricatedProductGroupsResponse[]>([]);
  const [selectedProductType, setSelectedProductType] = useState<IFabricatedProductGroupsResponse | null>(null);
  const lineItemsLimit = useTypedSelector(selectFabLineItemsLimit);
  const activeCart = useTypedSelector(selectActiveCart);

  const currentCustomer = useTypedSelector(selectCurrentCustomer);

  const clearSelectedProductType = () => {
    setSelectedProductType(null);
  };

  const onSelectedProductTypeChange = (value: any) => {
    if (lineItemsLimit && activeCart?.cartItems && activeCart.cartItems.length >= lineItemsLimit) {
      toast.error(COMMERCE_CORE_CONSTANTS.ERROR_MESSAGES.FAB_LINE_ITEMS_LIMIT_REACHED);
    } else {
      setSelectedProductType(value);
    }
  };

  useEffect(() => {
    const getFabricatedTypeOptions = async () => {
      const productGroups = await getAllFabricatedProductGroups(currentCustomer);
      let displayData: IFabricatedProductGroupsResponse[] = [];
      productGroups.forEach((item: IFabricatedProductGroupsResponse) => {
        if (item.productActiveLocations.includes('ProductTypeSecondary')) {
          displayData.push(item);
        }
      });
      setProductTypeOptions(displayData);
    };
    if (currentCustomer.id && productTypeOptions.length === 0) {
      getFabricatedTypeOptions();
    }
  }, [currentCustomer, productTypeOptions]);

  return (
    <>
      <Formik initialValues={{}} onSubmit={() => {}}>
        {(props) => {
          return (
            <Form>
              <Col xs={4}>
                <CambriaSelect
                  label={`Add Item To ${
                    parentItem.mainPiece.pieceLabel
                      ? `Piece ${parentItem.mainPiece.pieceLabel}`
                      : 'This Product or Service'
                  }`}
                  name="editItemType"
                  defaultValue={selectedProductType}
                  items={productTypeOptions}
                  displayValue="displayName"
                  placeholder="Select an Item"
                  onChange={onSelectedProductTypeChange}
                />
              </Col>
              {selectedProductType && (
                <Col xs={12}>
                  <FabricationPieceForm
                    productGroupCode={selectedProductType?.productGroupCode || ''}
                    parentItemCartItemId={parentItem.mainPiece.cartItemId}
                    parentItem={parentItem}
                    cancelEdit={() => {
                      clearSelectedProductType();
                      props.setFieldValue('editItemType', {displayName: ''});
                    }}
                  />
                </Col>
              )}
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default EditItem;
