import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {URLSearchParams} from 'url';
import COMMERCE_CORE_CONSTANTS from '../../../../Core/constants';
import {baseApiCallWithReauth} from '../../../../Framework/api-utils/api-utils';
import {CustomerFullProfile} from '../../ICustomerState';

const initialState: CustomerFullProfile = {
  productTypeWarehouses: [],
  classGroups: [],
  id: undefined,
  erpCustomerId: 0,
  name: '',
  crmAccountId: '',
  type: '',
  status: '',
  salesChannel: '',
  class: '',
  recordCreated: undefined,
  recordCreatedBy: '',
  recordUpdated: undefined,
  taxOverrideEligible: undefined,
  oracleAccountReference: undefined,
  contacts: [],
  sites: [],
  phoneNumber: [],
  email: [],
  defaultWarehouseCode: '',
  productTypeWarehouseCodes: [],
};

export const setCustomerClassGroups = createAsyncThunk(
  'currentCustomer/setCustomerClassGroups',
  async (data: URLSearchParams) => {
    const endpoint = COMMERCE_CORE_CONSTANTS.API_SERVICES.CUSTOMERGROUP.customerClassGroups;
    const response = await baseApiCallWithReauth('GET', endpoint, data);
    let newResponse = [];
    for (let i of response.results) {
      newResponse.push(i.customerClassGroupName);
    }
    return newResponse;
  }
);

export const setProductTypeWarehouseCodes = createAsyncThunk(
  'currentCustomer/setProductTypeWarehouseCodes',
  async (data: URLSearchParams) => {
    const endpoint = COMMERCE_CORE_CONSTANTS.API_SERVICES.SHIPPINGMETHOD.productTypeWarehouses;
    return await baseApiCallWithReauth('GET', endpoint, data);
  }
);

export const currentCustomerSlice = createSlice({
  name: 'currentCustomer',
  initialState,
  reducers: {
    setProductTypeWarehouses: (state, action) => {
      state.productTypeWarehouses = action.payload;
    },
    setDefaultWarehouseCode: (state, action) => {
      state.defaultWarehouseCode = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setCustomerClassGroups.fulfilled, (state, action) => {
      state.classGroups = action.payload;
    });
    builder.addCase(setProductTypeWarehouseCodes.fulfilled, (state, action) => {
      state.productTypeWarehouseCodes = action.payload;
    });
  },
});

export const {setProductTypeWarehouses, setDefaultWarehouseCode} = currentCustomerSlice.actions;
export default currentCustomerSlice.reducer;
