import styled from 'styled-components';

const TablePlaceholderContainer = styled.tr`
	.rs-placeholder-paragraph-graph-square {
		height: 90px;
		width: 90px;
		margin: 30px;
	}
	.date-block-placeholder {
		width: 200px;
		padding: 30px;
	}
`;

export default TablePlaceholderContainer;
