import React, {FC} from 'react';
import {toast} from 'react-toastify';
import Card from '../';
import Icon from '../../Icon/index';
import {AddressCardContainer} from './AddressCard.styled';

interface AddresCardProps {
  site: any;
}

const AddressCard: FC<AddresCardProps> = ({site}) => {
  const copyToClipboard = (value: string) => {
    navigator.clipboard.writeText(value);
    toast.success('Copied to clipboard');
  };

  const renderSiteType = () => {
    if (site.siteType === 'SHIP_TO') {
      return 'SHIP-TO ADDRESS';
    }
    if (site.siteType === 'BILL_TO') {
      return 'BILL-TO ADDRESS';
    }
  };

  return (
    <>
      <div className="d-flex align-items-center justify-content-between">
        <p className="font-weight-bold small">{renderSiteType()}</p>
        <p className="font-weight-bold small">{site.isPending && 'PENDING'}</p>
      </div>
      <Card>
        <AddressCardContainer>
          <p className="site-name">{site.siteName}</p>
          <p>
            {site.address1} {site.address2 ? `, ${site.address2}` : ''}
          </p>
          <p>{site.address3}</p>
          <p>
            {site.city}, {site.stateProvince} {site.postalCode}
          </p>
          <p>{site.country}</p>
          <div className="align-items-center d-flex site-info">
            <Icon icon="icons-cambria-Hr-Single" size="28" weight="900" color="black" colorOnHover="black" />
            <span className="site-info-text">
              {site.contacts[0]?.firstName} {site.contacts[0]?.lastName}
            </span>
            {site.contacts[0]?.firstName && (
              <i
                className="far fa-clone"
                data-testid="copyToClipboarName"
                onClick={() => copyToClipboard(`${site.contacts[0]?.firstName} ${site.contacts[0]?.lastName}`)}></i>
            )}
          </div>
          <div className="align-items-center d-flex site-info">
            <Icon icon="icons-cambria-Symbols-Mail" size="28" weight="900" color="black" colorOnHover="black" />
            <span className="site-info-text">{site.contacts[0]?.email[0]}</span>
            {site.contacts[0]?.email[0] && (
              <i
                className="far fa-clone"
                data-testid="copyToClipboardEmail"
                onClick={() => copyToClipboard(site.contacts[0]?.email[0])}></i>
            )}
          </div>
          <div className="align-items-center d-flex site-info">
            <Icon icon="icons-cambria-Ui-Phone" size="28" weight="900" color="black" colorOnHover="black" />
            <span className="site-info-text">{site.contacts[0]?.phoneNumber[0]}</span>
            {site.contacts[0]?.phoneNumber[0] && (
              <i
                className="far fa-clone"
                data-testid="copyToClipboardPhoneNumber"
                onClick={() => copyToClipboard(site.contacts[0]?.phoneNumber[0])}></i>
            )}
          </div>
        </AddressCardContainer>
      </Card>
    </>
  );
};

export default AddressCard;
