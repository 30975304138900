import {configureStore, ThunkAction, Action} from '@reduxjs/toolkit';
import {loadingBarReducer, loadingBarMiddleware} from 'react-redux-loading-bar';
import cartReducer from '../features/cart/slice/cart.slice';
import customerReducer from '../features/customer/slice/customer.slice';
import environmentReducer from '../features/environment/slice/environment.slice';
import salesforceReducer from '../features/salesforce/slice/salesforce.slice';
import reactPathReducer from '../features/reactPath/slice/reactPath.slice';
import permissionReducer from '../features/permission/slice/permission.slice';
import productCatalogReducer from '../features/productCatalog/slice/productCatalog.slice';
import authenticationReducer from '../features/auth/slice/authentication.slice';
import postLoginStateReducer from '../features/postLoginState/slice/postLoginState.slice';
import searchReducer from '../features/search/slice/search.slice';
import warehouseReducer from '../features/warehouse/slice/warehouse.slice';
import passwordReducer from '../features/password/slice/password.slice';
import orderReducer from '../features/order/slice/order.slice';
import locationReducer from '../features/location/slice/location.slice';
import paymentReducer from '../features/payment/slice/payment.slice';
import resolveReducer from '../features/resolves/slice/resolves.slice';
import quoteReducer from '../features/quote/slice/quote.slice';
import fullscreenLoaderReducer from '../features/fullscreenLoader/slice/fullscreenLoader.slice';
import notificationReducer from '../features/notification/slice/notification.slice';
import checkoutReducer from '../features/checkout/slice/checkout.slice';
import reportsReducer from '../features/reports/slice/reports.slice';
import fabricationReducer from '../features/fabrication/slice/fabrication.slice';
import fileReducer from '../features/file/slice/file.slice';

export const store = configureStore({
  reducer: {
    loadingBar: loadingBarReducer,
    fullscreenLoader: fullscreenLoaderReducer,
    cart: cartReducer,
    customer: customerReducer,
    environment: environmentReducer,
    permission: permissionReducer,
    reactPath: reactPathReducer,
    postLoginState: postLoginStateReducer,
    warehouse: warehouseReducer,
    payment: paymentReducer,
    authentication: authenticationReducer,
    search: searchReducer,
    productCatalog: productCatalogReducer,
    salesforce: salesforceReducer,
    password: passwordReducer,
    location: locationReducer,
    fabrication: fabricationReducer,
    file: fileReducer,
    resolves: resolveReducer,
    notification: notificationReducer,
    checkout: checkoutReducer,
    order: orderReducer,
    quote: quoteReducer,
    reports: reportsReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(
      loadingBarMiddleware({
        promiseTypeSuffixes: ['pending', 'fulfilled', 'rejected'],
      })
    ),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
