import React from 'react';
import {Col, Row} from 'react-bootstrap';
import DateBlock from '../../../../../../../Core/Components/OrderGrid/DateBlock';
import StatusCode from '../../../../../../../Core/Components/StatusCode';
import Icon from '../../../../../../../Framework/Components/Icon';
import {IOrderHeaderData} from '../IOrderHeader';

const OrderDetailsHeaderMobile = ({
  headerData,
  canSeeJobNumber,
}: {
  headerData: IOrderHeaderData;
  canSeeJobNumber: boolean;
}) => {
  return (
    <div className="order-detail-table-row-mobile">
      <Row className="order-row-mobile">
        <div className="order-date-container">
          <DateBlock date={headerData.dateOrdered ? new Date(headerData.dateOrdered) : null} />
        </div>
        <div className="order-status-container">
          <StatusCode status={headerData.status ? headerData.status : ''} />
        </div>
      </Row>
      <Row className="order-row-mobile">
        <Col>
          <div className="mobile-header-row">
            Order Created By:&nbsp; <span className="order-details-value"> {headerData.orderCreatedBy}</span>
          </div>
          <div className="mobile-header-row">
            Order Type: &nbsp;<span className="order-details-value"> {headerData.orderTypeDisplayName}</span>
          </div>
          <div hidden={!canSeeJobNumber || headerData.jobNumber === null} className="mobile-header-row">
            Job Number: &nbsp; <span className="order-details-value"> {headerData.jobNumber}</span>
          </div>
          <div className="mobile-header-row">
            Order Number: &nbsp;<span className="order-details-value"> {headerData.orderNumber}</span>
          </div>
          {headerData.crmQuoteNumber ? (
            <div className="mobile-header-row">
              Quote Number:&nbsp;<span className="order-details-value">{headerData.crmQuoteNumber}</span>
            </div>
          ) : (
            <></>
          )}
          <div className="mobile-header-row">
            Purchase Order Number: &nbsp;<span className="order-details-value"> {headerData.purchaseOrderNumber}</span>
          </div>
          <div className="mobile-header-row">
            Program Name: &nbsp;<span className="order-details-value"> {headerData.programName}</span>
          </div>
          <div className="mobile-header-row" hidden={!headerData.fabOrderRevisionDisplayValue}>
            Revision: &nbsp;<span className="order-details-value">{headerData.fabOrderRevisionDisplayValue}</span>
          </div>
          {headerData.orderCrmAccountName ? (
            <div className="mobile-header-row">
              Salesforce Account:&nbsp;<span className="order-details-value">{headerData.orderCrmAccountName}</span>
            </div>
          ) : (
            <></>
          )}
          <div className="mobile-header-row">
            Delivery Method: &nbsp;<span className="order-details-value"> {headerData.deliveryMethod}</span>
          </div>
          <div className="mobile-header-row" hidden={!headerData.orderFavoriteName}>
            <Icon icon="icons-cambria-General-Favorites-Filled" /> {headerData.orderFavoriteName}
          </div>
          {headerData.fabricationInstructions ? (
            <div className="mobile-header-row align-items-start mt-2">
              Fabrication Instructions: &nbsp;
              <span className="order-details-value ml-1">{headerData.fabricationInstructions}</span>
            </div>
          ) : (
            <></>
          )}
          {headerData.shippingInstructions ? (
            <div className="mobile-header-row align-items-start mt-2">
              Shipping Instructions: &nbsp;
              <span className="order-details-value ml-2">{headerData.shippingInstructions}</span>
            </div>
          ) : (
            <></>
          )}
          {headerData.homeDepotOrderNumbers && headerData.homeDepotOrderNumbers?.length > 0 && (
            <div className="mobile-header-row align-items-start mt-2 flex-column">
              Home Depot Order Numbers:&nbsp;
              <div>
                {headerData.homeDepotOrderNumbers.map((number: string, index) => {
                  return (
                    <span className="order-details-value" key={index}>
                      {number}
                      {index + 1 !== headerData.homeDepotOrderNumbers.length && ', '}
                    </span>
                  );
                })}
              </div>
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default OrderDetailsHeaderMobile;
