import {baseApiCallWithReauth} from '../../../Framework/api-utils/api-utils';
import COMMERCE_CORE_CONSTANTS from '../../../Core/constants';
import {EndConsumer} from '../../customer/ICustomerState';
import qs from 'qs';

export const postCustomerRetailSitesRequest = async (data: any) => {
  try {
    return await baseApiCallWithReauth(
      'POST',
      COMMERCE_CORE_CONSTANTS.API_SERVICES.CUSTOMER.retailSites,
      JSON.stringify(data),
      true
    );
  } catch (error: any) {
    throw error;
  }
};

export const putCustomerRetailSitesRequest = async (siteId: number, data: any) => {
  try {
    return await baseApiCallWithReauth(
      'PUT',
      `${COMMERCE_CORE_CONSTANTS.API_SERVICES.CUSTOMER.retailSites}/${siteId}`,
      JSON.stringify(data),
      true
    );
  } catch (error: any) {
    throw error;
  }
};

export const postCartOrderDetailsRequest = async (params: any) => {
  try {
    return await baseApiCallWithReauth(
      'POST',
      COMMERCE_CORE_CONSTANTS.API_SERVICES.ORDER.cartOrderDetails,
      params,
      true
    );
  } catch (error: any) {
    throw error;
  }
};

export const putEndConsumersRequest = async (endConsumerId: number, data: any) => {
  try {
    return await baseApiCallWithReauth(
      'PUT',
      `${COMMERCE_CORE_CONSTANTS.API_SERVICES.CUSTOMER.endConsumers}/${endConsumerId}`,
      JSON.stringify(data),
      true
    );
  } catch (error: any) {
    throw error;
  }
};

export const putEndConsumersWithJobNumberRequest = async (jobNumber: string | null, endUserData: EndConsumer) => {
  try {
    return await baseApiCallWithReauth(
      'PUT',
      `${COMMERCE_CORE_CONSTANTS.API_SERVICES.CUSTOMER.endConsumers}/${endUserData.id}`,
      JSON.stringify({...endUserData, jobNumber})
    );
  } catch (error: any) {
    throw error;
  }
};

export const postEndConsumersRequest = async (params: any) => {
  try {
    return await baseApiCallWithReauth(
      'POST',
      COMMERCE_CORE_CONSTANTS.API_SERVICES.CUSTOMER.endConsumers,
      JSON.stringify(params),
      true
    );
  } catch (error: any) {
    throw error;
  }
};

export const deleteRetailSitesRequest = async (purchasingCustomerId: number) => {
  try {
    return await baseApiCallWithReauth(
      'DELETE',
      `${COMMERCE_CORE_CONSTANTS.API_SERVICES.CUSTOMER.retailSites}/${purchasingCustomerId}`,
      undefined,
      true
    );
  } catch (error: any) {
    throw error;
  }
};

export const deleteEndConsumersRequest = async (endConsumerId: number) => {
  try {
    return await baseApiCallWithReauth(
      'DELETE',
      `${COMMERCE_CORE_CONSTANTS.API_SERVICES.CUSTOMER.endConsumers}/${endConsumerId}`,
      undefined,
      true
    );
  } catch (error: any) {
    throw error;
  }
};

export const getFabInstallAddressRequest = async (jobNumber: string) => {
  try {
    return await baseApiCallWithReauth(
      'GET',
      `${COMMERCE_CORE_CONSTANTS.API_SERVICES.CUSTOMER.fabInstallAddress}?jobNumber=${jobNumber}`
    );
  } catch (error: any) {
    throw error;
  }
};

export const getFabricatedDesignRequest = async () => {
  try {
    return await baseApiCallWithReauth('GET', COMMERCE_CORE_CONSTANTS.API_SERVICES.PRODUCT.fabricatedDesign);
  } catch (error: any) {
    throw error;
  }
};

export const getOrdersByNumberRequest = async (numberToSearchFor: number | string, erpCustomerId: number) => {
  try {
    return await baseApiCallWithReauth(
      'GET',
      COMMERCE_CORE_CONSTANTS.API_SERVICES.ORDER.ordersByNumber,
      qs.stringify({
        numberSearchTerm: numberToSearchFor,
        erpCustomerId: erpCustomerId,
        pager: {
          sort: ['-createdAt'],
          limit: 100,
          offset: 0,
        },
      })
    );
  } catch (error) {
    throw error;
  }
};
