import React, {useEffect, useState} from 'react';
import {
  selectIsPostLoginStateActive,
  selectPostLoginState,
  setPostLoginState,
} from '../../../features/postLoginState/slice/postLoginState.slice';
import {useAppDispatch, useTypedSelector} from '../../../hooks/store';
import Hero from './Hero';
import CambriaCarousel from '../../../Core/Components/Carousel';
import {HeroContainer} from './Hero/Hero.styled';
import {useHistory} from 'react-router-dom';
import {
  selectUserHasAccessToCustomer,
  selectUserHasLoggedIn,
  setUserHasLoggedIn,
} from '../../../features/resolves/slice/resolves.slice';
import {selectUiSettings} from '../../../features/environment/slice/environment.slice';
import {CarouselContainer, HeroCarouselContainer, HomeContainer} from './Home.styled';
import HeroCarousel from './HeroCarousel';
import COMMERCE_CORE_CONSTANTS from '../../../Core/constants';

export default function Home() {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const postLoginStateRedux: any = useTypedSelector(selectPostLoginState);
  const isPostLoginStateActive = useTypedSelector(selectIsPostLoginStateActive);
  const userHasAccessToCustomer = useTypedSelector(selectUserHasAccessToCustomer);
  const postLoginStateRan = useTypedSelector(selectUserHasLoggedIn);
  const uiSettings = useTypedSelector(selectUiSettings);
  const [postLoginState, setPostLoginStateLocal] = useState<any>();
  const [postLoginHasInitialized, setPostLoginHasInitialized] = useState(false);

  useEffect(() => {
    setPostLoginStateLocal(postLoginStateRedux);
    setPostLoginHasInitialized(true);
  }, [postLoginStateRedux]);

  useEffect(() => {
    if (postLoginHasInitialized && isPostLoginStateActive) {
      let postLoginStateCopy = JSON.parse(JSON.stringify(postLoginState));
      if (postLoginState && postLoginState.includes('/#!/')) {
        postLoginStateCopy = postLoginStateCopy.replace('/#!/', COMMERCE_CORE_CONSTANTS.PATHNAMES.home);
      }
      if (
        isPostLoginStateActive &&
        postLoginState &&
        !postLoginState.includes(COMMERCE_CORE_CONSTANTS.PATHNAMES.baseSearch) &&
        userHasAccessToCustomer
      ) {
        let path = postLoginStateCopy.replace(window.location.origin, '');
        dispatch(setPostLoginState(null));
        history.push(path);
      }
      if (
        ((postLoginState &&
          postLoginState.includes('account/orders/details') &&
          postLoginState.includes('erpCustomerId')) ||
          (postLoginState &&
            postLoginState.includes(COMMERCE_CORE_CONSTANTS.PATHNAMES.baseAccountOrder) &&
            postLoginState.includes('erpCustomerId'))) &&
        userHasAccessToCustomer
      ) {
        let path = postLoginStateCopy.replace(window.location.origin, '');
        dispatch(setPostLoginState(null));
        history.push(path);
      }

      dispatch(setUserHasLoggedIn(true));
    }
  }, [
    postLoginState,
    dispatch,
    history,
    isPostLoginStateActive,
    userHasAccessToCustomer,
    postLoginStateRan,
    postLoginHasInitialized,
    uiSettings,
  ]);

  return (
    <HomeContainer>
      {uiSettings && (
        <div>
          {uiSettings.enableHomePageHeroCarousel ? (
            <HeroCarouselContainer>
              <HeroCarousel items={uiSettings.homepageContentV2.homepageHeroCarousel.content} />
            </HeroCarouselContainer>
          ) : (
            <HeroContainer>
              <Hero />
            </HeroContainer>
          )}

          <CarouselContainer>
            <CambriaCarousel
              items={uiSettings.homepageContentV2.homepageCarousel.content}
              title={uiSettings.homepageContentV2.homepageCarousel.heading}
            />
          </CarouselContainer>
        </div>
      )}
    </HomeContainer>
  );
}
