import React from 'react';

import {DropdownContainer, DropdownToggle, DropdownBody} from './Dropdown.styled';

interface DropdownProps {
  children: React.ReactNode;
  compact?: boolean;
}

interface DropdownToggleProps {
  children: React.ReactNode;
}

interface DropdownBodyProps {
  children: React.ReactNode;
  orientation?: string;
  dropDownType?: any;
}

const Dropdown = ({children, compact}: DropdownProps) => {
  return <DropdownContainer className={`${compact ? 'compact' : ''}`}>{children}</DropdownContainer>;
};

export const Toggle = ({children}: DropdownToggleProps) => {
  return <DropdownToggle>{children}</DropdownToggle>;
};
Dropdown.Toggle = Toggle;

export const Body = ({children, orientation = 'left', dropDownType}: DropdownBodyProps) => {
  return (
    <DropdownBody orientation={orientation} dropDownType={dropDownType}>
      {children}
    </DropdownBody>
  );
};
Dropdown.Body = Body;

export default Dropdown;
