import COMMERCE_CORE_CONSTANTS from '../../../constants';

const search_constants = {
  homePage: COMMERCE_CORE_CONSTANTS.PATHNAMES.home,
  product: {
    productDetailsUrl: COMMERCE_CORE_CONSTANTS.PATHNAMES.productDetails,
    searchResults: {
      baseUrl: `${COMMERCE_CORE_CONSTANTS.PATHNAMES.searchResults}?q=`,
      productTypeFilter: '&productType=',
    },
  },
  customer: {},
};

export default search_constants;
