import styled from 'styled-components';

export const CartItemQuantityInputContainer = styled.div`
  .cart-preview-item-qty-input {
    .cambria-counter {
      width: 110px;
      margin-bottom: 0;

      input {
        border: none;
        box-shadow: none !important;
        background-color: transparent !important;
      }
    }
  }
`;
