import styled from 'styled-components/macro';
import {$gray30, $gray67, $gray93, $newprimary, $screenmd, $screensm} from '../../../../Styled/variables';

export const ShippingAndDeliveryContainer = styled.div`
  .current-warehouse {
    font-weight: 600;
    color: ${$gray67};
    @media screen and (min-width: ${$screensm}) {
      text-align: end;
    }
  }

  .button-section {
    margin-top: 29px;

    button {
      padding: 6px 20px;
      text-transform: uppercase;
      font-size: 14px !important;
      width: 260px;
    }

    @media screen and (max-width: ${$screenmd}) {
      margin-top: 0;
      margin-bottom: 10px;

      button {
        margin-left: auto;
      }
    }
  }

  .field-info {
    position: relative;
    bottom: 8px;
    display: flex;
    justify-content: space-between;
    width: inherit;
    font-weight: 400;
    font-size: 14px;

    .cta {
      cursor: pointer;
      color: ${$newprimary};
    }
  }

  .order-notifications {
    width: 100%;
    background-color: ${$gray93};
    padding: 30px 40px;
    margin: 10px 0;

    h2 {
      font-weight: 400;
      font-size: 28px;
      line-height: 44px;
      color: ${$gray30};
      margin-bottom: 10px;
    }

    .email-field {
      div {
        flex-grow: 1;
        margin-right: 20px;
      }

      button {
        height: 34px;
        margin-bottom: 19px;
        flex: 0;
      }
    }
  }

  .ups-logo {
    background: url('/app/assets/images/logo-ups.png') no-repeat center center;
    background-size: 36px;
    height: 40px;
    width: 26px;
    display: inline-block;
    margin-bottom: -12px;
  }
`;
