import {Cart, CartItem} from '../../cart/ICartState';
import {IFullPieces} from '../../fabrication/IFabricationState';

export const mapCartItems = (cart: Cart, cartItems: Array<CartItem>): Array<IFullPieces> => {
  const cartItemsArray = [];
  const cartItemIdIncludedArray: any[] = [];
  for (const cartItem of cartItems) {
    if (!cartItemIdIncludedArray.includes(cartItem.cartItemId)) {
      if (cartItem.parentCartItemId === null) {
        cartItemIdIncludedArray.push(cartItem.cartItemId);
        const fullPieces = setFullPiece(cart, cartItems, cartItem);
        cartItemsArray.push(fullPieces);
      }
    }
  }
  return cartItemsArray;
};

export const setFullPiece = (cart: Cart, items: Array<CartItem>, cartItem: CartItem): IFullPieces => {
  const fullPiece: IFullPieces = {
    mainPiece: cartItem,
    mainEdgeProfile: [],
    mainPieceChildItems: [],
    cartId: cart.id,
    duplicatedChildPiece: null,
  };
  fullPiece.cartId = cart.id;
  fullPiece.mainPiece = cartItem;
  for (const item of items) {
    if (cartItem.cartItemId === item.parentCartItemId) {
      if (
        item.cartItemType === 'FabAndInstallStandard' &&
        item.productGroupCode !== 'GeneralItems' &&
        item.productGroupCode !== 'CutoutsAndInlays'
      ) {
        fullPiece.mainEdgeProfile.push(item);
      } else if (
        item.cartItemType === 'FabAndInstallDiscrete' ||
        item.productGroupCode === 'CutoutsAndInlays' ||
        item.productGroupCode === 'GeneralItems'
      ) {
        const fullPieceChild = setFullPiece(cart, items, item);
        fullPiece.mainPieceChildItems.push(fullPieceChild);
      }
    }
  }
  return fullPiece;
};
