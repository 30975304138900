import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {
  DisabledNavLink,
  DropdownLink,
  HeaderNavigationContainer,
  InternalDropdownLink,
  NavItem,
} from './HeaderNavigation.styled';
import CambriaLogo from '../CambriaLogo';
import Dropdown from '../../../../Framework/Components/Dropdown';
import {useTypedSelector} from '../../../../hooks/store';
import Icon from '../../../../Framework/Components/Icon';
import {UiSettings} from '../../../../features/environment/IEnvironmentState';
import {
  getAvailableProductTabsByWarehouse,
  hasPermission,
  INavItem,
} from '../../../../store/permission/permission.service';
import {selectUiSettings} from '../../../../features/environment/slice/environment.slice';
import {selectUserActions} from '../../../../features/permission/slice/permission.slice';
import {selectCurrentCustomer} from '../../../../features/customer/slice/customer.slice';
import {selectSelectedWarehouse} from '../../../../features/warehouse/slice/warehouse.slice';
import COMMERCE_CORE_CONSTANTS from '../../../constants';
import {DropdownItem} from './IDropdownItem';
import {useShowCartPreview} from '../../../../features/cart/hooks/useShowCartPreview/useShowCartPreview';

const HeaderNavigation = () => {
  const [shouldRender, setShouldRender] = useState(false);
  const history = useHistory();

  const uiSettings: UiSettings | null = useTypedSelector(selectUiSettings);
  const userActions = useTypedSelector(selectUserActions);
  const customer = useTypedSelector(selectCurrentCustomer);
  const selectedWarehouse = useTypedSelector(selectSelectedWarehouse);
  const showCartPreview = useShowCartPreview();

  const [navItems] = useState<Array<INavItem>>([
    {
      name: 'Slabs',
      type: 'slabs',
      urn: 'urn:csa:commerceui:productCatalog:viewSlabs',
      show: true,
      href: '/product/slabs/',
    },
    {
      name: 'Samples',
      type: 'samples',
      urn: 'urn:csa:commerceui:productCatalog:viewSamples',
      show: true,
      href: '/product/samples/',
    },
    {
      name: 'Fabrication',
      type: 'fabrication',
      urn: 'urn:csa:commerceui:productcatalog:viewfabrication',
      show: true,
      href: COMMERCE_CORE_CONSTANTS.PATHNAMES.fabrication,
    },
  ]);

  const routeToHomePage = () => {
    history.push(COMMERCE_CORE_CONSTANTS.PATHNAMES.home);
  };

  const determineHasView = (tabName: string, urn: string, warehouseCode: string) => {
    const availableProductTabs = getAvailableProductTabsByWarehouse(warehouseCode);
    if (hasPermission(urn, userActions)) {
      return availableProductTabs.includes(tabName);
    } else {
      return true;
    }
  };

  useEffect(() => {
    if (userActions && customer && customer.class) {
      if (customer.class.toLowerCase() === 'enterprise') {
        if (selectedWarehouse && selectedWarehouse.warehouseCode) {
          setShouldRender(true);
        }
      } else {
        setShouldRender(true);
      }
    }
  }, [userActions, customer, selectedWarehouse]);

  const renderNavItems = () => {
    return navItems.map((item: INavItem) => {
      if (
        selectedWarehouse &&
        selectedWarehouse.warehouseCode &&
        customer &&
        customer.class &&
        customer.class.toLowerCase() === 'enterprise'
      ) {
        if (determineHasView(item.name, item.urn, selectedWarehouse.warehouseCode)) {
          return renderNavItem(true, item);
        } else {
          return renderNavItem(false, item);
        }
      } else if (hasPermission(item.urn, userActions)) {
        return renderNavItem(true, item);
      }
      return null;
    });
  };

  const renderNavItem = (hasClick: boolean, item: INavItem) => {
    return hasClick ? (
      <NavItem
        key={item.urn}
        className={`internal-link ${showCartPreview ? 'compact' : ''}`}
        to={item.href}
        activeClassName="selected"
        isActive={(match, location) => location.hash.includes(item.type)}
        data-testid="navItemOnClick">
        {item.name}
      </NavItem>
    ) : (
      <DisabledNavLink key={item.urn} className={`internal-link ${showCartPreview ? 'compact' : ''}`}>
        {item.name}
      </DisabledNavLink>
    );
  };

  const renderDropdownContent = (menu: any) => {
    return menu.map((item: DropdownItem) => {
      if (!item.openInNewTab) {
        return (
          <InternalDropdownLink
            className={`${showCartPreview ? 'compact' : ''}`}
            onClick={() => {
              history.push(item.url);
            }}
            key={item.order}>
            <div className="dropdown-content-section-title">
              {item.title}
              <Icon icon="icons-cambria-icon_external" color="#333" colorOnHover="#c59617" size="10" weight="800" />
            </div>
            <div className="dropdown-content-section-description">{item.description}</div>
          </InternalDropdownLink>
        );
      } else {
        return (
          <DropdownLink to={{pathname: item.url}} key={item.order} target="_blank">
            <div className="dropdown-content-section-title">
              {item.title}
              <Icon icon="icons-cambria-icon_external" color="#333" colorOnHover="#c59617" size="10" weight="800" />
            </div>
            <div className="dropdown-content-section-description">{item.description}</div>
          </DropdownLink>
        );
      }
    });
  };

  return (
    <>
      <HeaderNavigationContainer>
        <div className="container flex-nowrap justify-content-between m-auto row">
          <div className="align-items-center col-xs-4 d-flex justify-content-between header-product-nav-items">
            {shouldRender ? renderNavItems() : <div></div>}
          </div>
          <div data-testid="linkTo/a#!/" onClick={() => routeToHomePage()} className="col-xs-4 link-to-home">
            <CambriaLogo bgColor="black" />
          </div>
          <div className="align-items-center col-xs-4 d-flex justify-content-between right-dropdown-container">
            <Dropdown compact={showCartPreview}>
              <Dropdown.Toggle>
                <NavItem to="" className={`${showCartPreview ? 'compact' : ''}`}>
                  Technical Info
                  <Icon icon="icons-cambria-Ui-Triangle" color="#333" colorOnHover="#c59617" size="8" />
                </NavItem>
              </Dropdown.Toggle>
              <Dropdown.Body orientation="left" dropDownType={'technicalInfo'}>
                <div className="p-3">
                  {uiSettings && uiSettings.technicalInfo ? renderDropdownContent(uiSettings.technicalInfo) : null}
                </div>
              </Dropdown.Body>
            </Dropdown>
            <Dropdown compact={showCartPreview}>
              <Dropdown.Toggle>
                <NavItem to="" className={`${showCartPreview ? 'compact' : ''}`}>
                  Tools & Resources
                  <Icon icon="icons-cambria-Ui-Triangle" color="#333" colorOnHover="#c59617" size="8" />
                </NavItem>
              </Dropdown.Toggle>
              <Dropdown.Body orientation="right">
                <div className="d-flex p-3">
                  <div className="p-r-sm">
                    <div className="sub-section-title">
                      {uiSettings && uiSettings.toolsAndResources ? uiSettings.toolsAndResources[0].columnName : null}
                    </div>
                    {uiSettings && uiSettings.toolsAndResources
                      ? renderDropdownContent(uiSettings.toolsAndResources[0].items)
                      : null}
                  </div>
                  <div className="p-l-sm">
                    <div className="sub-section-title">
                      {uiSettings && uiSettings.toolsAndResources ? uiSettings.toolsAndResources[1].columnName : null}
                    </div>
                    {uiSettings && uiSettings.toolsAndResources
                      ? renderDropdownContent(uiSettings.toolsAndResources[1].items)
                      : null}
                  </div>
                </div>
              </Dropdown.Body>
            </Dropdown>
          </div>
        </div>
      </HeaderNavigationContainer>
    </>
  );
};

export default HeaderNavigation;
