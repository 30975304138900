import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import {Link, useHistory} from 'react-router-dom';
import {
  AccountInfoBody,
  AccountInfoDropdown,
  AccountInfoHeader,
  CartItemsQuantity,
  HeaderCDMPContainer,
} from './HeaderCDMP.styled';
import CustomerHeaderDropdown from './CustomerHeaderDropdown';
import Icon from '../../../../Framework/Components/Icon';
import Dropdown from '../../../../Framework/Components/Dropdown';
import {useTypedSelector} from '../../../../hooks/store';
import {StyledLogoutButton} from '../../../../Framework/Components/styles/StyledLogoutButton.component';
import {Cart, CartItem} from '../../../../features/cart/ICartState';
import CambriaLogo from '../CambriaLogo';
import {CustomerFullProfile} from '../../../../features/customer/ICustomerState';
import {useLogout} from '../../../../hooks/useLogout';
import {selectActiveCart} from '../../../../features/cart/slice/cart.slice';
import {selectActiveProgram, selectAvailablePrograms} from '../../../../features/salesforce/slice/salesforce.slice';
import {selectCurrentCustomer} from '../../../../features/customer/slice/customer.slice';
import COMMERCE_CORE_CONSTANTS from '../../../constants';
import 'balloon-css';
import CambriaTooltip from '../../../../Framework/Components/CambriaTooltip';
import {isBundleProgram} from '../../../../features/salesforce/service/salesforce.service';
import {selectIsRevH, selectIsRevI} from '../../../../features/environment/slice/environment.slice';

interface IHeaderCDMPProps {
  toggleSidebar?: Function;
  closeSidebar?: Function;
}

const HeaderCDMP = ({toggleSidebar, closeSidebar}: IHeaderCDMPProps) => {
  const logout = useLogout();
  const history = useHistory();

  const activeCart: Cart | null = useTypedSelector(selectActiveCart);
  const availablePrograms = useTypedSelector(selectAvailablePrograms);
  const activeProgram = useTypedSelector(selectActiveProgram);
  const currentCustomer: CustomerFullProfile | null = useTypedSelector(selectCurrentCustomer);
  const isRevH = useTypedSelector(selectIsRevH);
  const isRevI = useTypedSelector(selectIsRevI);
  const isRev = isRevH || isRevI;

  const updateCartItemsQuantity = (cart: Cart | null) => {
    if (!cart) {
      return;
    }

    let cartItemsQuantity = 0;
    if (cart && cart.cartItems && cart.cartItems.length > 0) {
      cart.cartItems.map((i: any) => (cartItemsQuantity += i.tempQuantity ? i.tempQuantity : i.quantity));
    }
    return cartItemsQuantity;
  };

  const cartItemsQuantity = updateCartItemsQuantity(activeCart);
  const isBundleProgramSelected = isBundleProgram(activeProgram);
  const halfBundlesQuantity = activeCart?.cartItems?.reduce(
    (acc: number, item: CartItem) => acc + (item.lineItemHalfBundleQuantity ?? 0),
    0
  );
  const hasEvenNumberOfHalfBundles =
    halfBundlesQuantity !== null && halfBundlesQuantity !== undefined && halfBundlesQuantity % 2 === 0;
  const invalidBundleProgramCart =
    isBundleProgramSelected &&
    activeCart &&
    ((!isRev && !hasEvenNumberOfHalfBundles) || (isRev && !activeCart.isValid));

  let invalidCartMessage = '';
  if (invalidBundleProgramCart) {
    invalidCartMessage = isRev
      ? COMMERCE_CORE_CONSTANTS.ERROR_MESSAGES.INVALID_BUNDLE_REV_H_PROGRAM_CART
      : COMMERCE_CORE_CONSTANTS.ERROR_MESSAGES.INVALID_BUNDLE_PROGRAM_CART;
  }

  const handleLogout = (event: any) => {
    event.preventDefault();

    logout();
  };

  const toggleAndRouteMenu = () => {
    if (closeSidebar) {
      closeSidebar();
    }
    history.push(COMMERCE_CORE_CONSTANTS.PATHNAMES.home);
  };

  const toggleAndRouteCart = () => {
    if (closeSidebar) {
      closeSidebar();
    }
    history.push(COMMERCE_CORE_CONSTANTS.PATHNAMES.cart);
  };

  return (
    <HeaderCDMPContainer>
      <Container className="h-100">
        <div className="h-100 hidden-before-gfb">
          <Row className="desktop h-100 flex-nowrap">
            <Col md={6} className="d-flex align-items-center justify-content-start customer-header-container">
              {!!currentCustomer?.name && (
                <AccountInfoDropdown>
                  <Dropdown>
                    <Dropdown.Toggle>
                      <AccountInfoHeader>
                        <div className="customer">
                          <span className="customer-name">{currentCustomer?.name}</span>
                          <Icon icon="icons-cambria-Ui-Triangle" color="#fff" colorOnHover="#333" size="8" />
                        </div>
                        {availablePrograms?.programs?.length && availablePrograms?.programs?.length > 1 ? (
                          <div className="ordering-through">
                            <span className="title">Ordering through: </span>
                            <span className="description">{activeProgram.commerceDisplayName}</span>
                          </div>
                        ) : (
                          ''
                        )}
                      </AccountInfoHeader>
                    </Dropdown.Toggle>
                    <Dropdown.Body orientation="left">
                      <AccountInfoBody>
                        <CustomerHeaderDropdown />
                      </AccountInfoBody>
                    </Dropdown.Body>
                  </Dropdown>
                </AccountInfoDropdown>
              )}
            </Col>
            <Col md={6} className="d-flex align-items-end justify-content-end profile-icon-container">
              <Link
                to="/cart"
                data-testid="linkA#!cart"
                className="m-3"
                onClick={() => history.push(COMMERCE_CORE_CONSTANTS.PATHNAMES.cart)}>
                <CambriaTooltip size="medium" position="down-right" tooltip={invalidCartMessage}>
                  <Icon className="m-3" color="#bcc0c6" size="34" weight="600" icon="icons-cambria-Ui-Store" />
                </CambriaTooltip>
                {cartItemsQuantity && cartItemsQuantity > 0 ? (
                  <CartItemsQuantity className={`${invalidBundleProgramCart ? 'invalid-cart' : ''}`}>
                    {cartItemsQuantity}
                  </CartItemsQuantity>
                ) : null}
              </Link>
              <Dropdown>
                <Dropdown.Toggle>
                  <Icon color="#bcc0c6" size="34" weight="600" icon="icons-cambria-Hr-Single" />
                </Dropdown.Toggle>
                <Dropdown.Body orientation="right">
                  <StyledLogoutButton data-testid="logout" onClick={handleLogout}>
                    Logout
                  </StyledLogoutButton>
                </Dropdown.Body>
              </Dropdown>
            </Col>
          </Row>
        </div>
        <div className="h-100 hidden-after-gfb">
          <Row className="h-100 hidden-after-gfb">
            <Col xs={2} sm={3} className="d-flex align-items-center justify-content-start">
              <div data-testid="togglesidebar" onClick={() => (toggleSidebar ? toggleSidebar() : null)}>
                <Icon color="#bcc0c6" size="34" weight="600" icon="icons-cambria-Ui-Menu" />
              </div>
            </Col>
            <Col xs={8} sm={6} className="d-flex align-items-center justify-content-center">
              <div data-testid="buttontogglerouteMenu" onClick={() => toggleAndRouteMenu()}>
                <CambriaLogo bgColor="black" height="17" />
              </div>
            </Col>
            <Col xs={2} sm={3} className="d-flex align-items-center justify-content-end">
              <Link to="/cart" data-testid="buttontoggleroutecart" onClick={() => toggleAndRouteCart()}>
                <CambriaTooltip size="medium" position="down-right" tooltip={invalidCartMessage}>
                  <Icon className="m-3" color="#bcc0c6" size="34" weight="600" icon="icons-cambria-Ui-Store" />
                </CambriaTooltip>
                {cartItemsQuantity && cartItemsQuantity > 0 ? (
                  <CartItemsQuantity className={`${invalidBundleProgramCart ? 'invalid-cart' : ''}`}>
                    {cartItemsQuantity}
                  </CartItemsQuantity>
                ) : null}
              </Link>
            </Col>
          </Row>
        </div>
      </Container>
    </HeaderCDMPContainer>
  );
};

export default HeaderCDMP;
