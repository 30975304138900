import React, {useEffect, useState} from 'react';
import {setCurrentCustomer, removeCurrentCustomer} from '../../../../features/customer/slice/customer.slice';
import {selectCustomerSearch} from '../../../../features/search/slice/search.slice';
import {useAppDispatch, useTypedSelector} from '../../../../hooks/store';
import CustomerOrderNav from '../CustomerOrderNav';
import {SearchBodyWrapper} from '../SearchBodyWrapper.styled';
import CustomerTable from './CustomerTable';
import CustomerPageContainer from './CustomerSearchPage.styled';
import {useScrollPercentage} from 'react-scroll-percentage';
import {selectCurrentUser} from '../../../../features/auth/slice/authentication.slice';
import COMMERCE_CORE_CONSTANTS from '../../../constants';
import {setResolvesAreLoading} from '../../../../features/resolves/slice/resolves.slice';
import {
  customerSearchAsync,
  getDefaultMarketRepCustomer,
  loadMoreCustomersAsync,
  setCustomerKeyword,
} from '../../../../features/search/customer/slice/customer-search.slice';

const CustomerSearchPage = () => {
  const dispatch = useAppDispatch();
  const [ref, percentageScrolled] = useScrollPercentage();

  let [customerResults, setCustomerResults] = useState([]);
  let [numberOfEntries, setNumberOfEntries] = useState(0);
  let [searchWord, setSearchWord] = useState('');
  let [isLoading, setIsLoading] = useState(true);
  const [resultDisplaySize, setResultDisplaySize] = useState(50);
  const [scrolledFarEnough, setScrolledFarEnough] = useState(true);

  let suggestedCustomers = useTypedSelector(selectCustomerSearch).suggestions;
  let totalResults = useTypedSelector(selectCustomerSearch).numberOfResults;
  let keyword = useTypedSelector(selectCustomerSearch).keyword;
  let loadingStatus = useTypedSelector(selectCustomerSearch).loading;
  let erpCustomerId = useTypedSelector(selectCurrentUser)?.erpcustomerId;

  const selectCustomer = (selectedCustomer: any) => {
    window.sessionStorage.setItem(COMMERCE_CORE_CONSTANTS.WINDOW_SESSION.selectedCustomerId, selectedCustomer.id);
    dispatch(setCurrentCustomer(selectedCustomer));
    dispatch(setResolvesAreLoading(true));
  };

  useEffect(() => {
    dispatch(setCustomerKeyword(''));
  }, [dispatch]);

  useEffect(() => {
    const scrollToTopOnLoad = () => {
      window.scrollTo({top: 0, left: 0});
    };
    setTimeout(() => {
      scrollToTopOnLoad();
    }, 200);
  }, []);

  useEffect(() => {
    const loadMoreDataOnScroll = (offset: number) => {
      let data = new URLSearchParams();
      if (searchWord) {
        data.append('name', searchWord);
      }
      data.append('pager.expand', 'identifyingAddress');
      data.append('pager.expand', 'totalResults');
      data.append('filterCustomersWithoutSites', 'true');
      data.append('pager.offset', offset.toString());
      data.append('pager.limit', '50');
      data.append('siteType', 'BILL_TO');
      dispatch(loadMoreCustomersAsync(data));
      setScrolledFarEnough(false);
    };
    const determineIfMoreDataShouldBeLoaded = () => {
      if (scrolledFarEnough && resultDisplaySize <= customerResults.length) {
        loadMoreDataOnScroll(resultDisplaySize);
        setResultDisplaySize((r) => r + 50);
      }
    };
    determineIfMoreDataShouldBeLoaded();
  }, [scrolledFarEnough, dispatch, resultDisplaySize, searchWord, customerResults]);

  useEffect(() => {
    if (percentageScrolled > 0.75) {
      setScrolledFarEnough(true);
    } else {
      setScrolledFarEnough(false);
    }
  }, [percentageScrolled]);

  // Get customers on initial page load
  useEffect(() => {
    let data = new URLSearchParams();
    data.append('pager.expand', 'identifyingAddress');
    data.append('pager.expand', 'totalResults');
    data.append('filterCustomersWithoutSites', 'true');
    data.append('pager.offset', '0');
    data.append('pager.limit', '50');
    data.append('siteType', 'BILL_TO');
    dispatch(customerSearchAsync(data));
  }, [dispatch]);

  // Saves Market Rep customer in redux if it exists
  useEffect(() => {
    if (erpCustomerId && erpCustomerId !== '0') {
      let data = new URLSearchParams();
      data.append('erpCustomerId', erpCustomerId);
      data.append('pager.expand', 'identifyingAddress');
      data.append('pager.expand', 'totalResults');
      data.append('filterCustomersWithoutSites', 'true');
      dispatch(getDefaultMarketRepCustomer(data));
    }
  }, [dispatch, erpCustomerId]);

  useEffect(() => {
    const clearCustomerOnPageLoad = () => {
      dispatch(removeCurrentCustomer());
    };
    clearCustomerOnPageLoad();
  }, [dispatch]);

  useEffect(() => {
    setCustomerResults(suggestedCustomers);
  }, [suggestedCustomers]);

  useEffect(() => {
    setNumberOfEntries(totalResults);
  }, [totalResults]);

  useEffect(() => {
    setSearchWord(keyword);
    setResultDisplaySize(50);
  }, [keyword]);

  useEffect(() => {
    if (loadingStatus === 'pending') {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [loadingStatus]);

  return (
    <SearchBodyWrapper>
      <CustomerPageContainer>
        <div className="section container-page all-contents-wrapper">
          <div className="container">
            <CustomerOrderNav location={COMMERCE_CORE_CONSTANTS.PATHNAMES.customerSearch} />
          </div>
          <div className="customer-table-wrapper" ref={ref}>
            <CustomerTable
              customerSuggestions={customerResults}
              numberOfEntries={numberOfEntries}
              searchWord={searchWord}
              customerSelectionFunction={selectCustomer}
              isLoading={isLoading}
            />
          </div>
        </div>
      </CustomerPageContainer>
    </SearchBodyWrapper>
  );
};
export default CustomerSearchPage;
