import styled from 'styled-components';
import {$gray30, $gray67, $gray93, $newprimary, $newwhite, $screensm} from '../../../../Styled/variables';

const OrderPreferencesContainer = styled.div`
  .account-order-preferences-nav {
    margin: 20px 0px 20px 0px;
    height: 40px;

    .link {
      border-radius: 3px;
      max-width: 50%;
      padding: 2px;

      :hover {
        cursor: pointer;
      }

      .link-text {
        text-align: center;
      }
    }
  }

  .preference-page-title {
    padding-bottom: 0px;
    font-size: 28px !important;
  }

  .preference-page-subheading-container {
    padding: 10px 0px 0px;

    .preference-page-subheading {
      font-weight: 600;
      color: ${$gray30};
      text-align: center;
    }
  }

  .checkbox-label {
    margin-left: 5px;
  }

  .btn-primary {
    background-color: ${$newprimary};
    border: none;
    font-weight: 600;
    padding: 10px;

    :hover {
      background-color: ${$newprimary};
    }

    :active {
      background-color: ${$newprimary} !important;
      border: none;
    }

    :focus {
      background-color: ${$newprimary} !important;
      border: none;
    }
  }

  .update-delivery-button-container {
    margin-top: 20px;
  }

  .checkbox-list-container {
    margin-top: 20px;
    box-shadow: 0px 2px 5px 0px rgb(101 101 101 / 35%);
    padding: 25px;
    background: ${$newwhite};
    margin: 5px;
    border-radius: 3px;
    margin-bottom: 50px;

    .update-delivery-days-btn {
      text-wrap: pretty;
      height: auto !important;
    }
  }

  .preferred-shipping-contact-form-container {
    margin-top: 20px;
    box-shadow: 0px 2px 5px 0px rgb(101 101 101 / 35%);
    padding: 25px;
    background: ${$newwhite};
    margin: 10px;
    border-radius: 3px;
    margin-bottom: 50px;

    .button-section {
      padding-top: 20px;
    }

    .update-preferred-shipping-contact-btn {
      margin-left: 15px;
      text-wrap: pretty;
      height: auto !important;
    }
  }

  .order-preference-body {
    padding: 0px 50px 0px 50px;

    .order-preference-page-header {
      margin: 25px 0px;
    }

    .btn {
      font-size: 14px;
      font-weight: 700;
      height: 45px;
      width: fit-content;
      margin-top: 10px;

      :disabled {
        background-color: ${$newprimary};
      }
    }
  }

  .active-email {
    box-shadow: 0px 2px 5px 0px rgb(101 101 101 / 35%);
    padding: 5px;
    width: fit-content;
    display: flex;
    align-items: center;
    background: ${$gray93};
    margin: 5px 10px 5px 5px;

    .email-text {
      margin-right: 10px;
      font-weight: 600;
      color: ${$gray30};
    }

    .fa-times {
      color: ${$gray67};

      :hover {
        cursor: pointer;
      }
    }
  }

  .form-control {
    width: 400px;
    margin-top: 10px;
    font-size: 14px;

    :focus {
      border: 1px solid #c59617;
      box-shadow:
        inset 0 1px 1px rgb(198 150 25 / 8%),
        0 0 8px rgb(198 150 25 / 60%);
    }
  }

  .email-list-heading {
    margin: 10px 0px 10px 0px;
    font-weight: 600;
    color: #4c4c4c;
  }

  .email-list-body {
    box-shadow: 0px 2px 5px 0px rgb(101 101 101 / 35%);
    padding: 10px;
    background: ${$newwhite};
    margin: 5px;
    border-radius: 3px;
  }

  .react-multi-email {
    font-size: 14px;

    input {
      background-color: ${$newwhite};
      min-width: 300px;
    }
  }

  .react-multi-email.focused {
    border-color: ${$newprimary};
  }

  .email-list-container {
    padding: 5px;
  }

  .checkbox {
    color: ${$newwhite};
    margin: 20px 0px;
    cursor: pointer;

    label {
      color: ${$gray30};
      font-size: 14px;
      font-weight: 700;
      line-height: 22px;
      padding-left: 32px;

      &:before {
        content: '';
        display: inline-block;
        position: absolute;
        left: 0px;
        box-shadow: none;
        width: 22px;
        height: 22px;
        color: ${$gray30};
        text-align: center;
        line-height: 22px;
        background-color: transparent;
        border-color: ${$gray30};
        border-width: 2px;
        border-style: solid;
        color: ${$newwhite};
        border-image: initial;
      }
    }

    input[type='checkbox']:checked ~ label:before {
      vertical-align: middle;
      color: #c59617;
      font-family: icomoon;
      font-size: 24px;
      line-height: 16px;
      content: '\\e906';
    }

    input {
      display: none;
      position: absolute;
      margin-left: -20px;
    }
  }

  @media screen and (max-width: ${$screensm}) {
    .order-preference-body {
      padding: 0px;
    }

    .link {
      .link-text {
        font-size: 12px;
      }
    }

    .react-multi-email {
      input {
        min-width: 200px;
      }
    }
  }
`;
export default OrderPreferencesContainer;
