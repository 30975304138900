import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import qs from 'qs';
import COMMERCE_CORE_CONSTANTS from '../../../Core/constants';
import {baseApiCallWithReauth} from '../../../Framework/api-utils/api-utils';
import {RootState} from '../../../store/store';
import {ResolvesInitialState} from '../IResolvesState';

export const initialState: ResolvesInitialState = {
  defaultCustomer: null,
  customerIsValid: false,
  resolvesAreLoading: true,
  allCustomerInformationLoaded: false,
  cartLoaded: false,
  salesforceInformationLoaded: false,
  defaultCustomerNotFound: null,
  userHasAccessToCustomer: true,
  userHasLoggedIn: false,
  checkoutInformationLoaded: false,
};

export const getDefaultCustomerAsync = createAsyncThunk('customer/getDefaultCustomerAsync', async () => {
  const endpoint = COMMERCE_CORE_CONSTANTS.API_SERVICES.CUSTOMER.customers;
  return await baseApiCallWithReauth(
    'GET',
    endpoint,
    qs.stringify({
      pager: {
        expand: ['identifyingAddress', 'totalResults'],
        limit: 1,
        offset: 0,
      },
      siteType: 'BILL_TO',
    })
  );
});

export const resolveSlice = createSlice({
  name: 'resolves',
  initialState,
  reducers: {
    setResolvesAreLoading: (state, action) => {
      state.resolvesAreLoading = action.payload;
    },
    setCustomerIsValid: (state, action) => {
      state.customerIsValid = action.payload;
    },
    setAllCustomerInformationLoaded: (state, action) => {
      state.allCustomerInformationLoaded = action.payload;
    },
    setCartLoaded: (state, action) => {
      state.cartLoaded = action.payload;
    },
    setSalesforceInformationLoaded: (state, action) => {
      state.salesforceInformationLoaded = action.payload;
    },
    setCheckoutInformationLoaded: (state, action) => {
      state.checkoutInformationLoaded = action.payload;
    },
    setDefaultCustomerNotFoundStatus: (state, action) => {
      state.defaultCustomerNotFound = action.payload;
    },
    setUserHasAccessToCustomer: (state, action) => {
      state.userHasAccessToCustomer = action.payload;
    },
    setDefaultCustomer: (state, action) => {
      state.defaultCustomer = action.payload;
    },
    setUserHasLoggedIn: (state, action) => {
      state.userHasLoggedIn = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getDefaultCustomerAsync.fulfilled, (state: any, action: any) => {
      if (action.payload.totalResults === 1) {
        state.defaultCustomerNotFound = false;
        state.defaultCustomer = action.payload.results[0];
      } else {
        state.defaultCustomerNotFound = true;
        state.defaultCustomer = null;
      }
    });
  },
});

export const selectDefaultCustomer = (state: RootState) => state.resolves.defaultCustomer;
export const selectDefaultCustomerStatus = (state: RootState) => state.resolves.defaultCustomerNotFound;
export const selectSalesforceInformationLoadStatus = (state: RootState) => state.resolves.salesforceInformationLoaded;
export const selectCartLoaded = (state: RootState) => state.resolves.cartLoaded;
export const selectAllCustomerInformationLoaded = (state: RootState) => state.resolves.allCustomerInformationLoaded;
export const selectResolveLoadStatus = (state: RootState) => state.resolves.resolvesAreLoading;
export const selectCustomerValidationStatus = (state: RootState) => state.resolves.customerIsValid;
export const selectUserHasAccessToCustomer = (state: RootState) => state.resolves.userHasAccessToCustomer;
export const selectUserHasLoggedIn = (state: RootState) => state.resolves.userHasLoggedIn;
export const selectCheckoutInformationLoaded = (state: RootState) => state.resolves.checkoutInformationLoaded;

export const {
  setResolvesAreLoading,
  setCustomerIsValid,
  setAllCustomerInformationLoaded,
  setCartLoaded,
  setSalesforceInformationLoaded,
  setDefaultCustomerNotFoundStatus,
  setUserHasAccessToCustomer,
  setDefaultCustomer,
  setUserHasLoggedIn,
  setCheckoutInformationLoaded,
} = resolveSlice.actions;

export default resolveSlice.reducer;
