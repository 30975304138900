import React from 'react';

import {useLocation} from 'react-router-dom';
import {FooterContainer} from './Footer.styled';
import FooterBase from './FooterBase';
import FooterLinks from './FooterLinks';
import COMMERCE_CORE_CONSTANTS from '../../constants';

export interface FooterLink {
  navigationItemLabel: string;
  textStyle?: string;
  navigationItemUrl?: string;
  urn?: string;
  disabled?: boolean;
  openInNewTab?: boolean;
}

export interface FooterMenu {
  menuLabel?: string;
  textStyle?: string;
  isMenuOpen?: boolean;
  mobileOnly?: boolean;
  menuContent?: Array<FooterLink>;
}

export interface FooterBlocks {
  block1: Array<FooterMenu>;
  block2: Array<FooterMenu>;
  block3: Array<FooterMenu>;
  block4: Array<FooterMenu>;
  block5: Array<FooterMenu>;
  block6: Array<FooterMenu>;
}

const Footer: React.FC<{alignment?: string}> = (props) => {
  const location = useLocation();

  let {alignment = 'space-between'} = props;

  const renderFooterContent = () => {
    if (
      location.pathname === COMMERCE_CORE_CONSTANTS.PATHNAMES.login ||
      location.pathname === COMMERCE_CORE_CONSTANTS.PATHNAMES.passwordReset
    ) {
      return (
        <FooterContainer alignment={alignment}>
          <div className="container">
            <FooterBase />
          </div>
        </FooterContainer>
      );
    }

    return (
      <FooterContainer alignment={alignment}>
        <div className="container noprint">
          <FooterLinks />
          <FooterBase />
        </div>
      </FooterContainer>
    );
  };

  return <div className="footer">{renderFooterContent()}</div>;
};

export default Footer;
