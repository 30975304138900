import React, {FC} from 'react';
import CreditCardForm from '../CreditCardForm';
import {AddCreditCardContainer} from './AddCreditCard.styled';

interface AddCreditCardProps {
  closeAddCardSection?: Function;
}

const AddCreditCard: FC<AddCreditCardProps> = ({closeAddCardSection}) => {
  return (
    <AddCreditCardContainer>
      <CreditCardForm onSumbitCallback={closeAddCardSection} onCancelCallback={closeAddCardSection} />
    </AddCreditCardContainer>
  );
};

export default AddCreditCard;
