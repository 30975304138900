import React, {FormEvent, useCallback, useEffect, useState} from 'react';
import {Col, Row} from 'react-bootstrap';
import ProductTable from '../../../../Core/Components/CambriaProductTable';
import OrderSummaryComponent from '../../../../Core/Components/OrderSummary';
import {selectActiveCart} from '../../../../features/cart/slice/cart.slice';
import {Cart, CartItem, EndConsumer, PurchasingCustomer, ShippingMethod} from '../../../../features/cart/ICartState';
import {
  selectCurrentCustomer,
  selectCurrentCustomerBillToSite,
  selectCurrentCustomerBillToSites,
  selectCurrentCustomerShipToSites,
} from '../../../../features/customer/slice/customer.slice';
import {Contact, Site} from '../../../../features/customer/ICustomerState';
import {IPaymentInfoCardData} from '../../../../features/order/IOrderState';
import {selectActiveProgram, selectAvailablePrograms} from '../../../../features/salesforce/slice/salesforce.slice';
import {useAppDispatch, useTypedSelector} from '../../../../hooks/store';
import AddressCard from '../../Account/OrderHistory/OrderDetails/AddressCards';
import FabricationCart from '../../Account/OrderHistory/OrderDetails/FabricationCart';
import PaymentInfoCard from '../../Account/OrderHistory/OrderDetails/PaymentInfoCard';
import ReviewHeader from './ReviewOrderHeader';
import {ReviewAndPlaceOrderContainer} from './ReviewAndPlaceOrder.styled';
import JobOrderTypeForm from '../../Account/OrderHistory/OrderDetails/JobOrderType';
import {getShippingWarehouse} from '../../../../features/warehouse/service/warehouse.service';
import {WarehouseFullData} from '../../../../features/warehouse/IWarehouseState';
import union from 'lodash/union';
import DealerEndUser from '../../Account/OrderHistory/OrderDetails/DealerEndUser';
import {
  getEndConsumersAsync,
  getPurchasingCustomersAsync,
  selectEndConsumers,
  selectPurchasingCustomer,
  setFabricationInitialState,
} from '../../../../features/fabrication/slice/fabrication.slice';
import {
  IAddressCardData,
  ICambriaJobOrderTypeData,
  ICambriaOrderMetaData,
  ICambriaOrderSummaryData,
  ICambriaShipFromWarehouseData,
  NewOrderDetails,
} from '../../../../features/order/orderDetails/IOrderDetailsState';
import {organizePlaceOrderParams, organizeResubmitOrder} from '../../../../features/checkout/service/checkout.service';
import {pushPurchaseEventToDataLayer} from '../../../../features/analytics/service/analytics.service';
import {toast} from 'react-toastify';
import {filterUserFiles} from '../../../../features/file/service/file.service';
import {getFilesRequest} from '../../../../features/file/controller/file.controller';
import {selectCurrentUser} from '../../../../features/auth/slice/authentication.slice';
import {getOrderRejectionsRequest} from '../../../../features/order/controller/order.controller';
import {useHistory} from 'react-router-dom';
import {setActiveStep, setIsSubmitting} from '../../../../features/checkout/slice/checkout.slice';
import {IOrderHeaderData} from '../../Account/OrderHistory/OrderDetails/OrderHeader/IOrderHeader';
import {getJobTypeCodeDependingOnTheType} from '../../../../features/jobType/service/jobType.service';
import {getCartItemsCount, initializeCart} from '../../../../features/cart/service/cart.service';
import {hasPermission} from '../../../../store/permission/permission.service';
import useWindowDimensions from '../../../../hooks/getWindowDimensions';
import Discount from '../../../../Core/Components/Discount';
import {selectSelectedWarehouse} from '../../../../features/warehouse/slice/warehouse.slice';
import {
  hideFullscreenLoader,
  showFullscreenLoader,
} from '../../../../features/fullscreenLoader/slice/fullscreenLoader.slice';
import {selectUiSettings} from '../../../../features/environment/slice/environment.slice';

export const permissionPlaceOrderResolved = async (userActions: string[]) => {
  return await hasPermission('urn:csa:commerceui:placeOrder', userActions);
};

export const permissionPlaceOrderBySelectedOrganizationResolved = async (userActions: string[]) => {
  return await hasPermission('urn:csa:commerceui:placeOrderBySelectedOrganization', userActions);
};

const ReviewAndPlaceOrder = () => {
  let [history] = useState(useHistory());
  const dispatch = useAppDispatch();
  const {width} = useWindowDimensions();

  const mapEndConsumersToPurchasingCustomers = (
    purchasingCustomers: PurchasingCustomer[],
    endConsumers: EndConsumer[] | null
  ) => {
    let purchasingCustomerArrayCopy: PurchasingCustomer[] = purchasingCustomers.map((customer: any) => {
      return {...customer, endConsumers: []};
    });
    if (endConsumers && endConsumers.length > 0) {
      purchasingCustomerArrayCopy.forEach((customer: PurchasingCustomer) => {
        endConsumers.forEach((consumer: EndConsumer) => {
          if (customer.id === consumer.retailSiteId) {
            customer.endConsumers.push(consumer);
          }
        });
      });
    }
    return purchasingCustomerArrayCopy;
  };

  const activeCartRedux = useTypedSelector(selectActiveCart);
  const billToSiteRedux = useTypedSelector(selectCurrentCustomerBillToSite);
  const currentCustomerRedux = useTypedSelector(selectCurrentCustomer);
  const activeProgramRedux = useTypedSelector(selectActiveProgram);
  const customerShipToSitesRedux = useTypedSelector(selectCurrentCustomerShipToSites);
  const customerBillToSitesRedux = useTypedSelector(selectCurrentCustomerBillToSites);
  const purchasingCustomersRedux = useTypedSelector(selectPurchasingCustomer);
  const endConsumersRedux = useTypedSelector(selectEndConsumers);
  const userInfoRedux = useTypedSelector(selectCurrentUser);
  const availableProgramsRedux = useTypedSelector(selectAvailablePrograms);
  const selectedWarehouse = useTypedSelector(selectSelectedWarehouse);
  const uiSettings = useTypedSelector(selectUiSettings);

  const [billToCardInfo, setBillToCardInfo] = useState<IAddressCardData>();
  const [paymentCardInfo, setPaymentCardInfo] = useState<IPaymentInfoCardData>();
  const [cartWarehouseInfo, setCartWarehouseInfo] = useState<WarehouseFullData | null>(null);
  const [shipToCardInfo, setShipToCardInfo] = useState<IAddressCardData>();
  const mappedPurchasingCustomers: PurchasingCustomer[] =
    purchasingCustomersRedux && purchasingCustomersRedux.length > 0
      ? mapEndConsumersToPurchasingCustomers(purchasingCustomersRedux, endConsumersRedux)
      : [];
  const [alertData, setAlertData] = useState<{type: string; msg: string}[]>([]);

  const [isFabricationOrder, setIsFabricationOrder] = useState(false);
  const [isSlabOrder, setIsSlabOrder] = useState(false);

  const shipToContactResolved = (cart: Cart, shipToSites: Site[]): Contact | null => {
    let allContacts = new Array<Contact>();
    for (const site of shipToSites) {
      allContacts = union(allContacts, site.contacts);
    }

    let contact: Contact | null = null;
    if (cart.shippingMethods[0] && cart.shippingMethods[0].shipToContactId) {
      let contactTemp = allContacts.find(
        (o: Contact) =>
          o.erpCustomerContactId === cart.shippingMethods[0].shipToContactId ||
          o.id === cart.shippingMethods[0].shipToContactId
      );
      if (contactTemp) {
        contact = contactTemp;
      }
    }
    return contact;
  };

  const configureCartItemsForProductTable = (cart: Cart | null): Array<CartItem> | null => {
    let tempCartItems = [];
    if (cart && cart?.cartItems && cart.requestedDeliverySchedules.length > 0) {
      for (const cartItem of cart.cartItems) {
        for (const requestedDeliverySchedule of cart.requestedDeliverySchedules) {
          let tempCartItem = JSON.parse(JSON.stringify(cartItem));
          if (requestedDeliverySchedule.cartItemId === cartItem.cartItemId) {
            tempCartItem.quantity = requestedDeliverySchedule.quantity;
            tempCartItem.requestedDeliveryDate = requestedDeliverySchedule.requestedDeliveryDate;
            tempCartItem.seamed = requestedDeliverySchedule.seamed;
            tempCartItem.extendedNetPrice =
              (cartItem.extendedNetPrice / (cartItem.quantity ? cartItem.quantity : 1)) *
              requestedDeliverySchedule.quantity;

            tempCartItems.push(tempCartItem);
          }
        }
      }
    } else if (cart?.cartItems) {
      tempCartItems = JSON.parse(JSON.stringify(cart?.cartItems));
    }
    return tempCartItems;
  };

  const organizeAddressInformation = useCallback((site: Site, billTo: boolean): IAddressCardData => {
    const contact: Contact | undefined = site.contacts && site.contacts.length > 0 ? site?.contacts[0] : undefined;
    const formattedAddress: IAddressCardData = {
      contactName: contact ? contact.firstName + ' ' + contact.lastName : '',
      locationName: site.siteName || '',
      addressLine1: site.address1 || '',
      addressLine2: site.address2 || '',
      addressLine3: site.address3 || '',
      city: site.city || '',
      stateProvince: site.stateProvince || '',
      postalCode: site.postalCode || '',
      country: site.country || '',
      email: contact?.email && contact?.email?.length > 0 ? contact.email[0] : '',
      phone: contact?.phoneNumber && contact?.phoneNumber.length > 0 ? contact.phoneNumber[0] : '',
      type: billTo ? 'BILL-TO ADDRESS' : 'SHIP-TO ADDRESS',
      icon: 'glyphicon glyphicon-credit-card',
      status: '',
    };
    return formattedAddress;
  }, []);

  const calculateShippingAndHandling = useCallback(() => {
    const calculatedShippingAndHandling = activeCartRedux?.totalShippingDiscountAmount
      ? activeCartRedux.totalShippingAmount - activeCartRedux.totalShippingDiscountAmount
      : activeCartRedux?.totalShippingAmount;

    if (!calculatedShippingAndHandling) {
      return 0;
    }

    return calculatedShippingAndHandling;
  }, [activeCartRedux]);

  const getAlertDataFromCart = useCallback((cart: Cart) => {
    let alertData: {type: string; msg: string}[] = [];
    let noShippingAmount = false;

    const shippingMethod = cart.shippingMethods;
    if (shippingMethod) {
      noShippingAmount = isNoShippingAmount(shippingMethod[0], cart);
    } else {
      toast.error('No Shipping Method Found.');
    }
    const wasTaxCalculated = cart.wasTaxCalculated;
    const hadTaxError =
      cart && cart.taxCalculations && cart.taxCalculations.length > 0 && cart.taxCalculations[0].hadError
        ? cart.taxCalculations[0].hadError
        : null;

    if (noShippingAmount || hadTaxError || !wasTaxCalculated) {
      alertData = [
        {
          type: 'info',
          msg: '',
        },
      ];
      if (noShippingAmount && (hadTaxError || !wasTaxCalculated)) {
        alertData[0].msg = 'Shipping & Handling fees and tax will be added to the order separately.';
      } else if (noShippingAmount) {
        alertData[0].msg = 'Shipping & handling fees and associated tax amounts will be added to the order separately.';
      } else {
        alertData[0].msg = 'Tax amounts will be added to the order separately.';
      }
    }
    if (alertData.length > 0) {
      setAlertData(alertData);
    }
  }, []);

  const getShipFromWarehouseData = (): ICambriaShipFromWarehouseData => {
    return {
      warehouse: cartWarehouseInfo?.name ? cartWarehouseInfo.name : '',
    };
  };

  const getJobOrderTypeData = (): ICambriaJobOrderTypeData => {
    let orderDetails =
      activeCartRedux && activeCartRedux.orderDetails.length > 0 ? activeCartRedux.orderDetails[0] : undefined;
    return {
      cartId: activeCartRedux?.id || '',
      jobType: getJobTypeCodeDependingOnTheType(orderDetails?.jobType || '', isFabricationOrder),
      projectType: orderDetails?.projectType || '',
      projectName: orderDetails?.projectName || '',
      projectCity: orderDetails?.projectCity || '',
      relatedAgency: orderDetails?.relatedAgency || '',
    };
  };

  const shouldShowEstimatedPaymentFee = (actualFee: number): boolean => {
    return actualFee ? true : false;
  };

  const isNoShippingAmount = (shippingMethod: ShippingMethod | undefined, cart: Cart | undefined): boolean => {
    return shippingMethod?.freightTerms === 'PREPAIDADD' && !cart?.totalShippingAmount;
  };

  const getOrderSummaryData = (): ICambriaOrderSummaryData => {
    let shippingMethod =
      activeCartRedux && activeCartRedux.shippingMethods.length > 0 ? activeCartRedux.shippingMethods[0] : undefined;
    let cartItemsCount = getCartItemsCount(activeCartRedux || null);
    return {
      totalItems: cartItemsCount,
      subtotalAmount: activeCartRedux?.subtotalAmount,
      totalShippingAmount: activeCartRedux?.totalShippingAmount,
      totalDiscountAmount: activeCartRedux
        ? activeCartRedux?.totalProductDiscountAndGiftCodeAmount + activeCartRedux?.standardDiscountAmount
        : undefined,
      totalNetAmount: activeCartRedux?.totalNetAmount,
      totalTaxAmount: activeCartRedux?.totalTaxAmount,
      totalAmount: activeCartRedux?.totalAmount,
      estimatedPaymentFeeAmount: activeCartRedux?.estimatedPaymentFee,
      actualPaymentFeeAmount: undefined,
      totalMaterialFee: undefined,
      alertData: undefined,
      showTax: activeCartRedux?.wasTaxCalculated || false,
      showEstimatedPaymentFeeAmount: shouldShowEstimatedPaymentFee(activeCartRedux?.estimatedPaymentFee || 0),
      showActualPaymentFeeAmount: false,
      showShipping: !isNoShippingAmount(shippingMethod, activeCartRedux || undefined),
      showMaterialFee: false,
      currency: activeCartRedux?.cartItems[0].currency || '',
      totalHeaderAdjustmentAmount: activeCartRedux?.totalHeaderAdjustmentAmount,
      totalShippingDiscountAmount: activeCartRedux?.totalShippingDiscountAmount,
      quoteDiscounts: [],
      quoteSubtotal: undefined,
      quoteTotalAmount: undefined,
    };
  };

  const buildMetadataForOrderPending = (): ICambriaOrderMetaData => {
    return {
      orderHeaderData: getOrderHeaderData(),
      shipFromWarehouseData: getShipFromWarehouseData(),
      billingAddressCardData: billToCardInfo,
      paymentInfoCardData: paymentCardInfo,
      shippingAddressCardData: shipToCardInfo,
      jobOrderTypeData: getJobOrderTypeData(),
      purchasingCustomers: mappedPurchasingCustomers || [],
      orderSummaryData: getOrderSummaryData(),
      userMetadata: userInfoRedux,
      quoteSummaryData: undefined,
      lineItems: [],
      voucherCodes: [],
      rejections: [],
    };
  };

  const placeOrder = async (): Promise<any> => {
    dispatch(setIsSubmitting(true));
    dispatch(showFullscreenLoader({showCartIcon: false}));
    let orderNumber;
    try {
      if (activeCartRedux?.transactionType === 'Resubmit') {
        const orderMetadata: ICambriaOrderMetaData = buildMetadataForOrderPending();
        if (isFabricationOrder) {
          orderMetadata.rejections = await getOrderRejectionsRequest(activeCartRedux?.orderNumber);
        }
        orderNumber = await organizeResubmitOrder(
          activeCartRedux.id,
          activeCartRedux.orderNumber,
          userInfoRedux?.userId || '',
          currentCustomerRedux,
          orderMetadata,
          dispatch
        );
      } else if (activeCartRedux?.transactionType === 'OrderEdit') {
        orderNumber = await organizePlaceOrderParams(
          activeCartRedux.id,
          userInfoRedux?.userId || '',
          currentCustomerRedux,
          activeCartRedux.orderNumber
        );
      } else {
        if (isFabricationOrder) {
          const params = {
            externalId: activeCartRedux?.id,
            erpCustomerId: activeCartRedux?.erpCustomerId,
            groupName: 'Fabrication',
            limit: 1,
            sort: ['createdAt'],
          };
          const uploadedFiles = await getFilesRequest(params).then((res) => res.results);
          const dwgFiles = filterUserFiles(uploadedFiles, 'dwg');
          const areUploadedFiles = dwgFiles.length > 0;
          if (!areUploadedFiles) {
            toast.error(
              'This order is missing the DWG file. Please attach the DWG file in the order details to complete this order.'
            );
            return;
          }
          dispatch(setFabricationInitialState());
        }
        const orderMetadata: ICambriaOrderMetaData = buildMetadataForOrderPending();
        orderNumber = await organizePlaceOrderParams(
          activeCartRedux?.id || '',
          userInfoRedux?.userId || '',
          currentCustomerRedux,
          dispatch,
          activeCartRedux?.orderNumber || '',
          orderMetadata
        );
      }
      dispatch(setActiveStep(null));
      history.push(`/checkout/orderConfirmation/${orderNumber}${isSlabOrder ? '/true' : ''}`);
      pushPurchaseEventToDataLayer(activeCartRedux, orderNumber);
      await initializeCart(
        'cdmpComponentResolve',
        currentCustomerRedux,
        parseInt(userInfoRedux?.userId || ''),
        availableProgramsRedux,
        activeProgramRedux,
        selectedWarehouse && selectedWarehouse?.warehouseCode ? selectedWarehouse?.warehouseCode : null,
        customerShipToSitesRedux || [],
        customerBillToSitesRedux || [],
        dispatch
      );
    } catch (e: any) {
      if (e.failureReason) {
        toast.error(e.failureReason);
      }
      throw Error(e);
    } finally {
      dispatch(setIsSubmitting(false));
      dispatch(hideFullscreenLoader());
    }
  };

  const getOrderHeaderData = (): IOrderHeaderData => {
    let orderDetails =
      activeCartRedux && activeCartRedux.orderDetails.length > 0 ? activeCartRedux.orderDetails[0] : undefined;
    let shippingMethod =
      activeCartRedux && activeCartRedux.shippingMethods.length > 0 ? activeCartRedux.shippingMethods[0] : undefined;

    let orderHeaderData: IOrderHeaderData = {
      customerName: currentCustomerRedux.name,
      dateOrdered: new Date(),
      purchaseOrderNumber: orderDetails?.purchaseOrderNumber,
      orderNumber: undefined,
      status: 'Order Review',
      statusCode: undefined,
      orderTypeDisplayName: undefined,
      orderUpdatedBy: undefined,
      orderCrmAccountName: orderDetails?.orderCrmAccountName,
      orderCreatedBy: userInfoRedux?.given_name,
      shippingInstructions: shippingMethod?.shippingInstructions,
      programName: activeCartRedux?.cartItems[0]?.programName,
      homeDepotOrderNumber: orderDetails?.homeDepotOrderNumber,
      homeDepotOrderNumbers: orderDetails?.homeDepotOrderNumbers || [],
      publicFacingDisplay: orderDetails ? orderDetails.publicFacingDisplay : undefined,
      orderPieceRework: orderDetails ? orderDetails.orderPieceRework : undefined,
      jobNumber:
        isFabricationOrder &&
        activeCartRedux?.purchasingCustomers &&
        activeCartRedux?.purchasingCustomers[0] &&
        activeCartRedux?.purchasingCustomers[0].endConsumers &&
        activeCartRedux?.purchasingCustomers[0].endConsumers[0]
          ? activeCartRedux?.purchasingCustomers[0].endConsumers[0].jobNumber
          : undefined,
      deliveryMethod: shippingMethod?.shippingOptionName,
      fabOrderRevisionDisplayValue: undefined,
      fabricationInstructions: orderDetails ? orderDetails.fabricationInstructions : undefined,
      crmQuoteNumber: activeCartRedux?.crmQuoteNumber ?? undefined,
      orderFavoriteName: '',
    };

    return orderHeaderData;
  };

  useEffect(() => {
    if (activeCartRedux && activeCartRedux.shippingMethods?.length > 0 && customerShipToSitesRedux && !shipToCardInfo) {
      let shippingMethod = activeCartRedux.shippingMethods[0];
      let shipToSite: Site | undefined = customerShipToSitesRedux.find(
        (shipToSite: Site) =>
          shipToSite.siteNumber === shippingMethod.shipToSiteId || shipToSite.id === shippingMethod.shipToSiteId
      );

      if (shipToSite) {
        let shipToData = organizeAddressInformation(shipToSite, false);
        const contactDetails = shipToContactResolved(activeCartRedux, customerShipToSitesRedux);

        shipToData.email =
          contactDetails && contactDetails.email && contactDetails.email.length ? contactDetails?.email[0] : '';
        shipToData.phone =
          contactDetails && contactDetails.phoneNumber && contactDetails.phoneNumber.length
            ? contactDetails.phoneNumber[0]
            : '';
        shipToData.contactName = `${contactDetails?.firstName || ''} ${contactDetails?.lastName || ''}`;
        setShipToCardInfo(shipToData);
      }
    }
  }, [activeCartRedux, customerShipToSitesRedux, shipToCardInfo, organizeAddressInformation]);

  useEffect(() => {
    const getCurrentWarehouse = async () => {
      if (!cartWarehouseInfo && activeCartRedux && currentCustomerRedux && customerShipToSitesRedux) {
        const currentShippingWarehouse = await getShippingWarehouse(
          activeCartRedux,
          currentCustomerRedux,
          customerShipToSitesRedux
        );
        setCartWarehouseInfo(() => currentShippingWarehouse);
      }
    };

    if (!selectedWarehouse) {
      getCurrentWarehouse();
    }
  }, [cartWarehouseInfo, activeCartRedux, currentCustomerRedux, customerShipToSitesRedux, selectedWarehouse]);

  useEffect(() => {
    if (activeCartRedux?.cartItems && activeCartRedux?.cartItems.length > 0) {
      const item = activeCartRedux.cartItems[0];

      if (item.productType === 'Fabricated' || item.productType === 'FabricatedService') {
        setIsFabricationOrder(true);
      }

      if (item.productType === 'Slab') {
        setIsSlabOrder(true);
      }
    }
  }, [activeCartRedux]);

  useEffect(() => {
    if (billToSiteRedux) {
      const billToCardData = organizeAddressInformation(billToSiteRedux, true);
      setBillToCardInfo(billToCardData);
    }
  }, [organizeAddressInformation, billToSiteRedux]);

  useEffect(() => {
    const organizePaymentCardInfo = () => {
      if (activeCartRedux && activeCartRedux.paymentMethods.length > 0) {
        const paymentMethod = activeCartRedux.paymentMethods[0];
        const paymentInfo: IPaymentInfoCardData = {
          paymentType: paymentMethod.paymentTypeCode,
          paymentTerms: paymentMethod.paymentTermCode,
          totalAmount: activeCartRedux.totalNetAmount + calculateShippingAndHandling(),
          currencyCode: activeCartRedux.cartItems[0].currency || 'USD',
        };
        setPaymentCardInfo(paymentInfo);
      }
    };
    if (activeCartRedux?.paymentMethods && currentCustomerRedux) {
      organizePaymentCardInfo();
    }
  }, [activeCartRedux, currentCustomerRedux, calculateShippingAndHandling]);

  useEffect(() => {
    if (!endConsumersRedux && !purchasingCustomersRedux && activeCartRedux) {
      dispatch(
        getPurchasingCustomersAsync({
          cartId: activeCartRedux.id,
          erpCustomerId: currentCustomerRedux.erpCustomerId,
        })
      );
      dispatch(
        getEndConsumersAsync({
          erpCustomerId: currentCustomerRedux.erpCustomerId,
          cartId: activeCartRedux.id,
        })
      );
    }
  }, [dispatch, currentCustomerRedux, endConsumersRedux, purchasingCustomersRedux, activeCartRedux]);

  useEffect(() => {
    if (activeCartRedux) {
      getAlertDataFromCart(activeCartRedux);
    }
  }, [activeCartRedux, getAlertDataFromCart]);

  return (
    <ReviewAndPlaceOrderContainer>
      <Row className="mt-0">
        <Col>
          {activeCartRedux && currentCustomerRedux && (
            <ReviewHeader
              customer={currentCustomerRedux}
              cart={activeCartRedux}
              programName={activeProgramRedux.name || ''}
            />
          )}
        </Col>
      </Row>
      <Row className="d-flex justify-content-end mt-4">
        <Col xs={12} sm={12} md={3} lg={3}>
          <span className="d-flex justify-content-end align-items-center">
            <em className="fa fa-industry mr-3"></em>
            <span className="active-warehouse-name">{cartWarehouseInfo?.name ?? selectedWarehouse?.displayName}</span>
          </span>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col xs={12} sm={12} md={6} lg={4}>
          {billToCardInfo && <AddressCard addressCardInfo={billToCardInfo} />}
        </Col>
        <Col xs={12} sm={12} md={6} lg={4} className={`${width < 760 ? 'mt-4' : ''}`}>
          {paymentCardInfo && <PaymentInfoCard voucherCodes={[]} paymentInfoCardData={paymentCardInfo} />}
        </Col>
        <Col xs={12} sm={12} md={6} lg={4} className={`${width < 760 ? 'mt-4' : ''}`}>
          {shipToCardInfo && <AddressCard addressCardInfo={shipToCardInfo} />}
        </Col>
      </Row>
      {isSlabOrder && (
        <Row className="mt-4">
          <Col>
            <JobOrderTypeForm
              isFabricatedOrder={isFabricationOrder}
              isEditDisabled={true}
              orderDetailsInitialValues={
                activeCartRedux && activeCartRedux?.orderDetails.length > 0
                  ? activeCartRedux.orderDetails[0]
                  : undefined
              }
            />
          </Col>
        </Row>
      )}
      {activeCartRedux && activeCartRedux.purchasingCustomers?.length > 0 && (
        <Row>
          <Col xs={12}>
            <DealerEndUser orderDetails={NewOrderDetails} mappedPurchasingCustomers={mappedPurchasingCustomers} />
          </Col>
        </Row>
      )}
      <Row>
        <Col>
          {isFabricationOrder ? (
            <>{activeCartRedux && <FabricationCart cart={activeCartRedux} shouldUseOrderMetadata={false} />}</>
          ) : (
            <ProductTable
              orderLineItems={configureCartItemsForProductTable(activeCartRedux) || []}
              forReviewAndPlaceOrder
            />
          )}
        </Col>
      </Row>
      {activeCartRedux?.voucherCodes && activeCartRedux.voucherCodes.length > 0 && (
        <Row className="p-x-0 m-a-0 mt-5">
          <Discount />
        </Row>
      )}
      {activeCartRedux && activeCartRedux.cartItems && activeCartRedux.cartItems.length > 0 && (
        <Row className={`d-flex justify-content-end ${width < 960 ? 'mt-0' : ''}`}>
          <Col xs={12} md={6} lg={4}>
            <OrderSummaryComponent
              alertData={alertData}
              numberOfLineItems={activeCartRedux.cartItems.length}
              totalAmount={activeCartRedux.totalNetAmount + calculateShippingAndHandling()}
              totalBeforeTaxAndShipping={activeCartRedux.totalNetAmount}
              shippingAndHandling={calculateShippingAndHandling()}
              voucherCodes={!uiSettings?.calculateCartDiscountsInBackend ? activeCartRedux.voucherCodes : []}
              orderSubtotal={activeCartRedux.subtotalAmount}
              headerAdjustmentTotal={activeCartRedux.totalHeaderAdjustmentAmount}
              currencyCode={activeCartRedux.cartItems[0].currency}
              pricingItemsTotalDiscount={
                uiSettings?.calculateCartDiscountsInBackend ? activeCartRedux.totalPricingItemsDiscounts : 0
              }
              voucherCodesTotalDiscount={
                uiSettings?.calculateCartDiscountsInBackend ? activeCartRedux.totalProductDiscountAndGiftCodeAmount : 0
              }
            />
          </Col>
        </Row>
      )}
      <form
        id="04"
        name="04"
        onSubmit={(e: FormEvent) => {
          e.preventDefault();
          placeOrder();
        }}></form>
    </ReviewAndPlaceOrderContainer>
  );
};

export default ReviewAndPlaceOrder;
