import {useAppDispatch} from './store';
import {logout} from '../features/auth/slice/authentication.slice';
import {removeActiveCart} from '../features/cart/slice/cart.slice';
import {
  removeCurrentCustomer,
  removeCurrentCustomerBillToSites,
  removeCurrentCustomerShipToSites,
} from '../features/customer/slice/customer.slice';
import {removeUiSettings} from '../features/environment/slice/environment.slice';
import {
  removeActiveProgram,
  removeAvailablePrograms,
  removeCurrentCustomerSfAccount,
} from '../features/salesforce/slice/salesforce.slice';
import {removeAvailableWarehouses, removeSelectedWarehouse} from '../features/warehouse/slice/warehouse.slice';
import {setDefaultCustomer} from '../features/resolves/slice/resolves.slice';
import {useCallback} from 'react';

export const useLogout = () => {
  const dispatch = useAppDispatch();

  // The useEffect in customer validation resolves was being
  // called many times because in every re-render, useLogout
  // was returning a new instance of the function.
  //
  // The useCallback reduces the times that effects
  // that depends on the logout function returned by
  // useLogout are called, as the function instance is reused.
  return useCallback(() => {
    dispatch(logout());
    dispatch(removeCurrentCustomer());
    dispatch(removeCurrentCustomerBillToSites());
    dispatch(removeCurrentCustomerShipToSites());
    dispatch(removeUiSettings());
    dispatch(removeAvailablePrograms());
    dispatch(removeActiveProgram());
    dispatch(removeCurrentCustomerSfAccount());
    dispatch(removeActiveCart());
    dispatch(removeAvailableWarehouses());
    dispatch(removeSelectedWarehouse());
    dispatch(setDefaultCustomer(null));
  }, [dispatch]);
};
