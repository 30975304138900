import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import OrderConfirmation from './OrderConfirmation/OrderConfirmation.component';
import OrderDetails from './OrderDetails/OrderDetails.component';
import PaymentMethod from './PaymentMethod/PaymentMethod.component';
import ReviewAndPlaceOrder from './ReviewAndPlaceOrder/ReviewAndPlaceOrder.component';
import ShippingAndDelivery from './ShippingAndDelivery/ShippingAndDelivery.component';

const CheckoutRoutes = () => {
  return (
    <Switch>
      <Route path="/checkout/order-details" component={OrderDetails} />
      <Route path="/checkout/shipping-and-delivery" component={ShippingAndDelivery} />
      <Route path="/checkout/payment-method" component={PaymentMethod} />
      <Route path="/checkout/review-and-place-order" component={ReviewAndPlaceOrder} />
      <Route path="/checkout/orderConfirmation/:orderNumber?/:isSlab?" component={OrderConfirmation} />
      <Redirect to="/order-details" />
    </Switch>
  );
};

export default CheckoutRoutes;
