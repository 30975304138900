import {getCallbacksRequest, getRelatedStatusesById} from '../controller/callbacks.controller';
import qs from 'qs';

export const callback = async (id: string, timeoutSeconds: any, dispatch: any, callbackParams?: any) => {
  if ('undefined' === typeof timeoutSeconds) {
    timeoutSeconds = 10;
  }

  function defer() {
    let deferred: any = {};
    deferred.promise = new Promise(function (resolve, reject) {
      deferred.resolve = resolve;
      deferred.reject = reject;
    });
    return deferred;
  }

  let deferred = defer();
  dispatch({type: 'loading-bar/SHOW'});

  if (callbackParams && callbackParams.areMultipleCallbacks) {
    await handleMultipleIterations(id, timeoutSeconds, deferred, dispatch, callbackParams);
  } else {
    await handleIteration(id, timeoutSeconds, deferred, dispatch);
  }

  return deferred.promise;
};

export const handleIteration = async (id: string, timeoutSeconds: any, deferred: any, dispatch: any) => {
  try {
    let result = await getCallbacksRequest(id);
    result = await result.json();

    if (!result.status || result.status === 'Failure') {
      deferred.reject(result);
      dispatch({type: 'loading-bar/HIDE'});
    } else if (result.status && result.status === 'Success') {
      deferred.resolve(result.metadata);
      dispatch({type: 'loading-bar/HIDE'});
    } else if (timeoutSeconds <= 0) {
      deferred.reject({
        timeout: result,
      });
      dispatch({type: 'loading-bar/HIDE'});
    } else {
      setTimeout(async () => {
        dispatch({type: 'loading-bar/HIDE'});
        handleIteration(result.id, timeoutSeconds - 1, deferred, dispatch);
        dispatch({type: 'loading-bar/SHOW'});
      }, 1000);
    }
  } catch (error) {
    console.error(error);
    deferred.reject({
      serverError: true,
    });
  }
};

export const handleMultipleIterations = async (
  id: string,
  timeoutSeconds: any,
  deferred: any,
  dispatch: any,
  callbackParams?: any
) => {
  const params =
    callbackParams && callbackParams.type && callbackParams.externalId
      ? qs.stringify({
          type: callbackParams.type,
          externalId: callbackParams.externalId,
        })
      : undefined;
  try {
    let result = await getRelatedStatusesById(id, params);
    result = await result.json();

    if (
      (callbackParams && callbackParams.stopOnFailure && result.failureCount > 0) ||
      (result.inProgressCount === 0 && result.failureCount > 0)
    ) {
      deferred.reject(result);
      dispatch({type: 'loading-bar/HIDE'});
    } else if (result.inProgressCount === 0) {
      deferred.resolve(result);
      dispatch({type: 'loading-bar/HIDE'});
    } else if (timeoutSeconds <= 0) {
      deferred.reject({
        timeout: result,
      });
      dispatch({type: 'loading-bar/HIDE'});
    } else {
      setTimeout(() => {
        dispatch({type: 'loading-bar/HIDE'});
        callbackParams.type = result.type;
        callbackParams.externalId = result.externalId;
        handleMultipleIterations(id, timeoutSeconds - 1, deferred, dispatch, callbackParams);
        dispatch({type: 'loading-bar/SHOW'});
      }, 1000);
    }
  } catch {
    deferred.reject({
      serverError: true,
    });
  }
};
