import React, {useEffect, useState} from 'react';
import {Link, Redirect, Route, Switch, useLocation, useRouteMatch} from 'react-router-dom';
import NavContainer from '../../../../Core/Components/SearchPages/CustomerOrderNav/CustomerOrderNav.styled';
import {selectCurrentCustomer} from '../../../../features/customer/slice/customer.slice';
import {selectUserActions} from '../../../../features/permission/slice/permission.slice';
import useWindowDimensions from '../../../../hooks/getWindowDimensions';
import {useTypedSelector} from '../../../../hooks/store';
import {hasPermission} from '../../../../store/permission/permission.service';
import FabricationPreferences from './Fabrication/FabricationOrderPreferences.component';
import {OrderPreferencesNavigationOption} from './IOrderPreferences';
import SamplesPreferences from './Samples/SamplesOrderPreferences.component';
import SlabPreferences from './Slab/SlabOrderPreferences.component';
import OrderPreferencesContainer from './OrderPreferences.styled';

const OrderPreferenceNavigationOptions: OrderPreferencesNavigationOption[] = [
  {
    name: 'fabrication',
    urn: 'urn:csa:commerceui:fabricationOrderPreferences',
  },
  {
    name: 'slab',
    urn: 'urn:csa:commerceui:slabOrderPreferences',
  },
  {
    name: 'samples',
    urn: 'urn:csa:commerceui:sampleOrderPreferences',
  },
];

export const renderPageWithPermissions = (fab: boolean, slab: boolean, samples: boolean, url: string) => {
  if (fab) {
    return <Redirect to={`${url}/fabrication`} />;
  } else if (slab) {
    return <Redirect to={`${url}/slab`} />;
  } else if (samples) {
    return <Redirect to={`${url}/samples`} />;
  }
};

const OrderPreferences = () => {
  let {url} = useRouteMatch();
  let {pathname} = useLocation();
  let {width} = useWindowDimensions();

  let [erpCustomerId, setCustomerId] = useState<number>(-1);
  let [hasSlabPermission, setHasSlabPermission] = useState<boolean>(false);
  let [hasSamplePermission, setHasSamplePermission] = useState<boolean>(false);
  let [hasFabPermission, setHasFabPermission] = useState<boolean>(false);

  let customerId = useTypedSelector(selectCurrentCustomer)?.erpCustomerId;
  const userActions = useTypedSelector(selectUserActions);

  const filterActiveNavOptions = (options: OrderPreferencesNavigationOption[]) => {
    let listToShow: OrderPreferencesNavigationOption[] = [];
    options.forEach((navOption) => {
      if (hasPermission(navOption.urn, userActions)) {
        listToShow.push(navOption);
      }
    });
    return listToShow;
  };

  useEffect(() => {
    const checkUserPermissions = () => {
      if (userActions) {
        let slabPermissions = hasPermission('urn:csa:commerceui:slabOrderPreferences', userActions);
        let fabPermissions = hasPermission('urn:csa:commerceui:fabricationOrderPreferences', userActions);
        let samplePermissions = hasPermission('urn:csa:commerceui:sampleOrderPreferences', userActions);
        setHasSlabPermission(slabPermissions);
        setHasSamplePermission(samplePermissions);
        setHasFabPermission(fabPermissions);
      }
    };
    checkUserPermissions();
  }, [userActions]);

  useEffect(() => {
    if (customerId) {
      setCustomerId(customerId);
    }
  }, [customerId]);

  const RenderFilterOptions = ({
    options,
    url,
    pathname,
  }: {
    options: OrderPreferencesNavigationOption[];
    url: string;
    pathname: string;
  }) => {
    let activeOptions = filterActiveNavOptions(options);
    if (activeOptions.length === 1) {
      return <></>;
    } else {
      return (
        <NavContainer className="account-order-preferences-nav">
          {activeOptions.map((option) => {
            return (
              <Link
                key={option.name}
                className={`link ripple ${pathname.includes(option.name) ? 'active-link' : ''}`}
                to={`${url}/${option.name}`}>
                <span className="link-text">
                  {width < 990 ? option.name.toUpperCase() : `${option.name.toUpperCase()} ORDER PREFERENCES`}
                </span>
              </Link>
            );
          })}
        </NavContainer>
      );
    }
  };

  return (
    <OrderPreferencesContainer>
      <RenderFilterOptions options={OrderPreferenceNavigationOptions} url={url} pathname={pathname} />
      <Switch>
        <Route
          exact
          path={`${url}`}
          render={() => renderPageWithPermissions(hasFabPermission, hasSlabPermission, hasSamplePermission, url)}
        />
        <Route path={`${url}/fabrication`} render={() => <FabricationPreferences erpCustomerId={erpCustomerId} />} />
        <Route path={`${url}/slab`} render={() => <SlabPreferences erpCustomerId={erpCustomerId} />} />
        <Route path={`${url}/samples`} render={() => <SamplesPreferences erpCustomerId={erpCustomerId} />} />
      </Switch>
    </OrderPreferencesContainer>
  );
};

export default OrderPreferences;
