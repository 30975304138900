import React, {useEffect, useState} from 'react';
import {Form, Formik} from 'formik';
import * as Yup from 'yup';
import {Col, Container, Row} from 'react-bootstrap';
import {useHistory, useParams} from 'react-router';
import {selectCurrentCustomer} from '../../../../features/customer/slice/customer.slice';
import CambriaInput from '../../../../Framework/Components/CambriaInput';
import {useTypedSelector} from '../../../../hooks/store';
import {OrderConfirmationContainer} from './OrderConfirmation.styled';
import {toast} from 'react-toastify';
import {selectCurrentUser} from '../../../../features/auth/slice/authentication.slice';
import CambriaButton from '../../../../Framework/Components/CambriaButton';
import {selectIsInEditMode} from '../../../../features/order/slice/order.slice';
import {Link} from 'react-router-dom';
import {postOrderEmailRequest} from '../../../../features/order/controller/order.controller';
import COMMERCE_CORE_CONSTANTS from '../../../../Core/constants';
import {getQRCodeByCustomer} from '../../../../features/lastMile/controller/lastmile.controller';
import {selectUiSettings} from '../../../../features/environment/slice/environment.slice';

const OrderConfirmation = () => {
  let [history] = useState(useHistory());

  const customer = useTypedSelector(selectCurrentCustomer);
  const currentUser = useTypedSelector(selectCurrentUser);
  const isInEditMode = useTypedSelector(selectIsInEditMode);
  const uiSettings = useTypedSelector(selectUiSettings);

  let [param]: any = useState(useParams());
  let [isEmailButtonDisabled, setIsEmailButtonDisabled] = useState(false);
  let [confirmationEmail, setConfirmationEmail] = useState(currentUser && currentUser.email ? currentUser.email : '');
  let [qrCode, setQrCode] = useState('');

  const sendConfirmationEmail = async () => {
    setIsEmailButtonDisabled(true);
    try {
      const orderDetailsUrl = `${window.location.origin}/account/orders/details/${param.orderNumber}?erpCustomerId=${customer.erpCustomerId}`;
      await postOrderEmailRequest(
        param.orderNumber,
        confirmationEmail,
        customer.erpCustomerId.toString(),
        orderDetailsUrl
      );
      toast.success('Email sent');
    } catch (error) {
      toast.error('Encountered an error while sending an email.');
    }

    setIsEmailButtonDisabled(false);
  };

  useEffect(() => {
    const loadQrCode = async () => {
      const qrCode = await getQRCodeByCustomer(customer.erpCustomerId.toString());
      setQrCode(qrCode);
    };

    if (!qrCode && customer && customer.erpCustomerId && uiSettings?.enableDetrack && param.isSlab) {
      loadQrCode();
    }
  }, [customer, param, qrCode, uiSettings]);

  return (
    <OrderConfirmationContainer>
      <Container className="order-confirmation">
        {!isInEditMode ? (
          <h1 className="order-confirmation-header">Thank you, your order has been placed.</h1>
        ) : (
          <h1 className="order-confirmation-header">Thank you, your order has been updated.</h1>
        )}
        <Row className="step">
          <Col xs={12} className="order-confirmation-project-name-text">
            Order Number -
            <Link
              to={`/account/orders/details/${param.orderNumber}?erpCustomerId=${customer.erpCustomerId}`}
              className="sub-text p-x-0 order-confirmation-project-text-button">
              {param.orderNumber}
            </Link>
          </Col>

          <Col xs={12} className="p-x-0">
            <Formik
              initialValues={{confirmationEmail: confirmationEmail}}
              validationSchema={Yup.object({
                confirmationEmail: Yup.string().email('Invalid email'),
              })}
              onSubmit={() => {}}>
              {(props) => {
                return (
                  <Form id="confirmationEmailForm" noValidate>
                    <Row className="order-confirmation-email-form">
                      <Col xs={6}>
                        <CambriaInput
                          name="confirmationEmail"
                          className="order-confirmation-email-input"
                          placeholder="Enter Email Address"
                          type="text"
                          onChange={(value: any) => {
                            let email = value;
                            if (typeof value === 'object') {
                              email = value.target.value;
                            }
                            setConfirmationEmail(email);
                          }}
                        />
                      </Col>
                      <Col xs={6}>
                        <CambriaButton
                          type="submit"
                          className="btn btn-primary btn-type-a order-confirmation-email-button"
                          disabled={!props.isValid || isEmailButtonDisabled || !confirmationEmail}
                          onClick={() => {
                            sendConfirmationEmail();
                          }}>
                          Email
                        </CambriaButton>
                      </Col>
                    </Row>
                  </Form>
                );
              }}
            </Formik>
          </Col>
          <Col xs={12}>
            <span className="order-confirmation-continue-browsing-text">
              Continue to the
              <button
                type="button"
                className="btn btn-link sub-text p-x-0 order-confirmation-project-text-button"
                onClick={() => {
                  history.push(COMMERCE_CORE_CONSTANTS.PATHNAMES.home);
                }}>
                home page
              </button>
              to browse additional products.
            </span>
          </Col>
        </Row>
      </Container>
      {param.isSlab && uiSettings?.enableDetrack ? (
        <Col sm={12} md={6} lg={4} className="qr-code-container">
          <img className={'qr-code-img'} alt={'qrCode'} src={qrCode}></img>
          <br />
          <h2 className={'qr-code-text'}>Scan QR code for automated estimated delivery times on all orders.</h2>
        </Col>
      ) : (
        <Col sm={12} md={6} lg={4}></Col>
      )}
    </OrderConfirmationContainer>
  );
};

export default OrderConfirmation;
