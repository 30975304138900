import {Cart} from '../../../../../features/cart/ICartState';
import {IOrderHeaderData} from './OrderHeader/IOrderHeader';
import {IAddressCardData} from '../../../../../features/order/orderDetails/IOrderDetailsState';

export const jobTypes = [
  {
    displayName: 'Commercial-New Construction',
    code: 'Commercial-New',
  },
  {
    displayName: 'Commercial-Remodel',
    code: 'Commercial-Remodel',
  },
  {
    displayName: 'Residential-New Construction',
    code: 'Residential-New',
  },
  {
    displayName: 'Residential-Remodel',
    code: 'Residential-Remodel',
  },
  {
    displayName: 'Stock',
    code: 'Stock',
  },
  {
    displayName: 'Residential-Swap a Top',
    code: 'Residential-Swap a Top',
  },
];

export const fabricationJobTypes = [
  {
    displayName: 'Commercial-New Construction',
    code: 'Commercial-New',
  },
  {
    displayName: 'Commercial-Remodel',
    code: 'Commercial-Remodel',
  },
  {
    displayName: 'Commercial-Swap a Top',
    code: 'Commercial-Swap a Top',
  },
  {
    displayName: 'Residential-New Construction',
    code: 'Residential-New',
  },
  {
    displayName: 'Residential-Remodel',
    code: 'Residential-Remodel',
  },
  {
    displayName: 'Residential-Swap a Top',
    code: 'Residential-Swap a Top',
  },
];

export const orderTypeOptions = [
  {displayName: 'Add-on'},
  {displayName: 'New'},
  {displayName: 'Remake'},
  {displayName: 'Rework'},
];

export class LineItem {
  designName: string = '';
  description: string = '';
  quantity: number = 0;
  unitPrice: number = 0;
  linePrice: number = 0;
  adjustedUnitPrice: number = 0;
  adjustedExtendedNetPrice: number = 0;
  itemNumber: number = 0;
  widenLink: string = '';
  productType: string = '';
  requestedDelivery: Date | null = new Date();
  scheduleDelivery: Date | null = new Date();
  status: string = '';
  extendedQuantity: number = 0;
  productId: string = '';
  expectedOrderDate: Date | null = new Date();
  currency: string = '';
  discountAmount: number = 0;
  extendedNetPrice: number = 0;
  extendedBasePrice: number = 0;
  basePrice: number = 0;
  netPrice: number = 0;
  seamed?: boolean;
  hasAdjustedExtendedNetPrice?: boolean;
  hasAdjustedUnitPrice?: boolean;
}

export const initialAddressCard: IAddressCardData = {
  contactName: '',
  locationName: '',
  addressLine1: '',
  addressLine2: '',
  addressLine3: '',
  city: '',
  stateProvince: '',
  postalCode: '',
  country: '',
  email: '',
  phone: '',
  type: '',
  icon: '',
  status: '',
};

export const orderHeaderInitial = (): IOrderHeaderData => {
  return {
    customerName: '',
    status: '',
    statusCode: '',
    dateOrdered: '',
    purchaseOrderNumber: '',
    orderNumber: '',
    orderTypeDisplayName: '',
    orderUpdatedBy: '',
    orderCrmAccountName: '',
    orderCreatedBy: '',
    shippingInstructions: '',
    programName: '',
    homeDepotOrderNumber: '',
    orderPieceRework: false,
    publicFacingDisplay: false,
    jobNumber: '',
    deliveryMethod: '',
    fabOrderRevisionDisplayValue: '',
    fabricationInstructions: '',
    crmQuoteNumber: '',
    orderFavoriteName: '',
    homeDepotOrderNumbers: [],
  };
};

export const initialCart: Cart = {
  id: '',
  userId: '',
  erpCustomerId: '',
  customerClass: '',
  isActive: false,
  isLocked: false,
  wasTaxCalculated: false,
  wasActualPaymentCalculated: false,
  isReinitializing: false,
  selectedBy: [],
  orderNumber: '',
  transactionType: '',
  createdAt: null,
  updatedAt: null,
  subtotalAmount: 0,
  totalNetAmount: 0,
  totalAmount: 0,
  totalProductDiscountAndGiftCodeAmount: 0,
  totalHeaderAdjustments: 0,
  standardDiscountAmount: 0,
  giftCodeAmount: 0,
  discountCodeAmount: 0,
  totalTaxAmount: 0,
  totalShippingAmount: 0,
  totalShippingDiscountAmount: 0,
  estimatedPaymentFee: 0,
  actualPaymentFeeAmount: 0,
  cartItems: [],
  voucherCodes: [],
  shippingMethods: [],
  paymentMethods: [],
  taxCalculations: [],
  orderDetails: [],
  customerClassGroups: [],
  purchasingCustomers: [],
  headerAdjustments: [],
  totalHeaderAdjustmentAmount: 0,
  quoteDiscounts: [],
  quoteId: 0,
  totalQuoteDiscountAmount: 0,
  crmQuoteNumber: '',
  requestedDeliverySchedules: [],
  totalPricingItemsDiscounts: 0,
};

export interface DefaultPurchasingCustomerInformation {
  customerFirstName: string;
  customerLastName: string;
  address1: string;
  address2: string;
  address3: string;
  city: string;
  state: string;
  postalCode: string;
  countryCode: string;
  phoneNumber: string;
  email: string;
}
