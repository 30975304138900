import React from 'react';
import MobileOrderCardPlaceholderContainer from './MobileOrderCardPlaceholder.styled';
import ContentLoader from 'react-content-loader';

const MobileOrderCardPlacholder = () => {
  return (
    <MobileOrderCardPlaceholderContainer>
      <div className="mobile-card-body">
        <div className="row-1">
          <ContentLoader
            width={310}
            height={90}
            animate={false}
            foregroundColor={'#dfdfdf'}
            backgroundColor={'#dfdfdf'}>
            <rect x="0" y="0" width="90" height="90" />
            <rect x="120" y="0" width="150" height="8" />
            <rect x="120" y="20" width="120" height="8" />
            <rect x="120" y="40" width="150" height="8" />
            <rect x="120" y="80" width="100" height="8" />
          </ContentLoader>
        </div>
        <div className="row-2">
          <ContentLoader
            width={310}
            height={90}
            animate={false}
            foregroundColor={'#dfdfdf'}
            backgroundColor={'#dfdfdf'}>
            <rect x="0" y="5" width="200" height="8" />
            <rect x="0" y="25" width="175" height="8" />
            <rect x="0" y="45" width="210" height="8" />
            <rect x="0" y="65" width="190" height="8" />
          </ContentLoader>
        </div>
      </div>
      <div className="amount-details-footer">
        <div className="amount">
          <ContentLoader
            width={150}
            height={30}
            animate={false}
            foregroundColor={'#dfdfdf'}
            backgroundColor={'#dfdfdf'}>
            <rect x="20" y="10" width="100" height="8" />
          </ContentLoader>
        </div>
        <div className="details">
          <ContentLoader
            width={150}
            height={30}
            animate={false}
            foregroundColor={'#dfdfdf'}
            backgroundColor={'#dfdfdf'}>
            <rect x="20" y="10" width="100" height="8" />
          </ContentLoader>
        </div>
      </div>
    </MobileOrderCardPlaceholderContainer>
  );
};

const MobileOrderCardsGridPlacholder = () => {
  return (
    <div>
      <MobileOrderCardPlacholder />
      <MobileOrderCardPlacholder />
      <MobileOrderCardPlacholder />
    </div>
  );
};

export default MobileOrderCardsGridPlacholder;
