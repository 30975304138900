import qs from 'qs';
import React, {useEffect, useState} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {toast} from 'react-toastify';
import COMMERCE_CORE_CONSTANTS from '../../../../Core/constants';
import {
  setCustomerClassGroups,
  setDefaultWarehouseCode,
  setProductTypeWarehouseCodes,
  setProductTypeWarehouses,
} from '../../../../features/customer/currentCustomer/slice/currentCustomer.slice';
import {
  getCustomerBillToSitesAsync,
  getCustomerFullProfileAsync,
  getCustomerShipToSitesAsync,
  getPreferredShippingContactAsync,
  selectCurrentCustomer,
  selectCurrentCustomerBillToSite,
  selectCurrentCustomerBillToSites,
  selectCurrentCustomerShipToSites,
  selectPreferredShippingContact,
  selectSiteInfo,
  selectSiteInfoStatus,
  setCurrentCustomer,
  setCustomerBillToSite,
  setSiteInfo,
} from '../../../../features/customer/slice/customer.slice';
import {CustomerFullProfile, ProductTypeWarehouse, Site} from '../../../../features/customer/ICustomerState';
import {
  selectIsPostLoginStateActive,
  selectPostLoginState,
} from '../../../../features/postLoginState/slice/postLoginState.slice';
import {
  selectAllCustomerInformationLoaded,
  selectCustomerValidationStatus,
  selectDefaultCustomerStatus,
  selectUserHasAccessToCustomer,
  setAllCustomerInformationLoaded,
  setResolvesAreLoading,
  setUserHasAccessToCustomer,
} from '../../../../features/resolves/slice/resolves.slice';
import {baseApiCallWithReauth} from '../../../../Framework/api-utils/api-utils';
import {useAppDispatch, useTypedSelector} from '../../../../hooks/store';
import {selectActiveCart} from '../../../../features/cart/slice/cart.slice';

const CustomerResolves = ({children}: {children: any}) => {
  const history = useHistory();
  const activeUrl = useLocation();
  const dispatch = useAppDispatch();

  const currentCustomer: CustomerFullProfile | null = useTypedSelector(selectCurrentCustomer);
  const customerIsValid = useTypedSelector(selectCustomerValidationStatus);
  const siteInfo = useTypedSelector(selectSiteInfo);
  const siteInfoStatus = useTypedSelector(selectSiteInfoStatus);
  const allCustomerInformationLoaded = useTypedSelector(selectAllCustomerInformationLoaded);
  const customerBillToSite = useTypedSelector(selectCurrentCustomerBillToSite);
  const customerBillToSites = useTypedSelector(selectCurrentCustomerBillToSites);
  const customerShipToSites: Site[] | null = useTypedSelector(selectCurrentCustomerShipToSites);
  const defaultCustomerNotFound = useTypedSelector(selectDefaultCustomerStatus);
  const userHasAccessToCustomer = useTypedSelector(selectUserHasAccessToCustomer);
  const postLoginState = useTypedSelector(selectPostLoginState);
  const postLoginStateIsActive = useTypedSelector(selectIsPostLoginStateActive);
  const userCannotAccessCustomerToastId = 'no-access-toast';
  const activeCart = useTypedSelector(selectActiveCart);
  const preferredShippingContact = useTypedSelector(selectPreferredShippingContact);

  const [userSetFromUrl, setUserSetFromUrl] = useState<boolean>(false);
  const [warehouseRequestPending, setWarehouseRequestPending] = useState<boolean>(false);
  const [preferredShippingContactRequestPending, setPreferredShippingContactRequestPending] = useState<boolean>(false);

  const sessionStorageCustomerId = window.sessionStorage.getItem(
    COMMERCE_CORE_CONSTANTS.WINDOW_SESSION.selectedCustomerId
  );
  const sessionStorageErpCustomerId = window.sessionStorage.getItem(
    COMMERCE_CORE_CONSTANTS.WINDOW_SESSION.selectedErpCustomerId
  );

  // Check for customer id in session storage, store in Redux if found
  useEffect(() => {
    const loginCustomerFromUrl = async (erpCustomerId: any) => {
      let results = await baseApiCallWithReauth(
        'GET',
        COMMERCE_CORE_CONSTANTS.API_SERVICES.CUSTOMER.customers,
        qs.stringify({erpCustomerId: erpCustomerId}),
        true
      );
      const customerData = await results.json();
      if (customerData.results.length === 0 && userHasAccessToCustomer) {
        dispatch(setUserHasAccessToCustomer(false));
        toast.error("User does not have access to view that customer's order details", {
          toastId: userCannotAccessCustomerToastId,
        });
        history.push(COMMERCE_CORE_CONSTANTS.PATHNAMES.home);
      } else {
        if (!userSetFromUrl) {
          try {
            window.sessionStorage.setItem(
              COMMERCE_CORE_CONSTANTS.WINDOW_SESSION.selectedCustomerId,
              customerData.results[0].id
            );
            window.sessionStorage.setItem(COMMERCE_CORE_CONSTANTS.WINDOW_SESSION.selectedErpCustomerId, erpCustomerId);
            dispatch(setCurrentCustomer(customerData.results[0]));
          } catch (e) {
            console.log(e);
          } finally {
            setUserSetFromUrl(true);
          }
        }
      }
    };
    if (
      (window.location.href.includes(COMMERCE_CORE_CONSTANTS.PATHNAMES.baseAccount) ||
        window.location.href.includes(COMMERCE_CORE_CONSTANTS.PATHNAMES.pdfViewer)) &&
      window.location.href.includes('erpCustomerId')
    ) {
      if (
        window.location.href.includes('erpCustomerId') &&
        (window.location.href.includes(COMMERCE_CORE_CONSTANTS.PATHNAMES.accountOrderDetails) ||
          window.location.href.includes(COMMERCE_CORE_CONSTANTS.PATHNAMES.accountQuoteDetails) ||
          window.location.href.includes(COMMERCE_CORE_CONSTANTS.PATHNAMES.pdfViewer)) &&
        !userSetFromUrl
      ) {
        let searchQuery = window.location.href.split('?')[1];
        let parsedSearchQuery = qs.parse(searchQuery);
        let erpCustomerId = parsedSearchQuery.erpCustomerId;
        if (erpCustomerId) {
          loginCustomerFromUrl(erpCustomerId);
        }
      }
    } else if (!sessionStorageCustomerId) {
      if (
        postLoginState &&
        (postLoginState.includes(COMMERCE_CORE_CONSTANTS.PATHNAMES.accountOrderDetails) ||
          postLoginState.includes(COMMERCE_CORE_CONSTANTS.PATHNAMES.accountQuoteDetails) ||
          postLoginState.includes(COMMERCE_CORE_CONSTANTS.PATHNAMES.pdfViewer)) &&
        postLoginState.includes('erpCustomerId') &&
        !postLoginStateIsActive
      ) {
        let searchQuery = postLoginState.split('?')[1];
        let parsedSearchQuery = qs.parse(searchQuery);
        let erpCustomerId = parsedSearchQuery.erpCustomerId;

        if (erpCustomerId) {
          loginCustomerFromUrl(erpCustomerId);
          let postLoginStateCopy = postLoginState;
          if (postLoginState && postLoginState.includes('/#!/')) {
            postLoginStateCopy = postLoginStateCopy.replace('/#!/', COMMERCE_CORE_CONSTANTS.PATHNAMES.home);
          }
          window.location.assign(postLoginStateCopy);
        }
      }
      if (defaultCustomerNotFound) {
        if (
          !(
            activeUrl.pathname === COMMERCE_CORE_CONSTANTS.PATHNAMES.customerSearch ||
            activeUrl.pathname === COMMERCE_CORE_CONSTANTS.PATHNAMES.orderSearch
          )
        ) {
          history.push(COMMERCE_CORE_CONSTANTS.PATHNAMES.customerSearch);
        }
        dispatch(setResolvesAreLoading(false));
      }
    } else if (
      sessionStorageCustomerId &&
      (!currentCustomer.id || sessionStorageCustomerId !== currentCustomer.id.toString()) &&
      !userSetFromUrl
    ) {
      dispatch(getCustomerFullProfileAsync({customerId: sessionStorageCustomerId}));
      dispatch(setResolvesAreLoading(true));
    }
  }, [
    sessionStorageCustomerId,
    currentCustomer,
    dispatch,
    defaultCustomerNotFound,
    history,
    sessionStorageErpCustomerId,
    userHasAccessToCustomer,
    activeUrl,
    postLoginState,
    postLoginStateIsActive,
    allCustomerInformationLoaded,
    userSetFromUrl,
  ]);

  // Checks if all information has been loaded for customer
  useEffect(() => {
    if (
      !allCustomerInformationLoaded &&
      currentCustomer &&
      currentCustomer.id &&
      customerIsValid &&
      customerBillToSite &&
      customerBillToSites &&
      customerShipToSites &&
      customerShipToSites.length > 0
    ) {
      dispatch(setAllCustomerInformationLoaded(true));
    }
  }, [
    currentCustomer,
    customerBillToSite,
    customerBillToSites,
    customerShipToSites,
    allCustomerInformationLoaded,
    customerIsValid,
    dispatch,
  ]);

  // Customer Default Warehouse Code
  useEffect(() => {
    const getCustomerDefaultWarehouseCode = (siteInfo: Site[]): ProductTypeWarehouse[] | void => {
      let productTypeWarehouses: ProductTypeWarehouse[] = [];
      const primaryShipToSites = siteInfo
        .filter((r: any) => r.isPrimary === 'Y' && r.siteType === 'SHIP_TO')
        .sort((r: any) => r.warehouseCode - r.warehouseCode);
      if (primaryShipToSites.length > 0) {
        dispatch(setDefaultWarehouseCode(primaryShipToSites[0].warehouseCode));

        primaryShipToSites.forEach((siteInfo: Site) => {
          productTypeWarehouses.push({
            warehouseCode: siteInfo.warehouseCode,
            operatingUnitCode: siteInfo.operatingUnitCode,
          });
        });
        dispatch(setProductTypeWarehouses(productTypeWarehouses));
        return productTypeWarehouses;
      }
    };
    if (
      currentCustomer &&
      currentCustomer.id &&
      siteInfo &&
      siteInfo.length > 0 &&
      (!currentCustomer.productTypeWarehouses ||
        (currentCustomer.productTypeWarehouses && currentCustomer.productTypeWarehouses.length === 0)) &&
      customerIsValid
    ) {
      getCustomerDefaultWarehouseCode(siteInfo);
    }
  }, [currentCustomer, siteInfo, dispatch, customerIsValid]);

  // Customer Class Groups
  useEffect(() => {
    const setClassGroups = (currentCustomer: CustomerFullProfile) => {
      const data = new URLSearchParams();
      data.append('CustomerClassName', currentCustomer.class);
      dispatch(setCustomerClassGroups(data));
    };
    if (
      currentCustomer?.id &&
      currentCustomer?.class &&
      (currentCustomer.classGroups?.length === 0 || !currentCustomer.classGroups) &&
      customerIsValid
    ) {
      setClassGroups(currentCustomer);
    }
  }, [currentCustomer, dispatch, customerIsValid]);

  // Customer Warehouse Codes
  useEffect(() => {
    const setWarehouseCodes = async (currentCustomer: CustomerFullProfile) => {
      setWarehouseRequestPending(true);
      const data: URLSearchParams = new URLSearchParams();
      currentCustomer.productTypeWarehouses.forEach((warehouse: ProductTypeWarehouse, index: number) => {
        data.append(`sites[${index}].operatingUnitCode`, warehouse.operatingUnitCode!);
        data.append(`sites[${index}].warehouseCode`, warehouse.warehouseCode!);
      });
      try {
        await dispatch(setProductTypeWarehouseCodes(data));
      } finally {
        setWarehouseRequestPending(false);
      }
    };
    if (
      !warehouseRequestPending &&
      (!currentCustomer?.productTypeWarehouseCodes || currentCustomer?.productTypeWarehouseCodes.length <= 0) &&
      currentCustomer?.productTypeWarehouses &&
      currentCustomer?.productTypeWarehouses.length > 0 &&
      currentCustomer?.productTypeWarehouses[0].warehouseCode &&
      currentCustomer?.productTypeWarehouses[0].operatingUnitCode &&
      customerIsValid
    ) {
      setWarehouseCodes(currentCustomer);
    }
  }, [warehouseRequestPending, currentCustomer, dispatch, customerIsValid]);

  // Customer Ship To Sites
  useEffect(() => {
    if (customerIsValid && sessionStorageCustomerId && !customerShipToSites) {
      dispatch(getCustomerShipToSitesAsync({customerId: sessionStorageCustomerId}));
    }
  }, [dispatch, customerShipToSites, customerIsValid, sessionStorageCustomerId]);

  // Customer Bill To Sites
  useEffect(() => {
    if (customerIsValid && sessionStorageCustomerId && !customerBillToSites) {
      dispatch(getCustomerBillToSitesAsync({customerId: sessionStorageCustomerId}));
    }
  }, [dispatch, customerBillToSites, customerIsValid, sessionStorageCustomerId]);

  // Site Info for Customer Validation
  useEffect(() => {
    if (currentCustomer?.id && !siteInfo && siteInfoStatus !== 'loading') {
      dispatch(setSiteInfo({currentCustomer}));
    }
  }, [currentCustomer, dispatch, siteInfo, siteInfoStatus]);

  // Filter Customer BillToSites once Loaded
  useEffect(() => {
    if (activeCart && activeCart.cartItems?.length > 0 && customerBillToSites && !customerBillToSite) {
      const billToSiteFilteredByOu = customerBillToSites.find(
        (x) => x.operatingUnitCode === activeCart.cartItems[0].operatingUnitCode
      );
      billToSiteFilteredByOu && dispatch(setCustomerBillToSite(billToSiteFilteredByOu));
    }

    if (activeCart && activeCart.cartItems?.length === 0 && customerBillToSites && !customerBillToSite) {
      dispatch(setCustomerBillToSite(customerBillToSites[0]));
    }
  }, [activeCart, customerBillToSite, customerBillToSites, dispatch]);

  useEffect(() => {
    if (
      currentCustomer &&
      customerIsValid &&
      preferredShippingContact &&
      preferredShippingContact.length === 0 &&
      !preferredShippingContactRequestPending
    ) {
      dispatch(getPreferredShippingContactAsync({erpCustomerId: currentCustomer.erpCustomerId?.toString()}));
      setPreferredShippingContactRequestPending(true);
    } else if (!customerIsValid && preferredShippingContact.length === 0 && preferredShippingContactRequestPending) {
      setPreferredShippingContactRequestPending(false);
    }
  }, [currentCustomer, customerIsValid, preferredShippingContact, preferredShippingContactRequestPending, dispatch]);

  useEffect(() => {
    if (
      currentCustomer.id &&
      !currentCustomer.defaultWarehouseCode &&
      customerBillToSite?.warehouseCode &&
      customerIsValid
    ) {
      const customerCopy = {...currentCustomer};
      customerCopy.defaultWarehouseCode = customerBillToSite.warehouseCode;
      dispatch(setCurrentCustomer(customerCopy));
    }
  }, [currentCustomer, customerIsValid, customerBillToSite, dispatch]);

  return <div>{children}</div>;
};

export default CustomerResolves;
