import React, {FC, useEffect, useState} from 'react';
import {Link, useHistory, useLocation} from 'react-router-dom';
import {Formik} from 'formik';
import {NavigationContainer, NavigationItem, NavigationLink} from './Navigation.styled';
import useWindowDimensions from '../../../hooks/getWindowDimensions';
import CambriaSelect from '../CambriaSelect/CambriaSelect.component';

interface NavItem {
  href: string;
  name: string;
  urn: string | null;
  description?: string;
  code?: string;
}

interface NavigationProps {
  navItems: Array<NavItem>;
  asNavigation?: boolean;
  onTabChange?: Function;
}

const Navigation: FC<NavigationProps> = ({navItems, asNavigation, onTabChange}) => {
  const location = useLocation();
  const history = useHistory();
  const {width} = useWindowDimensions();

  const [activeTab, setActiveTab] = useState<NavItem | null>(null);

  const handleOnTabClick = (nav: NavItem) => {
    setActiveTab(nav);
    if (onTabChange) {
      onTabChange(nav);
    }
    if (!asNavigation || width < 960) {
      // navigation already handled by the `to` property
      history.push(nav.href);
    }
  };

  useEffect(() => {
    if (navItems.length > 0) {
      let currentTab;

      if (asNavigation) {
        currentTab = navItems.find((item) => location.pathname.includes(item.href));

        if (currentTab) {
          setActiveTab(currentTab);
        } else {
          setActiveTab(navItems[0]);
          history.push(navItems[0].href);
        }
      } else {
        currentTab = activeTab ? activeTab : navItems[0];
        if (!activeTab) {
          setActiveTab(navItems[0]);
        }
      }

      if (onTabChange) {
        onTabChange(currentTab);
      }
    }
  }, [location.pathname, navItems, onTabChange, history, asNavigation, activeTab]);

  return (
    <>
      {width >= 960 && (
        <div className="tabs-navigation">
          <NavigationContainer variant="tabs">
            {navItems.map((nav, i) => (
              <NavigationItem
                key={nav.code ? `${nav.code}${i}` : `${nav.name}${i}`}
                onClick={() => handleOnTabClick(nav)}
                className={navItems.length <= 2 ? 'item-center' : ''}>
                {asNavigation ? (
                  <NavigationLink as={Link} to={nav.href} className={`${activeTab?.href === nav.href ? 'active' : ''}`}>
                    {nav.name}
                  </NavigationLink>
                ) : (
                  <NavigationLink className={`${activeTab?.code === nav.code ? 'active' : ''}`}>
                    {nav.name}
                  </NavigationLink>
                )}
              </NavigationItem>
            ))}
          </NavigationContainer>
        </div>
      )}
      {width < 960 && (
        <Formik enableReinitialize initialValues={{accountNavigation: activeTab}} onSubmit={() => {}}>
          <CambriaSelect
            placeholder=""
            name="accountNavigation"
            items={navItems}
            displayValue="name"
            onChange={handleOnTabClick}
            defaultValue={activeTab}
          />
        </Formik>
      )}
    </>
  );
};

export default Navigation;
