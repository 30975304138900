import React, {useState} from 'react';
import {useHistory} from 'react-router-dom';
import {QuoteSearchParams} from '../../../../../../../features/search/ISearchState';
import {addQuoteStringInput} from '../../../../../../../features/quote/service/quote.service';
import {cleanSearchParams} from '../../../../../../../features/order/service/order.service';

const StringInput = ({
  urlSearchParams,
  activeFilter,
  url,
}: {
  urlSearchParams: QuoteSearchParams;
  activeFilter: string;
  url: string;
}) => {
  let history = useHistory();

  let [inputValue, setInputValue] = useState('');

  const addStringInputParam = (currentReduxFilters: QuoteSearchParams, paramName: string, inputValue: string) => {
    let currentReduxCopy = {...currentReduxFilters};
    currentReduxCopy = addQuoteStringInput(paramName, currentReduxCopy, inputValue);

    let data = new URLSearchParams(currentReduxCopy);
    data = cleanSearchParams(data);
    history.push({
      pathname: url,
      search: '?' + data.toString(),
    });
  };

  return (
    <div className="input-and-button-container">
      <div className="quote-search-bar">
        <input
          className="quote-input"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          placeholder={`Search Quotes by ${activeFilter}...`}
          onKeyDown={(e) =>
            e.key === 'Enter' && addStringInputParam(urlSearchParams, activeFilter, inputValue)
          }></input>
      </div>
      <div className="search-button-container">
        <button
          className="search-button btn-primary"
          onClick={() => addStringInputParam(urlSearchParams, activeFilter, inputValue)}>
          SEARCH
        </button>
      </div>
    </div>
  );
};
export default StringInput;
