import * as browserDetectionService from '../../../Framework/Services/browser-detection.service';
import qs from 'qs';
import {getUiSettingsRequest} from '../controller/environment.controller';

export const combineWithConsulItems = (consulFooterMenu: any) => {
  let tempConsulFooterMenu: any = JSON.parse(JSON.stringify(consulFooterMenu));
  let tempFooterLinksMenu: any = JSON.parse(JSON.stringify(footerLinksMenu));

  for (let block in tempFooterLinksMenu) {
    if (tempConsulFooterMenu[block]) {
      tempFooterLinksMenu[block] = [...tempFooterLinksMenu[block], ...tempConsulFooterMenu[block]];
    }
  }

  tempFooterLinksMenu.block2[1].menuContent.unshift({
    navigationItemLabel: 'Branded Merchandise',
    textStyle: 'uppercase',
    navigationItemUrl: '/product/store/',
    urn: 'urn:csa:commerceui:productCatalog:viewStore',
    disabled: false,
    openInNewTab: false,
  });

  return tempFooterLinksMenu;
};

export const checkIfIsMobile = () => {
  const isMobileBrowser = browserDetectionService.detectMobileBrowser();
  const clientWidth = browserDetectionService.getClientWidth();
  return isMobileBrowser && clientWidth < 768;
};

export const getFooterMenu = async () => {
  try {
    const results = await getUiSettingsRequest(qs.stringify({key: 'ecommerce'}));
    return combineWithConsulItems(results.footerMenu);
  } catch (error: any) {
    throw error;
  }
};

export const footerLinksMenu: any = {
  block1: [
    {
      menuLabel: 'Contact Us',
      textStyle: 'uppercase',
      isMenuOpen: checkIfIsMobile(),
      menuContent: [
        {
          navigationItemLabel: '1-866-CAMBRIA',
          textStyle: 'uppercase',
          navigationItemUrl: 'tel:18662262742',
          openInNewTab: true,
        },
        {
          navigationItemLabel: 'CustomerService@CambriaUSA.com',
          textStyle: '',
          navigationItemUrl: 'mailto:customerservice@cambriausa.com',
          openInNewTab: true,
        },
        {
          navigationItemLabel: 'CambriaUSA.com',
          textStyle: '',
          navigationItemUrl: 'https://www.cambriausa.com',
          openInNewTab: true,
        },
      ],
    },
  ],
  block2: [
    {
      menuLabel: 'Products',
      textStyle: 'uppercase',
      isMenuOpen: false,
      menuContent: [
        {
          navigationItemLabel: 'Slabs',
          textStyle: 'uppercase',
          navigationItemUrl: '/product/slabs/',
          urn: 'urn:csa:commerceui:productCatalog:viewSlabs',
          disabled: false,
          openInNewTab: false,
        },
        {
          navigationItemLabel: 'Samples',
          textStyle: 'uppercase',
          navigationItemUrl: '/product/samples/',
          urn: 'urn:csa:commerceui:productCatalog:viewSamples',
          disabled: false,
          openInNewTab: false,
        },
        {
          navigationItemLabel: 'Fabrication',
          textStyle: 'uppercase',
          navigationItemUrl: '/fabrication',
          urn: 'urn:csa:commerceui:productcatalog:viewfabrication',
          disabled: false,
          openInNewTab: false,
        },
        {
          navigationItemLabel: 'Tile',
          textStyle: 'uppercase',
          navigationItemUrl: '/product/tile/',
          urn: 'urn:csa:commerceui:productCatalog:viewTiles',
          disabled: false,
          openInNewTab: false,
        },
      ],
    },
  ],
  block3: [],
  block4: [],
  block5: [],
  block6: [
    {
      menuLabel: 'Logout',
      textStyle: 'uppercase',
      isMenuOpen: checkIfIsMobile(),
      mobileOnly: !checkIfIsMobile(),
      menuContent: [],
    },
  ],
};
