import React from 'react';
import useWindowDimensions from '../../../../../hooks/getWindowDimensions';
import DesktopPage from './desktop/QuoteTableDesktop.component';
import MobilePage from './mobile/QuoteTableMobile.component';

const ToggleMobile = ({
  width,
  quoteSuggestions,
  isLoading,
}: {
  width: number;
  quoteSuggestions: any[];
  isLoading: boolean;
}) => {
  if (width < 960) {
    return <MobilePage quoteSuggestions={quoteSuggestions} isLoading={isLoading} />;
  }
  return <DesktopPage quoteSuggestions={quoteSuggestions} isLoading={isLoading} />;
};

const QuoteTable = ({quoteSuggestions, isLoading}: {quoteSuggestions: any[]; isLoading: boolean}) => {
  const {width} = useWindowDimensions();

  return (
    <div className="quote-search-section container">
      <div className="quote-list-header">
        <h1 className="h2 light-weight clearfix">QUOTES</h1>
      </div>
      <ToggleMobile width={width} quoteSuggestions={quoteSuggestions} isLoading={isLoading} />
    </div>
  );
};
export default QuoteTable;
