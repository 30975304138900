import React, {FC} from 'react';
import CambriaSelect from '../../../../../../../Framework/Components/CambriaSelect';
import {useTypedSelector} from '../../../../../../../hooks/store';
import {selectUiSettings} from '../../../../../../../features/environment/slice/environment.slice';

interface FabricationPieceFinishSelectProps {
  selectedDesign?: string | null;
  onChange?: (finish: string) => void;
  defaultValue?: string | null;
}

interface Finish {
  name: string;
}

const finishOptions: Finish[] = [
  {name: 'Polished'},
  {name: 'Matte'},
  {name: 'Double Polished'},
  {name: 'Double Matte'},
];

const FabricationPieceFinishSelect: FC<FabricationPieceFinishSelectProps> = ({
  selectedDesign,
  onChange = () => {},
  defaultValue,
}) => {
  const uiSettings = useTypedSelector(selectUiSettings);

  const fabricationConfig = uiSettings?.fabricationOrderConfugurations;

  let availableFinishOptions = [...finishOptions];
  if (selectedDesign) {
    if (fabricationConfig?.designsOnlyInMatte?.includes(selectedDesign)) {
      availableFinishOptions = [{name: 'Matte'}];
    } else if (fabricationConfig?.designsOnlyInSatinRidge?.includes(selectedDesign)) {
      availableFinishOptions = [{name: 'Satin Ridge'}];
    } else {
      fabricationConfig?.finishes?.forEach((finish: any) => {
        for (const finishName in finish) {
          if (finish[finishName].applicableDesigns.includes(selectedDesign)) {
            availableFinishOptions.push({name: finishName});
          }
        }
      });
    }

    const allowedFinishes = fabricationConfig?.finishesByDesign?.find((f) => f.designs.includes(selectedDesign))
      ?.allowedFinishes;
    if (allowedFinishes) {
      availableFinishOptions = availableFinishOptions.filter((o) => allowedFinishes.includes(o.name));
    }
  }

  return (
    <CambriaSelect
      required
      name="pieceFinish"
      items={availableFinishOptions}
      defaultValue={defaultValue ?? ''}
      displayValue="name"
      label="FABRICATED PIECE FINISH"
      placeholder="Piece Finish"
      onChange={(item: Finish) => onChange(item.name)}
    />
  );
};

export default FabricationPieceFinishSelect;
