export const formatOrderCurrency = (num: number) => {
  if (num) {
    let numString = num.toString();
    if (numString.includes('-')) {
      numString = numString.replace('-', '');
    }
    let formattedNum = numString.substring(0, numString.length - 2) + '.' + numString.substring(numString.length - 2);
    formattedNum = parseFloat(formattedNum)
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, '$&,');
    return formattedNum;
  }
  return num;
};

export const formatQuoteCurrency = (num: number) => {
  if (num !== 0 && !num) {
    return '';
  }
  let numString = num.toString();
  let formattedNum = numString.substring(0, numString.length - 2) + '.' + numString.substring(numString.length - 2);
  formattedNum = parseFloat(formattedNum)
    .toFixed(2)
    .replace(/\d(?=(\d{3})+\.)/g, '$&,');
  return formattedNum;
};

export const formatOracleCurrency = (num: number) => {
  if (num) {
    let numString = num.toString();

    let formattedNum = '';
    let numArray = numString.split('.');

    if (numArray.length === 1) {
      numArray.push('.00');
    }

    if (numArray[1].length === 1) {
      numArray[1] = '.' + numArray[1] + '0';
    }

    if (numArray[1].length === 2) {
      numArray[1] = '.' + numArray[1];
    }

    formattedNum = numArray[0] + numArray[1];
    formattedNum = formattedNum.replace(/\d(?=(\d{3})+\.)/g, '$&,');

    return formattedNum;
  }
  return num;
};
