import React, {useCallback, useEffect, useState} from 'react';
import {Col, Form} from 'react-bootstrap';
import {toast} from 'react-toastify';
import {selectActiveCart} from '../../../../../../../features/cart/slice/cart.slice';
import {selectCurrentCustomer} from '../../../../../../../features/customer/slice/customer.slice';
import {getUploadedFilesAsync} from '../../../../../../../features/file/slice/file.slice';
import {CambriaSavedUploadFile, FabricationFile, IFileRequest} from '../../../../../../../features/file/IFileState';
import {selectFabricationFiles} from '../../../../../../../features/order/slice/order.slice';
import CambriaModal from '../../../../../../../Framework/Components/CambriaModal';
import Icon from '../../../../../../../Framework/Components/Icon';
import {useAppDispatch, useTypedSelector} from '../../../../../../../hooks/store';
import {CambriaFileUploadStyled} from './CambriaFileUpload.styled';
import {deleteFilesByIdRequest} from '../../../../../../../features/file/controller/file.controller';
import {determineFileIcon, downloadFabFile} from '../../../../../../../features/file/service/file.service';

const CambriaFileUpload = ({
  files,
  fabOrderPage,
}: {
  files: CambriaSavedUploadFile[] | FabricationFile[];
  fabOrderPage?: boolean;
}) => {
  const dispatch = useAppDispatch();

  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [showFabFiles, setShowFabFiles] = useState<boolean | null>(null);

  const fabFiles = useTypedSelector(selectFabricationFiles);
  const currentCustomer = useTypedSelector(selectCurrentCustomer);
  const activeCart = useTypedSelector(selectActiveCart);

  const checkIfAnyFilesShouldBeShown = useCallback((fabFiles: FabricationFile[]): boolean => {
    let shouldShowFileSection = false;
    fabFiles.forEach((file: FabricationFile) => {
      if (!file.isHidden) {
        shouldShowFileSection = true;
      }
    });
    return shouldShowFileSection;
  }, []);

  const toggleShowRemove = () => {
    setShowRemoveModal((x: boolean) => !x);
    if (!showRemoveModal) {
      document.body.classList.add('disable-scroll');
    } else {
      document.body.classList.remove('disable-scroll');
    }
  };
  const [selectedFabFile, setSelectedFabFile] = useState<CambriaSavedUploadFile | FabricationFile | null>(null);

  const loadUploadedFilesOnPageLoad = useCallback(() => {
    const fileParams: IFileRequest = {
      erpCustomerId: currentCustomer.erpCustomerId,
      externalId: activeCart ? activeCart.id : '',
      groupName: 'Fabrication',
      limit: 100,
      sort: 'createdAt',
    };
    dispatch(getUploadedFilesAsync({params: fileParams}));
  }, [currentCustomer, activeCart, dispatch]);

  const deleteFabFile = async (file: CambriaSavedUploadFile | FabricationFile) => {
    try {
      await deleteFilesByIdRequest(file);
      toast.success('File removed successfully');
      loadUploadedFilesOnPageLoad();
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (fabFiles && fabFiles.length > 0 && showFabFiles === null) {
      const showFiles = checkIfAnyFilesShouldBeShown(fabFiles);
      setShowFabFiles(showFiles);
    }
  }, [fabFiles, checkIfAnyFilesShouldBeShown, showFabFiles]);

  if (files.length > 0) {
    return (
      <div className="upload-file-container">
        {files.map((file, index) => {
          return (
            <CambriaFileUploadStyled key={index}>
              <Col xs={5}>
                <Icon className="mr-3" size="16" weight="600" icon={determineFileIcon(file)} />
                <span
                  data-testid={`download-file-link-${index}`}
                  className="file-name"
                  onClick={() => downloadFabFile(file)}>
                  {file.originalFileName}
                </span>
              </Col>
              <Col xs={5}>
                <span className="ml-5">{(file.fileSize / 1000000).toFixed(1)} MB</span>
              </Col>
              <Col xs={2} className="d-flex justify-content-end">
                {fabOrderPage && (
                  <span
                    className="remove-text"
                    onClick={() => {
                      toggleShowRemove();
                      setSelectedFabFile(file);
                    }}>
                    Remove
                  </span>
                )}
              </Col>
            </CambriaFileUploadStyled>
          );
        })}
        {selectedFabFile && (
          <CambriaModal
            show={showRemoveModal}
            toggleShow={toggleShowRemove}
            confirmButton="Yes"
            cancelButton="No"
            heading="Remove File"
            formName="delete-fab-file">
            <Form
              id="delete-fab-file"
              onSubmit={() => {
                deleteFabFile(selectedFabFile);
                setSelectedFabFile(null);
                document.body.classList.remove('disable-scroll');
              }}>
              Are you sure you want to remove {selectedFabFile.originalFileName}?
            </Form>
          </CambriaModal>
        )}
      </div>
    );
  } else if (files.length === 0 && fabFiles && fabFiles.length > 0 && showFabFiles) {
    return (
      <div className="upload-file-container">
        {fabFiles.map((file: FabricationFile, index) => {
          if (file.isHidden) {
            return <></>;
          } else {
            return (
              <CambriaFileUploadStyled key={index}>
                <div>
                  <Icon size="27" weight="600" icon={determineFileIcon(file)} />
                  <span className="file-name" onClick={() => downloadFabFile(file)}>
                    {file.originalFileName}
                  </span>
                </div>
                <div>{(file.fileSize / 1000000).toFixed(1)} MB</div>
              </CambriaFileUploadStyled>
            );
          }
        })}
      </div>
    );
  }
  return <></>;
};

export default CambriaFileUpload;
