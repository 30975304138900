import {baseApiCallWithReauth} from '../../../../Framework/api-utils/api-utils';
import COMMERCE_CORE_CONSTANTS from '../../../../Core/constants';
import qs from 'qs';

export const deleteOrdersRequest = async (orderNumber: string) => {
  try {
    return await baseApiCallWithReauth(
      'DELETE',
      `${COMMERCE_CORE_CONSTANTS.API_SERVICES.ORDER.orders}/${orderNumber}`,
      null,
      true
    );
  } catch (error: any) {
    throw error;
  }
};

export const getOrderDetailsRequest = async (cartId: string) => {
  try {
    return await baseApiCallWithReauth('GET', `${COMMERCE_CORE_CONSTANTS.API_SERVICES.ORDER.orderDetails}/${cartId}`);
  } catch (error: any) {
    throw error;
  }
};

/**
 * Gets the Order Details PDF from Order Service
 * @param orderId
 * @param erpCustomerId
 * @returns the PDF
 */
export const getOrderDetailsPdfRequest = async (
  orderId: string,
  erpCustomerId: string,
  orderCreatedBy?: string | null
): Promise<Blob> => {
  const response = await baseApiCallWithReauth(
    'GET',
    `${COMMERCE_CORE_CONSTANTS.API_SERVICES.ORDER.orders}/${orderId}/pdf`,
    qs.stringify({erpCustomerId, createdByName: orderCreatedBy}),
    true
  );
  if (!response.ok) throw response;
  return response.blob();
};
