import {baseApiCallWithReauth} from '../../../Framework/api-utils/api-utils';
import COMMERCE_CORE_CONSTANTS from '../../../Core/constants';

export const postCartTaxCalculationsRequest = async (params: any) => {
  try {
    return await baseApiCallWithReauth(
      'POST',
      COMMERCE_CORE_CONSTANTS.API_SERVICES.TAX.cartTaxCalculations,
      JSON.stringify(params),
      true
    );
  } catch (error: any) {
    throw error;
  }
};
