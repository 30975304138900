import React, {FC} from 'react';
import {CartBundleValidation} from '../../../../../../features/cart/ICartState';
import CambriaAlerts from '../../../../../../Framework/Components/CambriaAlerts';

export interface BundleValidationsAlertsProps {
  bundleValidation?: CartBundleValidation;
}

const BundleValidationsAlerts: FC<BundleValidationsAlertsProps> = ({bundleValidation}) => {
  return (
    <>
      {bundleValidation?.requiredSlab && (
        <CambriaAlerts
          type={'warning'}
          msg={
            <>
              <strong>Bundle {bundleValidation?.bundleId}</strong>
              <br />
              {`You must add ${bundleValidation?.requiredSlab?.quantity} slab${
                bundleValidation?.requiredSlab?.quantity > 1 ? 's' : ''
              } of `}
              <a href={bundleValidation?.requiredSlab?.linkToProduct}>
                {bundleValidation?.requiredSlab?.itemDescription}
              </a>{' '}
              to complete the bundle
            </>
          }
          setAlertData={() => {}}
          viewCart={false}
        />
      )}

      {bundleValidation?.slabsNeeded && bundleValidation?.slabsNeeded.length > 0 && (
        <CambriaAlerts
          type={'warning'}
          msg={
            <>
              <strong>Bundle {bundleValidation?.bundleId}</strong>
              <br />
              To finish your bundle, you must add:
              <ul>
                {bundleValidation.slabsNeeded
                  .filter((x) => x.requiredQty > 0)
                  .map((slabNeeded, idx, filteredArray) => (
                    <li key={slabNeeded.requiredQty.toString() + slabNeeded.size + slabNeeded.thickness}>
                      {slabNeeded.requiredQty} - {slabNeeded.size} sq.ft.{' '}
                      {slabNeeded.requiredDesigns?.length
                        ? slabNeeded.requiredDesigns.map((design, designIdx) => (
                            <>
                              <a href={design.linkToProduct}>{design.designName}</a>
                              {slabNeeded.requiredDesigns && designIdx !== slabNeeded.requiredDesigns.length - 1
                                ? ', ' + (designIdx === slabNeeded.requiredDesigns.length - 2 ? 'or ' : '')
                                : ''}
                            </>
                          ))
                        : ''}{' '}
                      slab{slabNeeded.requiredQty > 1 && 's'} of&nbsp;
                      {slabNeeded.thickness} thickness{idx < filteredArray.length - 1 && ', or'}
                    </li>
                  ))}
              </ul>
            </>
          }
          setAlertData={() => {}}
          viewCart={false}
        />
      )}
    </>
  );
};

export default BundleValidationsAlerts;
