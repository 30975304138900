export const statusOptions = [
  'Approved',
  'Expired',
  'Ordered',
  'Pending Approval',
  'Pending Extension Approval',
  'Rejected',
];

export const quoteSearchQuoteFilterOptions = [
  'Details',
  'Amount',
  'Date',
  'End User Address',
  'End User First Name',
  'End User Last Name',
  'Favorites',
  'Job Number',
  'Order Number',
  'Order Type',
  'Purchase Order Number',
  'Status',
];

export const quoteHistoryQuoteFilterOptions = ['Details', 'Amount', 'Date', 'Status'];

export const validUrlParams = [
  'q',
  'endConsumerAddress1',
  'status',
  'purchaseOrderNumber',
  'QuoteType',
  'QuoteNumber',
  'favoriteName',
  'isFavorite',
  'endConsumerLastName',
  'endConsumerFirstName',
  'jobNumber',
  'amountRange.amountFrom',
  'amountRange.amountTo',
  'dateRange.dateFrom',
  'dateRange.dateTo',
];
