import {toast} from 'react-toastify';
import COMMERCE_CORE_CONSTANTS from '../../../Core/constants';
import {User} from '../../../store/auth/auth.service';
import {getCustomerRetailSitesRequest, getEndConsumersRequest} from '../../customer/controller/customer.controller';
import {BillToSite, CustomerFullProfile, ShipToSite, Site} from '../../customer/ICustomerState';
import {NewSingleItemPriceResponse} from '../../pricing/IPricingState';
import {
  deleteProductCartVoucher,
  deleteProductHeaderAdjustment,
  getSingleItemPriceBatch,
  postCartVoucherCodes,
  postDiscounts,
} from '../../pricing/service/pricing.service';
import {
  calculateTileSquareFootage,
  getItemAvailability,
  getProductsInCart,
  getSavedItems,
  productAddBatchCartItems,
  productAddCartItem,
  removeCartItem,
} from '../../productCatalog/service/productCatalog.service';
import {getCartItemsProductType, isFabricatedCart, isSurfacesCart} from '../../productType/service/productType.service';
import {ISalesforceAvailablePrograms} from '../../salesforce/ISalesforceState';
import {Program} from '../../salesforce/program/IProgramState';
import {setSelectedProgramByCartItems} from '../../salesforce/program/service/program.service';
import {
  getItemWarehouseCode,
  getOperatingUnitCodeByProductType,
  getUserSelectedWarehouse,
} from '../../warehouse/service/warehouse.service';
import {
  deleteCartsByIdRequest,
  getCartsIncludeInactiveRequest,
  getCartsRequest,
  postCartResubmissionOrReorder,
  postCarts,
  putCartsRequest,
  getMultipleCartsRequest,
  CreateCartRequest,
} from '../controller/cart.controller';
import {
  Cart,
  CartInitializeReason,
  CartItem,
  EndConsumer,
  GetCartRequest,
  NewGetCartRequest,
  NewGetCartResults,
  PurchasingCustomer,
  VoucherCode,
} from '../ICartState';
import {
  addingCartItem,
  addSavedItems,
  applyDiscount,
  removeDiscount,
  removeHeaderAdjustment,
  setActiveCart,
  setCartDiscounts,
  setSavedItems,
  updateCartItem,
} from '../slice/cart.slice';
import {callback} from '../../callbacks/service/callbacks.service';
import {setCheckoutInitialState} from '../../checkout/slice/checkout.slice';
import {getCallbackWithParams} from '../../callbacks/controller/callbacks.controller';
import {
  postSavedItemsRequest,
  deleteSavedItemsByNumberRequest,
} from '../../productCatalog/controller/productCatalog.controller';
import {ProductType} from '../../order/orderDetails/IOrderDetailsState';
import {getCartItem, getCartItemByItemNumber} from '../cartItem/service/cartItem.service';
import {isBundleProgram} from '../../salesforce/service/salesforce.service';

export const reorder = async (
  orderNumber: string,
  lastCartId: string,
  currentCartId: string,
  transactionType: string,
  customer: CustomerFullProfile,
  userId: number,
  availablePrograms: ISalesforceAvailablePrograms | null,
  activeProgram: Program,
  selectedWarehouseCode: string | null,
  customerShipToSites: Site[],
  customerBillToSites: Site[],
  operatingUnitCode: string,
  dispatch: any
) => {
  const callbackMetadata = await createUpdateOrderCartReorder(
    orderNumber,
    lastCartId,
    currentCartId,
    transactionType,
    COMMERCE_CORE_CONSTANTS.API_SERVICES.CART.reorder,
    customer,
    operatingUnitCode,
    dispatch
  );
  if (callbackMetadata) {
    if (callbackMetadata.ErrorMessage) {
      toast.error(callbackMetadata.ErrorMessage);
    } else if (callbackMetadata.WarningMessage) {
      toast.warning(callbackMetadata.WarningMessage);
    }
  }
  return await initializeCart(
    'reorder',
    customer,
    userId,
    availablePrograms,
    activeProgram,
    selectedWarehouseCode,
    customerShipToSites,
    customerBillToSites,
    dispatch
  );
};

export const resubmitOrder = async (
  orderNumber: string,
  cartId: string,
  customer: CustomerFullProfile,
  userId: number,
  availablePrograms: ISalesforceAvailablePrograms | null,
  activeProgram: Program,
  selectedWarehouseCode: string | null,
  shipToSites: Site[],
  billToSites: Site[],
  dispatch: any
): Promise<Cart> => {
  await createUpdateOrderCartEditResubmit(
    orderNumber,
    cartId,
    COMMERCE_CORE_CONSTANTS.API_SERVICES.CART.resubmission,
    dispatch
  );
  return await initializeCart(
    'resubmitOrder',
    customer,
    userId,
    availablePrograms,
    activeProgram,
    selectedWarehouseCode,
    shipToSites,
    billToSites,
    dispatch
  );
};

export const createUpdateOrderCartReorder = async (
  orderNumber: string,
  lastCartId: string,
  currentCartId: string,
  transactionType: string,
  endpoint: string,
  customer: CustomerFullProfile,
  operatingUnitCode: string,
  dispatch: any
): Promise<any> => {
  const params = {
    orderNumber: orderNumber,
    lastCartId: lastCartId,
    currentCartId: currentCartId,
    transactionType: transactionType,
    customer: {
      erpCustomerId: customer.erpCustomerId,
      customerClass: customer.class,
      customerClassGroups: customer.classGroups,
      slabWarehouseCode: customer.productTypeWarehouseCodes.find(
        (pt) => pt.productType === 'Slab' && pt.operatingUnitCode === operatingUnitCode
      )?.warehouseCode,
      tileWarehouseCode: customer.productTypeWarehouseCodes.find(
        (pt) => pt.productType === 'Tile' && pt.operatingUnitCode === operatingUnitCode
      )?.warehouseCode,
      samplesWarehouseCode: customer.productTypeWarehouseCodes.find(
        (pt) => pt.productType === 'Samples' && pt.operatingUnitCode === operatingUnitCode
      )?.warehouseCode,
      storeWarehouseCode: customer.productTypeWarehouseCodes.find(
        (pt) => pt.productType === 'Store' && pt.operatingUnitCode === operatingUnitCode
      )?.warehouseCode,
    },
  };

  return await createUpdateOrderCart(params, endpoint, dispatch);
};

export const getCartItemsCount = (cart: Cart | null): number => {
  let cartItemsQuantity = 0;
  if (!cart) {
    return cartItemsQuantity;
  }

  if (cart && cart.cartItems && cart.cartItems.length > 0) {
    cart.cartItems.map((i: any) => (cartItemsQuantity += i.tempQuantity ? i.tempQuantity : i.quantity));
  }
  return cartItemsQuantity;
};

export const initializeCartItems = async (
  cart: Cart,
  customer: CustomerFullProfile,
  userId: string,
  activeProgram: Program,
  shipToSites: Site[],
  billToSites: Site[],
  dispatch: any,
  availablePrograms: ISalesforceAvailablePrograms | null,
  calculateDiscountsInBackend: boolean = false
): Promise<void> => {
  const products: any = await getProductsInCart(cart.id, customer, shipToSites);
  for (let index = cart.cartItems.length - 1; index >= 0; index--) {
    const cartItem = cart.cartItems[index];
    const product = products.activeProducts.find((p: any) => p.id === cartItem.productId);
    if (!product && cart.isActive) {
      try {
        await cartServiceRemoveCartItem(
          cartItem,
          cart,
          customer,
          userId,
          activeProgram,
          shipToSites,
          billToSites,
          dispatch,
          availablePrograms
        );
        toast.error('There was an issue adding this item to your cart, please contact Customer Care for assistance.');
      } catch (error: any) {
        if (error.includes('Your cart is locked into quote')) {
          toast.error(
            `${cartItem.description} is not an active item at this time, we are unable to proceed with checkout due to this issue. Please contact the Commercial Nerve Center at 877-7QUARTZ (877-778-2789) for assistance.`
          );
          await removeCart(
            cart.id,
            null,
            undefined,
            customer,
            userId,
            activeProgram,
            shipToSites,
            billToSites,
            dispatch,
            availablePrograms
          );
          window.location.assign(`/account/quotes/details/${cart.quoteId}?erpCustomerId=${customer.erpCustomerId}`);
        }
      }
    } else {
      cart.cartItems[index] = getCartItem(cartItem, product, calculateDiscountsInBackend);
    }
  }
  const {isProgramAvailable, isProgramChanged} = await setSelectedProgramByCartItems(
    cart.cartItems,
    availablePrograms,
    activeProgram,
    dispatch
  );

  if (isProgramAvailable && isProgramChanged) {
    await removeCart(
      cart.id,
      null,
      undefined,
      customer,
      userId,
      activeProgram,
      shipToSites,
      billToSites,
      dispatch,
      availablePrograms
    );
  }
};

export const cartServiceRemoveCartItem = async (
  cartItem: CartItem,
  cart: Cart,
  customer: CustomerFullProfile,
  userId: string,
  activeProgram: Program,
  shipToSites: Site[],
  billToSites: Site[],
  dispatch: any,
  availablePrograms: ISalesforceAvailablePrograms | null,
  calculateDiscountsInBackend = false
): Promise<any> => {
  const isItemInTheCart = cart.cartItems.some(
    (x) => x.itemNumber === cartItem.itemNumber && cartItem.cartItemId === x.cartItemId
  );

  if (!isItemInTheCart) {
    return Promise.reject('Item does not exist on cart.');
  }

  try {
    await removeCartItem(cartItem, cart.id, dispatch);
    const currentCart = await getCart(
      cart.id,
      customer,
      userId,
      activeProgram,
      shipToSites,
      billToSites,
      dispatch,
      availablePrograms,
      false,
      true,
      true,
      calculateDiscountsInBackend
    );
    dispatch(setActiveCart(currentCart));
    return currentCart;
  } catch (error: any) {
    let callbackError = '';
    if (error && error.text) {
      callbackError = await error.text();
    }
    if (error.status === 400 && callbackError.includes('Your cart is locked into quote')) {
      throw callbackError;
    }
    console.error(error);
    throw error;
  }
};

export const getPurchasingCustomersFromCustomerService = async (
  cartId: string,
  erpCustomerId: string
): Promise<PurchasingCustomer[]> => {
  const params = new URLSearchParams();
  params.append('cartId', cartId);
  params.append('erpCustomerId', erpCustomerId);

  const purchasingCustomerResults = await getCustomerRetailSitesRequest(params);
  const purchasingCustomers = purchasingCustomerResults.results;

  await Promise.all(
    purchasingCustomers.map(async (purchasingCustomer: any) => {
      const endConsumers = await cartServiceGetEndConsumers(cartId, purchasingCustomer.id, erpCustomerId);
      purchasingCustomer = {...purchasingCustomer, endConsumers};
    })
  );

  return purchasingCustomers;
};

export const cartServiceGetEndConsumers = async (
  cartId: string,
  purchasingCustomerId: number,
  erpCustomerId: string | null = null
): Promise<EndConsumer[]> => {
  return await getEndConsumersRequest({
    cartId,
    erpCustomerId,
    retailSiteId: purchasingCustomerId,
  });
};

export const calculateStandartDiscounts = async (
  cart: Cart,
  customer: CustomerFullProfile,
  userId: string,
  activeProgram: Program,
  shipToSites: Site[],
  billToSites: Site[],
  dispatch: any,
  availablePrograms: ISalesforceAvailablePrograms | null,
  calculateDiscountsInBackend = false
) => {
  const cartItems = cart.cartItems;
  const productType = getCartItemsProductType(cart);
  if (cartItems.length === 0 || !productType) {
    return;
  }

  try {
    await postDiscounts(cart.id, customer, dispatch);
    const updatedCart = await getCart(
      cart.id,
      customer,
      userId,
      activeProgram,
      shipToSites,
      billToSites,
      dispatch,
      availablePrograms,
      false,
      true,
      true,
      calculateDiscountsInBackend
    );
    dispatch(setCartDiscounts(updatedCart));
  } catch (error) {
    console.error(error);
  }
};

export const applyDiscountsToItems = (cart: Cart, dispatch: any): void => {
  const isFabOrder = isFabricatedCart(cart);
  const isSurfacesOrder = isSurfacesCart(cart);
  const cartItems = cart.cartItems;
  const voucherCodes = cart.voucherCodes;
  cartItems.forEach((cartItem) => {
    cartItem.adjustedExtendedNetPrice = 0;
    cartItem.hasAdjustedExtendedNetPrice = false;
    cartItem.adjustedUnitPrice = 0;
    cartItem.hasAdjustedUnitPrice = false;
    cartItem.hasAdjusteditemQuantityPrice = false;
    cartItem.adjusteditemQuantityPrice = 0;
  });
  if (voucherCodes.length === 0) {
    return;
  }
  voucherCodes.forEach((voucher) => {
    const matchedCartItem = cartItems.find(
      (i) => i.itemNumber === voucher.itemNumber && (!i.cartItemId || i.cartItemId.toString() === voucher.cartItemId)
    );
    if (matchedCartItem) {
      if (isFabOrder || isSurfacesOrder) {
        matchedCartItem.adjusteditemQuantityPrice = matchedCartItem.adjusteditemQuantityPrice
          ? matchedCartItem.adjusteditemQuantityPrice - voucher.adjustmentAmount
          : matchedCartItem.itemQuantityPrice - voucher.adjustmentAmount;
        matchedCartItem.adjustedUnitPrice = Math.round(
          matchedCartItem.adjusteditemQuantityPrice / matchedCartItem.uomLineQuantity
        );
        matchedCartItem.hasAdjustedUnitPrice = true;
        matchedCartItem.hasAdjusteditemQuantityPrice = true;
      } else {
        if (matchedCartItem.adjustedExtendedNetPrice) {
          matchedCartItem.adjustedExtendedNetPrice -= voucher.adjustmentAmount;
          matchedCartItem.discountAmount += voucher.adjustmentAmount;
        } else {
          matchedCartItem.adjustedExtendedNetPrice = matchedCartItem.extendedNetPrice - voucher.adjustmentAmount;
          matchedCartItem.discountAmount = voucher.adjustmentAmount;
        }

        if (!matchedCartItem.adjustedUnitPrice) {
          matchedCartItem.adjustedUnitPrice = matchedCartItem.unitPrice;
        }
        if (matchedCartItem.unitSize && matchedCartItem.productType === 'Slab' && matchedCartItem.quantity) {
          matchedCartItem.adjustedUnitPrice -=
            voucher.adjustmentAmount / matchedCartItem.quantity / matchedCartItem.unitSize;
        } else if (matchedCartItem.unitSize && matchedCartItem.productType === 'Tile' && matchedCartItem.quantity) {
          matchedCartItem.adjustedUnitPrice -=
            voucher.adjustmentAmount /
            (calculateTileSquareFootage(matchedCartItem.unitSize, matchedCartItem.quantity) || 0);
        } else {
          matchedCartItem.adjustedUnitPrice -=
            voucher.adjustmentAmount / (matchedCartItem.quantity ? matchedCartItem.quantity : 1);
        }

        matchedCartItem.hasAdjustedExtendedNetPrice = true;
        matchedCartItem.hasAdjustedUnitPrice = true;
      }
    }
  });

  dispatch(updateCartItem(cart));
};

export const setDiscountsData = (cart: Cart): VoucherCode[] => {
  const discounts: VoucherCode[] = [];
  if (cart.voucherCodes && cart.voucherCodes.length > 0) {
    let copyOfVoucherCodes: VoucherCode[] = JSON.parse(JSON.stringify(cart.voucherCodes));
    copyOfVoucherCodes.forEach((voucher) => {
      const matchedVoucher = discounts.find((d) => d.validationId === voucher.validationId);
      if (matchedVoucher) {
        matchedVoucher.adjustmentAmount += voucher.adjustmentAmount;
      } else {
        voucher.removable = true;
        if (voucher.category === 'StandardDiscount') {
          voucher.removable = false;
        }
        discounts.push(voucher);
      }
    });
  }

  return discounts;
};

export const addCartVoucher = async (
  cart: Cart,
  discountCode: string,
  customer: CustomerFullProfile,
  userId: any,
  activeProgram: Program,
  shipToSites: Site[],
  billToSites: Site[],
  dispatch: any,
  availablePrograms: ISalesforceAvailablePrograms | null
) => {
  try {
    await postCartVoucherCodes(discountCode, cart.id, customer, dispatch);
  } catch (error: any) {
    throw error;
  } finally {
    const updatedCart = await getCart(
      cart.id,
      customer,
      userId,
      activeProgram,
      shipToSites,
      billToSites,
      dispatch,
      availablePrograms
    );
    dispatch(applyDiscount(updatedCart));
  }
};

export const deleteCartVoucher = async (
  cart: Cart,
  validationId: string,
  customer: CustomerFullProfile,
  userId: any,
  activeProgram: Program,
  shipToSites: Site[],
  billToSites: Site[],
  dispatch: any,
  availablePrograms: ISalesforceAvailablePrograms | null
) => {
  try {
    await deleteProductCartVoucher(validationId, cart.id, dispatch);
  } catch (error: any) {
    throw error;
  } finally {
    const updatedCart = await getCart(
      cart.id,
      customer,
      userId,
      activeProgram,
      shipToSites,
      billToSites,
      dispatch,
      availablePrograms
    );
    dispatch(removeDiscount(updatedCart));
  }
};

export const applyQuoteDiscounts = (cart: any, dispatch: any) => {
  const cartItems = cart.cartItems;
  const quoteDiscounts = cart.quoteDiscounts;
  cartItems.forEach((cartItem: CartItem) => {
    cartItem.adjustedExtendedNetPrice = 0;
    cartItem.hasAdjustedExtendedNetPrice = false;
    cartItem.adjustedUnitPrice = 0;
    cartItem.hasAdjustedUnitPrice = false;
    cartItem.hasAdjusteditemQuantityPrice = false;
    cartItem.adjusteditemQuantityPrice = 0;
    if (quoteDiscounts && quoteDiscounts.length > 0) {
      const itemDiscounts = quoteDiscounts.filter((x: any) => x.itemNumber === cartItem.itemNumber);
      if (itemDiscounts && itemDiscounts.length > 0 && cartItem.quantity) {
        var discountAmout = itemDiscounts.reduce((a: any, b: any) => a + b.value, 0);
        cartItem.discountAmount = discountAmout;
        cartItem.adjustedUnitPrice = cartItem.unitPrice - discountAmout;
        cartItem.adjustedExtendedNetPrice = cartItem.adjustedUnitPrice * cartItem.unitSize * cartItem.quantity;
        cartItem.hasAdjustedExtendedNetPrice = true;
        cartItem.hasAdjustedUnitPrice = true;
        itemDiscounts.forEach((discount: any) => {
          if (cartItem.quantity) {
            discount.extendedValue = discount.value * cartItem.unitSize * cartItem.quantity;
          }
        });
      }
    }
  });
  dispatch(setActiveCart(cart));
};

export const initializeCart = async (
  cartInitializeReason: CartInitializeReason,
  customer: CustomerFullProfile,
  userId: any,
  availablePrograms: any,
  activeProgram: Program,
  selectedWarehouseCode: string | null,
  customerShipToSites: Site[],
  customerBillToSites: Site[],
  dispatch: any
): Promise<Cart> => {
  // Initialize service variables
  dispatch(setSavedItems([]));
  let currentCart: any;
  let userIdentification: any;

  try {
    userIdentification = userId ? userId.toString() : null;
    let erpCustomerId = '';
    if (customer) {
      erpCustomerId = customer.erpCustomerId ? customer.erpCustomerId.toString() : '';
    }

    currentCart = await getUsersSelectedCart(
      userIdentification,
      erpCustomerId,
      customer.class ? customer.class : '',
      availablePrograms,
      activeProgram,
      selectedWarehouseCode,
      customerShipToSites,
      customer,
      customerBillToSites,
      dispatch
    );
  } catch (error: any) {
    console.error(error);
  }

  if (!currentCart) {
    currentCart = await selectLatestCart(
      userIdentification.toString(),
      customer.erpCustomerId.toString(),
      customer.class ? customer.class : '',
      activeProgram,
      selectedWarehouseCode,
      customerShipToSites,
      customer,
      customerBillToSites,
      dispatch,
      availablePrograms
    );
  }

  currentCart = await getCart(
    currentCart.id,
    customer,
    userId.toString(),
    activeProgram,
    customerShipToSites,
    customerBillToSites,
    dispatch,
    availablePrograms
  );

  await initializeSavedItems(
    customer,
    currentCart,
    true,
    activeProgram,
    customerShipToSites,
    userId.toString(),
    customerBillToSites,
    dispatch
  );

  dispatch(setActiveCart(currentCart));
  return currentCart;
};

export const getUsersSelectedCart = async (
  userId: string,
  erpCustomerId: string,
  customerClass: string,
  availablePrograms: any,
  selectedProgram: Program,
  selectedWarehouseCode: string | null,
  customerShipToSites: Site[],
  currentCustomer: CustomerFullProfile,
  customerBillToSites: Site[],
  dispatch: any
) => {
  // save all carts that the current user was working on
  // one user can work on many accounts (erpcustomerId)
  let customerCarts: Cart[] = [];
  const getCartsRequest = NewGetCartRequest;
  getCartsRequest.userId = userId;
  getCartsRequest.erpCustomerId = erpCustomerId;
  getCartsRequest.selectedBy = userId;
  getCartsRequest.customerClass = customerClass;
  getCartsRequest.pager = {
    sort: ['-createdAt'],
  };

  const cartsSelectedByThisUser = await getMultipleCarts(getCartsRequest);

  if (cartsSelectedByThisUser && cartsSelectedByThisUser.results.length !== 0) {
    // delete cart if it has an item with productCode null or programCode that isn't in the avaialble programs
    await removeInvalidCarts(
      cartsSelectedByThisUser.results,
      availablePrograms,
      currentCustomer,
      userId,
      selectedProgram,
      customerShipToSites,
      customerBillToSites,
      dispatch
    );

    const cartsWithItemWithSelectedProgram = cartsSelectedByThisUser.results.filter(
      (x: any) => !hasItemOfAnotherProgram(x, selectedProgram.code) && x.cartItems.length > 0
    );
    const cartsWithNoProgram = cartsSelectedByThisUser.results.filter(
      (x: any) => x.cartItems && x.cartItems.length === 0
    );

    if (cartsWithItemWithSelectedProgram && cartsWithItemWithSelectedProgram.length > 0) {
      customerCarts = await findCartWithUserSelectedWarehouseCode(
        cartsWithItemWithSelectedProgram,
        selectedWarehouseCode,
        customerShipToSites,
        currentCustomer
      );
    }

    if (customerCarts && customerCarts.length === 0 && cartsWithNoProgram && cartsWithNoProgram.length > 0) {
      customerCarts = await findCartWithUserSelectedWarehouseCode(
        cartsWithNoProgram,
        selectedWarehouseCode,
        customerShipToSites,
        currentCustomer
      );
    }
  }

  if (customerCarts && customerCarts.length > 0) {
    return customerCarts[0];
  } else {
    return undefined;
  }
};

export const processParamsForGetCartRequest = (params: GetCartRequest): URLSearchParams => {
  let data = new URLSearchParams();
  data.append('pager', params.pager);
  data.append('selectedBy', params.selectedBy);
  data.append('customerClass', params.customerClass);
  data.append('userId', params.userId);
  data.append('erpCustomerId', params.erpCustomerId);

  return data;
};

export const removeInvalidCarts = async (
  carts: Array<Cart>,
  availablePrograms: any,
  customer: CustomerFullProfile,
  userId: string,
  activeProgram: Program,
  shipToSites: Site[],
  billToSites: Site[],
  dispatch: any
) => {
  let cartIdsToRemove = [];
  const availableProgramsCodes = getAvailableProgramCodes(
    availablePrograms && availablePrograms.programs ? availablePrograms.programs : []
  );

  for (const cart of carts) {
    for (const cartItem of cart.cartItems) {
      const invalidCart = availableProgramsCodes.find((programCode) => programCode === cartItem.programCode)
        ? false
        : true;

      if (invalidCart) {
        cartIdsToRemove.push(cart.id);
      }
    }
  }

  if (cartIdsToRemove.length > 0) {
    for (const cartId of cartIdsToRemove) {
      await removeCart(
        cartId,
        undefined,
        undefined,
        customer,
        userId,
        activeProgram,
        shipToSites,
        billToSites,
        dispatch,
        availablePrograms
      );
    }
  }
};

export const getAvailableProgramCodes = (availablePrograms: Program[]) => {
  const availableProgramsCodes: string[] = [];
  availablePrograms.forEach((program) => {
    availableProgramsCodes.push(program.code);
  });
  return availableProgramsCodes;
};

export const hasItemOfAnotherProgram = (customerCart: Cart, selectedProgramCode: string): boolean => {
  if (!customerCart.cartItems || customerCart.cartItems.length < 1) {
    return false;
  }
  const itemOfAnotherProgram = customerCart.cartItems.find((cartItem) => cartItem.programCode !== selectedProgramCode);
  return itemOfAnotherProgram !== undefined;
};

export const findCartWithUserSelectedWarehouseCode = async (
  carts: Array<Cart>,
  selectedWarehouse: string | null,
  customerShipToSites: Site[],
  currentCustomer: CustomerFullProfile
): Promise<Cart[]> => {
  const userSelectedWarehouseCode = selectedWarehouse;
  if (userSelectedWarehouseCode) {
    let cartsWithUserSelectedWarehouseCode = carts.filter(
      (x) => x.userSelectedWarehouseCode && x.userSelectedWarehouseCode === userSelectedWarehouseCode
    );
    if (cartsWithUserSelectedWarehouseCode?.length > 0) return cartsWithUserSelectedWarehouseCode;
    const shipToSites = await customerShipToSites;
    const firstShipToSiteWithSelectedWarehouse = shipToSites.find((x) => {
      return (
        (x.warehouseCode === userSelectedWarehouseCode && !!x.operatingUnitCode) ||
        (x.warehouseCode === '211' && '231' === userSelectedWarehouseCode && !!x.operatingUnitCode) ||
        (x.warehouseCode === '231' && '211' === userSelectedWarehouseCode && !!x.operatingUnitCode)
      );
    });

    let operatingUnitCode = firstShipToSiteWithSelectedWarehouse
      ? firstShipToSiteWithSelectedWarehouse.operatingUnitCode
      : null;

    if (userSelectedWarehouseCode.startsWith('5')) {
      let allPossibleWarehouses: (string | undefined)[] = [userSelectedWarehouseCode];
      const customer = currentCustomer;
      //surfaces orders can add fabricated items with fab warehouse
      const fabPossibleWarehouses = customer.productTypeWarehouseCodes.filter(
        (x) => x.operatingUnitCode === operatingUnitCode && x.productType?.toLowerCase().startsWith('fabricated')
      );
      let possibleWarehouse = fabPossibleWarehouses.map((x) => x.warehouseCode);
      allPossibleWarehouses = allPossibleWarehouses.concat(possibleWarehouse);

      cartsWithUserSelectedWarehouseCode = carts.filter(
        (x) =>
          !x.userSelectedWarehouseCode &&
          x.cartItems &&
          x.cartItems.length > 0 &&
          x.cartItems[0].operatingUnitCode === operatingUnitCode &&
          allPossibleWarehouses.some((y) => y === x.cartItems[0].shipFromWarehouseId)
      );
    } else if (userSelectedWarehouseCode === '211' || userSelectedWarehouseCode === '231') {
      cartsWithUserSelectedWarehouseCode = carts.filter(
        (x) =>
          !x.userSelectedWarehouseCode &&
          ((x.cartItems &&
            x.cartItems.length > 0 &&
            x.cartItems[0].shipFromWarehouseId === '211' &&
            x.cartItems[0].operatingUnitCode === operatingUnitCode) ||
            (x.cartItems &&
              x.cartItems.length > 0 &&
              x.cartItems[0].shipFromWarehouseId === '231' &&
              x.cartItems[0].operatingUnitCode === operatingUnitCode))
      );
    } else {
      cartsWithUserSelectedWarehouseCode = carts.filter(
        (x) =>
          !x.userSelectedWarehouseCode &&
          x.cartItems &&
          x.cartItems.length > 0 &&
          x.cartItems[0].shipFromWarehouseId === userSelectedWarehouseCode &&
          x.cartItems[0].operatingUnitCode === operatingUnitCode
      );
    }

    if (cartsWithUserSelectedWarehouseCode.length === 0) {
      const cartsWithoutCartItems = carts.filter(
        (x) => !x.userSelectedWarehouseCode && (!x.cartItems || (x.cartItems && x.cartItems.length === 0))
      );

      if (cartsWithoutCartItems.length > 0) {
        return cartsWithoutCartItems;
      } else {
        return [];
      }
    } else {
      return cartsWithUserSelectedWarehouseCode;
    }
  } else {
    return carts;
  }
};

export const selectLatestCart = async (
  userId: string,
  erpCustomerId: string,
  customerClass: string,
  activeProgram: Program,
  selectedWarehouse: string | null,
  customerShipToSites: Site[],
  customer: CustomerFullProfile,
  billToSites: Site[],
  dispatch: any,
  availablePrograms: ISalesforceAvailablePrograms | null
): Promise<Cart> => {
  let metadata: any = null;
  const getCartsRequest = NewGetCartRequest;
  getCartsRequest.userId = userId;
  getCartsRequest.erpCustomerId = erpCustomerId;
  getCartsRequest.customerClass = customerClass;
  getCartsRequest.pager = {
    sort: ['-createdAt'],
  };
  const carts = await getMultipleCarts(getCartsRequest);

  const hasCarts = carts.results.length > 0;

  if (hasCarts) {
    const selectedProgram = activeProgram;

    const cartsWithItemWithSelectedProgram = carts.results.filter(
      (x: any) => !hasItemOfAnotherProgram(x, selectedProgram.code) && x.cartItems.length > 0
    );

    const cartsWithNoProgram = carts.results.filter((x: any) => x.cartItems && x.cartItems.length === 0);

    if (cartsWithItemWithSelectedProgram.length > 0) {
      // Select the most recent cart
      const cartToSelect: Cart = (
        await findCartWithUserSelectedWarehouseCode(
          cartsWithItemWithSelectedProgram,
          selectedWarehouse,
          customerShipToSites,
          customer
        )
      )[0];

      if (cartToSelect) {
        metadata = await selectCart(cartToSelect.id, userId, dispatch);
      }
    }

    if (!metadata && cartsWithNoProgram && cartsWithNoProgram.length > 0) {
      const carts: Cart[] = await findCartWithUserSelectedWarehouseCode(
        cartsWithNoProgram,
        selectedWarehouse,
        customerShipToSites,
        customer
      );
      const cartToSelect: Cart = carts[0];
      if (cartToSelect) {
        metadata = await selectCart(cartToSelect.id, userId, dispatch);
      }
    }
  }

  if (!metadata) {
    return await addCart(
      null,
      customer,
      userId,
      activeProgram,
      customerShipToSites,
      billToSites,
      dispatch,
      availablePrograms
    );
  }
  return await getCart(
    metadata.CartId,
    customer,
    userId,
    activeProgram,
    customerShipToSites,
    billToSites,
    dispatch,
    availablePrograms
  );
};

export const initializeSavedItems = async (
  customer: CustomerFullProfile,
  cart: Cart,
  shouldCheckAvailability: boolean,
  activeProgram: Program,
  shipToSites: Site[],
  userId: string,
  billToSites: Site[],
  dispatch: any
): Promise<void> => {
  dispatch(setSavedItems([]));
  const {results} = await getSavedItems(customer, userId);

  if (!results || results.length === 0) {
    return;
  }

  let itemNumbers = results.map((item: any) => {
    return item.itemNumber;
  });
  itemNumbers = Array.from(new Set(itemNumbers));

  const selectedProgram = activeProgram;

  const availabilityRequest = getParamsForItemAvailabilityRequest(
    itemNumbers,
    selectedProgram,
    customer,
    null,
    cart.cartItems
  );

  const availabilityResult = await getItemAvailability(availabilityRequest);

  if (!availabilityResult || !availabilityResult.results || availabilityResult.results.length === 0) {
    return;
  }
  const unavailableItems = availabilityResult.results.filter((x: any) => !x.isAvailable);

  if (!!unavailableItems && unavailableItems.length > 0) {
    try {
      const tasks = unavailableItems.map((x: any) => deleteSavedItemsByNumberRequest(x, customer));
      await Promise.all(tasks);
    } catch (error: any) {
      console.error(error);
    } finally {
      availabilityResult.results = availabilityResult.results.filter((x: any) => x.isAvailable);
      availabilityResult.totalResults = availabilityResult.results.length;
      if (availabilityResult.totalResults === 0) {
        console.error('Item is not available');
      }
    }
  }

  // remove items that do not have org so cant be priced
  let batchPriceRequest: any = await getBatchPriceRequest(
    availabilityResult,
    cart,
    customer,
    selectedProgram,
    shipToSites,
    billToSites
  );

  if (!batchPriceRequest || batchPriceRequest.length === 0) {
    return;
  }

  let singleItemPrices;
  try {
    singleItemPrices = await getSingleItemPriceBatch(batchPriceRequest, dispatch);
  } catch (error: any) {
    await Promise.all(results.map((item: any) => deleteSavedItemsByNumberRequest(item, customer)));
    await initializeSavedItems(customer, cart, true, activeProgram, shipToSites, userId, billToSites, dispatch);
    console.error(error);
    return;
  }

  if (!singleItemPrices) {
    return;
  }

  const itemsToWarehouse = batchPriceRequest.map((x: any) => ({
    itemNumber: x.itemNumber,
    warehouseCode: x.warehouseCode,
  }));
  // only show items that currently have prices
  let itemsAsCartItems = getSavedItemsAsCartItems(
    availabilityResult.results,
    singleItemPrices,
    selectedProgram,
    itemsToWarehouse
  );

  dispatch(addSavedItems(itemsAsCartItems));
};

export const removeSavedItem = async (
  savedItem: CartItem,
  cart: Cart,
  savedItems: CartItem[],
  customer: CustomerFullProfile,
  activeProgram: Program,
  shipToSites: Site[],
  userId: any,
  billToSites: Site[],
  dispatch: any
): Promise<any> => {
  const foundSavedItem = savedItems.find(
    (x) => x.itemNumber === savedItem.itemNumber && x.cartItemId === savedItem.cartItemId
  );
  if (!foundSavedItem) {
    return Promise.reject('Item does not exist on saved items.');
  }

  try {
    await deleteSavedItemsByNumberRequest(savedItem, customer);
    await initializeSavedItems(customer, cart, false, activeProgram, shipToSites, userId, billToSites, dispatch);
  } catch (error: any) {
    console.error(error);
    return Promise.reject('Error encountered while removing saved item.');
  }
};

export const getItemPriceRequest = async (
  item: any,
  selectedProgram: Program,
  cart: Cart,
  customer: CustomerFullProfile,
  shipToSites: Site[],
  billToSites: Site[]
) => {
  const operatingUnitCode = await getOperatingUnitCodeByProductType(
    item.productItem.baseProduct.productType,
    customer,
    shipToSites
  );
  let singleItemPriceResponse = NewSingleItemPriceResponse();

  let itemAsCartItem = getCartItemByItemNumber(
    item.itemNumber,
    item.productItem.baseProduct,
    singleItemPriceResponse,
    undefined,
    undefined,
    selectedProgram.code,
    selectedProgram.name
  );

  const shipFromWarehouse = await getItemWarehouseCode(itemAsCartItem, cart, customer, shipToSites);
  if (shipFromWarehouse.hasError) {
    return null;
  }
  let warehouseCode = shipFromWarehouse.mainWarehouseCode;
  if (
    !item.productItem.organizations ||
    item.productItem.organizations.length === 0 ||
    !item.productItem.organizations.some((org: any) => org.erpOrganizationCode === shipFromWarehouse.mainWarehouseCode)
  ) {
    warehouseCode = shipFromWarehouse.warehouseCode;
  }
  const billToSite = await getBillToSiteByOperatingUnitCode(operatingUnitCode, billToSites);
  const getPriceRequest: any = {};
  getPriceRequest['erpCustomerId'] = customer.erpCustomerId.toString();
  getPriceRequest['itemNumber'] = item.itemNumber;
  getPriceRequest['programCode'] = selectedProgram.code;
  getPriceRequest['siteUseId'] = billToSite.siteUseId;
  getPriceRequest['warehouseCode'] = warehouseCode;
  getPriceRequest['customerClass'] = customer.class;
  return getPriceRequest;
};

export const addBatchCartItems = async (
  cartItems: Array<CartItem>,
  cart: Cart,
  customer: any,
  shipToSites: ShipToSite[],
  billToSites: BillToSite[],
  user: User,
  program: Program,
  dispatch: any,
  availablePrograms: ISalesforceAvailablePrograms | null,
  calculateDiscountsInBackend = false
): Promise<any> => {
  const cartItemArrayPostBody = [];
  let shouldResolveWithMessageFromWarehouse = false;

  const isSameProductType = cart.cartItems.every((item) => item.productType === cartItems[0].productType);
  const billToSite = await getBillToSiteByOperatingUnitCode(cartItems[0].operatingUnitCode, billToSites);

  if (!isSameProductType) {
    return Promise.reject('Cart already contains items of different product type.');
  }

  const tempCartItemArray = removeDuplicateItemsFromArray(cartItems);

  for (const cartItem of tempCartItemArray) {
    const matchedItem = cart.cartItems.find((item) => item.itemNumber === cartItem.itemNumber);

    if (matchedItem) {
      // Update quantity if item already exists in the cart
      const itemCopy: any = {...matchedItem};
      itemCopy.quantity = itemCopy._quantity + cartItem.quantity;
      itemCopy.shipFromWarehouseId = matchedItem.shipFromWarehouseId;

      cartItemArrayPostBody.push(itemCopy);
    } else {
      const warehouse = await getItemWarehouseCode(cartItem, cart, customer, shipToSites);

      if (warehouse.hasError) {
        return Promise.reject(warehouse.message);
      } else {
        cartItem.shipFromWarehouseId = warehouse.warehouseCode;

        if (cartItem.quantity === 0 || !cartItem.quantity) {
          cartItem.quantity = 1;
        }

        cartItemArrayPostBody.push(cartItem);
      }

      if (warehouse.message === 'The current item must ship from MN Samples.') {
        shouldResolveWithMessageFromWarehouse = true;
      }
    }
  }

  try {
    await productAddBatchCartItems(cartItemArrayPostBody, cart.id, billToSite.siteUseId, dispatch);
    if (shouldResolveWithMessageFromWarehouse) {
      return Promise.resolve('Some of the items must ship from MN Samples.');
    }
  } catch (error: any) {
    console.error(error);
    return Promise.reject('Error encountered while adding item to cart.');
  } finally {
    const currentCart = await getCart(
      cart.id,
      customer,
      user.userId,
      program,
      shipToSites,
      billToSites,
      dispatch,
      availablePrograms,
      false,
      true,
      true,
      calculateDiscountsInBackend
    );
    if (currentCart) {
      dispatch(addingCartItem(currentCart));
    }
  }
};

export const removeDuplicateItemsFromArray = (cartItems: CartItem[]) => {
  const itemArray = cartItems;
  const tempCartItemArray = [];

  for (const cartItem of cartItems) {
    const removedArray = [];
    for (const item of itemArray) {
      if (item.itemNumber === cartItem.itemNumber) {
        removedArray.push(cartItem);
      }
    }

    if (removedArray.length !== 0) {
      let alreadyPushedToArray = false;
      for (const tempItem of tempCartItemArray) {
        if (tempItem.itemNumber === cartItem.itemNumber) {
          alreadyPushedToArray = true;
        }
      }
      if (!alreadyPushedToArray) {
        cartItem.quantity = removedArray.length;
        tempCartItemArray.push(cartItem);
      }
    }
  }

  return tempCartItemArray;
};

export const getParamsForItemAvailabilityRequest = (
  itemNumbers: string[],
  selectedProgram: Program,
  customer: any,
  productType: ProductType | null,
  cartItems: CartItem[]
): any => {
  const params = {
    itemNumbers: itemNumbers.length > 0 ? itemNumbers : [],
    programCode: selectedProgram.code,
    productType: getValue(productType),
    customerClass: getValue(customer.class),
    customerClassGroups: customer.classGroups?.length ? customer.classGroups : [],
    erpCustomerId: getValue(customer.erpCustomerId),
    productActiveLocations: cartItems.length > 0 ? cartItems[0].productActiveLocations : null,
    slabWarehouseCode: null,
    tileWarehouseCode: null,
    samplesWarehouseCode: null,
    storeWarehouseCode: null,
  };

  switch (productType) {
    case 'Slab':
      params.slabWarehouseCode = customer.productTypeWarehouseCodes.find((pt: any) => pt.productType === 'Slab')
        ?.warehouseCode;
      break;
    case 'Tile':
      params.tileWarehouseCode = customer.productTypeWarehouseCodes.find((pt: any) => pt.productType === 'Tile')
        ?.warehouseCode;
      break;
    case 'Samples':
      params.samplesWarehouseCode = customer.productTypeWarehouseCodes.find((pt: any) => pt.productType === 'Samples')
        ?.warehouseCode;
      break;
    case 'Store':
      params.storeWarehouseCode = customer.productTypeWarehouseCodes.find((pt: any) => pt.productType === 'Store')
        ?.warehouseCode;
      break;

    default:
      params.slabWarehouseCode = customer.productTypeWarehouseCodes.find((pt: any) => pt.productType === 'Slab')
        ?.warehouseCode;
      params.tileWarehouseCode = customer.productTypeWarehouseCodes.find((pt: any) => pt.productType === 'Tile')
        ?.warehouseCode;
      params.samplesWarehouseCode = customer.productTypeWarehouseCodes.find((pt: any) => pt.productType === 'Samples')
        ?.warehouseCode;
      params.storeWarehouseCode = customer.productTypeWarehouseCodes.find((pt: any) => pt.productType === 'Store')
        ?.warehouseCode;
      break;
  }
  return params;
};

const getValue = (value: any): any => {
  return value ? value : null;
};

export const getBillToSiteByOperatingUnitCode = async (operatingUnitCode: string, billToSites: Site[]) => {
  const allCustomerBillToSites = billToSites;
  const billToSite = allCustomerBillToSites.find(
    (x) => x.operatingUnitCode && x.operatingUnitCode === operatingUnitCode
  );

  if (!billToSite) {
    return Promise.reject('BillToSite is not found !');
  }
  return billToSite;
};

export const getSavedItemsAsCartItems = (
  itemsAvailability: any,
  singleItemPrices: any,
  selectedProgram: any,
  itemsToWarehouse: Array<{itemNumber: string; warehouseCode: string}>
): Array<CartItem> => {
  var result: any[] = [];
  itemsAvailability.forEach((itemAvailability: any) => {
    const warehouse = itemsToWarehouse.find((x) => x.itemNumber === itemAvailability.itemNumber);
    const price = singleItemPrices.find((x: any) => x.ItemNumber.toString() === itemAvailability.itemNumber);
    if (!!price && !!warehouse) {
      itemAvailability.productItem.baseProduct.isAvailable = itemAvailability.isAvailable;
      result.push(
        getCartItemByItemNumber(
          itemAvailability.itemNumber,
          itemAvailability.productItem.baseProduct,
          price,
          warehouse.warehouseCode,
          undefined,
          selectedProgram.code,
          selectedProgram.name
        )
      );
    }
  });
  return result;
};

export const createUpdateOrderCartEditResubmit = async (
  orderNumber: string,
  cartId: string,
  endpoint: string,
  dispatch: any
): Promise<any> => {
  if (!orderNumber) {
    return Promise.reject('Invalid/missing orderNumber parameter.');
  }
  if (!cartId) {
    return Promise.reject('Invalid/missing cartId parameter.');
  }

  const params = {
    orderNumber: orderNumber,
    cartId: cartId,
  };

  return await createUpdateOrderCart(params, endpoint, dispatch);
};

export const getCartItemsWeight = (selectedCart: Cart) => {
  if (selectedCart && selectedCart.cartItems && selectedCart.cartItems.length > 0) {
    return selectedCart.cartItems
      .map((cartItem: CartItem) => cartItem.unitWeight * (cartItem.quantity || 1))
      .reduce((acc: any, val: any) => acc + val);
  }

  return 0;
};

export const getCartItemsClassifications = (selectedCart: Cart) => {
  if (selectedCart && selectedCart.cartItems && selectedCart.cartItems.length > 0) {
    return selectedCart.cartItems
      .map((cartItem: CartItem) => cartItem.shippingClassifications)
      .reduce((a: any, b: any) => {
        if (!a) {
          a = [];
        }
        if (!b) {
          b = [];
        }

        return a.concat(b.filter((item: CartItem) => a.indexOf(item) === -1));
      });
  }

  return [];
};

export const addCartItem = async (
  cartItem: CartItem,
  cart: Cart,
  customer: CustomerFullProfile,
  shipToSites: ShipToSite[],
  billToSites: BillToSite[],
  activeProgram: Program,
  userId: string,
  dispatch: any,
  availablePrograms: ISalesforceAvailablePrograms | null,
  isRevH?: boolean,
  calculateDiscountsInBackend = false
): Promise<any> => {
  const isSameProductType = cart.cartItems.every((item: any) => item.productType === cartItem.productType);

  if (!isSameProductType) {
    return Promise.reject('Cart already contains items of different product type.');
  }

  // we use this not for the item but for billToSite and it must not be the item OU
  // example is FAB_CAN_OU adding Samples that fallback to CAMOU but we want FAB_CAN_OU
  const operatingUnitCode = await getOperatingUnitCodeByProductType(cartItem.productType, customer, shipToSites);
  const matchedItem: any = cart.cartItems.find((item) => item.itemNumber === cartItem.itemNumber);
  const billToSite = await getBillToSiteByOperatingUnitCode(operatingUnitCode, billToSites);

  try {
    if (!(isBundleProgram(activeProgram) && isRevH) && matchedItem) {
      // Update quantity if item already exists in the cart
      const itemCopy: any = JSON.parse(JSON.stringify(matchedItem));
      itemCopy.quantity = itemCopy.quantity + cartItem.quantity;
      await productAddCartItem(itemCopy, cart.id, matchedItem.shipFromWarehouseId, billToSite.siteUseId, dispatch);
    } else {
      const warehouse = await getItemWarehouseCode(cartItem, cart, customer, shipToSites);
      if (warehouse.saveItemForLater) {
        return Promise.resolve('This item will be placed in your Save for Later items.');
      } else if (warehouse.hasError) {
        return Promise.reject(warehouse.message);
      } else {
        if (cartItem.quantity === 0 || !cartItem.quantity) {
          cartItem.quantity = 1;
        }

        await productAddCartItem(cartItem, cart.id, warehouse.warehouseCode, billToSite.siteUseId, dispatch);
      }
      if (warehouse.message === 'The current item must ship from MN Samples.') {
        return Promise.resolve(warehouse.message);
      }
    }
  } catch (error: any) {
    let callbackError = '';
    if (error && error.text) {
      callbackError = await error.text();
    }
    if (error.status === 400 && callbackError.includes('Your cart is locked into quote')) {
      throw callbackError;
    }
    return Promise.reject('Error encountered while adding item to cart.');
  } finally {
    const currentCart = await getCart(
      cart.id,
      customer,
      userId,
      activeProgram,
      shipToSites,
      billToSites,
      dispatch,
      availablePrograms,
      false,
      true,
      true,
      calculateDiscountsInBackend
    );

    dispatch(addingCartItem(currentCart));
  }
};

export const moveCartItemToSaved = async (
  cartItem: CartItem,
  cart: Cart,
  savedItems: CartItem[],
  customer: CustomerFullProfile,
  activeProgram: Program,
  shipToSites: Site[],
  userId: any,
  billToSites: Site[],
  dispatch: any,
  availablePrograms: ISalesforceAvailablePrograms | null
): Promise<void> => {
  const itemAlreadyExists = savedItems.some(
    (item) => item.itemNumber === cartItem.itemNumber && item.cartItemId === cartItem.cartItemId
  );

  if (itemAlreadyExists) {
    return Promise.reject('Item is already saved.');
  }

  try {
    // Remove item from cart then add to saved
    await removeCartItem(cartItem, cart.id, dispatch);
    await addSavedItem(cartItem, savedItems, cart, customer, activeProgram, shipToSites, userId, billToSites, dispatch);
  } catch (error: any) {
    let callbackError = '';
    if (error && error.text) {
      callbackError = await error.text();
    }
    if (error.status === 400 && callbackError.includes('Your cart is locked into quote')) {
      throw callbackError;
    }
    console.error(error);
    return Promise.reject('Error encountered while saving cart item for later.');
  } finally {
    const currentCart = await getCart(
      cart.id,
      customer,
      userId,
      activeProgram,
      shipToSites,
      billToSites,
      dispatch,
      availablePrograms
    );
    dispatch(updateCartItem(currentCart));
  }
};

export const moveSavedItemToCart = async (
  savedItem: CartItem,
  cart: Cart,
  customer: CustomerFullProfile,
  shipToSites: any,
  billToSites: Site[],
  userId: any,
  activeProgram: Program,
  dispatch: any,
  availablePrograms: ISalesforceAvailablePrograms | null
) => {
  const isSameProductType = cart.cartItems.every((item) => item.productType === savedItem.productType);
  if (!isSameProductType) {
    throw new Error('Cart already contains items of different product type.');
  }
  const existingCartItem: any = cart.cartItems.find((x) => x.itemNumber === savedItem.itemNumber);

  if (existingCartItem) {
    let tempExistingCartItem = JSON.parse(JSON.stringify(existingCartItem));
    tempExistingCartItem.quantity += 1;
    await updateCartItemOnCurrentCart(
      tempExistingCartItem,
      cart,
      customer,
      shipToSites,
      billToSites,
      userId,
      activeProgram,
      dispatch,
      availablePrograms
    );
    await deleteSavedItemsByNumberRequest(savedItem, customer);
    await initializeSavedItems(customer, cart, false, activeProgram, shipToSites, userId, billToSites, dispatch);
    return 'Increase quantity of ' + savedItem.description;
  }

  const warehouse = await getItemWarehouseCode(savedItem, cart, customer, shipToSites);
  if (warehouse.saveItemForLater) {
    return 'This item will be placed in your Save for Later items.';
  } else if (warehouse.hasError) {
    throw new Error(warehouse.message);
  }

  try {
    await addCartItem(
      savedItem,
      cart,
      customer,
      shipToSites,
      billToSites,
      activeProgram,
      userId,
      dispatch,
      availablePrograms
    );
  } catch (error: any) {
    let callbackError = '';
    if (error && error.text) {
      callbackError = await error.text();
    }
    if (error.status === 400 && callbackError.includes('Your cart is locked into quote')) {
      throw callbackError;
    }
    throw new Error('Error encountered while moving item to cart.');
  }

  try {
    await deleteSavedItemsByNumberRequest(savedItem, customer);
    await initializeSavedItems(customer, cart, false, activeProgram, shipToSites, userId, billToSites, dispatch);
    if (warehouse.message === 'The current item must ship from MN Samples.') {
      return warehouse.message;
    }
  } catch (error: any) {
    console.error(error);
    throw new Error('Error encountered while removing saved item.');
  }
};

export const addSavedItem = async (
  savedItem: CartItem,
  savedItems: CartItem[],
  cart: Cart,
  customer: CustomerFullProfile,
  activeProgram: Program,
  shipToSites: Site[],
  userId: string,
  billToSites: BillToSite[],
  dispatch: any
): Promise<any> => {
  const itemAlreadyExists = savedItems.some(
    (item) => item.itemNumber === savedItem.itemNumber && item.cartItemId === savedItem.cartItemId
  );
  if (itemAlreadyExists) {
    return Promise.reject('Item is already saved.');
  }

  try {
    await postSavedItemsRequest(savedItem, customer);
    await initializeSavedItems(customer, cart, false, activeProgram, shipToSites, userId, billToSites, dispatch);
  } catch (error: any) {
    savedItems.splice(savedItems.indexOf(savedItem), 1);
    return Promise.reject('Error encountered while saving the item.');
  }
};

export const updateCartItemOnCurrentCart = async (
  cartItem: CartItem,
  cart: Cart,
  customer: CustomerFullProfile,
  shipToSites: Site[],
  billToSites: Site[],
  userId: any,
  activeProgram: Program,
  dispatch: any,
  availablePrograms: ISalesforceAvailablePrograms | null,
  calculateDiscountsInBackend = false
): Promise<void> => {
  const isItemInTheCart = cart.cartItems.some(
    (x) => x.itemNumber === cartItem.itemNumber && cartItem.cartItemId === x.cartItemId
  );
  if (!isItemInTheCart) {
    return Promise.reject('Item does not exist on cart.');
  }

  const removeItem = !cartItem.tempQuantity;
  const previousQuantity = cartItem.quantity;
  try {
    if (removeItem) {
      // Remove if quantity is set to zero
      await removeCartItem(cartItem, cart.id, dispatch);
    } else {
      const operatingUnitCode = await getOperatingUnitCodeByProductType(cartItem.productType, customer, shipToSites);
      const billToSite = await getBillToSiteByOperatingUnitCode(operatingUnitCode, billToSites);

      // Update cart item
      cartItem.quantity = cartItem.tempQuantity;
      await productAddCartItem(cartItem, cart.id, cartItem.shipFromWarehouseId, billToSite.siteUseId, dispatch);
    }
  } catch (error: any) {
    console.error(error);
    if (!removeItem) {
      // Revert changes if update fails
      cartItem.quantity = previousQuantity;
    }
    throw error;
  } finally {
    const currentCart = await getCart(
      cart.id,
      customer,
      userId,
      activeProgram,
      shipToSites,
      billToSites,
      dispatch,
      availablePrograms,
      false,
      true,
      true,
      calculateDiscountsInBackend
    );
    dispatch(updateCartItem(currentCart));
  }
};

export const deleteHeaderAdjustment = async (
  cart: Cart,
  headerAdjustmentId: number,
  customer: CustomerFullProfile,
  userId: any,
  activeProgram: Program,
  shipToSites: Site[],
  billToSites: Site[],
  dispatch: any,
  availablePrograms: ISalesforceAvailablePrograms | null
) => {
  await deleteProductHeaderAdjustment(headerAdjustmentId, dispatch);
  const updatedCart = await getCart(
    cart.id,
    customer,
    userId,
    activeProgram,
    shipToSites,
    billToSites,
    dispatch,
    availablePrograms
  );
  dispatch(removeHeaderAdjustment(updatedCart));
};

export const createUpdateOrderCart = async (params: any, endpoint: string, dispatch: any): Promise<any> => {
  const timeoutInSeconds =
    endpoint === COMMERCE_CORE_CONSTANTS.API_SERVICES.CART.resubmission ||
    endpoint === COMMERCE_CORE_CONSTANTS.API_SERVICES.CART.reorder
      ? 900
      : 60;
  let stringId = await postCartResubmissionOrReorder(endpoint, JSON.stringify(params));
  stringId = await stringId.text();
  if (endpoint === COMMERCE_CORE_CONSTANTS.API_SERVICES.CART.reorder) {
    toast.warning('Reordering large orders may take up to 15 minutes.');
  }
  return await callback(stringId, timeoutInSeconds, dispatch);
};

export const getCart = async (
  cartId: string,
  customer: CustomerFullProfile,
  userId: string,
  activeProgram: Program,
  shipToSites: Site[],
  billToSites: Site[],
  dispatch: any,
  availablePrograms: ISalesforceAvailablePrograms | null,
  includeInactive: boolean = false,
  shouldApplyDiscountsToItems: boolean = true,
  shouldInitializeCartItems: boolean = true,
  calculateDiscountsInBackend = false
) => {
  // if shouldApplyDiscountsToItems is true this means
  // that this cart is going to be set as a current cart
  try {
    const cart = await getCartsIncludeInactiveRequest(cartId, includeInactive);

    let tempCart = JSON.parse(JSON.stringify(cart));

    if (tempCart && tempCart.Message && tempCart.Message.includes('Cart is not found')) {
      toast.error(
        'This cart is no longer active due to revisions made to this order in another cart. Please refresh to clear this cart.'
      );
    }

    if (tempCart.quoteDiscounts) {
      tempCart.quoteDiscounts = tempCart.quoteDiscounts.filter((x: any) => x.isActive);
    }

    if (!shouldInitializeCartItems) {
      return tempCart;
    }

    if (tempCart && tempCart.cartItems && tempCart.cartItems.length > 0) {
      if (!isFabricatedCart(tempCart) && !isSurfacesCart(tempCart)) {
        await initializeCartItems(
          tempCart,
          customer,
          userId,
          activeProgram,
          shipToSites,
          billToSites,
          dispatch,
          availablePrograms,
          calculateDiscountsInBackend
        );
      } else if (calculateDiscountsInBackend) {
        // in non fabricated cart, these props are
        // set in initializeCartItems
        // Here we have to set it if we calculate the discount in backend
        for (let cartItem of tempCart.cartItems) {
          cartItem.hasAdjustedExtendedNetPrice =
            cartItem.hasAdjusteditemQuantityPrice =
            cartItem.hasAdjustedUnitPrice =
              cartItem.discountAmount > 0;
          cartItem.adjusteditemQuantityPrice = cartItem.adjustedExtendedNetPrice;
        }
      }
    } else {
      tempCart.cartItems = [];
    }

    tempCart.purchasingCustomers = await getPurchasingCustomersFromCustomerService(tempCart.id, tempCart.erpCustomerId);

    if (!calculateDiscountsInBackend) {
      // on reorder we shouldn't apply discounts
      if (shouldApplyDiscountsToItems && tempCart.voucherCodes && tempCart.voucherCodes.length > 0) {
        // first we should apply the real discount on each item and then reduce the collection
        applyDiscountsToItems(tempCart, dispatch);
        tempCart = JSON.parse(JSON.stringify(tempCart));
        tempCart.voucherCodes = setDiscountsData(tempCart);
      }

      //here apply quoteDisounts on items
      if (
        (tempCart.transactionType === 'QuoteCart' || tempCart.transactionType === 'Resubmit') &&
        shouldApplyDiscountsToItems &&
        tempCart.quoteDiscounts &&
        tempCart.quoteDiscounts.length > 0
      ) {
        // first we should apply the real discount on each item and then reduce the collection
        applyQuoteDiscounts(tempCart, dispatch);
      }
    }

    return tempCart;
  } catch (error: any) {
    console.error(error);
  }
};

export const getMultipleCarts = async (params: GetCartRequest) => {
  let cartResults = NewGetCartResults;
  cartResults.results = [];
  cartResults.totalResults = 0;
  let data = processParamsForGetCartRequest(params);
  return getMultipleCartsRequest(data);
};

export const getActiveCart = async (
  currentCustomer: CustomerFullProfile,
  userId: any,
  activeProgram: Program,
  availablePrograms: any,
  selectedWarehouseCode: any,
  customerShipToSites: Site[],
  customerBillToSites: Site[],
  dispatch: any
) => {
  const programCode = window.sessionStorage.getItem(`selectedProgramCode${availablePrograms.accountId}`);

  try {
    const response = await getCartsRequest({
      customerClass: currentCustomer.class,
      erpCustomerId: currentCustomer.erpCustomerId,
      selectedBy: userId,
      userId: userId,
      pager: {sort: ['-createdAt']},
    });

    let activeCart: any;
    if (currentCustomer.class === 'ENTERPRISE') {
      activeCart = response.results.find(
        (x: any) =>
          (x.cartItems[0]?.programCode === programCode &&
            x.cartItems[0]?.shipFromWarehouseId === selectedWarehouseCode) ||
          (x.cartItems[0]?.programCode === programCode &&
            selectedWarehouseCode === '211' &&
            x.cartItems[0]?.shipFromWarehouseId === '231') ||
          (x.cartItems[0]?.programCode === programCode &&
            selectedWarehouseCode === '231' &&
            x.cartItems[0]?.shipFromWarehouseId === '211')
      );
    } else {
      activeCart = response.results.find((x: any) => x.cartItems[0]?.programCode === programCode);
    }
    if (!activeCart) {
      activeCart = response.results.find((x: any) => x.cartItems.length === 0);
    }

    if (!activeCart) {
      await initializeCart(
        'cdmpComponentResolve',
        currentCustomer,
        parseInt(userId),
        availablePrograms,
        activeProgram,
        selectedWarehouseCode,
        customerShipToSites,
        customerBillToSites,
        dispatch
      );
    } else {
      dispatch(setActiveCart(activeCart));
    }

    return activeCart;
  } catch (error: any) {
    console.error(error);
    throw error;
  }
};

export const getCartCheckoutInvalidateStatus = async (cartId: string, iteration = 0): Promise<string> => {
  if (iteration === 10) {
    return 'Failed';
  }

  // look
  let response = await getCallbackWithParams({
    request: {
      externalId: cartId,
      type: 'csf:Cart:CartCheckoutInvalidate',
    },
    pager: {
      sort: ['-createdAt'],
    },
  });

  if (response && response.results.length > 0) {
    if (response.results[0].status === 'InProgress') {
      await new Promise((resolve) => setTimeout(resolve, iteration * 100 + 100));
      return await getCartCheckoutInvalidateStatus(cartId, ++iteration);
    } else {
      // return Success or Failure
      return response.results[0].status;
    }
  } else {
    return 'Success';
  }
};

export const selectCart = async (cartId: string, userId: string, dispatch: any) => {
  const callbackId = await putCartsRequest(cartId, {
    userId: userId,
  });
  const stringId = await callbackId.text();
  try {
    return callback(stringId, 90, dispatch);
  } catch (error: any) {
    throw error;
  }
};

export const getBatchPriceRequest = async (
  availabilityResult: {results: any[]; totalResults: number},
  cart: Cart,
  customer: CustomerFullProfile,
  selectedProgram: Program,
  shipToSites: Site[],
  billToSites: Site[]
) => {
  let batchPriceRequest: any = [];
  for (const item of availabilityResult.results) {
    let result = await getItemPriceRequest(item, selectedProgram, cart, customer, shipToSites, billToSites);
    batchPriceRequest.push(result);
  }

  return batchPriceRequest.filter((x: any) => x !== null);
};

export const addCart = async (
  cartTransactionType: string | null = null,
  currentCustomer: CustomerFullProfile,
  userId: string,
  activeProgram: Program,
  shipToSites: Site[],
  billToSites: Site[],
  dispatch: any,
  availablePrograms: ISalesforceAvailablePrograms | null
): Promise<Cart> => {
  const customer = currentCustomer;

  const params: CreateCartRequest = {
    userId: userId,
    erpCustomerId: customer.erpCustomerId,
    customerClass: customer.class,
    customerClassGroups: customer.classGroups,
    userSelectedWarehouseCode: null as string | null,
    ...(customer.salesforceRetailAcount &&
      customer.salesforceRetailAcount.oracleRegionName && {
        oracleSalesRegionName: customer.salesforceRetailAcount.oracleRegionName,
      }),
  };

  if (cartTransactionType) {
    Object.assign(params, {cartTransactionType});
  }

  if (customer.class.toLowerCase() === 'enterprise') {
    params.userSelectedWarehouseCode = getUserSelectedWarehouse();
  }
  try {
    const callbackId = await postCarts(params);
    const stringId = await callbackId.text();
    const cartCallback: any = await callback(stringId, 90, dispatch);
    let cartId;

    if (cartCallback && cartCallback.metadata) {
      cartId = cartCallback.metadata.CartId;
    } else {
      cartId = cartCallback.CartId;
    }

    const cart = await getCart(
      cartId,
      currentCustomer,
      userId,
      activeProgram,
      shipToSites,
      billToSites,
      dispatch,
      availablePrograms
    );
    await initializeSavedItems(customer, cart, false, activeProgram, shipToSites, userId, billToSites, dispatch);
    return cart;
  } catch (error: any) {
    throw error;
  }
};

export const removeCart = async (
  cartId: string,
  transactionType = null,
  createNewCart = true,
  customer: CustomerFullProfile,
  userId: string,
  activeProgram: Program,
  shipToSites: Site[],
  billToSites: Site[],
  dispatch: any,
  availablePrograms: ISalesforceAvailablePrograms | null
) => {
  dispatch(setCheckoutInitialState());
  try {
    const callbackId = await deleteCartsByIdRequest(cartId);
    const stringId = await callbackId.text();
    await callback(stringId, 90, dispatch);
    if (createNewCart) {
      const cart = await addCart(
        transactionType,
        customer,
        userId,
        activeProgram,
        shipToSites,
        billToSites,
        dispatch,
        availablePrograms
      );
      dispatch(setActiveCart(cart));
    }
  } catch (error: any) {
    return Promise.reject('An error occured while removing the cart.');
  }
};

export const cartItemsAlphabetized = (itemsToSort: CartItem[]): CartItem[] => {
  let tempCartItems = JSON.parse(JSON.stringify(itemsToSort));
  let cartItemArray = tempCartItems;
  cartItemArray.sort((a: any, b: any) => (a.description > b.description ? 1 : -1));

  return cartItemArray;
};

export const cropImagesBasedOnProductType = (cartItem: CartItem) => {
  const {imageUrl, productType} = cartItem;
  let newImageUrl = imageUrl;

  if (productType === 'Slab' || productType === 'Tile') {
    newImageUrl = imageUrl.replace('{size}px@{scale}x', '250x100px').replace('q={quality}', 'q=100') + '&keep=c';
  } else {
    newImageUrl = imageUrl.replace('{size}px@{scale}x', '250x200px').replace('q={quality}', 'q=100') + '&c=0';
  }
  return newImageUrl;
};

export const unitAndLinePriceFormat = (price: number) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  let formattedPrice = '0.00';
  let priceDividend = 100;
  let fractionDigits = 2;
  if (price) {
    formattedPrice = (parseFloat(price.toString()) / priceDividend).toFixed(fractionDigits);
  }

  const result = formatter.format(parseFloat(formattedPrice));
  return result;
};
