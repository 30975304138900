import React, {Fragment, useEffect, useState} from 'react';
import {AsyncTypeahead} from 'react-bootstrap-typeahead';
import {useDispatch} from 'react-redux';
import {setCurrentCustomer} from '../../../../../features/customer/slice/customer.slice';
import boldSuggestionMatch from '../../../../../Framework/Components/SearchBar';
import {baseApiCallWithReauth} from '../../../../../Framework/api-utils/api-utils';
import COMMERCE_CORE_CONSTANTS from '../../../../constants';
import {CustomerSearchContainer} from './CustomerSearch.styled';
import {setResolvesAreLoading} from '../../../../../features/resolves/slice/resolves.slice';
import {
  customerSearchAsync,
  setCustomerKeyword,
} from '../../../../../features/search/customer/slice/customer-search.slice';
import {
  organizeCustomerAutosuggestParams,
  organizeParamsForCustomerSearch,
} from '../../../../../features/customer/service/customer.service';

const CustomerSearch = () => {
  const dispatch = useDispatch();

  let initialState: any[] = [];

  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState(initialState);
  const [currentSearchTerm, setCurrentSearchTerm] = useState('');

  const typeahead = React.createRef<any>();
  const customerApiEndpoint = COMMERCE_CORE_CONSTANTS.API_SERVICES.CUSTOMER.customers;

  const getCustomerSearchSuggestions = async (keyword: string) => {
    setIsLoading(true);
    setOptions([]);
    let data = organizeCustomerAutosuggestParams(keyword);
    let results = await baseApiCallWithReauth('GET', customerApiEndpoint, data);
    setOptions(results.results);
    setIsLoading(false);
  };

  const customerSearchRedux = (keyword: string) => {
    let data = organizeParamsForCustomerSearch(keyword);
    dispatch(customerSearchAsync(data));
    dispatch(setCustomerKeyword(keyword));
  };

  const onCustomerSelection = (selectedCustomer: any) => {
    window.sessionStorage.setItem(COMMERCE_CORE_CONSTANTS.WINDOW_SESSION.selectedCustomerId, selectedCustomer.id);
    dispatch(setCurrentCustomer(selectedCustomer));
    dispatch(setResolvesAreLoading(true));
  };

  const filterBy = () => true;

  useEffect(() => {
    setCurrentSearchTerm(typeahead.current.state.text);
  }, [typeahead]);

  return (
    <CustomerSearchContainer>
      <div className="gray-header-background"></div>
      <div id="react-bootstrap-hack" className="container search-container global-search">
        <div className="search-items-container search-form">
          <AsyncTypeahead
            onKeyDown={(e) => {
              if (e.keyCode === 13) {
                customerSearchRedux(typeahead.current.state.text);
                typeahead.current.hideMenu();
              }
            }}
            filterBy={filterBy}
            id="autocomplete"
            labelKey="name"
            className="search-bar"
            isLoading={isLoading}
            onSearch={getCustomerSearchSuggestions}
            onChange={(selected) => {
              if (selected[0]) {
                onCustomerSelection(selected[0]);
              }
            }}
            ref={typeahead}
            minLength={3}
            options={options}
            delay={500}
            placeholder="Search Customer List"
            useCache={false}
            renderMenuItemChildren={(option: any) => (
              <Fragment>
                <div className="autosuggest-option" onClick={() => onCustomerSelection(option)}>
                  <div className="customer-name">{boldSuggestionMatch(option.name, currentSearchTerm)}</div>
                  <div className="customer-location">
                    <span className="location">
                      {option.city} {option.stateProvince}
                    </span>
                  </div>
                </div>
              </Fragment>
            )}></AsyncTypeahead>
          <div className="search-button-container">
            <button
              data-testid="customer-search-button"
              onClick={() => customerSearchRedux(typeahead.current.state.text)}
              className="search-button btn-primary">
              SEARCH
            </button>
          </div>
        </div>
      </div>
    </CustomerSearchContainer>
  );
};

export default CustomerSearch;
