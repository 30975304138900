import React from 'react';
import {useAppDispatch, useTypedSelector} from '../../../../hooks/store';
import {
  selectDisplayCategoriesFilters,
  selectProducts,
  selectSearchResultsCategoriesFilters,
  selectSubProductCategoriesFilters,
  selectTotalProducts,
  setLoadMoreAsync,
  selectOffset,
  setOffset,
} from '../../../../features/productCatalog/slice/productCatalog.slice';
import {useCustomScrollPercentage} from '../../../../Framework/Services/useCustomScrollPercentage';
import {useCallback, useEffect, useState} from 'react';
import ProductMapping from './ProductMapping';
import {Col, Spinner} from 'react-bootstrap';
import {Product} from '../../../../features/productCatalog/IProductCatalogState';

const ProductListProducts = (props: {
  productType: string | null;
  createProductRequestData: Function;
  isSearchResults: boolean;
}) => {
  const dispatch = useAppDispatch();
  const [ref, percentageScrolled] = useCustomScrollPercentage();

  let products: Product[] = useTypedSelector(selectProducts);
  let totalProducts = useTypedSelector(selectTotalProducts);
  let offset = useTypedSelector(selectOffset);
  let searchResultsCategoryFilter: any = useTypedSelector(selectSearchResultsCategoriesFilters);
  const displayCategoryFilter: any = useTypedSelector(selectDisplayCategoriesFilters);
  const subProductCategoryFilter: any = useTypedSelector(selectSubProductCategoriesFilters);

  const [scrolledFarEnough, setScrolledFarEnough] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const limit: number = 6;

  const loadMore = useCallback(
    async (offset: number) => {
      const params = props.createProductRequestData(
        props.isSearchResults ? searchResultsCategoryFilter : props.productType,
        offset,
        limit
      );
      params.data.append('displayCategories', displayCategoryFilter.join());
      params.data.append('subProductCategory', subProductCategoryFilter.join());
      await dispatch(setLoadMoreAsync(params));
      setLoading(false);
      setScrolledFarEnough(false);
    },
    [dispatch, props, searchResultsCategoryFilter, displayCategoryFilter, subProductCategoryFilter]
  );

  const handleScroll = useCallback(() => {
    if (percentageScrolled > 0.65 && !loading) {
      setScrolledFarEnough(true);
    } else if (percentageScrolled <= 0.69 && loading) {
      setScrolledFarEnough(false);
    }
  }, [percentageScrolled, loading]);

  const determineListTitle = () => {
    if (props?.isSearchResults) {
      return 'Search Results';
    } else {
      return props?.productType === 'Slab' ? props?.productType.toUpperCase() + 'S' : props?.productType?.toUpperCase();
    }
  };

  useEffect(() => {
    if (!loading && scrolledFarEnough && products.length <= offset && offset <= totalProducts) {
      setLoading(true);
      loadMore(offset);
      dispatch(setOffset(offset + limit));
    }
  }, [scrolledFarEnough, loadMore, limit, totalProducts, loading, products.length, offset, dispatch]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  useEffect(() => {
    dispatch(setOffset(6));
  }, [dispatch, props.productType, props.isSearchResults]);

  return (
    <Col ref={ref} md={9} className="product-list-list-container">
      <div className="productList">
        <div className="product-list">
          <div className="col-xs-12 hidden-xs">
            <h1 className="product-list-product-type-header">{determineListTitle()}</h1>
          </div>
          {/*// @ts-ignore*/}
          <ProductMapping
            products={products}
            productType={props.productType}
            isSearchResults={props.isSearchResults}
            limit={6}
            totalProducts={totalProducts}></ProductMapping>
        </div>
        {loading ? (
          <section className="product-list-load-more-button-container">
            <Spinner animation="border"></Spinner>
          </section>
        ) : (
          <div></div>
        )}
      </div>
    </Col>
  );
};

export default ProductListProducts;
