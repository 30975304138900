import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import qs from 'qs';
import COMMERCE_CORE_CONSTANTS from '../../../Core/constants';
import {baseApiCallWithReauth} from '../../../Framework/api-utils/api-utils';
import {RootState} from '../../../store/store';
import {IReportInitialState} from '../IReportsState';
import {
  getBolShipmentReportData,
  getBolShipmentReportSubscriptionData,
  getOpenSampleOrdersReportData,
  getOpenSampleOrdersReportSubscriptionData,
} from '../controllers/reports.controller';
import {getOpenFabOrdersReportData, getOpenFabOrdersReportSubscriptionData} from '../controllers/reports.controller';
import {mapOpenFabOrdersReportData} from '../service/reports.service';

export const initialState: IReportInitialState = {
  shipmentStatusReports: null,
  customerStatements: null,
  shipmentStatusEmailData: null,
  statusReportsLoading: true,
  customerStatementsLoading: true,
  openSampleOrdersReport: {
    entity: null,
    error: null,
    status: 'idle',
  },
  openSampleOrdersReportEmailData: null,
  openFabOrdersReport: {
    entity: null,
    error: null,
    status: 'idle',
  },
  bolShipmentReport: {
    entity: null,
    error: null,
    status: 'idle',
  },
  openFabOrdersReportEmailData: null,
  bolShipmentReportEmailData: null,
};

export const getShipmentStatusReportsAsync = createAsyncThunk(
  'reports/getShipmentStatusReportsAsync',
  async ({erpCustomerId}: {erpCustomerId: number}) => {
    const endpoint = `${COMMERCE_CORE_CONSTANTS.API_SERVICES.CUSTOMER.customers}/${erpCustomerId}${COMMERCE_CORE_CONSTANTS.API_SERVICES.CUSTOMER.loadReportV2}`;
    return await baseApiCallWithReauth('GET', endpoint);
  }
);

export const getCustomerStatementsAsync = createAsyncThunk(
  'reports/getCustomerStatementsAsync',
  async ({customerId}: {customerId: number}) => {
    const endpoint = `${COMMERCE_CORE_CONSTANTS.API_SERVICES.CUSTOMER.customers}/${customerId}${COMMERCE_CORE_CONSTANTS.API_SERVICES.CUSTOMER.statement}`;
    return await baseApiCallWithReauth('GET', endpoint);
  }
);

export const getShipmentReportEmailDataAsync = createAsyncThunk(
  'reports/getShipmentReportEmailDataAsync',
  async ({erpCustomerId, userId}: {erpCustomerId: number; userId: string}) => {
    const endpoint = `${COMMERCE_CORE_CONSTANTS.API_SERVICES.CUSTOMER.customers}/${erpCustomerId}/v2/report/subscribe`;
    return await baseApiCallWithReauth('GET', endpoint, qs.stringify({userId: userId, type: 'LoadReport'}));
  }
);

export const getOpenSampleOrdersReportDataAsync = createAsyncThunk(
  'reports/getOpenSampleOrdersReportDataAsync',
  async ({erpCustomerId}: {erpCustomerId: number}) => {
    return getOpenSampleOrdersReportData(erpCustomerId);
  }
);

export const getOpenSampleOrdersReportEmailDataAsync = createAsyncThunk(
  'reports/getOpenSampleOrdersReportEmailDataAsync',
  async ({erpCustomerId, userId}: {erpCustomerId: number; userId: string}) => {
    return await getOpenSampleOrdersReportSubscriptionData(erpCustomerId, userId);
  }
);

export const getOpenFabOrdersReportDataAsync = createAsyncThunk(
  'reports/getOpenFabOrdersReportDataAsync',
  async ({erpCustomerId}: {erpCustomerId: number}) => {
    const result = await getOpenFabOrdersReportData(erpCustomerId);
    return mapOpenFabOrdersReportData(result);
  }
);

export const getOpenFabOrdersReportEmailDataAsync = createAsyncThunk(
  'reports/getOpenFabOrdersReportEmailDataAsync',
  async ({erpCustomerId, userId}: {erpCustomerId: number; userId: string}) => {
    return await getOpenFabOrdersReportSubscriptionData(erpCustomerId, userId);
  }
);

export const getBolShipmentReportDataAsync = createAsyncThunk(
  'reports/getBolShipmentReportDataAsync',
  async ({erpCustomerId}: {erpCustomerId: number}) => {
    return getBolShipmentReportData(erpCustomerId);
  }
);

export const getBolShipmentReportEmailDataAsync = createAsyncThunk(
  'reports/getBolShipmentReportEmailDataAsync',
  async ({erpCustomerId, userId}: {erpCustomerId: number; userId: string}) => {
    return await getBolShipmentReportSubscriptionData(erpCustomerId, userId);
  }
);

export const reportsSlice = createSlice({
  name: 'reports',
  initialState,
  reducers: {
    setReportsInitialState: (state: any) => {
      for (const key in initialState) {
        state[key] = (initialState as any)[key];
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getShipmentStatusReportsAsync.pending, (state: any) => {
      state.statusReportsLoading = true;
    });
    builder.addCase(getShipmentStatusReportsAsync.fulfilled, (state: any, action: any) => {
      if (action.payload !== 'No Content') {
        state.shipmentStatusReports = action.payload;
      } else {
        state.shipmentStatusReports = null;
      }
      state.statusReportsLoading = false;
    });
    builder.addCase(getCustomerStatementsAsync.pending, (state: any) => {
      state.customerStatementsLoading = true;
    });
    builder.addCase(getCustomerStatementsAsync.fulfilled, (state: any, action: any) => {
      state.customerStatements = action.payload;

      state.customerStatementsLoading = false;
    });
    builder.addCase(getShipmentReportEmailDataAsync.fulfilled, (state: any, action: any) => {
      state.shipmentStatusEmailData = action.payload;
    });
    builder.addCase(getOpenSampleOrdersReportDataAsync.fulfilled, (state, action) => {
      state.openSampleOrdersReport.entity = action.payload;
      state.openSampleOrdersReport.status = 'succeeded';
      state.openSampleOrdersReport.error = null;
    });
    builder.addCase(getOpenSampleOrdersReportDataAsync.pending, (state) => {
      state.openSampleOrdersReport.entity = null;
      state.openSampleOrdersReport.status = 'loading';
      state.openSampleOrdersReport.error = null;
    });
    builder.addCase(getOpenSampleOrdersReportDataAsync.rejected, (state, action) => {
      state.openSampleOrdersReport.status = 'failed';
      state.openSampleOrdersReport.error = action.error.message ?? null;
    });
    builder.addCase(getOpenSampleOrdersReportEmailDataAsync.fulfilled, (state, action) => {
      state.openSampleOrdersReportEmailData = action.payload;
    });
    builder.addCase(getOpenFabOrdersReportDataAsync.fulfilled, (state, action) => {
      state.openFabOrdersReport.entity = action.payload;
      state.openFabOrdersReport.status = 'succeeded';
      state.openFabOrdersReport.error = null;
    });
    builder.addCase(getOpenFabOrdersReportDataAsync.pending, (state) => {
      state.openFabOrdersReport.entity = null;
      state.openFabOrdersReport.status = 'loading';
      state.openFabOrdersReport.error = null;
    });
    builder.addCase(getOpenFabOrdersReportDataAsync.rejected, (state, action) => {
      state.openFabOrdersReport.status = 'failed';
      state.openFabOrdersReport.error = action.error.message ?? null;
    });
    builder.addCase(getOpenFabOrdersReportEmailDataAsync.fulfilled, (state, action) => {
      state.openFabOrdersReportEmailData = action.payload;
    });
    builder.addCase(getBolShipmentReportDataAsync.fulfilled, (state, action) => {
      state.bolShipmentReport.entity = action.payload;
      state.bolShipmentReport.status = 'succeeded';
      state.bolShipmentReport.error = null;
    });
    builder.addCase(getBolShipmentReportDataAsync.pending, (state) => {
      state.bolShipmentReport.entity = null;
      state.bolShipmentReport.status = 'loading';
      state.bolShipmentReport.error = null;
    });
    builder.addCase(getBolShipmentReportDataAsync.rejected, (state, action) => {
      state.bolShipmentReport.status = 'failed';
      state.bolShipmentReport.error = action.error.message ?? null;
    });
    builder.addCase(getBolShipmentReportEmailDataAsync.fulfilled, (state, action) => {
      state.bolShipmentReportEmailData = action.payload;
    });
  },
});

export const {setReportsInitialState} = reportsSlice.actions;

export const selectCustomerStatementIsLoading = (state: RootState) => state.reports.customerStatementsLoading;
export const selectStatusReportIsLoading = (state: RootState) => state.reports.statusReportsLoading;
export const selectShipmentStatusReports = (state: RootState) => state.reports.shipmentStatusReports;
export const selectCustomerStatements = (state: RootState) => state.reports.customerStatements;
export const selectShipmentStatusReportEmailData = (state: RootState) => state.reports.shipmentStatusEmailData;
export const selectOpenSampleOrdersReport = (state: RootState) => state.reports.openSampleOrdersReport;
export const selectOpenSampleOrdersReportEmailData = (state: RootState) =>
  state.reports.openSampleOrdersReportEmailData;
export const selectOpenFabOrdersReport = (state: RootState) => state.reports.openFabOrdersReport;
export const selectOpenFabOrdersReportEmailData = (state: RootState) => state.reports.openFabOrdersReportEmailData;
export const selectBolShipmentReport = (state: RootState) => state.reports.bolShipmentReport;
export const selectBolShipmentReportEmailData = (state: RootState) => state.reports.bolShipmentReportEmailData;
export default reportsSlice.reducer;
