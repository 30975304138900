import React, {useState, FC, useEffect, useCallback, useRef} from 'react';
import {Form, Formik} from 'formik';
import * as Yup from 'yup';
import {toast} from 'react-toastify';
import {Alert, Col, Container, Row} from 'react-bootstrap';
import CambriaModal from '../../../../Framework/Components/CambriaModal';
import CambriaInput from '../../../../Framework/Components/CambriaInput';
import CambriaAutosuggest from '../../../../Framework/Components/CambriaAutosuggest';
import {useAppDispatch, useTypedSelector} from '../../../../hooks/store';
import {selectSalesforceCustomerAccount} from '../../../../features/salesforce/slice/salesforce.slice';
import {
  addressSearchAsync,
  getAddressValidation,
  getStatesOrProvinces,
  selectAddressSearchResults,
  selectAddressValidation,
  selectCountries,
  selectStates,
} from '../../../../features/location/slice/location.slice';
import {
  getCustomerShipToSitesAsync,
  selectCurrentCustomer,
  selectCurrentCustomerBillToSite,
  selectCurrentCustomerShipToSites,
  setSiteInfo,
} from '../../../../features/customer/slice/customer.slice';
import {saveNewShipToSite} from '../../../../features/checkout/service/checkout.service';
import CambriaSelect from '../../../../Framework/Components/CambriaSelect';
import COMMERCE_CORE_CONSTANTS from '../../../constants';
import {ICountry} from '../../../../features/location/ILocationState';
import {selectActiveCart} from '../../../../features/cart/slice/cart.slice';
import {
  hideFullscreenLoader,
  showFullscreenLoader,
} from '../../../../features/fullscreenLoader/slice/fullscreenLoader.slice';
import {getShippingWarehouse, getUserSelectedWarehouse} from '../../../../features/warehouse/service/warehouse.service';
import ExistingAddressSelect from './ExistingAddressSelect';
import SearchAllAddressesInput from './SearchAllAddressesInput';

interface AddShippingAddressModalProps {
  show?: boolean;
  toggleShow?: any;
  onSubmitCallback?: any;
  operatingUnitCode?: string;
  activeWarehouse?: any;
}

const AddShippingAddressModal: FC<AddShippingAddressModalProps> = ({
  show,
  toggleShow,
  onSubmitCallback,
  operatingUnitCode,
  activeWarehouse,
}) => {
  const dispatch = useAppDispatch();
  let formikRef = useRef();

  const [isAddressValidationInProgress, setIsAddressValidationInProgress] = useState<boolean>(false);
  const [isAddingSiteInProgress, setIsAddingSiteInProgress] = useState<boolean>(false);
  const [isFormVisible, setIsFormVisible] = useState<boolean>(false);
  const [validationErrors, setValidationErrors] = useState<Array<string>>([]);
  const [formData, setFormData] = useState<any>(null);
  const [originalAddress, setOriginalAddress] = useState<string>('');
  const [showSuggestionsSection, setShowSuggestionsSection] = useState<boolean>(false);
  const [addressSuggestions, setAddressSuggestions] = useState<Array<any>>([]);
  const [selectedCountry, setSelectedCountry] = useState<ICountry | null>(null);
  const [clearSuggestion, setClearSuggestion] = useState<boolean>(false);

  const cart: any = useTypedSelector(selectActiveCart);
  const customerShipToSites = useTypedSelector(selectCurrentCustomerShipToSites);
  const customerBillToSite = useTypedSelector(selectCurrentCustomerBillToSite);
  const allCountries = useTypedSelector(selectCountries);
  const currentCustomer = useTypedSelector(selectCurrentCustomer);
  const states = useTypedSelector(selectStates);
  const salesforceCustomerAccount = useTypedSelector(selectSalesforceCustomerAccount);
  const validationResult = useTypedSelector(selectAddressValidation);
  const address = useTypedSelector(selectAddressSearchResults);
  const isMarketrep = currentCustomer?.class === 'MARKETREP';

  const modalOptions = {
    title: 'Enter New Shipping Address',
    cancelButtonText: 'CANCEL',
    confirmButtonText: 'ADD ADDRESS',
    formName: 'addShippingAddressForm',
  };

  const isBasicInfoPage = activeWarehouse ? true : false;

  const initialFormValues = {
    selectExistingAddress: '',
    siteName: '',
    country: '',
    address1: '',
    address2: '',
    address3: '',
    city: '',
    state: '',
    postalCode: '',
    isFormVisible: false,
  };

  const validationSchema = Yup.object({
    selectExistingAddress: isFormVisible ? Yup.mixed() : Yup.mixed().required('This field is required'),
    siteName: Yup.string().required('This field is required'),
    country: Yup.mixed().required('This field is required'),
    address1: isFormVisible ? Yup.string().required('This field is required') : Yup.string(),
    address2: Yup.string(),
    address3: Yup.string(),
    city: isFormVisible ? Yup.string().required('This field is required') : Yup.string(),
    state: isFormVisible ? Yup.mixed().required('This field is required') : Yup.mixed(),
    postalCode: isFormVisible ? Yup.string().required('This field is required') : Yup.string(),
    isFormVisible: Yup.mixed(),
  });

  const onExistingAddressChange = (props: any, selectedSite: any) => {
    const {site} = selectedSite;
    if (props.values && site) {
      props.values.siteName = site.name;
      props.values.country = allCountries.find(
        (data: any) => data.alpha2Code.toLowerCase() === site.billingAddress.country.toLowerCase()
      );
      props.values.address1 = site.billingAddress.street;
      props.values.city = site.billingAddress.city;
      props.values.state = states.find(
        (state: any) => state.locationCode.toLowerCase() === site.billingAddress.state.toLowerCase()
      );
      props.values.postalCode = site.billingAddress.postalCode;
    }
  };

  const onAddressValidation = async (query: string) => {
    if (selectedCountry?.alpha2Code?.toLowerCase() === 'us') {
      await dispatch(addressSearchAsync({keyword: query}));
    }
  };

  const onAddressSelect = async (props: any, selectedAddress: any) => {
    if (props.values && selectedAddress) {
      props.values.country = allCountries.find(
        (data: any) => data.alpha2Code.toLowerCase() === selectedAddress.countryCode2Alpha.toLowerCase()
      );
      props.values.address2 = selectedAddress.addressLine2;
      props.values.address3 = selectedAddress.addressLine3;
      props.values.city = selectedAddress.city;
      props.values.state = states.find(
        (state: any) => state.locationCode.toLowerCase() === selectedAddress.state.toLowerCase()
      );
      props.values.postalCode = selectedAddress.postalCode;

      setAddressSuggestions(() => []);
      setShowSuggestionsSection(false);
      setValidationErrors(() => []);
      await dispatch(getAddressValidation(null));
    }
  };

  const hasAddressChangedResult = (form: any, records: any) => {
    // Result codes check excluding AC01 (see http://wiki.melissadata.com/index.php?title=Result_Codes)
    const resultCodesChecked = ['AC02', 'AC03', 'AC04', 'AC05', 'AC10', 'AC11', 'AC12', 'AC13', 'AC14', 'AC20'];
    let hasAddressChange = false;
    if (records.resultCodes && records.resultCodes.length > 0) {
      for (const resultCode of records.resultCodes) {
        if (resultCodesChecked.indexOf(resultCode.code) > -1) {
          hasAddressChange = true;
        }

        // Making sure that the postal code input and response are not equal before considering AC01
        if (resultCode.code === 'AC01' && form.postalCode !== records.postalCode) {
          hasAddressChange = true;
        }
      }
    }
    return hasAddressChange;
  };

  const formatExistingAddress = (form: any) => {
    return {
      siteName: form.siteName,
      country: form.country,
      address1: form.address1,
      address2: form.address2,
      address3: form.address3,
      state: form.state,
      city: form.city,
      postalCode: form.postalCode,
    };
  };

  const formatOriginalAddress = (form: any) => {
    const addressMap: any = {
      address1: form.address1,
      address2: form.address2,
      address3: form.address3,
      city: form.city,
      stateProvince: form.state.locationCode,
      postalCode: form.postalCode,
      country: form.country.countryName,
    };

    let address = '';
    for (const key in addressMap) {
      if (addressMap[key]) {
        address += `${addressMap[key]} `;
      }
    }

    setOriginalAddress(address);
  };

  const formatSuggestionAddress = (suggestion: any) => {
    const addressMap: any = {
      address1: suggestion.addressLine1,
      address2: suggestion.addressLine2,
      address3: suggestion.addressLine3,
      city: suggestion.city,
      stateProvince: suggestion.state,
      postalCode: suggestion.postalCode,
      country: suggestion.country,
    };

    let address = '';
    for (const key in addressMap) {
      if (addressMap[key]) {
        address += `${addressMap[key]} `;
      }
    }

    return address;
  };

  const onSaveSuccess = useCallback(
    async (formikRef: any) => {
      await dispatch(getAddressValidation(null));
      await dispatch(addressSearchAsync({keyword: null}));
      setAddressSuggestions(() => []);
      formikRef.resetForm();
      toast.success('New Address Saved');
    },
    [dispatch]
  );

  const onReset = useCallback(
    async (resetForm: Function) => {
      setIsFormVisible(false);
      setAddressSuggestions(() => []);
      resetForm();
      await dispatch(addressSearchAsync({keyword: null}));
      await dispatch(getAddressValidation(null));
    },
    [dispatch]
  );

  const saveNewAddress = useCallback(
    async (originalAddress: any, suggestion: any, formikRef: any) => {
      if (currentCustomer && currentCustomer.id) {
        setIsAddingSiteInProgress(true);
        let params: any = {};

        let warehouseCode: string = '';

        if (isBasicInfoPage) {
          warehouseCode = activeWarehouse.warehouseCode;
        } else if (currentCustomer && currentCustomer.class && currentCustomer.class === 'ENTERPRISE') {
          dispatch(showFullscreenLoader({showCartIcon: false}));
          toast.warning('Adding a new address for an enterprise customer class can sometimes take several minutes.');

          warehouseCode = getUserSelectedWarehouse() ?? '';
        } else if (currentCustomer.class === 'MARKETREP') {
          warehouseCode = '';
        } else {
          const currentShippingWarehouse = await getShippingWarehouse(
            cart,
            currentCustomer,
            customerShipToSites as any
          );

          warehouseCode =
            currentShippingWarehouse && currentShippingWarehouse.warehouseCode
              ? currentShippingWarehouse.warehouseCode
              : '';
        }

        if (originalAddress) {
          params = {
            siteName: originalAddress.siteName ? originalAddress.siteName.trim() : null,
            address: `${formikRef.values.siteName} ${originalAddress.address1} ${originalAddress.state.locationCode} ${originalAddress.postalCode}`,
            address1: originalAddress.address1 ? originalAddress.address1.trim() : null,
            address2: originalAddress.address2 ? originalAddress.address2.trim() : null,
            address3: originalAddress.address3 ? originalAddress.address3.trim() : null,
            city: originalAddress.city ? originalAddress.city.trim() : null,
            countryCode: originalAddress.country.alpha2Code ? originalAddress.country.alpha2Code.trim() : null,
            country: originalAddress.country.alpha2Code ? originalAddress.country.alpha2Code.trim() : null,
            postalCode: originalAddress.postalCode ? originalAddress.postalCode.trim() : null,
            stateProvince: originalAddress.state.locationCode ? originalAddress.state.locationCode.trim() : null,
            stateProvinceCode: originalAddress.state.locationCode ? originalAddress.state.locationCode.trim() : null,
            warehouseCode: warehouseCode ? warehouseCode : null,
          };
        }
        if (suggestion) {
          params = {
            siteName: formikRef.values.siteName ? formikRef.values.siteName.trim() : null,
            address: `${formikRef.values.siteName} ${suggestion.addressLine1} ${suggestion.state} ${suggestion.postalCode}`,
            address1: suggestion.addressLine1,
            address2: suggestion.addressLine2,
            address3: suggestion.addressLine3,
            city: suggestion.city,
            countryCode: suggestion.countryCode2Alpha,
            country: suggestion.countryCode2Alpha,
            postalCode: suggestion.postalCode,
            stateProvince: suggestion.state,
            stateProvinceCode: suggestion.state,
            warehouseCode: warehouseCode ? warehouseCode : null,
          };
        }

        if (!isBasicInfoPage) {
          params.siteType = 'SHIP_TO';
          params.retailCrmAccountId = salesforceCustomerAccount.id;
          if (currentCustomer.class !== 'MARKETREP') {
            params.operatingUnitCode = operatingUnitCode || customerBillToSite?.operatingUnitCode;
          }
        } else if (isBasicInfoPage && currentCustomer.class !== 'MARKETREP') {
          params.operatingUnitCode = operatingUnitCode || customerBillToSite?.operatingUnitCode;
        }

        try {
          let addressMatches = false;
          for (const sites of customerShipToSites!) {
            const trimmedSitesPostalCode = sites.postalCode ? sites.postalCode.split('-') : [''];
            const trimmedParamsPostalCode = params.postalCode ? params.postalCode.split('-') : [''];

            if (
              sites.siteName?.toLowerCase() === params.siteName?.toLowerCase() &&
              sites.address1?.toLowerCase() === params.address1?.toLowerCase() &&
              sites.address2?.toLowerCase() === params.address2?.toLowerCase() &&
              sites.address3?.toLowerCase() === params.address3?.toLowerCase() &&
              sites.city?.toLowerCase() === params.city?.toLowerCase() &&
              sites.stateProvince?.toLowerCase() === params.stateProvince?.toLowerCase() &&
              sites.country?.toLowerCase() === params.country?.toLowerCase() &&
              sites.warehouseCode?.toLowerCase() === params.warehouseCode?.toLowerCase() &&
              trimmedSitesPostalCode[0] === trimmedParamsPostalCode[0]
            ) {
              addressMatches = true;
            }
          }

          if (!addressMatches) {
            await saveNewShipToSite(params, dispatch);

            const sessionStorageCustomerId = window.sessionStorage.getItem(
              COMMERCE_CORE_CONSTANTS.WINDOW_SESSION.selectedCustomerId
            );

            if (sessionStorageCustomerId) {
              await dispatch(getCustomerShipToSitesAsync({customerId: sessionStorageCustomerId}));
              await dispatch(setSiteInfo({currentCustomer: currentCustomer}));
            }
          }

          if (onSubmitCallback) {
            onSubmitCallback(params);
          }

          onSaveSuccess(formikRef);
        } catch (error) {
          toast.error('Unable to confirm New Address');
        }

        dispatch(hideFullscreenLoader());
        setAddressSuggestions([]);
        setIsAddingSiteInProgress(false);
        setIsFormVisible(false);
        toggleShow();
      }
    },
    [
      toggleShow,
      currentCustomer,
      onSaveSuccess,
      onSubmitCallback,
      salesforceCustomerAccount,
      customerBillToSite,
      dispatch,
      operatingUnitCode,
      activeWarehouse,
      cart,
      customerShipToSites,
      isBasicInfoPage,
    ]
  );

  const validateAddressOnSubmit = async (form: any) => {
    setIsAddressValidationInProgress(true);
    setFormData(() => form);
    await dispatch(getAddressValidation(form));
  };

  useEffect(() => {
    if (isBasicInfoPage && !isFormVisible) {
      setIsFormVisible(true);
    }
  }, [isBasicInfoPage, isFormVisible]);

  useEffect(() => {
    const getStatesForSelectedCountry = async () => {
      if (selectedCountry && selectedCountry.countryName) {
        await dispatch(getStatesOrProvinces({countryName: selectedCountry.countryName}));
        setIsAddressValidationInProgress(false);
      }
    };

    getStatesForSelectedCountry();
  }, [selectedCountry, dispatch]);

  useEffect(() => {
    if (customerShipToSites && customerShipToSites.length > 0 && allCountries.length > 0) {
      let country = allCountries.find(
        (data: any) =>
          data.alpha2Code.toLowerCase() === customerShipToSites[0].country?.toLowerCase() ||
          data.alpha3Code.toLowerCase() === customerShipToSites[0].country?.toLowerCase()
      );

      if (country) {
        setSelectedCountry(country);
      }
    }
  }, [customerShipToSites, allCountries]);

  useEffect(() => {
    if (validationResult && validationResult.totalRecords > 0 && formData) {
      const result = JSON.parse(JSON.stringify(validationResult));
      setFormData(formData);
      setValidationErrors(validationResult.records.resultCodes);
      const suggestions: any = [];

      if (result.records.status === 'Pass') {
        if (hasAddressChangedResult(formData, result.records)) {
          if (result.records.postalCodeExtension) {
            result.records.postalCode += `-${result.records.postalCodeExtension}`;
          }
          formatOriginalAddress(formData);
          setShowSuggestionsSection(true);
          suggestions.push(result.records);
        } else {
          saveNewAddress(formData, null, formikRef);
          setShowSuggestionsSection(false);
          setFormData(null);
        }
      } else {
        if (result.suggestions) {
          for (const address of result.suggestions.address) {
            if (address.postalCodeExtension) {
              address.postalCode += `-${address.postalCodeExtension}`;
            }
            suggestions.push(address);
          }
        }
        formatOriginalAddress(formData);
        setShowSuggestionsSection(true);
      }

      if (addressSuggestions.length === 0 && suggestions.length > 0) {
        setAddressSuggestions(suggestions);
      }
      setIsAddressValidationInProgress(false);
    } else {
      setShowSuggestionsSection(false);
    }
  }, [validationResult, formData, addressSuggestions.length, saveNewAddress]);

  return (
    <Formik
      innerRef={(ref: any) => (formikRef = ref)}
      initialValues={initialFormValues}
      validationSchema={validationSchema}
      onSubmit={(props) => {
        if (isFormVisible) {
          validateAddressOnSubmit(props);
        } else {
          const originalAddress = formatExistingAddress(props);
          saveNewAddress(originalAddress, null, formikRef);
        }
      }}>
      {(props) => {
        return (
          <CambriaModal
            show={show}
            toggleShow={toggleShow}
            onCancel={() => {
              onReset(props.resetForm);
            }}
            heading={modalOptions.title}
            cancelButton={modalOptions.cancelButtonText}
            confirmButton={modalOptions.confirmButtonText}
            isValid={!props.isValid || !props.dirty}
            disableSubmitBtn={isAddressValidationInProgress || isAddingSiteInProgress}
            onSubmitFunctionIsRunning={isAddressValidationInProgress || isAddingSiteInProgress}
            formName={modalOptions.formName}>
            <Form id={modalOptions.formName} onSubmit={props.handleSubmit} onReset={props.handleReset} noValidate>
              <Container className="p-0">
                {!isFormVisible && !isBasicInfoPage && (
                  <Row>
                    <Col>
                      {isMarketrep ? (
                        <SearchAllAddressesInput
                          disabled={isAddressValidationInProgress || isAddingSiteInProgress}
                          onExistingAddressChange={(address) =>
                            onExistingAddressChange(props, {
                              site: {
                                name: address.name,
                                billingAddress: address,
                              },
                            })
                          }
                        />
                      ) : (
                        <ExistingAddressSelect
                          formikProps={props}
                          disabled={isAddressValidationInProgress || isAddingSiteInProgress}
                          onExistingAddressChange={onExistingAddressChange}
                        />
                      )}
                    </Col>
                  </Row>
                )}
                {(isFormVisible || isBasicInfoPage) && (
                  <>
                    <Row>
                      <Col>
                        <CambriaInput
                          name="siteName"
                          defaultValue={props.values.siteName}
                          label="Address Name"
                          placeholder="Address Name"
                          type="text"
                          required={true}
                          disabled={isAddressValidationInProgress}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <CambriaSelect
                          formikFormProps={props}
                          name="country"
                          defaultValue={selectedCountry}
                          items={allCountries}
                          label="Country"
                          placeholder="Country"
                          displayValue="countryName"
                          onChange={async (selection: ICountry) => {
                            if (selection.alpha2Code === selectedCountry?.alpha2Code) {
                              return;
                            }
                            setIsAddressValidationInProgress(true);
                            setSelectedCountry(selection);
                            if (selection.alpha2Code !== 'US') {
                              setAddressSuggestions(() => []);
                              await dispatch(addressSearchAsync({keyword: null}));
                              await dispatch(getAddressValidation(null));
                            }
                            setClearSuggestion(true);
                            props.setFieldValue('address1', '');
                            props.setFieldValue('address2', '');
                            props.setFieldValue('address3', '');
                            props.setFieldValue('city', '');
                            props.setFieldValue('postalCode', '');
                            props.setFieldValue('state', '');
                          }}
                          required={true}
                          disabled={isAddressValidationInProgress}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <CambriaAutosuggest
                          name="address1"
                          isAddressSearch={true}
                          defaultValue={props.values.address1}
                          options={address}
                          onSearch={(query: string) => onAddressValidation(query)}
                          onSelect={(address: any) => {
                            onAddressSelect(props, address);
                            setClearSuggestion(false);
                          }}
                          label="Street Address"
                          placeholder="Street Address"
                          displayValue="addressLine1"
                          required={true}
                          clearSuggestion={clearSuggestion}
                          disabled={isAddressValidationInProgress}></CambriaAutosuggest>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <CambriaInput
                          name="address2"
                          defaultValue={props.values.address2}
                          label="Apartment or Suite Number"
                          placeholder="Apartment or Suite Number"
                          type="text"
                          disabled={isAddressValidationInProgress}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <CambriaInput
                          name="address3"
                          defaultValue={props.values.address3}
                          label="Address Line 3"
                          placeholder="Address Line 3"
                          type="text"
                          disabled={isAddressValidationInProgress}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <CambriaInput
                          name="city"
                          defaultValue={props.values.city}
                          label="City"
                          placeholder="City"
                          type="text"
                          required={true}
                          disabled={isAddressValidationInProgress}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6} sm={12}>
                        <CambriaSelect
                          formikFormProps={props}
                          name="state"
                          defaultValue={props.values.state}
                          items={states}
                          label="State / Province"
                          placeholder="Select State / Province"
                          displayValue="stateName"
                          required={true}
                          disabled={isAddressValidationInProgress}
                        />
                      </Col>
                      <Col md={6} sm={12}>
                        <CambriaInput
                          name="postalCode"
                          defaultValue={props.values.postalCode}
                          label="Postal Zip Code"
                          placeholder="Postal Zip Code"
                          type="text"
                          required={true}
                          disabled={isAddressValidationInProgress}
                        />
                      </Col>
                    </Row>
                  </>
                )}
                {!isBasicInfoPage && (
                  <Row>
                    <Col>
                      <CambriaInput
                        name="isFormVisible"
                        defaultValue={props.values.isFormVisible}
                        label="Enter a New Address"
                        type="checkbox"
                        disabled={isAddressValidationInProgress || isAddingSiteInProgress}
                        onChange={() => {
                          setIsFormVisible((currentValue: boolean) => !currentValue);
                        }}
                      />
                    </Col>
                  </Row>
                )}
                <Row>
                  {showSuggestionsSection && (
                    <Col className="m-t-sm">
                      <Alert variant="info" dismissible>
                        <div className="m-4">New Shipping Address cannot be validated</div>
                        <hr />
                        <div className="m-4">
                          {validationErrors.map((error: any) => (
                            <div key={error.code}>
                              {error.code}: {error.message}
                            </div>
                          ))}
                        </div>
                        <hr />
                        {addressSuggestions.length > 0 && (
                          <div className="m-4">
                            <strong>Keep the Original Address</strong>
                            <div className="radio">
                              <input
                                type="radio"
                                id="enteredAddress"
                                onClick={() => saveNewAddress(props.values, null, formikRef)}
                                disabled={isAddingSiteInProgress}
                              />
                              <label htmlFor="enteredAddress">{originalAddress}</label>
                            </div>
                            <strong>Update the Address</strong>
                            {addressSuggestions.map((suggestion, i) => {
                              return (
                                <div className="radio" key={i}>
                                  <input
                                    type="radio"
                                    id={i.toString()}
                                    onClick={() => saveNewAddress(null, suggestion, formikRef)}
                                    disabled={isAddingSiteInProgress}
                                  />
                                  <label htmlFor={i.toString()}>{formatSuggestionAddress(suggestion)}</label>
                                </div>
                              );
                            })}
                          </div>
                        )}
                        {addressSuggestions.length === 0 && (
                          <div className="m-4">
                            There are no suggested address for your inputs.
                            <br />
                            Please review the form and try again or save this address.
                            <br />
                            <div className="radio">
                              <input
                                type="radio"
                                id="enteredAddress"
                                onClick={() => saveNewAddress(props.values, null, formikRef)}
                                disabled={isAddingSiteInProgress}
                              />
                              <label htmlFor="enteredAddress">{originalAddress}</label>
                            </div>
                          </div>
                        )}
                      </Alert>
                    </Col>
                  )}
                </Row>
              </Container>
            </Form>
          </CambriaModal>
        );
      }}
    </Formik>
  );
};

export default AddShippingAddressModal;
