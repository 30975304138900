import React from 'react';
import DateBlockContainer from './DateBlock.styled';

const DateBlock = ({date}: {date: Date | null}) => {
  const formatAMPM = (date: Date) => {
    let hours = date.getHours();
    let minutes: string | number = date.getMinutes();
    let ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    return hours + ':' + minutes + ' ' + ampm;
  };

  let year = date ? date.getFullYear().toString() : null;
  let month = date ? date.toLocaleString('en-us', {month: 'short'}) : null;
  let day = date ? date.getDate() : null;
  let time = date ? formatAMPM(date) : null;

  return (
    <DateBlockContainer className="date-block">
      <div>
        {month && <span className="month">{month}</span>} {year && <span className="year">{year}</span>}
      </div>
      {day && <span className="day">{day.toString()}</span>}
      {time && <span className="time">{time}</span>}
    </DateBlockContainer>
  );
};
export default DateBlock;
