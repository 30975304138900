import localForage from 'localforage';

export const setForage = (id: string, data: Object): void => {
  localForage.setItem(id, data);
};

export const getForage = (id: string): any => {
  return localForage.getItem(id);
};

export const removeForage = (id: string): void => {
  localForage.removeItem(id);
};
