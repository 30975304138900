import {baseApiCallWithReauth} from '../../../Framework/api-utils/api-utils';
import COMMERCE_CORE_CONSTANTS from '../../../Core/constants';
import qs from 'qs';

export const getProofOfDeliveryByDeliveryNumber = async (params: any) => {
  try {
    return await baseApiCallWithReauth(
      'GET',
      `${COMMERCE_CORE_CONSTANTS.API_SERVICES.LASTMILE.proofOfDelivery}/deliveryNumbers`,
      qs.stringify(params)
    );
  } catch (error: any) {
    throw error;
  }
};

export const getQRCodeByCustomer = async (erpCustomerId: string) => {
  const params = {
    customerNumber: erpCustomerId,
    includeCustomerName: true,
  };

  const qrCodeAsBlob = await baseApiCallWithReauth(
    'GET',
    `${COMMERCE_CORE_CONSTANTS.API_SERVICES.LASTMILE.qrCode}/optinByCustomer`,
    qs.stringify(params),
    false,
    false,
    false,
    true
  );
  let qrCodeUrl = URL.createObjectURL(qrCodeAsBlob);
  return qrCodeUrl;
};
