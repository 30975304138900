import styled from 'styled-components';
import {$gray55, $gray60} from '../../../../../../Styled/variables';

const TotalPriceContainer = styled.div`
  .totals-container {
    background-color: #e4e4e4;
    padding: 20px;
    border-radius: 3px;

    .header-row {
      color: ${$gray55};
      font-size: 12px;
      margin-top: 0px;
      padding: 0px 15px 0px 0px;

      .header-text {
        text-align: start;
      }
    }

    .header-row.totals {
      color: ${$gray55};
      font-size: 12px;
      margin-top: 0px;
      padding-right: 0px;
    }

    .total-row {
      font-size: 14px;
      margin-top: 5px;

      .total-num-col {
        text-align: start;
        display: flex;
        flex-direction: column;
      }
    }

    .table-bold-value {
      font-weight: 700;
    }

    .table-secondary-value {
      font-weight: 700;
      font-size: 10px;
      color: ${$gray60};
    }

    .table-value-currency-alignment {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  }
`;

export default TotalPriceContainer;
