import styled from 'styled-components/macro';
import {$brandprimary, $gray30, $gray93, $gray97, $newwhite, $screenmd} from '../../../../Styled/variables';

const OrderPageContainer = styled.div`
  .order-table {
    .order-table-header {
      margin-bottom: 5px;
      .order-table-header-cell {
        font-weight: 700;
        font-size: 12px;
        color: #464646;
        padding: 10px 0px 10px 30px;
        background: ${$gray97};
      }
    }

    .order-table-body {
      box-shadow: inset 0px 2px 5px 0px rgba(101, 101, 101, 0.35);
    }
    .order-table-row {
      font-size: 12px;
      box-shadow: 0px 1px 3px 0px rgba(101, 101, 101, 0.35);
      :nth-of-type(even) {
        background-color: ${$gray93};
      }
      :nth-of-type(odd) {
        background-color: ${$newwhite};
      }
      :hover {
        background-color: #f9ecc9;
        cursor: pointer;
      }

      .order-name {
        font-weight: 600;
        color: #7f7f7f;
      }
      .order-address-name {
        font-size: 12px;
        font-weight: 600;
        color: ${$gray30};
      }

      .order-amount {
        font-size: 12px;
        font-weight: 700;
      }
      .order-status-success {
        font-weight: 700;
        color: #c59617;
      }
      .order-status-pending {
        font-weight: 700;
        color: #7f7f7f;
      }
    }

    th {
      z-index: 2;
      top: 0;
      background: white;
      border-bottom: none;
    }
    tr {
      :hover {
        background-color: #f9ecc9;
      }
    }
  }
  .order-search-section {
    display: flex;
    flex-direction: column;
  }
  .order-list-header {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
  .number {
    float: right;
  }
  .order-table-wrapper {
    margin-top: 20px;
  }
  .load-more-button {
    box-shadow: 0px 1px 3px 0px rgba(135, 135, 135, 0.35);
    display: flex;
    align-content: center;
    justify-content: center;
    color: ${$brandprimary};
    font-weight: 600;
    padding: 10px;
    background: ${$newwhite};
    :hover {
      cursor: pointer;
    }
    .fa-plus {
      margin: 4px 8px 0px 0px;
      font-size: 12px;
    }
  }
  .mobile-ui-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .no-results-row {
    height: 250px;
    :hover {
      background-color: ${$gray97} !important;
      cursor: default;
    }
    .no-results {
      text-align: center;
      font-weight: 600;
      color: ${$gray30};
      margin-top: 30%;
      display: flex;
      justify-content: center;
      align-content: center;
      height: 250px;
      font-size: 16px;
    }
  }
  .no-results-found-mobile {
    width: 100%;
    height: 200px;
    display: flex;
    justify-content: center;
    margin-top: 100px;
    font-weight: 700;
    font-size: 16px;
  }
  @media screen and (max-width: ${$screenmd}) {
    .order-list-header {
      display: none;
    }
    .load-more-button {
      width: 340px;
    }
    .order-search-section {
      align-items: center;
    }
  }
`;
export default OrderPageContainer;
