import styled from 'styled-components/macro';

const CustomerPlaceholderContainer = styled.tr`
	.customer-name {
		width: 33%;
	}

	.class {
		width: 25%;
	}
	.rs-placeholder {
		.rs-placeholder-paragraph-rows {
			width: 100px !important;
		}
		p {
			background: #dfdfdf;
			width: 100% !important;
		}
	}
`;

export const CustomerPlaceholderMobileContainer = styled.div`
	.placeholder-header {
		display: flex;
	}
	.rs-panel {
		height: 70px;
	}
	.accordion-button {
		background: white !important;
		border: none !important;
		.placeholder-header {
			margin-top: 8px !important;
		}
	}
`;

export default CustomerPlaceholderContainer;
