import React from 'react';
import AddressBlock from '../../../../../../Core/Components/OrderGrid/AddressBlock';
import Icon from '../../../../../../Framework/Components/Icon';
import {IAddressCardData} from '../../../../../../features/order/orderDetails/IOrderDetailsState';

const AddressContactInformation = ({name, phone, email}: {name: string; phone: string; email: string}) => {
  return (
    <div className="contact-information">
      <div className="contact-line-item d-flex align-items-center">
        <Icon icon="icons-cambria-Hr-Single" size="28" weight="900" color="black" colorOnHover="black" />
        <span>{name}</span>
      </div>
      <div className="contact-line-item d-flex align-items-center">
        <Icon icon="icons-cambria-Symbols-Mail" size="28" weight="900" color="black" colorOnHover="black" />
        <span className="contact-line-item-text-overflow">{email}</span>
      </div>
      <div className="contact-line-item d-flex align-items-center">
        <Icon icon="icons-cambria-Ui-Phone" size="28" weight="900" color="black" colorOnHover="black" />
        <span>{phone}</span>
      </div>
    </div>
  );
};
const AddressCard = ({addressCardInfo}: {addressCardInfo: IAddressCardData}) => {
  return (
    <div>
      <span className="address-header">{addressCardInfo.type}</span>
      <div className="order-detail-card">
        <AddressBlock
          shipToName={addressCardInfo.locationName}
          address1={addressCardInfo.addressLine1}
          address2={addressCardInfo.addressLine2}
          address3={addressCardInfo.addressLine3}
          city={addressCardInfo.city}
          stateProvinceCode={addressCardInfo.stateProvince}
          postalCode={addressCardInfo.postalCode}
        />
        <AddressContactInformation
          name={addressCardInfo.contactName}
          email={addressCardInfo.email}
          phone={addressCardInfo.phone}
        />
      </div>
    </div>
  );
};

export default AddressCard;
