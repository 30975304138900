import {Program} from '../../salesforce/program/IProgramState';
import {ProductTypeResponse} from '../../order/orderDetails/IOrderDetailsState';
import {baseApiCallWithReauth} from '../../../Framework/api-utils/api-utils';
import COMMERCE_CORE_CONSTANTS from '../../../Core/constants';
import qs from 'qs';
import {CustomerFullProfile} from '../../customer/ICustomerState';

export const getFabricatedProductsByCodeRequest = async (
  productGroupCode: string,
  cartItemType: string,
  erpCustomerId: string,
  customerClass: string,
  customerClassGroups: Array<string>,
  selectedProgram: Program
): Promise<ProductTypeResponse> => {
  try {
    return await baseApiCallWithReauth(
      'GET',
      `${COMMERCE_CORE_CONSTANTS.API_SERVICES.PRODUCT.fabricatedProducts}/${productGroupCode}/items`,
      qs.stringify({
        cartItemType: cartItemType,
        erpCustomerId: erpCustomerId,
        customerClass: customerClass,
        customerClassGroups: customerClassGroups,
        programCodes: [selectedProgram.code],
      })
    );
  } catch (error: any) {
    throw error;
  }
};

export const getFabricatedProductsRequest = async (customer: CustomerFullProfile) => {
  try {
    return await baseApiCallWithReauth(
      'GET',
      COMMERCE_CORE_CONSTANTS.API_SERVICES.PRODUCT.fabricatedProducts,
      qs.stringify({
        erpCustomerId: customer.erpCustomerId,
        customerClassGroups: customer.classGroups,
      })
    );
  } catch (error: any) {
    throw error;
  }
};

export const getProductGroupItemsRequest = async (
  productGroupCode: string,
  erpCustomerId: string,
  customerClassGroups: Array<string>,
  program: Program
): Promise<ProductTypeResponse> => {
  try {
    return await baseApiCallWithReauth(
      'GET',
      `${COMMERCE_CORE_CONSTANTS.API_SERVICES.PRODUCT.fabricatedProducts}/${productGroupCode}/items`,
      qs.stringify({
        erpCustomerId: erpCustomerId,
        customerClassGroups: customerClassGroups,
        programCodes: [program.code],
      })
    );
  } catch (error: any) {
    throw error;
  }
};
