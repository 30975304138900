import {useCallback} from 'react';
import {useAppDispatch, useTypedSelector} from '../../../hooks/store';
import {
  selectCurrentCustomer,
  selectCurrentCustomerBillToSites,
  selectCurrentCustomerShipToSites,
} from '../../customer/slice/customer.slice';
import {ISalesforceAvailablePrograms} from '../../salesforce/ISalesforceState';
import {selectActiveProgram, selectAvailablePrograms} from '../../salesforce/slice/salesforce.slice';
import {CartItem} from '../ICartState';
import {addCartItem} from '../service/cart.service';
import {selectActiveCart} from '../slice/cart.slice';
import {selectCurrentUser} from '../../auth/slice/authentication.slice';
import {
  selectCalculateCartDiscountsInBackend,
  selectIsRevH,
  selectIsRevI,
} from '../../environment/slice/environment.slice';

export const useAddCartItem = (): ((cartItem: CartItem) => Promise<any>) => {
  const dispatch = useAppDispatch();
  let cart: any = useTypedSelector(selectActiveCart);
  let customer = useTypedSelector(selectCurrentCustomer);
  let shipToSites: any = useTypedSelector(selectCurrentCustomerShipToSites);
  let billToSites: any = useTypedSelector(selectCurrentCustomerBillToSites);
  let program = useTypedSelector(selectActiveProgram);
  let user: any = useTypedSelector(selectCurrentUser);
  const calculateDiscountsInBackend = useTypedSelector(selectCalculateCartDiscountsInBackend);
  const isRevH = useTypedSelector(selectIsRevH);
  const isRevI = useTypedSelector(selectIsRevI);
  const isRev = isRevH || isRevI;
  const availablePrograms: ISalesforceAvailablePrograms | null = useTypedSelector(selectAvailablePrograms);
  return useCallback(
    (cartItem: CartItem) => {
      return addCartItem(
        cartItem,
        cart,
        customer,
        shipToSites,
        billToSites,
        program,
        user.userId,
        dispatch,
        availablePrograms,
        isRev,
        calculateDiscountsInBackend
      );
    },
    [
      cart,
      customer,
      shipToSites,
      billToSites,
      program,
      user.userId,
      dispatch,
      availablePrograms,
      isRev,
      calculateDiscountsInBackend,
    ]
  );
};
