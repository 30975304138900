import {baseApiCallWithReauth} from '../../../../Framework/api-utils/api-utils';
import {ProductRecommendationsResponse} from '../IProductSearchState';

export const searchForProducts = async (
  data: URLSearchParams,
  endpoint: string
): Promise<ProductRecommendationsResponse | undefined> => {
  try {
    return await baseApiCallWithReauth('GET', endpoint, data);
  } catch (error) {
    console.error(error);
  }
};
