import styled from 'styled-components/macro';
import {$brandprimary, $gray50, $gray67} from '../../../Styled/variables';

const StatusCodeStyled = styled.div`
  font-weight: 700;
  div {
    border-radius: 3px;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .primary {
    background-color: ${$brandprimary};
    color: white;
  }
  .default {
    background-color: ${$gray50};
    color: white;
  }
  .danger {
    background-color: #9a4200;
    color: white;
  }
  .default-light {
    background-color: ${$gray67};
    color: white;
  }
`;
export default StatusCodeStyled;
