import React, {useState} from 'react';
import {Dropdown} from 'react-bootstrap';
import {useHistory} from 'react-router-dom';
import {OrderSearchParams} from '../../../../../../../features/search/ISearchState';
import COMMERCE_CORE_CONSTANTS from '../../../../../../constants';
import {cleanSearchParams, addOrderStringInput} from '../../../../../../../features/order/service/order.service';

const DropdownInput = ({
  options,
  urlSearchParams,
  activeFilter,
  url,
}: {
  options: string[];
  urlSearchParams: OrderSearchParams;
  activeFilter: string;
  url: string;
}) => {
  const history = useHistory();

  let [selectedOrderType, setOrderType] = useState('Fabricated');
  let [selectedOrderStatus, setOrderStatus] = useState(
    url === COMMERCE_CORE_CONSTANTS.PATHNAMES.orderSearch ? 'OrderReceived' : 'Order Received'
  );

  const handleDropdownSelection = (filter: string, selection: string) => {
    if (filter === 'Status') {
      setOrderStatus(selection);
    } else if (filter === 'Order Type') {
      setOrderType(selection);
    }
  };

  const addDropdownInputParam = (currentReduxFilters: OrderSearchParams, paramName: string, inputValue: string) => {
    let currentReduxCopy = {...currentReduxFilters};
    currentReduxCopy = addOrderStringInput(paramName, currentReduxCopy, inputValue, url);

    let data = new URLSearchParams(currentReduxCopy);
    data = cleanSearchParams(data);
    history.push({
      pathname: url,
      search: '?' + data.toString(),
    });
  };

  return (
    <div className="input-and-button-container">
      <Dropdown style={{width: '100%'}}>
        <Dropdown.Toggle id="dropdown" className="text-left secondary-dropdown-toggle">
          {activeFilter === 'Status' ? selectedOrderStatus : selectedOrderType}
        </Dropdown.Toggle>
        <Dropdown.Menu className="secondary-menu">
          {options.map((option: string) => (
            <Dropdown.Item
              key={option}
              onClick={() => {
                handleDropdownSelection(activeFilter, option);
              }}>
              {option}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
      <div className="search-button-container">
        <button
          className="search-button btn-primary"
          onClick={() =>
            addDropdownInputParam(
              urlSearchParams,
              activeFilter,
              activeFilter === 'Status' ? selectedOrderStatus : selectedOrderType
            )
          }>
          SEARCH
        </button>
      </div>
    </div>
  );
};
export default DropdownInput;
