import React, {useCallback, useEffect, useState} from 'react';
import {Form, Formik} from 'formik';
import {Col, Container, Row} from 'react-bootstrap';
import * as Yup from 'yup';
import {PurchasingCustomerContainer} from './SlabOrderDetails.styled';
import AddPurchasingCustomerModal from '../../../../../Core/Components/Modals/AddPurchasingCustomerModal';
import CambriaButton from '../../../../../Framework/Components/CambriaButton';
import CambriaInput from '../../../../../Framework/Components/CambriaInput';
import Icon from '../../../../../Framework/Components/Icon';
import {useAppDispatch, useTypedSelector} from '../../../../../hooks/store';
import {Cart, OrderDetail} from '../../../../../features/cart/ICartState';
import {getJobTypes, getOrderTypes} from '../../../../../features/checkout/service/checkout.service';
import {selectActiveCart, setActiveCart} from '../../../../../features/cart/slice/cart.slice';
import {
  invalidateStep1,
  selectActiveStep,
  selectStep1Completed,
  submitOrderDetails,
} from '../../../../../features/checkout/slice/checkout.slice';
import {
  selectActiveProgram,
  selectAvailablePrograms,
  selectSalesforceCustomerAccount,
} from '../../../../../features/salesforce/slice/salesforce.slice';
import {
  selectCurrentCustomer,
  selectCurrentCustomerBillToSites,
  selectCurrentCustomerShipToSites,
} from '../../../../../features/customer/slice/customer.slice';
import {getAllAvailableCountries} from '../../../../../features/location/slice/location.slice';
import PurchasingCustomersBlock from './PurchasingCustomersBlock';
import CambriaMultiInput from '../../../../../Framework/Components/CambriaMultiInput';
import CambriaSelect from '../../../../../Framework/Components/CambriaSelect';
import {
  hideFullscreenLoader,
  showFullscreenLoader,
} from '../../../../../features/fullscreenLoader/slice/fullscreenLoader.slice';
import {
  getRetailAccountsForACustomer,
  isBundleProgram,
} from '../../../../../features/salesforce/service/salesforce.service';
import {toast} from 'react-toastify';
import COMMERCE_CORE_CONSTANTS from '../../../../../Core/constants';
import {postCustomerRetailSitesRequest} from '../../../../../features/customer/controller/customer.controller';
import {selectCurrentUser} from '../../../../../features/auth/slice/authentication.slice';
import {ISalesforceAvailablePrograms} from '../../../../../features/salesforce/ISalesforceState';
import {getCart} from '../../../../../features/cart/service/cart.service';
import {pushAddOrderDetailsToDataLayer} from '../../../../../features/analytics/service/analytics.service';

interface SamplesOrderDetailsProps {
  orderDetails: OrderDetail | undefined;
}

const SlabOrderDetails = ({orderDetails}: SamplesOrderDetailsProps) => {
  const setInitialJobTypeValue = () => {
    if (orderDetails && orderDetails.jobType) {
      return getJobTypes(activeProgram.code, currentCustomer.class).find(
        (type: any) => type.code === orderDetails.jobType || type.displayName === orderDetails.jobType
      );
    }

    if (activeProgram.code === 'HD001') {
      return getJobTypes(activeProgram.code, currentCustomer.class).find(
        (type: any) => type.code === 'Residential-Remodel'
      );
    }

    if (
      isBundleProgram(activeProgram) ||
      (activeProgram.code !== 'HD001' &&
        (currentCustomer.class === 'LEXUS' || currentCustomer.class === 'DIS' || currentCustomer.class === 'CDAL'))
    ) {
      return getJobTypes(activeProgram.code, currentCustomer.class).find((type: any) => type.code === 'Stock');
    }

    return null;
  };

  const getInitialOrderTypeValue = () => {
    if (orderDetails && orderDetails.projectType) {
      return getOrderTypes().find(
        (type: any) =>
          type.code?.toUpperCase() === orderDetails.projectType?.toUpperCase() ||
          type.displayName?.toUpperCase() === orderDetails.projectType?.toUpperCase()
      );
    }

    if (activeProgram.code === 'HD001') {
      return getOrderTypes().find((type: any) => type.code === 'New');
    }

    if (
      activeProgram.code !== 'HD001' &&
      (currentCustomer.class === 'LEXUS' || currentCustomer.class === 'DIS' || currentCustomer.class === 'CDAL')
    ) {
      return getOrderTypes().find((type: any) => type.code === 'New');
    }
  };

  const activeStep = useTypedSelector(selectActiveStep);
  const step1Completed = useTypedSelector(selectStep1Completed);
  const activeCart = useTypedSelector(selectActiveCart);
  const activeProgram = useTypedSelector(selectActiveProgram);
  const customersAdded = activeCart?.purchasingCustomers;
  const currentCustomer = useTypedSelector(selectCurrentCustomer);
  const salesforceCustomerAccount = useTypedSelector(selectSalesforceCustomerAccount);
  const customerShipToSites = useTypedSelector(selectCurrentCustomerShipToSites);
  const customerBillToSites = useTypedSelector(selectCurrentCustomerBillToSites);
  const currentUser = useTypedSelector(selectCurrentUser);
  const availablePrograms: ISalesforceAvailablePrograms | null = useTypedSelector(selectAvailablePrograms);

  const dispatch = useAppDispatch();

  const [isBuilderOrHDISProgram, setIsBuilderOrHDISProgram] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [isInitialized, setIsInitialized] = useState<boolean>(false);
  const [jobType, setJobType] = useState(setInitialJobTypeValue());
  const [orderType] = useState(getInitialOrderTypeValue());
  const [homeDepotOrderNumbers, setHomeDepoOrderNumbers] = useState<string[]>([]);
  const [homeDepotOrderNumbersRequired, setHomeDepotOrderNumbersRequired] = useState(
    activeProgram.code === 'HD001' && orderDetails?.jobType !== 'Stock'
  );
  const [showHdRequiredError, setShowHdRequiredError] = useState<boolean>(false);
  const [availableRetailAccounts, setAvailableRetailAccounts] = useState<any>(null);
  const [hideAddPurchasingCustomerButton, setHideAddPurchasingCustomerButton] = useState<boolean>(false);
  const [isCheckingToPreselectPc, setIsCheckingToPreselectPC] = useState<boolean>(true);
  const [initializingPC, setInitializingPC] = useState<boolean>(false);

  const onJobTypeChange = (selectedJobType: any) => {
    if (activeProgram.code === 'HD001' && selectedJobType.displayName !== 'Stock') {
      dispatch(invalidateStep1());
      setHomeDepotOrderNumbersRequired(true);
    } else {
      setHomeDepotOrderNumbersRequired(false);
      setShowHdRequiredError(false);
    }

    setJobType(selectedJobType);
  };

  const getNewCart = useCallback(async () => {
    const cart = await getCart(
      activeCart?.id || '',
      currentCustomer,
      currentCustomer?.id?.toString() || '0',
      activeProgram,
      customerShipToSites || [],
      customerBillToSites || [],
      dispatch,
      availablePrograms
    );
    await dispatch(setActiveCart(cart));
  }, [
    activeCart?.id,
    activeProgram,
    customerShipToSites,
    customerBillToSites,
    currentCustomer,
    dispatch,
    availablePrograms,
  ]);

  const preselectPurchasingCustomer = useCallback(
    async (partnerAccounts: any) => {
      const {site: pc} = partnerAccounts[0];
      const params = {
        countryCode: pc.country,
        stateProvinceCode: pc.stateProvince,
        siteName: pc.siteName,
        address1: pc.address1,
        address2: pc.address2,
        address3: pc.address3,
        city: pc.city,
        postalCode: pc.postalCode,
        isActive: true,
        retailCrmAccountId: pc.retailCrmAccountId,
        erpCustomerId: currentCustomer.erpCustomerId.toString(),
        cartId: activeCart?.id,
        userId: currentUser?.userId,
      };
      try {
        await postCustomerRetailSitesRequest(params);
      } catch (error) {
        toast.error('Unable to preselect Purchasing Customer');
        throw error;
      } finally {
        await getNewCart();
      }
    },
    [getNewCart, activeCart?.id, currentCustomer.erpCustomerId, currentUser?.userId]
  );

  const toggleShowModal = () => setShowModal((p) => !p);

  const submitForm = useCallback(
    async (values: any) => {
      if (isCheckingToPreselectPc) {
        return;
      }
      dispatch(showFullscreenLoader({showCartIcon: false}));
      values.projectName =
        (jobType && jobType.displayName === 'Commercial-New') ||
        (jobType && jobType.displayName === 'Commercial-New Construction') ||
        (jobType && jobType.displayName === 'Commercial-Remodel')
          ? values.projectName
            ? values.projectName.trim()
            : ''
          : '';
      values.city =
        (jobType && jobType.displayName === 'Commercial-New') ||
        (jobType && jobType.displayName === 'Commercial-New Construction') ||
        (jobType && jobType.displayName === 'Commercial-Remodel')
          ? values.city
            ? values.city.trim()
            : ''
          : '';
      values.designFirmName =
        (jobType && jobType.displayName === 'Commercial-New') ||
        (jobType && jobType.displayName === 'Commercial-New Construction') ||
        (jobType && jobType.displayName === 'Commercial-Remodel')
          ? values.designFirmName
            ? values.designFirmName.trim()
            : ''
          : '';
      values.homeDepotOrderNumbers = activeProgram.code === 'HD001' ? homeDepotOrderNumbers : [];
      values.purchaseOrderNumber = values.purchaseOrderNumber ? values.purchaseOrderNumber.trim() : '';

      if (homeDepotOrderNumbersRequired && homeDepotOrderNumbers.length === 0) {
        setShowHdRequiredError(true);
        dispatch(hideFullscreenLoader());
        return;
      }

      if (
        isBuilderOrHDISProgram &&
        (!activeCart?.purchasingCustomers || activeCart?.purchasingCustomers.length === 0)
      ) {
        toast.error('A purchasing customer is required on this order.', {autoClose: false, toastId: 'PC-Required-Id'});
        dispatch(hideFullscreenLoader());
        return;
      }

      await dispatch(
        submitOrderDetails({
          activeCart,
          orderDetails: values,
        })
      );
      pushAddOrderDetailsToDataLayer({cart: activeCart as Cart});
    },
    [
      activeCart,
      activeProgram,
      dispatch,
      homeDepotOrderNumbers,
      homeDepotOrderNumbersRequired,
      jobType,
      isBuilderOrHDISProgram,
      isCheckingToPreselectPc,
    ]
  );

  useEffect(() => {
    if (isCheckingToPreselectPc) {
      dispatch(showFullscreenLoader({showCartIcon: false}));
    } else {
      dispatch(hideFullscreenLoader());
    }
  }, [isCheckingToPreselectPc, dispatch]);

  useEffect(() => {
    if (showHdRequiredError && homeDepotOrderNumbers.length > 0) {
      setShowHdRequiredError(false);
    }
  }, [homeDepotOrderNumbers, showHdRequiredError]);

  useEffect(() => {
    if (!!customersAdded && customersAdded.length > 0 && toast.isActive('PC-Required-Id')) {
      toast.dismiss('PC-Required-Id');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customersAdded]);

  useEffect(() => {
    if (homeDepotOrderNumbersRequired && homeDepotOrderNumbers.length === 0) {
      dispatch(invalidateStep1());
    }
  }, [homeDepotOrderNumbersRequired, homeDepotOrderNumbers, dispatch]);

  useEffect(() => {
    dispatch(getAllAvailableCountries());
  }, [dispatch]);

  useEffect(() => {
    const intiPCModalBlock = async () => {
      if (initializingPC) {
        return;
      }
      setInitializingPC(true);
      try {
        const programIsBuilderOrHDISAndNoPartners =
          (activeProgram.code === 'BP001' || activeProgram.code === 'HDIS001') && availableRetailAccounts.length === 0;
        const programIsBuilderAndOnlyOneBuilderPartner =
          (activeProgram.code === 'BP001' || activeProgram.code === 'HDIS001') && availableRetailAccounts.length === 1;

        if (programIsBuilderOrHDISAndNoPartners) {
          let partnerType = activeProgram.code === 'BP001' ? 'builder' : 'HDIS';
          toast.error(
            `No ${partnerType} is associated to your account, please contact ${COMMERCE_CORE_CONSTANTS.customerCarePhoneNumber} to assist`,
            {autoClose: false}
          );
          setHideAddPurchasingCustomerButton(true);
        } else if (programIsBuilderAndOnlyOneBuilderPartner) {
          if (customersAdded?.length === 0 && isCheckingToPreselectPc) {
            setHideAddPurchasingCustomerButton(true);
            if (!isCheckingToPreselectPc) {
              await preselectPurchasingCustomer(availableRetailAccounts);
            }
            setHideAddPurchasingCustomerButton(false);
          } else {
            setHideAddPurchasingCustomerButton(false);
          }
        } else {
          setHideAddPurchasingCustomerButton(false);
        }
      } catch (ex) {
        setHideAddPurchasingCustomerButton(false);
      } finally {
        if (isCheckingToPreselectPc) {
          setIsCheckingToPreselectPC(false);
          setInitializingPC(false);
        }
      }
    };

    if (!!availableRetailAccounts && !!customersAdded && activeProgram) {
      intiPCModalBlock();
    }
  }, [
    availableRetailAccounts,
    activeProgram,
    customersAdded,
    initializingPC,
    isCheckingToPreselectPc,
    preselectPurchasingCustomer,
  ]);

  useEffect(() => {
    const getRetailAccountsForDropdown = async () => {
      try {
        const result = await getRetailAccountsForACustomer(currentCustomer);
        let organizedArray: any[] = [];
        result.forEach((retailSite: any) => {
          if (
            (activeProgram.code === 'BP001' && retailSite.segment !== 'Builder') ||
            (activeProgram.code === 'HDIS001' && retailSite.segment !== 'HDI')
          ) {
            return;
          }
          organizedArray.push(organizeAddress(retailSite));
        });
        setAvailableRetailAccounts(organizedArray);
      } catch (e) {
        throw e;
      }
    };

    const organizeAddress = (site: any) => {
      return {
        displayName: site.address,
        value: `${site.siteName}`,
        site: site,
      };
    };

    if (
      salesforceCustomerAccount &&
      Object.keys(salesforceCustomerAccount).length > 0 &&
      currentCustomer &&
      activeProgram &&
      activeProgram.code
    ) {
      getRetailAccountsForDropdown();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [salesforceCustomerAccount, currentCustomer, dispatch, activeProgram]);

  useEffect(() => {
    if (step1Completed && !isInitialized) {
      setIsInitialized(true);
      dispatch(invalidateStep1());
    }

    return () => {};
  }, [step1Completed, dispatch, isInitialized, setIsInitialized]);

  useEffect(() => {
    setIsBuilderOrHDISProgram(activeProgram.code === 'BP001' || activeProgram.code === 'HDIS001');
  }, [activeProgram]);

  return (
    <div>
      <Formik
        initialValues={{
          jobType: jobType,
          orderType: orderType,
          purchaseOrderNumber: orderDetails?.purchaseOrderNumber?.trim() || '',
          homeDepotOrderNumbers: orderDetails?.homeDepotOrderNumbers || [],
          projectName: orderDetails?.projectName || '',
          city: orderDetails?.projectCity || '',
          designFirmName: orderDetails?.relatedAgency || '',
        }}
        validationSchema={Yup.object({
          jobType: Yup.mixed().required('This field is required'),
          orderType: Yup.mixed().required('This field is required'),
          purchaseOrderNumber: Yup.string()
            .max(50, 'The maximum length of this field is 50')
            .matches(/^[a-zA-Z0-9\\,:"()<>+;/~*. ]*$/, 'This field does not support the special character entered.'),
          homeDepotOrderNumbers: Yup.mixed(),
          projectName: Yup.string(),
          city: Yup.string(),
          designFirmName: Yup.string(),
        })}
        onSubmit={(values) => submitForm(values)}>
        {(props) => {
          return (
            <Form id={activeStep?.step} noValidate onSubmit={props.handleSubmit}>
              <Container className="p-0">
                <Row>
                  <div className={'col-12 col-lg-6'}>
                    <CambriaSelect
                      formikFormProps={props}
                      name={'jobType'}
                      defaultValue={jobType}
                      label={'Job Type'}
                      placeholder={'Job Type'}
                      items={getJobTypes(activeProgram.code, currentCustomer.class)}
                      displayValue={'displayName'}
                      onChange={(selectedType: any) => onJobTypeChange(selectedType)}
                      required={true}></CambriaSelect>
                  </div>
                  <div className={'col-12 col-lg-6'}>
                    <CambriaSelect
                      formikFormProps={props}
                      name={'orderType'}
                      defaultValue={orderType}
                      label={'Order Type'}
                      placeholder={'Order Type'}
                      items={getOrderTypes()}
                      displayValue={'displayName'}
                      required={true}></CambriaSelect>
                  </div>
                  <div className={'col-12 col-lg-6'}>
                    <CambriaInput
                      name={'purchaseOrderNumber'}
                      defaultValue={orderDetails?.purchaseOrderNumber || ''}
                      label={'Purchase Order ID'}
                      placeholder={'Purchase Order ID'}
                      type={'text'}
                      required={false}></CambriaInput>
                  </div>
                  {activeProgram.code === 'HD001' ? (
                    <div className={'col-12 col-lg-6'}>
                      <CambriaMultiInput
                        values={orderDetails?.homeDepotOrderNumbers || []}
                        fieldName="Home Depot Order Numbers"
                        name={'homeDepotOrderNumbers'}
                        required={homeDepotOrderNumbersRequired}
                        label={'Home Depot Order Numbers'}
                        placeholder="Home Depot Order Numbers"
                        onChange={setHomeDepoOrderNumbers}
                        type="number"
                        showRequiredError={showHdRequiredError}
                      />
                    </div>
                  ) : (
                    <div></div>
                  )}
                  {(jobType && jobType.displayName === 'Commercial-New') ||
                  (jobType && jobType.displayName === 'Commercial-New Construction') ||
                  (jobType && jobType.displayName === 'Commercial-Remodel') ? (
                    <div className={'col-12 col-lg-6'}>
                      <CambriaInput
                        name={'projectName'}
                        defaultValue={orderDetails?.projectName || ''}
                        label={'Project Name'}
                        placeholder={'Project Name'}
                        type={'text'}
                        required={false}></CambriaInput>
                    </div>
                  ) : (
                    <div></div>
                  )}
                  {(jobType && jobType.displayName === 'Commercial-New') ||
                  (jobType && jobType.displayName === 'Commercial-New Construction') ||
                  (jobType && jobType.displayName === 'Commercial-Remodel') ? (
                    <div className={'col-12 col-lg-6'}>
                      <CambriaInput
                        name={'city'}
                        defaultValue={orderDetails?.projectCity || ''}
                        label={'City'}
                        placeholder={'City'}
                        type={'text'}
                        required={false}></CambriaInput>
                    </div>
                  ) : (
                    <div></div>
                  )}
                  {(jobType && jobType.displayName === 'Commercial-New') ||
                  (jobType && jobType.displayName === 'Commercial-New Construction') ||
                  (jobType && jobType.displayName === 'Commercial-Remodel') ? (
                    <div className={'col-12 col-lg-6'}>
                      <CambriaInput
                        name={'designFirmName'}
                        defaultValue={orderDetails?.relatedAgency || ''}
                        label={'Architecture & Design Firm Name'}
                        placeholder={'Architecture & Design Firm Name'}
                        type={'text'}
                        required={false}></CambriaInput>
                    </div>
                  ) : (
                    <div></div>
                  )}
                </Row>
                <Row>
                  <Col>
                    <PurchasingCustomerContainer>
                      <h2>Purchasing Customers & Consumers</h2>
                      {isCheckingToPreselectPc || (customersAdded && customersAdded.length === 0) ? (
                        <p>
                          Entering the purchasing customer and consumer information allows Cambria to support and
                          service the end users.
                        </p>
                      ) : (
                        <PurchasingCustomersBlock customers={customersAdded ?? []} canEdit={!isBuilderOrHDISProgram} />
                      )}
                      {isCheckingToPreselectPc || hideAddPurchasingCustomerButton ? (
                        <></>
                      ) : (
                        <CambriaButton onClick={toggleShowModal} variant="secondary">
                          <Icon icon="glyph glyphicon-plus glyphicon--plus" color="#c59617" size="22" weight="500" />
                          ADD NEW CUSTOMER
                        </CambriaButton>
                      )}
                    </PurchasingCustomerContainer>
                  </Col>
                </Row>
              </Container>
            </Form>
          );
        }}
      </Formik>
      {showModal && (!hideAddPurchasingCustomerButton || !isCheckingToPreselectPc) && (
        <AddPurchasingCustomerModal
          availableAddresses={availableRetailAccounts ?? []}
          show={showModal}
          toggleShow={toggleShowModal}
          canAddNewPurchasingCustomer={!isBuilderOrHDISProgram}></AddPurchasingCustomerModal>
      )}
    </div>
  );
};

export default SlabOrderDetails;
