import {useCallback, useState, useEffect} from 'react';

export function useLocalStorage(key: string, defaultValue?: any) {
  return useStorage(key, window.localStorage, defaultValue);
}

export function useSessionStorage(key: string, defaultValue?: any) {
  return useStorage(key, window.sessionStorage, defaultValue);
}

function useStorage(key: string, storageObject: Storage, defaultValue?: any) {
  const [value, setValue] = useState(() => {
    const jsonValue = storageObject.getItem(key);
    if (jsonValue != null) {
      return JSON.parse(jsonValue);
    }

    if (typeof defaultValue === 'function') {
      return defaultValue();
    }
    return defaultValue;
  });

  useEffect(() => {
    if (value === undefined) {
      return storageObject.removeItem(key);
    }
    storageObject.setItem(key, JSON.stringify(value));
  }, [key, value, storageObject]);

  const remove = useCallback(() => {
    setValue(undefined);
  }, []);

  return [value, setValue, remove];
}
