import React, {FC} from 'react';
import {useBundleCartItems} from '../../../../features/cart/hooks/useBundleCartItems/useBundleCartItems';
import {cartItemsAlphabetized} from '../../../../features/cart/service/cart.service';
import CartItems from '../CartItems/CartItems.component';
import {useTypedSelector} from '../../../../hooks/store';
import {
  selectCartCalculationsComplete,
  selectDisableRemoveButtonWhileRemoving,
  selectIsMovingItemToSaveForLater,
} from '../../../../features/cart/slice/cart.slice';
import {useRemoveCartBundle} from '../../../../features/cart/hooks/useRemoveCartBundle/useRemoveCartBundle';
import {CartItem} from '../../../../features/cart/ICartState';
import {CartItemsByBundleContainer} from './CartItemsByBundle.styled';

export interface CartItemsByBundleProps {
  isRevisionMode: boolean;
  removeCartItemButton: (cartItem: CartItem) => any;
  saveForLater: (cartItem: CartItem) => any;
  updateQuantity: (cartItem: CartItem, value: any) => any;
}

const CartItemsByBundle: FC<CartItemsByBundleProps> = ({
  isRevisionMode,
  removeCartItemButton,
  saveForLater,
  updateQuantity,
}) => {
  const cartItemsByBundle = useBundleCartItems();
  const isMovingItemToSaveForLater = useTypedSelector(selectIsMovingItemToSaveForLater);
  const disableRemoveButtonWhileRemoving = useTypedSelector(selectDisableRemoveButtonWhileRemoving);
  const cartCalculationsComplete = useTypedSelector(selectCartCalculationsComplete);
  const removeCartBundle = useRemoveCartBundle();
  return (
    <CartItemsByBundleContainer>
      {cartItemsByBundle?.map(({cartItems, bundleId}) => (
        <div key={'bundle-cartitems-' + bundleId} className={`mt-5 ${bundleId ? 'bundle-container' : ''}`}>
          {bundleId && (
            <>
              <h2>Bundle {bundleId}</h2>
              <button
                type="button"
                className="btn btn-link sub-text p-x-0 remove-from-cart"
                disabled={!cartCalculationsComplete || isRevisionMode || disableRemoveButtonWhileRemoving}
                onClick={() => {
                  removeCartBundle(bundleId);
                }}>
                <small className="item-field-remove-save-buttons pull-left">REMOVE FROM CART</small>
              </button>
            </>
          )}
          <CartItems
            cartItems={cartItemsAlphabetized(cartItems)}
            isRevisionMode={isRevisionMode}
            isMovingItemToSaveForLater={isMovingItemToSaveForLater}
            disableRemoveButtonWhileRemoving={disableRemoveButtonWhileRemoving}
            removeCartItemButton={removeCartItemButton}
            saveForLater={saveForLater}
            updateQuantity={updateQuantity}></CartItems>
        </div>
      ))}
    </CartItemsByBundleContainer>
  );
};

export default CartItemsByBundle;
