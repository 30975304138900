import {baseApiCallWithReauth} from '../../../Framework/api-utils/api-utils';
import COMMERCE_CORE_CONSTANTS from '../../../Core/constants';
import {PreferredShippingContactRequest, PurchasingCustomer} from '../ICustomerState';
import qs from 'qs';
import {toast} from 'react-toastify';

export const postCustomerContactRequest = async (id: number, params: any) => {
  try {
    return await baseApiCallWithReauth(
      'POST',
      `${COMMERCE_CORE_CONSTANTS.API_SERVICES.CUSTOMER.customers}/${id}${COMMERCE_CORE_CONSTANTS.API_SERVICES.CUSTOMER.contact}`,
      JSON.stringify(params),
      true
    );
  } catch (error: any) {
    throw error;
  }
};

export const getCustomerRetailSitesRequest = async (
  params: any
): Promise<{results: PurchasingCustomer[]; totalResults: number}> => {
  try {
    return await baseApiCallWithReauth('GET', COMMERCE_CORE_CONSTANTS.API_SERVICES.CUSTOMER.retailSites, params);
  } catch (error: any) {
    throw error;
  }
};

export const postCustomerRetailSitesRequest = async (params: any) => {
  try {
    return await baseApiCallWithReauth(
      'POST',
      COMMERCE_CORE_CONSTANTS.API_SERVICES.CUSTOMER.retailSites,
      JSON.stringify(params)
    );
  } catch (error: any) {
    throw error;
  }
};

export const putPurchasingRetailSitesRequest = async (id: number, params: any): Promise<void> => {
  try {
    return await baseApiCallWithReauth(
      'PUT',
      `${COMMERCE_CORE_CONSTANTS.API_SERVICES.CUSTOMER.retailSites}/${id}`,
      JSON.stringify(params)
    );
  } catch (error: any) {
    throw error;
  }
};

export const deleteCustomerRetailSitesRequest = async (id: number): Promise<void> => {
  try {
    return await baseApiCallWithReauth('DELETE', `${COMMERCE_CORE_CONSTANTS.API_SERVICES.CUSTOMER.retailSites}/${id}`);
  } catch (error: any) {
    throw error;
  }
};

export const getEndConsumersRequest = async (params: any) => {
  try {
    return await baseApiCallWithReauth(
      'GET',
      COMMERCE_CORE_CONSTANTS.API_SERVICES.CUSTOMER.endConsumers,
      qs.stringify(params)
    );
  } catch (error: any) {
    throw error;
  }
};
export const putEndConsumersRequest = async (
  id: number,
  params?: {
    isActive: boolean;
    erpCustomerId: number;
    cartId: string;
    retailSiteId: number;
    contactFirstName: string;
    contactLastName: string;
    contactPhoneNumber: string;
    contactEmail: string;
    address1: string;
    address2: string;
    address3: string;
    address4: string;
    city: string;
    stateProvinceCode: string;
    postalCode: string;
    countryCode: string;
    jobNumber: string;
    orderNumber: string;
  }
) => {
  try {
    return await baseApiCallWithReauth(
      'PUT',
      `${COMMERCE_CORE_CONSTANTS.API_SERVICES.CUSTOMER.endConsumers}/${id}`,
      JSON.stringify(params)
    );
  } catch (error: any) {
    throw error;
  }
};

export const postEndConsumersRequest = async (params: any): Promise<any> => {
  try {
    return await baseApiCallWithReauth(
      'POST',
      COMMERCE_CORE_CONSTANTS.API_SERVICES.CUSTOMER.endConsumers,
      JSON.stringify(params),
      false,
      false,
      true
    );
  } catch (error: any) {
    throw error;
  }
};

export const getCustomerSitesRequest = async (id: number | string, params: any) => {
  try {
    return await baseApiCallWithReauth(
      'GET',
      `${COMMERCE_CORE_CONSTANTS.API_SERVICES.CUSTOMER.customers}/${id}/${COMMERCE_CORE_CONSTANTS.API_SERVICES.CUSTOMER.sites}`,
      params
    );
  } catch (error: any) {
    throw error;
  }
};

export const postCustomerSiteRequest = async (id: number | undefined, params: any) => {
  try {
    if (id) {
      return await baseApiCallWithReauth(
        'POST',
        `${COMMERCE_CORE_CONSTANTS.API_SERVICES.CUSTOMER.customers}/${id}${COMMERCE_CORE_CONSTANTS.API_SERVICES.CUSTOMER.site}`,
        JSON.stringify(params),
        true
      );
    }
  } catch (error: any) {
    throw error;
  }
};

export const getCustomers = async (params: any) => {
  try {
    const results = baseApiCallWithReauth('GET', COMMERCE_CORE_CONSTANTS.API_SERVICES.CUSTOMER.customers, params);

    return results;
  } catch (error: any) {
    throw error;
  }
};

export const putPreferredShippingContacts = async (data: Array<PreferredShippingContactRequest>) => {
  let putEndpoint =
    COMMERCE_CORE_CONSTANTS.API_SERVICES.CUSTOMER.preferredShippingContact + `/${data[0].erpCustomerId}`;
  let results = await baseApiCallWithReauth('PUT', putEndpoint, JSON.stringify(data), true);
  if (results.status === 200) {
    toast.success('Preferred shipping contact have been successfully updated!');
  }
};

export const postPreferredShippingContacts = async (data: Array<PreferredShippingContactRequest>) => {
  let results = await baseApiCallWithReauth(
    'POST',
    COMMERCE_CORE_CONSTANTS.API_SERVICES.CUSTOMER.preferredShippingContact,
    JSON.stringify(data),
    true
  );
  if (results.status === 200) {
    toast.success('Preferred shipping contact have been successfully updated!');
  }
};
