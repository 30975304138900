import styled from 'styled-components/macro';

export const CartTotalContainer = styled.div`
  margin: 30px 0px;

  .totals-container {
    background-color: #4c4c4c;
    padding: 20px;
    border-radius: 3px;

    .header-row {
      font-size: 12px;
      margin-top: 0px;
      padding: 0px 15px 0px 0px;

      .cart-price-text {
        text-align: start;
        color: #fff;
      }

      .cart-total-text {
        text-align: end;
        font-weight: 500;
        color: #c8c8c8;
      }

      @media (max-width: 960px) {
        .cart-price-text {
          text-align: right;
          font-size: 17px;
          margin-top: 2px;
        }

        .cart-total-text {
          text-align: left;
          font-size: 20px;
        }
      }
    }

    .header-row.totals {
      font-size: 12px;
      margin-top: 0px;
      padding-right: 0px;
    }

    .total-row {
      font-size: 14px;
      margin-top: 5px;

      .total-num-col {
        text-align: start;
        display: flex;
        flex-direction: column;
      }
    }

    .table-bold-value {
      font-weight: 700;
    }

    .table-secondary-value {
      font-weight: 700;
      font-size: 10px;
      color: #c8c8c8;
    }

    .table-value-currency-alignment {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  }
`;
