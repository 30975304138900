import styled from 'styled-components/macro';
import {$gray67, $gray78, $gray97, $newblack, $newprimary, $newwhite} from '../../../../Styled/variables';

export const CheckoutNavigationContainer = styled.div`
  width: 100%;
  position: relative;
  padding: 20px 0;

  @media (max-width: 960px) {
    margin-top: 50px;
  }

  .background-line {
    display: block;
    position: absolute;
    top: 0;
    width: 100%;
    height: 2px;
    background-color: ${$gray67};

    &:before {
      content: '';
      display: block;
      height: 30px;
      width: 2px;
      position: absolute;
      left: 0%;
      top: -15px;
      background-color: ${$gray67};
    }

    &:after {
      content: '';
      display: block;
      height: 30px;
      width: 2px;
      position: absolute;
      right: 0%;
      top: -15px;
      background-color: ${$gray67};
    }
  }

  .navigation-items {
    position: absolute;
    width: 100%;
    top: -15px;
    display: flex;
    justify-content: space-around;

    .navigation-item {
      padding: 4px 12px;
      background-color: ${$gray97};
      border: 2px solid ${$gray67};
      font-size: 12px;
      font-weight: 600;
      text-transform: uppercase;
      color: ${$newblack};
      cursor: pointer;
      transition: 0.25s all;
      border-radius: 3px;

      &:hover {
        background-color: ${$gray78};
      }

      &.active {
        background-color: ${$newprimary};
        border: 2px solid ${$gray97};
        color: ${$newwhite};
      }

      &.isDisabled {
        background-color: ${$gray67} !important;
        cursor: not-allowed;
      }
    }

    .navigation-item-disabled {
    }
  }
`;

export const ActiveStepHeading = styled.h1`
  font-weight: 300 !important;
  font-size: 36px !important;
  line-height: 44px !important;
  margin: 10px 0 30px 0 !important;
`;

export const CheckoutButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;

  .next-step-spinner {
    color: #ffffff !important;
  }

  .place-order-btn {
    padding: 10px 50px !important;
  }

  @media (max-width: 960px) {
    flex-flow: column;
    margin: 20px 0;

    button {
      margin: 10px 0 !important;
      justify-content: center;
    }
  }
`;
