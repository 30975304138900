import React from 'react';
import {useHistory} from 'react-router-dom';
import {QuoteFilter} from '../../../../../../features/search/ISearchState';
import {selectQuoteSearch} from '../../../../../../features/search/slice/search.slice';
import {useTypedSelector} from '../../../../../../hooks/store';
import {cleanSearchParams} from '../../../../../../features/order/service/order.service';
import {addQuoteStringInput} from '../../../../../../features/quote/service/quote.service';

const ActiveFiltersDisplay = ({activeSearchParams, url}: {activeSearchParams: any[]; url: string}) => {
  let history = useHistory();

  let urlSearchParams = useTypedSelector(selectQuoteSearch).quoteUrlSearchParams;

  const clearParamFromUrlSearch = (name: string) => {
    let paramsToSave;
    if (name === 'Date') {
      paramsToSave = {...urlSearchParams};
      paramsToSave['dateRange.dateTo'] = '';
      paramsToSave['dateRange.dateFrom'] = '';
    } else if (name === 'Amount') {
      paramsToSave = {...urlSearchParams};
      paramsToSave['amountRange.amountTo'] = '';
      paramsToSave['amountRange.amountFrom'] = '';
    } else {
      paramsToSave = addQuoteStringInput(name, urlSearchParams, '');
    }
    let data = new URLSearchParams(paramsToSave);
    data = cleanSearchParams(data);
    history.push({
      pathname: url,
      search: '?' + data.toString(),
    });
  };

  return (
    <div className="display-params">
      {activeSearchParams ? (
        activeSearchParams.map((item: QuoteFilter) => (
          <div className="active-param" key={item.name}>
            <div className="name">{item.name}</div>
            <div className="value">
              {item.value}
              <i className="fa fa-times" onClick={() => clearParamFromUrlSearch(item.name)}></i>
            </div>
          </div>
        ))
      ) : (
        <div></div>
      )}
    </div>
  );
};
export default ActiveFiltersDisplay;
