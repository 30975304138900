import {SingleItemPriceRequest} from '../IPricingState';
import {callback} from '../../callbacks/service/callbacks.service';
import {toast} from 'react-toastify';
import {CustomerFullProfile, Site} from '../../customer/ICustomerState';
import {Program} from '../../salesforce/program/IProgramState';
import {ISalesforceAvailablePrograms} from '../../salesforce/ISalesforceState';
import {getCart} from '../../cart/service/cart.service';
import {applyHeaderAdjustment} from '../../cart/slice/cart.slice';
import {
  deleteCartHeaderAdjustmentRequest,
  deleteCartVoucherCodesRequest,
  getPricingDiscountsRequest,
  getPricingSingleItemPricingRequest,
  getSingleItemBatchRequest,
  postCartHeaderAdjustmentRequest,
  postCartVoucherCodesRequest,
  postPricingDiscountsRequest,
} from '../controller/pricing.controller';

export const getSingleItemPriceBatch = async (singleItemBatchRequest: SingleItemPriceRequest[], dispatch: any) => {
  const callbackId = await getSingleItemBatchRequest({request: singleItemBatchRequest});
  let callbackIdString = await callbackId.text();
  try {
    return await callback(callbackIdString, 90, dispatch);
  } catch (error: any) {
    if (error.status === 'Failure') {
      toast.error(
        'One of the current items does not have a price configured. Please contact the Cambria Business Office to have this corrected.'
      );
    }
    throw error;
  }
};

export const getSingleItemPrice = async (
  erpCustomerId: string,
  warehouseCode: string,
  programCode: string,
  itemNumber: string,
  siteUseId: number,
  customerClass: string,
  dispatch: any
) => {
  if (!warehouseCode) {
    toast.error(
      'This item is not available in the selected warehouse. Please contact the finance department for assistance.'
    );
    return;
  }

  const callbackId = await getPricingSingleItemPricingRequest({
    erpCustomerId,
    warehouseCode,
    programCode,
    itemNumber,
    siteUseId,
    customerClass,
  });

  try {
    const stringId = await callbackId.text();
    // wip
    const results = await callback(stringId, 30, dispatch);
    return results;
  } catch (error: any) {
    throw error;
  }
};

export const postDiscounts = async (cartId: number | string, customer: CustomerFullProfile, dispatch: any) => {
  const params = {
    cartId,
    erpCustomerId: customer.erpCustomerId.toString(),
  };
  const callbackId = await postPricingDiscountsRequest(params);
  const stringId = await callbackId.text();
  try {
    return await callback(stringId, 90, dispatch);
  } catch (error: any) {
    console.error(error);
  }
};

export const getDiscounts = async (
  customer: CustomerFullProfile,
  product?: {items: Array<any>; productType: string; discountCategory: string},
  checkout?: boolean,
  quantity?: number,
  discountCategories?: Array<string>,
  lastCartId?: number | string
) => {
  let params = new URLSearchParams();
  if (lastCartId) {
    params.append('lastCartId', lastCartId.toString());
  } else {
    let cartItemType = 'Standard';
    if (product && product.items && product.items.length > 0) {
      cartItemType = product.items[0].bomItemType;
    }
    if (!checkout) {
      checkout = false;
    }
    quantity = !quantity ? 1 : quantity;
    if (product) {
      params.append('productType', product.productType);
    }
    params.append('customerClass', customer.class);
    params.append('cartItemType', cartItemType);
    params.append('checkout', checkout ? 'True' : 'False');
    params.append('quantity', quantity.toString());
    discountCategories?.forEach((category) => {
      params.append('discountCategories', category);
    });
  }
  return getPricingDiscountsRequest(params);
};

export const postCartVoucherCodes = async (
  voucherCode: String,
  cartId: number | string,
  customer: CustomerFullProfile,
  dispatch: any
) => {
  const callbackId = await postCartVoucherCodesRequest({
    cartId,
    erpCustomerId: customer.erpCustomerId.toString(),
    voucherCode: voucherCode.trim(),
  });
  const stringId = await callbackId.text();
  try {
    return await callback(stringId, 90, dispatch);
  } catch (error: any) {
    console.error(error);
    throw error;
  }
};

export const deleteProductCartVoucher = async (validationId: string, cartId: number | string, dispatch: any) => {
  const callbackId = await deleteCartVoucherCodesRequest(validationId, cartId);
  const stringId = await callbackId.text();
  try {
    return await callback(stringId, 90, dispatch);
  } catch (error: any) {
    console.error(error);
    throw error;
  }
};

export const addHeaderAdjustment = async (
  headerAdjustmentId: number,
  value: number,
  cartId: string,
  reasonText: string,
  customer: CustomerFullProfile,
  userId: any,
  activeProgram: Program,
  shipToSites: Site[],
  billToSites: Site[],
  availablePrograms: ISalesforceAvailablePrograms | null,
  dispatch: any
) => {
  try {
    await postCartHeaderAdjustmentRequest({
      headerAdjustmentId: headerAdjustmentId,
      value: value,
      cartId: cartId,
      reasonText: reasonText.trim(),
    });
    const updatedCart = await getCart(
      cartId,
      customer,
      userId,
      activeProgram,
      shipToSites,
      billToSites,
      dispatch,
      availablePrograms
    );
    dispatch(applyHeaderAdjustment(updatedCart));
  } catch (error: any) {
    throw error;
  }
};

export const deleteProductHeaderAdjustment = async (headerAdjustmentId: any, dispatch: any) => {
  const callbackId = await deleteCartHeaderAdjustmentRequest(headerAdjustmentId);
  const stringId = await callbackId.text();
  try {
    return await callback(stringId, 90, dispatch);
  } catch (error: any) {
    console.error(error);
  }
};

export const formatPrice = (price: string | number) => {
  let formattedPrice = '0.00';
  const priceDividend = 100;
  const fractionDigits = 2;

  if (price) {
    formattedPrice = (parseFloat(price.toString()) / priceDividend).toFixed(fractionDigits);
  }

  return formattedPrice;
};
