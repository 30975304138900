import React, {useState} from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import ContentLoader from 'react-content-loader';
import {CartItemsContainer} from '../CartItems/CartItems.styled';
import {CartPage} from '../Cart.styled';

const CartItemsPlaceholders = () => {
  const [placeholderCartItems] = useState([1, 2]);

  return (
    <CartItemsContainer className="items-container">
      {placeholderCartItems.map((cartItem: number, index: number) => {
        return (
          <div key={`cartItems${index}`}>
            <Row className={`hidden-sm hidden-xs item m-x-0 ${index % 2 === 0 ? 'white-item' : ''}`}>
              <Col xs={4} sm={5} md={2} className="p-x-0">
                <div className="item-image">
                  <div className="image-link">
                    <ContentLoader
                      className="w-100"
                      width={250}
                      height={100}
                      animate={false}
                      foregroundColor={'#dfdfdf'}
                      backgroundColor={'#dfdfdf'}>
                      <rect x="0" y="0" width="250" height="100" />
                    </ContentLoader>
                  </div>
                </div>
              </Col>
              <Col xs={8} sm={7} md={10} className="p-x-0">
                <Col md={6} className="item-field">
                  <div className="item-name">
                    <ContentLoader
                      className=""
                      width={250}
                      height={20}
                      animate={false}
                      foregroundColor={'#dfdfdf'}
                      backgroundColor={'#dfdfdf'}>
                      <rect x="0" y="0" width="250" height="20" />
                    </ContentLoader>
                    <Col xs={12} className="p-x-0">
                      <ContentLoader
                        className=""
                        width={115}
                        height={20}
                        animate={false}
                        foregroundColor={'#dfdfdf'}
                        backgroundColor={'#dfdfdf'}>
                        <rect x="0" y="0" width="115" height="20" />
                      </ContentLoader>
                    </Col>
                    <Col xs={12} className="p-x-0">
                      <ContentLoader
                        className=""
                        width={100}
                        height={20}
                        animate={false}
                        foregroundColor={'#dfdfdf'}
                        backgroundColor={'#dfdfdf'}>
                        <rect x="0" y="0" width="100" height="20" />
                      </ContentLoader>
                    </Col>
                  </div>
                </Col>
                <Col md={1} className="item-field p-x-0 text-center">
                  <div className="item-qty">
                    <ContentLoader
                      className=""
                      width={100}
                      height={40}
                      animate={false}
                      foregroundColor={'#dfdfdf'}
                      backgroundColor={'#dfdfdf'}>
                      <rect x="0" y="0" width="100" height="40" />
                    </ContentLoader>
                  </div>
                </Col>
                <Col md={1} className="item-field pull-left col-md-offset-2 p-x-0">
                  <span className="row">
                    <span className="col-12 item-field-price-text">
                      <ContentLoader
                        className="w-100"
                        width={80}
                        height={20}
                        animate={false}
                        foregroundColor={'#dfdfdf'}
                        backgroundColor={'#dfdfdf'}>
                        <rect x="0" y="0" width="80" height="20" />
                      </ContentLoader>
                    </span>
                    <span className="col-12 item-field-currency-text">
                      <ContentLoader
                        className="my-1"
                        width={60}
                        height={20}
                        animate={false}
                        foregroundColor={'#dfdfdf'}
                        backgroundColor={'#dfdfdf'}>
                        <rect x="0" y="0" width="60" height="20" />
                      </ContentLoader>
                    </span>
                  </span>
                </Col>
                <Col md={2} className="item-field pull-left">
                  <span className="row">
                    <span className="col-12 item-field-price-text">
                      <ContentLoader
                        className=""
                        width={100}
                        height={20}
                        animate={false}
                        foregroundColor={'#dfdfdf'}
                        backgroundColor={'#dfdfdf'}>
                        <rect x="0" y="0" width="100" height="20" />
                      </ContentLoader>
                    </span>
                    <span className="col-12 item-field-currency-text">
                      <ContentLoader
                        className="my-1"
                        width={80}
                        height={20}
                        animate={false}
                        foregroundColor={'#dfdfdf'}
                        backgroundColor={'#dfdfdf'}>
                        <rect x="0" y="0" width="40" height="20" />
                      </ContentLoader>
                    </span>
                  </span>
                </Col>
              </Col>
            </Row>
            <Row className={`hidden-md hidden-lg m-x-0 mobile-cart-items`}>
              <Col xs={12} className={'mobile-item-primary-container'}>
                <div>
                  <Col xs={7} className="pull-left mt-1">
                    <ContentLoader
                      className="w-100"
                      width={250}
                      height={20}
                      animate={false}
                      foregroundColor={'#dfdfdf'}
                      backgroundColor={'#dfdfdf'}>
                      <rect x="0" y="0" width="250" height="20" />
                    </ContentLoader>
                  </Col>
                  <Col xs={5} className="pull-left mobile-unit-price-container">
                    <span className="row pull-right">
                      <span className="col-12 item-field-price-text mobile-unit-price">
                        <ContentLoader
                          className=""
                          width={100}
                          height={20}
                          animate={false}
                          foregroundColor={'#dfdfdf'}
                          backgroundColor={'#dfdfdf'}>
                          <rect x="0" y="0" width="100" height="20" />
                        </ContentLoader>
                      </span>
                      <span className="col-12 item-field-currency-text mobile-unit-price">
                        <ContentLoader
                          className=" mt-1"
                          width={80}
                          height={20}
                          animate={false}
                          foregroundColor={'#dfdfdf'}
                          backgroundColor={'#dfdfdf'}>
                          <rect x="0" y="0" width="80" height="20" />
                        </ContentLoader>
                      </span>
                    </span>
                  </Col>
                </div>
                <div>
                  <Col xs={7} className="pull-left mobile-item-image-container">
                    <div className="item-image">
                      <div className="image-link">
                        <ContentLoader
                          className="w-100"
                          width={250}
                          height={100}
                          animate={false}
                          foregroundColor={'#dfdfdf'}
                          backgroundColor={'#dfdfdf'}>
                          <rect x="0" y="0" width="250" height="100" />
                        </ContentLoader>
                      </div>
                    </div>
                  </Col>
                  <Col xs={5} className="pull-left"></Col>
                </div>
                <div>
                  <Col xs={6} className="pull-left mobile-remove-button-container text-center">
                    <ContentLoader
                      className="my-3"
                      width={100}
                      height={20}
                      animate={false}
                      foregroundColor={'#dfdfdf'}
                      backgroundColor={'#dfdfdf'}>
                      <rect x="0" y="0" width="100" height="20" />
                    </ContentLoader>
                  </Col>
                  <Col xs={6} className="pull-left mobile-save-button-container text-center">
                    <ContentLoader
                      className="my-3"
                      width={100}
                      height={20}
                      animate={false}
                      foregroundColor={'#dfdfdf'}
                      backgroundColor={'#dfdfdf'}>
                      <rect x="0" y="0" width="100" height="20" />
                    </ContentLoader>
                  </Col>
                </div>
              </Col>
              <Col xs={12} className={'mobile-item-secondary-container'}>
                <Col xs={5} className="pull-left">
                  <div className="item-qty">
                    <ContentLoader
                      className=""
                      width={100}
                      height={40}
                      animate={false}
                      foregroundColor={'#dfdfdf'}
                      backgroundColor={'#dfdfdf'}>
                      <rect x="0" y="0" width="100" height="40" />
                    </ContentLoader>
                  </div>
                </Col>
                <Col xs={7} className="pull-left">
                  <span className="row">
                    <span className="col-12 item-field-price-text">
                      <ContentLoader
                        className=""
                        width={120}
                        height={20}
                        animate={false}
                        foregroundColor={'#dfdfdf'}
                        backgroundColor={'#dfdfdf'}>
                        <rect x="0" y="0" width="120" height="20" />
                      </ContentLoader>
                    </span>
                    <span className="col-12 item-field-currency-text">
                      <ContentLoader
                        className="my-1"
                        width={40}
                        height={20}
                        animate={false}
                        foregroundColor={'#dfdfdf'}
                        backgroundColor={'#dfdfdf'}>
                        <rect x="0" y="0" width="40" height="20" />
                      </ContentLoader>
                    </span>
                  </span>
                </Col>
              </Col>
            </Row>
          </div>
        );
      })}
    </CartItemsContainer>
  );
};

const CartPlaceholders = () => {
  return (
    <div className="react-bootstrap-hack cambria-main-content">
      <CartPage>
        <Container className="cart-container">
          <div className="cart">
            <Row className="continue-shopping-button-wrapper">
              <Col xs={12}>
                <ContentLoader
                  className=""
                  width={200}
                  height={30}
                  animate={false}
                  foregroundColor={'#dfdfdf'}
                  backgroundColor={'#dfdfdf'}>
                  <rect x="0" y="0" width="200" height="30" />
                </ContentLoader>
              </Col>
            </Row>
            <div>
              <Row className="shopping-cart-header-text-wrapper">
                <Col xs={12} md={6} lg={8}>
                  <ContentLoader
                    className=""
                    width={300}
                    height={50}
                    animate={false}
                    foregroundColor={'#dfdfdf'}
                    backgroundColor={'#dfdfdf'}>
                    <rect x="0" y="0" width="300" height="50" />
                  </ContentLoader>
                </Col>
                <Col xs={12} md={6} lg={4}>
                  <ContentLoader
                    className="pull-right w-100 mt-3"
                    width={350}
                    height={40}
                    animate={false}
                    foregroundColor={'#dfdfdf'}
                    backgroundColor={'#dfdfdf'}>
                    <rect x="0" y="0" width="350" height="40" />
                  </ContentLoader>
                </Col>
              </Row>
              <div className="items p-t-0">
                <Row className="item-header m-x-0 hidden-xs">
                  <div className="col-md-2 p-x-0 product-quantity-text">
                    <ContentLoader
                      className=""
                      width={80}
                      height={20}
                      animate={false}
                      foregroundColor={'#dfdfdf'}
                      backgroundColor={'#dfdfdf'}>
                      <rect x="0" y="0" width="80" height="20" />
                    </ContentLoader>
                  </div>
                  <Col md={10} className="p-x-0 hidden-sm">
                    <div className="col-md-offset-6 col-md-1 p-x-0 text-center product-quantity-text">
                      <ContentLoader
                        className=""
                        width={80}
                        height={20}
                        animate={false}
                        foregroundColor={'#dfdfdf'}
                        backgroundColor={'#dfdfdf'}>
                        <rect x="0" y="0" width="80" height="20" />
                      </ContentLoader>
                    </div>
                    <div className="col-md-1 col-md-offset-2 p-x-0 unit-price-header-text">
                      <span className="col-12 pull-left p-x-0">
                        <ContentLoader
                          className=""
                          width={40}
                          height={20}
                          animate={false}
                          foregroundColor={'#dfdfdf'}
                          backgroundColor={'#dfdfdf'}>
                          <rect x="0" y="0" width="40" height="20" />
                        </ContentLoader>
                      </span>
                      <span className="col-12 pull-left p-x-0 my-1">
                        <ContentLoader
                          className=""
                          width={50}
                          height={20}
                          animate={false}
                          foregroundColor={'#dfdfdf'}
                          backgroundColor={'#dfdfdf'}>
                          <rect x="0" y="0" width="50" height="20" />
                        </ContentLoader>
                      </span>
                    </div>
                    <div className="col-md-2 p-x-0">
                      <span className="col-12 pull-left p-x-0">
                        <ContentLoader
                          className=""
                          width={40}
                          height={20}
                          animate={false}
                          foregroundColor={'#dfdfdf'}
                          backgroundColor={'#dfdfdf'}>
                          <rect x="0" y="0" width="40" height="20" />
                        </ContentLoader>
                      </span>
                      <span className="col-12 pull-left p-x-0 my-1">
                        <ContentLoader
                          className=""
                          width={50}
                          height={20}
                          animate={false}
                          foregroundColor={'#dfdfdf'}
                          backgroundColor={'#dfdfdf'}>
                          <rect x="0" y="0" width="50" height="20" />
                        </ContentLoader>
                      </span>
                    </div>
                  </Col>
                </Row>
                <CartItemsPlaceholders></CartItemsPlaceholders>
                <ContentLoader
                  className="my-5 w-100"
                  width={2000}
                  height={60}
                  animate={false}
                  foregroundColor={'#dfdfdf'}
                  backgroundColor={'#dfdfdf'}>
                  <rect x="0" y="0" width="2000" height="60" />
                </ContentLoader>
              </div>
              <Row>
                <Col xs={12} sm={12} lg={4}>
                  <ContentLoader
                    className="mb-3"
                    width={350}
                    height={40}
                    animate={false}
                    foregroundColor={'#dfdfdf'}
                    backgroundColor={'#dfdfdf'}>
                    <rect x="0" y="0" width="350" height="40" />
                  </ContentLoader>
                </Col>
                <Col lg={4} className="hidden-xs hidden-sm hidden-md"></Col>
                <Col xs={12} sm={12} lg={4} className="proceed-to-checkout-button">
                  <ContentLoader
                    className=""
                    width={350}
                    height={40}
                    animate={false}
                    foregroundColor={'#dfdfdf'}
                    backgroundColor={'#dfdfdf'}>
                    <rect x="0" y="0" width="350" height="40" />
                  </ContentLoader>
                </Col>
              </Row>
            </div>
          </div>
        </Container>
      </CartPage>
    </div>
  );
};

export default CartPlaceholders;
