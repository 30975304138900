import React, {FC, useState} from 'react';
import CambriaModal from '../../../../../Framework/Components/CambriaModal';
import {calculateRequestedDateQualifiesForDiscount} from '../../../../../features/quote/service/quote.service';

export interface RequestedDateAcknowledgePopupProps {
  requestedDate?: Date | null;
}

const RequestedDateAcknowledgePopup: FC<RequestedDateAcknowledgePopupProps> = ({requestedDate}) => {
  const [hasBeenAcknowledged, setHasBeenAcknowledged] = useState(false);
  const [prevDate, setPrevDate] = useState<Date | null>(null);

  if (requestedDate && (!prevDate || requestedDate.getTime() !== prevDate.getTime())) {
    setPrevDate(requestedDate ?? null);
    setHasBeenAcknowledged(false);
  }

  const qualifiesForDiscount = requestedDate ? calculateRequestedDateQualifiesForDiscount(requestedDate) : false;
  const onConfirm = () => {
    setHasBeenAcknowledged(true);
  };

  return (
    <CambriaModal
      show={!!requestedDate && !qualifiesForDiscount && !hasBeenAcknowledged}
      hideCancelButton
      heading="Note"
      hideExitIcon
      onConfirm={onConfirm}>
      <p>Orders placed less than 30 days from being quoted will NOT qualify for quantity incentive discount</p>
    </CambriaModal>
  );
};

export default RequestedDateAcknowledgePopup;
