import styled from 'styled-components';
import {$gray30, $gray50, $gray78, $gray93, $gray97, $newwhite, $screenmd} from '../../../../../../Styled/variables';

interface EndUserProps {
  darkGray?: boolean;
}

const DealerEndUserContainer = styled.div<EndUserProps>`
  background-color: ${(props: EndUserProps) => (props.darkGray ? `#e5e5e5` : `${$gray93}`)};
  box-shadow: 0px 1px 3px 0px rgba(101, 101, 101, 0.35);
  color: ${$gray30} !important;
  padding: 20px;
  border-radius: 3px;
  font-weight: 600;

  .button-container {
    display: flex;
    justify-content: space-between;
  }

  .add-new-customer-list-items {
    color: ${$gray50} !important;
  }

  .row {
    width: 100%;
  }

  .purchasing-customer-card {
    background-color: ${$gray97};
    padding: 2px;
    box-shadow: 0px 1px 3px 0px rgba(101, 101, 101, 0.35);
    border-radius: 3px;
    margin-bottom: 10px;

    .customer-edit-icon {
      float: right;
    }

    .purchasing-customer-site-name {
      font-weight: 700;
      font-size: 18px;
    }

    .purchasing-customer-card-email {
      overflow: hidden;
    }

    .row {
      margin: 10px 0px;
    }
  }

  .consumer-card {
    box-shadow: 0px 1px 3px 0px rgba(101, 101, 101, 0.35);
    border-radius: 3px;
    padding: 3px;
    background-color: ${$newwhite};
    margin-top: 10px;

    .contact-line-item {
      background-color: ${$gray97};
      border-radius: 3px;
      padding: 5px;

      .contact-line-item-text-overflow {
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .consumer-name-row {
      margin-bottom: 5px;
      font-weight: 700;
      font-size: 15px;

      .col {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }

    .consumer-address-row {
      color: ${$gray50};
      margin-top: 0px;
    }
  }

  .isUpdatePending {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    font-size: 12px;
    font-weight: 500;
  }

  .save-button-purchasing-customer {
    display: flex;
    /* align-items: center; */
    justify-content: center;
    height: 51px;
  }

  .save-button-column {
    button {
      float: right;
      width: 75px;
      height: 51px;
      justify-content: center;
    }
  }

  @media screen and (max-width: ${$screenmd}) {
    .customer-info {
      text-align: center;
      width: 100%;
    }

    .customer-info-overflow {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;

export const DealerEndUserFormContainer = styled.div`
  font-size: 14px;

  .row {
    margin-bottom: 7px;
  }

  .search-bar {
    margin-bottom: 20px;

    .rbt-input-main,
    .rbt-menu {
      font-size: 14px !important;
      font: inherit;
    }

    .rbt-input-main {
      height: 35px;
      width: 100%;
      background-color: #ffffff !important;
      padding: 8px 18px !important;
      box-shadow: 0px 1px 1px rgb(0 0 0 / 20%);
      border-radius: 3px;
      font-size: 14px !important;
      line-height: 17px !important;
      font-weight: 500 !important;
      color: #4c4c4c;
      -webkit-transition: 0.5s all;
      transition: 0.5s all;
      border: 1px solid #f1f1f4;
    }
  }

  .input-label-placement {
    display: flex;
    flex-direction: column;
  }

  .text-input {
    background-color: #ffffff !important;
    padding: 8px 18px !important;
    box-shadow: 0px 1px 1px rgb(0 0 0 / 20%);
    border-radius: 3px;
    font-size: 14px !important;
    line-height: 17px !important;
    font-weight: 500 !important;
    color: #4c4c4c;
    -webkit-transition: 0.5s all;
    -webkit-transition: 0.5s all;
    transition: 0.5s all;
    border: 1px solid #f1f1f4;

    ::placeholder {
      font-weight: 400;
      color: ${$gray78};
    }
  }

  .col {
    margin-bottom: 10px;
  }

  .css-1s2u09g-control {
    border: 1px solid #f1f1f4;
    box-shadow: 0px 1px 1px rgb(0 0 0 / 20%);
    font-size: 14px;
    color: #4c4c4c;
  }

  .bold {
    font-weight: 600;
  }

  input[type='text']::-webkit-input-placeholder {
    color: ${$gray78};
    font-weight: 400 !important;
  }

  .address-validation-input {
    margin-right: 5px;
    margin-bottom: 10px;
  }

  .remove-margin-bottom {
    margin-bottom: 0px;
  }

  .dropdown-item {
    border-left: 3px solid transparent !important;
    color: black;

    :hover,
    :active {
      text-decoration: none !important;
      border-left: 3px solid #c59617 !important;
      background-color: #f8f8f8 !important;
      overflow: auto !important;
      color: black;
    }

    :active {
      text-decoration: none !important;
      border-left: 3px solid #c59617 !important;
      background-color: #f8f8f8 !important;
      overflow: auto !important;
      color: black;
    }
  }

  .dropdown-item.active {
    text-decoration: none !important;
    border-left: 3px solid #c59617 !important;
    background-color: #f8f8f8 !important;
    overflow: auto !important;
    color: black;
  }
`;

export default DealerEndUserContainer;
