import React from 'react';
import {Col, Row} from 'react-bootstrap';

interface HeroCarouselCardProps {
  key: string;
  image: string;
  description: string;
  callToActionLabel: string;
  subHeading: string;
  callToActionUrl: string;
}

const HeroCarouselCard = ({
  image,
  description,
  callToActionLabel,
  subHeading,
  callToActionUrl,
}: HeroCarouselCardProps) => {
  return (
    <div className="hero-carousel-item-wrapper">
      <Row className="hero-carousel-item-row">
        <Col md={6} xs={12}>
          <img className="hero-carousel-image" src={image} alt="Card top"></img>
        </Col>
        <Col md={6} xs={12} className="hero-carousel-text-container">
          <div className="card-title subheading text-left">
            <span className="subheading-text">{subHeading}</span>
            <span className="subheading-line"></span>
          </div>
          <h1 className="card-text description text-left">{description}</h1>
          <a href={callToActionUrl} className="cta text-left" target="_blank" rel="noopener noreferrer">
            {callToActionLabel}
          </a>
        </Col>
      </Row>
    </div>
  );
};

export default HeroCarouselCard;
