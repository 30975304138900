import styled from 'styled-components/macro';
import {$newblack, $screenmd} from '../../../../Styled/variables';

export const EditRequestedDeliveryScheduleContainer = styled.div`
  width: 100%;
  position: relative;
  padding: 20px 0;
`;

export const CurrentDeliveryScheduleTable = styled.table`
  width: 100%;

  td {
    margin-bottom: 15px;
    &.table-cell {
      vertical-align: top;
      padding: 15px;

      &:last-child() {
        padding-right: 0;
      }

      .btn {
        font-size: 15px;
        padding-bottom: 7px;
      }
    }
  }

  tr {
    @media (max-width: ${$screenmd}) {
      display: flex;
      flex-flow: row wrap;
    }
  }

  .description {
    display: flex;
    flex-flow: row;
    align-items: center;
    padding-top: 0;
    padding-left: 0;

    img {
      width: 110px;
      height: auto;
      margin-right: 15px;
    }

    &__text {
      display: flex;
      flex-flow: column;
      margin: 0 14px;

      &__row {
        width: 100%;
        display: flex;
        flex-flow: row;
        justify-content: space-between;
        margin: 7px 0;
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        color: ${$newblack};

        span {
          font-weight: 500;
        }
      }
    }
  }

  .fa-trash-alt {
    color: red;
    cursor: pointer;
  }

  &.updated-delivery-schedule-on-mobile {
    thead {
      tr {
        flex-flow: row;
        th {
          padding: 2px 14px;
        }
      }
    }
    tbody {
      tr {
        flex-flow: row wrap;
        td {
          &.description {
            flex: 3;
          }
          flex: 1;
          padding: 2px 14px;
        }
      }
    }
  }
`;
