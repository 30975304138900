import React from 'react';
import {useTypedSelector} from '../../../hooks/store';
import {CartBundleValidation} from '../../cart/ICartState';
import {selectActiveCart} from '../../cart/slice/cart.slice';
import {selectIsRevH, selectIsRevI} from '../../environment/slice/environment.slice';
import {IBundleProgramRule} from '../../productCatalog/IProductCatalogState';
import {selectBundleProgramRules} from '../../productCatalog/slice/productCatalog.slice';
import {isBundleProgram} from '../../salesforce/service/salesforce.service';
import {selectActiveProgram} from '../../salesforce/slice/salesforce.slice';

/**
 * @prop {boolean} isBundleProgram  Active program is bundle or not
 * @prop {boolean} disableAddToCart Disable add to cart button
 * @prop {string | React.ReactNode | null} helpText Help text in add to cart section
 * @prop {number} inputSteps Step property for quantity input
 * @prop {number | null} quantityNeeded Quantity required to be added of this item number
 * @prop {CartBundleValidation | undefined} bundleValidation Bundle validation from the cart for missing bundle
 * @prop {number | null} halfBundleQuantity Quantity for half bundle
 */
export interface UseBundleValidationForProductReturnType {
  isBundleProgram: boolean;
  disableAddToCart: boolean;
  helpText: string | React.ReactNode | null;
  inputSteps: number | null;
  quantityNeeded: number | null;
  bundleValidation: CartBundleValidation | undefined;
  halfBundleQuantity: number | null;
  maxQuantity: number | null;
}

/**
 * Returns validation info for bundle orders
 * @param  {Object} args
 * @param {string | null} args.size Size selected
 * @param {string | null} args.thickness Thickness selected
 * @param {string | null} args.itemNumber Item number of item selected
 * @returns {UseBundleValidationForProductReturnType} Validation info
 */
export const useBundleValidationForProduct = (args: {
  size: string | null;
  thickness: string | null;
  itemNumber: string | null;
  designCode?: string | null;
}): UseBundleValidationForProductReturnType => {
  const {size, thickness, itemNumber, designCode} = args;
  const program = useTypedSelector(selectActiveProgram);
  const activeCart = useTypedSelector(selectActiveCart);
  const bundleProgramRules: IBundleProgramRule[] = useTypedSelector(selectBundleProgramRules);

  const bundleProgram = isBundleProgram(program);

  const isRevH = useTypedSelector(selectIsRevH);
  const isRevI = useTypedSelector(selectIsRevI);

  const halfBundleQuantity: number | null =
    size && thickness
      ? bundleProgramRules?.find((r) => r.size === size && r.thickness === thickness)?.halfBundleQuantity ?? null
      : null;

  // v1 allows half bundle quantity
  // rev h allows any qty if there is no incomplete bundle
  const quantityStepsForBundleProgram = bundleProgram && !isRevH && !isRevI ? halfBundleQuantity : null;
  let maxQuantity: number | null = null;

  let helpText: string | null = null;
  if (quantityStepsForBundleProgram) {
    helpText = `Must be ordered in quantities of ${quantityStepsForBundleProgram}`;
  }

  let disableAddToCart = bundleProgram && (!size || !thickness);
  let quantityNeeded: number | null = null;
  let incompleteBundle: CartBundleValidation | undefined;
  if (bundleProgram && (isRevH || isRevI)) {
    incompleteBundle = activeCart?.bundleValidations?.find((x) => !x.isComplete);
    if (isRevH && incompleteBundle?.requiredSlab) {
      disableAddToCart = incompleteBundle.requiredSlab.itemNumber !== itemNumber;
      quantityNeeded =
        incompleteBundle.requiredSlab.itemNumber === itemNumber ? incompleteBundle.requiredSlab.quantity : null;
      const plural = incompleteBundle.requiredSlab.quantity > 1 ? 's' : '';
      helpText =
        incompleteBundle.requiredSlab.itemNumber === itemNumber
          ? `You must add ${quantityNeeded} slab${plural} to complete your bundle`
          : `You need to add ${incompleteBundle.requiredSlab.quantity} slab${plural} of ${incompleteBundle.requiredSlab.itemDescription} to complete your bundle`;
    } else {
      const bundleRequirement = incompleteBundle?.slabsNeeded.find((x) => x.size === size && x.thickness === thickness);
      const plural = bundleRequirement?.requiredQty && bundleRequirement.requiredQty > 1 ? 's' : '';
      if (bundleRequirement) {
        if (isRevI) {
          if (
            bundleRequirement?.requiredDesigns?.length &&
            !bundleRequirement.requiredDesigns.find((x) => x.designCode === designCode)
          ) {
            disableAddToCart = true;
            const requiredDesignNames = bundleRequirement.requiredDesigns.map((x) => x.designName);
            if (requiredDesignNames.length > 1) {
              requiredDesignNames[requiredDesignNames.length - 1] =
                'or ' + requiredDesignNames[requiredDesignNames.length - 1];
            }
            helpText = 'You can only add the following designs: ' + requiredDesignNames.join(', ');
          } else {
            maxQuantity = bundleRequirement.requiredQty;

            helpText =
              maxQuantity > 0
                ? `You can add up to ${maxQuantity} slab${plural} to this bundle`
                : 'You cannot add more slabs of this size and thickness';
          }
        } else if (isRevH) {
          quantityNeeded = bundleRequirement?.requiredQty ?? null;
          helpText = quantityNeeded ? `You must add ${quantityNeeded} slab${plural} to complete your bundle` : null;
        }
      }
    }

    if (!helpText && halfBundleQuantity) {
      helpText =
        helpText ?? `Half quantity: ${halfBundleQuantity} slabs.\n Full quantity: ${halfBundleQuantity * 2} slabs.`;
    }
  }

  return {
    isBundleProgram: bundleProgram,
    disableAddToCart,
    helpText,
    inputSteps: quantityStepsForBundleProgram,
    quantityNeeded,
    bundleValidation: incompleteBundle,
    halfBundleQuantity,
    maxQuantity,
  };
};
