import styled from 'styled-components/macro';
import {$gray30, $gray93, $screenmd} from '../../../../../Styled/variables';

export const PurchasingCustomerContainer = styled.div`
  width: 100%;
  background-color: ${$gray93};
  padding: 30px 40px;
  margin: 10px 0;

  h2 {
    font-weight: 300;
    font-size: 28px;
    line-height: 44px;
    color: ${$gray30};
    margin-bottom: 15px;
  }

  p {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: ${$gray30};
    margin-bottom: 30px;
  }

  @media screen and (max-width: ${$screenmd}) {
    padding: 10px 10px;
  }
`;
