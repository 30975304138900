import {OrderSearchParams, OrderSearchParamsInitialState} from '../../../../../../features/search/ISearchState';
import {formatOrderCurrency} from '../../../../../../Framework/Services/formatCurrency';
import {formatOrderDate} from '../../../../../../Framework/Services/formatDate';
import {validUrlParams} from '../filterOptions';

// Iterates through url params and adds them to active filter, search params
export const processOrderParamsFromUrl = (queryString: string) => {
  let orderSearchCopy: OrderSearchParams = OrderSearchParamsInitialState;
  let params = new URLSearchParams(queryString);
  let paramsList: any[] = [];
  let urlParamsJson: any = {};
  params.forEach((value: string, key: string) => {
    if (isValidFilter(key)) {
      urlParamsJson[key] = value;
      let displayParam = mapToUrlToActiveDisplay(key, value, params);
      if (displayParam) {
        paramsList.push(displayParam);
      }
    }
  });
  orderSearchCopy = {...orderSearchCopy, ...urlParamsJson};
  return {paramsList, orderSearchCopy};
};

export const isValidFilter = (name: string) => {
  let isValid = false;
  validUrlParams.forEach((validFilter: string) => {
    if (name === validFilter) {
      isValid = true;
    }
  });
  return isValid;
};

export const mapToUrlToActiveDisplay = (name: string, value: string, searchQuery: URLSearchParams) => {
  if (name === 'q') {
    return {name: 'Details', value: value};
  } else if (name === 'endConsumerAddress1') {
    return {name: 'End User Address', value: value};
  } else if (name === 'status') {
    return {name: 'Status', value: value};
  } else if (name === 'statusCode') {
    return {name: 'Status Code', value: value};
  } else if (name === 'purchaseOrderNumber') {
    return {name: 'Purchase Order Number', value: value};
  } else if (name === 'orderType') {
    return {name: 'Order Type', value: value};
  } else if (name === 'orderNumber') {
    return {name: 'Order Number', value: value};
  } else if (name === 'favoriteName') {
    return {name: 'Favorite Name', value: value};
  } else if (name === 'endConsumerLastName') {
    return {name: 'End User Last Name', value: value};
  } else if (name === 'endConsumerFirstName') {
    return {name: 'End User First Name', value: value};
  } else if (name === 'jobNumber') {
    return {name: 'Job Number', value: value};
  } else if (name === 'amountRange.amountFrom') {
    let amountTo: any = searchQuery.get('amountRange.amountTo');
    return {
      name: 'Amount',
      value: `$${formatOrderCurrency(parseInt(value))} to $${formatOrderCurrency(parseInt(amountTo))}`,
    };
  } else if (name === 'dateRange.dateFrom') {
    let dateRangeTo: any = searchQuery.get('dateRange.dateTo');
    return {
      name: 'Date',
      value: `${formatOrderDate(new Date(value))} to ${formatOrderDate(new Date(dateRangeTo))}`,
    };
  }
};
