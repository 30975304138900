import React, {useEffect, useState} from 'react';
import {Dropdown} from 'react-bootstrap';
import {
  quoteSearchAsync,
  setActiveQuoteFilter,
  setQuoteUrlSearchParams,
  setQuoteActiveParams,
} from '../../../../../features/search/quote/slice/quote-search.slice';
import {useTypedSelector, useAppDispatch} from '../../../../../hooks/store';
import {selectQuoteSearch} from '../../../../../features/search/slice/search.slice';
import {statusOptions} from './filterOptions';
import ActiveFiltersDisplay from './DisplayActiveFilters';
import {processQuoteParamsFromUrl} from './UrlFilterFunctions/quoteUrlParameters.service';
import {QuoteSearchContainer} from './QuoteSearch.styled';
import AmountInput from './Inputs/Amount';
import StringInput from './Inputs/String';
import DropdownInput from './Inputs/Dropdown';
import DateInput from './Inputs/Date';
import {useLocation} from 'react-router-dom';
import {baseApiCallWithReauth} from '../../../../../Framework/api-utils/api-utils';
import COMMERCE_CORE_CONSTANTS from '../../../../constants';
import {cleanSearchParams} from '../../../../../features/order/service/order.service';

const QuoteSearch = ({
  erpCustomerId,
  relativeUrl,
  quoteFilterOptions,
}: {
  erpCustomerId?: number;
  relativeUrl: string;
  quoteFilterOptions: string[];
}) => {
  const dispatch = useAppDispatch();
  const location = useLocation();

  let [activeSearchParams, setActiveSearchParams]: any[] = useState([]);
  let [amountMin, setAmountMin] = useState<number>(0);
  let [amountMax, setAmountMax] = useState<number>(0);

  let activeFilter = useTypedSelector(selectQuoteSearch).activeFilter;
  let urlSearchParams = useTypedSelector(selectQuoteSearch).quoteUrlSearchParams;
  let reduxActiveParams = useTypedSelector(selectQuoteSearch).quoteActiveParams;

  const endpoint = COMMERCE_CORE_CONSTANTS.API_SERVICES.QUOTE.quotes;

  // Grabs Params from URL everytime it changes
  useEffect(() => {
    let returnedData: any = processQuoteParamsFromUrl(location.search);
    let activeFiltersArray = returnedData.paramsList;
    let urlParamsJson = returnedData.quoteSearchCopy;
    dispatch(setQuoteActiveParams(activeFiltersArray));
    dispatch(setQuoteUrlSearchParams(urlParamsJson));
  }, [dispatch, location]);

  // Get amount minimum and maximum on initial load
  useEffect(() => {
    const loadAmountMinMax = async () => {
      let data = new URLSearchParams();
      if (erpCustomerId) {
        data.append('erpCustomerId', erpCustomerId.toString());
      }
      data.append('pager.expand', 'totalResults');
      data.append('pager.offset', '0');
      data.append('pager.limit', '1');
      data.append('pager.sort', 'totalAmount');
      let minResult = await baseApiCallWithReauth('GET', endpoint, data);
      data.set('pager.sort', '-totalAmount');
      let maxResult = await baseApiCallWithReauth('GET', endpoint, data);
      if (minResult.results && minResult.results.length > 0 && maxResult.results && maxResult.results.length > 0) {
        setAmountMin(minResult.results[0].totalAmount);
        setAmountMax(maxResult.results[0].totalAmount);
      }
    };
    loadAmountMinMax();
  }, [dispatch, erpCustomerId, endpoint]);

  // Performs the quote search API call whenever a param is added/removed
  useEffect(() => {
    setActiveSearchParams(reduxActiveParams);
    let filters: any = urlSearchParams;
    let data = new URLSearchParams(filters);
    data = cleanSearchParams(data);
    data.append('pager[expand]', 'totalResults');
    data.append('pager[offset]', '0');
    data.append('pager[limit]', '50');
    data.append('pager[sort]', '-createdAt');
    if (erpCustomerId) {
      data.append('erpCustomerId', erpCustomerId.toString());
    }
    dispatch(quoteSearchAsync(data));
  }, [reduxActiveParams, dispatch, urlSearchParams, erpCustomerId]);

  const RenderFilterOptions = ({filters}: {filters: string[]}) => {
    return (
      <Dropdown.Menu className="main-filter-menu">
        {filters.map((filter: string) => (
          <Dropdown.Item
            key={filter}
            onClick={() => {
              dispatch(setActiveQuoteFilter(filter));
            }}>
            {filter}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    );
  };

  const decideInputToRender = (filter: string) => {
    if (filter === 'Date') {
      return <DateInput urlSearchParams={urlSearchParams} url={relativeUrl} />;
    } else if (filter === 'Status') {
      return (
        <DropdownInput
          options={statusOptions}
          urlSearchParams={urlSearchParams}
          activeFilter={activeFilter}
          url={relativeUrl}
        />
      );
    } else if (filter === 'Amount') {
      return (
        <AmountInput amountMin={amountMin} amountMax={amountMax} urlSearchParams={urlSearchParams} url={relativeUrl} />
      );
    }

    return <StringInput urlSearchParams={urlSearchParams} activeFilter={activeFilter} url={relativeUrl} />;
  };

  return (
    <QuoteSearchContainer>
      <div className="bg-gray">
        <div className="gray-header-background"></div>
        <div id="react-bootstrap-hack" className="container search-container global-search">
          <div className="search-items-container">
            <Dropdown>
              <Dropdown.Toggle id="dropdown" className="main-dropdown-toggle text-left">
                {activeFilter}
              </Dropdown.Toggle>
              <RenderFilterOptions filters={quoteFilterOptions} />
            </Dropdown>
            {decideInputToRender(activeFilter)}
          </div>
          <ActiveFiltersDisplay activeSearchParams={activeSearchParams} url={relativeUrl} />
        </div>
      </div>
    </QuoteSearchContainer>
  );
};

export default QuoteSearch;
