import styled from 'styled-components/macro';

interface FooterContainerProps {
  alignment: string;
}

export const FooterContainer = styled.div<FooterContainerProps>`
  font-family: Montserrat, sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  color: #999999;
  position: relative;
  padding: 45px 0 30px 0;
  background-color: black;
  padding: 15px;
  font-size: 10px;
  font-weight: 400;
  color: #ccc;

  .contact-info {
    font-size: 12px;
    color: #ccc;
    text-align: center;
  }

  .build-info {
    text-align: right;
    .copywrite {
      font-size: 10px;
      color: #666666;
    }

    .privacy-terms {
      font-size: 12px;
      a {
        color: #999999;
      }
    }
  }

  div[class*='col-'] {
    @media (max-width: 960px) {
      text-align: center;
    }
  }
  .copywrite {
    text-align: right;
    padding-right: 5px;
  }
  .footer-links {
    margin: 0;
    -webkit-padding-start: 0;
    text-align: right;
    list-style: none;
    li {
      display: inline-block;
      a {
        text-transform: uppercase;
        border-right: 1px solid #999999;
        padding-right: 5px;
        margin-right: 5px;
        color: #ccc;
        &:hover {
          color: darken(#999999, 10%);
        }
      }
      &:last-child a {
        border: none;
      }
    }
  }

  .footer-menu-wrapper {
    display: flex;
    justify-content: space-between;
    flex-flow: row;

    @media (max-width: 960px) {
      flex-flow: column;
      align-items: center;
    }

    .footer-menu {
      max-width: 180px;

      &:first-child {
        min-width: 270px;
        max-width: 280px;
      }

      @media (max-width: 960px) {
        max-width: none;
        text-align: center;

        .menu-content {
          overflow: hidden;
          transition: max-height 0.3s ease-out;
          height: auto;
          max-height: 800px;

          &.collapsed {
            max-height: 0;
          }
        }
      }

      .title {
        font-weight: 700;
        margin: 30px 0;

        a {
          color: inherit;
        }

        .icon {
          font-size: 8px;
          position: absolute;
          padding: 4px 10px 6px 10px;
          transition: all 0.3s ease-out;

          &.up {
            transform: translate(0, 0) rotate(180deg);
          }
        }
      }

      .link {
        cursor: pointer;
        display: block;
        min-height: 25px;
        font-size: 14px;
        line-height: 18px;
        margin-bottom: 10px;

        @media (max-width: 960px) {
          &.bold-on-mobile {
            font-size: 16px;
            font-weight: 700;

            .footer-menu-link-div {
              color: #ccc;
            }
            a {
              color: #ccc;
            }
          }

          &.mr-bottom {
            margin-bottom: 30px;
          }
        }

        .footer-menu-link-div {
          padding: 5px 0;
          color: #999999;

          &:hover {
            cursor: pointer;
            color: #ffffff;
          }
        }
        a {
          padding: 5px 0;
          color: #999999;

          &:hover {
            cursor: pointer;
            color: #ffffff;
          }
        }
      }
    }
  }

  .build-version,
  .terms-and-conditions {
    display: flex;
    flex-flow: row;
    font-size: 10px;
    line-height: 12px;
    color: #999999;
    a {
      &:hover {
        color: #ffffff;
      }
    }
  }
  .build-version {
    justify-content: flex-start;
    @media (max-width: 960px) {
      justify-content: center;
    }
  }

  .terms-and-conditions {
    justify-content: flex-end;
    @media (max-width: 960px) {
      flex-flow: column;
    }

    .link {
      border-right: 1px solid #999999;
      &:last-child {
        border: none;
      }

      @media (max-width: 960px) {
        border: none;
        margin-bottom: 22px;
        &:last-child {
          margin-bottom: 0;
        }
      }

      a {
        padding: 0 10px;
        color: #999999;
      }
    }
  }

  .lowercase {
    text-transform: lowercase;
  }

  .uppercase {
    text-transform: uppercase;
  }

  .capitalize {
    text-transform: capitalize;
  }

  @media print {
    .noprint {
      visibility: hidden;
    }
  }
`;
