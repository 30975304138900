import {baseApiCallWithReauth} from '../../../Framework/api-utils/api-utils';
import COMMERCE_CORE_CONSTANTS from '../../../Core/constants';
import qs from 'qs';

export const getLocationRequest = async (city: string, stateCode: string, countryCode: string) => {
  try {
    return await baseApiCallWithReauth(
      'GET',
      `${COMMERCE_CORE_CONSTANTS.API_SERVICES.LOCATION.address}/onePostalCodeForACityAndState`,
      qs.stringify({city: city, stateCode: stateCode, countryCode: countryCode}),
      true
    );
  } catch (error: any) {
    throw error;
  }
};
