import styled from 'styled-components/macro';

const CheckoutPlaceholderContainer = styled.div`
  .placeholder-overflow-class {
    overflow: hidden;
  }

  .placeholder-top-margin {
    margin-top: 45px;
  }

  .placeholder-bottom-margin {
    margin-bottom: 5px;
  }
`;

export default CheckoutPlaceholderContainer;
