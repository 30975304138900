import styled from 'styled-components';

const RequestAccessStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 120px;

  p {
    text-align: center;
    margin-top: 20px;
  }
`;

export default RequestAccessStyled;
