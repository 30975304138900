import React, {useEffect} from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import UnauthenticatedNoMatch from './UnauthenticatedNoMatch';
import LoginPage from './LoginPage';
import PasswordResetPage from './PasswordResetPage';
import Header from '../../Core/Components/Header';
import Footer from '../../Core/Components/Footer';
import {UnathenticatedContainer} from './Unathenticated.styled';
import {useAppDispatch, useTypedSelector} from '../../hooks/store';
import {getUiSettingsAsync, selectUiSettings} from '../../features/environment/slice/environment.slice';
import {anonymousTokenAsync} from '../../features/auth/slice/authentication.slice';
import ConfirmResetPage from './ConfirmResetPage';
import Maintenance from '../../Core/Components/Maintenance';

const UnauthenticatedSlash = () => {
  return <Redirect to="/login" />;
};

const UnauthenticatedApp = () => {
  const dispatch = useAppDispatch();
  const uiSettings = useTypedSelector(selectUiSettings);

  useEffect(() => {
    dispatch(anonymousTokenAsync());
  }, [dispatch]);

  useEffect(() => {
    const handleUiSettings = () => {
      try {
        dispatch(getUiSettingsAsync());
      } catch {
        dispatch(anonymousTokenAsync());
      }
    };
    handleUiSettings();
  }, [dispatch]);

  return (
    <>
      <UnathenticatedContainer>
        {!uiSettings?.maintenanceMode && <Header />}
        {!uiSettings?.maintenanceMode ? (
          <Switch>
            <Route path="/login" component={LoginPage} />
            <Route path="/passwordReset" component={PasswordResetPage} />
            <Route path="/confirmReset" component={ConfirmResetPage} />
            <Route exact path="/" component={UnauthenticatedSlash} />
            <Route path="*" component={UnauthenticatedNoMatch} />
          </Switch>
        ) : (
          <Maintenance></Maintenance>
        )}
        {!uiSettings?.maintenanceMode && <Footer />}
      </UnathenticatedContainer>
    </>
  );
};

export default UnauthenticatedApp;
