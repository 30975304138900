import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {Container} from 'react-bootstrap';
import ProductDetails from './Details';
import {ProductPage} from './Product.styled';
import ProductList from './List';
import COMMERCE_CORE_CONSTANTS from '../../../Core/constants';

export default function Product() {
  let [history] = useState(useHistory());

  useEffect(() => {
    if (
      history.location.pathname === '/product/' ||
      history.location.pathname === '/product' ||
      (!history.location.pathname.includes('details') &&
        !history.location.pathname.includes('slabs') &&
        !history.location.pathname.includes('tile') &&
        !history.location.pathname.includes('samples') &&
        !history.location.pathname.includes('store'))
    ) {
      history.push('/product/slabs/');
    }
  }, [history]);

  if (history.location.pathname.includes(COMMERCE_CORE_CONSTANTS.PATHNAMES.productDetails)) {
    return (
      <div className="react-bootstrap-hack cambria-main-content">
        <ProductPage>
          <Container className="product-list-container">
            <ProductDetails location={history.location}></ProductDetails>
          </Container>
        </ProductPage>
      </div>
    );
  } else if (
    history.location.pathname.includes('slabs') ||
    history.location.pathname.includes('tile') ||
    history.location.pathname.includes('samples') ||
    history.location.pathname.includes('store')
  ) {
    return (
      <div className="react-bootstrap-hack cambria-main-content">
        <ProductPage>
          <Container className="product-list-container">
            <ProductList></ProductList>
          </Container>
        </ProductPage>
      </div>
    );
  } else {
    return <div></div>;
  }
}
