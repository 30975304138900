import styled from 'styled-components/macro';

export const SavedItemsContainer = styled.div`
  .mobile-saved-items {
    margin-top: 30px;

    .mobile-item-primary-container {
      background-color: #fff;
      padding: 7px 0px 0px;
      box-shadow: 0px 0px 3px #ccc;

      .mobile-unit-price-container {
        margin-top: 5px;

        .mobile-unit-price {
          text-align: right;
        }
      }

      .mobile-item-image-container {
        margin: 0px 0px 15px;
      }

      .mobile-remove-button-container {
        border-top: 1px solid #d8d8d8;
        border-right: 1px solid #d8d8d8;

        .mobile-remove-button {
          display: flex;
          justify-content: center;
          margin: 7px 0px;
        }

        .item-field-remove-save-buttons {
          text-decoration: none !important;
        }
      }

      .mobile-save-button-container {
        border-top: 1px solid #d8d8d8;

        .mobile-save-button {
          display: flex;
          justify-content: center;
          margin: 7px 0px;
        }

        .item-field-remove-save-buttons {
          text-decoration: none !important;
        }
      }

      .mobile-item-description-text {
        text-align: left;
        white-space: normal;
      }
    }
  }
`;
