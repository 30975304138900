import styled from 'styled-components';
import {$gray30, $gray50, $gray60, $gray93, $gray97, $newprimary, $newwhite} from '../../../../Styled/variables';

export const ReviewAndPlaceOrderContainer = styled.div`
  font-size: 12px;
  font-weight: 600;
  color: ${$gray30};

  .order-address-name {
    font-size: 14px;
  }

  .row {
    margin-top: 30px;
  }

  .main-header {
    margin: 0px;
    padding: 0px;
  }

  .icons-header {
    display: flex;
    justify-content: flex-end;
    justify-content: flex-end;
    flex-direction: column;
    align-items: flex-end;

    .icons-container {
      display: flex;
      align-items: center;
    }

    .favorite-email-input {
      width: 300px;
    }

    .btn.btn-primary {
      background-color: ${$newprimary};
      padding: 6px;
      font-weight: 600;
      border: none;
      font-size: 12px;
      height: 35px;
      width: 45px;

      :hover {
        background-color: rgb(151, 115, 18);
      }
    }
  }

  .special-instructions {
    padding: 0px 20px 20px 20px;
  }

  .order-details-background {
    border-radius: 3px;
    box-shadow: 0px 1px 3px 0px rgba(101, 101, 101, 0.35);
    color: ${$gray60};
    background-color: ${$newwhite};
  }

  .order-detail-table-row {
    display: flex;
    justify-content: space-between;

    padding: 20px 20px;

    .favorite-name {
      display: flex;
      align-items: center;
      color: ${$gray30};
    }
  }

  .order-detail-table-row-mobile {
    background-color: ${$newwhite};
    padding: 10px 10px;
    border-radius: 3px;
    box-shadow: 0px 1px 3px 0px rgba(101, 101, 101, 0.35);
    color: ${$gray60};

    .order-date-container {
      margin-right: 10px;
      padding: 0px 15px;
    }

    .order-status-container {
      max-width: 75px;
    }
  }

  .back-button-row {
    margin: 0px;

    .back-button {
      display: flex;
      color: ${$newprimary};
      width: 180px;
      font-size: 12px;
      align-items: center;
      justify-content: space-evenly;

      :hover {
        cursor: pointer;
        color: rgb(151, 115, 18) !important;
      }
    }
  }

  .order-details-value {
    color: ${$gray30};
  }

  .order-detail-card {
    background-color: ${$newwhite};
    color: ${$gray60};
    padding: 25px 25px;
    box-shadow: 0px 1px 3px 0px rgba(101, 101, 101, 0.35);
    border-radius: 3px;
    margin-top: 10px;
    height: 280px;
  }

  .payment-card {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 0px;
    color: ${$gray30};

    .order-total-container {
      height: 60%;
      display: flex;
      align-items: center;

      .order-total-text {
        font-size: 32px;
        font-weight: 700;
      }
    }

    .payment-terms {
      font-size: 16px;
      font-weight: 700;
      height: 40%;
      display: flex;
      align-items: center;
      flex-direction: column;
      background: ${$gray97};
      width: 100%;
      justify-content: center;

      .payment-terms-text {
        margin-top: -20px;
        font-size: 14px;
      }
    }
  }

  .legal-disclaimer {
    font-size: 14px;
    margin-bottom: 20px;

    a {
      color: ${$newprimary};

      :hover {
        color: #ae8514;
      }
    }

    .text-bold-underline {
      font-weight: 700;
      text-decoration: underline;
    }
  }

  .payment-address-information {
    display: flex;
    justify-content: space-between;

    .address-header {
      font-weight: 700;
    }
  }

  .icon {
    margin-right: 10px;
  }

  .contact-information {
    margin: 30px 0px 0px 0px;

    .contact-line-item {
      margin: 10px 0px;
      background: ${$gray97};
      padding: 5px;
      border-radius: 3px;

      .contact-line-item-text-overflow {
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .dropdown {
    margin-top: 5px;

    .dropdown-toggle {
      width: 100%;
      height: 30px;
      font-size: 12px;
      background-color: white;
      color: black;
      border-color: ${$gray60};
      box-shadow: 0px 1px 3px 0px rgba(101, 101, 101, 0.35);
      font-weight: 600;
      color: ${$gray30};
      display: flex;
      justify-content: space-between;
      align-items: center;

      :focus,
      :hover,
      :active,
      :focus-visible {
        color: ${$gray60} !important;
        background-color: white !important;
        border-color: white !important;
      }
    }

    .dropdown-menu {
      width: 100%;
    }

    .dropdown-item {
      width: 100%;
      font-size: 12px;
      color: ${$gray30};
      font-weight: 600;

      :hover {
        background-color: #c59617;
        color: white;
      }
    }
  }

  h2 {
    font-weight: 600 !important;
    color: ${$gray30} !important;
    margin-bottom: 15px;
  }

  p {
    font-weight: 600;
    color: ${$gray30} !important;
    margin-bottom: 0px !important;
  }

  button {
    font-size: 12px !important;
    font-weight: 600 !important;
    padding: 10px !important;
    margin-top: 10px;
  }

  .order-row-mobile {
    margin: 10px 0px 0px 0px;
  }

  .product-table-container {
    margin-top: 10px;

    tbody {
      box-shadow: 0px 1px 3px 0px rgba(101, 101, 101, 0.35);

      tr {
        :nth-of-type(even) {
          background-color: ${$gray93};
        }

        :nth-of-type(odd) {
          background-color: ${$newwhite};
        }
      }
    }

    th {
      border-top: none;
      border-bottom: none !important;
      color: ${$gray30} !important;
      font-weight: 700;
      text-align: center;
    }

    td {
      border: none;
      text-align: center;

      .usd-label {
        font-size: 10px;
      }

      .quantity-box {
        width: fit-content;
        height: fit-content;
        min-width: 30px;
        min-height: 30px;
        padding: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 2px solid #d8d8d8b0;
        border-radius: 3px;
      }
    }
  }

  .order-summary-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .order-summary-heading {
      font-weight: 600 !important;
      color: ${$gray30} !important;
      font-size: 22px;
    }

    .item-number {
      font-weight: 600;
      color: ${$gray50};
    }

    .before-tax-text {
      color: ${$gray60};
      font-size: 10px;
      font-weight: 600;
    }

    .before-tax-total {
      color: ${$gray30};
      font-weight: 700;
    }

    .shipping-and-handling-msg {
      font-size: 12px;
      color: ${$gray30};
      background-color: ${$gray93};
      padding: 12px;
      border-radius: 3px;
    }

    .order-total-container {
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding: 15px;
      background: ${$gray30};
      color: white;
      margin-top: 12px;
      font-weight: 500;
      border-radius: 3px;

      .total {
        font-weight: 700;
      }
    }

    .back-button {
      width: 100%;
      margin-top: 12px;
      background-color: ${$newwhite};
      border: 2px solid #d8d8d8b0;
      color: ${$gray60};
      padding: 6px;
      font-size: 12px;

      :hover {
        background-color: #e6e6e6;
      }

      i {
        margin-right: 5px;
      }
    }

    .btn.btn-primary {
      background-color: ${$newprimary};
      padding: 6px;
      width: 100%;
      font-weight: 600;
      border: none;
      font-size: 12px;
      margin-top: 12px;

      :hover {
        background-color: rgb(151, 115, 18);
      }
    }

    .remove-order {
      color: red;
      text-decoration: underline;
      width: 100%;
      font-weight: 600;
      margin-top: 12px;
      display: flex;

      justify-content: center;

      :hover {
        cursor: pointer;
      }
    }
  }

  .price-per-sqft-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .mobile-header-row {
    margin-top: 5px;
    display: flex;
    align-items: center;
  }

  .card {
    background-color: ${$newwhite};
    border-radius: 3px;
    padding: 5px 5px;
    box-shadow: 0px 1px 3px 0px rgba(101, 101, 101, 0.35);
    color: ${$gray30};
  }

  .line-item-card-group {
    margin-bottom: 30px;
  }

  .save-favorite-button {
    margin: 0px;
    padding: 0px !important;
  }

  .line-items-table-mobile {
    color: ${$gray30} !important;
    font-weight: 700 !important;
    font-size: 12px;

    .second-row {
      margin: 15px 0px 10px 0px !important;
    }

    img {
      width: 100px;
    }

    .usd-label {
      font-size: 10px;
    }

    .price-per-sqft {
      font-size: 14px;
    }

    .quantity-box-container {
      display: flex;
      align-items: center;
      justify-content: start;

      .quantity-box {
        font-size: 16px;
        width: 40px;
        height: 40px;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-align-items: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        border: 2px solid #d8d8d8b0;
        border-radius: 3px;
      }
    }

    .design-name {
      font-size: 18px;
    }

    .row {
      margin: 5px 0px;
    }

    .line-price-container {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      padding: 0px;

      .line-price-label,
      .usd-label {
        font-size: 10px;
      }

      .usd-label {
        margin-top: -5px;
      }

      .line-price {
        font-size: 18px;
      }
    }
  }

  .delivery-date-mobile {
    color: ${$gray60};

    .delivery-date-mobile-quantity {
      float: right;
    }

    .date {
      color: ${$gray30};
    }
  }

  .delivery-date-mobile-expected-requested {
    margin-top: 15px;
  }

  .upload-file-container {
    background-color: ${$gray93};
    box-shadow: 0px 1px 3px 0px rgba(101, 101, 101, 0.35);
    margin: 20px 0px;
    padding: 10px 25px;
    border-radius: 3px;
  }

  .rejection-reasons-container {
    background-color: ${$gray93};
    padding: 20px;
    box-shadow: 0px 1px 3px 0px rgba(101, 101, 101, 0.35);
    font-weight: 700;
    border-radius: 3px;

    .rejection-reasons-header-row {
      margin: 0px 0px 5px 0px;
      color: ${$gray60};
      font-weight: 600;
    }

    .rejection-reasons-rows {
      background-color: ${$newwhite};
      border-radius: 3px;
      padding: 10px;
      display: flex;
      flex-direction: column;
      align-items: stretch;

      .rejection-row {
        margin-top: 5px;
        padding: 12px;
        background-color: ${$gray97};
        border-radius: 3px;
        display: flex;
        align-items: stretch;

        .reasons-col {
          display: flex;
          flex-direction: column;
          align-items: start;

          div {
            display: flex;
            justify-content: start;
          }
        }

        .col-3 {
          display: flex;
          align-items: start;
        }
      }
    }
  }

  .pointer-on-hover {
    :hover {
      cursor: pointer;
    }
  }

  .resubmit-reorder-buttons {
    display: flex;
    justify-content: center;
    align-items: center;

    .resubmit-reorder-spinner {
      margin-left: 5px;
    }
  }

  .active-warehouse-name {
    font-size: 15px;
  }

  .fa-industry {
    font-size: 15px;
  }

  .voucher-code-section {
    background-color: ${$gray97};
    width: 100%;
    padding: 10px 0px;

    .voucher-code-title {
      font-weight: 700;
      font-size: 14px;
    }
  }

  .dicount-description-text {
    font-size: 14px;
  }

  .small {
    font-size: 14px !important;
  }

  @media (max-width: 960px) {
    .address-card-col {
      margin-top: 20px;
    }

    .back-button-row {
      margin-top: 20px;
    }

    .payment-address-information {
      margin-top: 5px;
    }
  }
  @media print {
    @page {
      size: 400mm 500mm;
      margin: 5mm;
    }

    .noprint {
      visibility: hidden;
    }
  }
`;
