import React from 'react';
import TablePlaceholderContainer from './QuoteTablePlaceholder.styled';
import ContentLoader from 'react-content-loader';
import useWindowDimensions from '../../../../../hooks/getWindowDimensions';

const PlaceholderQuoteRow = () => {
  const {width} = useWindowDimensions();

  return (
    <TablePlaceholderContainer className="quote-table-row">
      <td className="date-block-placeholder">
        <ContentLoader width={143} height={90} animate={false} foregroundColor={'#dfdfdf'} backgroundColor={'#dfdfdf'}>
          <rect x="0" y="0" width="90" height="90" />
        </ContentLoader>
      </td>
      <td>
        <ContentLoader
          width={width < 1200 ? 280 : 344}
          height={90}
          animate={false}
          foregroundColor={'#dfdfdf'}
          backgroundColor={'#dfdfdf'}>
          <rect x="0" y="0" width="120" height="10" />
          <rect x="0" y="20" width="110" height="10" />
          <rect x="0" y="40" width="120" height="10" />
          <rect x="0" y="60" width="100" height="10" />
        </ContentLoader>
      </td>
      <td>
        <ContentLoader width={88} height={90} animate={false} foregroundColor={'#dfdfdf'} backgroundColor={'#dfdfdf'}>
          <rect x="0" y="0" width="75" height="10" />
        </ContentLoader>
      </td>
      <td>
        <ContentLoader width={88} height={90} animate={false} foregroundColor={'#dfdfdf'} backgroundColor={'#dfdfdf'}>
          <rect x="0" y="0" width="75" height="10" />
        </ContentLoader>
      </td>
    </TablePlaceholderContainer>
  );
};

const PlaceholderTable = () => {
  return (
    <tbody className="quote-table-body">
      <PlaceholderQuoteRow />
      <PlaceholderQuoteRow />
      <PlaceholderQuoteRow />
    </tbody>
  );
};

export default PlaceholderTable;
