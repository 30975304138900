import React, {useEffect, useState} from 'react';
import {Col, Row} from 'react-bootstrap';
import {toast} from 'react-toastify';
import AddPurchasingCustomerModal from '../../../../../../Core/Components/Modals/AddPurchasingCustomerModal';
import {selectActiveCart} from '../../../../../../features/cart/slice/cart.slice';
import {PurchasingCustomer} from '../../../../../../features/cart/ICartState';
import {selectCurrentCustomer} from '../../../../../../features/customer/slice/customer.slice';
import {
  getPurchasingCustomersAsync,
  resetEndConsumer,
  resetRetailSiteId,
  selectEndConsumers,
  selectPurchasingCustomer,
} from '../../../../../../features/fabrication/slice/fabrication.slice';
import {
  deleteEndConsumersRequest,
  deleteRetailSitesRequest,
} from '../../../../../../features/fabrication/controller/fabrication.controller';
import {getAllAvailableCountries} from '../../../../../../features/location/slice/location.slice';
import CambriaButton from '../../../../../../Framework/Components/CambriaButton';
import Icon from '../../../../../../Framework/Components/Icon';
import {useAppDispatch, useTypedSelector} from '../../../../../../hooks/store';
import DealerEndUserContainer from '../../../../Account/OrderHistory/OrderDetails/DealerEndUser/DealerEndUser.styled';
import {formatPhoneNumber} from '../../../../../../features/customer/service/customer.service';
import {getRetailAccountsForACustomer} from '../../../../../../features/salesforce/service/salesforce.service';
import {selectSalesforceCustomerAccount} from '../../../../../../features/salesforce/slice/salesforce.slice';

const PurchasingCustomerDisplay = ({isEditDisabled}: {isEditDisabled?: boolean}) => {
  const dispatch = useAppDispatch();

  const purchasingCustomers = useTypedSelector(selectPurchasingCustomer);
  const activeCart = useTypedSelector(selectActiveCart);
  const currentCustomer = useTypedSelector(selectCurrentCustomer);
  const endUsers = useTypedSelector(selectEndConsumers);
  const salesforceCustomerAccount = useTypedSelector(selectSalesforceCustomerAccount);

  const [showAddPurchasingCustomerModal, setShowPurchasingCustomerModal] = useState<boolean>(false);
  const [existingCustomerInformation, setExistingCustomerInformation] = useState<any>();
  const [availableAddresses, setAvailableAddresses] = useState<any[]>([]);

  const toggleShowNewCustomer = () => {
    setShowPurchasingCustomerModal(!showAddPurchasingCustomerModal);
  };

  const removePurchasingCustomer = async (customerNumber: number) => {
    try {
      await deleteRetailSitesRequest(customerNumber);
      await dispatch(
        getPurchasingCustomersAsync({
          erpCustomerId: currentCustomer.erpCustomerId,
          cartId: activeCart ? activeCart.id : '',
        })
      );
      await dispatch(resetRetailSiteId());
      if (endUsers && endUsers.length > 0) {
        await deleteEndConsumersRequest(endUsers[0].id);
        await dispatch(resetEndConsumer());
      }
      toast.success('Purchasing Customer removed successfully');
    } catch (e) {
      console.log(e);
      toast.error('Unable to remove Purchasing Customer at this time');
    }
  };

  useEffect(() => {
    dispatch(getAllAvailableCountries());
  }, [dispatch]);

  useEffect(() => {
    const getPurchasingCustomers = async () => {
      if (activeCart && currentCustomer.erpCustomerId) {
        await dispatch(
          getPurchasingCustomersAsync({
            cartId: activeCart.id,
            erpCustomerId: currentCustomer.erpCustomerId,
          })
        );
      }
    };
    getPurchasingCustomers();
  }, [dispatch, currentCustomer, activeCart]);

  useEffect(() => {
    const getSitesForDropdown = async () => {
      try {
        const result = await getRetailAccountsForACustomer(currentCustomer);
        let organizedArray: any[] = [];
        result.forEach((item: any) => {
          organizedArray.push(organizeAddress(item));
        });
        setAvailableAddresses(organizedArray);
      } catch (e) {
        throw e;
      }
    };

    const organizeAddress = (site: any) => {
      return {
        displayName: site.address,
        value: `${site.siteName}`,
        site: site,
      };
    };

    if (salesforceCustomerAccount && Object.keys(salesforceCustomerAccount).length > 0 && currentCustomer) {
      getSitesForDropdown();
    }
  }, [salesforceCustomerAccount, currentCustomer, dispatch]);

  return (
    <>
      <DealerEndUserContainer darkGray>
        <h2 className="mb-4">Purchasing Customers & Consumers</h2>

        {purchasingCustomers && purchasingCustomers.length === 0 ? (
          <>
            <h4>
              Entering the purchasing customer and consumer information allows Cambria to support and service the end
              users.
            </h4>
            <CambriaButton
              data-testid="addNewPurchasingCustomerBtn"
              onClick={() => {
                toggleShowNewCustomer();
              }}
              variant="secondary">
              <Icon icon="glyph glyphicon-plus glyphicon--plus" color="#c59617" size="22" weight="500" />
              ADD NEW PURCHASING CUSTOMER
            </CambriaButton>
          </>
        ) : (
          <>
            {purchasingCustomers &&
              purchasingCustomers.length > 0 &&
              purchasingCustomers.map((point: PurchasingCustomer, index: number) => {
                return (
                  <div className="purchasing-customer-card" key={index}>
                    <Row>
                      <Col className="text-center">
                        <span className="purchasing-customer-site-name">{point.siteName}</span>
                        <div hidden={!point.isUpdatePending} className="isUpdatePending">
                          <Icon icon="fas fa-exclamation-triangle" color="red" size="16" weight="600" />
                          <span className="update-pending-text">
                            This purchasing customer has new or updated information. Click the 'Save' button to
                            finalize.
                          </span>
                        </div>
                        {!isEditDisabled ? (
                          <Icon
                            name="removePurchasingCustomerBtn"
                            className="float-right"
                            icon="fa fa-times"
                            size="18"
                            weight="600"
                            color="#bcc0c6"
                            colorOnHover="#c59617"
                            onClick={() => {
                              removePurchasingCustomer(point.id);
                              setExistingCustomerInformation(null);
                            }}
                          />
                        ) : (
                          <></>
                        )}
                        {!isEditDisabled ? (
                          <Icon
                            name="editPurchasingCustomerBtn"
                            className="float-right mr-2"
                            icon="fas fa-pencil-alt"
                            size="17"
                            weight="600"
                            color="#bcc0c6"
                            colorOnHover="#c59617"
                            onClick={() => {
                              toggleShowNewCustomer();
                              setExistingCustomerInformation(point);
                            }}
                          />
                        ) : (
                          <></>
                        )}
                      </Col>
                    </Row>
                    <div className="d-flex flex-wrap justify-content-center customer-info">
                      {point.contactFirstName || point.contactLastName ? (
                        <span className="customer-info-overflow">
                          {point.contactFirstName} {point.contactLastName}
                        </span>
                      ) : (
                        <span></span>
                      )}
                      <span className="customer-info-overflow">
                        {point.contactFirstName || point.contactLastName ? (
                          <span>&nbsp;&nbsp;&bull;&nbsp;&nbsp;</span>
                        ) : (
                          <span></span>
                        )}
                        {point.address1}
                        {point.address2 && <span>,&nbsp;</span>}
                        {point.address2}
                        {point.address3 && <span>,&nbsp;</span>}
                        {point.address3}
                      </span>
                      <span className="customer-info-overflow">
                        &nbsp;&nbsp;&bull;&nbsp;&nbsp;
                        {point.city}, {point.stateProvinceCode}
                        {point.postalCode}
                      </span>
                      {point.contactEmail && (
                        <span className="customer-info-overflow">
                          &nbsp;&nbsp;&bull;&nbsp;&nbsp;
                          <a href={`mailto:${point.contactEmail}`}>{point.contactEmail}</a>
                        </span>
                      )}
                      {point.contactPhoneNumber && (
                        <>
                          <span>&nbsp;&nbsp;&bull;&nbsp;&nbsp;</span>
                          <a href={`tel:${point.contactPhoneNumber}`}>{formatPhoneNumber(point.contactPhoneNumber)}</a>
                        </>
                      )}
                    </div>
                  </div>
                );
              })}
          </>
        )}

        {showAddPurchasingCustomerModal && (
          <AddPurchasingCustomerModal
            availableAddresses={availableAddresses}
            show={showAddPurchasingCustomerModal}
            toggleShow={toggleShowNewCustomer}
            customerToEdit={existingCustomerInformation}
            fabOrderEntry
            canAddNewPurchasingCustomer={true}
          />
        )}
      </DealerEndUserContainer>
    </>
  );
};

export default PurchasingCustomerDisplay;
