import {EndConsumer, VoucherCode} from '../../cart/ICartState';
import {FabricationFile} from '../../file/IFileState';
import {IOrderHeaderData} from '../../../App/AuthenticatedPages/Account/OrderHistory/OrderDetails/OrderHeader/IOrderHeader';
import {IPaymentInfoCardData} from '../IOrderState';
import {LineItem} from '../../../App/AuthenticatedPages/Account/OrderHistory/OrderDetails/IOrderDetails';
import {User} from '../../../store/auth/auth.service';

export interface IOrderDetails {
  name: string;
  orderNumber: string;
  homeDepotOrderNumber: string;
  lastOrderTransactionId: string;
  orderType: string;
  orderTypeDisplayName: string;
  originalCartId: string;
  lastCartId: string;
  lastRecType: string;
  userId: string;
  erpCustomerId: string;
  orderCrmAccountName: string;
  customerClass: string;
  currencyCode: string;
  statusCode: string;
  statusDisplayName: string;
  statusDescription: string;
  statusSortOrder: number;
  taxableStatus: string;
  taxExemptCertificateCode: string;
  paymentType: string;
  paymentTerms: string;
  paymentAmount: number;
  totalAmount: number;
  estimatedPaymentFeeAmount: number;
  actualPaymentFeeAmount: number;
  totalMaterialFee: number;
  paymentTransactionId: string;
  creditCardMasked: string;
  creditCardType: string;
  purchaseOrderNumber: string;
  deliveryRate: number;
  deliveryRateType: string;
  marketRep: string;
  billToSite: string;
  billToName: string;
  billToAddress1: string;
  billToAddress2: string;
  billToAddress3: string;
  billToStateProvinceCode: string;
  billToCity: string;
  billToCountryCode: string;
  billToPostalCode: number;
  billToPhone: string;
  billToEmail: string;
  operatingUnitCode: string;
  sourceSystem: string;
  customerContactId: string;
  billToContactId: string;
  totalTaxAmount: number;
  totalShippingAmount: number;
  subtotal: number;
  totalNetAmount: number;
  totalDiscounts: number;
  isReturnOrder: boolean;
  jobType: string;
  projectType: string;
  projectName: string;
  projectCity: string;
  orderPurpose: string;
  orderEventName: string;
  relatedAgency: string;
  billToContactFirstName: string;
  billToContactLastName: string;
  shipToContactLastName: string;
  shipToContactFirstName: string;
  shippingInstructions: string;
  shipToContactPhone: string;
  shipToContactEmail: string;
  shipToSiteName: string;
  createdAt: Date | null;
  updatedAt: Date | null;
  pointOfSales: PurchasingCustomer[];
  orderLineItems: ILineItem[];
  totalItems: number;
  orderCreatedById: string;
  warehouse: string;
  orderIsRevisionOfPreviousJob: boolean;
  publicFacingDisplay: boolean;
  surfacesWarehouseCodeOverride: string;
  orderPieceRework: boolean;
  orderMetadata: ICambriaOrderMetaData | null;
  headerAdjustments: HeaderAdjustment[];
  totalHeaderAdjustmentAmount: number;
  fabOrderRevision: number;
  fabOrderRevisionDisplayValue: string;
  fabricationJobNumber: string;
  fabricationInstructions: string;
  fabricationOrderDrafter: string;
  originalSalesOrderNumber: number;
  voucherCodes: string[];
  crmQuoteNumber: string;
  lastCartCrmId?: string;
  rejections: Array<IWorkflowRejection>;
  favorites: Array<IOrderFavorite>;
  files: FabricationFile[];
  trackingNumbers: ITrackingNumber[];
  totalShippingDiscountAmount?: number | null;
}

export interface ITrackingNumber {
  shipMethodCode: string;
  trackingNumber: string;
  trackingNumberUrl: string;
  shippingItems: IShippingItem[];
}

export interface IShippingItem {
  cancelledQuantity: number;
  deliveredQuantity: number;
  description: string;
  pickedQuantity: number;
  receivedQuantity: number;
  requestedQuantity: number;
  shippedQuantity: number;
  srcRequestedQuantity: number;
}

export interface IWorkflowRejection {
  statusCode: string;
  cadRejectMessage: string;
  cadRejectReasons: Array<string>;
  poRejectMessage: string;
  poRejectReasons: Array<string>;
  createdAt: Date;
}

export interface IOrderFavorite {
  id: number;
  name: string;
  userId: string;
  userName: string;
}

export interface HeaderAdjustment {
  id: number;
  name: string;
  type: string;
  modifier: string;
  value: number;
  listLine: string;
  reasonCode: string;
  reasonTxt: string;
  headerAdjustmentId: number;
  createdAt: Date;
  updatedAt: Date;
}

export type ProductType =
  | 'Slab'
  | 'Tile'
  | 'Store'
  | 'Samples'
  | 'Fabricated'
  | 'FabricatedService'
  | 'Surfaces'
  | 'Fabrication';

export interface ILineItem {
  productId: string;
  productType: ProductType;
  productCategory: string;
  lineItemNumber: number;
  lineItemVersion: number;
  itemNumber: string;
  itemDescription: string;
  quantity: number;
  extendedQuantity: number;
  pricingUom: string;
  orderedUom: string;
  erpStatusCode: string;
  statusCode: string;
  statusDisplayName: string;
  statusDescription: string;
  statusSortOrder: number;
  unitPrice: number;
  unitPriceAdjusted: number;
  netPrice: number;
  extendedPrice: number;
  extendedNetPrice: number;
  taxAmount: number;
  discountAmount: number;
  shippingAmount: number;
  shipFromWarehouseCode: string;
  shipToSiteId: string;
  shipToContactId: string;
  shipToFirstName: string;
  shipToLastName: string;
  shipToPhone: string;
  shipToEmail: string;
  shipToCustomerName: string;
  shipToAddress1: string;
  shipToAddress2: string;
  shipToAddress3: string;
  shipToCity: string;
  shipToStateProvinceCode: string;
  shipToPostalCode: string;
  shipToCountryCode: string;
  freightTerms: string;
  shippingMethod: string;
  deliveryRateType: string;
  deliveryRate: number;
  bomString: string;
  routingString: string;
  modifierString: any;
  createdAt: Date | null;
  updatedAt: Date | null;
  deliveries: any[];
  currency: string;
  operatingUnitCode: string;
  description: string;
  imageUrl: string;
  adjustedUnitPrice: number;
  adjustedExtendedNetPrice: number;
  programName: string;
  designName: string;
  uomLineQuantity: number;
  finish: string;
  thickness: string;
  productApplication: string;
  pieceLabel: string;
  dimension1: number;
  dimension2: number;
  dimension3: number;
  dimension4: number;
  serviceOfferings: boolean;
  edgeProfileCode: string;
  edgeProfileName: string;
  match: string;
  designCollection: string;
  fabOrderingViewType: string;
  productGroupCode: string;
  designCode: string;
  priceListCode: string;
  cartItemType: 'Unknown' | 'ComplimentaryCheeseboard' | 'Standard' | 'FabAndInstallDiscrete' | 'FabAndInstallStandard';
  taxProductCode: string;
  weightUomCode: string;
  unitWeight: number;
  primaryUomCode: string;
  cartItemId: number;
  unitSize: number;
  shipFromWarehouseId: string;
  edge: string;
  parentCartItemId: number;
  itemQuantityPrice: number;
  basePrice: number;
  extendedBasePrice: number;
  scheduleShipDate: string;
  deliverySchedule: {
    requestedDeliveryDate: string;
    seamed: boolean;
  } | null;
  warehouseName: string;
  basePriceExtended: number;
  unitPriceExtended: number;
}

export const NewLineItem: ILineItem = {
  productId: '',
  productType: 'Slab',
  productCategory: '',
  lineItemNumber: 0,
  lineItemVersion: 0,
  itemNumber: '',
  itemDescription: '',
  quantity: 0,
  extendedQuantity: 0,
  pricingUom: '',
  orderedUom: '',
  erpStatusCode: '',
  statusCode: '',
  statusDisplayName: '',
  statusDescription: '',
  statusSortOrder: 0,
  unitPrice: 0,
  unitPriceAdjusted: 0,
  netPrice: 0,
  extendedPrice: 0,
  extendedNetPrice: 0,
  taxAmount: 0,
  discountAmount: 0,
  shippingAmount: 0,
  shipFromWarehouseCode: '',
  shipToSiteId: '',
  shipToContactId: '',
  shipToFirstName: '',
  shipToLastName: '',
  shipToPhone: '',
  shipToEmail: '',
  shipToCustomerName: '',
  shipToAddress1: '',
  shipToAddress2: '',
  shipToAddress3: '',
  shipToCity: '',
  shipToStateProvinceCode: '',
  shipToPostalCode: '',
  shipToCountryCode: '',
  freightTerms: '',
  shippingMethod: '',
  deliveryRateType: '',
  deliveryRate: 0,
  bomString: '',
  routingString: '',
  modifierString: undefined,
  createdAt: null,
  updatedAt: null,
  deliveries: [],
  currency: '',
  operatingUnitCode: '',
  description: '',
  imageUrl: '',
  adjustedUnitPrice: 0,
  adjustedExtendedNetPrice: 0,
  programName: '',
  designName: '',
  uomLineQuantity: 0,
  finish: '',
  thickness: '',
  productApplication: '',
  pieceLabel: '',
  dimension1: 0,
  dimension2: 0,
  dimension3: 0,
  dimension4: 0,
  serviceOfferings: false,
  edgeProfileCode: '',
  edgeProfileName: '',
  match: '',
  designCollection: '',
  fabOrderingViewType: '',
  productGroupCode: '',
  designCode: '',
  priceListCode: '',
  cartItemType: 'Unknown',
  taxProductCode: '',
  weightUomCode: '',
  unitWeight: 0,
  primaryUomCode: '',
  cartItemId: 0,
  unitSize: 0,
  shipFromWarehouseId: '',
  edge: '',
  parentCartItemId: 0,
  itemQuantityPrice: 0,
  basePrice: 0,
  extendedBasePrice: 0,
  scheduleShipDate: '',
  deliverySchedule: {
    requestedDeliveryDate: '',
    seamed: false,
  },
  warehouseName: '',
  unitPriceExtended: 0,
  basePriceExtended: 0,
};

export interface ProductTypeResponse {
  id: string;
  pimProductId: string;
  displayName: string;
  description: string;
  productType: string;
  isActive: boolean;
  isActiveStartDate: string;
  isActiveEndDate: string;
  isFeatured: boolean;
  isNew: boolean;
  isNewStartDate: string;
  isNewEndDate: string;
  isOnSale: boolean;
  lowInventoryCount: number;
  manufacturingCountry: string;
  isBackorderable: boolean;
  isTrademarked: boolean;
  patentNumber: string;
  isCustomerSpecific: boolean;
  discountCategory: string;
  subProductCategory: string;
  luxuryDesign: boolean;
  displayCategories: string[];
  shippingClassifications: string[];
  items: Array<ProductTypeItems>;
  attributes: Array<ProductTypeAttributes>;
  attachments: Array<ProductTypeAttachments>;
  updateDate: string;
  isProductGroup: boolean;
  productGroupCode: string;
  productGroupCodes: string[];
  priceListCodeOverride: string;
  productActiveLocations: string[];
  fabOrderingViewType: string[];
  fabProductSortOrder: number;
  cartItemType: string;
  programCodes: string[];
}

export interface ProductTypeItems {
  id: string;
  itemNumber: string;
  description: string;
  bomItemType: string;
  itemStatusCode: string;
  primaryUomCode: string;
  secondaryUomCode: string;
  unitLength: string;
  unitHeight: string;
  unitWeight: string;
  weightUomCode: string;
  unitSize: number;
  availabilities: ProductTypeAvailability[];
  attributes: Array<ProductTypeAttributes>;
  attachments: ProductTypeAttachments[];
  quantities: ProductTypeQuantity[];
  organizations: ProductTypeOrganization[];
  updateDate: string;
  productId: string;
  productType: string;
  productDisplayName: string;
  qualifiesforFreeShippingForHomeDepot: boolean;
}

export interface ProductTypeOrganization {
  erpOrganizationCode: string;
  name: string;
}

export interface ProductTypeAttributes {
  name: string;
  description: string;
  usageType: string;
  sequence: number;
  value: string;
}

export interface ProductTypeAttachments {
  type: string;
  mediaType: string;
  location: string;
}

export interface ProductTypeQuantity {
  organizationCode: string;
  subInventory: string;
  qtyReservable: number;
  availableDate: string;
}

export interface ProductTypeAvailability {
  availabilityId: number;
  availabilityKeyType: string;
  availabilityKeyName: string;
  availabilityValue: string;
  isAvailable: boolean;
  updatedAt: string;
}

export interface CambriaUploadFile {
  id: string;
  lastModified: number;
  lastModifiedDate: string;
  name: string;
  progress: number;
  size: number;
  type: string;
  upload: any;
  result: any;
  isHidden: boolean;
  fileExtension: string;
}

export interface SignedUrl {
  contentType: string | null;
  expiresIn: number;
  href: string;
  verb: string;
}

export const NewOrderDetails: IOrderDetails = {
  name: '',
  orderNumber: '',
  homeDepotOrderNumber: '',
  lastOrderTransactionId: '',
  orderType: '',
  orderTypeDisplayName: '',
  originalCartId: '',
  lastCartId: '',
  lastRecType: '',
  userId: '',
  erpCustomerId: '',
  orderCrmAccountName: '',
  customerClass: '',
  currencyCode: '',
  statusCode: '',
  statusDisplayName: '',
  statusDescription: '',
  statusSortOrder: 0,
  taxableStatus: '',
  taxExemptCertificateCode: '',
  paymentType: '',
  paymentTerms: '',
  paymentAmount: 0,
  totalAmount: 0,
  estimatedPaymentFeeAmount: 0,
  actualPaymentFeeAmount: 0,
  totalMaterialFee: 0,
  paymentTransactionId: '',
  creditCardMasked: '',
  creditCardType: '',
  purchaseOrderNumber: '',
  deliveryRate: 0,
  deliveryRateType: '',
  marketRep: '',
  billToSite: '',
  billToName: '',
  billToAddress1: '',
  billToAddress2: '',
  billToAddress3: '',
  billToStateProvinceCode: '',
  billToCity: '',
  billToCountryCode: '',
  billToPostalCode: 0,
  billToPhone: '',
  billToEmail: '',
  operatingUnitCode: '',
  sourceSystem: '',
  customerContactId: '',
  billToContactId: '',
  totalTaxAmount: 0,
  totalShippingAmount: 0,
  subtotal: 0,
  totalNetAmount: 0,
  totalDiscounts: 0,
  isReturnOrder: false,
  jobType: '',
  projectType: '',
  projectName: '',
  projectCity: '',
  orderPurpose: '',
  orderEventName: '',
  relatedAgency: '',
  billToContactFirstName: '',
  billToContactLastName: '',
  shipToContactLastName: '',
  shipToContactFirstName: '',
  shippingInstructions: '',
  shipToContactPhone: '',
  shipToContactEmail: '',
  shipToSiteName: '',
  createdAt: new Date(),
  updatedAt: new Date(),
  pointOfSales: [],
  orderLineItems: [],
  totalItems: 0,
  orderCreatedById: '',
  warehouse: '',
  orderIsRevisionOfPreviousJob: false,
  publicFacingDisplay: false,
  surfacesWarehouseCodeOverride: '',
  orderPieceRework: false,
  orderMetadata: null,
  headerAdjustments: [],
  totalHeaderAdjustmentAmount: 0,
  fabOrderRevision: 0,
  fabOrderRevisionDisplayValue: '',
  fabricationJobNumber: '',
  fabricationInstructions: '',
  fabricationOrderDrafter: '',
  originalSalesOrderNumber: 0,
  voucherCodes: [],
  crmQuoteNumber: '',
  rejections: [],
  favorites: [],
  files: [],
  trackingNumbers: [],
};

export interface PurchasingCustomer {
  id: number;
  userId: string;
  erpCustomerId: number;
  cartId: string;
  customerSiteId: number;
  customerContactId: number;
  siteName: string;
  contactFirstName: string;
  contactLastName: string;
  contactPhoneNumber: string;
  contactEmail: string;
  address1: string;
  address2: string;
  address3: string;
  address4: string;
  city: string;
  stateProvinceCode: string;
  postalCode: string;
  countryCode: string;
  createdAt: Date | string;
  createdBy: string;
  updatedAt: Date | string;
  updatedBy: string;
  retailCrmAccountId: string;
  isActive: false;
  orderNumber: string;
  endConsumers: EndConsumer[];
  endUsers?: any[];
  isUpdatePending: boolean;
}

export interface ICambriaShipFromWarehouseData {
  warehouse: string;
}

export interface IAddressCardData {
  contactName: string;
  locationName: string;
  addressLine1: string;
  addressLine2: string;
  addressLine3: string;
  city: string;
  stateProvince: string;
  postalCode: string;
  country: string;
  email: string;
  phone: string;
  type: string;
  icon: string;
  status: string;
}

export interface ICambriaOrderMetaData {
  orderHeaderData: IOrderHeaderData;
  shipFromWarehouseData: ICambriaShipFromWarehouseData;
  billingAddressCardData?: IAddressCardData;
  paymentInfoCardData?: IPaymentInfoCardData;
  shippingAddressCardData?: IAddressCardData;
  jobOrderTypeData: ICambriaJobOrderTypeData;
  purchasingCustomers: PurchasingCustomer[];
  orderSummaryData: ICambriaOrderSummaryData;
  lineItems: LineItem[];
  voucherCodes: VoucherCode[];
  userMetadata?: User;
  quoteSummaryData?: ICambriaQuoteSummaryData;
  rejections?: IWorkflowRejection[];
}

export interface ICambriaJobOrderTypeData {
  cartId: string;
  jobType: string;
  projectType: string;
  projectName: string;
  projectCity: string;
  relatedAgency: string;
}

export interface ICambriaOrderSummaryData {
  totalItems: number;
  subtotalAmount?: number;
  totalShippingAmount?: number;
  totalDiscountAmount?: number;
  totalNetAmount?: number;
  totalTaxAmount?: number;
  totalAmount?: number;
  estimatedPaymentFeeAmount?: number;
  actualPaymentFeeAmount?: number;
  totalMaterialFee?: number;
  alertData?: any;
  showTax: boolean;
  showEstimatedPaymentFeeAmount: boolean;
  showActualPaymentFeeAmount: boolean;
  showShipping: boolean;
  showMaterialFee: boolean;
  currency: string;
  totalHeaderAdjustmentAmount?: number;
  totalShippingDiscountAmount?: number;
  quoteSubtotal?: number;
  quoteDiscounts?: Array<ISummaryQuoteDisount>;
  quoteTotalAmount?: number;
}

export interface ISummaryQuoteDisount {
  modifier: string;
  value: number;
}

export interface ICambriaQuoteSummaryData {
  totalItems: number;
  alertData: any;
  currency: string;
  incentiveDiscount: number;
  tierChangeDiscount: number;
  subtotal: number;
  totalAmount: number;
  discounts: any[];
  isQuoteOrdered: boolean;
}
