import {Cart} from '../../cart/ICartState';
import COMMERCE_CORE_CONSTANTS from '../../../Core/constants';
import {
  Contact,
  CustomerFullProfile,
  PreferredShippingContact,
  PreferredShippingContactRequest,
  Site,
} from '../ICustomerState';
import {
  getCustomerSitesRequest,
  getCustomers,
  postCustomerSiteRequest,
  postPreferredShippingContacts,
  putPreferredShippingContacts,
} from '../controller/customer.controller';
import qs from 'qs';
import {setCurrentCustomer, setCurrentCustomerShipToSites, setPreferredShippingContact} from '../slice/customer.slice';
import {getContactOptions, saveNewShipToContact} from '../../checkout/service/checkout.service';
import {hideFullscreenLoader, showFullscreenLoader} from '../../fullscreenLoader/slice/fullscreenLoader.slice';
import {toast} from 'react-toastify';

export const getCurrentCustomerId = () => {
  const customerId = window.sessionStorage.getItem(COMMERCE_CORE_CONSTANTS.WINDOW_SESSION.selectedCustomerId);
  return Number(customerId);
};

export const formatAddress = (address: any): string => {
  let _address = '';
  for (const key in address) {
    if (address[key]) {
      _address += `${address[key]} `;
    }
  }
  return _address;
};

const formatShippingAddress = (address: any): string => {
  const addressObject = {
    siteName: address.siteName,
    address1: address.address1,
    address2: address.address2,
    address3: address.address3,
    city: address.city,
    stateProvince: address.stateProvince,
    postalCode: address.postalCode,
  };

  return formatAddress(addressObject);
};

export const convertSalesforceContactToCheckoutContact = (contact: any) => {
  const checkoutContact: any = {};
  checkoutContact.firstName = contact.firstName;
  checkoutContact.lastName = contact.lastName;
  checkoutContact.phoneNumber = [contact.phone];
  checkoutContact.email = [contact.email];
  checkoutContact.mobileNumber = [contact.mobilePhone];
  checkoutContact.displayName = contact.firstName ? `${contact.firstName} ${contact.lastName}` : `${contact.lastName}`;
  return checkoutContact;
};

export const convertAccountToSite = (retailAccount: any, warehouse?: any): Site => {
  let result: any = {};

  if (retailAccount.billingAddress) {
    // tslint:disable-next-line: max-line-length
    result.address = `${retailAccount.name} ${retailAccount.billingAddress.street} ${retailAccount.billingAddress.state} ${retailAccount.billingAddress.postalCode}`;
    result.address1 = retailAccount.billingAddress.street;
    result.city = retailAccount.billingAddress.city;
    result.country = retailAccount.billingAddress.country;
    result.countryCode = retailAccount.billingAddress.country;
    result.postalCode = retailAccount.billingAddress.postalCode;
    result.stateProvince = retailAccount.billingAddress.state;
    result.stateProvinceCode = retailAccount.billingAddress.state;
    result.missingAddressDetails = false;
  } else {
    result.missingAddressDetails = true;
    result.address = retailAccount.name;
  }
  if (retailAccount.contacts) {
    result.contacts = retailAccount.contacts.map((contact: any) => {
      return convertSalesforceContactToCheckoutContact(contact);
    });
  }
  if (retailAccount.additional_Addresses) {
    result.additional_Addresses = retailAccount.additional_Addresses.map((address: any) => {
      return address;
    });
  }
  result.siteName = retailAccount.name;
  result.siteType = 'SHIP_TO';
  result.retailCrmAccountId = retailAccount.id;
  result.warehouseCode = warehouse && warehouse.warehouseCode ? warehouse.warehouseCode : '';

  return result;
};

export const getMatchingShipTo = (sites: any, shipTo: any) => {
  return sites.find(
    (s: any) =>
      s.address1 === shipTo.address1 &&
      s.postalCode?.split('-')[0] === shipTo.postalCode?.split('-')[0] &&
      s.siteName === shipTo.siteName
  );
};

export const getAccountDefaultShipToAddress = (sites: any) => {
  let defaultShipTo = sites.find((site: any) => {
    return site.isPrimary === 'Y';
  });

  if (!defaultShipTo) {
    defaultShipTo = sites[0];
  }

  return defaultShipTo;
};

export const getOperatingUnitFromCart = (cart: Cart) =>
  cart?.cartItems?.length > 0 ? cart.cartItems[0].operatingUnitCode : null;

export const getCustomerShipToSitesFilteredByWarehouse = (
  currentCart: Cart,
  currentCustomer: CustomerFullProfile,
  shipToSites: Site[]
): Site[] => {
  let cartProductType =
    currentCart && currentCart.cartItems && currentCart.cartItems.length > 0
      ? currentCart.cartItems[0].productType
      : null;
  let operatingUnitCode = getOperatingUnitFromCart(currentCart);
  let warehouseCodeOverride =
    currentCart && currentCart.orderDetails && currentCart.orderDetails.length > 0
      ? currentCart.orderDetails[0].surfacesWarehouseCodeOverride
      : null;

  if (currentCustomer && currentCustomer.class && currentCustomer.class.toLowerCase() === 'enterprise') {
    switch (cartProductType) {
      case 'Store':
      case 'Samples':
        warehouseCodeOverride = undefined;
        break;
      case 'Tile':
      case 'Surfaces':
      case 'Slab':
      case 'Fabricated':
      case 'FabricatedService':
      case 'Fabrication':
        warehouseCodeOverride =
          window.sessionStorage.getItem(COMMERCE_CORE_CONSTANTS.WINDOW_SESSION.selectedWarehouse) || undefined;
        break;
      default:
        throw new Error('Unknown productType');
    }
  }

  const result = shipToSites
    ? shipToSites.filter(
        (shipToSite: any) =>
          shipToSite.operatingUnitCode === operatingUnitCode &&
          (!warehouseCodeOverride || (warehouseCodeOverride && shipToSite.warehouseCode === warehouseCodeOverride))
      )
    : [];

  return result
    .map((site: any) => {
      return {
        ...site,
        address: formatShippingAddress(site),
        retailCrmAccountId: currentCustomer.crmAccountId,
      };
    })
    .sort((a: any, b: any) => {
      return a.address.localeCompare(b.address);
    });
};

export const getCurrentCustomerShipToSites = async (): Promise<Site[]> => {
  const sessionCustomerId = window.sessionStorage.getItem(COMMERCE_CORE_CONSTANTS.WINDOW_SESSION.selectedCustomerId);

  if (!sessionCustomerId) {
    return Promise.reject('No customer id found in session storage.');
  }

  try {
    const params = {
      siteUse: COMMERCE_CORE_CONSTANTS.SITE_USE.shipto,
    };
    return await getCustomerSitesRequest(sessionCustomerId, params);
  } catch (error) {
    console.log(error);
  }
  return [];
};

export const formatPhoneNumber = (phoneNumber: string | undefined) => {
  if (phoneNumber) {
    if (phoneNumber.includes('-')) {
      return phoneNumber;
    } else {
      const tempNum = phoneNumber.replace(/\D/g, '');
      if (tempNum.length < 10) {
        return phoneNumber;
      } else {
        return `(${tempNum.substring(0, 3)}) ${tempNum.substring(3, 6)}-${tempNum.substring(6)}`;
      }
    }
  } else {
    return '';
  }
};

export const addCustomerSite = async (id: number, params: any) => {
  if (!id) {
    throw new Error('Missing id parameter.');
  }
  if (!params) {
    throw new Error('Invalid/missing parameters.');
  }
  const result = await postCustomerSiteRequest(id, params);
  return await result.text();
};

export const organizeCustomerAutosuggestParams = (keyword: string) => {
  let data = new URLSearchParams();
  data.append('name', keyword);
  data.append('filterCustomersWithoutSites', 'true');
  data.append('pager.limit', '10');
  data.append('pager.offset', '0');
  data.append('pager.expand[0]', 'identifyingAddress');
  data.append('pager.expand[1]', 'totalResults');
  data.append('siteType', 'BILL_TO');

  return data;
};

export const organizeParamsForCustomerSearch = (keyword: string) => {
  let data = new URLSearchParams();
  data.append('name', keyword);
  data.append('filterCustomersWithoutSites', 'true');
  data.append('pager.limit', '50');
  data.append('pager.expand[0]', 'totalResults');
  data.append('pager.expand[1]', 'identifyingAddress');
  data.append('siteType', 'BILL_TO');
  return data;
};

export const routeToOrderDetail = async (
  orderId: number,
  customerId: number,
  url: string,
  history: any,
  dispatch: any
) => {
  if (url.includes('search')) {
    const params = qs.stringify({erpCustomerId: customerId});
    const customerResult = await getCustomers(params);
    const customerInformation = customerResult.results[0];
    dispatch(setCurrentCustomer(customerInformation));
    window.sessionStorage.setItem(COMMERCE_CORE_CONSTANTS.WINDOW_SESSION.selectedCustomerId, customerInformation.id);
    window.location.assign(`/account/orders/details/${orderId}?erpCustomerId=${customerId}`);
  } else {
    history.push(`/account/orders/details/${orderId}?erpCustomerId=${customerId}`);
  }
};

export const filterAvailableOperatingUnitCodes = (currentCustomerBillToSites: Site[]) => {
  let results: Array<string> = [];
  if (currentCustomerBillToSites && currentCustomerBillToSites.length > 0) {
    for (const site of currentCustomerBillToSites) {
      if (site.operatingUnitCode && !results.includes(site.operatingUnitCode)) {
        results.push(site.operatingUnitCode);
      }
    }
  }

  return results;
};

export const getExistingPreferredShippingContact = async (
  shippingAddress: Site,
  currentCustomerShipToSites: Site[],
  operatingUnitCode: string,
  preferredShippingContact: PreferredShippingContact[],
  dispatch: Function,
  contact?: Contact
) => {
  const allContactOptions = getContactOptions(
    currentCustomerShipToSites,
    undefined,
    undefined,
    undefined,
    operatingUnitCode,
    true
  );

  let preferredContact = allContactOptions.find(
    (contact: Contact) => contact.erpCustomerContactId?.toString() === preferredShippingContact[0]?.erpContactId
  );

  if (!preferredContact && contact) {
    preferredContact = contact;
  }

  let phoneNumber = [];
  if (preferredContact?.phoneNumber && preferredContact?.phoneNumber.length > 0) {
    phoneNumber = preferredContact.phoneNumber;
  } else if (preferredContact?.phone && preferredContact?.phone.length > 0) {
    phoneNumber = preferredContact.phone;
  }

  if (shippingAddress.contacts && shippingAddress.contacts.length > 0) {
    for (let contact of shippingAddress.contacts) {
      if (
        contact.firstName === preferredContact?.firstName &&
        contact.lastName === preferredContact?.lastName &&
        JSON.stringify(contact.email) === JSON.stringify(preferredContact?.email) &&
        JSON.stringify(contact.phoneNumber) === JSON.stringify(phoneNumber)
      ) {
        let tempContact = JSON.parse(JSON.stringify(contact));
        (tempContact as any).displayName = preferredContact?.displayName;
        return tempContact;
      }
    }
  }

  let params = {
    firstName: preferredContact.firstName ? preferredContact.firstName.trim() : null,
    lastName: preferredContact.lastName ? preferredContact.lastName.trim() : null,
    phone: phoneNumber[0] ? [formatPhoneNumber(phoneNumber[0].trim())] : [],
    email: preferredContact.email[0] ? [preferredContact.email[0].trim()] : [],
    customerSiteId: shippingAddress.id,
  };

  dispatch(showFullscreenLoader({showCartIcon: false}));

  await saveNewShipToContact(params, dispatch);

  dispatch(hideFullscreenLoader());

  const updatedSites = JSON.parse(JSON.stringify(currentCustomerShipToSites));

  updatedSites.forEach((site: any) => {
    if (site.id === shippingAddress.id) {
      site.contacts.push(preferredContact);
    }
  });

  await dispatch(setCurrentCustomerShipToSites(updatedSites));

  return preferredContact;
};

export const updatePreferredShippingContacts = async (
  data: Array<PreferredShippingContactRequest>,
  preferredShippingContacts: Array<PreferredShippingContact>,
  dispatch: Function
) => {
  const putData: PreferredShippingContactRequest[] = [];
  const postData: PreferredShippingContactRequest[] = [];

  for (const contact of data) {
    const existingContact = preferredShippingContacts.find((c) => c.operatingUnitCode === contact.operatingUnitCode);
    if (existingContact) {
      if (existingContact.erpContactId !== contact.erpContactId) {
        putData.push(contact);
      }
    } else {
      postData.push(contact);
    }
  }

  if (putData.length === 0 && postData.length === 0) {
    toast.warning(
      'Our records indicate the preferred shipping contact selected by the user is the same as it was before, no changes have been made!'
    );
  }

  if (postData.length > 0) {
    await postPreferredShippingContacts(postData);
  }

  if (putData.length > 0) {
    await putPreferredShippingContacts(putData);
  }

  dispatch(setPreferredShippingContact(data));
};
