/* istanbul ignore file */
import './bootstrap.tsx';
import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {ToastContainer, Slide} from 'react-toastify';

import App from './App';
import {store} from './store/store';
import {AppContainer} from './Styled/app';
import 'react-toastify/dist/ReactToastify.css';

ReactDOM.render(
  <AppContainer className="new-style">
    <ToastContainer
      theme="colored"
      position="top-right"
      autoClose={5000}
      hideProgressBar
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable={false}
      transition={Slide}
      pauseOnHover
    />
    <div className="react-bootstrap-hack" id="modal-container"></div>
    <Provider store={store}>
      <App />
    </Provider>
  </AppContainer>,
  document.getElementById('root')
);
