import styled from 'styled-components/macro';
import {$screenmd} from '../../../../Styled/variables';

type CambriaLogoContainerProps = {
  bgColor: string;
  height: string;
};

export const CambriaLogoContainer = styled.div<CambriaLogoContainerProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  height: 100%;
  padding: 20px;
  background-color: ${(props) => props.bgColor};
  @media (max-width: ${$screenmd}) {
    padding: 0;
  }

  &.unauthenticated,
  &.checkout {
    @media (max-width: ${$screenmd}) {
      img {
        height: 17px;
      }
    }
  }

  img {
    height: ${(props) => props.height}px;
    vertical-align: baseline !important;
    width: 100%;
  }
`;
