import React, {useState} from 'react';
import {Accordion, Card} from 'react-bootstrap';
import {CustomerTablePlaceholderMobile} from '../../Placeholders/CustomerTablePlaceholder.component';

export const MobileCustomerPanel = ({
  customer,
  customerSelectionFunction,
}: {
  customer: any;
  customerSelectionFunction: Function;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Accordion key={customer.name}>
      <Card>
        <Card.Header>
          <Accordion.Toggle className="accordion-header customer-name" eventKey="0" onClick={() => setIsOpen(!isOpen)}>
            {customer.name}
            <i className={`fa fa-chevron-${isOpen ? 'up' : 'down'}`}></i>
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey="0">
          <Card.Body>
            <div className="accordion-info-table">
              <div className="accordion-info-line address">
                <span className="accordion-info-line-value">
                  {customer.address1}
                  {customer.address2 ? `${customer.address2} ` : ''}
                  <br /> {customer.city}, {customer.stateProvince} {customer.postalCode}
                </span>
              </div>
              <div className="accordion-info-line class">
                <span className="accordion-info-line-value">{customer.class}</span>
              </div>
            </div>
            <hr />
            <div className="accordion-info-line select-account" onClick={() => customerSelectionFunction(customer)}>
              <span>SELECT ACCOUNT</span>
            </div>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
};
export const CustomerMobilePanelGroup = ({
  suggestions,
  customerSelectionFunction,
}: {
  suggestions: any[];
  customerSelectionFunction: Function;
}) => {
  return (
    <div>
      {suggestions.map((customer, index) => {
        return (
          <MobileCustomerPanel key={index} customer={customer} customerSelectionFunction={customerSelectionFunction} />
        );
      })}
    </div>
  );
};
const MobileResults = ({
  suggestions,
  isLoading,
  customerSelectionFunction,
}: {
  suggestions: any[];
  isLoading: boolean;
  customerSelectionFunction: Function;
}) => {
  if (isLoading) {
    return <CustomerTablePlaceholderMobile />;
  }
  return <CustomerMobilePanelGroup suggestions={suggestions} customerSelectionFunction={customerSelectionFunction} />;
};
export default MobileResults;
