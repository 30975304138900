import React from 'react';
import useWindowDimensions from '../../../../../hooks/getWindowDimensions';
import QuoteDetailsHeaderDesktop from './desktop';
import QuoteDetailsHeaderMobile from './mobile';
import {IQuoteDetails, IQuoteHeaderData, initialQuoteHeaderData} from '../../../../../features/quote/IQuoteState';
import {useTypedSelector} from '../../../../../hooks/store';
import {selectQuoteDetails} from '../../../../../features/quote/slice/quote.slice';

const QuoteDetailsHeader = () => {
  const {width} = useWindowDimensions();

  const quote: IQuoteDetails = useTypedSelector(selectQuoteDetails);

  const headerData: IQuoteHeaderData = quote?.id
    ? {
        requestedBy: '',
        quoteNumber: quote.crmQuoteNumber,
        subcontractorName: quote.subContractor.companyName,
        location: quote.type === 'commercial' ? quote.location.city + ', ' + quote.location.stateProvince : '',
        status: quote.statusDisplayName,
        statusCode: quote.statusCode,
        dateOrdered: quote.createdAt,
        projectName: quote.name,
        expirationDate: quote.expirationDate,
        createdBy: `${quote.crmContactFirstName} ${quote.crmContactLastName}`,
      }
    : initialQuoteHeaderData;

  if (width > 960) {
    return <QuoteDetailsHeaderDesktop headerData={headerData} />;
  } else {
    return <QuoteDetailsHeaderMobile headerData={headerData} />;
  }
};

export default QuoteDetailsHeader;
