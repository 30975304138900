import styled from 'styled-components/macro';

export const CartItemsContainer = styled.div`
  .mobile-cart-items {
    .mobile-item-primary-container {
      background-color: #fff;
      padding: 7px 0px 0px;
      box-shadow: 0px 0px 3px #ccc;

      .mobile-unit-price-container {
        margin-top: 5px;

        .mobile-unit-price {
          text-align: right;
        }
      }

      .mobile-item-image-container {
        margin: 0px 0px 15px;
      }

      .mobile-remove-button-container {
        border-top: 1px solid #d8d8d8;
        border-right: 1px solid #d8d8d8;

        .mobile-remove-button {
          display: flex;
          justify-content: center;
          margin: 7px 0px;
        }

        .item-field-remove-save-buttons {
          text-decoration: none !important;
        }
      }

      .mobile-save-button-container {
        border-top: 1px solid #d8d8d8;

        .mobile-save-button {
          display: flex;
          justify-content: center;
          margin: 7px 0px;
        }

        .item-field-remove-save-buttons {
          text-decoration: none !important;
        }
      }

      .mobile-item-description-text {
        text-align: left;
        white-space: normal;
      }
    }

    .mobile-item-secondary-container {
      padding: 7px 0px 0px;
      margin-bottom: 10px;

      .cambria-counter-plus-minus-container {
        width: 131px !important;
      }

      .counter-plus-icon-container {
        right: 15px !important;
        background-color: #fff !important;
        padding-left: 10px !important;
        padding-bottom: 1px !important;
        width: 35px !important;
        z-index: 0 !important;
      }

      .counter-minus-icon-container {
        left: -15px !important;
        background-color: #fff !important;
        padding-right: 10px !important;
        padding-bottom: 1px !important;
        width: 35px !important;
        z-index: 0 !important;
      }

      .cambria-counter-input {
        width: 40px !important;
        height: 35px !important;
        left: 30px !important;
        padding: 0px !important;
        border: 1px solid #d8d8d8 !important;
      }

      .item-field-price-text {
        text-align: right;
        padding: 0px;
      }

      .item-field-currency-text {
        text-align: right;
        padding: 0px;
      }

      .mobile-line-price-text {
        font-size: 9px;
        margin-right: 5px;
      }
    }
  }
`;
