import {Row} from 'react-bootstrap';
import styled from 'styled-components';
import {
  $gray30,
  $gray60,
  $newdanger,
  $newprimary,
  $newprimaryfont,
  $newwhite,
} from '../../../../../../Styled/variables';

interface OrderTypeContainerProps {
  boxshadow?: boolean;
}

const JobOrderTypeContainer = styled(Row)<OrderTypeContainerProps>`
  box-shadow: ${(props: OrderTypeContainerProps) =>
    props.boxshadow ? '0px 1px 3px 0px rgba(101, 101, 101, 0.35)' : ''};
  /* box-shadow: 0px 1px 3px 0px rgba(101, 101, 101, 0.35); */
  background-color: ${$newwhite};
  border-radius: 3px;
  padding: 5px;
  margin-right: 0px !important;
  margin-left: 0px !important;

  input {
    height: 34px !important;
    border-radius: 3px !important;
    font-size: 12px !important;
    box-shadow: 0px 1px 3px 0px rgba(101, 101, 101, 0.35);
    font-weight: 600 !important;
    color: ${$gray30} !important;
    border: ${$gray30};
  }

  .row {
    margin: 10px 0px;
  }

  .edit-icon-column {
    align-items: center;
    display: flex;
    justify-content: flex-end;

    .btn-primary {
      width: 100px;
      height: 41px;
      font-size: 14px;
      background-color: ${$newprimary};
      border: none;
      font-weight: 600;
      padding: 10px;

      :hover {
        background-color: ${$newprimary};
      }

      :active {
        background-color: ${$newprimary} !important;
        border: none;
      }

      :focus {
        background-color: ${$newprimary} !important;
        border: none;
      }
    }

    .btn-default {
      width: 100px;
      font-size: 14px;
      font-weight: 600;
      height: 41px;
      border: 1px solid ${$gray60};
      margin-right: 2px;
    }
  }

  .fab-data-display {
    font-size: 12px;
    color: ${$gray60};

    .display-line {
      margin-bottom: 5px;
    }

    span {
      color: ${$gray30};
    }
  }

  .checkbox {
    color: ${$newwhite};
    margin: 20px 0px;
    cursor: pointer;

    label {
      color: ${$gray60};
      font-size: 12px;
      font-weight: 700;
      line-height: 22px;
      padding-left: 38px;

      &:before {
        content: '';
        display: inline-block;
        position: absolute;
        left: 0px;
        box-shadow: none;
        width: 30px;
        height: 30px;
        color: ${$gray60};
        text-align: center;
        line-height: 22px;
        background-color: ${$newwhite};
        border-color: transparent;
        border-width: 1px;
        border-radius: 3px;
        color: ${$newwhite};
        border-image: initial;
        box-shadow: 0px 1px 3px 0px rgba(101, 101, 101, 0.35);
      }
    }

    input[type='checkbox']:checked ~ label:before {
      vertical-align: middle;
      color: #c59617;
      font-family: icomoon;
      font-size: 25px;
      line-height: 27px;
      content: '\\e906';
    }

    input {
      display: none;
      position: absolute;
      margin-left: -20px;
    }
  }

  .extra-info-column {
    padding-left: 30px;
    padding-right: 15px;
  }

  .z-index-0 {
    z-index: 0;
  }

  .checkbox-heavy-label {
    font-size: 14px;
    font-weight: 800;
    color: black;
  }

  .job-number-checking-spinner {
    position: absolute;
    top: 33px;
    right: 22px;
  }

  .order-details-save-button-help {
    .list-tooltip-wrapper {
      user-select: none;
      -webkit-user-select: none;
      padding: 8px 0 8px 8px;
      top: -2px;
      position: relative;
      cursor: pointer;

      &:hover {
        .order-details-save-button-tooltip {
          transform: translate3d(-50%, 0, 0);
          visibility: visible;
          opacity: 1;
        }
      }

      .order-details-save-button-tooltip {
        text-align: left;
        top: -81px;
        right: -162px;
        transform: translate3d(-50%, -15px, 0);
        position: absolute;
        padding: 12px;
        visibility: hidden;
        opacity: 0;
        background: white;
        transition: all 0.25s cubic-bezier(0, 0, 0.2, 1);
        color: ${$newdanger};
        border: 1px solid ${$gray60};
        border-radius: 3px;
        font-family: ${$newprimaryfont};
        font-size: 12px;
        font-weight: 500;
        line-height: 1.25;
        box-shadow: 0 1px 1px ${$gray60};
        z-index: 4;

        &:after {
          content: '';
          display: block;
          position: absolute;
          width: 0;
          height: 0;
          border-style: solid;
          bottom: -0.5em;
          left: 90%;
          transform: rotate(180deg);
          border-width: 0 0.5em 0.5em 0.5em;
          border-color: transparent transparent white transparent;
          -webkit-filter: drop-shadow(1px 2px 1px ${$gray60});
          filter: drop-shadow(1px -1px 1px ${$gray60});
        }
      }
    }
  }
`;

export default JobOrderTypeContainer;
