import React, {useEffect} from 'react';
import {selectActiveCart} from '../../../../features/cart/slice/cart.slice';
import {selectCurrentCustomer} from '../../../../features/customer/slice/customer.slice';
import {CustomerFullProfile} from '../../../../features/customer/ICustomerState';
import {
  selectCustomerValidationStatus,
  selectDefaultCustomerStatus,
  selectSalesforceInformationLoadStatus,
  setSalesforceInformationLoaded,
} from '../../../../features/resolves/slice/resolves.slice';
import {setSessionStorageProgram} from '../../../../features/salesforce/program/service/program.service';
import {
  selectActiveProgram,
  selectAvailablePrograms,
  selectAvailableProgramsStatus,
  selectCurrentCustomerSfAccount,
  selectCurrentCustomerSfAccountStatus,
  selectSalesforceCustomerAccount,
  selectSalesforceCustomerAccountStatus,
  setAvailableProgramsAsync,
  setRetailAccountsByErpCustomerIdAsync,
  setSalesforceCustomerAccount,
} from '../../../../features/salesforce/slice/salesforce.slice';
import {useAppDispatch, useTypedSelector} from '../../../../hooks/store';
import COMMERCE_CORE_CONSTANTS from '../../../../Core/constants';
import {toast} from 'react-toastify';
import {useLogout} from '../../../../hooks/useLogout';
import {useCustomerLogout} from '../../../../hooks/useCustomerLogout';
import {getBundleProgramRulesAsync} from '../../../../features/productCatalog/slice/productCatalog.slice';
import {isBundleProgram} from '../../../../features/salesforce/service/salesforce.service';

const SalesforceResolves = ({children}: {children: any}) => {
  const logout = useLogout();
  const customerLogout = useCustomerLogout();
  const dispatch = useAppDispatch();

  const activeCart = useTypedSelector(selectActiveCart);
  const availablePrograms = useTypedSelector(selectAvailablePrograms);
  const availableProgramsStatus = useTypedSelector(selectAvailableProgramsStatus);
  const currentCustomer: CustomerFullProfile | null = useTypedSelector(selectCurrentCustomer);
  const salesforceCustomerAccount = useTypedSelector(selectSalesforceCustomerAccount);
  const salesforceCustomerAccountStatus = useTypedSelector(selectSalesforceCustomerAccountStatus);
  const currentCustomerSfAccount = useTypedSelector(selectCurrentCustomerSfAccount);
  const customerIsValid = useTypedSelector(selectCustomerValidationStatus);
  const activeProgram = useTypedSelector(selectActiveProgram);
  const salesForceInformationLoaded = useTypedSelector(selectSalesforceInformationLoadStatus);
  const defaultCustomerNotFound = useTypedSelector(selectDefaultCustomerStatus);
  const currentCustomerSfAccountStatus = useTypedSelector(selectCurrentCustomerSfAccountStatus);

  const slabWarehouse = currentCustomer?.productTypeWarehouseCodes?.find(
    (t) => t.productType === COMMERCE_CORE_CONSTANTS.PRODUCT_TYPES.SLAB
  )?.warehouseCode;
  const customerCanChangeUsers = defaultCustomerNotFound === null || defaultCustomerNotFound;

  useEffect(() => {
    if (availablePrograms && salesforceCustomerAccount && currentCustomerSfAccount && activeProgram.name) {
      dispatch(setSalesforceInformationLoaded(true));
    }
  }, [availablePrograms, salesforceCustomerAccount, currentCustomerSfAccount, activeProgram, dispatch]);

  useEffect(() => {
    // Validate that the customer is not malconfigured and set only
    // with bundle program and 211 warehouse
    if (
      slabWarehouse === COMMERCE_CORE_CONSTANTS.WAREHOUSES.CAMBRIA_PLANT &&
      availablePrograms?.programs?.length === 1 &&
      isBundleProgram(availablePrograms.programs[0])
    ) {
      toast.error(
        "This customer can't select the bundle program because it ships from Cambria Plant. Contact Sales Operations to update the warehouse or program information."
      );
      if (customerCanChangeUsers) {
        customerLogout();
      } else {
        logout();
      }
    }
  }, [availablePrograms, slabWarehouse, customerCanChangeUsers, customerLogout, logout]);

  useEffect(() => {
    if (isBundleProgram(activeProgram)) {
      dispatch(getBundleProgramRulesAsync());
    }
  }, [dispatch, activeProgram]);

  useEffect(() => {
    if (availablePrograms && availablePrograms.programs && availablePrograms.programs.length > 0 && customerIsValid) {
      const sessionSelectedProgramCode = window.sessionStorage.getItem(
        `${COMMERCE_CORE_CONSTANTS.WINDOW_SESSION.selectedProgram}${availablePrograms.accountId}`
      );
      if ((!activeCart || !activeCart.cartItems || activeCart.cartItems.length === 0) && sessionSelectedProgramCode) {
        // We don't allow customers shipping to warehouse 211 to select the bundle program
        if (
          slabWarehouse !== COMMERCE_CORE_CONSTANTS.WAREHOUSES.CAMBRIA_PLANT ||
          !sessionSelectedProgramCode?.startsWith(COMMERCE_CORE_CONSTANTS.PROGRAMS.BUNDLE_PROGRAM_CODE_PREFIX)
        ) {
          setSessionStorageProgram(availablePrograms, dispatch);
        } else {
          toast.error(COMMERCE_CORE_CONSTANTS.ERROR_MESSAGES.CANT_SELECT_BUNDLE_ERROR);
          window.sessionStorage.setItem(
            `${COMMERCE_CORE_CONSTANTS.WINDOW_SESSION.selectedProgram}${availablePrograms.accountId}`,
            availablePrograms.defaultProgramCode
          );
        }
      } else if (!sessionSelectedProgramCode) {
        window.sessionStorage.setItem(
          `${COMMERCE_CORE_CONSTANTS.WINDOW_SESSION.selectedProgram}${availablePrograms.accountId}`,
          availablePrograms.defaultProgramCode
        );
      }
    }
  }, [activeCart, availablePrograms, customerIsValid, slabWarehouse, dispatch]);

  useEffect(() => {
    if (
      currentCustomer?.erpCustomerId &&
      !salesforceCustomerAccount.id &&
      customerIsValid &&
      salesforceCustomerAccountStatus !== 'loading'
    ) {
      dispatch(setSalesforceCustomerAccount({customerErpCustomerId: currentCustomer.erpCustomerId}));
    }
  }, [currentCustomer, salesforceCustomerAccount, dispatch, customerIsValid, salesforceCustomerAccountStatus]);

  useEffect(() => {
    if (
      !currentCustomerSfAccount?.id &&
      currentCustomer &&
      currentCustomer.erpCustomerId &&
      customerIsValid &&
      currentCustomerSfAccountStatus !== 'loading'
    ) {
      dispatch(setRetailAccountsByErpCustomerIdAsync({erpCustomerId: currentCustomer.erpCustomerId}));
    }
  }, [dispatch, currentCustomer, currentCustomerSfAccount, customerIsValid, currentCustomerSfAccountStatus]);

  useEffect(() => {
    if (
      currentCustomerSfAccount?.id &&
      currentCustomerSfAccount.id !== availablePrograms?.accountId &&
      !salesForceInformationLoaded &&
      availableProgramsStatus !== 'loading'
    ) {
      dispatch(
        setAvailableProgramsAsync({
          customerClassName: currentCustomer?.class,
          salesforceAccountId: currentCustomerSfAccount?.id,
        })
      );
    }
  }, [
    dispatch,
    availablePrograms,
    currentCustomer,
    currentCustomerSfAccount,
    salesForceInformationLoaded,
    availableProgramsStatus,
  ]);

  return <div>{children}</div>;
};

export default SalesforceResolves;
