import styled from 'styled-components';
import {$gray30, $gray60, $gray67, $gray78, $gray93, $newwhite} from '../../../../../Styled/variables';

export const ReviewOrderHeaderContainer = styled.div`
  background: ${$gray93};
  padding: 25px 20px;
  box-shadow: 0px 1px 3px 0px rgba(101, 101, 101, 0.35);

  .user-name {
    font-size: 24px;
    font-weight: 400;
  }

  .row {
    margin-top: 0px !important;
  }

  .status-box {
    color: ${$gray67};
    padding: 7px 20px;
    background: ${$newwhite};
    outline: 1px solid ${$gray78};
    box-shadow: 0px 1px 3px 0px rgba(101, 101, 101, 0.35);
    border-radius: 3px;
  }

  .attribute-title {
    font-weight: 700;
    color: ${$gray60};
  }

  .attribute-value {
    font-weight: 700;
    color: ${$gray30};
  }

  .fabrication-instructions-title {
    text-wrap: nowrap;
  }
`;
