import React, {useEffect, useState} from 'react';
import {toast} from 'react-toastify';
import {baseApiCallWithReauth} from '../../../../../Framework/api-utils/api-utils';
import {DeliveryDays, deliveryDaysBlank} from '../IOrderPreferences';
import {hasPermission} from '../../../../../store/permission/permission.service';
import {useTypedSelector} from '../../../../../hooks/store';
import {selectUserActions} from '../../../../../features/permission/slice/permission.slice';

const DeliveryDayPreferences = ({endpoint, erpCustomerId}: {endpoint: string; erpCustomerId: number}) => {
  let [noContent, setNoContent] = useState<boolean>(true);
  let [isLoading, setIsLoading] = useState<boolean>(true);
  let [deliveryDays, setSlabDeliveryDays] = useState<DeliveryDays>(deliveryDaysBlank);
  let [monday, setMonday] = useState<boolean>(false);
  let [tuesday, setTuesday] = useState<boolean>(false);
  let [wednesday, setWednesday] = useState<boolean>(false);
  let [thursday, setThursday] = useState<boolean>(false);
  let [friday, setFriday] = useState<boolean>(false);
  let [saturday, setSaturday] = useState<boolean>(false);
  let [sunday, setSunday] = useState<boolean>(false);

  let userActions = useTypedSelector(selectUserActions);

  let showPreferredShippingContact = hasPermission('urn:csa:commerceui:preferredShippingContact', userActions);

  const putDeliveryDatePreference = async (endpoint: string) => {
    let data = {
      canPickMonday: monday,
      canPickTuesday: tuesday,
      canPickWednesday: wednesday,
      canPickThursday: thursday,
      canPickFriday: friday,
      canPickSaturday: saturday,
      canPickSunday: sunday,
    };
    let putEndpoint = endpoint + `/${erpCustomerId}`;
    let results = await baseApiCallWithReauth('PUT', putEndpoint, JSON.stringify(data), true);
    if (results.status === 200) {
      toast.success('Delivery days have been successfully updated!');
    }
  };

  const postDeliveryDatePreference = async (endpoint: string) => {
    let data = {
      erpCustomerId: erpCustomerId,
      canPickMonday: monday,
      canPickTuesday: tuesday,
      canPickWednesday: wednesday,
      canPickThursday: thursday,
      canPickFriday: friday,
      canPickSaturday: saturday,
      canPickSunday: sunday,
    };
    let results = await baseApiCallWithReauth('POST', endpoint, JSON.stringify(data), true);
    if (results.status === 200) {
      toast.success('Delivery days have been successfully updated!');
    }
  };

  const updateDeliveryDays = async (endpoint: string) => {
    if (noContent) {
      postDeliveryDatePreference(endpoint);
    } else {
      putDeliveryDatePreference(endpoint);
    }
  };

  useEffect(() => {
    const getOrderPreferencesOnLoad = async (endpoint: string, erpCustomerId: number) => {
      if (erpCustomerId !== -1) {
        setIsLoading(true);
        let getEndpoint = endpoint + `/${erpCustomerId.toString()}`;
        let results = await baseApiCallWithReauth('GET', getEndpoint);
        if (results.id) {
          setNoContent(false);
        }
        setSlabDeliveryDays(results);
        setIsLoading(false);
      }
    };
    getOrderPreferencesOnLoad(endpoint, erpCustomerId);
  }, [endpoint, erpCustomerId]);

  useEffect(() => {
    setMonday(deliveryDays.canPickMonday);
    setTuesday(deliveryDays.canPickTuesday);
    setWednesday(deliveryDays.canPickWednesday);
    setThursday(deliveryDays.canPickThursday);
    setFriday(deliveryDays.canPickFriday);
    setSaturday(deliveryDays.canPickSaturday);
    setSunday(deliveryDays.canPickSunday);
  }, [deliveryDays]);

  return (
    <div className={`preference-page-subheading-container ${showPreferredShippingContact ? 'col-md-3' : ''} col-sm-12`}>
      <p className="preference-page-subheading w-100">DELIVERY DAYS</p>

      <div className="checkbox-list-container w-100">
        <div className="checkbox">
          <input id="sunday-checkbox" type="checkbox" checked={sunday} onChange={() => setSunday(!sunday)} />
          <label className="checkbox-label" htmlFor="sunday-checkbox">
            Sunday
          </label>
        </div>
        <div className="checkbox">
          <input id="monday-checkbox" type="checkbox" checked={monday} onChange={() => setMonday(!monday)} />
          <label htmlFor="monday-checkbox" className="checkbox-label">
            Monday
          </label>
        </div>
        <div className="checkbox">
          <input id="tuesday-checkbox" type="checkbox" checked={tuesday} onChange={() => setTuesday(!tuesday)} />
          <label className="checkbox-label" htmlFor="tuesday-checkbox">
            Tuesday
          </label>
        </div>
        <div className="checkbox">
          <input
            id="wednesday-checkbox"
            type="checkbox"
            checked={wednesday}
            onChange={() => setWednesday(!wednesday)}
          />
          <label className="checkbox-label" htmlFor="wednesday-checkbox">
            Wednesday
          </label>
        </div>
        <div className="checkbox">
          <input id="thursday-checkbox" type="checkbox" checked={thursday} onChange={() => setThursday(!thursday)} />
          <label className="checkbox-label" htmlFor="thursday-checkbox">
            Thursday
          </label>
        </div>
        <div className="checkbox">
          <input id="friday-checkbox" type="checkbox" checked={friday} onChange={() => setFriday(!friday)} />
          <label className="checkbox-label" htmlFor="friday-checkbox">
            Friday
          </label>
        </div>
        <div className="checkbox">
          <input id="saturday-checkbox" type="checkbox" checked={saturday} onChange={() => setSaturday(!saturday)} />
          <label className="checkbox-label" htmlFor="saturday-checkbox">
            Saturday
          </label>
        </div>
        <div className="update-delivery-button-container">
          <button
            disabled={isLoading}
            data-testid="update-delivery-days-btn"
            className="btn btn-primary update-delivery-days-btn"
            onClick={() => updateDeliveryDays(endpoint)}>
            UPDATE DELIVERY DAYS
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeliveryDayPreferences;
