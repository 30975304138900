import React, {useEffect, useState} from 'react';
import {Col, Row} from 'react-bootstrap';
import {LineItem} from '../../../../App/AuthenticatedPages/Account/OrderHistory/OrderDetails/IOrderDetails';
import CambriaProductTableContainer from '../CambriaProductTable.styled';
import {CartItem} from '../../../../features/cart/ICartState';
import {dateWithMonthSpelledOut, formatProductPrices} from '../CambriaProductTable.component';

export const removeDesignFromDescription = (designName: string, description: string) => {
  let newDescription = description.replace(designName, '');
  return newDescription;
};

const ProductTableMobile = ({
  orderLineItems,
  isSlabOrder,
  forQuoteDetails = false,
  forReviewAndPlaceOrder = false,
}: {
  orderLineItems: LineItem[] | CartItem[];
  isSlabOrder: boolean;
  forQuoteDetails?: boolean;
  forReviewAndPlaceOrder?: boolean;
}) => {
  const [showDeliveryDates, setShowDeliveryDates] = useState<boolean>(false);

  const cropImagesBasedOnProductType = (imageUrl: string, productType: string) => {
    let newImageUrl = imageUrl;

    if (productType === 'Slab' || productType === 'Tile') {
      newImageUrl = imageUrl.replace('250x200px', '250x100px');
    }
    return newImageUrl;
  };
  useEffect(() => {
    if (orderLineItems.length > 0) {
      orderLineItems.forEach((item: any) => {
        if (item.requestedDelivery || item.scheduleDelivery || item.requestedDeliveryDate) {
          setShowDeliveryDates(true);
          return;
        }
      });
    }
  }, [orderLineItems]);

  useEffect(() => {
    orderLineItems.forEach((item: LineItem | CartItem) => {
      if (item.designName && item.description) {
        item.description = removeDesignFromDescription(item.designName, item.description);
      }
    });
  }, [orderLineItems]);

  return (
    <CambriaProductTableContainer>
      <div className="line-items-table-mobile">
        <h2>Products</h2>
        {orderLineItems.map((item: any, index) => {
          return (
            <div key={index} className="line-item-card-group">
              <div className="card">
                <Row>
                  <Col xs={8} sm={8} md={8} lg={8}>
                    <div className="design-name">{item.designName}</div>
                    <div>{item.description}</div>
                  </Col>
                  <Col xs={4} sm={4} md={4} lg={4} className="price-per-sqft-container">
                    {item.hasAdjustedUnitPrice && !forQuoteDetails ? (
                      <>
                        {item.adjustedUnitPrice >= item.unitPrice ? (
                          <>
                            <span className="price-per-sqft">{`$${formatProductPrices(item.adjustedUnitPrice)}`}</span>
                            <span className="usd-label"> {item.currency} / sqft</span>
                          </>
                        ) : (
                          <>
                            <span className="price-per-sqft">{`$${formatProductPrices(item.adjustedUnitPrice)}`}</span>
                            <span className="usd-label"> USD / sqft</span>
                            <span className="price-per-sqft-strikethrough">
                              ${formatProductPrices(item.unitPrice)}
                              <span className="usd-label-strikethrough"> {item.currency} / sqft</span>
                            </span>
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        <span className="price-per-sqft">{`$${formatProductPrices(item.unitPrice)}`}</span>
                        <span className="usd-label"> {item.currency} / sqft</span>
                      </>
                    )}
                  </Col>
                </Row>
                <Row className="second-row">
                  {forQuoteDetails ? (
                    <></>
                  ) : (
                    <Col xs={6} sm={6} md={6} lg={6}>
                      {item.widenLink ? (
                        <img src={cropImagesBasedOnProductType(item.widenLink, item.productType)} alt={''} />
                      ) : (
                        <>
                          {item.imageUrl ? (
                            <>
                              <img src={cropImagesBasedOnProductType(item.imageUrl, item.productType)} alt={''} />
                            </>
                          ) : (
                            <></>
                          )}
                        </>
                      )}
                    </Col>
                  )}

                  <Col xs={forQuoteDetails ? 12 : 6} className="delivery-date-mobile">
                    <div className="delivery-date-mobile-quantity">
                      Quantity:
                      <span className="price-per-sqft date">
                        {' '}
                        {item.extendedQuantity ? item.extendedQuantity / 100 : item.quantity}
                      </span>
                    </div>
                  </Col>
                  <Col xs={12} className="delivery-date-mobile-expected-requested">
                    {forQuoteDetails ? (
                      <>
                        <div className="">
                          Expected Delivery Date:
                          <span className="price-per-sqft date">
                            {' '}
                            {item.expectedOrderDate ? dateWithMonthSpelledOut(item.expectedOrderDate) : ''}
                          </span>
                        </div>
                        <div className="status-container">
                          Status: &nbsp;
                          <span>{item.status}</span>
                        </div>
                      </>
                    ) : (
                      <>
                        {!showDeliveryDates ? (
                          <></>
                        ) : (
                          <>
                            <div>
                              Requested Delivery:{' '}
                              <span className="date" data-testid={'requestedDelivery' + index}>
                                {item.requestedDelivery || item.requestedDeliveryDate
                                  ? dateWithMonthSpelledOut(
                                      item.requestedDelivery ||
                                        new Date(item.requestedDeliveryDate.replace(/-/g, '/').replace(/T.+/, ''))
                                    )
                                  : ''}
                              </span>
                            </div>
                            {!forReviewAndPlaceOrder && (
                              <div>
                                Scheduled {isSlabOrder ? 'Delivery' : 'Ship'}:{' '}
                                <span className="date">
                                  {item.scheduleDelivery ? dateWithMonthSpelledOut(item.scheduleDelivery) : ''}
                                </span>
                              </div>
                            )}
                            {!forReviewAndPlaceOrder && <div>{item.status}</div>}
                            <div>
                              Seamed:{' '}
                              <span className="date" data-testid={'seamed' + index}>
                                {item.seamed ? 'Yes' : 'No'}
                              </span>
                            </div>
                          </>
                        )}
                      </>
                    )}
                  </Col>
                </Row>
              </div>
              <Row>
                <Col xs={4} sm={4} md={4} lg={4} className="quantity-box-container"></Col>
                <Col xs={8} sm={8} md={8} lg={8} className="line-price-container">
                  <div>
                    {item.hasAdjustedExtendedNetPrice && !forQuoteDetails ? (
                      <>
                        <span className="line-price-label">LINE PRICE</span> &nbsp;
                        <span className="line-price">{`$${formatProductPrices(item.adjustedExtendedNetPrice)}`}</span>
                        <div className="d-flex justify-content-end">
                          <span className="usd-label">{item.currency}</span>
                        </div>
                        <div className="price-per-sqft-strikethrough d-flex justify-content-end align-items-end">
                          ${formatProductPrices(item.extendedNetPrice ? item.extendedNetPrice : item.linePrice)} &nbsp;
                          <span className="usd-label">{item.currency}</span>
                        </div>
                      </>
                    ) : (
                      <>
                        <span className="line-price-label">LINE PRICE</span> &nbsp;
                        <span className="line-price">{`$${formatProductPrices(
                          item.linePrice ? item.linePrice : item.extendedNetPrice
                        )}`}</span>
                        <div className="d-flex justify-content-end">
                          <span className="usd-label">{item.currency}</span>
                        </div>
                      </>
                    )}
                  </div>
                </Col>
              </Row>
            </div>
          );
        })}
      </div>
    </CambriaProductTableContainer>
  );
};

export default ProductTableMobile;
