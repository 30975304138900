import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import qs from 'qs';
import COMMERCE_CORE_CONSTANTS from '../../../Core/constants';
import {baseApiCallWithReauth} from '../../../Framework/api-utils/api-utils';
import {EnvironmentState} from '../IEnvironmentState';
import {RootState} from '../../../store/store';

export const initialState: EnvironmentState = {
  uiSettings: null,
};

export const getUiSettingsAsync = createAsyncThunk('environment/getUiSettingsAsync', async () => {
  const endpoint = COMMERCE_CORE_CONSTANTS.API_SERVICES.ENVIRONMENT.uiSettings;
  return await baseApiCallWithReauth('GET', endpoint, qs.stringify({key: 'ecommerce'}));
});

export const environmentSlice = createSlice({
  name: 'environment',
  initialState,
  reducers: {
    setUiSettings: (state, action) => {
      state.uiSettings = action.payload;
    },
    removeUiSettings: (state) => {
      state.uiSettings = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUiSettingsAsync.fulfilled, (state: any, action: any) => {
      state.uiSettings = action.payload;
    });
  },
});

export const {setUiSettings, removeUiSettings} = environmentSlice.actions;

export const selectUiSettings = (state: RootState) => state.environment.uiSettings;
export const selectIsRevH = (state: RootState) => state.environment.uiSettings?.bundleRevH;
export const selectIsRevI = (state: RootState) => state.environment.uiSettings?.bundleRevI;
export const selectFabLineItemsLimit = (state: RootState) => state.environment.uiSettings?.fabOrderLineItemsLimit;
export const selectReportsSettings = (state: RootState) => state.environment.uiSettings?.reports;
export const selectShowCloseToExpireQuoteNotifications = (state: RootState) =>
  state.environment.uiSettings?.showCloseToExpireQuoteNotifications;
export const selectEnableCartPreview = (state: RootState) => state.environment.uiSettings?.enableCartPreview;
export const selectCalculateCartDiscountsInBackend = (state: RootState) =>
  state.environment.uiSettings?.calculateCartDiscountsInBackend;
export const selectIsOrderDetailPdfEnabled = (state: RootState) => state.environment.uiSettings?.enableOrderDetailsPdf;

export default environmentSlice.reducer;
