import styled from 'styled-components/macro';
import {
  $gray50,
  $gray60,
  $gray78,
  $gray93,
  $gray97,
  $newprimary,
  $newwhite,
  $screenmd,
  $screensm,
} from '../../../../../Styled/variables';

export const OrderSearchContainer = styled.div`
  background: ${$gray97};
  height: fit-content;

  .autocomplete {
    width: 100%;
  }
  .search-container {
    justify-content: center;
    align-items: center;
    height: fit-content;
    min-height: 140px;
  }
  .btn-primary {
    color: #fff !important;
    background-color: #c59617 !important;
    border-color: ${$gray60} !important;
    :hover {
      background-color: #977312 !important;
    }
  }
  .rbt-input-main,
  .dropdown-button,
  .search-button {
    height: 46px !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .search-bar {
    width: 100%;
  }
  .order-search-bar {
    width: 100%;
  }

  .rbt-input-main {
    width: 100% !important;
    display: block !important;
    padding: 6px 12px !important;
    font-size: 16px !important;
    line-height: 1.428571429 !important;
    color: #4c4c4c !important;
    background-color: #fff !important;
    background-image: none !important;
    border: 1px solid ${$gray60} !important;
    border-radius: 0 !important;
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%) !important;
    transition:
      border-color ease-in-out 0.15s,
      box-shadow ease-in-out 0.15s !important;
    :focus {
      box-shadow:
        inset 0 1px 1px rgb(198 150 25 / 8%),
        0 0 8px rgb(198 150 25 / 60%) !important;
    }
    ::placeholder {
      color: #cfcfd8 !important;
    }
  }

  .search-items-container {
    display: flex;
    height: 46px;
    margin-top: 45px;
    padding: 0px 47px;
  }
  .dropdown-body {
    position: absolute;
    position: absolute;
    border-radius: 0 3px 3px 3px;
    box-shadow: 0 2px 2px rgb(0 0 0 / 25%);
    padding: 10px 30px;
    background-color: #fff;
    width: max-content;
  }
  .dropdown-button {
    background-color: #efefef !important;

    /* width: -moz-fit-content !important;
        width: fit-content !important; */
    height: 46px !important;
    padding: 13px 11px !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-align: center !important;
    align-items: center !important;
    border: 1px solid ${$gray60} !important;
    border-right: none !important;
    white-space: nowrap !important;
    cursor: pointer !important;
  }

  .icons-cambria-Ui-Triangle {
    font-size: 7px !important;
    padding: 0px !important;
    margin: 0 0 2px 6px !important;
    color: ${$gray60};
  }

  .search-button {
    width: 161px;
    font-family: Montserrat;
    font-weight: 400;
    font-size: 16px !important;
    border: none;
    border: 2px solid;
    border-left: none;
    border-color: ${$gray60} !important;
  }

  button {
    border-radius: 0px !important;
  }

  .customer-name {
    width: 35%;
  }

  .dropdown-toggle {
    height: 100% !important;
    background: white !important;
    font-size: 12px;
    color: black !important;
    border: 2px solid #f1f1f4 !important;
    font-size: 16px !important;
    align-content: left !important;

    :hover {
      color: black !important;
      background: white !important;
    }
  }
  .main-dropdown-toggle {
    background-color: ${$gray93} !important;
    color: ${$gray50} !important;
    font-weight: 600 !important;
    text-transform: capitalize !important;
    ::after {
      margin-left: 20px !important;
    }
    :focus {
      box-shadow: none !important;
    }
  }
  .show.dropdown {
    border: 2px solid ${$newprimary} !important;
  }
  .date-input,
  .rs-picker-toggle,
  .rs-picker-toggle-textbox {
    height: 100% !important;
  }
  .rs-picker {
    border-radius: 0px;
    width: 100%;
    height: 100%;
    .rs-picker-toggle-caret {
      height: 28px;
    }
    .rs-picker-toggle {
      border-radius: 0px;
    }
  }
  .rs-calendar-table-cell-selected {
    background-color: ${$newprimary} !important;
  }
  .order-input {
    height: 100%;
    width: 100%;
    background: white !important;
    font-size: 16px !important;
    border: 2px solid ${$gray60} !important;
    border-left: none !important;
  }
  .dropdown {
    border: 2px solid ${$gray60};
  }

  .main-filter-dropdown {
    width: fit-content;
  }

  .secondary-menu {
    width: 100%;
  }

  .dropdown-item {
    font-size: 16px;
  }
  .secondary-dropdown-toggle {
    ::after {
      float: right;
      margin-top: 2px;
    }
  }
  .input-group-text {
    font-size: 16px !important;
  }
  .date-input {
    font-size: 16px !important;
  }
  .display-params {
    display: flex;
    flex-wrap: wrap;
    margin: auto;
    margin-top: 5px;
    margin-left: 45px;
    .active-param {
      box-shadow: 0px 0px 3px 0px rgba(101, 101, 101, 0.35);
      font-size: 16px;
      background: white;
      display: flex;
      margin: 5px;
      .name {
        background: ${$gray60};
        border: 1px solid #bbb;

        color: white;
        padding: 5px 10px;
        font-weight: 700;
      }
      .value {
        padding: 4px;
        color: ${$gray60};
        padding: 5px 10px;
        display: flex;
      }
      .fa-times {
        //position: absolute;
        margin: 5px 0px 0px 25px;
        color: ${$gray78};
        :hover {
          cursor: pointer;
        }
      }
    }
  }

  .rs-picker-toggle-read-only {
    opacity: 1 !important;
  }
  input {
    background: ${$newwhite} !important;
    border: none !important;
    padding: none !important;
  }

  .max-label {
    float: right;
    margin-top: -25px;
    margin-right: 10px;
    font-weight: bold;
    color: ${$newprimary};
    font-size: 16px;
  }
  .min-label {
    margin-left: 10px;
    font-weight: bold;
    color: ${$newprimary};
    font-size: 16px;
  }
  .date-min {
    display: inline-flex;
    width: 100%;
  }
  .date-max {
    display: inline-flex;
    width: 100%;
  }
  .input-group {
    flex-wrap: nowrap !important;
    border: 2px solid ${$gray60};
    border-left: none;
  }

  .rs-btn-close {
    margin-top: 4px;
  }
  .secondary-dropdown-toggle::after {
    margin-top: 10px;
  }
  .gray-header-background {
    position: relative;
    background-color: rgb(204, 204, 204);
    height: 100px;
  }
  .input-and-button-container {
    width: 100%;
    display: flex;
    position: relative;
  }
  .rc-slider {
    width: 90%;
    margin-left: 35px;
    .rc-slider-handle {
      border: 2px solid ${$newprimary};
      :active {
        box-shadow: 0 0 5px #c59617;
      }
    }
    .rc-slider-track {
      background-color: ${$newprimary};
    }
  }
  .react-datepicker {
    font-size: 1em;
  }
  .react-datepicker__header {
    padding-top: 0.8em;
  }
  .react-datepicker__month {
    margin: 0.4em 1em;
  }
  .react-datepicker__day-name,
  .react-datepicker__day {
    width: 1.9em;
    line-height: 1.9em;
    margin: 0.166em;
  }
  .react-datepicker__current-month {
    font-size: 1em;
  }
  .react-datepicker__navigation {
    top: 1em;
    line-height: 1.7em;
    border: 0.45em solid transparent;
  }
  .react-datepicker__navigation--previous {
    //border-right-color: #ccc;
    left: 1em;
  }
  .react-datepicker__navigation--next {
    //border-left-color: #ccc;
    right: 1em;
  }
  .react-datepicker__day--selected {
    background-color: ${$newprimary};
  }
  .react-datepicker-wrapper {
    input {
      width: 100%;
    }
  }
  @media screen and (max-width: ${$screenmd}) {
    min-height: 100px;
    .search-button {
      width: 90px;
    }
    .gray-header-background {
      height: 0px;
    }
    .search-items-container {
      margin-top: 0px;
      margin-bottom: 0px !important;
      padding: 0px;
    }
    .rs-slider {
      width: 90%;
      margin-left: 9px;
    }
    .display-params {
      margin-left: 0px;
    }
    .search-container {
      min-height: 0px;
      max-width: 961px !important;
    }
  }
  @media screen and (max-width: ${$screensm}) {
    .search-container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: fit-content;
      .search-items-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: fit-content !important;
        width: 100%;
        .order-search-bar {
          .order-input {
            border-left: 2px solid ${$gray60} !important;
          }
        }
        .input-group {
          border-left: 2px solid #999;
        }
      }
    }
    .rc-slider {
      margin-left: 15px;
    }
    .input-and-button-container {
      margin-top: 10px;
    }
  }
`;
