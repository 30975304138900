import styled from 'styled-components/macro';
import {$gray78, $newblack, $newprimary, $newprimaryfont, $newwhite, $screensmmax} from '../../../../Styled/variables';

export interface HeroContainerProps {}

export const HeroContainer = styled.div<HeroContainerProps>`
  .home-page-hero {
    position: relative;
    top: 0px;
    margin-bottom: 0px;

    @media (max-width: ${$screensmmax}) {
      top: 53px;
      margin-bottom: 53px;
    }
  }

  &__img {
    width: 100%;
    height: auto;
  }

  &__overlay {
    position: absolute;
    width: 100%;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7) !important;
    @media (max-width: ${$screensmmax}) {
      position: relative;
      background-color: ${$newblack};
    }

    .container {
      display: flex;
      flex-flow: row;
      height: 100%;
      top: 0 !important;
      margin: 50px auto;
      color: ${$newwhite};
      font-family: ${$newprimaryfont};
      font-weight: 500;
      font-size: 24px;
      line-height: 29px;
      text-transform: uppercase;

      @media (max-width: ${$screensmmax}) {
        margin-top: 0;
        margin-bottom: 0 !important;
        flex-flow: column;
        padding: 35px 30px;
      }

      .overlay-block-1,
      .overlay-block-2 {
        display: flex;
        align-items: center;
        padding: 5px 0;
        box-sizing: content-box;

        .content {
          display: flex;
          flex-flow: column;
          align-items: flex-start;
          justify-content: space-between;
          box-sizing: content-box;
          height: 100%;
        }
      }

      .overlay-block-1 {
        padding-right: 50px;

        .content {
          margin-left: auto;

          @media (max-width: ${$screensmmax}) {
            margin-left: initial;
          }

          .title {
            text-transform: initial;
          }
        }
      }

      .overlay-block-2 {
        border-left: 2px solid ${$gray78};
        padding-left: 50px;

        @media (max-width: ${$screensmmax}) {
          border: none;
          padding-left: 0;
          margin-top: 30px;
        }
      }

      .title {
        color: ${$newwhite};
        font-family: ${$newprimaryfont};
        font-weight: 300;
        font-size: 36px;

        @media (max-width: ${$screensmmax}) {
          font-size: 24px;
          line-height: 29px;
          margin-bottom: 24px;
          letter-spacing: 0.4px;
        }
      }

      .description {
        @media (max-width: ${$screensmmax}) {
          margin-bottom: 24px;
          font-size: 16px;
          line-height: 20px;
          letter-spacing: 0.26px;
        }
      }

      .cta {
        color: ${$newwhite};
        font-family: ${$newprimaryfont};
        border-bottom: 3px solid ${$newprimary};

        @media (max-width: ${$screensmmax}) {
          font-size: 16px;
          line-height: 20px;
          letter-spacing: 0.26px;
        }
      }
    }
  }
`;
