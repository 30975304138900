import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import qs from 'qs';
import {addCardInBraintree} from '../controller/payment.controller';
import {PaymentState} from '../IPaymentState';
import COMMERCE_CORE_CONSTANTS from '../../../Core/constants';
import {baseApiCallWithReauth} from '../../../Framework/api-utils/api-utils';
import {RootState} from '../../../store/store';

export const initialState: PaymentState = {
  paymentSelections: null,
  creditCards: null,
  selectedCheckoutCreditCard: null,
};

export const getPaymentSelections = createAsyncThunk(
  'payment/paymentSelections',
  async ({
    erpPaymentTermsId,
    customerClass,
    productType,
  }: {
    erpPaymentTermsId: number | undefined;
    customerClass?: any;
    productType?: string;
  }) => {
    const endpoint = COMMERCE_CORE_CONSTANTS.API_SERVICES.PAYMENT.paymentSelections;
    return await baseApiCallWithReauth(
      'GET',
      endpoint,
      qs.stringify({
        erpPaymentTermsId,
        customerClass,
        productType: productType || 'null',
      })
    );
  }
);

export const getCreditCards = createAsyncThunk(
  'payment/creditCards',
  async ({erpCustomerId}: {erpCustomerId: number | undefined}) => {
    const endpoint = COMMERCE_CORE_CONSTANTS.API_SERVICES.PAYMENT.creditCards;
    return await baseApiCallWithReauth(
      'GET',
      endpoint,
      qs.stringify({
        erpCustomerId,
        pager: {
          limit: 50,
        },
      })
    );
  }
);

export const addCreditCard = createAsyncThunk(
  'payment/addCreditCard',
  async (
    {userId, erpCustomerId, nonce}: {userId: string | undefined; erpCustomerId: number | undefined; nonce: string},
    {rejectWithValue}
  ) => {
    if (userId && erpCustomerId) {
      const endpoint = `${COMMERCE_CORE_CONSTANTS.API_SERVICES.PAYMENT.creditCards}`;
      try {
        return await baseApiCallWithReauth(
          'POST',
          endpoint,
          JSON.stringify({
            userId,
            erpCustomerId,
            nonce,
          })
        );
      } catch (error) {
        return rejectWithValue({error: error});
      }
    } else {
      return rejectWithValue(null);
    }
  }
);

export const updateCreditCard = createAsyncThunk(
  'payment/updateCreditCard',
  async (
    {
      body,
      userId,
      erpCustomerId,
      token,
    }: {
      body: any;
      userId: string | undefined;
      erpCustomerId: number | undefined;
      token: string | undefined;
    },
    {rejectWithValue}
  ) => {
    if (body && userId && erpCustomerId && token) {
      const endpoint = `${COMMERCE_CORE_CONSTANTS.API_SERVICES.PAYMENT.creditCards}/${token}`;
      try {
        const nonce = await addCardInBraintree(body);
        return await baseApiCallWithReauth(
          'PUT',
          endpoint,
          JSON.stringify({
            userId,
            erpCustomerId,
            nonce,
          })
        );
      } catch (error) {
        return rejectWithValue({error: error});
      }
    } else {
      return rejectWithValue(null);
    }
  }
);

export const deleteCreditCard = createAsyncThunk(
  'payment/deleteCreditCard',
  async (cardToken: string | null, {rejectWithValue}) => {
    if (cardToken) {
      const endpoint = `${COMMERCE_CORE_CONSTANTS.API_SERVICES.PAYMENT.creditCards}/${cardToken}`;
      await baseApiCallWithReauth('DELETE', endpoint);
      return cardToken;
    } else {
      return rejectWithValue(null);
    }
  }
);

export const paymentSlice = createSlice({
  name: 'payment',
  initialState,
  reducers: {
    setSelectedCheckoutCreditCard: (state, action) => {
      state.selectedCheckoutCreditCard = action.payload;
    },
    setPaymentInitialState: (state: any) => {
      for (const key in initialState) {
        state[key] = (initialState as any)[key];
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPaymentSelections.fulfilled, (state, action) => {
      state.paymentSelections = action.payload;
    });
    builder.addCase(getCreditCards.fulfilled, (state, action) => {
      state.creditCards = action.payload.results || [];
    });
    builder.addCase(deleteCreditCard.fulfilled, (state, action) => {
      state.creditCards =
        state.creditCards && state.creditCards.length > 0
          ? state.creditCards.filter((card: any) => card.token !== action.payload)
          : [];
    });
  },
});

export const {setSelectedCheckoutCreditCard, setPaymentInitialState} = paymentSlice.actions;

export const selectPaymentSelections = (state: RootState) => state.payment.paymentSelections;
export const selectCreditCards = (state: RootState) => state.payment.creditCards;
export const selectSelectedCheckoutCreditCard = (state: RootState) => state.payment.selectedCheckoutCreditCard;

export default paymentSlice.reducer;
