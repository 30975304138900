import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {FullscreenLoaderPayload} from '../IFullScreenLoaderState';
import {RootState} from '../../../store/store';

export const initialState = {
  isLoading: false,
  showCartIcon: false,
  message: '',
};

export const fullscreenLoaderSlice = createSlice({
  name: 'fullscreenLoader',
  initialState,
  reducers: {
    showFullscreenLoader: (state, action: PayloadAction<FullscreenLoaderPayload>) => {
      state.isLoading = true;
      state.showCartIcon = action.payload?.showCartIcon || false;
      state.message = action.payload?.message || '';
    },
    hideFullscreenLoader: (state) => {
      state.isLoading = initialState.isLoading;
      state.showCartIcon = initialState.showCartIcon;
      state.message = initialState.message;
    },
  },
  extraReducers: {},
});

export const {showFullscreenLoader, hideFullscreenLoader} = fullscreenLoaderSlice.actions;

export const selectIsLoading = (state: RootState) => state.fullscreenLoader.isLoading;
export const selectShowCartIcon = (state: RootState) => state.fullscreenLoader.showCartIcon;
export const selectMessage = (state: RootState) => state.fullscreenLoader.message;

export default fullscreenLoaderSlice.reducer;
