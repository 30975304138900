import styled from 'styled-components';
import {$gray50, $gray93, $gray97, $newwhite} from '../../../../../../Styled/variables';

export const CambriaShipmentTrackingContainer = styled.div`
  background-color: ${$newwhite};
  border-radius: 3px;
  padding: 10px 30px;
  box-shadow: 0px 1px 3px 0px rgba(101, 101, 101, 0.35);
  margin-top: 30px;

  .tracking-heading {
    font-weight: 700;
    font-size: 14px;
  }

  .tracking-number-row {
    background-color: ${$gray97};
    border-radius: 3px;
    box-shadow: 0px 1px 3px 0px rgba(101, 101, 101, 0.35);
  }

  a {
    :hover {
      color: #a57e12;
    }
  }

  .table-cell {
    font-weight: 600;
    font-size: 14px;
    display: flex;
    align-items: center;
  }

  .tracking-number-table {
    max-height: 450px;
  }

  .table-row:nth-child(odd) {
    background-color: ${$gray93};
  }

  .table-row {
    padding: 10px 0px;
    border-radius: 3px;
    margin-top: 5px;
  }

  .shipment-accordion-heading {
    font-size: 14px;
  }

  .card {
    box-shadow: 0px 1px 3px 0px rgba(101, 101, 101, 0.35);
  }

  i {
    color: ${$gray50};
  }

  @media screen and (max-width: 960px) {
    margin-top: 10px;
  }
`;
