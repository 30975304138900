import React, {useEffect, useState} from 'react';
import {Col, Form, Row} from 'react-bootstrap';
import {useHistory, useParams} from 'react-router-dom';
import {toast} from 'react-toastify';
import OrderSummaryComponent from '../../../../Core/Components/OrderSummary';
import COMMERCE_CORE_CONSTANTS from '../../../../Core/constants';
import {selectCurrentUser} from '../../../../features/auth/slice/authentication.slice';
import {callback} from '../../../../features/callbacks/service/callbacks.service';
import {initializeCart, removeCart} from '../../../../features/cart/service/cart.service';
import {selectActiveCart} from '../../../../features/cart/slice/cart.slice';
import {Cart} from '../../../../features/cart/ICartState';
import {
  selectCurrentCustomer,
  selectCurrentCustomerBillToSites,
  selectCurrentCustomerShipToSites,
} from '../../../../features/customer/slice/customer.slice';
import {
  BillToSite,
  CustomerFullProfile,
  NewCustomerFullProfile,
  ShipToSite,
  Site,
} from '../../../../features/customer/ICustomerState';
import {selectUiSettings} from '../../../../features/environment/slice/environment.slice';
import {IQuoteDetails} from '../../../../features/quote/IQuoteState';
import {
  cancelQuote,
  editQuoteCart,
  getShouldShowExtend,
  postQuoteToCart,
  requestQuoteExtension,
} from '../../../../features/quote/quoteDetails/service/quoteDetails.service';
import {
  loadQuoteDetailsAsync,
  resetQuoteDetails,
  selectQuoteDetails,
  selectQuoteDetailsAreLoading,
} from '../../../../features/quote/slice/quote.slice';
import {Program} from '../../../../features/salesforce/program/IProgramState';
import {selectActiveProgram, selectAvailablePrograms} from '../../../../features/salesforce/slice/salesforce.slice';
import {selectSelectedWarehouse} from '../../../../features/warehouse/slice/warehouse.slice';
import Icon from '../../../../Framework/Components/Icon';
import {useAppDispatch, useTypedSelector} from '../../../../hooks/store';
import {User} from '../../../../store/auth/auth.service';
import QuoteDetailsHeader from './QuoteHeader';
import {QuoteDetailsContainer} from './QuoteDetails.styled';
import {hasPermission} from '../../../../store/permission/permission.service';
import {selectUserActions} from '../../../../features/permission/slice/permission.slice';
import CambriaModal from '../../../../Framework/Components/CambriaModal';
import useWindowDimensions from '../../../../hooks/getWindowDimensions';
import {
  hideFullscreenLoader,
  showFullscreenLoader,
} from '../../../../features/fullscreenLoader/slice/fullscreenLoader.slice';
import {UiSettings} from '../../../../features/environment/IEnvironmentState';
import {ISalesforceAvailablePrograms} from '../../../../features/salesforce/ISalesforceState';
import {getQuotePdfRequest} from '../../../../features/quote/quoteDetails/controller/quoteDetails.controller';
import QuoteLines from './QuoteLines';
import CambriaTooltip from '../../../../Framework/Components/CambriaTooltip';

const PROCESSED_CRM_CONFIRMED_STATUS_CODE = 180;

const PDF_DOWNLOAD_DISABLED_MESSAGE = 'Download is unavailable. Wait a couple of minutes and refresh the page';

const QuoteDetails = () => {
  const {quoteid} = useParams<{quoteid?: string}>();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const {width} = useWindowDimensions();

  const [shouldShowExtend, setShouldShowExtend] = useState<boolean>(false);
  const [canOverrideAllowedToOrderDate, setCanOverrideAllowedToOrderDate] = useState<boolean>(false);
  const [discounts, setDiscounts] = useState<Array<any>>([]);
  const [discountsHaveBeenInitialized, setDiscountsHaveBeenInitialized] = useState<boolean>(false);
  let [processOngoing, setProcessOngoing] = useState<boolean>(false);
  let [showConfirmRemoval, setShowConfirmRemoval] = useState<boolean>(false);
  const toggleShow = () => setShowConfirmRemoval((p) => !p);
  const [proceedToCheckoutIsLoading, setProceedToCheckoutIsLoading] = useState<boolean>(false);
  const [editQuoteIsLoading, setEditQuoteIsLoading] = useState<boolean>(false);
  const [quoteExtensionLoading, setQuoteExtensionLoading] = useState<boolean>(false);
  const [refreshInProgress, setRefreshInProgress] = useState<boolean>(false);

  let customerInfo: CustomerFullProfile | null = useTypedSelector(selectCurrentCustomer);
  let quoteDetails: IQuoteDetails = useTypedSelector(selectQuoteDetails);
  let uiSettings: UiSettings | null = useTypedSelector(selectUiSettings);
  let cart: Cart | null = useTypedSelector(selectActiveCart);
  let currentUser: User | undefined = useTypedSelector(selectCurrentUser);
  let activeProgram: Program = useTypedSelector(selectActiveProgram);
  let shipToSites: Site[] | null = useTypedSelector(selectCurrentCustomerShipToSites);
  let billToSites: BillToSite[] | null = useTypedSelector(selectCurrentCustomerBillToSites);
  let availablePrograms: ISalesforceAvailablePrograms | null = useTypedSelector(selectAvailablePrograms);
  let selectedWarehouse: ShipToSite | null = useTypedSelector(selectSelectedWarehouse);
  let userActions: string[] = useTypedSelector(selectUserActions);
  let quoteDetailsLoading = useTypedSelector(selectQuoteDetailsAreLoading);
  const pdfDownloadEnabled = quoteDetails?.statusSortOrder >= PROCESSED_CRM_CONFIRMED_STATUS_CODE;

  const editMessage =
    'Editting for this quote is being initialized.' +
    'This may take several seconds. Do not refresh the screen or hit the back button until ' +
    'this is complete and you have been redirected to the shopping cart.';

  const submitCommercialQuoteMessage =
    'This quote is in the process of being added to the shopping cart. ' +
    'This may take several seconds. Do not refresh the screen or hit the back button until ' +
    'this is complete and you have been redirected to the shopping cart.';

  const submitStandardCiaQuoteMessage =
    'This quote is in the process of being added to the shopping cart. ' +
    'This may take several seconds. Do not refresh the screen or hit the back button until ' +
    'this is complete and you have been redirected to the fabrication page.';

  const getOverrideAllowedToOrderDate = (userActions: string[]): boolean => {
    return hasPermission('urn:csa:commerceui:quote:overrideAllowedToOrderDate', userActions);
  };

  const reloadData = async () => {
    let quoteId = quoteid;
    quoteId && dispatch(loadQuoteDetailsAsync({quoteId}));
  };

  const validateCurrentCart = (message: string): boolean => {
    if (cart) {
      if (cart.cartItems && cart.cartItems.length !== 0) {
        toast.error(message);
        return false;
      }
      return true;
    }
    return true;
  };

  const processEditQuoteCart = async () => {
    setProcessOngoing(true);
    setEditQuoteIsLoading(true);
    if (
      !validateCurrentCart(
        'Your shopping cart currently contains products. Please complete your current checkout, or clear your shopping cart to continue quote editing.'
      )
    ) {
      setEditQuoteIsLoading(false);
      return;
    }

    try {
      toast.warning(editMessage, {delay: 5000});
      const customer = {
        erpCustomerId: customerInfo ? customerInfo.erpCustomerId : 0,
        customerClass: customerInfo ? customerInfo.class : '',
        customerClassGroups: customerInfo ? customerInfo.classGroups : [],
        slabWarehouseCode: '',
        tileWarehouseCode: '',
        samplesWarehouseCode: '',
        storeWarehouseCode: '',
      };
      if (cart) {
        await removeCart(
          cart.id,
          null,
          false,
          customerInfo ? customerInfo : NewCustomerFullProfile(),
          currentUser ? currentUser.erpcustomerId : '',
          activeProgram,
          shipToSites ? shipToSites : [],
          billToSites ? billToSites : [],
          dispatch,
          availablePrograms
        );
      }
      const callbackId = await editQuoteCart(quoteDetails.id, customer);

      const callbackMetadata = await callback(callbackId, 60, dispatch);
      const expectedCartId = callbackMetadata.CartId;

      let currentCart = await initializeCart(
        'checkoutComponentResolve',
        customerInfo ? customerInfo : NewCustomerFullProfile(),
        (currentUser as any).userId,
        availablePrograms,
        activeProgram,
        selectedWarehouse?.warehouseCode ? selectedWarehouse.warehouseCode?.toString() : '',
        shipToSites ? shipToSites : [],
        billToSites ? billToSites : [],
        dispatch
      );

      if (currentCart.id !== expectedCartId) {
        for (let i = 0; i < 5; i++) {
          await new Promise((resolve) => setTimeout(resolve, 1000));
          currentCart = await initializeCart(
            'checkoutComponentResolve',
            customerInfo ? customerInfo : NewCustomerFullProfile(),
            (currentUser as any).userId,
            availablePrograms,
            activeProgram,
            selectedWarehouse?.warehouseCode ? selectedWarehouse.warehouseCode?.toString() : '',
            shipToSites ? shipToSites : [],
            billToSites ? billToSites : [],
            dispatch
          );
          if (currentCart.id === expectedCartId) {
            break;
          }
        }
      }
      if (quoteDetails.type === 'standardCia') {
        history.push(COMMERCE_CORE_CONSTANTS.PATHNAMES.fabrication);
      } else {
        history.push(COMMERCE_CORE_CONSTANTS.PATHNAMES.cart);
      }
    } catch (e: any) {
      console.log(e);
      toast.dismiss();
      toast.error(`Failed to initialize quote edit for #${quoteDetails.id}`);
      reloadData();
    } finally {
      setProcessOngoing(false);
      setEditQuoteIsLoading(false);
    }
  };

  const processRequestQuoteExtension = async () => {
    setProcessOngoing(true);
    setQuoteExtensionLoading(true);
    try {
      const callbackId = await requestQuoteExtension(
        quoteDetails.id.toString(),
        customerInfo ? customerInfo.erpCustomerId : 0
      );
      await callback(callbackId, 60, dispatch);
      toast.success(`Extension for Quote #${quoteDetails.id} was successfully requested`);
      reloadData();
    } catch (e: any) {
      toast.error(`Failed to request extension for quote #${quoteDetails.id}`);
      reloadData();
      throw new Error(e);
    } finally {
      setProcessOngoing(false);
      setQuoteExtensionLoading(false);
    }
  };

  const proceedToCheckout = async () => {
    dispatch(
      showFullscreenLoader({
        message: quoteDetails.type === 'commercial' ? submitCommercialQuoteMessage : submitStandardCiaQuoteMessage,
      })
    );
    if (
      !validateCurrentCart(
        `Your shopping cart currently contains products. Please complete your current checkout, or clear your shopping cart to continue quote ordering.`
      )
    ) {
      dispatch(hideFullscreenLoader());
      return;
    }

    if (!canOverrideAllowedToOrderDate && !!quoteDetails.allowedToOrderDate && !quoteDetails.override30DayIncentive) {
      const today = new Date();
      const tempDate = new Date(quoteDetails.allowedToOrderDate);
      const allowedToOrderDate = new Date(tempDate.getFullYear(), tempDate.getMonth(), tempDate.getDate());
      if (today < allowedToOrderDate) {
        toast.warning(
          `You are trying to order a quote that qualified for the commercial registration discount before you’re able to. You can order this on ${allowedToOrderDate.toDateString()}.`
        );
        dispatch(hideFullscreenLoader());
        return;
      }
    }

    const customer = {
      erpCustomerId: customerInfo ? customerInfo.erpCustomerId : 0,
      customerClass: customerInfo ? customerInfo.class : '',
      customerClassGroups: customerInfo ? customerInfo.classGroups : [],
      slabWarehouseCode: '',
      tileWarehouseCode: '',
      samplesWarehouseCode: '',
      storeWarehouseCode: '',
    };

    const params = {
      quoteId: quoteDetails.id,
      customer: customer,
    };

    try {
      setProcessOngoing(true);
      setProceedToCheckoutIsLoading(true);
      if (cart) {
        await removeCart(
          cart.id,
          null,
          false,
          customerInfo ? customerInfo : NewCustomerFullProfile(),
          currentUser ? currentUser.erpcustomerId : '',
          activeProgram,
          shipToSites ? shipToSites : [],
          billToSites ? billToSites : [],
          dispatch,
          availablePrograms
        );
      }
      const callbackId = await postQuoteToCart(params);
      await callback(callbackId, 90, dispatch);
      await initializeCart(
        'checkoutComponentResolve',
        customerInfo ? customerInfo : NewCustomerFullProfile(),
        (currentUser as any).userId,
        availablePrograms,
        activeProgram,
        selectedWarehouse?.warehouseCode ? selectedWarehouse.warehouseCode?.toString() : '',
        shipToSites ? shipToSites : [],
        billToSites ? billToSites : [],
        dispatch
      );
      if (quoteDetails.type === 'standardCia') {
        history.push(COMMERCE_CORE_CONSTANTS.PATHNAMES.fabrication);
      } else {
        history.push(COMMERCE_CORE_CONSTANTS.PATHNAMES.cart);
      }
    } catch (error: any) {
      toast.error(`Failed to proceed quote ${quoteDetails.id}`);
      console.error(error);
      await reloadData();
    } finally {
      setProcessOngoing(false);
      setProceedToCheckoutIsLoading(false);
      dispatch(hideFullscreenLoader());
    }
  };

  const processCancelQuote = async () => {
    try {
      const callbackId = await cancelQuote(quoteDetails.id.toString(), customerInfo ? customerInfo.erpCustomerId : 0);
      await callback(callbackId, 60, dispatch);
      toast.success(`Quote #${quoteDetails.id} was successfully canceled`);
      reloadData();
    } catch (e: any) {
      toast.error(`Failed to cancel quote #${quoteDetails.id}`);
      throw new Error(e);
    }
  };

  const downloadPdf = async () => {
    try {
      setRefreshInProgress(true);
      const content = await getQuotePdfRequest(quoteDetails.id, quoteDetails.erpCustomerId);
      let resultArrayBuffer = await content.arrayBuffer();
      const url = window.URL.createObjectURL(
        new Blob([resultArrayBuffer], {
          type: 'application/pdf',
        })
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'Quote.pdf');
      document.body.appendChild(link);
      link.click();
    } catch (e) {
      toast.error('An error has occurred preparing the Quote PDF.');
    } finally {
      setRefreshInProgress(false);
    }
  };

  const confirmRemoveQuote = async () => {
    await processCancelQuote();
    toggleShow();
  };

  const getSubtotal = (quote: IQuoteDetails): number => {
    let subtotal = 0;
    quote.lines.forEach((lineItem: any) => {
      subtotal += lineItem.originalBasePriceExtended ?? lineItem.basePriceExtended;
    });
    return subtotal;
  };

  useEffect(() => {
    return () => {
      dispatch(resetQuoteDetails());
    };
  }, [dispatch]);

  useEffect(() => {
    const loadQuoteDetails = (quoteId: string) => {
      dispatch(loadQuoteDetailsAsync({quoteId: quoteId, useHistory: history}));
    };
    if (quoteid) {
      loadQuoteDetails(quoteid);
    }
  }, [quoteid, dispatch, history]);

  useEffect(() => {
    setRefreshInProgress(quoteDetailsLoading);
  }, [quoteDetailsLoading]);

  useEffect(() => {
    const setOverridePermissions = async () => {
      let canOverrideAllowed = await getOverrideAllowedToOrderDate(userActions);
      setCanOverrideAllowedToOrderDate(canOverrideAllowed);
    };
    if (quoteDetails.id && uiSettings && userActions.length > 0) {
      setShouldShowExtend(getShouldShowExtend(quoteDetails, uiSettings));
      setOverridePermissions();
    }
  }, [quoteDetails, uiSettings, userActions]);

  useEffect(() => {
    const organizeDiscountInformation = (quote: IQuoteDetails) => {
      let discounts: Array<any> = [];
      quote.lines.forEach((line: any) => {
        if (line.discounts && line.discounts.length > 0) {
          line.discounts.forEach((discount: any) => {
            if (discount.isActive) {
              discounts.push(discount);
            }
          });
        }
      });
      setDiscounts(discounts);
    };
    if (quoteDetails.id && !discountsHaveBeenInitialized) {
      organizeDiscountInformation(quoteDetails);
      setDiscountsHaveBeenInitialized(true);
    }
  }, [quoteDetails, discounts, discountsHaveBeenInitialized]);

  return (
    <QuoteDetailsContainer>
      <Row className="back-button-row">
        <Col>
          <div className="back-button noprint" onClick={() => history.push('/account/quotes')}>
            <i className="fa fa-angle-left" />
            BACK TO QUOTE HISTORY
          </div>
        </Col>
      </Row>

      <Row>
        <Col xs={10} md={8}>
          {width > 960 ? (
            <h1 className="main-header">QUOTE DETAILS - {customerInfo?.name}</h1>
          ) : (
            <>
              <h1 className="main-header">QUOTE DETAILS</h1>
              <h3 className="mobile-subheader">{customerInfo?.name}</h3>
            </>
          )}
        </Col>
        <Col xs={2} md={4} className="icons-header">
          <CambriaTooltip tooltip={!pdfDownloadEnabled ? PDF_DOWNLOAD_DISABLED_MESSAGE : ''} size="medium">
            <Icon
              disabled={!pdfDownloadEnabled || refreshInProgress}
              size="23"
              weight="600"
              icon="fa fa-print"
              onClick={() => downloadPdf()}
            />
          </CambriaTooltip>
          <Icon disabled={refreshInProgress} size="20" weight="600" icon="fa fa-sync" onClick={() => reloadData()} />
        </Col>
      </Row>
      <Row>
        <Col>
          <QuoteDetailsHeader />
        </Col>
      </Row>
      <Row>
        <Col className="product-table-container">
          <QuoteLines />
        </Col>
      </Row>
      <Row>
        <Col></Col>
        <Col xs={12} sm={12} md={6} lg={4} className="order-summary-container">
          <OrderSummaryComponent
            numberOfLineItems={quoteDetails.lines.length}
            totalAmount={quoteDetails.totalAmount}
            totalBeforeTaxAndShipping={getSubtotal(quoteDetails)}
            orderSubtotal={getSubtotal(quoteDetails)}
            currencyCode={quoteDetails.lines[0]?.currency}
            linkForBackButton={'/account/quotes'}
            quoteDiscounts={discounts}
            quote={true}
            hideQuoteTotals={true}
          />
          <button
            className="btn btn-primary noprint resubmit-reorder-buttons"
            disabled={quoteDetails.statusCode !== 'Approved' || processOngoing}
            onClick={() => processEditQuoteCart()}
            data-testid={'edit-quote'}>
            EDIT QUOTE
            {editQuoteIsLoading ? (
              <Icon
                className={'resubmit-reorder-spinner'}
                size="20"
                weight="600"
                icon="fa fa-spinner fa-spin"
                disabled={true}
              />
            ) : (
              <></>
            )}
          </button>

          {shouldShowExtend ? (
            <button
              disabled={processOngoing}
              className="btn btn-primary noprint resubmit-reorder-buttons"
              onClick={() => processRequestQuoteExtension()}
              data-testid={'request-quote-extension'}>
              REQUEST QUOTE EXTENSION
              {quoteExtensionLoading ? (
                <Icon
                  className={'resubmit-reorder-spinner'}
                  size="20"
                  weight="600"
                  icon="fa fa-spinner fa-spin"
                  disabled={true}
                />
              ) : (
                <></>
              )}
            </button>
          ) : (
            <></>
          )}
          <button
            className="btn btn-primary noprint resubmit-reorder-buttons"
            disabled={quoteDetails.statusCode !== 'Approved' || processOngoing}
            onClick={() => proceedToCheckout()}
            data-testid={'proceed-to-checkout'}>
            PROCEED TO CHECKOUT
            {proceedToCheckoutIsLoading ? (
              <Icon
                className={'resubmit-reorder-spinner'}
                size="20"
                weight="600"
                icon="fa fa-spinner fa-spin"
                disabled={true}
              />
            ) : (
              <></>
            )}
          </button>
          {quoteDetails.statusCancellable ? (
            <div className="remove-order noprint" onClick={() => toggleShow()}>
              CANCEL
            </div>
          ) : (
            <></>
          )}
          <CambriaModal
            show={showConfirmRemoval}
            confirmButton={'Yes'}
            cancelButton={'No'}
            heading={'Canceling Quote'}
            toggleShow={toggleShow}
            formName={'remove-quote'}>
            <Form
              id={'remove-quote'}
              onSubmit={(e) => {
                e.preventDefault();
                confirmRemoveQuote();
              }}>
              Are you sure you want to cancel this quote?
            </Form>
          </CambriaModal>
        </Col>
      </Row>
    </QuoteDetailsContainer>
  );
};

export default QuoteDetails;
