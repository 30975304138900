import {baseApiCallWithReauth} from '../../../Framework/api-utils/api-utils';
import COMMERCE_CORE_CONSTANTS from '../../../Core/constants';
import {ICloseToExpireQuote, IQuoteDetails} from '../IQuoteState';
import qs from 'qs';

export const postQuoteRequest = async (params: any) => {
  try {
    return await baseApiCallWithReauth(
      'POST',
      COMMERCE_CORE_CONSTANTS.API_SERVICES.QUOTES.quote,
      JSON.stringify(params),
      true
    );
  } catch (error: any) {
    throw error;
  }
};

export const getQuoteRequest = async (args: {quoteId: string}): Promise<IQuoteDetails | null> => {
  const {quoteId} = args;
  const endpoint = `${COMMERCE_CORE_CONSTANTS.API_SERVICES.QUOTE.quotes}/${quoteId}`;
  const results = await baseApiCallWithReauth('GET', endpoint, undefined, true);
  if (results.status === 404) {
    return null;
  }
  return await results.json();
};

export const getExpiringQuotes = async (erpCustomerId: number): Promise<ICloseToExpireQuote[]> => {
  const params = qs.stringify({erpCustomerId});
  return await baseApiCallWithReauth('GET', COMMERCE_CORE_CONSTANTS.API_SERVICES.QUOTE.expiring, params);
};

export const postAcknowledgeExpiringQuoteNotification = async (quoteId: number): Promise<void> => {
  return await baseApiCallWithReauth(
    'POST',
    `${COMMERCE_CORE_CONSTANTS.API_SERVICES.QUOTE.quotes}/${quoteId}${COMMERCE_CORE_CONSTANTS.API_SERVICES.QUOTE.ackExpiring}`
  );
};
