export interface INavItem {
  name: string;
  type: string;
  urn: string;
  show: boolean;
  href: string;
}

export const getAvailableProductTabsByWarehouse = (warehouseCode: string): Array<string> => {
  let availableProductTabs = ['Slabs', 'Tile', 'Fabrication', 'Store', 'Samples'];
  switch (true) {
    case /211/.test(warehouseCode):
      availableProductTabs = availableProductTabs.filter((x) => {
        return x !== 'Fabrication';
      });
      break;
    case /^[2]\d{2}/.test(warehouseCode):
      availableProductTabs = availableProductTabs.filter((x) => {
        return x !== 'Fabrication' && x !== 'Store' && x !== 'Tile';
      });
      break;
    case /^[3,5]\d{2}/.test(warehouseCode):
      availableProductTabs = availableProductTabs.filter((x) => {
        return x === 'Fabrication';
      });
      break;
  }
  return availableProductTabs;
};

export const hasPermission = (action: string, userActions: string[]): boolean => {
  let hasPermission = false;
  if (userActions) {
    for (const userAction of userActions) {
      if (!hasPermission) {
        hasPermission = matchFeature(userAction, action);
      }
    }
  }

  return hasPermission;
};

export const hasMultiplePermission = (actions: string[], userActions: string[]): boolean => {
  for (const action of actions) {
    const result = hasPermission(action, userActions);
    if (!result) {
      return false;
    }
  }
  return true;
};

const matchFeature = (userAction: string, action: string): boolean => {
  // Is a valid wildcard
  // tslint:disable-next-line
  if (userAction.match(/([A-z0-9:]+\*)/g)) {
    return action.indexOf(userAction.replace(':*', '')) >= 0;
  }

  // Use exact search
  return action === userAction;
};
