import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import COMMERCE_CORE_CONSTANTS from '../../../Core/constants';
import {baseApiCallWithReauth} from '../../../Framework/api-utils/api-utils';
import {RootState} from '../../../store/store';
import qs from 'qs';
import {ILocationState} from '../ILocationState';

export const initialState: ILocationState = {
  countries: [],
  states: [],
  statesAndProvidences: [],
  addressSearchResults: [],
  addressValidation: null,
  disableZipCodeField: false,
};

export const addressSearchAsync = createAsyncThunk(
  'location/addressSearchAsync',
  async ({keyword, country}: {keyword: string | null; country?: string}, {rejectWithValue}) => {
    if (keyword) {
      const endpoint = COMMERCE_CORE_CONSTANTS.API_SERVICES.LOCATION.addressSearch;
      let params = {
        q: keyword,
        limit: '10',
        country: country ? country : '',
      };
      const data = new URLSearchParams(params);
      return await baseApiCallWithReauth('GET', endpoint, data);
    } else {
      rejectWithValue(null);
    }
  }
);

export const getAllAvailableCountries = createAsyncThunk('location/getAllAvailableCountries', async () => {
  const endpoint = COMMERCE_CORE_CONSTANTS.API_SERVICES.LOCATION.countries;
  const data = new URLSearchParams();
  data.append('pager.limit', '250');
  return await baseApiCallWithReauth('GET', endpoint, data);
});

export const getStatesOrProvinces = createAsyncThunk(
  'location/getStatesOrProvinces',
  async ({countryName}: {countryName?: string}) => {
    const endpoint = `${COMMERCE_CORE_CONSTANTS.API_SERVICES.LOCATION.states}`;
    const data = new URLSearchParams();
    data.append('pager.limit', '100');
    if (countryName) {
      data.append('countryName', countryName);
    }
    return await baseApiCallWithReauth('GET', endpoint, data);
  }
);

export const getStatesAndProvinces = createAsyncThunk('location/getStatesAndProvinces', async () => {
  const endpoint = `${COMMERCE_CORE_CONSTANTS.API_SERVICES.LOCATION.states}`;

  let statesAndProvinces = await baseApiCallWithReauth('GET', endpoint, undefined, undefined, true);

  return statesAndProvinces.results;
});

export const getAddressValidation = createAsyncThunk(
  'location/getAddressValidation',
  async (params: any, {rejectWithValue}) => {
    if (params) {
      const endpoint = COMMERCE_CORE_CONSTANTS.API_SERVICES.LOCATION.addressValidation;
      return await baseApiCallWithReauth(
        'GET',
        endpoint,
        qs.stringify({
          addressLine1: params.address1 ? params.address1.trim() : null,
          addressLine2: params.address2 ? params.address2.trim() : null,
          addressLine3: params.address3 ? params.address3.trim() : null,
          city: params.city,
          state: params.state.locationCode ? params.state.locationCode.trim() : null,
          postalCode: params.postalCode,
          country: params.country.alpha3Code,
          includeSuggestions: true,
        })
      );
    } else {
      rejectWithValue(null);
    }
  }
);

export const locationSlice = createSlice({
  name: 'location',
  initialState,
  reducers: {
    setDisableZipCodeField: (state: any, action: any) => {
      state.disableZipCodeField = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllAvailableCountries.fulfilled, (state, action) => {
      state.countries = action.payload.results;
    });
    builder.addCase(getStatesOrProvinces.fulfilled, (state, action) => {
      state.states = action.payload.results;
    });
    builder.addCase(getStatesAndProvinces.fulfilled, (state, action) => {
      state.statesAndProvidences = action.payload;
    });
    builder.addCase(addressSearchAsync.pending, (state) => {
      state.addressSearchResults = [];
    });
    builder.addCase(addressSearchAsync.fulfilled, (state, action) => {
      state.addressSearchResults = action.payload;
    });
    builder.addCase(addressSearchAsync.rejected, (state) => {
      state.addressValidation = initialState.addressSearchResults;
    });
    builder.addCase(getAddressValidation.pending, (state) => {
      state.addressValidation = initialState.addressValidation;
    });
    builder.addCase(getAddressValidation.rejected, (state) => {
      state.addressValidation = initialState.addressValidation;
    });
    builder.addCase(getAddressValidation.fulfilled, (state, action) => {
      state.addressValidation = action.payload;
    });
  },
});

export const {setDisableZipCodeField} = locationSlice.actions;

export const selectStates = (state: RootState) => state.location.states;
export const selectCountries = (state: RootState) => state.location.countries;
export const selectAddressSearchResults = (state: RootState) => state.location.addressSearchResults;
export const selectAddressValidation = (state: RootState) => state.location.addressValidation;
export const selectStatesAndProvidences = (state: RootState) => state.location.statesAndProvidences;
export const selectDisableZipCodeField = (state: RootState) => state.location.disableZipCodeField;

export default locationSlice.reducer;
