import styled from 'styled-components/macro';
import {$gray16, $gray60, $newprimaryfont} from '../../../../../Styled/variables';

export const SamplesOrderContainer = styled.div`
  .salesforce-asset-account-help {
    text-align: right;

    .list-tooltip-wrapper {
      user-select: none;
      -webkit-user-select: none;
      padding: 8px 0 8px 8px;
      position: relative;
      cursor: pointer;

      &:hover {
        .salesforce-tooltip {
          transform: translate3d(-50%, 0, 0);
          visibility: visible;
          opacity: 1;
        }
      }

      .salesforce-tooltip {
        text-align: left;
        bottom: calc(-100% + 5em);
        left: -85px;
        right: -125px;
        transform: translate3d(-50%, -15px, 0);
        position: absolute;
        padding: 12px;
        visibility: hidden;
        opacity: 0;
        background: white;
        transition: all 0.25s cubic-bezier(0, 0, 0.2, 1);
        color: ${$gray16};
        border: 1px solid ${$gray60};
        border-radius: 3px;
        font-family: ${$newprimaryfont};
        font-size: 12px;
        font-weight: 500;
        line-height: 1.25;
        box-shadow: 0 1px 1px ${$gray60};
        z-index: 4;

        &:after {
          content: '';
          display: block;
          position: absolute;
          width: 0;
          height: 0;
          border-style: solid;
          bottom: -0.5em;
          left: 90%;
          transform: rotate(180deg);
          border-width: 0 0.5em 0.5em 0.5em;
          border-color: transparent transparent white transparent;
          -webkit-filter: drop-shadow(1px 2px 1px ${$gray60});
          filter: drop-shadow(1px -1px 1px ${$gray60});
        }
      }
    }
  }

  .alert button.close {
    font-size: 2rem !important;
    padding: 2px 5px !important;
  }
`;
