import React, {useEffect, useState} from 'react';
import {Col, Container, Form, Row} from 'react-bootstrap';
import CambriaSelect from '../../../../../Framework/Components/CambriaSelect';
import CambriaButton from '../../../../../Framework/Components/CambriaButton';
import Icon from '../../../../../Framework/Components/Icon';
import {Formik} from 'formik';
import AddShippingContactModal from '../../../../../Core/Components/Modals/AddShippingContactModal';
import {useAppDispatch, useTypedSelector} from '../../../../../hooks/store';
import {
  selectCurrentCustomer,
  selectCurrentCustomerBillToSites,
  selectCurrentCustomerShipToSites,
  selectPreferredShippingContact,
  setCurrentCustomerShipToSites,
} from '../../../../../features/customer/slice/customer.slice';
import {getSalesforceContacts} from '../../../../../features/salesforce/service/salesforce.service';
import {selectSalesforceCustomerAccount} from '../../../../../features/salesforce/slice/salesforce.slice';
import {PreferredShippingContactRequest, Site} from '../../../../../features/customer/ICustomerState';
import {getContactDisplayName, getContactOptions} from '../../../../../features/checkout/service/checkout.service';
import {hasPermission} from '../../../../../store/permission/permission.service';
import {selectUserActions} from '../../../../../features/permission/slice/permission.slice';
import {updatePreferredShippingContacts} from '../../../../../features/customer/service/customer.service';

const PreferredShippingContactPreferences = ({erpCustomerId}: {erpCustomerId: number}) => {
  const dispatch = useAppDispatch();

  const buildFormObject = () => {
    let newForm: any = {
      initialValues: {
        contact: '',
      },
    };

    return newForm;
  };

  const salesforceCustomerAccount = useTypedSelector(selectSalesforceCustomerAccount);
  const currentCustomerShipToSites = useTypedSelector(selectCurrentCustomerShipToSites);
  const currentCustomerBillToSites = useTypedSelector(selectCurrentCustomerBillToSites);
  const currentCustomer = useTypedSelector(selectCurrentCustomer);
  const preferredShippingContacts = useTypedSelector(selectPreferredShippingContact);
  const userActions = useTypedSelector(selectUserActions);

  let [primaryShipToSite, setPrimaryShipToSite] = useState<Site>();
  let [availableContacts, setAvailableContacts] = useState<any[]>([]);
  let [showAddNewShippingContactModal, setShowAddNewShippingContactModal] = useState(false);
  let [shippingAddress, setShippingAddress] = useState<Site>();
  let [formikFormValues]: any = useState(buildFormObject());
  let [isInitialized, setIsInitialized] = useState(false);

  let showDeliveryDays = hasPermission('urn:csa:commerceui:slabDeliveryDays', userActions);

  const toggleAddNewContactModalShow = (shippingAddress?: Site) => {
    if (shippingAddress) {
      setShippingAddress(shippingAddress);
    }

    setShowAddNewShippingContactModal((p) => !p);
  };

  useEffect(() => {
    const autoFillExistingContacts = async () => {
      if (
        preferredShippingContacts.length > 0 &&
        formikFormValues.initialValues &&
        primaryShipToSite &&
        !isInitialized
      ) {
        for (const contact of preferredShippingContacts) {
          const shipToSiteContact = primaryShipToSite?.contacts?.find(
            (c: any) => c.erpCustomerContactId.toString() === contact.erpContactId
          );
          const contactOption = {
            ...shipToSiteContact,
            customerSiteId: primaryShipToSite?.id,
            displayName: getContactDisplayName(shipToSiteContact),
          };
          formikFormValues.initialValues['contact'] = contactOption;
        }
        setIsInitialized(true);
      }
    };

    autoFillExistingContacts();
  }, [preferredShippingContacts, primaryShipToSite, formikFormValues.initialValues, isInitialized]);

  useEffect(() => {
    const getAvailableContacts = async () => {
      const contacts = await getSalesforceContacts(salesforceCustomerAccount.id);
      setAvailableContacts(contacts);
    };

    getAvailableContacts();
  }, [salesforceCustomerAccount]);

  useEffect(() => {
    const filterPrimaryShipToSitesBasedOnOperatingUnitCode = (operatingUnitCode: string): Site | undefined => {
      let tempCurrentCustomerShipToSites = JSON.parse(JSON.stringify(currentCustomerShipToSites));
      const site = tempCurrentCustomerShipToSites.find(
        (site: Site) => site.operatingUnitCode === operatingUnitCode && site.isPrimary === 'Y'
      );

      if (site) {
        site.contacts = getContactOptions(
          tempCurrentCustomerShipToSites,
          undefined,
          undefined,
          undefined,
          operatingUnitCode,
          true
        );
      }

      return site;
    };

    let slabOperatingUnitCodes = currentCustomer?.productTypeWarehouseCodes?.find(
      (p: any) => p.productType?.toLocaleLowerCase() === 'slab'
    )?.operatingUnitCode;
    if (slabOperatingUnitCodes) {
      setPrimaryShipToSite(filterPrimaryShipToSitesBasedOnOperatingUnitCode(slabOperatingUnitCodes));
    }
  }, [currentCustomerBillToSites, currentCustomerShipToSites, currentCustomer?.productTypeWarehouseCodes]);

  return (
    <div className={`preference-page-subheading-container ${showDeliveryDays ? 'col-md-9' : ''} col-sm-12`}>
      <p className="preference-page-subheading">PREFERRED SHIPPING CONTACT</p>
      <div className="preferred-shipping-contact-form-container">
        <Formik
          initialValues={formikFormValues.initialValues}
          onSubmit={async (values: any) => {
            const data: Array<PreferredShippingContactRequest> = [];
            const contact = values['contact'];
            data.push({
              erpCustomerId: erpCustomerId.toString(),
              operatingUnitCode: primaryShipToSite?.operatingUnitCode || '',
              erpContactId: contact.erpCustomerContactId.toString(),
            });
            await updatePreferredShippingContacts(data, preferredShippingContacts, dispatch);
          }}>
          {(props) => {
            return (
              <Form id={'preferred-shipping-contact-form'} noValidate onSubmit={props.handleSubmit}>
                <Container className="p-0 not-marketrep-form">
                  <Row>
                    <Col className="col-12 col-lg-6">
                      <CambriaSelect
                        name={'contact'}
                        formikFormProps={props}
                        defaultValue={formikFormValues.initialValues['contact']}
                        label="Contact"
                        placeholder="Select Contact"
                        items={primaryShipToSite?.contacts ? primaryShipToSite?.contacts : []}
                        displayValue="displayName"
                      />
                    </Col>
                    <Col className="col-12 col-lg-6 button-section">
                      <CambriaButton
                        onClick={() => {
                          toggleAddNewContactModalShow(primaryShipToSite);
                        }}
                        variant="secondary"
                        id="add-or-revise-contact-test-id">
                        <Icon icon="icons-cambria-Symbols-Plus" color="#c59617" size="15" weight="bold" />
                        Add or Revise Contact
                      </CambriaButton>
                    </Col>
                  </Row>
                  <Row>
                    <button
                      data-testid="update-preferred-shipping-contact-btn"
                      className="btn btn-primary update-preferred-shipping-contact-btn"
                      type="submit">
                      UPDATE PREFERRED SHIPPING CONTACT
                    </button>
                  </Row>
                </Container>
                {showAddNewShippingContactModal && (
                  <AddShippingContactModal
                    show={showAddNewShippingContactModal}
                    shouldRequireEmail={true}
                    toggleShow={toggleAddNewContactModalShow}
                    currentShippingSiteId={shippingAddress?.id}
                    existingContacts={availableContacts}
                    onSubmitCallback={async (contact: any) => {
                      props.values['contact'] = contact;
                      const updatedSites = JSON.parse(JSON.stringify(currentCustomerShipToSites));

                      updatedSites.forEach((site: any) => {
                        if (site.id === shippingAddress?.id) {
                          site.contacts.push(contact);
                        }
                      });

                      const data: Array<PreferredShippingContactRequest> = [];
                      data.push({
                        erpCustomerId: erpCustomerId.toString(),
                        operatingUnitCode: primaryShipToSite?.operatingUnitCode || '',
                        erpContactId: contact.erpCustomerContactId.toString(),
                      });
                      await updatePreferredShippingContacts(data, preferredShippingContacts, dispatch);

                      await dispatch(setCurrentCustomerShipToSites(updatedSites));
                    }}></AddShippingContactModal>
                )}
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default PreferredShippingContactPreferences;
