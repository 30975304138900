import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import qs from 'qs';
import {fabSliceInitialState} from '../IFabricationState';
import COMMERCE_CORE_CONSTANTS from '../../../Core/constants';
import {baseApiCallWithReauth} from '../../../Framework/api-utils/api-utils';
import {RootState} from '../../../store/store';

export const initialState: fabSliceInitialState = {
  orderApprovedEmails: null,
  cadRevisionEmails: null,
  poRevisionEmails: null,
  selectedDrafter: null,
  purchasingCustomers: null,
  purchasingCustomersStatus: 'idle',
  endConsumer: null,
  endConsumerStatus: 'idle',
  endConsumerSearchResults: null,
  retailSiteId: null,
  duplicateBeingEditted: null,
  parentOfEditPieceLabel: null,
  duplicatePieceModel: null,
  edgeProfileBeingAdded: false,
};

export const getPurchasingCustomersAsync = createAsyncThunk(
  'fabrication/getPurchasingCustomersAsync',
  async ({cartId, erpCustomerId}: {cartId: string; erpCustomerId: number}) => {
    const endpoint = COMMERCE_CORE_CONSTANTS.API_SERVICES.CUSTOMER.retailSites;
    return await baseApiCallWithReauth('GET', endpoint, qs.stringify({cartId: cartId, erpCustomerId: erpCustomerId}));
  }
);

export const getEndConsumersAsync = createAsyncThunk(
  'fabrication/getEndConsumersRequest',
  async ({cartId, erpCustomerId, retailSiteId}: {cartId: string; erpCustomerId: number; retailSiteId?: number}) => {
    const endpoint = COMMERCE_CORE_CONSTANTS.API_SERVICES.CUSTOMER.endConsumers;
    let params;
    if (retailSiteId) {
      params = qs.stringify({cartId: cartId, erpCustomerId: erpCustomerId, retailSiteId: retailSiteId});
    } else {
      params = qs.stringify({cartId: cartId, erpCustomerId: erpCustomerId});
    }
    return await baseApiCallWithReauth('GET', endpoint, params);
  }
);

export const loadEmailListsAsync = createAsyncThunk(
  'fabrication/loadEmailListsAsync',
  async ({erpCustomerId}: {erpCustomerId: number}) => {
    const endpoint = `${COMMERCE_CORE_CONSTANTS.API_SERVICES.CUSTOMER.fabricationOrderPreferences}/${erpCustomerId}`;
    return await baseApiCallWithReauth('GET', endpoint);
  }
);

export const endConsumerSearchAsync = createAsyncThunk(
  'fabrication/endConsumerSearchAsync',
  async ({searchString, userIsAdmin}: {searchString: string; userIsAdmin: boolean}) => {
    let endpoint = COMMERCE_CORE_CONSTANTS.API_SERVICES.CUSTOMER.fabInstallAddress;
    if (userIsAdmin) {
      endpoint = COMMERCE_CORE_CONSTANTS.API_SERVICES.CUSTOMER.fabInstallAddressAdmin;
    }
    return await baseApiCallWithReauth('GET', endpoint, qs.stringify({q: searchString, 'pager[limit]': 100}));
  }
);

export const fabricationSlice = createSlice({
  name: 'fabrication',
  initialState,
  reducers: {
    setPurchasingCustomer: (state, action) => {
      state.purchasingCustomers = action.payload;
    },
    setEndConsumer: (state, action) => {
      state.endConsumer = action.payload;
    },
    setOrderApprovedEmails: (state, action) => {
      state.orderApprovedEmails = action.payload;
    },
    setCadRevisionEmails: (state, action) => {
      state.cadRevisionEmails = action.payload;
    },
    setPoRevisionEmails: (state, action) => {
      state.poRevisionEmails = action.payload;
    },
    setSelectedDrafter: (state, action) => {
      state.selectedDrafter = action.payload;
    },
    setEndConsumerSearchResults: (state, action) => {
      state.endConsumerSearchResults = action.payload;
    },
    setDuplicateBeingEditted: (state, action) => {
      state.duplicateBeingEditted = action.payload;
    },
    setDuplicatePieceModel: (state, action) => {
      state.duplicatePieceModel = action.payload;
    },
    setParentPieceLabelOfDuplicate: (state, action) => {
      state.parentOfEditPieceLabel = action.payload;
    },
    setEdgeProfileBeingAdded: (state, action) => {
      state.edgeProfileBeingAdded = action.payload;
    },
    resetPurchasingCustomers: (state) => {
      state.purchasingCustomers = [];
    },
    resetEndConsumer: (state) => {
      state.endConsumer = [];
    },
    resetEndConsumerSearchResults: (state) => {
      state.endConsumerSearchResults = [];
    },
    resetRetailSiteId: (state) => {
      state.retailSiteId = null;
    },
    setFabricationInitialState: (state: any) => {
      for (const key in initialState) {
        state[key] = (initialState as any)[key];
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(endConsumerSearchAsync.fulfilled, (state, action) => {
      state.endConsumerSearchResults = action.payload.results;
    });
    builder.addCase(getPurchasingCustomersAsync.fulfilled, (state, action) => {
      state.purchasingCustomers = action.payload.results;
      if (action.payload.results.length > 0) {
        state.retailSiteId = action.payload.results[0].id;
      }
      state.purchasingCustomersStatus = 'succeeded';
    });
    builder.addCase(getEndConsumersAsync.fulfilled, (state, action) => {
      state.endConsumer = action.payload.results;
      state.endConsumerStatus = 'succeeded';
    });
    builder.addCase(getEndConsumersAsync.pending, (state, action) => {
      state.endConsumerStatus = 'loading';
    });
    builder.addCase(getEndConsumersAsync.rejected, (state, action) => {
      state.endConsumerStatus = 'failed';
    });
    builder.addCase(getPurchasingCustomersAsync.pending, (state, action) => {
      state.purchasingCustomersStatus = 'loading';
    });
    builder.addCase(getPurchasingCustomersAsync.rejected, (state, action) => {
      state.purchasingCustomersStatus = 'failed';
    });
  },
});
export const {
  setPurchasingCustomer,
  setEndConsumer,
  setOrderApprovedEmails,
  setCadRevisionEmails,
  setPoRevisionEmails,
  setSelectedDrafter,
  setEndConsumerSearchResults,
  setDuplicateBeingEditted,
  setDuplicatePieceModel,
  setParentPieceLabelOfDuplicate,
  setEdgeProfileBeingAdded,
  resetPurchasingCustomers,
  resetEndConsumer,
  resetEndConsumerSearchResults,
  resetRetailSiteId,
  setFabricationInitialState,
} = fabricationSlice.actions;
export const selectParentPieceLabel = (state: RootState) => state.fabrication.parentOfEditPieceLabel;
export const selectOrderApprovedEmails = (state: RootState) => state.fabrication.orderApprovedEmails;
export const selectSelectedDrafter = (state: RootState) => state.fabrication.selectedDrafter;
export const selectCadRevisionEmails = (state: RootState) => state.fabrication.cadRevisionEmails;
export const selectPoRevisionEmails = (state: RootState) => state.fabrication.poRevisionEmails;
export const selectPurchasingCustomer = (state: RootState) => state.fabrication.purchasingCustomers;
export const selectPurchasingCustomerStatus = (state: RootState) => state.fabrication.purchasingCustomersStatus;
export const selectEndConsumerSearchResults = (state: RootState) => state.fabrication.endConsumerSearchResults;
export const selectEndConsumers = (state: RootState) => state.fabrication.endConsumer;
export const selectEndConsumersStatus = (state: RootState) => state.fabrication.endConsumerStatus;
export const selectFabRetailSiteId = (state: RootState) => state.fabrication.retailSiteId;
export const selectDuplicateBeingEditted = (state: RootState) => state.fabrication.duplicateBeingEditted;
export const selectDuplicatePieceModel = (state: RootState) => state.fabrication.duplicatePieceModel;
export const selectEdgeProfileBeingAdded = (state: RootState) => state.fabrication.edgeProfileBeingAdded;

export default fabricationSlice.reducer;
