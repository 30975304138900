import React from 'react';
import CambriaLogo from '../Header/CambriaLogo';
import {selectUiSettings} from '../../../features/environment/slice/environment.slice';
import {useTypedSelector} from '../../../hooks/store';
import {MaintenancePage} from './Maintenance.styled';

function Maintenance() {
  const uiSettings = useTypedSelector(selectUiSettings);
  return (
    <MaintenancePage>
      <div className="container">
        <div className="logo-container">
          <CambriaLogo></CambriaLogo>
        </div>
        <div>
          {uiSettings && uiSettings.maintenanceMessage ? (
            <h1 className="maintenance-mode-text">{uiSettings.maintenanceMessage}</h1>
          ) : (
            <div></div>
          )}
        </div>
      </div>
    </MaintenancePage>
  );
}

export default Maintenance;
