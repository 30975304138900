export const $newprimaryfont = "'Montserrat', sans-serif";
export const $newheadercolor = '#5c6165';
export const $newnacolor = '#00000066';
export const $newblack = '#000000';
export const $newsuccess = '#7bc258';
export const $newprimary = '#c59617';
export const $newwarning = '#c7aa00';
export const $newdanger = '#c40f0f';
export const $newprimarygradient = 'linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), #c59617';
export const $newactivecolor = '#7e7d7d';
export const $newactivebackground = 'linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), #f7f8f9';
export const $newinactivebackground = '#edeeef';
export const $newinactivecolor = '#bcc0c6';
export const $newwhite = '#ffffff';
export const $newrounded = '3px';
export const $newwarningbackground = 'rgba(199, 170, 0, 0.05)';
export const $newsuccessbackground = 'rgba(123, 194, 88, 0.05)';
export const $newdangerbackground = 'rgba(196, 15, 15, 0.05)';
export const $gray10 = '#191919';
export const $gray16 = '#282828';
export const $gray30 = '#4c4c4c';
export const $gray50 = '#7f7f7f';
export const $gray55 = '#8c8c8c';
export const $gray60 = '#999';
export const $gray67 = '#aaaaaa';
export const $gray78 = '#c8c8c8';
export const $gray93 = '#efefef';
export const $gray97 = '#f8f8f8';
export const $brandprimary = '#c59617';
export const $brandprimarylight = `lighten(${$brandprimary}, 30%)`;
export const $brandprimarylighter = `lighten(${$brandprimary}, 45%)`;
export const $brandsecondary = 'black';
export const $brandsecondarylight = `lighten(${$brandsecondary}, 31%)`;
export const $brandsecondarylighter = `lighten(${$brandsecondary}, 67%)`;
export const $brandsuccess = '#5cb85c';
export const $brandinfo = '#5bc0de';
export const $brandwarning = '#f0ad4e';
export const $branddanger = '#9a4200';
export const $grayyellow = '#97938b';
export const $fontweightbase = '400';
export const $fontsizebase = '16px';
export const $fontsizelarge = '22px';
export const $fontsizesmall = '14px';
export const $fontsizeh1 = '48px';
export const $fontsizeh1sm = `ceil((${$fontsizeh1} * 0.8))`;
export const $fontsizeh1xs = `ceil((${$fontsizeh1} * 0.6))`;
export const $fontsizeh2 = '32px';
export const $fontsizeh2sm = `ceil((${$fontsizeh2} * 0.9))`;
export const $fontsizeh2xs = `ceil((${$fontsizeh2} * 0.8))`;
export const $fontsizeh3 = '20px';
export const $fontsizeh3sm = `ceil((${$fontsizeh3} * 0.9))`;
export const $fontsizeh3xs = `ceil((${$fontsizeh3} * 0.8))`;
export const $fontsizeh4 = '14px';
export const $fontsizeh4sm = `ceil((${$fontsizeh4} * 0.9))`;
export const $fontsizeh4xs = `ceil((${$fontsizeh4} * 0.8))`;
export const $screenxs = '480px';
export const $screenxsmin = `${$screenxs} !default`;
export const $screenphone = `${$screenxsmin} !default`;
export const $screensm = '768px';
export const $screensmmin = `${$screensm} !default`;
export const $screentablet = `${$screensmmin} !default`;
export const $screenmd = '961px';
export const $screenmdmin = `${$screenmd} !default`;
export const $screendesktop = `${$screenmdmin} !default`;
export const $screenlg = '1200px';
export const $screenlgmin = `${$screenlg} !default`;
export const $screenlgdesktop = `${$screenlgmin} !default`;
export const $screenxsmax = `(${$screensmmin} - 1) !default`;
export const $screensmmax = `(${$screenmdmin} - 1) !default`;
export const $screenmdmax = `(${$screenlgmin} - 1) !default`;
export const $gridcolumns = '12 !default';
export const $gridgutterwidth = '30px !default';
export const $gridfloatbreakpoint = $screenmdmin;
export const $gridfloatbreakpointmax = `(${$gridfloatbreakpoint} - 1) !default;`;
export const $cartpreviewwidth = '200px';
