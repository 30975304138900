import React from 'react';
import HeroCarouselCard from './HeroCarouselCard';
import Carousel from 'react-bootstrap/Carousel';
import {HeroCarouselContainer} from './HeroCarousel.styles';
import {Container} from 'react-bootstrap';

interface HeroCarouselItem {
  imageUrl: string;
  subHeading: string;
  description: string;
  callToActionLabel: string;
  callToActionUrl: string;
}

interface HeroCarouselProps {
  items: Array<HeroCarouselItem>;
}

const HeroCarousel = ({items}: HeroCarouselProps) => {
  const renderSlide = (slideData: JSX.Element[]) => {
    return (
      <Carousel.Item className="carousel-card-group carousel-item">
        <div className="">{slideData}</div>
      </Carousel.Item>
    );
  };

  const renderCards = (cardData: HeroCarouselItem[]) => {
    return cardData.map((x: HeroCarouselItem) => {
      return (
        <HeroCarouselCard
          key={x.imageUrl}
          image={x.imageUrl}
          description={x.description}
          callToActionLabel={x.callToActionLabel}
          subHeading={x.subHeading}
          callToActionUrl={x.callToActionUrl}></HeroCarouselCard>
      );
    });
  };

  const renderCarouselByWindowSize = (carouselCardInfo: HeroCarouselItem[]) => {
    return (
      <Carousel controls={false} touch={true} interval={null}>
        {renderSlide(renderCards(carouselCardInfo.slice(0, 1)))}
        {renderSlide(renderCards(carouselCardInfo.slice(1, 2)))}
        {renderSlide(renderCards(carouselCardInfo.slice(2, 3)))}
      </Carousel>
    );
  };

  return (
    <Container className="carousel-section new-style p-x-0">
      <HeroCarouselContainer className="react-bootstrap-hack">
        <div className="carousel slide cambria-carousel">{renderCarouselByWindowSize(items)}</div>
      </HeroCarouselContainer>
    </Container>
  );
};

export default HeroCarousel;
