import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import COMMERCE_CORE_CONSTANTS from '../../../Core/constants';
import {baseApiCallWithReauth} from '../../../Framework/api-utils/api-utils';
import {RootState} from '../../../store/store';
import {INotificationInitialState} from '../INotificationState';

export const initialState: INotificationInitialState = {
  campaignNotifications: [],
};

export const getCampaignUserEventsAsync = createAsyncThunk(
  'notification/getCampaignUserEventsAsync',
  async ({userId}: {userId: string}) => {
    const endpoint = `${COMMERCE_CORE_CONSTANTS.API_SERVICES.NOTIFICATION.campaigns}/userevents/${userId}`;
    return await baseApiCallWithReauth('GET', endpoint);
  }
);

export const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    setCampaignMessages: (state, action) => {
      state.campaignNotifications = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCampaignUserEventsAsync.fulfilled, (state, action) => {
      state.campaignNotifications = action.payload;
    });
  },
});

export const {setCampaignMessages} = notificationSlice.actions;
export const selectCampaignMessages = (state: RootState) => state.notification.campaignNotifications;
export default notificationSlice.reducer;
