import React from 'react';
import {selectUiSettings} from '../../../../features/environment/slice/environment.slice';
import {useTypedSelector} from '../../../../hooks/store';

const Hero = () => {
  const uiSettings = useTypedSelector(selectUiSettings);
  return (
    <div className="home-page-hero">
      {uiSettings ? (
        <div>
          <picture>
            <source media="(max-width: 768px)"></source>
            <img
              className="home-page-hero__img"
              src={uiSettings.homepageContentV2.homepageHero.imageUrl}
              alt="Background hero"
            />
          </picture>
          <div className="home-page-hero__overlay">
            <div className="container row">
              <div className="col-md-8 col-sm-12 overlay-block-1">
                <div className="content">
                  <h2 className="title">{uiSettings.homepageContentV2.homepageHero.overlayBlock1.heading}</h2>
                  <p className="description">{uiSettings.homepageContentV2.homepageHero.overlayBlock1.description}</p>
                  <a
                    className="cta"
                    target="_blank_"
                    href={uiSettings.homepageContentV2.homepageHero.overlayBlock1.callToActionUrl}>
                    {uiSettings.homepageContentV2.homepageHero.overlayBlock1.callToActionLabel}
                  </a>
                </div>
              </div>
              <div className="col-md-4 col-sm-12 overlay-block-2">
                <div className="content">
                  <p className="description">{uiSettings.homepageContentV2.homepageHero.overlayBlock2.description}</p>
                  <a
                    className="cta"
                    target="_blank_"
                    href={uiSettings.homepageContentV2.homepageHero.overlayBlock2.callToActionUrl}>
                    {uiSettings.homepageContentV2.homepageHero.overlayBlock2.callToActionLabel}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default Hero;
