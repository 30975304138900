import {CambriaSavedUploadFile, FabricationFile} from '../IFileState';
import {detectBrowser, detectMobileBrowser} from '../../../Framework/Services/browser-detection.service';
import {EndConsumer} from '../../cart/ICartState';
import {WarehouseFullData} from '../../warehouse/IWarehouseState';
import {PurchaseAgreementBillToSite} from '../../customer/ICustomerState';
import {toast} from 'react-toastify';
import {callback} from '../../callbacks/service/callbacks.service';
import {getFilesByIdRequest, postPurchaseAgreementRequest} from '../controller/file.controller';

export const downloadFileFromUrl = async (url: string, fileName: string) => {
  let match = detectBrowser();
  let isMobile = detectMobileBrowser();

  switch (match) {
    case 'chrome':
      await triggerDownload(fileName, url);
      break;
    case 'safari':
      downloadFromALink(fileName, url);
      if (isMobile) {
        await triggerDownload(fileName, url);
      }
      break;
    case 'firefox':
      downloadFromALink(fileName, url);
      break;
    case 'ipad':
    case 'iphone':
      downloadFromALink(fileName, url);
      break;
    case 'trident': // that is IE
    case 'edge':
      downloadFromALink(fileName, url);
      if (isMobile) {
        await triggerDownload(fileName, url);
      }
      break;
    default:
      let content = await fetch(url);
      let file = await content.blob();
      window.open(URL.createObjectURL(file));
      break;
  }
};

const downloadFromALink = (fileName: string, url: string) => {
  const downloadLink = document.createElement('a');
  downloadLink.setAttribute('href', url);
  downloadLink.setAttribute('download', fileName);
  downloadLink.setAttribute('target', '_self');
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
};

const triggerDownload = async (fileName: string, url: string) => {
  const reader = new FileReader();
  const content = await fetch(url);
  const file = await content.blob();

  reader.onload = (e) => {
    const a = document.createElement('a');
    a.href = URL.createObjectURL(file);
    a.download = fileName;
    a.click();
  };

  reader.readAsDataURL(file);
};

export const filterUserFiles = (
  allFiles: Array<CambriaSavedUploadFile>,
  fileExtension: string | null = null
): Array<CambriaSavedUploadFile> => {
  // on change update order-files.reducer.ts
  return allFiles
    ? allFiles.filter(
        (x) => !x.isHidden && (!fileExtension || x.fileExtension.toLowerCase() === fileExtension.toLowerCase())
      )
    : [];
};

export const createPurchaseAgreement = async (
  erpCustomerId: string,
  cartId: string,
  endConsumer: EndConsumer,
  warehouse: WarehouseFullData,
  billToSite: PurchaseAgreementBillToSite,
  shipToAddress: any,
  dispatch: any,
  purchasingCustomerOrderNumber?: string
) => {
  try {
    const params: any = {
      erpCustomerId,
      cartId,
      endConsumer,
      warehouse,
      billToSite,
      shipToAddress,
      purchasingCustomerOrderNumber,
    };

    const response = await postPurchaseAgreementRequest(params);

    if (response.status !== 202) {
      toast.error('Something went wrong.');
      throw new Error('Something went wrong.');
    }

    const callbackId = await response.text();
    return await callback(callbackId, 90, dispatch);
  } catch (e: any) {
    toast.error(e);
    return Promise.reject(e);
  }
};

export const downloadFile = async (fileId: string, erpCustomerId: string): Promise<any> => {
  const file = await getFilesByIdRequest(fileId, erpCustomerId);
  const downloadLink = document.createElement('a');
  downloadLink.setAttribute('href', file.signedUrl.href);
  downloadLink.setAttribute('download', file.originalFileName);
  downloadLink.click();
};

export const determineFileIcon = (file: CambriaSavedUploadFile | FabricationFile): string => {
  if (file.fileFormatType === 'Document') {
    return 'fa fa-file';
  } else if (file.fileFormatType === 'CAD') {
    return 'fa fa-drafting-compass';
  }
  return '';
};

export const downloadFabFile = async (file: CambriaSavedUploadFile | FabricationFile) => {
  await downloadFile(file.id, file.erpCustomerId);
};
