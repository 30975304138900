import React, {useState} from 'react';
import {ButtonGroup, Col, Row, ToggleButton} from 'react-bootstrap';
import {setStep3CompletedStatus} from '../../../../../features/checkout/slice/checkout.slice';
import {CreditCard} from '../../../../../features/payment/IPaymentState';
import {
  selectSelectedCheckoutCreditCard,
  setSelectedCheckoutCreditCard,
} from '../../../../../features/payment/slice/payment.slice';
import Icon from '../../../../../Framework/Components/Icon';
import useWindowDimensions from '../../../../../hooks/getWindowDimensions';
import {useAppDispatch, useTypedSelector} from '../../../../../hooks/store';
import CreditCardForm from '../../../Account/PaymentPreferences/CreditCardForm/CreditCardForm.component';
import {AvailableCreditCardsContainer} from './AvailableCreditCards.styled';

const AvailableCreditCards = ({creditCards}: {creditCards: CreditCard[]}) => {
  const {width} = useWindowDimensions();
  const dispatch = useAppDispatch();
  const [showAddCreditCardForm, setShowAddCreditCardForm] = useState<boolean>(false);
  const selectedCreditCard = useTypedSelector(selectSelectedCheckoutCreditCard);

  return (
    <AvailableCreditCardsContainer>
      <Row className="p-2">
        <Col xs={width > 960 ? 7 : 12} className="table-headers">
          CREDIT & DEBIT CARDS
        </Col>
        {width > 960 && (
          <Col xs={5} className="table-headers">
            EXPIRATION
          </Col>
        )}
      </Row>
      <Col className="credit-card-container pt-2 pb-2">
        {creditCards &&
          creditCards.map((card, index) => {
            return (
              <Row
                key={index}
                className="pb-3 pt-3"
                data-testid={`${card.lastFour}`}
                role="button"
                onClick={() => {
                  dispatch(setSelectedCheckoutCreditCard(card));
                  dispatch(setStep3CompletedStatus(false));
                }}>
                <Col xs={1}>
                  <div className="rounded p-3 number-box d-flex align-items-center justify-content-center">
                    <ButtonGroup>
                      <ToggleButton
                        role="button"
                        disabled
                        value=""
                        checked={selectedCreditCard?.providerToken === card.providerToken}
                      />
                    </ButtonGroup>
                  </div>
                </Col>
                <Col xs={width > 960 ? 6 : 9} className="d-flex align-items-center">
                  <Icon
                    icon={`icons-cambria-General-${card.cardType.toLowerCase()}`}
                    color="#000"
                    colorOnHover='color="#000"'
                    size="28"
                    weight="bold"
                    className="m-r-sm"
                  />
                  <span>
                    {card.cardType} CARD ENDING IN {card.lastFour}
                  </span>
                </Col>
                {width > 960 && (
                  <Col xs={5} className="d-flex align-items-center">
                    <span>{card.expirationDate}</span>
                  </Col>
                )}
              </Row>
            );
          })}
      </Col>
      {showAddCreditCardForm ? (
        <>
          <Row className="p-2 mt-4">
            <Col xs={12} className="table-headers">
              ADD CREDIT OR DEBIT CARD
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="credit-card-form-container">
                <CreditCardForm
                  onSumbitCallback={() => setShowAddCreditCardForm(false)}
                  onCancelCallback={() => setShowAddCreditCardForm(false)}
                />
              </div>
            </Col>
          </Row>
        </>
      ) : (
        <Row className="mt-3">
          <Col className="add-card-button" onClick={() => setShowAddCreditCardForm(true)}>
            + ADD CREDIT OR DEBIT CARD
          </Col>
        </Row>
      )}
    </AvailableCreditCardsContainer>
  );
};

export default AvailableCreditCards;
