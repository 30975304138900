import styled from 'styled-components/macro';
import {$newwhite, $screenmd} from '../../../Styled/variables';

export const CardContainer = styled.div`
  background-color: ${$newwhite};
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  padding: 50px;

  @media screen and (max-width: ${$screenmd}) {
    padding: 20px;
  }
`;
