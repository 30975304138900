import {Product} from '../../productCatalog/IProductCatalogState';

export interface IProductSearchState {
  keyword: string;
  activeFilter: IFilter;
  suggestions: Product[];
  availableFilters: IFilter[];
}

export interface IFilter {
  name: string;
  param: string;
  mobileName?: string;
  order?: number;
  mobileSelected?: string;
}

export interface IProductSearchFilter {
  mobileSelected: string;
  name: string;
  order: number;
  param: string;
  urn: string;
}

export const filters = [
  {
    name: 'All',
    mobileSelected: 'All',
    param: '',
    order: 1,
  },
  {
    name: 'Order SLABS',
    mobileSelected: 'SLABS',
    param: 'Slab',
    order: 2,
  },
  {
    name: 'Order SAMPLES',
    mobileSelected: 'SAMPLES',
    param: 'Samples',
    order: 3,
  },
  {
    name: 'Order TILE',
    mobileSelected: 'TILE',
    param: 'Tile',
    order: 4,
  },
  {
    name: 'Order BRANDED MERCHANDISE',
    mobileSelected: 'MERCH',
    param: 'Store',
    order: 5,
  },
];

export interface ProductRecommendationsResponse {
  results: Product[];
  totalResults: number;
}
