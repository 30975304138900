import React, {FC} from 'react';
import {CartItem} from '../../../../features/cart/ICartState';
import CambriaInput from '../../../../Framework/Components/CambriaInput';
import {Form, Formik} from 'formik';
import {useUpdateCartItemQuantity} from '../../../../features/cart/hooks/useUpdateCartItemQuantity/useUpdateCartItemQuantity';
import {CartItemQuantityInputContainer} from './CartItemQuantityInput.styled';
import COMMERCE_CORE_CONSTANTS from '../../../../Core/constants';

export interface CartItemQuantityInputProps {
  cartItem: CartItem;
}

const CartItemQuantityInput: FC<CartItemQuantityInputProps> = ({cartItem}) => {
  const updateCartItemQuantity = useUpdateCartItemQuantity();

  const onQuantityUpdate = async (value: any) => {
    await updateCartItemQuantity(cartItem, value);
  };

  return (
    <CartItemQuantityInputContainer>
      <Formik enableReinitialize onSubmit={() => {}} initialValues={{cartItemQuantity: cartItem.quantity}}>
        {(props) => {
          return (
            <Form noValidate>
              <CambriaInput
                disabled={cartItem.programCode.startsWith(COMMERCE_CORE_CONSTANTS.PROGRAMS.BUNDLE_PROGRAM_CODE_PREFIX)}
                className="cart-preview-item-qty-input"
                name={`cartItemQuantity`}
                type="counter"
                onChange={onQuantityUpdate}
                required={true}
              />
            </Form>
          );
        }}
      </Formik>
    </CartItemQuantityInputContainer>
  );
};

export default CartItemQuantityInput;
