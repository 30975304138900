import React, {FC, useState} from 'react';
import {ICloseToExpireQuote} from '../../../../features/quote/IQuoteState';
import CambriaModal from '../../../../Framework/Components/CambriaModal';
import {useAcknowledgeQuoteNotification} from '../../../../features/quote/hooks/useAcknowledgeQuoteNotification';
import {toast} from 'react-toastify';
import {formatISODate} from '../../../../Framework/Services/formatDate';
import {Form} from 'react-bootstrap';

export interface ExpiringQuoteNotificationProps {
  expiringQuote: ICloseToExpireQuote;
}

const ExpiringQuoteNotification: FC<ExpiringQuoteNotificationProps> = ({expiringQuote}) => {
  let [showMessage, setShowMessage] = useState<boolean>(true);
  let [loading, setLoading] = useState<boolean>(false);
  const acknowledgeNotification = useAcknowledgeQuoteNotification({quoteId: expiringQuote.id});

  const onFormSubmit = async () => {
    setLoading(true);
    try {
      await acknowledgeNotification();
    } catch (err) {
      toast.error('There was an error saving the acknowledge');
    }
    setShowMessage(false);
  };

  return (
    <CambriaModal
      show={showMessage}
      confirmButton={'I Acknowledge'}
      heading={'Quote close to expiration'}
      formName={`${expiringQuote.id}-expiring-quote-notification`}
      hideCancelButton={true}
      hideExitIcon={true}
      onSubmitFunctionIsRunning={loading}
      disableClickOutsideToClose={true}>
      <Form
        id={`${expiringQuote.id}-expiring-quote-notification`}
        onSubmit={(e) => {
          e.preventDefault();
          onFormSubmit();
        }}>
        <>
          The following quote {expiringQuote.name} is expiring on {formatISODate(expiringQuote.expirationDate)}. Please
          contact your Cambria Commercial Market Representative for any additional information.
        </>
      </Form>
    </CambriaModal>
  );
};

export default ExpiringQuoteNotification;
