import React from 'react';
import {Col, Row} from 'react-bootstrap';
import {selectUiSettings} from '../../../features/environment/slice/environment.slice';
import {UiSettings} from '../../../features/environment/IEnvironmentState';
import {useTypedSelector} from '../../../hooks/store';
import LoginForm from './LoginForm';
import {LoginPageContainer} from './LoginPage.styled';

const LoginPage = () => {
  const uiSettings: UiSettings | null = useTypedSelector(selectUiSettings);

  const renderBackgroundImage = () => {
    if (uiSettings && uiSettings.loginPageContent) {
      return (
        <picture>
          <source media="(max-width: 1024px)" srcSet={uiSettings.loginPageContent.mobileImageUrl} />
          <source media="(max-width: 1500px)" srcSet={uiSettings.loginPageContent.tabletImageUrl} />
          <img src={uiSettings.loginPageContent.desktopImageUrl} alt="background" />
        </picture>
      );
    }
  };

  return (
    <LoginPageContainer>
      <div className="background-content">{renderBackgroundImage()}</div>
      <div className="main-content">
        <div className="transparent-box user-login">
          <LoginForm />
        </div>
        <div className="transparent-box customer-care">
          <div>
            To request a login, please contact us at
            <a href="tel:18662262742" target="_blank" rel="noopener noreferrer">
              {' '}
              1-866-CAMBRIA{' '}
            </a>
            (1-866-226-2742) or
            <a href="mailto:CustomerService@CambriaUSA.com" target="_blank" rel="noopener noreferrer">
              {' '}
              CustomerService@CambriaUSA.com
            </a>
          </div>
        </div>
        <Row className="login-page-image-text-container">
          <Col xs={12} sm={9} className="login-page-image-text-section-1">
            <h2 className="login-page-image-text">{uiSettings?.loginPageContent?.loginSubHeading}</h2>
          </Col>
          <Col xs={12} sm={3} className="login-page-image-text-section-2">
            <div className="login-page-image-design">{uiSettings?.loginPageContent?.loginImageDesign} &trade;</div>
          </Col>
        </Row>
      </div>
    </LoginPageContainer>
  );
};

export default LoginPage;
