export interface CustomerState {
  currentCustomer: CustomerFullProfile;
  currentCustomerBillToSite: BillToSite | null;
  currentCustomerBillToSites: BillToSite[] | null;
  currentCustomerShipToSites: Site[] | null;
  siteInfo: Site[] | null;
  siteInfoStatus: 'idle' | 'loading' | 'succeeded' | 'failed';
  preferredShippingContact: PreferredShippingContact[];
}

export interface DisplayField {
  code: string;
  name: string;
}

export interface LoadReportLine {
  shipToName: string;
  orderNumber: string;
  orderLine: string;
  customerPO: string;
  orderDate: string;
  scheduledShipDate: string;
  availableToShipDate: string;
  dockDate: string;
  deliveryID: number;
  itemThickness: string;
  itemSize: string;
  itemDesign: string;
  itemFinish: string;
  orderedQuantity: number;
}

export interface LoadReports {
  name: string;
  displayFields: DisplayField[];
  lines: LoadReportLine[];
}

export interface CustomerStatementInformation {
  statements: CustomerStatement[];
  customerAccountName: string;
  customerAccountNumber: string;
  ranOn: string;
}

export interface CustomerStatement {
  operatingUnitCode: number;
  currencyCode: string;
  operatingUnitName: string;
  statementContactDescription: string;
  lines: CustomerStatementLine[];
}

export interface CustomerStatementLine {
  documentType: string;
  documentNumber: string;
  documentDateTime: string;
  paymentTermsCode: string;
  paymentTermsDescription: string;
  documentDueDateTime: string;
  originalAmount: number;
  remainingAmount: number;
  daysPastDue: number;
  current: number;
  oneToThirtyPastDue: number;
  thirtyOneToSixyPastDue: number;
  sixtyOneToNinetyPastDue: number;
  overNinetyPastDue: number;
}

export interface CustomerFullProfile {
  id?: number | undefined;
  erpCustomerId: number;
  name?: string;
  crmAccountId?: string;
  siteType?: string;
  siteName?: string;
  type?: string;
  status?: string;
  address1?: string;
  address2?: string;
  address3?: string;
  address4?: string;
  city?: string;
  county?: string;
  stateProvince?: string;
  postalCode?: string;
  country?: string;
  salesChannel?: string;
  class: string;
  recordCreated?: Date;
  recordCreatedBy?: string;
  recordUpdated?: Date;
  recordUpdatedBy?: string;
  taxOverrideEligible?: string;
  oracleAccountReference?: string;
  contacts?: Contact[];
  isIdentifying?: string;
  sites?: Site[];
  phoneNumber?: string[];
  email?: string[];
  isPrimary?: string;
  warehouseCode?: string;
  defaultWarehouseCode: string;
  productTypeWarehouses: ProductTypeWarehouse[];
  classGroups: string[];
  siteNumber?: number | null;
  productTypeWarehouseCodes: ProductTypeWarehouseResponse[];
  salesforceRetailAcount?: SalesforceRetailAccount;
  isSetupProperly?: boolean;
}

export const NewBillingAddress: BillingAddress = {
  city: '',
  country: '',
  geocodeAccuracy: '',
  latitude: 0,
  longitude: 0,
  postalCode: '',
  state: '',
  street: '',
};
export const NewSalesForceRetailAccount: SalesforceRetailAccount = {
  id: '',
  name: '',
  billingAddress: NewBillingAddress,
  contacts: [],
  oracleRegionName: '',
  additional_Addresses: [],
};
export const NewCustomerFullProfile = (): CustomerFullProfile => {
  return {
    id: 0,
    erpCustomerId: 0,
    class: '',
    defaultWarehouseCode: '',
    productTypeWarehouses: [],
    classGroups: [],
    productTypeWarehouseCodes: [],
    salesforceRetailAcount: NewSalesForceRetailAccount,
  };
};

export interface PurchaseAgreementBillToSite {
  siteName?: string;
  address1?: string;
  address2?: string;
  address3?: string;
  address4?: string;
  city?: string;
  stateProvinceCode?: string;
  postalCode?: string;
  countryCode?: string;
  county?: string;
  warehouseCode?: string;
  siteNumber: number;
  erpPaymentTermsId?: number;
  paymentTerms?: string;
  oracleAccountSiteReference?: string;
  salesRep?: string;
  siteUseId: number;
  operatingUnit?: string;
  operatingUnitCode?: string;
}

export interface BillToSite {
  id: number;
  erpAccountSiteId: number;
  erpPartySiteId: number;
  siteType?: string;
  siteName?: string;
  address1?: string;
  address2?: string;
  address3?: string;
  address4?: string;
  city?: string;
  county?: string;
  stateProvince?: string;
  postalCode?: string;
  country?: string;
  status?: string;
  isPrimary?: string;
  isIdentifying?: string;
  warehouseCode?: string;
  siteNumber: number;
  recordCreated?: Date;
  recordCreatedBy?: string;
  recordUpdated?: Date;
  recordUpdatedBy?: string;
  contacts?: Contact[];
  erpWarehouseId?: number;
  erpPaymentTermsId?: number;
  paymentTerms?: string;
  freightTerms?: string;
  oracleAccountSiteReference?: string;
  salesRep?: string;
  operatingUnit?: string;
  operatingUnitCode?: string;
  siteUseId: number;
}

export interface ShipToSite {
  id: number;
  name: string;
  displayName: string;
  erpAccountSiteId: number;
  erpPartySiteId: number;
  siteType?: string;
  siteName?: string;
  address1?: string;
  address2?: string;
  address3?: string;
  address4?: string;
  city?: string;
  county?: string;
  stateProvince?: string;
  postalCode?: string;
  country?: string;
  status?: string;
  isPrimary?: string;
  isIdentifying?: string;
  warehouseCode?: string;
  siteNumber: number;
  recordCreated?: Date;
  recordCreatedBy?: string;
  recordUpdated?: Date;
  recordUpdatedBy?: string;
  contacts?: Contact[];
  erpWarehouseId?: number;
  erpPaymentTermsId?: number;
  paymentTerms?: string;
  freightTerms?: string;
  oracleAccountSiteReference?: string;
  salesRep?: string;
  operatingUnit?: string;
  operatingUnitCode?: string;
  siteUseId: number;
  isPending: boolean;
}

export interface Contact {
  id: number;
  crmContactId?: string;
  firstName?: string;
  lastName?: string;
  title?: string;
  role?: string;
  type?: string;
  status?: string;
  recordCreated?: Date;
  recordCreatedBy?: string;
  recordUpdated?: Date;
  recordUpdatedBy?: string;
  phoneNumber?: string[];
  email?: string[];
  oracleContactReference?: string;
  erpCustomerContactId?: number;
}

export const NewContact = (): Contact => {
  return {
    id: 0,
  };
};

export interface Site {
  id: number;
  erpAccountSiteId: number;
  erpPartySiteId: number;
  siteType?: string;
  siteName?: string;
  address1?: string;
  address2?: string;
  address3?: string;
  address4?: string;
  city?: string;
  county?: string;
  stateProvince?: string;
  postalCode?: string;
  country?: string;
  status?: string;
  isPrimary?: string;
  isIdentifying?: string;
  warehouseCode?: string;
  siteNumber: number;
  recordCreated?: Date;
  recordCreatedBy?: string;
  recordUpdated?: Date;
  recordUpdatedBy?: string;
  contacts?: Contact[];
  erpWarehouseId?: number;
  erpPaymentTermsId?: number;
  paymentTerms?: string;
  freightTerms?: string;
  oracleAccountSiteReference?: string;
  salesRep?: string;
  operatingUnit?: string;
  operatingUnitCode?: string;
  siteUseId: number;
  address?: string;
  missingAddressDetails?: boolean;
  retailCrmAccountId?: string;
  additional_Addresses?: any;
  segment?: string;
}

export const NewSite = (): Site => {
  return {
    id: 0,
    erpAccountSiteId: 0,
    erpPartySiteId: 0,
    siteType: '',
    siteName: '',
    address1: '',
    siteNumber: 0,
    siteUseId: 0,
  };
};

export interface ProductTypeWarehouse {
  warehouseCode?: string;
  operatingUnitCode?: string;
}

export interface ProductTypeWarehouseResponse {
  productType?: string;
  warehouseCode?: string;
  fallbackWarehouseCode?: string;
  crossDockWarehouseCode?: string;
  operatingUnitCode?: string;
}

export interface SalesforceRetailAccount {
  id: string;
  name: string;
  billingAddress: BillingAddress;
  contacts: Array<SalesforceContact>;
  oracleRegionName: string;
  additional_Addresses: Array<BillingAddress>;
}

export interface BillingAddress {
  city: string;
  country: string;
  geocodeAccuracy: string;
  latitude: number | null;
  longitude: number | null;
  postalCode: string;
  state: string;
  street: string;
}

export interface SalesforceContact {
  id: string;
  firstName: string;
  lastName: string;
  mobilePhone: string;
  phone: string;
  email: string;
}

export interface PurchasingCustomer {
  id: number;
  userId: string;
  erpCustomerId: number;
  cartId: string;
  customerSiteId: number;
  customerContactId: number;
  siteName: string;
  contactFirstName: string;
  contactLastName: string;
  contactPhoneNumber: string;
  contactEmail: string;
  address1: string;
  address2: string;
  address3: string;
  address4: string;
  city: string;
  stateProvinceCode: string;
  postalCode: string;
  countryCode: string;
  createdAt: Date;
  createdBy: string;
  updatedAt: Date;
  updatedBy: string;
  retailCrmAccountId: string;
  isActive: false;
  orderNumber: string;
  endConsumers: EndConsumer[];
  isUpdatePending: boolean;
}

export interface EndConsumer {
  id: number;
  userId: string;
  erpCustomerId: number;
  cartId: string;
  retailSiteId: number;
  contactFirstName: string;
  contactLastName: string;
  contactPhoneNumber: string;
  contactEmail: string;
  address1: string;
  address2: string;
  address3: string;
  address4: string;
  city: string;
  stateProvinceCode: string;
  postalCode: string;
  countryCode: string;
  createdAt: Date;
  createdBy: string;
  updatedAt: Date;
  updatedBy: string;
  isActive: boolean;
  jobNumber: string;
  companyName: string;
  orderNumber: string;
  cheeseboardDesign: number;
  cheeseboard: string;
  isUpdatePending: boolean;
  isForFabOrder: boolean;
  estimatedInstallDate: string;
}

export interface IEmailSubscriptionData {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  userId: number;
  erpCustomerId: string;
  type: string;
}

export interface IEndConsumerSearchResult {
  createdAt: string | Date;
  createdBy: string;
  updatedAt: string | Date;
  updatedBy: string | null;
  fabricationJobType: string;
  fabricationAddOnNumber: number | null;
  fabricationReworkNumber: null;
  originalFabricationJobNumber: number | null;
  orderNumber: string;
  endUserName: string;
  address1: string;
  address2: string | null;
  address3: string | null;
  city: string;
  stateProvinceCode: string;
  countryCode: string;
  postalCode: string;
  contactPhoneNumber: string | null;
  contactEmail: string | null;
  companyName: string | null;
  orderCreatedAt: string | Date;
  orderUpdatedAt: string | Date;
  fabricationJobNumber: string;
}

export interface IEditConsumerData {
  firstName: string;
  lastName: string;
  businessName: string;
  country: string;
  address1: string;
  address2: string;
  address3: string;
  city: string;
  state: string;
  zipCode: string;
  endUserPhone: string;
  endUserEmail: string;
  jobNumber: string;
  estimatedInstall?: Date | null;
  isForFabOrder: boolean;
  id: number;
}

export interface PreferredShippingContactRequest {
  erpCustomerId: string;
  erpContactId: string;
  operatingUnitCode: string;
}

export interface PreferredShippingContact {
  id: number;
  erpCustomerId: string;
  erpContactId: string;
  operatingUnitCode: string;
}

export const initialCustomer: CustomerFullProfile = {
  productTypeWarehouses: [],
  classGroups: [],
  productTypeWarehouseCodes: [],
  id: undefined,
  erpCustomerId: 0,
  name: '',
  crmAccountId: '',
  type: '',
  status: '',
  salesChannel: '',
  class: '',
  recordCreated: undefined,
  recordCreatedBy: '',
  recordUpdated: undefined,
  taxOverrideEligible: undefined,
  oracleAccountReference: undefined,
  contacts: [],
  sites: [],
  phoneNumber: [],
  email: [],
  defaultWarehouseCode: '',
};
