import {IProduct} from '../product/IProductState';

export interface ProductSearch {
  activeFilter: ProductFilter;
  keyword: string | null;
  suggestions: IProduct[];
  availableFilters: any[];
}

export interface CustomerSearch {
  keyword: string | null;
  suggestions: ICustomerSearchResult[];
  numberOfResults: number;
  loading: string;
  currentRequestId: string | undefined;
  marketRepCustomer: any;
}

export interface OrderSearch {
  suggestions: any[];
  orderUrlSearchParams: OrderSearchParams;
  activeFilter: string | null;
  orderActiveParams: OrderFilter[];
  loading: string;
  currentRequestId: string | undefined;
}

export interface QuoteSearch {
  suggestions: any[];
  quoteUrlSearchParams: QuoteSearchParams;
  activeFilter: string | null;
  quoteActiveParams: QuoteFilter[];
  loading: string;
  currentRequestId: string | undefined;
}

export interface ProductFilter {
  name: string;
  param: string;
  mobileName: string;
  urn: string;
}

export interface OrderFilter {
  name: string;
  value: string;
}

export interface QuoteFilter {
  name: string;
  value: string;
}

export interface OrderSearchParams {
  status: string;
  purchaseOrderNumber: string;
  orderType: string;
  orderNumber: string;
  isFavorite: string;
  favoriteName: string;
  endConsumerLastName: string;
  endConsumerFirstName: string;
  'dateRange.dateFrom': string;
  'dateRange.dateTo': string;
  'amountRange.amountFrom': string;
  'amountRange.amountTo': string;
  q: string;
  endConsumerAddress1: string;
  jobNumber: string;
  statusCode?: string;
}

export const OrderSearchParamsInitialState: OrderSearchParams = {
  status: '',
  purchaseOrderNumber: '',
  orderType: '',
  orderNumber: '',
  isFavorite: '',
  favoriteName: '',
  endConsumerLastName: '',
  endConsumerFirstName: '',
  'dateRange.dateFrom': '',
  'dateRange.dateTo': '',
  'amountRange.amountFrom': '',
  'amountRange.amountTo': '',
  q: '',
  endConsumerAddress1: '',
  jobNumber: '',
};

export interface QuoteSearchParams {
  status: string;
  q: string;
  'dateRange.dateFrom': string;
  'dateRange.dateTo': string;
  'amountRange.amountFrom': string;
  'amountRange.amountTo': string;
}

export const QuoteSearchParamsInitialState: QuoteSearchParams = {
  status: '',
  q: '',
  'dateRange.dateFrom': '',
  'dateRange.dateTo': '',
  'amountRange.amountFrom': '',
  'amountRange.amountTo': '',
};

export interface ICustomerSearchResult {
  id: number;
  erpCustomerId: number;
  name: string;
  siteType: string;
  siteName: string;
  address1: string;
  address2: string;
  address3: string;
  address4: string;
  city: string;
  county: string;
  stateProvince: string;
  postalCode: string;
  country: string;
  status: string;
  isPrimary: string;
  isIdentifying: string;
  class: string;
  taxOverrideEligible: string;
  warehouseCode: string;
  siteNumber: number;
}

export const filters = [
  {
    name: 'All',
    mobileSelected: 'All',
    param: '',
    order: 1,
    urn: '',
  },
  {
    name: 'Order SLABS',
    mobileSelected: 'SLABS',
    param: 'Slab',
    order: 2,
    urn: 'urn:csa:commerceui:productCatalog:viewSlabs',
  },
  {
    name: 'Order SAMPLES',
    mobileSelected: 'SAMPLES',
    param: 'Samples',
    order: 3,
    urn: 'urn:csa:commerceui:productCatalog:viewSamples',
  },
  {
    name: 'Order TILE',
    mobileSelected: 'TILE',
    param: 'Tile',
    order: 4,
    urn: 'urn:csa:commerceui:productCatalog:viewTiles',
  },
  {
    name: 'Order BRANDED MERCHANDISE',
    mobileSelected: 'MERCH',
    param: 'Store',
    order: 5,
    urn: 'urn:csa:commerceui:productCatalog:viewStore',
  },
];

export const initialOrderParams: OrderSearchParams = {
  status: '',
  purchaseOrderNumber: '',
  orderType: '',
  orderNumber: '',
  isFavorite: '',
  favoriteName: '',
  endConsumerLastName: '',
  endConsumerFirstName: '',
  'dateRange.dateFrom': '',
  'dateRange.dateTo': '',
  'amountRange.amountFrom': '',
  'amountRange.amountTo': '',
  q: '',
  endConsumerAddress1: '',
  jobNumber: '',
};

export const initialQuoteParams: QuoteSearchParams = {
  status: '',
  'dateRange.dateFrom': '',
  'dateRange.dateTo': '',
  'amountRange.amountFrom': '',
  'amountRange.amountTo': '',
  q: '',
};
