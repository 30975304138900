import {toast} from 'react-toastify';
import {CheckoutState} from '../ICheckoutState';
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {Cart} from '../../cart/ICartState';
import {postOrderNotificationContactsRequest} from '../../order/controller/order.controller';
import {RootState} from '../../../store/store';
import {saveOrderDetails, saveShippingDetails} from '../service/checkout.service';
import {hideFullscreenLoader} from '../../fullscreenLoader/slice/fullscreenLoader.slice';

export const initialState: CheckoutState = {
  steps: [
    {
      step: '01',
      name: 'Order Details',
      url: 'order-details',
      isEnabled: true,
      isActive: false,
      isDisabled: false,
    },
    {
      step: '02',
      name: 'Shipping & Delivery',
      url: 'shipping-and-delivery',
      isEnabled: true,
      isActive: false,
      isDisabled: true,
    },
    {
      step: '03',
      name: 'Payment Method',
      url: 'payment-method',
      isEnabled: true,
      isActive: false,
      isDisabled: true,
    },
    {
      step: '04',
      name: 'Review & Place Order',
      url: 'review-and-place-order',
      isEnabled: true,
      isActive: false,
      isDisabled: true,
    },
  ],
  activeStep: null,
  isSubmitting: false,
  step1Completed: false,
  step2Completed: false,
  step3Completed: false,
  step4Completed: false,
  paymentMethodPageSkipped: false,
  savePaymentMethodToCart: false,
  nextButtonToastErrorMessages: [],
};

export const submitOrderDetails = createAsyncThunk(
  'checkout/submitOrderDetails',
  async ({activeCart, orderDetails}: {activeCart: any; orderDetails: any}, {rejectWithValue, dispatch}) => {
    try {
      return await saveOrderDetails(activeCart, orderDetails, dispatch);
    } catch (error) {
      dispatch(hideFullscreenLoader());
      return rejectWithValue(null);
    }
  }
);

export const submitShippingAndDelivery = createAsyncThunk(
  'checkout/submitShippingAndDelivery',
  async (
    {
      activeCart,
      shippingDetails,
    }: {
      activeCart: Cart;
      shippingDetails: any;
    },
    {rejectWithValue, dispatch}
  ) => {
    try {
      await saveShippingDetails(activeCart, shippingDetails, dispatch);
      await postOrderNotificationContactsRequest(activeCart.id, shippingDetails.phoneNumber, shippingDetails.email);
    } catch (error: any) {
      dispatch(hideFullscreenLoader());
      toast.error(error);
      return rejectWithValue(null);
    }
  }
);

export const checkoutSlice = createSlice({
  name: 'checkout',
  initialState,
  reducers: {
    setActiveStep: (state, action) => {
      if (action.payload === null) {
        state.activeStep = null;
        state.steps.forEach((s) => {
          s.isActive = false;
        });
        state.step1Completed = false;
        state.step2Completed = false;
        state.step3Completed = false;
        state.step4Completed = false;
        return;
      }

      const activeStep = action.payload;
      state.steps.forEach((s) => {
        if (s.step === activeStep.step) {
          s.isActive = true;
          s.isDisabled = false;
          state.activeStep = activeStep;
          return;
        } else {
          s.isActive = false;
          if (parseInt(s.step) < parseInt(activeStep.step)) {
            s.isDisabled = false;
          }
        }
      });
    },
    invalidateStep1: (state) => {
      state.step1Completed = false;
    },
    invalidateStep2: (state) => {
      state.step2Completed = false;
    },
    hideCheckoutStep: (state, action) => {
      const stepToDisable = action.payload;
      state.steps.forEach((s) => {
        if (s.step === stepToDisable.step) {
          s.isEnabled = false;
        }
      });
    },
    disableCheckoutStepsAfterActiveStep: (state, action) => {
      const activeStepIndex = action.payload;
      state.steps.forEach((s, index) => {
        if (index > activeStepIndex) {
          s.isDisabled = true;
        }
      });
    },
    enableCheckoutStep: (state, action) => {
      const stepToEnable = action.payload;
      state.steps.forEach((s) => {
        if (s.step === stepToEnable) {
          s.isDisabled = false;
        }
      });
    },
    setStep2Completed: (state, action) => {
      state.step2Completed = action.payload;
    },
    setStep3CompletedStatus: (state, action) => {
      state.step3Completed = action.payload;
    },
    setIsSubmitting: (state, action) => {
      state.isSubmitting = action.payload;
    },
    setPaymentMethodPageSkipped: (state, action) => {
      state.paymentMethodPageSkipped = action.payload;
    },
    setSavePaymentMethodToCart: (state, action) => {
      state.savePaymentMethodToCart = action.payload;
    },
    setNextButtonToastErrorMessages: (state, action) => {
      state.nextButtonToastErrorMessages = action.payload;
    },
    setCheckoutInitialState: (state: any) => {
      for (const key in initialState) {
        state[key] = (initialState as any)[key];
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(submitOrderDetails.pending, (state: any) => {
      state.step1Completed = false;
      state.isSubmitting = true;
    });
    builder.addCase(submitOrderDetails.fulfilled, (state: any) => {
      state.step1Completed = true;
      state.isSubmitting = false;
    });
    builder.addCase(submitOrderDetails.rejected, (state: any) => {
      state.step1Completed = false;
      state.isSubmitting = false;
    });
    builder.addCase(submitShippingAndDelivery.pending, (state: any) => {
      state.step2Completed = false;
      state.isSubmitting = true;
    });
    builder.addCase(submitShippingAndDelivery.fulfilled, (state: any) => {});
    builder.addCase(submitShippingAndDelivery.rejected, (state: any) => {
      state.step2Completed = false;
    });
  },
});

export const {
  setActiveStep,
  hideCheckoutStep,
  invalidateStep1,
  invalidateStep2,
  setStep3CompletedStatus,
  setIsSubmitting,
  enableCheckoutStep,
  disableCheckoutStepsAfterActiveStep,
  setPaymentMethodPageSkipped,
  setStep2Completed,
  setSavePaymentMethodToCart,
  setNextButtonToastErrorMessages,
  setCheckoutInitialState,
} = checkoutSlice.actions;

export const selectActiveStep = (state: RootState) => state.checkout.activeStep;
export const selectCheckoutSteps = (state: RootState) => state.checkout.steps;
export const selectIsSubmitting = (state: RootState) => state.checkout.isSubmitting;
export const selectStep1Completed = (state: RootState) => state.checkout.step1Completed;
export const selectStep2Completed = (state: RootState) => state.checkout.step2Completed;
export const selectStep3Completed = (state: RootState) => state.checkout.step3Completed;
export const selectStep4Completed = (state: RootState) => state.checkout.step4Completed;
export const selectPaymentMethodpageSkipped = (state: RootState) => state.checkout.paymentMethodPageSkipped;
export const selectSavePaymentMethodToCart = (state: RootState) => state.checkout.savePaymentMethodToCart;
export const selectNextButtonToastErrorMessages = (state: RootState) => state.checkout.nextButtonToastErrorMessages;

export default checkoutSlice.reducer;
