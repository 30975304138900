import styled from 'styled-components/macro';
import {$screenmd} from '../../../../Styled/variables';

const PurchaseAgreementContainer = styled.div`
  .placeholder-overflow-class {
    overflow: hidden;
  }

  .placeholder-top-margin {
    margin-top: 30px;
  }

  .placeholder-bottom-margin {
    margin-bottom: 5px;
  }

  .submit-button {
    margin-left: -25px;

    @media screen and (max-width: ${$screenmd}) {
      margin-left: 0;
    }
  }
`;

export default PurchaseAgreementContainer;
