import {CORE_CONSTANTS} from '../../constant';

export const baseApiCall = async (method: string, endpointConstant: string, data?: any) => {
  //Base URL which corresponds to int, stg, prod
  const windowObj: any = {...window};
  let header = new Headers();

  let endpoint = windowObj.SERVER_DATA ? windowObj.SERVER_DATA.apiUrl + endpointConstant : '';

  //Prepare Header params
  const savedAuth = windowObj.localStorage.getItem(CORE_CONSTANTS.COOKIES.ecommerceToken);
  const anonymousAuth = windowObj.localStorage.getItem(CORE_CONSTANTS.COOKIES.cambriaAnonymousRefresh);
  if (savedAuth) {
    const auth = JSON.parse(savedAuth);
    header.set('authorization', `Bearer ${auth.response.access_token}`);
  } else if (anonymousAuth) {
    const auth = JSON.parse(anonymousAuth);
    header.set('authorization', `Bearer ${auth.response.access_token}`);
  }
  header.set('Content-Type', 'application/json; charset=UTF-8');

  //Conditionally decide what kind of fetch params are needed
  method = method.toUpperCase();
  let response: any = {};
  if (method === 'GET' || method === 'DELETE') {
    if (data) {
      endpoint += '?' + data.toString();
    }
    response = await fetch(endpoint, {
      method: method,
      headers: header,
    });
    return response;
  } else if (method === 'PUT' || method === 'PATCH' || method === 'POST') {
    response = await fetch(endpoint, {
      method: method,
      headers: header,
      body: data,
    });

    return response;
  }
};
