import React, {useEffect, useState} from 'react';
import StatusCodeStyled from './StatusCode.styled';

export const searchFor = (statusString: string, text: string) => {
  if (statusString) {
    if (statusString.toLowerCase().search(text) !== -1) {
      return true;
    }
  } else {
    return false;
  }
};

const primaryClassStatuses = [
  'Preparing Shipment',
  'Order Received',
  'Partially Shipped',
  'CAD Approval Pending',
  'CAD Revisions Needed',
  'Awaiting Order Approval',
  'CCC Revising Order',
  'Order Revisions Needed',
  'PO Approval Pending',
  'PO Revisions Needed',
  'Sales Accepted',
  'Ordered',
  'Approved',
  'Pending Edit',
];

const StatusCode = ({status}: {status: string}) => {
  const [statusColoring, setStatusColoring] = useState('default');

  useEffect(() => {
    if (status === 'Shipped' || status === 'Order Review') {
      setStatusColoring('default-light');
    } else if (status === 'Cancelled' || status === 'Expired') {
      setStatusColoring('default');
    } else if (status === 'CAD Revisions Needed' || status === 'Order Revisions Needed' || status === 'Rejected') {
      setStatusColoring('danger');
    } else if (primaryClassStatuses.includes(status)) {
      setStatusColoring('primary');
    }
  }, [status]);

  return (
    <StatusCodeStyled>
      <div className={statusColoring}>{status}</div>
    </StatusCodeStyled>
  );
};

export default StatusCode;
