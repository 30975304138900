import React, {useEffect, useState} from 'react';
import {Table} from 'react-bootstrap';
import {LineItem} from '../../../../App/AuthenticatedPages/Account/OrderHistory/OrderDetails/IOrderDetails';
import CambriaProductTableContainer from '../CambriaProductTable.styled';
import dragonImage from '../../../../Framework/img/main-logo.svg';
import {CartItem} from '../../../../features/cart/ICartState';
import {dateWithMonthSpelledOut, formatProductPrices} from '../CambriaProductTable.component';

const ProductTableDesktop = ({
  orderLineItems,
  isSlabOrder,
  forQuoteDetails = false,
  forReviewAndPlaceOrder = false,
}: {
  orderLineItems: LineItem[] | CartItem[];
  isSlabOrder: boolean;
  forQuoteDetails?: boolean;
  forReviewAndPlaceOrder?: boolean;
}) => {
  const [showDeliveryDates, setShowDeliveryDates] = useState<boolean>(false);
  useEffect(() => {
    if (orderLineItems.length > 0) {
      orderLineItems.forEach((item: any) => {
        if (item.requestedDelivery || item.scheduleDelivery || item.requestedDeliveryDate) {
          setShowDeliveryDates(true);
          return;
        }
      });
    }
  }, [orderLineItems]);

  return (
    <CambriaProductTableContainer>
      <div className="product-table-container">
        <Table>
          <thead>
            <tr>
              <th>PRODUCT</th>
              {forQuoteDetails ? <></> : <th></th>}
              <th>QUANTITY</th>
              <th>{forQuoteDetails ? 'DISCOUNTED' : ''} UNIT PRICE</th>
              <th>{forQuoteDetails ? 'DISCOUNTED' : ''} LINE PRICE</th>
              {showDeliveryDates ? (
                <>
                  <th>REQUESTED DELIVERY DATE</th>
                  {!forReviewAndPlaceOrder && <th>SCHEDULED {isSlabOrder ? 'DELIVERY' : 'SHIP'} DATE</th>}
                  <th>SEAMED</th>
                </>
              ) : (
                <></>
              )}
              {forQuoteDetails ? <th>EXPECTED ORDER DATE</th> : <></>}
              {!forReviewAndPlaceOrder && <th>STATUS</th>}
            </tr>
          </thead>
          <tbody>
            {orderLineItems.map((item: any, index) => {
              return (
                <tr key={index}>
                  {!forQuoteDetails && (
                    <td>
                      {item.widenLink ? (
                        <img src={item.widenLink} alt={''} />
                      ) : (
                        <>
                          {item.imageUrl ? (
                            <img height={50} src={item.imageUrl} alt={''} />
                          ) : (
                            <img src={dragonImage} height={40} alt={'Cambria Logo'} />
                          )}
                        </>
                      )}
                    </td>
                  )}

                  <td>{item.description}</td>
                  <td>
                    <div className="quantity-box">
                      {item.extendedQuantity ? item.extendedQuantity / 100 : item.quantity}
                    </div>
                  </td>
                  <td>
                    {item.hasAdjustedUnitPrice && !forQuoteDetails ? (
                      <>
                        {item.adjustedUnitPrice >= item.unitPrice ? (
                          <>
                            <>
                              {`$${formatProductPrices(item.adjustedUnitPrice)}`}
                              <br />
                              <span className="usd-label">{item.currency} / sqft</span>
                            </>
                          </>
                        ) : (
                          <>
                            {`$${formatProductPrices(item.adjustedUnitPrice)}`}
                            <br />
                            <span className="usd-label">{item.currency} / sqft</span>
                            <br />
                            <span className="original-price-strike-through">
                              ${formatProductPrices(item.unitPrice)}
                              <br /> <span className="usd-label">{item.currency} / sqft</span>
                            </span>
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        {`$${formatProductPrices(item.unitPrice)}`}
                        <br />
                        <span className="usd-label">{item.currency} / sqft</span>
                      </>
                    )}
                  </td>
                  <td>
                    {item.hasAdjustedExtendedNetPrice && !forQuoteDetails ? (
                      <>
                        {`$${formatProductPrices(item.adjustedExtendedNetPrice)}`}
                        <br />
                        <span className="usd-label">{item.currency}</span>
                        <br />
                        <span className="original-price-strike-through">
                          ${formatProductPrices(item.extendedNetPrice ? item.extendedNetPrice : item.linePrice)}
                          <br /> <span className="usd-label">{item.currency}</span>
                        </span>
                      </>
                    ) : (
                      <>
                        {`$${formatProductPrices(
                          item.extendedNetPrice ? item.extendedNetPrice : item.quantity * item.linePrice
                        )}`}
                        <br /> <span className="usd-label">{item.currency}</span>
                      </>
                    )}
                  </td>
                  {showDeliveryDates ? (
                    <>
                      <td data-testid={'requestedDelivery' + index}>
                        {item.requestedDelivery || item.requestedDeliveryDate
                          ? `${dateWithMonthSpelledOut(
                              item.requestedDelivery ||
                                new Date(item.requestedDeliveryDate.replace(/-/g, '/').replace(/T.+/, ''))
                            )}`
                          : ''}{' '}
                      </td>
                      {!forReviewAndPlaceOrder && (
                        <td>{item.scheduleDelivery ? `${dateWithMonthSpelledOut(item.scheduleDelivery)}` : ''}</td>
                      )}
                      <td data-testid={'seamed' + index}>{item.seamed ? 'Yes' : 'No'}</td>
                    </>
                  ) : (
                    <></>
                  )}
                  {forQuoteDetails ? (
                    <td>{item.expectedOrderDate ? `${dateWithMonthSpelledOut(item.expectedOrderDate)}` : ''}</td>
                  ) : (
                    <></>
                  )}

                  {!forReviewAndPlaceOrder && <td>{item.status}</td>}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    </CambriaProductTableContainer>
  );
};

export default ProductTableDesktop;
