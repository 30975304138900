import styled from 'styled-components';
import {$gray30, $gray60, $gray93, $newwhite} from '../../../../Styled/variables';

export const StyledReports = styled.div`
  min-height: 620px;
  font-weight: 600;

  .table-condensed {
    width: 100%;
  }

  .account-reports-nav {
    margin: 20px 0px 20px 0px;
    height: 40px;

    .link {
      border-radius: 3px;

      :hover {
        cursor: pointer;
      }
    }
  }

  tbody {
    box-shadow: 0px 2px 5px 0px rgba(101, 101, 101, 0.35);
    border-radius: 3px;
    border-collapse: separate;
  }

  .print-icon-button {
    background: none;
    border: none;
  }

  .fa-print {
    color: ${$gray60};
    font-size: 25px;

    :hover {
      cursor: pointer;
    }
  }

  thead > tr > th {
    color: ${$gray30} !important;
    font-weight: 600;
    font-size: 14px;
    text-align: center;
  }

  tbody > tr {
    height: 60px;
    vertical-align: middle;

    :nth-of-type(even) {
      background-color: ${$gray93};
    }

    :nth-of-type(odd) {
      background-color: ${$newwhite};
    }
  }

  tbody > tr > td {
    font-size: 14px;
    color: #4c4c4c;
    vertical-align: middle !important;
    text-align: center;
  }

  .heading {
    padding-bottom: 15px;
  }
`;
