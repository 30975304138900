import styled from 'styled-components';
import {$gray30, $gray67, $gray93} from '../../../../../Styled/variables';

export const EmailListContainer = styled.div`
  .react-multi-email input {
    background: transparent;
    width: 100% !important;
  }

  .react-multi-email {
    box-shadow: 0px 1px 1px rgb(0 0 0 / 20%);
    border: 1px solid #f1f1f4;
    line-height: 17px;
  }

  .active-email {
    box-shadow: 0px 2px 5px 0px rgb(101 101 101 / 35%);
    padding: 5px;
    width: fit-content;
    display: flex;
    align-items: center;
    background: ${$gray93};
    margin: 2px 2px 2px 2px;

    .email-text {
      margin-right: 10px;
      font-weight: 600;
      color: ${$gray30};
    }

    .fa-times {
      color: ${$gray67};

      :hover {
        cursor: pointer;
      }
    }
  }
`;
