import React, {useEffect, useState} from 'react';
import {useScrollPercentage} from 'react-scroll-percentage';
import {loadMoreOrdersAsync} from '../../../../features/search/order/slice/order-search.slice';
import {selectOrderSearch} from '../../../../features/search/slice/search.slice';
import {useAppDispatch, useTypedSelector} from '../../../../hooks/store';
import CustomerOrderNav from '../CustomerOrderNav';
import {SearchBodyWrapper} from '../SearchBodyWrapper.styled';
import OrderTable from './OrderTable';
import OrderPageContainer from './OrderSearchPage.styled';
import COMMERCE_CORE_CONSTANTS from '../../../constants';
import {cleanSearchParams} from '../../../../features/order/service/order.service';

const OrderSearchPage = () => {
  const dispatch = useAppDispatch();
  const [ref, percentageScrolled] = useScrollPercentage();

  const [orderSearchResults, setOrderResults] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [scrolledFarEnough, setScrolledFarEnough] = useState(false);
  const [resultDisplaySize, setResultDisplaySize] = useState(50);

  let orderSuggestions = useTypedSelector(selectOrderSearch).suggestions;
  let loadingStatus = useTypedSelector(selectOrderSearch).loading;
  let urlSearchParams = useTypedSelector(selectOrderSearch).orderUrlSearchParams;

  useEffect(() => {
    const scrollToTopOnLoad = () => {
      window.scrollTo({top: 0, left: 0});
    };
    setTimeout(() => {
      scrollToTopOnLoad();
    }, 500);
  }, []);

  useEffect(() => {
    const loadMoreDataOnScroll = (numResultsToShow: number) => {
      let filters: any = urlSearchParams;
      let data = new URLSearchParams(filters);
      data = cleanSearchParams(data);
      data.append('pager[expand]', 'totalResults');
      data.append('pager[offset]', '0');
      data.append('pager[limit]', numResultsToShow.toString());
      data.append('pager[expand]', 'orderLineItems');
      data.append('pager[sort]', '-createdAt');
      data.append('pager[expand]', 'purchaseOrderNumber');
      dispatch(loadMoreOrdersAsync(data));
      setScrolledFarEnough(false);
    };
    const determineIfMoreDataShouldBeLoaded = () => {
      if (scrolledFarEnough && resultDisplaySize <= orderSearchResults.length) {
        loadMoreDataOnScroll(resultDisplaySize + 50);
        setResultDisplaySize((r) => r + 50);
      }
    };
    determineIfMoreDataShouldBeLoaded();
  }, [scrolledFarEnough, dispatch, orderSearchResults, resultDisplaySize, urlSearchParams]);

  useEffect(() => {
    if (percentageScrolled > 0.75) {
      setScrolledFarEnough(true);
    } else {
      setScrolledFarEnough(false);
    }
  }, [percentageScrolled]);

  useEffect(() => {
    setResultDisplaySize(50);
  }, [urlSearchParams]);

  useEffect(() => {
    if (orderSuggestions === null) {
      setOrderResults([]);
    } else {
      setOrderResults(orderSuggestions);
    }
  }, [orderSuggestions]);

  useEffect(() => {
    if (loadingStatus === 'pending') {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [loadingStatus]);

  return (
    <SearchBodyWrapper>
      <OrderPageContainer>
        <div className="section container-page all-contents-wrapper">
          <div className="container">
            <CustomerOrderNav location={COMMERCE_CORE_CONSTANTS.PATHNAMES.orderSearch} />
          </div>
          <div ref={ref}>
            <OrderTable orderSuggestions={orderSearchResults} isLoading={isLoading} />
          </div>
        </div>
      </OrderPageContainer>
    </SearchBodyWrapper>
  );
};
export default OrderSearchPage;
