import React from 'react';
import {selectUiSettings} from '../../../features/environment/slice/environment.slice';
import {UiSettings} from '../../../features/environment/IEnvironmentState';
import {useTypedSelector} from '../../../hooks/store';
import PasswordResetForm from './PasswordResetPageForm';
import {PasswordResetPageContainter} from './PasswordResetPage.styled';

const PasswordResetPage = () => {
  const uiSettings: UiSettings | null = useTypedSelector(selectUiSettings);

  const renderBackgroundImage = () => {
    if (uiSettings && uiSettings.loginPageContent) {
      return (
        <picture>
          <source media="(max-width: 1024px)" srcSet={uiSettings.loginPageContent.mobileImageUrl} />
          <source media="(max-width: 1500px)" srcSet={uiSettings.loginPageContent.tabletImageUrl} />
          <img src={uiSettings.loginPageContent.desktopImageUrl} alt="background" />
        </picture>
      );
    }
  };
  return (
    <PasswordResetPageContainter>
      <div className="background-content">{renderBackgroundImage()}</div>
      <div className="main-content">
        <div className="transparent-box user-login">
          <PasswordResetForm />
        </div>
        <div className="transparent-box customer-care">
          <div>
            Please contact Cambria Customer Care at{' '}
            <a href="tel:18662262742" target="_blank" rel="noopener noreferrer">
              1-866-CAMBRIA
            </a>{' '}
            (1-866-226-2742) with any questions on placing your online order.
          </div>
        </div>
      </div>
    </PasswordResetPageContainter>
  );
};

export default PasswordResetPage;
