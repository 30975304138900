import React, {useState} from 'react';
import {useLocation} from 'react-router-dom';
import Headroom from 'react-headroom';
import {HeaderContainer, MobileSearchBarContainer} from './Header.styled';
import HeaderSearch from './HeaderSearch';
import HeaderCDMP from './HeaderCDMP';
import HeaderNavigation from './HeaderNavigation';
import HeaderCheckout from './HeaderCheckout';
import MobileSidebar from './HeaderCDMP/MobileSidebar';
import HeaderUnathenticated from './HeaderUnathenticated';
import ProductSearch from './Search/ProductSearch';
import CustomerSearch from './Search/CustomerSearch';
import OrderSearch from './Search/OrderSearch';
import {orderSearchOrderFilterOptions} from './Search/OrderSearch/filterOptions';
import COMMERCE_CORE_CONSTANTS from '../../constants';

export const SearchBarToggle = ({url}: {url: string}) => {
  if (url.includes(COMMERCE_CORE_CONSTANTS.PATHNAMES.customerSearch)) {
    return <CustomerSearch />;
  } else if (url.includes(COMMERCE_CORE_CONSTANTS.PATHNAMES.orderSearch)) {
    return (
      <OrderSearch
        relativeUrl={COMMERCE_CORE_CONSTANTS.PATHNAMES.orderSearch}
        orderFilterOptions={orderSearchOrderFilterOptions}
      />
    );
  }
  return <></>;
};

const Header = () => {
  const location = useLocation();

  const [sidebarOn, setSidebarOn] = useState(false);

  const renderHeaderContent = () => {
    if (
      location.pathname.includes(COMMERCE_CORE_CONSTANTS.PATHNAMES.customerSearch) ||
      location.pathname.includes(COMMERCE_CORE_CONSTANTS.PATHNAMES.orderSearch)
    ) {
      return (
        <HeaderContainer id="react-bootstrap-hack" style={{height: 'fit-content'}}>
          <HeaderSearch />
          <SearchBarToggle url={window.location.href} />
        </HeaderContainer>
      );
    }
    if (location.pathname.includes('checkout')) {
      return (
        <HeaderContainer id="react-bootstrap-hack">
          <HeaderCheckout />
        </HeaderContainer>
      );
    }
    if (
      location.pathname === COMMERCE_CORE_CONSTANTS.PATHNAMES.login ||
      location.pathname === COMMERCE_CORE_CONSTANTS.PATHNAMES.passwordReset ||
      location.pathname === COMMERCE_CORE_CONSTANTS.PATHNAMES.confirmReset
    ) {
      return (
        <HeaderContainer id="react-bootstrap-hack" height={80} className="login-header">
          <HeaderUnathenticated />
        </HeaderContainer>
      );
    }

    return (
      <div id="react-bootstrap-hack">
        <div className="hidden-before-gfb">
          <HeaderContainer>
            <HeaderCDMP />
          </HeaderContainer>
          <HeaderNavigation />
          <ProductSearch />
        </div>
        <div className="hidden-after-gfb">
          <Headroom className={`w-100 ${sidebarOn ? 'fixed-on-top' : ''}`}>
            <HeaderContainer>
              <HeaderCDMP toggleSidebar={() => setSidebarOn(!sidebarOn)} closeSidebar={() => setSidebarOn(false)} />
            </HeaderContainer>
            <MobileSearchBarContainer>
              <ProductSearch />
            </MobileSearchBarContainer>
          </Headroom>
          <MobileSidebar
            sidebarOn={sidebarOn}
            toggleSidebar={() => setSidebarOn(!sidebarOn)}
            closeSidebar={() => setSidebarOn(false)}
          />
        </div>
      </div>
    );
  };

  return <div className="react-bootstrap-hack header">{renderHeaderContent()}</div>;
};

export default Header;
