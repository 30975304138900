export const orderHistoryStatusOptions = [
  'Order Received',
  'Preparing Shipment',
  'Partially Shipped',
  'Shipped',
  'Cancelled',
  'CCC Revising Order',
  'Order Revisions Needed',
  'CAD Revisions Needed',
  'Awaiting Order Approval',
  'CAD Approval Pending',
  'Sales Accepted',
];

export const orderSearchStatusOptions = [
  'AwaitingCadApproval',
  'AwaitingPoApproval',
  'Cancelled',
  'OrderReceived',
  'PartiallyShipped',
  'Pending',
  'PreparingShipment',
  'RejectedCAD',
  'RejectedPo',
  'RejectedPoCccRevisingOrder',
  'Shipped',
  'SubmitFailed',
  'SubmitSuccessful',
  'Submitting',
  'Verified',
];

export const orderSearchOrderFilterOptions = [
  'Details',
  'Amount',
  'Date',
  'End User Address',
  'End User First Name',
  'End User Last Name',
  'Favorites',
  'Job Number',
  'Order Number',
  'Order Type',
  'Purchase Order Number',
  'Status',
];

export const orderHistoryOrderFilterOptions = [
  'Details',
  'Amount',
  'Date',
  'End User Address',
  'End User First Name',
  'End User Last Name',
  'Favorites',
  'Purchase Order Number',
  'Status',
];

export const orderTypes = ['Fabricated', 'Samples/Store', 'Slab', 'Surfaces', 'Tile'];

export const validUrlParams = [
  'q',
  'endConsumerAddress1',
  'status',
  'purchaseOrderNumber',
  'orderType',
  'orderNumber',
  'favoriteName',
  'isFavorite',
  'endConsumerLastName',
  'endConsumerFirstName',
  'jobNumber',
  'amountRange.amountFrom',
  'amountRange.amountTo',
  'dateRange.dateFrom',
  'dateRange.dateTo',
  'statusCode',
];
