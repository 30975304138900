import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {URLSearchParams} from 'url';
import COMMERCE_CORE_CONSTANTS from '../../../../Core/constants';
import {baseApiCallWithReauth} from '../../../../Framework/api-utils/api-utils';
import {CustomerSearch} from '../../ISearchState';

export const initialState: CustomerSearch = {
  keyword: null,
  suggestions: [],
  numberOfResults: 0,
  loading: 'idle',
  currentRequestId: undefined,
  marketRepCustomer: undefined,
};

export const customerSearchAsync = createAsyncThunk(
  'customerSearch/customerSearchAsync',
  async (data: URLSearchParams) => {
    const endpoint = COMMERCE_CORE_CONSTANTS.API_SERVICES.CUSTOMER.customers;
    return await baseApiCallWithReauth('GET', endpoint, data);
  }
);
export const getDefaultMarketRepCustomer = createAsyncThunk(
  'customerSearch/getDefaultMarketRepCustomer',
  async (data: URLSearchParams) => {
    const endpoint = COMMERCE_CORE_CONSTANTS.API_SERVICES.CUSTOMER.customers;
    return await baseApiCallWithReauth('GET', endpoint, data);
  }
);

export const loadMoreCustomersAsync = createAsyncThunk(
  'customerSearch/customerSearachAsync',
  async (data: URLSearchParams) => {
    const endpoint = COMMERCE_CORE_CONSTANTS.API_SERVICES.CUSTOMER.customers;
    return await baseApiCallWithReauth('GET', endpoint, data);
  }
);

export const customerSearchSlice = createSlice({
  name: 'customerSearch',
  initialState,
  reducers: {
    setCustomerKeyword: (state, action) => {
      state.keyword = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(customerSearchAsync.pending, (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending';
      }
      state.currentRequestId = action.meta.requestId;
    });
    builder.addCase(customerSearchAsync.fulfilled, (state, action) => {
      state.loading = 'idle';
      state.suggestions = action.payload.results;
      if (!state.keyword && state.marketRepCustomer) {
        state.suggestions.unshift(state.marketRepCustomer);
      }
      state.numberOfResults = action.payload.totalResults;
      state.currentRequestId = undefined;
    });
    builder.addCase(getDefaultMarketRepCustomer.fulfilled, (state, action) => {
      state.marketRepCustomer = action.payload.results[0];
      if (!state.keyword && state.suggestions) {
        state.suggestions.unshift(state.marketRepCustomer);
      }
    });
    builder.addCase(loadMoreCustomersAsync.fulfilled, (state, action) => {
      let tempSuggestionsArray = JSON.parse(JSON.stringify(state.suggestions));
      tempSuggestionsArray.push(...action.payload.results);
      state.suggestions = tempSuggestionsArray;
      if (state.keyword === '' && state.marketRepCustomer) {
        state.suggestions.unshift(state.marketRepCustomer);
      }
    });
  },
});

export const {setCustomerKeyword} = customerSearchSlice.actions;
export default customerSearchSlice.reducer;
