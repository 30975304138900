import React, {useEffect, useState} from 'react';
import {toast} from 'react-toastify';
import COMMERCE_CORE_CONSTANTS from '../../../../../Core/constants';
import {selectCurrentUser} from '../../../../../features/auth/slice/authentication.slice';
import {selectCurrentCustomer} from '../../../../../features/customer/slice/customer.slice';
import {LoadReports} from '../../../../../features/customer/ICustomerState';
import {
  getShipmentReportEmailDataAsync,
  selectShipmentStatusReportEmailData,
  selectShipmentStatusReports,
  selectStatusReportIsLoading,
} from '../../../../../features/reports/slice/reports.slice';
import {baseApiCallWithReauth} from '../../../../../Framework/api-utils/api-utils';
import {useAppDispatch, useTypedSelector} from '../../../../../hooks/store';
import RequestAccessPage from '../Placeholders/RequestAccessPage';
import {LoadReportContainer} from './LoadReport.styled';
import TableHeader from '../ReportTables/TableHeader';
import TableItems from '../ReportTables/TableItems';
import {subscribeToReport, unsubscribeFromReport} from '../../../../../features/reports/controllers/reports.controller';

const LoadReport = ({hasPermission, endpoint}: {hasPermission: boolean; endpoint: string}) => {
  const dispatch = useAppDispatch();

  let loadReportInfo: any = useTypedSelector(selectShipmentStatusReports);
  let activeUser = useTypedSelector(selectCurrentUser);
  let isLoading = useTypedSelector(selectStatusReportIsLoading);
  let emailSubscriptionData = useTypedSelector(selectShipmentStatusReportEmailData);
  let [subscriptionLoading, setSubscriptionLoading] = useState<boolean>(false);
  let erpCustomerId = useTypedSelector(selectCurrentCustomer)?.erpCustomerId;

  const exportToExcel = async () => {
    const endpoint = `${COMMERCE_CORE_CONSTANTS.API_SERVICES.CUSTOMER.customers}/${erpCustomerId}${COMMERCE_CORE_CONSTANTS.API_SERVICES.CUSTOMER.downloadReportV2}`;
    let result = await baseApiCallWithReauth('GET', endpoint, null, true);
    let resultArrayBuffer = await result.arrayBuffer();
    const url = window.URL.createObjectURL(
      new Blob([resultArrayBuffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      })
    );
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'Report.xlsx');
    document.body.appendChild(link);
    link.click();
  };

  const handleEmailSubscription = async () => {
    setSubscriptionLoading(true);
    if (emailSubscriptionData?.length === 0) {
      try {
        const userNames = activeUser ? activeUser?.given_name.split(' ') : [];
        await subscribeToReport({
          email: activeUser?.email ?? '',
          firstName: userNames[0],
          lastName: userNames[1],
          userId: activeUser?.userId ?? '',
          type: 'LoadReport',
          erpCustomerId,
        });
        toast.success('Your user email has been successfully subscribed to the daily Shipment Status Report');
      } catch (e) {
        console.log(e);
      }
    } else if (emailSubscriptionData && emailSubscriptionData.length > 0) {
      try {
        await unsubscribeFromReport({
          erpCustomerId,
          email: emailSubscriptionData[0].email,
          subscriptionId: emailSubscriptionData[0].id,
        });
        toast.success('Your user email has been successfully unsubscribed from the daily Shipment Status Report');
      } catch (e) {
        console.log(e);
      }
    }
    if (erpCustomerId && activeUser?.userId) {
      dispatch(getShipmentReportEmailDataAsync({erpCustomerId: erpCustomerId, userId: activeUser.userId}));
    }
  };

  useEffect(() => {
    setSubscriptionLoading(false);
  }, [emailSubscriptionData]);

  useEffect(() => {
    if (erpCustomerId && !emailSubscriptionData && activeUser?.userId) {
      dispatch(getShipmentReportEmailDataAsync({erpCustomerId: erpCustomerId, userId: activeUser.userId}));
    }
  }, [erpCustomerId, emailSubscriptionData, activeUser, dispatch]);

  const LoadingScreen = () => {
    return (
      <LoadReportContainer>
        <div className="heading">
          <h1 className="statement-name-header">{'Loading...'}</h1>
        </div>
        <table className="table-condensed" id="load-reports-table">
          <TableHeader tableColumns={[]} />
          <TableItems reportLines={[]} tableColumns={[]} isLoading={true} />
        </table>
        <br></br>
      </LoadReportContainer>
    );
  };

  if (!hasPermission) {
    return <RequestAccessPage endpoint={endpoint} />;
  } else if (isLoading) {
    return <LoadingScreen />;
  } else if (!loadReportInfo) {
    return (
      <LoadReportContainer>
        <div className="no-results-page">
          There is no current activity to display at this time.
          {emailSubscriptionData ? (
            <button
              className={`subscription-btn ${
                emailSubscriptionData && emailSubscriptionData.length > 0 ? 'unsubscribe' : 'subscribe'
              }`}
              data-testid="subscribe-to-emails"
              onClick={() => handleEmailSubscription()}
              disabled={subscriptionLoading}
              children={
                emailSubscriptionData && emailSubscriptionData.length > 0 ? (
                  <>
                    <i className="fa fa-envelope" />
                    &nbsp; Unsubscribe
                  </>
                ) : (
                  <>
                    <i className="fa fa-envelope" />
                    &nbsp; Subscribe
                  </>
                )
              }
            />
          ) : (
            <></>
          )}
        </div>
      </LoadReportContainer>
    );
  } else {
    return (
      <LoadReportContainer>
        {loadReportInfo.shipFroms.map((loadReport: LoadReports, index: number) => {
          return (
            <div key={index}>
              <div className="heading">
                <h1 className="statement-name-header">{isLoading ? 'Loading...' : loadReport?.name}</h1>

                {!isLoading && index === 0 ? (
                  <div className="load-report-action-buttons">
                    {emailSubscriptionData ? (
                      <button
                        className={`subscription-btn ${
                          emailSubscriptionData && emailSubscriptionData.length > 0 ? 'unsubscribe' : 'subscribe'
                        }`}
                        data-testid="subscribe-to-emails"
                        onClick={() => handleEmailSubscription()}
                        disabled={subscriptionLoading}
                        children={
                          emailSubscriptionData && emailSubscriptionData.length > 0 ? (
                            <>
                              <i className="fa fa-envelope" />
                              &nbsp; Unsubscribe
                            </>
                          ) : (
                            <>
                              <i className="fa fa-envelope" />
                              &nbsp; Subscribe
                            </>
                          )
                        }
                      />
                    ) : (
                      <></>
                    )}
                    <button
                      data-testid="export-load-report-excel"
                      className="print-icon-button"
                      onClick={exportToExcel}>
                      <i className="fa fa-print" />
                    </button>
                  </div>
                ) : (
                  <></>
                )}
              </div>
              <table className="table-condensed" id="load-reports-table">
                <TableHeader tableColumns={loadReport?.displayFields ? loadReport.displayFields : []} />
                <TableItems
                  reportLines={loadReport?.lines ? loadReport.lines : []}
                  tableColumns={loadReport?.displayFields ? loadReport.displayFields : []}
                  isLoading={isLoading}
                />
              </table>
              <br></br>
            </div>
          );
        })}
      </LoadReportContainer>
    );
  }
};

export default LoadReport;
