export const formatPrice = (input: any) => {
  if (!input) {
    return input;
  }

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  let inputValue = input.toString();
  let leftDecimal = '0';
  let rightDecimal = '00';
  if (inputValue.length === 1) {
    rightDecimal = `0${inputValue}`;
  } else if (inputValue.length === 2) {
    rightDecimal = inputValue;
  } else if (inputValue.length > 2) {
    let splitValue = inputValue.split('.');

    if (splitValue.length === 2) {
      leftDecimal = splitValue[0];

      if (splitValue[1].length === 1) {
        rightDecimal = `${splitValue[1]}0`;
      } else {
        rightDecimal = splitValue[1].slice(0, 2);
      }
    } else {
      rightDecimal = inputValue.slice(-2);
      leftDecimal = inputValue.slice(0, -2);
    }
  }

  let n: any = Number(leftDecimal + '.' + rightDecimal).toFixed(2);
  const formattedPrice = formatter.format(n);
  return formattedPrice;
};
