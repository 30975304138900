import React from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import ContentLoader from 'react-content-loader';
import PurchaseAgreementContainer from './PurchaseAgreementPlaceholder.styled';

const PaCheckoutPlaceholders = () => {
  return (
    <PurchaseAgreementContainer>
      <Container>
        <Row>
          <Col className="placeholder-overflow-class placeholder-top-margin" lg={12}>
            <Col className="placeholder-overflow-class placeholder-bottom-margin p-x-0 col-lg-offset-1" lg={12}>
              <ContentLoader
                width={150}
                height={20}
                animate={true}
                foregroundColor={'#dfdfdf'}
                backgroundColor={'#dfdfdf'}>
                <rect x="0" y="0" width="150" height="20" />
              </ContentLoader>
            </Col>
            <br></br>
            <Col className="placeholder-overflow-class p-x-0  col-lg-7 col-lg-offset-1">
              <ContentLoader
                width={850}
                height={35}
                animate={true}
                foregroundColor={'#dfdfdf'}
                backgroundColor={'#dfdfdf'}>
                <rect x="0" y="0" width="850" height="35" />
              </ContentLoader>
            </Col>
            <Col className="placeholder-overflow-class p-x-0 col-lg-3 m-l-md">
              <ContentLoader
                width={250}
                height={35}
                animate={true}
                foregroundColor={'#dfdfdf'}
                backgroundColor={'#dfdfdf'}>
                <rect x="0" y="0" width="250" height="35" />
              </ContentLoader>
            </Col>
          </Col>
          <Col className="placeholder-overflow-class placeholder-top-margin" lg={12}>
            <Col className="placeholder-overflow-class placeholder-bottom-margin p-x-0 col-lg-offset-1" lg={12}>
              <ContentLoader
                width={150}
                height={20}
                animate={true}
                foregroundColor={'#dfdfdf'}
                backgroundColor={'#dfdfdf'}>
                <rect x="0" y="0" width="150" height="20" />
              </ContentLoader>
            </Col>
            <br></br>
            <Col className="placeholder-overflow-class p-x-0  col-lg-7 col-lg-offset-1">
              <ContentLoader
                width={850}
                height={35}
                animate={true}
                foregroundColor={'#dfdfdf'}
                backgroundColor={'#dfdfdf'}>
                <rect x="0" y="0" width="850" height="35" />
              </ContentLoader>
            </Col>
            <Col className="placeholder-overflow-class p-x-0 col-lg-3 m-l-md">
              <ContentLoader
                width={250}
                height={35}
                animate={true}
                foregroundColor={'#dfdfdf'}
                backgroundColor={'#dfdfdf'}>
                <rect x="0" y="0" width="250" height="35" />
              </ContentLoader>
            </Col>
          </Col>
        </Row>
        <Row>
          <Col className="placeholder-overflow-class placeholder-top-margin" lg={12}>
            <Col className="placeholder-overflow-class placeholder-bottom-margin p-x-0 col-lg-offset-1" lg={12}>
              <ContentLoader
                width={150}
                height={20}
                animate={true}
                foregroundColor={'#dfdfdf'}
                backgroundColor={'#dfdfdf'}>
                <rect x="0" y="0" width="150" height="20" />
              </ContentLoader>
            </Col>
            <br></br>
            <Col className="placeholder-overflow-class p-x-0  col-lg-7 col-lg-offset-1">
              <ContentLoader
                width={850}
                height={35}
                animate={true}
                foregroundColor={'#dfdfdf'}
                backgroundColor={'#dfdfdf'}>
                <rect x="0" y="0" width="850" height="35" />
              </ContentLoader>
            </Col>
          </Col>
        </Row>
        <Row>
          <Col className="placeholder-overflow-class col-lg-offset-8 col-lg-3 m-t-md m-b-md">
            <ContentLoader
              className="submit-button"
              width={300}
              height={35}
              animate={true}
              foregroundColor={'#dfdfdf'}
              backgroundColor={'#dfdfdf'}>
              <rect x="0" y="0" width="300" height="35" />
            </ContentLoader>
          </Col>
        </Row>
      </Container>
    </PurchaseAgreementContainer>
  );
};

export default PaCheckoutPlaceholders;
