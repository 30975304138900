import React, {useEffect, useState} from 'react';
import {OrderSearchParams} from '../../../../../../../features/search/ISearchState';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import {useHistory} from 'react-router-dom';
import {cleanSearchParams} from '../../../../../../../features/order/service/order.service';
import {formatOrderCurrency} from '../../../../../../../Framework/Services/formatCurrency';

const AmountInput = ({
  amountMin,
  amountMax,
  urlSearchParams,
  url,
}: {
  amountMin: number;
  amountMax: number;
  urlSearchParams: OrderSearchParams;
  url: string;
}) => {
  const history = useHistory();

  let [trackedAmounts, setTrackedAmounts] = useState([0, 1]);

  const min = formatOrderCurrency(trackedAmounts[0]);
  const max = formatOrderCurrency(trackedAmounts[1]);

  const addAmountParam = (currentReduxFilters: OrderSearchParams) => {
    let currentReduxCopy = {...currentReduxFilters};
    currentReduxCopy['amountRange.amountTo'] = trackedAmounts[1].toString();
    currentReduxCopy['amountRange.amountFrom'] = trackedAmounts[0].toString();

    let data = new URLSearchParams(currentReduxCopy);
    data = cleanSearchParams(data);
    history.push({
      pathname: url,
      search: '?' + data.toString(),
    });
  };

  useEffect(() => {
    setTrackedAmounts([amountMin, amountMax]);
  }, [amountMin, amountMax]);

  return (
    <div className="input-and-button-container">
      <div className="order-search-bar">
        <div className="min-label">${min}</div>
        <div className="max-label">${max}</div>
        <Slider
          allowCross={false}
          range
          min={amountMin}
          max={amountMax}
          defaultValue={[amountMin, amountMax]}
          onChange={(value: any) => setTrackedAmounts(value)}
        />
      </div>
      <div className="search-button-container">
        <button className="search-button btn-primary" onClick={() => addAmountParam(urlSearchParams)}>
          SEARCH
        </button>
      </div>
    </div>
  );
};
export default AmountInput;
