import React from 'react';
import {Col, Row} from 'react-bootstrap';
import EndUserAddressSearch from './EndUserAddressSearch';
import PurchasingCustomerDisplay from './PurchasingCustomer';

const CustomerInformationComponent = ({isEditDisabled = true}: {isEditDisabled?: boolean}) => {
  return (
    <>
      <Row className="mt-5">
        <Col xs={12}>
          <PurchasingCustomerDisplay isEditDisabled={isEditDisabled} />
        </Col>
      </Row>
      <Row className="mt-5">
        <Col xs={12} className="pl-2 pr-2">
          <EndUserAddressSearch isEditDisabled={isEditDisabled} />
        </Col>
      </Row>
    </>
  );
};

export default CustomerInformationComponent;
