import React, {FC, useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import {CambriaAlertContainer} from './CambriaAlerts.styled';
import COMMERCE_CORE_CONSTANTS from '../../../Core/constants';

interface CambriaAlertsProps {
  type: 'success' | 'warning' | 'info';
  msg: string | React.ReactNode;
  viewCart: boolean;
  setAlertData: any;
  timeout?: number;
  className?: string;
}

const CambriaAlerts: FC<CambriaAlertsProps> = ({type, msg, timeout, viewCart, setAlertData, className}) => {
  const history = useHistory();

  useEffect(() => {
    if (timeout) {
      setTimeout(() => {
        setAlertData([]);
      }, timeout);
    }
  }, [timeout, setAlertData]);

  return (
    <CambriaAlertContainer className={`col-xs-12 ${className}`}>
      <div className={`col-xs-12 alert-data-container alert-data-type-${type}`}>
        <div className="alert-data-msg-container">
          {msg} {'  '}
          {viewCart ? (
            <span
              className="alert-data-view-cart-button"
              onClick={() => {
                history.push(COMMERCE_CORE_CONSTANTS.PATHNAMES.cart);
              }}>
              View my cart.
            </span>
          ) : (
            <span></span>
          )}
        </div>
      </div>
    </CambriaAlertContainer>
  );
};

export default CambriaAlerts;
