import {useAppDispatch, useTypedSelector} from '../../../../hooks/store';
import {pushRemoveFromCartEventToDataLayer} from '../../../analytics/service/analytics.service';
import {callback} from '../../../callbacks/service/callbacks.service';
import {hideFullscreenLoader, showFullscreenLoader} from '../../../fullscreenLoader/slice/fullscreenLoader.slice';
import {deleteCartBundleById} from '../../controller/cart.controller';
import {selectActiveCart, setActiveCart} from '../../slice/cart.slice';
import {useGetCart} from '../useGetCart';

const CALLBACK_TIMEOUT = 30;

/**
 * Returns function to delete a bundle from the active cart,
 * handling loader, callback waiting, and GA4 events
 * @returns
 */
export const useRemoveCartBundle = (): ((bundleId: string) => Promise<void>) => {
  const cart = useTypedSelector(selectActiveCart);
  const dispatch = useAppDispatch();
  const getCart = useGetCart();

  return async (bundleId: string) => {
    if (!cart) return Promise.reject('No cart selected');
    dispatch(showFullscreenLoader({showCartIcon: true}));
    try {
      const callbackId = await deleteCartBundleById(cart?.id, bundleId);
      pushRemoveFromCartEventToDataLayer(
        cart.cartItems
          .filter((ci) => ci.bundleId?.toString() === bundleId)
          .map((cartItem) => ({
            cartItem,
            quantity: cartItem.quantity,
          }))
      );
      await callback(callbackId, CALLBACK_TIMEOUT, dispatch);
      const newCart = await getCart(cart.id);
      dispatch(setActiveCart(newCart));
    } catch (error) {
      console.error(error);
      throw error;
    } finally {
      dispatch(hideFullscreenLoader());
    }
  };
};
