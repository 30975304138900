import styled from 'styled-components/macro';
import DatePicker from 'react-datepicker';
import {$gray30, $newdanger, $newwhite, $gray78, $brandprimary} from '../../../Styled/variables';

export const CambriaDateInputContainer = styled.div`
  position: relative;
  font-size: 16px;
  line-height: 22px;
  padding-bottom: 17px;
  margin-bottom: 2px;

  button.cambria-datepicker-clear-btn::after {
    background-color: ${$brandprimary};
  }
`;

export const CambriaDateInputField = styled(DatePicker)`
  width: 100%;
  background-color: ${$newwhite} !important;
  padding: 8px 18px !important;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  font-size: 14px !important;
  line-height: 17px !important;
  font-weight: 500 !important;
  color: ${$gray30};
  transition: 0.5s all;
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:active,
  &:-webkit-autofill:focus {
    transition: background-color 5000s ease-in-out 0s;
  }

  &:disabled {
    -webkit-text-fill-color: ${$gray78} !important;
    color: ${$gray78} !important;
    cursor: not-allowed !important;
  }

  &.input-error {
    border-color: ${$newdanger} !important;
  }
`;

export const CambriaDateInputLabel = styled.label`
  font-weight: 700;
`;

export const CambriaDateInputError = styled.span`
  position: absolute;
  left: 0;
  bottom: -2px;
  font-size: 12px;
  font-weight: 500;
  color: ${$newdanger};
`;
