import React from 'react';
import {useHistory} from 'react-router-dom';
import NavContainer from './CustomerOrderNav.styled';
import COMMERCE_CORE_CONSTANTS from '../../../constants';

const CustomerOrderNav = ({location}: {location: string}) => {
  const history = useHistory();
  return (
    <NavContainer className="container">
      <div
        onClick={() => history.push(COMMERCE_CORE_CONSTANTS.PATHNAMES.customerSearch)}
        className={`link ripple ${location === COMMERCE_CORE_CONSTANTS.PATHNAMES.customerSearch ? 'active-link' : ''}`}>
        <span>SEARCH</span>&nbsp;
        <span>CUSTOMERS</span>
      </div>
      <div
        onClick={() => history.push(COMMERCE_CORE_CONSTANTS.PATHNAMES.orderSearch)}
        className={`link ripple ${location === COMMERCE_CORE_CONSTANTS.PATHNAMES.orderSearch ? 'active-link' : ''}`}>
        <span>SEARCH</span> &nbsp;
        <span>ORDERS</span>
      </div>
    </NavContainer>
  );
};
export default CustomerOrderNav;
