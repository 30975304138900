import React from 'react';
import {Accordion, Card, Col, Row} from 'react-bootstrap';
import {ITrackingNumber} from '../../../../../../features/order/orderDetails/IOrderDetailsState';
import useWindowDimensions from '../../../../../../hooks/getWindowDimensions';
import {CambriaShipmentTrackingContainer} from './CambriaShipmentTracking.styled';
import {CustomerFullProfile} from '../../../../../../features/customer/ICustomerState';
import {selectCurrentCustomer} from '../../../../../../features/customer/slice/customer.slice';
import {useTypedSelector} from '../../../../../../hooks/store';

const CambriaShipmentTracking = ({
  trackingNumbers,
  isSlabOrder,
}: {
  trackingNumbers: ITrackingNumber[];
  isSlabOrder?: boolean;
}) => {
  const {width} = useWindowDimensions();

  const customerCustomer: CustomerFullProfile | null = useTypedSelector(selectCurrentCustomer);

  if (width > 960) {
    return (
      <CambriaShipmentTrackingContainer>
        <Row className="mt-0">
          <Col xs={4} className="d-flex align-items-center justify-content-center p-3 tracking-heading">
            Tracking Number
          </Col>
          <Col xs={8} className="d-flex align-items-center justify-content-center p-3 tracking-heading">
            Items on Shipment
          </Col>
        </Row>
        <div className="tracking-number-table">
          {trackingNumbers.map((number, index) => {
            return (
              <Row className="table-row" key={index}>
                <Col xs={4} className="d-flex align-items-center justify-content-center p-1">
                  {isSlabOrder ? (
                    <a
                      href={`/pdfViewer/proofOfDelivery/${number.trackingNumber}/true?erpCustomerId=${customerCustomer.erpCustomerId}`}
                      target="_blank"
                      rel="noopener noreferrer">
                      {number.trackingNumber}
                    </a>
                  ) : (
                    <a href={number.trackingNumberUrl}>{number.trackingNumber}</a>
                  )}
                </Col>
                <Col xs={8} className="d-flex align-items-center justify-content-center p-1 flex-column">
                  {number.shippingItems.map((item, index) => {
                    return (
                      <Row className="mt-0 p-2" key={index}>
                        <Col>
                          {item.description} ({item.shippedQuantity})
                        </Col>
                      </Row>
                    );
                  })}
                </Col>
              </Row>
            );
          })}
        </div>
      </CambriaShipmentTrackingContainer>
    );
  } else {
    return (
      <CambriaShipmentTrackingContainer>
        <Row className="mt-0">
          <Col xs={12} className="d-flex align-items-center justify-content-center p-3 tracking-heading">
            Tracking Number
          </Col>
        </Row>
        {trackingNumbers.map((number, index) => {
          return (
            <Row className="mt-0" key={index}>
              <Accordion className="w-100 mt-3">
                <Card className="border-0 border-bottom-0 p-0">
                  <Card.Header className="card-header w-100 p-3 border-bottom-0">
                    <Accordion.Toggle
                      className="d-flex w-100 border-0 align-items-center justify-content-between bg-transparent"
                      eventKey="0">
                      <div className="d-flex justify-content-start align-items-center w-25">
                        <a className="ml-3" href={number.trackingNumberUrl}>
                          {number.trackingNumber}
                        </a>
                      </div>
                      <i className={`fa fa-chevron-down float-right`}></i>
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="0">
                    <Card.Body className="card-body d-flex align-items-center flex-column">
                      <span className="font-weight-bold shipment-accordion-heading">Items on Shipment</span>
                      {number.shippingItems.map((item, index) => {
                        return (
                          <span key={index}>
                            {item.description} ({item.shippedQuantity})
                          </span>
                        );
                      })}
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
            </Row>
          );
        })}
      </CambriaShipmentTrackingContainer>
    );
  }
};

export default CambriaShipmentTracking;
