import qs from 'qs';
import COMMERCE_CORE_CONSTANTS from '../../../Core/constants';
import {baseApiCallWithReauth} from '../../../Framework/api-utils/api-utils';
import {IGetUserResponse} from '../IUserState';

export const getUserByIdRequest = async (userId: string | number, erpCustomerId: string): Promise<IGetUserResponse> => {
  return await baseApiCallWithReauth(
    'GET',
    `${COMMERCE_CORE_CONSTANTS.API_SERVICES.USERMANAGEMENT.users}/${userId}`,
    qs.stringify({erpCustomerId: erpCustomerId})
  );
};
