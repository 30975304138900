import styled from 'styled-components/macro';
import {$gray97, $gray30} from '../../../../Styled/variables';

export const ContactCardContainer = styled.div`
  font-size: 14px;
  line-height: 22px;

  p {
    margin-bottom: 0 !important;
    color: ${$gray30};

    &.contact-name {
      font-weight: 700;
    }
  }

  .contact-actions {
    font-size: 14px;

    em {
      padding: 0 2px;
      cursor: pointer;
      font-size: 14px !important;
      &:hover {
        opacity: 0.6;
      }
    }
  }

  .contact-info {
    position: relative;
    font-weight: 700;
    background-color: ${$gray97};
    padding: 16px 12px;
    margin: 10px 0;

    &:first-of-type {
      margin-top: 30px;
    }

    .icon {
      margin-right: 10px;
    }

    .contact-info-text {
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .far.fa-clone {
      position: absolute;
      top: 6px;
      right: 6px;
      cursor: pointer;
    }
  }
`;
