import styled from 'styled-components/macro';
import {$newblack} from '../../../../../Styled/variables';

export const PaymentTermsInfoContainer = styled.div`
  background: rgba(0, 0, 0, 0.04);
  border-radius: 3px;
  padding: 34px;

  h3 {
    font-weight: 500;
    font-size: 28px;
    line-height: 44px;
    color: ${$newblack};
  }
`;
