import React, {useEffect, useState} from 'react';
import {Col, Form, Row} from 'react-bootstrap';
import {Input} from 'react-bootstrap-typeahead';
import {useHistory, useLocation, useParams} from 'react-router-dom';
import {toast} from 'react-toastify';
import COMMERCE_CORE_CONSTANTS from '../../../../../Core/constants';
import {selectCurrentUser} from '../../../../../features/auth/slice/authentication.slice';
import {Cart} from '../../../../../features/cart/ICartState';
import {
  getOrderDetailsInitialState,
  initialPaymentInfoCardData,
  IPaymentInfoCardData,
  IUserInfo,
} from '../../../../../features/order/IOrderState';
import {
  deleteOrderFavoriteByIdRequest,
  postOrderEmailRequest,
  postOrderFavoriteRequest,
} from '../../../../../features/order/controller/order.controller';
import {
  getOrderUserInfoAsync,
  loadOrderDetailsAsync,
  loadOrderFiles,
  resetOrderDetailsRedux,
  resetUserInfoRedux,
  selectOrderDetails,
  selectOrderDetailsRefreshInProgress,
  selectUserInfo,
} from '../../../../../features/order/slice/order.slice';
import {selectUserActions} from '../../../../../features/permission/slice/permission.slice';
import CambriaModal from '../../../../../Framework/Components/CambriaModal';
import Icon from '../../../../../Framework/Components/Icon';
import {useAppDispatch, useTypedSelector} from '../../../../../hooks/store';
import AddressCard from './AddressCards';
import {initialAddressCard, LineItem, orderHeaderInitial} from './IOrderDetails';
import OrderDetailsHeader from './OrderHeader';
import {IOrderHeaderData} from './OrderHeader/IOrderHeader';
import ProductTable from '../../../../../Core/Components/CambriaProductTable';
import OrderDetailsContainer from './OrderDetails.styled';
import JobOrderTypeRow from './JobOrderType';
import {selectActiveCart} from '../../../../../features/cart/slice/cart.slice';
import {
  selectCurrentCustomer,
  selectCurrentCustomerBillToSites,
  selectCurrentCustomerShipToSites,
} from '../../../../../features/customer/slice/customer.slice';
import {
  BillToSite,
  CustomerFullProfile,
  NewCustomerFullProfile,
  ShipToSite,
  Site,
} from '../../../../../features/customer/ICustomerState';
import {selectActiveProgram, selectAvailablePrograms} from '../../../../../features/salesforce/slice/salesforce.slice';
import {Program} from '../../../../../features/salesforce/program/IProgramState';
import {
  selectAvailableWarehouses,
  selectSelectedWarehouse,
  setSelectedWarehouse,
} from '../../../../../features/warehouse/slice/warehouse.slice';
import DealerEndUser from './DealerEndUser';
import {getAllAvailableCountries} from '../../../../../features/location/slice/location.slice';
import FabricationCart from './FabricationCart/FabricationCart.component';
import {hasPermission} from '../../../../../store/permission/permission.service';
import PaymentInfoCard from './PaymentInfoCard/PaymentInfoCard.component';
import OrderSummaryComponent from '../../../../../Core/Components/OrderSummary/OrderSummary.component';
import CambriaShipmentTracking from './CambriaShipmentTracking/CambriaShipmentTracking.component';
import {getOrderShippingWarehouseName} from '../../../../../features/warehouse/service/warehouse.service';
import {getUserByIdRequest} from '../../../../../features/user/controller/user.controller';
import {ISalesforceAvailablePrograms} from '../../../../../features/salesforce/ISalesforceState';
import {isEmail} from 'react-multi-email';
import ConfirmationModal from '../../../../../Core/Components/Modals/ConfirmationModal/ConfirmationModal.component';
import {reorder, resubmitOrder} from '../../../../../features/cart/service/cart.service';
import {
  checkIfHasCIAReorderablePermission,
  checkIfHasCIAResubmittablePermission,
  checkIfHasCIARevisablePermission,
  checkIfHasPermissionsToSeeJobNumber,
  getCanEditPurchasingCustomers,
  getContactName,
  getIsEditReorderable,
  getIsRemovable,
  getIsReorderable,
  getIsResubmittable,
  getIsShippedOrCanceled,
  getLineItemsForOrderProductTable,
  getOrderHeaderData,
  getShouldUseOrderMetadata,
  isFabricatedOrder,
  removeOrder,
  setSelectedProgram,
} from '../../../../../features/order/orderDetails/service/orderDetails.service';
import {
  IAddressCardData,
  IOrderDetails,
  ITrackingNumber,
} from '../../../../../features/order/orderDetails/IOrderDetailsState';
import {getDiscounts, postCartVoucherCodes} from '../../../../../features/pricing/service/pricing.service';
import {
  selectFabLineItemsLimit,
  selectIsOrderDetailPdfEnabled,
  selectUiSettings,
} from '../../../../../features/environment/slice/environment.slice';
import {getQRCodeByCustomer} from '../../../../../features/lastMile/controller/lastmile.controller';
import {getOrderDetailsPdfRequest} from '../../../../../features/order/orderDetails/controller/orderDetails.controller';

const OrderDetailsPage = () => {
  let dispatch = useAppDispatch();
  let {orderid} = useParams<{orderid: string}>();
  let location = useLocation();
  const endpoint = COMMERCE_CORE_CONSTANTS.API_SERVICES.ORDER.orders;
  let history = useHistory();
  let confirmModalOptions = {
    heading: 'Are you editing a “Sales Accepted” order?',
    body: (
      <div>
        <p>
          This checkbox is for edits to sales accepted (approved) orders. You do not need to check this for order
          revisions. If you are looking to edit an existing order, it is expected you have called the fab shop and
          confirmed this to put the order on hold.
        </p>
        <br />
        <p>California - 760-343-0014</p>
        <p>Cleveland - 330-677-4010</p>
        <p>Indiana - 317-894-2388</p>
        <p>Minnesota - 507-665-2007</p>
        <p>Toronto - 905-951-1011</p>
      </div>
    ),
    cancelButton: 'Cancel',
    confirmButton: `I'm Editing a Sales Accepted Order`,
    onSubmit: () => {
      processReorder(true, true);
    },
  };

  let [erpCustomerId, setErpCustomerId] = useState<string>('');
  let [orderLineItems, setOrderLineItems] = useState<LineItem[]>([]);
  let [emailButtonDisabled, setEmailButtonDisabled] = useState<boolean>(false);
  let [isConfirmModalOpen, setIsConfirmModalOpen] = useState<boolean>(false);
  let [resubmitPermission, setResubmitPermission] = useState<boolean>(false);
  let [reorderPermission, setReorderPermission] = useState<boolean>(false);
  let [revisePermission, setRevisePermission] = useState<boolean>(false);
  let [removePermission, setRemovePermission] = useState<boolean>(false);
  let [canSeeJobNumber, setCanSeeJobNumber] = useState<boolean>(false);
  let [CIAResubmittablePermission, setCIAResubmittablePermission] = useState<boolean>(false);
  let [CIARevisablePermission, setCIARevisablePermission] = useState<boolean>(false);
  let [CIAReorderablePermission, setCIAReorderablePermission] = useState<boolean>(false);
  let [shouldUseOrderMetaData, setShouldUseOrderMetaData] = useState<boolean>(false);
  let [orderHeaderData, setOrderHeaderData] = useState<IOrderHeaderData>(() => orderHeaderInitial());
  let [shipToAddressInfo, setShipToAddressInfo] = useState<IAddressCardData>(initialAddressCard);
  let [billingAddressInfo, setBillingInfo] = useState<IAddressCardData>(initialAddressCard);
  let [paymentInfoCardData, setPaymentInfoCardData] = useState<IPaymentInfoCardData>(initialPaymentInfoCardData);
  let [currencyCode, setCurrencyCode] = useState<string>('USD');
  let [isFavorite, setIsFavorite] = useState<boolean>(false);
  let [shouldRefreshOrderHeaderDetails, setShouldRefreshOrderHeaderDetails] = useState<boolean>(false);
  let [reorderLoading, setReorderLoading] = useState<boolean>(false);
  let [resubmitLoading, setResubmitLoading] = useState<boolean>(false);
  let [showConfirmRemoval, setShowConfirmRemoval] = useState<boolean>(false);
  const toggleShow = () => setShowConfirmRemoval((p) => !p);
  let [showFavoriteInput, toggleFavoriteInput] = useState<boolean>(false);
  let [favoriteInputText, setFavoriteInputText] = useState<string>('');
  let [showEmailInput, toggleEmailInput] = useState<boolean>(false);
  let [emailInputText, setEmailInputText] = useState<string>('');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  let [isHomeDepotProgram, setIsHomeDepotProgram] = useState<boolean>(false);
  let [shippedFromWarehouseData, setShippedFromWarehouseData] = useState('');
  let [pendingStatusFileSearchRan, setPendingStatusFileSearchRan] = useState(false);
  let [orderDetailsWithDiscounts, setOrderDetailsWithDiscounts] = useState<IOrderDetails>(
    getOrderDetailsInitialState()
  );
  let [qrCode, setQrCode] = useState<string>('');
  let [slabProofOfDelivery, setSlabProofOfDelivery] = useState<ITrackingNumber[]>([]);
  let [pdfDownloading, setPdfDownloading] = useState<boolean>(false);

  const userActions: string[] = useTypedSelector(selectUserActions);
  const orderDetails: IOrderDetails = useTypedSelector(selectOrderDetails);
  const userInfo: IUserInfo = useTypedSelector(selectUserInfo);
  const loggedInUserId = useTypedSelector(selectCurrentUser)?.userId;
  const userEmail = useTypedSelector(selectCurrentUser)?.email;
  const customerInfo: CustomerFullProfile | null = useTypedSelector(selectCurrentCustomer);
  const currentUser: any = useTypedSelector(selectCurrentUser);
  const activeCart: Cart | null = useTypedSelector(selectActiveCart);
  const availablePrograms: ISalesforceAvailablePrograms | null = useTypedSelector(selectAvailablePrograms);
  const activeProgram: Program = useTypedSelector(selectActiveProgram);
  const selectedWarehouseCode = useTypedSelector(selectSelectedWarehouse);
  const currentCustomerShipToSites: Site[] | null = useTypedSelector(selectCurrentCustomerShipToSites);
  const currentCustomerBillToSites: BillToSite[] | null = useTypedSelector(selectCurrentCustomerBillToSites);
  const orderDetailsRefreshInProgress: boolean = useTypedSelector(selectOrderDetailsRefreshInProgress);
  const availableWarehouses: ShipToSite[] = useTypedSelector(selectAvailableWarehouses);
  const selectedShipTo: ShipToSite | null = useTypedSelector(selectSelectedWarehouse);
  const uiSettings = useTypedSelector(selectUiSettings);
  const lineItemsLimit = useTypedSelector(selectFabLineItemsLimit);
  const isOrderDetailPdfEnabled = useTypedSelector(selectIsOrderDetailPdfEnabled);

  const hasOrderLineItems = orderDetails?.orderLineItems?.length > 0;
  const isFabOrder =
    hasOrderLineItems && isFabricatedOrder(orderDetails.orderLineItems[0].productType, orderDetails.orderType);
  const isSlabOrder = hasOrderLineItems && !isFabOrder && orderDetails.orderLineItems[0]?.productType === 'Slab';
  const canEditPurchasingCustomers = hasOrderLineItems && getCanEditPurchasingCustomers(orderDetails.orderLineItems[0]);
  const isShippedOrCanceled = orderDetails && orderDetails.totalAmount > 0 && getIsShippedOrCanceled(orderDetails);

  const calculateShippingAndHandlingWithOrderMetadata = () => {
    if (orderDetails?.orderMetadata?.orderSummaryData?.totalShippingAmount) {
      if (orderDetails.orderMetadata?.orderSummaryData?.totalShippingDiscountAmount) {
        return (
          orderDetails.orderMetadata.orderSummaryData.totalShippingAmount -
          orderDetails.orderMetadata.orderSummaryData.totalShippingDiscountAmount
        );
      }
      return orderDetails.orderMetadata.orderSummaryData.totalShippingAmount;
    }

    return 0;
  };

  const calculateShippingAndHandling = () => {
    if (orderDetails?.orderMetadata && orderDetails.orderMetadata.orderSummaryData) {
      return calculateShippingAndHandlingWithOrderMetadata();
    }

    if (orderDetails?.totalShippingAmount || orderDetails.totalShippingAmount === 0) {
      if (orderDetails.totalShippingDiscountAmount) {
        return orderDetails.totalShippingAmount - orderDetails.totalShippingDiscountAmount;
      }

      return orderDetails.totalShippingAmount;
    }

    return 0;
  };

  const changeWarehouseIfNeeded = () => {
    const warehouse = selectedShipTo;
    if (warehouse && warehouse.warehouseCode !== orderDetails?.warehouse) {
      const newWarehouse = availableWarehouses.find((x) => x.warehouseCode === orderDetails.warehouse);
      dispatch(setSelectedWarehouse(newWarehouse));
      window.sessionStorage.setItem(COMMERCE_CORE_CONSTANTS.WINDOW_SESSION.selectedWarehouse, orderDetails.warehouse);
      return newWarehouse;
    }
  };

  const confirmRemoveOrder = async (orderNumber: string) => {
    await removeOrder(orderNumber);
    toggleShow();
    history.push('/account/orders');
  };

  const sendFavorite = async (orderNumber: string, favoriteName: string) => {
    const result = await postOrderFavoriteRequest(orderNumber, favoriteName);
    reloadData();

    if (result.status === 202) {
      toast.success('Favorite saved');
      setIsFavorite(true);
      toggleFavoriteInput(false);
      setFavoriteInputText('');
    }
  };

  const removeOrderFavorite = async () => {
    let favorite = orderDetails?.favorites?.find((fav) => fav.userId === loggedInUserId);
    let id = favorite?.id;
    if (id) {
      let result = await deleteOrderFavoriteByIdRequest(id);
      if (result.status === 202) {
        orderHeaderData.orderFavoriteName = '';
        toast.success('Favorite Removed Successfully');
        setIsFavorite(false);
      }
    } else {
      toast.error('Favorite Not Found');
    }
  };

  const reloadData = () => {
    let orderId = orderid;
    let data = new URLSearchParams();
    dispatch(loadOrderDetailsAsync({data: data, orderId: orderId, useHistory: history}));
  };

  const sendEmail = async () => {
    setEmailButtonDisabled(true);
    if (
      orderDetails?.orderNumber &&
      emailInputText &&
      orderDetails.erpCustomerId &&
      window.location.href.includes('?erpCustomerId=')
    ) {
      await postOrderEmailRequest(
        orderDetails.orderNumber,
        emailInputText,
        orderDetails.erpCustomerId,
        window.location.href.includes('?erpCustomerId=')
          ? window.location.href
          : `${window.location.href}?erpCustomerId=${orderDetails.erpCustomerId}`
      );
    } else {
      toast.error('Unable to send email');
    }
    toast.success('Email sent');
    toggleEmailInput(false);
    setEmailButtonDisabled(false);
  };

  const processReorder = async (isEditReorder?: boolean, isRevise?: boolean) => {
    if (lineItemsLimit && isFabOrder && orderDetails.orderLineItems.length >= lineItemsLimit) {
      toast.error(COMMERCE_CORE_CONSTANTS.ERROR_MESSAGES.REORDER_LINE_ITEMS_OVER_LIMIT);
      return;
    }

    const newWarehouse = changeWarehouseIfNeeded();
    try {
      if (isRevise) {
        setResubmitLoading(true);
      } else {
        setReorderLoading(true);
      }
      if (isFabOrder) {
        if (activeCart?.cartItems.length !== 0) {
          toast.error(
            'This Fabrication order cannot be combined with the items that are present in your cart.' +
              ' Please delete your cart or complete your purchase before initiating this action.'
          );
          return;
        }
      }
      const {hasSelectedProgram, programSelected} = await setSelectedProgram(
        orderDetails.lastCartId,
        customerInfo ? customerInfo : NewCustomerFullProfile(),
        loggedInUserId ? loggedInUserId.toString() : '',
        activeProgram,
        currentCustomerShipToSites ? currentCustomerShipToSites : [],
        currentCustomerBillToSites ? currentCustomerBillToSites : [],
        dispatch,
        availablePrograms
      );
      if (!hasSelectedProgram) {
        toast.error('The order cannot be revised, because you are no longer associated to that sales program.');
        return;
      }
      const transactionType = isFabOrder && isEditReorder ? 'CartRevision' : 'CartReorder';

      await reorder(
        orderDetails.orderNumber,
        orderDetails.lastCartId,
        activeCart ? activeCart.id : '',
        transactionType,
        customerInfo ? customerInfo : NewCustomerFullProfile(),
        currentUser?.userId,
        availablePrograms,
        programSelected,
        newWarehouse?.warehouseCode ? newWarehouse.warehouseCode : selectedWarehouseCode?.warehouseCode || null,
        currentCustomerShipToSites ? currentCustomerShipToSites : [],
        currentCustomerBillToSites ? currentCustomerBillToSites : [],
        orderDetails.orderLineItems[0].operatingUnitCode,
        dispatch
      );
      toast.success('Items were successfully added to cart.');
      setTimeout(() => {
        if (isFabOrder) {
          history.push(COMMERCE_CORE_CONSTANTS.PATHNAMES.fabrication);
        } else {
          history.push(COMMERCE_CORE_CONSTANTS.PATHNAMES.cart);
        }
      }, 2000);
    } catch (e) {
      console.log(e);
      toast.error("Couldn't reorder the products from this order at this time.");
    } finally {
      setReorderLoading(false);
      setResubmitLoading(false);
    }
  };

  const processResubmitOrder = async () => {
    if (lineItemsLimit && isFabOrder && orderDetails.orderLineItems.length >= lineItemsLimit) {
      toast.error(COMMERCE_CORE_CONSTANTS.ERROR_MESSAGES.REORDER_LINE_ITEMS_OVER_LIMIT);
      return;
    }
    const newWarehouse = changeWarehouseIfNeeded();
    try {
      setResubmitLoading(true);
      const lastCartId = orderDetails.lastCartId;
      const orderNumber = orderDetails.orderNumber;
      toast.info('Rebuilding this order may take several minutes depending on the number of items.');
      const {hasSelectedProgram, programSelected} = await setSelectedProgram(
        lastCartId,
        customerInfo ? customerInfo : NewCustomerFullProfile(),
        loggedInUserId ? loggedInUserId.toString() : '',
        activeProgram,
        currentCustomerShipToSites ? currentCustomerShipToSites : [],
        currentCustomerBillToSites ? currentCustomerBillToSites : [],
        dispatch,
        availablePrograms
      );
      if (!hasSelectedProgram) {
        toast.error('The order cannot be revised, because you are no longer associated to that sales program.');
        return;
      }
      var currentCart = await resubmitOrder(
        orderNumber,
        lastCartId,
        customerInfo ? customerInfo : NewCustomerFullProfile(),
        loggedInUserId ? Number(loggedInUserId) : 0,
        availablePrograms,
        programSelected,
        newWarehouse?.warehouseCode ? newWarehouse?.warehouseCode : selectedWarehouseCode?.warehouseCode || null,
        currentCustomerShipToSites ? currentCustomerShipToSites : [],
        currentCustomerBillToSites ? currentCustomerBillToSites : [],
        dispatch
      );
      const voucherCodes = await getDiscounts(
        customerInfo ? customerInfo : NewCustomerFullProfile(),
        undefined,
        undefined,
        undefined,
        undefined,
        lastCartId
      );
      const uniqueVoucherCodes: any[] = [];
      await Promise.all(
        voucherCodes.map(async (voucherCode: any) => {
          if (uniqueVoucherCodes.findIndex((x) => x.codeString === voucherCode.codeString) <= -1) {
            uniqueVoucherCodes.push(voucherCode);
            await postCartVoucherCodes(
              voucherCode.codeString,
              currentCart ? currentCart.id.toString() : '',
              customerInfo ? customerInfo : NewCustomerFullProfile(),
              dispatch
            );
          }
        })
      );
      if (
        orderDetails.orderLineItems[0].cartItemType === 'FabAndInstallDiscrete' ||
        orderDetails.orderLineItems[0].cartItemType === 'FabAndInstallStandard'
      ) {
        history.push(COMMERCE_CORE_CONSTANTS.PATHNAMES.fabrication);
      } else {
        history.push(COMMERCE_CORE_CONSTANTS.PATHNAMES.cart);
      }
    } catch (e: any) {
      console.log(e);
      toast.error(`Failed to resubmit order #${orderDetails.orderNumber}`);
    } finally {
      setResubmitLoading(false);
    }
  };

  const getOrderWarehouseCode = (order: IOrderDetails): string => {
    let warehouseCode = order.warehouse;
    if (
      warehouseCode === null &&
      order.orderLineItems &&
      order.orderLineItems.length > 0 &&
      order.orderLineItems[0].shipFromWarehouseCode
    ) {
      warehouseCode = order.orderLineItems[0].shipFromWarehouseCode;
    }
    return warehouseCode;
  };

  useEffect(() => {
    return () => {
      dispatch(resetOrderDetailsRedux());
      dispatch(resetUserInfoRedux());
    };
  }, [dispatch]);

  useEffect(() => {
    if (orderDetails?.erpCustomerId && orderDetails.files.length === 0 && !pendingStatusFileSearchRan) {
      dispatch(loadOrderFiles({erpCustomerId: erpCustomerId, lastCartId: orderDetails.lastCartId}));
      setPendingStatusFileSearchRan(true);
    }
  }, [dispatch, erpCustomerId, orderDetails, pendingStatusFileSearchRan]);

  useEffect(() => {
    if (orderDetails?.orderNumber) {
      setShouldRefreshOrderHeaderDetails(true);
    }
  }, [orderDetails]);

  useEffect(() => {
    dispatch(getAllAvailableCountries());
  }, [dispatch]);

  useEffect(() => {
    if (userActions?.length > 0) {
      setResubmitPermission(hasPermission('urn:csa:commerceui:order:resubmit', userActions));
      setReorderPermission(hasPermission('urn:csa:commerceui:reorder', userActions));
      setRevisePermission(hasPermission('urn:csa:commerceui:editOrder', userActions));
      setRemovePermission(hasPermission('urn:csa:commerceui:removeOrder', userActions));
      setCanSeeJobNumber(checkIfHasPermissionsToSeeJobNumber(userActions));
      setCIAResubmittablePermission(checkIfHasCIAResubmittablePermission(userActions));
      setCIARevisablePermission(checkIfHasCIARevisablePermission(userActions));
      setCIAReorderablePermission(checkIfHasCIAReorderablePermission(userActions));
    }
  }, [userActions]);

  useEffect(() => {
    const getErpCustomerId = (searchQuery: string) => {
      let paramsFromUrl = new URLSearchParams(searchQuery);
      let customerIdValue = paramsFromUrl.get('erpCustomerId');
      return customerIdValue ? customerIdValue : 'none';
    };
    const loadOrderData = (orderId: string, searchQuery: string) => {
      let erpCustomerId = getErpCustomerId(searchQuery);
      setErpCustomerId(erpCustomerId);
      let data = new URLSearchParams();
      if (erpCustomerId !== 'none') {
        data.append('erpCustomerId', erpCustomerId);
      }
      dispatch(loadOrderDetailsAsync({data: data, orderId: orderId, useHistory: history}));
    };
    if (erpCustomerId === '') {
      loadOrderData(orderid, location.search);
    }
  }, [endpoint, orderid, orderDetails, erpCustomerId, location, dispatch, history]);

  useEffect(() => {
    const mapOrderLineItems = async () => {
      const mappedOrderLines = await getLineItemsForOrderProductTable(
        orderDetails,
        uiSettings?.calculateCartDiscountsInBackend ?? false
      );
      setOrderLineItems(mappedOrderLines);

      const copyOfOrder = JSON.parse(JSON.stringify(orderDetails));
      copyOfOrder.orderLineItems = mappedOrderLines;
      setOrderDetailsWithDiscounts(copyOfOrder);
    };

    if (orderDetails?.orderLineItems?.length > 0) {
      mapOrderLineItems();
    }
  }, [orderDetails, uiSettings]);

  useEffect(() => {
    const getErpCustomerId = (searchQuery: string) => {
      let paramsFromUrl = new URLSearchParams(searchQuery);
      let customerIdValue = paramsFromUrl.get('erpCustomerId');
      return customerIdValue ? customerIdValue : 'none';
    };
    const loadUserInfo = async () => {
      let erpCustomerId = getErpCustomerId(location.search);
      let data = new URLSearchParams();
      data.append('erpCustomerId', erpCustomerId);
      let userId = orderDetails.userId;
      dispatch(getOrderUserInfoAsync({data, userId}));
    };
    if (orderDetails?.userId) {
      loadUserInfo();
    }
  }, [orderDetails, dispatch, location.search]);

  useEffect(() => {
    const organizeHeaderData = async () => {
      let shouldUseOrderMetaData = getShouldUseOrderMetadata(orderDetails);
      setShouldUseOrderMetaData(shouldUseOrderMetaData);
      let orderCreatedBy;
      if (orderDetails.orderCreatedById) {
        orderCreatedBy = await getUserByIdRequest(orderDetails.orderCreatedById, erpCustomerId);
      }
      let orderHeaderMetaData = getOrderHeaderData(
        orderDetails,
        userInfo.displayName || orderDetails.billToName,
        orderCreatedBy?.displayName || '',
        isFabOrder,
        shouldUseOrderMetaData,
        loggedInUserId ? Number(loggedInUserId) : 0
      );
      setOrderHeaderData(orderHeaderMetaData);
      if (orderHeaderMetaData.orderFavoriteName) {
        setIsFavorite(true);
      }
    };

    if (shouldRefreshOrderHeaderDetails && (userInfo.displayName || orderDetails?.billToName) && erpCustomerId) {
      organizeHeaderData();
      setShouldRefreshOrderHeaderDetails(false);
    }
  }, [
    shouldRefreshOrderHeaderDetails,
    userInfo,
    isFabOrder,
    loggedInUserId,
    orderDetails,
    orderHeaderData,
    erpCustomerId,
  ]);

  useEffect(() => {
    const getShippingAddressInfo = (order: IOrderDetails): IAddressCardData => {
      if (shouldUseOrderMetaData && order.orderMetadata && order.orderMetadata.shippingAddressCardData) {
        return {
          contactName: getContactName(order),
          locationName: order.orderMetadata.shippingAddressCardData.locationName,
          addressLine1: order.orderMetadata.shippingAddressCardData.addressLine1,
          addressLine2: order.orderMetadata.shippingAddressCardData.addressLine2,
          addressLine3: order.orderMetadata.shippingAddressCardData.addressLine3,
          city: order.orderMetadata.shippingAddressCardData.city,
          stateProvince: order.orderMetadata.shippingAddressCardData.stateProvince,
          postalCode: order.orderMetadata.shippingAddressCardData.postalCode,
          country: order.orderMetadata.shippingAddressCardData.country,
          email: order.orderMetadata.shippingAddressCardData.email,
          phone: order.orderMetadata.shippingAddressCardData.phone,
          type: 'SHIP-TO ADDRESS',
          icon: 'glyphicon glyphicon-globe',
          status: order.orderMetadata.shippingAddressCardData.status,
        };
      } else {
        let lineItem1 = order.orderLineItems[0];
        return {
          contactName: getContactName(order),
          locationName: lineItem1.shipToCustomerName,
          addressLine1: lineItem1.shipToAddress1,
          addressLine2: lineItem1.shipToAddress2,
          addressLine3: lineItem1.shipToAddress3,
          city: lineItem1.shipToCity,
          stateProvince: lineItem1.shipToStateProvinceCode,
          postalCode: lineItem1.shipToPostalCode,
          country: lineItem1.shipToCountryCode,
          email: order.shipToContactEmail,
          phone: order.shipToContactPhone,
          type: 'SHIP-TO ADDRESS',
          icon: 'glyphicon glyphicon-globe',
          status: '',
        };
      }
    };

    const getBillingAddressInfo = (order: IOrderDetails): IAddressCardData => {
      if (shouldUseOrderMetaData && order.orderMetadata && order.orderMetadata.billingAddressCardData) {
        return {
          type: 'BILL-TO ADDRESS',
          status: '',
          icon: 'glyphicon glyphicon-credit-card',
          contactName: '',
          locationName: order.orderMetadata.billingAddressCardData.locationName,
          addressLine1: order.orderMetadata.billingAddressCardData.addressLine1,
          addressLine2: order.orderMetadata.billingAddressCardData.addressLine2,
          addressLine3: order.orderMetadata.billingAddressCardData.addressLine3,
          email: order.orderMetadata.billingAddressCardData.email,
          phone: order.orderMetadata.billingAddressCardData.phone,
          city: order.orderMetadata.billingAddressCardData.city,
          stateProvince: order.orderMetadata.billingAddressCardData.stateProvince,
          postalCode: order.orderMetadata.billingAddressCardData.postalCode?.toString(),
          country: order.orderMetadata.billingAddressCardData.country,
        };
      } else {
        return {
          type: 'BILL-TO ADDRESS',
          status: '',
          icon: 'glyphicon glyphicon-credit-card',
          contactName: '',
          locationName: order.billToName,
          addressLine1: order.billToAddress1,
          addressLine2: order.billToAddress2,
          addressLine3: order.billToAddress3,
          email: '',
          phone: '',
          city: order.billToCity,
          stateProvince: order.billToStateProvinceCode,
          postalCode: order.billToPostalCode?.toString(),
          country: order.billToCountryCode,
        };
      }
    };

    const getPaymentCardInfo = (order: IOrderDetails): IPaymentInfoCardData => {
      if (shouldUseOrderMetaData && order.orderMetadata && order.orderMetadata.paymentInfoCardData) {
        setCurrencyCode(order.orderMetadata.paymentInfoCardData.currencyCode);
        return {
          paymentTerms: order.orderMetadata.paymentInfoCardData.paymentTerms,
          currencyCode: order.orderMetadata.paymentInfoCardData.currencyCode,
          paymentType: order.orderMetadata.paymentInfoCardData.paymentType,
          totalAmount: order.orderMetadata.paymentInfoCardData.totalAmount,
        };
      } else {
        setCurrencyCode(order.currencyCode);
        return {
          paymentTerms: order.paymentTerms,
          currencyCode: order.currencyCode,
          paymentType: order.paymentType,
          totalAmount: order.totalAmount,
        };
      }
    };

    if (orderDetails?.orderLineItems?.length > 0) {
      setShipToAddressInfo(getShippingAddressInfo(orderDetails));
      setBillingInfo(getBillingAddressInfo(orderDetails));
      setPaymentInfoCardData(getPaymentCardInfo(orderDetails));
    }
  }, [orderDetails, shouldUseOrderMetaData]);

  useEffect(() => {
    const checkIfHomeDepotProgram = (code: string) => {
      return code === 'HD001';
    };
    if (activeProgram && activeProgram.code) {
      setIsHomeDepotProgram(checkIfHomeDepotProgram(activeProgram.code));
    }
  }, [activeProgram]);

  useEffect(() => {
    const loadWarehouseData = async () => {
      const warehouseCode = getOrderWarehouseCode(orderDetails);
      if (warehouseCode) {
        const warehouse = await getOrderShippingWarehouseName(warehouseCode);
        setShippedFromWarehouseData(warehouse);
      }
    };
    if (shouldRefreshOrderHeaderDetails && shippedFromWarehouseData === '') {
      loadWarehouseData();
    }
  }, [shouldRefreshOrderHeaderDetails, orderDetails, shippedFromWarehouseData]);

  useEffect(() => {
    const getProofOfDelivery = async () => {
      let slabProofOfDeliveryArray: Array<ITrackingNumber> = [];
      for (const lineItem of orderDetails.orderLineItems) {
        for (const delivery of lineItem.deliveries) {
          const existingSlabProofOfDelivery = slabProofOfDeliveryArray.find(
            (x: ITrackingNumber) => x.trackingNumber === delivery.lineItemDeliveryNumber
          );

          if (existingSlabProofOfDelivery) {
            existingSlabProofOfDelivery.shippingItems.push({
              description: lineItem.description,
              shippedQuantity: 0,
              cancelledQuantity: 0,
              deliveredQuantity: 0,
              pickedQuantity: 0,
              receivedQuantity: 0,
              requestedQuantity: 0,
              srcRequestedQuantity: 0,
            });
          } else {
            slabProofOfDeliveryArray.push({
              shipMethodCode: '',
              trackingNumber: delivery.lineItemDeliveryNumber,
              trackingNumberUrl: '',
              shippingItems: [
                {
                  description: lineItem.itemDescription,
                  shippedQuantity: lineItem.quantity,
                  cancelledQuantity: 0,
                  deliveredQuantity: 0,
                  pickedQuantity: 0,
                  receivedQuantity: 0,
                  requestedQuantity: 0,
                  srcRequestedQuantity: 0,
                },
              ],
            });
          }
        }
      }

      setSlabProofOfDelivery(slabProofOfDeliveryArray);
    };

    const loadQrCode = async () => {
      const qrCode = await getQRCodeByCustomer(erpCustomerId);
      setQrCode(qrCode);
    };

    if (
      !qrCode &&
      erpCustomerId &&
      uiSettings?.enableDetrack &&
      orderDetails &&
      orderDetails.orderTypeDisplayName === 'Slab' &&
      orderDetails.orderLineItems &&
      orderDetails.orderLineItems.length > 0
    ) {
      loadQrCode();
      getProofOfDelivery();
    }
  }, [qrCode, erpCustomerId, orderDetails, uiSettings]);

  const toggleConfirmModalShow = () => setIsConfirmModalOpen((p) => !p);

  const downloadPdf = async () => {
    toast.success('Please be patient while we make your order available to download');
    setPdfDownloading(true);
    try {
      const file = await getOrderDetailsPdfRequest(
        orderDetails.orderNumber,
        orderDetails.erpCustomerId,
        orderHeaderData?.orderCreatedBy
      );
      const url = window.URL.createObjectURL(file);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `Cambria Order Details - ${orderDetails.orderNumber}.pdf`);
      document.body.appendChild(link);
      link.click();
    } catch (e) {
      toast.error('Something went wrong while trying to download your order.');
      console.error(e);
    }
    setPdfDownloading(false);
  };

  if (!orderDetails) {
    return <div>No Results</div>;
  } else {
    return (
      <OrderDetailsContainer>
        <Row className="back-button-row">
          <Col>
            <div className="back-button noprint" onClick={() => history.push('/account/orders')}>
              <i className="fa fa-angle-left" />
              BACK TO ORDER HISTORY
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <h1 className="main-header">ORDER DETAILS - {customerInfo?.name}</h1>
          </Col>
          <Col className="icons-header">
            <div className="icons-container noprint">
              {isFavorite ? (
                <Icon
                  size="27"
                  weight="600"
                  icon="icons-cambria-General-Favorites-Filled"
                  name="Favorites-Filled"
                  onClick={() => removeOrderFavorite()}
                />
              ) : (
                <Icon
                  size="27"
                  weight="600"
                  icon="icons-cambria-General-Favorites-Empty"
                  name="Favorites-Empty"
                  onClick={() => {
                    toggleFavoriteInput(!showFavoriteInput);
                    toggleEmailInput(false);
                  }}
                />
              )}
              {isOrderDetailPdfEnabled ? (
                orderDetails.statusSortOrder >= 100 && ( // Order Received
                  <Icon
                    name="download-icon"
                    disabled={pdfDownloading}
                    size="23"
                    weight="600"
                    icon="fa fa-download"
                    onClick={downloadPdf}
                  />
                )
              ) : (
                <Icon name="print-icon" size="23" weight="600" icon="fa fa-print" onClick={() => window.print()} />
              )}
              <Icon
                size="23"
                weight="600"
                name="email-icon"
                icon="fa fa-envelope"
                onClick={() => {
                  toggleEmailInput(!showEmailInput);
                  setEmailInputText(userEmail ? userEmail : '');
                  toggleFavoriteInput(false);
                }}
              />
              <Icon
                disabled={orderDetailsRefreshInProgress}
                size="20"
                name="refresh-icon"
                weight="600"
                icon="fa fa-sync"
                onClick={() => reloadData()}
              />
            </div>
            <br />
            {showEmailInput && (
              <div className="icons-container noprint">
                <Input
                  className="favorite-email-input"
                  onKeyDown={(event) => (event.key === 'Enter' ? sendEmail() : '')}
                  onChange={(e) => setEmailInputText(e.currentTarget.value)}
                  defaultValue={userEmail}
                  placeholder="Enter Email Address..."
                />
                <button
                  className="btn btn-primary save-favorite-button"
                  onClick={sendEmail}
                  disabled={emailButtonDisabled || !isEmail(emailInputText)}>
                  SEND
                </button>
              </div>
            )}
            {showFavoriteInput && (
              <div className="icons-container noprint">
                <Input
                  onKeyDown={(event) =>
                    event.key === 'Enter' ? sendFavorite(orderDetails.orderNumber, favoriteInputText) : ''
                  }
                  className="favorite-email-input"
                  onChange={(e) => setFavoriteInputText(e.currentTarget.value)}
                  placeholder="Enter Favorite Name..."
                />
                <button
                  className="btn btn-primary save-favorite-button"
                  disabled={!favoriteInputText}
                  onClick={() => sendFavorite(orderDetails.orderNumber, favoriteInputText)}>
                  SAVE
                </button>
              </div>
            )}
          </Col>
        </Row>
        <Row>
          <Col>
            <OrderDetailsHeader headerData={orderHeaderData} canSeeJobNumber={canSeeJobNumber} />
          </Col>
        </Row>
        <Row>
          <Col>
            <span className="d-flex justify-content-end align-items-center">
              <em className="fa fa-industry mr-2"></em>
              <span className="active-warehouse-name">{shippedFromWarehouseData}</span>
            </span>
          </Col>
        </Row>
        <Row className="payment-address-information">
          <Col className="bill-to-address address-card-col" xs={12} sm={12} md={6} lg={4}>
            <AddressCard addressCardInfo={billingAddressInfo} />
          </Col>
          <Col xs={12} sm={12} md={6} lg={4} className="address-card-col">
            <PaymentInfoCard paymentInfoCardData={paymentInfoCardData} voucherCodes={orderDetails.voucherCodes} />
          </Col>
          <Col xs={12} sm={12} md={6} lg={4} className="address-card-col">
            <AddressCard addressCardInfo={shipToAddressInfo} />
          </Col>
        </Row>
        {(isFabOrder || isSlabOrder) && <JobOrderTypeRow isFabricatedOrder={isFabOrder} isEditDisabled={true} />}
        <Row hidden={!canEditPurchasingCustomers}>
          <Col>
            <DealerEndUser orderDetails={orderDetails} />
          </Col>
        </Row>
        <Row>
          <Col>
            {!isFabOrder || isShippedOrCanceled ? (
              <ProductTable
                orderLineItems={orderLineItems}
                orderDetails={orderDetails}
                isFabOrder={isFabOrder}
                files={orderDetails.files?.length > 0 ? orderDetails.files : []}
              />
            ) : (
              <FabricationCart
                order={orderDetailsWithDiscounts}
                shouldUseOrderMetadata={getShouldUseOrderMetadata(orderDetails)}
              />
            )}
          </Col>
        </Row>
        {orderDetails.trackingNumbers?.length > 0 ? (
          <>
            <Row className="mt-0">
              <Col>
                <CambriaShipmentTracking trackingNumbers={orderDetails.trackingNumbers} />
              </Col>
            </Row>
          </>
        ) : (
          <></>
        )}
        {slabProofOfDelivery?.length > 0 ? (
          <>
            <Row className="mt-0">
              <Col>
                <CambriaShipmentTracking trackingNumbers={slabProofOfDelivery} isSlabOrder={true} />
              </Col>
            </Row>
          </>
        ) : (
          <></>
        )}
        <Row>
          {orderDetails.orderTypeDisplayName === 'Slab' && uiSettings?.enableDetrack ? (
            <Col sm={12} md={6} lg={4} className="qr-code-container">
              <img className={'qr-code-img'} alt={'qrCode'} src={qrCode}></img>
              <br />
              <h2 className={'qr-code-text'}>Scan QR code for automated estimated delivery times on all orders.</h2>
            </Col>
          ) : (
            <Col sm={12} md={6} lg={4}></Col>
          )}
          <Col lg={4} className="d-md-none d-sm-none d-xs-none d-lg-block" />
          <Col xs={12} sm={12} md={6} lg={4} className="order-summary-container">
            <OrderSummaryComponent
              numberOfLineItems={orderDetails.orderLineItems?.length}
              totalAmount={orderDetails.totalAmount}
              totalBeforeTaxAndShipping={
                orderDetails.totalNetAmount || orderDetails.totalNetAmount === 0
                  ? orderDetails.totalNetAmount
                  : orderDetails.orderMetadata?.orderSummaryData?.totalNetAmount || 0
              }
              currencyCode={currencyCode}
              linkForBackButton={'/account/orders'}
              shippingAndHandling={calculateShippingAndHandling()}
              estimatedTax={orderDetails.totalTaxAmount}
              orderSubtotal={orderDetails.subtotal}
              orderDiscount={orderDetails.totalDiscounts}
              estimatedPaymentFeeAmount={orderDetails.estimatedPaymentFeeAmount}
              actualPaymentFeeAmount={orderDetails.actualPaymentFeeAmount}
              headerAdjustmentTotal={orderDetails.totalHeaderAdjustmentAmount}
            />
            <button
              hidden={!reorderPermission || !getIsReorderable(orderDetails, CIAReorderablePermission)}
              className="btn btn-primary noprint resubmit-reorder-buttons"
              onClick={() => processReorder(false)}
              data-testid="reorder-button"
              disabled={reorderLoading}>
              REORDER
              {reorderLoading ? (
                <Icon
                  className={'resubmit-reorder-spinner'}
                  size="20"
                  weight="600"
                  icon="fa fa-spinner fa-spin"
                  disabled={true}
                />
              ) : (
                <></>
              )}
            </button>
            <button
              hidden={!resubmitPermission || !getIsResubmittable(orderDetails)}
              className="btn btn-primary noprint resubmit-reorder-buttons"
              onClick={() => processResubmitOrder()}
              data-testid="resubmit-button"
              disabled={resubmitLoading || reorderLoading}>
              {CIAResubmittablePermission ? 'REVISE ORDER' : 'RESUBMIT'}
              {resubmitLoading ? (
                <Icon
                  className={'resubmit-reorder-spinner'}
                  size="20"
                  weight="600"
                  icon="fa fa-spinner fa-spin"
                  disabled={true}
                />
              ) : (
                <></>
              )}
            </button>
            <div
              hidden={!removePermission || !getIsRemovable(orderDetails.statusCode, orderDetails.sourceSystem)}
              className="remove-order noprint"
              onClick={() => toggleShow()}>
              REMOVE ORDER
            </div>
            <button
              className="btn btn-primary noprint resubmit-reorder-buttons"
              onClick={() => setIsConfirmModalOpen(true)}
              hidden={!revisePermission || !getIsEditReorderable(orderDetails, isFabOrder, CIARevisablePermission)}
              disabled={resubmitLoading}>
              {CIARevisablePermission ? 'REVISE ORDER' : 'EDIT'}
              {resubmitLoading ? (
                <Icon
                  className={'resubmit-reorder-spinner'}
                  size="20"
                  weight="600"
                  icon="fa fa-spinner fa-spin"
                  disabled={true}
                />
              ) : (
                <></>
              )}
            </button>
            <CambriaModal
              show={showConfirmRemoval}
              confirmButton={'Yes'}
              cancelButton={'No'}
              heading={'Remove Order'}
              toggleShow={toggleShow}
              formName={'remove-order'}>
              <Form
                id={'remove-order'}
                onSubmit={(e) => {
                  e.preventDefault();
                  confirmRemoveOrder(orderDetails.orderNumber);
                }}>
                Are you sure you want to remove this order?
              </Form>
            </CambriaModal>
          </Col>
        </Row>
        <Row>
          <Col className="legal-disclaimer">
            <span className="text-uppercase text-bold-underline">IMPORTANT</span>: if a third party will pay this order
            on your behalf, you must provide a copy of this order confirmation to such third party. No planned or actual
            payment by a third party waives, limits or otherwise affects your obligations with respect to this order,
            including, without limitation, to ensure payment of this order in full. If you are a third party making
            payment of this order, by making payment you agree (i) to be bound by Cambria’s Product Supply Terms and
            Conditions (<a href="https://www.cambriausa.com/product-supply">www.CambriaUSA.com/ProductSupplyTC</a>) as
            they relate to payments and payment obligations under this order, (ii) as a third party payor, Cambria has
            no obligations to you and you have no rights or remedies under Cambria’s Product Supply Terms and
            Conditions, and (iii) you shall not cancel or dispute with your financial institution any payment or charges
            that correspond with this order confirmation and Cambria’s Product Supply Terms and Conditions.
          </Col>
        </Row>
        {isConfirmModalOpen && (
          <ConfirmationModal
            show={isConfirmModalOpen}
            toggleShow={toggleConfirmModalShow}
            onSubmitCallback={() => {
              if (confirmModalOptions.onSubmit) {
                confirmModalOptions.onSubmit();
              }
              setIsConfirmModalOpen(() => false);
            }}
            modalOptions={confirmModalOptions}></ConfirmationModal>
        )}
      </OrderDetailsContainer>
    );
  }
};
export default OrderDetailsPage;
