import qs from 'qs';
import {CORE_CONSTANTS} from '../constant';
import {baseApiCall} from './functions/baseApiCall';
import {forceLogout, getSavedAuth} from '../../features/auth/service/authentication.service';
import {AuthenticationState} from '../../features/auth/IAuthenticationState';

const windowObj: any = {...window};

export const baseApiCallWithReauth = async (
  method: string,
  endpointConstant: string,
  data?: any,
  nonJSONResponse?: boolean,
  getAll?: boolean,
  callbackResponse?: boolean,
  blob?: boolean
) => {
  const originalArgs = data ? JSON.parse(JSON.stringify(data)) : {};
  const promises = [];
  let responseItems: Array<any> = [];
  let pager = {
    limit: 200,
    offset: 0,
  };

  if (getAll) {
    data = qs.stringify({pager, ...originalArgs.params});
    pager.offset = pager.limit;
  }

  let response: any = await baseApiCall(method, endpointConstant, data);
  if (response && response.status === 400 && !callbackResponse) {
    let errorText = await response.json();
    if (errorText && errorText.Message) {
      throw errorText.Message;
    } else {
      throw response.statusText;
    }
  }

  if (response && response.status === 400 && callbackResponse) {
    throw response;
  }

  if (response && (response.status === 401 || response.status === 403)) {
    const savedAuth = getSavedAuth();
    if (savedAuth && savedAuth.response && savedAuth.response.refresh_token) {
      const tokenData = new URLSearchParams();
      tokenData.append('grant_type', 'refresh_token');
      tokenData.append('refresh_token', savedAuth.response.refresh_token);
      const endpoint = windowObj.SERVER_DATA.apiUrl + CORE_CONSTANTS.API_SERVICES.authToken;

      let authResponse = await fetch(endpoint, {
        method: 'POST',
        body: tokenData,
      });
      if (authResponse) {
        const authObject = await authResponse.json();
        let auth: AuthenticationState = {
          isAuthenticated: authResponse.status === 200,
          isAuthenticating: false,
          isRefreshing: false,
          isRefreshNeeded: false,
          response: authObject,
          error: authResponse.status === 200 ? '' : 'Error Logging In',
        };
        if (auth.isAuthenticated && auth.response && auth.response.access_token) {
          auth.user = JSON.parse(atob(auth.response.access_token.split('.')[1]));
          if (auth.user && auth.user.email === 'csfanonymous@cambriausa.com') {
            auth.isAuthenticated = false;
          }
        }
        windowObj.localStorage.setItem(CORE_CONSTANTS.COOKIES.ecommerceToken, JSON.stringify(auth));
        response = await baseApiCall(method, endpointConstant, data ? data : undefined);
      }
    } else {
      forceLogout();
    }
  }

  if (nonJSONResponse) {
    return response;
  }

  let results: any = {};
  if (response.status === 204) {
    return 'No Content';
  } else {
    if (blob) {
      return await response.blob();
    } else {
      results = await response.json();
    }
  }

  if (getAll && results?.totalResults > pager.limit) {
    while (pager.offset < results?.totalResults) {
      data = qs.stringify({pager, ...originalArgs.params});
      promises.push(await baseApiCall(method, endpointConstant, data));
      pager.offset += pager.limit;
    }

    return Promise.all(promises).then(async (responses: any) => {
      if (responses) {
        for (const response of responses) {
          if (response) {
            const res = await response.json();
            responseItems.push(...res.results);
          }
        }
      }

      results.results = [...results.results, ...responseItems];

      return results;
    });
  }

  return results;
};
