import React, {useEffect, useMemo, useState} from 'react';
import {Formik} from 'formik';
import * as Yup from 'yup';
import {Col, Container, Form, Row} from 'react-bootstrap';
import {useAppDispatch, useTypedSelector} from '../../../../hooks/store';
import {selectUserActions} from '../../../../features/permission/slice/permission.slice';
import {
  selectIsSubmitting,
  selectPaymentMethodpageSkipped,
  setIsSubmitting,
  setSavePaymentMethodToCart,
  setStep2Completed,
  submitShippingAndDelivery,
} from '../../../../features/checkout/slice/checkout.slice';
import {
  convertAccountToSite,
  getAccountDefaultShipToAddress,
  getCustomerShipToSitesFilteredByWarehouse,
  getMatchingShipTo,
} from '../../../../features/customer/service/customer.service';
import {
  selectActiveProgram,
  selectCurrentCustomerSfAccount,
  selectSalesforceCustomerAccount,
} from '../../../../features/salesforce/slice/salesforce.slice';
import {hasPermission} from '../../../../store/permission/permission.service';
import {
  getContactOptions,
  getShippingCost,
  getShippingSelections,
  showToastrMessageIfZeroShippingRate,
} from '../../../../features/checkout/service/checkout.service';
import {selectActiveCart} from '../../../../features/cart/slice/cart.slice';
import {
  selectCurrentCustomer,
  selectCurrentCustomerBillToSite,
  selectCurrentCustomerBillToSites,
  selectCurrentCustomerShipToSites,
  setCurrentCustomerShipToSites,
} from '../../../../features/customer/slice/customer.slice';
import {getShippingWarehouse} from '../../../../features/warehouse/service/warehouse.service';
import {WarehouseFullData} from '../../../../features/warehouse/IWarehouseState';
import {PaShippingAndDeliveryContainer} from './PurchaseAgreementPage.styled';
import CambriaButton from '../../../../Framework/Components/CambriaButton';
import Icon from '../../../../Framework/Components/Icon';
import AddShippingAddressModal from '../../../../Core/Components/Modals/AddShippingAddressModal';
import {getAllAvailableCountries} from '../../../../features/location/slice/location.slice';
import AddShippingContactModal from '../../../../Core/Components/Modals/AddShippingContactModal';
import CambriaInput from '../../../../Framework/Components/CambriaInput';
import ConfirmationModal from '../../../../Core/Components/Modals/ConfirmationModal';
import {formatPrice} from '../../../../features/pricing/service/pricing.service';
import CambriaSelect from '../../../../Framework/Components/CambriaSelect';
import {selectPaymentSelections} from '../../../../features/payment/slice/payment.slice';
import {selectCurrentUser} from '../../../../features/auth/slice/authentication.slice';
import {getSalesforceContacts} from '../../../../features/salesforce/service/salesforce.service';
import {
  hideFullscreenLoader,
  showFullscreenLoader,
} from '../../../../features/fullscreenLoader/slice/fullscreenLoader.slice';
import {useHistory} from 'react-router-dom';
import {PurchaseAgreementBillToSite, Site} from '../../../../features/customer/ICustomerState';
import {startCreatePurchaseAgreementProcess} from '../../../../features/file/slice/file.slice';
import {getEndConsumersAsync, selectEndConsumers} from '../../../../features/fabrication/slice/fabrication.slice';
import {EndConsumer} from '../../../../features/cart/ICartState';
import {toast} from 'react-toastify';
import {downloadFileFromUrl} from '../../../../features/file/service/file.service';
import COMMERCE_CORE_CONSTANTS from '../../../../Core/constants';
import {useDisabledPurchaseAgreement} from '../../../../hooks/useDisabledPurchaseAgreement/useDisabledPurchaseAgreement';

const PurchaseAgreementPage = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [purchaseAgreementDisabled, purchaseAgreementDisabledMessage] = useDisabledPurchaseAgreement();

  const userActions = useTypedSelector(selectUserActions);
  const activeCart = useTypedSelector(selectActiveCart);
  const currentCustomerSfAccount = useTypedSelector(selectCurrentCustomerSfAccount);
  const currentCustomer = useTypedSelector(selectCurrentCustomer);
  const currentCustomerShipToSites = useTypedSelector(selectCurrentCustomerShipToSites);
  const currentCustomerBillToSites = useTypedSelector(selectCurrentCustomerBillToSites);
  const currentCustomerBillToSite = useTypedSelector(selectCurrentCustomerBillToSite);
  const activeProgram = useTypedSelector(selectActiveProgram);
  const currentUser = useTypedSelector(selectCurrentUser);
  const paymentSelections = useTypedSelector(selectPaymentSelections);
  const salesforceCustomerAccount = useTypedSelector(selectSalesforceCustomerAccount);
  const paymentMethodPageHasBeenSkipped = useTypedSelector(selectPaymentMethodpageSkipped);
  const isSubmitting = useTypedSelector(selectIsSubmitting);
  const endConsumers = useTypedSelector(selectEndConsumers);

  const [contactOptions, setContactOptions] = useState<Array<any>>([]);
  const [shippingZonePricing, setShippingZonePricing] = useState<any>(null);
  const [currentDeliveryMethod, setCurrentDeliveryMethod] = useState<any>(null);
  const [currentWarehouse, setCurrentWarehouse] = useState<WarehouseFullData | null>(null);
  const [showAddNewShippingAddressModal, setShowAddNewShippingAddressModal] = useState(false);
  const [showAddNewShippingContactModal, setShowAddNewShippingContactModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [confirmModalOptions, setConfirmModalOptions] = useState<any>({});
  const [alreadyInitialized, setAlreadyInitialized] = useState<boolean>(false);
  const [disableFields, setDisableFields] = useState<boolean>(true);
  const [showShippingMethodField, setShowShippingMethodField] = useState<boolean>(false);
  const [deliveryMethods, setDeliveryMethods] = useState<Array<any>>([]);
  const [shippingMethods, setShippingMethods] = useState<Array<any>>([]);
  const [shippingMethodLabel, setShippingMethodLabel] = useState<any>('Shipping Method');
  const [isPageSubmitted, setIsPageSubmitted] = useState<boolean>(false);
  const [availableContacts, setAvailableContacts] = useState<any[]>([]);
  const [newShippingAddress, setNewShippingAddress] = useState<any>(null);
  const customerShipToSiteOptions: Site[] = getCustomerShipToSitesFilteredByWarehouse(
    activeCart as any,
    currentCustomer,
    currentCustomerShipToSites as any
  );

  const initialFormValues = useMemo(
    () => ({
      shippingAddress: '',
      contact: '',
      purchasingCustomerOrderNumber: '',
      deliveryMethod: '',
      shippingMethod: '',
      customerShippingAccountId: '',
      sendInFewerBoxes: false,
    }),
    []
  );

  const validationSchema = Yup.object({
    shippingAddress: Yup.mixed().required('This field is required'),
    contact: Yup.mixed(),
    purchasingCustomerOrderNumber: Yup.string()
      .max(12, 'The maximum length of this field is 12')
      .matches(/^[a-zA-Z0-9\\,:"()<>+;/~*. ]*$/, 'This field does not support the special character(s) entered.'),
    deliveryMethod: Yup.mixed().required('This field is required'),
    shippingMethod: showShippingMethodField ? Yup.mixed().required('This field is required') : Yup.mixed(),
    customerShippingAccountId:
      currentDeliveryMethod && currentDeliveryMethod.name.toLowerCase().includes('fedex')
        ? Yup.string().required('This field is required')
        : Yup.string(),
    sendInFewerBoxes: Yup.mixed(),
  });

  const getShippingZonePrices = async (props: any, value: any) => {
    if (
      props.values.shippingAddress &&
      value.name === 'Cambria Delivery' &&
      activeCart &&
      currentCustomer &&
      currentCustomerShipToSites
    ) {
      try {
        const result = await getShippingCost(
          activeCart.cartItems[0].productType,
          (initialFormValues.shippingAddress as any).postalCode,
          activeCart,
          currentCustomer,
          currentCustomerShipToSites
        );
        setShippingZonePricing(result);
      } catch (error) {
        if (error === 'Shipping zone not found.') {
          setConfirmModalOptions({
            heading: 'Shipping Zone Verification',
            body1: `The shipping address postal code is outside of the Cambria delivery zone for ${currentWarehouse?.name} Sales & Distribution Center.`,
            body2:
              'Please contact CambriaCustomerCare to have this postal code added to your local delivery schedule at 1-866-CAMBRIA. Or, please change your delivery method.',
            hideCancelButton: true,
            hideExitIcon: true,
            disableClickOutsideToClose: true,
            confirmButton: 'Change Delivery Method',
            onSubmit: () => {
              props.setFieldValue('deliveryMethod', '');
              setCurrentDeliveryMethod(null);
            },
          });
        } else if (error === 'Shipping zone pricing not found.') {
          setConfirmModalOptions({
            heading: 'Shipping Zone Verification',
            body1: 'Pricing is not available for the assigned Cambria Delivery zone.',
            hideCancelButton: true,
            hideExitIcon: true,
            disableClickOutsideToClose: true,
            confirmButton: 'Change Delivery Method',
            onSubmit: () => {
              props.setFieldValue('deliveryMethod', '');
              setCurrentDeliveryMethod(null);
            },
          });
        }
        setShowConfirmModal(() => true);
      }
    }
  };

  const toggleAddNewAddressModalShow = () => setShowAddNewShippingAddressModal((p) => !p);
  const toggleAddNewContactModalShow = () => setShowAddNewShippingContactModal((p) => !p);
  const toggleConfirmModalShow = () => setShowConfirmModal((p) => !p);

  const renderDeliveryMethodInfo = () => {
    let message = currentDeliveryMethod.message || '';

    if (currentDeliveryMethod.name.toLowerCase().includes('combine with a slab order')) {
      message = `${message} We will ship on the next available slab truck.`;
    }

    if (currentDeliveryMethod.name === 'Cambria Delivery' && shippingZonePricing && activeCart) {
      message = `Shipping Cost: $${formatPrice(shippingZonePricing.deliveryRate)} ${activeCart.cartItems[0].currency} ${
        shippingZonePricing.deliveryRateType ? `/ ${shippingZonePricing.deliveryRateType}` : ''
      }`;
    }

    if (currentDeliveryMethod.name === 'Customer Arranged from DC') {
      message =
        'The customer will arrange for a carrier or will pick up the order. The carrier will invoice delivery charges to consignee.';
    }

    if (currentDeliveryMethod.name === 'FedEx - Use My Account') {
      message =
        "Cambria will ship with FedEx and FedEx will bill delivery to the customer's FedEx account. FedEx account number must be provided.";
    }

    return (
      <div className="field-info">
        <span className="text">{message}</span>
      </div>
    );
  };

  const submitForm = async (values: any, props: any) => {
    if (activeCart && currentCustomerShipToSites && currentCustomerBillToSites && currentUser) {
      dispatch(showFullscreenLoader({showCartIcon: false}));
      setDisableFields(true);
      setIsPageSubmitted(true);
      const shippingDetails = JSON.parse(JSON.stringify(values));
      shippingDetails.phoneNumber = shippingDetails.phoneNumber ? shippingDetails.phoneNumber : null;
      await dispatch(
        submitShippingAndDelivery({
          activeCart,
          shippingDetails,
        })
      );

      if (paymentMethodPageHasBeenSkipped && paymentSelections) {
        await dispatch(setSavePaymentMethodToCart(true));
      } else {
        await dispatch(setIsSubmitting(false));
        await dispatch(setStep2Completed(true));
      }
      let customerBillToSite = JSON.parse(JSON.stringify(currentCustomerBillToSite));
      customerBillToSite.stateProvinceCode = customerBillToSite.stateProvince;
      const paFileUrl = await dispatch(
        startCreatePurchaseAgreementProcess({
          erpCustomerId: activeCart.erpCustomerId,
          cartId: activeCart.id,
          endConsumer: endConsumers ? endConsumers[0] : ({} as EndConsumer),
          warehouse: currentWarehouse ? currentWarehouse : ({} as WarehouseFullData),
          billToSite: customerBillToSite as PurchaseAgreementBillToSite,
          shipToAddress: shippingDetails.shippingAddress,
          purchasingCustomerOrderNumber: values.purchasingCustomerOrderNumber,
        })
      ).unwrap();

      const fileName = `Purchase agreement -${currentCustomer.erpCustomerId} - ${Date.now()}.pdf`;
      await downloadFileFromUrl(paFileUrl.Href, fileName);

      dispatch(hideFullscreenLoader());
      toast.success('Successfully created Purchase Agreement!');
      history.push(COMMERCE_CORE_CONSTANTS.PATHNAMES.cart);
    }
  };

  useEffect(() => {
    const getEndConsumers = async () => {
      if (activeCart && currentCustomer.id) {
        dispatch(
          getEndConsumersAsync({
            erpCustomerId: currentCustomer.erpCustomerId,
            cartId: activeCart.id,
          })
        );
      }
    };
    getEndConsumers();
  }, [dispatch, currentCustomer, activeCart]);

  useEffect(() => {
    dispatch(getAllAvailableCountries());
    setDisableFields(true);
  }, [dispatch]);

  useEffect(() => {
    if (newShippingAddress) {
      const shipToSites = getCustomerShipToSitesFilteredByWarehouse(
        activeCart as any,
        currentCustomer,
        currentCustomerShipToSites as any
      );

      const selectedShipToSite = shipToSites.find(
        (site: any) =>
          site.siteName === newShippingAddress.siteName &&
          site.address1 === newShippingAddress.address1 &&
          site.postalCode === newShippingAddress.postalCode
      ) as any;

      initialFormValues.shippingAddress = selectedShipToSite;
      setNewShippingAddress(null);
    }
  }, [newShippingAddress, activeCart, currentCustomer, currentCustomerShipToSites, initialFormValues]);

  useEffect(() => {
    const getCurrentWarehouse = async () => {
      if (!currentWarehouse && activeCart && currentCustomer && currentCustomerShipToSites) {
        const currentShippingWarehouse = await getShippingWarehouse(
          activeCart,
          currentCustomer,
          currentCustomerShipToSites
        );
        setCurrentWarehouse(() => currentShippingWarehouse);
      }
    };

    getCurrentWarehouse();
  }, [currentWarehouse, activeCart, currentCustomer, currentCustomerShipToSites]);

  useEffect(() => {
    const onDeliveryMethodChange = async () => {
      if (currentDeliveryMethod && !isPageSubmitted) {
        setDisableFields(true);

        if (currentDeliveryMethod.name.includes('Customer Arranged')) {
          setShowShippingMethodField(true);
          setShippingMethods(currentDeliveryMethod.shippingMethods);
        } else {
          setShowShippingMethodField(false);
        }

        setShippingMethodLabel(() => 'Shipping Method');
        setDisableFields(false);
      }
    };

    onDeliveryMethodChange();
  }, [
    currentDeliveryMethod,
    activeCart,
    currentCustomer,
    initialFormValues.shippingMethod,
    userActions,
    isPageSubmitted,
  ]);

  useEffect(() => {
    if (showShippingMethodField) {
      setTimeout(() => {
        if (currentDeliveryMethod.name.toLowerCase().includes('customer arranged')) {
          setShippingMethodLabel(() => 'Preferred Shipping Provider');
        } else {
          setShippingMethodLabel(() => 'Shipping Method');
        }
      });
    }
  }, [showShippingMethodField, currentDeliveryMethod]);

  useEffect(() => {
    if (shippingZonePricing) {
      showToastrMessageIfZeroShippingRate(shippingZonePricing.deliveryRate);
    }
  }, [shippingZonePricing]);

  useEffect(() => {
    const getAvailableContacts = async () => {
      const contacts = await getSalesforceContacts(salesforceCustomerAccount.id);
      setAvailableContacts(contacts);
    };

    getAvailableContacts();
  }, [salesforceCustomerAccount]);

  useEffect(() => {
    const getExistingShippingDetails = async () => {
      if (
        activeCart &&
        !initialFormValues.shippingAddress &&
        currentCustomer &&
        currentCustomerShipToSites &&
        currentWarehouse &&
        !alreadyInitialized
      ) {
        setDisableFields(true);
        let existingOrDefaultShippingAddress = '';
        if (activeCart.shippingMethods[0]?.shipToSiteId) {
          const allSites = getCustomerShipToSitesFilteredByWarehouse(
            activeCart as any,
            currentCustomer,
            currentCustomerShipToSites
          );
          existingOrDefaultShippingAddress = allSites.find(
            (address: any) => address.siteNumber === activeCart.shippingMethods[0].shipToSiteId
          ) as any;
        } else if (currentCustomerSfAccount && userActions) {
          if (hasPermission('urn:csa:commerceui:checkout:defaultShipToToSalesforceAsset', userActions)) {
            const sfAddress = convertAccountToSite(
              currentCustomerSfAccount,
              currentCustomer.class !== 'MARKETREP' ? currentWarehouse : null
            );
            if (sfAddress) {
              existingOrDefaultShippingAddress = getMatchingShipTo(
                getCustomerShipToSitesFilteredByWarehouse(
                  activeCart as any,
                  currentCustomer,
                  currentCustomerShipToSites
                ),
                sfAddress
              );
            }
          }
        }

        if (!existingOrDefaultShippingAddress) {
          existingOrDefaultShippingAddress = getAccountDefaultShipToAddress(
            getCustomerShipToSitesFilteredByWarehouse(activeCart as any, currentCustomer, currentCustomerShipToSites)
          );
        }

        initialFormValues.shippingAddress = existingOrDefaultShippingAddress;

        const deliveryMethodsResponse = await getShippingSelections(
          existingOrDefaultShippingAddress,
          activeCart,
          activeProgram,
          currentCustomer,
          currentCustomerShipToSites,
          false
        );
        setDeliveryMethods(deliveryMethodsResponse);
        const contactOptions = getContactOptions(
          currentCustomerShipToSites,
          activeCart,
          currentCustomer,
          existingOrDefaultShippingAddress as any
        );
        setContactOptions(contactOptions);

        if (activeCart.shippingMethods[0]?.shipToContactId) {
          initialFormValues.contact = contactOptions.find(
            (contact: any) =>
              contact.erpCustomerContactId === activeCart.shippingMethods[0]?.shipToContactId ||
              contact.id === activeCart.shippingMethods[0]?.shipToContactId
          );
        }

        if (activeCart.shippingMethods[0]?.shippingOptionId) {
          const existingDeliveryMethod = deliveryMethodsResponse.find(
            (method: any) => method.id === activeCart.shippingMethods[0]?.shippingOptionId
          );

          setCurrentDeliveryMethod(() => existingDeliveryMethod);
          initialFormValues.deliveryMethod = existingDeliveryMethod;

          if (activeCart.shippingMethods[0]?.shippingMethodCode) {
            initialFormValues.shippingMethod = existingDeliveryMethod.shippingMethods.find(
              (method: any) => method.code === activeCart.shippingMethods[0]?.shippingMethodCode
            );
          }

          if (activeCart.shippingMethods[0]?.customerShippingAccountId) {
            initialFormValues.customerShippingAccountId = activeCart.shippingMethods[0]?.customerShippingAccountId;
          }
        }

        if (activeCart.shippingMethods[0]?.shipComplete) {
          initialFormValues.sendInFewerBoxes = true;
        }
        setAlreadyInitialized(true);
        setDisableFields(false);
      }
    };

    getExistingShippingDetails();
  }, [
    alreadyInitialized,
    activeCart,
    activeProgram,
    shippingMethods,
    currentCustomer,
    currentCustomerShipToSites,
    currentCustomerSfAccount,
    currentWarehouse,
    userActions,
    initialFormValues,
  ]);

  useEffect(() => {
    // If the purchase agreement was disabled, we kick the user to the cart
    if (purchaseAgreementDisabled) {
      toast.error(purchaseAgreementDisabledMessage);
      history.push(COMMERCE_CORE_CONSTANTS.PATHNAMES.cart);
    }
  }, [purchaseAgreementDisabled, purchaseAgreementDisabledMessage, history]);

  return (
    <PaShippingAndDeliveryContainer>
      <Formik initialValues={initialFormValues} validationSchema={validationSchema} onSubmit={submitForm}>
        {(props) => {
          return (
            <Form id="purchase-agreement-form" noValidate onSubmit={props.handleSubmit}>
              <Container className="p-0 not-marketrep-form">
                <Row>
                  <Col className="col-12 col-lg-7 col-lg-offset-1">
                    <CambriaSelect
                      name="shippingAddress"
                      formikFormProps={props}
                      defaultValue={props.values.shippingAddress}
                      label="Shipping Address"
                      placeholder="Select Shipping Address"
                      items={customerShipToSiteOptions}
                      displayValue="address"
                      disabled={disableFields}
                      onChange={async (value: any) => {
                        initialFormValues.shippingAddress = value;
                        props.setFieldValue('deliveryMethod', '');
                        props.setFieldValue('contact', '');
                        props.setFieldValue('shippingMethod', '');
                        setShowShippingMethodField(false);
                        setContactOptions(() =>
                          getContactOptions(
                            currentCustomerShipToSites as any,
                            activeCart as any,
                            currentCustomer,
                            value
                          )
                        );
                        setDeliveryMethods(
                          await getShippingSelections(
                            value,
                            activeCart as any,
                            activeProgram,
                            currentCustomer,
                            currentCustomerShipToSites as any,
                            false
                          )
                        );
                      }}
                      required={true}></CambriaSelect>
                  </Col>
                  <Col className="col-12 col-lg-4 button-section">
                    <CambriaButton
                      onClick={toggleAddNewAddressModalShow}
                      variant="secondary"
                      id="add-new-address-test-id">
                      <Icon icon="icons-cambria-Symbols-Plus" color="#c59617" size="15" weight="bold" />
                      Add New Address
                    </CambriaButton>
                  </Col>
                </Row>
                <Row>
                  <Col className="col-12 col-lg-7 col-lg-offset-1">
                    <CambriaSelect
                      name="contact"
                      formikFormProps={props}
                      defaultValue={initialFormValues.contact || props.values.contact}
                      label="Contact"
                      placeholder="Select Contact"
                      items={contactOptions}
                      displayValue="displayName"
                      disabled={disableFields}
                    />
                  </Col>
                  <Col className="col-12 col-lg-4 button-section">
                    <CambriaButton
                      onClick={toggleAddNewContactModalShow}
                      variant="secondary"
                      id="add-or-revise-contact-test-id">
                      <Icon icon="icons-cambria-Symbols-Plus" color="#c59617" size="15" weight="bold" />
                      Add or Revise Contact
                    </CambriaButton>
                  </Col>
                </Row>
                <Row>
                  <Col className="col-12 col-lg-7 col-lg-offset-1">
                    <span className="current-warehouse">{currentWarehouse?.name}</span>
                    <CambriaSelect
                      name="deliveryMethod"
                      formikFormProps={props}
                      defaultValue={props.values.deliveryMethod}
                      label="Delivery Method"
                      placeholder="Select Delivery Method"
                      items={deliveryMethods}
                      displayValue="name"
                      disabled={disableFields}
                      onChange={(value: any) => {
                        setCurrentDeliveryMethod(value);
                        getShippingZonePrices(props, value);
                        props.setFieldValue('shippingMethod', '');
                      }}
                      required={true}
                    />
                    {currentDeliveryMethod && renderDeliveryMethodInfo()}
                  </Col>
                </Row>
                {showShippingMethodField && (
                  <Row>
                    <Col className="col-12 col-lg-7 col-lg-offset-1">
                      <CambriaSelect
                        name="shippingMethod"
                        formikFormProps={props}
                        defaultValue={props.values.shippingMethod}
                        label={shippingMethodLabel}
                        placeholder="Select Shipping Method"
                        items={shippingMethods}
                        displayValue="name"
                        disabled={disableFields}
                        required={showShippingMethodField}
                      />
                    </Col>
                  </Row>
                )}
                {currentDeliveryMethod && currentDeliveryMethod.requireCustomerNumber && (
                  <Row>
                    <Col className="col-12 col-lg-6">
                      <CambriaInput
                        name="customerShippingAccountId"
                        defaultValue={initialFormValues.customerShippingAccountId}
                        label="Account Number"
                        placeholder="Enter account number here"
                        type="text"
                        required={currentDeliveryMethod.requireCustomerNumber}
                        disabled={disableFields}></CambriaInput>
                    </Col>
                  </Row>
                )}
                <Row>
                  <Col className="col-12 col-lg-7 col-lg-offset-1">
                    <CambriaInput
                      name="purchasingCustomerOrderNumber"
                      defaultValue={
                        initialFormValues.purchasingCustomerOrderNumber || props.values.purchasingCustomerOrderNumber
                      }
                      label="Purchasing Customer Order Number"
                      placeholder="Purchasing Customer Order Number"
                      disabled={disableFields}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col className="col-12 col-lg-offset-8 col-lg-4">
                    <CambriaButton
                      onClick={() => {}}
                      id="submit-purchase-agreement"
                      disabled={isSubmitting}
                      shadow={false}
                      type="submit"
                      form="purchase-agreement-form"
                      className="btn btn-type-a btn-primary m-t-md m-b-md">
                      {isSubmitting ? (
                        <Icon
                          className="next-step-spinner"
                          size="20"
                          weight="600"
                          icon="fa fa-spinner fa-spin"
                          disabled
                        />
                      ) : (
                        ''
                      )}
                      CREATE PURCHASE AGREEMENT
                    </CambriaButton>
                  </Col>
                </Row>
              </Container>
              {showAddNewShippingAddressModal && (
                <AddShippingAddressModal
                  show={showAddNewShippingAddressModal}
                  toggleShow={toggleAddNewAddressModalShow}
                  onSubmitCallback={(newAddress: any) => setNewShippingAddress(newAddress)}
                  operatingUnitCode={
                    customerShipToSiteOptions && customerShipToSiteOptions.length > 0
                      ? customerShipToSiteOptions[0]?.operatingUnitCode
                      : undefined
                  }
                />
              )}
              {showAddNewShippingContactModal && (
                <AddShippingContactModal
                  show={showAddNewShippingContactModal}
                  toggleShow={toggleAddNewContactModalShow}
                  currentShippingSiteId={(initialFormValues as any).shippingAddress.id}
                  existingContacts={availableContacts}
                  onSubmitCallback={async (contact: any) => {
                    props.values.contact = contact;
                    const updatedSites = JSON.parse(JSON.stringify(currentCustomerShipToSites));
                    const currentShippingSite = JSON.parse(JSON.stringify(props.values.shippingAddress));

                    updatedSites.forEach((site: any) => {
                      if (site.id === currentShippingSite.id) {
                        site.contacts.push(contact);
                      }
                    });

                    await dispatch(setCurrentCustomerShipToSites(updatedSites));
                  }}></AddShippingContactModal>
              )}
              {showConfirmModal && (
                <ConfirmationModal
                  show={showConfirmModal}
                  toggleShow={toggleConfirmModalShow}
                  onSubmitCallback={() => {
                    if (confirmModalOptions.onSubmit) {
                      confirmModalOptions.onSubmit();
                      props.setFieldValue('deliveryMethod', '');
                    }
                    setShowConfirmModal(() => false);
                  }}
                  modalOptions={confirmModalOptions}></ConfirmationModal>
              )}
            </Form>
          );
        }}
      </Formik>
    </PaShippingAndDeliveryContainer>
  );
};

export default PurchaseAgreementPage;
