import styled from 'styled-components/macro';
import {$gray78} from '../../../Styled/variables';

interface IconSpanProps {
  size: string;
  color: string;
  colorOnHover: string;
  weight: string;
  onClick: any;
}

export const IconSpan = styled.span<IconSpanProps>`
  font-weight: 400 !important;
  font-size: ${(props) => props.size}px !important;
  font-weight: ${(props) => props.weight} !important;
  color: ${(props) => props.color};
  padding: 0 !important;
  cursor: ${(props) => (props.onClick ? 'pointer !important' : 'initial')};

  &:hover {
    color: ${(props) => props.colorOnHover};
  }

  &.disabled {
    color: ${$gray78};
    cursor: not-allowed !important;
  }
`;
