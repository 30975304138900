import React, {useEffect, useState} from 'react';
import {Formik} from 'formik';
import * as Yup from 'yup';
import {Container, Form, Row} from 'react-bootstrap';
import CambriaInput from '../../../../../Framework/Components/CambriaInput';
import {useAppDispatch, useTypedSelector} from '../../../../../hooks/store';
import {OrderDetail, Cart} from '../../../../../features/cart/ICartState';
import {
  invalidateStep1,
  selectActiveStep,
  selectNextButtonToastErrorMessages,
  selectStep1Completed,
  setNextButtonToastErrorMessages,
  submitOrderDetails,
} from '../../../../../features/checkout/slice/checkout.slice';
import {selectActiveCart} from '../../../../../features/cart/slice/cart.slice';
import {FabricationOrderDetailsContainer} from './FabricationOrderDetails.styled';
import {showFullscreenLoader} from '../../../../../features/fullscreenLoader/slice/fullscreenLoader.slice';
import EmailList from '../../../FabricationOrderEntry/OrderDetails/EmailList';
import {
  selectCadRevisionEmails,
  selectOrderApprovedEmails,
  selectPoRevisionEmails,
  selectSelectedDrafter,
} from '../../../../../features/fabrication/slice/fabrication.slice';
import {selectUserActions} from '../../../../../features/permission/slice/permission.slice';
import {hasPermission} from '../../../../../store/permission/permission.service';
import {pushAddOrderDetailsToDataLayer} from '../../../../../features/analytics/service/analytics.service';

interface FabricationOrderDetailsProps {
  orderDetails: OrderDetail | undefined;
}

const FabricationOrderDetails = ({orderDetails}: FabricationOrderDetailsProps) => {
  const dispatch = useAppDispatch();

  const activeStep = useTypedSelector(selectActiveStep);
  const activeCart = useTypedSelector(selectActiveCart);
  const userActions = useTypedSelector(selectUserActions);
  const step1Completed = useTypedSelector(selectStep1Completed);
  const orderApprovedEmailsRedux = useTypedSelector(selectOrderApprovedEmails);
  const cadRevisionEmailsRedux = useTypedSelector(selectCadRevisionEmails);
  const poRevisionEmailsRedux = useTypedSelector(selectPoRevisionEmails);
  const selectedDrafterRedux = useTypedSelector(selectSelectedDrafter);
  const nextButtonToastErrorMessages = useTypedSelector(selectNextButtonToastErrorMessages);

  const [isInitialized, setIsInitialized] = useState<boolean>(false);
  let [drafterRequired, setDrafterIsRequired] = useState<boolean>(true);
  let [reviewEmailsRequired, setReviewEmailsRequired] = useState<boolean>(true);
  let [purchaseOrderNumber, setPurchaseOrderNumber] = useState<string>(
    activeCart?.orderDetails[0]?.purchaseOrderNumber || ''
  );

  const handleSubmit = async (values: any) => {
    if (nextButtonToastErrorMessages.length > 0) {
      return;
    }

    dispatch(showFullscreenLoader({showCartIcon: false}));
    await dispatch(
      submitOrderDetails({
        activeCart,
        orderDetails: {
          cartId: activeCart ? activeCart.id : '',
          jobType: activeCart?.orderDetails[0]?.jobType ? {code: activeCart?.orderDetails[0]?.jobType} : null,
          orderType: activeCart?.orderDetails[0]?.projectType ? {code: activeCart?.orderDetails[0]?.projectType} : null,
          projectName: activeCart?.orderDetails[0]?.projectName || null,
          fabReworkReason: activeCart?.orderDetails[0]?.fabReworkReason || null,
          fabAssignableCause: activeCart?.orderDetails[0]?.fabAssignableCause || null,
          projectCity: activeCart?.orderDetails[0]?.projectCity || null,
          purchaseOrderNumber: values.purchaseOrderNumber.trim(),
          relatedAgency: activeCart?.orderDetails[0]?.relatedAgency || null,
          orderPieceRework: activeCart?.orderDetails[0]?.orderPieceRework || null,
          publicFacingDisplay: activeCart?.orderDetails[0]?.publicFacingDisplay || null,
          orderIsRevisionOfPreviousJob: activeCart?.orderDetails[0]?.orderIsRevisionOfPreviousJob || null,
          fabricationOrderCadRevisionsNeededEmailList: cadRevisionEmailsRedux,
          fabricationOrderDrafter: selectedDrafterRedux ? selectedDrafterRedux.trim() : null,
          fabricationOrderOrderApprovedEmailList: orderApprovedEmailsRedux,
          fabricationOrderPoRevisionsNeededEmailList: poRevisionEmailsRedux,
          originalSalesOrderNumber: activeCart?.orderDetails[0]?.originalSalesOrderNumber || null,
          fabricationInstructions: activeCart?.orderDetails[0]?.fabricationInstructions || null,
        },
      })
    );

    pushAddOrderDetailsToDataLayer({cart: activeCart as Cart});
  };

  useEffect(() => {
    if (userActions.length > 0) {
      setDrafterIsRequired(
        !hasPermission('urn:csa:commerceui:order:fab:dontrequireorderapprovedemaillist', userActions)
      );
      setReviewEmailsRequired(!hasPermission('urn:csa:commerceui:disableRequiredReviewEmails', userActions));
    }
  }, [userActions]);

  useEffect(() => {
    const testPurchaseOrderNumberRegex = (purchaseOrderNumberTestValue: string) => {
      const regex = new RegExp(/^[a-zA-Z0-9\\,:"()<>+;/~*. ]*$/);
      const isValid = regex.test(purchaseOrderNumberTestValue);
      return isValid;
    };

    const generateSaveAndContinueToolTipMessage = () => {
      let tooltipMessageArray = [];

      if (drafterRequired && !selectedDrafterRedux) {
        tooltipMessageArray.push('Order Drafter is a required field.');
      }
      if (reviewEmailsRequired && (!cadRevisionEmailsRedux || !orderApprovedEmailsRedux || !poRevisionEmailsRedux)) {
        tooltipMessageArray.push(
          'All Approval Email Fields are Required. Hit the enter button after typing in the email address to ensure it is added to the list.'
        );
      }

      if (!purchaseOrderNumber) {
        tooltipMessageArray.push('Purchase Order Number is a required field.');
      }
      if (purchaseOrderNumber) {
        if (purchaseOrderNumber.length > 50) {
          tooltipMessageArray.push('The maximum length of a Purchase Order Number is 50.');
        }
        if (!testPurchaseOrderNumberRegex(purchaseOrderNumber)) {
          tooltipMessageArray.push('Purchase Order Number does not support the special character entered.');
        }
      }

      dispatch(setNextButtonToastErrorMessages(tooltipMessageArray));
    };

    generateSaveAndContinueToolTipMessage();
  }, [
    dispatch,
    purchaseOrderNumber,
    drafterRequired,
    reviewEmailsRequired,
    selectedDrafterRedux,
    cadRevisionEmailsRedux,
    orderApprovedEmailsRedux,
    poRevisionEmailsRedux,
  ]);

  useEffect(() => {
    if (step1Completed && !isInitialized) {
      setIsInitialized(true);
      dispatch(invalidateStep1());
    }

    return () => {};
  }, [step1Completed, dispatch, isInitialized]);

  return (
    <FabricationOrderDetailsContainer>
      <EmailList></EmailList>
      <br></br>
      <Formik
        initialValues={{
          purchaseOrderNumber: orderDetails && orderDetails.purchaseOrderNumber ? orderDetails.purchaseOrderNumber : '',
        }}
        validationSchema={Yup.object({
          purchaseOrderNumber: Yup.string()
            .max(50, 'The maximum length of this field is 50')
            .matches(/^[a-zA-Z0-9\\,:"()<>+;/~*. ]*$/, 'This field does not support the special character entered.')
            .required('This field is required'),
        })}
        onSubmit={handleSubmit}>
        {(props) => {
          return (
            <Form id={activeStep?.step} onSubmit={props.handleSubmit}>
              <Container className="p-0 not-marketrep-form">
                <Row className="m-b-sm">
                  <div className={'col-12 col-lg-6'}>
                    <CambriaInput
                      disabled={false}
                      name={'purchaseOrderNumber'}
                      placeholder={'Purchase Order ID'}
                      defaultValue={
                        orderDetails && orderDetails.purchaseOrderNumber ? orderDetails.purchaseOrderNumber : ''
                      }
                      label={'Purchase Order ID'}
                      type={'text'}
                      required={true}
                      onChange={(event: any) => setPurchaseOrderNumber(event.target.value)}></CambriaInput>
                  </div>
                </Row>
              </Container>
            </Form>
          );
        }}
      </Formik>
    </FabricationOrderDetailsContainer>
  );
};

export default FabricationOrderDetails;
