import React, {FC, useCallback, useEffect, useRef, useState} from 'react';
import {Formik, Form} from 'formik';
import * as Yup from 'yup';
import 'yup-phone';
import {toast} from 'react-toastify';
import {Alert, Col, Container, Row} from 'react-bootstrap';
import CambriaModal from '../../../../Framework/Components/CambriaModal';
import CambriaInput from '../../../../Framework/Components/CambriaInput';
import {
  addressSearchAsync,
  getAddressValidation,
  getStatesOrProvinces,
  selectAddressSearchResults,
  selectAddressValidation,
  selectCountries,
  selectStates,
} from '../../../../features/location/slice/location.slice';
import {useAppDispatch, useTypedSelector} from '../../../../hooks/store';
import CambriaAutosuggest from '../../../../Framework/Components/CambriaAutosuggest';
import {
  selectCurrentCustomer,
  selectCurrentCustomerShipToSites,
} from '../../../../features/customer/slice/customer.slice';
import {getEndConsumersAsync} from '../../../../features/fabrication/slice/fabrication.slice';
import {selectActiveCart} from '../../../../features/cart/slice/cart.slice';
import CambriaSelect from '../../../../Framework/Components/CambriaSelect';
import {
  postEndConsumersRequest,
  putEndConsumersRequest,
} from '../../../../features/fabrication/controller/fabrication.controller';

interface AddConsumerModalProps {
  retailSiteId: number;
  show?: boolean;
  toggleShow?: any;
  editInformation?: any;
  editMode?: boolean;
  notFabOrder?: boolean;
}

const AddConsumerModal: FC<AddConsumerModalProps> = ({
  retailSiteId,
  show,
  toggleShow,
  editInformation,
  editMode,
  notFabOrder,
}) => {
  const dispatch = useAppDispatch();
  let formikRef = useRef();

  const [isAddressValidationInProgress, setIsAddressValidationInProgress] = useState<boolean>(false);
  const [isAddingSiteInProgress, setIsAddingSiteInProgress] = useState<boolean>(false);
  const [validationErrors, setValidationErrors] = useState<Array<string>>([]);
  const [originalAddress, setOriginalAddress] = useState<string>('');
  const [showSuggestionsSection, setShowSuggestionsSection] = useState<boolean>(false);
  const [addressSuggestions, setAddressSuggestions] = useState<Array<any>>([]);
  const [selectedState, setSelectedState] = useState</*IState*/ any | undefined>(undefined);
  const [isStateRequired, setIsStateRequired] = useState<boolean>(false);
  const [selectedCountry, setSelectedCountry] = useState</*ICountry*/ any | undefined>(undefined);

  const customerShipToSites = useTypedSelector(selectCurrentCustomerShipToSites);
  const allCountries = useTypedSelector(selectCountries);
  const currentCustomer = useTypedSelector(selectCurrentCustomer);
  const states = useTypedSelector(selectStates);
  const validationResult = useTypedSelector(selectAddressValidation);
  const address = useTypedSelector(selectAddressSearchResults);
  const activeCart = useTypedSelector(selectActiveCart);

  const modalOptions = {
    heading: editMode ? 'Edit End User Address' : 'Enter New End User Address',
    cancelButtonText: 'CANCEL',
    confirmButtonText: editMode ? 'UPDATE' : 'ADD ADDRESS',
    formName: editMode ? 'editNewConsumerForm' : 'addNewConsumerForm',
  };

  const initialFormValues = {
    firstName: editInformation?.firstName || '',
    lastName: editInformation?.lastName || '',
    country: '',
    address1: editInformation?.address1 || '',
    address2: editInformation?.address2 || '',
    address3: editInformation?.address3 || '',
    city: editInformation?.city || '',
    state: '',
    postalCode: editInformation?.zipCode || '',
    phoneNumber: editInformation?.endUserPhone || '',
    email: editInformation?.endUserEmail || '',
  };

  const validationSchema = Yup.object({
    firstName: Yup.string().required('This field is required'),
    lastName: Yup.string().required('This field is required'),
    country: Yup.mixed().required('This field is required'),
    address1: Yup.string().required('This field is required'),
    address2: Yup.string(),
    address3: Yup.string(),
    city: Yup.string().required('This field is required'),
    state: isStateRequired ? Yup.mixed().required('This field is required') : Yup.mixed(),
    postalCode: Yup.string().required('This field is required'),
    phoneNumber: Yup.string(),
    email: Yup.string().email('Invalid email'),
  });

  const onAddressValidation = async (query: string) => {
    if (selectedCountry?.alpha2Code?.toLowerCase() === 'us') {
      await dispatch(addressSearchAsync({keyword: query}));
    }
  };

  const onAddressSelect = (props: any, selectedAddress: any) => {
    if (props.values && selectedAddress) {
      props.values.country = allCountries.find(
        (data: any) => data.alpha2Code.toLowerCase() === selectedAddress.countryCode2Alpha.toLowerCase()
      );
      props.values.address2 = selectedAddress.addressLine2;
      props.values.address3 = selectedAddress.addressLine3;
      props.values.city = selectedAddress.city;
      props.values.state = states.find(
        (state: any) => state.locationCode.toLowerCase() === selectedAddress.state.toLowerCase()
      );
      props.values.postalCode = selectedAddress.postalCode;
    }
  };

  const hasAddressChangedResult = (form: any, records: any) => {
    // Result codes check excluding AC01 (see http://wiki.melissadata.com/index.php?title=Result_Codes)
    const resultCodesChecked = ['AC02', 'AC03', 'AC04', 'AC05', 'AC10', 'AC11', 'AC12', 'AC13', 'AC14', 'AC20'];
    let hasAddressChange = false;
    if (records.resultCodes && records.resultCodes.length > 0) {
      for (const resultCode of records.resultCodes) {
        if (resultCodesChecked.indexOf(resultCode.code) > -1) {
          hasAddressChange = true;
        }

        // Making sure that the postal code input and response are not equal before considering AC01
        if (resultCode.code === 'AC01' && form.postalCode !== records.postalCode) {
          hasAddressChange = true;
        }
      }
    }
    return hasAddressChange;
  };

  const formatOriginalAddress = (form: any) => {
    const addressMap: any = {
      address1: form.address1,
      address2: form.address2,
      address3: form.address3,
      city: form.city,
      stateProvince: form.state && form.state.locationCode,
      postalCode: form.postalCode,
      country: form.country.countryName,
    };

    let address = '';
    for (const key in addressMap) {
      if (addressMap[key]) {
        address += `${addressMap[key]} `;
      }
    }

    setOriginalAddress(address);
  };

  const formatSuggestionAddress = (suggestion: any) => {
    const addressMap: any = {
      address1: suggestion.addressLine1,
      address2: suggestion.addressLine2,
      address3: suggestion.addressLine3,
      city: suggestion.city,
      stateProvince: suggestion.state,
      postalCode: suggestion.postalCode,
      country: suggestion.country,
    };

    let address = '';
    for (const key in addressMap) {
      if (addressMap[key]) {
        address += `${addressMap[key]} `;
      }
    }

    return address;
  };

  const onSaveSuccess = useCallback(async () => {
    setAddressSuggestions(() => []);
    await dispatch(getAddressValidation(null));
    await dispatch(addressSearchAsync({keyword: null}));
  }, [dispatch]);

  const onReset = useCallback(
    async (resetForm: Function) => {
      setAddressSuggestions(() => []);
      setShowSuggestionsSection(false);
      setSelectedState(undefined);
      resetForm({
        values: {
          firstName: '',
          lastName: '',
          country: '',
          address1: '',
          address2: '',
          address3: '',
          city: '',
          state: '',
          postalCode: '',
          phoneNumber: '',
          email: '',
        },
      });
      await dispatch(addressSearchAsync({keyword: null}));
      await dispatch(getAddressValidation(null));
    },
    [dispatch]
  );

  const saveNewAddress = useCallback(
    async (originalAddress: any, suggestion: any, formikRef: any) => {
      if (currentCustomer) {
        setIsAddingSiteInProgress(true);
        let params = {};

        if (originalAddress) {
          params = {
            address1: originalAddress.address1 ? originalAddress.address1.trim() : null,
            address2: originalAddress.address2 ? originalAddress.address2.trim() : null,
            address3: originalAddress.address3 ? originalAddress.address3.trim() : null,
            city: originalAddress.city ? originalAddress.city.trim() : null,
            countryCode: originalAddress.country.alpha2Code,
            postalCode: originalAddress.postalCode ? originalAddress.postalCode.trim() : null,
            stateProvinceCode:
              originalAddress.state && originalAddress.state.locationCode
                ? originalAddress.state.locationCode
                : originalAddress.state && originalAddress.state !== ''
                ? originalAddress.state
                : originalAddress.country.countryName,
            companyName: formikRef.values.companyName ? formikRef.values.companyName.trim() : null,
            contactFirstName: formikRef.values.firstName ? formikRef.values.firstName.trim() : null,
            contactLastName: formikRef.values.lastName ? formikRef.values.lastName.trim() : null,
            contactPhoneNumber: formikRef.values.phoneNumber ? formikRef.values.phoneNumber.trim() : null,
            contactEmail: formikRef.values.email ? formikRef.values.email.trim() : null,
          };
        }
        if (suggestion) {
          params = {
            address1: suggestion.addressLine1,
            address2: suggestion.addressLine2,
            address3: suggestion.addressLine3,
            city: suggestion.city,
            countryCode: suggestion.countryCode2Alpha,
            postalCode: suggestion.postalCode,
            stateProvinceCode: suggestion.state,
            companyName: formikRef.values.companyName ? formikRef.values.companyName.trim() : null,
            contactFirstName: formikRef.values.firstName ? formikRef.values.firstName.trim() : null,
            contactLastName: formikRef.values.lastName ? formikRef.values.lastName.trim() : null,
            contactPhoneNumber: formikRef.values.phoneNumber ? formikRef.values.phoneNumber.trim() : null,
            contactEmail: formikRef.values.email ? formikRef.values.email.trim() : null,
          };
        }
        params = {
          ...params,
          erpCustomerId: currentCustomer.erpCustomerId,
          cartId: activeCart ? activeCart.id : '',
          isForFabOrder: !notFabOrder,
          retailSiteId: retailSiteId,
        };

        if (editMode && editInformation) {
          params = {...params, id: editInformation.id, isActive: true};
          try {
            await putEndConsumersRequest(editInformation.id, params);
            toast.success('End Consumer updated successfully');
            await onSaveSuccess();
          } catch (e: any) {
            if (e && e.message) {
              toast.error(e.message);
            } else {
              toast.success('Unable to edit end consumer information.');
            }
          }
        } else {
          try {
            await postEndConsumersRequest(params);
            toast.success('End Consumer successfully added to cart.');
            await onSaveSuccess();
          } catch (e: any) {
            if (e && e.message) {
              toast.error(e.message);
            } else {
              toast.error('Unable to add end consumer to cart.');
            }
          }
        }
        try {
          await dispatch(
            getEndConsumersAsync({
              cartId: activeCart ? activeCart.id : '',
              erpCustomerId: currentCustomer.erpCustomerId,
            })
          );
        } catch (error) {
          toast.error('Unable to confirm New Address');
        }

        await onReset(formikRef.resetForm);
        setIsAddingSiteInProgress(false);
        toggleShow();
      }
    },
    [
      currentCustomer,
      activeCart,
      onSaveSuccess,
      dispatch,
      onReset,
      toggleShow,
      editInformation,
      editMode,
      retailSiteId,
      notFabOrder,
    ]
  );

  const validateAddressOnSubmit = async (form: any) => {
    setIsAddressValidationInProgress(true);
    await dispatch(getAddressValidation(form));
  };

  useEffect(() => {
    if (customerShipToSites && customerShipToSites.length > 0 && allCountries.length > 0) {
      let country;

      if (editInformation && editInformation.country) {
        country = allCountries.find(
          (data: any) =>
            data.alpha2Code.toLowerCase() === editInformation.country.toLowerCase() ||
            data.alpha3Code.toLowerCase() === editInformation.country.toLowerCase()
        );
      } else {
        country = allCountries.find(
          (data: any) =>
            data.alpha2Code.toLowerCase() === customerShipToSites[0].country?.toLowerCase() ||
            data.alpha3Code.toLowerCase() === customerShipToSites[0].country?.toLowerCase()
        );
      }

      setSelectedCountry(country);
    }
  }, [customerShipToSites, allCountries, editInformation]);

  useEffect(() => {
    if (selectedCountry && states.length > 0 && editInformation && editInformation.state) {
      setSelectedState(() => states.find((state: any) => state.locationCode === editInformation.state));
    }
  }, [selectedCountry, states, editInformation]);

  useEffect(() => {
    const validateAddress = async () => {
      if (validationResult && validationResult.totalRecords > 0) {
        const result = JSON.parse(JSON.stringify(validationResult));
        setValidationErrors(validationResult.records.resultCodes);
        const suggestions: any = [];
        const formValues = JSON.parse(JSON.stringify((formikRef as any).values));

        if (result.records.status === 'Pass') {
          if (hasAddressChangedResult(formValues, result.records)) {
            if (result.records.postalCodeExtension) {
              result.records.postalCode += `-${result.records.postalCodeExtension}`;
            }
            formatOriginalAddress(formValues);
            setShowSuggestionsSection(true);
            suggestions.push(result.records);
          } else {
            if (isAddressValidationInProgress) {
              await saveNewAddress(formValues, null, formikRef);
              setShowSuggestionsSection(false);
            }
          }
        } else {
          if (result.suggestions) {
            for (const address of result.suggestions.address) {
              if (address.postalCodeExtension) {
                address.postalCode += `-${address.postalCodeExtension}`;
              }
              suggestions.push(address);
            }
          }
          formatOriginalAddress(formValues);
          setShowSuggestionsSection(true);
        }

        if (addressSuggestions.length === 0 && suggestions.length > 0) {
          setAddressSuggestions(suggestions);
        }
        setIsAddressValidationInProgress(false);
      } else {
        setShowSuggestionsSection(false);
      }
    };

    validateAddress();
  }, [validationResult, addressSuggestions.length, isAddressValidationInProgress, saveNewAddress]);

  useEffect(() => {
    const getStatesForSelectedCountry = async () => {
      if (selectedCountry) {
        await dispatch(getStatesOrProvinces({countryName: selectedCountry.countryName}));
        setIsAddressValidationInProgress(false);
      }
    };

    getStatesForSelectedCountry();
    setIsStateRequired(selectedCountry && selectedCountry?.hasStates ? true : false);
  }, [selectedCountry, dispatch]);

  return (
    <Formik
      innerRef={(ref: any) => (formikRef = ref)}
      initialValues={initialFormValues}
      validationSchema={validationSchema}
      onSubmit={(props) => {
        validateAddressOnSubmit(props);
      }}>
      {(props) => {
        return (
          <CambriaModal
            show={show}
            toggleShow={toggleShow}
            onCancel={() => {
              onReset(props.resetForm);
            }}
            heading={modalOptions.heading}
            cancelButton={modalOptions.cancelButtonText}
            confirmButton={modalOptions.confirmButtonText}
            disableSubmitBtn={isAddressValidationInProgress || isAddingSiteInProgress}
            formName={modalOptions.formName}>
            <Form id={modalOptions.formName} onSubmit={props.handleSubmit} onReset={props.handleReset} noValidate>
              <Container className="p-0">
                <Row>
                  <Col md={6} sm={12}>
                    <CambriaInput
                      name="firstName"
                      defaultValue={props.values.firstName || initialFormValues.firstName}
                      label="End User First Name"
                      placeholder="End User First Name"
                      type="text"
                      required={true}
                      disabled={isAddressValidationInProgress}
                    />
                  </Col>
                  <Col md={6} sm={12}>
                    <CambriaInput
                      name="lastName"
                      defaultValue={props.values.lastName || initialFormValues.lastName}
                      label="End User Last Name"
                      placeholder="End User Last Name"
                      type="text"
                      required={true}
                      disabled={isAddressValidationInProgress}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <CambriaSelect
                      formikFormProps={props}
                      name="country"
                      defaultValue={selectedCountry}
                      items={allCountries}
                      label="Country"
                      placeholder="Country"
                      displayValue="countryName"
                      onChange={async (selection: any /*ICountry*/) => {
                        setIsAddressValidationInProgress(true);
                        setSelectedCountry(selection);
                        if (selection.alpha2Code !== 'US') {
                          setAddressSuggestions(() => []);
                          await dispatch(addressSearchAsync({keyword: null}));
                          await dispatch(getAddressValidation(null));
                        }
                        props.setFieldValue('address1', '');
                        props.setFieldValue('address2', '');
                        props.setFieldValue('address3', '');
                        props.setFieldValue('city', '');
                        props.setFieldValue('postalCode', '');
                        props.setFieldValue('state', '');
                      }}
                      required={true}
                      disabled={isAddressValidationInProgress}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <CambriaAutosuggest
                      name="address1"
                      isAddressSearch={true}
                      defaultValue={props.values.address1 || initialFormValues.address1}
                      options={address}
                      onSearch={(query: string) => onAddressValidation(query)}
                      onSelect={(address: any) => onAddressSelect(props, address)}
                      label="Street Address"
                      placeholder="Street Address"
                      displayValue="addressLine1"
                      required={true}
                      disabled={isAddressValidationInProgress}></CambriaAutosuggest>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <CambriaInput
                      name="address2"
                      defaultValue={props.values.address2 || initialFormValues.address2}
                      label="Apartment or Suite Number"
                      placeholder="Apartment or Suite Number"
                      type="text"
                      disabled={isAddressValidationInProgress}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <CambriaInput
                      name="address3"
                      defaultValue={props.values.address3 || initialFormValues.address3}
                      label="Address Line 3"
                      placeholder="Address Line 3"
                      type="text"
                      disabled={isAddressValidationInProgress}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <CambriaInput
                      name="city"
                      defaultValue={props.values.city || initialFormValues.city}
                      label="City"
                      placeholder="City"
                      type="text"
                      required={true}
                      disabled={isAddressValidationInProgress}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={6} sm={12}>
                    <CambriaSelect
                      formikFormProps={props}
                      name="state"
                      defaultValue={selectedState}
                      items={states}
                      label="State / Province"
                      placeholder="Select State / Province"
                      displayValue="stateName"
                      required={isStateRequired}
                      disabled={isAddressValidationInProgress}
                    />
                  </Col>
                  <Col md={6} sm={12}>
                    <CambriaInput
                      name="postalCode"
                      defaultValue={props.values.postalCode || initialFormValues.postalCode}
                      label="Postal Zip Code"
                      placeholder="Postal Zip Code"
                      type="text"
                      required={true}
                      disabled={isAddressValidationInProgress}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <CambriaInput
                      name="phoneNumber"
                      defaultValue={props.values.phoneNumber || initialFormValues.phoneNumber}
                      label="End User Phone"
                      placeholder="End User Phone"
                      type="phoneNumber"
                      disabled={isAddressValidationInProgress}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <CambriaInput
                      name="email"
                      defaultValue={props.values.email || initialFormValues.email}
                      label="End User Email"
                      placeholder="End User Email"
                      type="text"
                      disabled={isAddressValidationInProgress}
                    />
                  </Col>
                </Row>
                <Row>
                  {showSuggestionsSection && (
                    <Col>
                      <Alert variant="info" dismissible>
                        <div className="m-4">New Shipping Address cannot be validated</div>
                        <hr />
                        <div className="m-4">
                          {validationErrors.map((error: any) => (
                            <div key={error.code}>
                              {error.code}: {error.message}
                            </div>
                          ))}
                        </div>
                        <hr />
                        {addressSuggestions.length > 0 && (
                          <div className="m-4">
                            <strong>Keep the Original Address</strong>
                            <div className="radio">
                              <input
                                type="radio"
                                id="enteredAddress"
                                onClick={() => saveNewAddress(props.values, null, formikRef)}
                                disabled={isAddingSiteInProgress}
                              />
                              <label htmlFor="enteredAddress">{originalAddress}</label>
                            </div>
                            <strong>Update the Address</strong>
                            {addressSuggestions.map((suggestion, i) => {
                              return (
                                <div className="radio" key={i}>
                                  <input
                                    type="radio"
                                    id={i.toString()}
                                    onClick={() => saveNewAddress(null, suggestion, formikRef)}
                                    disabled={isAddingSiteInProgress}
                                  />
                                  <label htmlFor={i.toString()}>{formatSuggestionAddress(suggestion)}</label>
                                </div>
                              );
                            })}
                          </div>
                        )}
                        {addressSuggestions.length === 0 && (
                          <div className="m-4">
                            There are no suggested address for your inputs.
                            <br />
                            Please review the form and try again or save this address.
                            <br />
                            <div className="radio">
                              <input
                                type="radio"
                                id="enteredAddress"
                                onClick={() => saveNewAddress(props.values, null, formikRef)}
                                disabled={isAddingSiteInProgress}
                              />
                              <label htmlFor="enteredAddress">{originalAddress}</label>
                            </div>
                          </div>
                        )}
                      </Alert>
                    </Col>
                  )}
                </Row>
              </Container>
            </Form>
          </CambriaModal>
        );
      }}
    </Formik>
  );
};

export default AddConsumerModal;
