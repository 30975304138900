import React, {useCallback, useEffect, useState} from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import {CustomerFullProfile, ShipToSite} from '../../../../features/customer/ICustomerState';
import CambriaButton from '../../../../Framework/Components/CambriaButton';
import Icon from '../../../../Framework/Components/Icon';
import {useAppDispatch, useTypedSelector} from '../../../../hooks/store';
import {Formik} from 'formik';
import {getAllAvailableCountries} from '../../../../features/location/slice/location.slice';
import {selectCurrentCustomer, selectSiteInfo} from '../../../../features/customer/slice/customer.slice';
import {setAvailableWarehouses, setSelectedWarehouse} from '../../../../features/warehouse/slice/warehouse.slice';
import AddShippingAddressModal from '../../../../Core/Components/Modals/AddShippingAddressModal';
import {getWarehouses} from '../../../../features/warehouse/service/warehouse.service';
import CambriaSelect from '../../../../Framework/Components/CambriaSelect';
import AddressCard from '../../../../Framework/Components/Card/AddressCard';

const BasicInfo = () => {
  const dispatch = useAppDispatch();

  const [distinctWarehouseCodes, setDistinctWarehouseCodes] = useState<Array<string>>([]);
  const [activeWarehouse, setActiveWarehouse] = useState<ShipToSite | null>(null);
  const [allAvailableWarehouses, setAllAvailableWarehouses] = useState<ShipToSite[]>([]);
  const [filteredCustomerShipToSites, setFilteredCustomerShipToSites] = useState<Array<ShipToSite>>([]);
  const [show, setShow] = useState(false);
  const toggleShow = () => setShow((p) => !p);

  const currentCustomer: CustomerFullProfile | null = useTypedSelector(selectCurrentCustomer);
  const customerSites = useTypedSelector(selectSiteInfo);

  const changeSelectedWarehouse = (warehouse: ShipToSite) => {
    if (warehouse && activeWarehouse?.warehouseCode !== warehouse?.warehouseCode) {
      setActiveWarehouse(() => warehouse);
    }
  };

  const filterCustomerSites = useCallback(() => {
    const tempCustomerSites = JSON.parse(JSON.stringify(customerSites));
    tempCustomerSites.map((site: ShipToSite) => (site.isPending = site.erpAccountSiteId === 0));
    const tempFilteredCustomerShipToSites = tempCustomerSites.filter(
      (site: ShipToSite) => site.warehouseCode === activeWarehouse?.warehouseCode
    );
    setFilteredCustomerShipToSites(tempFilteredCustomerShipToSites);

    const newDistinctWarehouseCodes = tempCustomerSites
      .map((x: any) => x.warehouseCode)
      .filter(
        (value: any, index: number, self: any) => self.indexOf(value) === index && value !== undefined && value !== null
      );

    if (JSON.stringify(newDistinctWarehouseCodes) !== JSON.stringify(distinctWarehouseCodes)) {
      setDistinctWarehouseCodes(newDistinctWarehouseCodes);
    }
  }, [activeWarehouse?.warehouseCode, customerSites, distinctWarehouseCodes]);

  const formatWarehouses = useCallback(
    (warehouseData: ShipToSite[]) => {
      setAllAvailableWarehouses(() => warehouseData);
      dispatch(setAvailableWarehouses(warehouseData));
      if (currentCustomer) {
        const defaultWarehouse = warehouseData.find(
          (warehouse: ShipToSite) => warehouse.warehouseCode === currentCustomer.defaultWarehouseCode
        );
        setActiveWarehouse(defaultWarehouse || null);
        dispatch(setSelectedWarehouse(defaultWarehouse));
      }
    },
    [currentCustomer, dispatch]
  );

  useEffect(() => {
    const loadAvailableWarehouseData = async () => {
      let warehouseData = await getWarehouses(distinctWarehouseCodes);
      formatWarehouses(warehouseData);
    };
    if (distinctWarehouseCodes.length > 0) {
      loadAvailableWarehouseData();
    }
  }, [distinctWarehouseCodes, formatWarehouses]);

  useEffect(() => {
    dispatch(getAllAvailableCountries());
  }, [dispatch]);

  useEffect(() => {
    if (customerSites) {
      filterCustomerSites();
    }
  }, [filterCustomerSites, customerSites]);

  return (
    <div>
      <Container className="p-0">
        <Row>
          <Col xs={12} sm={12} md={6} lg={4}>
            <div className="p-b-md p-t-md">
              <Formik enableReinitialize initialValues={{warehouseSelector: activeWarehouse}} onSubmit={() => {}}>
                {allAvailableWarehouses.length > 1 && (
                  <CambriaSelect
                    name="warehouseSelector"
                    label="Warehouse:"
                    defaultValue={activeWarehouse}
                    items={allAvailableWarehouses}
                    displayValue="displayName"
                    placeholder=""
                    onChange={changeSelectedWarehouse}
                  />
                )}
              </Formik>
              {allAvailableWarehouses.length === 1 && (
                <span className="d-flex justify-content-start">
                  <em className="fa fa-industry m-r-sm"></em>
                  <span className="active-warehouse-name">{activeWarehouse?.displayName}</span>
                </span>
              )}
            </div>
          </Col>
        </Row>
      </Container>
      <Container className="p-0">
        <Row>
          {filteredCustomerShipToSites.map((shipToSite: any) => (
            <Col md={12} lg={6} xl={4} key={shipToSite.id} className="p-0">
              <div className="m-4">
                <AddressCard site={shipToSite}></AddressCard>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
      <Container className="p-l-0 p-r-0 p-t-sm p-b-md">
        <Row>
          <Col xs={12} sm={12} md={6} lg={4}>
            <CambriaButton onClick={toggleShow} variant="secondary">
              <Icon icon="icons-cambria-Symbols-Plus" color="#c59617" size="15" weight="bold" />
              Request a New Address
            </CambriaButton>
            <AddShippingAddressModal show={show} toggleShow={toggleShow} activeWarehouse={activeWarehouse} />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default BasicInfo;
