import {baseApiCallWithReauth} from '../../../Framework/api-utils/api-utils';
import COMMERCE_CORE_CONSTANTS from '../../../Core/constants';
import {CartItem} from '../../cart/ICartState';
import {CustomerFullProfile} from '../../customer/ICustomerState';
import {IGetProductGroupItemsByCodeRequest} from '../../product/IProductState';
import qs from 'qs';
import {
  IGetItemBySkuResponse,
  ProductCatalogCartItemUpdateRequest,
  IBundleProgramRule,
  Item,
} from '../IProductCatalogState';

export const deleteCartItemsWithParamsRequest = async (itemNumber: string, cartId: string) => {
  try {
    return await baseApiCallWithReauth(
      'DELETE',
      `${COMMERCE_CORE_CONSTANTS.API_SERVICES.PRODUCT.cartItems}/${itemNumber}`,
      qs.stringify({
        cartId: cartId,
      }),
      true,
      false,
      true
    );
  } catch (error) {
    throw error;
  }
};

export const deleteCartItemsByItemNumberRequest = async (itemNumber: string, cartId: string, cartItemId: number) => {
  try {
    return await baseApiCallWithReauth(
      'DELETE',
      `${COMMERCE_CORE_CONSTANTS.API_SERVICES.PRODUCT.cartItems}/${itemNumber}`,
      qs.stringify({
        cartId: cartId,
        cartItemId: cartItemId,
      })
    );
  } catch (error) {
    throw error;
  }
};

export const deleteSavedItemsByNumberRequest = async (
  cartItem: CartItem,
  currentCustomer: CustomerFullProfile
): Promise<any> => {
  try {
    return await baseApiCallWithReauth(
      'DELETE',
      `${COMMERCE_CORE_CONSTANTS.API_SERVICES.PRODUCT.savedItems}/${cartItem.itemNumber}`,
      qs.stringify({
        erpCustomerId: currentCustomer.erpCustomerId,
      }),
      true
    );
  } catch (error: any) {
    throw error;
  }
};

export const getProductsByIdRequest = async (productId: string, params: IGetProductGroupItemsByCodeRequest) => {
  try {
    return await baseApiCallWithReauth(
      'GET',
      `${COMMERCE_CORE_CONSTANTS.API_SERVICES.PRODUCT.products}/${productId}`,
      qs.stringify(params)
    );
  } catch (error: any) {
    throw error;
  }
};

export const genericServiceGetRequest = async (endpoint: string, data: URLSearchParams) => {
  try {
    return await baseApiCallWithReauth('GET', endpoint, data);
  } catch (error: any) {
    throw error;
  }
};

export const getProductGroupsRequest = async (endpoint: string, data?: string) => {
  try {
    return await baseApiCallWithReauth('GET', endpoint, data);
  } catch (error: any) {
    throw error;
  }
};

export const postCartItemsRequest = async (params: any) => {
  try {
    return await baseApiCallWithReauth(
      'POST',
      COMMERCE_CORE_CONSTANTS.API_SERVICES.PRODUCT.cartItems,
      JSON.stringify(params),
      true,
      false,
      true
    );
  } catch (error) {
    throw error;
  }
};

export const postProductCartItemsRequest = async (params: any) => {
  try {
    return await baseApiCallWithReauth(
      'POST',
      COMMERCE_CORE_CONSTANTS.API_SERVICES.PRODUCT.cartItems,
      JSON.stringify(params),
      true
    );
  } catch (error) {
    throw error;
  }
};

export const getBatchCartItemsAddSizeRequest = async () => {
  try {
    return await baseApiCallWithReauth('GET', COMMERCE_CORE_CONSTANTS.API_SERVICES.PRODUCT.batchCartItemsAddSize);
  } catch (error: any) {
    throw error;
  }
};

export const postBatchCartItemsAddSizeRequest = async (params: any) => {
  try {
    return await baseApiCallWithReauth(
      'POST',
      COMMERCE_CORE_CONSTANTS.API_SERVICES.PRODUCT.batchCartItems,
      JSON.stringify(params),
      true
    );
  } catch (error: any) {
    throw error;
  }
};

export const postSavedItemsRequest = async (cartItem: CartItem, customer: CustomerFullProfile): Promise<any> => {
  try {
    return await baseApiCallWithReauth(
      'POST',
      COMMERCE_CORE_CONSTANTS.API_SERVICES.PRODUCT.savedItems,
      JSON.stringify({
        productId: cartItem.productId,
        itemNumber: cartItem.itemNumber,
        erpCustomerId: customer.erpCustomerId,
      })
    );
  } catch (error: any) {
    throw error;
  }
};

export const putCartItemsRequest = async (cartItemId: number, params: ProductCatalogCartItemUpdateRequest) => {
  try {
    return await baseApiCallWithReauth(
      'PUT',
      `${COMMERCE_CORE_CONSTANTS.API_SERVICES.PRODUCT.cartItems}/${cartItemId}`,
      JSON.stringify(params),
      true
    );
  } catch (error: any) {
    throw error;
  }
};

export const getItemDetailsBySkuRequest = async (sku: string): Promise<IGetItemBySkuResponse> => {
  try {
    return await baseApiCallWithReauth('GET', `${COMMERCE_CORE_CONSTANTS.API_SERVICES.PRODUCT.itemDetails}${sku}`);
  } catch (error: any) {
    throw error;
  }
};

export const getBundleProgramRules: () => Promise<Array<IBundleProgramRule>> = async () => {
  return await baseApiCallWithReauth('GET', COMMERCE_CORE_CONSTANTS.API_SERVICES.PRODUCT.bundleProgramRules);
};

export const getItems = async (itemNumbers: string[]): Promise<Array<Item>> => {
  let apiData = new URLSearchParams();
  itemNumbers.forEach((item) => {
    apiData.append('itemNumbers[]', item);
  });
  const results = await baseApiCallWithReauth('GET', COMMERCE_CORE_CONSTANTS.API_SERVICES.PRODUCT.batchItems, apiData);
  return results.results;
};
