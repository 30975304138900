import React, {useCallback, useEffect} from 'react';
import {Product} from '../../../../../features/productCatalog/IProductCatalogState';
import {useHistory} from 'react-router-dom';
import {setUpdatedProducts} from '../../../../../features/productCatalog/slice/productCatalog.slice';
import {useAppDispatch} from '../../../../../hooks/store';
import ProductPlaceholders from '../Placeholders';
import {pushSelectItemInformationToDataLayer} from '../../../../../features/analytics/service/analytics.service';

const ProductMapping = (props: {
  productType: string | null;
  isSearchResults: boolean;
  limit: number;
  totalProducts: number;
  products: Product[];
}) => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const setDefaultImage = (attachments: any): any => {
    const defaultImage = attachments.find((attachment: any) => attachment.type === 'default');
    if (defaultImage) {
      return defaultImage.location;
    }
  };

  const generateProductData = useCallback((product: any): any => {
    let tempProduct = JSON.parse(JSON.stringify(product));
    tempProduct.tag = '';
    if (tempProduct.isOnSale) {
      tempProduct.tag = 'SALE';
    }
    if (tempProduct.isFeatured) {
      tempProduct.tag = 'FEATURED';
    }
    if (tempProduct.isNew) {
      tempProduct.tag = 'NEW';
    }

    const dimensions = '450x215px';

    if (tempProduct.attachments && tempProduct.attachments.length > 0) {
      for (const attachment of tempProduct.attachments) {
        attachment.location = `${attachment.location
          .replace('{size}px@{scale}x', dimensions)
          .replace('q={quality}', 'q=100')}&c=0`;
      }
    }

    return tempProduct;
  }, []);

  const productDetailsNav = (product: any) => {
    pushSelectItemInformationToDataLayer(product);
    let url = '/product/details/' + product.id;
    history.push(url);
  };

  useEffect(() => {
    if (
      props.products &&
      props.products.length > 0 &&
      props.products[props.products.length - 1].attachments &&
      props.products[props.products.length - 1].attachments.length > 0 &&
      props.products[props.products.length - 1].attachments[0].location.includes('{size}px@{scale}x')
    ) {
      const updatedProducts: Product[] = [];
      for (let product of props.products) {
        updatedProducts.push(generateProductData(product));
      }
      dispatch(setUpdatedProducts(updatedProducts));
    }
  }, [props, props.products, dispatch, generateProductData]);

  return props.products &&
    props.products.length > 0 &&
    !props.products[0].attachments[0].location.includes('{size}px@{scale}x') ? (
    props.products.map((product: any, $index: number) => {
      return (
        <div
          key={'product-image-tile-' + $index}
          className="col-xs-12 col-md-6 product-item"
          onClick={() => {
            productDetailsNav(product);
          }}>
          <div className="img img-tile img-responsive no-border product-item-tile">
            <div className="priority-product-flag-container">
              {product.isNew || product.isFeatured || product.isOnSale ? (
                <div className="grid-priority-product">{product.tag}</div>
              ) : (
                <div></div>
              )}
              {product.luxuryDesign ? (
                <div className="luxury-design">
                  <img
                    className="luxury-logo"
                    src="https://embed.widencdn.net/img/cambriausa/uem2bbifz9/exact/luxury-series-logo-1000x176.png"
                    alt="Luxury Design"
                  />
                </div>
              ) : (
                <div></div>
              )}
              {product.designSeries === 'Signature' ? (
                <div className="signature-design">
                  <img
                    className="signature-logo"
                    src="https://embed.widencdn.net/img/cambriausa/2f02ebfbjh/exact/Signature Series_Final_outline.png"
                    alt="Signature Design"
                  />
                </div>
              ) : (
                <div></div>
              )}
              {product.designSeries === 'Grandeur' ? (
                <div className="grandeur-design">
                  <img
                    className="grandeur-logo"
                    src="https://cambriausa.widen.net/content/wqbhjrqexx/png/Grandeur-logo_2022.png?crop=false&position=c&color=ffffff00&u=jjx44y&w=931&h=217"
                    alt="Grandeur Design"
                  />
                </div>
              ) : (
                <div></div>
              )}
            </div>
            <div className="product-image-container">
              <img className="product-image" src={setDefaultImage(product.attachments)} alt={product.displayName} />
            </div>
            <div className="img-text product-detail">
              <div className="title product-name">{product.displayName}</div>
              <span className="product-type">
                <br className="visible-xs title" />
                {product.productType.toUpperCase()}
              </span>
            </div>
          </div>
        </div>
      );
    })
  ) : (
    <div>
      <ProductPlaceholders></ProductPlaceholders>
      <ProductPlaceholders></ProductPlaceholders>
      <ProductPlaceholders></ProductPlaceholders>
      <ProductPlaceholders></ProductPlaceholders>
      <ProductPlaceholders></ProductPlaceholders>
      <ProductPlaceholders></ProductPlaceholders>
    </div>
  );
};

export default ProductMapping;
