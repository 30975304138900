import React from 'react';
import {toast} from 'react-toastify';
import {Cart, CartItem, OrderDetail} from '../../cart/ICartState';
import {CustomerFullProfile, Site} from '../../customer/ICustomerState';
import {getShippingWarehouse} from '../../warehouse/service/warehouse.service';
import {getCartItemsClassifications, getCartItemsWeight} from '../../cart/service/cart.service';
import {
  addCustomerSite,
  formatAddress,
  getCurrentCustomerId,
  getCustomerShipToSitesFilteredByWarehouse,
} from '../../customer/service/customer.service';
import {setActiveCart} from '../../cart/slice/cart.slice';
import {callback} from '../../callbacks/service/callbacks.service';
import {IAddCartPaymentMethodRequest, PaymentSelection, PaymentType} from '../../payment/IPaymentState';
import {addPaymentMethod, removePaymentMethod} from '../../payment/service/payment.service';
import {getCreditCards} from '../../payment/slice/payment.slice';
import {calculateTax} from '../../tax/service/tax.service';
import {ICambriaOrderMetaData, ProductType} from '../../order/orderDetails/IOrderDetailsState';
import COMMERCE_CORE_CONSTANTS from '../../../Core/constants';
import {
  deleteCartShippingMethodsRequest,
  getHolidayRequest,
  getSamplesOrderPreferencesRequest,
  getShippingSelectionsRequest,
  getShippingZonePricingRequest,
  getShippingZoneRequest,
  getSlabOrderPreferencesRequest,
  postCartOrderDetailsRequest,
  postCartShippingMethodsRequest,
} from '../controller/checkout.controller';
import {postCustomerContactRequest} from '../../customer/controller/customer.controller';
import {placeOrder, resubmitOrder, updateOrder} from '../../order/service/order.service';
import {addDeliverySchedule} from '../../product/service/product.service';
import {pushAddPaymentInfoToDataLayer} from '../../analytics/service/analytics.service';

export const getAllProductTypesBasedOnCartItems = (selectedCart: Cart | null): string[] => {
  if (selectedCart) {
    let uniqueProductTypes: string[] = [];

    if (selectedCart && selectedCart.cartItems && selectedCart.cartItems.length > 0) {
      uniqueProductTypes = selectedCart.cartItems.map((item: CartItem) => item.productType);
      uniqueProductTypes = uniqueProductTypes.filter(
        (value: string, index: any, array: any) => array.indexOf(value) === index
      );
    }

    return uniqueProductTypes;
  } else {
    return [];
  }
};

export const getShippingSelectionsParams = async (
  shipToSite: any,
  activeCart: Cart,
  activeProgram: any,
  currentCustomer: CustomerFullProfile,
  currentCustomerShipToSites: Site[],
  useUpsEndpoint?: boolean
) => {
  let params = {} as any;
  const warehouse = await getShippingWarehouse(activeCart, currentCustomer, currentCustomerShipToSites);
  const crossdockWarehouseCode = !warehouse ? '' : warehouse.crossDockWarehouseCode;
  const productTypes = getAllProductTypesBasedOnCartItems(activeCart);
  const programCode = activeProgram.code;

  params.warehouseCode = warehouse?.warehouseCode.toString();
  params.productType = productTypes;
  params.shippingClassifications = activeCart ? getCartItemsClassifications(activeCart) : null;
  params.crossdockWarehouseCode = crossdockWarehouseCode;
  params.programCode = programCode?.toString();

  if (!params.shippingClassifications || params.shippingClassifications.length === 0) {
    params.shippingClassifications = [''];
  }

  if (programCode === 'HD001' && productTypes[0] === 'Slab') {
    params.qualifiesforFreeShippingForHomeDepot = true;
  }

  if (activeCart && shipToSite) {
    if (useUpsEndpoint) {
      params.shipToName = shipToSite.siteName || shipToSite.SiteName;
      params.shipToAddressLine1 = shipToSite.address1 || shipToSite.Address1;
      params.shipToAddressLine2 = shipToSite.address2 || shipToSite.Address2;
      params.shipToAddressLine3 = shipToSite.address3 || shipToSite.Address3;
      params.shipToCity = shipToSite.city || shipToSite.City;
      params.shipToStateProvinceCode = shipToSite.stateProvince || shipToSite.StateProvinceCode;
      params.shipToPostalCode = shipToSite.postalCode?.toString() || shipToSite.PostalCode;
      params.shipToCountryCode = shipToSite.country || shipToSite.CountryCode;
      params.weight = getCartItemsWeight(activeCart) / 100;
      params.monetaryValue = activeCart.totalAmount / 100;
      params.currencyCode = activeCart.cartItems[0].currency;
    } else {
      params.shipToCountry = shipToSite.country || shipToSite.Country;
      params.shipmentWeight = parseInt(getCartItemsWeight(activeCart).toString(), 10);
    }
  }

  return params;
};

export const addContactDisplayNamePart = (stringPart: string, displayName: string) => {
  if (stringPart) {
    if (displayName) {
      displayName += ' ' + stringPart;
    } else {
      displayName = stringPart;
    }
  }
  return displayName;
};

export const getContactDisplayName = (contact: any) => {
  let displayName = '';
  if (!contact) {
    return displayName;
  }
  displayName = addContactDisplayNamePart(contact.firstName, displayName);
  displayName = addContactDisplayNamePart(contact.lastName, displayName);
  if (Array.isArray(contact.email) && contact.email.length) {
    displayName = addContactDisplayNamePart(contact.email[0], displayName);
  }
  if (Array.isArray(contact.phoneNumber) && contact.phoneNumber.length) {
    displayName = addContactDisplayNamePart(contact.phoneNumber[0], displayName);
  } else if (Array.isArray(contact.phone) && contact.phone.length) {
    displayName = addContactDisplayNamePart(contact.phone[0], displayName);
  }
  return displayName;
};

export const getContactOptions = (
  shipToSites: Site[],
  activeCart?: Cart,
  currentCustomer?: CustomerFullProfile,
  shipToSite?: Site,
  operatingUnitCode?: string,
  isSlab?: boolean
) => {
  let currentCustomerShipToSites: Site[] = [];
  if (activeCart && currentCustomer) {
    currentCustomerShipToSites = getCustomerShipToSitesFilteredByWarehouse(
      activeCart as any,
      currentCustomer,
      shipToSites
    );
  } else {
    currentCustomerShipToSites = shipToSites;
  }

  let shipToContacts = [];
  if (currentCustomerShipToSites) {
    for (const shipToSite of currentCustomerShipToSites) {
      if ((operatingUnitCode && shipToSite.operatingUnitCode === operatingUnitCode) || !operatingUnitCode) {
        const contacts = JSON.parse(JSON.stringify(shipToSite.contacts));
        for (const contact of contacts) {
          if (
            (isSlab &&
              contact.email &&
              contact.email.length > 0 &&
              contact.role === 'Ship To' &&
              contact.status === 'A') ||
            (!isSlab && contact.role === 'Ship To' && contact.status === 'A')
          ) {
            const contactOption = {
              ...contact,
              customerSiteId: shipToSite.id,
              displayName: getContactDisplayName(contact),
            };

            shipToContacts.push(contactOption);
          }
        }
      }
    }
  }

  if (shipToSite && shipToContacts.length > 0) {
    shipToContacts = shipToContacts
      .filter((contact) => contact.customerSiteId === shipToSite.id)
      .sort((a, b) => {
        return a.displayName.localeCompare(b.displayName);
      });
  }

  return shipToContacts;
};

export const onSelectDeliveryDate = async (requestedDeliveryDate: Date, selectedCart: Cart, dispatch: any) => {
  const scheduledItems: any = [];
  const cartItems = JSON.parse(JSON.stringify(selectedCart?.cartItems));
  const formattedDate = new Date(requestedDeliveryDate).toLocaleDateString('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });

  if (selectedCart && cartItems && cartItems.length > 0) {
    cartItems.forEach((item: any) => {
      const isSeamedQuantity = item.quantity === 2 || item.quantity === 3;
      scheduledItems.push({
        cartItemId: item.cartItemId,
        quantity: item.quantity,
        imageUrl: item.imageUrl,
        description: item.description,
        cartId: selectedCart.id,
        requestedDeliveryDate: formattedDate,
        seamed: isSeamedQuantity,
      });

      item.requestedDeliveryDate = requestedDeliveryDate;
      item.seamed = isSeamedQuantity;
    });

    const result = await addDeliverySchedule(scheduledItems, dispatch);

    if (!result) {
      return null;
    } else {
      return scheduledItems;
    }
  }
};

export const getDatesDisabledByHolidays = async () => {
  const holidaysDiabled: Array<Date> = [];
  const holidays = await getHolidayRequest(null, 'US');

  holidays.forEach((holiday: any) => {
    holidaysDiabled.push(new Date(holiday.date));
  });
  return holidaysDiabled;
};

export const disableNextWorkingDay = () => {
  let minDate = new Date();

  if (minDate.getDay() === 5) {
    // if order on Friday should disable next Monday
    minDate.setDate(minDate.getDate() + 4);
  } else {
    // otherwise just disable next day
    minDate.setDate(minDate.getDate() + 2);
  }

  return new Date(minDate);
};

export const getDaysDisabledByCustomerPreferences = async (deliveryMethod: string, erpCustomerId: string) => {
  let customerDaysDisabled = [];
  let orderPreferences;

  if (deliveryMethod === 'Cambria Delivery' || deliveryMethod === 'Cambria Delivery - PrePay and Absorb') {
    try {
      orderPreferences = await getSlabOrderPreferencesRequest(erpCustomerId);
    } catch (error) {
      orderPreferences = null;
    }
  } else {
    try {
      orderPreferences = await getSamplesOrderPreferencesRequest(erpCustomerId);
    } catch (error) {
      orderPreferences = null;
    }
  }

  if (orderPreferences) {
    if (orderPreferences.canPickSunday === false) {
      customerDaysDisabled.push(0);
    }
    if (orderPreferences.canPickMonday === false) {
      customerDaysDisabled.push(1);
    }
    if (orderPreferences.canPickTuesday === false) {
      customerDaysDisabled.push(2);
    }
    if (orderPreferences.canPickWednesday === false) {
      customerDaysDisabled.push(3);
    }
    if (orderPreferences.canPickThursday === false) {
      customerDaysDisabled.push(4);
    }
    if (orderPreferences.canPickFriday === false) {
      customerDaysDisabled.push(5);
    }
    if (orderPreferences.canPickSaturday === false) {
      customerDaysDisabled.push(6);
    }
  } else {
    customerDaysDisabled = [0, 1, 2, 3, 4, 5, 6];
  }
  return customerDaysDisabled;
};

export const disableDayAfterHolidayIfNeeded = (holidaysDiabled: Array<string>) => {
  const today = new Date();
  const tomorrow = today.getDate() + 1;
  const thisMonth = today.getMonth();
  const thisYear = today.getFullYear();
  holidaysDiabled.forEach((holiday, index) => {
    // check if tomorrow is holiday
    if (
      new Date(holiday).getDate() === tomorrow &&
      new Date(holiday).getMonth() === thisMonth &&
      new Date(holiday).getFullYear() === thisYear
    ) {
      // check if the day after tomorrow is also holiday
      if (new Date(holidaysDiabled[index + 1]).getDate() === tomorrow + 1) {
        // if order on Wednesday and Thursday and Friday are holidays should disable next Monday
        if (today.getDay() === 3) {
          const disabledDateAfterHoliday = new Date(today.setDate(today.getDate() + 5)).toLocaleDateString();
          holidaysDiabled.push(disabledDateAfterHoliday);
          // if order on Sunday and Monday and Tuesday are holidays should disable Wednesday
          // if order on Monday and Tuesday and Wednesday are holidays should disable Thursday
          // if order on Tuesday and Wednesday and Thursday are holidays should disable Friday
        } else {
          const disabledDateAfterHoliday = new Date(today.setDate(today.getDate() + 3)).toLocaleDateString();
          holidaysDiabled.push(disabledDateAfterHoliday);
        }
      } else {
        // if order on Sunday and Monday is holiday should disable Tuesday
        // if order on Monday and Tuesday is holiday should disable Wednesday
        // if order on Tuesday and Wednesday is holiday should disable Thursday
        // if order on Wednesday and Thursday is holiday should disable Friday
        if (today.getDay() === 0 || today.getDay() === 1 || today.getDay() === 2 || today.getDay() === 3) {
          const disabledDateAfterHoliday = new Date(today.setDate(today.getDate() + 2)).toLocaleDateString();
          holidaysDiabled.push(disabledDateAfterHoliday);
        }
        // if order on Thursday and Friday is holiday should disable next Monday
        if (today.getDay() === 4) {
          const disabledDateAfterHoliday = new Date(today.setDate(today.getDate() + 4)).toLocaleDateString();
          holidaysDiabled.push(disabledDateAfterHoliday);
        }
      }
    }

    // if order on Friday and next Monday is holiday should disable next Tuesday
    if (
      today.getDay() === 5 &&
      new Date(holiday).getDate() === tomorrow + 2 &&
      new Date(holiday).getMonth() === thisMonth &&
      new Date(holiday).getFullYear() === thisYear
    ) {
      const disabledDateAfterHoliday = new Date(today.setDate(today.getDate() + 4)).toLocaleDateString();
      holidaysDiabled.push(disabledDateAfterHoliday);
    }
  });

  return holidaysDiabled;
};

export const setAvailableDatesForCambriaDelivery = async (deliveryMethod: string, selectedCart: Cart) => {
  if (!selectedCart) {
    return [];
  }

  const customerDaysDisabled = await getDaysDisabledByCustomerPreferences(deliveryMethod, selectedCart.erpCustomerId);
  const holidaysDiabled = await getDatesDisabledByHolidays();
  let minDate = disableNextWorkingDay();

  return {
    minDate: minDate,
    holidaysDiabled: holidaysDiabled,
    daysDisabled: customerDaysDisabled,
  };
};

export const setAvailableDatesForCambriaArrange = async (deliveryMethod: string) => {
  let holidaysDiabled = await getDatesDisabledByHolidays();
  let minDate = new Date();
  let weekendDays = [];

  if (deliveryMethod === 'Customer Arranged from DC') {
    minDate = disableNextWorkingDay();
  } else {
    minDate.setDate(minDate.getDate() + 1);
  }

  weekendDays.push(0);
  weekendDays.push(6);

  return {
    minDate: minDate,
    holidaysDiabled: holidaysDiabled,
    daysDisabled: weekendDays,
  };
};

export const getShippingMethod = (shippingDetails: any) => {
  if (shippingDetails.shippingMethod) {
    if (shippingDetails.shippingMethod.name.type === 'div') {
      return shippingDetails.shippingMethod.code;
    } else {
      return shippingDetails.shippingMethod.name;
    }
  } else {
    return shippingDetails.deliveryMethod.shippingMethods[0].name;
  }
};

export const updateCartOrderDetailsInRedux = (currentCart: Cart, newOrderDetails: OrderDetail, dispatch: any) => {
  currentCart.orderDetails = [newOrderDetails];
  dispatch(setActiveCart(currentCart));
};

export const getJobTypes = (programCode: string, customerCLass: string) => {
  const jobTypes = [
    {
      displayName: 'Commercial-New Construction',
      code: 'Commercial-New',
    },
    {
      displayName: 'Commercial-Remodel',
      code: 'Commercial-Remodel',
    },
    {
      displayName: 'Residential-New Construction',
      code: 'Residential-New',
    },
    {
      displayName: 'Residential-Remodel',
      code: 'Residential-Remodel',
    },
    {
      displayName: 'Residential-Swap a Top',
      code: 'Residential-Swap a Top',
    },
  ];

  if (
    (programCode !== 'HD001' && customerCLass === 'LEXUS') ||
    customerCLass === 'DIS' ||
    customerCLass === 'CDAL' ||
    programCode.startsWith(COMMERCE_CORE_CONSTANTS.PROGRAMS.BUNDLE_PROGRAM_CODE_PREFIX)
  ) {
    jobTypes.push({
      displayName: 'Stock',
      code: 'Stock',
    });
  }

  return jobTypes;
};

export const getOrderTypes = () => {
  return [
    {
      displayName: 'New',
      code: 'New',
    },
    {
      displayName: 'Add-on',
      code: 'Add-on',
    },
    {
      displayName: 'Remake',
      code: 'Remake',
    },
  ];
};

export const saveNewShipToContact = async (contact: any, dispatch: any) => {
  try {
    const customerId = getCurrentCustomerId();
    if (customerId && contact) {
      let callbackId = await postCustomerContactRequest(customerId, contact);
      callbackId = await callbackId.text();
      return await callback(callbackId, 60, dispatch);
    }
  } catch (e: any) {
    toast.error(e);
  }
};

export const saveNewShipToSite = async (site: any, dispatch: any) => {
  try {
    const customerId = getCurrentCustomerId();
    let callbackId = await addCustomerSite(Number(customerId), site);
    await callback(callbackId, 60, dispatch);
    return site;
  } catch (error: any) {
    toast.error(error);
  }
};

export const getShippingCost = async (
  productType: ProductType,
  zipCode: string,
  cart: Cart,
  currentCustomer: CustomerFullProfile,
  currentCustomerShipToSites: Site[]
) => {
  const sqFtCartItems = cart.cartItems.filter((x) => x.pricingUom === 'SF');
  const programCode = cart.cartItems[0]?.programCode;
  const itIsAnHdProgramWithFreeOfShippingForAll =
    programCode && programCode.includes('HD') && sqFtCartItems.every((x) => x.qualifiesforFreeShippingForHomeDepot);

  let removeSlabPricing = false;
  if (productType === 'Slab' && (sqFtCartItems.length === 0 || itIsAnHdProgramWithFreeOfShippingForAll)) {
    removeSlabPricing = true;
  }
  const warehouse = await getShippingWarehouse(cart, currentCustomer, currentCustomerShipToSites);
  const shippingZones = await getShippingZoneRequest(zipCode, warehouse?.warehouseCode);
  const [shippingZone] = shippingZones.results;
  if (!shippingZone) {
    return Promise.reject('Shipping zone not found.');
  }

  const shippingZonePricings = await getShippingZonePricingRequest(shippingZone.zoneName, warehouse?.warehouseCode);
  const [shippingZonePricing] = shippingZonePricings.results;
  if (!shippingZonePricing) {
    return Promise.reject('Shipping zone pricing not found.');
  }

  if (removeSlabPricing) {
    shippingZonePricing.deliveryRate = 0;
  }

  return shippingZonePricing;
};

export const showToastrMessageIfZeroShippingRate = (deliveryRate: number) => {
  if (deliveryRate === 0) {
    toast.success('Shipping & handling costs will be added to the order separately.');
  }
};

export const savePaymentMethod = async (
  paymentTerms: PaymentSelection | null,
  paymentType: PaymentType | null,
  billToSite: Site | null,
  cart: Cart,
  dispatch: any,
  prepaymentMethodToken?: string,
  currentCustomer?: CustomerFullProfile
): Promise<any> => {
  const deletePaymentMethodPromises: Array<Promise<any>> = cart.paymentMethods.map((cartPaymentMethod) =>
    removePaymentMethod(cartPaymentMethod.selectedPaymentMethodId, dispatch)
  );

  try {
    await Promise.all(deletePaymentMethodPromises);
    const params: IAddCartPaymentMethodRequest = {
      cartId: cart.id,
      paymentTermId: paymentTerms?.id,
      paymentTypeId: paymentType?.id,
      billToSiteCode: billToSite?.siteNumber.toString(),
      itemNumbers: cart.cartItems.map((cartItem) => cartItem.itemNumber),
      prepaymentMethodToken: prepaymentMethodToken,
    };
    await addPaymentMethod(params, dispatch);
    if (paymentType || paymentTerms) {
      pushAddPaymentInfoToDataLayer({cart, paymentType: paymentType?.code ?? paymentTerms?.code ?? ''});
    }
    if (prepaymentMethodToken) {
      dispatch(getCreditCards({erpCustomerId: currentCustomer?.erpCustomerId}));
    }
  } catch (e) {
    return Promise.reject('An error occurred while saving the payment method.');
  }
};

export const saveTaxInformation = async (
  cart: Cart,
  customerShipToSites: Site[],
  customer: CustomerFullProfile,
  dispatch: any
): Promise<any> => {
  try {
    const [cartShippingMethod] = cart.shippingMethods;
    const shippingAddress = customerShipToSites.find(
      (shipToSite) =>
        shipToSite.siteNumber === cartShippingMethod?.shipToSiteId ||
        shipToSite?.id === cartShippingMethod?.shipToSiteId
    );
    const [defaultTaxCalculation] = cart ? cart.taxCalculations : [undefined];

    let taxOverrideReason;
    let taxExemptCertificateCode;

    if (defaultTaxCalculation) {
      taxOverrideReason = defaultTaxCalculation.taxOverrideReason;
      taxExemptCertificateCode = defaultTaxCalculation.taxExemptCertificateCode;
    }

    const warehouse = await getShippingWarehouse(cart, customer, customerShipToSites);
    const params = {
      cartId: cart.id,
      erpCustomerId: customer.erpCustomerId,
      customerName: customer.name,
      taxOverrideStatus: taxOverrideReason,
      taxOverrideReason: taxOverrideReason,
      taxExemptCertificateCode: taxExemptCertificateCode,
      lineItemsByShipping: [
        {
          shipFrom: {
            warehouseCode: warehouse?.warehouseCode,
            city: warehouse?.city,
            countryCode: warehouse?.country,
            postalCode: warehouse?.zipCode,
            stateProvinceCode: warehouse?.state,
            county: warehouse?.county,
          },
          shipTo: {
            siteId: shippingAddress?.id,
            city: shippingAddress?.city,
            countryCode: shippingAddress?.country,
            postalCode: shippingAddress?.postalCode,
            stateProvinceCode: shippingAddress?.stateProvince,
            county: shippingAddress?.county,
          },
          itemNumbers: cart.cartItems.map((cartItem) => cartItem.itemNumber),
        },
      ],
    };

    await calculateTax(params, dispatch);
  } catch (e) {
    console.log(e);
    return Promise.reject('An error occurred while calculating tax.');
  }
};

export const formatShippingMethodsForUPS = (shippingMethods: any) => {
  return shippingMethods.map((method: any) => {
    if (!method.formated && method.plannedPickupDate) {
      const formattedPickupDate = `
        ${method.plannedPickupDate.slice(0, 4)}/
        ${method.plannedPickupDate.slice(4, 6)}/
        ${method.plannedPickupDate.slice(6, 8)}`;

      const formattedExpectedDate = `
        ${method.expectedDeliveryDate.slice(0, 4)}/
        ${method.expectedDeliveryDate.slice(4, 6)}/
        ${method.expectedDeliveryDate.slice(6, 8)}`;

      method.formatedPlannedPickupDate = new Date(formattedPickupDate).toLocaleString('en-US', {
        weekday: 'long',
        month: 'long',
        day: 'numeric',
      });

      method.formatedExpectedDeliveryDate = new Date(formattedExpectedDate).toLocaleString('en-US', {
        weekday: 'long',
        month: 'long',
        day: 'numeric',
      });

      method.formatedRequestedDeliveryDate = new Date(formattedExpectedDate).toLocaleDateString('en-US');

      if (method.useAsNeedByDate) {
        method.name = (
          <div>
            <b>{method.formatedExpectedDeliveryDate}</b> (${method.totalCharges}) <i>{method.name}</i>
          </div>
        );
      } else {
        method.name = (
          <div>
            <b>{method.name}</b> (${method.totalCharges}) Estimated <i>{method.formatedPlannedPickupDate}</i>
          </div>
        );
      }

      method.formated = true;
    }

    return method;
  });
};

export const organizeResubmitOrder = async (
  cartId: string,
  orderNumber: string,
  userId: string,
  customer: CustomerFullProfile,
  orderMetadata: ICambriaOrderMetaData,
  dispatch: any
): Promise<string> => {
  return await resubmitOrder(
    {
      cartId: cartId,
      erpCustomerId: customer.erpCustomerId,
      userId: userId,
      orderNumber: orderNumber,
      orderMetadata,
    },
    dispatch
  );
};

export const organizePlaceOrderParams = async (
  cartId: string,
  userId: string,
  customer: CustomerFullProfile,
  dispatch: any,
  orderNumber?: string,
  orderMetadata: ICambriaOrderMetaData | null = null
): Promise<string> => {
  let params: any = {
    cartId: cartId,
    erpCustomerId: customer.erpCustomerId,
    userId: userId,
  };

  if (orderNumber) {
    return updateOrder(params, orderNumber, dispatch);
  } else {
    if (orderMetadata) {
      params = {...params, orderMetadata: orderMetadata};
    }
    return placeOrder(params, dispatch);
  }
};

export const getShippingSelections = async (
  shipToSite: any,
  activeCart: Cart,
  activeProgram: any,
  currentCustomer: CustomerFullProfile,
  currentCustomerShipToSites: Site[],
  useUpsEndpoint?: boolean
) => {
  const params = await getShippingSelectionsParams(
    shipToSite,
    activeCart,
    activeProgram,
    currentCustomer,
    currentCustomerShipToSites,
    useUpsEndpoint
  );
  const url = useUpsEndpoint
    ? COMMERCE_CORE_CONSTANTS.API_SERVICES.SHIPPING.shippingSelectionsV2
    : COMMERCE_CORE_CONSTANTS.API_SERVICES.SHIPPING.shippingSelections;

  const result = await getShippingSelectionsRequest(url, params);

  return result?.length > 0
    ? result.sort((a: any, b: any) => {
        return a.name.localeCompare(b.name);
      })
    : [];
};

export const saveOrderDetails = async (cart: Cart, orderDetails: any, dispatch: any) => {
  const params: OrderDetail = {
    cartId: cart?.id,
    purchaseOrderNumber: orderDetails.purchaseOrderNumber ? orderDetails.purchaseOrderNumber : null,
    homeDepotOrderNumbers: orderDetails.homeDepotOrderNumbers ? orderDetails.homeDepotOrderNumbers : null,
    jobType: orderDetails.jobType ? orderDetails.jobType.code : null,
    projectType: orderDetails.orderType ? orderDetails.orderType.code : null,
    projectName: orderDetails.projectName ? orderDetails.projectName : null,
    projectCity: orderDetails.city ? orderDetails.city : null,
    relatedAgency: orderDetails.designFirmName ? orderDetails.designFirmName : null,
    orderCrmAccountId: orderDetails.salesforceAccount ? orderDetails.salesforceAccount.id : null,
    orderCrmAccountName: orderDetails.salesforceAccount ? orderDetails.salesforceAccount.name : null,
    orderCrmAccountTradeType: orderDetails.salesforceAccount ? orderDetails.salesforceAccount.tradeType : null,
    fabReworkReason: orderDetails.fabReworkReason ? orderDetails.fabReworkReason : null,
    fabAssignableCause: orderDetails.fabAssignableCause ? orderDetails.fabAssignableCause : null,
    orderPieceRework: orderDetails.orderPieceRework ? orderDetails.orderPieceRework : null,
    publicFacingDisplay: orderDetails.publicFacingDisplay ? orderDetails.publicFacingDisplay : null,
    orderIsRevisionOfPreviousJob: orderDetails.orderIsRevisionOfPreviousJob
      ? orderDetails.orderIsRevisionOfPreviousJob
      : null,
    fabricationOrderCadRevisionsNeededEmailList: orderDetails.fabricationOrderCadRevisionsNeededEmailList
      ? orderDetails.fabricationOrderCadRevisionsNeededEmailList
      : null,
    fabricationOrderDrafter: orderDetails.fabricationOrderDrafter ? orderDetails.fabricationOrderDrafter : null,
    fabricationOrderOrderApprovedEmailList: orderDetails.fabricationOrderOrderApprovedEmailList
      ? orderDetails.fabricationOrderOrderApprovedEmailList
      : null,
    fabricationOrderPoRevisionsNeededEmailList: orderDetails.fabricationOrderPoRevisionsNeededEmailList
      ? orderDetails.fabricationOrderPoRevisionsNeededEmailList
      : null,
    originalSalesOrderNumber: orderDetails.originalSalesOrderNumber ? orderDetails.originalSalesOrderNumber : null,
    fabricationInstructions: orderDetails.fabricationInstructions ? orderDetails.fabricationInstructions : null,
  };

  const response = await postCartOrderDetailsRequest(params, true);

  if (response.status !== 202) {
    toast.error('Something went wrong.');
    throw new Error('Something went wrong.');
  } else {
    updateCartOrderDetailsInRedux(JSON.parse(JSON.stringify(cart)), params, dispatch);
  }
};

export const removeShippingMethod = async (selectedShippingMethodId: number, dispatch: any) => {
  try {
    const response = await deleteCartShippingMethodsRequest(selectedShippingMethodId, undefined, true);
    const callbackId = await response.text();
    return await callback(callbackId, 90, dispatch);
  } catch (error: any) {
    return Promise.reject(error);
  }
};

export const saveShippingDetails = async (cart: Cart, shippingDetails: any, dispatch: any) => {
  const deleteShippingMethodPromises: Array<Promise<any>> = cart.shippingMethods.map((cartShippingMethod) =>
    removeShippingMethod(cartShippingMethod.selectedShippingMethodId, dispatch)
  );

  try {
    await Promise.all(deleteShippingMethodPromises);

    const params: any = {
      cartId: cart.id,
      itemNumbers: cart.cartItems.map((cartItem) => cartItem.itemNumber),
      shipComplete: shippingDetails.sendInFewerBoxes,
      shipToAddressLine: formatAddress({
        address1: shippingDetails.shippingAddress.address1,
        address2: shippingDetails.shippingAddress.address2,
        address3: shippingDetails.shippingAddress.address3,
      }),
      shipToCity: shippingDetails.shippingAddress.city,
      shipToContactId: shippingDetails.contact.erpCustomerContactId,
      shipToCountryCode: shippingDetails.shippingAddress.country,
      shipToCustomerName: shippingDetails.shippingAddress.siteName,
      shipToPostalCode: shippingDetails.shippingAddress.postalCode,
      shipToSiteId: shippingDetails.shippingAddress.siteNumber,
      shipToStateProvinceCode: shippingDetails.shippingAddress.stateProvince || shippingDetails.shippingAddress.country,
      shippingMethod: getShippingMethod(shippingDetails),
      shippingMethodCode: shippingDetails.shippingMethod.code || shippingDetails.deliveryMethod.shippingMethods[0].code,
      shippingOptionId: shippingDetails.deliveryMethod.id,
      customerShippingAccountId: shippingDetails.customerShippingAccountId,
      shippingInstructions: shippingDetails.shippingInstructions,
    };

    const response = await postCartShippingMethodsRequest(params, true);

    if (response.status !== 202) {
      toast.error('Something went wrong.');
      throw new Error('Something went wrong.');
    }

    const callbackId = await response.text();
    return await callback(callbackId, 90, dispatch);
  } catch (e: any) {
    toast.error(e);
    return Promise.reject(e);
  }
};
