import styled from 'styled-components';
import {$gray30, $gray50, $newprimary} from '../../../../../../../Styled/variables';

export const CambriaFileUploadStyled = styled.div`
  box-shadow: 0px 1px 3px 0px rgba(101, 101, 101, 0.35);
  display: flex;
  justify-content: space-between;
  color: ${$gray50};
  font-size: 14px;
  padding: 0px 50px;
  background-color: #e4e4e4;
  padding: 15px;
  border-radius: 3px;
  margin: 10px 0px;
  font-weight: 700;

  .file-name {
    color: ${$gray30};

    :hover {
      cursor: pointer;
      color: ${$gray50};
    }
  }

  .fa {
    color: ${$gray30};

    :hover {
      color: ${$gray30};
      cursor: default !important;
    }
  }

  .remove-text {
    :hover {
      cursor: pointer;
      color: #947214;
    }

    margin-left: 10px;
    font-weight: 700;
    color: ${$newprimary};
  }
`;
