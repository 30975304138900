import React, {useEffect, useState} from 'react';
import {Accordion, Card} from 'react-bootstrap';
import JobOrderTypeForm from '../../Account/OrderHistory/OrderDetails/JobOrderType';
import {useTypedSelector} from '../../../../hooks/store';
import {usePrevious} from '../../../../hooks/usePrevious';
import {selectActiveCart} from '../../../../features/cart/slice/cart.slice';
import CustomerInformationComponent from './CustomerInformation';
import {selectEndConsumers, selectPurchasingCustomer} from '../../../../features/fabrication/slice/fabrication.slice';
import EmailList from './EmailList';
import {selectUiSettings} from '../../../../features/environment/slice/environment.slice';

const FabOrderDetails = () => {
  const activeCart = useTypedSelector(selectActiveCart);
  const endUsers = useTypedSelector(selectEndConsumers);
  const purchasingCustomers = useTypedSelector(selectPurchasingCustomer);
  const uiSettings = useTypedSelector(selectUiSettings);

  const [isOpen, setIsOpen] = useState(false);

  const prevEndUsers = usePrevious(endUsers);

  useEffect(() => {
    if (
      (!!purchasingCustomers && purchasingCustomers.length === 0) ||
      (!!endUsers && endUsers.length === 0) ||
      (!!activeCart && !!activeCart.orderDetails && activeCart.orderDetails.length === 0)
    ) {
      setIsOpen(true);
    } else if (
      activeCart &&
      activeCart.orderDetails &&
      activeCart.orderDetails.length > 0 &&
      purchasingCustomers &&
      purchasingCustomers.length > 0 &&
      prevEndUsers &&
      prevEndUsers.length === 0 &&
      endUsers &&
      endUsers.length > 0
    ) {
      setIsOpen(false);
    }
  }, [purchasingCustomers, endUsers, activeCart, prevEndUsers]);

  return (
    <Accordion className="order-details-accordion" activeKey={isOpen ? '0' : '1'}>
      <Card>
        <Card.Header className="card-header w-100 p-3">
          <Accordion.Toggle
            data-testid="step1ToggleBtn"
            className="d-flex w-100 border-0 align-items-center justify-content-between bg-transparent"
            eventKey="0"
            onClick={() => setIsOpen((isOpen: boolean) => !isOpen)}>
            <div className="d-flex justify-content-start align-items-center w-25">
              <div className="rounded p-3 number-box d-flex align-items-center justify-content-center">1</div>
              <span className="ml-3 step-heading">Order Details</span>
            </div>
            <i className={`fa fa-chevron-${isOpen ? 'up' : 'down'} float-right`}></i>
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey="0">
          <Card.Body className="card-body">
            {!uiSettings?.fabricationOrderDetailsCheckoutIsActive ? <EmailList /> : <></>}
            <CustomerInformationComponent
              isEditDisabled={
                activeCart?.transactionType === 'QuoteCart' || activeCart?.transactionType === 'QuoteEditCart'
              }
            />
            <JobOrderTypeForm
              isFabricatedOrder={true}
              isEditDisabled={
                activeCart?.transactionType === 'QuoteCart' || activeCart?.transactionType === 'QuoteEditCart'
              }
              collapseFabStep1={() => setIsOpen(false)}
              orderDetailsInitialValues={
                activeCart && activeCart?.orderDetails.length > 0 ? activeCart.orderDetails[0] : undefined
              }
              isOnFabPage={true}
            />
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
};

export default FabOrderDetails;
