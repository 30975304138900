import React, {useEffect, useState} from 'react';
import useWindowDimensions from '../../../hooks/getWindowDimensions';
import {LineItem} from '../../../App/AuthenticatedPages/Account/OrderHistory/OrderDetails/IOrderDetails';
import ProductTableDesktop from './desktop';
import ProductTableMobile from './mobile';
import CambriaFileUpload from '../../../App/AuthenticatedPages/Account/OrderHistory/OrderDetails/FabricationCart/CambriaFileUpload';
import {CartItem} from '../../../features/cart/ICartState';
import TotalPrice from '../../../App/AuthenticatedPages/Account/OrderHistory/OrderDetails/TotalPrice';
import {FabricationFile} from '../../../features/file/IFileState';

export const formatProductPrices = (price: string | number): string => {
  let formattedPrice = '0.00';
  const priceDividend = 100;
  const fractionDigits = 2;
  if (price) {
    formattedPrice = (parseFloat(price.toString()) / priceDividend).toFixed(fractionDigits);
  }
  return formattedPrice.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const dateWithMonthSpelledOut = (date: Date) => {
  const month = date.toLocaleDateString('default', {month: 'short'});
  return `${month} ${date.getDate()}, ${date.getFullYear()}`;
};

export const getTotalLF = (cartItems: Array<CartItem>): number => {
  const cartItemsWithUnitPrice = cartItems.filter((x) => x.unitPrice > 0);
  return getTotalQuantityOfGivenUnits(cartItemsWithUnitPrice, 'LF');
};

const getTotalQuantityOfGivenUnits = (cartItems: CartItem[], unitCode: string) => {
  let total = 0;
  const cartItemsWithPrimaryUnitSF = cartItems.filter((x) => x.primaryUomCode === unitCode);
  for (const item of cartItemsWithPrimaryUnitSF) {
    total += item.uomLineQuantity;
  }
  return total;
};

const ProductTable = ({
  orderLineItems,
  orderDetails,
  forQuoteDetails = false,
  isFabOrder,
  files,
  forReviewAndPlaceOrder = false,
}: {
  orderLineItems: LineItem[] | CartItem[];
  orderDetails?: any;
  forQuoteDetails?: boolean;
  isFabOrder?: boolean;
  files?: FabricationFile[];
  forReviewAndPlaceOrder?: boolean;
}) => {
  const {width} = useWindowDimensions();

  const [isSlabOrder, setIsSlabOrder] = useState<boolean>(false);

  useEffect(() => {
    if (orderLineItems.length > 0) {
      if (orderLineItems[0].productType === 'Slab') {
        setIsSlabOrder(true);
      }
    }
  }, [orderLineItems]);

  if (width > 960) {
    return (
      <>
        <ProductTableDesktop
          orderLineItems={orderLineItems}
          isSlabOrder={isSlabOrder}
          forQuoteDetails={forQuoteDetails}
          forReviewAndPlaceOrder={forReviewAndPlaceOrder}
        />
        {isFabOrder && <TotalPrice orderDetails={orderDetails}></TotalPrice>}
        {isFabOrder && files && <CambriaFileUpload files={files} />}
      </>
    );
  } else {
    return (
      <>
        <ProductTableMobile
          orderLineItems={orderLineItems}
          isSlabOrder={isSlabOrder}
          forQuoteDetails={forQuoteDetails}
          forReviewAndPlaceOrder={forReviewAndPlaceOrder}
        />
        {isFabOrder && <TotalPrice orderDetails={orderDetails}></TotalPrice>}
        {isFabOrder && files && <CambriaFileUpload files={files} />}
      </>
    );
  }
};

export default ProductTable;
