import React, {useEffect} from 'react';
import {toast} from 'react-toastify';
import COMMERCE_CORE_CONSTANTS from '../../../../../Core/constants';
import {
  removeCurrentCustomer,
  selectCurrentCustomer,
  selectSiteInfo,
  selectSiteInfoStatus,
  setCurrentCustomer,
} from '../../../../../features/customer/slice/customer.slice';
import {CustomerFullProfile, Site} from '../../../../../features/customer/ICustomerState';
import {
  getDefaultCustomerAsync,
  selectCustomerValidationStatus,
  selectDefaultCustomer,
  selectDefaultCustomerStatus,
  setCustomerIsValid,
  setResolvesAreLoading,
} from '../../../../../features/resolves/slice/resolves.slice';
import {useAppDispatch, useTypedSelector} from '../../../../../hooks/store';
import groupBy from 'lodash/groupBy';
import {useLogout} from '../../../../../hooks/useLogout';
import {useHistory} from 'react-router-dom';
import {
  selectSalesforceCustomerAccount,
  selectSalesforceCustomerAccountStatus,
  setSalesforceCustomerAccount,
} from '../../../../../features/salesforce/slice/salesforce.slice';
import {useCustomerLogout} from '../../../../../hooks/useCustomerLogout';

const CustomerValidationResolve = ({children}: {children: any}) => {
  const dispatch = useAppDispatch();
  const logout = useLogout();
  const history = useHistory();
  const customerLogout = useCustomerLogout();

  const currentCustomer: CustomerFullProfile | null = useTypedSelector(selectCurrentCustomer);
  const customerIsValid = useTypedSelector(selectCustomerValidationStatus);
  const defaultCustomerNotFound = useTypedSelector(selectDefaultCustomerStatus);
  const defaultCustomer = useTypedSelector(selectDefaultCustomer);
  const salesforceCustomerAccount = useTypedSelector(selectSalesforceCustomerAccount);
  const salesforceCustomerAccountStatus = useTypedSelector(selectSalesforceCustomerAccountStatus);
  const siteInfo = useTypedSelector(selectSiteInfo);
  const siteInfoStatus = useTypedSelector(selectSiteInfoStatus);

  const salesforceErrorId = 'salesforce-toast-error';

  // Load Default Customer Information
  useEffect(() => {
    dispatch(getDefaultCustomerAsync());
  }, [dispatch]);

  useEffect(() => {
    if (
      currentCustomer?.erpCustomerId &&
      salesforceCustomerAccountStatus !== 'loading' &&
      !salesforceCustomerAccount?.id
    ) {
      dispatch(setSalesforceCustomerAccount({customerErpCustomerId: currentCustomer.erpCustomerId}));
    }
  }, [dispatch, currentCustomer, salesforceCustomerAccountStatus, salesforceCustomerAccount]);

  // Default Customer if there is one
  useEffect(() => {
    if (defaultCustomer?.id && defaultCustomerNotFound === false && !currentCustomer?.id) {
      window.sessionStorage.setItem(
        COMMERCE_CORE_CONSTANTS.WINDOW_SESSION.selectedCustomerId,
        defaultCustomer?.id.toString()
      );
      window.sessionStorage.setItem(
        COMMERCE_CORE_CONSTANTS.WINDOW_SESSION.selectedErpCustomerId,
        defaultCustomer?.erpCustomerId.toString()
      );
      dispatch(setCurrentCustomer(defaultCustomer));
    }
  }, [dispatch, defaultCustomerNotFound, defaultCustomer, currentCustomer]);

  // Validates the selected customer
  useEffect(() => {
    const checkIfSalesForceAccountExists = (erpCustomerId: number) => {
      let salesForceInfo = salesforceCustomerAccount;
      if (salesForceInfo?.id) {
        return true;
      } else {
        toast.error(
          `Customer with Account Number ${erpCustomerId} is missing in Salesforce. Contact Sales Operations to update the information.`,
          {toastId: salesforceErrorId}
        );
        return false;
      }
    };

    const getMissingPrimarySiteError = (
      siteType: string,
      operatingUnitCode: string,
      primaryBillTo?: Site,
      primaryShipTo?: Site
    ) => {
      let primarySiteError: string = `The selected customer is not setup completely for the operating unit ${operatingUnitCode}. `;

      if (primaryBillTo) {
        primarySiteError =
          primarySiteError +
          (primaryBillTo && !primaryBillTo.erpPaymentTermsId
            ? `Its “BILL_TO” primary site is missing Payment Terms`
            : `It is missing a "${siteType}" primary site setup.`);
      } else if (primaryShipTo) {
        primarySiteError =
          primarySiteError +
          (primaryShipTo && !primaryShipTo.warehouseCode
            ? `Its "SHIP_TO" primary site is missing a warehouse setup, Please contact CCC at ${COMMERCE_CORE_CONSTANTS.customerCarePhoneNumber}`
            : `It is missing a "${siteType}" primary site setup.`);
      } else {
        primarySiteError = primarySiteError + `It is missing a "${siteType}" primary site setup.`;
      }

      return primarySiteError;
    };

    const checkMissingBillToOrShipToPrimarySiteForEachOperatingUnit = (allSites: Site[]) => {
      const sitesGroupByOperatingUnit = groupBy(allSites, (x) => x.operatingUnitCode);
      for (const [operatingUnitCode, sites] of Object.entries(sitesGroupByOperatingUnit)) {
        let primarySitesByOU = sites.filter((r: Site) => r.isPrimary === 'Y');
        let isTherePrimaryShipTo = primarySitesByOU.some(
          (x: Site) => x.siteType === COMMERCE_CORE_CONSTANTS.SITE_USE.shipto && x.warehouseCode !== null
        );
        let isTherePrimaryBillTo = primarySitesByOU.some(
          (x: Site) => x.siteType === COMMERCE_CORE_CONSTANTS.SITE_USE.billto && x.erpPaymentTermsId !== null
        );

        if (!isTherePrimaryBillTo) {
          let primaryBillTo = primarySitesByOU.find(
            (x: Site) => x.siteType === COMMERCE_CORE_CONSTANTS.SITE_USE.billto
          );

          let message = getMissingPrimarySiteError(
            COMMERCE_CORE_CONSTANTS.SITE_USE.billto,
            operatingUnitCode,
            primaryBillTo
          );
          toast.error(message);
          return false;
        }
        if (!isTherePrimaryShipTo) {
          let primaryShipTo = primarySitesByOU.find(
            (x: Site) => x.siteType === COMMERCE_CORE_CONSTANTS.SITE_USE.shipto
          );
          let message = getMissingPrimarySiteError(
            COMMERCE_CORE_CONSTANTS.SITE_USE.shipto,
            operatingUnitCode,
            undefined,
            primaryShipTo
          );
          toast.error(message);
          return false;
        }
      }
      return true;
    };

    const checkForDuplicatePrimarySites = (sites: Site[]) => {
      let primarySites = sites.filter((x) => x.isPrimary === 'Y');
      for (let primarySite of primarySites) {
        let type =
          primarySite.siteType === COMMERCE_CORE_CONSTANTS.SITE_USE.shipto
            ? COMMERCE_CORE_CONSTANTS.SITE_USE.shipto
            : COMMERCE_CORE_CONSTANTS.SITE_USE.billto;
        const sitesWithSameSiteNumber = sites.filter(
          (x) =>
            primarySite.siteNumber === x.siteNumber &&
            x.operatingUnitCode === primarySite.operatingUnitCode &&
            x.siteType === primarySite.siteType
        );
        if (sitesWithSameSiteNumber && sitesWithSameSiteNumber.length > 1) {
          let message = `The selected customer needs to have its sites updated. The primary "${type}" site shares the same site number "${primarySite.siteNumber}" with another site.`;
          toast.error(message);
          return false;
        }
      }
      return true;
    };

    const validateBillToSites = (billToSites: Site[]) => {
      if (!billToSites || billToSites.length === 0) {
        toast.error('Customer payment terms are missing. Contact the Business Office to update the information.');
        return false;
      }
      let erpPaymentTermSites = billToSites.filter((x) => x.erpPaymentTermsId !== null);
      if (!erpPaymentTermSites || erpPaymentTermSites.length === 0) {
        toast.error('Customer payment terms are missing. Contact the Business Office to update the information.');
        return false;
      }
      return true;
    };

    const checkSitesSetup = async (customerId: string) => {
      if (!siteInfo) {
        return false;
      }
      let siteArray: Site[] = siteInfo;
      let billToSites = siteArray.filter((x) => x.siteType === 'BILL_TO');
      let billToAndShipToPrimarySitesSetupCorrectly =
        checkMissingBillToOrShipToPrimarySiteForEachOperatingUnit(siteArray);
      let billToSitesSetupCorrectly = validateBillToSites(billToSites);
      let noDuplicateSites = checkForDuplicatePrimarySites(siteArray);

      return noDuplicateSites && billToSitesSetupCorrectly && billToAndShipToPrimarySitesSetupCorrectly;
    };

    const checkForCustomerSetupErrors = async (customer: any) => {
      if (!customer) {
        let notFoundCustomerError = 'Customer not found, Failed to retrieve customer full profile.';
        toast.error(notFoundCustomerError);
      }
      if (!customer.class) {
        let missingCustomer = 'Customer class is missing. Contact Sales Operations to update the information.';
        toast.error(missingCustomer);
      }

      let salesForceAccountSetupCorrect = checkIfSalesForceAccountExists(customer.erpCustomerId);
      let billShipSitesSetupCorrect = await checkSitesSetup(customer.id);

      return salesForceAccountSetupCorrect && billShipSitesSetupCorrect;
    };

    const validateCustomerOnSelection = async (customerInfo: CustomerFullProfile) => {
      let customerSetupCorrectly = await checkForCustomerSetupErrors(customerInfo);
      if (customerSetupCorrectly) {
        dispatch(setCustomerIsValid(true));
      } else {
        if (defaultCustomer?.id) {
          logout();
        }
        dispatch(setCustomerIsValid(false));
        dispatch(removeCurrentCustomer());
        window.sessionStorage.removeItem(COMMERCE_CORE_CONSTANTS.WINDOW_SESSION.selectedCustomerId);
        if (window.location.href.includes(COMMERCE_CORE_CONSTANTS.PATHNAMES.accountOrderDetails)) {
          history.push(COMMERCE_CORE_CONSTANTS.PATHNAMES.customerSearch);
        }
        dispatch(setResolvesAreLoading(false));
        customerLogout();
      }
    };

    if (
      currentCustomer?.id &&
      !customerIsValid &&
      salesforceCustomerAccountStatus !== 'loading' &&
      salesforceCustomerAccountStatus !== 'idle' &&
      siteInfoStatus !== 'idle' &&
      siteInfoStatus !== 'loading'
    ) {
      validateCustomerOnSelection(currentCustomer);
    }
  }, [
    currentCustomer,
    dispatch,
    customerIsValid,
    defaultCustomer,
    logout,
    history,
    salesforceCustomerAccountStatus,
    salesforceCustomerAccount,
    siteInfoStatus,
    siteInfo,
    customerLogout,
  ]);

  return <div>{children}</div>;
};

export default CustomerValidationResolve;
