import {useEffect} from 'react';
import COMMERCE_CORE_CONSTANTS from '../../Core/constants';
import {
  getEndConsumersAsync,
  getPurchasingCustomersAsync,
  selectEndConsumers,
  selectEndConsumersStatus,
  selectPurchasingCustomer,
  selectPurchasingCustomerStatus,
} from '../../features/fabrication/slice/fabrication.slice';
import {useAppDispatch, useTypedSelector} from '../store';
import {selectActiveCart} from '../../features/cart/slice/cart.slice';
import {selectCurrentCustomer} from '../../features/customer/slice/customer.slice';

/**
 * Hook to determine wether the current cart and user state
 * can proceed to the purchase agreement page
 */
export const useDisabledPurchaseAgreement = () => {
  const dispatch = useAppDispatch();
  const endConsumers = useTypedSelector(selectEndConsumers);
  const purchasingCustomers = useTypedSelector(selectPurchasingCustomer);
  const purchasingCustomersStatus = useTypedSelector(selectPurchasingCustomerStatus);
  const endConsumersStatus = useTypedSelector(selectEndConsumersStatus);
  const cart: any = useTypedSelector(selectActiveCart);
  const currentCustomer = useTypedSelector(selectCurrentCustomer);

  const missingPurchasingCustomer =
    (!purchasingCustomers || purchasingCustomers.length === 0) &&
    purchasingCustomersStatus !== 'idle' &&
    purchasingCustomersStatus !== 'loading';
  const missingEndConsumer =
    (!endConsumers || endConsumers.length === 0) && endConsumersStatus !== 'idle' && endConsumersStatus !== 'loading';

  const disabled = missingEndConsumer || missingPurchasingCustomer;

  let errorMesssage = '';

  if (missingPurchasingCustomer && missingEndConsumer) {
    errorMesssage =
      COMMERCE_CORE_CONSTANTS.ERROR_MESSAGES.PURCHASING_AGREEMENT.FABRICATION_MISSING_PURCHASING_CUSTOMER_END_CONSUMER;
  } else if (missingEndConsumer) {
    errorMesssage = COMMERCE_CORE_CONSTANTS.ERROR_MESSAGES.PURCHASING_AGREEMENT.FABRICATION_MISSING_END_CONSUMER;
  } else if (missingPurchasingCustomer) {
    errorMesssage = COMMERCE_CORE_CONSTANTS.ERROR_MESSAGES.PURCHASING_AGREEMENT.FABRICATION_MISSING_PURCHASING_CUSTOMER;
  }

  useEffect(() => {
    // Sync endConsumers
    if (endConsumersStatus === 'idle' && cart && currentCustomer?.erpCustomerId) {
      dispatch(
        getEndConsumersAsync({
          cartId: cart.id,
          erpCustomerId: currentCustomer.erpCustomerId,
        })
      );
    }
  }, [endConsumersStatus, cart, currentCustomer, dispatch]);

  useEffect(() => {
    // Sync purchasingCustomers
    if (purchasingCustomersStatus === 'idle' && cart && currentCustomer?.erpCustomerId) {
      dispatch(
        getPurchasingCustomersAsync({
          cartId: cart.id,
          erpCustomerId: currentCustomer.erpCustomerId,
        })
      );
    }
  }, [purchasingCustomersStatus, currentCustomer, cart, dispatch]);

  return [disabled, errorMesssage] as const;
};
