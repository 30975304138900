import {CartItem} from '../../../cart/ICartState';
import {Program} from '../IProgramState';
import {setActiveProgram} from '../../slice/salesforce.slice';

export const setSelectedProgramByCartItems = async (
  cartItems: CartItem[],
  availablePrograms: any,
  selectedProgram: any,
  dispatch: any
) => {
  if (
    !cartItems ||
    cartItems.length <= 0 ||
    !cartItems[0].programCode ||
    !availablePrograms ||
    availablePrograms.programs.length <= 0
  ) {
    return {
      isProgramAvailable: false,
      isProgramChanged: false,
      programSelected: null,
    };
  }
  const program = availablePrograms.programs.find((p: Program) => p.code === cartItems[0].programCode);
  const isProgramChanged = !!(program && selectedProgram && program.code !== selectedProgram.code);

  if (!!program && isProgramChanged) {
    setSelectedProgram(program, selectedProgram, dispatch);
  }

  return {
    isProgramAvailable: !!program,
    isProgramChanged: isProgramChanged,
    programSelected: program,
  };
};

export const setSelectedProgram = (newProgram: Program, selectedProgram: Program, dispatch: any) => {
  if (selectedProgram && newProgram && selectedProgram.code !== newProgram.code) {
    dispatch(setActiveProgram(newProgram));
  }
};

export const setSessionStorageProgram = (availablePrograms: any, dispatch: any) => {
  const selectedProgramCode = window.sessionStorage.getItem(`selectedProgramCode${availablePrograms.accountId}`);
  let programCode = selectedProgramCode ? selectedProgramCode : availablePrograms.defaultProgramCode;
  for (const program of availablePrograms.programs) {
    if (program.code === programCode) {
      dispatch(setActiveProgram(program));
    }
  }
};
