import React from 'react';
import {Col, Row} from 'react-bootstrap';
import DateBlock from '../../../../../../Core/Components/OrderGrid/DateBlock';
import StatusCode from '../../../../../../Core/Components/StatusCode';
import {IQuoteHeaderData} from '../../../../../../features/quote/IQuoteState';
import {formatQuoteDate} from '../../../../../../Framework/Services/formatDate';

const QuoteDetailsHeaderDesktop = ({headerData}: {headerData: IQuoteHeaderData}) => {
  return (
    <Row className="quote-detail-table-row">
      <Col xs={6} className="quote-date-info-container">
        <div>
          <DateBlock date={headerData.dateOrdered ? new Date(headerData.dateOrdered) : null} />
        </div>
        <div className="quote-details-subcontainer">
          <div>
            Created By: <span className="quote-details-value created-by">{headerData.createdBy}</span>
          </div>
          <div>
            Project Name: <span className="quote-details-value">{headerData.projectName}</span>
          </div>
          <div>
            Quote Number: <span className="quote-details-value">{headerData.quoteNumber}</span>
          </div>
          <div>
            Subcontractor Name: <span className="quote-details-value">{headerData.subcontractorName}</span>
          </div>
          {headerData.location ? (
            <div>
              Location: <span className="quote-details-value">{headerData.location}</span>
            </div>
          ) : (
            <></>
          )}
          <div>
            Expiration Date:{' '}
            <span className="quote-details-value expiration-date">
              {headerData.expirationDate ? formatQuoteDate(new Date(headerData.expirationDate), true) : ''}
            </span>
          </div>
        </div>
      </Col>
      <Col xs={6} className="pointer-on-hover align-right" title={headerData.statusCode}>
        <StatusCode status={headerData.status} />
      </Col>
    </Row>
  );
};

export default QuoteDetailsHeaderDesktop;
