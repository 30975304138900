import {useEffect} from 'react';
import COMMERCE_CORE_CONSTANTS from '../../../../Core/constants';
import {CartItem} from '../../ICartState';
import {selectActiveCart} from '../../slice/cart.slice';
import {
  getEndConsumersAsync,
  getPurchasingCustomersAsync,
  selectEndConsumers,
  selectEndConsumersStatus,
  selectPurchasingCustomer,
  selectPurchasingCustomerStatus,
} from '../../../fabrication/slice/fabrication.slice';
import {isFabricatedCart, isSurfacesCart} from '../../../productType/service/productType.service';
import {selectActiveProgram} from '../../../salesforce/slice/salesforce.slice';
import {useAppDispatch, useTypedSelector} from '../../../../hooks/store';
import {selectCurrentCustomer} from '../../../customer/slice/customer.slice';
import {selectIsRevH, selectIsRevI} from '../../../environment/slice/environment.slice';

/**
 * Returns a boolean indicating if checkout is enabled/disabled
 * depending on bundle program rules and fabrication order rules and the error message.
 *
 * TODO
 * Add the logic to disable the checkout for
 *  * missing DWG files for fab orders
 *  * missing edge profile
 *  * cart calculations incomplete
 * so we redirect the user to the cart when navigating
 * to the checkout through URL in an invalid state.
 *
 * Also, we have to define where this custom hooks should live, maybe in features?
 *
 */
export const useDisabledCheckout = () => {
  const dispatch = useAppDispatch();
  const cart: any = useTypedSelector(selectActiveCart);
  const activeProgram = useTypedSelector(selectActiveProgram);
  const purchasingCustomers = useTypedSelector(selectPurchasingCustomer);
  const purchasingCustomersStatus = useTypedSelector(selectPurchasingCustomerStatus);
  const isRevH = useTypedSelector(selectIsRevH);
  const isRevI = useTypedSelector(selectIsRevI);
  const isRev = isRevH || isRevI;

  const endConsumers = useTypedSelector(selectEndConsumers);
  const endConsumersStatus = useTypedSelector(selectEndConsumersStatus);

  const currentCustomer = useTypedSelector(selectCurrentCustomer);

  const isItAFabricatedCart = isFabricatedCart(cart);
  const isItASurfacesCart = isSurfacesCart(cart);
  const isFabOrSurfaceCart = isItAFabricatedCart || isItASurfacesCart;

  const isBundleProgram = activeProgram?.code?.startsWith(COMMERCE_CORE_CONSTANTS.PROGRAMS.BUNDLE_PROGRAM_CODE_PREFIX);
  const halfBundleItemsSum = cart?.cartItems?.reduce(
    (curr: number, cartItem: CartItem) => (cartItem.lineItemHalfBundleQuantity ?? 0) + curr,
    0
  );
  const hasEvenHalfBundles =
    halfBundleItemsSum !== null && halfBundleItemsSum !== undefined && halfBundleItemsSum % 2 === 0;

  const missingPurchasingCustomer =
    (!purchasingCustomers || purchasingCustomers.length === 0) &&
    purchasingCustomersStatus !== 'idle' &&
    purchasingCustomersStatus !== 'loading';
  const missingEndConsumer =
    (!endConsumers || endConsumers.length === 0) && endConsumersStatus !== 'idle' && endConsumersStatus !== 'loading';

  let errorMesssage = '';
  if (isBundleProgram) {
    if (!isRev && !hasEvenHalfBundles) {
      errorMesssage = COMMERCE_CORE_CONSTANTS.ERROR_MESSAGES.INVALID_BUNDLE_PROGRAM_CART;
    } else if (isRev && cart && !cart.isValid) {
      errorMesssage = COMMERCE_CORE_CONSTANTS.ERROR_MESSAGES.INVALID_BUNDLE_REV_H_PROGRAM_CART;
    }
  }

  if (!errorMesssage && isFabOrSurfaceCart) {
    if (missingPurchasingCustomer && missingEndConsumer) {
      errorMesssage = COMMERCE_CORE_CONSTANTS.ERROR_MESSAGES.CART.FABRICATION_MISSING_PURCHASING_CUSTOMER_END_CONSUMER;
    } else if (missingEndConsumer) {
      errorMesssage = COMMERCE_CORE_CONSTANTS.ERROR_MESSAGES.CART.FABRICATION_MISSING_END_CONSUMER;
    } else if (missingPurchasingCustomer) {
      errorMesssage = COMMERCE_CORE_CONSTANTS.ERROR_MESSAGES.CART.FABRICATION_MISSING_PURCHASING_CUSTOMER;
    }
  }

  useEffect(() => {
    // Sync endConsumers
    if (endConsumersStatus === 'idle' && cart && currentCustomer?.erpCustomerId) {
      dispatch(
        getEndConsumersAsync({
          cartId: cart.id,
          erpCustomerId: currentCustomer.erpCustomerId,
        })
      );
    }
  }, [endConsumersStatus, cart, currentCustomer, dispatch]);

  useEffect(() => {
    // Sync purchasingCustomers
    if (purchasingCustomersStatus === 'idle' && cart && currentCustomer?.erpCustomerId) {
      dispatch(
        getPurchasingCustomersAsync({
          cartId: cart.id,
          erpCustomerId: currentCustomer.erpCustomerId,
        })
      );
    }
  }, [purchasingCustomersStatus, currentCustomer, cart, dispatch]);

  return [
    ((isBundleProgram && !isRev && !hasEvenHalfBundles) ||
      (isBundleProgram && isRev && !cart?.isValid) ||
      (isFabOrSurfaceCart && (missingPurchasingCustomer || missingEndConsumer))) as boolean,
    errorMesssage,
  ] as const;
};
