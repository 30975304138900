import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {requestPasswordResetAsyncFunction, verifyTokenAsyncFunction} from '../service/password.service';
import {putPasswordResetsRequest} from '../controller/password.controller';
import {RootState} from '../../../store/store';

export const initialState = {
  response: null,
};

export const requestPasswordResetAsync = createAsyncThunk('password/requestPasswordReset', async (email: string) => {
  return await requestPasswordResetAsyncFunction(email);
});

export const resetPasswordAsync = createAsyncThunk(
  'password/resetPassword',
  async ({id, resetToken, newPassword}: {id: string; resetToken: string; newPassword: string}) => {
    return await putPasswordResetsRequest({id, resetToken, newPassword});
  }
);

export const verifyTokenAsync = createAsyncThunk(
  'password/verifyToken',
  async ({userName, resetToken}: {userName: string; resetToken: string}) => {
    return await verifyTokenAsyncFunction({userName, resetToken});
  }
);

export const PasswordSlice = createSlice({
  name: 'password',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(requestPasswordResetAsync.pending, () => {})
      .addCase(requestPasswordResetAsync.fulfilled, () => {})
      .addCase(resetPasswordAsync.pending, () => {})
      .addCase(resetPasswordAsync.fulfilled, () => {})
      .addCase(verifyTokenAsync.pending, (state) => {
        state.response = null;
      })
      .addCase(verifyTokenAsync.fulfilled, (state, action) => {
        state.response = action.payload;
      });
  },
});

export const selectVerfiedResponse = (state: RootState) => state.password.response;

export default PasswordSlice.reducer;
