import styled from 'styled-components';
import {NavLink, Link} from 'react-router-dom';
import * as colors from '../../../../Framework/Components/styles/colors';
import {large, xlarge} from '../../../../Framework/Components/styles/media-queries';

export const HeaderNavigationContainer = styled.div`
  flex-flow: row;
  position: relative;
  background-color: #ccc;
  height: 160px;

  .sub-section-title {
    font-weight: 400;
    color: #333;
    padding-left: 0;
    font-size: 14px;
    line-height: 26px;
    text-transform: uppercase;
  }

  .header-product-nav-items {
    padding-left: 9px;
  }
  .link-to-home {
    cursor: pointer;
  }
  @media print {
    visibility: hidden;
  }

  .right-dropdown-container {
    padding: 0;
  }
`;

export const NavItem = styled(NavLink)`
  color: ${colors.black} !important;
  text-transform: uppercase;
  font-size: 14px;
  font-family: 'Montserrat', sans-serif;
  text-align: center;
  font-weight: 600;
  padding: 2px 4px;
  white-space: nowrap;

  ${xlarge} {
    &.compact {
      font-size: 12px;
    }

    &.internal-link.compact {
      font-size: 13px;
      flex: 1;
      text-align: left;
    }
  }

  &.internal-link {
    font-size: 15px;
    flex: 1;
    text-align: left;
  }

  &.selected {
    color: ${colors.primary} !important;
  }

  &:hover {
    color: ${colors.primary} !important;

    .icon.icons-cambria-Ui-Triangle {
      color: ${colors.primary};
    }
  }

  .icon.icons-cambria-Ui-Triangle {
    position: relative;
    bottom: 3px;
    margin-left: 10px;

    ${large} {
      font-size: 5px !important;
    }
  }

  ${large} {
    font-size: 11px !important;
  }
`;

export const DisabledNavLink = styled.div`
  color: ${colors.gray80} !important;
  text-transform: uppercase;
  font-size: 14px;
  font-family: 'Montserrat', sans-serif;
  text-align: center;
  font-weight: 600;
  padding: 2px 4px;
  white-space: nowrap;
  cursor: pointer;

  &.internal-link {
    font-size: 15px;
    flex: 1;
    text-align: left;
  }

  &.selected {
    color: ${colors.primary} !important;
  }

  .icon.icons-cambria-Ui-Triangle {
    position: relative;
    bottom: 3px;
    margin-left: 10px;

    ${large} {
      font-size: 5px !important;
    }
  }

  ${large} {
    font-size: 11px !important;
  }
`;

export const DropdownLink = styled(Link)`
  display: block;
  margin-bottom: 15px;

  &:hover {
    .dropdown-content-section-title {
      color: ${colors.primary};
    }
    .icon {
      color: ${colors.primary};
    }
  }

  .dropdown-content-section-title {
    font-size: 14px;
    font-weight: bolder;
    color: #333;
    text-transform: uppercase;
    white-space: nowrap;

    ${large} {
      font-size: 12px;
    }
  }

  .dropdown-content-section-description {
    font-size: 14px;
    font-weight: 200;
    color: #000;
    line-height: normal;
    max-width: 260px;

    ${large} {
      font-size: 12px;
    }
  }

  .icon {
    margin-left: 7px;
  }
`;

export const InternalDropdownLink = styled.div`
  cursor: pointer;
  display: block;
  margin-bottom: 15px;

  &:hover {
    .dropdown-content-section-title {
      color: ${colors.primary};
    }
    .icon {
      color: ${colors.primary};
    }
  }

  .compact {
  }

  .dropdown-content-section-title {
    font-size: 14px;
    font-weight: bolder;
    color: #333;
    text-transform: uppercase;
    white-space: nowrap;

    ${large} {
      font-size: 12px;
    }
  }

  .dropdown-content-section-description {
    font-size: 14px;
    font-weight: 200;
    color: #000;
    line-height: normal;
    max-width: 260px;

    ${large} {
      font-size: 12px;
    }
  }

  .icon {
    margin-left: 7px;
  }
`;
