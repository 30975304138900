import React, {useCallback, useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import ProductListFilters from '../../../../Core/Components/ProductList/Filters';
import ProductListProducts from '../../../../Core/Components/ProductList/Products';
import {setShipFromWarehouseId} from '../../../../features/warehouse/slice/warehouse.slice';
import {setProductsAsync} from '../../../../features/productCatalog/slice/productCatalog.slice';
import {useAppDispatch, useTypedSelector} from '../../../../hooks/store';
import {
  selectCurrentCustomer,
  selectCurrentCustomerShipToSites,
} from '../../../../features/customer/slice/customer.slice';
import {selectActiveProgram} from '../../../../features/salesforce/slice/salesforce.slice';
import {selectActiveCart} from '../../../../features/cart/slice/cart.slice';
import {getShipFromWarehouseId} from '../../../../features/warehouse/service/warehouse.service';

const pathToProductType: {[path: string]: string} = {
  '/slabs/': 'Slab',
  '/tile/': 'Tile',
  '/store/': 'Store',
  '/samples/': 'Samples',
};

const ProductList = () => {
  const dispatch = useAppDispatch();
  let history = useHistory();

  let productType: string | null = pathToProductType[history.location.pathname.replace('/product', '')] ?? null;

  let customer = useTypedSelector(selectCurrentCustomer);
  let shipToSites = useTypedSelector(selectCurrentCustomerShipToSites);
  let currentProgram = useTypedSelector(selectActiveProgram);
  let cart = useTypedSelector(selectActiveCart);
  const warehouseCode = getShipFromWarehouseId(productType, customer, shipToSites, cart);

  let [oldProductType, setOldProductType]: any = useState('');
  let [oldWarehouseCode, setOldWarehouseCode] = useState(warehouseCode);
  let [lastProductsRequest, setLastProductsRequest] = useState<any>(null);

  const createData = useCallback((offset: number = 0, limit: number = 6) => {
    let data = new URLSearchParams();
    data.append('pager.offset', offset.toString());
    data.append('pager.limit', limit.toString());
    data.append('pager.expand', 'attachments');
    data.append('pager.sort', '-isFeatured');
    data.append('pager.sort', '-isNew');
    data.append('pager.sort', '-isOnSale');
    data.append('pager.sort', 'displayName');

    return data;
  }, []);

  const createProductRequestData = useCallback(
    (productType: string | null, offset?: number, limit?: number) => {
      return {
        data: createData(offset, limit),
        customer,
        currentProgram,
        productType,
        warehouseCode,
        shipToSites,
      };
    },
    [customer, currentProgram, warehouseCode, shipToSites, createData]
  );

  useEffect(() => {
    if (productType && customer?.id && shipToSites) {
      dispatch(setShipFromWarehouseId({productType, customer, shipToSites, cart}));
    }
  }, [dispatch, productType, customer, shipToSites, cart]);

  useEffect(() => {
    if (
      customer?.id &&
      (productType !== oldProductType || warehouseCode !== oldWarehouseCode) &&
      currentProgram?.code &&
      warehouseCode &&
      ((customer.class && customer.class.toLowerCase() !== 'enterprise') || shipToSites)
    ) {
      setOldProductType(productType);
      setOldWarehouseCode(warehouseCode);
      if (lastProductsRequest) {
        lastProductsRequest.abort();
      }
      setLastProductsRequest(dispatch(setProductsAsync(createProductRequestData(productType))));
    }
  }, [
    dispatch,
    customer,
    currentProgram,
    productType,
    warehouseCode,
    oldWarehouseCode,
    createProductRequestData,
    oldProductType,
    shipToSites,
    lastProductsRequest,
  ]);

  return (
    <div className="row product-list-row">
      <ProductListFilters
        productType={productType}
        createProductRequestData={createProductRequestData}
        isSearchResults={false}
        customer={customer}
        cart={cart}
        shipToSites={shipToSites || []}></ProductListFilters>
      <ProductListProducts
        productType={productType}
        createProductRequestData={createProductRequestData}
        isSearchResults={false}></ProductListProducts>
    </div>
  );
};

export default ProductList;
