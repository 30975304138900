import React, {useState} from 'react';
import {useHistory} from 'react-router-dom';
import {OrderSearchParams} from '../../../../../../../features/search/ISearchState';
import {addOrderStringInput, cleanSearchParams} from '../../../../../../../features/order/service/order.service';

const StringInput = ({
  urlSearchParams,
  activeFilter,
  url,
}: {
  urlSearchParams: OrderSearchParams;
  activeFilter: string;
  url: string;
}) => {
  let history = useHistory();

  let [inputValue, setInputValue] = useState('');

  const addStringInputParam = (currentReduxFilters: OrderSearchParams, paramName: string, inputValue: string) => {
    let currentReduxCopy = {...currentReduxFilters};
    currentReduxCopy = addOrderStringInput(paramName, currentReduxCopy, inputValue, url);

    let data = new URLSearchParams(currentReduxCopy);
    data = cleanSearchParams(data);
    history.push({
      pathname: url,
      search: '?' + data.toString(),
    });
  };

  return (
    <div className="input-and-button-container">
      <div className="order-search-bar">
        <input
          className="order-input"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          placeholder={`Search Orders by ${activeFilter}...`}
          onKeyDown={(e) =>
            e.key === 'Enter' && addStringInputParam(urlSearchParams, activeFilter, inputValue)
          }></input>
      </div>
      <div className="search-button-container">
        <button
          className="search-button btn-primary"
          data-testid={'order-search-string-input-button'}
          onClick={() => addStringInputParam(urlSearchParams, activeFilter, inputValue)}>
          SEARCH
        </button>
      </div>
    </div>
  );
};
export default StringInput;
