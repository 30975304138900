import styled from 'styled-components/macro';

export const AddQuoteModalPage = styled.div`
  .application-pill {
    display: block;
    float: left;
    padding: 0px 5px;
    background-color: white;
    border: 1px solid #e9e9e9;
    margin-bottom: 10px;
    margin-right: 10px;

    .application-pill-text {
      font-weight: 300;

      .application-pill-clear-button {
        padding-left: 15px;
        font-size: 13px;
        cursor: pointer;
        font-weight: 600;
      }
    }
  }

  .quote-state-select {
    white-space: nowrap;
  }
`;
