export interface DeliveryDays {
  canPickFriday: boolean;
  canPickMonday: boolean;
  canPickSaturday: boolean;
  canPickSunday: boolean;
  canPickThursday: boolean;
  canPickTuesday: boolean;
  canPickWednesday: boolean;
}

export interface FabOrderEmails {
  cadApprovedEmailList: string;
  cadRevisionsNeededEmailList: string;
  drafters: string;
  orderApprovedEmailList: string;
  poRevisionsNeededEmailList: string;
}

export interface OrderPreferencesNavigationOption {
  name: string;
  urn: string;
}

export const deliveryDaysBlank: DeliveryDays = {
  canPickFriday: false,
  canPickMonday: false,
  canPickSaturday: false,
  canPickSunday: false,
  canPickThursday: false,
  canPickTuesday: false,
  canPickWednesday: false,
};
