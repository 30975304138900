import {toast} from 'react-toastify';
import {ILineItem, IOrderDetails} from '../IOrderDetailsState';
import {hasPermission} from '../../../../store/permission/permission.service';
import {removableStatusCodes, resubmittableStatusCodes} from '../../IOrderState';
import {IOrderHeaderData} from '../../../../App/AuthenticatedPages/Account/OrderHistory/OrderDetails/OrderHeader/IOrderHeader';
import {CustomerFullProfile, Site} from '../../../customer/ICustomerState';
import {Program} from '../../../salesforce/program/IProgramState';
import {ISalesforceAvailablePrograms} from '../../../salesforce/ISalesforceState';
import {getCart} from '../../../cart/service/cart.service';
import {setSelectedProgramByCartItems} from '../../../salesforce/program/service/program.service';
import {PurchasingCustomer, VoucherCode} from '../../../cart/ICartState';
import {deleteOrdersRequest} from '../controller/orderDetails.controller';
import {LineItem} from '../../../../App/AuthenticatedPages/Account/OrderHistory/OrderDetails/IOrderDetails';
import {getItems} from '../../../productCatalog/controller/productCatalog.controller';

export const processWidenLink = (link: string, productType: string, quality: number) => {
  let dimensions = '100x45px';
  let processedLink = link.replace('{size}px@{scale}x', dimensions);
  processedLink = processedLink.replace('{quality}', quality.toString());
  return processedLink;
};

export const isOrderComplete = (statusCode: string): boolean => {
  return statusCode === 'Shipped' || statusCode === 'Cancelled';
};

export const isActiveOrder = (statusCode: string): boolean => {
  return !isOrderComplete(statusCode);
};

export const removeOrder = async (orderNumber: string) => {
  try {
    let results = await deleteOrdersRequest(orderNumber);
    toast.success(`Order #${orderNumber} was successfully removed`);
    return results;
  } catch {
    toast.error(`Failed to remove order #${orderNumber}`);
  }
};

export const isFabricatedOrder = (productType: string, orderType: string): boolean => {
  const orderTypeIsFabricated =
    orderType && (orderType.startsWith('FabShop') || orderType.startsWith('Surfaces')) && productType !== 'Tile';
  return !!(
    productType === 'Fabricated' ||
    productType === 'FabricatedService' ||
    productType === 'Surfaces' ||
    productType === 'Fabrication' ||
    orderTypeIsFabricated
  );
};
export const getIsEditReorderable = (
  order: IOrderDetails,
  isFabOrder: boolean,
  hasCIARevisablePermission: boolean
): boolean => {
  return (
    (isFabOrder &&
      (order.statusCode === 'Submitting' ||
        order.statusCode === 'Submitted' ||
        order.statusCode === 'SubmitFailed' ||
        order.statusCode === 'SubmitSuccess' ||
        order.statusCode === 'OrderReceived' ||
        order.statusCode === 'PreparingShipment') &&
      !hasCIARevisablePermission) ||
    (hasCIARevisablePermission &&
      isFabOrder &&
      (order.statusCode === 'OrderReceived' || order.statusCode === 'PreparingShipment'))
  );
};

export const getCanEditPurchasingCustomers = (firstLineItem: ILineItem) => {
  const productType = firstLineItem.productType;
  return !(productType === 'Tile' || productType === 'Samples' || productType === 'Store');
};

export const checkIfHasLineItemsShipDatePermissions = (userPermissions: string[]) => {
  return hasPermission('urn:csa:commerceui:order:seeItemsShipDate', userPermissions);
};
export const checkIfHasPermissionsToSeeJobNumber = (userPermissions: string[]) => {
  return hasPermission('urn:csa:commerceui:order:fab:showUnofficalJobNumber', userPermissions);
};
export const checkIfHasCIAResubmittablePermission = (userPermissions: string[]) => {
  return hasPermission('urn:csa:commerceui:ciaResubmitOrder', userPermissions);
};
export const checkIfHasCIARevisablePermission = (userPermissions: string[]) => {
  return hasPermission('urn:csa:commerceui:ciaReviseOrder', userPermissions);
};
export const checkIfHasCIAReorderablePermission = (userPermissions: string[]) => {
  return hasPermission('urn:csa:commerceui:ciaReorderOrder', userPermissions);
};

export const getIsRemovable = (orderStatus: string, orderSourceSystem: string): boolean => {
  if (orderSourceSystem === 'CDMP') {
    return removableStatusCodes.some((status) => status === orderStatus);
  }
  return false;
};

export const getIsResubmittable = (order: any): boolean => {
  return resubmittableStatusCodes.some((status) => status === order.statusCode);
};

export const getIsReorderable = (order: any, hasCIAReorderablePermission: boolean): boolean => {
  return (
    (!order.isReturnOrder && !hasCIAReorderablePermission) ||
    (hasCIAReorderablePermission &&
      !order.isReturnOrder &&
      (order.statusCode === 'Shipped' || order.statusCode === 'Cancelled'))
  );
};

export const getJobNumberFromOrder = (isFabOrder: boolean, order: any): string => {
  return isFabOrder ? order.fabricationJobNumber : null;
};

export const getShouldUseOrderMetadata = (order: any) => {
  return (
    order.orderMetadata &&
    (order.statusCode === 'Pending' ||
      order.statusCode === 'Verified' ||
      order.statusCode === 'Submitted' ||
      order.statusCode === 'RejectedPoCccRevisingOrder' ||
      order.statusCode === 'RejectedCAD' ||
      order.statusCode === 'RejectedPo' ||
      order.statusCode === 'AwaitingPoApproval' ||
      order.statusCode === 'AwaitingCadApproval' ||
      order.statusCode === 'SubmitSuccessful' ||
      order.statusCode === 'SubmitFailed')
  );
};

export const getOrderFavorite = (userId: string, order: IOrderDetails): string => {
  const orderFavorite = order.favorites.find((fav: any) => fav.userId === userId);
  return orderFavorite ? orderFavorite.name : '';
};

export const getOrderHeaderData = (
  order: any,
  userName: string,
  orderCreatedBy: string,
  isFabOrder: boolean,
  shouldUseOrderMetadata: boolean,
  userId: number
): IOrderHeaderData => {
  if (shouldUseOrderMetadata && order.statusCode === 'Pending') {
    let orderDataTemp = {
      status: order.statusDisplayName,
      statusCode: order.statusCode,
      orderCreatedBy: orderCreatedBy,
      orderNumber: order.orderNumber,
      dateOrdered: new Date(order.orderMetadata.orderHeaderData.dateOrdered as string),
    };
    return {...orderDataTemp, ...order.orderMetadata.orderHeaderData};
  }
  let publicFacingDisplay: boolean = false,
    orderPieceRework: boolean = false,
    programName: string = '';

  if (order && order.orderLineItems && order.orderLineItems.length > 0) {
    programName = order.orderLineItems[0].programName;
  }
  publicFacingDisplay = order.publicFacingDisplay;
  orderPieceRework = order.orderPieceRework;

  return {
    customerName: order.billToName,
    dateOrdered: order.createdAt,
    purchaseOrderNumber: order.purchaseOrderNumber,
    homeDepotOrderNumber: order.homeDepotOrderNumber,
    orderNumber: order.orderNumber,
    status: order.statusDisplayName,
    statusCode: order.statusCode,
    orderTypeDisplayName: order.orderTypeDisplayName,
    orderUpdatedBy: userName,
    orderCrmAccountName: order.orderCrmAccountName,
    orderCreatedBy: orderCreatedBy,
    shippingInstructions: order.shippingInstructions,
    programName,
    publicFacingDisplay,
    orderPieceRework,
    jobNumber: getJobNumberFromOrder(isFabOrder, order),
    deliveryMethod:
      order.orderLineItems && order.orderLineItems.length > 0 ? order.orderLineItems[0].shippingMethod : null,
    fabOrderRevisionDisplayValue: order.fabOrderRevisionDisplayValue,
    fabricationInstructions: order.fabricationInstructions,
    crmQuoteNumber: order.crmQuoteNumber,
    orderFavoriteName: getOrderFavorite(userId.toString(), order),
    homeDepotOrderNumbers: order.homeDepotOrderNumbers,
  };
};

export const getContactName = (order: IOrderDetails): string => {
  let contactName: string = '';

  if (order.shipToContactFirstName) {
    contactName = order.shipToContactFirstName;
  }
  if (order.shipToContactLastName) {
    contactName += ' ' + order.shipToContactLastName;
  }
  return contactName;
};

export const setSelectedProgram = async (
  lastCartId: string,
  customer: CustomerFullProfile,
  userId: string,
  activeProgram: Program,
  shipToSites: Site[],
  billToSites: Site[],
  dispatch: any,
  availablePrograms: ISalesforceAvailablePrograms | null
) => {
  const lastCart = await getCart(
    lastCartId,
    customer,
    userId,
    activeProgram,
    shipToSites,
    billToSites,
    dispatch,
    availablePrograms,
    true,
    true
  );
  const cartItems = lastCart ? lastCart.cartItems : null;
  const {isProgramAvailable, programSelected} = await setSelectedProgramByCartItems(
    cartItems,
    availablePrograms,
    activeProgram,
    dispatch
  );

  return {hasSelectedProgram: isProgramAvailable, programSelected};
};

export const getPurchasingCustomers = (
  orderDetails: IOrderDetails,
  shouldUseOrderMetadata: boolean
): PurchasingCustomer[] => {
  let purchasingCustomers: PurchasingCustomer[] = [];

  if (!shouldUseOrderMetadata) {
    mapPurchasingCustomerAndEndConsumerForComponent(orderDetails);
    purchasingCustomers = orderDetails.pointOfSales;
  }

  if (shouldUseOrderMetadata && orderDetails.orderMetadata) {
    orderDetails.orderMetadata.purchasingCustomers.forEach((pc: any) => {
      (pc as any).endUsers = pc.endConsumers;
    });

    purchasingCustomers = orderDetails.orderMetadata.purchasingCustomers;
  }

  return purchasingCustomers;
};

export const mapPurchasingCustomerAndEndConsumerForComponent = (order: IOrderDetails) => {
  order.pointOfSales.forEach((pointOfSale) => {
    pointOfSale.endUsers = pointOfSale.endConsumers;
  });
};

export const getIsShippedOrCanceled = (order: IOrderDetails): boolean => {
  return (
    getIsShipped(order) ||
    order.statusCode === 'Cancelled' ||
    !order.orderLineItems[0] ||
    (!order.orderLineItems[0].cartItemId && !order.orderLineItems[0].parentCartItemId)
  );
};

const getIsShipped = (order: IOrderDetails): boolean => {
  return order.statusCode === 'PartiallyShipped' || order.statusCode === 'Shipped';
};

export const getLineItemsForOrderProductTable = async (
  orderDetails: IOrderDetails,
  calculateDiscountsInBackend: boolean = false
): Promise<LineItem[]> => {
  if (!orderDetails?.orderLineItems?.length) {
    return [];
  }
  const itemInformation =
    orderDetails.orderTypeDisplayName !== 'Fabricated'
      ? await getItems(orderDetails.orderLineItems.map((line) => line.itemNumber))
      : [];

  let orderItemsList: ILineItem[] = orderDetails.orderLineItems;
  return orderItemsList.map((item: ILineItem) => {
    let newItem: LineItem = JSON.parse(JSON.stringify(item));
    newItem.description = item.itemDescription;
    newItem.linePrice = item.extendedPrice ? item.extendedPrice : item.unitPrice * item.unitSize;
    newItem.itemNumber = parseInt(item.itemNumber);
    newItem.widenLink = '';
    newItem.requestedDelivery = item.deliverySchedule?.requestedDeliveryDate
      ? new Date(item.deliverySchedule.requestedDeliveryDate)
      : null;
    newItem.seamed = !!item.deliverySchedule?.seamed;
    newItem.scheduleDelivery =
      item.deliveries?.length > 0 && item.deliveries[0].scheduleShipDate
        ? new Date(item.deliveries[0].scheduleShipDate)
        : null;
    newItem.status = item.statusDisplayName;
    newItem.expectedOrderDate = null;

    let filteredList = itemInformation.filter((x) => x.id === item.itemNumber);
    let widenLink = '';
    if (filteredList && filteredList.length > 0) {
      try {
        widenLink = filteredList[0].attachments[0].location;
      } catch (e: any) {
        console.log(e.message);
      }
    }
    let linkToSave = processWidenLink(widenLink, item.productType, 100);
    newItem.widenLink = linkToSave;

    if (orderDetails.statusCode === 'Pending' && !calculateDiscountsInBackend) {
      const voucherCodes = orderDetails.orderMetadata?.voucherCodes
        ? orderDetails.orderMetadata.voucherCodes.filter(
            (voucher: VoucherCode) =>
              newItem.itemNumber === parseInt(voucher.itemNumber) &&
              (!item.cartItemId || item.cartItemId.toString() === voucher.cartItemId)
          )
        : [];
      newItem.extendedNetPrice = item.unitPrice * item.uomLineQuantity;
      if (voucherCodes.length > 0) {
        newItem.discountAmount = voucherCodes.reduce((a, b) => {
          return a + b.adjustmentAmount;
        }, 0);
        newItem.hasAdjustedExtendedNetPrice = true;
        newItem.hasAdjustedUnitPrice = true;
        newItem.adjustedExtendedNetPrice = newItem.extendedNetPrice - newItem.discountAmount;
        newItem.adjustedUnitPrice = Math.round(newItem.adjustedExtendedNetPrice / item.uomLineQuantity);
      } else {
        newItem.discountAmount = 0;
        newItem.adjustedExtendedNetPrice = item.extendedNetPrice - newItem.discountAmount;
        newItem.adjustedUnitPrice = item.unitPrice;
      }
    } else {
      if (item.netPrice < item.basePrice) {
        newItem.hasAdjustedExtendedNetPrice = true;
        newItem.hasAdjustedUnitPrice = true;
        newItem.adjustedExtendedNetPrice = item.extendedNetPrice;
        newItem.extendedNetPrice = item.extendedBasePrice;
      }
      newItem.adjustedUnitPrice = item.netPrice;
      newItem.unitPrice = item.basePrice;
      newItem.currency = orderDetails.orderLineItems[0].currency;
    }
    return newItem;
  });
};
