import styled from 'styled-components/macro';
import {$gray30} from '../../../../Styled/variables';

const OrderDetailsContainer = styled.div`
  color: #acacac !important;
  font-size: 12px !important;
  font-weight: 600;
  .order-details-value {
    color: ${$gray30};
  }
`;
export default OrderDetailsContainer;
