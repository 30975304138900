import React from 'react';
import DateBlock from '../../../../../../../Core/Components/OrderGrid/DateBlock';
import StatusCode from '../../../../../../../Core/Components/StatusCode';
import Icon from '../../../../../../../Framework/Components/Icon';
import {IOrderHeaderData} from '../IOrderHeader';

const OrderDetailsHeaderDesktop = ({
  headerData,
  canSeeJobNumber,
}: {
  headerData: IOrderHeaderData;
  canSeeJobNumber: boolean;
}) => {
  return (
    <div className="order-details-background">
      <div className="order-detail-table-row">
        <div className="order-name">
          <DateBlock date={headerData.dateOrdered ? new Date(headerData.dateOrdered) : null} />
        </div>
        <div className="order-details-subcontainer">
          {headerData.orderCreatedBy && (
            <div>
              Order Created By: <span className="order-details-value">{headerData.orderCreatedBy}</span>
            </div>
          )}

          <div>
            Order Type: <span className="order-details-value">{headerData.orderTypeDisplayName}</span>
          </div>
          {canSeeJobNumber && headerData.jobNumber && (
            <div>
              Job Number: <span className="order-details-value">{headerData.jobNumber}</span>
            </div>
          )}

          {headerData.orderFavoriteName && (
            <div className="favorite-name">
              <Icon icon="icons-cambria-General-Favorites-Filled" /> {headerData.orderFavoriteName}
            </div>
          )}

          {headerData.fabOrderRevisionDisplayValue && (
            <div>
              Revision: <span className="order-details-value">{headerData.fabOrderRevisionDisplayValue}</span>
            </div>
          )}
        </div>
        <div className="order-details-subcontainer">
          Order Number: <span className="order-details-value">{headerData.orderNumber}</span>
          <br />
          Purchase Order Number: <span className="order-details-value">{headerData.purchaseOrderNumber}</span>
          <br />
          {headerData.crmQuoteNumber && (
            <div>
              Quote Number: <span className="order-details-value">{headerData.crmQuoteNumber}</span>
            </div>
          )}
          {headerData.orderCrmAccountName && (
            <>
              Salesforce Account: <span className="order-details-value">{headerData.orderCrmAccountName}</span>
            </>
          )}
        </div>

        <div className="order-details-subcontainer">
          {headerData.programName && (
            <>
              Program Name: <span className="order-details-value">{headerData.programName}</span>
              <br />
            </>
          )}
          Delivery Method: <span className="order-details-value">{headerData.deliveryMethod}</span>
        </div>
        <div className="pointer-on-hover" title={headerData.statusCode}>
          <StatusCode status={headerData.status ? headerData.status : ''} />
        </div>
      </div>
      {headerData.fabricationInstructions ||
      headerData.shippingInstructions ||
      headerData?.homeDepotOrderNumbers?.length > 0 ? (
        <div className="order-details-subcontainer special-instructions">
          {headerData.fabricationInstructions ? (
            <div>
              Fabrication Instructions:{' '}
              <span className="order-details-value">{headerData.fabricationInstructions}</span>
            </div>
          ) : (
            <></>
          )}
          {headerData.shippingInstructions ? (
            <div className="mt-2">
              Shipping Instructions: <span className="order-details-value">{headerData.shippingInstructions}</span>
            </div>
          ) : (
            <></>
          )}
          {headerData.homeDepotOrderNumbers && headerData.homeDepotOrderNumbers.length > 0 && (
            <div className="mt-2">
              Home Depot Order Numbers:&nbsp;
              {headerData.homeDepotOrderNumbers.map((number: string, index) => {
                return (
                  <span className="order-details-value" key={index}>
                    {number}
                    {index + 1 !== headerData.homeDepotOrderNumbers.length && ', '}
                  </span>
                );
              })}
            </div>
          )}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default OrderDetailsHeaderDesktop;
