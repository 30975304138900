import styled from 'styled-components/macro';
import {$newprimary, $newwhite} from '../../../../Styled/variables';

const MobileQuoteCardContainer = styled.div`
  margin: 10px;
  width: 340px;
  display: grid;

  .mobile-card-body {
    box-shadow: 0px 0px 3px 0px rgba(101, 101, 101, 0.35);
    background: ${$newwhite};
    border-radius: 3px;
    padding: 10px;
    .row-1 {
      display: flex;
      justify-content: flex-start;
      .address-status-col {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-bottom: 15px;
      }
      .status-code {
      }
    }
    .row-2 {
    }
    .date-block {
      margin: 0px 15px 15px 0px;
    }
  }
  .amount-details-footer {
    display: flex;
    justify-content: space-between;
    border-radius: 0px 0px 3px 3px;
    background: ${$newwhite};
    font-size: 12px;
    font-weight: 600;
    .amount {
      width: 50%;
      display: flex;
      justify-content: center;
      box-shadow: 0px 0px 3px 0px rgba(101, 101, 101, 0.35);
      padding: 10px;
    }
    .details {
      box-shadow: 0px 0px 3px 0px rgba(101, 101, 101, 0.35);
      width: 50%;
      display: flex;
      justify-content: center;
      padding: 10px;
      color: ${$newprimary};
      :hover {
        cursor: pointer;
      }
    }
  }
`;

export default MobileQuoteCardContainer;
