import React from 'react';
import SpinnerContainer from './SpinnerContainer.styled';

const CambriaSpinner = () => {
  return (
    <SpinnerContainer>
      <div id="bootstrap-hack" className="main-view anim-in-out anim-fade" data-anim-speed="500" data-anim-sync="true">
        <div className="splash-page">
          <div className="logo-container">
            <object data="/app/assets/images/main-logo.svg" type="image/svg+xml" id="logo">
              spinner
            </object>
          </div>
        </div>
      </div>
    </SpinnerContainer>
  );
};
export default CambriaSpinner;
