import React from 'react';
import {Table} from 'react-bootstrap';
import useWindowDimensions from '../../../../../hooks/getWindowDimensions';
import DesktopResults from './desktop/CustomerTableDesktop.component';
import MobileResults from './mobile/CustomerTableMobile.component';

const NoCustomerResults = () => {
  return <div className="no-customers-found">No Customers Found</div>;
};

const CustomerTable = ({
  customerSuggestions,
  numberOfEntries,
  searchWord,
  customerSelectionFunction,
  isLoading,
}: {
  customerSuggestions: any[];
  numberOfEntries: number;
  searchWord: string;
  customerSelectionFunction: Function;
  isLoading: boolean;
}) => {
  const {width} = useWindowDimensions();

  return (
    <div className="customer-search-section container">
      <div className="customer-list-header">
        <h1 className="h2 light-weight clearfix">CUSTOMER LIST</h1>
        {searchWord ? (
          <span className="results-text in-header">
            {numberOfEntries} Search Results for &nbsp;<span className="number"> "{searchWord}" </span>
          </span>
        ) : (
          <span className="results-text in-header">
            NUMBER OF ENTRIES: &nbsp;<span className="number">{numberOfEntries}</span>
          </span>
        )}
      </div>
      <div className="customer-table">
        <Table bordered={false} borderless striped className="table">
          <thead>
            <tr className="customer-table-header">
              <th className="mobile-hidden customer-table-header-cell">
                <span className="customer-table-header">CUSTOMER NAME</span>
              </th>
              <th className="mobile-hidden customer-table-header-cell">
                <span className="customer-table-header">ADDRESS</span>
              </th>
              <th className="mobile-hidden customer-table-header-cell">
                <span className="customer-table-header">CLASS</span>
              </th>
            </tr>
          </thead>
          {width > 960 && customerSuggestions ? (
            <DesktopResults
              suggestions={customerSuggestions}
              isLoading={isLoading}
              customerSelectionFunction={customerSelectionFunction}
            />
          ) : (
            <></>
          )}
        </Table>
        <div className="customer-table-body">
          {!customerSuggestions || (customerSuggestions.length === 0 && isLoading === false) ? (
            <NoCustomerResults />
          ) : (
            <></>
          )}
          {width < 960 && customerSuggestions ? (
            <MobileResults
              suggestions={customerSuggestions}
              isLoading={isLoading}
              customerSelectionFunction={customerSelectionFunction}
            />
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};
export default CustomerTable;
