import React from 'react';
import COMMERCE_CORE_CONSTANTS from '../../../../../Core/constants';
import DeliveryDayPreferences from '../DeliveryDaysComponent/DeliveryDays.component';

const SamplesPreferences = ({erpCustomerId}: {erpCustomerId: number}) => {
  let samplesEndpoint = `${COMMERCE_CORE_CONSTANTS.API_SERVICES.CUSTOMER.samplesOrderPreferences}`;

  return (
    <div className="order-preference-body">
      <div className="order-preference-page-header">
        <h1 className="preference-page-title">SAMPLES ORDER PREFERENCES</h1>
        <p className="preference-page-subheading">DELIVERY DAYS</p>
      </div>
      <DeliveryDayPreferences endpoint={samplesEndpoint} erpCustomerId={erpCustomerId} />
    </div>
  );
};

export default SamplesPreferences;
