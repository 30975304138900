import styled from 'styled-components/macro';
import {
  $branddanger,
  $fontsizeh2sm,
  $gray78,
  $gray97,
  $newinactivebackground,
  $newprimaryfont,
  $screensmmax,
} from '../../../Styled/variables';

export const CartPage = styled.div`
  padding-bottom: 50px;

  .cart-container {
    padding-left: 0px;
    padding-right: 0px;

    .cart {
      margin-bottom: 50px;

      .shopping-cart-header-text-wrapper {
        margin-bottom: 20px;

        .shopping-cart-header-text {
          font-weight: 300 !important;
        }

        @media (max-width: 960px) {
          .shopping-cart-header-text {
            margin-bottom: 15px;
          }
        }
      }

      .cart-header-text-container {
        padding: 20px;
        background-color: ${$newinactivebackground};
        margin-top: 20px;
        margin-bottom: 20px;

        .cart-header-text {
          font-weight: 600;
        }
      }

      .continue-shopping-button-wrapper {
        margin-top: -50px;
        margin-bottom: 10px;
      }

      .saved-items-header-text {
        font-weight: 300;
        margin-top: 50px;
      }

      .margin-botom {
        margin-bottom: 20vh;
      }

      h2 {
        font-size: ${$fontsizeh2sm};
        font-weight: normal;
      }

      .btn-primary {
        color: #fff !important;
        background-color: #c59617 !important;
        border-color: #979797 !important;
        width: 100%;

        :hover {
          background-color: #977312 !important; //linear-gradient(0deg, rgba(0, 0, 0, 0.295), rgba(0, 0, 0, 0.259)), #c59617 !important;
        }
      }

      .step,
      .comments {
        background-color: ${$gray97};
        border: 1px solid ${$gray78};
        padding: 30px;
      }

      .discount-code-error-message {
        color: rgb(196, 15, 15);
      }

      div.comments-container {
        margin-top: 30px;
        padding: 0px;
        border: none;
        background-color: transparent;
      }

      .sub-text {
        font-size: 14px;

        .item-field-remove-save-buttons {
          font-weight: 500;
          text-decoration: underline;
        }
      }

      .items {
        padding: 0 !important;

        > .row {
          border-bottom: none !important;

          &:last-child {
            border-bottom: 0;
          }
        }
      }

      .item-header {
        padding: 0px 0px 10px 0px;
        font-size: 12px;
        font-weight: 600;
      }

      .unit-price-header-text {
        position: relative;
        right: 10px;
      }

      .cart-item-qty-input {
        input {
          border: none;
          box-shadow: none !important;
          background-color: transparent !important;
        }

        .counter-minus-icon-container {
          left: -9px;
        }

        .counter-plus-icon-container {
          right: -9px;
        }
      }

      .product-quantity-text {
        padding-top: 17px;
      }

      .batch-add-button {
        margin: 20px 0px;
      }

      .items-container {
        box-shadow: 0px 0px 3px #ccc;
      }

      @media (max-width: 960px) {
        .items-container {
          box-shadow: none;
        }
      }

      .white-item {
        background-color: #fff;
      }

      .item-image-class {
        width: 100%;
      }

      .item-field-price-text {
        font-size: 14px;
        font-weight: 600;
      }

      .item-field-currency-text {
        font-size: 10px;
        font-weight: 600;
      }

      .item {
        padding: 15px;

        .item-qty {
          input {
            max-width: 100%;
          }

          .warning-message {
            color: ${$branddanger};
            font-size: 12px;
            font-family: ${$newprimaryfont};
          }
        }

        .item-name a {
          color: #4c4c4c;

          &:hover {
            color: lighten(#4c4c4c, 10%);
          }
        }

        @media (max-width: ${$screensmmax}) {
          .item-field {
            margin-top: 10px;
            margin-bottom: 10px;

            .hidden-md {
              padding: 10px;
            }

            &:first-of-type {
              margin-top: 0;
            }
          }

          .item-image {
            max-width: 220px;
            margin: auto;
          }

          .item-name {
            text-align: center;
            margin: auto;
          }

          .item-qty {
            max-width: 90px;
            margin: auto;

            .form-group {
              margin: 0;
            }
          }
        }

        @media (max-width: 560px) {
          .item-field {
            font-size: 14px;
            margin-top: 6px;
            margin-bottom: 6px;

            .hidden-md {
              padding: 6px;
            }

            &:first-of-type {
              margin-top: 0;
            }

            &:last-of-type {
              margin-bottom: 12px;
            }
          }

          .item-qty {
            max-width: 50px;
            margin: auto;
          }

          .sub-text {
            font-size: 12px;
          }
        }
      }

      .clear-cart-button {
        color: #8c8c8c;
        border: 1px solid #c8c8c8;
        transition: all ease-in-out 0.5s;

        &:hover {
          color: #c8c8c8;
        }

        span {
          color: #8c8c8c;
        }
      }

      .btn-quotes {
        color: #8c8c8c;
        font-weight: 500;
        text-decoration: underline;
        transition: all ease-in-out 0.5s;

        &:hover {
          color: #c8c8c8;
        }

        &:focus {
          box-shadow: none;
        }
      }

      .divider {
        margin: 44px auto;
        width: calc(100% - 40px);
        border-color: ${$gray78};

        &:before {
          border-color: ${$gray78};
        }
      }
    }

    .cart-select-item-modal {
      .modal-container {
        padding: 20px;
      }

      .select-item {
        background-color: ${$gray97};
        border: 1px solid ${$gray78};
        padding: 20px;
        margin-top: 10px;
      }

      .quantity {
        font-size: 30px;
        font-weight: bold;
      }
    }

    .pricingAdjusmentContainer {
      margin-top: 20px;
      margin-left: 0 !important;

      .ui-select-choices.ui-select-choices-content.ui-select-dropdown.dropdown-menu {
        height: auto;
        max-height: 25vh;
      }
    }

    .adjustmentAmountInputBox {
      padding-left: 10px !important;
      margin-top: 28px;
      z-index: 4;
    }

    .original-price-strike-through {
      text-decoration: line-through;
      font-size: 0.8em;
    }

    .proceed-to-checkout-button,
    .proceed-to-purchase-agreement {
      margin-bottom: 30px;
    }

    @media (max-width: 960px) {
      .cart {
        margin-top: 70px;
        padding: 0px 15px;
      }

      .request-commercial-quote-button {
        margin-top: 25px;
      }

      .adjustmentAmountInputBox {
        margin-top: 0px !important;
        padding-left: 0px !important;
      }
    }
  }
`;
