import React, {useCallback, useEffect, useState} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {selectCurrentUser} from '../../../../features/auth/slice/authentication.slice';
import {getCart} from '../../../../features/cart/service/cart.service';
import {selectActiveCart, setActiveCart} from '../../../../features/cart/slice/cart.slice';
import {Cart, NewCart} from '../../../../features/cart/ICartState';
import {savePaymentMethod, saveTaxInformation} from '../../../../features/checkout/service/checkout.service';
import {
  hideCheckoutStep,
  selectActiveStep,
  selectCheckoutSteps,
  selectPaymentMethodpageSkipped,
  selectSavePaymentMethodToCart,
  setActiveStep,
  setIsSubmitting,
  setPaymentMethodPageSkipped,
  setSavePaymentMethodToCart,
  setStep2Completed,
} from '../../../../features/checkout/slice/checkout.slice';
import {
  selectCurrentCustomer,
  selectCurrentCustomerBillToSite,
  selectCurrentCustomerBillToSites,
  selectCurrentCustomerShipToSites,
  setCustomerBillToSite,
} from '../../../../features/customer/slice/customer.slice';
import {BillToSite, CustomerFullProfile} from '../../../../features/customer/ICustomerState';
import {hideFullscreenLoader} from '../../../../features/fullscreenLoader/slice/fullscreenLoader.slice';
import {PaymentSelection} from '../../../../features/payment/IPaymentState';
import {getPaymentSelections, selectPaymentSelections} from '../../../../features/payment/slice/payment.slice';
import {setCheckoutInformationLoaded} from '../../../../features/resolves/slice/resolves.slice';
import {selectActiveProgram, selectAvailablePrograms} from '../../../../features/salesforce/slice/salesforce.slice';
import {useAppDispatch, useTypedSelector} from '../../../../hooks/store';
import {ISalesforceAvailablePrograms} from '../../../../features/salesforce/ISalesforceState';
import COMMERCE_CORE_CONSTANTS from '../../../../Core/constants';
import {toast} from 'react-toastify';
import {useDisabledCheckout} from '../../../../features/cart/hooks/useDisabledCheckout/useDisabledCheckout';
import {selectUiSettings} from '../../../../features/environment/slice/environment.slice';

const CheckoutResolves = ({children}: {children: any}) => {
  const activeUrl = useLocation();
  const dispatch = useAppDispatch();
  const history = useHistory();

  const [billToSiteHasBeenFiltered, setBillToSiteHasBeenFiltered] = useState(false);

  const activeCart = useTypedSelector(selectActiveCart);
  const currentCustomer = useTypedSelector(selectCurrentCustomer);
  const billToSite = useTypedSelector(selectCurrentCustomerBillToSite);
  const checkoutSteps = useTypedSelector(selectCheckoutSteps);
  const paymentSelections = useTypedSelector(selectPaymentSelections);
  const userInfo = useTypedSelector(selectCurrentUser);
  const shipToSites = useTypedSelector(selectCurrentCustomerShipToSites);
  const activeProgram = useTypedSelector(selectActiveProgram);
  const customerBillToSites = useTypedSelector(selectCurrentCustomerBillToSites);
  const paymentMethodPageHasBeenSkipped = useTypedSelector(selectPaymentMethodpageSkipped);
  const shouldSavePaymentMethodToCart = useTypedSelector(selectSavePaymentMethodToCart);
  const activeStep = useTypedSelector(selectActiveStep);
  const uiSettings = useTypedSelector(selectUiSettings);
  const availablePrograms: ISalesforceAvailablePrograms | null = useTypedSelector(selectAvailablePrograms);
  const [checkoutDisabled, checkoutDisabledErrorMessage] = useDisabledCheckout();

  const loadPaymentSelections = useCallback(
    async (cart: Cart, customer: CustomerFullProfile, billToSite: BillToSite) => {
      let currentCartProductType = cart.cartItems[0]?.productType;
      await dispatch(
        getPaymentSelections({
          erpPaymentTermsId: billToSite.erpPaymentTermsId,
          customerClass: customer.class,
          productType: currentCartProductType,
        })
      );
    },
    [dispatch]
  );

  const shouldSkipPaymentMethodPage = useCallback((paymentTerm: string) => {
    const isOnlyOnePaymentTerm = paymentTerm && paymentTerm.split(',').length === 1;
    const isItNET = paymentTerm && paymentTerm.includes('NET');

    if (isOnlyOnePaymentTerm && isItNET) {
      return true;
    } else {
      return false;
    }
  }, []);

  useEffect(() => {
    if (!activeUrl.pathname.includes(COMMERCE_CORE_CONSTANTS.PATHNAMES.baseCheckout)) {
      dispatch(setCheckoutInformationLoaded(true));
    } else if (
      activeUrl.pathname.includes(COMMERCE_CORE_CONSTANTS.PATHNAMES.baseCheckout) &&
      !activeUrl.pathname.includes(COMMERCE_CORE_CONSTANTS.PATHNAMES.orderConfirmation) &&
      activeCart
    ) {
      if (activeCart.cartItems.length === 0) {
        history.push(COMMERCE_CORE_CONSTANTS.PATHNAMES.home);
      } else if (checkoutDisabled) {
        toast.error(checkoutDisabledErrorMessage);
        history.push(COMMERCE_CORE_CONSTANTS.PATHNAMES.cart);
      }
    }
  }, [activeUrl, dispatch, activeCart, history, checkoutDisabled, checkoutDisabledErrorMessage]);

  useEffect(() => {
    if (paymentSelections === null && currentCustomer && billToSite && activeCart && activeCart.cartItems.length > 0) {
      loadPaymentSelections(activeCart, currentCustomer, billToSite);
    }
  }, [paymentSelections, activeCart, currentCustomer, billToSite, loadPaymentSelections]);

  useEffect(() => {
    if (paymentSelections && checkoutSteps) {
      if (shouldSkipPaymentMethodPage(paymentSelections.code)) {
        dispatch(setPaymentMethodPageSkipped(true));
        dispatch(hideCheckoutStep(checkoutSteps[2]));
      }
      dispatch(setCheckoutInformationLoaded(true));
    }
  }, [paymentSelections, dispatch, shouldSkipPaymentMethodPage, checkoutSteps]);

  useEffect(() => {
    const savePaymentMethodToCart = async (paymentMethod: PaymentSelection) => {
      try {
        await savePaymentMethod(paymentMethod, null, billToSite, activeCart || NewCart(), dispatch);
        await saveTaxInformation(activeCart || NewCart(), shipToSites || [], currentCustomer, dispatch);
        const updatedCart = await getCart(
          activeCart?.id || '',
          currentCustomer,
          userInfo?.userId || '',
          activeProgram,
          shipToSites || [],
          customerBillToSites || [],
          dispatch,
          availablePrograms,
          false,
          true,
          true,
          uiSettings?.calculateCartDiscountsInBackend
        );
        dispatch(setActiveCart(updatedCart));
        dispatch(setSavePaymentMethodToCart(false));
        dispatch(setStep2Completed(true));
        dispatch(setIsSubmitting(false));
      } catch (e: any) {
        dispatch(hideFullscreenLoader());
        throw new Error(e);
      }
    };

    if (
      activeCart?.shippingMethods &&
      activeCart.shippingMethods.length > 0 &&
      paymentMethodPageHasBeenSkipped &&
      paymentSelections &&
      shouldSavePaymentMethodToCart &&
      uiSettings
    ) {
      savePaymentMethodToCart(paymentSelections);
      dispatch(setSavePaymentMethodToCart(false));
    }
  }, [
    activeCart,
    paymentSelections,
    paymentMethodPageHasBeenSkipped,
    activeProgram,
    billToSite,
    currentCustomer,
    customerBillToSites,
    dispatch,
    shipToSites,
    shouldSavePaymentMethodToCart,
    userInfo,
    availablePrograms,
    uiSettings,
  ]);

  useEffect(() => {
    if (
      !billToSiteHasBeenFiltered &&
      activeUrl.pathname.includes('/checkout') &&
      activeCart &&
      activeCart.cartItems.length > 0
    ) {
      const cartOperatingUnitCode = activeCart.cartItems[0].operatingUnitCode;
      const billToSitesFilteredByOperatingUnit = customerBillToSites?.filter(
        (x: BillToSite) => x.operatingUnitCode === cartOperatingUnitCode
      );

      if (billToSitesFilteredByOperatingUnit) {
        dispatch(setCustomerBillToSite(billToSitesFilteredByOperatingUnit[0]));
        setBillToSiteHasBeenFiltered(true);
      }
    }

    if (!activeUrl.pathname.includes('/checkout') && billToSiteHasBeenFiltered) {
      setBillToSiteHasBeenFiltered(false);
    }
  }, [activeCart, customerBillToSites, activeUrl, billToSiteHasBeenFiltered, dispatch]);

  useEffect(() => {
    if (activeUrl.pathname.includes('orderConfirmation') && activeStep) {
      dispatch(setActiveStep(null));
    }
  }, [activeStep, activeUrl, dispatch]);

  return <div>{children}</div>;
};

export default CheckoutResolves;
