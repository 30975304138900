import React, {FC} from 'react';
import {CartPreviewContainer} from './CartPreview.styled';
import {useTypedSelector} from '../../../../hooks/store';
import {selectActiveCart} from '../../../../features/cart/slice/cart.slice';
import {formatOrderCurrency} from '../../../../Framework/Services/formatCurrency';
import {useCartTotalValues} from '../../../../features/cart/hooks/useCartTotalValues/useCartTotalValues';
import {
  cartItemsAlphabetized,
  cropImagesBasedOnProductType,
  unitAndLinePriceFormat,
} from '../../../../features/cart/service/cart.service';
import {Col} from 'react-bootstrap';
import CartItemQuantityInput from '../CartItemQuantityInput';
import CambriaButton from '../../../../Framework/Components/CambriaButton';
import {useRemoveCartItem} from '../../../../features/cart/hooks/useRemoveCartItem/useRemoveCartItem';
import {useBundleCartItems} from '../../../../features/cart/hooks/useBundleCartItems/useBundleCartItems';
import {useRemoveCartBundle} from '../../../../features/cart/hooks/useRemoveCartBundle/useRemoveCartBundle';
import Icon from '../../../../Framework/Components/Icon';

const CartPreview: FC = () => {
  const cart = useTypedSelector(selectActiveCart);
  const items = cartItemsAlphabetized(cart?.cartItems ?? []);
  const {totalLinePrice, subTotalPrice, isDiscountOrPriceAdjustment} = useCartTotalValues();
  const removeCartItem = useRemoveCartItem();
  const cartItemsByBundle = useBundleCartItems();
  const removeCartBundle = useRemoveCartBundle();

  return (
    <CartPreviewContainer>
      <div className="cart-preview-title">
        <strong>Subtotal</strong>
      </div>
      <div className="total-value">
        <strong>
          <span className="table-bold-value">${formatOrderCurrency(totalLinePrice)} </span>
          <span className="table-secondary-value">{items && items.length > 0 ? items[0].currency : 'USD'}</span>
        </strong>
      </div>
      {isDiscountOrPriceAdjustment && subTotalPrice && (
        <div>
          <span className="original-price-strike-through">
            ${formatOrderCurrency(subTotalPrice)}&nbsp;
            <span className="table-secondary-value">{items && items.length > 0 ? items[0].currency : 'USD'}</span>
          </span>
        </div>
      )}

      <Col className="items-list">
        {cartItemsByBundle?.map(({cartItems, bundleId}) => (
          <div className="bundle" key={`previewCartItemBundle${bundleId}`}>
            {bundleId && (
              <div className="bundle-title">
                <strong className="bundle-name">Bundle {bundleId}</strong>
                <Icon size="16" weight="600" icon="fa fa-trash-alt" onClick={() => removeCartBundle(bundleId)} />
              </div>
            )}
            <div className={`${bundleId ? 'bundle-content' : ''}`}>
              {cartItems.map((cartItem) => (
                <Col className="cartitem" key={`previewCartItem${cartItem.cartItemId}`}>
                  {cartItem.imageUrl && (
                    <div>
                      <img
                        className="item-image"
                        src={cartItem.imageUrl ? cropImagesBasedOnProductType(cartItem) : ''}
                        alt={cartItem.description}
                      />
                    </div>
                  )}
                  <div className="item-description">
                    <strong>{cartItem.description}</strong>
                  </div>
                  <div className="price">
                    Unit price:
                    <span className="price-value">
                      {cartItem.hasAdjustedUnitPrice && (
                        <span>
                          <s>
                            {unitAndLinePriceFormat(cartItem.unitPrice)}&nbsp;
                            {cartItem.currency}
                            {cartItem.pricingUom === 'SF' ? '/sq.ft.' : ''}
                          </s>
                        </span>
                      )}
                      <strong>
                        {unitAndLinePriceFormat(
                          cartItem.hasAdjustedUnitPrice ? cartItem.adjustedUnitPrice : cartItem.unitPrice
                        )}
                        &nbsp;
                        {cartItem.currency}
                        {cartItem.pricingUom === 'SF' ? '/sq.ft.' : ''}
                      </strong>
                    </span>
                  </div>
                  <div className="price">
                    Line price:
                    <span className="price-value">
                      {cartItem.hasAdjustedExtendedNetPrice && (
                        <span>
                          <s>
                            {unitAndLinePriceFormat(cartItem.extendedNetPrice)}&nbsp;
                            {cartItem.currency}
                          </s>
                        </span>
                      )}
                      <strong>
                        {unitAndLinePriceFormat(
                          cartItem.hasAdjustedExtendedNetPrice
                            ? cartItem.adjustedExtendedNetPrice
                            : cartItem.extendedNetPrice
                        )}
                        &nbsp;
                        {cartItem.currency}
                      </strong>
                    </span>
                  </div>
                  <>
                    <div className="actions">
                      <div className="qty-input">
                        <CartItemQuantityInput cartItem={cartItem} />
                      </div>
                      {!bundleId && (
                        <div>
                          <CambriaButton
                            onClick={() => {
                              removeCartItem(cartItem);
                            }}>
                            <em className="fa fa-trash-alt"></em>
                          </CambriaButton>
                        </div>
                      )}
                    </div>
                  </>
                </Col>
              ))}
            </div>
          </div>
        ))}
      </Col>
    </CartPreviewContainer>
  );
};

export default CartPreview;
