import React from 'react';
import {Link, useLocation} from 'react-router-dom';
import {setCheckoutInitialState} from '../../../../features/checkout/slice/checkout.slice';
import {useAppDispatch} from '../../../../hooks/store';
import CambriaLogo from '../CambriaLogo';
import COMMERCE_CORE_CONSTANTS from '../../../constants';

const HeaderCheckout = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();

  return (
    <>
      <Link
        to={
          location.pathname.includes(COMMERCE_CORE_CONSTANTS.PATHNAMES.orderConfirmation)
            ? COMMERCE_CORE_CONSTANTS.PATHNAMES.home
            : COMMERCE_CORE_CONSTANTS.PATHNAMES.cart
        }
        data-testid="linkToA#!/cart"
        onClick={() => dispatch(setCheckoutInitialState())}>
        <CambriaLogo bgColor="black" height="27" className="checkout" />
      </Link>
    </>
  );
};

export default HeaderCheckout;
