import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {toast} from 'react-toastify';
import {IQuoteInitialState, NewQuoteDetailsInitialState} from '../IQuoteState';
import {RootState} from '../../../store/store';
import {getExpiringQuotes, getQuoteRequest} from '../controller/quote.controller';

export const initialState: IQuoteInitialState = {
  quoteDetails: NewQuoteDetailsInitialState(),
  loading: false,
  quoteOperationInProgress: false,
  processingRequest: false,
  expiringQuotes: {
    entities: [],
    error: null,
    status: 'idle',
  },
};

export const loadQuoteDetailsAsync = createAsyncThunk(
  'quote/loadQuoteDetailsAsync',
  async ({quoteId, useHistory}: {quoteId: string; useHistory?: any}) => {
    const quoteDetails = getQuoteRequest({quoteId});
    if (quoteDetails === null && useHistory) {
      useHistory.push('/account/quotes');
      toast.error('This quote could not be found');
    }
    return quoteDetails;
  }
);

export const loadExpiringQuotesAsync = createAsyncThunk(
  'quote/loadExpiringQuotesAsync',
  async ({erpCustomerId}: {erpCustomerId: number}) => {
    return await getExpiringQuotes(erpCustomerId);
  }
);

export const quoteSlice = createSlice({
  name: 'quote',
  initialState,
  reducers: {
    setQuoteOperationInProgress: (state, action) => {
      state.quoteOperationInProgress = action.payload;
    },
    setProcessingRequest: (state, action) => {
      state.processingRequest = action.payload;
    },
    resetQuoteDetails: (state) => {
      state.quoteDetails = NewQuoteDetailsInitialState();
    },
    setQuoteInitialState: (state: any) => {
      for (const key in initialState) {
        state[key] = (initialState as any)[key];
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadQuoteDetailsAsync.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(loadQuoteDetailsAsync.fulfilled, (state, action) => {
      state.quoteDetails = action.payload ?? NewQuoteDetailsInitialState();
      state.loading = false;
    });
    builder.addCase(loadExpiringQuotesAsync.pending, (state) => {
      state.expiringQuotes.status = 'loading';
    });
    builder.addCase(loadExpiringQuotesAsync.fulfilled, (state, action) => {
      state.expiringQuotes.entities = action.payload;
      state.expiringQuotes.status = 'succeeded';
    });
    builder.addCase(loadExpiringQuotesAsync.rejected, (state) => {
      state.expiringQuotes.entities = [];
      state.expiringQuotes.status = 'failed';
    });
  },
});

export const {setQuoteOperationInProgress, setProcessingRequest, resetQuoteDetails, setQuoteInitialState} =
  quoteSlice.actions;

export const selectQuoteDetails = (state: RootState) => state.quote.quoteDetails;
export const selectQuoteDetailsAreLoading = (state: RootState) => state.quote.loading;
export const selectQuoteOperationInProgress = (state: RootState) => state.quote.quoteOperationInProgress;
export const selectProcessingRequest = (state: RootState) => state.quote.processingRequest;
export const selectExpiringQuotes = (state: RootState) => state.quote.expiringQuotes.entities;
export const selectExpiringQuotesStatus = (state: RootState) => state.quote.expiringQuotes.status;
export default quoteSlice.reducer;
