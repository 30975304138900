import styled from 'styled-components/macro';
import {$newprimary} from '../../../Styled/variables';
import {Nav} from 'react-bootstrap';

export const NavigationContainer = styled(Nav)`
  height: 36px;
`;

export const NavigationItem = styled(Nav['Item'])`
  padding-right: 0;
  flex: auto;
  text-align: center;
  height: 16px;
  border-right: 1px solid #ccc !important;
  cursor: pointer;

  &:first-child {
    flex: 1;
    text-align: left;
    @media (max-width: 1200px) {
      flex: auto !important;
    }
  }

  &:last-child {
    flex: 1;
    text-align: right;
    border-right: none !important;
    @media (max-width: 1200px) {
      flex: auto !important;
    }
  }

  &.item-center {
    text-align: center !important;
  }
`;

export const NavigationLink = styled(Nav['Link'])`
  font-size: 14px;
  font-weight: 900;
  display: inline-block !important;
  padding: 0 !important;
  color: black !important;
  height: 32px;
  line-height: 1;

  &:hover {
    border-color: transparent !important;
    color: ${$newprimary} !important;
  }

  &.active {
    border-color: transparent !important;
    color: ${$newprimary} !important;
    border-bottom: 3px solid ${$newprimary} !important;
    background-color: transparent !important;
  }
`;
