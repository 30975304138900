import styled from 'styled-components/macro';
import {$screensm} from '../../../Styled/variables';

export const LoginPageContainer = styled.div`
  flex: 1;

  .login-page-image-text-container {
    margin: 45px 0px;

    @media (max-width: ${$screensm}) {
      margin: 15px 0;
    }

    .login-page-image-text-section-1 {
      text-align: left;

      @media (max-width: ${$screensm}) {
        text-align: center;
      }

      > h2 {
        color: #ffffffd9;
        letter-spacing: 4px;

        @media (max-width: ${$screensm}) {
          font-size: 14px;
        }
      }
    }

    .login-page-image-text-section-2 {
      text-align: right;

      @media (max-width: ${$screensm}) {
        text-align: center;
      }

      > div {
        color: #ffffffd9;
        margin-top: 8px;
        white-space: nowrap;

        @media (max-width: ${$screensm}) {
          font-size: 10px;
          margin-top: 0;
        }
      }
    }
  }
`;
