import React from 'react';
import {appConfig} from '../../../../Framework/app-config';
import {Col, Row} from 'react-bootstrap';

const FooterBase = () => {
  const windowObj = window as any;
  const termsAndConditionsMenu = [
    {
      navigationItemLabel: 'Privacy Notice',
      textStyle: 'capitalize',
      navigationItemUrl: 'https://www.cambriausa.com/privacy/',
    },
    {
      navigationItemLabel: 'Terms and Conditions',
      textStyle: 'capitalize',
      navigationItemUrl: 'https://www.cambriausa.com/productsupplytc/',
    },
  ];

  let date = new Date();

  const buildTimeStamp = `${date.getMonth()}/${date.getDate()}/${date.getFullYear()}`;

  return (
    <Row className="m-t-md">
      <Col md={6} sm={12} className="build-version m-b-sm">
        <span className="copywrite">
          &copy; {new Date().getFullYear()} Cambria
          {appConfig.extraFooterCopyright ? <span>&mdash; {appConfig.extraFooterCopyright}</span> : ' '}
          {windowObj.SERVER_DATA && windowObj.SERVER_DATA.version ? windowObj.SERVER_DATA.version : 'local'} ({' '}
          {buildTimeStamp} )
        </span>
      </Col>
      <Col md={6} sm={12} className="terms-and-conditions">
        {termsAndConditionsMenu.map((link, index) => (
          <div className="link" key={index}>
            <span>
              <a href={link.navigationItemUrl} target="_blank" rel="noopener noreferrer">
                {link.navigationItemLabel}
              </a>
            </span>
          </div>
        ))}
      </Col>
    </Row>
  );
};

export default FooterBase;
