import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {WarehouseState} from '../IWarehouseState';
import {getShipFromWarehouseId} from '../service/warehouse.service';
import {RootState} from '../../../store/store';

export const initialState: WarehouseState = {
  availableWarehouses: [],
  selectedWarehouse: null,
  shipFromWarehouseId: null,
};

export const warehouseSlice = createSlice({
  name: 'warehouse',
  initialState,
  reducers: {
    setShipFromWarehouseId: (state: any, action: PayloadAction<any>) => {
      state.shipFromWarehouseId = getShipFromWarehouseId(
        action.payload.productType,
        action.payload.customer,
        action.payload.shipToSites,
        action.payload.cart
      );
    },
    setAvailableWarehouses: (state: WarehouseState, action: PayloadAction<any>) => {
      state.availableWarehouses = action.payload;
    },
    setSelectedWarehouse: (state: WarehouseState, action: PayloadAction<any>) => {
      state.selectedWarehouse = action.payload;
    },
    removeAvailableWarehouses: (state: WarehouseState) => {
      state.availableWarehouses = [];
    },
    removeSelectedWarehouse: (state: WarehouseState) => {
      state.selectedWarehouse = null;
    },
    setWarehouseInitialState: (state: any) => {
      for (const key in initialState) {
        state[key] = (initialState as any)[key];
      }
    },
  },
  extraReducers: {},
});

export const {
  setShipFromWarehouseId,
  setAvailableWarehouses,
  setSelectedWarehouse,
  removeAvailableWarehouses,
  removeSelectedWarehouse,
  setWarehouseInitialState,
} = warehouseSlice.actions;

export const selectShipFromWarehouseId = (state: RootState) => state.warehouse.shipFromWarehouseId;
export const selectAvailableWarehouses = (state: RootState) => state.warehouse.availableWarehouses;
export const selectSelectedWarehouse = (state: RootState) => state.warehouse.selectedWarehouse;

export default warehouseSlice.reducer;
