import React from 'react';

import {CambriaLogoContainer} from './CambriaLogo.styled';
import logoSrc from '../../../../Framework/img/the-cambria-exchange-logo.svg';

type CambriaLogoProps = {
  bgColor?: string;
  height?: string;
  className?: string;
};

const CambriaLogo = ({bgColor = 'transparent', height = '30', className = ''}: CambriaLogoProps) => {
  return (
    <CambriaLogoContainer bgColor={bgColor} height={height} className={className}>
      <img src={logoSrc} height={height} alt={'Cambria Logo'} />
    </CambriaLogoContainer>
  );
};

export default CambriaLogo;
