export const CORE_CONSTANTS: any = {
  API_SERVICES: {
    authToken: '/public/usermanagement/v1/api/oauth/token',
    anonymousToken: '/public/usermanagement/v1/api/oauth/anonymous/token',
    resetPassword: '/usermanagement/api/passwordresets',
  },
  COOKIES: {
    cambriaAnonymousRefresh: 'cambriaAnonymousRefresh',
    cambriaRefreshToken: 'cambriaRefreshToken',
    ecommerceToken: 'ecommerce_token',
  },
};
