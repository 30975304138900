import styled from 'styled-components';
import {$gray30, $gray60, $gray67, $gray93, $newprimary, $newwhite} from '../../../Styled/variables';

export const CambriaMultiInputContainer = styled.div`
  border-radius: 3px;
  .error-messaging {
    font-size: 11px;
    color: red;
  }
  .react-multi-email input {
    background: transparent;
    width: 100% !important;
    margin-left: 5px;
  }
  .react-multi-email {
    box-shadow: 0px 1px 1px rgb(0 0 0 / 20%);
    border: 1px solid #f1f1f4;
    line-height: 17px;
    padding: 0.15em 0.1em;
  }
  .react-multi-email {
    font-size: 14px;
    input {
      background-color: ${$newwhite};
      min-width: 300px;
    }
  }
  .react-multi-email.focused {
    border-color: ${$newprimary};
  }
  .email-list-container {
    padding: 5px;
  }
  .active-email {
    box-shadow: 0px 2px 5px 0px rgb(101 101 101 / 35%);
    padding: 5px;
    width: fit-content;
    display: flex;
    align-items: center;
    background: ${$gray93};
    margin: 5px 10px 5px 5px;
    .email-text {
      margin-right: 10px;
      font-weight: 600;
      color: ${$gray30};
    }
    .fa-times {
      color: ${$gray67};
      :hover {
        cursor: pointer;
      }
    }
  }
  .react-multi-email > span[data-placeholder] {
    top: 2.5px !important;
  }
  .multi-input-direction {
    font-size: 11px;
    color: ${$gray60};
    font-weight: 600;
    margin-left: 15px;
  }

  @media screen and (max-width: 960px) {
    .multi-input-direction {
      margin-left: 0px;
      margin-bottom: 5px;
    }
  }
`;
