import React, {FC, useEffect} from 'react';
import {useField, useFormikContext} from 'formik';
import {
  CambriaDateInputContainer,
  CambriaDateInputError,
  CambriaDateInputField,
  CambriaDateInputLabel,
} from './CambriaDateInput.styled';

interface CambriaDateInputProps {
  name: string;
  defaultValue?: any;
  label?: string;
  excludeDates?: any;
  filterDate?: any;
  minDate?: any;
  maxDate?: any;
  placeholder?: string;
  disabled?: boolean;
  required?: boolean;
  onChange?: any;
  isClearable?: boolean;
}

const CambriaDateInput: FC<CambriaDateInputProps> = ({
  name,
  defaultValue,
  label,
  excludeDates,
  filterDate,
  minDate,
  maxDate,
  placeholder,
  disabled,
  required,
  onChange,
  isClearable,
}) => {
  const [field, meta] = useField(name);
  const {setFieldValue} = useFormikContext();

  const daysDisabled = (date: Date) => {
    if (filterDate) {
      const day = date.getDay();
      return !filterDate.includes(day);
    } else {
      return true;
    }
  };

  useEffect(() => {
    if (typeof defaultValue !== 'undefined' && defaultValue !== null) {
      setFieldValue(name, defaultValue);
    }
  }, [defaultValue, name, setFieldValue]);

  return (
    <CambriaDateInputContainer>
      {label && <CambriaDateInputLabel htmlFor={name}>{label}</CambriaDateInputLabel>}
      {required && <strong> *</strong>}
      <CambriaDateInputField
        {...field}
        id={name}
        minDate={minDate}
        maxDate={maxDate}
        excludeDates={excludeDates}
        filterDate={daysDisabled}
        popperPlacement="bottom"
        placeholderText={placeholder}
        className={meta.error && meta.touched ? 'input-error' : ''}
        disabled={disabled}
        selected={(field.value && new Date(field.value)) || null}
        onKeyDown={(e) => {
          e.preventDefault();
        }}
        onChange={(val: any) => {
          setFieldValue(field.name, val);
          if (onChange) {
            onChange(val);
          }
        }}
        disabledKeyboardNavigation
        autoComplete="off"
        isClearable={isClearable}
        clearButtonClassName="cambria-datepicker-clear-btn"
      />
      {meta.error && meta.touched && <CambriaDateInputError>{meta.error}</CambriaDateInputError>}
    </CambriaDateInputContainer>
  );
};

export default CambriaDateInput;
