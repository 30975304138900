import styled from 'styled-components/macro';
import {$gray50, $newprimary, $newprimaryfont, $newwhite} from '../../../Styled/variables';

interface ButtonContainerProps {
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  variant?: 'primary' | 'secondary' | 'transparent';
  round?: boolean;
  shadow?: boolean;
  type?: string;
}

const handleFgColor = (variant: string) => {
  switch (variant) {
    case 'primary':
      return $newwhite;
    case 'secondary':
      return $newprimary;
    case 'transparent':
      return $gray50;
    default:
      return $newwhite;
  }
};

const handleBgColor = (variant: string) => {
  switch (variant) {
    case 'primary':
      return $newprimary;
    case 'secondary':
      return $newwhite;
    case 'transparent':
      return 'transparent';
    default:
      return $newprimary;
  }
};

const handleBgColorOnFocus = (variant: string) => {
  switch (variant) {
    case 'primary':
      return '#977312';
    case 'secondary':
      return '#e9e9e9';
    case 'transparent':
      return '#cdcdcd';
    default:
      return $newprimary;
  }
};

const handleBorder = (variant: string) => {
  switch (variant) {
    case 'primary':
      return `1px solid ${$newprimary}`;
    case 'secondary':
      return `1px solid ${$newwhite}`;
    case 'transparent':
      return `1px solid ${$gray50}`;
    default:
      return $newprimary;
  }
};

export const ButtonContainer = styled.button<ButtonContainerProps>`
  display: flex;
  align-items: center;
  width: fit-content;
  padding: 10px 20px;
  background: ${(props) => handleBgColor(props.variant ? props.variant : 'primary')};
  box-shadow: ${(props) => (props.shadow ? '0px 1px 2px rgba(0, 0, 0, 0.2)' : 'none')} !important;
  border-radius: ${(props) => (props.round ? '3px' : '0')} !important;
  font-family: ${$newprimaryfont} !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 20px;
  text-align: center;
  color: ${(props) => handleFgColor(props.variant ? props.variant : 'primary')};
  cursor: pointer;
  border: ${(props) => handleBorder(props.variant ? props.variant : 'primary')};

  @media (max-width: 991px) {
    width: 100%;
  }

  .icon {
    margin-right: 10px;
  }
  :focus {
    background: ${(props) => handleBgColorOnFocus(props.variant || 'primary')};
  }
`;
