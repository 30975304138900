import React, {FC} from 'react';
import Card from '../';
import Icon from '../../Icon/index';
import {ContactCardContainer} from './ContactCard.styled';
import {formatPhoneNumber} from '../../../../features/customer/service/customer.service';
import {EndConsumer} from '../../../../features/cart/ICartState';

interface ContactCardProps {
  contact: EndConsumer;
  onEditCallback?: Function;
  onDeleteCallback?: Function;
}

const ContactCard: FC<ContactCardProps> = ({contact, onEditCallback, onDeleteCallback}) => {
  return (
    <>
      <Card>
        <ContactCardContainer>
          <div className="align-items-center justify-content-between d-flex">
            <p className="contact-name">{`${contact.contactFirstName} ${contact.contactLastName}`}</p>
            <div className="contact-actions">
              <em
                data-testid="edit-consumer-btn"
                title="Edit Consumer"
                onClick={() => {
                  if (onEditCallback) {
                    onEditCallback(contact);
                  }
                }}
                className="fa fa-pencil-alt"></em>
              <span>&nbsp;|&nbsp;</span>
              <em
                data-testid="remove-consumer-btn"
                title="Remove Consumer"
                onClick={() => {
                  if (onDeleteCallback) {
                    onDeleteCallback(contact.id);
                  }
                }}
                className="fa fa-times"></em>
            </div>
          </div>
          <div className="align-items-center d-flex contact-info">
            <Icon icon="icons-cambria-Hr-Single" size="28" weight="900" color="black" colorOnHover="black" />
            <span className="contact-info-text">
              <p>
                {contact.address1} {contact.address2 ? `, ${contact.address2}` : ''}
              </p>
              <p>{contact.address3}</p>
              <p>
                {contact.city}, {contact.stateProvinceCode} {contact.postalCode}
              </p>
            </span>
          </div>
          <div className="align-items-center d-flex contact-info">
            <Icon icon="icons-cambria-Symbols-Mail" size="28" weight="900" color="black" colorOnHover="black" />
            <span className="contact-info-text">{contact.contactEmail}</span>
          </div>
          <div className="align-items-center d-flex contact-info">
            <Icon icon="icons-cambria-Ui-Phone" size="28" weight="900" color="black" colorOnHover="black" />
            <span className="contact-info-text">{formatPhoneNumber(contact.contactPhoneNumber)}</span>
          </div>
        </ContactCardContainer>
      </Card>
    </>
  );
};

export default ContactCard;
