import React, {FC, useEffect, useState} from 'react';
import {Modal} from 'react-bootstrap';
import CambriaButton from '../CambriaButton';
import Icon from '../Icon';
import {ModalContainer} from './CambriaModal.styled';

interface CambriaModalProps {
  heading?: string;
  children?: React.ReactNode;
  confirmButton?: string;
  cancelButton?: string;
  show?: boolean;
  toggleShow?: any;
  size?: 'sm' | 'lg' | 'xl';
  formName?: string;
  isValid?: boolean;
  disableCancelBtn?: boolean;
  disableSubmitBtn?: boolean;
  onCancel?: any;
  onConfirm?: any;
  hideCancelButton?: boolean;
  hideSubmitButton?: boolean;
  hideExitIcon?: boolean;
  onSubmitFunctionIsRunning?: boolean;
  disableClickOutsideToClose?: boolean;
  disableBackdrop?: boolean;
}

const CambriaModal: FC<CambriaModalProps> = ({
  heading,
  confirmButton = 'OK',
  cancelButton = 'Cancel',
  children,
  show,
  toggleShow,
  size = 'lg',
  formName,
  isValid,
  disableCancelBtn,
  disableSubmitBtn,
  onCancel,
  onConfirm,
  hideCancelButton = false,
  hideSubmitButton = false,
  hideExitIcon = false,
  onSubmitFunctionIsRunning,
  disableClickOutsideToClose = false,
  disableBackdrop = false,
}) => {
  const modalContainer = document.getElementById('modal-container');

  const [submitButtonIsDisabled, setSubmitButtonIsDisabled] = useState(false);
  const [cancelButtonIsDisabled, setCancelButtonIsDisabled] = useState(false);

  useEffect(() => {
    if (show) {
      document.body.classList.add('disable-scroll');
    } else {
      document.body.classList.remove('disable-scroll');
    }
  }, [show]);

  useEffect(() => {
    const determineIfSubmitIsDisabled = () => {
      if (isValid || disableSubmitBtn || onSubmitFunctionIsRunning) {
        setSubmitButtonIsDisabled(true);
      } else {
        setSubmitButtonIsDisabled(false);
      }
    };
    determineIfSubmitIsDisabled();
  }, [disableSubmitBtn, isValid, onSubmitFunctionIsRunning]);

  useEffect(() => {
    const determineIfCancelButtonIsDisabled = () => {
      if (disableSubmitBtn || onSubmitFunctionIsRunning) {
        setCancelButtonIsDisabled(true);
      } else {
        setCancelButtonIsDisabled(false);
      }
    };
    determineIfCancelButtonIsDisabled();
  }, [disableSubmitBtn, onSubmitFunctionIsRunning]);

  return (
    <ModalContainer
      show={show}
      onHide={() => {
        if (show) {
          document.body.classList.remove('disable-scroll');
        }
        if (toggleShow && !disableClickOutsideToClose) {
          toggleShow();
        }
      }}
      container={modalContainer}
      size={size}
      backdrop={!disableBackdrop}
      keyboard={!disableClickOutsideToClose}>
      <Modal.Header>
        <Modal.Title>{heading}</Modal.Title>
        {hideExitIcon ? (
          <></>
        ) : (
          <Icon
            color="#000"
            size="22"
            weight="600"
            icon="icons-cambria-Ui-Multiply"
            onClick={() => {
              if (show) {
                document.body.classList.remove('disable-scroll');
              }
              toggleShow();
              if (onCancel) {
                onCancel();
              }
            }}
            disabled={disableCancelBtn}
          />
        )}
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
      <Modal.Footer>
        {hideCancelButton ? (
          <></>
        ) : (
          <CambriaButton
            type="reset"
            form={formName}
            variant="transparent"
            id={`${formName}-modal-cancel-btn`}
            onClick={() => {
              if (show) {
                document.body.classList.remove('disable-scroll');
              }
              toggleShow();
              if (onCancel) {
                onCancel();
              }
            }}
            round={false}
            shadow={false}
            disabled={cancelButtonIsDisabled}>
            {cancelButton}
          </CambriaButton>
        )}

        {hideSubmitButton ? (
          <></>
        ) : (
          <CambriaButton
            type="submit"
            form={formName}
            variant="primary"
            id={`${formName}-modal-submit-btn`}
            onClick={() => {
              if (onConfirm) {
                onConfirm();
              }
              if (show) {
                document.body.classList.remove('disable-scroll');
              }
            }}
            round={false}
            shadow={false}
            disabled={submitButtonIsDisabled}>
            {onSubmitFunctionIsRunning && <Icon size="20" weight="600" icon="fa fa-spinner fa-spin" disabled />}
            {confirmButton}
          </CambriaButton>
        )}
      </Modal.Footer>
    </ModalContainer>
  );
};

export default CambriaModal;
