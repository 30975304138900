import React, {useCallback, useEffect, useRef, useState} from 'react';
import {AsyncTypeahead} from 'react-bootstrap-typeahead';
import {useAppDispatch, useTypedSelector} from '../../../../../../hooks/store';
import {selectProductSearch} from '../../../../../../features/search/slice/search.slice';
import boldSuggestionMatch from '../../../../../../Framework/Components/SearchBar';
import {selectCurrentCustomer} from '../../../../../../features/customer/slice/customer.slice';
import {selectActiveProgram} from '../../../../../../features/salesforce/slice/salesforce.slice';
import search_constants from '../../searchConstants';
import ProductTypeDropdown from '../ProductTypeDropdown';
import {useHistory, useLocation} from 'react-router-dom';
import {Product} from '../../../../../../features/productCatalog/IProductCatalogState';
import {
  setProductKeyword,
  setProductRecommendations,
} from '../../../../../../features/search/product/slice/product-search.slice';
import {searchForProducts} from '../../../../../../features/search/product/controller/product-search.controller';
import {IProductSearchState} from '../../../../../../features/search/product/IProductSearchState';
import {assignEndpoint, setUrlForNav} from '../../../../../../features/product/service/product.service';
import {pushSelectItemInformationToDataLayer} from '../../../../../../features/analytics/service/analytics.service';
import {pushSearchTermInformationToDataLayer} from '../../../../../../features/analytics/service/analytics.service';
import {pushViewItemListInformationToDataLayer} from '../../../../../../features/productCatalog/service/productCatalog.service';

const ProductSearchBar = () => {
  let {hash, search} = useLocation();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const typeahead = useRef<any>(null);

  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState<Product[]>([]);
  const [searchTerm, setSearchTerm] = useState('');

  let searchParams: IProductSearchState = useTypedSelector(selectProductSearch);
  let customerInfo: any = useTypedSelector(selectCurrentCustomer);
  let activeProgram: any = useTypedSelector(selectActiveProgram);

  const clearInput = useCallback(() => {
    typeahead.current.clear();
    dispatch(setProductKeyword(''));
    setOptions([]);
    setSearchTerm('');
  }, [typeahead, dispatch]);

  const executeProductSearch = async (keyword: string) => {
    setIsLoading(true);
    setOptions([]);

    let data = new URLSearchParams();
    pushSearchTermInformationToDataLayer(keyword);
    data.append('q', keyword);
    data.append('customerClass', customerInfo.class);
    data.append('productType', searchParams.activeFilter.param);
    data.append('programCodes', activeProgram.code);
    for (let group of customerInfo.classGroups) {
      data.append('customerClassGroups', group);
    }
    data.append('erpCustomerId', customerInfo.erpCustomerId);
    data.append('pager.expand', 'attachments');
    data.append('pager.limit', '21');
    data.append('pager.offset', '0');
    data.append('productActiveLocations', 'Commerce');
    data.append(
      'samplesWarehouseCode',
      customerInfo.productTypeWarehouseCodes.filter((x: any) => x.productType === 'Samples')[0].warehouseCode
    );
    data.append(
      'slabWarehouseCode',
      customerInfo.productTypeWarehouseCodes.filter((x: any) => x.productType === 'Slab')[0].warehouseCode
    );
    data.append(
      'storeWarehouseCode',
      customerInfo.productTypeWarehouseCodes.filter((x: any) => x.productType === 'Store')[0].warehouseCode
    );
    data.append(
      'tileWarehouseCode',
      customerInfo.productTypeWarehouseCodes.filter((x: any) => x.productType === 'Tile')[0].warehouseCode
    );

    const filter = searchParams.activeFilter.param;
    const endpoint = assignEndpoint(filter);

    const searchResults = await searchForProducts(data, endpoint);
    dispatch(setProductRecommendations(searchResults ? searchResults.results : []));
    setOptions(searchResults ? searchResults.results : []);
    if (searchResults?.results?.length) {
      pushViewItemListInformationToDataLayer(searchResults?.results);
    }
    setIsLoading(false);
  };

  const productDetailsNav = (product: Product) => {
    pushSelectItemInformationToDataLayer(product);
    let url = search_constants.product.productDetailsUrl + product.id;

    typeahead.current.blur();
    history.push(url);
  };

  const navigateToSearchResults = () => {
    let searchUrl = setUrlForNav(searchParams.keyword, searchParams.activeFilter.param);

    history.push(searchUrl);

    clearInput();
    setOptions([]);
  };

  const handleSearch = (searchInput: string) => {
    setSearchTerm(searchInput.trim());
    dispatch(setProductKeyword(searchInput.trim()));
    executeProductSearch(searchInput.trim());
  };

  const handleEnterKeypress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      typeahead.current.blur();
      navigateToSearchResults();
      typeahead.current.hideMenu();
    }
  };

  const filterBy = () => true;

  useEffect(() => {
    if (hash && !search) {
      clearInput();
    }
  }, [hash, search, clearInput]);

  return (
    <div className="search-items-container search-form">
      <ProductTypeDropdown clearInput={clearInput} />
      <div className="autocomplete">
        <AsyncTypeahead
          onKeyDown={(e) => handleEnterKeypress(e)}
          filterBy={filterBy}
          id="autocomplete"
          labelKey="displayName"
          className="search-bar"
          isLoading={isLoading}
          onSearch={handleSearch}
          onChange={(selected: any) => selected[0] && productDetailsNav(selected[0])}
          ref={typeahead}
          options={options}
          useCache={false}
          minLength={3}
          delay={500}
          placeholder="Search by design, product type, etc..."
          renderMenuItemChildren={(option: any) => (
            <>
              <div className="autosuggest-option">
                {boldSuggestionMatch(option.displayName, searchTerm)}
                <span className="suggestion-in"> in </span>
                <span className="product-type">{option.productType}</span>
              </div>
            </>
          )}
        />
      </div>
      <div className="search-button-container">
        <button onClick={() => navigateToSearchResults()} className="search-button btn-primary">
          SEARCH
        </button>
      </div>
    </div>
  );
};

export default ProductSearchBar;
