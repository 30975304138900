import React, {useEffect, useState} from 'react';
import {toast} from 'react-toastify';
import {selectActiveCart} from '../../../features/cart/slice/cart.slice';
import {selectUiSettings} from '../../../features/environment/slice/environment.slice';
import {selectUploadedFiles} from '../../../features/file/slice/file.slice';
import CambriaButton from '../../../Framework/Components/CambriaButton';
import {useAppDispatch, useTypedSelector} from '../../../hooks/store';
import FabOrderDetails from './OrderDetails';
import FabOrderPieces from './OrderPieces';
import {FabricationOrderEntryContainer} from './FabricationOrderEntry.styled';
import FabOrderUploadFiles from './UploadFiles';
import {selectEndConsumers, selectPurchasingCustomer} from '../../../features/fabrication/slice/fabrication.slice';
import {useHistory} from 'react-router-dom';
import COMMERCE_CORE_CONSTANTS from '../../../Core/constants';
import {Col, Row} from 'react-bootstrap';
import {selectUserActions} from '../../../features/permission/slice/permission.slice';
import {hasPermission} from '../../../store/permission/permission.service';
import AddQuoteModal from '../../../Core/Components/Modals/AddQuoteModal';
import Icon from '../../../Framework/Components/Icon';
import {selectQuoteOperationInProgress, setQuoteOperationInProgress} from '../../../features/quote/slice/quote.slice';
import {
  hideFullscreenLoader,
  showFullscreenLoader,
} from '../../../features/fullscreenLoader/slice/fullscreenLoader.slice';
import {removeCart} from '../../../features/cart/service/cart.service';
import {
  selectCurrentCustomer,
  selectCurrentCustomerBillToSites,
  selectCurrentCustomerShipToSites,
} from '../../../features/customer/slice/customer.slice';
import {selectCurrentUser} from '../../../features/auth/slice/authentication.slice';
import {selectActiveProgram, selectAvailablePrograms} from '../../../features/salesforce/slice/salesforce.slice';
import {IFullPieces} from '../../../features/fabrication/IFabricationState';
import {pushRemoveFabPieceFromCartEventToDataLayer} from '../../../features/analytics/service/analytics.service';
import {selectSelectedWarehouse} from '../../../features/warehouse/slice/warehouse.slice';
import {saveQuote} from '../../../features/quote/service/quote.service';

const FabricationOrderEntry = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const activeCart = useTypedSelector(selectActiveCart);
  const uiSettings = useTypedSelector(selectUiSettings);
  const fabricationUploadedFiles = useTypedSelector(selectUploadedFiles);
  const purchasingCustomers = useTypedSelector(selectPurchasingCustomer);
  const endConsumers = useTypedSelector(selectEndConsumers);
  const userActions = useTypedSelector(selectUserActions);
  const quoteOperationInProgress = useTypedSelector(selectQuoteOperationInProgress);
  const customer = useTypedSelector(selectCurrentCustomer);
  const currentUser = useTypedSelector(selectCurrentUser);
  const activeProgram = useTypedSelector(selectActiveProgram);
  const shipToSites: any = useTypedSelector(selectCurrentCustomerShipToSites);
  const billToSites: any = useTypedSelector(selectCurrentCustomerBillToSites);
  const availablePrograms = useTypedSelector(selectAvailablePrograms);
  const selectedWarehouse = useTypedSelector(selectSelectedWarehouse);

  const [dwgFilesCount, setDwgFilesCount] = useState(0);
  const [proceedToCartBtnDisabled, setProceedToCartBtnDisabled] = useState(true);
  const [showAddQuoteModal, setShowAddQuoteModal] = useState(false);
  const [quoteType, setQuoteType] = useState('');

  const toggleShowAddQuoteModal = () => setShowAddQuoteModal((p) => !p);

  const showCreateCIAQuoteButton = hasPermission('urn:csa:commerceui:requestStandardCiaQuote', userActions);

  const clearShoppingCart = async () => {
    if (quoteOperationInProgress) {
      return false;
    }

    try {
      dispatch(setQuoteOperationInProgress(true));
      await removeCartButton();
    } catch (e) {
      console.log(e);
    } finally {
      dispatch(setQuoteOperationInProgress(false));
    }
  };

  const removeCartButton = async (): Promise<void> => {
    try {
      dispatch(showFullscreenLoader({showCartIcon: true}));
      await removeCart(
        activeCart?.id || '',
        undefined,
        undefined,
        customer,
        currentUser?.userId || '',
        activeProgram,
        shipToSites,
        billToSites,
        dispatch,
        availablePrograms
      );

      if (activeCart?.cartItems && activeCart?.cartItems?.length > 0) {
        pushRemoveFabPieceFromCartEventToDataLayer(
          (activeCart?.cartItems ?? []).map((ci) => ({
            pieceModel: {
              mainPiece: ci,
            } as IFullPieces,
            quantity: ci.uomLineQuantity ?? 1,
          }))
        );
      }

      toast.success('All items are removed from the cart.');
    } catch (error: any) {
      toast.error(error);
    } finally {
      dispatch(hideFullscreenLoader());
    }
  };

  const saveQuoteButton = async () => {
    saveQuote(
      activeCart as any,
      customer,
      billToSites,
      shipToSites,
      currentUser as any,
      availablePrograms,
      activeProgram,
      selectedWarehouse,
      dispatch,
      history,
      true
    );
  };

  useEffect(() => {
    const throwCartItemTypeError = () => {
      if (activeCart && activeCart.cartItems.length > 0) {
        const hasItemsThatAreNotFab = activeCart.cartItems.find(
          (item) => item.cartItemType !== 'FabAndInstallStandard' && item.cartItemType !== 'FabAndInstallDiscrete'
        );
        if (hasItemsThatAreNotFab) {
          toast.warning(
            'Your shopping cart currently contains products that cannot ' +
              'be combined with a Fabrication order. Either complete your current checkout,' +
              ' or clear your shopping cart to access Fabrication ordering.'
          );
        }
      }
    };
    throwCartItemTypeError();
  }, [activeCart]);

  useEffect(() => {
    const determineIfProceedToCartIsDisabled = (): boolean => {
      const purchasingCustomerNotCompleted =
        !purchasingCustomers || purchasingCustomers?.length === 0 || !endConsumers || endConsumers?.length === 0;
      const cartHasNoItems = !activeCart || !activeCart.cartItems || activeCart.cartItems.length === 0;
      const needsFilesUploaded = uiSettings?.requireFabricationFiles && dwgFilesCount === 0;
      return (
        !!cartHasNoItems ||
        activeCart.orderDetails.length === 0 ||
        !!needsFilesUploaded ||
        purchasingCustomerNotCompleted
      );
    };
    setProceedToCartBtnDisabled(determineIfProceedToCartIsDisabled());
  }, [activeCart, uiSettings, dwgFilesCount, endConsumers, purchasingCustomers]);

  useEffect(() => {
    const countDwgFiles = () => {
      let tempCount = 0;
      fabricationUploadedFiles.forEach((file) => {
        if (file.fileExtension.toLowerCase() === 'dwg') {
          tempCount++;
        }
      });
      setDwgFilesCount(tempCount);
    };
    if (fabricationUploadedFiles.length > 0) {
      countDwgFiles();
    } else {
      setDwgFilesCount(0);
    }
  }, [fabricationUploadedFiles]);

  return (
    <div className="react-bootstrap-hack cambria-main-content">
      <FabricationOrderEntryContainer>
        <Row>
          {activeCart?.transactionType === 'QuoteCart' ? (
            <Col sm={12} lg={12}>
              <div className="fab-header-text-container">
                <div className="fab-header-text">
                  Your cart is locked into quote. Please edit the items through the quote editing process.
                </div>
                <div>To order different items, click the 'Clear Shopping Cart' button on the cart page.</div>
              </div>
            </Col>
          ) : (
            <div></div>
          )}
          {activeCart?.transactionType === 'QuoteEditCart' ? (
            <Col sm={12} lg={12}>
              <div className="fab-header-text-container">
                <div className="fab-header-text">
                  You are in the process of editing the quote {activeCart?.quoteName}
                </div>
                <div>If you do not wish to edit this quote, proceed to the cart and select “Cancel Quote Edit”</div>
                <div>Edit this quote by changing the items in this cart</div>
              </div>
            </Col>
          ) : (
            <div></div>
          )}
        </Row>
        <FabOrderDetails />
        <FabOrderPieces />
        <FabOrderUploadFiles />
        <div className="proceed-to-cart-container d-flex align-items-center justify-content-end">
          {showCreateCIAQuoteButton && activeCart?.transactionType === 'QuoteEditCart' ? (
            <Col xs={12} sm={12} lg={4} className="text-center">
              <CambriaButton
                disabled={quoteOperationInProgress}
                id="clear-shopping-cart-button"
                className="btn btn-block btn-default ripple cancel-quote-edit-button"
                onClick={() => {
                  clearShoppingCart();
                }}>
                <Icon icon="icon icons-cambria-Ui-Multiply" color="#eee" size="15" weight="bold" />
                CANCEL QUOTE EDIT
              </CambriaButton>
            </Col>
          ) : (
            <></>
          )}
          {showCreateCIAQuoteButton &&
          activeCart?.transactionType !== 'QuoteCart' &&
          activeCart?.transactionType !== 'QuoteEditCart' &&
          activeCart?.orderDetails &&
          activeCart?.orderDetails.length > 0 &&
          activeCart?.orderDetails[0].jobType?.includes('Commercial') ? (
            <Col xs={12} sm={12} lg={4} className="text-center">
              <button
                type="button"
                disabled={
                  activeCart && activeCart?.orderDetails.length > 0 && activeCart.cartItems.length > 0 ? false : true
                }
                className="btn btn-link sub-text p-x-0 btn-quotes"
                id={'request-commercial-quote-button'}
                onClick={() => {
                  setQuoteType('cia');
                  toggleShowAddQuoteModal();
                }}>
                REQUEST QUOTE
              </button>
            </Col>
          ) : (
            <Col xs={12} sm={12} lg={4}></Col>
          )}
          <Col xs={12} sm={12} lg={4} className="text-center">
            {activeCart?.transactionType === 'QuoteEditCart' ? (
              <CambriaButton
                disabled={quoteOperationInProgress}
                className="fab-proceed-to-cart-button"
                type="button"
                onClick={() => {
                  saveQuoteButton();
                }}
                id="commerce-proceed-to-checkout-bottom">
                <Icon icon="icon icons-cambria-Ui-Store" color="#eee" size="15" weight="bold" />
                SAVE QUOTE
              </CambriaButton>
            ) : (
              <CambriaButton
                disabled={proceedToCartBtnDisabled}
                className="fab-proceed-to-cart-button"
                onClick={() => {
                  history.push(COMMERCE_CORE_CONSTANTS.PATHNAMES.cart);
                }}>
                PROCEED TO CART SUMMARY
              </CambriaButton>
            )}
          </Col>
        </div>
      </FabricationOrderEntryContainer>
      <AddQuoteModal show={showAddQuoteModal} toggleShow={toggleShowAddQuoteModal} type={quoteType}></AddQuoteModal>
    </div>
  );
};

export default FabricationOrderEntry;
