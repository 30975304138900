import React, {useEffect, useState} from 'react';
import {
  getOpenFabOrdersReportEmailDataAsync,
  selectOpenFabOrdersReport,
  selectOpenFabOrdersReportEmailData,
} from '../../../../../features/reports/slice/reports.slice';
import {useAppDispatch, useTypedSelector} from '../../../../../hooks/store';
import {OpenFabReportContainer} from './OpenFabReport.styled';
import {DisplayField} from '../../../../../features/customer/ICustomerState';
import TableHeader from '../ReportTables/TableHeader';
import TableItems from '../ReportTables/TableItems';
import {
  downloadOpenFabOrdersReportData,
  subscribeToReport,
  unsubscribeFromReport,
} from '../../../../../features/reports/controllers/reports.controller';
import {selectCurrentCustomer} from '../../../../../features/customer/slice/customer.slice';
import RequestAccessPage from '../Placeholders/RequestAccessPage';
import COMMERCE_CORE_CONSTANTS from '../../../../../Core/constants';
import {toast} from 'react-toastify';
import {selectCurrentUser} from '../../../../../features/auth/slice/authentication.slice';

const defaultTableColumns: DisplayField[] = [
  {
    code: 'jobNumber',
    name: 'Job Number',
  },
  {
    code: 'customerPO',
    name: 'Customer PO',
  },
  {
    code: 'customerName',
    name: 'Customer Name',
  },
  {
    code: 'purchasingCustomer',
    name: 'Purchasing Customer',
  },
  {
    code: 'designThicknessFinish',
    name: 'Design/Thickness/Finish',
  },
  {
    code: 'jobProfileCodes',
    name: 'Profile(s)',
  },
  {
    code: 'jobUomQuantity',
    name: 'SQ FT',
  },
  {
    code: 'fabricatedPieceQuantity',
    name: 'Tops',
  },
  {
    code: 'backSplashPieceQuantity',
    name: 'BS',
  },
  {
    code: 'status',
    name: 'Job Status',
  },
  {
    code: 'availablePickupDate',
    name: 'Available for Pickup Date',
  },
];

const OpenFabsReport = ({hasPermission}: {hasPermission: boolean}) => {
  const dispatch = useAppDispatch();

  const {entity: reportData, status} = useTypedSelector(selectOpenFabOrdersReport);
  const erpCustomerId = useTypedSelector(selectCurrentCustomer)?.erpCustomerId;
  const [subscriptionLoading, setSubscriptionLoading] = useState<boolean>(false);
  const activeUser = useTypedSelector(selectCurrentUser);
  const emailSubscriptionData = useTypedSelector(selectOpenFabOrdersReportEmailData);

  const isLoading = status === 'loading';

  const handleEmailSubscription = async () => {
    setSubscriptionLoading(true);
    if (emailSubscriptionData?.length === 0) {
      try {
        const userNames = activeUser ? activeUser?.given_name.split(' ') : [];
        await subscribeToReport({
          email: activeUser?.email ?? '',
          firstName: userNames[0],
          lastName: userNames[1],
          userId: activeUser?.userId ?? '',
          type: COMMERCE_CORE_CONSTANTS.REPORTS.openFabOrders,
          erpCustomerId,
        });
        toast.success('Your user email has been successfully subscribed to the daily Open Fab Orders Report');
      } catch (e) {
        console.log(e);
      }
    } else if (emailSubscriptionData && emailSubscriptionData.length > 0) {
      try {
        await unsubscribeFromReport({
          erpCustomerId,
          email: emailSubscriptionData[0].email,
          subscriptionId: emailSubscriptionData[0].id,
        });
        toast.success('Your user email has been successfully unsubscribed from the daily Open Fab Orders Report');
      } catch (e) {
        console.log(e);
      }
    }
    if (erpCustomerId && activeUser?.userId) {
      dispatch(getOpenFabOrdersReportEmailDataAsync({erpCustomerId: erpCustomerId, userId: activeUser.userId}));
    }
  };

  useEffect(() => {
    setSubscriptionLoading(false);
  }, [emailSubscriptionData]);

  useEffect(() => {
    if (erpCustomerId && !emailSubscriptionData && activeUser?.userId) {
      dispatch(getOpenFabOrdersReportEmailDataAsync({erpCustomerId: erpCustomerId, userId: activeUser.userId}));
    }
  }, [erpCustomerId, emailSubscriptionData, activeUser, dispatch]);

  const exportToPdf = async () => {
    const file = await downloadOpenFabOrdersReportData(erpCustomerId);
    const url = window.URL.createObjectURL(file);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `Open Fab Orders Report - ${new Date().toISOString()}.pdf`);
    document.body.appendChild(link);
    link.click();
  };

  if (!hasPermission) {
    return (
      <RequestAccessPage
        endpoint={`${COMMERCE_CORE_CONSTANTS.API_SERVICES.CUSTOMER.customers}/${erpCustomerId}${COMMERCE_CORE_CONSTANTS.API_SERVICES.CUSTOMER.openFabOrdersReport}`}
      />
    );
  }

  return (
    <OpenFabReportContainer>
      {isLoading && <h1>Loading...</h1>}
      {!reportData?.jobs && !isLoading ? (
        <div className="no-results-page">
          There is no current activity to display at this time.
          {emailSubscriptionData && (
            <button
              className={`subscription-btn ${
                emailSubscriptionData && emailSubscriptionData.length > 0 ? 'unsubscribe' : 'subscribe'
              }`}
              data-testid="subscribe-to-emails"
              onClick={() => handleEmailSubscription()}
              disabled={subscriptionLoading}>
              <>
                <i className="fa fa-envelope" />
                &nbsp; {emailSubscriptionData?.length > 0 ? 'Unsubscribe' : 'Subscribe'}
              </>
            </button>
          )}
        </div>
      ) : (
        <>
          <div className="load-report-action-buttons">
            {emailSubscriptionData && (
              <button
                className={`subscription-btn ${
                  emailSubscriptionData && emailSubscriptionData.length > 0 ? 'unsubscribe' : 'subscribe'
                }`}
                data-testid="subscribe-to-emails"
                onClick={() => handleEmailSubscription()}
                disabled={subscriptionLoading}>
                <>
                  <i className="fa fa-envelope" />
                  &nbsp; {emailSubscriptionData?.length > 0 ? 'Unsubscribe' : 'Subscribe'}
                </>
              </button>
            )}
            <button className="print-icon-button" onClick={exportToPdf}>
              <i className="fa fa-print" />
            </button>
          </div>
          <table className="table-condensed">
            <TableHeader tableColumns={reportData?.displayFields ?? defaultTableColumns} />
            <TableItems
              reportLines={reportData?.jobs ?? []}
              tableColumns={reportData?.displayFields ?? defaultTableColumns}
              isLoading={isLoading}
            />
          </table>
        </>
      )}
    </OpenFabReportContainer>
  );
};

export default OpenFabsReport;
