import {FabricationFile} from '../file/IFileState';
import {ICambriaOrderMetaData, IOrderDetails} from './orderDetails/IOrderDetailsState';

export interface IOrderSliceState {
  orderDetails: IOrderDetails;
  userInfo: IUserInfo;
  reworkReasons: IReworkReasons[];
  orderLastCartDetails: ILastCartDetails;
  fabricationFiles: FabricationFile[];
  refreshInProgress: boolean;
  isInEditMode: boolean;
}

export interface ILastCartDetails {
  purchaseOrderNumber: string;
  jobType: string;
  projectType: string;
  projectName: string;
  projectCity: string;
  orderPurpose: string;
  orderEventName: string;
  relatedAgency: string;
  orderCrmAccountId: string;
  orderCrmAccountName: string;
  homeDepotOrderNumber: string;
  publicFacingDisplay: boolean;
  surfacesWarehouseCodeOverride: string;
  orderPieceRework: boolean;
  fabReworkReason: string;
  fabAssignableCause: string;
  salesforceQuoteId: string;
  orderIsRevisionOfPreviousJob: boolean;
  fabricationOrderDrafter: string;
  fabricationOrderOrderApprovedEmailList: string;
  fabricationOrderCadRevisionsNeededEmailList: string;
  fabricationOrderPoRevisionsNeededEmailList: string;
  orderCrmAccountTradeType: string;
  accountLink: string;
}

export const LastCartDetailInitial: ILastCartDetails = {
  purchaseOrderNumber: '',
  jobType: '',
  projectType: '',
  projectName: '',
  projectCity: '',
  orderPurpose: '',
  orderEventName: '',
  relatedAgency: '',
  orderCrmAccountId: '',
  orderCrmAccountName: '',
  homeDepotOrderNumber: '',
  publicFacingDisplay: false,
  surfacesWarehouseCodeOverride: '',
  orderPieceRework: false,
  fabReworkReason: '',
  fabAssignableCause: '',
  salesforceQuoteId: '',
  orderIsRevisionOfPreviousJob: false,
  fabricationOrderDrafter: '',
  fabricationOrderOrderApprovedEmailList: '',
  fabricationOrderCadRevisionsNeededEmailList: '',
  fabricationOrderPoRevisionsNeededEmailList: '',
  orderCrmAccountTradeType: '',
  accountLink: '',
};

export interface IReworkReasons {
  reasonName: string;
  assignableCauses: string[];
}

export interface IUserInfo {
  id: number;
  tenantId: number;
  alternateIdentifier: string;
  displayName: string;
  email: string;
  isSysAdmin: boolean;
  active: boolean;
  userProfileId: number;
  mainOrganizationId: number;
  mainOrganizationName: string;
  organizationGroupIds: number[];
  userGroupIds: number[];
  tags: string;
  version: number;
  dateCreated: Date | null;
  createdBy: string;
  dateUpdated: Date | null;
  updatedBy: string;
}

export const userInfoInitialState = (): IUserInfo => {
  return {
    id: 0,
    tenantId: 0,
    alternateIdentifier: '',
    displayName: '',
    email: '',
    isSysAdmin: false,
    active: false,
    userProfileId: 0,
    mainOrganizationId: 0,
    mainOrganizationName: '',
    organizationGroupIds: [],
    userGroupIds: [],
    tags: '',
    version: 0,
    dateCreated: null,
    createdBy: '',
    dateUpdated: null,
    updatedBy: '',
  };
};

export const orderDetailsInitialState: IOrderDetails = {
  name: '',
  orderNumber: '',
  homeDepotOrderNumber: '',
  lastOrderTransactionId: '',
  orderType: '',
  orderTypeDisplayName: '',
  originalCartId: '',
  lastCartId: '',
  lastRecType: '',
  userId: '',
  erpCustomerId: '',
  orderCrmAccountName: '',
  customerClass: '',
  currencyCode: '',
  statusCode: '',
  statusDisplayName: '',
  statusDescription: '',
  statusSortOrder: 0,
  taxableStatus: '',
  taxExemptCertificateCode: '',
  paymentType: '',
  paymentTerms: '',
  paymentAmount: 0,
  totalAmount: 0,
  estimatedPaymentFeeAmount: 0,
  actualPaymentFeeAmount: 0,
  totalMaterialFee: 0,
  paymentTransactionId: '',
  creditCardMasked: '',
  creditCardType: '',
  purchaseOrderNumber: '',
  deliveryRate: 0,
  deliveryRateType: '',
  marketRep: '',
  billToSite: '',
  billToName: '',
  billToAddress1: '',
  billToAddress2: '',
  billToAddress3: '',
  billToStateProvinceCode: '',
  billToCity: '',
  billToCountryCode: '',
  billToPostalCode: 0,
  billToPhone: '',
  billToEmail: '',
  operatingUnitCode: '',
  sourceSystem: '',
  customerContactId: '',
  billToContactId: '',
  totalTaxAmount: 0,
  totalShippingAmount: 0,
  subtotal: 0,
  totalNetAmount: 0,
  totalDiscounts: 0,
  isReturnOrder: false,
  jobType: '',
  projectType: '',
  projectName: '',
  projectCity: '',
  orderPurpose: '',
  orderEventName: '',
  relatedAgency: '',
  billToContactFirstName: '',
  billToContactLastName: '',
  shipToContactLastName: '',
  shipToContactFirstName: '',
  shippingInstructions: '',
  shipToContactPhone: '',
  shipToContactEmail: '',
  shipToSiteName: '',
  createdAt: new Date(Date.UTC(0, 0, 0, 0, 0, 0)),
  updatedAt: new Date(Date.UTC(0, 0, 0, 0, 0, 0)),
  pointOfSales: [],
  orderLineItems: [],
  totalItems: 0,
  orderCreatedById: '',
  warehouse: '',
  orderIsRevisionOfPreviousJob: false,
  publicFacingDisplay: false,
  surfacesWarehouseCodeOverride: '',
  orderPieceRework: false,
  orderMetadata: null,
  headerAdjustments: [],
  totalHeaderAdjustmentAmount: 0,
  fabOrderRevision: 0,
  fabOrderRevisionDisplayValue: '',
  fabricationJobNumber: '',
  fabricationInstructions: '',
  voucherCodes: [],
  crmQuoteNumber: '',
  rejections: [],
  favorites: [],
  fabricationOrderDrafter: '',
  originalSalesOrderNumber: 0,
  files: [],
  trackingNumbers: [],
};

export const getOrderDetailsInitialState = (): IOrderDetails => {
  return {
    name: '',
    orderNumber: '',
    homeDepotOrderNumber: '',
    lastOrderTransactionId: '',
    orderType: '',
    orderTypeDisplayName: '',
    originalCartId: '',
    lastCartId: '',
    lastRecType: '',
    userId: '',
    erpCustomerId: '',
    orderCrmAccountName: '',
    customerClass: '',
    currencyCode: '',
    statusCode: '',
    statusDisplayName: '',
    statusDescription: '',
    statusSortOrder: 0,
    taxableStatus: '',
    taxExemptCertificateCode: '',
    paymentType: '',
    paymentTerms: '',
    paymentAmount: 0,
    totalAmount: 0,
    estimatedPaymentFeeAmount: 0,
    actualPaymentFeeAmount: 0,
    totalMaterialFee: 0,
    paymentTransactionId: '',
    creditCardMasked: '',
    creditCardType: '',
    purchaseOrderNumber: '',
    deliveryRate: 0,
    deliveryRateType: '',
    marketRep: '',
    billToSite: '',
    billToName: '',
    billToAddress1: '',
    billToAddress2: '',
    billToAddress3: '',
    billToStateProvinceCode: '',
    billToCity: '',
    billToCountryCode: '',
    billToPostalCode: 0,
    billToPhone: '',
    billToEmail: '',
    operatingUnitCode: '',
    sourceSystem: '',
    customerContactId: '',
    billToContactId: '',
    totalTaxAmount: 0,
    totalShippingAmount: 0,
    subtotal: 0,
    totalNetAmount: 0,
    totalDiscounts: 0,
    isReturnOrder: false,
    jobType: '',
    projectType: '',
    projectName: '',
    projectCity: '',
    orderPurpose: '',
    orderEventName: '',
    relatedAgency: '',
    billToContactFirstName: '',
    billToContactLastName: '',
    shipToContactLastName: '',
    shipToContactFirstName: '',
    shippingInstructions: '',
    shipToContactPhone: '',
    shipToContactEmail: '',
    shipToSiteName: '',
    createdAt: new Date(Date.UTC(0, 0, 0, 0, 0, 0)),
    updatedAt: new Date(Date.UTC(0, 0, 0, 0, 0, 0)),
    pointOfSales: [],
    orderLineItems: [],
    totalItems: 0,
    orderCreatedById: '',
    warehouse: '',
    orderIsRevisionOfPreviousJob: false,
    publicFacingDisplay: false,
    surfacesWarehouseCodeOverride: '',
    orderPieceRework: false,
    orderMetadata: null,
    headerAdjustments: [],
    totalHeaderAdjustmentAmount: 0,
    fabOrderRevision: 0,
    fabOrderRevisionDisplayValue: '',
    fabricationJobNumber: '',
    fabricationInstructions: '',
    voucherCodes: [],
    crmQuoteNumber: '',
    rejections: [],
    favorites: [],
    fabricationOrderDrafter: '',
    originalSalesOrderNumber: 0,
    files: [],
    trackingNumbers: [],
  };
};

export const removableStatusCodes: Array<string> = [
  'Pending',
  'Verified',
  'Submitting',
  'SubmitFailed',
  'RejectedPoCccRevisingOrder',
  'RejectedCAD',
  'RejectedPo',
];

export const resubmittableStatusCodes: Array<string> = [
  'Pending',
  'Verified',
  'Submitting',
  'Submitted',
  'SubmitFailed',
  'SubmitSuccess',
  'RejectedPoCccRevisingOrder',
  'RejectedCAD',
  'RejectedPo',
];

export interface IPaymentInfoCardData {
  paymentType: string;
  paymentTerms: string;
  totalAmount: number;
  currencyCode: string;
  voucherCodes?: string[];
  creditCartType?: string;
  creditCardMasked?: string;
}

export const initialPaymentInfoCardData: IPaymentInfoCardData = {
  paymentType: '',
  paymentTerms: '',
  totalAmount: 0,
  currencyCode: '',
};

export interface IPlaceOrderRequest {
  cartId: string;
  userId: string;
  erpCustomerId: string;
  orderMetadata: ICambriaOrderMetaData;
}
