import React from 'react';
import {Col, Row} from 'react-bootstrap';
import {AcademyQuote} from '../../../../features/environment/IEnvironmentState';
import {InTheirOwnWordsContainer} from './InTheirOwnWords.styled';

const InTheirOwnWords = ({academyQuotes}: {academyQuotes: AcademyQuote[]}) => {
  return (
    <InTheirOwnWordsContainer>
      <Row>
        <Col className="d-flex justify-content-center">
          <h2>IN THEIR OWN WORDS</h2>
        </Col>
      </Row>
      {academyQuotes && academyQuotes.length > 0 && (
        <>
          {academyQuotes.map((quote: AcademyQuote, index: number) => {
            return (
              <Row key={index} className="academy-quote-row">
                <Col>
                  <Row className="quote-row">
                    <Col>
                      <p className="quote">"{quote?.quote}"</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <p>
                        {quote.name}
                        {quote?.company && `, ${quote.company}`}
                        {quote?.location && `, ${quote.location}`}
                      </p>
                    </Col>
                  </Row>
                </Col>
              </Row>
            );
          })}
        </>
      )}
    </InTheirOwnWordsContainer>
  );
};

export default InTheirOwnWords;
