import React, {FC, useEffect, useRef, useState} from 'react';
import {useField, useFormikContext} from 'formik';
import {
  CambriaAutosuggestContainer,
  CambriaAutosuggestError,
  CambriaAutosuggestField,
  CambriaLabel,
} from './CambriaAutosuggest.styled';
import boldSuggestionMatch from '../SearchBar';

export interface CambriaAutosuggestProps {
  name: string;
  onSearch: Function;
  options: Array<any>;
  defaultValue?: any;
  onSelect?: Function;
  displayValue: string;
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  shouldLockOnSelectValue?: boolean;
  required?: boolean;
  isAddressSearch?: boolean;
  orderNumberSearch?: boolean;
  onChange?: Function;
  clearSuggestion?: boolean;
  itemDisplayValue?: string;
}

const CambriaAutosuggest: FC<CambriaAutosuggestProps> = ({
  name,
  onSearch,
  options,
  defaultValue,
  onSelect,
  displayValue,
  label,
  placeholder,
  disabled,
  shouldLockOnSelectValue,
  required,
  isAddressSearch,
  orderNumberSearch,
  onChange,
  clearSuggestion,
  itemDisplayValue,
}) => {
  const [field, meta, value] = useField(name);
  const {setFieldValue} = useFormikContext();
  let typeaheadRef: any = useRef();

  const [isLoading, setIsLoading] = useState(false);
  const [locked, setLocked] = useState(false);
  const [touched, setTouched] = useState(false);

  useEffect(() => {
    if (clearSuggestion) {
      clearValue();
    }
    // eslint-disable-next-line
  }, [clearSuggestion]);

  const handleSearch = async (query: string) => {
    setIsLoading(true);

    value.setValue(query);

    if (query.length === 0) {
      await onSearch(query, field);
    }
    if (query.length > 2) {
      await onSearch(query.trim(), field);
    }

    setIsLoading(false);
  };

  const handleBlur = (e: any) => {
    setTouched(true);
    value.setValue(e.target.value);
  };

  const clearValue = () => {
    typeaheadRef.clear();
    setFieldValue(name, '');
    setLocked(false);
    if (onSelect) {
      onSelect('');
    }
  };

  const filterBy = () => true;

  const determineDisplayValue = (option: any, props: any) => {
    if (isAddressSearch) {
      return boldSuggestionMatch(
        `${option['addressLine1']} ${option['addressLine2']} ${option['addressLine3']} ${option['city']}, ${option['state']} ${option['postalCode']}`,
        props.text
      );
    } else if (orderNumberSearch) {
      return boldSuggestionMatch(`${option['purchaseOrderNumber']} (${option['orderNumber']})`, props.text);
    } else {
      return boldSuggestionMatch(option[itemDisplayValue ?? displayValue], props.text);
    }
  };

  useEffect(() => {
    if (defaultValue) {
      if (setFieldValue) {
        setFieldValue(name, defaultValue);
      }
      typeaheadRef.state.text = defaultValue;
      if (defaultValue === '') {
        typeaheadRef.state.selected = [];
      }
      if (shouldLockOnSelectValue && field.value) {
        setLocked(true);
      }
    }
  }, [defaultValue, field.value, name, setFieldValue, shouldLockOnSelectValue]);

  return (
    <CambriaAutosuggestContainer>
      {label && <CambriaLabel htmlFor={name}>{label}</CambriaLabel>}
      {required && <strong> *</strong>}
      <CambriaAutosuggestField
        data-testid={`cambria-autosuggest-${name}`}
        ref={(ref) => (typeaheadRef = ref)}
        isLoading={isLoading}
        id={name}
        filterBy={filterBy}
        labelKey={displayValue}
        onSearch={handleSearch}
        className={`${meta.error && meta.touched ? 'input-error' : ''} ${locked ? 'locked' : ''} ${name}-test-class`}
        options={options}
        onBlur={handleBlur}
        onInputChange={(text: string) => onChange && onChange(text)}
        useCache={false}
        delay={300}
        disabled={disabled}
        defaultInputValue={defaultValue}
        placeholder={placeholder}
        onChange={(selectedOption) => {
          if (shouldLockOnSelectValue && field.value) {
            setLocked(true);
          }
          if (onSelect) {
            onSelect(selectedOption[0]);
          }
          if (setFieldValue && name) {
            setFieldValue(name, selectedOption[0]);
          }
        }}
        renderMenuItemChildren={(option: any, props: any) => (
          <div
            className="autosuggest-item"
            onClick={() => {
              value.setValue(props.text);
            }}>
            {determineDisplayValue(option, props)}
          </div>
        )}>
        {locked && (
          <button type="button" className="clear-value" onClick={clearValue}>
            <i className="fa fa-times-circle"></i>
          </button>
        )}
      </CambriaAutosuggestField>
      {meta.error && touched && <CambriaAutosuggestError>{meta.error}</CambriaAutosuggestError>}
    </CambriaAutosuggestContainer>
  );
};

export default CambriaAutosuggest;
