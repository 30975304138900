import {QuoteSearchParams} from '../ISearchState';

export const initialQuoteParams: QuoteSearchParams = {
  status: '',
  q: '',
  'dateRange.dateFrom': '',
  'dateRange.dateTo': '',
  'amountRange.amountFrom': '',
  'amountRange.amountTo': '',
};
