import styled from 'styled-components/macro';
import {$brandprimary, $gray78, $newwhite} from '../../../../../Styled/variables';

export const DeliveryScheduleContainer = styled.div`
  width: 100%;
  position: relative;
  padding: 20px 0;
`;

export const DeliveryScheduleTable = styled.table`
  width: 100%;

  tbody {
    tr {
      &:nth-child(odd) {
        background-color: ${$newwhite};
      }
    }
  }

  th {
    font-size: 16px;
    line-height: 16px;
    font-weight: bold;

    @media (max-width: 767px) {
      font-size: 12px;
    }
  }

  td {
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    vertical-align: top;

    &.description {
      display: flex;

      .description-title {
        position: relative;
        top: 22px;
        vertical-align: top;
      }

      img {
        margin: 2px 50px 2px 0;
        height: 100px;
        width: auto;
      }
    }

    &.edit {
      color: ${$brandprimary};
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &.mobile-table {
    margin-bottom: 30px;
    border-bottom: 1px solid ${$gray78};

    th {
      padding: 12px 2px;
    }

    td {
      padding: 14px 2px 0 2px;
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;

      &.description {
        padding-top: 0;

        img {
          height: 80px;
          margin: 0 10px 0 0;
        }

        .description-title {
          top: 14px;
        }
      }

      &.edit {
        padding-right: 6px;
      }
    }
  }
`;
