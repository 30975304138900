import {baseApiCallWithReauth} from '../../../Framework/api-utils/api-utils';
import COMMERCE_CORE_CONSTANTS from '../../../Core/constants';

export const getUiSettingsRequest = async (params: any) => {
  try {
    return await baseApiCallWithReauth('GET', COMMERCE_CORE_CONSTANTS.API_SERVICES.ENVIRONMENT.uiSettings, params);
  } catch (error: any) {
    throw error;
  }
};
