import React, {useCallback, useEffect} from 'react';
import {useAppDispatch, useTypedSelector} from '../../../hooks/store';
import {getUiSettingsAsync, selectUiSettings} from '../../../features/environment/slice/environment.slice';
import {selectCurrentCustomer} from '../../../features/customer/slice/customer.slice';
import {CustomerFullProfile} from '../../../features/customer/ICustomerState';
import {selectCurrentUser, selectIsAuthenticated} from '../../../features/auth/slice/authentication.slice';
import {getUserActionsAsync, selectUserActions} from '../../../features/permission/slice/permission.slice';
import {setProductKeyword} from '../../../features/search/product/slice/product-search.slice';
import CustomerResolves from './Customer/customer.resolves';
import SalesforceResolves from './Salesforce/salesforce.resolves';
import CustomerValidationResolve from './Customer/CustomerValidation/customerValidation.resolves';
import {useHistory, useLocation} from 'react-router-dom';
import {
  selectAllCustomerInformationLoaded,
  selectCartLoaded,
  selectCheckoutInformationLoaded,
  selectSalesforceInformationLoadStatus,
  setResolvesAreLoading,
} from '../../../features/resolves/slice/resolves.slice';
import CartResolves from './Cart/cart.resolves';
import {getCampaignUserEventsAsync} from '../../../features/notification/slice/notification.slice';
import WarehouseResolves from './Warehouse/warehouse.resolves';
import CheckoutResolves from './Checkout/checkout.resolves';
import {forceLogout} from '../../../features/auth/service/authentication.service';
import COMMERCE_CORE_CONSTANTS from '../../../Core/constants';
import {useExpiringQuoteNotificationResolve} from '../../../features/quote/hooks/useExpiringQuoteNotificationResolve';

export default function Resolves({children}: {children: any}) {
  const dispatch = useAppDispatch();
  const history = useHistory();
  let {hash, pathname} = useLocation();

  const currentUser = useTypedSelector(selectCurrentUser);
  const currentCustomer: CustomerFullProfile | null = useTypedSelector(selectCurrentCustomer);
  const isAuthenticated: boolean = useTypedSelector(selectIsAuthenticated);
  const cartLoaded = useTypedSelector(selectCartLoaded);
  const salesforceInformationLoaded = useTypedSelector(selectSalesforceInformationLoadStatus);
  const customerInfoLoaded = useTypedSelector(selectAllCustomerInformationLoaded);
  const uiSettings = useTypedSelector(selectUiSettings);
  const userActions = useTypedSelector(selectUserActions);
  const checkoutInfoLoaded = useTypedSelector(selectCheckoutInformationLoaded);
  useExpiringQuoteNotificationResolve();

  const redirectToHome = useCallback((): void => {
    if (cartLoaded && customerInfoLoaded && salesforceInformationLoaded && checkoutInfoLoaded) {
      if (
        pathname === COMMERCE_CORE_CONSTANTS.PATHNAMES.login ||
        pathname.includes(COMMERCE_CORE_CONSTANTS.PATHNAMES.baseSearch)
      ) {
        history.push(COMMERCE_CORE_CONSTANTS.PATHNAMES.home);
      }
      dispatch(setResolvesAreLoading(false));
    }
  }, [dispatch, cartLoaded, customerInfoLoaded, salesforceInformationLoaded, history, checkoutInfoLoaded, pathname]);

  // Logout if not authenticated
  useEffect(() => {
    if (!isAuthenticated) {
      dispatch(setResolvesAreLoading(false));
      forceLogout();
    } else if (currentUser) {
      dispatch(getCampaignUserEventsAsync({userId: currentUser.userId}));
    }
  }, [isAuthenticated, dispatch, currentUser]);

  useEffect(() => {
    if (window.location.href.includes('/#!/')) {
      window.location.assign(window.location.href.replace('/#!/', COMMERCE_CORE_CONSTANTS.PATHNAMES.home));
    }
  }, []);

  useEffect(() => {
    redirectToHome();
  }, [
    cartLoaded,
    customerInfoLoaded,
    salesforceInformationLoaded,
    dispatch,
    pathname,
    history,
    checkoutInfoLoaded,
    redirectToHome,
  ]);

  // User Permissions
  useEffect(() => {
    if (currentUser?.userId && (!userActions || userActions.length === 0)) {
      dispatch(getUserActionsAsync({userId: currentUser.userId}));
    }
  }, [dispatch, currentUser, userActions]);

  // UI Settings
  useEffect(() => {
    if (currentCustomer && currentCustomer.id !== undefined && !uiSettings) {
      dispatch(getUiSettingsAsync());
    }
  }, [dispatch, currentCustomer, uiSettings]);

  useEffect(() => {
    dispatch(setProductKeyword(null));
  }, [hash, dispatch]);

  return (
    <CustomerValidationResolve>
      <CustomerResolves>
        <CartResolves>
          <WarehouseResolves>
            <SalesforceResolves>
              <CheckoutResolves>{children}</CheckoutResolves>
            </SalesforceResolves>
          </WarehouseResolves>
        </CartResolves>
      </CustomerResolves>
    </CustomerValidationResolve>
  );
}
