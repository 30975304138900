import React, {useEffect, useState} from 'react';
import ProductTable from '../../../../../Core/Components/CambriaProductTable';
import {IQuoteDetails} from '../../../../../features/quote/IQuoteState';
import {selectQuoteDetails} from '../../../../../features/quote/slice/quote.slice';
import {useTypedSelector} from '../../../../../hooks/store';
import {LineItem} from '../../OrderHistory/OrderDetails/IOrderDetails';
import {getLineItemsForQuoteProductTable} from '../../../../../features/quote/quoteDetails/service/quoteDetails.service';
import FabricationCart from '../../OrderHistory/OrderDetails/FabricationCart';

const QuoteLines = () => {
  const quoteDetails: IQuoteDetails = useTypedSelector(selectQuoteDetails);
  const [productLineItems, setProductLineItems] = useState<Array<LineItem>>([]);

  useEffect(() => {
    const getProductLineItems = async () => {
      setProductLineItems(await getLineItemsForQuoteProductTable({quoteDetails}));
    };

    if (quoteDetails && quoteDetails.type === 'commercial') {
      getProductLineItems();
    }
  }, [quoteDetails]);

  return (
    <>
      {quoteDetails?.type === 'commercial' && <ProductTable orderLineItems={productLineItems} forQuoteDetails={true} />}
      {quoteDetails?.type === 'standardCia' && <FabricationCart quote={quoteDetails} shouldUseOrderMetadata={false} />}
    </>
  );
};

export default QuoteLines;
