import React from 'react';
import {useTypedSelector} from '../../../hooks/store';
import {selectUiSettings} from '../../../features/environment/slice/environment.slice';
import {AcademyContainer} from './Academy.styles';
import {Col, Container, Row} from 'react-bootstrap';
import CambriaCarousel from '../../../Core/Components/Carousel';
import InterestForm from './InterestForm';
import MeetTheTeam from './MeetTheTeam';
import InTheirOwnWords from './InTheirOwnWords';

const Academy = () => {
  const uiSettings = useTypedSelector(selectUiSettings);

  if (uiSettings) {
    return (
      <div className="react-bootstrap-hack cambria-main-content">
        <AcademyContainer>
          <Container>
            <Row className="flex-column header">
              <Col>
                <h1>{uiSettings.academyPageContent.header}</h1>
                <h2>{uiSettings.academyPageContent.subHeader}</h2>
                <p>{uiSettings.academyPageContent.description}</p>
              </Col>
            </Row>
            <Row className="flex-column m-t-lg">
              <Col>
                <CambriaCarousel isOnAcademyPage items={uiSettings.academyPageContent.academyCarousel.content} />
              </Col>
            </Row>
            <Row>
              <Col>
                <MeetTheTeam teamMemberImages={uiSettings.academyPageContent.meetTheTeam.teamMemberImages} />
              </Col>
            </Row>
            <Row>
              <Col>
                <InTheirOwnWords academyQuotes={uiSettings.academyPageContent.inTheirOwnWords.academyQuotes} />
              </Col>
            </Row>
            <Row className="flex-column m-t-lg">
              <Col>
                <InterestForm />
              </Col>
            </Row>
          </Container>
        </AcademyContainer>
      </div>
    );
  }

  return <div></div>;
};

export default Academy;
