import React, {useEffect, useState} from 'react';
import {Col, Row} from 'react-bootstrap';
import {Cart} from '../../../../../features/cart/ICartState';
import {CustomerFullProfile} from '../../../../../features/customer/ICustomerState';
import useWindowDimensions from '../../../../../hooks/getWindowDimensions';
import {ReviewOrderHeaderContainer} from './ReviewOrderHeader.styled';

const ReviewOrderHeader = ({
  cart,
  programName,
  customer,
}: {
  cart: Cart;
  programName: string;
  customer: CustomerFullProfile | null;
}) => {
  const {width} = useWindowDimensions();

  const [isMarketRepCustomer, setIsMarketRepCustomer] = useState(false);

  useEffect(() => {
    if (customer && (customer?.class === 'MARKETREP' || customer?.class === 'INT MARKETREP')) {
      setIsMarketRepCustomer(true);
    }
  }, [customer]);

  return (
    <ReviewOrderHeaderContainer>
      <Row>
        <Col className="d-flex justify-content-start align-items-center">
          <span className="user-name">{customer?.name}</span>
        </Col>
        {width > 960 && (
          <Col className="d-flex justify-content-end align-items-center">
            <div className="status-box">ORDER REVIEW</div>
          </Col>
        )}
      </Row>
      {cart.orderDetails[0]?.purchaseOrderNumber && (
        <Row>
          <Col className="d-flex justify-content-start mt-3">
            <span className="attribute-title">PURCHASE ORDER NUMBER:</span>&nbsp;
            <span className="attribute-value">{cart.orderDetails[0].purchaseOrderNumber}</span>
          </Col>
        </Row>
      )}
      <Row>
        <Col className="d-flex justify-content-start mt-3">
          <span className="attribute-title">PROGRAM NAME:</span>&nbsp;
          <span className="attribute-value">{programName.toUpperCase()}</span>
        </Col>
      </Row>
      {cart.orderDetails[0]?.orderCrmAccountName && isMarketRepCustomer && (
        <Row>
          <Col className="d-flex justify-content-start mt-3">
            <span className="attribute-title">SALESFORCE ACCOUNT:</span>&nbsp;
            <span className="attribute-value">{cart.orderDetails[0].orderCrmAccountName.toUpperCase()}</span>
          </Col>
        </Row>
      )}
      {cart.shippingMethods[0]?.shippingMethodName && (
        <Row>
          <Col className="d-flex justify-content-start mt-3">
            <span className="attribute-title">DELIVERY METHOD:</span>&nbsp;
            <span className="attribute-value">{cart.shippingMethods[0].shippingMethodName.toUpperCase()}</span>
          </Col>
        </Row>
      )}
      {cart.shippingMethods[0]?.shippingInstructions && (
        <Row>
          <Col className="d-flex justify-content-start mt-3">
            <span className="attribute-title">SHIPPING INSTRUCTIONS:</span>&nbsp;
            <span className="attribute-value">{cart.shippingMethods[0].shippingInstructions}</span>
          </Col>
        </Row>
      )}
      {cart.orderDetails[0]?.fabricationInstructions && (
        <Row>
          <Col className="d-flex justify-content-start mt-3">
            <span className="attribute-title fabrication-instructions-title">FABRICATION INSTRUCTIONS:</span>&nbsp;
            <span className="attribute-value">{cart.orderDetails[0]?.fabricationInstructions}</span>
          </Col>
        </Row>
      )}
      {width <= 960 && (
        <Row>
          <Col className="d-flex justify-content-start align-items-center mt-4">
            <div className="status-box">ORDER REVIEW</div>
          </Col>
        </Row>
      )}
    </ReviewOrderHeaderContainer>
  );
};

export default ReviewOrderHeader;
