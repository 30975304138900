import React, {useEffect, useState} from 'react';
import {Dropdown} from 'react-bootstrap';
import {useTypedSelector, useAppDispatch} from '../../../../../hooks/store';
import {selectOrderSearch} from '../../../../../features/search/slice/search.slice';
import {orderHistoryStatusOptions, orderSearchStatusOptions, orderTypes} from './filterOptions';
import ActiveFiltersDisplay from './DisplayActiveFilters';
import {processOrderParamsFromUrl} from './UrlFilterFunctions/orderUrlParameters.service';
import {OrderSearchContainer} from './OrderSearch.styled';
import AmountInput from './Inputs/Amount';
import StringInput from './Inputs/String';
import DropdownInput from './Inputs/Dropdown';
import DateInput from './Inputs/Date';
import {useHistory, useLocation} from 'react-router-dom';
import {baseApiCallWithReauth} from '../../../../../Framework/api-utils/api-utils';
import COMMERCE_CORE_CONSTANTS from '../../../../constants';
import {
  orderSearchAsync,
  setActiveOrderFilter,
  setOrderActiveParams,
  setOrderUrlSearchParams,
} from '../../../../../features/search/order/slice/order-search.slice';
import {cleanSearchParams} from '../../../../../features/order/service/order.service';

const OrderSearch = ({
  erpCustomerId,
  relativeUrl,
  orderFilterOptions,
}: {
  erpCustomerId?: number;
  relativeUrl: string;
  orderFilterOptions: string[];
}) => {
  let [activeSearchParams, setActiveSearchParams]: any[] = useState([]);
  const endpoint = COMMERCE_CORE_CONSTANTS.API_SERVICES.ORDER.orders;

  let [amountMin, setAmountMin] = useState<number>(0);
  let [amountMax, setAmountMax] = useState<number>(0);

  let activeFilter = useTypedSelector(selectOrderSearch).activeFilter;
  let urlSearchParams = useTypedSelector(selectOrderSearch).orderUrlSearchParams;
  let reduxActiveParams = useTypedSelector(selectOrderSearch).orderActiveParams;

  const dispatch = useAppDispatch();
  const history = useHistory();
  const location = useLocation();

  // Grabs Params from URL everytime it changes
  useEffect(() => {
    let returnedData: any = processOrderParamsFromUrl(location.search);
    let activeFiltersArray = returnedData.paramsList;
    let urlParamsJson = returnedData.orderSearchCopy;
    dispatch(setOrderActiveParams(activeFiltersArray));
    dispatch(setOrderUrlSearchParams(urlParamsJson));
  }, [dispatch, location]);

  // Get amount minimum and maximum on initial load
  useEffect(() => {
    const loadAmountMinMax = async () => {
      let data = new URLSearchParams();
      if (erpCustomerId) {
        data.append('erpCustomerId', erpCustomerId.toString());
      }
      data.append('pager.expand', 'totalResults');
      data.append('pager.offset', '0');
      data.append('pager.limit', '1');
      data.append('pager.sort', 'totalAmount');
      let minResult = await baseApiCallWithReauth('GET', endpoint, data);
      data.set('pager.sort', '-totalAmount');
      let maxResult = await baseApiCallWithReauth('GET', endpoint, data);

      if (minResult.results && minResult.results.length > 0 && minResult.results && maxResult.results.length > 0) {
        setAmountMin(minResult.results[0].totalAmount);
        setAmountMax(maxResult.results[0].totalAmount);
      }
    };
    loadAmountMinMax();
  }, [dispatch, erpCustomerId, endpoint]);

  // Performs the order search API call whenever a param is added/removed
  useEffect(() => {
    setActiveSearchParams(reduxActiveParams);
    let filters: any = urlSearchParams;
    let data = new URLSearchParams(filters);
    data = cleanSearchParams(data);
    data.append('pager[expand]', 'totalResults');
    data.append('pager[offset]', '0');
    data.append('pager[limit]', '50');
    data.append('pager[expand]', 'orderLineItems');
    data.append('pager[sort]', '-createdAt');
    data.append('pager[expand]', 'purchaseOrderNumber');
    if (erpCustomerId) {
      data.append('erpCustomerId', erpCustomerId.toString());
    }
    dispatch(orderSearchAsync(data));
  }, [reduxActiveParams, dispatch, urlSearchParams, erpCustomerId]);

  const setIsFavorite = (trueOrFalse: boolean) => {
    let urlSearchParamCopy = {...urlSearchParams};
    if (trueOrFalse === true) {
      urlSearchParamCopy.isFavorite = 'true';
    } else {
      urlSearchParamCopy.isFavorite = '';
    }
    if (location.search.includes('isFavorite') || urlSearchParamCopy.isFavorite === 'true') {
      let data = new URLSearchParams(urlSearchParamCopy);
      data = cleanSearchParams(data);
      history.push({
        pathname: relativeUrl,
        search: '?' + data.toString(),
      });
    }
  };

  const RenderFilterOptions = ({filters}: {filters: string[]}) => {
    return (
      <Dropdown.Menu className="main-filter-menu">
        {filters.map((filter: string) => (
          <Dropdown.Item
            key={filter}
            onClick={() => {
              dispatch(setActiveOrderFilter(filter));
              if (filter === 'Favorites') {
                setIsFavorite(true);
              } else {
                setIsFavorite(false);
              }
            }}>
            {filter}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    );
  };

  const decideInputToRender = (filter: string) => {
    if (filter === 'Date') {
      return <DateInput urlSearchParams={urlSearchParams} url={relativeUrl} />;
    } else if (filter === 'Status') {
      const statusOptions = relativeUrl.includes('search') ? orderSearchStatusOptions : orderHistoryStatusOptions;
      return (
        <DropdownInput
          options={statusOptions}
          urlSearchParams={urlSearchParams}
          activeFilter={activeFilter}
          url={relativeUrl}
        />
      );
    } else if (filter === 'Amount') {
      return (
        <AmountInput amountMin={amountMin} amountMax={amountMax} urlSearchParams={urlSearchParams} url={relativeUrl} />
      );
    } else if (filter === 'Order Type') {
      return (
        <DropdownInput
          options={orderTypes}
          urlSearchParams={urlSearchParams}
          activeFilter={activeFilter}
          url={relativeUrl}
        />
      );
    }
    return <StringInput urlSearchParams={urlSearchParams} activeFilter={activeFilter} url={relativeUrl} />;
  };

  return (
    <OrderSearchContainer>
      <div className="bg-gray">
        <div className="gray-header-background"></div>
        <div id="react-bootstrap-hack" className="container search-container global-search">
          <div className="search-items-container">
            <Dropdown>
              <Dropdown.Toggle id="dropdown" className="main-dropdown-toggle text-left">
                {activeFilter}
              </Dropdown.Toggle>
              <RenderFilterOptions filters={orderFilterOptions} />
            </Dropdown>
            {decideInputToRender(activeFilter)}
          </div>
          <ActiveFiltersDisplay activeSearchParams={activeSearchParams} url={relativeUrl} />
        </div>
      </div>
    </OrderSearchContainer>
  );
};

export default OrderSearch;
