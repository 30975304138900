import React, {FC, useCallback, useEffect, useState} from 'react';
import {Form, Formik} from 'formik';
import * as Yup from 'yup';
import 'yup-phone';
import {toast} from 'react-toastify';
import {Col, Container, Row} from 'react-bootstrap';
import CambriaModal from '../../../../Framework/Components/CambriaModal';
import CambriaInput from '../../../../Framework/Components/CambriaInput';
import {
  getStatesAndProvinces,
  selectDisableZipCodeField,
  selectStatesAndProvidences,
  setDisableZipCodeField,
} from '../../../../features/location/slice/location.slice';
import {useAppDispatch, useTypedSelector} from '../../../../hooks/store';
import CambriaAutosuggest from '../../../../Framework/Components/CambriaAutosuggest';
import {selectUiSettings} from '../../../../features/environment/slice/environment.slice';
import {selectActiveCart} from '../../../../features/cart/slice/cart.slice';
import {IQuote, NewQuote, NewQuoteLocation} from '../../../../features/quote/IQuoteState';
import {
  selectCurrentCustomer,
  selectCurrentCustomerBillToSites,
  selectCurrentCustomerShipToSites,
} from '../../../../features/customer/slice/customer.slice';
import {selectCurrentUser} from '../../../../features/auth/slice/authentication.slice';
import {setProcessingRequest} from '../../../../features/quote/slice/quote.slice';
import {getBillToSiteByOperatingUnitCode, removeCart} from '../../../../features/cart/service/cart.service';
import {calculateRequestedDateQualifiesForDiscount, postQuote} from '../../../../features/quote/service/quote.service';
import {useHistory} from 'react-router-dom';
import {
  selectActiveProgram,
  selectAvailablePrograms,
  selectProjectOpportunities,
  selectSalesforceCustomerAccount,
  setProjectOpportunitiesAsync,
} from '../../../../features/salesforce/slice/salesforce.slice';
import CambriaDateInput from '../../../../Framework/Components/CambriaDateInput';
import {AddQuoteModalPage} from './AddQuoteModal.styled';
import CambriaSelect from '../../../../Framework/Components/CambriaSelect';
import {
  hideFullscreenLoader,
  showFullscreenLoader,
} from '../../../../features/fullscreenLoader/slice/fullscreenLoader.slice';
import {newDate} from '../../../../Framework/Services/newDate.service';
import {hasPermission} from '../../../../store/permission/permission.service';
import {selectUserActions} from '../../../../features/permission/slice/permission.slice';
import {ISalesforceAvailablePrograms} from '../../../../features/salesforce/ISalesforceState';
import {getLocationRequest} from '../../../../features/location/controller/location.controller';
import {getUserByIdRequest} from '../../../../features/user/controller/user.controller';
import {Cart} from '../../../../features/cart/ICartState';
import {selectPurchasingCustomer} from '../../../../features/fabrication/slice/fabrication.slice';
import RequestedDateAcknowledgePopup from './RequestedDateAcknowledgePopup';

interface AddQuoteModalProps {
  show?: boolean;
  toggleShow?: any;
  type?: string;
}

const AddQuoteModal: FC<AddQuoteModalProps> = ({show, toggleShow, type}) => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const cart: Cart | null = useTypedSelector(selectActiveCart);
  const customer = useTypedSelector(selectCurrentCustomer);
  const shipToSites: any = useTypedSelector(selectCurrentCustomerShipToSites);
  const billToSites: any = useTypedSelector(selectCurrentCustomerBillToSites);
  const uiSettings = useTypedSelector(selectUiSettings);
  const currentUser: any = useTypedSelector(selectCurrentUser);
  const activeProgram = useTypedSelector(selectActiveProgram);
  const projectOpportunitiesRedux = useTypedSelector(selectProjectOpportunities);
  const projectOpportunities = projectOpportunitiesRedux?.map((x) => {
    let itemLabel = x.name;
    if (x.city || x.state) {
      itemLabel += ' - ';
    }
    itemLabel += x.city ?? '';
    if (x.state) {
      itemLabel += `, ${x.state}`;
    }
    return {
      ...x,
      itemLabel,
    };
  });
  const statesAndProvidences = useTypedSelector(selectStatesAndProvidences);
  const disableZipCodeField = useTypedSelector(selectDisableZipCodeField);
  const salesforceCustomerAccount = useTypedSelector(selectSalesforceCustomerAccount);
  const userActions = useTypedSelector(selectUserActions);
  const availablePrograms: ISalesforceAvailablePrograms | null = useTypedSelector(selectAvailablePrograms);
  const fabricationPurchasingCustomers = useTypedSelector(selectPurchasingCustomer);

  const [hasInitialized, setHasInitialized] = useState<boolean>(false);
  const [quote, setQuote] = useState<IQuote>(NewQuote());
  const [applicationItems, setApplicationItems] = useState(() => {
    if (uiSettings?.commercialQuoteApplications) {
      return uiSettings.commercialQuoteApplications.map((category: string) => ({
        label: category,
        value: category.toLowerCase(),
      }));
    } else {
      return [];
    }
  });
  const [applications, setApplications]: any = useState([]);
  const [tomorrow, setTomorrow]: any = useState(newDate());
  const [maxExpirationQuoteDate, setMaxExpirationQuoteDate]: any = useState(newDate());
  const [projectOpportunity, setProjectOpportunity]: any = useState<IQuote | null>(null);
  const [show30daysWarning, setShow30daysWarning] = useState<boolean>(false);
  const [projectOpErrorMessage, setProjectOpErrorMessage] = useState<boolean>(false);
  const [disableSubmit, setDisableSubmit] = useState<boolean>(false);
  const [timer, setTimer] = useState<any>(null);

  const isCommercialQuote: boolean = type === 'commercial';
  const isCIAQuote: boolean = type === 'cia';

  let modalOptions = {
    heading: '',
    cancelButtonText: '',
    confirmButtonText: '',
    formName: '',
  };

  let quoteInitialValues: any = {};
  let quoteValidationSchema: any = {};
  let addQuoteFormTemplate: any = {};

  if (isCommercialQuote) {
    modalOptions.heading = 'Commercial Quote Request';
    modalOptions.cancelButtonText = 'CANCEL';
    modalOptions.confirmButtonText = 'REQUEST QUOTE';
    modalOptions.formName = 'addCommercialQuoteForm';

    quoteInitialValues = {
      name: '',
      expectedOrderDate: '',
      category: '',
      application: '',
      subContractor: '',
      location: '',
      city: '',
      state: '',
      postalCode: '',
      expirationQuoteDate: '',
    };

    quoteValidationSchema = {
      name: Yup.string()
        .required('This field is required')
        .max(255, 'The maximum length of this field is 255')
        .min(3, 'Project name should be at least 3 characters long.'),
      expectedOrderDate: Yup.mixed().required('This field is required'),
      category: Yup.mixed().required('This field is required'),
      application: Yup.mixed(),
      subContractor: Yup.string().required('This field is required'),
      location: Yup.string().required('This field is required'),
      city: Yup.string().required('This field is required'),
      state: Yup.mixed().required('This field is required'),
      postalCode: Yup.string().required('This field is required'),
      expirationQuoteDate: Yup.mixed(),
    };

    addQuoteFormTemplate = {
      name: {
        validation: Yup.string().required('This field is required'),
      },
      expectedOrderDate: {
        validation: Yup.mixed().required('This field is required'),
      },
      category: {
        validation: Yup.mixed().required('This field is required'),
      },
      application: {
        validation: Yup.mixed(),
      },
      subContractor: {
        validation: Yup.string().required('This field is required'),
      },
      location: {
        validation: Yup.string().required('This field is required'),
      },
      city: {
        validation: Yup.string().required('This field is required'),
      },
      state: {
        validation: Yup.mixed().required('This field is required'),
      },
      postalCode: {
        validation: Yup.string().required('This field is required'),
      },
      expirationQuoteDate: {
        validation: Yup.mixed().required('This field is required'),
      },
    };
  }

  if (isCIAQuote) {
    modalOptions.heading = 'Quote Request';
    modalOptions.cancelButtonText = 'CANCEL';
    modalOptions.confirmButtonText = 'REQUEST QUOTE';
    modalOptions.formName = 'addCIAQuoteForm';

    quoteInitialValues = {
      name: '',
      expectedOrderDate: '',
      expirationQuoteDate: '',
    };
    if (cart?.orderDetails[0]?.jobType?.includes('Commercial')) {
      (quoteInitialValues as any).category = '';
    }

    quoteValidationSchema = {
      name: Yup.string()
        .required('This field is required')
        .max(255, 'The maximum length of this field is 255')
        .min(3, 'Project name should be at least 3 characters long.'),
      expectedOrderDate: Yup.mixed().required('This field is required'),
      expirationQuoteDate: Yup.mixed(),
    };
    if (cart?.orderDetails[0]?.jobType?.includes('Commercial')) {
      (quoteValidationSchema as any).category = Yup.mixed().required('This field is required');
    }

    addQuoteFormTemplate = {
      name: {
        validation: Yup.string().required('This field is required'),
      },
      expectedOrderDate: {
        validation: Yup.mixed().required('This field is required'),
      },
      expirationQuoteDate: {
        validation: Yup.mixed().required('This field is required'),
      },
    };
    if (cart?.orderDetails[0]?.jobType?.includes('Commercial')) {
      (addQuoteFormTemplate as any).category = {
        validation: Yup.mixed().required('This field is required'),
      };
    }
  }

  const onDateChange = (change: Date) => {
    let tempQuote = {...quote} as IQuote;
    tempQuote.expectedOrderDate = change;
    const daysDifference =
      (new Date(tempQuote.expectedOrderDate).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24);

    setShow30daysWarning(!calculateRequestedDateQualifiesForDiscount(tempQuote.expectedOrderDate));

    if (daysDifference <= -1) {
      tempQuote.expectedOrderDate = null;
    }
    setQuote(tempQuote);
  };

  const onExpirationDateChange = (change: any) => {
    let copyOfExpirationDate = new Date(change.getTime());
    let hoursDifference = new Date().getTimezoneOffset() / -60;
    let sameDateInUTC = new Date(
      copyOfExpirationDate.setTime(copyOfExpirationDate.getTime() + hoursDifference * 60 * 60 * 1000)
    );
    let tempQuote = {...quote};
    tempQuote.expirationDate = sameDateInUTC;
    setQuote(tempQuote);
  };

  const getStateCodeOrCountryCodeByStateName = (stateCode: string, isStateCode: boolean) => {
    if (!stateCode) {
      return null;
    }
    const match: any = statesAndProvidences.find(
      (location: any) => location.locationCode.toLowerCase() === stateCode.toLowerCase()
    );

    if (match) {
      return isStateCode ? match.locationCode : match.countryAlpha2Code;
    }
  };

  const getPostalCode = async (props: any, tempQuote: any) => {
    const countryCode = getStateCodeOrCountryCodeByStateName(tempQuote.location.stateProvince, false);
    const stateCode = getStateCodeOrCountryCodeByStateName(tempQuote.location.stateProvince, true);
    let postalCode = await getLocationRequest(tempQuote.location.city.trim(), stateCode, countryCode);
    postalCode = await postalCode.text();
    tempQuote.location.postalZipCode = postalCode;
    props.setFieldValue('postalCode', postalCode);
  };

  const onCityOrStateChange = async (change: any, props: any, field: any) => {
    if (timer) {
      clearTimeout(timer);
      setTimer(null);
    }
    setTimer(
      setTimeout(async () => {
        let tempQuote = {...quote} as any;
        tempQuote.location[field] = change?.locationCode ?? change?.stateName ?? change;
        dispatch(setProcessingRequest(true));
        if (tempQuote.location.city === null || tempQuote.location.city.trim() === '') {
          props.setFieldValue(field, '');
          return;
        }
        if (tempQuote.location.city && tempQuote.location.stateProvince) {
          dispatch(setDisableZipCodeField(true));
          try {
            await getPostalCode(props, tempQuote);
          } finally {
            dispatch(setDisableZipCodeField(false));
          }
        }
        setQuote(tempQuote);
        dispatch(setProcessingRequest(false));
      }, 500)
    );
  };

  const getStateByCode = async (code: string) => {
    if (!code) {
      return null;
    }

    const match: any = statesAndProvidences.find((location: any) => location.locationCode === code);

    if (match) {
      return match;
    }
  };

  const removeProjectName = (props: any) => {
    setProjectOpportunity(null);

    let tempQuote: IQuote = {...quote};

    tempQuote.name = '';
    tempQuote.category = '';
    props.setFieldValue('category', '');
    tempQuote.expectedOrderDate = null;
    props.setFieldValue('expectedOrderDate', null);
    tempQuote.location = NewQuoteLocation();
    props.setFieldValue('location', '');
    props.setFieldValue('city', '');
    props.setFieldValue('state', '');
    props.setFieldValue('postalCode', '');

    setQuote(tempQuote);
  };

  const suggestProjectOpportunity = async (change: string) => {
    let tempQuote = {...quote};
    tempQuote.name = change;
    setQuote(tempQuote);
    setDisableSubmit(true);
    await dispatch(setProjectOpportunitiesAsync({searchString: change}));
    setDisableSubmit(false);
  };

  const onProjectOpportunitySelect = async (object: any, props: any) => {
    if (object === '') {
      removeProjectName(props);
      setProjectOpErrorMessage(false);
      setShow30daysWarning(false);
      return;
    }
    if (!(object.street && object.city && object.state && object.zipCode)) {
      setProjectOpErrorMessage(true);
      setDisableSubmit(true);
      return;
    }
    setDisableSubmit(false);

    setProjectOpportunity(object);
    let tempQuote = {...quote};
    tempQuote.name = object.name;
    tempQuote.category = object.category;
    props.setFieldValue(
      'category',
      object.category ? {label: object.category, name: object.category.toLowerCase()} : ''
    );
    tempQuote.expectedOrderDate = new Date(object.closeDate);
    const daysDifference =
      (new Date(tempQuote.expectedOrderDate).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24);

    setShow30daysWarning(daysDifference < 30);

    if (daysDifference <= -1) {
      tempQuote.expectedOrderDate = null;
    }
    props.setFieldValue('expectedOrderDate', tempQuote.expectedOrderDate);
    if (object.street || object.city || object.state || object.zipCode || object.country) {
      tempQuote.location = NewQuoteLocation();
      tempQuote.location.businessName = object.businessName;
      props.setFieldValue('location', object.businessName ? object.businessName : '');
      tempQuote.location.addressLine1 = object.street;
      tempQuote.location.city = object.city;
      props.setFieldValue('city', object.city ? object.city : '');
      const state = await getStateByCode(object.state);
      tempQuote.location.stateProvince = state?.locationCode ?? state?.stateName ?? '';
      props.setFieldValue('state', state ? state : '');
      tempQuote.location.postalZipCode = object.zipCode;
      props.setFieldValue('postalCode', object.zipCode ? object.zipCode : '');
      tempQuote.location.country = object.country;
    }
    setQuote(tempQuote);
  };

  const removeSingleApplication = (application: any) => {
    let tempApplicationItems = JSON.parse(JSON.stringify(applicationItems));
    tempApplicationItems.unshift(application);
    setApplicationItems(tempApplicationItems);

    const applicationArray = applications.filter((item: any) => item.label !== application.label);
    setApplications(applicationArray);
    const tempQuote = {...quote};
    tempQuote.application = applicationArray;
    setQuote(tempQuote);
  };

  const updateQuoteOnChange = (change: any, key: string, childKey?: any) => {
    let tempQuote = {...quote} as any;

    if (childKey) {
      tempQuote[key][childKey] = change;
    } else {
      if (key === 'application') {
        let applicationArray = applications;
        applicationArray.push(change);
        let tempApplicationItems = JSON.parse(JSON.stringify(applicationItems));
        let result = tempApplicationItems.filter((tempItem: any) => tempItem.label !== change.label);
        setApplicationItems(result);
        setApplications(applicationArray);
        tempQuote[key].push(change.label);
      } else {
        tempQuote[key] = change.label;
      }
    }
    setQuote(tempQuote);
  };

  const isUserDisplayNameValidForCommercialQuote = async () => {
    const user = await getUserByIdRequest(currentUser.userId, customer.erpCustomerId.toString());
    const displayName = user.displayName;
    const regex = new RegExp(/\S+\s+\S[\s\S]*/i);
    return regex.test(displayName);
  };

  const submitCommercialQuote = async () => {
    const isDisplayNameValid = await isUserDisplayNameValidForCommercialQuote();

    if (!isDisplayNameValid) {
      toast.error('Please contact your Market Rep, your user setup is not complete to create a commercial quote');
      return;
    }

    try {
      toggleShow();
      dispatch(showFullscreenLoader({showCartIcon: true}));
      dispatch(setProcessingRequest(true));
      const lines = [];
      if (cart) {
        for (const cartItem of cart.cartItems) {
          const billToSite = await getBillToSiteByOperatingUnitCode(cartItem.operatingUnitCode, billToSites);
          const line = {
            warehouseCode: cartItem.shipFromWarehouseId,
            programCode: cartItem.programCode,
            ItemNumber: cartItem.itemNumber,
            priceListCode: cartItem.priceListCode,
            siteUseId: billToSite.siteUseId,
            quantity: cartItem.quantity,
            pricingUom: cartItem.pricingUom,
            unitPrice: cartItem.unitPrice,
            currency: cartItem.currency,
            imageUrl: cartItem.imageUrl,
          };
          lines.push(line);
        }
      }

      const subContractor = {
        crmAccountId: quote.subContractor.crmAccountId ? quote.subContractor.crmAccountId.trim() : null,
        companyName: quote.subContractor.companyName ? quote.subContractor.companyName.trim() : null,
        contactFirstName: quote.subContractor.contactFirstName ? quote.subContractor.contactFirstName.trim() : null,
        contactLastName: quote.subContractor.contactLastName ? quote.subContractor.contactLastName.trim() : null,
        contactEmail: quote.subContractor.contactEmail ? quote.subContractor.contactEmail.trim() : null,
        contactPhone: quote.subContractor.contactPhone ? quote.subContractor.contactPhone.trim() : null,
        addressLine1: quote.subContractor.addressLine1 ? quote.subContractor.addressLine1.trim() : null,
        addressLine2: quote.subContractor.addressLine2 ? quote.subContractor.addressLine2.trim() : null,
        addressLine3: quote.subContractor.addressLine3 ? quote.subContractor.addressLine3.trim() : null,
        city: quote.subContractor.city ? quote.subContractor.city.trim() : null,
        stateProvince: quote.subContractor.stateProvince ? quote.subContractor.stateProvince.trim() : null,
        postalZipCode: quote.subContractor.postalZipCode ? quote.subContractor.postalZipCode.trim() : null,
        country: quote.subContractor.country ? quote.subContractor.country.trim() : null,
      };

      const location = {
        businessName: quote.location.businessName ? quote.location.businessName.trim() : null,
        addressLine1: quote.location.addressLine1 ? quote.location.addressLine1.trim() : null,
        addressLine2: quote.location.addressLine2 ? quote.location.addressLine2.trim() : null,
        addressLine3: quote.location.addressLine3 ? quote.location.addressLine3.trim() : null,
        city: quote.location.city ? quote.location.city.trim() : null,
        stateProvince: quote.location.stateProvince ? quote.location.stateProvince.trim() : null,
        postalZipCode: quote.location.postalZipCode ? quote.location.postalZipCode.trim() : null,
        country: quote.location.country ? quote.location.country.trim() : null,
      };

      let expectedOrderDate: any;
      if (quote && quote.expectedOrderDate) {
        let newDate: any = new Date((quote as any).expectedOrderDate);
        expectedOrderDate = new Date(Date.UTC(newDate.getFullYear(), newDate.getMonth(), newDate.getDate()));
      }

      let expirationDate: any;
      if (quote && quote.expirationDate) {
        expirationDate = new Date(quote.expirationDate);
      }

      const params = {
        type: 'commercial',
        name: quote.name,
        customerName: customer.name,
        customerClass: customer.class,
        erpCustomerId: customer.erpCustomerId,
        crmAccountId: salesforceCustomerAccount ? salesforceCustomerAccount.id : null,
        crmProjectOpportunityId: projectOpportunity ? projectOpportunity.id : null,
        cartId: cart?.id,
        expectedOrderDate: expectedOrderDate,
        category: quote.category,
        application: quote.application,
        lines: lines,
        subContractor: subContractor,
        location: location,
        expirationDate: expirationDate,
      };

      const tempQuote = await postQuote(params, dispatch);
      dispatch(setProcessingRequest(false));
      await removeCart(
        cart?.id || '',
        undefined,
        undefined,
        customer,
        currentUser.userId,
        activeProgram,
        shipToSites,
        billToSites,
        dispatch,
        availablePrograms
      );
      history.push(`/quoteConfirmation/${tempQuote.quoteId}`);
    } catch (e) {
      toast.error('An error occured while requesting the quote. Please contact Customer Care for assistance.');
    } finally {
      dispatch(hideFullscreenLoader());
    }
  };

  const submitCIAQuote = async () => {
    const isDisplayNameValid = await isUserDisplayNameValidForCommercialQuote();

    if (!isDisplayNameValid) {
      toast.error('Please contact your Market Rep, your user setup is not complete to create a commercial quote');
      return;
    }

    try {
      toggleShow();
      dispatch(showFullscreenLoader({showCartIcon: true}));
      dispatch(setProcessingRequest(true));
      const lines = [];
      if (cart) {
        for (const cartItem of cart.cartItems) {
          const billToSite = await getBillToSiteByOperatingUnitCode(cartItem.operatingUnitCode, billToSites);
          const line = {
            warehouseCode: cartItem.shipFromWarehouseId,
            programCode: cartItem.programCode,
            ItemNumber: cartItem.itemNumber,
            siteUseId: billToSite.siteUseId,
            quantity: cartItem.quantity,
            pricingUom: cartItem.pricingUom,
            unitPrice: cartItem.unitPrice,
            currency: cartItem.currency,
            imageUrl: null,
            priceListCode: cartItem.priceListCode,
            productType: cartItem.productType,
            cartItemId: cartItem.cartItemId,
            cartItemType: cartItem.cartItemType,
            parentCartItemId: cartItem.parentCartItemId,
            pieceLabel: cartItem.pieceLabel,
            productGroupCode: cartItem.productGroupCode,
            productApplication: cartItem.productApplication,
            designName: cartItem.designName,
            designCode: cartItem.designCode,
            thickness: cartItem.thickness,
            finish: cartItem.finish,
            match: cartItem.match,
            dimension1: cartItem.dimension1,
            dimension2: cartItem.dimension2,
            dimension3: cartItem.dimension3,
            dimension4: cartItem.dimension4,
            description: cartItem.description,
            edgeProfileCode: cartItem.edgeProfileCode,
            edgeProfileName: cartItem.edgeProfileName,
            uomLineQuantity: cartItem.uomLineQuantity,
          };
          lines.push(line);
        }
      }

      const subContractor = {
        crmAccountId: null,
        companyName: cart?.purchasingCustomers
          ? cart.purchasingCustomers[0].siteName
          : fabricationPurchasingCustomers
          ? fabricationPurchasingCustomers[0].siteName
          : null,
        contactFirstName: null,
        contactLastName: null,
        contactEmail: null,
        contactPhone: null,
        addressLine1: null,
        addressLine2: null,
        addressLine3: null,
        city: null,
        stateProvince: null,
        postalZipCode: null,
        country: null,
      };

      let expectedOrderDate: any;
      if (quote && quote.expectedOrderDate) {
        let newDate: any = new Date((quote as any).expectedOrderDate);
        expectedOrderDate = new Date(Date.UTC(newDate.getFullYear(), newDate.getMonth(), newDate.getDate()));
      }

      let expirationDate: any;
      if (quote && quote.expirationDate) {
        expirationDate = new Date(quote.expirationDate);
      }

      const params = {
        type: 'standardCia',
        name: quote.name,
        customerName: customer.name,
        erpCustomerId: customer.erpCustomerId,
        crmAccountId: salesforceCustomerAccount ? salesforceCustomerAccount.id : null,
        cartId: cart?.id,
        expectedOrderDate: expectedOrderDate,
        category: quote.category,
        application: null,
        crmOpporunityId: null,
        lines: lines,
        subContractor: subContractor,
        location: null,
        originalCrmQuoteId: null,
        crmProjectOpportunityId: projectOpportunity ? projectOpportunity.id : null,
        customerClass: customer.class,
        expirationDate: expirationDate,
      };

      const tempQuote = await postQuote(params, dispatch);
      dispatch(setProcessingRequest(false));
      await removeCart(
        cart?.id || '',
        undefined,
        undefined,
        customer,
        currentUser.userId,
        activeProgram,
        shipToSites,
        billToSites,
        dispatch,
        availablePrograms
      );
      history.push(`/quoteConfirmation/${tempQuote.quoteId}`);
    } catch (e) {
      toast.error('An error occured while requesting the quote. Please contact Customer Care for assistance.');
    } finally {
      dispatch(hideFullscreenLoader());
    }
  };

  const initialize = useCallback(async (): Promise<any> => {
    setTomorrow(tomorrow.setDate(tomorrow.getDate() + 1));
    setMaxExpirationQuoteDate(maxExpirationQuoteDate.setDate(maxExpirationQuoteDate.getDate() + 365));
    dispatch(getStatesAndProvinces());
  }, [dispatch, maxExpirationQuoteDate, tomorrow]);

  const getCommercialQuoteCategories = (): Array<any> => {
    if (uiSettings?.commercialQuoteCategories) {
      return uiSettings.commercialQuoteCategories.map((category: string) => ({
        label: category,
        value: category.toLowerCase(),
      }));
    } else {
      return [];
    }
  };

  const getCIAQuoteCategories = (): Array<any> => {
    if (uiSettings?.ciaQuoteCategories) {
      return uiSettings.ciaQuoteCategories.map((category: string) => ({
        label: category,
        value: category.toLowerCase(),
      }));
    } else {
      return [];
    }
  };

  useEffect(() => {
    if (!hasInitialized) {
      setHasInitialized(true);
      initialize();
    }
  }, [hasInitialized, initialize]);

  const renderQuoteFormFields = (field: string, props: any) => {
    if (field === 'name') {
      return (
        <div className={'col-12 col-lg-6'}>
          <CambriaAutosuggest
            name={'name'}
            defaultValue={''}
            options={projectOpportunities && projectOpportunities.length > 0 ? projectOpportunities : []}
            shouldLockOnSelectValue={true}
            onSearch={suggestProjectOpportunity}
            onSelect={(event: any) => {
              onProjectOpportunitySelect(event, props);
            }}
            displayValue={'name'}
            label={'Name'}
            itemDisplayValue="itemLabel"
            placeholder={'Search Projects'}
            required={addQuoteFormTemplate['name'].validation.exclusiveTests.required || false}></CambriaAutosuggest>
          {projectOpErrorMessage ? (
            <section className="help-block has-warning">
              <p style={{color: 'red'}}>
                The project opportunity you selected is missing required information. In order to proceed with
                requesting a quote for this project opportunity, please contact Market Representative for assistance.
              </p>
            </section>
          ) : (
            <></>
          )}
        </div>
      );
    }
    if (field === 'expectedOrderDate') {
      return (
        <div className={'col-12 col-lg-6'}>
          <CambriaDateInput
            name={'expectedOrderDate'}
            defaultValue={''}
            label={'Expected Order Date'}
            minDate={newDate()}
            maxDate={maxExpirationQuoteDate}
            placeholder={'Select Requested Delivery Date'}
            onChange={onDateChange}
            disabled={false}
            required={
              addQuoteFormTemplate['expectedOrderDate'].validation.exclusiveTests.required || false
            }></CambriaDateInput>
          {show30daysWarning ? (
            <div className="expected-date-warning has-warning">
              <div className="help-block">
                Orders placed less than 30 days from being quoted will not qualify for a quantity incentive discount
              </div>
            </div>
          ) : (
            <div></div>
          )}
        </div>
      );
    }
    if (field === 'category') {
      return (
        <div className={'col-md-12 col-lg-6'}>
          <CambriaSelect
            formikFormProps={props}
            name={'category'}
            defaultValue={''}
            label={'Category'}
            placeholder={'Select Category'}
            items={isCommercialQuote ? getCommercialQuoteCategories() : isCIAQuote ? getCIAQuoteCategories() : []}
            infoText={'The industry of dweller type of the install location'}
            required={addQuoteFormTemplate['category'].validation.exclusiveTests.required || false}
            onChange={(change: any) => {
              updateQuoteOnChange(change, 'category');
            }}
            disableTextInput></CambriaSelect>
        </div>
      );
    }
    if (field === 'application') {
      return (
        <div className={'col-md-12 col-lg-6'}>
          <CambriaSelect
            formikFormProps={props}
            name={'application'}
            defaultValue={''}
            label={'Application'}
            placeholder={'Select Application'}
            items={applicationItems}
            infoText={'The intended use of the installation'}
            required={true}
            onChange={(change: any) => {
              updateQuoteOnChange(change, 'application');
            }}
            disableTextInput
            multiSelect></CambriaSelect>
          <Col xs={12} className="p-x-0">
            {applications.map((application: any, $index: any) => {
              return (
                <div className="application-pill" key={application.value + $index}>
                  <div className="application-pill-text">
                    {application.label}
                    <span
                      className="application-pill-clear-button"
                      onClick={() => removeSingleApplication(application)}>
                      X
                    </span>
                  </div>
                </div>
              );
            })}
          </Col>
        </div>
      );
    }
    if (field === 'subContractor') {
      return (
        <div className={'col-12'}>
          <CambriaInput
            name={'subContractor'}
            data-testid="subContractor"
            defaultValue={
              quote && quote.subContractor && quote.subContractor.companyName ? quote.subContractor.companyName : ''
            }
            label={'Subcontractor'}
            placeholder={'Subcontractor'}
            type={'text'}
            infoText={'The A&D Firm, Millwork, General Contract, or End User'}
            onChange={(change: any) => {
              updateQuoteOnChange(change.target.value, 'subContractor', 'companyName');
            }}
            required={addQuoteFormTemplate['subContractor'].validation.exclusiveTests.required || false}></CambriaInput>
        </div>
      );
    }
    if (field === 'location') {
      return (
        <div className={'col-12'}>
          <CambriaInput
            name={'location'}
            data-testid="location"
            defaultValue={''}
            label={'Location'}
            placeholder={'Location'}
            type={'text'}
            infoText={'The business name, city, state, and zip of the install location.'}
            onChange={(change: any) => {
              updateQuoteOnChange(change.target.value, 'location', 'businessName');
            }}
            required={addQuoteFormTemplate['location'].validation.exclusiveTests.required || false}></CambriaInput>
        </div>
      );
    }
    if (field === 'city') {
      return (
        <div className={'col-md-12 col-lg-4'}>
          <CambriaInput
            name={'city'}
            data-testid="city"
            defaultValue={''}
            placeholder={'City'}
            type={'text'}
            onChange={(change: any) => {
              onCityOrStateChange(change.target.value, props, 'city');
            }}
            required={addQuoteFormTemplate['city'].validation.exclusiveTests.required || false}></CambriaInput>
        </div>
      );
    }
    if (field === 'state') {
      return (
        <div className={'col-md-12 col-lg-4 quote-state-select'}>
          <CambriaSelect
            name={'state'}
            defaultValue={''}
            placeholder={'Select State / Province'}
            items={statesAndProvidences ? statesAndProvidences : []}
            displayValue={'stateName'}
            required={addQuoteFormTemplate['state'].validation.exclusiveTests.required || false}
            formikFormProps={props}
            onChange={(change: any) => {
              onCityOrStateChange(change, props, 'stateProvince');
            }}></CambriaSelect>
        </div>
      );
    }
    if (field === 'postalCode') {
      return (
        <div className={'col-md-12 col-lg-4'}>
          <CambriaInput
            name={'postalCode'}
            data-testid="postalCode"
            defaultValue={''}
            placeholder={'Postal Zip Code'}
            type="text"
            disabled={disableZipCodeField}
            onChange={(change: any) => {
              updateQuoteOnChange(change.target.value, 'location', 'postalZipCode');
            }}
            required={addQuoteFormTemplate['postalCode'].validation.exclusiveTests.required || false}></CambriaInput>
        </div>
      );
    }
    if (field === 'expirationQuoteDate') {
      return hasPermission('urn:csa:commerceui:setQuoteExpirationDate', userActions) ? (
        <div className={'col-12 col-lg-6'}>
          <CambriaDateInput
            name={'expirationQuoteDate'}
            defaultValue={''}
            label={'Expiration Quote Date'}
            minDate={tomorrow}
            maxDate={maxExpirationQuoteDate}
            placeholder={'Expiration Quote Date'}
            onChange={onExpirationDateChange}
            disabled={false}
            required={false}></CambriaDateInput>
        </div>
      ) : (
        <div></div>
      );
    }
  };

  if (isCommercialQuote) {
    return (
      <>
        <Formik
          initialValues={quoteInitialValues}
          validationSchema={Yup.object(quoteValidationSchema)}
          validateOnMount
          validateOnChange
          validateOnBlur
          innerRef={(ref: any) => {}}
          onSubmit={(values) => {
            submitCommercialQuote();
          }}>
          {(props) => {
            return (
              <CambriaModal
                show={show}
                toggleShow={toggleShow}
                isValid={!(applications.length > 0 && props.dirty && props.isValid)}
                disableSubmitBtn={disableSubmit}
                cancelButton={modalOptions.cancelButtonText}
                confirmButton={modalOptions.confirmButtonText}
                heading={modalOptions.heading}
                formName={modalOptions.formName}
                onCancel={() => {}}>
                <AddQuoteModalPage>
                  <Form id={modalOptions.formName} onSubmit={props.handleSubmit} onReset={props.handleReset} noValidate>
                    <Container className="p-0">
                      <Row>
                        {renderQuoteFormFields('name', props)}
                        {renderQuoteFormFields('expectedOrderDate', props)}
                        {renderQuoteFormFields('category', props)}
                        {renderQuoteFormFields('application', props)}
                        {renderQuoteFormFields('subContractor', props)}
                        {renderQuoteFormFields('location', props)}
                        {renderQuoteFormFields('city', props)}
                        {renderQuoteFormFields('state', props)}
                        {renderQuoteFormFields('postalCode', props)}
                        {renderQuoteFormFields('expirationQuoteDate', props)}
                      </Row>
                    </Container>
                  </Form>
                </AddQuoteModalPage>
              </CambriaModal>
            );
          }}
        </Formik>
        <RequestedDateAcknowledgePopup requestedDate={quote?.expectedOrderDate} />
      </>
    );
  }

  if (isCIAQuote) {
    return (
      <Formik
        initialValues={quoteInitialValues}
        validationSchema={Yup.object(quoteValidationSchema)}
        validateOnMount
        validateOnChange
        validateOnBlur
        innerRef={(ref: any) => {}}
        onSubmit={(values) => {
          submitCIAQuote();
        }}>
        {(props) => {
          return (
            <CambriaModal
              show={show}
              toggleShow={toggleShow}
              isValid={!(props.dirty && props.isValid)}
              disableSubmitBtn={disableSubmit}
              cancelButton={modalOptions.cancelButtonText}
              confirmButton={modalOptions.confirmButtonText}
              heading={modalOptions.heading}
              formName={modalOptions.formName}
              onCancel={() => {}}>
              <AddQuoteModalPage>
                <Form id={modalOptions.formName} onSubmit={props.handleSubmit} onReset={props.handleReset} noValidate>
                  <Container className="p-0">
                    <Row>
                      {renderQuoteFormFields('name', props)}
                      {renderQuoteFormFields('expectedOrderDate', props)}
                      {cart?.orderDetails[0]?.jobType?.includes('Commercial')
                        ? renderQuoteFormFields('category', props)
                        : renderQuoteFormFields('', props)}
                      {renderQuoteFormFields('expirationQuoteDate', props)}
                    </Row>
                  </Container>
                </Form>
              </AddQuoteModalPage>
            </CambriaModal>
          );
        }}
      </Formik>
    );
  }

  return (
    <CambriaModal
      show={show}
      toggleShow={toggleShow}
      isValid={true}
      disableSubmitBtn={disableSubmit}
      cancelButton={modalOptions.cancelButtonText}
      confirmButton={modalOptions.confirmButtonText}
      heading={modalOptions.heading}
      formName={modalOptions.formName}
      onCancel={() => {}}></CambriaModal>
  );
};

export default AddQuoteModal;
