import React from 'react';
import {IPaymentInfoCardData} from '../../../../../../features/order/IOrderState';
import {formatOrderCurrency} from '../../../../../../Framework/Services/formatCurrency';

const PaymentInfoCard = ({
  paymentInfoCardData,
  voucherCodes = [],
}: {
  paymentInfoCardData: IPaymentInfoCardData;
  voucherCodes?: string[];
}) => {
  return (
    <>
      <span className="address-header">PAYMENT INFO</span>
      <div className="order-detail-card payment-card">
        <div className="payment-terms">
          <span>Pay by {paymentInfoCardData.paymentType ? paymentInfoCardData.paymentType : 'My Terms'}</span>
          <br />
          <span className="payment-terms-text">({paymentInfoCardData.paymentTerms})</span>
        </div>
        <div className="order-total-container">
          <span className="order-total-text">
            ${formatOrderCurrency(paymentInfoCardData.totalAmount)} {paymentInfoCardData.currencyCode}
          </span>
        </div>
        {voucherCodes.length > 0 ? (
          <div className="voucher-code-section d-flex flex-column align-items-center">
            <span className="voucher-code-title">Voucher Codes</span>
            {voucherCodes.map((code: string, index: number) => {
              return <div key={index}>{code}</div>;
            })}
          </div>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default PaymentInfoCard;
