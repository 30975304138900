import {Form} from 'react-bootstrap';
import React, {useState} from 'react';
import CambriaModal from '../../../../Framework/Components/CambriaModal';
import {useAppDispatch, useTypedSelector} from '../../../../hooks/store';
import {selectCurrentUser} from '../../../../features/auth/slice/authentication.slice';
import {baseApiCallWithReauth} from '../../../../Framework/api-utils/api-utils';
import COMMERCE_CORE_CONSTANTS from '../../../constants';
import {setCampaignMessages} from '../../../../features/notification/slice/notification.slice';

const CampaignMessage = ({
  title,
  body,
  campaignId,
  isLastMessageToBeShown,
}: {
  title: string;
  body: string;
  campaignId: number;
  isLastMessageToBeShown: boolean;
}) => {
  const dispatch = useAppDispatch();

  const currentUser: any = useTypedSelector(selectCurrentUser);

  let [showMessage, setShowMessage] = useState<boolean>(true);

  const toggleShow = () => setShowMessage((p) => !p);

  const confirmUserViewedMessage = async (isLastMessage: boolean, userId?: string, campaignId?: number) => {
    if (!userId || !campaignId) {
    } else {
      try {
        return await baseApiCallWithReauth(
          'POST',
          `${COMMERCE_CORE_CONSTANTS.API_SERVICES.NOTIFICATION.campaigns}/${campaignId}/userevents/${userId}`,
          null,
          true
        );
      } catch (error) {
        console.log(error);
      } finally {
        toggleShow();
      }
    }
    if (isLastMessage) {
      dispatch(setCampaignMessages([]));
    }
  };

  return (
    <CambriaModal
      show={showMessage}
      confirmButton={'Ok'}
      heading={title}
      toggleShow={toggleShow}
      formName={`${campaignId}-campaign-message`}
      hideCancelButton={true}
      hideExitIcon={true}
      disableClickOutsideToClose={true}
      disableBackdrop={true}>
      <Form
        id={`${campaignId}-campaign-message`}
        onSubmit={(e) => {
          e.preventDefault();
          confirmUserViewedMessage(isLastMessageToBeShown, currentUser?.userId, campaignId);
        }}>
        {body}
      </Form>
    </CambriaModal>
  );
};

export default CampaignMessage;
