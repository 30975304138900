import styled from 'styled-components/macro';
import {$brandprimary, $fontsizebase, $newdanger} from '../../../../../Styled/variables';

export const StandardPage = styled.div`
  margin-bottom: 50px;

  .collection-header {
    text-transform: capitalize;
    color: #9a9a9a;
  }

  .account .item-number {
    font-style: italic;
    color: #9a9a9a;
    font-size: 12px;
    font-weight: 400;
  }

  .product-group {
    float: left;
    padding: 0px;
  }

  .choose-another-design {
    padding: 0px;
  }

  .product-header {
    vertical-align: top;

    h2.collection-title {
      padding-left: 12px;
      border-left: 1px solid #4c4c4c;
      float: left;
      margin-left: 10px;
      line-height: 36px;
      color: #9a9a9a;
      font-size: 24px;
    }
  }

  .attribute-select-errorMessaging {
    position: relative;
    top: -20px;
    font-size: 12px;
    color: #c40f0f;
  }

  .product-details-item-number-text-container {
    white-space: nowrap;
    float: left;
    margin-top: 11px;
    margin-left: -30px;
    padding-left: 45px;
  }

  .product-details-page-quantity-text {
    font-weight: bolder;
    color: #000;
  }

  .product-details-page-counter-container {
    margin-left: -6px;

    @media (max-width: 1300px) {
      margin-left: -10px;
    }

    @media (max-width: 1200px) {
      margin-left: -15px;
      padding-left: 0;
      padding-right: 0;
    }
  }

  .design-header {
    font-size: 36px;
    color: #4b4b4b;
    margin: 0;
    word-wrap: break-word;
    float: left;
    text-align: center;
    font-weight: 300;
    display: flex;

    @media (max-width: 992px) {
      text-align: left;
    }

    .new-design {
      color: ${$brandprimary};
      font-size: ${$fontsizebase};
      font-weight: bold;
      vertical-align: top;
    }
  }

  .product-details-page-design-series-container {
    display: flex;

    .luxury-series,
    .signature-series {
      height: 55px;
      margin-left: -10px;
      margin-top: 15px;

      @media (max-width: 992px) {
        height: 35px;
      }
    }

    .grandeur-series {
      height: 85px;
      margin-left: -25px;
      margin-top: -5px;

      @media (max-width: 992px) {
        height: 60px;
      }
    }
  }

  .patent-information {
    margin-top: 7px;
    line-height: 14px;
    text-align: right;
  }

  .in-stock-container {
    display: flex;
    justify-content: center;

    .in-stock-circle-container {
      width: 30px;
      float: left;

      .in-stock-circle {
        background-color: green;
        border-radius: 50%;
        height: 20px;
        width: 20px;
      }
    }

    .in-stock-message {
      color: green;
      float: left;
      text-align: left;
    }
  }

  .in-stock-over-20-container {
    display: flex;
    justify-content: center;

    .in-stock-over-20-circle-container {
      width: 30px;
      float: left;

      .in-stock-over-20-circle {
        background-color: red;
        border-radius: 50%;
        height: 20px;
        width: 20px;
      }
    }

    .in-stock-over-20-message {
      color: red;
      float: left;
      text-align: left;
    }

    .in-stock-over-20-description {
      text-align: center;
    }
  }

  .limited-stock-container,
  .no-stock-container {
    padding: 0 10px 0 10px;

    .limited-stock-title,
    .no-stock-title {
      display: flex;
      justify-content: center;

      .limited-stock-circle-container,
      .no-stock-circle-container {
        width: 30px;
        float: left;

        .limited-stock-circle,
        .no-stock-circle {
          background-color: red;
          border-radius: 50%;
          height: 20px;
          width: 20px;
        }
      }

      .limited-stock-message,
      .no-stock-message {
        color: red;
        @media (max-width: 1199px) and (min-width: 961px) {
          width: 200px;
        }
      }

      .limited-stock-description,
      .no-stock-description {
        text-align: center;
      }
    }
  }

  .placeholder-overflow-class {
    overflow: hidden;
    margin-bottom: 40px;
  }

  .counter-placeholder {
    margin-top: 9px;
    float: right;
  }

  .save-for-later-button-placeholder,
  .add-to-cart-button-placeholder {
    width: 100%;
    display: block;
    text-align: center;
  }

  .pdp-standard-content-loader {
    width: inherit;
  }

  .product-details-image-zoom-component-container {
    overflow: hidden;
  }

  .bundle-program-qty-alert {
    text-align: center;
    font-style: italic;
    padding-top: 8px;
    color: rgba(1, 1, 1, 0.5);
    white-space: pre-line;
  }

  .no-bundle-rule {
    color: ${$newdanger};
    opacity: 0.5;
  }

  .quantity-row {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;

    .cambria-counter {
      margin-bottom: 0;
      max-width: 100px;
    }
  }
`;
