import styled from 'styled-components/macro';
import {$newprimary, $newwhite, $screenmd} from '../../../../Styled/variables';

export const LoginFormContainer = styled.form`
  max-width: 560px;
  margin: 0 auto;
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 19px;

  .title {
    font-family: Montserrat, sans-serif;
    font-weight: 300;
    font-size: 28px;
    line-height: 44px;
    text-align: center;
    color: ${$newwhite};
    margin-bottom: 45px;

    @media (max-width: ${$screenmd}) {
      margin-bottom: 15px;
    }
  }

  .error-container {
    max-width: 560px;
    margin: 0 auto;
    border: 1px solid #c40f0f;
    background: #f4eced;
    border-radius: 3px;
    color: #4c4c4c;
    margin-bottom: 30px;
    padding: 30px;

    p {
      margin: 0 5px;
      font-family: Montserrat, sans-serif;
      font-size: 14px;
      line-height: 20px;
      text-align: left;
    }
  }

  .form-group {
    margin-bottom: 15px;

    input {
      padding-right: 45px;
      font-weight: 400;
      box-shadow: none;
      display: block;
      width: 100%;
      height: 36px;
      padding: 6px 12px;
      font-size: 16px;
      line-height: 1.428571429;
      color: #555;
      background-color: ${$newwhite};
      background-image: none;
      border: 1px solid #ccc;
      border-radius: 0;
      transition:
        border-color ease-in-out 0.15s,
        box-shadow ease-in-out 0.15s;
    }
  }

  .privacy-terms {
    font-size: 14px;
    font-weight: 400;
    color: ${$newwhite};
    margin-bottom: 30px;

    a {
      color: ${$newwhite};
      text-decoration: underline;
    }
  }

  .submit-button {
    width: auto;
    padding: 10px 25px;
    background: ${$newwhite};
    color: ${$newprimary};
    border-radius: 3px;
    border: 1px solid ${$newwhite};
    box-shadow: 0 1px 2px rgb(0 0 0 / 20%);
    font-family: Montserrat, sans-serif;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    text-transform: uppercase;
    vertical-align: middle;
    cursor: pointer;
  }

  .checkbox {
    color: ${$newwhite};
    margin: 30px 0px;
    cursor: pointer;

    label {
      color: ${$newwhite};
      font-size: 14px;
      font-weight: 700;
      line-height: 22px;
      padding-left: 32px;

      &:before {
        content: '';
        display: inline-block;
        position: absolute;
        left: 0px;
        box-shadow: none;
        width: 22px;
        height: 22px;
        color: rgb(0, 0, 0);
        text-align: center;
        line-height: 22px;
        background-color: transparent;
        border-color: ${$newwhite};
        border-width: 2px;
        border-style: solid;
        color: ${$newwhite};
        border-image: initial;
      }
    }

    input {
      display: none;
      position: absolute;
      margin-left: -20px;
    }
  }

  .forgot-password {
    margin: 30px 0 0 0;
    height: 20px;

    a {
      color: ${$newwhite};
      text-decoration: underline;
      padding: 0;
      font-weight: 400;
      font-size: 14px;
    }
  }
`;
