import {baseApiCallWithReauth} from '../../../Framework/api-utils/api-utils';
import COMMERCE_CORE_CONSTANTS from '../../../Core/constants';
import qs from 'qs';

export const getShippingSelectionsRequest = async (endpoint: string, params: any) => {
  try {
    return await baseApiCallWithReauth('GET', endpoint, qs.stringify(params));
  } catch (error: any) {
    throw error;
  }
};

export const getHolidayRequest = async (warehouseCode: string | null, countryCode: string) => {
  try {
    return await baseApiCallWithReauth(
      'GET',
      COMMERCE_CORE_CONSTANTS.API_SERVICES.SHIPPING.holiday,
      qs.stringify({warehouseCode, countryCode})
    );
  } catch (error: any) {
    throw error;
  }
};

export const getSlabOrderPreferencesRequest = async (id: string) => {
  try {
    return await baseApiCallWithReauth(
      'GET',
      `${COMMERCE_CORE_CONSTANTS.API_SERVICES.CUSTOMER.slabOrderPreferences}/${id}`,
      null
    );
  } catch (error: any) {
    throw error;
  }
};

export const putSlabOrderPreferencesRequest = async (params: any, erpCustomerId: string) => {
  try {
    return await baseApiCallWithReauth(
      'PUT',
      `${COMMERCE_CORE_CONSTANTS.API_SERVICES.CUSTOMER.slabOrderPreferences}/${erpCustomerId}`,
      params
    );
  } catch (error: any) {
    throw error;
  }
};

export const getSamplesOrderPreferencesRequest = async (id: string) => {
  try {
    return await baseApiCallWithReauth(
      'GET',
      `${COMMERCE_CORE_CONSTANTS.API_SERVICES.CUSTOMER.samplesOrderPreferences}/${id}`,
      null
    );
  } catch (error: any) {
    throw error;
  }
};

export const putSamplesOrderPreferences = async (params: any, erpCustomerId: string) => {
  try {
    return await baseApiCallWithReauth(
      'PUT',
      `${COMMERCE_CORE_CONSTANTS.API_SERVICES.CUSTOMER.samplesOrderPreferences}/${erpCustomerId}`,
      params
    );
  } catch (error: any) {
    throw error;
  }
};

export const postSamplesOrderPreferences = async (params: any, erpCustomerId: string) => {
  params.erpCustomerId = erpCustomerId;

  return await baseApiCallWithReauth(
    'PUT',
    COMMERCE_CORE_CONSTANTS.API_SERVICES.CUSTOMER.samplesOrderPreferences,
    params
  );
};

export const postCartOrderDetailsRequest = async (params: any, nonJSONResponse: boolean) => {
  try {
    return await baseApiCallWithReauth(
      'POST',
      COMMERCE_CORE_CONSTANTS.API_SERVICES.ORDER.cartOrderDetails,
      JSON.stringify(params),
      nonJSONResponse
    );
  } catch (error: any) {
    throw error;
  }
};

export const deleteCartShippingMethodsRequest = async (
  selectedShippingMethodId: number,
  nonJSONResponse: boolean | undefined,
  getAll: boolean
) => {
  try {
    return await baseApiCallWithReauth(
      'DELETE',
      `${COMMERCE_CORE_CONSTANTS.API_SERVICES.SHIPPING.cartShippingMethods}/${selectedShippingMethodId}`,
      nonJSONResponse,
      getAll
    );
  } catch (error: any) {
    throw error;
  }
};

export const postCartShippingMethodsRequest = async (params: any, nonJSONResponse: boolean) => {
  try {
    return await baseApiCallWithReauth(
      'POST',
      COMMERCE_CORE_CONSTANTS.API_SERVICES.SHIPPING.cartShippingMethods,
      JSON.stringify(params),
      nonJSONResponse
    );
  } catch (error: any) {
    throw error;
  }
};

export const getShippingZoneRequest = async (zipCode: string, warehouseCode?: string) => {
  if (warehouseCode) {
    return await baseApiCallWithReauth(
      'GET',
      COMMERCE_CORE_CONSTANTS.API_SERVICES.SHIPPING.shippingZone,
      qs.stringify({zipCode, warehouseCode})
    );
  } else {
    throw new Error('No warehouse code');
  }
};

export const getShippingZonePricingRequest = async (zoneName: string, warehouseCode?: string) => {
  if (warehouseCode) {
    return await baseApiCallWithReauth(
      'GET',
      COMMERCE_CORE_CONSTANTS.API_SERVICES.SHIPPING.shippingZonePricing,
      qs.stringify({zoneName, warehouseCode})
    );
  } else {
    throw new Error('No warehouse code');
  }
};
