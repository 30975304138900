import styled from 'styled-components/macro';
import {$gray78, $screenmd} from '../../../../../../Styled/variables';

export const PurchasingCustomerBlock = styled.div`
  position: relative;
  background: rgba(0, 0, 0, 0.04);
  border-radius: 3px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  margin-bottom: 20px;
  border: 1px solid ${$gray78};

  .purchasing-customer-actions {
    position: absolute;
    top: 5px;
    right: 10px;
    font-size: 14px;

    em {
      padding: 0 2px;
      cursor: pointer;
      font-size: 14px !important;

      &:hover {
        opacity: 0.6;
      }
    }
  }

  @media screen and (max-width: ${$screenmd}) {
    .customer-info {
      text-align: center;
      width: 100%;
    }

    .customer-info-overflow {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;
