export const jobTypes = [
  {
    displayName: 'Commercial-New Construction',
    code: 'Commercial-New',
  },
  {
    displayName: 'Commercial-Remodel',
    code: 'Commercial-Remodel',
  },
  {
    displayName: 'Residential-New Construction',
    code: 'Residential-New',
  },
  {
    displayName: 'Residential-Remodel',
    code: 'Residential-Remodel',
  },
  {
    displayName: 'Stock',
    code: 'Stock',
  },
  {
    displayName: 'Residential-Swap a Top',
    code: 'Residential-Swap a Top',
  },
];

export const fabricationJobTypes = [
  {
    displayName: 'Commercial-New Construction',
    code: 'Commercial-New',
  },
  {
    displayName: 'Commercial-Remodel',
    code: 'Commercial-Remodel',
  },
  {
    displayName: 'Commercial-Swap a Top',
    code: 'Commercial-Swap a Top',
  },
  {
    displayName: 'Residential-New Construction',
    code: 'Residential-New',
  },
  {
    displayName: 'Residential-Remodel',
    code: 'Residential-Remodel',
  },
  {
    displayName: 'Residential-Swap a Top',
    code: 'Residential-Swap a Top',
  },
];
