import React, {useEffect} from 'react';
import {useLocation} from 'react-router-dom';

const ScrollToTop = ({children}: {children: any}) => {
  const location = useLocation();

  useEffect(() => {
    setTimeout(() => {
      const rootElement = document.getElementById('root');
      window.scrollTo({
        top: rootElement ? rootElement.getBoundingClientRect().top + window.scrollY : 0,
      });
    }, 100);
  }, [location.pathname]);

  return <>{children}</>;
};

export default ScrollToTop;
