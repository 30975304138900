import {CustomerFullProfile, Site} from '../../customer/ICustomerState';
import {getShipFromWarehouseId} from '../../warehouse/service/warehouse.service';
import COMMERCE_CORE_CONSTANTS from '../../../Core/constants';
import {CartItem} from '../../cart/ICartState';
import {callback} from '../../callbacks/service/callbacks.service';
import {IGetProductGroupItemsByCodeRequest} from '../../product/IProductState';
import {guid} from '../../../Framework/Services/guid.service';
import {IProductCatalogCartItemRequest, Product, ProductCatalogCartItemUpdateRequest} from '../IProductCatalogState';
import qs from 'qs';
import {
  getItemAvailabilityRequest,
  getProductsInCartRequest,
  getSavedItemsRequest,
} from '../../product/controller/product.controller';
import {
  deleteCartItemsByItemNumberRequest,
  deleteCartItemsWithParamsRequest,
  genericServiceGetRequest,
  getBatchCartItemsAddSizeRequest,
  getItemDetailsBySkuRequest,
  getProductGroupsRequest,
  getProductsByIdRequest,
  postBatchCartItemsAddSizeRequest,
  postCartItemsRequest,
  postProductCartItemsRequest,
  putCartItemsRequest,
} from '../controller/productCatalog.controller';

export const getSelectAttributes = (attributes: any[]): any[] => {
  const attributesOut = [];

  if (attributes) {
    let tempAttributes = JSON.parse(JSON.stringify(attributes));
    tempAttributes = sortAttributes(tempAttributes);
    for (const prodAttr of tempAttributes) {
      if (prodAttr && prodAttr.usageType === 'select') {
        attributesOut.push(prodAttr);
      }
    }
  }

  return attributesOut;
};

export const isCustomStoreItem = async (sku: string) => {
  const item = await getItemDetailsBySkuRequest(sku);
  return item.categories[0].subType === 'Custom';
};

const sortAttributes = (attributes: any[]) => {
  return attributes.sort(function (a, b) {
    if (a.sequence < b.sequence) {
      return -1;
    }
    if (a.sequence > b.sequence) {
      return 1;
    }
    return 0;
  });
};

export const calculateTileSquareFootage = (unitSize: number, quantity: number): number => {
  const totalTileSquareFootage = quantity * unitSize;
  return Math.ceil(totalTileSquareFootage);
};

export const calculateTileUnitSize = (dimensions: string): number => {
  const dimensionsArray = dimensions.split('x');
  return (parseInt(dimensionsArray[0], 10) / 12) * (parseInt(dimensionsArray[1], 10) / 12);
};

export const getStoreSizeSort = (attributeSelections: Array<any>): any => {
  const isChildrenSize = attributeSelections.every(
    (selection) => typeof selection.value === 'string' && selection.value.trim().match(/^\d+M$/gi)
  );
  if (isChildrenSize) {
    return (selection: any) => parseInt(selection.value.slice(0, -1), 10);
  }

  let matchedAdultSize = 0;
  const adultSizes = ['XS', 'S', 'SM', 'S/M', 'M', 'MD', 'M/L', 'L', 'LG', 'L/XL', 'XL', '1X', '2X', '3X'];
  attributeSelections.forEach((selection) => adultSizes.includes(selection.value) && matchedAdultSize++);
  if (matchedAdultSize > 1) {
    return (selection: any) => {
      const rank = adultSizes.indexOf(selection.value);
      return rank < 0 ? 9999 : rank;
    };
  }
};

export const getProductsInCart = async (cartId: string, customer: CustomerFullProfile, shipToSites: Site[]) => {
  const classGroups: string[] = customer.classGroups;
  let params = new URLSearchParams();
  params.append('cartId', cartId);
  params.append('erpCustomerId', customer.erpCustomerId.toString());
  params.append('customerClass', customer.class);
  classGroups.forEach((item) => {
    params.append('customerClassGroups', item);
  });

  let slabWarehouse = getShipFromWarehouseId('Slab', customer, shipToSites);
  let tileWarehouse = getShipFromWarehouseId('Tile', customer, shipToSites);
  let samplesWarehouse = getShipFromWarehouseId('Samples', customer, shipToSites);
  let storeWarehouse = getShipFromWarehouseId('Store', customer, shipToSites);

  params.append('slabWarehouseCode', slabWarehouse);
  params.append('tileWarehouseCode', tileWarehouse);
  params.append('samplesWarehouseCode', samplesWarehouse);
  params.append('storeWarehouseCode', storeWarehouse);
  return getProductsInCartRequest(params);
};

export const removeCartItem = async (cartItem: CartItem, cartId: string, dispatch: any) => {
  try {
    const callbackId = await deleteCartItemsWithParamsRequest(cartItem.itemNumber, cartId);
    let stringId = await callbackId.text();
    while (stringId.includes('"')) {
      stringId = stringId.replace('"', '');
    }

    return await callback(stringId, 90, dispatch);
  } catch (error: any) {
    throw error;
  }
};

export const deleteCartItem = async (itemNumber: string, cartId: string, cartItemId: number, dispatch: any) => {
  try {
    let callbackId = await deleteCartItemsByItemNumberRequest(itemNumber, cartId, cartItemId);
    return await callback(callbackId, 90, dispatch);
  } catch (error: any) {
    throw error;
  }
};

export const getSavedItems = async (currentCustomer: CustomerFullProfile, userId: string): Promise<any> => {
  const customer = currentCustomer;

  let params = new URLSearchParams();
  params.append('erpCustomerId', customer.erpCustomerId.toString());
  params.append('userId', userId);
  params.append('pager.limit', '100');
  params.append('pager.sort', '-id');
  return await getSavedItemsRequest(params);
};

export const getItemAvailability = async (params: any) => {
  let data = qs.stringify(params);
  return await getItemAvailabilityRequest(data);
};

export const getProduct = async (productId: string, customer: CustomerFullProfile, shipToSites: Site[]) => {
  const params: IGetProductGroupItemsByCodeRequest = {
    erpCustomerId: customer.erpCustomerId.toString(),
    customerClass: customer.class,
    customerClassGroups: customer.classGroups,
    slabWarehouseCode: '',
    tileWarehouseCode: '',
    samplesWarehouseCode: '',
    storeWarehouseCode: '',
  };
  params.slabWarehouseCode = getShipFromWarehouseId('Slab', customer, shipToSites);
  params.tileWarehouseCode = getShipFromWarehouseId('Tile', customer, shipToSites);
  params.samplesWarehouseCode = getShipFromWarehouseId('Samples', customer, shipToSites);
  params.storeWarehouseCode = getShipFromWarehouseId('Store', customer, shipToSites);

  const product = await getProductsByIdRequest(productId, params);
  if (product.items && product.items.length > 0) {
    return product;
  } else {
    return await getProductGroupItemsByCode(product.productGroupCode, params);
  }
};

export const pushViewItemListInformationToDataLayer = (products: Product[]) => {
  const dataLayer = (window as any).dataLayer || [];
  dataLayer.push({
    ecommerce: null,
  });
  dataLayer.push({
    event: 'view_item_list',
    ecommerce: {
      items: products.map((p) => ({
        item_name: p.displayName,
        item_id: p.id,
        item_brand: 'Cambria',
        item_category: p.productType,
      })),
    },
  });
};

export const getProducts = async (
  data: URLSearchParams,
  customer: any,
  currentProgram: any,
  productType: string,
  warehouseCode?: any,
  shipToSites?: any,
  itemNumbers?: string[]
) => {
  let endpoint: string = COMMERCE_CORE_CONSTANTS.API_SERVICES.PRODUCT.products;

  if (productType !== 'All') {
    data.append('warehouseCode', warehouseCode.toString());
    switch (productType) {
      case 'Slab':
        endpoint = COMMERCE_CORE_CONSTANTS.API_SERVICES.PRODUCT.slabs;
        break;
      case 'Tile':
        endpoint = COMMERCE_CORE_CONSTANTS.API_SERVICES.PRODUCT.tiles;
        break;
      case 'Store':
        endpoint = COMMERCE_CORE_CONSTANTS.API_SERVICES.PRODUCT.store;
        break;
      case 'Samples':
        endpoint = COMMERCE_CORE_CONSTANTS.API_SERVICES.PRODUCT.samples;
        break;
    }
  } else {
    data.append('slabWarehouseCode', getShipFromWarehouseId('Slab', customer, shipToSites));
    data.append('tileWarehouseCode', getShipFromWarehouseId('Tile', customer, shipToSites));
    data.append('samplesWarehouseCode', getShipFromWarehouseId('Samples', customer, shipToSites));
    data.append('storeWarehouseCode', getShipFromWarehouseId('Store', customer, shipToSites));
  }

  data.append('productType', productType.toString());
  data.append('erpCustomerId', customer.erpCustomerId.toString());
  data.append('customerClass', customer.class.toString());
  for (const classGroup of customer.classGroups) {
    data.append('customerClassGroups', classGroup.toString());
  }
  data.append('productActiveLocations', 'Commerce');
  data.append('programCodes', currentProgram.code.toString());

  if (itemNumbers) {
    for (const itemNumber of itemNumbers) {
      data.append('itemNumbers', itemNumber);
    }
  }

  return await genericServiceGetRequest(endpoint, data);
};

export const getProductGroupByCode = async (code: string): Promise<any> => {
  const endpoint = `${COMMERCE_CORE_CONSTANTS.API_SERVICES.PRODUCT.productGroups}/${code}`;
  return await getProductGroupsRequest(endpoint);
};

export const getProductGroupItemsByCode = async (code: string, params: IGetProductGroupItemsByCodeRequest) => {
  const data = qs.stringify(params);
  const endpoint = `${COMMERCE_CORE_CONSTANTS.API_SERVICES.PRODUCT.productGroups}/${code}/items`;
  return await getProductGroupsRequest(endpoint, data);
};

export const productAddCartItem = async (
  cartItem: any, // CartItem or IProductCatalogCartItemRequest,
  cartId: string,
  shipFromWarehouseId: string,
  siteUseId: number,
  dispatch: any
): Promise<any> => {
  const callbackExternalIdGuid = await guid();

  let request: any = {
    cartId: cartId,
    itemNumber: cartItem.itemNumber,
    quantity: cartItem.quantity,
    shipFromWarehouseId: shipFromWarehouseId,
    cartItemType: cartItem.cartItemType,
    requireOrderingInQuantitiesOfTwo: cartItem.requireOrderingInQuantitiesOfTwo,
    currency: cartItem.currency,
    operatingUnitCode: cartItem.operatingUnitCode,
    priceListCode: cartItem.priceListCode,
    programCode: cartItem.programCode,
    programName: cartItem.programName,
    siteUseId: siteUseId,
    callbackExternalId: callbackExternalIdGuid,
  };
  if (cartItem.uomQuantity) {
    request = {...request, uomQuantity: cartItem.uomQuantity};
  }

  if (cartItem.parentCartItemId) {
    request = {...request, parentCartItemId: cartItem.parentCartItemId};
  }
  try {
    const callbackId = await postCartItemsRequest(request);

    let stringId = await callbackId.text();

    while (stringId.includes('"')) {
      stringId = stringId.replace('"', '');
    }
    return await callback(stringId, 60, dispatch, {
      areMultipleCallbacks: true,
      stopOnFailure: false,
    });
  } catch (error: any) {
    throw error;
  }
};

export const productAddBatchCartItems = async (
  cartItems: CartItem[],
  cartId: string,
  siteUseId: number,
  dispatch: any
): Promise<any> => {
  const isTreeStructure = cartItems.some((i) => i.parentCartItemId);
  if (isTreeStructure) {
    // Note: BE supports items with a tree structure (parent-child relations).
    // There was a requirement for the relation between a parent and all of his children including all dept levels to be sent in one batch.
    // At the moment of change, this method is used for quick products (linear structure) only.
    return Promise.reject(
      'ProductService.addBatchCartItems method does not support items with tree structure (parent-child relations)!'
    );
  }

  let result: any;
  const request = [];

  const cartItemsBatchSize = await getBatchCartItemsAddSizeRequest();

  const callbackExternalId = await guid();

  for (const cartItem of cartItems) {
    let itemRequest: any = {};
    itemRequest['cartId'] = cartId;
    itemRequest['itemNumber'] = cartItem.itemNumber;
    itemRequest['quantity'] = (cartItem as any).quantity;
    itemRequest['shipFromWarehouseId'] = cartItem.shipFromWarehouseId;
    itemRequest['cartItemType'] = cartItem.cartItemType;
    itemRequest['currency'] = cartItem.currency;
    itemRequest['operatingUnitCode'] = cartItem.operatingUnitCode;
    itemRequest['priceListCode'] = cartItem.priceListCode;
    itemRequest['programCode'] = cartItem.programCode;
    itemRequest['programName'] = cartItem.programName;
    itemRequest['siteUseId'] = siteUseId;
    itemRequest['callbackExternalId'] = callbackExternalId;

    request.push(itemRequest);
    // send in batches
    if (request.length === cartItemsBatchSize) {
      const callbackId = await postBatchCartItemsAddSizeRequest(request);
      let stringId = await callbackId.text();

      while (stringId.includes('"')) {
        stringId = stringId.replace('"', '');
      }

      request.splice(0, request.length);

      result = callback(stringId, 60, dispatch, {
        areMultipleCallbacks: true,
        stopOnFailure: false,
      });
    }
  }

  if (request.length > 0) {
    const callbackId = await postBatchCartItemsAddSizeRequest(request);
    let stringId = await callbackId.text();

    while (stringId.includes('"')) {
      stringId = stringId.replace('"', '');
    }

    const callbackParams = {
      areMultipleCallbacks: true,
      stopOnFailure: false,
    };
    result = callback(stringId, 60, dispatch, callbackParams);
  }

  return result;
};

export const updateCartItem = async (
  cartItem: ProductCatalogCartItemUpdateRequest,
  cartItemId: number,
  dispatch: any
) => {
  const callbackExternalIdGuid = await guid();
  let params = cartItem;
  params = {...params, callbackExternalId: callbackExternalIdGuid, cartItemId: cartItemId};
  let callbackId;
  try {
    callbackId = await putCartItemsRequest(cartItemId, params);
    let callbackString: string = await callbackId.text();
    callbackString = callbackString.replace(/^"(.*)"$/, '$1');

    // throw an error on failure without the callback metadata
    return await callback(callbackString, 60, dispatch);
  } catch (error: any) {
    console.error(error);
    await callback(callbackId, 60, dispatch);
    // fallback statement
    throw new Error('Failed to update cart item');
  }
};

export const fabricationProductAddCartItem = async (
  cartItem: IProductCatalogCartItemRequest,
  cartId: string,
  shipFromWarehouseId: string,
  siteUseId: number,
  dispatch: any
): Promise<any> => {
  const callbackExternalIdGuid = await guid();
  const callbackId = await postProductCartItemsRequest({
    cartId: cartId,
    itemNumber: cartItem.itemNumber,
    quantity: cartItem.quantity,
    shipFromWarehouseId: shipFromWarehouseId,
    cartItemType: cartItem.cartItemType,
    requireOrderingInQuantitiesOfTwo: cartItem.requireOrderingInQuantitiesOfTwo,
    currency: cartItem.currency,
    operatingUnitCode: cartItem.operatingUnitCode,
    priceListCode: cartItem.priceListCode,
    programCode: cartItem.programCode,
    programName: cartItem.programName,
    siteUseId: siteUseId,
    callbackExternalId: callbackExternalIdGuid,
    uomQuantity: cartItem.uomQuantity,
    designCode: cartItem.designCode,
    dimension3: cartItem.dimension3,
    dimension4: cartItem.dimension4,
    fabOrderingViewType: cartItem.fabOrderingViewType,
    parentCartItemId: cartItem.parentCartItemId,
    productApplication: cartItem.productApplication,
    productGroupCode: cartItem.productGroupCode,
    relatedItems: cartItem.relatedItems,
    pieceLabel: cartItem.pieceLabel,
    dimension1: cartItem.dimension1,
    dimension2: cartItem.dimension2,
  });
  let stringId = await callbackId.text();

  while (stringId.includes('"')) {
    stringId = stringId.replace('"', '');
  }

  return await callback(stringId, 60, dispatch, {
    areMultipleCallbacks: true,
    stopOnFailure: false,
  });
};
