import React from 'react';
import {Route, Switch} from 'react-router-dom';
import BasicInfo from './BasicInfo/BasicInfo.component';
import OrderDetailsPage from './OrderHistory/OrderDetails/OrderDetails.component';
import OrderHistory from './OrderHistory/OrderHistory.component';
import QuoteHistory from './QuoteHistory/QuoteHistory.component';
import PaymentPreferences from './PaymentPreferences/PaymentPreferences.component';
import OrderPreferences from './OrderPreferences/OrderPreferences.component';
import Reports from './Reports/Reports.component';
import QuoteDetails from './QuoteDetails/QuoteDetails.component';

const AccountRoutes = () => {
  return (
    <Switch>
      <Route path="/account/reports" component={Reports} />
      <Route path="/account/basic-information" component={BasicInfo} />
      <Route path="/account/payment-preferences" component={PaymentPreferences} />
      <Route path="/account/order-preferences" component={OrderPreferences} />
      <Route path="/account/orders/details/:orderid" component={OrderDetailsPage} />
      <Route exact path="/account/orders" component={OrderHistory} />
      <Route path="/account/quotes/details/:quoteid" component={QuoteDetails} />
      <Route exact path="/account/quotes" component={QuoteHistory} />
    </Switch>
  );
};

export default AccountRoutes;
