import React, {useEffect, useState} from 'react';
import {useRouteMatch} from 'react-router-dom';
import OrderSearch from '../../../../Core/Components/Header/Search/OrderSearch';
import {orderHistoryOrderFilterOptions} from '../../../../Core/Components/Header/Search/OrderSearch/filterOptions';
import OrderTable from '../../../../Core/Components/SearchPages/OrderSearchPage/OrderTable';
import OrderPageContainer from '../../../../Core/Components/SearchPages/OrderSearchPage/OrderSearchPage.styled';
import {selectCurrentCustomer} from '../../../../features/customer/slice/customer.slice';
import {selectOrderSearch} from '../../../../features/search/slice/search.slice';
import {useAppDispatch, useTypedSelector} from '../../../../hooks/store';
import OrderHistoryStyled from './OrderHistory.styled';
import {useScrollPercentage} from 'react-scroll-percentage';
import {loadMoreOrdersAsync} from '../../../../features/search/order/slice/order-search.slice';
import {cleanSearchParams} from '../../../../features/order/service/order.service';

const OrderHistory = () => {
  const dispatch = useAppDispatch();
  const {url} = useRouteMatch();
  const [ref, percentageScrolled] = useScrollPercentage();

  const [isLoading, setIsLoading] = useState(true);
  const [scrolledFarEnough, setScrolledFarEnough] = useState(false);
  const [resultDisplaySize, setResultDisplaySize] = useState(50);

  const suggestions = useTypedSelector(selectOrderSearch).suggestions;
  let loadingStatus = useTypedSelector(selectOrderSearch).loading;
  let erpCustomerId = useTypedSelector(selectCurrentCustomer)?.erpCustomerId;
  let urlSearchParams = useTypedSelector(selectOrderSearch).orderUrlSearchParams;

  useEffect(() => {
    const scrollToTopOnLoad = () => {
      window.scrollTo({top: 0, left: 0});
    };
    setTimeout(() => {
      scrollToTopOnLoad();
    }, 500);
  }, []);

  useEffect(() => {
    const loadMoreDataOnScroll = (numResultsToShow: number) => {
      let filters: any = urlSearchParams;
      let data = new URLSearchParams(filters);
      data = cleanSearchParams(data);
      if (erpCustomerId) {
        data.append('erpCustomerId', erpCustomerId.toString());
      }
      data.append('pager[expand]', 'totalResults');
      data.append('pager[offset]', '0');
      data.append('pager[limit]', numResultsToShow.toString());
      data.append('pager[expand]', 'orderLineItems');
      data.append('pager[sort]', '-createdAt');
      data.append('pager[expand]', 'purchaseOrderNumber');
      dispatch(loadMoreOrdersAsync(data));
      setScrolledFarEnough(false);
    };
    const determineIfMoreDataShouldBeLoaded = () => {
      if (scrolledFarEnough && resultDisplaySize <= suggestions.length) {
        loadMoreDataOnScroll(resultDisplaySize + 50);
        setResultDisplaySize((r) => r + 50);
      }
    };
    determineIfMoreDataShouldBeLoaded();
  }, [scrolledFarEnough, dispatch, suggestions, resultDisplaySize, urlSearchParams, erpCustomerId]);

  useEffect(() => {
    if (percentageScrolled > 0.75) {
      setScrolledFarEnough(true);
    } else {
      setScrolledFarEnough(false);
    }
  }, [percentageScrolled]);

  useEffect(() => {
    if (loadingStatus === 'pending') {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [loadingStatus]);

  return (
    <OrderHistoryStyled>
      <OrderPageContainer>
        <OrderSearch
          erpCustomerId={erpCustomerId}
          relativeUrl={url}
          orderFilterOptions={orderHistoryOrderFilterOptions}
        />
        <div ref={ref}>
          <OrderTable orderSuggestions={suggestions} isLoading={isLoading} />
        </div>
      </OrderPageContainer>
    </OrderHistoryStyled>
  );
};

export default OrderHistory;
