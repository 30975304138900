import {newDate} from '../../../Framework/Services/newDate.service';
import {Cart, CartItem} from '../../cart/ICartState';
import {IFullPieces, IPieceModel} from '../../fabrication/IFabricationState';
import {formatPrice} from '../../pricing/service/pricing.service';
import {Item, Product} from '../../productCatalog/IProductCatalogState';
import {GAEcommerceItem} from '../types';

// This is done to mock pushCartEventToDataLayer for testing
// (pretty ugly, I know)
import * as thisModule from './analytics.service';

export const pushUserLoginEventToDataLayer = (didLoginSucceed: boolean) => {
  const dataLayer = (window as any).dataLayer || [];
  const timeStamp = newDate().toISOString();
  dataLayer.push({
    event: 'user_login',
    loginStatus: didLoginSucceed ? 'success' : 'failure',
    loginTimestamp: timeStamp,
  });
};
export const pushViewCartEventToDataLayer = (cart: Cart | null) => {
  const dataLayer = (window as any).dataLayer || [];
  const items = [];
  if (cart) {
    for (const cartItem of cart.cartItems) {
      const item = {
        item_id: cartItem.itemNumber,
        item_name: cartItem.description,
        price: cartItem.unitPrice && cartItem.unitSize ? formatPrice(cartItem.unitPrice * cartItem.unitSize) : '',
        affiliation: 'The Cambria Exchange',
        quantity: cartItem.quantity,
        item_category: cartItem.productType,
        item_list_id: cartItem.productId,
        item_brand: 'Cambria',
      };
      items.push(item);
    }

    dataLayer.push({
      event: 'view_cart',
      ecommerce: {
        currency: cart.cartItems && cart.cartItems.length > 0 ? cart.cartItems[0].currency : '',
        items: items,
      },
    });
  }
};
export const pushPurchaseEventToDataLayer = (cart: Cart | null, orderNumber: string) => {
  const dataLayer = (window as any).dataLayer || [];
  const items = [];
  const voucherCodes = [];
  if (cart) {
    for (const cartItem of cart.cartItems) {
      const item = {
        item_id: cartItem.itemNumber,
        item_name: cartItem.description,
        price: cartItem.unitPrice && cartItem.unitSize ? formatPrice(cartItem.unitPrice * cartItem.unitSize) : '',
        quantity: cartItem.quantity,
        item_category: cartItem.productType,
        item_list_id: cartItem.productId,
        item_brand: 'Cambria',
      };
      items.push(item);
    }
    if (cart.voucherCodes && cart.voucherCodes.length > 0) {
      for (const voucherCode of cart.voucherCodes) {
        voucherCodes.push(voucherCode.codeString);
      }
    }
    dataLayer.push({
      event: 'purchase',
      ecommerce: {
        currency: cart.cartItems[0].currency,
        value: cart.totalAmount ? formatPrice(cart.totalAmount) : '',
        shipping: cart.totalShippingAmount ? formatPrice(cart.totalShippingAmount) : '',
        affiliation: 'The Cambria Exchange',
        transaction_id: orderNumber,
        coupon: voucherCodes,
        items: items,
      },
    });
  }
};
export const pushBeginCheckoutEventToDataLayer = (cart: Cart | null) => {
  const dataLayer = (window as any).dataLayer || [];
  const items = [];
  if (cart) {
    for (const cartItem of cart.cartItems) {
      const item = {
        item_id: cartItem.itemNumber,
        item_name: cartItem.description,
        price: cartItem.unitPrice && cartItem.unitSize ? formatPrice(cartItem.unitPrice * cartItem.unitSize) : '',
        affiliation: 'The Cambria Exchange',
        quantity: cartItem.quantity,
        item_category: cartItem.productType,
        item_list_id: cartItem.productId,
        item_brand: 'Cambria',
      };
      items.push(item);
    }

    dataLayer.push({
      event: 'begin_checkout',
      ecommerce: {
        currency: cart.cartItems && cart.cartItems.length > 0 ? cart.cartItems[0].currency : '',
        items: items,
      },
    });
  }
};
export const pushSearchTermInformationToDataLayer = (searchTerm: any) => {
  const dataLayer = (window as any).dataLayer || [];
  dataLayer.push({
    search: null,
  });
  dataLayer.push({
    event: 'search_term',
    search: {
      term: searchTerm,
    },
  });
};
export const pushSelectItemInformationToDataLayer = (product: Partial<Product>) => {
  const dataLayer = (window as any).dataLayer || [];
  dataLayer.push({
    ecommerce: null,
  });
  dataLayer.push({
    event: 'select_item',
    ecommerce: {
      items: [
        {
          item_name: product.displayName,
          item_id: product.id,
          item_brand: 'Cambria',
          item_category: product.productType,
        },
      ],
    },
  });
};
export const pushViewItemInformationToDataLayer = (product: Partial<Product>) => {
  const dataLayer = (window as any).dataLayer || [];
  dataLayer.push({
    ecommerce: null,
  });
  dataLayer.push({
    event: 'view_item',
    ecommerce: {
      items: [
        {
          item_name: product.displayName,
          item_id: product.id,
          item_brand: 'Cambria',
          item_category: product.productType,
        },
      ],
    },
  });
};
export const buildEventItemForFullFabPiece = ({
  pieceModel,
  quantity,
}: {
  pieceModel: IFullPieces;
  quantity?: number;
}): GAEcommerceItem[] => {
  const cartItem = pieceModel.mainPiece;
  const unitPrice = cartItem.hasAdjustedUnitPrice ? cartItem.adjustedUnitPrice : cartItem.unitPrice;
  const mainPieceEvent = buildEventItemForCartEvents(
    pieceModel.mainPiece,
    pieceModel.mainPiece.productType,
    unitPrice ?? 0,
    quantity ?? 1,
    cartItem.productId,
    cartItem.itemNumber,
    cartItem.description,
    cartItem.productGroupCode
  );
  const subPiecesEventItems =
    pieceModel.mainPieceChildItems
      ?.map((p) => buildEventItemForFullFabPiece({pieceModel: p, quantity: p.mainPiece.uomLineQuantity ?? 1}))
      .flat() ?? [];
  const edgeProfilesEventItems =
    pieceModel.mainEdgeProfile
      ?.map((cartItem) => {
        const unitPrice = cartItem.hasAdjustedUnitPrice ? cartItem.adjustedUnitPrice : cartItem.unitPrice;
        return buildEventItemForCartEvents(
          cartItem,
          cartItem.productType,
          unitPrice ?? 0,
          cartItem.quantity ?? 1,
          cartItem.productId,
          cartItem.itemNumber,
          cartItem.description,
          cartItem.edgeProfileName
        );
      })
      .flat() ?? [];
  return [...mainPieceEvent, ...subPiecesEventItems, ...edgeProfilesEventItems];
};

/**
 * Push a remove_from_cart GA4 event when deleting piece item
 * from a fab cart
 * @param items
 */
export const pushRemoveFabPieceFromCartEventToDataLayer = (
  items: Array<{
    pieceModel: IFullPieces;
    quantity?: number;
  }>
) => {
  let eventItems = items
    .map((argItem) => {
      const {pieceModel, quantity} = argItem;
      return buildEventItemForFullFabPiece({pieceModel, quantity});
    })
    .flat();
  thisModule.pushEventToDataLayer('remove_from_cart', eventItems, items[0]?.pieceModel.mainPiece.currency);
};
export const pushRemoveFromCartEventToDataLayer = (
  items: Array<{
    cartItem: CartItem;
    product?: Product;
    quantity?: number | null;
  }>
): void => {
  let eventItems = items
    .map((argItem) => {
      const {cartItem, product, quantity} = argItem;
      const item = product?.items?.find((i) => i.itemNumber === cartItem.itemNumber);

      const unitPrice = cartItem.hasAdjustedUnitPrice ? cartItem.adjustedUnitPrice : cartItem.unitPrice;
      return buildEventItemForCartEvents(
        item as Item,
        product?.productType || cartItem.productType,
        unitPrice ?? '',
        quantity ?? 1,
        product?.id || cartItem.productId,
        cartItem.itemNumber,
        item?.description || cartItem.description,
        product?.displayName || cartItem.description
      );
    })
    .flat();

  thisModule.pushEventToDataLayer('remove_from_cart', eventItems, items[0]?.cartItem?.currency ?? '');
};
export const pushEventToDataLayer = (
  event: 'add_to_cart' | 'remove_from_cart' | 'add_shipping_info' | 'add_payment_info' | 'add_order_details',
  items: GAEcommerceItem[],
  currency?: string,
  extraData?: object
) => {
  const dataLayer = (window as any).dataLayer || [];
  dataLayer.push({
    ecommerce: null,
  });
  dataLayer.push({
    event: event,
    ecommerce: {
      currency: currency || '',
      items,
      ...(extraData ?? {}),
    },
  });
};
export const buildEventItemForQuickOrdering = (
  item: Array<any>,
  productType: string,
  quantity?: number
): Array<GAEcommerceItem> => {
  return item.map((cartItem) => ({
    affiliation: 'The Cambria Exchange',
    quantity,
    item_id: cartItem.itemNumber,
    item_name: cartItem.description,
    price:
      cartItem.shipFromWarehouseId && cartItem.shipFromWarehouseId.SellingPrice
        ? formatPrice(cartItem.shipFromWarehouseId.SellingPrice)
        : '',
    item_category: productType,
    item_category2: cartItem.designName || '',
    item_category3: cartItem.thickness || '',
    item_category4: cartItem.finish || '',
    item_category5: cartItem.size || '',
    item_list_id: cartItem.productId,
    item_list_name: cartItem.displayName,
    item_variant: cartItem.description,
  }));
};
export const buildEventItemForCartEvents = (
  item: Partial<Item> | Partial<IPieceModel> | Partial<CartItem>,
  productType: string,
  priceNumber?: number,
  quantity?: number,
  productId?: string,
  itemNumber?: string,
  description?: string,
  displayName?: string
): Array<GAEcommerceItem> => {
  const cartItem = item as CartItem;
  quantity = quantity || cartItem?.uomLineQuantity || cartItem?.quantity || 1;
  priceNumber = priceNumber ?? (cartItem?.hasAdjustedUnitPrice ? cartItem.adjustedUnitPrice : cartItem.unitPrice);
  const price: string = priceNumber ? formatPrice(priceNumber) : '';

  const buildItem = (attributes: Partial<GAEcommerceItem> = {}): GAEcommerceItem => ({
    item_id: itemNumber as string,
    item_name: description as string,
    affiliation: 'The Cambria Exchange',
    price: price,
    quantity,
    ...attributes,
  });

  switch (productType) {
    case 'Slab':
    case 'Samples':
    case 'Tile':
      item = item as Partial<Item>;
      return [
        buildItem({
          item_category: productType,
          item_category2:
            item?.attributes?.find((attr: any) => attr.name === 'Design Name' || attr.name === 'Color/Design')?.value ||
            '',
          item_category3: item?.attributes?.find((attr: any) => attr.name === 'Thickness')?.value || '',
          item_category4: item?.attributes?.find((attr: any) => attr.name === 'Finish')?.value || '',
          item_category5: item?.attributes?.find((attr: any) => attr.name === 'Size')?.value || '',
          item_list_id: productId,
          item_list_name: displayName,
          item_variant: description,
        }),
      ];
    case 'Store':
      return [
        buildItem({
          item_category: productType,
          item_list_id: productId,
          item_list_name: description,
        }),
      ];

    case 'FabricatedService':
      return [
        buildItem({
          item_category: productType,
          item_category2: displayName,
          item_list_id: productId,
          item_list_name: description,
        }),
      ];

    case 'Fabricated':
      // So we can send add/remove from cart from
      // FabricationPieceForm or from CambriaFabricationPieceCartInfo
      // but we have different objects in both places
      // (IPieceModel in the form and CartItem in Info)
      let items: GAEcommerceItem[] = [];
      item = item as Partial<IPieceModel> | Partial<CartItem>;
      const eventItemWithCommonData = {
        item_category: productType,
        item_category3: item.thickness || '',
        item_category4: item.finish || '',
        item_list_id: productId,
        item_list_name: displayName,
      };
      item = item as IPieceModel;
      if (item.selectedDesign || item.edgeProfiles) {
        // item is a piece model
        items = [
          buildItem({
            ...eventItemWithCommonData,
            item_category2: item.selectedDesign?.designDescription || '',
          }),
        ];

        if (item?.edgeProfiles) {
          for (const edgeProfile of item.edgeProfiles) {
            let edgeProfilePrice = formatPrice(
              parseInt(edgeProfile.linePrice.toString().replace(/\b(?:USD|CAN)\b/g, ''))
            );
            items.push(
              buildItem({
                item_id: edgeProfile.selectedEdgeProfile?.itemNumber,
                item_name: edgeProfile.selectedEdgeProfile?.description,
                item_category: edgeProfile.selectedEdgeProfile?.productType,
                item_category2:
                  edgeProfile.selectedEdgeProfile?.attributes.find((attr: any) => attr.name === 'Edge Profile Name')
                    ?.value || '',
                item_category3:
                  edgeProfile.selectedEdgeProfile?.attributes.find((attr: any) => attr.name === 'Thickness')?.value ||
                  '',
                item_category4:
                  edgeProfile.selectedEdgeProfile?.attributes.find((attr: any) => attr.name === 'Base Product Type')
                    ?.value || '',
                item_list_id: edgeProfile.selectedEdgeProfile?.productId,
                item_list_name: edgeProfile.selectedEdgeProfile?.productDisplayName,
                price: edgeProfilePrice,
                quantity: edgeProfile.linearFeet || 0,
              })
            );
          }
        }
      } else {
        // item is a cartitem
        item = item as Partial<CartItem>;
        if (item) {
          items = [
            buildItem({
              ...eventItemWithCommonData,
              item_category2: item.designName || item.edgeProfileName || '',
            }),
          ];
        }
      }
      return items;

    default:
      return [];
  }
};
export const pushAddToCartInformationToDataLayer = (
  isQuickOrdering: boolean,
  item: Partial<Item> | Partial<IPieceModel> | any[],
  currency: string,
  productType: string,
  price?: any,
  quantity?: number,
  productId?: string,
  itemNumber?: string,
  description?: string,
  displayName?: string
) => {
  let items = isQuickOrdering
    ? buildEventItemForQuickOrdering(item as any[], productType, quantity)
    : buildEventItemForCartEvents(
        item as Partial<Item> | Partial<IPieceModel>,
        productType,
        price,
        quantity,
        productId,
        itemNumber,
        description,
        displayName
      );
  pushEventToDataLayer('add_to_cart', items, currency);
};

export const pushAddShippingInfoToDataLayer = (args: {cart: Cart; deliveryMethod: string}): void => {
  const {cart, deliveryMethod} = args;
  pushEventToDataLayer(
    'add_shipping_info',
    cart.cartItems
      .map((cartItem) =>
        buildEventItemForCartEvents(
          cartItem,
          cartItem.productType,
          undefined,
          undefined,
          cartItem.productId,
          cartItem.itemNumber,
          cartItem.description,
          cartItem.description
        ).flat()
      )
      .flat(),
    cart?.cartItems[0]?.currency,
    {
      ship_tier: deliveryMethod,
    }
  );
};

export const pushAddPaymentInfoToDataLayer = (args: {cart: Cart; paymentType: string}): void => {
  const {cart, paymentType} = args;
  pushEventToDataLayer(
    'add_payment_info',
    cart.cartItems
      .map((cartItem) =>
        buildEventItemForCartEvents(
          cartItem,
          cartItem.productType,
          undefined,
          undefined,
          cartItem.productId,
          cartItem.itemNumber,
          cartItem.description,
          cartItem.description
        ).flat()
      )
      .flat(),
    cart?.cartItems[0]?.currency,
    {
      payment_type: paymentType,
    }
  );
};

export const pushAddOrderDetailsToDataLayer = (args: {cart: Cart}): void => {
  const {cart} = args;
  pushEventToDataLayer(
    'add_order_details',
    cart.cartItems
      .map((cartItem) =>
        buildEventItemForCartEvents(
          cartItem,
          cartItem.productType,
          undefined,
          undefined,
          cartItem.productId,
          cartItem.itemNumber,
          cartItem.description,
          cartItem.description
        ).flat()
      )
      .flat(),
    cart?.cartItems[0]?.currency
  );
};
