import React, {useEffect, useState} from 'react';
import {useTypedSelector} from '../../../../hooks/store';
import {selectUserActions} from '../../../../features/permission/slice/permission.slice';
import {useLogout} from '../../../../hooks/useLogout';
import {useHistory} from 'react-router-dom';
import {getFooterMenu} from '../../../../features/environment/service/environment.service';
import {selectSelectedWarehouse} from '../../../../features/warehouse/slice/warehouse.slice';
import {getAvailableProductTabsByWarehouse, hasPermission} from '../../../../store/permission/permission.service';

const FooterLinks = () => {
  const history = useHistory();
  const logout = useLogout();

  const userActions = useTypedSelector(selectUserActions);
  const selectedWarehouse = useTypedSelector(selectSelectedWarehouse);

  let [footerLinksMenuArray, setFooterLinksMenuArray]: any = useState([]);

  const openMenu = (menu: any) => {
    if (menu.menuLabel === 'Contact Us') {
      return;
    }

    if (menu.menuLabel === 'Logout') {
      logout();
    }

    const previousState = menu.isMenuOpen;

    for (const block of footerLinksMenuArray) {
      block.forEach((menu: any) => {
        if (menu.menuLabel !== 'Contact Us') {
          menu.isMenuOpen = false;
        }
      });
    }

    menu.isMenuOpen = !previousState;

    const tempFooterLinkMenuArray = JSON.parse(JSON.stringify(footerLinksMenuArray));

    setFooterLinksMenuArray(tempFooterLinkMenuArray);
  };

  useEffect(() => {
    let setPermissions = (footerLinksMenu: any, warehouseCode?: string) => {
      let tempArray = [];
      for (let block in footerLinksMenu) {
        for (let index = 0; index < footerLinksMenu[block].length; index++) {
          const menu = footerLinksMenu[block].find((m: any) => m.menuLabel === 'Products');
          let hasView: boolean = false;
          if (menu && menu.menuContent) {
            menu.menuContent.forEach((link: any) => {
              if (warehouseCode) {
                const availableProducts = getAvailableProductTabsByWarehouse(warehouseCode);
                hasView = hasPermission(link.urn, userActions) && availableProducts.includes(link.navigationItemLabel);
              } else {
                hasView = hasPermission(link.urn, userActions);
              }
              link.disabled = !hasView;
            });
          }
        }
        tempArray.push(footerLinksMenu[block]);
      }
      return tempArray;
    };
    const loadFooterData = async () => {
      let tempFooterMenuResponse = await getFooterMenu();
      tempFooterMenuResponse = JSON.parse(JSON.stringify(tempFooterMenuResponse));
      const configuredFooterMenuResponse = selectedWarehouse?.warehouseCode
        ? setPermissions(tempFooterMenuResponse, selectedWarehouse.warehouseCode)
        : setPermissions(tempFooterMenuResponse);
      setFooterLinksMenuArray(configuredFooterMenuResponse);
    };
    loadFooterData();
  }, [userActions, selectedWarehouse]);

  return (
    <div className="footer-menu-wrapper">
      {footerLinksMenuArray.length > 0 ? (
        footerLinksMenuArray.map((block: any, index1: any) => (
          <div className={`footer-menu m-b-sm ${block[0].mobileOnly ? 'display-none' : ''}`} key={'block' + index1}>
            {block.map((menu: any, index2: any) => (
              <div className={menu.mobileOnly ? 'hidden-after-gfb' : ''} key={'menu' + index2}>
                <h5 className={'title ' + menu.textStyle} onClick={() => openMenu(menu)}>
                  {menu.link ? (
                    <a href={menu.link} target="_blank" rel="noopener noreferrer">
                      {menu.menuLabel}
                    </a>
                  ) : (
                    <>{menu.menuLabel}</>
                  )}
                  <span
                    className={
                      'hidden-after-gfb icon icons-cambria-Ui-Triangle ' +
                      (menu.isMenuOpen ? 'up ' : '') +
                      (menu.menuLabel === 'Logout' || menu.menuLabel === 'Contact Us' || !menu.menuContent?.length
                        ? 'hidden '
                        : '')
                    }></span>
                </h5>
                <div className={'menu-content' + (!menu.isMenuOpen ? ' collapsed' : '')}>
                  {menu.menuContent &&
                    menu.menuContent.map((link: any, index3: any) => (
                      <div key={'link' + index3}>
                        {!link.disabled ? (
                          <span className={'link ' + link.textStyle}>
                            {link.openInNewTab ? (
                              <a href={link.navigationItemUrl} target={'_blank'} rel="noreferrer">
                                {link.navigationItemLabel}
                              </a>
                            ) : (
                              <div
                                className="footer-menu-link-div"
                                onClick={() => {
                                  history.push(link.navigationItemUrl);
                                }}>
                                {link.navigationItemLabel}
                              </div>
                            )}
                          </span>
                        ) : null}
                      </div>
                    ))}
                </div>
              </div>
            ))}
          </div>
        ))
      ) : (
        <></>
      )}
    </div>
  );
};

export default FooterLinks;
