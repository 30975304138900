import React from 'react';
import CustomerTablePlaceholder from '../../Placeholders/CustomerTablePlaceholder.component';

export const DesktopMapping = ({
  suggestions,
  customerSelectionFunction,
}: {
  suggestions: any[];
  customerSelectionFunction: Function;
}) => {
  return (
    <tbody className="customer-table-body">
      {suggestions.map((customer, index) => {
        return (
          <tr key={index} className="customer-table-row" onClick={() => customerSelectionFunction(customer)}>
            <td className="customer-name">{customer.name}</td>
            <td className="mobile-hidden address">
              {customer.address1} {customer.address2 ? `${customer.address2} ` : ''} {customer.city},{' '}
              {customer.stateProvince} {customer.postalCode}
            </td>
            <td className="mobile-hidden class">{customer.class}</td>
          </tr>
        );
      })}
    </tbody>
  );
};

const DesktopResults = ({
  suggestions,
  customerSelectionFunction,
  isLoading,
}: {
  suggestions: any[];
  customerSelectionFunction: Function;
  isLoading: boolean;
}) => {
  if (isLoading) {
    return <CustomerTablePlaceholder />;
  }
  return <DesktopMapping suggestions={suggestions} customerSelectionFunction={customerSelectionFunction} />;
};

export default DesktopResults;
