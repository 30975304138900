import {IFullPieces, IPieceModel} from '../IFabricationState';
import {IOrderDetails, ProductTypeItems} from '../../order/orderDetails/IOrderDetailsState';
import {callback} from '../../callbacks/service/callbacks.service';
import {getFabInstallAddressRequest, postCartOrderDetailsRequest} from '../controller/fabrication.controller';
import {Cart, CartItem, NewCartItem, getNewCart} from '../../cart/ICartState';
import {IQuoteDetails} from '../../quote/IQuoteState';
import {isFabricatedOrder} from '../../order/orderDetails/service/orderDetails.service';
import {toast} from 'react-toastify';
import {mapCartItems} from '../../itemMapper/service/itemMapper.service';

export const filterEdgeProfileItems = (
  items: Array<ProductTypeItems>,
  productType: string,
  thickness: string
): Array<ProductTypeItems> => {
  const filteredItemsArray: Array<ProductTypeItems> = [];
  if (items) {
    items.forEach((item) => {
      item.attributes.forEach((attribute) => {
        if (attribute.name === 'Base Product Type' && attribute.value === productType) {
          item.attributes.forEach((attr) => {
            if (attr.name === 'Thickness' && attr.value === thickness) {
              filteredItemsArray.push(item);
            }
          });
        }
      });
    });
  }
  return filteredItemsArray;
};

export const checkIfAllSelectsOnFormCompleted = (form: IPieceModel, productGroupCode: string): boolean => {
  if (productGroupCode === 'MiteredEdge') {
    if (form.selectedDesign && form.thickness && form.finish) {
      return true;
    }
  } else if (productGroupCode === 'Backsplash') {
    if (form.match && form.selectedDesign && form.thickness && form.finish) {
      return true;
    }
  } else {
    if (form.selectedFabricatedApplication && form.selectedDesign && form.thickness && form.finish) {
      return true;
    }
  }
  return false;
};

export const updateFabOrderCartDetails = async (data: any, dispatch: any) => {
  try {
    const callbackId = await postCartOrderDetailsRequest(JSON.stringify(data));
    let stringId = await callbackId.text();

    while (stringId.includes('"')) {
      stringId = stringId.replace('"', '');
    }

    return await callback(stringId, 60, dispatch);
  } catch (e: any) {
    throw e;
  }
};

export const checkIfJobNumberExists = async (jobNumber: string) => {
  const results = await getFabInstallAddressRequest(jobNumber);
  return results.totalResults !== 0;
};

export const getParentChildCartView = (shouldUseOrderMetadata: boolean, order: IOrderDetails): Cart => {
  const parentChildCartView = getNewCart();
  parentChildCartView.cartItems = [];
  parentChildCartView.id = order.lastCartId;
  parentChildCartView.totalAmount = order.totalAmount;
  parentChildCartView.subtotalAmount =
    shouldUseOrderMetadata && order.orderMetadata?.orderSummaryData.subtotalAmount
      ? order.orderMetadata.orderSummaryData.subtotalAmount
      : order.subtotal;
  parentChildCartView.totalNetAmount =
    shouldUseOrderMetadata && order.orderMetadata?.orderSummaryData.totalNetAmount
      ? order.orderMetadata.orderSummaryData.totalNetAmount
      : order.totalNetAmount;
  const isfabricatedOrder = isFabricatedOrder(order.orderLineItems[0].productType, order.orderType);
  const items: CartItem[] = [];
  for (const item of order.orderLineItems) {
    let cartItem: CartItem = {
      productId: item.productId,
      pieceLabel: item.pieceLabel,
      productGroupCode: item.productGroupCode,
      productApplication: item.productApplication,
      designName: item.designName,
      thickness: item.thickness,
      finish: item.finish,
      match: item.match,
      dimension1: item.dimension1,
      dimension2: item.dimension2,
      dimension3: item.dimension3,
      dimension4: item.dimension4,
      description: item.description ? item.description : item.itemDescription,
      parentCartItemId: item.parentCartItemId,
      cartItemId: item.cartItemId,
      itemNumber: item.itemNumber,
      uomLineQuantity: item.uomLineQuantity,
      pricingUom: item.pricingUom,
      cartItemType: item.cartItemType,
      edgeProfileName: item.edgeProfileName,
      edgeProfileCode: item.edgeProfileCode,
      unitPrice: item.unitPrice < item.unitPriceAdjusted ? item.unitPriceAdjusted : item.unitPrice,
      adjustedUnitPrice: item.unitPriceAdjusted,
      currency: item.currency,
      hasAdjustedUnitPrice: item.discountAmount > 0,
      hasAdjusteditemQuantityPrice: item.discountAmount > 0 && isfabricatedOrder ? true : false,
      hasAdjustedExtendedNetPrice: item.discountAmount > 0 && !isfabricatedOrder ? true : false,
      itemQuantityPrice: item.extendedNetPrice,
      adjusteditemQuantityPrice: item.adjustedExtendedNetPrice,
      primaryUomCode: item.primaryUomCode,
      productType: item.productType,
      productActiveLocations: [],
      productCategory: item.productCategory,
      unitSize: item.unitSize,
      extendedNetPrice: item.extendedNetPrice,
      shipFromWarehouseId: item.shipFromWarehouseId,
      unitWeight: item.unitWeight,
      weightUomCode: item.weightUomCode,
      taxProductCode: item.taxProductCode,
      tempQuantity: item.itemQuantityPrice,
      shippingClassifications: [],
      organizations: [],
      adjustedExtendedNetPrice: item.adjustedExtendedNetPrice,
      discountAmount: item.discountAmount,
      discountCategory: '',
      createdAt: item.createdAt,
      updatedAt: item.updatedAt,
      operatingUnitCode: item.operatingUnitCode,
      priceListCode: item.priceListCode,
      programCode: '',
      programName: item.programName,
      isAvailable: false,
      uomLineQuantityDisplayString: item.uomLineQuantity?.toString(),
      designCode: item.designCode,
      fabOrderingViewType: item.fabOrderingViewType,
      designCollection: item.designCollection,
      serviceOfferings: false,
      siteUseId: 0,
      requestedDeliveryDate: null,
      seamed: false,
      cartId: '',
      quantity: item.quantity,
      imageUrl: item.imageUrl,
      _quantity: 0,
      _imageUrl: '',
    };
    items.push(cartItem);
  }
  parentChildCartView.cartItems = items;
  return parentChildCartView;
};

export const getParentChildCartViewFromQuote = (quote: IQuoteDetails): Cart => {
  const parentChildCartView = getNewCart();
  parentChildCartView.cartItems = [];
  parentChildCartView.id = quote.quoteCartId;

  const items: CartItem[] = [];
  for (const item of quote.lines) {
    let cartItem: CartItem = NewCartItem();
    cartItem.unitPrice = item.unitPrice ? item.unitPrice : item.originalBasePrice;
    cartItem.adjustedUnitPrice = item.unitPrice ? item.unitPrice : item.originalBasePrice;
    cartItem.itemQuantityPrice = item.originalBasePriceExtended;
    cartItem.adjustedExtendedNetPrice = item.originalBasePriceExtended;
    cartItem.productId = item.productId;
    cartItem.pieceLabel = item.pieceLabel;
    cartItem.productGroupCode = item.productGroupCode;
    cartItem.productApplication = item.productApplication;
    cartItem.designName = item.design;
    cartItem.thickness = item.thickness;
    cartItem.finish = item.polish;
    cartItem.match = item.match;
    cartItem.dimension1 = item.dimension1;
    cartItem.dimension2 = item.dimension2;
    cartItem.dimension3 = item.dimension3;
    cartItem.dimension4 = item.dimension4;
    cartItem.description = item.description;
    cartItem.parentCartItemId = item.parentCartItemId;
    cartItem.cartItemId = item.cartItemId;
    cartItem.cartItemType = item.cartItemType;
    cartItem.itemNumber = item.itemNumber;
    cartItem.uomLineQuantity = item.uomLineQuantity;
    cartItem.pricingUom = item.pricingUom;
    cartItem.edgeProfileName = item.edgeProfileName;
    cartItem.edgeProfileCode = item.edgeProfileCode;
    items.push(cartItem);
    parentChildCartView.totalNetAmount += item.originalBasePriceExtended;
  }
  parentChildCartView.cartItems = items;
  return parentChildCartView;
};

export const checkForEdgeProfiles = (cartItemsArray: Array<IFullPieces>) => {
  for (const cartItem of cartItemsArray) {
    if (cartItem.mainPiece?.productGroupCode !== 'GeneralItems') {
      if (cartItem.mainEdgeProfile.length === 0) {
        toast.error(
          `Piece '${cartItem.mainPiece.pieceLabel}' requires an edge profile. Please add at least 1 edge profile before proceeding to checkout.`
        );
        if (cartItem.mainPieceChildItems.length > 0) {
          checkForEdgeProfiles(cartItem.mainPieceChildItems);
        }
      }
    }
  }
};

export const onPageLoad = (parentChildView: Cart) => {
  const fabItems = parentChildView.cartItems.filter(
    (x: CartItem) => x.cartItemType === 'FabAndInstallStandard' || x.cartItemType === 'FabAndInstallDiscrete'
  );
  let cartItems = mapCartItems(parentChildView, fabItems);
  return cartItems;
};

export const getTotalSqFt = (cartItems: Array<CartItem>): number => {
  return getTotalQuantityOfGivenUnits(cartItems, 'SF');
};

export const getTotalLF = (cartItems: Array<CartItem>): number => {
  const cartItemsWithUnitPrice = cartItems.filter((x) => x.unitPrice > 0);
  return getTotalQuantityOfGivenUnits(cartItemsWithUnitPrice, 'LF');
};

export const getTotalQuantityOfGivenUnits = (cartItems: CartItem[], unitCode: string) => {
  let total = 0;
  const cartItemsWithPrimaryUnitSF = cartItems.filter((x) =>
    x.primaryUomCode ? x.primaryUomCode === unitCode : x.pricingUom === unitCode
  );
  for (const item of cartItemsWithPrimaryUnitSF) {
    total += item.uomLineQuantity;
  }
  return total;
};
