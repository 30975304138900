import React from 'react';
import CambriaLogo from '../CambriaLogo';

const HeaderUnathenticated = () => (
  <div className="align-items-center d-flex h-100 justify-content-center">
    <CambriaLogo height="27" className="unauthenticated" />
  </div>
);

export default HeaderUnathenticated;
