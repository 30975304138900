import React from 'react';
import {Col, Row} from 'react-bootstrap';
import {formatPrice} from '../../../../Framework/Services/formatPrice.service';
import {SavedItemsContainer} from './SavedItems.styled';
import {CartItem} from '../../../../features/cart/ICartState';

const SavedItems = ({
  savedItemsArray,
  isRevisionMode,
  isMovingItemToCart,
  removeSavedItemButton,
  moveToCart,
}: {
  savedItemsArray: CartItem[];
  isRevisionMode: boolean;
  isMovingItemToCart: boolean;
  removeSavedItemButton: Function;
  moveToCart: Function;
}) => {
  const cropImagesBasedOnProductType = (imageUrl: string, productType: string) => {
    let newImageUrl = imageUrl;

    if (productType === 'Slab' || productType === 'Tile') {
      newImageUrl = imageUrl.replace('{size}px@{scale}x', '250x100px').replace('q={quality}', 'q=100') + '&keep=c';
    } else {
      newImageUrl = imageUrl.replace('{size}px@{scale}x', '250x200px').replace('q={quality}', 'q=100') + '&c=0';
    }
    return newImageUrl;
  };

  if (savedItemsArray.length > 0) {
    return (
      <SavedItemsContainer className="items-container">
        {savedItemsArray.map((savedItem: CartItem, index: number) => {
          return (
            <div key={`savedItems${index}`}>
              <Row className={`item m-x-0 hidden-xs hidden-sm ${index % 2 === 0 ? 'white-item' : ''}`}>
                <Col xs={4} sm={5} md={2} className="p-x-0">
                  <div className="item-image">
                    <div className="image-link">
                      <img
                        className="item-image-class"
                        src={cropImagesBasedOnProductType(savedItem.imageUrl, savedItem.productType)}
                        alt={savedItem.description}
                      />
                    </div>
                  </div>
                </Col>
                <Col xs={8} sm={7} md={10} className="p-x-0">
                  <Col md={8} className="item-field">
                    <div className="item-name">
                      <strong>{savedItem.description}</strong>
                      <div>
                        <button
                          type="button"
                          className="btn btn-link sub-text col-12 p-x-0"
                          onClick={() => {
                            removeSavedItemButton(savedItem);
                          }}>
                          <small className="item-field-remove-save-buttons pull-left">REMOVE FROM CART</small>
                        </button>
                        <button
                          type="button"
                          className="btn btn-link sub-text col-12 p-x-0"
                          onClick={() => {
                            moveToCart(savedItem);
                          }}
                          disabled={isRevisionMode || !savedItem.isAvailable || isMovingItemToCart}>
                          <small className="item-field-remove-save-buttons pull-left">MOVE TO CART</small>
                        </button>
                      </div>
                      {!savedItem.isAvailable ? (
                        <p className="text-muted">
                          This item is not currently available because it cannot be combined with items already in your
                          cart, is not available for your current purchasing program, or the item is no longer available
                          for sale.
                        </p>
                      ) : (
                        <div></div>
                      )}
                    </div>
                  </Col>
                  {savedItem.isAvailable ? (
                    <Col md={2} className="item-field pull-left">
                      <span className="row">
                        <span className="col-12 item-field-price-text">{formatPrice(savedItem.unitPrice)}</span>
                        <span className="col-12 item-field-currency-text">
                          {savedItem.currency} {savedItem.pricingUom === 'SF' ? '/ sqft' : ''}
                        </span>
                      </span>
                    </Col>
                  ) : (
                    <div></div>
                  )}
                </Col>
              </Row>
              <Row className={`hidden-md hidden-lg m-x-0 mobile-saved-items`}>
                <Col xs={12} className={'mobile-item-primary-container'}>
                  <div>
                    <Col xs={7} className="pull-left">
                      <strong>{savedItem.description}</strong>
                    </Col>
                    <Col xs={5} className="pull-left mobile-unit-price-container">
                      <span className="row pull-right">
                        <span className="col-12 item-field-price-text mobile-unit-price">
                          {formatPrice(savedItem.unitPrice)}
                        </span>
                        <span className="col-12 item-field-currency-text mobile-unit-price">
                          {savedItem.currency} {savedItem.pricingUom === 'SF' ? '/ sqft' : ''}
                        </span>
                      </span>
                    </Col>
                  </div>
                  <div>
                    <Col xs={7} className="pull-left mobile-item-image-container">
                      <div className="item-image">
                        <div className="image-link">
                          <img
                            className="item-image-class"
                            src={
                              savedItem.imageUrl
                                ? cropImagesBasedOnProductType(savedItem.imageUrl, savedItem.productType)
                                : ''
                            }
                            alt={savedItem.description}
                          />
                        </div>
                      </div>
                    </Col>
                    <Col xs={5} className="pull-left"></Col>
                  </div>
                  <div>
                    <Col xs={6} className="pull-left mobile-remove-button-container">
                      <button
                        type="button"
                        className="btn btn-link sub-text col-12 p-x-0 mobile-remove-button"
                        onClick={() => {
                          removeSavedItemButton(savedItem);
                        }}>
                        <small className="item-field-remove-save-buttons pull-left">REMOVE FROM CART</small>
                      </button>
                    </Col>
                    <Col xs={6} className="pull-left mobile-save-button-container">
                      <button
                        type="button"
                        className="btn btn-link sub-text col-12 p-x-0 mobile-save-button"
                        disabled={isRevisionMode || !savedItem.isAvailable || isMovingItemToCart}
                        onClick={() => {
                          moveToCart(savedItem);
                        }}>
                        <small className="item-field-remove-save-buttons pull-left">MOVE TO CART</small>
                      </button>
                    </Col>
                  </div>
                </Col>
              </Row>
            </div>
          );
        })}
      </SavedItemsContainer>
    );
  } else {
    return <div></div>;
  }
};

export default SavedItems;
