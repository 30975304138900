import React from 'react';
import {Container} from 'react-bootstrap';
import AccountRoutes from './Account.routing';
import {AccountPage} from './Account.styled';
import AccountNavigation from './AccountNavigation';

const Account = () => {
  return (
    <div className="react-bootstrap-hack cambria-main-content">
      <AccountPage>
        <Container>
          <AccountNavigation />
          <AccountRoutes></AccountRoutes>
        </Container>
      </AccountPage>
    </div>
  );
};

export default Account;
