export const formatOrderDate = (date: Date, previousDay?: boolean) => {
  let day = previousDay ? String(date.getDate() - 1) : String(date.getDate());
  let month = String(date.getMonth() + 1);
  let year = date.getFullYear();

  return `${month}/${day}/${year}`;
};

export const formatQuoteDate = (date: Date, padding = false): string => {
  let day = String(date.getUTCDate());
  if (day.length === 1 && padding) {
    day = '0' + day;
  }
  let month = String(date.getUTCMonth() + 1);
  if (month.length === 1 && padding) {
    month = '0' + month;
  }
  let year = date.getUTCFullYear();

  return `${month}/${day}/${year}`;
};

export const formatISODate = (date: string): string => {
  const day = date.substring(8, 10);
  const month = date.substring(5, 7);
  const year = date.substring(0, 4);
  return `${month}/${day}/${year}`;
};
