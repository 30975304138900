import React from 'react';
import {Table} from 'react-bootstrap';
import DateBlock from '../../../../QuoteGrid/DateBlock';
import QuoteDetails from '../../../../QuoteGrid/QuoteDetails';
import PlaceholderTable from '../../PlaceHolders/QuoteTablePlaceholder.component';
import {useHistory} from 'react-router-dom';
import StatusCode from '../../../../StatusCode';
import {useTypedSelector} from '../../../../../../hooks/store';
import {selectCurrentCustomer} from '../../../../../../features/customer/slice/customer.slice';
import {formatQuoteCurrency} from '../../../../../../Framework/Services/formatCurrency';

export const renderResults = (suggestions: any[], useHistory: any, erpCustomerId: number) => {
  return suggestions.map((quote, index) => {
    return (
      <tr
        className="quote-table-row"
        key={index}
        onClick={() => useHistory.push(`/account/quotes/details/${quote.id}?erpCustomerId=${erpCustomerId}`)}>
        <td className="quote-name">
          <DateBlock date={new Date(quote.createdAt)} />
        </td>
        <td>
          <QuoteDetails
            projectName={quote.name}
            quoteNumber={quote.crmQuoteNumber}
            subcontractorName={quote.subContractor.companyName}
            location={
              quote.location
                ? `${quote.location?.city ?? ''}${
                    quote.location?.stateProvince ? ` ,${quote.location.stateProvince}` : ''
                  }`
                : null
            }
            expirationDate={quote.expirationDate}
          />
        </td>
        <td className="quote-amount">{quote.totalAmount ? `$${formatQuoteCurrency(quote.totalAmount)}` : `$0.00`}</td>
        <td>
          <StatusCode status={quote.statusDisplayName} />
        </td>
      </tr>
    );
  });
};

export const NoResults = () => {
  return (
    <tbody className="quote-table-body">
      <tr className="no-results-row">
        <td> </td>
        <td> </td>
        <td className="no-results">No Quotes Found</td>
        <td> </td>
        <td> </td>
      </tr>
    </tbody>
  );
};

export const ChooseViewToShow = ({
  isLoading,
  suggestions,
  useHistory,
  erpCustomerId,
}: {
  isLoading: boolean;
  suggestions: any[];
  useHistory: any;
  erpCustomerId: number;
}) => {
  if (isLoading) {
    return <PlaceholderTable />;
  } else if (!suggestions || suggestions.length === 0) {
    return <NoResults />;
  }
  return <tbody className="quote-table-body">{renderResults(suggestions, useHistory, erpCustomerId)}</tbody>;
};

const DesktopPage = ({quoteSuggestions, isLoading}: {quoteSuggestions: any[]; isLoading: boolean}) => {
  const history = useHistory();
  const currentCustomer = useTypedSelector(selectCurrentCustomer);
  return (
    <div className="quote-table">
      <Table bordered={false} borderless striped className="table">
        <thead className="quote-table-header">
          <tr>
            <th className="quote-table-header-cell date">
              <span className="quote-table-header">DATE</span>
            </th>
            <th className="quote-table-header-cell details">
              <span className="quote-table-header">DETAILS</span>
            </th>
            <th className="quote-table-header-cell amount">
              <span className="quote-table-header">AMOUNT</span>
            </th>
            <th className="quote-table-header-cell status">
              <span className="quote-table-header">STATUS</span>
            </th>
          </tr>
        </thead>
        <ChooseViewToShow
          isLoading={isLoading}
          suggestions={quoteSuggestions}
          useHistory={history}
          erpCustomerId={currentCustomer.erpCustomerId}
        />
      </Table>
    </div>
  );
};

export default DesktopPage;
