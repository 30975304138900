import React, {useCallback, useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {Col, Container, Row} from 'react-bootstrap';
import {CartPage} from './Cart.styled';
import {hasPermission} from '../../../store/permission/permission.service';
import {useAppDispatch, useTypedSelector} from '../../../hooks/store';
import {
  saveOrderDetailsToCart,
  selectActiveCart,
  selectCartCalculationsComplete,
  selectIsMovingItemToCart,
  selectIsMovingItemToSaveForLater,
  selectSavedItems,
  setCartCalculationsComplete,
  setIsMovingItemToCart,
  setIsMovingItemToSaveForLater,
} from '../../../features/cart/slice/cart.slice';
import {selectUiSettings} from '../../../features/environment/slice/environment.slice';
import {
  addBatchCartItems,
  addCartVoucher,
  calculateStandartDiscounts,
  cartItemsAlphabetized,
  getBillToSiteByOperatingUnitCode,
  getCart,
  getCartCheckoutInvalidateStatus,
  getCartItemsCount,
  moveCartItemToSaved,
  moveSavedItemToCart,
  removeSavedItem,
} from '../../../features/cart/service/cart.service';
import {
  pushViewCartEventToDataLayer,
  pushRemoveFromCartEventToDataLayer,
} from '../../../features/analytics/service/analytics.service';
import {getForage, setForage} from '../../../Framework/Services/localForage';
import {
  getCartItemsProductType,
  isFabricatedCart,
  isSurfacesCart,
} from '../../../features/productType/service/productType.service';
import {
  selectCurrentCustomer,
  selectCurrentCustomerBillToSites,
  selectCurrentCustomerShipToSites,
} from '../../../features/customer/slice/customer.slice';
import {selectActiveProgram, selectAvailablePrograms} from '../../../features/salesforce/slice/salesforce.slice';
import {selectCurrentUser} from '../../../features/auth/slice/authentication.slice';
import {
  hideFullscreenLoader,
  showFullscreenLoader,
} from '../../../features/fullscreenLoader/slice/fullscreenLoader.slice';
import {addHeaderAdjustment} from '../../../features/pricing/service/pricing.service';
import {getUploadedFilesAsync, selectUploadedFiles} from '../../../features/file/slice/file.slice';
import {toast} from 'react-toastify';
import {filterUserFiles} from '../../../features/file/service/file.service';
import AddQuoteModal from '../../../Core/Components/Modals/AddQuoteModal';
import {selectQuoteOperationInProgress, setQuoteOperationInProgress} from '../../../features/quote/slice/quote.slice';
import {selectSelectedWarehouse} from '../../../features/warehouse/slice/warehouse.slice';
import {Cart as CartType, CartItem} from '../../../features/cart/ICartState';
import {getProduct} from '../../../features/productCatalog/service/productCatalog.service';
import CambriaButton from '../../../Framework/Components/CambriaButton';
import Icon from '../../../Framework/Components/Icon';
import {Form, Formik} from 'formik';
import * as Yup from 'yup';
import CambriaInput from '../../../Framework/Components/CambriaInput';
import {selectUserActions} from '../../../features/permission/slice/permission.slice';
import Discount from '../../../Core/Components/Discount';
import PriceAdjustments from '../../../Core/Components/PriceAdjustments';
import CambriaModal from '../../../Framework/Components/CambriaModal';
import FabricationCart from '../Account/OrderHistory/OrderDetails/FabricationCart';
import CartTotal from './CartTotal';
import SavedItems from './SavedItems';
import CartPlaceholders from './CartPlaceholders';
import {updateFabOrderCartDetails} from '../../../features/fabrication/service/fabrication.service';
import {mapCartItems} from '../../../features/itemMapper/service/itemMapper.service';
import {getOrderDetailsRequest} from '../../../features/order/orderDetails/controller/orderDetails.controller';
import {getHeaderAdjustmentRequest} from '../../../features/pricing/controller/pricing.controller';
import {getOperatingUnitCodeByProductType} from '../../../features/warehouse/service/warehouse.service';
import COMMERCE_CORE_CONSTANTS from '../../../Core/constants';
import CambriaTooltip from '../../../Framework/Components/CambriaTooltip';
import {useDisabledCheckout} from '../../../features/cart/hooks/useDisabledCheckout/useDisabledCheckout';
import {useDisabledPurchaseAgreement} from '../../../hooks/useDisabledPurchaseAgreement/useDisabledPurchaseAgreement';
import CambriaSelect from '../../../Framework/Components/CambriaSelect';

import {IFileRequest} from '../../../features/file/IFileState';
import {isBundleProgram} from '../../../features/salesforce/service/salesforce.service';
import BundleValidationsAlerts from '../Product/Details/Standard/BundleValidationsAlerts';
import CartItemsByBundle from './CartItemsByBundle';
import {applyQuoteDiscounts, saveQuote} from '../../../features/quote/service/quote.service';
import {useUpdateCartItemQuantity} from '../../../features/cart/hooks/useUpdateCartItemQuantity/useUpdateCartItemQuantity';
import {useRemoveCartButton} from '../../../features/cart/hooks/useRemoveCartButton/useRemoveCartButton';
import {useRemoveCartItem} from '../../../features/cart/hooks/useRemoveCartItem/useRemoveCartItem';

const Cart = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  let [hasInitialized, setHasInitialized] = useState(false);
  let [isInitializing, setIsInitializing] = useState(false);
  let [shouldSeeCommercialQuoting, setUseStateShouldSeeCommercialQuoting] = useState(false);
  let [hasPermissionsToSeeCommentsSection, setHasPermissionsToSeeCommentsSection] = useState(false);
  let [tempCartItemArray, setTempCartItemArray]: any = useState([]);
  let [discountCode, setDiscountCode] = useState('');
  let [disableApplyDiscountButton, setDisableApplyDiscountButton] = useState(false);
  let [discountCodeErrorMessage, setDiscountCodeErrorMessage] = useState('');
  let [isResubmitMode, setIsResubmitMode] = useState(false);
  let [isRevisionMode, setIsRevisionMode] = useState(false);
  let [shouldSeeResubmitMessage, setShouldSeeResubmitMessage] = useState(false);
  let [hasCartHeaderAdjustmentsPermission, setHasCartHeaderAdjustmentsPermission] = useState(false);
  let [adjustmentTypes, setAdjustmentTypes]: any = useState(null);
  let [selectedAdjustmentType, setSelectedAdjustmentType]: any = useState(null);
  let [adjustmentAmount, setAdjustmentAmount]: any = useState(0.01);
  let [adjustmentReasonText, setAdjustmentReasonText]: any = useState('');
  let [areUploadedFiles, setAreUploadedFiles] = useState(false);
  let [previousVersionOfComment, setPreviousVersionOfComment] = useState('');
  let [missingEdgeProfile, setMissingEdgeProfile] = useState(false);
  let [showClearShoppingCartModal, setShowClearShoppingCartModal] = useState(false);
  let [showAddQuoteModal, setShowAddQuoteModal] = useState(false);
  let [quoteType, setQuoteType] = useState('');

  let cart: CartType = useTypedSelector(selectActiveCart) as CartType;
  let uiSettings: any = useTypedSelector(selectUiSettings);
  let savedItems = useTypedSelector(selectSavedItems);
  let cartCalculationsComplete = useTypedSelector(selectCartCalculationsComplete);
  let quoteOperationInProgress = useTypedSelector(selectQuoteOperationInProgress);
  let customer = useTypedSelector(selectCurrentCustomer);
  let shipToSites: any = useTypedSelector(selectCurrentCustomerShipToSites);
  let billToSites: any = useTypedSelector(selectCurrentCustomerBillToSites);
  let activeProgram = useTypedSelector(selectActiveProgram);
  let availablePrograms = useTypedSelector(selectAvailablePrograms);
  let currentUser: any = useTypedSelector(selectCurrentUser);
  let uploadedFiles = useTypedSelector(selectUploadedFiles);
  let selectedWarehouse = useTypedSelector(selectSelectedWarehouse);
  let isMovingItemToSaveForLater = useTypedSelector(selectIsMovingItemToSaveForLater);
  let isMovingItemToCart = useTypedSelector(selectIsMovingItemToCart);
  let userActions = useTypedSelector(selectUserActions);

  const updateCartItemQuantity = useUpdateCartItemQuantity();
  const removeCartButton = useRemoveCartButton();
  const removeCartItem = useRemoveCartItem();

  const savedItemsArray = savedItems?.length > 0 ? cartItemsAlphabetized(savedItems) : [];

  const [disabledCheckout, disabledCheckoutMessage] = useDisabledCheckout();
  const [disabledPurchaseAgreement, disabledPurchaseAgreementMessage] = useDisabledPurchaseAgreement();
  const cartItemsCount = getCartItemsCount(cart);
  const isItAFabricatedCart = isFabricatedCart(cart);
  const isItASurfacesCart = isSurfacesCart(cart);
  const isFabOrSurfaceCart = isItAFabricatedCart || isItASurfacesCart;
  const showCreatePurchaseAgreementButton =
    cart?.transactionType !== 'QuoteEditCart' &&
    customer?.classGroups?.some((x: any) => x === 'Surfaces') &&
    isItASurfacesCart &&
    (cart?.cartItems[0].cartItemType === 'FabAndInstallDiscrete' ||
      cart?.cartItems[0].cartItemType === 'FabAndInstallStandard') &&
    hasPermission('urn:csa:commerceui:fabrication:purchaseagreement:create', userActions);
  const showCreateCIAQuoteButton =
    (isItAFabricatedCart || isItASurfacesCart) &&
    hasPermission('urn:csa:commerceui:requestStandardCiaQuote', userActions);
  const isBundleProgramSelected = isBundleProgram(activeProgram);
  const requireUploadedFiles = isFabOrSurfaceCart && !areUploadedFiles && uiSettings.requireFabricationFiles;
  const disableProceedToCheckoutButton =
    disabledCheckout || !cartCalculationsComplete || requireUploadedFiles || missingEdgeProfile;
  let disabledCheckoutTooltip = disabledCheckoutMessage;
  const disableCreatePurchaseAgreementButton =
    !cartCalculationsComplete || missingEdgeProfile || disabledPurchaseAgreement;
  const disabledPurchaseAgreementTooltip = disabledPurchaseAgreementMessage;
  const customerClassesThatShouldNotSeeQuotes = ['CDAL', 'LEXUS', 'DIS1', 'INTERNATIONAL'];

  const toggleShowClearShoppingCartModal = () => setShowClearShoppingCartModal((p) => !p);
  const toggleShowAddQuoteModal = () => setShowAddQuoteModal((p) => !p);

  const checkForEdgeProfiles = useCallback((cartItemsArray: any) => {
    for (const cartItem of cartItemsArray) {
      if (cartItem.mainPiece.productGroupCode !== 'GeneralItems') {
        if (cartItem.mainEdgeProfile.length === 0) {
          setMissingEdgeProfile(true);
          if (cartItem.mainPieceChildItems.length > 0) {
            checkForEdgeProfiles(cartItem.mainPieceChildItems);
          }
        }
      }
    }
  }, []);

  const setShouldSeeCommercialQuoting = useCallback(async (): Promise<boolean> => {
    const hasPermissionsForQuoting = await hasPermission('urn:csa:commerceui:requestCommercialQuote', userActions);

    const isClassThatIsForbidenToSeeQuotes = customerClassesThatShouldNotSeeQuotes.includes(cart?.customerClass);

    return (
      cart &&
      cart.cartItems &&
      cart.cartItems.length > 0 &&
      cart.cartItems[0].productType === 'Slab' &&
      cart.transactionType !== 'QuoteCart' &&
      cart.transactionType !== 'Resubmit' &&
      hasPermissionsForQuoting &&
      !isClassThatIsForbidenToSeeQuotes
    );
  }, [cart, userActions, customerClassesThatShouldNotSeeQuotes]);

  const initialize = useCallback(async (): Promise<void> => {
    setIsInitializing(true);
    dispatch(showFullscreenLoader({message: '', showCartIcon: true}));

    const tempShouldSeeCommercialQuoting = await setShouldSeeCommercialQuoting();
    setUseStateShouldSeeCommercialQuoting(tempShouldSeeCommercialQuoting);

    const tempHasPermissionsToSeeCommentsSection = await hasPermission(
      'urn:csa:commerceui:fabrication:addComments',
      userActions
    );
    setHasPermissionsToSeeCommentsSection(tempHasPermissionsToSeeCommentsSection);

    const hasCiaResubmitPermissions = await hasPermission('urn:csa:commerceui:ciaResubmitOrder', userActions);

    const indexDbCartItemArray = await getForage('quickSampleItems');
    if (indexDbCartItemArray && indexDbCartItemArray.cartId === cart.id) {
      setTempCartItemArray(indexDbCartItemArray.quickSampleOrderCartItemArray || []);
    } else {
      await setForage('quickSampleItems', {
        cartId: cart.id,
        quickSampleOrderCartItemArray: tempCartItemArray,
      });
    }

    if (cart && cart.id) {
      await getCartCheckoutInvalidateStatus(cart.id);
    }

    const fabItems = cart.cartItems.filter(
      (x: any) => x.cartItemType === 'FabAndInstallStandard' || x.cartItemType === 'FabAndInstallDiscrete'
    );
    const cartItemsArray = mapCartItems(cart, fabItems);
    checkForEdgeProfiles(cartItemsArray);

    const tempIsResbmitMode = cart.transactionType === 'Resubmit';
    const tempIsRevisionMode = cart.transactionType === 'Revision';
    setIsResubmitMode(tempIsResbmitMode);
    setIsRevisionMode(tempIsRevisionMode);
    setShouldSeeResubmitMessage(tempIsResbmitMode && !hasCiaResubmitPermissions);

    dispatch(setCartCalculationsComplete(false));
    await calculateStandartDiscounts(
      cart,
      customer,
      currentUser.userId,
      activeProgram,
      shipToSites,
      billToSites,
      dispatch,
      availablePrograms,
      uiSettings?.calculateCartDiscountsInBackend
    );
    dispatch(setCartCalculationsComplete(true));

    const tempHasCartheaderadjustmentsPermission = hasPermission(
      'urn:csa:commerceui:cartheaderadjustments',
      userActions
    );
    setHasCartHeaderAdjustmentsPermission(tempHasCartheaderadjustmentsPermission);

    const tempAdjustmentTypes = await getHeaderAdjustmentRequest();
    setAdjustmentTypes(tempAdjustmentTypes);

    if (isFabOrSurfaceCart && uiSettings.requireFabricationFiles) {
      const params: IFileRequest = {
        externalId: cart.id,
        erpCustomerId: Number(cart.erpCustomerId),
        groupName: 'Fabrication',
        limit: 1,
        sort: 'createdAt',
      };
      dispatch(getUploadedFilesAsync({params}));
    }

    dispatch(hideFullscreenLoader());

    setIsInitializing(false);
  }, [
    activeProgram,
    billToSites,
    cart,
    checkForEdgeProfiles,
    currentUser,
    customer,
    dispatch,
    setShouldSeeCommercialQuoting,
    shipToSites,
    tempCartItemArray,
    uiSettings,
    userActions,
    availablePrograms,
    isFabOrSurfaceCart,
  ]);

  const saveComments = async (saveComment: string) => {
    if (hasPermissionsToSeeCommentsSection) {
      saveComment = saveComment.trim();
      if (
        isFabOrSurfaceCart &&
        ((saveComment && saveComment.length > 0) || (!saveComment && previousVersionOfComment))
      ) {
        if (previousVersionOfComment === saveComment) {
          return;
        }
        let orderDetails = null;
        try {
          if (cart.id) {
            orderDetails = await getOrderDetailsRequest(cart.id);
          }
        } catch (error: any) {
          if (error.status === 404) {
            orderDetails = null;
          } else {
            console.error(error);
            toast.error('There was a problem while retrieving cart details.');
          }
        }

        if (orderDetails) {
          orderDetails.fabricationInstructions = saveComment;
        } else {
          orderDetails = {
            cartId: cart.id,
            fabricationInstructions: saveComment,
          };
        }
        await updateFabOrderCartDetails(orderDetails, dispatch);
        const tempCart = await getCart(
          cart.id,
          customer,
          currentUser?.userId,
          activeProgram,
          shipToSites,
          billToSites,
          dispatch,
          availablePrograms
        );
        dispatch(saveOrderDetailsToCart(tempCart));
        setPreviousVersionOfComment(saveComment);
        toast.success('Instructions saved successfully.');
      }
    }
  };

  const updateQuantity = async (cartItem: CartItem, value: any): Promise<any> => {
    setDiscountCodeErrorMessage('');

    await updateCartItemQuantity(cartItem, value);

    if (cart && cart.cartItems && cart.cartItems.length === 0 && cart.transactionType !== 'QuoteEditCart') {
      await removeCartButton();
    } else {
      await initialize();
    }
  };

  const addCachedCartItemsToCart = async () => {
    await addBatchCartItems(
      tempCartItemArray,
      cart,
      customer,
      shipToSites,
      billToSites,
      currentUser,
      activeProgram,
      dispatch,
      availablePrograms
    );
    setTempCartItemArray([]);
    setForage('quickSampleItems', {
      cartId: cart.id,
      quickSampleOrderCartItemArray: [],
    });
  };

  const removeCartItemButton = async (cartItem: CartItem): Promise<void> => {
    setDiscountCodeErrorMessage('');
    await removeCartItem(cartItem);
  };

  const removeSavedItemButton = async (savedItem: CartItem): Promise<any> => {
    try {
      dispatch(showFullscreenLoader({showCartIcon: true}));
      await removeSavedItem(
        savedItem,
        cart,
        savedItems,
        customer,
        activeProgram,
        shipToSites,
        currentUser.userId,
        billToSites,
        dispatch
      );
      toast.success(`${savedItem.description} was removed from saved items.`);
    } catch (error: any) {
      toast.error(error);
    } finally {
      dispatch(hideFullscreenLoader());
    }
  };

  const saveForLater = async (cartItem: CartItem): Promise<any> => {
    try {
      dispatch(setIsMovingItemToSaveForLater(true));
      dispatch(showFullscreenLoader({showCartIcon: true}));
      setDiscountCodeErrorMessage('');
      const getProductPromise = getProduct(cartItem.productId, customer, shipToSites);
      await moveCartItemToSaved(
        cartItem,
        cart,
        savedItems,
        customer,
        activeProgram,
        shipToSites,
        currentUser?.userId,
        billToSites,
        dispatch,
        availablePrograms
      );
      const product = await getProductPromise;
      pushRemoveFromCartEventToDataLayer([
        {
          cartItem,
          product,
          quantity: cartItem.quantity ?? 1,
        },
      ]);
      await initialize();
      toast.success(`${cartItem.description} is saved for later.`);
      if (cart.cartItems.length === 0) {
        await removeCartButton();
      } else {
        await initialize();
      }
    } catch (error: any) {
      let callbackError = '';
      if (error && error.text) {
        callbackError = await error.text();
      }
      if (error.status === 400 && callbackError.includes('Your cart is locked into quote')) {
        toast.error(callbackError);
      } else {
        toast.error(error);
      }
    } finally {
      dispatch(setIsMovingItemToSaveForLater(false));
      dispatch(hideFullscreenLoader());
    }
  };

  const moveToCart = async (savedItem: CartItem): Promise<void> => {
    try {
      setDiscountCodeErrorMessage('');
      dispatch(setIsMovingItemToCart(true));
      dispatch(showFullscreenLoader({showCartIcon: true}));
      if (hasVouchers()) {
        dispatch(setCartCalculationsComplete(false));
      }
      let tempSavedItem = JSON.parse(JSON.stringify(savedItem));
      if (!savedItem.operatingUnitCode) {
        tempSavedItem.operatingUnitCode = await getOperatingUnitCodeByProductType(
          savedItem.productType,
          customer,
          shipToSites
        );
      }
      const product = await getProduct(savedItem.productId, customer, shipToSites);
      const operatingUnit = await getOperatingUnitCodeByProductType(product.productType, customer, shipToSites);
      const billToSite = await getBillToSiteByOperatingUnitCode(operatingUnit, billToSites);

      tempSavedItem.siteUseId = billToSite.siteUseId;
      tempSavedItem.programCode = activeProgram.code;
      tempSavedItem.programName = activeProgram.name;
      const response = await moveSavedItemToCart(
        tempSavedItem,
        cart,
        customer,
        shipToSites,
        billToSites,
        currentUser?.userId,
        activeProgram,
        dispatch,
        availablePrograms
      );
      await initialize();
      if (response === 'The current item must ship from MN Samples.') {
        toast.success(
          `${savedItem.description} is moved to cart.` +
            ` ${response} Any additional items included with this order will also ship from MN Samples.`
        );
      } else if (response === 'This item will be placed in your Save for Later items.') {
        toast.warning(
          `The current item must ship from a different location than items in the cart.` +
            ` This item will be placed in your Save for Later items and can be ordered separately` +
            ` once the current order is completed.`
        );
      } else {
        toast.success(`${savedItem.description} is moved to cart.`);
      }
    } catch (error: any) {
      let callbackError = '';
      if (error && error.text) {
        callbackError = await error.text();
      }

      if (error.toString() === 'Error: Cart already contains items of different product type.') {
        const productType = getCartItemsProductType(cart);
        toast.error(
          `Your shopping cart currently contains ${productType}` +
            ` products that cannot be combined with ${savedItem.productType} products.` +
            ` The item has been moved back to your Save for Later items and can be purchased separately` +
            ` once the ${productType} request is completed.`
        );
      } else if (error.status === 400 && callbackError.includes('Your cart is locked into quote')) {
        toast.error(callbackError);
      } else {
        toast.error(error);
      }
    } finally {
      dispatch(setCartCalculationsComplete(true));
      dispatch(hideFullscreenLoader());
      dispatch(setIsMovingItemToCart(false));
    }
  };

  const hasVouchers = (): boolean => {
    return cart && cart.voucherCodes && cart.voucherCodes.length > 0;
  };

  const checkout = async () => {
    if (discountCode !== '') {
      setDiscountCodeErrorMessage('Unused promotion code. Remove code or press apply button.');
      return;
    }

    setDiscountCodeErrorMessage('');

    if (cart.cartItems.length < 1) {
      await initialize();
    }

    if (
      (!uiSettings?.fabricationOrderDetailsCheckoutIsActive &&
        cart.cartItems[0].cartItemType === 'FabAndInstallDiscrete') ||
      (!uiSettings?.fabricationOrderDetailsCheckoutIsActive &&
        cart.cartItems[0].cartItemType === 'FabAndInstallStandard')
    ) {
      history.push(COMMERCE_CORE_CONSTANTS.PATHNAMES.shippingAndDelivery);
    } else {
      history.push(COMMERCE_CORE_CONSTANTS.PATHNAMES.orderDetails);
    }
  };

  const createPurchaseAgreement = async () => {
    if (discountCode !== '') {
      setDiscountCodeErrorMessage('Unused promotion code. Remove code or press apply button.');
      return;
    }

    setDiscountCodeErrorMessage('');

    if (cart.cartItems.length < 1) {
      await initialize();
    }

    history.push(COMMERCE_CORE_CONSTANTS.PATHNAMES.purchasingAgreement);
  };

  const addMore = (): void => {
    history.push(COMMERCE_CORE_CONSTANTS.PATHNAMES.home);
  };

  const clearShoppingCart = async () => {
    if (cart.transactionType !== 'QuoteEditCart') {
      toggleShowClearShoppingCartModal();
    }

    if (!cartCalculationsComplete || quoteOperationInProgress) {
      return false;
    }

    try {
      dispatch(setQuoteOperationInProgress(true));
      await removeCartButton();
    } catch (e) {
      console.log(e);
    } finally {
      dispatch(setQuoteOperationInProgress(false));
    }
  };

  const applyDiscount = async (): Promise<void> => {
    if (discountCode === '') {
      setDiscountCodeErrorMessage('Please submit a discount code.');
      return;
    } else {
      setDiscountCodeErrorMessage('');
      dispatch(showFullscreenLoader({showCartIcon: true}));
      setDisableApplyDiscountButton(true);
    }

    try {
      await addCartVoucher(
        cart,
        discountCode,
        customer,
        currentUser.userId,
        activeProgram,
        shipToSites,
        billToSites,
        dispatch,
        availablePrograms
      );
      toast.success(`Promo code ${discountCode} has been applied!`);
      setDiscountCode('');
      await initialize();
    } catch (e: any) {
      if (e.failureReason === 'quantity exceeded') {
        toast.error('The voucher code use quantity has been exceeded.');
      } else if (e.timeout) {
        toast.error('Took too long for the server to respond. Try again later.');
      } else {
        toast.error(e.failureReason);
        setDiscountCode('');
      }
    } finally {
      setDisableApplyDiscountButton(false);
      dispatch(hideFullscreenLoader());
    }
  };

  const applyAdjustmentAmount = async () => {
    if (!adjustmentAmount || !selectedAdjustmentType || !adjustmentReasonText) {
      toast.warning('Invalid entry. Please ensure all fields contain valid values before click apply button.');
      return;
    }

    try {
      dispatch(showFullscreenLoader({showCartIcon: true}));
      await addHeaderAdjustment(
        selectedAdjustmentType.id,
        Math.trunc(adjustmentAmount * 100),
        cart.id,
        adjustmentReasonText,
        customer,
        currentUser.id,
        activeProgram,
        shipToSites,
        billToSites,
        availablePrograms,
        dispatch
      );
      toast.success(`${selectedAdjustmentType.type} adjustment has been applied to the cart!`);
      setAdjustmentAmount(0.01);
      setAdjustmentReasonText('');
      await initialize();
    } catch (e) {
      toast.error(`${selectedAdjustmentType.type} adjustment failed to apply to cart, please try again.`);
    } finally {
      dispatch(hideFullscreenLoader());
    }
  };

  const saveQuoteButton = async () => {
    saveQuote(
      cart,
      customer,
      billToSites,
      shipToSites,
      currentUser,
      availablePrograms,
      activeProgram,
      selectedWarehouse,
      dispatch,
      history,
      isFabOrSurfaceCart,
      cartCalculationsComplete,
      quoteOperationInProgress
    );

    if (cart && cart.cartItems && !isFabOrSurfaceCart) {
      for (let cartItem of cart.cartItems) {
        if (cartItem.quantity !== cartItem.tempQuantity) {
          await updateQuantity(cartItem, cartItem.tempQuantity);
        }
      }
    }
  };

  useEffect(() => {
    if (uploadedFiles && uploadedFiles.length > 0 && !areUploadedFiles) {
      const dwgFiles = filterUserFiles(uploadedFiles, 'dwg');

      setAreUploadedFiles(dwgFiles.length > 0);
      if (dwgFiles.length <= 0) {
        toast.error(
          'Please attach a DWG file to the order to proceed to checkout. You do not have a DWG file attached to your cart.'
        );
      }
    }
  }, [areUploadedFiles, uploadedFiles]);

  useEffect(() => {
    if (!hasInitialized) {
      setHasInitialized(true);
      initialize();
      pushViewCartEventToDataLayer(cart);
    }
  }, [hasInitialized, initialize, cart]);

  useEffect(() => {
    if (cart) {
      applyQuoteDiscounts(cart, dispatch);
    }
  }, [cart, dispatch]);

  return (
    <>
      {!isInitializing && cart && cart.transactionType ? (
        <div className="react-bootstrap-hack cambria-main-content">
          <CartPage>
            <Container className="cart-container">
              <div className="cart">
                {cart.transactionType !== 'QuoteEditCart' ? (
                  <Row className="continue-shopping-button-wrapper">
                    <Col xs={12}>
                      <div className="pull-left">
                        <button
                          type="button"
                          data-testid={'continue-shopping-button'}
                          className="btn btn-link sub-text p-x-0"
                          onClick={() => {
                            addMore();
                          }}>
                          <em className="fa fa-chevron-left"></em>
                          &nbsp; CONTINUE SHOPPING
                        </button>
                      </div>
                    </Col>
                  </Row>
                ) : (
                  <div></div>
                )}
                {cartItemsCount === 0 ? (
                  <div className="shopping-cart-header-text-wrapper">
                    {cart.transactionType === 'QuoteEditCart' ? (
                      <Row>
                        <Col sm={12} lg={12}>
                          <div className="cart-header-text-container">
                            <div className="cart-header-text">
                              You are in the process of editing the quote {cart.quoteName}
                            </div>
                            <div>
                              If you do not wish to edit this quote, proceed to the cart and select “Cancel Quote Edit”
                            </div>
                            <div>Edit this quote by changing the items in this cart</div>
                          </div>
                        </Col>
                      </Row>
                    ) : (
                      <div></div>
                    )}

                    <h1 className="text-serif shopping-cart-header-text">Shopping Cart (0 Items)</h1>
                    <div className="cart-header-text-container">
                      <div className="cart-header-text">Your Shopping Cart is empty.</div>
                      {savedItems.length > 0 ? (
                        <div>
                          You have items saved to buy later. To buy one or more now, click Move to Cart next to the
                          item.
                        </div>
                      ) : (
                        <div></div>
                      )}
                      {savedItems.length === 0 ? (
                        <div>
                          Continue shopping from the{' '}
                          <button
                            type="button"
                            className="btn btn-link sub-text p-x-0"
                            onClick={() => {
                              history.push(COMMERCE_CORE_CONSTANTS.PATHNAMES.home);
                            }}>
                            Home page
                          </button>
                          .
                        </div>
                      ) : (
                        <div></div>
                      )}
                    </div>
                    {tempCartItemArray.length !== 0 ? (
                      <Col xs={12}>
                        <CambriaButton
                          className="btn btn-primary low-res-padding col-xs-12 batch-add-button"
                          data-testid="add-total-saved-button"
                          type="submit"
                          onClick={() => {
                            addCachedCartItemsToCart();
                          }}>
                          {'Add Total Saved ' +
                            tempCartItemArray[0].productType +
                            ' to Cart (' +
                            tempCartItemArray.length +
                            ')'}
                        </CambriaButton>
                      </Col>
                    ) : (
                      <div></div>
                    )}
                    {cart.transactionType === 'QuoteEditCart' ? (
                      <Row>
                        <div className="col-lg-6 hidden-xs hidden-sm"></div>
                        <Col xs={12} sm={12} lg={3} className="p-t-sm">
                          <CambriaButton
                            disabled={
                              !cartCalculationsComplete ||
                              ((isMovingItemToSaveForLater || quoteOperationInProgress) &&
                                cart.transactionType === 'QuoteEditCart')
                            }
                            id={'cancel-quote-edit-button'}
                            className="btn btn-block btn-default ripple"
                            onClick={() => {
                              clearShoppingCart();
                            }}>
                            <Icon icon="fa fa-times" color="#eee" size="15" weight="bold" />
                            CANCEL QUOTE EDIT
                          </CambriaButton>
                        </Col>
                        <Col xs={12} sm={12} lg={3} className="p-t-sm">
                          <CambriaButton
                            disabled={true}
                            className="btn btn-type-a btn-primary"
                            type="button"
                            id="commerce-proceed-to-checkout-bottom"
                            onClick={() => {}}>
                            <Icon icon="icon icons-cambria-Ui-Store" color="#eee" size="15" weight="bold" />
                            SAVE QUOTE
                          </CambriaButton>
                        </Col>
                      </Row>
                    ) : (
                      <div></div>
                    )}
                  </div>
                ) : (
                  <div></div>
                )}
                {cartItemsCount ? (
                  <div>
                    <Row className="shopping-cart-header-text-wrapper">
                      <Col xs={12} md={6} lg={8}>
                        <h1 className="text-serif shopping-cart-header-text">
                          {`Shopping Cart ( ${cartItemsCount} Item`}
                          {cartItemsCount > 1 ? <span>s</span> : <span></span>}
                          {`)`}
                        </h1>
                      </Col>
                      {cart.transactionType !== 'QuoteEditCart' ? (
                        <Col xs={12} md={6} lg={4}>
                          <CambriaTooltip position="up-right" size="medium" tooltip={disabledCheckoutTooltip}>
                            <CambriaButton
                              disabled={disableProceedToCheckoutButton}
                              className="btn btn-type-a btn-primary pull-right"
                              id={'top-proceed-to-checkout-button'}
                              type="button"
                              onClick={() => {
                                checkout();
                              }}
                              button-id="commerce-proceed-to-checkout-top">
                              <Icon icon="icon icons-cambria-Ui-Store" color="#eee" size="15" weight="bold" />
                              PROCEED TO CHECKOUT
                            </CambriaButton>
                          </CambriaTooltip>
                        </Col>
                      ) : (
                        <div></div>
                      )}
                      {(isResubmitMode || isRevisionMode) && !shouldSeeResubmitMessage ? (
                        <Col sm={12} lg={12}>
                          <div className="cart-header-text-container">
                            <div className="cart-header-text">
                              You are in the process of revising the order details for order{' '}
                              {isRevisionMode && cart && cart.orderDetails && cart.orderDetails.length > 0
                                ? cart.orderDetails[0].originalSalesOrderNumber
                                : cart.orderNumber}
                              .
                            </div>
                            <div>
                              If you do not wish to make revisions to this order, proceed to the cart and select "Clear
                              Shopping Cart"
                            </div>
                          </div>
                        </Col>
                      ) : (
                        <div></div>
                      )}
                      {cart.transactionType === 'QuoteCart' ? (
                        <Col sm={12} lg={12}>
                          <div className="cart-header-text-container">
                            <div className="cart-header-text">
                              Your cart is locked into quote. Please edit the items through the quote editing process.
                            </div>
                            <div>To order different items, click the 'Clear Shopping Cart' button.</div>
                          </div>
                        </Col>
                      ) : (
                        <div></div>
                      )}
                      {cart.transactionType === 'QuoteEditCart' ? (
                        <Col sm={12} lg={12}>
                          <div className="cart-header-text-container">
                            <div className="cart-header-text">
                              You are in the process of editing the quote {cart.quoteName}
                            </div>
                            <div>
                              If you do not wish to edit this quote, proceed to the cart and select “Cancel Quote Edit”
                            </div>
                            <div>Edit this quote by changing the items in this cart</div>
                          </div>
                        </Col>
                      ) : (
                        <div></div>
                      )}
                      {shouldSeeResubmitMessage ? (
                        <Col sm={12} lg={12}>
                          <div className="cart-header-text-container">
                            <div className="cart-header-text">
                              Your cart is in Resubmit mode for order number {cart.orderNumber}.
                            </div>
                            <div>To cancel this request, click the 'Clear Shopping Cart' button.</div>
                          </div>
                        </Col>
                      ) : (
                        <div></div>
                      )}
                    </Row>
                    {tempCartItemArray.length !== 0 &&
                    cart.cartItems[0].productType === tempCartItemArray[0].productType ? (
                      <Col xs={12}>
                        <CambriaButton
                          className="btn btn-primary low-res-padding col-xs-12 batch-add-button"
                          type="submit"
                          id={'add-total-saved-lower-button'}
                          onClick={() => {
                            addCachedCartItemsToCart();
                          }}>
                          {'Add Total Saved ' +
                            tempCartItemArray[0].productType +
                            ' to Cart (' +
                            tempCartItemArray.length +
                            ')'}
                        </CambriaButton>
                      </Col>
                    ) : (
                      <div></div>
                    )}
                    {isBundleProgramSelected && (
                      <Row className="mt-5">
                        <BundleValidationsAlerts
                          bundleValidation={cart?.bundleValidations?.find((v) => !v.isComplete)}
                        />
                      </Row>
                    )}
                    {!isFabOrSurfaceCart ? (
                      <div className="items p-t-0">
                        <CartItemsByBundle
                          isRevisionMode={isRevisionMode}
                          removeCartItemButton={removeCartItemButton}
                          saveForLater={saveForLater}
                          updateQuantity={updateQuantity}
                        />
                        <CartTotal cart={cart}></CartTotal>
                      </div>
                    ) : (
                      <div></div>
                    )}
                    {isFabOrSurfaceCart ? (
                      <div>
                        <Row>
                          <Col xs={12}>
                            <FabricationCart
                              cart={cart}
                              shouldUseOrderMetadata={false}
                              fabOrderPage={false}></FabricationCart>
                          </Col>
                        </Row>
                        {isFabOrSurfaceCart && hasPermissionsToSeeCommentsSection ? (
                          <div className="comments-container">
                            <Formik
                              enableReinitialize
                              initialValues={{comments: cart?.orderDetails[0]?.fabricationInstructions || ''}}
                              validationSchema={Yup.object()}
                              onSubmit={() => {}}>
                              {(props) => {
                                return (
                                  <Form id="commentsForm" noValidate>
                                    <CambriaInput
                                      name="comments"
                                      label="Special Fabrication Instructions"
                                      type="textarea"
                                      placeholder="Add special fabrication instructions here..."
                                      onBlur={(event: any) => {
                                        saveComments(event.target.value);
                                      }}
                                    />
                                  </Form>
                                );
                              }}
                            </Formik>
                          </div>
                        ) : (
                          <div></div>
                        )}
                      </div>
                    ) : (
                      <div></div>
                    )}
                    <Row>
                      {cart.transactionType !== 'QuoteEditCart' ? (
                        <Col xs={12} sm={12} lg={4} className="proceed-to-checkout-button hidden-md hidden-lg">
                          <CambriaTooltip position="up-right" size="medium" tooltip={disabledCheckoutTooltip}>
                            <CambriaButton
                              disabled={disableProceedToCheckoutButton}
                              className="btn btn-type-a btn-primary pull-right"
                              type="button"
                              onClick={() => {
                                checkout();
                              }}
                              id="commerce-proceed-to-checkout-bottom">
                              <Icon icon="icon icons-cambria-Ui-Store" color="#eee" size="15" weight="bold" />
                              PROCEED TO CHECKOUT
                            </CambriaButton>
                          </CambriaTooltip>
                        </Col>
                      ) : (
                        <div></div>
                      )}
                      {showCreatePurchaseAgreementButton ? (
                        <Col xs={12} sm={12} lg={4} className="proceed-to-purchase-agreement hidden-md hidden-lg">
                          <CambriaTooltip position="up-right" size="medium" tooltip={disabledPurchaseAgreementTooltip}>
                            <CambriaButton
                              disabled={disableCreatePurchaseAgreementButton}
                              className="btn btn-type-a btn-primary pull-right"
                              type="button"
                              onClick={() => {
                                createPurchaseAgreement();
                              }}
                              id="commerce-proceed-to-purchase-agreement-bottom">
                              <Icon
                                icon="icon icons-cambria-General-Documents-46"
                                color="#eee"
                                size="15"
                                weight="bold"
                              />
                              CREATE PURCHASE AGREEMENT
                            </CambriaButton>
                          </CambriaTooltip>
                        </Col>
                      ) : (
                        <div></div>
                      )}
                      <Col xs={12} sm={12} lg={4}>
                        <CambriaButton
                          disabled={
                            !cartCalculationsComplete ||
                            ((isMovingItemToSaveForLater || quoteOperationInProgress) &&
                              cart.transactionType === 'QuoteEditCart')
                          }
                          id="clear-shopping-cart-button"
                          className="btn btn-block btn-default ripple clear-cart-button"
                          onClick={() => {
                            if (cart.transactionType === 'QuoteEditCart') {
                              clearShoppingCart();
                            } else {
                              toggleShowClearShoppingCartModal();
                            }
                          }}>
                          <Icon icon="icon icons-cambria-Ui-Multiply" color="#eee" size="15" weight="bold" />
                          {cart.transactionType !== 'QuoteEditCart' ? 'CLEAR SHOPPING CART' : 'CANCEL QUOTE EDIT'}
                        </CambriaButton>
                        <CambriaModal
                          show={showClearShoppingCartModal}
                          confirmButton={'CLEAR'}
                          cancelButton={'CANCEL'}
                          heading={'Clear Shopping Cart'}
                          toggleShow={toggleShowClearShoppingCartModal}
                          formName={'clear-cart'}>
                          <form
                            name={'clear-cart'}
                            id={'clear-cart'}
                            onSubmit={(e) => {
                              e.preventDefault();
                              clearShoppingCart();
                            }}>
                            Remove all items from order?
                          </form>
                        </CambriaModal>
                      </Col>
                      {showCreateCIAQuoteButton &&
                      cart.transactionType !== 'QuoteEditCart' &&
                      cart.transactionType !== 'QuoteCart' &&
                      cart.orderDetails &&
                      cart.orderDetails.length > 0 &&
                      cart.orderDetails[0].jobType?.includes('Commercial') ? (
                        <Col xs={12} sm={12} lg={4} className="text-center request-commercial-quote-button">
                          <button
                            type="button"
                            disabled={!cartCalculationsComplete}
                            className="btn btn-link sub-text p-x-0 btn-quotes"
                            id={'request-commercial-quote-button'}
                            onClick={() => {
                              setQuoteType('cia');
                              toggleShowAddQuoteModal();
                            }}>
                            REQUEST QUOTE
                          </button>
                        </Col>
                      ) : shouldSeeCommercialQuoting &&
                        cart.transactionType !== 'QuoteEditCart' &&
                        !isBundleProgramSelected ? (
                        <Col xs={12} sm={12} lg={4} className="text-center request-commercial-quote-button">
                          <button
                            type="button"
                            disabled={!cartCalculationsComplete}
                            className="btn btn-link sub-text p-x-0 btn-quotes"
                            id={'request-commercial-quote-button'}
                            onClick={() => {
                              setQuoteType('commercial');
                              toggleShowAddQuoteModal();
                            }}>
                            REQUEST COMMERCIAL QUOTE
                          </button>
                        </Col>
                      ) : (
                        <Col lg={4} className="hidden-xs hidden-sm"></Col>
                      )}
                      {cart.transactionType !== 'QuoteEditCart' ? (
                        <Col xs={12} sm={12} lg={4} className="proceed-to-checkout-button  hidden-xs hidden-sm">
                          <CambriaTooltip position="up-right" size="medium" tooltip={disabledCheckoutTooltip}>
                            <CambriaButton
                              disabled={disableProceedToCheckoutButton}
                              className="btn btn-type-a btn-primary pull-right"
                              type="button"
                              onClick={() => {
                                checkout();
                              }}
                              id="commerce-proceed-to-checkout-bottom">
                              <Icon icon="icon icons-cambria-Ui-Store" color="#eee" size="15" weight="bold" />
                              PROCEED TO CHECKOUT
                            </CambriaButton>
                          </CambriaTooltip>
                        </Col>
                      ) : (
                        <div></div>
                      )}
                      {showCreatePurchaseAgreementButton ? (
                        <Col
                          xs={12}
                          sm={12}
                          lg={4}
                          className="proceed-to-purchase-agreement hidden-xs hidden-sm col-lg-offset-8">
                          <CambriaTooltip position="up-right" size="medium" tooltip={disabledPurchaseAgreementTooltip}>
                            <CambriaButton
                              disabled={disableCreatePurchaseAgreementButton}
                              className="btn btn-type-a btn-primary pull-right"
                              type="button"
                              onClick={() => {
                                createPurchaseAgreement();
                              }}
                              id="commerce-proceed-to-purchase-agreement-bottom">
                              <Icon icon="icon icons-cambria-Ui-Store" color="#eee" size="15" weight="bold" />
                              CREATE PURCHASE AGREEMENT
                            </CambriaButton>
                          </CambriaTooltip>
                        </Col>
                      ) : (
                        <div></div>
                      )}
                      {cart.transactionType === 'QuoteEditCart' ? (
                        <Col xs={12} sm={12} lg={4}>
                          <CambriaButton
                            disabled={
                              !cartCalculationsComplete || isMovingItemToSaveForLater || quoteOperationInProgress
                            }
                            className="btn btn-type-a btn-primary"
                            type="button"
                            onClick={async () => {
                              saveQuoteButton();
                            }}
                            id="commerce-proceed-to-checkout-bottom">
                            <Icon icon="icon icons-cambria-Ui-Store" color="#eee" size="15" weight="bold" />
                            SAVE QUOTE
                          </CambriaButton>
                        </Col>
                      ) : (
                        <div></div>
                      )}
                    </Row>
                    <div className="discounts-container m-t-sm">
                      <Row className="p-x-0 m-a-0">
                        <Discount></Discount>
                        <Col md={6} xs={12} className="p-x-0">
                          {!isBundleProgramSelected && (
                            <Formik
                              enableReinitialize
                              initialValues={{discountCode: ''}}
                              validationSchema={Yup.object()}
                              onSubmit={() => {}}>
                              {(props) => {
                                return (
                                  <Form id="promo-code-form" noValidate>
                                    <CambriaInput
                                      label="Promo Code"
                                      name="discountCode"
                                      placeholder="Promo Code"
                                      type="text"
                                      onApply={applyDiscount}
                                      disableApply={disableApplyDiscountButton}
                                      onChange={(value: any) => {
                                        let code = value;
                                        if (typeof value === 'object') {
                                          code = value.target.value;
                                        }
                                        setDiscountCodeErrorMessage('');
                                        setDiscountCode(code.trim());
                                      }}
                                    />
                                  </Form>
                                );
                              }}
                            </Formik>
                          )}
                          {discountCodeErrorMessage ? (
                            <div className="discount-code-error-message">{discountCodeErrorMessage}</div>
                          ) : (
                            <div></div>
                          )}
                        </Col>
                      </Row>
                      {hasCartHeaderAdjustmentsPermission ? (
                        <Row className="p-x-0 m-a-0 margin-botom">
                          <PriceAdjustments></PriceAdjustments>
                          <Col xs={12} className="p-x-0">
                            <Formik
                              enableReinitialize
                              initialValues={{pricingAdjustments: '', adjustmentAmount: '', reasonText: ''}}
                              validationSchema={Yup.object()}
                              onSubmit={() => {}}>
                              {(props) => {
                                return (
                                  <Form id="cartItemQuantityForm" noValidate>
                                    <Col className="pricingAdjusmentContainer">
                                      <Row>
                                        <Col md={6} className="p-x-0 adjustmentselectBox">
                                          <CambriaSelect
                                            name="pricingAdjustments"
                                            defaultValue={''}
                                            label="Pricing Adjustments"
                                            placeholder="Select Pricing Adjustment"
                                            required={false}
                                            displayValue={'name'}
                                            items={adjustmentTypes && adjustmentTypes.length > 0 ? adjustmentTypes : []}
                                            onChange={setSelectedAdjustmentType}
                                          />
                                        </Col>
                                        <Col md={6} className="adjustmentAmountInputBox p-x-0">
                                          <CambriaInput
                                            type="number"
                                            placeholder="0.00"
                                            min={0.01}
                                            step="0.01"
                                            name="adjustmentAmount"
                                            onApply={applyAdjustmentAmount}
                                            onChange={(value: any) => {
                                              let adjAmount = value;
                                              if (typeof value === 'object') {
                                                adjAmount = value.target.value;
                                              }
                                              setAdjustmentAmount(adjAmount);
                                            }}
                                          />
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col xs={12} className="p-x-0">
                                          <CambriaInput
                                            name="reasonText"
                                            placeholder="Reason Text"
                                            type="text"
                                            max={255}
                                            onChange={(value: any) => {
                                              let rsnText = value;
                                              if (typeof value === 'object') {
                                                rsnText = value.target.value;
                                              }
                                              setAdjustmentReasonText(rsnText);
                                            }}
                                          />
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Form>
                                );
                              }}
                            </Formik>
                          </Col>
                        </Row>
                      ) : (
                        <div></div>
                      )}
                      <div className="clearfix"></div>
                    </div>
                  </div>
                ) : (
                  <div></div>
                )}
                {savedItems.length > 0 && !isFabOrSurfaceCart ? (
                  <div>
                    <h1 className="text-serif saved-items-header-text">
                      {`Saved For Later (${savedItems.length} Item`}
                      {savedItems.length > 1 ? <span>{`s`}</span> : <span></span>}
                      {`)`}
                    </h1>
                    <div className="items p-t-0">
                      <div className="row item-header hidden-xs m-x-0">
                        <strong className="col-md-2 p-x-0 product-quantity-text">PRODUCT</strong>
                        <Col md={10} className="p-x-0 hidden-sm">
                          <div className="col-md-offset-8 col-md-2 unit-price-header-text">
                            <span className="col-12 pull-left p-x-0">UNIT</span>
                            <span className="col-12 pull-left p-x-0">PRICE</span>
                          </div>
                        </Col>
                      </div>
                      <SavedItems
                        savedItemsArray={savedItemsArray}
                        isRevisionMode={isRevisionMode}
                        isMovingItemToCart={isMovingItemToCart}
                        removeSavedItemButton={removeSavedItemButton}
                        moveToCart={moveToCart}></SavedItems>
                    </div>
                  </div>
                ) : (
                  <div></div>
                )}
                <AddQuoteModal
                  show={showAddQuoteModal}
                  toggleShow={toggleShowAddQuoteModal}
                  type={quoteType}></AddQuoteModal>
              </div>
            </Container>
          </CartPage>
        </div>
      ) : (
        <CartPlaceholders></CartPlaceholders>
      )}
      ;
    </>
  );
};

export default Cart;
