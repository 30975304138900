import React from 'react';
import {BrowserRouter as Router} from 'react-router-dom';
import LoadingBar from 'react-redux-loading-bar';
import {useTypedSelector, useAppDispatch} from './hooks/store';
import {selectIsAuthenticated} from './features/auth/slice/authentication.slice';
import './App.scss';
import AuthenticatedApp from './App/AuthenticatedPages';
import UnauthenticatedApp from './App/UnauthenticatedPages';
import FullPageSpinner from './Framework/Components/FullPageSpinner';
import {setPostLoginState} from './features/postLoginState/slice/postLoginState.slice';
import FullscreenLoader from './Framework/Components/FullscreenLoader';
import {
  selectIsLoading,
  selectShowCartIcon,
  selectMessage,
} from './features/fullscreenLoader/slice/fullscreenLoader.slice';
import COMMERCE_CORE_CONSTANTS from './Core/constants';

export let postLoginState: any = null;

function App() {
  const dispatch = useAppDispatch();
  if (window.location.pathname !== COMMERCE_CORE_CONSTANTS.PATHNAMES.login) {
    dispatch(setPostLoginState(window.location.href));
  }

  const isAuthenticated = useTypedSelector(selectIsAuthenticated);
  const showFullscreenLoader = useTypedSelector(selectIsLoading);
  const showCartIcon = useTypedSelector(selectShowCartIcon);
  const fullscreenLoaderMessage = useTypedSelector(selectMessage);

  return (
    <React.Suspense fallback={<FullPageSpinner />}>
      <LoadingBar className="loading-bar" updateTime={100} maxProgress={99} />
      {showFullscreenLoader && <FullscreenLoader message={fullscreenLoaderMessage} shoppingCart={showCartIcon} />}
      <Router>
        {isAuthenticated ? (
          <div className="authenticated-layout">
            <AuthenticatedApp />
          </div>
        ) : (
          <div>
            <UnauthenticatedApp />
          </div>
        )}
      </Router>
    </React.Suspense>
  );
}

export default App;
