import React from 'react';
import MobileOrderCard from '../../../../OrderGrid/MobileOrderCard';
import MobileOrderCardsGridPlacholder from '../../../../OrderGrid/MobileOrderCard/placeholder';

const DecideWhatToRender = ({
  isLoading,
  suggestions,
  userId,
}: {
  isLoading: boolean;
  suggestions: any[];
  userId?: string;
}) => {
  if (isLoading) {
    return <MobileOrderCardsGridPlacholder />;
  } else if (!suggestions || suggestions.length === 0) {
    return <NoResults />;
  }
  return renderMobileResults(suggestions, userId);
};

const renderMobileResults = (suggestions: any[], userId?: string): JSX.Element => {
  return (
    <div>
      {suggestions.map((order) => {
        const userFavorite = order.favorites.length > 0 ? order.favorites.find((x: any) => x.userId === userId) : null;
        return (
          <MobileOrderCard
            key={order.orderNumber}
            date={new Date(order.createdAt)}
            createdBy={order.billToName}
            orderNumber={order.orderNumber}
            purchaseOrderNumber={order.purchaseOrderNumber}
            orderType={order.orderTypeDisplayName}
            shipToName={order.orderLineItems[0]?.shipToCustomerName}
            address1={order.orderLineItems[0]?.shipToAddress1}
            address2={order.orderLineItems[0]?.shipToAddress2}
            address3={order.orderLineItems[0]?.shipToAddress3}
            city={order.orderLineItems[0]?.shipToCity}
            state={order.orderLineItems[0]?.shipToStateProvinceCode}
            postalCode={order.orderLineItems[0]?.shipToPostalCode}
            status={order.statusDisplayName}
            amount={order.totalAmount}
            customerId={order.erpCustomerId}
            jobNumber={order.fabricationJobNumber ? order.fabricationJobNumber : null}
            favorite={userFavorite?.name}
          />
        );
      })}
    </div>
  );
};
const NoResults = () => {
  return <div className="no-results-found-mobile">No Orders Found</div>;
};

const MobilePage = ({
  orderSuggestions,
  isLoading,
  userId,
}: {
  orderSuggestions: any[];
  isLoading: boolean;
  userId?: string;
}) => {
  return (
    <div className="mobile-ui-container">
      <DecideWhatToRender isLoading={isLoading} suggestions={orderSuggestions} userId={userId} />
    </div>
  );
};
export default MobilePage;
