import React from 'react';
import {Col, Row} from 'react-bootstrap';
import ContentLoader from 'react-content-loader';
import {QuickPage} from '../Quick.styled';

export function QuickGridItemPlaceholders(props: {grayItem: any}) {
  return (
    <Row className={`item-row ${props.grayItem ? 'alternating-grid-color-gray' : ''}`}>
      <Col sm={5} xs={5} className=" quick-order-description-container placeholder-overflow-class">
        <ContentLoader width={150} height={20} animate={true} foregroundColor={'#dfdfdf'} backgroundColor={'#dfdfdf'}>
          <rect x="0" y="0" width="150" height="20" />
        </ContentLoader>
      </Col>
      <Col sm={7} xs={7}>
        <div className="quick-select-button-containers quick-select-button-containers-placeholders">
          <ContentLoader width={32} height={32} animate={true} foregroundColor={'#dfdfdf'} backgroundColor={'#dfdfdf'}>
            <rect x="0" y="0" width="32" height="32" />
          </ContentLoader>
        </div>
        <div className="quick-select-button-containers quick-select-button-containers-placeholders">
          <ContentLoader width={32} height={32} animate={true} foregroundColor={'#dfdfdf'} backgroundColor={'#dfdfdf'}>
            <rect x="0" y="0" width="32" height="32" />
          </ContentLoader>
        </div>
        <div className="quick-select-button-containers quick-select-button-containers-placeholders">
          <ContentLoader width={32} height={32} animate={true} foregroundColor={'#dfdfdf'} backgroundColor={'#dfdfdf'}>
            <rect x="0" y="0" width="32" height="32" />
          </ContentLoader>
        </div>
      </Col>
    </Row>
  );
}

export default function QuickPlaceholders() {
  return (
    <QuickPage>
      <section className="quick-product-details container">
        <div className=" product">
          <Col md={8} xs={12} className="col-md-offset-2  product-details">
            <Row className="product-details-quick-attribute-select-row">
              <Col md={6} xs={12} className="quick-attribute-select-containers placeholder-overflow-class">
                <ContentLoader
                  width={1000}
                  height={40}
                  animate={true}
                  foregroundColor={'#dfdfdf'}
                  backgroundColor={'#dfdfdf'}>
                  <rect x="0" y="0" width="1000" height="40" />
                </ContentLoader>
              </Col>
              <Col md={6} xs={12} className="quick-attribute-select-containers placeholder-overflow-class">
                <ContentLoader
                  width={1000}
                  height={40}
                  animate={true}
                  foregroundColor={'#dfdfdf'}
                  backgroundColor={'#dfdfdf'}>
                  <rect x="0" y="0" width="1000" height="40" />
                </ContentLoader>
              </Col>
              <Col md={6} xs={12} className="quick-attribute-select-containers placeholder-overflow-class">
                <ContentLoader
                  width={1000}
                  height={40}
                  animate={true}
                  foregroundColor={'#dfdfdf'}
                  backgroundColor={'#dfdfdf'}>
                  <rect x="0" y="0" width="1000" height="40" />
                </ContentLoader>
              </Col>
            </Row>
            <div className="quick-batch-add-button-container placeholder-overflow-class">
              <ContentLoader
                width={2000}
                height={40}
                animate={true}
                foregroundColor={'#dfdfdf'}
                backgroundColor={'#dfdfdf'}>
                <rect x="0" y="0" width="2000" height="60" />
              </ContentLoader>
            </div>
            <div>
              <Col xs={12}>
                <QuickGridItemPlaceholders grayItem={true}></QuickGridItemPlaceholders>
                <QuickGridItemPlaceholders grayItem={false}></QuickGridItemPlaceholders>
                <QuickGridItemPlaceholders grayItem={true}></QuickGridItemPlaceholders>
                <QuickGridItemPlaceholders grayItem={false}></QuickGridItemPlaceholders>
                <QuickGridItemPlaceholders grayItem={true}></QuickGridItemPlaceholders>
                <QuickGridItemPlaceholders grayItem={false}></QuickGridItemPlaceholders>
                <QuickGridItemPlaceholders grayItem={true}></QuickGridItemPlaceholders>
                <QuickGridItemPlaceholders grayItem={false}></QuickGridItemPlaceholders>
              </Col>
            </div>
          </Col>
        </div>
      </section>
    </QuickPage>
  );
}
