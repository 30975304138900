import styled from 'styled-components/macro';
import {$newblack, $newprimary, $newwhite, $screenmd} from '../../Styled/variables';

export const UnathenticatedContainer = styled.div`
  position: relative;
  background-color: ${$newblack};
  height: 100vh;
  margin: 0;
  padding: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: column;
  flex-flow: column;
  -ms-flex-pack: start;
  justify-content: flex-start;
  font-family: Montserrat, sans-serif;

  .background-content {
    z-index: 0;

    @media (max-width: ${$screenmd}) {
      top: 50px;
    }

    picture {
      min-width: 100%;
      min-height: 100%;
      display: block;

      img {
        width: 100%;
      }
    }
  }

  .main-content {
    position: absolute;
    top: 100px;
    left: 0;
    right: 0;
    width: fit-content;
    margin: 15px auto;
    flex: 1;
    z-index: 1;

    .transparent-box {
      color: ${$newwhite};
      padding: 60px 140px;
      border-radius: 3px;
      background: rgba(0, 0, 0, 0.5);
      max-width: none;
    }

    .user-login {
      margin-bottom: 30px;

      @media (max-width: ${$screenmd}) {
        padding: 22px;
        margin: 13px;
        margin-bottom: 15px;
      }
    }

    .customer-care {
      @media (max-width: ${$screenmd}) {
        padding: 22px;
        margin: 13px;
      }

      > div {
        max-width: 510px;
        font-weight: 300;
        font-size: 14px;
        line-height: 19px;

        a {
          font-weight: 600;
          white-space: nowrap;
          color: ${$newprimary};
          text-decoration: none;
        }
      }
    }
  }

  .footer {
    position: relative;
    bottom: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    margin-top: 0;
    transition: bottom 0.5s;
  }
`;
