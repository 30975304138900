import React from 'react';
import MobileQuoteCard from '../../../../QuoteGrid/MobileQuoteCard';
import MobileQuoteCardsGridPlacholder from '../../../../QuoteGrid/MobileQuoteCard/placeholder/MobileQuoteCardPlaceholder.component';

const DecideWhatToRender = ({isLoading, suggestions}: {isLoading: boolean; suggestions: any[]}) => {
  if (isLoading) {
    return <MobileQuoteCardsGridPlacholder />;
  } else if (!suggestions || suggestions.length === 0) {
    return <NoResults />;
  }
  return renderMobileResults(suggestions);
};

const renderMobileResults = (suggestions: any[]): JSX.Element => {
  return (
    <div>
      {suggestions.map((quote, index) => {
        return (
          <MobileQuoteCard
            key={index}
            projectName={quote.name}
            date={new Date(quote.createdAt)}
            quoteId={quote.id}
            companyName={quote.subContractor?.companyName ?? ''}
            city={quote.location?.city ?? ''}
            stateProvince={quote.location?.stateProvince ?? ''}
            crmQuoteNumber={quote.crmQuoteNumber}
            status={quote.statusDisplayName}
            amount={quote.totalAmount}
            expirationDate={quote.expirationDate}
          />
        );
      })}
    </div>
  );
};
const NoResults = () => {
  return <div className="no-results-found-mobile">No Quotes Found</div>;
};

const MobilePage = ({quoteSuggestions, isLoading}: {quoteSuggestions: any[]; isLoading: boolean}) => {
  return (
    <div className="mobile-ui-container">
      <DecideWhatToRender isLoading={isLoading} suggestions={quoteSuggestions} />
    </div>
  );
};
export default MobilePage;
