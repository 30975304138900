import {toast} from 'react-toastify';
import {useAppDispatch, useTypedSelector} from '../../../../hooks/store';
import {
  pushAddToCartInformationToDataLayer,
  pushRemoveFromCartEventToDataLayer,
} from '../../../analytics/service/analytics.service';
import {selectCurrentUser} from '../../../auth/slice/authentication.slice';
import {
  selectCurrentCustomer,
  selectCurrentCustomerBillToSites,
  selectCurrentCustomerShipToSites,
} from '../../../customer/slice/customer.slice';
import {hideFullscreenLoader, showFullscreenLoader} from '../../../fullscreenLoader/slice/fullscreenLoader.slice';
import {getProduct} from '../../../productCatalog/service/productCatalog.service';
import {setQuoteOperationInProgress} from '../../../quote/slice/quote.slice';
import {selectActiveProgram, selectAvailablePrograms} from '../../../salesforce/slice/salesforce.slice';
import {getOperatingUnitCodeByProductType} from '../../../warehouse/service/warehouse.service';
import {Cart, CartItem} from '../../ICartState';
import {
  calculateStandartDiscounts,
  getBillToSiteByOperatingUnitCode,
  updateCartItemOnCurrentCart,
} from '../../service/cart.service';
import {selectActiveCart, setCartCalculationsComplete, setDisableQuantityButtons} from '../../slice/cart.slice';
import {selectCalculateCartDiscountsInBackend} from '../../../environment/slice/environment.slice';

export const useUpdateCartItemQuantity = () => {
  const dispatch = useAppDispatch();
  const cart = useTypedSelector(selectActiveCart);
  const customer = useTypedSelector(selectCurrentCustomer);
  const shipToSites = useTypedSelector(selectCurrentCustomerShipToSites);
  const billToSites: any = useTypedSelector(selectCurrentCustomerBillToSites);
  const currentUser: any = useTypedSelector(selectCurrentUser);
  const activeProgram = useTypedSelector(selectActiveProgram);
  const availablePrograms = useTypedSelector(selectAvailablePrograms);
  const calculateDiscountsInBackend = useTypedSelector(selectCalculateCartDiscountsInBackend);

  const updateQuantity = async (cartItem: CartItem, value: any): Promise<any> => {
    if (cart && shipToSites) {
      let quantity = value;
      if (typeof value === 'object') {
        quantity = value.target.value;
      }

      try {
        dispatch(setDisableQuantityButtons(true));
        dispatch(showFullscreenLoader({showCartIcon: true}));

        if (cart && cart.voucherCodes && cart.voucherCodes.length > 0) {
          dispatch(setCartCalculationsComplete(false));
        } else {
          dispatch(setQuoteOperationInProgress(true));
        }

        const product = await getProduct(cartItem.productId, customer, shipToSites ?? []);
        const item = product.items.find((item: any) => item.itemNumber === cartItem.itemNumber);
        const operatingUnit = await getOperatingUnitCodeByProductType(product.productType, customer, shipToSites);
        const billToSite = await getBillToSiteByOperatingUnitCode(operatingUnit, billToSites);

        let tempCartItem = JSON.parse(JSON.stringify(cartItem));
        tempCartItem.tempQuantity = quantity;
        tempCartItem.siteUseId = billToSite.siteUseId;
        await updateCartItemOnCurrentCart(
          tempCartItem,
          cart as Cart,
          customer,
          shipToSites,
          billToSites,
          currentUser?.userId,
          activeProgram,
          dispatch,
          availablePrograms,
          calculateDiscountsInBackend
        );

        const quantityDelta = quantity - (cartItem.quantity ?? 0);

        if (quantityDelta < 0) {
          pushRemoveFromCartEventToDataLayer([
            {
              product,
              cartItem,
              quantity: Math.abs(quantityDelta),
            },
          ]);
        } else {
          pushAddToCartInformationToDataLayer(
            false,
            item,
            tempCartItem.currency || '',
            product.productType,
            tempCartItem.hasAdjustedUnitPrice
              ? tempCartItem.adjustedUnitPrice
              : tempCartItem.unitPrice
              ? tempCartItem.unitPrice
              : '',
            quantity,
            product.id,
            item.itemNumber,
            item.description,
            product.displayName
          );
        }

        await calculateStandartDiscounts(
          cart,
          customer,
          currentUser.userId,
          activeProgram,
          shipToSites,
          billToSites,
          dispatch,
          availablePrograms,
          calculateDiscountsInBackend
        );

        toast.success(`Quantity of ${cartItem.description} is updated.`);
      } catch (error: any) {
        let callbackError = '';
        if (error && error.text) {
          callbackError = await error.text();
        }
        if (error.status === 400 && callbackError.includes('Your cart is locked into quote')) {
          toast.error(callbackError);
        } else {
          toast.error(error);
        }
      } finally {
        dispatch(setCartCalculationsComplete(true));
        dispatch(hideFullscreenLoader());
        dispatch(setQuoteOperationInProgress(false));
        dispatch(setDisableQuantityButtons(false));
      }
    }
  };
  return updateQuantity;
};
