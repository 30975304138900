import React, {useEffect, useState} from 'react';
import {Link, Redirect, Route, Switch, useLocation, useRouteMatch} from 'react-router-dom';
import NavContainer from '../../../../Core/Components/SearchPages/CustomerOrderNav/CustomerOrderNav.styled';
import COMMERCE_CORE_CONSTANTS from '../../../../Core/constants';
import {selectCurrentCustomer} from '../../../../features/customer/slice/customer.slice';
import {selectUserActions} from '../../../../features/permission/slice/permission.slice';
import {
  getBolShipmentReportDataAsync,
  getCustomerStatementsAsync,
  getOpenSampleOrdersReportDataAsync,
  getOpenFabOrdersReportDataAsync,
  getShipmentStatusReportsAsync,
} from '../../../../features/reports/slice/reports.slice';
import {useAppDispatch, useTypedSelector} from '../../../../hooks/store';
import {hasPermission} from '../../../../store/permission/permission.service';
import CustomerStatements from './CustomerStatements';
import LoadReport from './LoadReport';
import {StyledReports} from './Reports.styled';
import OpenSamplesReport from './OpenSamplesReport';
import OpenFabReport from './OpenFabReport';
import BolShipmentReport from './BolShipmentReport';
import {selectReportsSettings} from '../../../../features/environment/slice/environment.slice';

const openSampleReportPath = '/openSamplesReport';
const openFabReportPath = '/openFabricationReport';
const bolShipmentReportPath = '/bolShipmentReport';

export const NavLinks = ({pathname, url}: {pathname: string; url: string}) => {
  const reportSettings = useTypedSelector(selectReportsSettings);

  return (
    <NavContainer className="account-reports-nav">
      <Link
        className={`link ripple ${pathname.includes('customerStatements') ? 'active-link' : ''}`}
        to={`${url}/customerStatements`}>
        CUSTOMER STATEMENTS
      </Link>
      <Link
        className={`link ripple ${pathname.includes('shipmentStatusReport') ? 'active-link' : ''}`}
        to={`${url}/shipmentStatusReport`}>
        OPEN ORDER REPORT
      </Link>
      {reportSettings?.enableOpenSampleReport && (
        <Link
          className={`link ripple ${pathname.includes(openSampleReportPath) ? 'active-link' : ''}`}
          to={`${url}${openSampleReportPath}`}>
          OPEN SAMPLE ORDERS
        </Link>
      )}
      {reportSettings?.enableOpenSampleReport && (
        <Link
          className={`link ripple ${pathname.includes('openFabricationReport') ? 'active-link' : ''}`}
          to={`${url}${openFabReportPath}`}>
          OPEN FABRICATION ORDERS
        </Link>
      )}
      {reportSettings?.enableRecentlyShippedReport && (
        <Link
          className={`link ripple ${pathname.includes(bolShipmentReportPath) ? 'active-link' : ''}`}
          to={`${url}${bolShipmentReportPath}`}>
          RECENTLY SHIPPED ORDERS
        </Link>
      )}
    </NavContainer>
  );
};

const Reports = () => {
  const dispatch = useAppDispatch();
  let {pathname} = useLocation();
  let {url} = useRouteMatch();

  let [customerStatementsPermission, setStatementPermission] = useState<boolean>(false);
  let [loadReportPermission, setLoadReportPermission] = useState<boolean>(false);

  let currentCustomerId = useTypedSelector(selectCurrentCustomer)?.id;
  let erpCustomerId = useTypedSelector(selectCurrentCustomer)?.erpCustomerId;
  let userActions = useTypedSelector(selectUserActions);

  const openSampleOrdersReportPermission = hasPermission(
    'urn:csa:commerceui:account:viewOpenSampleOrdersReport',
    userActions
  );

  const openFabOrdersReportPermission = hasPermission(
    'urn:csa:commerceui:account:viewOpenFabOrdersReport',
    userActions
  );

  const bolShipmentReportPermission = hasPermission(
    'urn:csa:commerceui:account:viewRecentShippedOrdersReport',
    userActions
  );

  const loadReportEndpoint = `${COMMERCE_CORE_CONSTANTS.API_SERVICES.CUSTOMER.customers}/${erpCustomerId}${COMMERCE_CORE_CONSTANTS.API_SERVICES.CUSTOMER.loadReportV2}`;
  const customerStatementsEndpoint = `${COMMERCE_CORE_CONSTANTS.API_SERVICES.CUSTOMER.customers}/${currentCustomerId}${COMMERCE_CORE_CONSTANTS.API_SERVICES.CUSTOMER.statement}`;

  useEffect(() => {
    const getCustomerStatementsOnPageLoad = async () => {
      if (userActions && erpCustomerId) {
        let permission = hasPermission('urn:csa:commerceui:account:viewCustomerStatement', userActions);
        if (permission && currentCustomerId) {
          setStatementPermission(true);
          dispatch(getCustomerStatementsAsync({customerId: currentCustomerId}));
        }
      }
    };
    getCustomerStatementsOnPageLoad();
  }, [dispatch, userActions, erpCustomerId, currentCustomerId]);

  useEffect(() => {
    const getLoadReportDataOnPageLoad = async () => {
      if (userActions && erpCustomerId) {
        let permission = hasPermission('urn:csa:commerceui:account:viewLoadReport', userActions);
        if (permission) {
          setLoadReportPermission(true);
          dispatch(getShipmentStatusReportsAsync({erpCustomerId: erpCustomerId}));
        }
      }
    };
    getLoadReportDataOnPageLoad();
  }, [dispatch, userActions, erpCustomerId]);

  useEffect(() => {
    const getOpenSampleOrdersReportsDataOnPageLoad = async () => {
      if (erpCustomerId && openSampleOrdersReportPermission) {
        dispatch(getOpenSampleOrdersReportDataAsync({erpCustomerId: erpCustomerId}));
      }
    };
    const getOpenFabOrdersReportsDataOnPageLoad = async () => {
      if (erpCustomerId && openFabOrdersReportPermission) {
        dispatch(getOpenFabOrdersReportDataAsync({erpCustomerId: erpCustomerId}));
      }
    };
    getOpenSampleOrdersReportsDataOnPageLoad();
    getOpenFabOrdersReportsDataOnPageLoad();
  }, [dispatch, erpCustomerId, openSampleOrdersReportPermission, openFabOrdersReportPermission]);

  useEffect(() => {
    const getBolShipmentReportsDataOnPageLoad = async () => {
      if (erpCustomerId && bolShipmentReportPermission) {
        dispatch(getBolShipmentReportDataAsync({erpCustomerId: erpCustomerId}));
      }
    };
    getBolShipmentReportsDataOnPageLoad();
  }, [dispatch, erpCustomerId, bolShipmentReportPermission]);

  return (
    <StyledReports>
      <NavLinks pathname={pathname} url={url} />
      <Switch>
        <Route exact path={`${url}`} render={() => <Redirect to={`${url}/customerStatements`} />} />
        <Route
          path={`${url}/customerStatements`}
          render={() => (
            <CustomerStatements hasPermission={customerStatementsPermission} endpoint={customerStatementsEndpoint} />
          )}
        />
        <Route
          path={`${url}/shipmentStatusReport`}
          render={() => <LoadReport hasPermission={loadReportPermission} endpoint={loadReportEndpoint} />}
        />
        <Route
          path={`${url}${openSampleReportPath}`}
          render={() => <OpenSamplesReport hasPermission={openSampleOrdersReportPermission} />}
        />
        <Route
          path={`${url}${openFabReportPath}`}
          render={() => <OpenFabReport hasPermission={openFabOrdersReportPermission} />}
        />
        <Route
          path={`${url}${bolShipmentReportPath}`}
          render={() => <BolShipmentReport hasPermission={bolShipmentReportPermission} />}
        />
      </Switch>
    </StyledReports>
  );
};

export default Reports;
