import {Form, Formik} from 'formik';
import React, {ChangeEvent, useCallback, useEffect, useState} from 'react';
import {Col, FormControl, InputGroup, Row} from 'react-bootstrap';
import {toast} from 'react-toastify';
import {isOrderOfTypeModification, isReworkOrRemake} from '../../../../../../features/jobType/service/jobType.service';
import {
  getOrderLastCartDetailsAsync,
  getOrderReworkReasonsAsync,
  selectOrderDetails,
  selectOrderLastCardDetails,
  selectReworkReasons,
} from '../../../../../../features/order/slice/order.slice';
import {useAppDispatch, useTypedSelector} from '../../../../../../hooks/store';
import {fabricationJobTypes, jobTypes, orderTypeOptions} from '../IOrderDetails';
import JobOrderTypeContainer from './JobOrderType.styled';
import * as Yup from 'yup';
import CambriaInput from '../../../../../../Framework/Components/CambriaInput';
import {IReworkReasons} from '../../../../../../features/order/IOrderState';
import CambriaButton from '../../../../../../Framework/Components/CambriaButton';
import {
  selectCadRevisionEmails,
  selectEndConsumers,
  selectOrderApprovedEmails,
  selectPoRevisionEmails,
  selectPurchasingCustomer,
  selectSelectedDrafter,
} from '../../../../../../features/fabrication/slice/fabrication.slice';
import {selectActiveCart, setActiveCart} from '../../../../../../features/cart/slice/cart.slice';
import {OrderDetail} from '../../../../../../features/cart/ICartState';
import {
  checkIfJobNumberExists,
  updateFabOrderCartDetails,
} from '../../../../../../features/fabrication/service/fabrication.service';
import Icon from '../../../../../../Framework/Components/Icon';
import {hasPermission} from '../../../../../../store/permission/permission.service';
import {selectUserActions} from '../../../../../../features/permission/slice/permission.slice';
import {
  CustomerFullProfile,
  ProductTypeWarehouseResponse,
  Site,
} from '../../../../../../features/customer/ICustomerState';
import {
  selectCurrentCustomer,
  selectCurrentCustomerBillToSites,
  selectCurrentCustomerShipToSites,
} from '../../../../../../features/customer/slice/customer.slice';
import COMMERCE_CORE_CONSTANTS from '../../../../../../Core/constants';
import CambriaAutosuggest from '../../../../../../Framework/Components/CambriaAutosuggest';
import CambriaSelect from '../../../../../../Framework/Components/CambriaSelect';
import {getCart} from '../../../../../../features/cart/service/cart.service';
import {selectCurrentUser} from '../../../../../../features/auth/slice/authentication.slice';
import {
  selectActiveProgram,
  selectAvailablePrograms,
} from '../../../../../../features/salesforce/slice/salesforce.slice';
import {useDebounce} from '../../../../../../hooks/useDebounce';
import {
  hideFullscreenLoader,
  showFullscreenLoader,
} from '../../../../../../features/fullscreenLoader/slice/fullscreenLoader.slice';
import {ISalesforceAvailablePrograms} from '../../../../../../features/salesforce/ISalesforceState';
import {
  getOrdersByNumberRequest,
  putEndConsumersWithJobNumberRequest,
} from '../../../../../../features/fabrication/controller/fabrication.controller';
import {getShippingWarehousesRequest} from '../../../../../../features/warehouse/controller/warehouse.controller';
import {selectUiSettings} from '../../../../../../features/environment/slice/environment.slice';

interface IDisplayOrderData {
  jobType: string;
  orderType: string;
  projectName: string;
  projectCity: string;
  projectFirmName: string;
  purchaseOrderNumber: string;
  orderPieceRework: boolean;
  orderIsRevisionOfPreviousJob: boolean;
  publicFacingDisplay: boolean;
  jobNumber: string;
  reasonForRework: string;
  assignableCause: string;
}

const fabDataCopy: IDisplayOrderData = {
  jobType: '',
  orderType: '',
  projectName: '',
  projectCity: '',
  projectFirmName: '',
  purchaseOrderNumber: '',
  orderPieceRework: false,
  orderIsRevisionOfPreviousJob: false,
  publicFacingDisplay: false,
  jobNumber: '',
  reasonForRework: '',
  assignableCause: '',
};

const JobOrderTypeRow = ({
  isFabricatedOrder,
  isEditDisabled = true,
  orderDetailsInitialValues,
  collapseFabStep1,
  isOnFabPage = false,
}: {
  isFabricatedOrder: boolean;
  isEditDisabled?: boolean;
  orderDetailsInitialValues?: OrderDetail;
  collapseFabStep1?: Function;
  isOnFabPage?: boolean;
}) => {
  let dispatch = useAppDispatch();
  const sessionStorageWarehouseCode = window.sessionStorage.getItem(
    COMMERCE_CORE_CONSTANTS.WINDOW_SESSION.selectedWarehouse
  );

  let [jobNumberChecking, setJobNumberChecking] = useState<boolean>(false);
  let [jobNumberExists, setJobNumberExists] = useState<boolean>(false);
  let [jobType, setJobType] = useState<string>(orderDetailsInitialValues?.jobType || '');
  let [jobTypeCode, setJobTypeCode] = useState<string>(orderDetailsInitialValues?.jobType || '');
  let [orderType, setOrderType] = useState<string>(orderDetailsInitialValues?.projectType || '');
  let [projectName, setProjectName] = useState<string>(orderDetailsInitialValues?.projectName || '');
  let [projectCity, setProjectCity] = useState<string>(orderDetailsInitialValues?.projectCity || '');
  let [projectFirmName, setProjectFirmName] = useState<string>(orderDetailsInitialValues?.relatedAgency || '');
  let [purchaseOrderNumber, setPurchaseOrderNumber] = useState<string>(
    orderDetailsInitialValues?.purchaseOrderNumber || ''
  );
  let [jobNumber, setJobNumber] = useState<string>('');
  let [tempJobNumber, setTempJobNumber] = useState<string>('');
  let [orderPieceRework, setOrderPieceRework] = useState<boolean>(orderDetailsInitialValues?.orderPieceRework || false);
  let [orderIsRevisionOfPreviousJob, setOrderIsRevisionOfPreviousJob] = useState<boolean>(
    orderDetailsInitialValues?.orderIsRevisionOfPreviousJob || false
  );
  let [publicFacingDisplay, setPublicFacingDisplay] = useState<boolean>(
    orderDetailsInitialValues?.publicFacingDisplay || false
  );
  let [selectedReasonForRework, setSelectedReasonForRework] = useState<string>(
    orderDetailsInitialValues?.fabReworkReason
      ? typeof orderDetailsInitialValues.fabReworkReason === 'string'
        ? orderDetailsInitialValues.fabReworkReason
        : orderDetailsInitialValues.fabReworkReason.reasonName
      : ''
  );
  let [assignableCauses, setAssignableCauses] = useState<Array<{displayName: string}>>([]);
  let [selectedAssignableCause, setSelectedAssignableCause] = useState<string | null>(
    orderDetailsInitialValues?.fabAssignableCause || null
  );
  let [originalOrderNum, setOriginalOrderNum] = useState<any>(
    orderDetailsInitialValues && orderDetailsInitialValues.originalSalesOrderNumber
      ? orderDetailsInitialValues.originalSalesOrderNumber
      : ''
  );
  let [orderNumberOptions, setOrderNumberOptions] = useState<any[]>([]);
  let [copyData, setCopyData] = useState<IDisplayOrderData>(fabDataCopy);
  let [saveInProgress, setSaveInProgress] = useState<boolean>(false);
  let [canSeeChangeToExistingOrder, setCanSeeChangeToExistingOrder] = useState<boolean>(false);
  let [drafterRequired, setDrafterIsRequired] = useState<boolean>(true);
  let [reviewEmailsRequired, setReviewEmailsRequired] = useState<boolean>(true);
  let [hasMultipleWarehouses, setHasMultipleWarehouses] = useState<boolean>(false);
  let [availableWarehouses, setAvailableWarehouses] = useState<Site[]>([]);
  let [hasPermissionToSeeWarehouseSelector, setHasPermissionToSeeWarehouseSelector] = useState<boolean>(false);
  let [selectedWarehouse, setSelectedWarehouse] = useState<Site>();
  let [warehouseQueriesRan, setWarehouseQueriesRan] = useState<boolean>(false);
  let [saveAndContinueDisabled, setSaveAndContinueDisabled] = useState<boolean>(true);
  let [canSeeJobNumber, setCanSeeJobNumber] = useState<boolean>(false);
  let [cartInfoHasBeenLoaded, setCartInfoHasBeenLoaded] = useState(false);
  let [mappedCodeToJobType, setMappedCodeToJobType] = useState(false);
  let [endCosumerHasLoaded, setEndCosumerHasLoaded] = useState(false);
  let debouncedJobNumber = useDebounce<string>(tempJobNumber, 1000);
  let [prevOrderDetailsInitialValues, setPrevOrderDetailsInitialValues] = useState<OrderDetail | undefined>(
    orderDetailsInitialValues
  );
  let [saveAndContinueToolTipMessages, setSaveAndContinueToolTipMessages] = useState<Array<string>>([]);
  let [validation, setValidation] = useState<any>({
    projectName: Yup.string().when('jobType', {
      is: () => isJobOfTypeCommercial(jobType),
      then: Yup.string().required('This field is required'),
      otherwise: Yup.string(),
    }),
    jobNumber: Yup.string().when('orderType', {
      is: () => canSeeJobNumber && (orderType === 'Add-on' || (isReworkOrRemake(orderType) && isFabricatedOrder)),
      then: Yup.string().required('This field is required'),
      otherwise: Yup.string(),
    }),
    originalSalesOrderNumber: Yup.mixed().when('jobType', {
      is: () => orderIsRevisionOfPreviousJob,
      then: Yup.mixed().required('This field is required'),
    }),
  });
  let [initialValues, setInitialValues] = useState<any>({
    jobType: jobType ? {displayName: jobType} : undefined,
    orderType: orderType ? {displayName: orderType} : undefined,
    assignableCauses: selectedAssignableCause ? {displayName: selectedAssignableCause} : undefined,
    reworkReason: orderDetailsInitialValues ? {reasonName: orderDetailsInitialValues.fabReworkReason} : undefined,
    jobNumber: jobNumber,
    projectName: projectName,
    city: projectCity,
    firmName: projectFirmName,
  });

  const orderDetails = useTypedSelector(selectOrderDetails);
  const orderLastCartInfo = useTypedSelector(selectOrderLastCardDetails);
  const reworkReasons = useTypedSelector(selectReworkReasons);
  const orderApprovedEmailsRedux = useTypedSelector(selectOrderApprovedEmails);
  const cadRevisionEmailsRedux = useTypedSelector(selectCadRevisionEmails);
  const poRevisionEmailsRedux = useTypedSelector(selectPoRevisionEmails);
  const selectedDrafterRedux = useTypedSelector(selectSelectedDrafter);
  const purchasingCustomers = useTypedSelector(selectPurchasingCustomer);
  const endUsers = useTypedSelector(selectEndConsumers);
  const activeCart = useTypedSelector(selectActiveCart);
  const userActions = useTypedSelector(selectUserActions);
  const customerShipToSites = useTypedSelector(selectCurrentCustomerShipToSites);
  const currentCustomer = useTypedSelector(selectCurrentCustomer);
  const userInfo = useTypedSelector(selectCurrentUser);
  const customerBillToSites = useTypedSelector(selectCurrentCustomerBillToSites);
  const activeProgram = useTypedSelector(selectActiveProgram);
  const availablePrograms: ISalesforceAvailablePrograms | null = useTypedSelector(selectAvailablePrograms);
  const uiSettings = useTypedSelector(selectUiSettings);

  if (JSON.stringify(orderDetailsInitialValues) !== JSON.stringify(prevOrderDetailsInitialValues)) {
    // When there is a change in the initial values, we update the fields from the form
    // This usually happens when another tab saves the changes from this form
    setPrevOrderDetailsInitialValues(orderDetailsInitialValues);
    setJobType(orderDetailsInitialValues?.jobType ?? '');
    setJobTypeCode(orderDetailsInitialValues?.jobType ?? '');
    setOrderType(orderDetailsInitialValues?.projectType ?? '');
    setProjectName(orderDetailsInitialValues?.projectName ?? '');
    setProjectCity(orderDetailsInitialValues?.projectCity ?? '');
    setProjectFirmName(orderDetailsInitialValues?.relatedAgency ?? '');
    setPurchaseOrderNumber(orderDetailsInitialValues?.purchaseOrderNumber ?? '');
    setOrderPieceRework(orderDetailsInitialValues?.orderPieceRework ?? false);
    setOrderIsRevisionOfPreviousJob(orderDetailsInitialValues?.orderIsRevisionOfPreviousJob ?? false);
    setPublicFacingDisplay(orderDetailsInitialValues?.publicFacingDisplay ?? false);
    setSelectedReasonForRework(
      orderDetailsInitialValues?.fabReworkReason
        ? typeof orderDetailsInitialValues.fabReworkReason === 'string'
          ? orderDetailsInitialValues.fabReworkReason
          : orderDetailsInitialValues.fabReworkReason.reasonName
        : ''
    );

    setSelectedAssignableCause(orderDetailsInitialValues?.fabAssignableCause ?? null);
    setOriginalOrderNum(orderDetailsInitialValues?.originalSalesOrderNumber ?? '');
  }

  const isJobOfTypeCommercial = (orderType: string) => {
    return orderType?.includes('Commercial');
  };

  const mapJobCodeToDisplayName = (
    options: {displayName: string; code: string}[],
    jobTypeCode: string,
    jobType?: string
  ): string => {
    let entry: {displayName: string; code: string} | undefined = options.find(
      (x) => x.code === jobTypeCode || x.displayName.includes(jobType || 'undefined')
    );
    return entry?.displayName || '';
  };

  const reloadCart = async () => {
    const updatedCart = await getCart(
      activeCart?.id || '',
      currentCustomer,
      userInfo?.userId || '',
      activeProgram,
      customerShipToSites || [],
      customerBillToSites || [],
      dispatch,
      availablePrograms
    );
    await dispatch(setActiveCart(updatedCart));
  };

  const saveFabOrderDetails = async (formData: any) => {
    if (isReworkOrRemake(jobType)) {
      return;
    }
    setSaveInProgress(true);
    let params;
    try {
      params = {
        cartId: activeCart ? activeCart.id : '',
        jobType: jobTypeCode,
        projectType: orderType,
        projectName: formData.projectName ? formData.projectName.trim() : null,
        fabReworkReason: selectedReasonForRework,
        fabAssignableCause: selectedAssignableCause,
        projectCity: formData.city ? formData.city.trim() : null,
        purchaseOrderNumber: uiSettings?.fabricationOrderDetailsCheckoutIsActive
          ? activeCart?.orderDetails[0]?.purchaseOrderNumber
            ? activeCart?.orderDetails[0]?.purchaseOrderNumber
            : null
          : formData.purchaseOrderNumber
          ? formData.purchaseOrderNumber.trim()
          : null,
        relatedAgency: formData.designFirm ? formData.designFirm.trim() : null,
        orderPieceRework: orderPieceRework,
        publicFacingDisplay: publicFacingDisplay,
        orderIsRevisionOfPreviousJob: orderIsRevisionOfPreviousJob,
        fabricationOrderCadRevisionsNeededEmailList: cadRevisionEmailsRedux,
        fabricationOrderDrafter: selectedDrafterRedux ? selectedDrafterRedux.trim() : null,
        fabricationOrderOrderApprovedEmailList: orderApprovedEmailsRedux,
        fabricationOrderPoRevisionsNeededEmailList: poRevisionEmailsRedux,
        originalSalesOrderNumber: originalOrderNum ? originalOrderNum : null,
        fabricationInstructions: activeCart?.orderDetails[0]?.fabricationInstructions
          ? activeCart?.orderDetails[0]?.fabricationInstructions
          : null,
      };
      if (hasMultipleWarehouses && hasPermissionToSeeWarehouseSelector && selectedWarehouse) {
        params = {...params, surfacesWarehouseCodeOverride: selectedWarehouse.warehouseCode};
      }
      await updateFabOrderCartDetails(params, dispatch);
      if (endUsers && endUsers.length > 0) {
        if (isOrderOfTypeModification(orderType)) {
          await putEndConsumersWithJobNumberRequest(jobNumber, endUsers[0]);
        } else if (originalOrderNum) {
          const previousOrder = orderNumberOptions.find((x) => x.orderNumber === originalOrderNum);
          const jobNumberToUse = previousOrder && previousOrder.fabricationJobNumber;
          await putEndConsumersWithJobNumberRequest(jobNumberToUse, endUsers[0]);
        } else {
          await putEndConsumersWithJobNumberRequest(null, endUsers[0]);
        }
      }
      toast.success('Order details saved successfully');
      await reloadCart();
      if (collapseFabStep1) {
        collapseFabStep1();
      }
    } catch (e) {
      console.log(e);
    } finally {
      setSaveInProgress(false);
    }
  };

  const checkJobNumberAndSet = useCallback(
    async (jobN: string) => {
      if (jobNumberChecking) {
        return;
      }

      if (
        !canSeeJobNumber ||
        (orderType !== 'Add-on' && !(isReworkOrRemake(orderType) && isFabricatedOrder)) ||
        !jobN ||
        jobN.length === 0
      ) {
        setJobNumberExists(false);
        setJobNumber('');
        return;
      }

      if (orderIsRevisionOfPreviousJob) {
        setJobNumber(jobN);
        setJobNumberExists(false);
        return;
      }

      try {
        setJobNumberChecking(true);
        const exists = await checkIfJobNumberExists(jobN);
        setJobNumberExists(exists);
      } catch (ex) {
        setJobNumberExists(false);
        console.log(ex);
      } finally {
        setJobNumber(jobN);
        setJobNumberChecking(false);
      }
    },
    [jobNumberChecking, orderIsRevisionOfPreviousJob, canSeeJobNumber, orderType, isFabricatedOrder]
  );

  const handleOriginalNumberSearch = async (searchString: string) => {
    const results = await getOrdersByNumberRequest(searchString, currentCustomer.erpCustomerId);
    setOrderNumberOptions(results.results);
  };

  useEffect(() => {
    if (!uiSettings?.fabricationOrderDetailsCheckoutIsActive) {
      setValidation({
        projectName: Yup.string().when('jobType', {
          is: () => isJobOfTypeCommercial(jobType),
          then: Yup.string().required('This field is required'),
          otherwise: Yup.string(),
        }),
        purchaseOrderNumber: Yup.string().when('jobType', {
          is: () => isFabricatedOrder,
          then: Yup.string()
            .max(50, 'The maximum length of this field is 50')
            .matches(/^[a-zA-Z0-9\\,:"()<>+;/~*. ]*$/, 'This field does not support the special character entered.')
            .required('This field is required'),
          otherwise: Yup.string(),
        }),
        jobNumber: Yup.string().when('orderType', {
          is: () => canSeeJobNumber && (orderType === 'Add-on' || (isReworkOrRemake(orderType) && isFabricatedOrder)),
          then: Yup.string().required('This field is required'),
          otherwise: Yup.string(),
        }),
        originalSalesOrderNumber: Yup.mixed().when('jobType', {
          is: () => orderIsRevisionOfPreviousJob,
          then: Yup.mixed().required('This field is required'),
        }),
      });

      setInitialValues({
        jobType: jobType ? {displayName: jobType} : undefined,
        orderType: orderType ? {displayName: orderType} : undefined,
        assignableCauses: selectedAssignableCause ? {displayName: selectedAssignableCause} : undefined,
        reworkReason: orderDetailsInitialValues ? {reasonName: orderDetailsInitialValues.fabReworkReason} : undefined,
        purchaseOrderNumber: purchaseOrderNumber,
        jobNumber: jobNumber,
        projectName: projectName,
        city: projectCity,
        firmName: projectFirmName,
      });
    }
  }, [
    canSeeJobNumber,
    isFabricatedOrder,
    jobNumber,
    jobType,
    orderDetailsInitialValues,
    orderIsRevisionOfPreviousJob,
    orderType,
    projectCity,
    projectFirmName,
    projectName,
    purchaseOrderNumber,
    selectedAssignableCause,
    uiSettings,
  ]);

  useEffect(() => {
    if (saveInProgress) {
      dispatch(showFullscreenLoader({}));
    } else {
      dispatch(hideFullscreenLoader());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveInProgress]);

  useEffect(() => {
    const setOriginalOrderNumbersOptions = async (searchString: string) => {
      const results = await getOrdersByNumberRequest(searchString, currentCustomer.erpCustomerId);
      setOrderNumberOptions(results.results);
    };
    if (originalOrderNum && orderNumberOptions.length === 0) {
      setOriginalOrderNumbersOptions(originalOrderNum);
    }
  }, [originalOrderNum, orderNumberOptions, currentCustomer]);

  useEffect(() => {
    if (endUsers && endUsers[0]?.jobNumber && !jobNumber && !endCosumerHasLoaded) {
      checkJobNumberAndSet(endUsers[0]?.jobNumber);
      setEndCosumerHasLoaded(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endUsers, jobNumber, endCosumerHasLoaded]);

  useEffect(() => {
    if (!isEditDisabled && !mappedCodeToJobType && orderDetailsInitialValues && orderDetailsInitialValues.jobType) {
      setJobType(mapJobCodeToDisplayName(fabricationJobTypes, '', orderDetailsInitialValues.jobType) || '');
      setMappedCodeToJobType(true);
    }
  }, [isEditDisabled, orderDetailsInitialValues, mappedCodeToJobType]);

  useEffect(() => {
    const loadLastCartInfo = () => {
      if (orderLastCartInfo.jobType === '') {
        dispatch(getOrderLastCartDetailsAsync({cartId: orderDetails.lastCartId}));
      }
    };
    if (orderDetails.lastCartId && !cartInfoHasBeenLoaded) {
      loadLastCartInfo();
      setCartInfoHasBeenLoaded(true);
    }
  }, [orderDetails, orderLastCartInfo, dispatch, cartInfoHasBeenLoaded]);

  useEffect(() => {
    if (
      activeCart?.orderDetails &&
      activeCart.orderDetails[0]?.surfacesWarehouseCodeOverride &&
      availableWarehouses.length > 0 &&
      !selectedWarehouse
    ) {
      const orderDetailsWarehouse = availableWarehouses.find(
        (x) => x.warehouseCode === activeCart.orderDetails[0]?.surfacesWarehouseCodeOverride
      );
      if (orderDetailsWarehouse !== selectedWarehouse) {
        setSelectedWarehouse(orderDetailsWarehouse);
      }
    }
  }, [activeCart, availableWarehouses, selectedWarehouse]);

  useEffect(() => {
    const checkIfHasPermissionsToSeeJobNumber = (userPermissions: string[]) => {
      return hasPermission('urn:csa:commerceui:order:fab:showUnofficalJobNumber', userPermissions);
    };
    if (userActions.length > 0) {
      if (!uiSettings?.fabricationOrderDetailsCheckoutIsActive) {
        setDrafterIsRequired(
          !hasPermission('urn:csa:commerceui:order:fab:dontrequireorderapprovedemaillist', userActions)
        );
        setReviewEmailsRequired(!hasPermission('urn:csa:commerceui:disableRequiredReviewEmails', userActions));
      }
      setCanSeeJobNumber(checkIfHasPermissionsToSeeJobNumber(userActions));
    }
  }, [userActions, uiSettings]);

  useEffect(() => {
    if (!canSeeChangeToExistingOrder) {
      setCanSeeChangeToExistingOrder(
        hasPermission('urn:csa:commerceui:order:fab:OrderChangeOverrideCheckbox', userActions)
      );
    }
  }, [canSeeChangeToExistingOrder, userActions]);

  useEffect(() => {
    const setTypesOnLoad = () => {
      if (orderLastCartInfo && orderLastCartInfo.jobType !== '') {
        setJobType(
          mapJobCodeToDisplayName(isFabricatedOrder ? fabricationJobTypes : jobTypes, orderLastCartInfo.jobType)
        );
        setOrderType(orderLastCartInfo.projectType);
        setProjectName(orderLastCartInfo.projectName);
        setProjectCity(orderLastCartInfo.projectCity);
        setProjectFirmName(orderLastCartInfo.relatedAgency);

        // Fab Information
        setPurchaseOrderNumber(orderLastCartInfo.purchaseOrderNumber);
        setJobNumber(orderDetails.fabricationJobNumber);
        setOrderPieceRework(orderLastCartInfo.orderPieceRework);
        setOrderIsRevisionOfPreviousJob(orderLastCartInfo.orderIsRevisionOfPreviousJob);
        setPublicFacingDisplay(orderLastCartInfo.publicFacingDisplay);
        setSelectedReasonForRework(orderLastCartInfo.fabReworkReason);
        setSelectedAssignableCause(orderLastCartInfo.fabAssignableCause);

        setCopyDataOnLoad();
      }
    };

    const setCopyDataOnLoad = () => {
      let dataInitialState: IDisplayOrderData = {
        jobType: orderLastCartInfo.jobType,
        orderType: orderLastCartInfo.projectType,
        projectName: orderLastCartInfo.projectName,
        projectCity: orderLastCartInfo.projectCity,
        projectFirmName: orderLastCartInfo.relatedAgency,
        purchaseOrderNumber: orderLastCartInfo.purchaseOrderNumber,
        jobNumber: orderDetails.fabricationJobNumber,
        orderPieceRework: orderLastCartInfo.orderPieceRework,
        orderIsRevisionOfPreviousJob: orderLastCartInfo.orderIsRevisionOfPreviousJob,
        publicFacingDisplay: orderLastCartInfo.publicFacingDisplay,
        reasonForRework: orderLastCartInfo.fabReworkReason,
        assignableCause: orderLastCartInfo.fabAssignableCause,
      };
      setCopyData(dataInitialState);
    };

    if (isEditDisabled) {
      setTypesOnLoad();
    }
  }, [orderDetails, orderLastCartInfo, isEditDisabled, isFabricatedOrder]);

  useEffect(() => {
    const loadDetailsDataOnLoad = () => {
      const cartId = orderDetails.lastCartId;
      dispatch(getOrderLastCartDetailsAsync({cartId}));
    };

    if (orderDetails.lastCartId) {
      loadDetailsDataOnLoad();
    }
  }, [orderDetails, dispatch]);

  useEffect(() => {
    const setJobTypeListOnLoad = () => {
      let jobTypeDisplayName: string;
      if (isFabricatedOrder) {
        jobTypeDisplayName = mapJobCodeToDisplayName(fabricationJobTypes, jobType) || '';
      } else {
        jobTypeDisplayName = mapJobCodeToDisplayName(jobTypes, jobType) || '';
      }
      setJobType(jobTypeDisplayName);
      let copyOfCopyData = {...copyData};
      copyOfCopyData.jobType = jobTypeDisplayName;
      setCopyData(copyOfCopyData);
    };
    if (copyData === fabDataCopy && isEditDisabled) {
      setJobTypeListOnLoad();
    }
  }, [isFabricatedOrder, jobType, copyData, isEditDisabled]);

  useEffect(() => {
    dispatch(getOrderReworkReasonsAsync());
  }, [dispatch]);

  useEffect(() => {
    const findAssignableCausesForReason = () => {
      let selectedReason = reworkReasons.find((x: IReworkReasons) => x.reasonName === selectedReasonForRework);
      if (selectedReason) {
        let copyList: Array<{displayName: string}> = [];
        selectedReason.assignableCauses.forEach((item: string) => {
          copyList.push({displayName: item});
        });
        setAssignableCauses(copyList);
      }
    };
    if (reworkReasons.length > 0) {
      findAssignableCausesForReason();
    }
  }, [selectedReasonForRework, reworkReasons]);

  useEffect(() => {
    const setDefaultWarehouse = (
      productTypeSites: Array<ProductTypeWarehouseResponse>,
      warehouseList: Site[]
    ): void => {
      let primarySiteWarehouseCode: string | undefined;
      if (isClassGroupSurfaces(currentCustomer)) {
        const primarySite = productTypeSites.find((site) => {
          return site.productType === 'Surfaces';
        });
        primarySiteWarehouseCode = primarySite && primarySite.warehouseCode;
      } else {
        primarySiteWarehouseCode = currentCustomer.defaultWarehouseCode;
      }

      if (primarySiteWarehouseCode) {
        const defaultWarehouse: Site | undefined = warehouseList.find(
          (warehouse) =>
            warehouse.warehouseCode ===
            (sessionStorageWarehouseCode ? sessionStorageWarehouseCode : primarySiteWarehouseCode)
        );
        setSelectedWarehouse(defaultWarehouse);
      }
    };

    const isClassGroupSurfaces = (customer: CustomerFullProfile): boolean => {
      return (
        customer &&
        customer.classGroups &&
        customer.classGroups.length > 0 &&
        customer.classGroups.some((x: string) => x === 'Surfaces')
      );
    };

    const getAllAvailableWarehousesCodes = (sites: Array<Site>): Array<string> => {
      let warehouseCodes: string[] = [];

      sites.forEach((site) => {
        site.warehouseCode && warehouseCodes.push(site.warehouseCode);
      });
      warehouseCodes = warehouseCodes.filter((v, i, a) => a.indexOf(v) === i);
      warehouseCodes = warehouseCodes.filter((code) => code !== null);
      return warehouseCodes;
    };

    const populateAvailableWarehouses = async (warehouseCodes: Array<string>) => {
      const warehouses: Site[] = [];
      await Promise.all(
        warehouseCodes.map(async (warehouseCode) => {
          const warehouse = await getShippingWarehousesRequest(warehouseCode);
          warehouses.push(warehouse.results[0]);
        })
      );
      setAvailableWarehouses(warehouses);
      return warehouses;
    };

    const checkForMultipleWarehouses = async (sites: Array<Site>) => {
      let hasMultipleWarehouses = false;
      let warehouseList: Site[] = [];
      if (sites.length > 1) {
        const warehouseCodes = getAllAvailableWarehousesCodes(sites);
        if (warehouseCodes && warehouseCodes.length > 1) {
          hasMultipleWarehouses = true;
          warehouseList = await populateAvailableWarehouses(warehouseCodes);
        }
      }
      setHasMultipleWarehouses(hasMultipleWarehouses);
      return {hasMultipleWarehouses, warehouseList};
    };

    const setVisibilityOfShipFromWarehouseOverideDropdown = async (currentCustomer: CustomerFullProfile) => {
      const hasWarehousePermission = hasPermission('urn:csa:commerceui:selectFabricationaWarehouse', userActions);
      const {hasMultipleWarehouses, warehouseList} = await checkForMultipleWarehouses(
        customerShipToSites ? customerShipToSites : []
      );
      setHasPermissionToSeeWarehouseSelector(hasWarehousePermission);

      if (hasMultipleWarehouses && hasWarehousePermission) {
        setDefaultWarehouse(currentCustomer.productTypeWarehouseCodes, warehouseList);
      }
    };
    if (currentCustomer.id && !hasMultipleWarehouses && availableWarehouses.length === 0 && !warehouseQueriesRan) {
      setVisibilityOfShipFromWarehouseOverideDropdown(currentCustomer);
      setWarehouseQueriesRan(true);
    }
  }, [
    currentCustomer,
    hasMultipleWarehouses,
    availableWarehouses,
    customerShipToSites,
    hasPermissionToSeeWarehouseSelector,
    sessionStorageWarehouseCode,
    userActions,
    warehouseQueriesRan,
  ]);

  useEffect(() => {
    const testPurchaseOrderNumberRegex = (purchaseOrderNumberTestValue: string) => {
      const regex = new RegExp(/^[a-zA-Z0-9\\,:"()<>+;/~*. ]*$/);
      return regex.test(purchaseOrderNumberTestValue);
    };

    const checkIfFormIsValid = () => {
      const isValidCommercial = () => jobType?.includes('Commercial') && projectName;
      const isValidResidential = () => jobType?.includes('Residential');
      const isValidNew = () => orderType === 'New';
      const isValidAddOn = () => orderType === 'Add-on' && (!canSeeJobNumber || jobNumber);
      const isValidReworkOrRemake = () =>
        isReworkOrRemake(orderType) &&
        (!canSeeJobNumber || jobNumber) &&
        selectedReasonForRework &&
        selectedAssignableCause;

      let submitDisabled = true;

      if (uiSettings?.fabricationOrderDetailsCheckoutIsActive) {
        if (isValidCommercial() || isValidResidential()) {
          if (isValidNew() || isValidAddOn() || isValidReworkOrRemake()) {
            submitDisabled = false;
          }
        }
        if (orderIsRevisionOfPreviousJob && !originalOrderNum) {
          submitDisabled = true;
        }
      } else {
        if ((isValidCommercial() || isValidResidential()) && purchaseOrderNumber) {
          if (isValidNew() || isValidAddOn() || isValidReworkOrRemake()) {
            submitDisabled = false;
          }
        }
        if (orderIsRevisionOfPreviousJob && !originalOrderNum) {
          submitDisabled = true;
        }
        if (purchaseOrderNumber) {
          if (purchaseOrderNumber.length > 50 || !testPurchaseOrderNumberRegex(purchaseOrderNumber)) {
            submitDisabled = true;
          }
        }
        if (drafterRequired && !selectedDrafterRedux) {
          submitDisabled = true;
        }
        if (reviewEmailsRequired && (!cadRevisionEmailsRedux || !orderApprovedEmailsRedux || !poRevisionEmailsRedux)) {
          submitDisabled = true;
        }
      }
      setSaveAndContinueDisabled(submitDisabled);
    };

    const generateSaveAndContinueToolTipMessage = () => {
      let tooltipMessageArray = [];

      if (drafterRequired && !selectedDrafterRedux && !uiSettings?.fabricationOrderDetailsCheckoutIsActive) {
        tooltipMessageArray.push('Order Drafter is a required field.');
      }
      if (
        reviewEmailsRequired &&
        (!cadRevisionEmailsRedux || !orderApprovedEmailsRedux || !poRevisionEmailsRedux) &&
        !uiSettings?.fabricationOrderDetailsCheckoutIsActive
      ) {
        tooltipMessageArray.push(
          'All Approval Email Fields are Required. Hit the enter button after typing in the email address to ensure it is added to the list.'
        );
      }
      if (!purchasingCustomers || (purchasingCustomers && purchasingCustomers.length === 0)) {
        tooltipMessageArray.push('Purchasing Customer is a required field.');
      }
      if (!endUsers || (endUsers && endUsers.length === 0)) {
        tooltipMessageArray.push('End User is a required field.');
      }
      if (jobType && isJobOfTypeCommercial(jobType) && !projectName) {
        tooltipMessageArray.push('Project Name is a required field.');
      }
      if (!jobType) {
        tooltipMessageArray.push('Job Type is a required field.');
      }
      if (!orderType) {
        tooltipMessageArray.push('Order Type is a required field.');
      }
      if (isFabricatedOrder && !uiSettings?.fabricationOrderDetailsCheckoutIsActive) {
        if (!purchaseOrderNumber) {
          tooltipMessageArray.push('Purchase Order Number is a required field.');
        }
        if (purchaseOrderNumber) {
          if (purchaseOrderNumber.length > 50) {
            tooltipMessageArray.push('The maximum length of a Purchase Order Number is 50.');
          }
          if (!testPurchaseOrderNumberRegex(purchaseOrderNumber)) {
            tooltipMessageArray.push('Purchase Order Number does not support the special character entered.');
          }
        }
      }
      if (
        canSeeJobNumber &&
        ((orderType === 'Add-on' && !jobNumber) || (isReworkOrRemake(orderType) && isFabricatedOrder && !jobNumber))
      ) {
        tooltipMessageArray.push('Job Number is a required field.');
      }
      if (jobNumberExists) {
        tooltipMessageArray.push('Job Number already exists.');
      }
      if (orderIsRevisionOfPreviousJob && !originalOrderNum) {
        tooltipMessageArray.push('Original Sales Order Number is a required field.');
      }
      if (isFabricatedOrder && !isEditDisabled && isReworkOrRemake(orderType)) {
        if (!selectedReasonForRework) {
          tooltipMessageArray.push('Reason is a required field.');
        }

        if (!selectedAssignableCause) {
          tooltipMessageArray.push('Assignable Cause is a required field.');
        }
      }

      setSaveAndContinueToolTipMessages(tooltipMessageArray);
    };

    if (jobType || orderType) {
      checkIfFormIsValid();
    }

    generateSaveAndContinueToolTipMessage();
  }, [
    uiSettings,
    cadRevisionEmailsRedux,
    drafterRequired,
    orderApprovedEmailsRedux,
    poRevisionEmailsRedux,
    selectedDrafterRedux,
    reviewEmailsRequired,
    isEditDisabled,
    jobType,
    orderType,
    jobNumber,
    projectName,
    purchaseOrderNumber,
    selectedAssignableCause,
    selectedReasonForRework,
    originalOrderNum,
    orderIsRevisionOfPreviousJob,
    canSeeJobNumber,
    endUsers,
    isFabricatedOrder,
    jobNumberExists,
    purchasingCustomers,
  ]);

  useEffect(() => {
    checkJobNumberAndSet(debouncedJobNumber);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedJobNumber]);

  const DisplayCommercialJobData = () => {
    if (isEditDisabled === true && isJobOfTypeCommercial(jobType)) {
      return (
        <Row className="w-100">
          <Col className="fab-data-display">
            <div className="display-line">
              Project Name: <span>{projectName}</span>
            </div>
            <div className="display-line" hidden={!projectCity}>
              City: <span>{projectCity}</span>
            </div>
            <div className="display-line" hidden={!projectFirmName}>
              Architecture & Design Firm Name: <span>{projectFirmName}</span>
            </div>
          </Col>
        </Row>
      );
    }
    return <></>;
  };

  return (
    <Formik
      validationSchema={Yup.object().shape(validation)}
      initialValues={initialValues}
      onSubmit={(values) => saveFabOrderDetails(values)}
      validateOnChange>
      <Form>
        <JobOrderTypeContainer
          boxshadow={isEditDisabled ? 'true' : undefined}
          className={`${isEditDisabled ? '' : 'bg-transparent'}`}>
          <Row className="w-100">
            <Col xs={12} sm={12} md={6} lg={4}>
              {!isEditDisabled ? (
                <CambriaSelect
                  required
                  tabIndex={1}
                  placeholder="Job Type"
                  label="JOB TYPE"
                  defaultValue={jobType}
                  name="jobType"
                  items={fabricationJobTypes}
                  displayValue="displayName"
                  onChange={(selected: {displayName: string; code: string}) => {
                    setJobType(selected.displayName);
                    setJobTypeCode(selected.code);
                  }}
                />
              ) : (
                <>
                  <span>JOB TYPE</span>
                  <InputGroup hidden={!isEditDisabled} className="mb-3 z-index-0">
                    <FormControl disabled defaultValue={jobType} />
                  </InputGroup>
                </>
              )}
            </Col>
            <Col xs={12} sm={12} md={6} lg={4}>
              {!isEditDisabled ? (
                <CambriaSelect
                  required
                  tabIndex={2}
                  defaultValue={orderType}
                  placeholder="Order Type"
                  label="ORDER TYPE"
                  name="orderType"
                  items={orderTypeOptions}
                  displayValue="displayName"
                  onChange={(selected: any) => {
                    setOrderType(selected.displayName);
                    checkJobNumberAndSet('');
                  }}
                />
              ) : (
                <>
                  <span>ORDER TYPE</span>
                  <InputGroup hidden={!isEditDisabled} className="mb-3 z-index-0">
                    <FormControl disabled defaultValue={orderType} />
                  </InputGroup>
                </>
              )}
            </Col>
            <Col xs={4} className="d-flex align-items-end">
              {!isEditDisabled && (
                <div className="checkbox">
                  <input
                    id="public-facing-checkbox"
                    type="checkbox"
                    checked={publicFacingDisplay}
                    onChange={() => setPublicFacingDisplay(!publicFacingDisplay)}
                  />
                  <label
                    className="checkbox-label d-flex flex-column align-items-start justify-content-center"
                    htmlFor="public-facing-checkbox">
                    <span className="text-uppercase checkbox-heavy-label">Public Facing Display</span>{' '}
                    <span className="mt-n2">Built in Display or Model Home</span>
                  </label>
                </div>
              )}
            </Col>
            <Col className="edit-icon-column">
              <DisplayCommercialJobData />
            </Col>
          </Row>
          {!isEditDisabled && isJobOfTypeCommercial(jobType) && (
            <div className="w-100">
              <Row className="w-100">
                <Col xs={12} sm={12} md={6} lg={4}>
                  <CambriaInput
                    tabIndex={4}
                    placeholder="Project Name"
                    label="PROJECT NAME *"
                    name="projectName"
                    defaultValue={projectName}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => setProjectName(event.target.value)}
                  />
                </Col>
                <Col xs={12} sm={12} md={6} lg={4}>
                  <CambriaInput
                    tabIndex={5}
                    placeholder="City"
                    label="CITY"
                    name="city"
                    defaultValue={projectCity}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => setProjectCity(event.target.value)}
                  />
                </Col>
                <Col xs={12} sm={12} md={6} lg={4}>
                  <CambriaInput
                    tabIndex={6}
                    placeholder="A&D Firm Name"
                    label="A&D FIRM NAME"
                    name="designFirm"
                    defaultValue={projectFirmName}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => setProjectFirmName(event.target.value)}
                  />
                </Col>
              </Row>
            </div>
          )}
          <Row className="w-100">
            {isFabricatedOrder && (
              <>
                {!isEditDisabled ? (
                  uiSettings?.fabricationOrderDetailsCheckoutIsActive ? (
                    <></>
                  ) : (
                    <Col xs={12} sm={12} md={6} lg={4}>
                      <CambriaInput
                        tabIndex={7}
                        placeholder="Purchase Order Number"
                        label="PURCHASE ORDER NUMBER *"
                        name="purchaseOrderNumber"
                        disabled={isEditDisabled}
                        defaultValue={purchaseOrderNumber}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => setPurchaseOrderNumber(event.target.value)}
                      />
                    </Col>
                  )
                ) : isOnFabPage ? (
                  <></>
                ) : (
                  <Col xs={12} sm={12} md={6} lg={4}>
                    <>
                      <span>PURCHASE ORDER NUMBER</span>
                      <InputGroup className="mb-3 z-index-0">
                        <FormControl
                          disabled={isEditDisabled}
                          value={purchaseOrderNumber}
                          onChange={(event) => setPurchaseOrderNumber(event.target.value)}
                        />
                      </InputGroup>
                    </>
                  </Col>
                )}

                {jobNumber && isEditDisabled && (
                  <Col xs={12} sm={12} md={6} lg={4}>
                    <span>JOB NUMBER</span>
                    <InputGroup className="mb-3 z-index-0">
                      <FormControl
                        disabled
                        defaultValue={jobNumber}
                        onChange={(event) => setTempJobNumber(event.target.value)}
                      />
                    </InputGroup>
                  </Col>
                )}
              </>
            )}
            {!isEditDisabled && canSeeJobNumber && (
              <>
                {isReworkOrRemake(orderType) || orderType === 'Add-on' ? (
                  <Col xs={4}>
                    <CambriaInput
                      tabIndex={8}
                      placeholder="Job Number"
                      label="JOB NUMBER *"
                      defaultValue={jobNumber}
                      name="jobNumber"
                      invalidMessage="Job number already exists"
                      valid={!jobNumberExists}
                      onChange={(event: ChangeEvent<HTMLInputElement>) => setTempJobNumber(event.target.value)}
                      disabled={jobNumberChecking}
                    />
                    {jobNumberChecking ? (
                      <Icon
                        className={'job-number-checking-spinner'}
                        size="20"
                        weight="600"
                        icon="fa fa-spinner fa-spin"
                        disabled={true}
                      />
                    ) : (
                      <></>
                    )}
                  </Col>
                ) : (
                  <>
                    {orderType === 'New' && (
                      <Col xs={4} className="d-flex align-items-center">
                        <div className="checkbox">
                          <input
                            id="uses-reworked-checkbox"
                            type="checkbox"
                            checked={orderPieceRework}
                            onChange={() => setOrderPieceRework(!orderPieceRework)}
                          />
                          <label
                            className="checkbox-label d-flex flex-column align-items-start justify-content-center"
                            htmlFor="uses-reworked-checkbox">
                            Order Uses a Reworked Piece
                          </label>
                        </div>
                      </Col>
                    )}
                  </>
                )}
                <Col xs={4} className="d-flex align-items-end">
                  {canSeeChangeToExistingOrder && orderType ? (
                    <>
                      <div className="checkbox">
                        <input
                          id="change-existing-checkbox"
                          type="checkbox"
                          checked={orderIsRevisionOfPreviousJob}
                          onChange={() => {
                            setOrderIsRevisionOfPreviousJob(!orderIsRevisionOfPreviousJob);
                            setOriginalOrderNum('');
                            checkJobNumberAndSet('');
                          }}
                        />
                        <label
                          className="checkbox-label d-flex flex-column align-items-start justify-content-center"
                          htmlFor="change-existing-checkbox">
                          Order is a Change to an Existing Order
                        </label>
                      </div>
                    </>
                  ) : (
                    <div></div>
                  )}
                </Col>
              </>
            )}
            <Col hidden={!isEditDisabled} xs={12} sm={12} md={6} lg={4} className="extra-info-column fab-data-display">
              <div className="display-line" hidden={!publicFacingDisplay}>
                <i className="fa fa-check" />
                &nbsp;Public Facing Display
              </div>
              <div className="display-line" hidden={!orderPieceRework}>
                <i className="fa fa-check" />
                &nbsp;Order Piece Rework
              </div>
              <div className="display-line" hidden={!orderIsRevisionOfPreviousJob}>
                <i className="fa fa-check" />
                &nbsp;Is Revision of Previous Job
              </div>
              <div className="display-line" hidden={!selectedReasonForRework || selectedReasonForRework === ''}>
                Reason for Rework: <span>{selectedReasonForRework}</span>
              </div>
              <div className="display-line" hidden={!selectedAssignableCause || selectedAssignableCause === ''}>
                Assignable Cause: <span>{selectedAssignableCause}</span>
              </div>
            </Col>
          </Row>
          {orderIsRevisionOfPreviousJob && !isEditDisabled ? (
            <Row className="w-100">
              <Col xs={4}>
                <CambriaAutosuggest
                  label="ORIGINAL SALES ORDER NUMBER"
                  name={'originalSalesOrderNumber'}
                  onSearch={handleOriginalNumberSearch}
                  options={orderNumberOptions && orderNumberOptions.length > 0 ? orderNumberOptions : []}
                  displayValue={'orderNumber'}
                  required
                  orderNumberSearch
                  defaultValue={originalOrderNum}
                  placeholder="Original Sales Order Number"
                  onSelect={(selection: any) => {
                    setOriginalOrderNum(selection.orderNumber);
                    checkJobNumberAndSet(selection.fabricationJobNumber);
                  }}
                />
              </Col>
            </Row>
          ) : (
            <div></div>
          )}
          {isFabricatedOrder && !isEditDisabled && isReworkOrRemake(orderType) && (
            <Row className="w-100">
              <Col xs={12} sm={12} md={6} lg={4}>
                <CambriaSelect
                  tabIndex={10}
                  required
                  placeholder="Reason"
                  name="reworkReason"
                  label="REASON"
                  defaultValue={selectedReasonForRework}
                  items={reworkReasons}
                  displayValue={'reasonName'}
                  onChange={(option: IReworkReasons) => {
                    setSelectedReasonForRework(option.reasonName);
                    setSelectedAssignableCause(null);
                  }}
                />
              </Col>
              <Col xs={12} sm={12} md={6} lg={6}>
                <CambriaSelect
                  tabIndex={11}
                  placeholder="Assignable Cause"
                  label="ASSIGNABLE CAUSE *"
                  disabled={!selectedReasonForRework}
                  items={assignableCauses}
                  name="assignableCauses"
                  displayValue="displayName"
                  defaultValue={selectedAssignableCause}
                  onChange={(option: {displayName: string}) => setSelectedAssignableCause(option.displayName)}
                />
              </Col>
            </Row>
          )}
          {!isEditDisabled && hasPermissionToSeeWarehouseSelector && hasMultipleWarehouses && warehouseQueriesRan && (
            <>
              <Row className="w-100">
                <Col xs={4}>
                  <CambriaSelect
                    required
                    tabIndex={12}
                    defaultValue={selectedWarehouse}
                    label="SHIP FROM LOCATION"
                    items={availableWarehouses}
                    displayValue="name"
                    name={'shipFromLocation'}
                    placeholder="Ship From Location"
                    onChange={(selection: Site) => {
                      setSelectedWarehouse(selection);
                    }}
                  />
                </Col>
              </Row>
            </>
          )}
          {!isEditDisabled && (
            <Row className="w-100 d-flex justify-content-end">
              <Col xs={4} className="d-flex justify-content-end">
                <div className="order-details-save-button-help">
                  <div className="list-tooltip-wrapper">
                    <CambriaButton
                      type="submit"
                      tabIndex={13}
                      disabled={
                        jobNumberChecking ||
                        jobNumberExists ||
                        saveAndContinueDisabled ||
                        (endUsers && endUsers.length === 0) ||
                        (purchasingCustomers && purchasingCustomers.length === 0) ||
                        saveInProgress
                      }
                      onClick={() => {}}>
                      {saveInProgress ? (
                        <Icon icon="fa fa-spinner fa-spin" weight="600" size="18" color="white" />
                      ) : (
                        <></>
                      )}
                      SAVE AND CONTINUE
                    </CambriaButton>
                    {saveAndContinueToolTipMessages && saveAndContinueToolTipMessages.length > 0 ? (
                      <span className="order-details-save-button-tooltip">
                        <ul>
                          {saveAndContinueToolTipMessages.map((message, index) => {
                            return <li key={`saveAndContinueMessage-${index}`}>{message}</li>;
                          })}
                        </ul>
                      </span>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              </Col>
            </Row>
          )}
        </JobOrderTypeContainer>
      </Form>
    </Formik>
  );
};

export default JobOrderTypeRow;
