import styled from 'styled-components/macro';
import {AsyncTypeahead} from 'react-bootstrap-typeahead';
import {$gray50, $gray55, $gray78, $gray93, $newblack, $newdanger, $newwhite} from '../../../Styled/variables';

export const CambriaAutosuggestContainer = styled.div`
  position: relative;
  font-size: 16px;
  line-height: 22px;
  padding-bottom: 20px;
  margin-bottom: 2px;
`;

export const CambriaAutosuggestField = styled(AsyncTypeahead)`
  width: 100%;
  padding: 0;
  background-color: ${$newwhite} !important;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2) !important;
  border: 1px solid ${$newwhite};
  border-color: none;
  border-radius: 3px;
  margin-bottom: 2px;
  color: ${$newblack};
  transition: 0.5s all;

  &.input-error {
    border-color: ${$newdanger} !important;
  }

  &.locked {
    .form-control {
      background-color: ${$gray93} !important;
      -webkit-text-fill-color: ${$gray50} !important;
      color: ${$gray50} !important;
      cursor: not-allowed !important;
      pointer-events: none;
      padding: 8px 36px 8px 18px !important;
    }
  }

  .clear-value {
    color: ${$gray55};
    position: absolute;
    top: 7px;
    right: 10px;
    border: none;
    padding: 0;
  }

  input {
    color: ${$newblack} !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 17px !important;
    border: none !important;
    height: 100% !important;
    padding: 8px 18px !important;

    &::placeholder {
      color: ${$gray78} !important;
      font-weight: 400 !important;
    }

    &:focus,
    &:active {
      box-shadow: none !important;
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:active,
    &:-webkit-autofill:focus {
      transition: background-color 5000s ease-in-out 0s !important;
    }

    &:disabled {
      -webkit-text-fill-color: ${$gray78} !important;
      color: ${$gray78} !important;
      cursor: not-allowed !important;
      transition: background-color 5000s ease-in-out 0s !important;
    }
  }
`;

export const CambriaLabel = styled.label`
  font-weight: 700;
`;

export const CambriaAutosuggestError = styled.span`
  position: absolute;
  left: 0;
  bottom: 0;
  font-size: 12px;
  font-weight: 500;
  color: ${$newdanger};
`;
