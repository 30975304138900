import React, {useEffect, useState} from 'react';
import {Formik} from 'formik';
import {ShipToSite} from '../../../../../features/customer/ICustomerState';
import {
  selectAvailableWarehouses,
  selectSelectedWarehouse,
  setSelectedWarehouse,
} from '../../../../../features/warehouse/slice/warehouse.slice';
import {useAppDispatch, useTypedSelector} from '../../../../../hooks/store';
import COMMERCE_CORE_CONSTANTS from '../../../../constants';
import CambriaSelect from '../../../../../Framework/Components/CambriaSelect/CambriaSelect.component';

const WarehouseSelector = () => {
  const dispatch = useAppDispatch();

  const [activeWarehouse, setActiveWarehouse] = useState<ShipToSite | null>(null);

  const selectedWarehouse = useTypedSelector(selectSelectedWarehouse);
  const allAvailableWarehouses = useTypedSelector(selectAvailableWarehouses);

  const changeSelectedWarehouse = (warehouse: ShipToSite) => {
    if (warehouse && activeWarehouse && warehouse.warehouseCode === activeWarehouse.warehouseCode) {
      return;
    }
    setActiveWarehouse(() => warehouse);
    dispatch(setSelectedWarehouse(warehouse));
    if (warehouse.warehouseCode) {
      window.sessionStorage.setItem(COMMERCE_CORE_CONSTANTS.WINDOW_SESSION.selectedWarehouse, warehouse.warehouseCode);
    }

    window.location.assign(COMMERCE_CORE_CONSTANTS.PATHNAMES.home);
    window.location.reload();
  };

  useEffect(() => {
    if (selectedWarehouse) {
      setActiveWarehouse(() => selectedWarehouse);
    }
  }, [selectedWarehouse]);

  return (
    <Formik enableReinitialize initialValues={{headerWarehouseSelector: activeWarehouse}} onSubmit={() => {}}>
      <CambriaSelect
        outlined
        name="headerWarehouseSelector"
        items={allAvailableWarehouses}
        displayValue="displayName"
        onChange={changeSelectedWarehouse}
        defaultValue={activeWarehouse}
        placeholder=""
      />
    </Formik>
  );
};

export default WarehouseSelector;
