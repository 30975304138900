import {CartItem, NewCartItem} from '../../ICartState';
import {calculateTileSquareFootage, calculateTileUnitSize} from '../../../product/service/product.service';
import {Item, ItemAttribute, Product} from '../../../productCatalog/IProductCatalogState';
import COMMERCE_CORE_CONSTANTS from '../../../../Core/constants';

export const getCartItem = (
  itemArg: CartItem,
  product: Product,
  calculateDiscountsInBackend: boolean = false
): CartItem => {
  let cartItem: CartItem = NewCartItem();
  cartItem.programCode = itemArg.programCode;
  cartItem.programName = itemArg.programName;
  cartItem.productId = itemArg.productId;
  cartItem.productType = itemArg.productType;
  cartItem.productActiveLocations = product.productActiveLocations;
  cartItem.productCategory = itemArg.productCategory;
  cartItem.description = itemArg.description;
  cartItem.itemNumber = itemArg.itemNumber;
  cartItem.quantity = itemArg.quantity;
  cartItem.tempQuantity = itemArg.quantity;
  cartItem.unitPrice = itemArg.unitPrice;
  cartItem.unitSize = itemArg.unitSize;
  cartItem.pricingUom = itemArg.pricingUom;
  cartItem.discountAmount = 0;
  cartItem.extendedNetPrice = itemArg.itemQuantityPrice;
  cartItem.adjustedExtendedNetPrice = itemArg.extendedNetPrice;
  cartItem.adjustedUnitPrice = itemArg.unitPrice;
  cartItem.shipFromWarehouseId = itemArg.shipFromWarehouseId;
  cartItem.priceListCode = itemArg.priceListCode;
  cartItem.primaryUomCode = itemArg.primaryUomCode;
  cartItem.unitWeight = itemArg.unitWeight * 100;
  cartItem.weightUomCode = itemArg.weightUomCode;
  cartItem.taxProductCode = itemArg.taxProductCode;
  cartItem.cartItemType = itemArg.cartItemType;
  cartItem.cartItemId = itemArg.cartItemId;
  cartItem.createdAt = itemArg.createdAt;
  cartItem.currency = itemArg.currency;
  cartItem.programCode = itemArg.programCode;
  cartItem.operatingUnitCode = itemArg.operatingUnitCode;
  cartItem.requireOrderingInQuantitiesOfTwo = itemArg.requireOrderingInQuantitiesOfTwo;
  cartItem.qualifiesforFreeShippingForHomeDepot = itemArg.qualifiesforFreeShippingForHomeDepot;
  cartItem.bundleId = itemArg.bundleId;

  if (calculateDiscountsInBackend) {
    cartItem.discountAmount = itemArg.discountAmount;
    cartItem.adjustedExtendedNetPrice = itemArg.adjustedExtendedNetPrice;
    cartItem.adjustedUnitPrice = itemArg.adjustedUnitPrice;
    cartItem.hasAdjustedExtendedNetPrice =
      cartItem.hasAdjusteditemQuantityPrice =
      cartItem.hasAdjustedUnitPrice =
        cartItem.discountAmount > 0;
    cartItem.adjusteditemQuantityPrice = itemArg.adjustedExtendedNetPrice;
  }

  const selectedProdItem = product.items.find((prodItem) => prodItem.itemNumber === cartItem.itemNumber);

  if (selectedProdItem && selectedProdItem.attachments) {
    cartItem.imageUrl = getImageUrl(selectedProdItem.attachments);
    cartItem.imageUrl =
      cartItem.imageUrl.replace('{size}px@{scale}x', '250x200px').replace('q={quality}', 'q=100') + '&c=0';
  }
  if (!cartItem.imageUrl) {
    cartItem.imageUrl = getImageUrl(product.attachments);
    cartItem.imageUrl =
      cartItem.imageUrl.replace('{size}px@{scale}x', '250x200px').replace('q={quality}', 'q=100') + '&c=0';
  }
  cartItem.shippingClassifications = product.shippingClassifications;
  cartItem.discountCategory = product.discountCategory;
  const item = product.items ? product.items.find((prodItem) => prodItem.itemNumber === cartItem.itemNumber) : null;
  if (item) {
    cartItem.organizations = item.organizations;
  }
  cartItem.thickness =
    product.items
      ?.find((i) => i.itemNumber === cartItem.itemNumber)
      ?.attributes?.find((a) => a.name === COMMERCE_CORE_CONSTANTS.PRODUCT_ATTRIBUTES.thickness)?.value ?? '';

  cartItem.lineItemHalfBundleQuantity = itemArg.lineItemHalfBundleQuantity;

  return cartItem;
};

export const getImageUrl = (attachments: Array<any>) => {
  const mainImage = attachments.filter((attachment) => attachment.type === 'main');
  const defaultImage = attachments.filter((attachment) => attachment.type === 'default');
  const mainImageUrls = mainImage && mainImage.length > 0 ? mainImage.map((attachment) => attachment.location) : [];
  const defaultImageUrls =
    defaultImage && defaultImage.length > 0 ? defaultImage.map((attachment) => attachment.location) : [];

  if (mainImageUrls && mainImageUrls.length > 0) {
    return mainImageUrls[0];
  } else if (defaultImageUrls && defaultImageUrls.length > 0) {
    return defaultImageUrls[0];
  } else {
    return '';
  }
};

export const getCartItemByItemNumber = (
  itemNumber: string,
  product: Product,
  pricing: any,
  shipFromWarehouseId?: string | null,
  quantity: number = 1,
  programCode?: string,
  programName?: string
): CartItem => {
  const cartItem: CartItem = JSON.parse(JSON.stringify(NewCartItem()));
  const item = getProductItemByItemNumber(product, itemNumber);
  let sizeAttribute: ItemAttribute | null = null;
  if (item) {
    cartItem.description = item.description;
    cartItem.itemNumber = item.itemNumber;
    cartItem.primaryUomCode = item.primaryUomCode;
    cartItem.weightUomCode = item.weightUomCode;
    cartItem.unitWeight = parseFloat(item.unitWeight) * 100;
    cartItem.cartItemType = item.bomItemType;
    cartItem.organizations = item.organizations;
    cartItem.qualifiesforFreeShippingForHomeDepot = item.qualifiesforFreeShippingForHomeDepot;
    sizeAttribute = getSizeAttribute(item);
  }
  cartItem.programCode = programCode ? programCode : '';
  cartItem.programName = programName ? programName : '';
  cartItem.itemNumber = itemNumber;
  cartItem.isAvailable = product.isAvailable || false;
  cartItem.productId = product.id;
  cartItem.productType = product.productType;
  cartItem.discountCategory = product.discountCategory;
  cartItem.productCategory = '';
  cartItem.quantity = quantity;
  cartItem._quantity = quantity;
  cartItem.tempQuantity = quantity;
  cartItem.unitPrice = pricing.SellingPrice || pricing.unitPrice;
  cartItem.adjustedUnitPrice = pricing.SellingPrice || pricing.unitPrice;
  cartItem.pricingUom = pricing.PricingUom || pricing.pricingUom;
  cartItem.currency = pricing.CurrencyCode || pricing.currencyCode;
  if (sizeAttribute && cartItem.productType === 'Slab') {
    cartItem.unitSize = parseInt(sizeAttribute.value, 10);
    cartItem.extendedNetPrice = cartItem.unitSize * cartItem.unitPrice * cartItem.quantity;
  } else if (sizeAttribute && cartItem.productType === 'Tile') {
    cartItem.unitSize = calculateTileUnitSize(sizeAttribute.value);
    cartItem.extendedNetPrice = cartItem.unitPrice * (calculateTileSquareFootage(cartItem.unitSize, quantity) || 0);
  } else {
    cartItem.unitSize = 0;
    cartItem.extendedNetPrice = cartItem.unitPrice * cartItem.quantity;
  }
  cartItem.discountAmount = 0;
  cartItem.adjustedExtendedNetPrice = cartItem.extendedNetPrice;
  cartItem.shipFromWarehouseId = shipFromWarehouseId ? shipFromWarehouseId : '';
  // No way to get value of cartItem.taxProductCode
  const selectedProdItem = getProductItemByItemNumber(product, cartItem.itemNumber);
  if (selectedProdItem && selectedProdItem.attachments) {
    cartItem.imageUrl = getImageUrl(selectedProdItem.attachments);
    cartItem._imageUrl =
      cartItem.imageUrl.replace('{size}px@{scale}x', '250x200px').replace('q={quality}', 'q=100') + '&c=0';
  }
  if (!cartItem.imageUrl) {
    cartItem.imageUrl = getImageUrl(product.attachments);
    cartItem._imageUrl =
      cartItem.imageUrl.replace('{size}px@{scale}x', '250x200px').replace('q={quality}', 'q=100') + '&c=0';
  }
  cartItem.shippingClassifications = product.shippingClassifications;

  return cartItem;
};

export const getProductItemByItemNumber = (product: Product, itemNumber: string) => {
  return product.items.find((productItem) => productItem.itemNumber === itemNumber);
};

export const getSizeAttribute = (item: Item) => {
  let sizeAttribute = item.attributes.find((attribute) => attribute.name === 'Size');
  if (sizeAttribute) {
    return sizeAttribute;
  }
  return null;
};
