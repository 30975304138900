import styled from 'styled-components/macro';
import {$gray30} from '../../.././../Styled/variables';

export const HeaderDescription = styled.span`
  position: absolute;
  margin-top: -35px;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: ${$gray30};
  font-style: italic;
`;
