import React, {useEffect, useRef, useState} from 'react';
import {Formik} from 'formik';
import * as Yup from 'yup';
import {Alert, Container, Form, Row} from 'react-bootstrap';
import CambriaInput from '../../../../../Framework/Components/CambriaInput';
import {useAppDispatch, useTypedSelector} from '../../../../../hooks/store';
import {Cart, OrderDetail} from '../../../../../features/cart/ICartState';
import {
  invalidateStep1,
  invalidateStep2,
  selectActiveStep,
  selectStep1Completed,
  submitOrderDetails,
} from '../../../../../features/checkout/slice/checkout.slice';
import {selectActiveCart} from '../../../../../features/cart/slice/cart.slice';
import {selectCurrentCustomer} from '../../../../../features/customer/slice/customer.slice';
import CambriaAutosuggest from '../../../../../Framework/Components/CambriaAutosuggest';
import {
  getSalesforceAccountById,
  getSalesforceAccounts,
  removeSalesforceAccountIdFromSessionStorage,
  setSalesforceAccountIdInSessionStorage,
} from '../../../../../features/salesforce/service/salesforce.service';
import {SamplesOrderContainer} from './SamplesOrderDetails.styled';
import {showFullscreenLoader} from '../../../../../features/fullscreenLoader/slice/fullscreenLoader.slice';
import {setIsUpdatedSalesforceAssetAccount} from '../../../../../features/salesforce/slice/salesforce.slice';
import {pushAddOrderDetailsToDataLayer} from '../../../../../features/analytics/service/analytics.service';

interface SamplesOrderDetailsProps {
  orderDetails: OrderDetail | undefined;
}

const SamplesOrderDetails = ({orderDetails}: SamplesOrderDetailsProps) => {
  let formikRef = useRef();
  const dispatch = useAppDispatch();

  const [isInitialized, setIsInitialized] = useState<boolean>(false);
  const [selectedSalesforceAccount, setSelectedSalesforceAccount] = useState<any>(null);
  const [salesforceAccountOptions, setSalesforceAccountOptions] = useState<any>([]);
  const [salesforceAccountInitialValue, setSalesforceAccountInitialValue] = useState<string>(
    orderDetails && orderDetails.orderCrmAccountName ? orderDetails.orderCrmAccountName : ''
  );
  const [salesforceAccountSearchKeyword, setSalesforceAccountSearchKeyword] = useState<string>('');
  const [showTradeTypeAlert, setShowTradeTypeAlert] = useState<boolean>(false);

  const activeStep = useTypedSelector(selectActiveStep);
  const activeCart = useTypedSelector(selectActiveCart);
  const step1Completed = useTypedSelector(selectStep1Completed);
  const currentCustomer = useTypedSelector(selectCurrentCustomer);

  const isMarketRepCustomer =
    currentCustomer && (currentCustomer.class === 'MARKETREP' || currentCustomer.class === 'INT MARKETREP');

  const searchSalesforceAccounts = async (keyword: string) => {
    setSalesforceAccountSearchKeyword(keyword);
  };

  const selectSalesforceAccount = (account: any) => {
    if (account) {
      setSelectedSalesforceAccount(account);
    } else {
      setSelectedSalesforceAccount(null);
      // Resetting defaultValue for CambriaAutoSuggest:
      setSalesforceAccountInitialValue('');
    }
  };

  useEffect(() => {
    const getSalesforceAccountsByKeyword = async (keyword: string) => {
      if (keyword.length === 0) {
        setShowTradeTypeAlert(false);
      } else {
        setSalesforceAccountOptions(await getSalesforceAccounts(keyword));
      }
    };
    getSalesforceAccountsByKeyword(salesforceAccountSearchKeyword);
  }, [salesforceAccountSearchKeyword]);

  useEffect(() => {
    if (selectedSalesforceAccount) {
      setSalesforceAccountInitialValue(selectedSalesforceAccount.name);

      setSalesforceAccountIdInSessionStorage(selectedSalesforceAccount.id, currentCustomer?.id);
      setShowTradeTypeAlert(true);
    } else {
      dispatch(invalidateStep1());
      setSalesforceAccountInitialValue('');
      setSalesforceAccountOptions([]);
      removeSalesforceAccountIdFromSessionStorage();
      setShowTradeTypeAlert(false);
    }
  }, [selectedSalesforceAccount, currentCustomer?.id, dispatch]);

  useEffect(() => {
    const getFullSalesforceAccountFromOrderDetails = async () => {
      if (orderDetails && orderDetails.orderCrmAccountId) {
        const salesforceAccount = await getSalesforceAccountById(orderDetails.orderCrmAccountId);
        setSelectedSalesforceAccount(salesforceAccount);
      }
    };

    getFullSalesforceAccountFromOrderDetails();
  }, [orderDetails]);

  useEffect(() => {
    if (step1Completed && !isInitialized) {
      setIsInitialized(true);
      dispatch(invalidateStep1());
    }

    return () => {};
  }, [step1Completed, dispatch, isInitialized, setIsInitialized]);

  const renderTradeTypeInfoMessage = () => {
    if (selectedSalesforceAccount && selectedSalesforceAccount.tradeType) {
      return (
        <Alert key="primary" variant="primary" dismissible onClose={() => setShowTradeTypeAlert(false)}>
          The account you selected has the trade type of {selectedSalesforceAccount.tradeType}
        </Alert>
      );
    }
    if (selectedSalesforceAccount && !selectedSalesforceAccount.tradeType) {
      return (
        <Alert key="primary" variant="primary" dismissible onClose={() => setShowTradeTypeAlert(false)}>
          The account you selected doesn’t have a trade type. Please add the trade type
          <a target="_blank" rel="noopener noreferrer" href={selectedSalesforceAccount.accountLink}>
            (here)
          </a>
          . If a trade type isn’t provided, we will assume residential
        </Alert>
      );
    }
  };

  if (!isMarketRepCustomer) {
    return (
      <Formik
        initialValues={{
          purchaseOrderNumber: orderDetails && orderDetails.purchaseOrderNumber ? orderDetails.purchaseOrderNumber : '',
        }}
        validationSchema={Yup.object({
          purchaseOrderNumber: Yup.string()
            .max(50, 'The maximum length of this field is 50')
            .matches(/^[a-zA-Z0-9\\,:"()<>+;/~*. ]*$/, 'This field does not support the special character entered.'),
        })}
        onSubmit={(values) => {
          dispatch(showFullscreenLoader({showCartIcon: false}));
          dispatch(
            submitOrderDetails({
              activeCart,
              orderDetails: {
                purchaseOrderNumber: values.purchaseOrderNumber.trim(),
              },
            })
          );
          pushAddOrderDetailsToDataLayer({cart: activeCart as Cart});
        }}>
        {(props) => {
          return (
            <Form id={activeStep?.step} noValidate onSubmit={props.handleSubmit}>
              <Container className="p-0 not-marketrep-form">
                <Row className="m-b-sm">
                  <div className={'col-12 col-lg-6'}>
                    <CambriaInput
                      disabled={false}
                      name={'purchaseOrderNumber'}
                      placeholder={'Purchase Order ID'}
                      defaultValue={
                        orderDetails && orderDetails.purchaseOrderNumber ? orderDetails.purchaseOrderNumber : ''
                      }
                      label={'Purchase Order ID'}
                      type={'text'}
                      required={false}></CambriaInput>
                  </div>
                </Row>
              </Container>
            </Form>
          );
        }}
      </Formik>
    );
  } else {
    return (
      <SamplesOrderContainer>
        <Formik
          innerRef={(ref: any) => (formikRef = ref)}
          initialValues={{
            purchaseOrderNumber:
              orderDetails && orderDetails.purchaseOrderNumber ? orderDetails.purchaseOrderNumber : '',
            salesforceAccount: orderDetails && orderDetails.orderCrmAccountName ? orderDetails.orderCrmAccountName : '',
          }}
          validationSchema={Yup.object({
            purchaseOrderNumber: Yup.string()
              .max(50, 'The maximum length of this field is 50')
              .matches(/^[a-zA-Z0-9\\,:"()<>+;/~*. ]*$/, 'This field does not support the special character entered.'),
            salesforceAccount: Yup.string().required('This field is required'),
          })}
          onSubmit={(values) => {
            if (selectedSalesforceAccount) {
              dispatch(
                submitOrderDetails({
                  activeCart,
                  orderDetails: {
                    purchaseOrderNumber: values.purchaseOrderNumber,
                    salesforceAccount: selectedSalesforceAccount,
                  },
                })
              );
              pushAddOrderDetailsToDataLayer({cart: activeCart as Cart});
            } else {
              (formikRef as any).setFieldError(
                'salesforceAccount',
                'Please select salesforce account from the dropdown list'
              );
            }
          }}>
          {(props) => {
            return (
              <Form id={activeStep?.step} noValidate onSubmit={props.handleSubmit}>
                <Container className="p-0 marketrep-form">
                  <Row className="m-b-sm">
                    <div className={'col-12 col-lg-6'}>
                      <CambriaInput
                        disabled={false}
                        name={'purchaseOrderNumber'}
                        placeholder={'Purchase Order ID'}
                        defaultValue={
                          orderDetails && orderDetails.purchaseOrderNumber ? orderDetails.purchaseOrderNumber : ''
                        }
                        label={'Purchase Order ID'}
                        type={'text'}
                        required={false}></CambriaInput>
                    </div>
                  </Row>
                  <Row>
                    <div className={'col-12 col-lg-12'}>
                      <CambriaAutosuggest
                        name={'salesforceAccount'}
                        placeholder={'Salesforce Asset Account'}
                        options={salesforceAccountOptions}
                        onSearch={(keyword: string) => searchSalesforceAccounts(keyword)}
                        onSelect={async (account: any) => {
                          selectSalesforceAccount(account);
                          if (activeCart?.shippingMethods[0]) {
                            dispatch(invalidateStep2());
                            dispatch(setIsUpdatedSalesforceAssetAccount(true));
                          }
                        }}
                        shouldLockOnSelectValue={true}
                        defaultValue={salesforceAccountInitialValue}
                        displayValue={'name'}
                        label={'Salesforce Asset Account'}
                        required={true}></CambriaAutosuggest>
                    </div>
                  </Row>
                </Container>
              </Form>
            );
          }}
        </Formik>
        {showTradeTypeAlert && renderTradeTypeInfoMessage()}
        <div className="salesforce-asset-account-help">
          <span>Can’t find the account </span>
          <em className="list-tooltip-wrapper fa fa-question-circle">
            <span className="salesforce-tooltip">
              <ul>
                <li>Account must exist in Salesforce to be selected</li>
                <li>Account must be a Business Account record type</li>
                <li>Account rank cannot be NP</li>
                <li>Account Company Status cannot be inactive</li>
              </ul>
            </span>
          </em>
        </div>
      </SamplesOrderContainer>
    );
  }
};

export default SamplesOrderDetails;
