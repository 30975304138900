import React from 'react';
import {Table} from 'react-bootstrap';
import AddressBlock from '../../../../OrderGrid/AddressBlock';
import DateBlock from '../../../../OrderGrid/DateBlock';
import OrderDetails from '../../../../OrderGrid/OrderDetails';
import StatusCode from '../../../../StatusCode';
import PlaceholderTable from '../../PlaceHolders/OrderTablePlaceholder.component';
import {useHistory, useLocation} from 'react-router-dom';
import {useAppDispatch} from '../../../../../../hooks/store';
import {routeToOrderDetail} from '../../../../../../features/customer/service/customer.service';
import {formatOrderCurrency} from '../../../../../../Framework/Services/formatCurrency';

export const renderResults = (suggestions: any[], url: string, history: any, dispatch: any, userId?: string) => {
  return suggestions.map((order) => {
    const userFavorite = order.favorites.length > 0 ? order.favorites.find((x: any) => x.userId === userId) : null;
    return (
      <tr
        className="order-table-row"
        key={order.orderNumber}
        onClick={() => routeToOrderDetail(order.orderNumber, order.erpCustomerId, url, history, dispatch)}>
        <td className="order-name">
          <DateBlock date={new Date(order.createdAt)} />
        </td>
        <td>
          <OrderDetails
            url={url}
            createdBy={order.billToName}
            orderNumber={order.orderNumber}
            purchaseOrderNumber={order.purchaseOrderNumber}
            orderType={order.orderTypeDisplayName}
            jobNumber={order.fabricationJobNumber ? order.fabricationJobNumber : null}
            favorite={userFavorite?.name}
          />
        </td>
        <td>
          {order.orderLineItems[0] ? (
            <AddressBlock
              shipToName={order.orderLineItems[0]?.shipToCustomerName}
              address1={order.orderLineItems[0]?.shipToAddress1}
              address2={order.orderLineItems[0]?.shipToAddress2}
              address3={order.orderLineItems[0]?.shipToAddress3}
              city={order.orderLineItems[0]?.shipToCity}
              stateProvinceCode={order.orderLineItems[0]?.shipToStateProvinceCode}
              postalCode={order.orderLineItems[0]?.shipToPostalCode}
            />
          ) : (
            <div></div>
          )}
        </td>
        <td className="order-amount">${formatOrderCurrency(order.totalAmount)}</td>
        <td>
          <StatusCode status={order.statusDisplayName} />
        </td>
      </tr>
    );
  });
};

export const NoResults = () => {
  return (
    <tbody className="order-table-body">
      <tr className="no-results-row">
        <td> </td>
        <td> </td>
        <td className="no-results">No Orders Found</td>
        <td> </td>
        <td> </td>
      </tr>
    </tbody>
  );
};

export const ChooseViewToShow = ({
  isLoading,
  suggestions,
  url,
  userId,
  history,
  dispatch,
}: {
  isLoading: boolean;
  suggestions: any[];
  url: string;
  userId?: string;
  history: any;
  dispatch: any;
}) => {
  if (isLoading) {
    return <PlaceholderTable />;
  } else if (!suggestions || suggestions.length === 0) {
    return <NoResults />;
  }
  return <tbody className="order-table-body">{renderResults(suggestions, url, history, dispatch, userId)}</tbody>;
};

const DesktopPage = ({
  orderSuggestions,
  isLoading,
  userId,
}: {
  orderSuggestions: any[];
  isLoading: boolean;
  userId?: string;
}) => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useAppDispatch();

  return (
    <div className="order-table">
      <Table bordered={false} borderless striped className="table">
        <thead className="order-table-header">
          <tr>
            <th className="order-table-header-cell">
              <span className="order-table-header">DATE</span>
            </th>
            <th className="order-table-header-cell">
              <span className="order-table-header">DETAILS</span>
            </th>
            <th className="order-table-header-cell">
              <span className="order-table-header">SHIPPING ADDRESS</span>
            </th>
            <th className="order-table-header-cell">
              <span className="order-table-header">AMOUNT</span>
            </th>
            <th className="order-table-header-cell">
              <span className="order-table-header">STATUS</span>
            </th>
          </tr>
        </thead>
        <ChooseViewToShow
          isLoading={isLoading}
          suggestions={orderSuggestions}
          url={location.pathname}
          userId={userId}
          history={history}
          dispatch={dispatch}
        />
      </Table>
    </div>
  );
};

export default DesktopPage;
