import {useEffect} from 'react';
import {useAppDispatch, useTypedSelector} from '../../../hooks/store';
import {selectCurrentUser} from '../../auth/slice/authentication.slice';
import {loadExpiringQuotesAsync} from '../slice/quote.slice';
import {selectCurrentCustomer} from '../../customer/slice/customer.slice';

/**
 * Resolve to get close to expiring quotes
 */
export const useExpiringQuoteNotificationResolve = () => {
  const dispatch = useAppDispatch();
  const loggedInUserId = useTypedSelector(selectCurrentUser)?.userId;
  const erpCustomerId = useTypedSelector(selectCurrentCustomer)?.erpCustomerId;

  useEffect(() => {
    if (loggedInUserId && erpCustomerId) {
      dispatch(loadExpiringQuotesAsync({erpCustomerId}));
    }
  }, [dispatch, loggedInUserId, erpCustomerId]);
};
