import {OrderSearchParams} from '../ISearchState';
import COMMERCE_CORE_CONSTANTS from '../../../Core/constants';

export const initialOrderParams: OrderSearchParams = {
  status: '',
  purchaseOrderNumber: '',
  orderType: '',
  orderNumber: '',
  isFavorite: '',
  favoriteName: '',
  endConsumerLastName: '',
  endConsumerFirstName: '',
  'amountRange.amountFrom': '',
  'amountRange.amountTo': '',
  'dateRange.dateFrom': '',
  'dateRange.dateTo': '',
  q: '',
  endConsumerAddress1: '',
  jobNumber: '',
};

export const endpoint = COMMERCE_CORE_CONSTANTS.API_SERVICES.ORDER.orders;
