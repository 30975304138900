import styled from 'styled-components/macro';
import * as colors from './colors';

export const StyledLogoutButton = styled.span`
  color: #333;
  font-weight: 700;
  text-transform: uppercase;
  cursor: pointer;

  &:hover {
    color: ${colors.primary};
  }
`;
