import {Form, Formik} from 'formik';
import React, {useCallback, useEffect, useState} from 'react';
import * as Yup from 'yup';
import {Col, Row} from 'react-bootstrap';
import {useTypedSelector} from '../../../../../../hooks/store';
import {selectCurrentCustomer} from '../../../../../../features/customer/slice/customer.slice';
import {selectActiveProgram} from '../../../../../../features/salesforce/slice/salesforce.slice';
import {selectDuplicatePieceModel} from '../../../../../../features/fabrication/slice/fabrication.slice';
import {ProductTypeItems, ProductTypeResponse} from '../../../../../../features/order/orderDetails/IOrderDetailsState';
import {IFullPieces} from '../../../../../../features/fabrication/IFabricationState';
import CambriaInput from '../../../../../../Framework/Components/CambriaInput';
import CambriaButton from '../../../../../../Framework/Components/CambriaButton';
import Icon from '../../../../../../Framework/Components/Icon';
import CambriaSelect from '../../../../../../Framework/Components/CambriaSelect';
import {getProductGroupItemsRequest} from '../../../../../../features/productType/controller/productType.controller';

interface ISavePieceFunction {
  (shouldTriggerAddNewPiece: boolean, saveAndDuplicate: boolean): Promise<boolean | false>;
}

interface IUpdatePieceFunction {
  (saveAndDuplicate: boolean): Promise<boolean | undefined>;
}

const AdditionalProductsForm = ({
  savePieceFunction,
  saveAndDuplicatePieceFunction,
  handleSelectionFunction,
  parentPieceLabel,
  clearFunction,
  editItem,
  pieceIsSaving,
  duplicateBtnClicked,
  updateItemFunction,
  isDuplicate,
}: {
  savePieceFunction: ISavePieceFunction;
  saveAndDuplicatePieceFunction: Function;
  handleSelectionFunction: any;
  parentPieceLabel?: string;
  clearFunction?: any;
  editItem?: IFullPieces;
  pieceIsSaving: boolean;
  duplicateBtnClicked: boolean;
  updateItemFunction?: IUpdatePieceFunction;
  isDuplicate?: boolean;
}) => {
  const currentCustomer = useTypedSelector(selectCurrentCustomer);
  const activeProgram = useTypedSelector(selectActiveProgram);
  const duplicatePieceModel = useTypedSelector(selectDuplicatePieceModel);

  const [edittedProductHasBeenInitialized, setEdittedProductHasBeenInitialized] = useState<boolean>(false);
  const [productBeingEditted, setProductBeingEditted] = useState<ProductTypeItems>();
  const [productGroupItems, setProductGroupItems] = useState<ProductTypeResponse | null>(null);
  const [serviceType, setServiceType] = useState<any | null>(null);
  const [selectedServiceType, setSelectedServiceType] = useState<string | null>(null);
  const [selectedItemDescription, setSelectedItemDescription] = useState<string | null | undefined>(
    duplicatePieceModel?.itemDescription || editItem?.mainPiece.description
  );
  const [descriptionOptions, setDescriptionOptions] = useState<any[]>([]);
  const [unitOfMeasure, setUnitOfMeasure] = useState<string>();

  const setServiceTypeFunction = useCallback(
    (productType: ProductTypeResponse) => {
      const allServiceTypesAsDictionary: any = {};
      productType.items.forEach(
        (element) => (allServiceTypesAsDictionary[element.productDisplayName] = element.productDisplayName)
      );
      const allServiceTypesAsArray = Object.values(allServiceTypesAsDictionary).map((x) => ({value: x}));
      const sortBy = '';
      let serviceTypeTemp = {
        name: 'Product/Service Type',
        selections: allServiceTypesAsArray,
        selected: selectedServiceType,
        disabled: false,
        sortBy,
        error: false,
        errorMsg: '',
      };
      setServiceType(serviceTypeTemp);
    },
    [selectedServiceType]
  );

  const getProductByItemNumberFromProductGroupItem = useCallback(
    (response: ProductTypeResponse, editItem: IFullPieces) => {
      response.items.forEach((item: ProductTypeItems) => {
        if (item.itemNumber === editItem?.mainPiece?.itemNumber) {
          setProductBeingEditted(item);
        }
      });
    },
    []
  );

  const getUnitOfMeasure = useCallback((selectedItem: any) => {
    const unitOfMeasure = selectedItem.primaryUomCode === 'EA' ? '' : selectedItem.primaryUomCode;
    setUnitOfMeasure(unitOfMeasure);
  }, []);

  const serviceTypeSelection = useCallback(
    (selectedValue: string) => {
      setSelectedServiceType(selectedValue);
      if (productGroupItems) {
        let filteredItems = setFilteredItems(productGroupItems, selectedValue);
        setItemDescriptions(filteredItems);
      }
    },
    [productGroupItems]
  );

  const setFilteredItems = (productType: ProductTypeResponse, selectedServiceType: string): Array<ProductTypeItems> => {
    let filteredItems: any[] = [];
    if (productType.items && productType.items.length > 0) {
      filteredItems = productType.items.filter((x) => x.productDisplayName === selectedServiceType);
    }
    return filteredItems;
  };

  const setItemDescriptions = (filteredItems: Array<any>) => {
    const allItemDescriptionsForSelectedServiceType: any[] = [];

    if (filteredItems && filteredItems.length > 0) {
      filteredItems.forEach((element) => {
        allItemDescriptionsForSelectedServiceType.push({
          name: element.description,
          value: element.description,
          items: element.attributes,
          primaryUomCode: element.primaryUomCode,
        });
      });
    }

    if (allItemDescriptionsForSelectedServiceType.length > 1) {
      allItemDescriptionsForSelectedServiceType.sort((a, b) => {
        return a.value.localeCompare(b.value);
      });
    }
    setDescriptionOptions(allItemDescriptionsForSelectedServiceType);
  };

  const disableSaveButton = (props: any) => {
    return !props.values.itemDescription || !props.values.quantity || !serviceType || !props.isValid || pieceIsSaving;
  };

  useEffect(() => {
    if (productBeingEditted && selectedServiceType && !edittedProductHasBeenInitialized) {
      handleSelectionFunction('selectedServiceType', productBeingEditted.productDisplayName);
      setEdittedProductHasBeenInitialized(true);
    }
  }, [productBeingEditted, handleSelectionFunction, selectedServiceType, edittedProductHasBeenInitialized]);

  useEffect(() => {
    if (productBeingEditted && !edittedProductHasBeenInitialized && !selectedServiceType) {
      setSelectedItemDescription(productBeingEditted.description);
      setSelectedServiceType(productBeingEditted.productDisplayName);
    }
  }, [productBeingEditted, handleSelectionFunction, edittedProductHasBeenInitialized, selectedServiceType]);

  useEffect(() => {
    const getProductItemsAndServiceType = async () => {
      let results = await getProductGroupItemsRequest(
        'GeneralItems',
        currentCustomer.erpCustomerId.toString(),
        currentCustomer.classGroups,
        activeProgram
      );
      setProductGroupItems(results);
      setServiceTypeFunction(results);
      if (editItem && !productBeingEditted) {
        getProductByItemNumberFromProductGroupItem(results, editItem);
      }
    };
    if (!productGroupItems) {
      getProductItemsAndServiceType();
    }
  }, [
    currentCustomer,
    activeProgram,
    setServiceTypeFunction,
    getProductByItemNumberFromProductGroupItem,
    editItem,
    productBeingEditted,
    productGroupItems,
  ]);

  useEffect(() => {
    if (productBeingEditted && productGroupItems) {
      let filteredItems = setFilteredItems(productGroupItems, productBeingEditted.productDisplayName);
      setItemDescriptions(filteredItems);
    }
  }, [productBeingEditted, productGroupItems]);

  useEffect(() => {
    if (duplicatePieceModel?.selectedServiceType) {
      serviceTypeSelection(duplicatePieceModel.selectedServiceType);
    }
  }, [duplicatePieceModel, serviceTypeSelection]);

  return (
    <Formik
      enableReinitialize
      initialValues={{
        serviceType: duplicatePieceModel?.selectedServiceType || editItem?.mainPiece.productType,
        itemDescription: selectedItemDescription,
        quantity: duplicatePieceModel?.quantity || editItem?.mainPiece.unitSize,
      }}
      onSubmit={() => {}}
      validation={Yup.object().shape({
        serviceType: Yup.mixed().required('This field is required'),
        itemDescription: Yup.mixed().required('This field is required'),
        quantity: Yup.string().required('This field is required'),
      })}>
      {(props) => {
        return (
          <Form>
            <Row>
              <Col xs={4}>
                <CambriaSelect
                  disabled={!serviceType || !serviceType.selections}
                  label="PRODUCT/SERVICE TYPE"
                  name="serviceType"
                  items={serviceType?.selections || []}
                  displayValue="value"
                  required
                  defaultValue={productBeingEditted?.productDisplayName || duplicatePieceModel?.selectedServiceType}
                  placeholder="Product/Service Type"
                  onChange={(item: any) => {
                    serviceTypeSelection(item.value);
                    handleSelectionFunction('selectedServiceType', item.value);
                    props.setFieldValue('quantity', '');
                    setSelectedItemDescription(null);
                  }}
                />
              </Col>
              <Col xs={4}>
                <CambriaSelect
                  disabled={!editItem && !duplicatePieceModel && !selectedServiceType}
                  label="ITEM DESCRIPTION"
                  name="itemDescription"
                  required
                  defaultValue={selectedItemDescription}
                  placeholder="Item Description"
                  displayValue="name"
                  items={descriptionOptions}
                  onChange={(selection: any) => {
                    getUnitOfMeasure(selection);
                    setSelectedItemDescription(selection.name);
                    props.setFieldValue('quantity', '');
                    handleSelectionFunction('itemDescription', selection.name);
                  }}
                />
              </Col>
              <Col xs={4}>
                <CambriaInput
                  label={`QUANTITY ${unitOfMeasure ? `(${unitOfMeasure})` : ''}`}
                  required
                  type="number"
                  name="quantity"
                  defaultValue={duplicatePieceModel?.quantity}
                  disabled={!editItem && !duplicatePieceModel && !selectedItemDescription}
                  onChange={(e: any) => {
                    handleSelectionFunction('quantity', e.target.value);
                  }}
                  placeholder="Quantity"
                />
              </Col>
            </Row>
            <Row className="w-100 d-flex justify-content-end m-0">
              <Col xs={6} className="d-flex justify-content-around pr-0">
                <CambriaButton variant="transparent" onClick={() => clearFunction()}>
                  CANCEL
                </CambriaButton>
                <CambriaButton
                  disabled={disableSaveButton(props)}
                  variant="primary"
                  onClick={() =>
                    !!editItem && !!updateItemFunction && !isDuplicate
                      ? updateItemFunction(false)
                      : savePieceFunction(false, false)
                  }>
                  {pieceIsSaving && (
                    <Icon className="save-piece-spinner" size="20" weight="600" icon="fa fa-spinner fa-spin" disabled />
                  )}
                  {parentPieceLabel ? `SAVE ITEM TO PIECE ${parentPieceLabel}` : 'SAVE PIECE'}
                </CambriaButton>
                {!parentPieceLabel && (
                  <CambriaButton
                    disabled={disableSaveButton(props)}
                    variant="primary"
                    onClick={() => saveAndDuplicatePieceFunction()}>
                    {duplicateBtnClicked && (
                      <Icon
                        className="save-piece-spinner"
                        size="20"
                        weight="600"
                        icon="fa fa-spinner fa-spin"
                        disabled
                      />
                    )}
                    SAVE AND DUPLICATE
                  </CambriaButton>
                )}
              </Col>
            </Row>
          </Form>
        );
      }}
    </Formik>
  );
};

export default AdditionalProductsForm;
