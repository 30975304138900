import {Route, Switch} from 'react-router-dom';
import React from 'react';
import Home from './Home';
import NoMatch from './NoMatch';
import Product from './Product';
import SearchResults from './SearchResults';
import Account from './Account';
import Academy from './Academy';
import Header from '../../Core/Components/Header';
import Footer from '../../Core/Components/Footer';
import {useTypedSelector} from '../../hooks/store';
import {selectUiSettings} from '../../features/environment/slice/environment.slice';
import OrderSearchPage from '../../Core/Components/SearchPages/OrderSearchPage';
import Resolves from './Resolves';
import CambriaSpinner from '../../Framework/Components/CambriaSpinner';
import {selectResolveLoadStatus} from '../../features/resolves/slice/resolves.slice';
import NotificationManager from '../../Core/Components/Notifications';
import ScrollToTop from './ScrollToTopWrapper/ScrollToTop';
import Checkout from './Checkout';
import PurchaseAgreement from './PurchaseAgreement';
import FabricationOrderEntry from './FabricationOrderEntry';
import Maintenance from '../../Core/Components/Maintenance';
import Cart from './Cart';
import QuoteConfirmation from './QuoteConfirmation';
import CustomerSearchPage from '../../Core/Components/SearchPages/CustomerSearchPage';
import {useShowCartPreview} from '../../features/cart/hooks/useShowCartPreview/useShowCartPreview';
import {AuthenticatedPagesContainer} from './AuthenticatedPages.styled';
import CartPreview from './Cart/CartPreview';
import PDFViewer from './PDFViewer';

const AuthenticatedApp: React.FC = () => {
  const uiSettings = useTypedSelector(selectUiSettings);
  const resolvesAreLoading = useTypedSelector(selectResolveLoadStatus);
  const showCartPreview = useShowCartPreview();

  return (
    <AuthenticatedPagesContainer>
      <ScrollToTop>
        <Resolves>
          {resolvesAreLoading ? (
            <CambriaSpinner />
          ) : (
            <div className={showCartPreview ? 'with-cart-preview' : ''}>
              {<NotificationManager />}
              {!uiSettings?.maintenanceMode && <Header />}
              {!uiSettings?.maintenanceMode ? (
                <Switch>
                  <Route path="/product/:type?" component={Product} />
                  <Route path="/searchresults" component={SearchResults} />
                  <Route path="/account" component={Account} />
                  <Route path="/cart" component={Cart} />
                  <Route path="/checkout" component={Checkout} />
                  <Route path="/purchaseagreement" component={PurchaseAgreement} />
                  <Route path="/search/customers" component={CustomerSearchPage} />
                  <Route path="/search/orders" component={OrderSearchPage} />
                  <Route path="/fabrication" component={FabricationOrderEntry} />
                  <Route path="/quoteConfirmation/:quoteId?" component={QuoteConfirmation} />
                  <Route path="/academy" component={Academy} />
                  <Route path="/pdfViewer/:pdfOrigin/:pdfId?/:useToolbar?" component={PDFViewer} />
                  <Route exact path="/" component={Home} />
                  <Route path="*" component={NoMatch} />
                </Switch>
              ) : (
                <Maintenance></Maintenance>
              )}
              {!uiSettings?.maintenanceMode && <Footer />}
              {showCartPreview && <CartPreview />}
            </div>
          )}
        </Resolves>
      </ScrollToTop>
    </AuthenticatedPagesContainer>
  );
};

export default AuthenticatedApp;
