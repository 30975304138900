import qs from 'qs';
import React, {useEffect, useState} from 'react';
import {Col, Row} from 'react-bootstrap';
import {useHistory} from 'react-router-dom';
import {toast} from 'react-toastify';
import {
  selectCurrentCustomer,
  selectCurrentCustomerShipToSites,
} from '../../../../features/customer/slice/customer.slice';
import {selectUserActions} from '../../../../features/permission/slice/permission.slice';
import {
  selectProduct,
  selectProductId,
  setProductByIdAsync,
  setProductGroupItemsByCodeAsync,
  setProductId,
  setUpdatedProduct,
} from '../../../../features/productCatalog/slice/productCatalog.slice';
import {selectActiveProgram} from '../../../../features/salesforce/slice/salesforce.slice';
import {useAppDispatch, useTypedSelector} from '../../../../hooks/store';
import {hasPermission} from '../../../../store/permission/permission.service';
import Quick from './Quick';
import QuickPlaceholders from './Quick/QuickPlaceholders';
import Standard from './Standard';
import StandardPlaceholders from './Standard/StandardPlaceholders';
import {IProductNavTabOption} from '../../../../features/product/IProductState';
import {getShipFromWarehouseId} from '../../../../features/warehouse/service/warehouse.service';
import COMMERCE_CORE_CONSTANTS from '../../../../Core/constants';
import {pushViewItemInformationToDataLayer} from '../../../../features/analytics/service/analytics.service';

const getProductId = (pathname: string) => {
  let productId = pathname;

  if (productId.includes(COMMERCE_CORE_CONSTANTS.PATHNAMES.productQuick)) {
    productId = productId.replace(COMMERCE_CORE_CONSTANTS.PATHNAMES.productQuick, '');
  }

  productId = productId.replace(COMMERCE_CORE_CONSTANTS.PATHNAMES.productDetails, '');

  return productId;
};

const ProductDetails = (props: {location: any}) => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const activeProgram = useTypedSelector(selectActiveProgram);
  const customer = useTypedSelector(selectCurrentCustomer);
  const shipToSites = useTypedSelector(selectCurrentCustomerShipToSites);
  const product: any = useTypedSelector(selectProduct);
  const productId = useTypedSelector(selectProductId);
  const actions = useTypedSelector(selectUserActions);

  const data =
    customer?.erpCustomerId && customer?.class && customer?.classGroups && shipToSites
      ? qs.stringify({
          erpCustomerId: customer.erpCustomerId,
          customerClass: customer.class,
          customerClassGroups: customer.classGroups,
          slabWarehouseCode: getShipFromWarehouseId('Slab', customer, shipToSites),
          tileWarehouseCode: getShipFromWarehouseId('Tile', customer, shipToSites),
          samplesWarehouseCode: getShipFromWarehouseId('Samples', customer, shipToSites),
          storeWarehouseCode: getShipFromWarehouseId('Store', customer, shipToSites),
        })
      : null;

  const canSeeQuickOrdering = hasPermission('urn:csa:commerceui:productcatalog:viewquickorder', actions);
  const availableNavTabs: IProductNavTabOption[] =
    productId && product?.displayName && canSeeQuickOrdering && product?.quickOrderAttributes?.length > 0
      ? [
          {
            id: 'Standard',
            sref: `/product/details/${productId}`,
            name: `Standard ${product.displayName} Order`,
          },
          {
            id: 'Quick',
            sref: `/product/details/${productId}/quick`,
            name: `Quick ${product.displayName} Order`,
          },
        ]
      : [];

  let [isQuickPDP, setIsQuickPDP] = useState(
    props.location.pathname.includes(COMMERCE_CORE_CONSTANTS.PATHNAMES.productQuick)
  );
  let [currentState, setCurrentState] = useState('Standard');
  let [shouldShowQuickOrdering, setShouldShowQuickOrdering] = useState(false);

  const currentProductId = getProductId(history.location.pathname);

  useEffect(() => {
    if (data) {
      if (!productId && currentProductId) {
        dispatch(setProductId(currentProductId));
      } else if (productId !== currentProductId) {
        dispatch(setProductId(null));
        dispatch(setUpdatedProduct(null));
      } else if (productId) {
        dispatch(setProductByIdAsync({productId, data}));
      }
    }
  }, [productId, currentProductId, data, dispatch]);

  useEffect(() => {
    const validateProgramCodeOnProduct = () => {
      let productProgramCodeDoesNotMatchActiveProgramCode = true;
      product.programCodes.forEach((programCode: any) => {
        if (programCode === activeProgram.code) {
          productProgramCodeDoesNotMatchActiveProgramCode = false;
        }
      });
      if (productProgramCodeDoesNotMatchActiveProgramCode) {
        toast.error(
          `The requested product is not available while ordering through the ${activeProgram.commerceDisplayName} program.`
        );
        history.push(COMMERCE_CORE_CONSTANTS.PATHNAMES.home);
      }
    };
    if (product && (!product.items || product.items.length === 0)) {
      dispatch(setProductGroupItemsByCodeAsync({product, data}));
    } else if (product && product.items && product.items.length > 0 && activeProgram && activeProgram.code) {
      validateProgramCodeOnProduct();
    }
  }, [product, dispatch, activeProgram, data, history]);

  useEffect(() => {
    if (availableNavTabs.length > 0 && availableNavTabs[0].sref && !shouldShowQuickOrdering) {
      setShouldShowQuickOrdering(true);
      if (isQuickPDP) {
        setCurrentState(availableNavTabs[1].id);
        history.push(availableNavTabs[1].sref);
      }
    }
  }, [availableNavTabs, history, isQuickPDP, shouldShowQuickOrdering]);

  useEffect(() => {
    if (product && product.Message && product.Message === 'Id is not found.') {
      toast.error(`The requested product is either not available or does not exist.`);
      history.push(COMMERCE_CORE_CONSTANTS.PATHNAMES.home);
    }
  }, [product, history]);

  const updateState = (tab: IProductNavTabOption): void => {
    if (isQuickPDP && tab.id === 'Standard') {
      setIsQuickPDP(false);
    }
    setCurrentState(tab.id);
    history.push(tab.sref);
  };

  useEffect(() => {
    if (product) {
      pushViewItemInformationToDataLayer(product);
    }
  }, [product]);

  return (
    <div>
      {shouldShowQuickOrdering ? (
        <Row className="product-details">
          {availableNavTabs.map((tab: IProductNavTabOption, index: number) => (
            <Col
              xs={6}
              key={tab.id + index}
              className={`product-details-nav-tabs${tab.id === currentState ? ' active-product-details-nav-tab' : ''}`}>
              <span
                className="ripple"
                onClick={() => {
                  updateState(tab);
                }}>
                {tab.name}
              </span>
            </Col>
          ))}
        </Row>
      ) : (
        <div></div>
      )}
      {product && !product.Message && product.items.length !== 0 ? (
        <div>
          {currentState === 'Standard' ? <Standard product={product}></Standard> : <Quick product={product}></Quick>}
        </div>
      ) : !props.location.pathname.includes(COMMERCE_CORE_CONSTANTS.PATHNAMES.productQuick) ? (
        <StandardPlaceholders></StandardPlaceholders>
      ) : (
        <QuickPlaceholders></QuickPlaceholders>
      )}
    </div>
  );
};

export default ProductDetails;
