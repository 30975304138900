import React, {FormEvent, useState} from 'react';
import {PasswordResetPageFormContainer} from './PasswordResetPageForm.styled';
import {useAppDispatch} from '../../../../hooks/store';
import {useHistory} from 'react-router-dom';
import {requestPasswordResetAsync} from '../../../../features/password/slice/password.slice';
import COMMERCE_CORE_CONSTANTS from '../../../../Core/constants';

const PasswordResetForm = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const [errorMessage, setErrorMessage] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [isFetchingData, setIsFetchingData] = useState<boolean>(false);

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setIsFetchingData(true);
    if (!email) {
      setErrorMessage('Please input your email');
      return;
    }

    const result: any = await dispatch(requestPasswordResetAsync(email));

    if (result.error) {
      setErrorMessage(result.error.message);
      setIsFetchingData(false);
      return;
    }
    if (result.payload === '') {
      history.push(COMMERCE_CORE_CONSTANTS.PATHNAMES.login);
    }
    setIsFetchingData(false);
  };

  return (
    <PasswordResetPageFormContainer onSubmit={handleSubmit}>
      <h3 className="title">Password Reset</h3>
      {errorMessage && (
        <div className="error-container">
          <p>
            <span>{errorMessage}</span>
          </p>
        </div>
      )}
      <div className="privacy-terms">
        Enter your email address and if we recognize it, we will send you an email to reset your password.
      </div>
      <div className="form-group has-feedback">
        <input
          type="email"
          name="username"
          placeholder="Email"
          data-testid="emailField"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>
      <button id="LoginSignIn" type="submit" className="submit-button" disabled={isFetchingData}>
        Submit
      </button>
    </PasswordResetPageFormContainer>
  );
};

export default PasswordResetForm;
