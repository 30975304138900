import styled from 'styled-components';
import {$gray78, $newdanger} from '../../../Styled/variables';

export const CambriaSelectContainer = styled.div`
  margin-bottom: 19px;

  .select-tooltip-wrapper {
    position: absolute !important;
    margin-left: 10px !important;
    margin-top: 2px !important;
    width: 17px;
    height: 17px;
    .select-tooltip {
      top: 10px !important;
      left: 7px !important;
      position: relative !important;
    }
  }

  .react-select {
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
    border-radius: 3px;
    font-weight: 500;
    font-size: 14px;

    .cambria-select-add-on-template-text {
      position: absolute;
      right: 40px;
      font-size: 12px;
      color: ${$gray78};
    }

    .icon {
      float: right;
      transition: all 0.25s ease-out;
      transform: '';

      &.is-open {
        transform: rotate(180deg);
      }
    }

    &.error {
      border: 1px solid ${$newdanger};
    }

    &.outlined {
      border: 1px solid rgb(204, 204, 204);
      box-shadow: none;
    }
  }

  input {
    box-shadow: none !important;
    border: none !important;
    :focus {
      box-shadow: none !important;
      border: none !important;
    }
  }
  .error-messaging {
    font-size: 12px;
    font-weight: 500;
    color: ${$newdanger};
  }
`;
