import styled from 'styled-components';
import {$screensm} from '../../../../Styled/variables';

export const InTheirOwnWordsContainer = styled.div`
  margin-top: 30px;

  h2 {
    font-weight: 400;
    font-size: 24px;
    margin-bottom: 18px;
    text-transform: uppercase;
    margin-bottom: 30px;
  }

  p {
    max-width: 65%;
    margin: 0 auto;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
  }

  .academy-quote-row {
    margin-bottom: 50px;

    .quote-row {
      margin-bottom: 20px;

      .quote {
        font-style: italic;
      }
    }
  }

  @media screen and (max-width: ${$screensm}) {
    p {
      max-width: 90%;
    }
  }
`;
