import React from 'react';
import {Accordion, Card} from 'react-bootstrap';
import ContentLoader from 'react-content-loader';
import CustomerPlaceholderContainer, {CustomerPlaceholderMobileContainer} from './CustomerTablePlaceholder.styled';

export const CustomerTableRowPlaceholder = () => {
  return (
    <CustomerPlaceholderContainer className="customer-table-row">
      <td className="customer-name">
        <ContentLoader width={360} height={30} animate={false} foregroundColor={'#dfdfdf'} backgroundColor={'#dfdfdf'}>
          <rect x="0" y="0" width="150" height="10" />
        </ContentLoader>
      </td>
      <td className="mobile-hidden customer-details">
        <ContentLoader width={570} height={30} animate={false} foregroundColor={'#dfdfdf'} backgroundColor={'#dfdfdf'}>
          <rect x="0" y="0" width="300" height="10" />
        </ContentLoader>
      </td>
      <td className="mobile-hidden customer-address">
        <ContentLoader width={80} height={30} animate={false} foregroundColor={'#dfdfdf'} backgroundColor={'#dfdfdf'}>
          <rect x="0" y="0" width="50" height="10" />
        </ContentLoader>
      </td>
    </CustomerPlaceholderContainer>
  );
};

export const PanelHeader = () => {
  return (
    <div className="placeholder-header">
      <ContentLoader width={150} height={25} animate={false} foregroundColor={'#dfdfdf'} backgroundColor={'#dfdfdf'}>
        <rect x="0" y="0" width="250" height="15" />
      </ContentLoader>
    </div>
  );
};

export const CustomerTablePlaceholderMobileRow = () => {
  return (
    <Accordion className="customer-name">
      <Card>
        <Card.Header>
          <Accordion.Toggle eventKey="0" className="accordion-button">
            <PanelHeader />
          </Accordion.Toggle>
        </Card.Header>
      </Card>
    </Accordion>
  );
};
export const CustomerTablePlaceholderMobile = () => {
  return (
    <CustomerPlaceholderMobileContainer>
      <CustomerTablePlaceholderMobileRow />
      <CustomerTablePlaceholderMobileRow />
      <CustomerTablePlaceholderMobileRow />
      <CustomerTablePlaceholderMobileRow />
      <CustomerTablePlaceholderMobileRow />
      <CustomerTablePlaceholderMobileRow />
    </CustomerPlaceholderMobileContainer>
  );
};

const CustomerTablePlaceholder = () => {
  return (
    <tbody>
      <CustomerTableRowPlaceholder />
      <CustomerTableRowPlaceholder />
      <CustomerTableRowPlaceholder />
      <CustomerTableRowPlaceholder />
      <CustomerTableRowPlaceholder />
      <CustomerTableRowPlaceholder />
    </tbody>
  );
};

export default CustomerTablePlaceholder;
