import React from 'react';
import {ICampaignNotification} from '../../../features/notification/INotificationState';
import {selectCampaignMessages} from '../../../features/notification/slice/notification.slice';
import {useTypedSelector} from '../../../hooks/store';
import CampaignMessage from './Campaign';
import {selectShowCloseToExpireQuoteNotifications} from '../../../features/environment/slice/environment.slice';
import {selectExpiringQuotes} from '../../../features/quote/slice/quote.slice';
import ExpiringQuoteNotification from './ExpiringQuote';

const NotificationManager = () => {
  const campaignMessages = useTypedSelector(selectCampaignMessages);
  const showExpiringQuoteNotifications = useTypedSelector(selectShowCloseToExpireQuoteNotifications);
  const expiringQuotes = useTypedSelector(selectExpiringQuotes);

  return (
    <>
      {campaignMessages.map((message: ICampaignNotification, index) => {
        return (
          <CampaignMessage
            title={message.title}
            body={message.body}
            campaignId={message.id}
            key={index}
            isLastMessageToBeShown={index === campaignMessages.length - 1}
          />
        );
      })}
      {showExpiringQuoteNotifications &&
        expiringQuotes.map((quote) => (
          <ExpiringQuoteNotification expiringQuote={quote} key={`quote-notification-${quote.id}`} />
        ))}
    </>
  );
};

export default NotificationManager;
