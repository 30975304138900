import {createSlice} from '@reduxjs/toolkit';
import {filters, IProductSearchState} from '../IProductSearchState';

export const initialState: IProductSearchState = {
  keyword: '',
  activeFilter: {
    name: 'All',
    param: 'All',
    mobileName: 'All',
  },
  suggestions: [],
  availableFilters: filters,
};

export const productSearchSlice = createSlice({
  name: 'productSearch',
  initialState,
  reducers: {
    setProductKeyword: (state, action) => {
      state.keyword = action.payload;
    },
    setProductFilter: (state, action) => {
      state.activeFilter = action.payload;
    },
    setProductRecommendations: (state, action) => {
      state.suggestions = action.payload;
    },
  },
});

export const {setProductKeyword, setProductFilter, setProductRecommendations} = productSearchSlice.actions;
export default productSearchSlice.reducer;
