export interface CartState {
  activeCart: Cart | null;
  savedItems: any[];
  cartCalculationsComplete: boolean;
  disableRemoveButtonWhileRemoving: boolean;
  isMovingItemToSaveForLater: boolean;
  isMovingItemToCart: boolean;
  disableQuantityButtons: boolean;
}

export interface Cart {
  id: string;
  userId: string;
  erpCustomerId: string;
  customerClass: string;
  isActive: boolean;
  isLocked: boolean;
  wasTaxCalculated: boolean;
  wasActualPaymentCalculated: boolean;
  isReinitializing: boolean;
  selectedBy: Array<string>;
  orderNumber: string;
  transactionType: string;
  createdAt: Date | null;
  updatedAt: Date | null;
  subtotalAmount: number;
  totalNetAmount: number;
  totalAmount: number;
  totalProductDiscountAndGiftCodeAmount: number;
  totalHeaderAdjustments: number;
  standardDiscountAmount: number;
  giftCodeAmount: number;
  discountCodeAmount: number;
  totalTaxAmount: number;
  totalShippingAmount: number;
  totalShippingDiscountAmount: number;
  estimatedPaymentFee: number;
  actualPaymentFeeAmount: number;
  cartItems: Array<CartItem>;
  voucherCodes: Array<VoucherCode>;
  requestedDeliverySchedules: Array<any>;
  shippingMethods: Array<ShippingMethod>;
  paymentMethods: Array<PaymentMethod>;
  taxCalculations: Array<TaxCalculation>;
  orderDetails: Array<OrderDetail>;
  customerClassGroups: Array<string>;
  purchasingCustomers: Array<PurchasingCustomer>;
  headerAdjustments: Array<HeaderAdjustments>;
  totalHeaderAdjustmentAmount: number;
  quoteDiscounts: Array<QuoteDiscount>;
  quoteId: number;
  totalQuoteDiscountAmount: number;
  crmQuoteNumber: string;
  totalPricingItemsDiscounts: number;
  userSelectedWarehouseCode?: string | null;
  quoteName?: string;
  bundleValidations?: CartBundleValidation[] | null;
  isValid?: boolean;
}

export interface CartBundleValidation {
  bundleId: number;
  isComplete: boolean;
  requiredSlab: CartBundleRequiredSlab | null;
  slabsNeeded: CartBundleSlabNeeded[];
}

export interface CartBundleSlabNeeded {
  size: string;
  thickness: string;
  requiredQty: number;
  requiredDesigns: CartBundleDesign[] | null;
}

export interface CartBundleDesign {
  designCode: string;
  designName: string;
  linkToProduct: string;
}

export interface CartBundleRequiredSlab {
  itemNumber: string;
  itemDescription: string;
  linkToProduct: string;
  quantity: number;
}

export interface CartItem {
  productId: string;
  productType: 'Slab' | 'Tile' | 'Store' | 'Samples' | 'Fabricated' | 'FabricatedService' | 'Surfaces' | 'Fabrication';
  productActiveLocations: Array<string>;
  productCategory: string;
  description: string;
  itemNumber: string;
  cartItemId: number;
  unitPrice: number;
  unitSize: number;
  pricingUom: string;
  itemQuantityPrice: number;
  extendedNetPrice: number;
  shipFromWarehouseId: string;
  primaryUomCode: string;
  unitWeight: number;
  weightUomCode: string;
  taxProductCode: string;
  tempQuantity: number | null;
  shippingClassifications: Array<string>;
  organizations: Array<any>;
  adjustedExtendedNetPrice: number;
  adjustedUnitPrice: number;
  hasAdjustedExtendedNetPrice: boolean;
  hasAdjustedUnitPrice: boolean;
  discountAmount: number;
  discountCategory: string;
  createdAt: Date | null;
  updatedAt: Date | null;
  cartItemType: 'Unknown' | 'ComplimentaryCheeseboard' | 'Standard' | 'FabAndInstallDiscrete' | 'FabAndInstallStandard';
  currency: string;
  operatingUnitCode: string;
  priceListCode: string;
  programCode: string;
  programName: string;
  isAvailable: boolean;
  uomLineQuantity: number;
  uomLineQuantityDisplayString: string;
  designCode: string;
  pieceLabel: string;
  productApplication: string;
  productGroupCode: string;
  fabOrderingViewType: string;
  dimension1: number;
  dimension2: number;
  dimension3: number;
  dimension4: number;
  parentCartItemId: number | null;
  designName: string;
  designCollection: string;
  thickness: string;
  finish: string;
  match: string;
  edgeProfileName: string;
  edgeProfileCode: string;
  serviceOfferings: boolean;
  adjusteditemQuantityPrice: number;
  hasAdjusteditemQuantityPrice: boolean;
  siteUseId: number;
  requestedDeliveryDate: Date | null;
  seamed: boolean;
  cartId: string;
  quantity: number | null;
  imageUrl: string;
  requireOrderingInQuantitiesOfTwo?: boolean;
  qualifiesforFreeShippingForHomeDepot?: boolean;
  _quantity: number | null;
  _imageUrl: string;
  lineItemHalfBundleQuantity?: number | null;
  bundleId?: number | null;
}

export type CartInitializeReason =
  | 'resubmitOrder'
  | 'editOrder'
  | 'cdmpComponentResolve'
  | 'fabricationComponentResolve'
  | 'checkoutComponentResolve'
  | 'reorder'
  | 'editQuoteComplete';

export interface VoucherCode {
  description: string;
  codeActivationId: number;
  adjustmentAmount: number;
  cartItemId: string;
  category: string;
  itemNumber: string;
  validationId: string;
  createdAt: Date;
  updatedAt: Date;
  itemNumbers: Array<string>;
  removable: boolean;
  codeString: string;
}

export interface ShippingMethod {
  cartId: string;
  selectedShippingMethodId: number;
  shippingOptionId: number;
  shippingOptionName: string;
  shippingMethodCode: string;
  shippingMethodName: string;
  freightTerms: string;
  message: string;
  costAmount: number;
  customerShippingAccountId: string;
  shipToSiteId: number;
  shipToContactId: number;
  shipFromWarehouseId: number;
  deliveryRate: number;
  deliveryRateType: string;
  createdAt: Date;
  updatedAt: Date;
  itemNumbers: Array<string>;
  shippingInstructions: string;
  shipComplete: boolean;
}

export interface PaymentMethod {
  selectedPaymentMethodId: number;
  paymentTypeCode: string;
  paymentTermCode: string;
  paymentTermDescription: string;
  prepaymentMethodToken: string;
  billToSiteCode: string;
  createdAt: Date;
  updatedAt: Date;
  itemNumbers: Array<string>;
}

export interface TaxCalculation {
  taxCalculationId: number;
  taxableOverrideStatus: string;
  taxOverrideReason: string;
  taxExemptCertificateCode: string;
  taxableBasis: number;
  nonTaxableBasis: number;
  exemptAmount: number;
  taxRate: string;
  taxAmount: number;
  createdAt: Date;
  updatedAt: Date;
  itemNumber: string;
  hadError: boolean;
}

export interface OrderDetail {
  cartId: string;
  purchaseOrderNumber?: string;
  homeDepotOrderNumber?: string;
  homeDepotOrderNumbers?: string[];
  orderCrmAccountId?: string;
  orderCrmAccountName?: string;
  orderCrmAccountTradeType?: string;
  jobType?: string;
  jobTypeName?: string;
  projectType?: string;
  projectName?: string;
  projectCity?: string;
  orderPurpose?: string;
  orderEventName?: string;
  relatedAgency?: string;
  createdAt?: Date;
  updatedAt?: Date;
  publicFacingDisplay?: boolean;
  surfacesWarehouseCodeOverride?: string;
  orderPieceRework?: boolean;
  orderIsRevisionOfPreviousJob?: boolean;
  salesforceQuoteId?: string;
  fabricationOrderDrafter?: string;
  fabricationOrderCadRevisionsNeededEmailList?: string;
  fabricationOrderPoRevisionsNeededEmailList?: string;
  fabricationOrderOrderApprovedEmailList?: string;
  fabAssignableCause?: string;
  fabReworkReason?: string | IRemakeReason;
  originalSalesOrderNumber?: string;
  fabricationInstructions?: string;
}

export interface IRemakeReason {
  reasonName: string;
  assignableCauses: Array<string>;
}

export interface HeaderAdjustments {
  id: number;
  name: string;
  type: string;
  modifier: string;
  value: number;
  listLine: string;
  reasonCode: string;
  reasonTxt: string;
  createdAt: Date;
  updatedAt: Date;
}

export interface QuoteDiscount {
  itemNumber: string;
  listLine: string;
  modifier: string;
  reasonText: string;
  reasonCode: string;
  value: number;
  extendedValue: number;
}

export interface PurchasingCustomer {
  id: number;
  userId: string;
  erpCustomerId: number;
  cartId: string;
  customerSiteId: number;
  customerContactId: number;
  siteName: string;
  contactFirstName: string;
  contactLastName: string;
  contactPhoneNumber: string;
  contactEmail: string;
  address1: string;
  address2: string;
  address3: string;
  address4: string;
  city: string;
  stateProvinceCode: string;
  postalCode: string;
  countryCode: string;
  createdAt: Date | string;
  createdBy: string;
  updatedAt: Date | string;
  updatedBy: string;
  retailCrmAccountId: string;
  isActive: false;
  orderNumber: string;
  endConsumers: EndConsumer[];
  endUsers?: any[];
  isUpdatePending: boolean;
}

export interface EndConsumer {
  id: number;
  userId: string;
  erpCustomerId: number;
  cartId: string;
  retailSiteId: number;
  contactFirstName: string;
  contactLastName: string;
  contactPhoneNumber: string;
  contactEmail: string;
  address1: string;
  address2: string;
  address3: string;
  address4: string;
  city: string;
  stateProvinceCode: string;
  postalCode: string;
  countryCode: string;
  createdAt: Date | undefined;
  createdBy: string | undefined;
  updatedAt: Date | undefined;
  updatedBy: string;
  isActive: boolean;
  jobNumber: string;
  companyName: string;
  orderNumber: string;
  cheeseboardDesign: number;
  cheeseboard: string;
  isUpdatePending: boolean;
}

export const NewCartItem = (): CartItem => {
  let newCartItem: any = {};
  newCartItem['productId'] = '';
  newCartItem['productType'] = 'Slab';
  newCartItem['productActiveLocations'] = [];
  newCartItem['productCategory'] = '';
  newCartItem['description'] = '';
  newCartItem['itemNumber'] = '';
  newCartItem['cartItemId'] = 0;
  newCartItem['unitPrice'] = 0;
  newCartItem['unitSize'] = 0;
  newCartItem['pricingUom'] = '';
  newCartItem['itemQuantityPrice'] = 0;
  newCartItem['extendedNetPrice'] = 0;
  newCartItem['shipFromWarehouseId'] = '';
  newCartItem['primaryUomCode'] = '';
  newCartItem['unitWeight'] = 0;
  newCartItem['weightUomCode'] = '';
  newCartItem['taxProductCode'] = '';
  newCartItem['tempQuantity'] = 0;
  newCartItem['shippingClassifications'] = [];
  newCartItem['organizations'] = [];
  newCartItem['adjustedExtendedNetPrice'] = 0;
  newCartItem['adjustedUnitPrice'] = 0;
  newCartItem['hasAdjustedExtendedNetPrice'] = false;
  newCartItem['hasAdjustedUnitPrice'] = false;
  newCartItem['discountAmount'] = 0;
  newCartItem['discountCategory'] = '';
  newCartItem['createdAt'] = null;
  newCartItem['updatedAt'] = null;
  newCartItem['cartItemType'] = 'Unknown';
  newCartItem['currency'] = '';
  newCartItem['operatingUnitCode'] = '';
  newCartItem['priceListCode'] = '';
  newCartItem['programCode'] = '';
  newCartItem['programName'] = '';
  newCartItem['isAvailable'] = false;
  newCartItem['uomLineQuantity'] = 0;
  newCartItem['uomLineQuantityDisplayString'] = '';
  newCartItem['designCode'] = '';
  newCartItem['pieceLabel'] = '';
  newCartItem['productApplication'] = '';
  newCartItem['productGroupCode'] = '';
  newCartItem['fabOrderingViewType'] = '';
  newCartItem['dimension1'] = 0;
  newCartItem['dimension2'] = 0;
  newCartItem['dimension3'] = 0;
  newCartItem['dimension4'] = 0;
  newCartItem['parentCartItemId'] = 0;
  newCartItem['designName'] = '';
  newCartItem['designCollection'] = '';
  newCartItem['thickness'] = '';
  newCartItem['finish'] = '';
  newCartItem['match'] = '';
  newCartItem['edgeProfileName'] = '';
  newCartItem['edgeProfileCode'] = '';
  newCartItem['serviceOfferings'] = false;
  newCartItem['adjusteditemQuantityPrice'] = 0;
  newCartItem['hasAdjusteditemQuantityPrice'] = false;
  newCartItem['siteUseId'] = 0;
  newCartItem['requestedDeliveryDate'] = null;
  newCartItem['seamed'] = false;
  newCartItem['cartId'] = '';
  newCartItem['quantity'] = 0;
  newCartItem['imageUrl'] = '';
  newCartItem['_quantity'] = 0;
  newCartItem['_imageUrl'] = '';

  return newCartItem;
};

export interface GetCartRequest {
  pager: any;
  selectedBy: string;
  customerClass: string;
  userId: string;
  erpCustomerId: string;
}

export const NewGetCartRequest: GetCartRequest = {
  pager: undefined,
  selectedBy: '',
  customerClass: '',
  userId: '',
  erpCustomerId: '',
};

export interface GetCartsResults {
  results: Array<Cart>;
  totalResults: number;
}

export const NewGetCartResults: GetCartsResults = {
  results: [],
  totalResults: 0,
};

export const NewCart = (): Cart => {
  return {
    id: '',
    userId: '',
    erpCustomerId: '',
    customerClass: '',
    isActive: false,
    isLocked: false,
    wasTaxCalculated: false,
    wasActualPaymentCalculated: false,
    isReinitializing: false,
    selectedBy: [],
    orderNumber: '',
    transactionType: '',
    createdAt: null,
    updatedAt: null,
    subtotalAmount: 0,
    totalNetAmount: 0,
    totalAmount: 0,
    totalProductDiscountAndGiftCodeAmount: 0,
    totalHeaderAdjustments: 0,
    standardDiscountAmount: 0,
    giftCodeAmount: 0,
    discountCodeAmount: 0,
    totalTaxAmount: 0,
    totalShippingAmount: 0,
    totalShippingDiscountAmount: 0,
    estimatedPaymentFee: 0,
    actualPaymentFeeAmount: 0,
    cartItems: [],
    voucherCodes: [],
    shippingMethods: [],
    paymentMethods: [],
    taxCalculations: [],
    orderDetails: [],
    customerClassGroups: [],
    purchasingCustomers: [],
    headerAdjustments: [],
    requestedDeliverySchedules: [],
    totalHeaderAdjustmentAmount: 0,
    quoteDiscounts: [],
    quoteId: 0,
    totalQuoteDiscountAmount: 0,
    crmQuoteNumber: '',
    totalPricingItemsDiscounts: 0,
  };
};

export const getNewCart = (): Cart => {
  return {
    id: '',
    userId: '',
    erpCustomerId: '',
    customerClass: '',
    isActive: false,
    isLocked: false,
    wasTaxCalculated: false,
    wasActualPaymentCalculated: false,
    isReinitializing: false,
    selectedBy: [],
    orderNumber: '',
    transactionType: '',
    createdAt: null,
    updatedAt: null,
    subtotalAmount: 0,
    totalNetAmount: 0,
    totalAmount: 0,
    totalProductDiscountAndGiftCodeAmount: 0,
    totalHeaderAdjustments: 0,
    standardDiscountAmount: 0,
    giftCodeAmount: 0,
    discountCodeAmount: 0,
    totalTaxAmount: 0,
    totalShippingAmount: 0,
    totalShippingDiscountAmount: 0,
    estimatedPaymentFee: 0,
    actualPaymentFeeAmount: 0,
    cartItems: [],
    voucherCodes: [],
    shippingMethods: [],
    paymentMethods: [],
    taxCalculations: [],
    orderDetails: [],
    customerClassGroups: [],
    purchasingCustomers: [],
    headerAdjustments: [],
    totalHeaderAdjustmentAmount: 0,
    quoteDiscounts: [],
    quoteId: 0,
    totalQuoteDiscountAmount: 0,
    crmQuoteNumber: '',
    requestedDeliverySchedules: [],
    totalPricingItemsDiscounts: 0,
  };
};
