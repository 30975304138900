import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';
import COMMERCE_CORE_CONSTANTS from '../../../../../Core/constants';
import {baseApiCallWithReauth} from '../../../../../Framework/api-utils/api-utils';
import {isEmail, ReactMultiEmail} from 'react-multi-email';
import 'react-multi-email/style.css';
import {toast} from 'react-toastify';
import {IFabricationEmailResponse} from '../../../../../features/fabrication/IFabricationState';

const FabricationPreferences = ({erpCustomerId}: {erpCustomerId: number}) => {
  let fabEndpoint = `${COMMERCE_CORE_CONSTANTS.API_SERVICES.CUSTOMER.fabricationOrderPreferences}`;
  let [dataAlreadyExists, setDataAlreadyExists] = useState<boolean>(false);
  let [orderApproved, setOrderApproved] = useState<string[]>([]);
  let [cadApproved, setCadApproved] = useState<string[]>([]);
  let [cadRevisionNeeded, setCadRevisionNeeded] = useState<string[]>([]);
  let [poRevisionNeeded, setPoRevisionNeeded] = useState<string[]>([]);
  let [drafters, setDrafters] = useState<string[]>([]);
  let [orderApprovedCopy, setOrderApprovedCopy] = useState<string>('');
  let [cadApprovedCopy, setCadApprovedCopy] = useState<string>('');
  let [cadRevisionNeededCopy, setCadRevisionNeededCopy] = useState<string>('');
  let [poRevisionNeededCopy, setPoRevisionNeededCopy] = useState<string>('');
  let [draftersCopy, setDraftersCopy] = useState<string>('');

  const checkIfDataExists = (results: IFabricationEmailResponse) => {
    return !!results.id;
  };

  const setCopyDataOnInitialLoad = (apiResults: IFabricationEmailResponse) => {
    setOrderApprovedCopy(
      JSON.stringify(apiResults.orderApprovedEmailList ? apiResults.orderApprovedEmailList.split(', ') : [])
    );
    setCadApprovedCopy(
      JSON.stringify(apiResults.cadApprovedEmailList ? apiResults.cadApprovedEmailList.split(', ') : [])
    );
    setDraftersCopy(JSON.stringify(apiResults.drafters ? apiResults.drafters.split(', ') : []));
    setCadRevisionNeededCopy(
      JSON.stringify(apiResults.cadRevisionsNeededEmailList ? apiResults.cadRevisionsNeededEmailList.split(', ') : [])
    );
    setPoRevisionNeededCopy(
      JSON.stringify(apiResults.poRevisionsNeededEmailList ? apiResults.poRevisionsNeededEmailList.split(', ') : [])
    );
  };

  const setEmailData = (apiResults: IFabricationEmailResponse) => {
    setOrderApproved(apiResults.orderApprovedEmailList ? apiResults.orderApprovedEmailList.split(', ') : []);
    setCadApproved(apiResults.cadApprovedEmailList ? apiResults.cadApprovedEmailList.split(', ') : []);
    setDrafters(apiResults.drafters ? apiResults.drafters.split(', ') : []);
    setCadRevisionNeeded(
      apiResults.cadRevisionsNeededEmailList ? apiResults.cadRevisionsNeededEmailList.split(', ') : []
    );
    setPoRevisionNeeded(apiResults.poRevisionsNeededEmailList ? apiResults.poRevisionsNeededEmailList.split(', ') : []);
  };

  const putEmails = async () => {
    let data = {
      orderApprovedEmailList: orderApproved.join(', '),
      cadApprovedEmailList: cadApproved.join(', '),
      poRevisionsNeededEmailList: poRevisionNeeded.join(', '),
      drafters: drafters.join(', '),
      cadRevisionsNeededEmailList: cadRevisionNeeded.join(', '),
    };
    let putEndpoint = fabEndpoint + `/${erpCustomerId}`;
    let results = await baseApiCallWithReauth('PUT', putEndpoint, JSON.stringify(data), true);
    return results;
  };

  const postEmails = async () => {
    let data = {
      erpCustomerId: erpCustomerId,
      orderApprovedEmailList: orderApproved.join(', '),
      cadApprovedEmailList: cadApproved.join(', '),
      poRevisionsNeededEmailList: poRevisionNeeded.join(', '),
      drafters: drafters.join(', '),
      cadRevisionsNeededEmailList: cadRevisionNeeded.join(', '),
    };
    let results = await baseApiCallWithReauth('POST', fabEndpoint, JSON.stringify(data), true);
    return results;
  };

  const makeCopyOfSavedData = () => {
    setOrderApprovedCopy(JSON.stringify(orderApproved));
    setCadApprovedCopy(JSON.stringify(cadApproved));
    setPoRevisionNeededCopy(JSON.stringify(poRevisionNeeded));
    setDraftersCopy(JSON.stringify(drafters));
    setCadRevisionNeededCopy(JSON.stringify(cadRevisionNeeded));
  };

  const saveEmails = async () => {
    if (
      JSON.stringify(orderApproved) === orderApprovedCopy &&
      JSON.stringify(cadApproved) === cadApprovedCopy &&
      JSON.stringify(poRevisionNeeded) === poRevisionNeededCopy &&
      JSON.stringify(drafters) === draftersCopy &&
      JSON.stringify(cadRevisionNeeded) === cadRevisionNeededCopy
    ) {
      toast.error(
        'No new email was entered, hit the enter button after typing in the email address to ensure it is added to the list.'
      );
      return;
    }
    let results;
    if (dataAlreadyExists) {
      results = await putEmails();
    } else {
      results = await postEmails();
    }
    if (results.status === 200) {
      toast.success('Email Lists Updated Successfully');
      makeCopyOfSavedData();
    }
  };

  useEffect(() => {
    const getFabEmailsOnLoad = async () => {
      if (erpCustomerId !== -1) {
        let getEndpoint = fabEndpoint + `/${erpCustomerId}`;
        let apiResults: IFabricationEmailResponse = await baseApiCallWithReauth('GET', getEndpoint);
        setDataAlreadyExists(checkIfDataExists(apiResults));
        setEmailData(apiResults);
        setCopyDataOnInitialLoad(apiResults);
      }
    };
    getFabEmailsOnLoad();
  }, [erpCustomerId, fabEndpoint]);

  const EmailList = ({
    listName,
    emailList,
    setListFunction,
  }: {
    listName: string;
    emailList: string[];
    setListFunction: Dispatch<SetStateAction<string[]>>;
  }) => {
    return (
      <div className="email-list-container">
        <h3 className="email-list-heading">{listName}</h3>
        <ReactMultiEmail
          data-testid={`${listName}-email-input`}
          emails={emailList}
          onChange={(_emailList: string[]) => setListFunction(_emailList)}
          validateEmail={(email) => {
            return isEmail(email);
          }}
          getLabel={(email: string, index: number, removeEmail: (index: number) => void) => {
            return (
              <div className="active-email" key={email}>
                <span className="email-text">{email}</span>
                <i className="fa fa-times" onClick={() => removeEmail(index)} />
              </div>
            );
          }}
        />
      </div>
    );
  };

  return (
    <div className="order-preference-body">
      <div className="order-preference-page-header">
        <h1 className="preference-page-title">FABRICATION ORDER PREFERENCES</h1>
        <p className="preference-page-subheading">EMAIL LIST</p>
      </div>
      <div className="email-list-body">
        <EmailList listName={'Order Approved'} emailList={orderApproved} setListFunction={setOrderApproved} />
        <EmailList listName={'CAD Approved'} emailList={cadApproved} setListFunction={setCadApproved} />
        <EmailList
          listName={'CAD Revisions Needed'}
          emailList={cadRevisionNeeded}
          setListFunction={setCadRevisionNeeded}
        />
        <EmailList
          listName={'PO Revisions Needed'}
          emailList={poRevisionNeeded}
          setListFunction={setPoRevisionNeeded}
        />
        <div className="email-list-container">
          <h3 className="email-list-heading">Drafters</h3>
          <ReactMultiEmail
            emails={drafters}
            onChange={(_emailList: string[]) => setDrafters(_emailList)}
            validateEmail={(name: any) => {
              if (name !== 'undefined') {
                return true;
              }
              return false;
            }}
            getLabel={(email: string, index: number, removeEmail: (index: number) => void) => {
              return (
                <div className="active-email" key={email}>
                  <span className="email-text">{email}</span>
                  <i className="fa fa-times" onClick={() => removeEmail(index)} />
                </div>
              );
            }}
          />
        </div>
        <div className="email-list-container">
          <button data-testid="save-email-button" className="btn btn-primary" onClick={saveEmails}>
            UPDATE EMAIL LIST
          </button>
        </div>
      </div>
    </div>
  );
};

export default FabricationPreferences;
