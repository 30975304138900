import React, {useCallback, useEffect, useState} from 'react';
import ProductListFilters from '../../../Core/Components/ProductList/Filters';
import ProductListProducts from '../../../Core/Components/ProductList/Products';
import {selectShipFromWarehouseId, setShipFromWarehouseId} from '../../../features/warehouse/slice/warehouse.slice';
import {
  selectOffset,
  selectSearchResultsCategoriesFilters,
  setProductsAsync,
  setUpdatedSearchResultsCategoriesFilters,
} from '../../../features/productCatalog/slice/productCatalog.slice';
import {useAppDispatch, useTypedSelector} from '../../../hooks/store';
import {selectCurrentCustomer, selectCurrentCustomerShipToSites} from '../../../features/customer/slice/customer.slice';
import {selectActiveProgram} from '../../../features/salesforce/slice/salesforce.slice';
import {selectActiveCart} from '../../../features/cart/slice/cart.slice';
import {useLocation} from 'react-router-dom';
import qs from 'qs';
import {ProductPage} from '../Product/Product.styled';
import {Container} from 'react-bootstrap';
import {CustomerFullProfile, Site} from '../../../features/customer/ICustomerState';

export interface ProductRequestResponse {
  data: URLSearchParams;
  customer: CustomerFullProfile;
  currentProgram: any;
  productType: any;
  warehouseCode: any;
  shipToSites: Site[] | null;
}

const SearchResults = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();

  let customer = useTypedSelector(selectCurrentCustomer);
  let shipToSites = useTypedSelector(selectCurrentCustomerShipToSites);
  let currentProgram = useTypedSelector(selectActiveProgram);
  let cart = useTypedSelector(selectActiveCart);
  let warehouseCode = useTypedSelector(selectShipFromWarehouseId);
  let productType = useTypedSelector(selectSearchResultsCategoriesFilters);
  let offset = useTypedSelector(selectOffset);

  let [oldProductType, setOldProductType]: any = useState('');
  let [oldSearchParams, setOldSearchParams]: any = useState('');
  let [searchParams, setSearchParams]: any = useState(qs.parse(location.search, {ignoreQueryPrefix: true}).q);

  const createData = useCallback(
    (currentOffset: number = offset, limit: number = 6) => {
      let data = new URLSearchParams();
      data.append('pager.offset', offset.toString());
      data.append('pager.limit', limit.toString());
      data.append('pager.expand', 'attachments');
      data.append('q', searchParams);
      return data;
    },
    [searchParams, offset]
  );

  const createProductRequestData = useCallback(
    (productType: any, currentOffset?: number, limit?: number): ProductRequestResponse => {
      return {
        data: createData(currentOffset, limit),
        customer,
        currentProgram,
        productType,
        warehouseCode,
        shipToSites: shipToSites,
      };
    },
    [customer, currentProgram, warehouseCode, shipToSites, createData]
  );

  useEffect(() => {
    setSearchParams(qs.parse(location.search, {ignoreQueryPrefix: true}).q);
    dispatch(
      setUpdatedSearchResultsCategoriesFilters(qs.parse(location.search, {ignoreQueryPrefix: true}).productType)
    );
  }, [location.search, dispatch]);

  useEffect(() => {
    if (productType && customer && customer.id && shipToSites) {
      dispatch(setShipFromWarehouseId({productType, customer, shipToSites, cart}));
    }
  }, [dispatch, productType, customer, shipToSites, cart]);

  useEffect(() => {
    if (
      customer &&
      customer.id &&
      (productType !== oldProductType || searchParams !== oldSearchParams) &&
      currentProgram &&
      currentProgram.code &&
      ((customer.class && customer.class.toLowerCase() !== 'enterprise') || shipToSites) &&
      ((!warehouseCode && productType === 'All') || warehouseCode)
    ) {
      setOldSearchParams(searchParams);
      setOldProductType(productType);
      dispatch(setProductsAsync(createProductRequestData(productType)));
    }
  }, [
    dispatch,
    customer,
    currentProgram,
    productType,
    searchParams,
    warehouseCode,
    createProductRequestData,
    oldProductType,
    oldSearchParams,
    shipToSites,
  ]);

  return (
    <div className="react-bootstrap-hack cambria-main-content">
      <ProductPage>
        <Container className="product-list-container">
          <div className="row product-list-row">
            <ProductListFilters
              productType={productType}
              createProductRequestData={createProductRequestData}
              isSearchResults={true}
              customer={customer}
              cart={cart}
              shipToSites={shipToSites || []}></ProductListFilters>
            <ProductListProducts
              productType={productType}
              createProductRequestData={createProductRequestData}
              isSearchResults={true}></ProductListProducts>
          </div>
        </Container>
      </ProductPage>
    </div>
  );
};

export default SearchResults;
