import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import CambriaLogo from '../CambriaLogo';
import Icon from '../../../../Framework/Components/Icon';
import Dropdown from '../../../../Framework/Components/Dropdown';
import {StyledLogoutButton} from '../../../../Framework/Components/styles/StyledLogoutButton.component';
import {useLogout} from '../../../../hooks/useLogout';

const HeaderSearch = () => {
  const logout = useLogout();

  const handleLogout = () => {
    logout();
  };

  return (
    <Container>
      <Row>
        <Col xs={6} className="d-flex align-items-center justify-content-start">
          <Link to="/">
            <CambriaLogo />
          </Link>
        </Col>
        <Col xs={6} className="d-flex align-items-center justify-content-end">
          <Dropdown>
            <Dropdown.Toggle>
              <Icon color="#bcc0c6" size="34" weight="600" icon="icons-cambria-Hr-Single" />
            </Dropdown.Toggle>
            <Dropdown.Body orientation="right">
              <StyledLogoutButton onClick={handleLogout}>Logout</StyledLogoutButton>
            </Dropdown.Body>
          </Dropdown>
        </Col>
      </Row>
    </Container>
  );
};

export default HeaderSearch;
