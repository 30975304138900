import React, {FC, useCallback, useEffect, useState} from 'react';
import {Col, Container, Form, Row} from 'react-bootstrap';
import {Formik} from 'formik';
import * as Yup from 'yup';
import {toast} from 'react-toastify';
import CambriaButton from '../../../../../Framework/Components/CambriaButton';
import Icon from '../../../../../Framework/Components/Icon';
import {useAppDispatch, useTypedSelector} from '../../../../../hooks/store';
import {addCreditCard, deleteCreditCard, getCreditCards} from '../../../../../features/payment/slice/payment.slice';
import {selectCurrentCustomer} from '../../../../../features/customer/slice/customer.slice';
import {selectCurrentUser} from '../../../../../features/auth/slice/authentication.slice';
import {AddCreditCardFormContainer} from '../../../Checkout/PaymentMethod/AddCreditCardForm/AddCreditCardForm.styled';
import braintree from 'braintree-web';
import COMMERCE_CORE_CONSTANTS from '../../../../../Core/constants';
import {baseApiCallWithReauth} from '../../../../../Framework/api-utils/api-utils';
import {CreditCard} from '../../../../../features/payment/IPaymentState';
import {
  hideFullscreenLoader,
  showFullscreenLoader,
} from '../../../../../features/fullscreenLoader/slice/fullscreenLoader.slice';
import useWindowDimensions from '../../../../../hooks/getWindowDimensions';
import {callback} from '../../../../../features/callbacks/service/callbacks.service';

interface CreditCardProps {
  creditCardData?: CreditCard;
  isSubmitting?: Function;
  onSumbitCallback?: Function;
  onCancelCallback?: Function;
  isCreditCardEdit?: boolean;
}

const CreditCardForm: FC<CreditCardProps> = ({
  creditCardData,
  isSubmitting,
  onSumbitCallback,
  onCancelCallback,
  isCreditCardEdit,
}) => {
  const dispatch = useAppDispatch();
  const {width} = useWindowDimensions();
  let form: any = document.querySelector('#add-credit-card-form');
  let submit: any = document.querySelector('#add-credit-card-button');

  const [submitting, setSumbitting] = useState<boolean>(false);
  const [tokenHasBeenFetched, setTokenHasBeenFetched] = useState(false);
  const [formHasRendered, setFormHasRendered] = useState(false);
  const [showNameError, setShowNameError] = useState(false);
  const [showCardNumberError, setShowCardNumberError] = useState(false);
  const [showExpError, setShowExpError] = useState(false);
  const [showCvvError, setShowCvvError] = useState(false);
  const [showPostalCodeError, setShowPostalCodeError] = useState(false);
  const [braintreeAuthToken, setBraintreeAuthToken] = useState<string>('');

  const currentUser = useTypedSelector(selectCurrentUser);
  const currentCustomer = useTypedSelector(selectCurrentCustomer);

  const closeModalAndRefreshCreditCardList = useCallback(async () => {
    await dispatch(getCreditCards({erpCustomerId: currentCustomer?.erpCustomerId}));
    setSumbitting(false);
    if (isSubmitting) {
      isSubmitting(false);
    }
    if (onSumbitCallback) {
      onSumbitCallback();
    }
    toast.success('Credit card was successfully added.', {toastId: 'creditCardAddedSuccess'});
  }, [currentCustomer?.erpCustomerId, dispatch, isSubmitting, onSumbitCallback]);

  const addNewCard = useCallback(
    async (nonce: string) => {
      setSumbitting(true);

      if (isCreditCardEdit && creditCardData) {
        try {
          await dispatch(deleteCreditCard(creditCardData.token));
          toast.success('Credit card was successfully updated.', {toastId: 'creditCardAddedSuccess'});
        } catch (e: any) {
          toast.error('There was an error adding or completing verification of the credit card.', {
            toastId: 'creditCardAddedFail',
          });
          throw Error(e);
        }
      }

      try {
        const result = await dispatch(
          addCreditCard({
            userId: currentUser?.userId,
            erpCustomerId: currentCustomer?.erpCustomerId,
            nonce: nonce,
          })
        );

        await callback(result.payload.callBackId, 60, dispatch);
        await closeModalAndRefreshCreditCardList();
      } catch (e: any) {
        toast.error('There was an error adding or completing verification of the credit card.', {
          toastId: 'creditCardAddedFail',
        });
        setSumbitting(false);
        throw e;
      }
    },
    [creditCardData, currentCustomer, currentUser, dispatch, isCreditCardEdit, closeModalAndRefreshCreditCardList]
  );

  const getBraintreeAuthorization = useCallback(async () => {
    const endpoint = `${COMMERCE_CORE_CONSTANTS.API_SERVICES.PAYMENT.clientToken}`;
    const token = await baseApiCallWithReauth('POST', endpoint, null, true);
    const tokenToText = await token.text();
    setBraintreeAuthToken(tokenToText);
  }, []);

  useEffect(() => {
    if (!braintreeAuthToken && !tokenHasBeenFetched) {
      dispatch(showFullscreenLoader({}));
      getBraintreeAuthorization();
      setTokenHasBeenFetched(true);
    }
  }, [tokenHasBeenFetched, braintreeAuthToken, getBraintreeAuthorization, dispatch]);

  useEffect(() => {
    const handleShowErrors = (inputs: string[]) => {
      inputs.forEach((input: string) => {
        handleErrorMessaging(input, true);
      });
    };

    const handleErrorMessaging = (inputName: string, showErrorMessage: boolean) => {
      switch (inputName) {
        case 'cardholderName': {
          setShowNameError(showErrorMessage);
          break;
        }
        case 'cvv': {
          setShowCvvError(showErrorMessage);
          break;
        }
        case 'expirationDate': {
          setShowExpError(showErrorMessage);
          break;
        }
        case 'postalCode': {
          setShowPostalCodeError(showErrorMessage);
          break;
        }
        case 'number': {
          setShowCardNumberError(showErrorMessage);
          break;
        }
      }
    };

    if (braintreeAuthToken && !formHasRendered) {
      braintree.client.create(
        {
          authorization: braintreeAuthToken,
        },
        (clientErr, clientInstance) => {
          if (clientErr) {
            console.error(clientErr);
            return;
          }

          braintree.hostedFields.create(
            {
              client: clientInstance,
              styles: {
                input: {
                  'font-size': '14px',
                  height: '48px',
                },
                'input.invalid': {
                  color: 'red',
                },
                'input.valid': {
                  color: 'green',
                },
              },
              fields: {
                cardholderName: {
                  container: '#cc-name',
                  placeholder: 'Name on Card',
                  prefill: creditCardData?.cardholderName,
                },
                number: {
                  container: '#card-number',
                  placeholder: 'Card Number',
                },
                cvv: {
                  container: '#cvv',
                  placeholder: 'CVV',
                },
                expirationDate: {
                  container: '#expiration-date',
                  placeholder: 'Expiration Date',
                },
                postalCode: {
                  container: '#postal-code',
                  placeholder: 'Postal Code',
                },
              },
            },

            (hostedFieldsErr, hostedFieldsInstance: any) => {
              hostedFieldsInstance.on('notEmpty', (event: any) => {
                if (event) {
                  handleErrorMessaging(event.emittedBy, false);
                }
              });

              if (hostedFieldsErr) {
                console.error(hostedFieldsErr);
                return;
              }

              submit.removeAttribute('disabled');

              form.addEventListener(
                'submit',
                (event: any) => {
                  event.preventDefault();
                  var fields = hostedFieldsInstance.getState().fields;
                  var isValid = Object.keys(fields).every((field) => {
                    return fields[field].isValid;
                  });
                  var isEmpty = Object.keys(fields).filter((field) => {
                    return fields[field].isEmpty;
                  });

                  if (isValid) {
                    hostedFieldsInstance.tokenize(async (tokenizeErr: any, payload: any) => {
                      if (tokenizeErr) {
                        toast.error(tokenizeErr);
                        return;
                      }
                      await addNewCard(payload.nonce);
                    });
                  } else {
                    if (isEmpty && isEmpty.length > 0) {
                      handleShowErrors(isEmpty);
                      toast.error('Please finish filling out all fields in form.');
                      return;
                    }
                    toast.error('Invalid form values. Please adjust and submit again.');
                  }
                },
                false
              );
            }
          );
        }
      );
      setFormHasRendered(true);
      dispatch(hideFullscreenLoader());
    }
  }, [
    braintreeAuthToken,
    form,
    getBraintreeAuthorization,
    submit,
    addNewCard,
    formHasRendered,
    dispatch,
    creditCardData,
  ]);

  return (
    <>
      <script src="https://js.braintreegateway.com/web/3.90.0/js/client.min.js"></script>
      <script src="https://js.braintreegateway.com/web/3.90.0/js/hosted-fields.min.js"></script>
      <Formik enableReinitialize initialValues={{}} validationSchema={Yup.object({})} onSubmit={() => {}}>
        <Form id="add-credit-card-form" noValidate>
          <Container className="p-0">
            <Row>
              <AddCreditCardFormContainer>
                <Col>
                  <Row className="w-100 mb-3">
                    <Col xs={12} md={6} className="mb-2">
                      <label>Cardholder Name *</label>
                      <div id="cc-name"></div>
                      {showNameError && <span className="error-message">This field is required.</span>}
                    </Col>
                    <Col xs={12} md={6} className="mb-2">
                      <label>Card Number *</label>
                      <div id="card-number"></div>
                      {showCardNumberError && <span className="error-message">This field is required.</span>}
                    </Col>
                  </Row>
                  <Row className="w-100 mb-3">
                    <Col xs={12} md={6} className="mb-2">
                      <label>Expiration Date *</label>
                      <div id="expiration-date" />
                      {showExpError && <span className="error-message">This field is required.</span>}
                    </Col>
                    <Col xs={12} md={6} className="mb-2">
                      <div className="d-flex justify-content-start">
                        <label>CVV *</label>
                        <em className="input-tooltip-wrapper tooltip-wrapper fa fa-question-circle">
                          <span className="input-tooltip tooltip">
                            For Amex, the <strong>4 digits above the credit card number on the front</strong> of your
                            card. Otherwise,
                            <strong>the last 3 digits on the back</strong> of your card.
                          </span>
                        </em>
                      </div>
                      <div id="cvv"></div>
                      {showCvvError && <span className="error-message">This field is required.</span>}
                    </Col>
                  </Row>
                  <Row className="w-100 mb-3">
                    <Col xs={12} md={6} className="mb-2">
                      <label>Postal Code *</label>
                      <div id="postal-code"></div>
                      {showPostalCodeError && <span className="error-message">This field is required.</span>}
                    </Col>
                  </Row>
                </Col>
              </AddCreditCardFormContainer>
            </Row>
            <Row className="d-flex justify-content-end">
              <Col
                xs={12}
                md={12}
                sm={12}
                lg={6}
                xl={6}
                className={`d-flex justify-content-end ${width < 770 ? 'flex-column' : ''}`}>
                <CambriaButton
                  onClick={() => {
                    if (onCancelCallback) {
                      onCancelCallback();
                    }
                  }}
                  variant="transparent"
                  disabled={submitting}
                  className={`mr-3 ${width < 770 ? 'mb-3' : ''}`}>
                  <Icon
                    icon="icons-cambria-Ui-Multiply"
                    color="#909090"
                    colorOnHover='color="#909090"'
                    size="15"
                    weight="bold"
                  />
                  Cancel
                </CambriaButton>
                <CambriaButton
                  id="add-credit-card-button"
                  onClick={() => {}}
                  form="add-credit-card-form"
                  className="add-cc-button"
                  type="submit"
                  disabled={submitting}>
                  {submitting ? (
                    <Icon
                      className={'resubmit-reorder-spinner'}
                      size="15"
                      weight="600"
                      icon="fa fa-spinner fa-spin"
                      color="#fffff"
                    />
                  ) : (
                    <Icon icon="icons-cambria-Symbols-Plus" color="#fffff" size="15" weight="600" />
                  )}
                  {creditCardData ? 'Save' : 'Add Credit Card'}
                </CambriaButton>
              </Col>
            </Row>
          </Container>
        </Form>
      </Formik>
    </>
  );
};

export default CreditCardForm;
