import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import AnimateHeight, {Height} from 'react-animate-height';
import AddCreditCard from './AddCreditCard';
import CreditCardSelect from './CreditCardSelect';
import {AddNewCardToggleBtn, PaymentPreferencesLabel} from './PaymentPreferences.styled';
import PaymentTermsInfo from './PaymentTermsInfo';
import {useAppDispatch, useTypedSelector} from '../../../../hooks/store';
import {
  getCreditCards,
  getPaymentSelections,
  selectCreditCards,
  selectPaymentSelections,
} from '../../../../features/payment/slice/payment.slice';
import {CustomerFullProfile} from '../../../../features/customer/ICustomerState';
import {
  selectCurrentCustomer,
  selectCurrentCustomerBillToSite,
} from '../../../../features/customer/slice/customer.slice';
import {getAllAvailableCountries} from '../../../../features/location/slice/location.slice';
import {selectUserActions} from '../../../../features/permission/slice/permission.slice';
import {hasPermission} from '../../../../store/permission/permission.service';
import {
  hideFullscreenLoader,
  showFullscreenLoader,
} from '../../../../features/fullscreenLoader/slice/fullscreenLoader.slice';

const PaymentPreferences = () => {
  const dispatch = useAppDispatch();
  const creditCardsSection = useRef<null | HTMLDivElement>(null);

  const [isAddCardOpen, setIsAddCardOpen] = useState<any>(0);

  const currentCustomer: CustomerFullProfile | null = useTypedSelector(selectCurrentCustomer);
  const billToSites = useTypedSelector(selectCurrentCustomerBillToSite);
  const paymentSelections = useTypedSelector(selectPaymentSelections);
  const creditCards = useTypedSelector(selectCreditCards);
  const userActions = useTypedSelector(selectUserActions);

  const onAddCreditCardEnd = (height: Height) => {
    if (height === 0) {
      creditCardsSection?.current?.scrollIntoView({behavior: 'smooth', block: 'start'});
    }
  };

  const loadPaymentPreferencesItems = useCallback(
    async (paymentTermsId: number, erpCustomerId: number, customerClass: string) => {
      dispatch(
        getPaymentSelections({
          erpPaymentTermsId: paymentTermsId,
          customerClass: customerClass,
        })
      );
      await dispatch(getCreditCards({erpCustomerId: erpCustomerId}));
      dispatch(hideFullscreenLoader());
    },
    [dispatch]
  );

  useEffect(() => {
    dispatch(showFullscreenLoader({}));
  }, [dispatch]);

  useEffect(() => {
    dispatch(getAllAvailableCountries());
  }, [dispatch]);

  useEffect(() => {
    if (currentCustomer?.class && billToSites?.erpPaymentTermsId && currentCustomer?.erpCustomerId) {
      loadPaymentPreferencesItems(billToSites.erpPaymentTermsId, currentCustomer.erpCustomerId, currentCustomer.class);
    }
  }, [billToSites, currentCustomer, loadPaymentPreferencesItems]);

  useEffect(() => {
    if (creditCards && creditCards.length === 0) {
      setIsAddCardOpen('auto');
    }
  }, [creditCards]);

  return (
    <Container className="p-r-0 p-l-0 m-t-md m-b-md">
      <Row>
        {paymentSelections && (
          <Col xl={12} className="m-b-md">
            <Row>
              <Col xl={6}>
                <PaymentPreferencesLabel>Payment Terms:</PaymentPreferencesLabel>
              </Col>
            </Row>
            <PaymentTermsInfo paymentSelections={paymentSelections}></PaymentTermsInfo>
          </Col>
        )}
        <Col xl={12} ref={creditCardsSection}>
          <Row>
            <Col>
              <PaymentPreferencesLabel>Credit & Debit Cards:</PaymentPreferencesLabel>
            </Col>
            <Col>
              <PaymentPreferencesLabel>Expiration:</PaymentPreferencesLabel>
            </Col>
          </Row>
          <CreditCardSelect creditCards={creditCards || []}></CreditCardSelect>
        </Col>
        {hasPermission('urn:csa:commerceui:addCreditDebitCardAccountInformation', userActions) && (
          <Col xl={12}>
            <AddNewCardToggleBtn onClick={() => setIsAddCardOpen(isAddCardOpen === 0 ? 'auto' : 0)}>
              + Add Credit/Debit Card
            </AddNewCardToggleBtn>
          </Col>
        )}
        {hasPermission('urn:csa:commerceui:addCreditDebitCardAccountInformation', userActions) && (
          <Col xl={12}>
            <AnimateHeight
              duration={250}
              height={isAddCardOpen}
              onHeightAnimationEnd={(height: Height) => onAddCreditCardEnd(height)}>
              <Row>
                <Col xl={6}>
                  <PaymentPreferencesLabel>Add Credit & Debit Cards:</PaymentPreferencesLabel>
                </Col>
              </Row>
              {isAddCardOpen !== 0 && <AddCreditCard closeAddCardSection={() => setIsAddCardOpen(0)} />}
            </AnimateHeight>
          </Col>
        )}
      </Row>
    </Container>
  );
};

export default PaymentPreferences;
