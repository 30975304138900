import React, {useCallback, useEffect, useState} from 'react';
import {Formik, FormikProps} from 'formik';
import * as Yup from 'yup';
import {Col, Container, Form, Row, Spinner} from 'react-bootstrap';
import {useAppDispatch, useTypedSelector} from '../../../../hooks/store';
import {
  invalidateStep2,
  selectActiveStep,
  selectPaymentMethodpageSkipped,
  setIsSubmitting,
  setSavePaymentMethodToCart,
  setStep2Completed,
  submitShippingAndDelivery,
} from '../../../../features/checkout/slice/checkout.slice';
import {selectUserActions} from '../../../../features/permission/slice/permission.slice';
import {
  getAccountDefaultShipToAddress,
  getCustomerShipToSitesFilteredByWarehouse,
  getExistingPreferredShippingContact,
  updatePreferredShippingContacts,
} from '../../../../features/customer/service/customer.service';
import {
  selectActiveProgram,
  selectAvailablePrograms,
  selectCurrentCustomerSfAccount,
  selectIsUpdatedSalesforceAssetAccount,
  selectSalesforceCustomerAccount,
  setIsUpdatedSalesforceAssetAccount,
} from '../../../../features/salesforce/slice/salesforce.slice';
import {hasPermission} from '../../../../store/permission/permission.service';
import {
  formatShippingMethodsForUPS,
  getContactOptions,
  getShippingCost,
  getShippingSelections,
  onSelectDeliveryDate,
  setAvailableDatesForCambriaArrange,
  setAvailableDatesForCambriaDelivery,
  showToastrMessageIfZeroShippingRate,
} from '../../../../features/checkout/service/checkout.service';
import {selectActiveCart} from '../../../../features/cart/slice/cart.slice';
import CambriaDateInput from '../../../../Framework/Components/CambriaDateInput';
import AdvancedDeliverySchedule from './AdvancedDeliverySchedule';
import {
  selectCurrentCustomer,
  selectCurrentCustomerBillToSites,
  selectCurrentCustomerShipToSites,
  selectPreferredShippingContact,
  setCurrentCustomerShipToSites,
} from '../../../../features/customer/slice/customer.slice';
import {getShippingWarehouse} from '../../../../features/warehouse/service/warehouse.service';
import {WarehouseFullData} from '../../../../features/warehouse/IWarehouseState';
import {ShippingAndDeliveryContainer} from './ShippingAndDelivery.styled';
import CambriaButton from '../../../../Framework/Components/CambriaButton';
import Icon from '../../../../Framework/Components/Icon';
import AddShippingAddressModal from '../../../../Core/Components/Modals/AddShippingAddressModal';
import {getAllAvailableCountries} from '../../../../features/location/slice/location.slice';
import AddShippingContactModal from '../../../../Core/Components/Modals/AddShippingContactModal';
import CambriaInput from '../../../../Framework/Components/CambriaInput';
import ConfirmationModal from '../../../../Core/Components/Modals/ConfirmationModal';
import {formatPrice} from '../../../../features/pricing/service/pricing.service';
import CambriaSelect from '../../../../Framework/Components/CambriaSelect';
import {toast} from 'react-toastify';
import {getDeliverySchedule} from '../../../../features/product/service/product.service';
import {selectPaymentSelections} from '../../../../features/payment/slice/payment.slice';
import {selectCurrentUser} from '../../../../features/auth/slice/authentication.slice';
import {getSalesforceContacts} from '../../../../features/salesforce/service/salesforce.service';
import {showFullscreenLoader} from '../../../../features/fullscreenLoader/slice/fullscreenLoader.slice';
import {initializeCart} from '../../../../features/cart/service/cart.service';
import {Cart} from '../../../../features/cart/ICartState';
import {isFabricatedCart} from '../../../../features/productType/service/productType.service';
import CambriaModal from '../../../../Framework/Components/CambriaModal';
import {useHistory} from 'react-router-dom';
import {
  PreferredShippingContact,
  PreferredShippingContactRequest,
  Site,
} from '../../../../features/customer/ICustomerState';
import COMMERCE_CORE_CONSTANTS from '../../../../Core/constants';
import {selectSelectedWarehouse} from '../../../../features/warehouse/slice/warehouse.slice';
import {getOrderNotificationContactsRequest} from '../../../../features/order/controller/order.controller';
import {deleteDeliverySchedulesRequest} from '../../../../features/product/controller/product.controller';
import {useSalesforceAccountInit} from './hooks/useSalesforceAccountInit';
import {pushAddShippingInfoToDataLayer} from '../../../../features/analytics/service/analytics.service';
import {selectUiSettings} from '../../../../features/environment/slice/environment.slice';

interface ShippingAndDeliveryFormValues {
  shippingAddress: string;
  contact: string;
  deliveryMethod: any;
  shippingMethod: string;
  requestedDeliveryDate: string;
  customerShippingAccountId: string;
  sendInFewerBoxes: boolean;
  shippingInstructions: string;
  mobileNotification: boolean;
  phoneNumber: string;
  emailNotification: boolean;
  email: string;
}

const ShippingAndDelivery = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const userActions = useTypedSelector(selectUserActions);
  const activeStep = useTypedSelector(selectActiveStep);
  const activeCart = useTypedSelector(selectActiveCart);
  const currentCustomerSfAccount = useTypedSelector(selectCurrentCustomerSfAccount);
  const currentCustomer = useTypedSelector(selectCurrentCustomer);
  const currentCustomerShipToSites = useTypedSelector(selectCurrentCustomerShipToSites);
  const currentCustomerBillToSites = useTypedSelector(selectCurrentCustomerBillToSites);
  const activeProgram = useTypedSelector(selectActiveProgram);
  const availablePrograms = useTypedSelector(selectAvailablePrograms);
  const currentUser = useTypedSelector(selectCurrentUser);
  const paymentSelections = useTypedSelector(selectPaymentSelections);
  const paymentMethodPageHasBeenSkipped = useTypedSelector(selectPaymentMethodpageSkipped);
  const salesforceCustomerAccount = useTypedSelector(selectSalesforceCustomerAccount);
  const isUpdatedSalesforceAssetAccount = useTypedSelector(selectIsUpdatedSalesforceAssetAccount);
  const selectedWarehouse = useTypedSelector(selectSelectedWarehouse);
  const preferredShippingContact = useTypedSelector(selectPreferredShippingContact);
  const uiSettings = useTypedSelector(selectUiSettings);

  const [contactOptions, setContactOptions] = useState<Array<any>>([]);
  const [shippingZonePricing, setShippingZonePricing] = useState<any>(null);
  const [scheduledItems, setScheduledItems] = useState<Array<any> | null>(null);
  const [isRequestedDateFieldRequired, setIsRequestedDateFieldRequired] = useState<boolean>(false);
  const [allDaysDisabled, setAllDaysDisabled] = useState<boolean>(false);
  const [currentDeliveryMethod, setCurrentDeliveryMethod] = useState<any>(null);
  const [currentWarehouse, setCurrentWarehouse] = useState<WarehouseFullData | null>(null);
  const [showAddNewShippingAddressModal, setShowAddNewShippingAddressModal] = useState(false);
  const [showAddNewShippingContactModal, setShowAddNewShippingContactModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [confirmModalOptions, setConfirmModalOptions] = useState<any>({});
  const [lodaingAdvancedSchedule, setLodaingAdvancedSchedule] = useState<boolean>(false);
  const [smsNotificationsOptIn, setSmsNotificationsOptIn] = useState<boolean>(false);
  const [emailNotificationsOptIn, setEmailNotificationsOptIn] = useState<boolean>(false);
  const [emailListNotification, setEmailListNotification] = useState<Array<any>>([]);
  const [alreadyInitialized, setAlreadyInitialized] = useState<boolean>(false);
  const [disableFields, setDisableFields] = useState<boolean>(true);
  const [requestedDeliveryDateFieldLabel, setRequestedDeliveryDateFieldLabel] = useState<string | null>(null);
  const [orderNotificationContacts, setOrderNotificationContacts] = useState<Array<any> | null>(null);
  const [minDate, setMinDate] = useState<any>(null);
  const [disabledDates, setDisabledDates] = useState<any>(null);
  const [disabledDays, setDisabledDays] = useState<any>(null);
  const [showRequestedDateField, setShowRequestedDateField] = useState<boolean>(false);
  const [showShippingMethodField, setShowShippingMethodField] = useState<boolean>(false);
  const [deliveryMethods, setDeliveryMethods] = useState<Array<any>>([]);
  const [shippingMethods, setShippingMethods] = useState<Array<any>>([]);
  const [shippingMethodLabel, setShippingMethodLabel] = useState<any>('Shipping Method');
  const [isPageSubmitted, setIsPageSubmitted] = useState<boolean>(false);
  const [availableContacts, setAvailableContacts] = useState<any[]>([]);
  const [newShippingAddress, setNewShippingAddress] = useState<any>(null);
  const [showJobTypeModal, setShowJobTypeModal] = useState(false);
  const [customerShipToSiteOptions, setCustomerShipToSiteOptions] = useState<Site[]>([]);
  const [shippingAddress, setShippingAddress] = useState('');
  const [contact, setContact] = useState('');
  const [deliveryMethod, setDeliveryMethod]: any = useState('');
  const [shippingMethod, setShippingMethod] = useState('');
  const [requestedDeliveryDate, setRequestedDeliveryDate] = useState('');
  const [customerShippingAccountId, setCustomerShippingAccountId] = useState('');
  const [sendInFewerBoxes, setSendInFewerBoxes] = useState(false);
  const [shippingInstructions, setShippingInstructions] = useState('');
  const [mobileNotification, setMobileNotification] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [emailNotification, setEmailNotification] = useState(false);
  const [email, setEmail] = useState<string>('');
  const [existingPreferredShippingContact, setExistingPreferredShippingContact] = useState<any>('');

  const validationSchema = Yup.object({
    shippingAddress: Yup.mixed().required('This field is required'),
    contact:
      activeCart?.cartItems[0].productType === 'Slab' && uiSettings?.enableDetrack
        ? Yup.mixed().required('This field is required')
        : Yup.mixed(),
    deliveryMethod: Yup.mixed().required('This field is required'),
    shippingMethod: showShippingMethodField ? Yup.mixed().required('This field is required') : Yup.mixed(),
    requestedDeliveryDate:
      showRequestedDateField && isRequestedDateFieldRequired
        ? Yup.mixed().required('This field is required')
        : Yup.mixed(),
    customerShippingAccountId:
      currentDeliveryMethod && currentDeliveryMethod.name.toLowerCase().includes('fedex')
        ? Yup.string().required('This field is required')
        : Yup.string(),
    sendInFewerBoxes: Yup.mixed(),
    shippingInstructions: Yup.string(),
    mobileNotification: Yup.mixed(),
    phoneNumber: smsNotificationsOptIn
      ? Yup.string().required('This field is required').length(10, 'Invalid phone number format')
      : Yup.string().length(10, 'Invalid phone number format'),
    emailNotification: Yup.mixed(),
    email:
      emailNotificationsOptIn && emailListNotification.length === 0
        ? Yup.string().required('This field is required').email('Invalid email')
        : Yup.string().email('Invalid email'),
  });

  const salesforceAccountInit = useSalesforceAccountInit({currentWarehouse});

  const isFabOrderAndNoJobType = useCallback((cart: Cart) => {
    if (isFabricatedCart(cart)) {
      const orderDetails = cart.orderDetails[0];
      if (!orderDetails.jobType || !orderDetails.projectType) {
        setShowJobTypeModal(true);
      } else {
        setShowJobTypeModal(false);
      }
    }
  }, []);

  const resetShippingOnShippingAddressUpdate = useCallback(
    async (value: any) => {
      setShowShippingMethodField(false);
      setShowRequestedDateField(false);
      setCurrentDeliveryMethod(null);
      setScheduledItems(null);
      setContactOptions(
        getContactOptions(
          currentCustomerShipToSites as any,
          activeCart as any,
          currentCustomer,
          value,
          undefined,
          activeCart?.cartItems[0].productType === 'Slab' && uiSettings?.enableDetrack
        )
      );

      const canUseUpsShippingIntegration = hasPermission('urn:csa:commerceui:useUpsShippingIntegration', userActions);
      const useUpsEndpoint = currentCustomer.class === 'MARKETREP' && canUseUpsShippingIntegration;

      setDeliveryMethods(
        await getShippingSelections(
          value,
          activeCart as any,
          activeProgram,
          currentCustomer,
          currentCustomerShipToSites as any,
          useUpsEndpoint
        )
      );
    },
    [activeCart, activeProgram, currentCustomer, currentCustomerShipToSites, userActions, uiSettings?.enableDetrack]
  );

  const onSetRequestedDeliveryDate = async (selectedDate: Date) => {
    if (selectedDate) {
      setScheduledItems(null);
      setLodaingAdvancedSchedule(true);
      const result = await onSelectDeliveryDate(selectedDate, activeCart as any, dispatch);
      setScheduledItems(result);
      setLodaingAdvancedSchedule(true);
    }
  };

  const getShippingZonePrices = useCallback(
    async (shippingAddressArg: any, value: any) => {
      if (
        (shippingAddressArg &&
          value.name === 'Cambria Delivery' &&
          activeCart &&
          currentCustomer &&
          currentCustomerShipToSites) ||
        (shippingAddressArg &&
          value.name === 'Cambria Arranged Delivery' &&
          activeCart &&
          currentCustomer &&
          currentCustomerShipToSites)
      ) {
        try {
          const result = await getShippingCost(
            activeCart.cartItems[0].productType,
            (shippingAddress as any).postalCode,
            activeCart,
            currentCustomer,
            currentCustomerShipToSites
          );
          setShippingZonePricing(result);
        } catch (error) {
          if (
            error === 'Shipping zone not found.' ||
            (error && (error as string).toLowerCase().includes('invalid zipcode format.'))
          ) {
            setConfirmModalOptions({
              heading: 'Shipping Zone Verification',
              body1: `The shipping address postal code is outside of the Cambria delivery zone for ${currentWarehouse?.name} Sales & Distribution Center.`,
              body2:
                'Please contact CambriaCustomerCare to have this postal code added to your local delivery schedule at 1-866-CAMBRIA. Or, please change your delivery method.',
              hideCancelButton: true,
              hideExitIcon: true,
              disableClickOutsideToClose: true,
              confirmButton: 'Change Delivery Method',
              onSubmit: () => {
                setDeliveryMethod('');
                setCurrentDeliveryMethod(null);
              },
            });
          } else if (error === 'Shipping zone pricing not found.') {
            setConfirmModalOptions({
              heading: 'Shipping Zone Verification',
              body1: 'Pricing is not available for the assigned Cambria Delivery zone.',
              hideCancelButton: true,
              hideExitIcon: true,
              disableClickOutsideToClose: true,
              confirmButton: 'Change Delivery Method',
              onSubmit: () => {
                setDeliveryMethod('');
                setCurrentDeliveryMethod(null);
              },
            });
          } else {
            toast.error('Error while trying to get shipping zones!');
            return;
          }
          setShowConfirmModal(() => true);
        }
      }
    },
    [activeCart, currentCustomer, currentCustomerShipToSites, currentWarehouse, shippingAddress]
  );

  const showConfirmationModalIfPreferredContactIsEnabled = async (value: any) => {
    if (
      value &&
      value !== existingPreferredShippingContact &&
      activeCart?.cartItems[0].productType === 'Slab' &&
      uiSettings?.enableDetrack
    ) {
      setConfirmModalOptions({
        heading: 'Update Preferred Shipping Contact?',
        body1:
          existingPreferredShippingContact !== ''
            ? `The contact you have entered will update your existing preferred shipping contact. If you would like to proceed, ` +
              `click the 'Update' button below to update the preferred shipping contact to ${value.displayName}.`
            : `We are improving our delivery communications and would like a primary shipping contact. ` +
              `Click the 'Update' button below to update the preferred shipping contact to  ${value.displayName}.`,
        body2:
          'You can adjust your preferred shipping contact at any time from the Account Slab Order Preferences page.',
        hideCancelButton: false,
        hideExitIcon: false,
        disableClickOutsideToClose: false,
        confirmButton: 'Update',
        onSubmit: async () => {
          let slabOperatingUnitCode = currentCustomer?.productTypeWarehouseCodes?.find(
            (p: any) => p.productType?.toLocaleLowerCase() === 'slab'
          )?.operatingUnitCode;

          const data: Array<PreferredShippingContactRequest> = [];
          data.push({
            erpCustomerId: currentCustomer.erpCustomerId.toString(),
            operatingUnitCode: slabOperatingUnitCode || '',
            erpContactId: value.erpCustomerContactId.toString(),
          });
          updatePreferredShippingContacts(data, preferredShippingContact, dispatch);

          let selectedPreferredContact: PreferredShippingContact = {
            id: 0,
            erpCustomerId: currentCustomer.erpCustomerId.toString(),
            operatingUnitCode: slabOperatingUnitCode || '',
            erpContactId: value.erpCustomerContactId.toString(),
          };

          let preferredContact = await getExistingPreferredShippingContact(
            shippingAddress as any,
            currentCustomerShipToSites || [],
            slabOperatingUnitCode || '',
            [selectedPreferredContact],
            dispatch,
            value
          );

          setExistingPreferredShippingContact(preferredContact ? preferredContact : '');
        },
      });

      toggleConfirmModalShow();
    }
  };

  const toggleAddNewAddressModalShow = () => setShowAddNewShippingAddressModal((p) => !p);
  const toggleAddNewContactModalShow = () => setShowAddNewShippingContactModal((p) => !p);
  const toggleConfirmModalShow = () => setShowConfirmModal((p) => !p);

  const showUpsInfoForMarketrep = (props: any) => {
    if (
      currentCustomer.class === 'MARKETREP' &&
      currentDeliveryMethod.name === 'UPS' &&
      hasPermission('urn:csa:commerceui:useUpsShippingIntegration', userActions) &&
      props.values.shippingMethod &&
      (props.values.shippingMethod as any).formatedPlannedPickupDate
    ) {
      return (
        <span className="text">
          The dates shown are based on being
          <b> picked up on {(props.values.shippingMethod as any).formatedPlannedPickupDate}</b> and shipping from the
          local warehouse. The invoiced rates may vary from the displayed rates.
        </span>
      );
    }
  };

  useEffect(() => {
    if (activeCart && currentCustomerShipToSites && currentCustomer) {
      let options = getCustomerShipToSitesFilteredByWarehouse(
        activeCart as any,
        currentCustomer,
        currentCustomerShipToSites as any
      );
      setCustomerShipToSiteOptions(options);
    }
  }, [activeCart, currentCustomerShipToSites, currentCustomer]);

  useEffect(() => {
    if (activeCart && activeCart.orderDetails.length > 0 && !showJobTypeModal) {
      isFabOrderAndNoJobType(activeCart);
    }
  }, [isFabOrderAndNoJobType, activeCart, showJobTypeModal]);

  useEffect(() => {
    dispatch(invalidateStep2());
    dispatch(getAllAvailableCountries());
    setDisableFields(true);
  }, [dispatch]);

  if (newShippingAddress && activeCart && currentCustomer && currentCustomerShipToSites) {
    const shipToSites = getCustomerShipToSitesFilteredByWarehouse(
      activeCart as any,
      currentCustomer,
      currentCustomerShipToSites as any
    );

    const selectedShipToSite = shipToSites.find((site: any) => {
      const trimmedSitesPostalCode = site.postalCode ? site.postalCode.split('-') : [''];
      const trimmedNewShippingAddressPostalCode = newShippingAddress.postalCode
        ? newShippingAddress.postalCode.split('-')
        : [''];

      return (
        site.siteName === newShippingAddress.siteName &&
        site.address1 === newShippingAddress.address1 &&
        trimmedSitesPostalCode[0] === trimmedNewShippingAddressPostalCode[0]
      );
    }) as any;

    let slabOperatingUnitCode = currentCustomer?.productTypeWarehouseCodes?.find(
      (p: any) => p.productType?.toLocaleLowerCase() === 'slab'
    )?.operatingUnitCode;

    if (
      preferredShippingContact &&
      preferredShippingContact.length > 0 &&
      slabOperatingUnitCode &&
      slabOperatingUnitCode === activeCart?.cartItems[0].operatingUnitCode &&
      currentCustomerShipToSites &&
      activeCart?.cartItems[0].productType === 'Slab' &&
      uiSettings?.enableDetrack
    ) {
      getExistingPreferredShippingContact(
        selectedShipToSite,
        currentCustomerShipToSites,
        slabOperatingUnitCode,
        preferredShippingContact,
        dispatch
      );
    }

    setShippingAddress(selectedShipToSite);
    setDeliveryMethod('');
    setCurrentDeliveryMethod('');
    setContact(existingPreferredShippingContact);
    setShippingMethod('');
    setRequestedDeliveryDate('');

    setLodaingAdvancedSchedule(false);

    resetShippingOnShippingAddressUpdate(selectedShipToSite);
    setNewShippingAddress(null);
  }

  useEffect(() => {
    const getCurrentWarehouse = async () => {
      if (!currentWarehouse && activeCart && currentCustomer && currentCustomerShipToSites) {
        const currentShippingWarehouse = await getShippingWarehouse(
          activeCart,
          currentCustomer,
          currentCustomerShipToSites
        );
        setCurrentWarehouse(() => currentShippingWarehouse);
      }
    };

    getCurrentWarehouse();
  }, [currentWarehouse, activeCart, currentCustomer, currentCustomerShipToSites]);

  useEffect(() => {
    const onDeliveryMethodChange = async () => {
      if (currentDeliveryMethod && !isPageSubmitted) {
        setDisableFields(true);
        if (
          currentDeliveryMethod.name === 'Cambria Delivery' ||
          currentDeliveryMethod.name === 'Cambria Sample Delivery' ||
          currentDeliveryMethod.name === 'Cambria Delivery - PrePay and Absorb'
        ) {
          const {minDate, holidaysDiabled, daysDisabled} = (await setAvailableDatesForCambriaDelivery(
            currentDeliveryMethod.name,
            activeCart as any
          )) as any;
          setAllDaysDisabled(() => daysDisabled.length === 7);
          setMinDate(minDate);
          setDisabledDates(holidaysDiabled);
          setDisabledDays(daysDisabled);
        } else {
          const {minDate, holidaysDiabled, daysDisabled} = (await setAvailableDatesForCambriaArrange(
            currentDeliveryMethod.name
          )) as any;
          setMinDate(minDate);
          setDisabledDates(holidaysDiabled);
          setDisabledDays(daysDisabled);
        }

        const hasCartItems = activeCart && activeCart.cartItems && activeCart.cartItems.length > 0;

        if (
          activeCart &&
          activeCart.cartItems &&
          activeCart.cartItems.length > 0 &&
          activeCart.cartItems[0].productType !== 'Fabricated' &&
          activeCart.cartItems[0].productType !== 'Tile' &&
          hasPermission('urn:csa:commerceui:checkout:requestedDeliveryDate', userActions)
        ) {
          if (
            (currentDeliveryMethod.name === 'UPS' && currentCustomer.class === 'MARKETREP') ||
            currentDeliveryMethod.name.toLowerCase().includes('combine with a slab order')
          ) {
            setShowRequestedDateField(false);
          } else {
            setShowRequestedDateField(true);
          }
        } else if (activeCart?.cartItems[0].productType === 'Fabricated') {
          if (currentDeliveryMethod.name.includes('Customer Arranged')) {
            setShowShippingMethodField(true);
            setShippingMethods(currentDeliveryMethod.shippingMethods);
          } else {
            setShowShippingMethodField(false);
          }
        }

        if (
          (currentDeliveryMethod.shippingMethods.length > 1 &&
            currentDeliveryMethod.name !== 'Customer Arranged' &&
            currentDeliveryMethod.name !== 'UPS') ||
          (currentDeliveryMethod.name === 'UPS' && currentDeliveryMethod.shippingMethods.length > 0)
        ) {
          setShowShippingMethodField(true);
          setShippingMethods(currentDeliveryMethod.shippingMethods);
        } else {
          setShowShippingMethodField(false);
        }

        if (
          hasCartItems &&
          activeCart.cartItems[0].productType === 'Samples' &&
          currentDeliveryMethod &&
          (currentDeliveryMethod.name === 'Pick Up' || currentDeliveryMethod.name === 'Cambria Sample Delivery') &&
          currentCustomer &&
          currentCustomer.class === 'MARKETREP'
        ) {
          setRequestedDeliveryDateFieldLabel('Need by Date');
        } else if (hasCartItems && activeCart.cartItems[0].productType === 'Samples') {
          setRequestedDeliveryDateFieldLabel('Requested Ship Date');
        } else {
          setRequestedDeliveryDateFieldLabel('Requested Delivery Date');
        }

        if (
          currentCustomer.class === 'MARKETREP' &&
          currentDeliveryMethod.name === 'UPS' &&
          hasPermission('urn:csa:commerceui:useUpsShippingIntegration', userActions)
        ) {
          setShippingMethodLabel(() => (
            <div>
              <div className="ups-logo"></div>
              <span>Shipping Method</span>
            </div>
          ));
          setShippingMethods(formatShippingMethodsForUPS(currentDeliveryMethod.shippingMethods));
        } else {
          setShippingMethodLabel(() => 'Shipping Method');
        }
        setDisableFields(false);
      }
    };

    onDeliveryMethodChange();
  }, [currentDeliveryMethod, activeCart, currentCustomer, userActions, isPageSubmitted]);

  useEffect(() => {
    if (showShippingMethodField) {
      setTimeout(() => {
        if (currentDeliveryMethod.name.toLowerCase().includes('customer arranged')) {
          setShippingMethodLabel(() => 'Preferred Shipping Provider');
        } else {
          setShippingMethodLabel(() => 'Shipping Method');
        }
      });
    }
  }, [showShippingMethodField, currentDeliveryMethod]);

  useEffect(() => {
    if (shippingZonePricing) {
      showToastrMessageIfZeroShippingRate(shippingZonePricing.deliveryRate);
    }
  }, [shippingZonePricing]);

  useEffect(() => {
    const getNotificationContacts = async () => {
      if (activeCart) {
        const contacts = await getOrderNotificationContactsRequest(activeCart.id);
        setOrderNotificationContacts(contacts);
      }
    };

    if (!orderNotificationContacts || (orderNotificationContacts && orderNotificationContacts.length < 0)) {
      getNotificationContacts();
    }
  }, [activeCart, orderNotificationContacts]);

  useEffect(() => {
    const getAvailableContacts = async () => {
      const contacts = await getSalesforceContacts(salesforceCustomerAccount.id);
      setAvailableContacts(contacts);
    };

    getAvailableContacts();
  }, [salesforceCustomerAccount]);

  useEffect(() => {
    const getExistingShippingDetails = async () => {
      if (
        activeCart &&
        !shippingAddress &&
        orderNotificationContacts &&
        currentCustomer &&
        currentCustomerShipToSites &&
        currentWarehouse &&
        !alreadyInitialized
      ) {
        setAlreadyInitialized(true);
        setDisableFields(true);

        let {existingOrDefaultShippingAddress, newShipToSite} = await salesforceAccountInit();

        if (!existingOrDefaultShippingAddress) {
          existingOrDefaultShippingAddress = getAccountDefaultShipToAddress(
            getCustomerShipToSitesFilteredByWarehouse(activeCart as any, currentCustomer, currentCustomerShipToSites)
          );
        }

        if (newShipToSite) {
          setNewShippingAddress(newShipToSite);
        } else {
          setShippingAddress(existingOrDefaultShippingAddress);
        }

        const canUseUpsShippingIntegration = hasPermission('urn:csa:commerceui:useUpsShippingIntegration', userActions);
        const useUpsEndpoint = currentCustomer.class === 'MARKETREP' && canUseUpsShippingIntegration;

        const deliveryMethodsResponse = await getShippingSelections(
          existingOrDefaultShippingAddress,
          activeCart,
          activeProgram,
          currentCustomer,
          currentCustomerShipToSites,
          useUpsEndpoint
        );
        setDeliveryMethods(deliveryMethodsResponse);
        const contactOptions = getContactOptions(
          currentCustomerShipToSites,
          activeCart,
          currentCustomer,
          existingOrDefaultShippingAddress as any,
          undefined,
          activeCart.cartItems[0].productType === 'Slab' && uiSettings?.enableDetrack
        );
        setContactOptions(contactOptions);

        let preferredContact = undefined;

        let slabOperatingUnitCode = currentCustomer?.productTypeWarehouseCodes?.find(
          (p: any) => p.productType?.toLocaleLowerCase() === 'slab'
        )?.operatingUnitCode;

        if (
          preferredShippingContact &&
          preferredShippingContact.length > 0 &&
          slabOperatingUnitCode === activeCart?.cartItems[0].operatingUnitCode &&
          activeCart?.cartItems[0].productType === 'Slab' &&
          uiSettings?.enableDetrack
        ) {
          preferredContact = await getExistingPreferredShippingContact(
            existingOrDefaultShippingAddress,
            currentCustomerShipToSites,
            slabOperatingUnitCode,
            preferredShippingContact,
            dispatch
          );
        }

        setExistingPreferredShippingContact(preferredContact ? preferredContact : '');

        let existingContact = null;

        if (activeCart.shippingMethods[0]?.shipToContactId && !isUpdatedSalesforceAssetAccount) {
          existingContact = contactOptions.find(
            (contact: any) =>
              ((contact.erpCustomerContactId === activeCart.shippingMethods[0]?.shipToContactId ||
                contact.id === activeCart.shippingMethods[0]?.shipToContactId) &&
                contact.email &&
                contact.email.lenth > 0 &&
                uiSettings?.enableDetrack) ||
              contact.erpCustomerContactId === activeCart.shippingMethods[0]?.shipToContactId ||
              contact.id === activeCart.shippingMethods[0]?.shipToContactId
          );
        }

        if (existingContact) {
          setContact(existingContact);
        } else if (preferredContact) {
          setContact(preferredContact);
        }

        if (activeCart.shippingMethods[0]?.shippingOptionId && !isUpdatedSalesforceAssetAccount) {
          const existingDeliveryMethod = deliveryMethodsResponse.find(
            (method: any) => method.id === activeCart.shippingMethods[0]?.shippingOptionId
          );

          setCurrentDeliveryMethod(() => existingDeliveryMethod);
          setDeliveryMethod(existingDeliveryMethod);

          getShippingZonePrices(existingOrDefaultShippingAddress, existingDeliveryMethod);

          if (activeCart.shippingMethods[0]?.shippingMethodCode && !isUpdatedSalesforceAssetAccount) {
            setShippingMethod(
              existingDeliveryMethod.shippingMethods.find(
                (method: any) => method.code === activeCart.shippingMethods[0]?.shippingMethodCode
              )
            );
          }

          if (activeCart.shippingMethods[0]?.customerShippingAccountId && !isUpdatedSalesforceAssetAccount) {
            setCustomerShippingAccountId(activeCart.shippingMethods[0]?.customerShippingAccountId);
          }

          if (existingDeliveryMethod) {
            const existingDeliverySchedule = await getDeliverySchedule(activeCart.id, dispatch);

            if (existingDeliverySchedule?.length > 0) {
              setRequestedDeliveryDate(
                new Date(existingDeliverySchedule[0].requestedDeliveryDate).toLocaleDateString('en-US')
              );

              const existingSchedulesCopy = JSON.parse(JSON.stringify(existingDeliverySchedule));
              const deliveryDateScheduleData: any = [];

              existingSchedulesCopy.forEach((existingSchedule: any) => {
                activeCart.cartItems.forEach((item: any) => {
                  if (item.cartItemId === existingSchedule.cartItemId && item.imageUrl && item.description) {
                    existingSchedule.imageUrl = item.imageUrl;
                    existingSchedule.description = item.description;
                    deliveryDateScheduleData.push(existingSchedule);
                  }
                });
              });
              setScheduledItems(deliveryDateScheduleData);
            }

            if (
              activeCart.cartItems[0].productType === 'Samples' &&
              (existingDeliveryMethod.name === 'Pick Up' ||
                existingDeliveryMethod.name === 'Cambria Sample Delivery') &&
              currentCustomer &&
              currentCustomer.class === 'MARKETREP'
            ) {
              setRequestedDeliveryDateFieldLabel('Need by Date');
            } else if (activeCart.cartItems[0].productType === 'Samples') {
              setRequestedDeliveryDateFieldLabel('Requested Ship Date');
            } else {
              setRequestedDeliveryDateFieldLabel('Requested Delivery Date');
            }
          }
        }

        if (activeCart.shippingMethods[0]?.shipComplete && !isUpdatedSalesforceAssetAccount) {
          setSendInFewerBoxes(true);
        }

        if (activeCart.shippingMethods[0]?.shippingInstructions && !isUpdatedSalesforceAssetAccount) {
          setShippingInstructions(activeCart.shippingMethods[0]?.shippingInstructions);
        }

        if (orderNotificationContacts && orderNotificationContacts.length > 0) {
          let emailAddressArray: any = [];
          let smsArray: any = [];

          orderNotificationContacts.forEach((orderContact: any) => {
            if (orderContact.method === 'Email') {
              if (emailAddressArray.find((e: any) => e.email === orderContact.email) === undefined) {
                emailAddressArray.push(orderContact);
              }
            } else if (orderContact.method === 'Sms') {
              smsArray.push(orderContact.mobilePhone);
            }
          });

          if (smsArray.length > 0) {
            setMobileNotification(true);
            setPhoneNumber(smsArray[0]);
            setSmsNotificationsOptIn(true);
          }

          if (emailAddressArray.length > 0) {
            setEmailNotification(true);
            setEmailListNotification(() => emailAddressArray);
            setEmailNotificationsOptIn(true);
          }
        }
        dispatch(setIsUpdatedSalesforceAssetAccount(false));
        setDisableFields(false);
      }
    };

    getExistingShippingDetails();
  }, [
    dispatch,
    shippingAddress,
    selectedWarehouse,
    getShippingZonePrices,
    isUpdatedSalesforceAssetAccount,
    alreadyInitialized,
    orderNotificationContacts,
    activeCart,
    activeProgram,
    shippingMethods,
    emailListNotification.length,
    currentCustomer,
    currentCustomerShipToSites,
    currentCustomerSfAccount,
    currentWarehouse,
    userActions,
    preferredShippingContact,
    salesforceAccountInit,
    uiSettings?.enableDetrack,
  ]);

  useEffect(() => {
    if (!allDaysDisabled && activeCart?.cartItems[0]?.productType !== 'Slab') {
      setIsRequestedDateFieldRequired(true);
    }
  }, [allDaysDisabled, activeCart]);

  const renderDeliveryMethodInfo = () => {
    let message = currentDeliveryMethod.message || '';

    if (currentDeliveryMethod.name.toLowerCase().includes('combine with a slab order')) {
      message = `${message} We will ship on the next available slab truck.`;
    }

    if (currentDeliveryMethod.name === 'Cambria Delivery' && shippingZonePricing && activeCart) {
      message = `Shipping Cost: $${formatPrice(shippingZonePricing.deliveryRate)} ${activeCart.cartItems[0].currency} ${
        shippingZonePricing.deliveryRateType ? `/ ${shippingZonePricing.deliveryRateType}` : ''
      }`;
    }

    if (
      (currentDeliveryMethod.name === 'Cambria Delivery' &&
        shippingZonePricing &&
        activeCart &&
        activeProgram.code.includes('HD') &&
        shippingZonePricing.deliveryRateType !== 'Flat') ||
      (currentDeliveryMethod.name === 'Cambria Arranged Delivery' &&
        shippingZonePricing &&
        activeCart &&
        activeProgram.code.includes('HD') &&
        shippingZonePricing.deliveryRateType !== 'Flat')
    ) {
      message = `Shipping Cost for non-standard items: $${formatPrice(shippingZonePricing.deliveryRate)} ${
        activeCart.cartItems[0].currency
      } ${shippingZonePricing.deliveryRateType ? `/ ${shippingZonePricing.deliveryRateType}` : ''}`;
    }

    if (currentDeliveryMethod.name === 'Customer Arranged from DC') {
      message =
        'The customer will arrange for a carrier or will pick up the order. The carrier will invoice delivery charges to consignee.';
    }

    if (currentDeliveryMethod.name === 'FedEx - Use My Account') {
      message =
        "Cambria will ship with FedEx and FedEx will bill delivery to the customer's FedEx account. FedEx account number must be provided.";
    }

    return (
      <div className="field-info">
        <span className="text">{message}</span>
      </div>
    );
  };

  const onNewAddressCallback = (newAddress: any, props: FormikProps<ShippingAndDeliveryFormValues>) => {
    setNewShippingAddress(newAddress);
    props.setFieldValue('deliveryMethod', '');
  };

  return (
    <ShippingAndDeliveryContainer>
      <Formik<ShippingAndDeliveryFormValues>
        initialValues={{
          shippingAddress: shippingAddress,
          contact: contact,
          deliveryMethod: deliveryMethod,
          shippingMethod: shippingMethod,
          requestedDeliveryDate: requestedDeliveryDate,
          customerShippingAccountId: customerShippingAccountId,
          sendInFewerBoxes: sendInFewerBoxes,
          shippingInstructions: shippingInstructions,
          mobileNotification: mobileNotification,
          phoneNumber: phoneNumber,
          emailNotification: emailNotification,
          email: email,
        }}
        validationSchema={validationSchema}
        onSubmit={async (values, props) => {
          if (values.emailNotification && emailListNotification.length === 0) {
            props.setFieldError('email', 'Please add at least one email');
            return;
          }
          if (activeCart && currentCustomerShipToSites && currentCustomerBillToSites && currentUser) {
            dispatch(showFullscreenLoader({showCartIcon: false}));
            setDisableFields(true);
            setIsPageSubmitted(true);
            const shippingDetails = JSON.parse(JSON.stringify(values));
            shippingDetails.phoneNumber = shippingDetails.phoneNumber ? shippingDetails.phoneNumber : null;
            shippingDetails.email = emailListNotification.length > 0 ? emailListNotification : [];
            shippingDetails.shippingInstructions = shippingDetails.shippingInstructions
              ? shippingDetails.shippingInstructions.trim()
              : null;
            if (!showShippingMethodField) {
              shippingDetails.shippingMethod = '';
            }
            await dispatch(
              submitShippingAndDelivery({
                activeCart,
                shippingDetails,
              })
            );
            pushAddShippingInfoToDataLayer({
              cart: activeCart,
              deliveryMethod: values.deliveryMethod.name,
            });

            if (paymentMethodPageHasBeenSkipped && paymentSelections) {
              dispatch(setSavePaymentMethodToCart(true));
            } else {
              dispatch(setIsSubmitting(false));
              dispatch(setStep2Completed(true));
            }

            await initializeCart(
              'cdmpComponentResolve',
              currentCustomer,
              parseInt(currentUser.userId),
              availablePrograms,
              activeProgram,
              selectedWarehouse && selectedWarehouse?.warehouseCode ? selectedWarehouse?.warehouseCode : null,
              currentCustomerShipToSites,
              currentCustomerBillToSites,
              dispatch
            );
          }
        }}>
        {(props) => {
          return (
            <Form id={activeStep?.step} noValidate onSubmit={props.handleSubmit}>
              <Container className="p-0 not-marketrep-form">
                <Row>
                  <Col className="col-12 col-lg-6">
                    <CambriaSelect
                      name="shippingAddress"
                      formikFormProps={props}
                      defaultValue={shippingAddress}
                      label="Shipping Address"
                      placeholder="Select Shipping Address"
                      items={customerShipToSiteOptions}
                      displayValue="address"
                      disabled={disableFields}
                      onChange={async (value: any) => {
                        let preferredContact = undefined;
                        let slabOperatingUnitCode = currentCustomer?.productTypeWarehouseCodes?.find(
                          (p: any) => p.productType?.toLocaleLowerCase() === 'slab'
                        )?.operatingUnitCode;

                        if (
                          preferredShippingContact &&
                          preferredShippingContact.length > 0 &&
                          slabOperatingUnitCode &&
                          slabOperatingUnitCode === activeCart?.cartItems[0].operatingUnitCode &&
                          currentCustomerShipToSites &&
                          activeCart?.cartItems[0].productType === 'Slab' &&
                          uiSettings?.enableDetrack
                        ) {
                          preferredContact = await getExistingPreferredShippingContact(
                            value,
                            currentCustomerShipToSites,
                            slabOperatingUnitCode,
                            preferredShippingContact,
                            dispatch
                          );
                        }

                        setShippingAddress(value);
                        setDeliveryMethod('');
                        props.setFieldValue('deliveryMethod', '');
                        setContact(preferredContact ? preferredContact : '');
                        setShippingMethod('');

                        await resetShippingOnShippingAddressUpdate(value);
                      }}
                      required={true}></CambriaSelect>
                  </Col>
                  <Col className="col-12 col-lg-6 button-section">
                    <CambriaButton
                      onClick={toggleAddNewAddressModalShow}
                      variant="secondary"
                      id="add-new-address-test-id">
                      <Icon icon="icons-cambria-Symbols-Plus" color="#c59617" size="15" weight="bold" />
                      Add New Address
                    </CambriaButton>
                  </Col>
                </Row>
                <Row>
                  <Col className="col-12 col-lg-6">
                    <CambriaSelect
                      name="contact"
                      formikFormProps={props}
                      defaultValue={contact}
                      label={`Contact ${
                        activeCart?.cartItems[0].productType === 'Slab' && uiSettings?.enableDetrack ? '*' : ''
                      }`}
                      placeholder="Select Contact"
                      items={contactOptions}
                      displayValue="displayName"
                      disabled={disableFields}
                      onChange={(value: any) => {
                        showConfirmationModalIfPreferredContactIsEnabled(value);
                      }}
                    />
                  </Col>
                  <Col className="col-12 col-lg-6 button-section">
                    <CambriaButton
                      onClick={toggleAddNewContactModalShow}
                      variant="secondary"
                      id="add-or-revise-contact-test-id">
                      <Icon icon="icons-cambria-Symbols-Plus" color="#c59617" size="15" weight="bold" />
                      Add or Revise Contact
                    </CambriaButton>
                  </Col>
                </Row>
                <Row>
                  <Col className="col-12 col-lg-6">
                    <CambriaSelect
                      name="deliveryMethod"
                      formikFormProps={props}
                      defaultValue={deliveryMethod}
                      label={
                        <>
                          <Row>
                            <Col className="col-12 col-md-6">
                              <span>
                                <label htmlFor="deliveryMethod">Delivery Method *</label>
                              </span>
                            </Col>
                            <Col className="col-12 col-md-6 current-warehouse">
                              <span>{currentWarehouse?.name}</span>
                            </Col>
                          </Row>
                        </>
                      }
                      placeholder="Select Delivery Method"
                      items={deliveryMethods}
                      displayValue="name"
                      disabled={disableFields}
                      onChange={async (value: any) => {
                        setRequestedDeliveryDate('');
                        setCurrentDeliveryMethod(value);
                        setScheduledItems(null);
                        setLodaingAdvancedSchedule(false);
                        getShippingZonePrices(props.values.shippingAddress, value);
                        if (activeCart && scheduledItems) {
                          await deleteDeliverySchedulesRequest(activeCart.id);
                        }
                        if (
                          currentCustomer.class === 'MARKETREP' &&
                          value.name === 'UPS' &&
                          hasPermission('urn:csa:commerceui:useUpsShippingIntegration', userActions)
                        ) {
                          setShippingMethod(value.shippingMethods[0]);
                        } else {
                          setShippingMethod('');
                        }
                      }}
                      required={true}
                    />
                    {currentDeliveryMethod && renderDeliveryMethodInfo()}
                  </Col>
                </Row>
                {showShippingMethodField && (
                  <Row>
                    <Col className="col-12 col-lg-6">
                      <CambriaSelect
                        name="shippingMethod"
                        formikFormProps={props}
                        defaultValue={shippingMethod}
                        label={shippingMethodLabel}
                        placeholder="Select Shipping Method"
                        items={shippingMethods}
                        displayValue="name"
                        disabled={disableFields}
                        required={showShippingMethodField}
                      />
                      {showUpsInfoForMarketrep(props)}
                    </Col>
                  </Row>
                )}
                {currentDeliveryMethod && currentDeliveryMethod.requireCustomerNumber && (
                  <Row>
                    <Col className="col-12 col-lg-6">
                      <CambriaInput
                        name="customerShippingAccountId"
                        defaultValue={customerShippingAccountId}
                        label="Account Number"
                        placeholder="Enter account number here"
                        type="text"
                        required={currentDeliveryMethod.requireCustomerNumber}
                        disabled={disableFields}></CambriaInput>
                    </Col>
                  </Row>
                )}
                {showRequestedDateField && currentDeliveryMethod && requestedDeliveryDateFieldLabel && (
                  <Row>
                    <Col className="col-12 col-lg-6">
                      <CambriaDateInput
                        name="requestedDeliveryDate"
                        defaultValue={requestedDeliveryDate}
                        label={requestedDeliveryDateFieldLabel}
                        minDate={minDate}
                        excludeDates={disabledDates}
                        filterDate={disabledDays}
                        placeholder={requestedDeliveryDateFieldLabel}
                        onChange={onSetRequestedDeliveryDate}
                        disabled={disableFields}
                        required={isRequestedDateFieldRequired}
                      />
                    </Col>
                  </Row>
                )}
                {lodaingAdvancedSchedule && !scheduledItems && <Spinner animation="border"></Spinner>}
                {scheduledItems && scheduledItems.length > 0 && (
                  <AdvancedDeliverySchedule
                    scheduledItems={scheduledItems}
                    dateFieldOptions={{
                      minDate: minDate,
                      holidaysDiabled: disabledDates,
                      daysDisabled: disabledDays,
                    }}
                  />
                )}
                {activeCart &&
                  activeCart.cartItems &&
                  activeCart.cartItems.length > 0 &&
                  activeCart.cartItems[0].productType === 'Samples' && (
                    <Row>
                      <Col className="col-12 m-t-sm m-b-sm">
                        <CambriaInput
                          name="sendInFewerBoxes"
                          defaultValue={sendInFewerBoxes}
                          label="Send my order in the fewest number of boxes possible"
                          type="checkbox"
                          disabled={disableFields}></CambriaInput>
                      </Col>
                    </Row>
                  )}
                <Row>
                  <Col>
                    <CambriaInput
                      name="shippingInstructions"
                      type="textarea"
                      defaultValue={shippingInstructions}
                      label="Additional Shipping Instructions"
                      disabled={disableFields}
                      placeholder="Additional details may be provided here"></CambriaInput>
                  </Col>
                </Row>
                {hasPermission('urn:csa:commerceui:viewOrderNotificationComponent', userActions) && (
                  <Row>
                    <div className="order-notifications">
                      <h2 className="m-b-sm">Order Notifications</h2>

                      {hasPermission('urn:csa:commerceui:viewSmsComponent', userActions) && (
                        <>
                          <Row>
                            <Col className="col-12 col-lg-8 m-b-sm">
                              <CambriaInput
                                name="mobileNotification"
                                defaultValue={smsNotificationsOptIn}
                                label="By checking box and providing my number below, I agree, provide my prior express written consent, and authorize Cambria and its service providers to contact me at number via phone and/or text (SMS) using automated dialing technology for non-marketing purposes, including regarding the status of and updates on my orders. Not a condition for purchase. Message and data rates may apply. To opt-out at any time text STOP to (331) 256-2092. Privacy Notice"
                                type="checkbox"
                                disabled={disableFields}
                                onChange={(value: boolean) => {
                                  setSmsNotificationsOptIn(value);
                                  if (!value) {
                                    props.values.phoneNumber = '';
                                  }
                                }}></CambriaInput>
                            </Col>
                          </Row>
                          <Row>
                            <Col className="col-12 col-lg-8 m-b-md">
                              <CambriaInput
                                name="phoneNumber"
                                defaultValue={phoneNumber}
                                label="Mobile Number"
                                placeholder="Mobile Number"
                                type="phoneNumber"
                                disabled={!smsNotificationsOptIn || disableFields}
                                required={smsNotificationsOptIn}></CambriaInput>
                            </Col>
                          </Row>
                        </>
                      )}

                      {hasPermission('urn:csa:commerceui:viewEmailComponent', userActions) && (
                        <>
                          <Row>
                            <Col className="col-12 col-lg-8 m-b-sm">
                              <CambriaInput
                                name="emailNotification"
                                defaultValue={emailNotificationsOptIn}
                                label="I would like to be notified of delivery status updates via Emails."
                                type="checkbox"
                                disabled={disableFields}
                                onChange={(value: boolean) => {
                                  setEmailNotificationsOptIn(value);
                                  if (!value) {
                                    setEmailListNotification([]);
                                    props.setFieldValue('email', email);
                                  }
                                  setEmailNotification(value);
                                }}></CambriaInput>
                            </Col>
                          </Row>
                          <Row>
                            <Col className="email-field align-items-end col-12 col-lg-8 d-flex">
                              <CambriaInput
                                name="email"
                                defaultValue={email}
                                label="Add emails to be notified"
                                placeholder="Email Address"
                                type="text"
                                disabled={!emailNotificationsOptIn || disableFields}
                                required={emailNotificationsOptIn && emailListNotification.length === 0}></CambriaInput>
                              <CambriaButton
                                onClick={() => {
                                  if (props.values.email && !props.getFieldMeta('email').error) {
                                    setEmailListNotification((emails: Array<string>) => [
                                      ...emails,
                                      {email: props.values.email},
                                    ]);
                                    setEmail('');
                                    props.setFieldValue('email', email);
                                    props.setTouched({}, false);
                                  } else {
                                    toast.info('Please add an email address');
                                  }
                                }}
                                disabled={
                                  disableFields || !emailNotificationsOptIn || !!props.getFieldMeta('email').error
                                }>
                                ADD
                              </CambriaButton>
                            </Col>
                          </Row>
                          {emailListNotification && (
                            <Row>
                              <section className="col-6">
                                {emailListNotification.map((email: any, i: number) => {
                                  return (
                                    <article key={i} className="d-flex justify-content-between">
                                      <p>{email.email}</p>
                                      <Icon
                                        icon="icons-cambria-Ui-Multiply"
                                        color="#909090"
                                        colorOnHover="#c59617"
                                        size="15"
                                        weight="bold"
                                        onClick={() => {
                                          const newList = emailListNotification.filter(
                                            (e: any, index: number) => index !== i
                                          );
                                          if (newList.length === 0) {
                                            setEmailNotificationsOptIn(false);
                                            setEmailNotification(false);
                                          }
                                          setEmailListNotification(() => newList);
                                        }}
                                      />
                                    </article>
                                  );
                                })}
                              </section>
                            </Row>
                          )}
                        </>
                      )}
                    </div>
                  </Row>
                )}
              </Container>
              {showAddNewShippingAddressModal && (
                <AddShippingAddressModal
                  show={showAddNewShippingAddressModal}
                  toggleShow={toggleAddNewAddressModalShow}
                  onSubmitCallback={async (newAddress: any) => {
                    onNewAddressCallback(newAddress, props);
                  }}
                  operatingUnitCode={
                    customerShipToSiteOptions && customerShipToSiteOptions.length > 0
                      ? customerShipToSiteOptions[0]?.operatingUnitCode
                      : undefined
                  }
                />
              )}
              {showAddNewShippingContactModal && (
                <AddShippingContactModal
                  show={showAddNewShippingContactModal}
                  shouldRequireEmail={activeCart?.cartItems[0].productType === 'Slab' && uiSettings?.enableDetrack}
                  toggleShow={toggleAddNewContactModalShow}
                  currentShippingSiteId={(shippingAddress as any).id}
                  existingContacts={availableContacts}
                  onSubmitCallback={async (contact: any) => {
                    props.values.contact = contact;
                    const updatedSites = JSON.parse(JSON.stringify(currentCustomerShipToSites));
                    const currentShippingSite = JSON.parse(JSON.stringify(props.values.shippingAddress));

                    updatedSites.forEach((site: any) => {
                      if (site.id === currentShippingSite.id) {
                        site.contacts.push(contact);
                      }
                    });

                    await dispatch(setCurrentCustomerShipToSites(updatedSites));
                    await showConfirmationModalIfPreferredContactIsEnabled(contact);
                  }}></AddShippingContactModal>
              )}
              {showJobTypeModal && (
                <CambriaModal
                  heading="Fabrication Order Details Incomplete"
                  confirmButton="Go Back to Fabrication Step 1"
                  hideCancelButton
                  hideExitIcon
                  show
                  formName="job-type-missing">
                  <Form
                    id="job-type-missing"
                    onSubmit={() => history.push(COMMERCE_CORE_CONSTANTS.PATHNAMES.fabrication)}>
                    <p>The order details on this fabrication cart are incomplete.</p>
                    <br />
                    <p>Please go back to step 1 of the fabrication page to add this information.</p>
                  </Form>
                </CambriaModal>
              )}
              {showConfirmModal && (
                <ConfirmationModal
                  show={showConfirmModal}
                  toggleShow={toggleConfirmModalShow}
                  onSubmitCallback={() => {
                    if (confirmModalOptions.onSubmit) {
                      confirmModalOptions.onSubmit();
                      props.setFieldValue('deliveryMethod', '');
                    }
                    setShowConfirmModal(() => false);
                  }}
                  modalOptions={confirmModalOptions}></ConfirmationModal>
              )}
            </Form>
          );
        }}
      </Formik>
    </ShippingAndDeliveryContainer>
  );
};

export default ShippingAndDelivery;
