export const appConfig = {
  name: 'commerceFramework',
  homeState: 'app.home',
  showCustomerSearch: false,
  showAppGridMoreLink: true,
  extraFooterCopyright: '',
  expireSessions: true,
  timeoutWarningDuration: 60000,
  loginContactMessage: '',
  includeAppGrid: true,
  contactInfo: '1-866-CAMBRIA',
  localStorageKey: 'CAMBRIA_LOCAL_STORE',
};
