import React, {useState} from 'react';
import {Col, Dropdown, Form, Row} from 'react-bootstrap';
import {Cart} from '../../../../../../../features/cart/ICartState';
import {IFullPieces} from '../../../../../../../features/fabrication/IFabricationState';
import {deleteCartItem} from '../../../../../../../features/productCatalog/service/productCatalog.service';
import Icon from '../../../../../../../Framework/Components/Icon';
import {toast} from 'react-toastify';
import {useAppDispatch, useTypedSelector} from '../../../../../../../hooks/store';
import {
  selectCurrentCustomer,
  selectCurrentCustomerBillToSites,
  selectCurrentCustomerShipToSites,
} from '../../../../../../../features/customer/slice/customer.slice';
import {selectCurrentUser} from '../../../../../../../features/auth/slice/authentication.slice';
import {
  selectActiveProgram,
  selectAvailablePrograms,
} from '../../../../../../../features/salesforce/slice/salesforce.slice';
import FabricationPieceForm from '../../../../../FabricationOrderEntry/OrderPieces/PieceInfoForms/FabricationForm';
import {selectActiveCart, setActiveCart} from '../../../../../../../features/cart/slice/cart.slice';
import {
  setDuplicateBeingEditted,
  setParentPieceLabelOfDuplicate,
} from '../../../../../../../features/fabrication/slice/fabrication.slice';
import CambriaModal from '../../../../../../../Framework/Components/CambriaModal';
import {getCart} from '../../../../../../../features/cart/service/cart.service';
import {Site} from '../../../../../../../features/customer/ICustomerState';
import {showFullscreenLoader} from '../../../../../../../features/fullscreenLoader/slice/fullscreenLoader.slice';
import {useHistory} from 'react-router-dom';
import {ISalesforceAvailablePrograms} from '../../../../../../../features/salesforce/ISalesforceState';
import COMMERCE_CORE_CONSTANTS from '../../../../../../../Core/constants';
import {formatQuoteCurrency} from '../../../../../../../Framework/Services/formatCurrency';
import {
  selectFabLineItemsLimit,
  selectUiSettings,
} from '../../../../../../../features/environment/slice/environment.slice';
import {pushRemoveFabPieceFromCartEventToDataLayer} from '../../../../../../../features/analytics/service/analytics.service';

const CambriaFabricationPieceCartInfo = ({
  cart,
  piece,
  isNested = false,
  fabOrderPage,
  parentPieceLabel,
}: {
  cart: Cart;
  piece: IFullPieces;
  isNested?: boolean;
  fabOrderPage?: boolean;
  parentPieceLabel?: string;
}) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const uiSettings = useTypedSelector(selectUiSettings);
  const cutoutOrGeneralItems =
    piece &&
    piece.mainPiece &&
    (piece.mainPiece.productGroupCode === 'CutoutsAndInlays' || piece.mainPiece.productGroupCode === 'GeneralItems');
  const hasAdjustedUnitPrice: boolean = uiSettings?.calculateCartDiscountsInBackend
    ? piece?.mainPiece?.adjustedUnitPrice < piece?.mainPiece?.unitPrice
    : piece?.mainPiece?.hasAdjustedUnitPrice;
  const hasAdjustedItemQuantityPrice: boolean = uiSettings?.calculateCartDiscountsInBackend
    ? piece?.mainPiece?.adjustedExtendedNetPrice < piece?.mainPiece?.itemQuantityPrice
    : piece?.mainPiece?.hasAdjusteditemQuantityPrice;
  const [edittingPiece, setEdittingPiece] = useState<boolean>(false);
  const [pieceBeingEditted, setPieceBeingEditted] = useState<IFullPieces | null>(null);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [onCartPage] = useState(window.location.href.includes(COMMERCE_CORE_CONSTANTS.PATHNAMES.cart));
  const toggleShow = () => {
    setShowDeleteModal((p) => !p);
  };
  const [deleteIsRunning, setDeleteIsRunning] = useState(false);

  const currentCustomer = useTypedSelector(selectCurrentCustomer);
  const userInfo = useTypedSelector(selectCurrentUser);
  const activeProgramInfo = useTypedSelector(selectActiveProgram);
  const activeCart = useTypedSelector(selectActiveCart);
  const currentCustomerShipToSites: Site[] | null = useTypedSelector(selectCurrentCustomerShipToSites);
  const currentCustomerBillToSites: any = useTypedSelector(selectCurrentCustomerBillToSites);
  const availablePrograms: ISalesforceAvailablePrograms | null = useTypedSelector(selectAvailablePrograms);
  const lineItemsLimit = useTypedSelector(selectFabLineItemsLimit);

  const callDeleteCartItem = async (piece: IFullPieces) => {
    setDeleteIsRunning(true);
    try {
      await deleteCartItem(
        piece.mainPiece.itemNumber,
        activeCart ? activeCart.id : '',
        piece.mainPiece.cartItemId,
        dispatch
      );
      pushRemoveFabPieceFromCartEventToDataLayer([
        {
          pieceModel: piece,
          quantity: piece.mainPiece.uomLineQuantity ?? 1,
        },
      ]);
      toast.success('Item successfully removed from cart');
      const updatedCart = await getCart(
        activeCart?.id || '',
        currentCustomer,
        userInfo?.userId || '',
        activeProgramInfo,
        currentCustomerShipToSites || [],
        currentCustomerBillToSites,
        dispatch,
        availablePrograms,
        false,
        true,
        true,
        uiSettings?.calculateCartDiscountsInBackend
      );
      dispatch(setActiveCart(updatedCart));
    } catch (e) {
      console.log(e);
    } finally {
      setDeleteIsRunning(false);
    }
  };

  const onDuplicatePiece = () => {
    if (lineItemsLimit && activeCart?.cartItems && activeCart.cartItems.length >= lineItemsLimit) {
      toast.error(COMMERCE_CORE_CONSTANTS.ERROR_MESSAGES.FAB_LINE_ITEMS_LIMIT_REACHED);
    } else {
      dispatch(setDuplicateBeingEditted(piece));
      isNested && dispatch(setParentPieceLabelOfDuplicate(parentPieceLabel));
      dispatch(showFullscreenLoader({showCartIcon: false}));
    }
  };

  if (edittingPiece && pieceBeingEditted) {
    return (
      <div className={isNested ? 'edit-piece-container' : ''}>
        <FabricationPieceForm
          productGroupCode={pieceBeingEditted.mainPiece.productGroupCode}
          editItem={pieceBeingEditted}
          initialFormValues={pieceBeingEditted}
          cancelEdit={setEdittingPiece}
          parentItemCartItemId={pieceBeingEditted.mainPiece.parentCartItemId}
          editingExistingPiece={true}
        />
      </div>
    );
  } else {
    return (
      <Col className={`fab-piece-info-display fab-piece-table no-shadow`}>
        {!piece.mainPiece.parentCartItemId && (
          <Col xs={12}>
            <Row className="header-row">
              <Col xs={3} sm={5} className="main-piece-header">
                {piece.mainPiece.pieceLabel ? piece.mainPiece.pieceLabel : 'Additional Products & Services'}
              </Col>
              <Col xs={3} sm={2} className="header-text center d-flex justify-content-center">
                LINE QUANTITY
              </Col>
              <Col xs={3} sm={2} className="header-text center d-flex justify-content-center">
                UNIT PRICE
              </Col>
              <Col xs={3} sm={2} className="header-text center d-flex justify-content-center">
                LINE PRICE
              </Col>
              <Col xs={1}></Col>
            </Row>
          </Col>
        )}
        <Col className="fab-piece-info-container">
          <Row className="main-label-row">
            <Col xs={8} sm={5} className="main-label-text">
              <span>{piece.mainPiece.pieceLabel?.toUpperCase()}</span>
              <span>
                {piece.mainPiece.productGroupCode && piece.mainPiece.pieceLabel
                  ? ` / ${piece.mainPiece.productGroupCode.toUpperCase()}`
                  : `${piece.mainPiece.productGroupCode.toUpperCase()}`}
              </span>
              <span>
                {piece.mainPiece.productApplication ? ` / ${piece.mainPiece.productApplication.toUpperCase()}` : ''}
              </span>
            </Col>
          </Row>
          <div className={`sub-table ${onCartPage ? 'tooltip-wrapper' : ''}`}>
            {onCartPage ? (
              <span className="cart-page-tooltip tooltip">Go to Fabrication tab to change</span>
            ) : (
              <span></span>
            )}
            <Row className="main-piece-design-information-row">
              <Col xs={3} sm={5} className="main-piece-information-col">
                {cutoutOrGeneralItems ? (
                  <span className="table-bold-value">{piece.mainPiece.description}</span>
                ) : (
                  <span className="table-bold-value">
                    {piece.mainPiece.designName} / {piece.mainPiece.thickness} / {piece.mainPiece.finish}
                    {piece.mainPiece.dimension3 ? '/ Seamless L' : ''}
                    {piece.mainPiece.match ? '/ ' + piece.mainPiece.match : ''}
                  </span>
                )}
                <div className="dimension-text" hidden={!piece.mainPiece.dimension1}>
                  <span className="table-secondary-value">
                    LxW {piece.mainPiece.dimension2}x{piece.mainPiece.dimension1}
                  </span>
                </div>
                <div className="dimension-text" hidden={!piece.mainPiece.dimension3}>
                  <span className="table-secondary-value">
                    LxW {piece.mainPiece.dimension4}x{piece.mainPiece.dimension3}
                  </span>
                </div>
              </Col>
              <Col xs={3} sm={2} className="main-piece-information-col table-value-currency-alignment center">
                <span
                  className="table-bold-value"
                  data-testid={'main-piece-line-quantity-' + piece.mainPiece.cartItemId}>
                  {piece.mainPiece.uomLineQuantity}&nbsp;
                </span>
                <span
                  className="table-secondary-value"
                  data-testid={'main-piece-line-quantity-uom-' + piece.mainPiece.cartItemId}>
                  {piece.mainPiece.pricingUom === 'EA'
                    ? ''
                    : piece.mainPiece.pricingUom === 'SF'
                    ? 'sqft'
                    : piece.mainPiece.pricingUom}
                </span>
              </Col>
              <Col xs={3} sm={2} className="main-piece-information-col center">
                {hasAdjustedUnitPrice ? (
                  <div className="table-value-currency-alignment">
                    <span
                      className="table-bold-value"
                      data-testid={'main-piece-adjusted-unit-price-' + piece.mainPiece.cartItemId}>
                      ${formatQuoteCurrency(piece.mainPiece.adjustedUnitPrice)}&nbsp;
                    </span>
                    <span
                      className="table-secondary-value"
                      data-testid={'main-piece-adjusted-unit-price-uom-' + piece.mainPiece.cartItemId}>
                      {piece.mainPiece.currency}{' '}
                      {piece.mainPiece.pricingUom === 'EA'
                        ? ''
                        : piece.mainPiece.pricingUom === 'SF'
                        ? '/ sqft'
                        : '/ ' + piece.mainPiece.pricingUom}
                    </span>
                    <br />
                    <span
                      className="original-price-strike-through"
                      data-testid={'main-piece-original-unit-price-' + piece.mainPiece.cartItemId}>
                      ${formatQuoteCurrency(piece.mainPiece.unitPrice)}&nbsp;
                      <span
                        className="table-secondary-value"
                        data-testid={'main-piece-original-unit-price-uom-' + piece.mainPiece.cartItemId}>
                        {piece.mainPiece.currency}{' '}
                        {piece.mainPiece.pricingUom === 'EA'
                          ? ''
                          : piece.mainPiece.pricingUom === 'SF'
                          ? '/ sqft'
                          : '/ ' + piece.mainPiece.pricingUom}
                      </span>
                    </span>
                  </div>
                ) : (
                  <div className="table-value-currency-alignment">
                    <span
                      className="table-bold-value"
                      data-testid={'main-piece-unit-price-' + piece.mainPiece.cartItemId}>
                      ${formatQuoteCurrency(piece.mainPiece.unitPrice)}&nbsp;
                    </span>
                    <span
                      className="table-secondary-value"
                      data-testid={'main-piece-unit-price-uom-' + piece.mainPiece.cartItemId}>
                      {piece.mainPiece.currency}{' '}
                      {piece.mainPiece.pricingUom === 'EA'
                        ? ''
                        : piece.mainPiece.pricingUom === 'SF'
                        ? '/ sqft'
                        : '/ ' + piece.mainPiece.pricingUom}
                    </span>
                  </div>
                )}
              </Col>
              <Col xs={3} sm={2} className="main-piece-information-col center">
                {hasAdjustedItemQuantityPrice ? (
                  <div className="table-value-currency-alignment">
                    <span className="table-bold-value">
                      ${formatQuoteCurrency(piece.mainPiece.adjusteditemQuantityPrice)}
                      &nbsp;
                    </span>
                    <span className="table-secondary-value">{piece.mainPiece.currency}</span>
                    <br />
                    <span className="original-price-strike-through">
                      ${formatQuoteCurrency(piece.mainPiece.itemQuantityPrice)}&nbsp;
                      <span className="table-secondary-value">{piece.mainPiece.currency}</span>
                    </span>
                  </div>
                ) : (
                  <div className="table-value-currency-alignment">
                    <span className="table-bold-value">
                      ${formatQuoteCurrency(piece.mainPiece.itemQuantityPrice)}&nbsp;
                    </span>
                    <span className="table-secondary-value">{piece.mainPiece.currency}</span>
                  </div>
                )}
              </Col>
              {fabOrderPage && activeCart?.transactionType !== 'QuoteCart' && (
                <Col xs={1} className="d-flex justify-content-end">
                  <Dropdown>
                    <Dropdown.Toggle className="menu-dropdown-toggle" data-testid="cart-info-dropdown-toggle">
                      <Icon icon="glyphicon glyphicon-option-vertical gi-halfx" color="black" size="16" weight="500" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="dropdown-menu-container">
                      <Dropdown.Item data-testid="duplicate-cart-item" onClick={onDuplicatePiece}>
                        Duplicate
                      </Dropdown.Item>
                      <Dropdown.Item
                        data-testid="edit-cart-item"
                        onClick={() => {
                          setEdittingPiece(true);
                          setPieceBeingEditted(piece);
                        }}>
                        Edit
                      </Dropdown.Item>
                      <Dropdown.Item data-testid="delete-cart-item" onClick={toggleShow}>
                        Delete
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                  {showDeleteModal && (
                    <CambriaModal
                      hideExitIcon
                      formName="delete-item-form"
                      heading="Delete Item from Cart"
                      cancelButton="CANCEL"
                      confirmButton="CONTINUE"
                      toggleShow={toggleShow}
                      show={showDeleteModal}
                      onSubmitFunctionIsRunning={deleteIsRunning}>
                      <Form
                        id="delete-item-form"
                        onSubmit={async (e) => {
                          e.preventDefault();
                          await callDeleteCartItem(piece);
                          setShowDeleteModal(false);
                          document.body.classList.remove('disable-scroll');
                        }}>
                        Removing this item will also remove all of it's child pieces. Do you wish to continue?
                      </Form>
                    </CambriaModal>
                  )}
                </Col>
              )}
            </Row>
            {piece.mainPiece.productGroupCode !== 'GeneralItems' &&
              piece.mainEdgeProfile.length === 0 &&
              !onCartPage && <div className="m-4 text-danger">Please add at least one edge Profile</div>}
            {piece.mainPiece.productGroupCode !== 'GeneralItems' &&
              piece.mainEdgeProfile.length === 0 &&
              onCartPage && (
                <div
                  className="m-4 text-danger"
                  onClick={() => history.push(COMMERCE_CORE_CONSTANTS.PATHNAMES.fabrication)}
                  role="button">
                  Piece '{piece.mainPiece.pieceLabel}' needs at least 1 edge profile. Click here to go to fabrication
                  order entry screen.
                </div>
              )}
            {piece.mainEdgeProfile.length > 0 && (
              <Row className="main-piece-design-information-row">
                <Col xs={5} className="main-piece-information-col">
                  <span className="table-bold-value">Edge Profiles:</span>
                </Col>
              </Row>
            )}
            {piece.mainEdgeProfile.map((edgeProfile, index) => {
              return (
                <Row key={index} className="main-piece-design-information-row">
                  <Col xs={8} sm={5} className="main-piece-information-col">
                    <div className="dimension-text">
                      <span className="table-secondary-value">
                        {edgeProfile.edgeProfileName} ({edgeProfile.edgeProfileCode}) - {edgeProfile.thickness}
                      </span>
                    </div>
                  </Col>
                  <Col xs={3} sm={2} className="main-piece-information-col center">
                    {edgeProfile.unitPrice ? (
                      <div className="table-value-currency-alignment">
                        <span className="table-bold-value">{edgeProfile.uomLineQuantity}</span>
                        <span className="table-secondary-value">LF</span>
                      </div>
                    ) : (
                      <div className="table-value-currency-alignment">
                        <span className="table-bold-value">N/A</span>
                      </div>
                    )}
                  </Col>
                  <Col xs={3} sm={2} className="main-piece-information-col center">
                    {edgeProfile.hasAdjustedUnitPrice ? (
                      <div className="table-value-currency-alignment">
                        <span className="table-bold-value">
                          ${formatQuoteCurrency(edgeProfile.adjustedUnitPrice)}&nbsp;
                        </span>
                        <span className="table-secondary-value">{edgeProfile.currency} / LF</span>
                        <br />
                        <span className="original-price-strike-through">
                          ${formatQuoteCurrency(edgeProfile.unitPrice)}&nbsp;
                          <span className="table-secondary-value">{edgeProfile.currency} / LF</span>
                        </span>
                      </div>
                    ) : (
                      <div className="table-value-currency-alignment">
                        <span className="table-bold-value">${formatQuoteCurrency(edgeProfile.unitPrice)}&nbsp;</span>
                        <span className="table-secondary-value">{edgeProfile.currency} / LF</span>
                      </div>
                    )}
                  </Col>
                  <Col xs={3} sm={2} className="main-piece-information-col center">
                    {edgeProfile.hasAdjusteditemQuantityPrice ? (
                      <div className="table-value-currency-alignment">
                        <span className="table-bold-value">
                          ${formatQuoteCurrency(edgeProfile.adjusteditemQuantityPrice)}&nbsp;
                        </span>
                        <span className="table-secondary-value">{edgeProfile.currency}</span>
                        <br />
                        <span className="original-price-strike-through">
                          ${formatQuoteCurrency(edgeProfile.itemQuantityPrice)}&nbsp;
                          <span className="table-secondary-value">{edgeProfile.currency}</span>
                        </span>
                      </div>
                    ) : (
                      <div className="table-value-currency-alignment">
                        <span className="table-bold-value">
                          ${formatQuoteCurrency(edgeProfile.itemQuantityPrice)}&nbsp;
                        </span>
                        <span className="table-secondary-value">{edgeProfile.currency}</span>
                      </div>
                    )}
                  </Col>
                </Row>
              );
            })}
          </div>
          {piece.mainPieceChildItems && piece.mainPieceChildItems.length > 0 ? (
            piece.mainPieceChildItems.map((childItem, index) => {
              return (
                <CambriaFabricationPieceCartInfo
                  key={index}
                  piece={childItem}
                  cart={cart}
                  isNested={true}
                  fabOrderPage={fabOrderPage}
                  parentPieceLabel={piece.mainPiece.pieceLabel}
                />
              );
            })
          ) : (
            <></>
          )}
        </Col>
      </Col>
    );
  }
};

export default CambriaFabricationPieceCartInfo;
