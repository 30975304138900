import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import type {RootState} from '../../../store/store';
import {IPostLoginState} from '../IPostLoginState';

export const initialState: IPostLoginState = {
  postLoginState: null,
  isPostLoginStateActive: true,
  previousUser: '',
};

export const postLoginStateSlice = createSlice({
  name: 'postLoginState',
  initialState,
  reducers: {
    setPostLoginState: (state, action: PayloadAction<any>) => {
      state.postLoginState = action.payload;
    },
    setIsPostLoginStateActive: (state, action: PayloadAction<any>) => {
      state.isPostLoginStateActive = action.payload;
    },
    setPreviousUser: (state, action: PayloadAction<any>) => {
      state.previousUser = action.payload;
    },
  },
  extraReducers: {},
});

export const {setPostLoginState, setIsPostLoginStateActive, setPreviousUser} = postLoginStateSlice.actions;

export const selectPostLoginState = (state: RootState) => state.postLoginState.postLoginState;
export const selectIsPostLoginStateActive = (state: RootState) => state.postLoginState.isPostLoginStateActive;
export const selectPreviousUser = (state: RootState) => state.postLoginState.previousUser;

export default postLoginStateSlice.reducer;
