import React, {useEffect, useState} from 'react';
import {Col, Row} from 'react-bootstrap';
import {EndConsumer, PurchasingCustomer} from '../../../../../../features/cart/ICartState';
import Icon from '../../../../../../Framework/Components/Icon';
import DealerEndUserContainer from './DealerEndUser.styled';
import {formatPhoneNumber} from '../../../../../../features/customer/service/customer.service';
import {IOrderDetails} from '../../../../../../features/order/orderDetails/IOrderDetailsState';

const DealerEndUser = ({
  orderDetails,
  mappedPurchasingCustomers,
}: {
  orderDetails: IOrderDetails;
  mappedPurchasingCustomers?: PurchasingCustomer[];
}) => {
  const [purchasingCustomers, setPurchasingCustomers] = useState<PurchasingCustomer[]>([]);

  useEffect(() => {
    if (mappedPurchasingCustomers) {
      setPurchasingCustomers(mappedPurchasingCustomers ?? []);
    } else {
      setPurchasingCustomers(orderDetails.pointOfSales ?? []);
    }
  }, [orderDetails, mappedPurchasingCustomers]);

  return (
    <DealerEndUserContainer>
      <h2>Purchasing Customers & Consumers</h2>
      <div hidden={purchasingCustomers.length > 0}>No Purchasing Customer Information</div>
      <div hidden={purchasingCustomers.length === 0}>
        {purchasingCustomers.map((point: PurchasingCustomer, index: number) => {
          return (
            <div className="purchasing-customer-card" key={index}>
              <Row>
                <Col className="text-center">
                  <span className="purchasing-customer-site-name">{point.siteName}</span>
                  <div hidden={!point.isUpdatePending} className="isUpdatePending">
                    <Icon icon="fas fa-exclamation-triangle" color="red" size="16" weight="600" />
                    <span className="update-pending-text">
                      This purchasing customer has new or updated information. Click the 'Save' button to finalize.
                    </span>
                  </div>
                </Col>
              </Row>
              <div className="d-flex flex-wrap justify-content-center customer-info">
                {point.contactFirstName || point.contactLastName ? (
                  <span className="customer-info-overflow">
                    {point.contactFirstName} {point.contactLastName}
                  </span>
                ) : (
                  <span></span>
                )}
                <span className="customer-info-overflow">
                  {point.contactFirstName || point.contactLastName ? (
                    <span>&nbsp;&nbsp;&bull;&nbsp;&nbsp;</span>
                  ) : (
                    <span></span>
                  )}
                  {point.address1}
                  {point.address2 && <span>,&nbsp;</span>}
                  {point.address2}
                  {point.address3 && <span>,&nbsp;</span>}
                  {point.address3}
                </span>
                <span className="customer-info-overflow">
                  &nbsp;&nbsp;&bull;&nbsp;&nbsp;
                  {point.city}, {point.stateProvinceCode}
                  {point.postalCode}
                </span>
                {point.contactEmail && (
                  <span className="customer-info-overflow">
                    &nbsp;&nbsp;&bull;&nbsp;&nbsp;
                    <a href={`mailto:${point.contactEmail}`}>{point.contactEmail}</a>
                  </span>
                )}
                {point.contactPhoneNumber && (
                  <>
                    <span>&nbsp;&nbsp;&bull;&nbsp;&nbsp;</span>
                    <a href={`tel:${point.contactPhoneNumber}`}>{formatPhoneNumber(point.contactPhoneNumber)}</a>
                  </>
                )}
              </div>
              {/* <Row className="d-flex justify-content-center">
                {point.contactFirstName || point.contactLastName ? (
                  <Col xs={2} className="text-center">
                    {point.contactFirstName} {point.contactLastName}
                  </Col>
                ) : (
                  <></>
                )}
                {point.address1 ? (
                  <Col xs={3} className="text-center">
                    {point.address1}
                  </Col>
                ) : (
                  <></>
                )}
                {point.city ? (
                  <Col xs={3} className="text-center">
                    {point.city}, {point.stateProvinceCode} {point.postalCode}
                  </Col>
                ) : (
                  <></>
                )}
                {point.contactEmail ? (
                  <Col xs={2} className="text-center purchasing-customer-card-email">
                    {point.contactEmail}
                  </Col>
                ) : (
                  <></>
                )}
                {point.contactPhoneNumber ? (
                  <Col xs={2} className="text-center">
                    {formatPhoneNumber(point.contactPhoneNumber)}
                  </Col>
                ) : (
                  <></>
                )}
              </Row> */}
              <Row>
                {point.endConsumers.map((consumer: EndConsumer, index: number) => {
                  return (
                    <Col xs={12} sm={12} md={6} lg={4} key={index}>
                      <div className="consumer-card">
                        <Row className="consumer-name-row">
                          <Col>
                            <span>
                              {consumer.contactFirstName} {consumer.contactLastName}
                            </span>
                          </Col>
                        </Row>
                        <Row className="consumer-address-row">
                          <Col>
                            <div>
                              {consumer.address1} {consumer.address2} {consumer.address3}
                            </div>

                            <div>
                              {consumer.city}, {consumer.stateProvinceCode} {consumer.postalCode}
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div className="contact-line-item d-flex align-items-center">
                              <Icon
                                icon="icons-cambria-Symbols-Mail"
                                size="28"
                                weight="900"
                                color="black"
                                colorOnHover="black"
                              />
                              <span className="contact-line-item-text-overflow">{consumer.contactEmail}</span>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div className="contact-line-item d-flex align-items-center">
                              <Icon
                                icon="icons-cambria-Ui-Phone"
                                size="28"
                                weight="900"
                                color="black"
                                colorOnHover="black"
                              />
                              <span>{consumer.contactPhoneNumber}</span>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  );
                })}
              </Row>
            </div>
          );
        })}
      </div>
    </DealerEndUserContainer>
  );
};

export default DealerEndUser;
