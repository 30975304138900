import React, {FC, useState} from 'react';
import {Form, Formik} from 'formik';
import CambriaModal from '../../../../Framework/Components/CambriaModal';
import {useAppDispatch} from '../../../../hooks/store';
import {deleteCreditCard} from '../../../../features/payment/slice/payment.slice';
import {toast} from 'react-toastify';

interface DeleteCreditCardModalProps {
  card?: any;
  show?: boolean;
  toggleShow?: any;
}

const DeleteCreditCardModal: FC<DeleteCreditCardModalProps> = ({card, show, toggleShow}) => {
  const dispatch = useAppDispatch();

  const [deleteCardInProgress, setDeleteCardInProgress] = useState<boolean>(false);

  const modalOptions = {
    title: 'Delete Payment Method',
    cancelButtonText: 'CANCEL',
    confirmButtonText: 'CONFIRM DELETE',
    formName: 'deleteCreditCardForm',
  };

  const handleDeleteCreditCard = async () => {
    setDeleteCardInProgress(true);
    try {
      await dispatch(deleteCreditCard(card.token));
      toast.success('Card was successfully removed.');
    } catch (error) {
      toast.error('Card removal failed. Please contact Cambria Customer Care for further assistance.');
    } finally {
      setDeleteCardInProgress(false);
      show && toggleShow();
    }
  };

  return (
    <Formik enableReinitialize initialValues={{}} onSubmit={() => handleDeleteCreditCard()}>
      {(props) => {
        return (
          <Form id={modalOptions.formName} onSubmit={props.handleSubmit} noValidate>
            <CambriaModal
              show={show}
              toggleShow={toggleShow}
              disableSubmitBtn={deleteCardInProgress}
              heading={modalOptions.title}
              cancelButton={modalOptions.cancelButtonText}
              confirmButton={modalOptions.confirmButtonText}
              formName={modalOptions.formName}>
              <h3>
                {card?.cardType} ending in {card?.lastFour}
              </h3>
              <span>
                If you do not want this payment method to be displayed in your list of payment options, click "Confirm
                delete". (Disabling this payment method will not cancel any of your open orders that use this method.)
              </span>
            </CambriaModal>
          </Form>
        );
      }}
    </Formik>
  );
};

export default DeleteCreditCardModal;
