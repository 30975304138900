import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import qs from 'qs';
import COMMERCE_CORE_CONSTANTS from '../../../Core/constants';
import {baseApiCallWithReauth} from '../../../Framework/api-utils/api-utils';
import {createPurchaseAgreement} from '../service/file.service';
import {RootState} from '../../../store/store';
import {fileSliceInitialState, IFileRequest} from '../IFileState';
import {toast} from 'react-toastify';
import {hideFullscreenLoader} from '../../fullscreenLoader/slice/fullscreenLoader.slice';
import {EndConsumer} from '../../cart/ICartState';
import {PurchaseAgreementBillToSite} from '../../customer/ICustomerState';
import {WarehouseFullData} from '../../warehouse/IWarehouseState';

export const initialState: fileSliceInitialState = {
  uploadedFiles: [],
};

export const getUploadedFilesAsync = createAsyncThunk(
  'file/getUploadedFilesAsync',
  async ({params}: {params: IFileRequest}) => {
    const endpoint = COMMERCE_CORE_CONSTANTS.API_SERVICES.FILE.files;
    return await baseApiCallWithReauth('GET', endpoint, qs.stringify(params));
  }
);

export const startCreatePurchaseAgreementProcess = createAsyncThunk(
  'file/startCreatePurchaseAgreementProcess',
  async (
    {
      erpCustomerId,
      cartId,
      endConsumer,
      warehouse,
      billToSite,
      shipToAddress,
      purchasingCustomerOrderNumber,
    }: {
      erpCustomerId: string;
      cartId: string;
      endConsumer: EndConsumer;
      warehouse: WarehouseFullData;
      billToSite: PurchaseAgreementBillToSite;
      shipToAddress: any;
      purchasingCustomerOrderNumber?: string;
    },
    {rejectWithValue, dispatch}
  ) => {
    try {
      return await createPurchaseAgreement(
        erpCustomerId,
        cartId,
        endConsumer,
        warehouse,
        billToSite,
        shipToAddress,
        dispatch,
        purchasingCustomerOrderNumber
      );
    } catch (error: any) {
      dispatch(hideFullscreenLoader());
      toast.error(error);
      return rejectWithValue(null);
    }
  }
);

export const fileSlice = createSlice({
  name: 'file',
  initialState,
  reducers: {
    setFileInitialState: (state: any) => {
      for (const key in initialState) {
        state[key] = (initialState as any)[key];
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUploadedFilesAsync.fulfilled, (state, action) => {
      state.uploadedFiles = action.payload.results;
    });
    builder.addCase(startCreatePurchaseAgreementProcess.pending, (state: any) => {
      state.isCreatingPurchaseAgreement = true;
    });
    builder.addCase(startCreatePurchaseAgreementProcess.fulfilled, (state: any) => {
      state.isCreatingPurchaseAgreement = false;
    });
    builder.addCase(startCreatePurchaseAgreementProcess.rejected, (state: any) => {
      state.isCreatingPurchaseAgreement = false;
    });
  },
});

export const {setFileInitialState} = fileSlice.actions;

export const selectUploadedFiles = (state: RootState) => state.file.uploadedFiles;
export default fileSlice.reducer;
