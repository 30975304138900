import {Cart} from '../../cart/ICartState';
import {CustomerFullProfile} from '../../customer/ICustomerState';
import {ProductFilter} from '../../search/ISearchState';
import {IProductSearchFilter} from '../../search/product/IProductSearchState';
import {IFabricatedProductGroupsResponse} from '../IProductTypeState';
import {getFabricatedProductsRequest} from '../controller/productType.controller';

export const isFabricatedCart = (cart: Cart): boolean => {
  return cart && cart.cartItems && cart.cartItems.length > 0
    ? cart.cartItems.some(
        (x) =>
          x.operatingUnitCode.toLocaleLowerCase().startsWith('fab_') &&
          x.productType !== 'Samples' &&
          x.productType !== 'Slab'
      )
    : false;
};

export const isSurfacesCart = (cart: Cart): boolean => {
  return cart && cart.cartItems && cart.cartItems.length > 0
    ? cart.cartItems.some((x) => x.operatingUnitCode.toLocaleLowerCase().startsWith('sur_'))
    : false;
};

export const getCartItemsProductType = (selectedCart: Cart): any => {
  let selectedProductType = null;
  if (hasCartItems(selectedCart)) {
    const isFabCart = isFabricatedCart(selectedCart);
    const isSurfaceCart = isSurfacesCart(selectedCart);

    switch (true) {
      case isSurfaceCart:
        selectedProductType = 'Surfaces';
        break;
      case isFabCart:
        selectedProductType = 'Fabricated';
        break;
      default:
        selectedProductType = selectedCart.cartItems[0].productType;
        break;
    }
  }
  return selectedProductType;
};

export const hasCartItems = (selectedCart: Cart): boolean => {
  return selectedCart && selectedCart.cartItems && selectedCart.cartItems.length > 0;
};

export const getAllFabricatedProductGroups = async (
  currentCustomer: CustomerFullProfile
): Promise<Array<IFabricatedProductGroupsResponse>> => {
  let response = await getFabricatedProductsRequest(currentCustomer);
  return response.results;
};

export const findFilterMatch = (filters: IProductSearchFilter[], filterName: string) => {
  for (let item of filters) {
    if (item.name === filterName) {
      let selection: ProductFilter = {
        param: item.param,
        mobileName: item.mobileSelected,
        name: item.name,
        urn: item.urn,
      };
      return selection;
    }
  }
  return '';
};

export const setMenuOptions = (selectedFilter: string, filterList: IProductSearchFilter[]) => {
  let filterMenu = [];
  for (let item of filterList) {
    if (item.name !== selectedFilter) {
      filterMenu.push(item);
    }
  }
  return filterMenu;
};
