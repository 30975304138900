import styled from 'styled-components/macro';
import * as colors from '../../../../../Framework/Components/styles/colors';

export const CustomerHeaderDropdownContent = styled.div`
  .dropdown-content-section {
    margin-bottom: 15px;

    .dropdown-content-section-title
      display: block;
      font-weight: bolder;
      color: #333;
    }

    .dropdown-content-section-content {
      display: block;
      margin-bottom: -8px;
      font-weight: 400;
      color: ${colors.black};
    }

    .primary-button {
        min-width: 300px;
        border-radius: 3px;
        border: 1px solid ${colors.primary};
        background: ${colors.primary};
        color: ${colors.base};
        padding: 10px;
        text-align: center;
        vertical-align: middle;
        touch-action: manipulation;
        width: 100%;
        cursor: pointer;
        
        &:hover {
          background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), ${colors.primary};
        }
        
        a {
          text-transform: uppercase;
          color: ${colors.base} !important;
        }

        span {            
            font-size: 14px;
            line-height: 26px;
            text-transform: uppercase;
            padding: 10px;
            font-weight: 600;
        }
    }

    .secondary-button {
        min-width: 300px;
        border-radius: 3px;
        border: 1px solid ${colors.primary};
        background: ${colors.base};
        color: ${colors.primary};
        padding: 10px;
        text-align: center;
        vertical-align: middle;
        touch-action: manipulation;
        width: 100%;
        cursor: pointer;

        &:hover {
            background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), ${colors.primary};
            a {
              color: ${colors.base} !important;
            }
        }

        a {
          color: ${colors.primary} !important;
        }

        span {            
            font-size: 14px;
            line-height: 26px;
            text-transform: uppercase;
            padding: 10px;
            font-weight: 600;
        }
    }

    .program {
      &.active {
        label {
          color: ${colors.primary};
        }

        input {
          border: 2px solid ${colors.base} !important;
          background-color: ${colors.primary}!important;
          outline: 2px solid ${colors.primary}!important;
          &:focus {
            outline-offset: 0;
        }
        }
      }

      label {
        margin-left: 5px;
        margin-bottom: 0 !important;
        vertical-align: middle;
        cursor: pointer;
      }

      input {
        border: 2px solid ${colors.black} !important;
        background: ${colors.base} !important;
        height: 15px;
        width: 15px;
        padding: 0;
        margin: 0;
        border-radius: 0;
        vertical-align: middle;
        -webkit-appearance: none;
        cursor: pointer;

      }
    }
  }
`;
