import styled from 'styled-components';
import {$gray30, $gray50, $gray60, $gray93, $newwhite} from '../../../Styled/variables';

const OrderSummaryContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
  .order-summary-heading {
    font-weight: 600 !important;
    color: ${$gray30} !important;
    font-size: 22px;
  }
  .item-number {
    font-weight: 600;
    color: ${$gray50};
  }
  .before-tax-text {
    color: ${$gray60};
    font-size: 10px;
    font-weight: 600;
  }
  .before-tax-total {
    color: ${$gray30};
    font-weight: 700;
  }
  .shipping-and-handling-msg {
    font-size: 12px;
    color: ${$gray30};
    background-color: ${$gray93};
    padding: 12px;
    border-radius: 3px;
  }
  .order-total-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 15px;
    background: ${$gray30};
    color: white;
    margin-top: 12px;
    font-weight: 500;
    border-radius: 3px;

    .total {
      font-weight: 700;
    }
  }
  .back-button {
    width: 100%;
    margin-top: 12px;
    background-color: ${$newwhite};
    border: 2px solid #d8d8d8b0;
    color: ${$gray60};
    padding: 6px;
    font-size: 12px;
    :hover {
      background-color: #e6e6e6;
    }
    i {
      margin-right: 5px;
    }
  }
  .discounts-section {
    text-align: right;
    width: 100%;
    padding: 2px 0px;
    font-size: 14px;
    font-weight: 700;
    .discount-code-text {
      font-size: 12px;
      color: ${$gray60};
      font-weight: 600;
    }
    .discount-amount {
      color: ${$gray30};
      font-size: 12px;
    }
  }
  .before-tax-text {
    font-size: 12px !important;
  }
`;

export default OrderSummaryContainer;
