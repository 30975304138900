import React, {useState} from 'react';
import {Dropdown} from 'react-bootstrap';
import {useHistory} from 'react-router-dom';
import {QuoteSearchParams} from '../../../../../../../features/search/ISearchState';
import {addQuoteStringInput} from '../../../../../../../features/quote/service/quote.service';
import {cleanSearchParams} from '../../../../../../../features/order/service/order.service';

const DropdownInput = ({
  options,
  urlSearchParams,
  activeFilter,
  url,
}: {
  options: string[];
  urlSearchParams: QuoteSearchParams;
  activeFilter: string;
  url: string;
}) => {
  const history = useHistory();

  let [selectedQuoteStatus, setQuoteStatus] = useState(options[0]);

  const handleDropdownSelection = (filter: string, selection: string) => {
    if (filter === 'Status') {
      setQuoteStatus(selection);
    }
  };

  const addDropdownInputParam = (currentReduxFilters: QuoteSearchParams, paramName: string, inputValue: string) => {
    let currentReduxCopy = {...currentReduxFilters};
    currentReduxCopy = addQuoteStringInput(paramName, currentReduxCopy, inputValue);

    let data = new URLSearchParams(currentReduxCopy);
    data = cleanSearchParams(data);
    history.push({
      pathname: url,
      search: '?' + data.toString(),
    });
  };

  return (
    <div className="input-and-button-container">
      <Dropdown style={{width: '100%'}}>
        <Dropdown.Toggle id="dropdown" className="text-left secondary-dropdown-toggle">
          {selectedQuoteStatus}
        </Dropdown.Toggle>
        <Dropdown.Menu className="secondary-menu">
          {options.map((option: string) => (
            <Dropdown.Item
              key={option}
              data-testid={`select-${option}`}
              onClick={() => {
                handleDropdownSelection(activeFilter, option);
              }}>
              {option}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
      <div className="search-button-container">
        <button
          className="search-button btn-primary"
          onClick={() => addDropdownInputParam(urlSearchParams, activeFilter, selectedQuoteStatus)}>
          SEARCH
        </button>
      </div>
    </div>
  );
};
export default DropdownInput;
