import React, {useEffect, useState} from 'react';
import {Col, Row} from 'react-bootstrap';
import {toast} from 'react-toastify';
import {selectCurrentUser} from '../../../features/auth/slice/authentication.slice';
import {deleteCartVoucher, setDiscountsData} from '../../../features/cart/service/cart.service';
import {selectActiveCart} from '../../../features/cart/slice/cart.slice';
import {
  selectCurrentCustomer,
  selectCurrentCustomerBillToSites,
  selectCurrentCustomerShipToSites,
} from '../../../features/customer/slice/customer.slice';
import {
  hideFullscreenLoader,
  showFullscreenLoader,
} from '../../../features/fullscreenLoader/slice/fullscreenLoader.slice';
import {selectActiveProgram, selectAvailablePrograms} from '../../../features/salesforce/slice/salesforce.slice';
import CambriaButton from '../../../Framework/Components/CambriaButton';
import Icon from '../../../Framework/Components/Icon';
import {formatPrice} from '../../../Framework/Services/formatPrice.service';
import {useAppDispatch, useTypedSelector} from '../../../hooks/store';
import DiscountContainer from './Discount.styled';
import {VoucherCode} from '../../../features/cart/ICartState';
import {ISalesforceAvailablePrograms} from '../../../features/salesforce/ISalesforceState';

const Discount = () => {
  const dispatch = useAppDispatch();

  let cart: any = useTypedSelector(selectActiveCart);
  let customer = useTypedSelector(selectCurrentCustomer);
  let currentUser: any = useTypedSelector(selectCurrentUser);
  let activeProgram = useTypedSelector(selectActiveProgram);
  let shipToSites: any = useTypedSelector(selectCurrentCustomerShipToSites);
  let billToSites: any = useTypedSelector(selectCurrentCustomerBillToSites);
  const availablePrograms: ISalesforceAvailablePrograms | null = useTypedSelector(selectAvailablePrograms);

  let [cartVoucherCodes, setCartVoucherCodes] = useState([]);
  let [discounts, setDiscounts] = useState<VoucherCode[]>([]);

  const deleteVoucherCode = async (validationId: string) => {
    dispatch(showFullscreenLoader({showCartIcon: true}));
    const matchedVoucher = discounts.find((d: any) => d.validationId === validationId);
    if (matchedVoucher) {
      const voucherDesc = JSON.parse(JSON.stringify(matchedVoucher.description));
      try {
        await deleteCartVoucher(
          cart,
          validationId,
          customer,
          currentUser.userId,
          activeProgram,
          shipToSites,
          billToSites,
          dispatch,
          availablePrograms
        );
        toast.success(`${voucherDesc} discount was removed.`);
      } catch (e: any) {
        if (e.timeout) {
          e.failureReason = 'Took too long for the server to respond. Try again later.';
        } else if (!e.failureReason) {
          e.failureReason = 'Failed to remove discount.';
        }
        console.log(e);
        toast.error(e.failureReason);
      } finally {
        dispatch(hideFullscreenLoader());
      }
    }
    dispatch(hideFullscreenLoader());
  };

  useEffect(() => {
    if (
      (cart &&
        cart.voucherCodes &&
        cart.voucherCodes.length > 0 &&
        cart.voucherCodes !== cartVoucherCodes &&
        cart.voucherCodes.length !== discounts.length) ||
      (cart && cart.voucherCodes && cart.voucherCodes.length === 0 && discounts.length > 0)
    ) {
      setCartVoucherCodes(cart.voucherCodes);
      const results = setDiscountsData(cart);
      setDiscounts(results);
    }
  }, [discounts, cart, cartVoucherCodes]);

  return (
    <DiscountContainer className="col-12 p-x-0">
      {discounts && discounts.length > 0 ? (
        <div className="discounts-box panel step">
          <h1 className="discount-header-text">Discounts</h1>
          {discounts.map((discount: VoucherCode, index: number) => {
            return (
              <Row key={`discount-item-${index}`} className="discount-item">
                <Col xs={12} md={8}>
                  <div className="m-b-0 small dicount-description-text">{discount.description.toUpperCase()}</div>
                  <div className="small">DISCOUNT AMOUNT: {formatPrice(discount.adjustmentAmount)}</div>
                </Col>
                {discount.removable ? (
                  <Col xs={12} md={4} className="pull-right">
                    <CambriaButton
                      className="btn btn-block btn-default ripple remove-discount-button"
                      onClick={() => {
                        deleteVoucherCode(discount.validationId);
                      }}>
                      <Icon icon="icon icons-cambria-Ui-Multiply" color="#eee" size="15" weight="bold" />
                      REMOVE DISCOUNT
                    </CambriaButton>
                  </Col>
                ) : (
                  <div></div>
                )}
              </Row>
            );
          })}
        </div>
      ) : (
        <div></div>
      )}
    </DiscountContainer>
  );
};

export default Discount;
