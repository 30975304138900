import styled from 'styled-components/macro';
import {$newinactivebackground, $screenmd} from '../../../Styled/variables';

export const QuoteConfirmationPage = styled.div`
  padding-bottom: 100px;

  .quote-confirmation {
    margin-bottom: 0;

    .quote-confirmation-header {
      font-weight: 300;
      margin-bottom: 20px;
    }

    .quote-confirmation-project-name-text {
      font-weight: 600;
    }

    .quote-confirmation-project-text-button {
      font-size: 16px;
      margin: 0px 5px 1px;
    }

    .quote-confirmation-alerts-container {
      margin-top: 20px;
    }

    .step {
      background-color: ${$newinactivebackground};
      border: none !important;
      padding: 30px;
    }

    .row {
      padding: 15px;
    }
  }

  .email-input {
    width: 300px;
  }

  .container.checkout.confirm {
    position: relative;
    top: -100px;

    @media (max-width: ${$screenmd}) {
      top: -80px;
    }
  }

  .new-style {
    .container.checkout.confirm {
      top: 0;

      @media (max-width: ${$screenmd}) {
        top: 80px;
      }
    }
  }
`;
