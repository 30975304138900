import React, {useState} from 'react';
import {Col, Row} from 'react-bootstrap';
import {IEditConsumerData, IEndConsumerSearchResult} from '../../../../../../../features/customer/ICustomerState';
import {selectFabRetailSiteId} from '../../../../../../../features/fabrication/slice/fabrication.slice';
import {useTypedSelector} from '../../../../../../../hooks/store';
import AddEndUserForm from '../AddEndUserForm';

const EndUserSearchResultsTable = ({endUserResults}: {endUserResults: IEndConsumerSearchResult[]}) => {
  const [editEndUserInformation, setEditEndUserInformation] = useState<IEditConsumerData>();
  const [showEndUserForm, setShowEndUserForm] = useState<boolean>(false);

  const retailSiteId = useTypedSelector(selectFabRetailSiteId);

  const toggleShow = () => {
    setShowEndUserForm(!showEndUserForm);
    if (showEndUserForm) {
      document.body.classList.remove('disable-scroll');
    }
  };

  const setEditInformation = (consumer: IEndConsumerSearchResult) => {
    const editInfo: IEditConsumerData = {
      firstName: '',
      lastName: '',
      businessName: consumer.companyName || '',
      country: consumer.countryCode,
      address1: consumer.address1,
      address2: consumer.address2 || '',
      address3: consumer.address3 || '',
      city: consumer.city || '',
      state: consumer.stateProvinceCode || '',
      zipCode: consumer.postalCode || '',
      endUserPhone: consumer.contactPhoneNumber || '',
      endUserEmail: consumer.contactEmail || '',
      jobNumber: consumer.fabricationJobNumber || '',
      isForFabOrder: true,
      id: 0,
    };
    setEditEndUserInformation(editInfo);
  };

  return (
    <div className="p-3 table-container mb-2">
      <Row className="m-0 table-headers">
        <Col xs={5} className="p-4">
          Address
        </Col>
        <Col xs={3} className="p-4">
          Fab Job #
        </Col>
        <Col xs={4} className="p-4">
          Order #
        </Col>
      </Row>
      <div className="search-results-table">
        {endUserResults.map((endUser, index) => {
          return (
            <Row
              data-testid={`end-user-search-result-${index}`}
              className="m-0 table-row"
              key={index}
              onClick={() => {
                setEditInformation(endUser);
                toggleShow();
              }}>
              <Col xs={5} className="p-4 table-cell">
                {endUser.address1}
                <br />
                {endUser.city}, {endUser.stateProvinceCode} {endUser.postalCode}
                <br /> {endUser.countryCode}
              </Col>
              <Col xs={3} className="p-4 table-cell">
                {endUser.fabricationJobNumber}
              </Col>
              <Col xs={4} className="p-4 table-cell">
                {endUser.orderNumber}
              </Col>
            </Row>
          );
        })}
      </div>
      {showEndUserForm && (
        <AddEndUserForm
          toggleShow={toggleShow}
          show={showEndUserForm}
          retailSiteId={retailSiteId ? retailSiteId : 0}
          editInformation={editEndUserInformation}
          editMode={false}
        />
      )}
    </div>
  );
};

export default EndUserSearchResultsTable;
