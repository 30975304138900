import React from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import ContentLoader from 'react-content-loader';
import CheckoutPlaceholderContainer from './CheckoutPlaceholders.styled';

const CheckoutPlaceholders = () => {
  return (
    <CheckoutPlaceholderContainer>
      <Container>
        <Row>
          <Col xs={3} className="placeholder-overflow-class">
            <ContentLoader
              width={400}
              height={30}
              animate={true}
              foregroundColor={'#dfdfdf'}
              backgroundColor={'#dfdfdf'}>
              <rect x="0" y="0" width="400" height="30" />
            </ContentLoader>
          </Col>
          <Col xs={3} className="placeholder-overflow-class">
            <ContentLoader
              width={400}
              height={30}
              animate={true}
              foregroundColor={'#dfdfdf'}
              backgroundColor={'#dfdfdf'}>
              <rect x="0" y="0" width="400" height="30" />
            </ContentLoader>
          </Col>
          <Col xs={3} className="placeholder-overflow-class">
            <ContentLoader
              width={400}
              height={30}
              animate={true}
              foregroundColor={'#dfdfdf'}
              backgroundColor={'#dfdfdf'}>
              <rect x="0" y="0" width="400" height="30" />
            </ContentLoader>
          </Col>
          <Col xs={3} className="placeholder-overflow-class">
            <ContentLoader
              width={400}
              height={30}
              animate={true}
              foregroundColor={'#dfdfdf'}
              backgroundColor={'#dfdfdf'}>
              <rect x="0" y="0" width="400" height="30" />
            </ContentLoader>
          </Col>
        </Row>
        <br></br>
        <br></br>
        <Row>
          <Col className="placeholder-overflow-class">
            <ContentLoader
              width={250}
              height={45}
              animate={true}
              foregroundColor={'#dfdfdf'}
              backgroundColor={'#dfdfdf'}>
              <rect x="0" y="0" width="250" height="45" />
            </ContentLoader>
          </Col>
        </Row>
        <Row>
          <Col className="placeholder-overflow-class placeholder-top-margin" lg={6}>
            <Col className="placeholder-overflow-class placeholder-bottom-margin p-x-0" lg={12}>
              <ContentLoader
                width={150}
                height={20}
                animate={true}
                foregroundColor={'#dfdfdf'}
                backgroundColor={'#dfdfdf'}>
                <rect x="0" y="0" width="150" height="20" />
              </ContentLoader>
            </Col>
            <br></br>
            <Col className="placeholder-overflow-class p-x-0" lg={12}>
              <ContentLoader
                width={700}
                height={35}
                animate={true}
                foregroundColor={'#dfdfdf'}
                backgroundColor={'#dfdfdf'}>
                <rect x="0" y="0" width="700" height="35" />
              </ContentLoader>
            </Col>
          </Col>
          <Col className="placeholder-overflow-class placeholder-top-margin" lg={6}>
            <Col className="placeholder-overflow-class placeholder-bottom-margin p-x-0" lg={12}>
              <ContentLoader
                width={150}
                height={20}
                animate={true}
                foregroundColor={'#dfdfdf'}
                backgroundColor={'#dfdfdf'}>
                <rect x="0" y="0" width="150" height="20" />
              </ContentLoader>
            </Col>
            <br></br>
            <Col className="placeholder-overflow-class p-x-0" lg={12}>
              <ContentLoader
                width={700}
                height={35}
                animate={true}
                foregroundColor={'#dfdfdf'}
                backgroundColor={'#dfdfdf'}>
                <rect x="0" y="0" width="700" height="35" />
              </ContentLoader>
            </Col>
          </Col>
        </Row>
        <Row>
          <Col className="placeholder-overflow-class placeholder-top-margin" lg={6}>
            <Col className="placeholder-overflow-class placeholder-bottom-margin p-x-0" lg={12}>
              <ContentLoader
                width={150}
                height={20}
                animate={true}
                foregroundColor={'#dfdfdf'}
                backgroundColor={'#dfdfdf'}>
                <rect x="0" y="0" width="150" height="20" />
              </ContentLoader>
            </Col>
            <br></br>
            <Col className="placeholder-overflow-class p-x-0" lg={12}>
              <ContentLoader
                width={700}
                height={35}
                animate={true}
                foregroundColor={'#dfdfdf'}
                backgroundColor={'#dfdfdf'}>
                <rect x="0" y="0" width="700" height="35" />
              </ContentLoader>
            </Col>
          </Col>
        </Row>
        <br></br>
        <br></br>
        <Row>
          <Col className="placeholder-overflow-class" lg={6}>
            <ContentLoader
              width={1280}
              height={170}
              animate={true}
              foregroundColor={'#dfdfdf'}
              backgroundColor={'#dfdfdf'}>
              <rect x="0" y="0" width="1280" height="170" />
            </ContentLoader>
          </Col>
        </Row>
        <br></br>
        <br></br>
        <Row>
          <Col className="placeholder-overflow-class" lg={12}>
            <ContentLoader
              className="pull-right"
              width={110}
              height={45}
              animate={true}
              foregroundColor={'#dfdfdf'}
              backgroundColor={'#dfdfdf'}>
              <rect x="0" y="0" width="110" height="45" />
            </ContentLoader>
          </Col>
        </Row>
        <br></br>
        <br></br>
      </Container>
    </CheckoutPlaceholderContainer>
  );
};

export default CheckoutPlaceholders;
