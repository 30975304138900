import React from 'react';
import {DisplayField} from '../../../../../../features/customer/ICustomerState';
import LoadReportTablePlaceholder from '../../Placeholders/LoadReportPlaceholders';
import ReportRow from '../ReportRow';

export interface TableItemsProps {
  reportLines: any[];
  tableColumns: DisplayField[];
  isLoading: boolean;
}

const TableItems = ({reportLines, tableColumns, isLoading}: TableItemsProps) => {
  if (isLoading) {
    return <LoadReportTablePlaceholder />;
  }
  return (
    <tbody>
      {reportLines.map((entry, index) => {
        return <ReportRow entry={entry} index={index} tableColumns={tableColumns} key={index} />;
      })}
    </tbody>
  );
};

export default TableItems;
