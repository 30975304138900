import React from 'react';
import {useHistory} from 'react-router-dom';
import {VoucherCode} from '../../../features/cart/ICartState';
import OrderSummaryContainer from './OrderSummary.styled';
import {formatOrderCurrency} from '../../../Framework/Services/formatCurrency';

const OrderSummaryComponent = ({
  numberOfLineItems,
  totalAmount,
  totalBeforeTaxAndShipping,
  currencyCode = 'USD',
  linkForBackButton,
  estimatedTax,
  shippingAndHandling = 0,
  orderSubtotal,
  orderDiscount,
  quoteDiscounts,
  quote = false,
  hideQuoteTotals = false,
  estimatedPaymentFeeAmount,
  actualPaymentFeeAmount,
  alertData,
  voucherCodes,
  headerAdjustmentTotal,
  pricingItemsTotalDiscount = 0,
  voucherCodesTotalDiscount = 0,
}: {
  numberOfLineItems: number;
  totalAmount: number;
  totalBeforeTaxAndShipping?: number;
  currencyCode?: string;
  linkForBackButton?: string;
  estimatedTax?: number;
  shippingAndHandling?: number;
  orderSubtotal?: number;
  orderDiscount?: number;
  quoteDiscounts?: any[];
  quote?: boolean;
  hideQuoteTotals?: boolean;
  paymentType?: string;
  estimatedPaymentFeeAmount?: number;
  actualPaymentFeeAmount?: number;
  alertData?: {type: string; msg: string}[];
  voucherCodes?: VoucherCode[];
  headerAdjustmentTotal?: number;
  pricingItemsTotalDiscount?: number;
  voucherCodesTotalDiscount?: number;
}) => {
  let voucherDiscountTotal = voucherCodesTotalDiscount;
  if (voucherCodes && voucherCodes.length > 0) {
    voucherDiscountTotal = voucherCodes.reduce((total: number, code: VoucherCode) => total + code.adjustmentAmount, 0);
  }

  const history = useHistory();
  return (
    <OrderSummaryContainer>
      <h2 className="order-summary-heading mb-0">{quote ? 'Quote' : 'Order'} Summary</h2>
      <p className="item-number mb-4">
        ({numberOfLineItems} {numberOfLineItems > 1 ? 'Items' : 'Item'})
      </p>
      {quote && !hideQuoteTotals && (
        <div className="discounts-section">
          <span className="discount-code-text">Before Discounts: </span>
          <span className="discount-amount">{`$${formatOrderCurrency(orderSubtotal ? orderSubtotal : 0)} ${
            currencyCode ? currencyCode : ''
          }`}</span>
        </div>
      )}

      {quoteDiscounts && quoteDiscounts.length > 0 && (
        <>
          <div className="discounts-section">
            Discounts
            {quoteDiscounts.map((discount: any, index) => {
              return (
                <div key={index}>
                  <span className="discount-code-text">{discount.modifierCode}: </span>
                  <span className="discount-amount">{`- $${formatOrderCurrency(discount.discountValueExtended)} ${
                    currencyCode ? currencyCode : ''
                  }`}</span>
                </div>
              );
            })}
          </div>
          <hr />
        </>
      )}
      {!quote && !hideQuoteTotals && (
        <>
          {!!orderSubtotal &&
          (voucherDiscountTotal || headerAdjustmentTotal || pricingItemsTotalDiscount || orderDiscount) ? (
            <div className="mb-4">
              <div className="before-tax-text discounts-section">
                Subtotal:{' '}
                <span className="before-tax-total">
                  {`$${formatOrderCurrency(orderSubtotal)}`} {currencyCode ? currencyCode : ''}
                </span>
              </div>
              {!!(voucherDiscountTotal || pricingItemsTotalDiscount || orderDiscount) && (
                <div className="discounts-section">
                  <span className="discount-code-text">Discount Total: </span>
                  <span className="discount-amount">
                    -{' '}
                    {`$${formatOrderCurrency(
                      voucherDiscountTotal + pricingItemsTotalDiscount + (orderDiscount ?? 0)
                    )} ${currencyCode ? currencyCode : ''}`}
                  </span>
                </div>
              )}
              {!!headerAdjustmentTotal ? (
                <div className="discounts-section">
                  <span className="discount-code-text">Header Adjustments: </span>
                  <span className="discount-amount header-adjustment">
                    {headerAdjustmentTotal < 0 && '-'}{' '}
                    {`$${formatOrderCurrency(headerAdjustmentTotal)} ${currencyCode ? currencyCode : ''}`}
                  </span>
                </div>
              ) : (
                <div></div>
              )}
            </div>
          ) : (
            <div></div>
          )}
          <div className="before-tax-text">
            Total Before Tax and Shipping:{' '}
            <span className="before-tax-total">
              {totalBeforeTaxAndShipping
                ? `${totalBeforeTaxAndShipping < 0 ? '-' : ''} $${formatOrderCurrency(totalBeforeTaxAndShipping)}`
                : '$0.00'}{' '}
              {currencyCode ? currencyCode : ''}
            </span>
          </div>

          {shippingAndHandling || shippingAndHandling === 0 ? (
            <div className="discounts-section">
              <span className="discount-code-text">Shipping and Handling: </span>
              <span className="discount-amount">{`$${
                shippingAndHandling ? formatOrderCurrency(shippingAndHandling) : '0.00'
              } ${currencyCode ? currencyCode : ''}`}</span>
            </div>
          ) : (
            <></>
          )}
          {estimatedTax || estimatedTax === 0 ? (
            <div className="discounts-section">
              <span className="discount-code-text">Estimated Tax: </span>
              <span className="discount-amount">{`$${formatOrderCurrency(estimatedTax)} ${
                currencyCode ? currencyCode : ''
              }`}</span>
            </div>
          ) : (
            <></>
          )}
          {estimatedPaymentFeeAmount && estimatedPaymentFeeAmount > 0 ? (
            <div className="discounts-section">
              <span className="discount-code-text">Estimated Processing Fee: </span>
              <span className="discount-amount">{`$${formatOrderCurrency(estimatedPaymentFeeAmount ?? 0)} ${
                currencyCode ? currencyCode : ''
              }`}</span>
            </div>
          ) : (
            <></>
          )}
          {actualPaymentFeeAmount && actualPaymentFeeAmount > 0 ? (
            <div className="discounts-section">
              <span className="discount-code-text">Processing Fee: </span>
              <span className="discount-amount">{`$${formatOrderCurrency(actualPaymentFeeAmount ?? 0)} ${
                currencyCode ? currencyCode : ''
              }`}</span>
            </div>
          ) : (
            <></>
          )}
          {alertData && alertData.length > 0 ? (
            <>
              {alertData.map((alert: {type: string; msg: string}, index) => {
                return (
                  <div key={`shipping-and-handling-msg${index}`} className="shipping-and-handling-msg">
                    {alert.msg}
                  </div>
                );
              })}
            </>
          ) : (
            <div className="shipping-and-handling-msg">
              Shipping & handling fees and tax will be added to the order separately.
            </div>
          )}
        </>
      )}

      <div className="order-total-container">
        <div>{quote ? 'QUOTE' : 'ORDER'} TOTAL</div>
        <div className="total">
          {totalAmount
            ? `${totalAmount < 0 ? '-' : ''} $${formatOrderCurrency(totalAmount)} ${currencyCode ? currencyCode : ''}`
            : hideQuoteTotals
            ? `$${formatOrderCurrency(orderSubtotal ?? 0)} ${currencyCode ? currencyCode : ''}`
            : '$0.00'}
        </div>
      </div>
      {linkForBackButton && (
        <button className="back-button noprint" onClick={() => history.push(linkForBackButton)}>
          <i className="fa fa-angle-left" />
          BACK
        </button>
      )}
    </OrderSummaryContainer>
  );
};

export default OrderSummaryComponent;
