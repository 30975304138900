import styled from 'styled-components';

const SpinnerContainer = styled.div`
	.main-view {
		&.ng-leave {
			transition: 0.5s;
			opacity: 1;
			.splash-page .logo-container #logo,
			.splash-page .logo-container:before,
			.splash-page .logo-container:after {
				transition: 0.5s;
				opacity: 1;
			}
		}
		&.ng-leave-active {
			opacity: 0;
			.splash-page .logo-container #logo,
			.splash-page .logo-container:before,
			.splash-page .logo-container:after {
				opacity: 0;
			}
		}
	}
	.splash-page {
		z-index: 2147483646;
		background-color: black;
		width: 100%;
		height: 100%;
		opacity: 1;
		position: absolute;
		top: 0;
		left: 0;

		.logo-container {
			top: 50%;
			position: absolute;
			left: 50%;
			transform: translate(-50%, -50%);
			width: 80%;
			opacity: 0;
			animation: fadeIn ease-in 1;
			animation-fill-mode: forwards;
			animation-duration: 1s;
			animation-delay: 1s;
			&:before,
			&:after {
				content: '';
				position: absolute;
				width: 400px;
				height: 440px;
				border-width: 1px 3px;
				border-style: solid;
				border-color: $gray-78;
				left: 50%;
				top: 50%;
				transform: translate(-50%, -50%) rotate(-45deg);
				-webkit-animation: loading-one 4s infinite; /* Chrome, Safari, Opera */
				animation: loading-one 4s infinite;
				-webkit-animation-delay: 2s; /* Chrome, Safari, Opera */
				animation-delay: 2s;
			}
			&:after {
				transform: translate(-50%, -50%) rotate(45deg);
				-webkit-animation: loading-two 4s infinite; /* Chrome, Safari, Opera */
				animation: loading-two 4s infinite;
				-webkit-animation-delay: 2s; /* Chrome, Safari, Opera */
				animation-delay: 2s;
			}
			#logo {
				max-width: 270px;
				height: 143px;
				margin: 0 auto;
				display: block;
				width: 100%;
				.type {
					fill: white;
				}
			}
		}
	}

	@keyframes loading-one {
		0% {
			opacity: 1;
			transform: translate(-50%, -50%) rotate(-45deg);
			height: 440px;
		}
		5% {
			opacity: 1;
			transform: translate(-50%, -50%) rotate(-45deg);
			height: 440px;
		}
		25% {
			opacity: 0.3;
			height: 400px;
		}
		45% {
			opacity: 1;
			transform: translate(-50%, -50%) rotate(45deg);
			height: 440px;
		}
		55% {
			opacity: 1;
			transform: translate(-50%, -50%) rotate(45deg);
			height: 440px;
		}
		75% {
			opacity: 0.3;
			height: 400px;
		}
		95% {
			opacity: 1;
			height: 440px;
			transform: translate(-50%, -50%) rotate(135deg);
		}
		100% {
			opacity: 1;
			height: 440px;
			transform: translate(-50%, -50%) rotate(135deg);
		}
	}
	@keyframes loading-two {
		0% {
			opacity: 1;
			transform: translate(-50%, -50%) rotate(45deg);
			height: 440px;
		}
		5% {
			opacity: 1;
			transform: translate(-50%, -50%) rotate(45deg);
			height: 440px;
		}
		25% {
			opacity: 0.3;
			height: 400px;
		}
		45% {
			opacity: 1;
			transform: translate(-50%, -50%) rotate(315deg);
			height: 440px;
		}
		55% {
			opacity: 1;
			transform: translate(-50%, -50%) rotate(315deg);
			height: 440px;
		}
		75% {
			opacity: 0.3;
			height: 400px;
		}
		95% {
			opacity: 1;
			height: 440px;
			transform: translate(-50%, -50%) rotate(225deg);
		}
		100% {
			opacity: 1;
			height: 440px;
			transform: translate(-50%, -50%) rotate(225deg);
		}
	}

	@-webkit-keyframes fadeIn {
		from {
			opacity: 0;
		}
		to {
			opacity: 1;
		}
	}
	@-moz-keyframes fadeIn {
		from {
			opacity: 0;
		}
		to {
			opacity: 1;
		}
	}
	@keyframes fadeIn {
		from {
			opacity: 0;
		}
		to {
			opacity: 1;
		}
	}
`;
export default SpinnerContainer;
