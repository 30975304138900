import {createAsyncThunk, createSlice, current, PayloadAction} from '@reduxjs/toolkit';
import COMMERCE_CORE_CONSTANTS from '../../../Core/constants';
import {baseApiCallWithReauth} from '../../../Framework/api-utils/api-utils';
import {RootState} from '../../../store/store';
import {SalesforceState} from '../ISalesforceState';
import {getRetailAccountsWithParamsRequest} from '../controller/salesforce.controller';

export const initialState: SalesforceState = {
  currentCustomerSfAccount: null,
  currentCustomerSfAccountStatus: 'idle',
  availablePrograms: null,
  availableProgramsStatus: 'idle',
  activeProgram: {},
  salesforceCustomerAccount: {},
  salesforceCustomerAccountStatus: 'idle',
  projectOpportunities: [],
  isLoadingSubcontractors: false,
  isUpdatedSalesforceAssetAccount: false,
  accountPartners: [],
  accountPartnersStatus: 'idle',
};

export const setSalesforceCustomerAccount = createAsyncThunk(
  'salesforce/setSalesforceCustomerAccount',
  async ({customerErpCustomerId}: {customerErpCustomerId: any}) => {
    const endpoint = COMMERCE_CORE_CONSTANTS.API_SERVICES.SALESFORCE.customerAccount + `/${customerErpCustomerId}`;

    return await baseApiCallWithReauth('GET', endpoint);
  }
);

export const setRetailAccountsByErpCustomerIdAsync = createAsyncThunk(
  'salesforce/setRetailAccountsByErpCustomerIdAsync',
  async ({erpCustomerId}: {erpCustomerId: any}) => {
    const endpoint = COMMERCE_CORE_CONSTANTS.API_SERVICES.SALESFORCE.retailAccounts + `/${erpCustomerId}`;

    return await baseApiCallWithReauth('GET', endpoint);
  }
);

export const setAvailableProgramsAsync = createAsyncThunk(
  'salesforce/setAvailableProgramsAsync',
  async ({salesforceAccountId, customerClassName}: {salesforceAccountId: any; customerClassName: any}) => {
    const data = new URLSearchParams();
    data.append('salesforceAccountId', salesforceAccountId);
    data.append('customerClassName', customerClassName);

    const endpoint = COMMERCE_CORE_CONSTANTS.API_SERVICES.SALESFORCE.programs;
    return await baseApiCallWithReauth('GET', endpoint, data);
  }
);

export const setProjectOpportunitiesAsync = createAsyncThunk(
  'salesforce/setProjectOpportunitiesAsync',
  async ({searchString}: {searchString: string}) => {
    return await baseApiCallWithReauth(
      'GET',
      COMMERCE_CORE_CONSTANTS.API_SERVICES.SALESFORCE.projectOpportunities + `?name=${encodeURIComponent(searchString)}`
    );
  }
);

export const getPartnerAccountsAsync = createAsyncThunk(
  'salesforce/getPartnerAccountsAsync',
  async ({salesforceAccountId}: {salesforceAccountId: string}) => {
    return getRetailAccountsWithParamsRequest(salesforceAccountId);
  }
);

export const salesforceSlice = createSlice({
  name: 'salesforce',
  initialState,
  reducers: {
    setCurrentCustomerSfAccount: (state, action: PayloadAction<any>) => {
      state.currentCustomerSfAccount = action.payload;
    },
    setAvailablePrograms: (state, action: PayloadAction<any>) => {
      state.availablePrograms = action.payload;

      if (Object.keys(current(state.activeProgram)).length === 0) {
        state.activeProgram = action.payload.programs.find(
          (program: any) => program.code === action.payload.defaultProgramCode
        );
      }
    },
    setActiveProgram: (state, action: PayloadAction<any>) => {
      state.activeProgram = action.payload;
      window.sessionStorage.setItem(
        `${COMMERCE_CORE_CONSTANTS.WINDOW_SESSION.selectedProgram}${state.currentCustomerSfAccount?.id}`,
        action.payload?.code
      );
    },
    removeCurrentCustomerSfAccount: (state) => {
      state.currentCustomerSfAccount = null;
    },
    removeSalesforceCustomerAccount: (state) => {
      state.salesforceCustomerAccount = {};
    },
    removeAvailablePrograms: (state) => {
      state.availablePrograms = null;
    },
    removeActiveProgram: (state) => {
      state.activeProgram = {};
    },
    setIsLoadingSubcontractors: (state, action) => {
      state.isLoadingSubcontractors = action.payload;
    },
    setSalesforceInitialState: (state: any) => {
      for (const key in initialState) {
        state[key] = (initialState as any)[key];
      }
    },
    setIsUpdatedSalesforceAssetAccount: (state, action) => {
      state.isUpdatedSalesforceAssetAccount = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setRetailAccountsByErpCustomerIdAsync.fulfilled, (state, action) => {
      state.currentCustomerSfAccount = action.payload.results[0];
      state.currentCustomerSfAccountStatus = 'succeeded';
    });
    builder.addCase(setRetailAccountsByErpCustomerIdAsync.pending, (state) => {
      state.currentCustomerSfAccountStatus = 'loading';
    });
    builder.addCase(setRetailAccountsByErpCustomerIdAsync.rejected, (state) => {
      state.currentCustomerSfAccountStatus = 'failed';
    });
    builder.addCase(setSalesforceCustomerAccount.fulfilled, (state, action) => {
      state.salesforceCustomerAccount = action.payload;
      state.salesforceCustomerAccountStatus = 'succeeded';
    });
    builder.addCase(setSalesforceCustomerAccount.pending, (state) => {
      state.salesforceCustomerAccountStatus = 'loading';
    });
    builder.addCase(setSalesforceCustomerAccount.rejected, (state) => {
      state.salesforceCustomerAccountStatus = 'failed';
    });
    builder.addCase(setAvailableProgramsAsync.fulfilled, (state, action) => {
      state.availablePrograms = action.payload;

      const selectedProgramCode = window.sessionStorage.getItem(`selectedProgramCode${action.payload.accountId}`);
      let programCode = selectedProgramCode ? selectedProgramCode : action.payload.defaultProgramCode;
      for (const program of action.payload.programs) {
        if (program.code === programCode) {
          state.activeProgram = program;
        }
      }
      state.availableProgramsStatus = 'succeeded';
    });
    builder.addCase(setAvailableProgramsAsync.pending, (state) => {
      state.availableProgramsStatus = 'loading';
    });
    builder.addCase(setAvailableProgramsAsync.rejected, (state) => {
      state.availableProgramsStatus = 'failed';
    });
    builder.addCase(setProjectOpportunitiesAsync.pending, (state) => {
      state.projectOpportunities = initialState.projectOpportunities;
    });
    builder.addCase(setProjectOpportunitiesAsync.fulfilled, (state: any, action: any) => {
      state.projectOpportunities = action.payload.results;
    });
    builder.addCase(setProjectOpportunitiesAsync.rejected, (state) => {
      state.projectOpportunities = initialState.projectOpportunities;
    });

    builder.addCase(getPartnerAccountsAsync.fulfilled, (state, action: PayloadAction<any>) => {
      state.accountPartners = action.payload.results;
      state.accountPartnersStatus = 'succeeded';
    });
    builder.addCase(getPartnerAccountsAsync.pending, (state) => {
      state.accountPartnersStatus = 'loading';
      state.accountPartners = initialState.accountPartners;
    });
    builder.addCase(getPartnerAccountsAsync.rejected, (state) => {
      state.accountPartnersStatus = 'failed';
    });
  },
});

export const {
  setCurrentCustomerSfAccount,
  setAvailablePrograms,
  setActiveProgram,
  removeCurrentCustomerSfAccount,
  removeAvailablePrograms,
  removeActiveProgram,
  removeSalesforceCustomerAccount,
  setIsLoadingSubcontractors,
  setSalesforceInitialState,
  setIsUpdatedSalesforceAssetAccount,
} = salesforceSlice.actions;

export const selectCurrentCustomerSfAccount = (state: RootState) => state.salesforce.currentCustomerSfAccount;
export const selectCurrentCustomerSfAccountStatus = (state: RootState) =>
  state.salesforce.currentCustomerSfAccountStatus;
export const selectAvailablePrograms = (state: RootState) => state.salesforce.availablePrograms;
export const selectAvailableProgramsStatus = (state: RootState) => state.salesforce.availableProgramsStatus;
export const selectActiveProgram = (state: RootState) => state.salesforce.activeProgram;
export const selectSalesforceCustomerAccount = (state: RootState) => state.salesforce.salesforceCustomerAccount;
export const selectSalesforceCustomerAccountStatus = (state: RootState) =>
  state.salesforce.salesforceCustomerAccountStatus;
export const selectProjectOpportunities = (state: RootState) => state.salesforce.projectOpportunities;
export const selectIsLoadingSubcontractors = (state: RootState) => state.salesforce.isLoadingSubcontractors;
export const selectIsUpdatedSalesforceAssetAccount = (state: RootState) =>
  state.salesforce.isUpdatedSalesforceAssetAccount;
export const selectSalesforcePartners = (state: RootState) => state.salesforce.accountPartners;
export const selectSalesforcePartnersStatus = (state: RootState) => state.salesforce.accountPartnersStatus;

export default salesforceSlice.reducer;
