import {baseApiCallWithReauth} from '../../../Framework/api-utils/api-utils';
import CORE_CONSTANTS from '../../../Core/constants';

export const postPasswordResetsRequest = async (email: string) => {
  try {
    return await baseApiCallWithReauth(
      'POST',
      CORE_CONSTANTS.API_SERVICES.USERMANAGEMENT.passwordResets,
      JSON.stringify({
        directoryName: 'local',
        resetEndpoint: `${window.location.protocol}//${window.location.host}/confirmReset`,
        username: email,
      }),
      true
    );
  } catch (error: any) {
    throw error;
  }
};

export const putPasswordResetsRequest = async ({
  id,
  resetToken,
  newPassword,
}: {
  id: string;
  resetToken: string;
  newPassword: string;
}) => {
  try {
    return await baseApiCallWithReauth(
      'PUT',
      `${CORE_CONSTANTS.API_SERVICES.USERMANAGEMENT.passwordResets}/${id}`,
      JSON.stringify({
        id: id,
        resetToken: resetToken,
        password: newPassword,
      })
    );
  } catch (error: any) {
    throw error;
  }
};

export const getVerifyTokenRequest = async (params: URLSearchParams) => {
  try {
    return await baseApiCallWithReauth('GET', CORE_CONSTANTS.API_SERVICES.USERMANAGEMENT.verifyToken, params);
  } catch (error: any) {
    throw error;
  }
};
