import styled from 'styled-components/macro';
import {$gray30} from '../../../../Styled/variables';

const DateBlockContainer = styled.div`
  background: ${$gray30};
  font-weight: 600;
  color: white;
  display: grid;
  justify-content: center;
  align-content: center;
  height: 90px;
  width: 90px;
  border-radius: 3px;

  .time,
  .day {
    font-size: 12px;
    display: flex;
    justify-content: center;
  }

  .day {
    font-size: 28px;
  }

  .month,
  .year {
    font-size: 10px;
  }
`;
export default DateBlockContainer;
