import qs from 'qs';
import {baseApiCallWithReauth} from '../../../Framework/api-utils/api-utils';
import COMMERCE_CORE_CONSTANTS from '../../../Core/constants';
import {
  CambriaSavedUploadFile,
  FabricationFile,
  IFileUploadRequest,
  IGetFilesRequest,
  IGetFilesResponse,
  IUploadedFile,
} from '../IFileState';

export const postPurchaseAgreementRequest = async (params: any) => {
  try {
    return await baseApiCallWithReauth(
      'POST',
      COMMERCE_CORE_CONSTANTS.API_SERVICES.FILE.purchaseAgreement,
      JSON.stringify(params),
      true
    );
  } catch (error: any) {
    throw error;
  }
};

export const getFilesByIdRequest = async (fileId: string, erpCustomerId: string) => {
  try {
    return await baseApiCallWithReauth(
      'GET',
      `${COMMERCE_CORE_CONSTANTS.API_SERVICES.FILE.files}/${fileId}`,
      qs.stringify({erpCustomerId: erpCustomerId})
    );
  } catch (error: any) {
    throw error;
  }
};

export const postFilesWithParamsRequest = async (params: IFileUploadRequest): Promise<IUploadedFile> => {
  try {
    return await baseApiCallWithReauth('POST', COMMERCE_CORE_CONSTANTS.API_SERVICES.FILE.files, JSON.stringify(params));
  } catch (error: any) {
    throw error;
  }
};

export const putFilesByIdRequest = async (id: string, isActive: boolean, isFileDeleted: boolean) => {
  await baseApiCallWithReauth(
    'PUT',
    `${COMMERCE_CORE_CONSTANTS.API_SERVICES.FILE.files}/${id}`,
    JSON.stringify({isActive: isActive, isFileDeleted: isFileDeleted}),
    true
  );
};

export const deleteFilesByIdRequest = async (file: FabricationFile | CambriaSavedUploadFile) => {
  try {
    await baseApiCallWithReauth(
      'DELETE',
      `${COMMERCE_CORE_CONSTANTS.API_SERVICES.FILE.files}/${file.id}`,
      undefined,
      true
    );
  } catch (error: any) {
    throw error;
  }
};

export const getFilesRequest = async (params: IGetFilesRequest): Promise<IGetFilesResponse> => {
  try {
    return await baseApiCallWithReauth('GET', COMMERCE_CORE_CONSTANTS.API_SERVICES.FILE.files, qs.stringify(params));
  } catch (error: any) {
    throw error;
  }
};
