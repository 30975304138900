import React, {useEffect, useState} from 'react';
import {Col, Row} from 'react-bootstrap';
import TotalPriceContainer from './TotalPrice.styles';
import {getOriginalCart} from '../../../../../../features/cart/controller/cart.controller';
import {formatOrderCurrency} from '../../../../../../Framework/Services/formatCurrency';
import {getTotalLF, getTotalSqFt} from '../../../../../../features/fabrication/service/fabrication.service';

const TotalPrice = ({orderDetails, fabOrderPage}: {orderDetails: any; fabOrderPage?: any}) => {
  const [items, setItems] = useState<Array<any>>([]);
  const [totalPieces, setTotalPieces] = useState<number | null>(null);
  const [totalSqFt, setTotalSqFt] = useState<number | null>(null);
  const [totalLF, setTotalLF] = useState<number | null>(null);
  const [totalLinePrice, setTotalLinePrice] = useState<number>(0);
  const [subTotalPrice, setSubTotalPrice] = useState<number>(0);

  useEffect(() => {
    const getOriginalCartById = async (originalCartId: string) => {
      const originalCart = await getOriginalCart(originalCartId);
      setItems(originalCart.cartItems);
    };

    if (orderDetails && orderDetails.originalCartId) {
      getOriginalCartById(orderDetails.originalCartId);
    } else {
      setItems(orderDetails.cartItems);
    }
  }, [orderDetails]);

  useEffect(() => {
    const isDiscountOrPriceAdjustment = () => {
      return (
        orderDetails.totalProductDiscountAndGiftCodeAmount !== 0 ||
        orderDetails.totalHeaderAdjustmentAmount !== 0 ||
        orderDetails.standardDiscountAmount !== 0
      );
    };

    if (items && items.length > 0) {
      const totalPiecesNotEdgeProfiles = items.filter((x) => !x.edgeProfileCode && !x.parentCartItemId);
      setTotalPieces(totalPiecesNotEdgeProfiles.length);
      setTotalSqFt(getTotalSqFt(items));
      setTotalLF(getTotalLF(items));
    }
    setTotalLinePrice(orderDetails.totalNetAmount);
    if (!fabOrderPage && isDiscountOrPriceAdjustment()) {
      setSubTotalPrice(orderDetails.subtotalAmount);
    } else {
      setSubTotalPrice(0);
    }
  }, [items, orderDetails, fabOrderPage]);

  return (
    <TotalPriceContainer>
      <Col className="totals-container">
        <Row className="header-row totals">
          <Col xs={3} sm={5} className="header-text">
            TOTAL PIECES
          </Col>
          <Col xs={3} sm={2} className="header-text">
            TOTAL SQFT
          </Col>
          <Col xs={3} sm={2} className="header-text">
            TOTAL LF
          </Col>
          <Col xs={3} sm={2} className="header-text">
            TOTAL PRICE
          </Col>
        </Row>
        <Row className="total-row">
          <Col xs={3} sm={5} className="total-num-col">
            <span className="table-bold-value">{totalPieces}</span>
          </Col>
          <Col xs={3} sm={2} className="total-num-col">
            <span className="table-bold-value">{totalSqFt?.toFixed(2)}</span>
            <span className="table-secondary-value">sqft</span>
          </Col>
          <Col xs={3} sm={2} className="total-num-col">
            <span className="table-bold-value">{totalLF?.toFixed(2)}</span>
            <span className="table-secondary-value">LF</span>
          </Col>
          <Col xs={3} sm={2} className="total-num-col">
            <span className="table-bold-value">${formatOrderCurrency(totalLinePrice)} </span>
            <span className="table-secondary-value">{items && items.length > 0 ? items[0].currency : 'USD'}</span>
            {!fabOrderPage && subTotalPrice > 0 ? (
              <div>
                <br />
                <span className="original-price-strike-through">
                  ${formatOrderCurrency(subTotalPrice)}&nbsp;
                  <span className="table-secondary-value">{items && items.length > 0 ? items[0].currency : 'USD'}</span>
                </span>
              </div>
            ) : (
              <div></div>
            )}
          </Col>
        </Row>
      </Col>
    </TotalPriceContainer>
  );
};

export default TotalPrice;
