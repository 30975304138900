import styled from 'styled-components/macro';
import {$gray30} from '../../../../Styled/variables';

const AddressBlockContainer = styled.div`
  font-size: 12px;
  .order-address-name {
    font-size: 12px;
    font-weight: 600;
    color: ${$gray30};
  }
`;

export default AddressBlockContainer;
