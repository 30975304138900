import React from 'react';
import {selectActiveProgram, setActiveProgram} from '../../../../../../features/salesforce/slice/salesforce.slice';
import {useAppDispatch, useTypedSelector} from '../../../../../../hooks/store';
import COMMERCE_CORE_CONSTANTS from '../../../../../constants';
import {toast} from 'react-toastify';
import {selectCurrentCustomer} from '../../../../../../features/customer/slice/customer.slice';
import {CustomerFullProfile} from '../../../../../../features/customer/ICustomerState';
import {isBundleProgram} from '../../../../../../features/salesforce/service/salesforce.service';

interface Program {
  code: string;
  commerceDisplayName?: string;
}

interface AvailableProgramCheckboxProp {
  programs: Array<Program>;
}

const AvailableProgramCheckbox = ({programs}: AvailableProgramCheckboxProp) => {
  const dispatch = useAppDispatch();

  const activeProgram: any = useTypedSelector(selectActiveProgram);
  const currentCustomer: CustomerFullProfile | null = useTypedSelector(selectCurrentCustomer);

  const slabWarehouse = currentCustomer?.productTypeWarehouseCodes?.find(
    (t) => t.productType === COMMERCE_CORE_CONSTANTS.PRODUCT_TYPES.SLAB
  )?.warehouseCode;

  return (
    <>
      {programs.map((program: any) => (
        <div key={program.code} className={`program ${activeProgram?.code === program.code ? 'active' : ''}`}>
          <input
            id={program.code}
            type="checkbox"
            defaultChecked={activeProgram?.code === program.code}
            onChange={async () => {
              if (!isBundleProgram(program) || slabWarehouse !== COMMERCE_CORE_CONSTANTS.WAREHOUSES.CAMBRIA_PLANT) {
                await dispatch(setActiveProgram(program));
                window.location.href = COMMERCE_CORE_CONSTANTS.PATHNAMES.home;
              } else {
                toast.error(COMMERCE_CORE_CONSTANTS.ERROR_MESSAGES.CANT_SELECT_BUNDLE_ERROR);
              }
            }}
          />
          <label htmlFor={program.code}>{program.commerceDisplayName}</label>
        </div>
      ))}
    </>
  );
};

export default AvailableProgramCheckbox;
