import React, {FC} from 'react';
import {CardContainer} from './Card.styled';

interface CardProps {
  children: JSX.Element;
}

const Card: FC<CardProps> = ({children}) => {
  return <CardContainer>{children}</CardContainer>;
};

export default Card;
