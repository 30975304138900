import {baseApiCallWithReauth} from '../../../../Framework/api-utils/api-utils';
import COMMERCE_CORE_CONSTANTS from '../../../../Core/constants';
import {toast} from 'react-toastify';

export const putQuoteExtensionRequest = async (quoteId: string, erpCustomerId: number) => {
  try {
    return await baseApiCallWithReauth(
      'PUT',
      `${COMMERCE_CORE_CONSTANTS.API_SERVICES.QUOTE.requestExtension}/${quoteId}/${erpCustomerId}`,
      undefined,
      true
    );
  } catch (error: any) {
    throw error;
  }
};

export const putQuoteCartRequest = async (quoteId: number, customer: any) => {
  try {
    return await baseApiCallWithReauth(
      'PUT',
      `${COMMERCE_CORE_CONSTANTS.API_SERVICES.QUOTE.cart}`,
      JSON.stringify({
        quoteId: quoteId,
        customer: customer,
      }),
      true
    );
  } catch (error: any) {
    throw error;
  }
};

export const putQuoteWithParamsRequest = async (params: any) => {
  try {
    return await baseApiCallWithReauth(
      'PUT',
      COMMERCE_CORE_CONSTANTS.API_SERVICES.QUOTES.quote,
      JSON.stringify(params),
      true
    );
  } catch (error: any) {
    throw error;
  }
};

export const postQuoteWithParamsRequest = async (params: any) => {
  try {
    return await baseApiCallWithReauth(
      'POST',
      `${COMMERCE_CORE_CONSTANTS.API_SERVICES.QUOTE.cart}`,
      JSON.stringify(params),
      true
    );
  } catch (error: any) {
    throw error;
  }
};

export const putCancelQuoteRequest = async (quoteId: string, erpCustomerId: number) => {
  try {
    return await baseApiCallWithReauth(
      'PUT',
      `${COMMERCE_CORE_CONSTANTS.API_SERVICES.QUOTE.cancelQuote}/${quoteId}/${erpCustomerId}`,
      undefined,
      true
    );
  } catch (error: any) {
    throw error;
  }
};

export const getQuoteRequest = async (quoteId: string, erpCustomerId: number): Promise<any> => {
  try {
    return await baseApiCallWithReauth(
      'GET',
      `${COMMERCE_CORE_CONSTANTS.API_SERVICES.QUOTES.quote}/${quoteId}?erpCustomerId=${erpCustomerId}`
    );
  } catch (error: any) {
    toast.error(error.data.message);
    throw error;
  }
};

export const getQuotePdfRequest = async (quoteId: number, erpCustomerId: string): Promise<any> => {
  try {
    return await baseApiCallWithReauth(
      'GET',
      `${COMMERCE_CORE_CONSTANTS.API_SERVICES.QUOTE.pdf}?request.quoteId=${quoteId}&request.erpCustomerId=${erpCustomerId}`,
      undefined,
      true
    );
  } catch (error: any) {
    throw error;
  }
};
