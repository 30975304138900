import {baseApiCallWithReauth} from '../../../Framework/api-utils/api-utils';
import COMMERCE_CORE_CONSTANTS from '../../../Core/constants';
import {CartItem} from '../../cart/ICartState';
import {CustomerFullProfile} from '../../customer/ICustomerState';
import qs from 'qs';

export const getProductsInCartRequest = async (params: any) => {
  try {
    return await baseApiCallWithReauth('GET', COMMERCE_CORE_CONSTANTS.API_SERVICES.PRODUCT.productsInCart, params);
  } catch (error: any) {
    throw error;
  }
};

export const deleteCartItemsRequest = async (cartItem: CartItem, cartId: string) => {
  try {
    return await baseApiCallWithReauth(
      'DELETE',
      `${COMMERCE_CORE_CONSTANTS.API_SERVICES.PRODUCT.cartItems}/${cartItem.itemNumber}`,
      qs.stringify({
        cartId: cartId,
      }),
      true,
      false,
      true
    );
  } catch (error) {
    throw error;
  }
};

export const getSavedItemsRequest = async (params: any) => {
  try {
    return await baseApiCallWithReauth('GET', COMMERCE_CORE_CONSTANTS.API_SERVICES.PRODUCT.savedItems, params);
  } catch (error: any) {
    throw error;
  }
};

export const getItemAvailabilityRequest = async (params: any) => {
  try {
    return await baseApiCallWithReauth('GET', COMMERCE_CORE_CONSTANTS.API_SERVICES.PRODUCT.itemAvailability, params);
  } catch (error: any) {
    throw error;
  }
};

export const getProductDesignsRequest = async (currentCustomer: CustomerFullProfile, programCode: string) => {
  try {
    return await baseApiCallWithReauth(
      'GET',
      COMMERCE_CORE_CONSTANTS.API_SERVICES.PRODUCT.productDesigns,
      qs.stringify({
        erpCustomerId: currentCustomer.erpCustomerId,
        customerClassGroups: currentCustomer.classGroups,
        programCode: programCode ? programCode : null,
      })
    );
  } catch (error: any) {
    throw error;
  }
};

export const getDeliverySchedulesRequest = async (cartId: string) => {
  try {
    return await baseApiCallWithReauth(
      'GET',
      `${COMMERCE_CORE_CONSTANTS.API_SERVICES.PRODUCT.requestedDeliverySchedules}/${cartId}`
    );
  } catch (error: any) {
    throw error;
  }
};

export const postRequestedDeliverySchedules = async (params: any) => {
  try {
    return await baseApiCallWithReauth(
      'POST',
      COMMERCE_CORE_CONSTANTS.API_SERVICES.PRODUCT.requestedDeliverySchedules,
      JSON.stringify(params)
    );
  } catch (error: any) {
    throw error;
  }
};

export const deleteDeliverySchedulesRequest = async (cartId: string) => {
  try {
    return await baseApiCallWithReauth(
      'DELETE',
      `${COMMERCE_CORE_CONSTANTS.API_SERVICES.PRODUCT.requestedDeliverySchedules}?cartId=${cartId}`
    );
  } catch (error: any) {
    throw error;
  }
};
