import React, {FC, useEffect} from 'react';
import CambriaSelect from '../../../../../Framework/Components/CambriaSelect';
import {SalesforceRetailAccount} from '../../../../../features/customer/ICustomerState';
import {useAppDispatch, useTypedSelector} from '../../../../../hooks/store';
import {
  getPartnerAccountsAsync,
  selectSalesforceCustomerAccount,
  selectSalesforcePartners,
  selectSalesforcePartnersStatus,
} from '../../../../../features/salesforce/slice/salesforce.slice';
import {ISalesforceAccount} from '../../../../../features/salesforce/ISalesforceState';

export interface ExistingAddressSelectProps {
  formikProps: any;
  onExistingAddressChange: (props: any, selectedSite: SalesforceRetailAccount) => void;
  disabled: boolean;
}

const organizeAddress = (site: ISalesforceAccount) => {
  return {
    displayName: `${site.name} ${site.billingAddress.street} ${site.billingAddress.state} ${site.billingAddress.postalCode}`,
    value: `${site.name}`,
    site: site,
  };
};

const ExistingAddressSelect: FC<ExistingAddressSelectProps> = ({
  formikProps,
  onExistingAddressChange,
  disabled = false,
}) => {
  const dispatch = useAppDispatch();
  const salesforceCustomerAccount = useTypedSelector(selectSalesforceCustomerAccount);
  const salesforcePartners = useTypedSelector(selectSalesforcePartners);
  const salesforcePartnersStatus = useTypedSelector(selectSalesforcePartnersStatus);
  const loading = salesforcePartnersStatus !== 'succeeded';

  useEffect(() => {
    if (salesforceCustomerAccount?.id) {
      dispatch(getPartnerAccountsAsync({salesforceAccountId: salesforceCustomerAccount.id}));
    }
  }, [salesforceCustomerAccount, dispatch]);

  const availableAddresses = [
    ...(salesforceCustomerAccount?.id ? [organizeAddress(salesforceCustomerAccount)] : []),
    ...(salesforcePartners?.map((x) => organizeAddress(x)) ?? []),
  ];
  return (
    <CambriaSelect
      formikFormProps={formikProps}
      name="selectExistingAddress"
      defaultValue={formikProps.values.selectExistingAddress}
      items={availableAddresses}
      placeholder={!loading ? 'Select or Enter Ship To Address...' : 'Loading...'}
      displayValue="displayName"
      onChange={(selectedSite: SalesforceRetailAccount) => onExistingAddressChange(formikProps, selectedSite)}
      disabled={disabled || loading}
    />
  );
};

export default ExistingAddressSelect;
