import COMMERCE_CORE_CONSTANTS from '../../../Core/constants';
import {callback} from '../../callbacks/service/callbacks.service';
import {OrderFilter, OrderSearchParams, QuoteFilter} from '../../search/ISearchState';
import {postOrdersRequest, postResubmitOrderRequest, putOrdersRequest} from '../controller/order.controller';
import {IPlaceOrderRequest} from '../IOrderState';

export const resubmitOrder = async (params: any, dispatch: any): Promise<any> => {
  let cartSaveCallbackId = await postResubmitOrderRequest(params);
  cartSaveCallbackId = await cartSaveCallbackId.text();
  try {
    const callbackMethod = await callback(cartSaveCallbackId, 60, dispatch);
    return callbackMethod.OrderNumber;
  } catch (error: any) {
    throw error;
  }
};

export const updateOrder = async (params: any, orderNumber: string, dispatch: any): Promise<string> => {
  let cartSaveCallbackId = await putOrdersRequest(orderNumber, params);
  cartSaveCallbackId = await cartSaveCallbackId.text();
  try {
    const result = await callback(cartSaveCallbackId, 60, dispatch);
    return result.OrderNumber;
  } catch (error: any) {
    throw error;
  }
};

export const placeOrder = async (params: IPlaceOrderRequest, dispatch: any): Promise<string> => {
  let orderPlaceCallbackId = await postOrdersRequest(params);
  orderPlaceCallbackId = await orderPlaceCallbackId.text();
  try {
    const result = await callback(orderPlaceCallbackId, 60, dispatch);
    await callback(result.CartSaveCallbackId, 60, dispatch);
    return result.OrderNumber;
  } catch (error: any) {
    throw error;
  }
};

export const addOrderStringInput = (
  currentFilter: string,
  orderFilters: OrderSearchParams,
  inputValue: string,
  url: string
) => {
  let searchParamsToSave = {...orderFilters};
  if (currentFilter === 'Details') {
    searchParamsToSave['q'] = inputValue;
  } else if (currentFilter === 'End User Address') {
    searchParamsToSave['endConsumerAddress1'] = inputValue;
  } else if (currentFilter === 'End User First Name') {
    searchParamsToSave['endConsumerFirstName'] = inputValue;
  } else if (currentFilter === 'End User Last Name') {
    searchParamsToSave['endConsumerLastName'] = inputValue;
  } else if (currentFilter === 'Job Number') {
    searchParamsToSave['jobNumber'] = inputValue;
  } else if (currentFilter === 'Order Number') {
    searchParamsToSave['orderNumber'] = inputValue;
  } else if (currentFilter === 'Purchase Order Number') {
    searchParamsToSave['purchaseOrderNumber'] = inputValue;
  } else if (currentFilter === 'Status') {
    url === COMMERCE_CORE_CONSTANTS.PATHNAMES.orderSearch
      ? (searchParamsToSave['statusCode'] = inputValue)
      : (searchParamsToSave['status'] = inputValue);
  } else if (currentFilter === 'Status Code') {
    searchParamsToSave['statusCode'] = inputValue;
  } else if (currentFilter === 'Order Type') {
    searchParamsToSave['orderType'] = inputValue;
  } else if (currentFilter === 'Favorite') {
    searchParamsToSave['favoriteName'] = inputValue;
  }
  return searchParamsToSave;
};

export const cleanSearchParams = (data: URLSearchParams) => {
  let keysForDel: string[] = [];
  data.forEach((value, key) => {
    if (value === '') {
      keysForDel.push(key);
    }
  });
  keysForDel.forEach((key: any) => {
    data.delete(key);
  });
  return data;
};

export const checkForDuplicate = (itemToAdd: QuoteFilter | OrderFilter, activeParams: any[]) => {
  let i = 0;
  let arrayCopy = [];
  for (i = 0; i < activeParams.length; i++) {
    if (activeParams[i].name !== itemToAdd.name) {
      arrayCopy.push(activeParams[i]);
    }
  }
  arrayCopy.push(itemToAdd);
  return arrayCopy;
};

export const removeListElement = (name: string, activeParams: any[]) => {
  let i = 0;
  let copyArray = [];
  for (i = 0; i < activeParams.length; i++) {
    if (activeParams[i].name !== name) {
      copyArray.push(activeParams[i]);
    }
  }
  return copyArray;
};
