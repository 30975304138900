import React, {useEffect, useState} from 'react';
import {Col, Row} from 'react-bootstrap';
import {toast} from 'react-toastify';
import {selectCurrentUser} from '../../../features/auth/slice/authentication.slice';
import {deleteHeaderAdjustment} from '../../../features/cart/service/cart.service';
import {selectActiveCart} from '../../../features/cart/slice/cart.slice';
import {
  selectCurrentCustomer,
  selectCurrentCustomerBillToSites,
  selectCurrentCustomerShipToSites,
} from '../../../features/customer/slice/customer.slice';
import {
  hideFullscreenLoader,
  showFullscreenLoader,
} from '../../../features/fullscreenLoader/slice/fullscreenLoader.slice';
import {selectActiveProgram, selectAvailablePrograms} from '../../../features/salesforce/slice/salesforce.slice';
import CambriaButton from '../../../Framework/Components/CambriaButton';
import Icon from '../../../Framework/Components/Icon';
import {formatPrice} from '../../../Framework/Services/formatPrice.service';
import {useAppDispatch, useTypedSelector} from '../../../hooks/store';
import PriceAdjustmentsContainer from './PriceAdjustments.styled';
import {ISalesforceAvailablePrograms} from '../../../features/salesforce/ISalesforceState';

const PriceAdjustments = () => {
  const dispatch = useAppDispatch();

  let cart: any = useTypedSelector(selectActiveCart);
  let customer = useTypedSelector(selectCurrentCustomer);
  let currentUser: any = useTypedSelector(selectCurrentUser);
  let activeProgram = useTypedSelector(selectActiveProgram);
  let shipToSites: any = useTypedSelector(selectCurrentCustomerShipToSites);
  let billToSites: any = useTypedSelector(selectCurrentCustomerBillToSites);
  const availablePrograms: ISalesforceAvailablePrograms | null = useTypedSelector(selectAvailablePrograms);

  let [priceAdjustments, setPriceAdjustments]: any = useState(null);

  const deletePriceAdjustments = async (headerAdjustmentId: any) => {
    try {
      dispatch(showFullscreenLoader({showCartIcon: true}));
      await deleteHeaderAdjustment(
        cart,
        headerAdjustmentId,
        customer,
        currentUser.userId,
        activeProgram,
        shipToSites,
        billToSites,
        dispatch,
        availablePrograms
      );
      toast.success('Pricing Adjustment has been successfully removed from the cart!');
    } catch (e) {
      toast.error('The Pricing Adjustment failed to be removed from cart. Please try again.');
    } finally {
      dispatch(hideFullscreenLoader());
    }
  };

  useEffect(() => {
    if (cart && cart.headerAdjustments) {
      setPriceAdjustments(cart.headerAdjustments);
    }
  }, [cart]);

  return (
    <PriceAdjustmentsContainer className="col-12 p-x-0">
      {priceAdjustments && priceAdjustments.length > 0 ? (
        <div className="priceAdjustments-box panel step">
          <h1 className="price-adjustment-header-text">Price Adjustments</h1>
          {priceAdjustments.map((priceAdjustment: any, index: number) => {
            return (
              <Row key={`price-adjustment-item-${index}`} className="priceAdjustment-item">
                <Col xs={12} md={8}>
                  <div className="m-b-0 small price-adjustment-name-text">{priceAdjustment.name.toUpperCase()}</div>
                  <div className="small">
                    <div>PRICE ADJUSTMENT AMOUNT: {formatPrice(priceAdjustment.value)}</div>
                    <div>{priceAdjustment.reasonTxt}</div>
                  </div>
                </Col>
                <Col xs={12} md={4} className=" pull-right">
                  <CambriaButton
                    className="btn btn-block btn-default ripple remove-price-adjustment-button"
                    onClick={() => {
                      deletePriceAdjustments(priceAdjustment.id);
                    }}>
                    <Icon icon="icon icons-cambria-Ui-Multiply" color="#eee" size="15" weight="bold" />
                    REMOVE PRICE ADJUSTMENT
                  </CambriaButton>
                </Col>
              </Row>
            );
          })}
        </div>
      ) : (
        <div></div>
      )}
    </PriceAdjustmentsContainer>
  );
};

export default PriceAdjustments;
