import styled from 'styled-components';
import {$gray78, $gray93, $newwhite} from '../../../../../../Styled/variables';

export const EndUserAddressSearchContainer = styled.div`
  font-weight: 600;
  margin-bottom: 10px;

  .contact-line-item {
    background-color: ${$gray93};
    border-radius: 3px;
    padding: 5px;

    .contact-line-item-text-overflow {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .add-new-address-button {
    height: 35px;
    font-size: 14px !important;
    margin-top: 5px;
  }

  .consumer-card {
    border-radius: 3px;
    box-shadow: 0px 1px 3px 0px rgba(101, 101, 101, 0.35);

    .consumer-name {
      font-weight: 800;
    }
  }

  .table-container {
    box-shadow: 0px 1px 3px 0px rgba(101, 101, 101, 0.35);
    border-radius: 3px;
    background-color: ${$newwhite};
    margin: 5px;
  }

  .table-headers {
    font-weight: 700;
    font-size: 16px;
    border-radius: 3px;
    background-color: ${$gray78};
  }

  .table-cell {
    font-weight: 600;
    font-size: 14px;
    display: flex;
    align-items: center;
  }

  .search-results-table {
    max-height: 450px;
    overflow-y: auto;
  }

  .table-row:nth-child(even) {
    background-color: ${$gray93};
  }

  .table-row {
    border-radius: 3px;

    :hover {
      background-color: #f9ecc9 !important;
      cursor: pointer;
    }
  }

  .CambriaInputstyled__CambriaInputContainer-sc-1vs0scz-0 {
    width: 100%;
  }
`;
