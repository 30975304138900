import React from 'react';
import ContentLoader from 'react-content-loader';

export default function ProductPlaceholders() {
  return (
    <div className="col-xs-12 col-md-6 product-item">
      <div className="img img-tile img-responsive no-border product-item-tile">
        <div className="priority-product-flag-container">
          <div className="grid-priority-product">
            <ContentLoader
              width={50}
              height={10}
              animate={false}
              foregroundColor={'#dfdfdf'}
              backgroundColor={'#dfdfdf'}>
              <rect x="0" y="0" width="50" height="10" />
            </ContentLoader>
          </div>
        </div>
        <div className="product-image-container">
          <div className="product-image-placeholder"></div>
        </div>
        <div className="img-text product-detail">
          <div className="title product-name">
            <ContentLoader
              width={120}
              height={10}
              animate={false}
              foregroundColor={'#dfdfdf'}
              backgroundColor={'#dfdfdf'}>
              <rect x="0" y="0" width="120" height="10" />
            </ContentLoader>
          </div>
          <span className="product-type">
            <br className="visible-xs title" />
            <ContentLoader
              width={40}
              height={10}
              animate={false}
              foregroundColor={'#dfdfdf'}
              backgroundColor={'#dfdfdf'}>
              <rect x="0" y="0" width="40" height="10" />
            </ContentLoader>
          </span>
        </div>
      </div>
    </div>
  );
}
