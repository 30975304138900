import {baseApiCallWithReauth} from '../../../Framework/api-utils/api-utils';
import COMMERCE_CORE_CONSTANTS from '../../../Core/constants';
import {callback} from '../../callbacks/service/callbacks.service';
import {IAddCartPaymentMethodRequest} from '../IPaymentState';

export const removePaymentMethod = async (selectedPaymentMethodId: number, dispatch: any) => {
  let callbackId = await baseApiCallWithReauth(
    'DELETE',
    `${COMMERCE_CORE_CONSTANTS.API_SERVICES.PAYMENT.cartPaymentMethods}/${selectedPaymentMethodId}`,
    undefined,
    true
  );
  callbackId = await callbackId.text();
  return callback(callbackId, 60, dispatch);
};

export const addPaymentMethod = async (params: IAddCartPaymentMethodRequest, dispatch: any): Promise<any> => {
  let callbackId = await baseApiCallWithReauth(
    'POST',
    COMMERCE_CORE_CONSTANTS.API_SERVICES.PAYMENT.cartPaymentMethods,
    JSON.stringify(params),
    true
  );
  callbackId = await callbackId.text();
  if (callbackId) {
    return callback(callbackId, 60, dispatch);
  }
};
