import {Modal} from 'react-bootstrap';
import styled from 'styled-components/macro';
import {$gray97, $newprimaryfont, $gray67, $newblack, $newwhite} from '../../../Styled/variables';

export const ModalContainer = styled(Modal)`
  &.show {
    max-height: 100%;
    overflow-y: auto;
  }

  .modal-dialog {
    border: 1px solid ${$gray67};

    .modal-content {
      padding: 3% 8%;
      border-radius: 0;
      box-shadow: none;
      background: ${$gray97};

      .modal-header {
        border-bottom: 1px solid ${$gray67};
        padding: 25px 0;
        align-items: center;

        .modal-title {
          font-family: ${$newprimaryfont};
          font-size: 24px;
          line-height: 32px;
          color: ${$newblack};
          flex: 1;
        }
      }

      .modal-body {
        padding: 20px 0;
      }

      .modal-footer {
        padding: 0;
        border-top: none;
        border-radius: 0;
      }
    }
  }
  .fa-spinner {
    color: ${$newwhite};
  }
`;
