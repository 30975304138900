import styled from 'styled-components';
import * as colors from '../../../../../Framework/Components/styles/colors';

export const MobileSidebarContainer = styled.div`
  display: block;
  position: fixed;
  top: 70px;
  bottom: 0;
  left: -100%;
  width: 100%;
  height: auto;
  background: #ccc;
  z-index: 9999;
  transition: all 0.2s ease-in-out;
  overflow-x: auto;

  &.on {
    left: 0;
  }

  .customer-toggle {
    margin: 15px;
    padding: 25px;
    border-radius: 3px;
    box-shadow: 0 1px 2px rgb(0 0 0 / 20%);

    &.collapsed {
      background: ${colors.black};
      .customer-name {
        color: ${colors.base};
      }
    }

    &.expanded {
      background: ${colors.base};
      .customer-name {
        color: ${colors.black};
      }
    }

    .customer-name {
      font-weight: 500;
      font-size: 24px;
      text-transform: uppercase;
      line-height: 1.4;
    }
  }

  .mobile-links {
    margin: 15px;
    border-top: 1px solid #979797;

    .mobile-link {
      border-bottom: 1px solid #979797;

      &.open {
        .mobile-link-content {
          margin: 10px 0;
          background-color: ${colors.base} !important;
        }
      }

      .mobile-link-content {
        padding: 20px;
        text-align: left;

        .dropdown-content-section-description {
          text-transform: none;
          font-weight: 200;
          margin-bottom: 20px;
        }
      }

      a,
      .mobile-link-title {
        display: inline;
        color: ${colors.black} !important;
        text-transform: uppercase;
        font-weight: 500;
        font-size: 18px !important;
        text-align: left;
        box-sizing: border-box;
        padding: 0;
      }

      .icon {
        position: relative;
        bottom: 3px;
      }
    }

    .section-title {
      font-weight: 500;
      color: #333;
      text-transform: uppercase;
      font-size: 12px;
      margin-bottom: 20px;
    }
  }
`;
