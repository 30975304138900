import styled from 'styled-components/macro';
import {medium, small, xsmall} from '../../../Framework/Components/styles/media-queries';
import {
  $brandprimary,
  $brandsecondary,
  $brandsecondarylight,
  $fontsizesmall,
  $gray30,
  $newblack,
  $newinactivebackground,
} from '../../../Styled/variables';

export const ProductPage = styled.div`
  .product-list-container {
    padding: 0px;
    margin-top: -35px;
    margin-bottom: 30px;

    .product-list-row {
      padding: 0px 15px;
      max-width: 100%;
    }

    .product-list-filter-container {
      padding: 20px;

      .product-list-filter-row-container {
        background-color: #e9e9e9;
        margin: -20px;
        padding: 20px;
      }

      .product-filter-header {
        font-weight: 300;
        padding-bottom: 25px;
      }

      .product-filter-reset-button {
        font-weight: 500;
        margin-top: 3px;
      }

      .mobile-filter-done-button {
        background-color: #ffffff;
        color: #c59617;
        border: 1px solid #e9e9e9e9;
        font-weight: 600;
        padding: 9px;
      }

      .mobile-filter-done-button:focus {
        box-shadow: 0 0 0 0.2rem #c59617;
      }

      .mobile-filter-done-button:active {
        background-color: white;
        border-color: #c59617;
      }

      .mobile-filter-pill {
        display: block;
        float: left;
        padding: 8px;
        background-color: white;
        border: 1px solid #e9e9e9;
        margin-bottom: 15px;
        margin-right: 15px;

        .mobile-filter-pill-text {
          font-weight: 300;

          .mobile-filter-pill-clear-button {
            padding-left: 15px;
            font-size: medium;
            color: #c59617;
            cursor: pointer;
            font-weight: 600;
          }
        }
      }

      .mobile-filter-filter-text-and-reset-button-container {
        margin-bottom: 15px;

        .mobile-filter-reset-button {
          font-size: initial;
        }
      }

      .mobile-filter-product-type-header-container {
        padding: 0px;
        margin-bottom: 40px;

        .product-list-product-type-header {
          font-weight: 300;
        }
      }

      form {
        .checkbox {
          label {
            font-weight: 600;
          }

          label:before {
            border: 2px solid #4c4c4c;
          }
        }

        .radio {
          label:before {
            border: 2px solid #4c4c4c;
          }
        }
      }
    }

    .product-list-list-container {
      padding: 0px;

      .productList {
        margin-top: 20px;
        margin-bottom: 20px;

        .h3-default {
          font-size: 20px;
        }

        .product-list-load-more-button-container {
          display: flex;
          flex-direction: row;
          justify-content: center;
          padding-left: 25px;

          .spinner-border {
            width: 4rem;
            height: 4rem;
          }

          ${small} {
            padding-right: 25px;
          }
        }

        .product-list-load-more-button:focus {
          box-shadow: 0 0 0 0.2rem #c59617;
        }

        .cta-image {
          height: 260px;
          width: 100%;
          background-size: cover;
          background-position: center;
          padding: 100px 0;

          h1,
          p {
            color: #ffffff;
          }
        }

        .thumbnail {
          height: 50px;
          margin-right: 15px;
          margin-bottom: 0;
        }

        .product-title {
          font-size: 14px;
          font-weight: bold;
          text-transform: uppercase;
        }

        .product-detail {
          padding: 8px 8px 8px 30px;
          display: flex;
          justify-content: space-between;
          padding-top: 19px;
        }

        .product-description {
          display: table;
        }

        .subtitle {
          font-style: italic;
          font-size: 12px;
        }

        .list-priority-product {
          float: right;
          color: ${$brandprimary};
          background-color: ${$brandsecondary};
          font-style: italic;
          padding: 1px 5px;
        }

        .item-name,
        .reset-link {
          font-size: ${$fontsizesmall};
          margin-top: 4px;
        }

        .h2--white {
          color: #ffffff;
        }

        .double-border {
          position: relative;
          z-index: 10;

          &:before,
          &:after {
            content: '';
            display: block;
            position: absolute;
            z-index: 11;
            border: 1px solid ${$brandprimary};
          }

          &:hover:before,
          &:hover:after {
            border: 1px solid ${$brandsecondarylight};
          }

          &:before {
            top: -1px;
            left: -5px;
            right: -5px;
            bottom: -2px;
          }

          &:after {
            top: -5px;
            left: -1px;
            right: -1px;
            bottom: -6px;
          }
        }

        .img.img-tile .img-text {
          .category {
            position: relative;
            top: 50%;
            transform: translateY(-50%);

            .text {
              color: white;
              text-shadow: 1px 1px #333;

              .title {
                font-size: 24px;
                font-weight: bold;
                height: 60px;
              }
            }
          }

          .btn {
            color: ${$brandprimary};
            background-color: rgba(red(black), green(black), blue(black), 0.6);

            &:hover {
              color: white;
              background-color: ${$brandprimary};
            }
          }
        }

        .col-xs-6 {
          .title {
            font-size: 14px;
            font-weight: bold;
          }
        }

        .img.img-tile {
          .product-image-container {
            img.product-image {
              width: 100%;
            }

            .product-image-placeholder {
              width: 100%;
              height: 204px;
              background-color: #dfdfdf;
            }
          }
        }

        .capitalize {
          text-transform: capitalize;
        }

        .priority-product-flag-container {
          position: absolute;
          top: 1px;
          left: 15px;

          .grid-priority-product {
            position: initial;
            font-style: normal;
            color: #c59617;
            background-color: white;
            font-size: 12px;
            padding: 10px;
            margin-right: 15px;
            border-radius: 0 0 4px 4px;
            display: block;
            float: left;
            font-weight: 600;
          }

          .luxury-design,
          .signature-design,
          .grandeur-design {
            display: block;
            float: left;
            position: relative;
            background: white;
            height: 37px;
            padding: 7px;
            padding-right: 14px;
            border-radius: 0 0 4px 4px;
          }

          .luxury-design {
            top: 24px;
          }

          .signature-design {
            top: 0;
          }

          .grandeur-design {
            top: 0;
            padding: 0;
          }
        }

        /* .product-table {
          .luxury-design,
          .signature-design {
            height: 24px;
            display: inline-block;
            margin-left: 10px;
          }
        } */

        .product-list {
          display: -webkit-box;
          display: -webkit-flex;
          display: -ms-flexbox;
          display: flex;
          flex-wrap: wrap;

          .product-list-product-type-header {
            font-weight: 300;
            margin-bottom: 20px;
            margin-left: 10px;
          }

          .product-item {
            display: flex;
            //For all browsers except IE and Edge
            flex-direction: column;
            //For IE and Edge
            -ms-flex-direction: row;
            padding-right: 0;
            padding-left: 25px;

            .product-item-tile {
              border-radius: 3px;
              box-shadow: 0px 1px 1px rgb(0 0 0 / 25%);
              background-color: white;
            }

            .luxury-design {
              margin-top: -24px;

              .luxury-logo {
                padding: 0px;
                margin-left: 10px;
                height: 25px;
              }
            }

            .signature-design {
              .signature-logo {
                height: 25px;
              }
            }

            .grandeur-design {
              .grandeur-logo {
                height: 40px;
              }
            }

            .product-name {
              color: #4c4c4c;
              font-weight: 600;
            }

            .product-type {
              color: #c59617;
              padding-right: 20px;
              font-weight: 500;
            }
          }

          ${small} {
            .product-item {
              padding-right: 25px;

              .product-name {
                padding-top: 7px;
              }

              .product-type {
                margin-top: -15px;
              }
            }
          }
        }

        .category-box {
          &.min-options {
            form {
              height: 170px;
            }
          }

          .form-filter {
            height: inherit;
            overflow-y: hidden;
          }

          .form-mobile-filter {
            margin-left: 15px;
            margin-right: 15px;
          }

          .more-category-link {
            display: block;
            text-align: right;
            margin-bottom: 20px;
            cursor: pointer;
          }
        }

        .search-tag-container {
          padding-left: 15px;
        }

        ${small} {
          .img.img-tile {
            .tile-img-area.mobile-two-col {
              height: 200px;
            }
          }
        }

        ${xsmall} {
          .img.img-tile .img-text {
            .category {
              .text {
                .title {
                  font-size: 18px;
                }
              }
            }
          }
        }
      }
    }

    .product-details {
      padding: 15px;

      .product-details-nav-tabs {
        text-align: center;
        padding: 20px;
        background-color: #edeeef;
        text-transform: uppercase;
        font-weight: 500;
        font-size: 12px;
        cursor: pointer;
      }

      .active-product-details-nav-tab {
        background-color: #fff !important;
        color: #c59617 !important;
      }
    }
  }

  ${medium} {
    .product-list-container {
      padding-top: 30px;
    }
  }

  .btn-primary {
    color: #fff !important;
    background-color: #c59617 !important;
    border-color: #979797 !important;

    :hover {
      background-color: #977312 !important; //linear-gradient(0deg, rgba(0, 0, 0, 0.295), rgba(0, 0, 0, 0.259)), #c59617 !important;
    }
  }

  .product {
    .design-header {
      color: ${$gray30};
      font-family: 'Montserrat', sans-serif;
      font-weight: 300;
      font-size: 36px;
      line-height: 44px;
    }

    .item-number {
      color: #777777;
      font-family: 'Montserrat', sans-serif;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
    }

    .ui-select-placeholder {
      font-weight: 500;
      font-family: Montserrat;
      font-size: 16px;
      line-height: 22px;
      text-transform: capitalize;
    }

    .ui-select-match-text {
      color: ${$newblack};
      text-transform: capitalize;
    }

    .price-summary-container {
      padding: 30px 70px;
      border: none;
      border-radius: 3px;
      background-color: ${$newinactivebackground};

      .discounted {
        .price {
          h2,
          .price-currency {
            .actual-price {
              text-decoration: line-through;
            }
            font-size: 22px;
            @media (max-width: 1200px) {
              font-size: 16px;
            }
          }
          .price-uom {
            text-decoration: line-through;
            font-size: 14px;
            @media (max-width: 1200px) {
              font-size: 12px;
            }
          }
        }
      }

      .price {
        .price-uom {
          margin-left: 5px;
        }

        white-space: nowrap;
        margin: 0;

        h2,
        .price-currency {
          top: 0;
          font-weight: bold;
          font-size: 36px;
          line-height: 22px;

          @media (max-width: 1200px) {
            font-size: 23px;
          }
        }
      }

      .price-summary {
        border: none;
        background-color: ${$newinactivebackground};

        label {
          margin: 10px auto;
          color: ${$newblack};
          text-transform: uppercase;
          font-weight: bold;
          font-size: 14px;
          line-height: 22px;
        }
      }

      .product-action {
        padding: 0;
      }
    }

    .product-details-add-to-cart-button {
      width: 100%;
    }

    .product-details-save-for-later-button {
      border: none;
      box-shadow: none !important;
      text-decoration: underline;
      text-underline-position: from-font;
      color: #c59617;
      margin-top: 8px;
    }

    .product-details-additional-designs-button-container {
      @media (max-width: 992px) {
        float: left;
        padding-left: 15px;
      }
    }

    .product-details-additional-designs-button {
      float: right;
      margin-right: -15px;
      border: none;
      box-shadow: none !important;
      text-decoration: underline;
      text-underline-position: from-font;
      color: #c59617;
      margin-top: 8px;

      @media (max-width: 992px) {
        float: left;
      }
    }

    .market-description {
      color: ${$gray30};
      font-family: 'Montserrat', sans-serif;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
    }
  }
`;
