import React, {useCallback, useEffect, useState} from 'react';
import {Accordion, Card} from 'react-bootstrap';
import {selectActiveCart} from '../../../../features/cart/slice/cart.slice';
import {useAppDispatch, useTypedSelector} from '../../../../hooks/store';
import Dropzone from 'react-dropzone';
import {selectCurrentCustomer} from '../../../../features/customer/slice/customer.slice';
import CambriaFileUpload from '../../Account/OrderHistory/OrderDetails/FabricationCart/CambriaFileUpload';
import {CambriaDropzone} from './FabOrderUploadFiles.styled';
import {selectCurrentUser} from '../../../../features/auth/slice/authentication.slice';
import {toast} from 'react-toastify';
import {getUploadedFilesAsync, selectUploadedFiles} from '../../../../features/file/slice/file.slice';
import {IFileRequest, IFileUploadRequest} from '../../../../features/file/IFileState';
import {postFilesWithParamsRequest, putFilesByIdRequest} from '../../../../features/file/controller/file.controller';

const FabOrderUploadFiles = () => {
  const dispatch = useAppDispatch();

  const activeCart = useTypedSelector(selectActiveCart);
  const currentCustomer = useTypedSelector(selectCurrentCustomer);
  const uploadedFiles = useTypedSelector(selectUploadedFiles);
  const currentUser = useTypedSelector(selectCurrentUser);

  const [isOpen, setIsOpen] = useState(false);
  const [shouldBeExpanded, setShouldBeExpanded] = useState(false);
  const [fileUploadInProgress, setUploadInProgress] = useState('');

  const loadUploadedFilesOnPageLoad = useCallback(() => {
    const fileParams: IFileRequest = {
      erpCustomerId: currentCustomer.erpCustomerId,
      externalId: activeCart ? activeCart.id : '',
      groupName: 'Fabrication',
      limit: 100,
      sort: 'createdAt',
    };
    dispatch(getUploadedFilesAsync({params: fileParams}));
  }, [currentCustomer, activeCart, dispatch]);

  const uploadFile = async (file: any) => {
    const params: IFileUploadRequest = {
      erpCustomerId: currentCustomer.erpCustomerId,
      externalId: activeCart?.id || '',
      fileSize: file.size,
      groupName: 'Fabrication',
      originalFileName: file.name,
      userId: currentUser?.userId || '',
    };
    let fileInfo = null;
    try {
      setUploadInProgress(file.name);
      fileInfo = await postFilesWithParamsRequest(params);
    } catch (e) {
      toast.error(typeof e === 'string' ? (e as string) : 'File upload failed');
    }

    if (fileInfo) {
      try {
        //upload file to AWS
        await fetch(fileInfo.signedUrl.href, {
          method: fileInfo.signedUrl.verb,
          body: file,
          headers: {
            'Content-Type': fileInfo.signedUrl.contentType,
          },
        });

        await putFilesByIdRequest(fileInfo.id, true, false);
        toast.success(`'${file.name}' Uploaded Successfully`);
      } catch (e) {
        console.log(e);
        toast.error('File upload failed');
      }
    }

    const fileParams: IFileRequest = {
      erpCustomerId: currentCustomer.erpCustomerId,
      externalId: activeCart ? activeCart.id : '',
      groupName: 'Fabrication',
      limit: 100,
      sort: 'createdAt',
    };
    dispatch(getUploadedFilesAsync({params: fileParams}));
    setUploadInProgress('');
  };

  useEffect(() => {
    if (activeCart && activeCart.cartItems && activeCart.cartItems.length > 0) {
      let fabItems = activeCart.cartItems.filter(
        (item) => item.cartItemType === 'FabAndInstallStandard' || item.cartItemType === 'FabAndInstallDiscrete'
      );
      if (fabItems.length > 0) {
        setIsOpen(true);
        setShouldBeExpanded(true);
      }
    }
  }, [activeCart]);

  useEffect(() => {
    if (currentCustomer.erpCustomerId && activeCart) {
      loadUploadedFilesOnPageLoad();
    }
  }, [currentCustomer, activeCart, dispatch, loadUploadedFilesOnPageLoad]);

  if (shouldBeExpanded) {
    return (
      <Accordion className="order-details-accordion" activeKey={isOpen ? '0' : '1'}>
        <Card>
          <Card.Header className="card-header w-100 p-3">
            <Accordion.Toggle
              data-testid="step3ToggleBtn"
              className="d-flex w-100 border-0 align-items-center justify-content-between bg-transparent"
              eventKey="0"
              onClick={() => setIsOpen(!isOpen)}>
              <div className="d-flex justify-content-start align-items-center w-50">
                <div className="rounded p-3 number-box d-flex align-items-center justify-content-center">3</div>
                <span className="ml-3 step-heading">Upload CAD / Related Files</span>
              </div>
              <i className={`fa fa-chevron-${isOpen ? 'up' : 'down'} float-right`}></i>
            </Accordion.Toggle>
          </Card.Header>
          <Accordion.Collapse eventKey="0">
            <Card.Body className="card-body">
              {uploadedFiles.length > 0 && <CambriaFileUpload files={uploadedFiles} fabOrderPage />}
              <Dropzone multiple={false} onDrop={(files) => uploadFile(files[0])}>
                {({getRootProps, getInputProps}) => (
                  <CambriaDropzone>
                    <div {...getRootProps()} className="d-flex justify-content-center align-items-center h-100 w-100">
                      <input {...getInputProps()} />
                      {fileUploadInProgress ? (
                        <span>Uploading file {fileUploadInProgress}...</span>
                      ) : (
                        <p className="mb-0">
                          DRAG A FILE HERE TO UPLOAD OR <span className="choose-file-btn">CHOOSE A FILE</span>
                        </p>
                      )}
                    </div>
                  </CambriaDropzone>
                )}
              </Dropzone>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    );
  } else {
    return (
      <Accordion className="order-details-accordion disabled-accordion-toggle">
        <Card>
          <Card.Header className="card-header w-100 p-3">
            <Accordion.Toggle
              data-testid="step3ToggleBtn"
              className="d-flex w-100 border-0 align-items-center justify-content-between bg-transparent disabled-accordion-toggle"
              eventKey="0">
              <div className="d-flex justify-content-start align-items-center w-50 disabled-accordion-toggle">
                <div className="rounded p-3 number-box d-flex align-items-center justify-content-center">3</div>
                <span className="ml-3 step-heading disabled-accordion-toggle">Upload CAD / Related Files</span>
              </div>
              <i className="fa fa-chevron-down float-right"></i>
            </Accordion.Toggle>
          </Card.Header>
        </Card>
      </Accordion>
    );
  }
};

export default FabOrderUploadFiles;
