import styled from 'styled-components/macro';
import * as colors from '../styles/colors';
import {xlarge, large} from '../styles/media-queries';

interface DropdownBodyProps {
  orientation: string;
  dropDownType: string;
}

export const DropdownToggle = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 17px;
  height: 100%;

  ${xlarge} {
    padding: 17px 10px;
  }
`;

export const DropdownBody = styled.div<DropdownBodyProps>`
  position: absolute;
  left: ${(props) => (props.orientation === 'left' ? '0' : '')};
  right: ${(props) => (props.orientation === 'right' ? '0' : '')};
  border-radius: ${(props) => (props.orientation === 'left' ? '0 3px 3px 3px' : '3px 0 3px 3px')};
  box-shadow: 0px 1px 2px rgb(0 0 0 / 20%);
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  padding: 15px;
  color: ${colors.black};
  display: none;
  background-color: ${colors.base};
  z-index: 100;
  min-width: min-content;
  width: ${(props) => (props.dropDownType === 'technicalInfo' ? '417px' : 'auto')};
  padding-top: 20px;
  padding-bottom: 20px;

  ${xlarge} {
    width: ${(props) => (props.dropDownType === 'technicalInfo' ? '385px' : 'auto')};
  }

  ${large} {
    width: ${(props) => (props.dropDownType === 'technicalInfo' ? '316px' : 'auto')};
  }
`;

export const DropdownContainer = styled.div`
  position: relative;
  height: 100%;

  &.compact ${DropdownToggle} {
    padding: 17px 4px;
  }

  &:hover ${DropdownToggle} {
    background-color: ${colors.base};
    border-radius: 3px 3px 0 0;
    box-shadow: 0px 1px 2px rgb(0 0 0 / 20%);
  }

  &:hover ${DropdownBody} {
    display: block;
  }
`;
