import styled from 'styled-components';

const QuoteHistoryStyled = styled.div`
  .gray-header-background {
    background-color: transparent;
    height: 0px;
  }

  .search-container {
    background: none;
    box-shadow: none;
    margin: 0px 0px 20px 0px;
    position: unset;

    .dropdown {
      position: unset;
    }

    .search-items-container {
      padding: 0px;
    }

    .display-params {
      margin-left: 0px;
    }
  }

  .search-container.global-search {
    top: 0px;
  }

  .no-results-row {
    background-color: #f8f8f8 !important;
    cursor: default;
  }

  .search-container {
    .btn {
      font-weight: 400;
    }
  }
`;

export default QuoteHistoryStyled;
