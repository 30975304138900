const COMMERCE_CORE_CONSTANTS = {
  API_SERVICES: {
    CALLBACK: {
      callbacks: '/callback/api/callbacks',
    },
    PRODUCT: {
      products: '/productcatalog/api/v2/products',
      productsInCart: '/productcatalog/api/v2/products/cart',
      productCatalog: '/productcatalog/api/v2/items',
      slabs: '/productcatalog/api/products/slabs',
      tiles: '/productcatalog/api/products/tiles',
      store: '/productcatalog/api/products/store',
      samples: '/productcatalog/api/products/samples',
      cartItems: '/productcatalog/api/cartitems',
      batchCartItems: '/productcatalog/api/batchCartItems',
      savedItems: '/productcatalog/api/saveditems',
      itemAvailability: '/productcatalog/api/v2/items/availability',
      item: '/productcatalog/api/v2/items',
      itemDetails: '/productcatalog/api/items/sku?sku=',
      productGroups: '/productcatalog/api/productgroups',
      productDesigns: '/productcatalog/api/fabricated/designs',
      fabricatedProducts: '/productcatalog/api/fabricatedproductgroups',
      fabricatedDesign: '/productcatalog/api/v2/fabricated/applications',
      batchItems: '/productcatalog/api/v2/items/batch',
      batchCartItemsAddSize: '/productcatalog/api/batchCartItemsAddSize',
      requestedDeliverySchedules: '/productcatalog/api/v2/requesteddeliveryschedules',
      bundleProgramRules: '/productcatalog/api/bundleprogramrules',
    },
    CART: {
      carts: '/cart/api/carts',
      resubmission: '/cart/api/carts/resubmit',
      editOrder: '/cart/api/orderedits',
      reorder: '/cart/api/carts/reorder',
    },
    PRICING: {
      itemPricings: '/pricing/api/itempricings',
      price: '/pricing/api/price',
      batchPrices: '/pricing/api/price/batch',
      cartVoucherCodes: '/pricing/api/cartvouchercodes',
      discounts: '/pricing/api/discounts',
      headerAdjustment: '/pricing/api/headeradjustment',
      cartHeaderAdjustment: '/pricing/api/cartheaderadjustment',
      singleItem: '/pricing/api/price/singleitem',
      singleItemBatch: '/pricing/api/price/singleitem/batch',
    },
    SHIPPING: {
      cartShippingMethods: '/shippingmethod/api/cartshippingmethods',
      shippingSelectionsV2: '/shippingmethod/api/v2/shippingselections',
      shippingSelections: '/shippingmethod/api/shippingselections',
      shippingZone: '/shippingmethod/api/shippingzones',
      shippingZonePricing: '/shippingmethod/api/shippingzonepricings',
      warehouses: '/shippingmethod/api/warehouses',
      holiday: '/shippingmethod/api/holiday',
    },
    PAYMENT: {
      cartPaymentMethods: '/payment/api/cartpaymentmethods',
      creditCards: '/payment/api/v2/paymentmethods/creditcards',
      paymentSelections: '/payment/api/paymentselections',
      clientToken: '/payment/api/clientToken',
      braintreeCreditCards: '/payment_methods/credit_cards',
    },
    TAX: {
      cartTaxCalculations: '/tax/api/carttaxCalculations',
    },
    ORDER: {
      orders: '/order/api/v2/orders',
      ordersByNumber: '/order/api/v2/ordersbynumber',
      cartOrderDetails: '/order/api/cartorderdetails',
      orderDetails: '/order/api/orderdetails',
      email: '/order/api/email',
      resubmitOrder: '/order/api/v2/orders/resubmit',
      reworkRreason: '/order/api/orderdetails/fabreworkreasons',
      orderNotificationContacts: '/order/api/orderNotificationContacts',
      ordersRejections: '/order/api/v2/orders/rejections',
      favorite: '/order/api/favorites',
    },
    QUOTE: {
      quotes: '/quote/api/quotes',
      cancelQuote: '/quote/api/quotes/cancel',
      requestExtension: '/quote/api/quotes/extend',
      cart: '/quote/api/cart',
      pdf: '/quote/api/quotes/pdf',
      expiring: '/quote/api/quotes/expiring',
      ackExpiring: '/ackexpiring',
    },
    SALESFORCE: {
      accounts: '/salesforcerelay/api/accounts',
      retailAccounts: '/salesforcerelay/api/retailaccounts',
      customerAccount: '/salesforcerelay/api/customeraccount',
      programs: '/salesforcerelay/api/programs',
      quotes: '/salesforcerelay/api/quotes',
      contacts: '/salesforcerelay/api/contacts',
      addresses: '/salesforcerelay/api/addresses',
      projectOpportunities: '/salesforcerelay/api/projectopportunity',
      partners: '/partners',
    },
    ENVIRONMENT: {
      frontEndGroups: '/environment/api/frontendgroups',
      uiSettings: '/environment/api/uisettings',
    },
    CUSTOMER: {
      customers: '/customer/api/customers',
      retailSites: '/customer/api/retailsites',
      purcashingCustomer: '/customer/api/retailsites/purchasingCustomer',
      endConsumers: '/customer/api/endconsumers',
      fabInstallAddress: '/customer/api/fabinstalladdress',
      fabInstallAddressAdmin: '/customer/api/fabinstalladdressadmin',
      fabricationOrderPreferences: '/customer/api/fabricationorderpreferences',
      slabOrderPreferences: '/customer/api/slaborderpreferences',
      samplesOrderPreferences: '/customer/api/sampleorderpreferences',
      preferredShippingContact: '/customer/api/preferredshippingcontact',
      fullProfile: '/FullProfile',
      sites: '/sites',
      site: '/site',
      contact: '/contact',
      statement: '/statement',
      statementRequestAccess: '/statement/requestaccess',
      loadReportV2: '/v2/loadreport',
      downloadReportV2: '/v2/loadreport/download',
      openSampleOrdersReport: '/opensampleorderreport',
      downloadOpenSampleOrdersReport: '/opensampleorderreport/download',
      openFabOrdersReport: '/openfaborderreport',
      downloadOpenFabOrdersReport: '/openfaborderreport/download',
      bolShipmentReport: '/bolshipmentreport',
      downloadBolShipmentReport: '/bolshipmentreport/download',
    },
    CUSTOMERGROUP: {
      customerClassGroups: '/customer/api/customerClassGroups',
    },
    SHIPPINGMETHOD: {
      productTypeWarehouses: '/shippingmethod/api/producttypewarehouses',
    },
    QUOTES: {
      quote: '/quote/api/quotes',
    },
    NOTIFICATION: {
      campaigns: '/notification/api/campaigns',
    },
    USERMANAGEMENT: {
      passwordResets: '/usermanagement/api/passwordresets',
      verifyToken: '/usermanagement/api/passwordresets/verifytoken',
      users: '/usermanagement/api/users',
    },
    LOCATION: {
      address: '/location/api/location/address',
      addressSearch: '/location/api/location/address/search',
      addressValidation: '/location/api/location/address/validation',
      countries: '/location/api/locations/countries',
      states: '/location/api/locations/states',
    },
    FILE: {
      files: '/file/api/files',
      purchaseAgreement: '/file/api/purchaseagreement/create',
    },
    LASTMILE: {
      proofOfDelivery: '/lastmile/api/proofofdelivery',
      qrCode: '/lastmile/api/qrcode',
    },
  },
  WINDOW_SESSION: {
    windowTabGUID: 'tabGuid',
    selectedCustomerId: 'CDMP_selectedCustomerId',
    selectedErpCustomerId: 'CDMP_selectedErpCustomerId',
    selectedSalesforceAccountId: 'CDMP_selectedSalesforceAccountId',
    selectedWarehouse: 'CDMP_selectedWarehouse',
    localStorageKey: 'CAMBRIA_LOCAL_STORE',
    selectedProgram: 'selectedProgramCode',
  },
  SITE_USE: {
    shipto: 'SHIP_TO',
    billto: 'BILL_TO',
  },
  PRODUCT_TYPES: {
    SLAB: 'Slab',
  },
  PROGRAMS: {
    BUNDLE_PROGRAM_CODE_PREFIX: 'BUNDLE',
  },
  PRODUCT_ATTRIBUTES: {
    thickness: 'Thickness',
  },
  WAREHOUSES: {
    CAMBRIA_PLANT: '211',
  },
  ERROR_MESSAGES: {
    CANT_SELECT_BUNDLE_ERROR: 'You have to select a different ship from warehouse to order in the bundle program',
    INVALID_BUNDLE_PROGRAM_CART: 'You must have complete bundles to checkout. Add an additional half bundle. ',
    INVALID_BUNDLE_REV_H_PROGRAM_CART: 'Cart invalid. You must finish your bundle.',
    CART: {
      FABRICATION_MISSING_PURCHASING_CUSTOMER:
        'A purchasing customer must be added in the fabrication tab in order to proceed to checkout. ',
      FABRICATION_MISSING_END_CONSUMER:
        'An end consumer must be added in the fabrication tab in order to proceed to checkout. ',
      FABRICATION_MISSING_PURCHASING_CUSTOMER_END_CONSUMER:
        'A purchasing customer and end consumer must be added in the fabrication tab in order to proceed to checkout. ',
    },
    PURCHASING_AGREEMENT: {
      FABRICATION_MISSING_PURCHASING_CUSTOMER:
        'A purchasing customer must be added in the fabrication tab in order to create a purchasing agreement. ',
      FABRICATION_MISSING_END_CONSUMER:
        'An end consumer must be added in the fabrication tab in order to create a purchasing agreement. ',
      FABRICATION_MISSING_PURCHASING_CUSTOMER_END_CONSUMER:
        'A purchasing customer and end consumer must be added in the fabrication tab in order to create a purchasing agreement. ',
    },
    FAB_LINE_ITEMS_LIMIT_REACHED:
      'You have reached the line item limit for a fabrication order. If there are more items to add, this order will need to be split into separate orders',
    REORDER_LINE_ITEMS_OVER_LIMIT:
      'This order exceeds the line item limit for a fabrication order. This order will need to be re-entered manually as separate orders.',
  },
  PATHNAMES: {
    login: '/login',
    passwordReset: '/passwordReset',
    confirmReset: '/confirmReset',
    home: '/',
    baseSearch: '/search/',
    customerSearch: '/search/customers',
    orderSearch: '/search/orders',
    baseCheckout: '/checkout/',
    orderConfirmation: '/checkout/orderConfirmation/',
    shippingAndDelivery: '/checkout/shipping-and-delivery',
    orderDetails: '/checkout/order-details',
    purchasingAgreement: '/purchaseagreement',
    cart: '/cart',
    fabrication: '/fabrication',
    productDetails: '/product/details/',
    productQuick: '/quick',
    searchResults: '/searchResults',
    baseAccountOrder: '/order/',
    baseAccount: '/account',
    accountOrderDetails: '/account/orders/details',
    accountQuoteDetails: '/account/quotes/details',
    pdfViewer: '/pdfViewer',
  },
  REPORTS: {
    openSampleOrders: 'OpenSampleReport',
    openFabOrders: 'OpenFabReport',
    bolShipment: 'BolShipmentReport',
  },
  customerCareEmailAddress: 'CustomerCare@CambriaUSA.com',
  customerCarePhoneNumber: '1-866-226-2742',
};

export default COMMERCE_CORE_CONSTANTS;
