import {baseApiCallWithReauth} from '../../../Framework/api-utils/api-utils';
import COMMERCE_CORE_CONSTANTS from '../../../Core/constants';
import qs from 'qs';

export const getShippingWarehousesRequest = async (code: string) => {
  try {
    return await baseApiCallWithReauth(
      'GET',
      COMMERCE_CORE_CONSTANTS.API_SERVICES.SHIPPING.warehouses,
      qs.stringify({warehouseCode: code})
    );
  } catch (error: any) {
    throw error;
  }
};
