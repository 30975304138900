import styled from 'styled-components';
import {$brandprimary, $cartpreviewwidth, $gray67} from '../../../../Styled/variables';

export const CartPreviewContainer = styled.div`
  position: fixed;
  width: ${$cartpreviewwidth};
  top: 0;
  bottom: 0;
  right: 0;
  background: white;
  padding: 16px 8px 0;
  overflow-y: auto;

  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;

  .total-value {
    color: ${$brandprimary};
  }

  .items-list {
    padding-top: 16px;

    .bundle {
      margin-bottom: 32px;
    }

    .bundle-title {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid black;
    }

    .bundle-content {
      padding-left: 8px;
    }

    .cartitem {
      border-top: 1px solid ${$gray67};
      padding-top: 4px;
    }

    .item-image {
      width: 100%;
      height: auto;
    }

    .item-description {
      margin-bottom: 4px;
    }

    .price {
      font-size: 12px;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
    }

    .price-value {
      display: flex;
      flex-direction: column;
    }

    .actions {
      display: flex;
      margin-top: 8px;
      justify-content: space-around;
      margin-bottom: 8px;

      .qty-input {
        margin-right: 4px;
      }
    }
  }
`;
