import React, {useState} from 'react';
import {QuoteSearchParams} from '../../../../../../../features/search/ISearchState';
import DatePicker from 'react-datepicker';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import {useHistory} from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';
import {cleanSearchParams} from '../../../../../../../features/order/service/order.service';

const DateInput = ({urlSearchParams, url}: {urlSearchParams: QuoteSearchParams; url: string}) => {
  const history = useHistory();

  let [dateMin, setDateMin] = useState(new Date());
  let [dateMax, setDateMax] = useState(new Date());

  const addDateParam = (currentReduxFilters: QuoteSearchParams, dateMin: Date, dateMax: Date) => {
    let currentReduxCopy = {...currentReduxFilters};
    dateMin.setHours(0, 0, 0, 0);
    dateMax.setHours(23, 59, 59);
    currentReduxCopy['dateRange.dateFrom'] = dateMin.toISOString();
    currentReduxCopy['dateRange.dateTo'] = dateMax.toISOString();

    let data = new URLSearchParams(currentReduxCopy);
    data = cleanSearchParams(data);
    history.push({
      pathname: url,
      search: '?' + data.toString(),
    });
  };

  return (
    <div id="react-bootstrap-hack" className="input-and-button-container">
      <div className="input-group">
        <div className="date-min">
          <div className="input-group-prepend">
            <span className="input-group-text" id="basic-addon1">
              From
            </span>
          </div>
          <DatePicker
            showWeekNumbers
            disabledKeyboardNavigation
            placeholderText="Select a date..."
            selected={dateMin}
            onChange={(value: any) => setDateMin(value)}
            maxDate={new Date()}
          />
        </div>
        <div className="date-max">
          <div className="input-group-prepend">
            <span className="input-group-text" id="basic-addon1">
              To
            </span>
          </div>
          <DatePicker
            showWeekNumbers
            disabledKeyboardNavigation
            placeholderText="Select a date..."
            selected={dateMax}
            onChange={(value: any) => setDateMax(value)}
            maxDate={new Date()}
            minDate={dateMin}
          />
        </div>
      </div>
      <div className="search-button-container">
        <button className="search-button btn-primary" onClick={() => addDateParam(urlSearchParams, dateMin, dateMax)}>
          SEARCH
        </button>
      </div>
    </div>
  );
};

export default DateInput;
