import styled from 'styled-components/macro';
import {medium, small} from '../../../../../../Framework/Components/styles/media-queries';

export interface ProductImageZoomContainerProps {}

export const ProductImageZoomContainer = styled.div<ProductImageZoomContainerProps>`
  justify-content: center;
  margin: 0px !important;

  .carousel-container {
    justify-content: center;
  }

  .carousel-section {
    background-color: #f7f8f9 !important;
    /* margin-bottom: 33px !important; */
  }

  .carousel-card-group {
    justify-content: center !important;
    background-color: #f7f8f9 !important;
  }

  .card {
    margin: 0px 15px;
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;
    box-shadow: 0 1px 1px rgb(0 0 0 / 15%);
  }

  .carousel-item-wrapper {
    width: 40px;
    height: 40px !important;
    margin: 0px 10px;
    box-shadow: 0 1px 1px rgb(0 0 0 / 15%);
    background-color: #fff;
    overflow: hidden;
  }

  .cta {
    color: #c59617 !important;

    :hover {
      color: rgb(197, 150, 23, 0.5);
    }
  }

  .carousel-heading {
    font-family: Montserrat;
    font-weight: 300;
    font-size: 36px;
    line-height: 44px;
    text-transform: uppercase;
    color: #4c4c4c;
    margin: 100px 0 50px 0;
  }

  .card-title {
    margin-bottom: 20px !important;
  }

  .card-text {
    margin-bottom: 20px !important;
  }

  .card-img-top {
    margin-bottom: 30px;
    width: auto !important;
    height: 100%;
  }

  .carousel-control-prev {
    width: unset !important;
  }

  .carousel-control-next {
    width: unset !important;
  }

  .carousel-dot {
    background-color: black !important;
  }

  .carousel {
  }

  .carousel-inner {
    overflow: inherit !important;
    margin: 20px 0px;
  }

  .icon {
    color: black !important;
    font-weight: 700 !important;
    top: 190px !important;
  }

  .icons-cambria-Ui-Right-Arrow-No-Tail {
    left: 10px;
  }

  .icons-cambria-Ui-Left-Arrow-No-Tail {
    right: 70px;
  }

  .card-body {
    padding: 0.25rem !important;
    display: contents;
  }

  ${medium} {
    .carousel-heading {
      font-size: 32px;
    }

    .cambria-carousel {
      margin-bottom: -25px;
      margin-top: -25px;
    }
  }

  ${small} {
    .icons-cambria-Ui-Right-Arrow-No-Tail,
    .icons-cambria-Ui-Left-Arrow-No-Tail {
      display: contents;
    }

    .carousel,
    .carousel-indicators {
      width: 86vw;
    }

    .carousel-heading {
      font-size: 24px;
    }

    .cambria-carousel {
      margin-bottom: -50px;
      margin-top: -50px;
    }
  }
`;
