import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {AuthenticationRequest, AuthenticationState} from '../IAuthenticationState';
import {forceLogout, getSavedAuth, sendAuthRequest} from '../service/authentication.service';
import {pushUserLoginEventToDataLayer} from '../../analytics/service/analytics.service';
import {RootState} from '../../../store/store';

export const initialState = getSavedAuth();

export const loginAsync = createAsyncThunk(
  'authentication/login',
  async (request: AuthenticationRequest, {rejectWithValue}) => {
    const data = new URLSearchParams();
    data.append('grant_type', 'password');
    data.append('client_id', 'Internal');
    data.append('username', request.email);
    data.append('password', request.password);
    return await sendAuthRequest(data, rejectWithValue, false, request.rememberMe);
  }
);

export const refreshTokenAsync = createAsyncThunk(
  'authentication/refreshToken',
  async (refreshToken: string, {rejectWithValue}) => {
    const data = new URLSearchParams();
    data.append('grant_type', 'refresh_token');
    data.append('refresh_token', refreshToken);
    return await sendAuthRequest(data, rejectWithValue);
  }
);

export const anonymousTokenAsync = createAsyncThunk('authentication/anonymousToken', async (_, {rejectWithValue}) => {
  const data = new URLSearchParams();
  data.append('grant_type', 'password');
  return await sendAuthRequest(data, rejectWithValue, true);
});

export const authenticationSlice = createSlice({
  name: 'authentication',
  initialState,
  reducers: {
    logout: (state: AuthenticationState) => {
      state.isAuthenticated = false;
      state.isAuthenticating = false;
      state.isRefreshing = false;
      state.user = undefined;
      state.response = undefined;
      state.error = '';

      forceLogout();
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginAsync.pending, (state) => {
        state.isAuthenticated = false;
      })
      .addCase(loginAsync.rejected, (state) => {
        state.isAuthenticated = false;
        pushUserLoginEventToDataLayer(false);
      })
      .addCase(loginAsync.fulfilled, (state, action) => {
        state.isAuthenticated = action.payload.isAuthenticated;
        state.isAuthenticating = action.payload.isAuthenticating;
        state.isRefreshing = action.payload.isRefreshing;
        state.user = action.payload.user;
        state.response = action.payload.response;
        state.error = action.payload.error;
        pushUserLoginEventToDataLayer(action.payload.isAuthenticated);
      })
      .addCase(refreshTokenAsync.pending, (state) => {
        state.isRefreshing = true;
      })
      .addCase(refreshTokenAsync.fulfilled, (state, action) => {
        state.isAuthenticated = action.payload.isAuthenticated;
        state.isAuthenticating = action.payload.isAuthenticating;
        state.isRefreshing = action.payload.isRefreshing;
        state.user = action.payload.user;
        state.response = action.payload.response;
        state.error = action.payload.error;
      })
      .addCase(anonymousTokenAsync.pending, (state) => {
        state.isAuthenticated = false;
      })
      .addCase(anonymousTokenAsync.fulfilled, (state, action) => {
        state.isAuthenticated = action.payload.isAuthenticated;
        state.isAuthenticating = action.payload.isAuthenticating;
        state.isRefreshing = action.payload.isRefreshing;
        state.user = action.payload.user;
        state.response = action.payload.response;
        state.error = action.payload.error;
      });
  },
});

export const {logout} = authenticationSlice.actions;

export const selectIsAuthenticated = (state: RootState) => state.authentication.isAuthenticated;
export const selectIsRefreshing = (state: RootState) => state.authentication.isRefreshing;
export const selectIsRefreshNeeded = (state: RootState) => state.authentication.isRefreshNeeded;
export const selectAuthenticationResponse = (state: RootState) => state.authentication.response;
export const selectCurrentUser = (state: RootState) => state.authentication.user;

export default authenticationSlice.reducer;
