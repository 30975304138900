import React from 'react';
import {toast} from 'react-toastify';
import {baseApiCallWithReauth} from '../../../../../../Framework/api-utils/api-utils';
import CambriaButton from '../../../../../../Framework/Components/CambriaButton';
import RequestAccessStyled from './RequestAccess.styled';

const RequestAccessPage = ({endpoint}: {endpoint: string}) => {
  const requestAccess = async (endpoint: string) => {
    let requestAccessEndpoint = (endpoint += '/requestaccess');
    let request = await baseApiCallWithReauth('POST', requestAccessEndpoint);
    if (request === 'No Content') {
      toast.success('Request for access submitted.  Please allow 1-2 business days for review.');
    }
  };

  return (
    <RequestAccessStyled>
      <CambriaButton data-testid="request-access-button" onClick={() => requestAccess(endpoint)}>
        Request Access
      </CambriaButton>
      <p>
        For immediate inquiries about outstanding balances, please contact Accounts Receivable at 952-873-4800 or
        AccountsReceivable@CambriaUSA.com.
      </p>
    </RequestAccessStyled>
  );
};

export default RequestAccessPage;
