import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import qs from 'qs';
import {baseApiCallWithReauth} from '../../../Framework/api-utils/api-utils';

import type {RootState} from '../../../store/store';

const initialState: any = {
  id: null,
  app: null,
  actions: [],
};

export const getUserActionsAsync = createAsyncThunk(
  'permission/getUserActionsAsync',
  async ({userId}: {userId: string}) => {
    const endpoint = `/usermanagement/api/users/${userId}/actions`;
    return await baseApiCallWithReauth(
      'GET',
      endpoint,
      qs.stringify({
        primaryResource: 'commerceui',
      }),
      false,
      true
    );
  }
);

export const permissionSlice = createSlice({
  name: 'permission',
  initialState,
  reducers: {
    setUserId: (state, action) => {
      state.id = action.payload;
    },
    setUserActions: (state, action) => {
      state.actions = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUserActionsAsync.fulfilled, (state: any, action: any) => {
      state.actions = action.payload.results;
    });
  },
});

export const {setUserId, setUserActions} = permissionSlice.actions;

export const selectPermissionId = (state: RootState) => state.permission.id;
export const selectUserActions = (state: RootState) => state.permission.actions;

export default permissionSlice.reducer;
