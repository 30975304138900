import React, {FC, useState} from 'react';
import CreditCardForm from '../../../../App/AuthenticatedPages/Account/PaymentPreferences/CreditCardForm/CreditCardForm.component';
import CambriaModal from '../../../../Framework/Components/CambriaModal/CambriaModal.component';
import {CreditCard} from '../../../../features/payment/IPaymentState';

interface EditCreditCardModalProps {
  card?: CreditCard;
  show?: boolean;
  toggleShow?: any;
}

const EditCreditCardModal: FC<EditCreditCardModalProps> = ({card, show, toggleShow}) => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const modalOptions = {
    title: 'Edit Payment Method',
    cancelButtonText: 'CANCEL',
    confirmButtonText: 'SAVE',
    formName: 'editCardForm',
  };

  return (
    <div>
      <CambriaModal
        hideSubmitButton
        hideCancelButton
        show={show}
        toggleShow={toggleShow}
        heading={modalOptions.title}
        cancelButton={modalOptions.cancelButtonText}
        confirmButton={modalOptions.confirmButtonText}
        disableSubmitBtn={isSubmitting}
        formName={modalOptions.formName}>
        {card && (
          <h3 className="m-b-md">
            {card.cardType} CARD ENDING IN {card.lastFour}
          </h3>
        )}
        <CreditCardForm
          creditCardData={card}
          isSubmitting={setIsSubmitting}
          onSumbitCallback={toggleShow}
          onCancelCallback={toggleShow}
          isCreditCardEdit
        />
      </CambriaModal>
    </div>
  );
};

export default EditCreditCardModal;
