import React from 'react';
import {selectCurrentUser} from '../../../../../features/auth/slice/authentication.slice';
import useWindowDimensions from '../../../../../hooks/getWindowDimensions';
import {useTypedSelector} from '../../../../../hooks/store';
import DesktopPage from './desktop';
import MobilePage from './mobile';

const ToggleMobile = ({
  width,
  orderSuggestions,
  isLoading,
  userId,
}: {
  width: number;
  orderSuggestions: any[];
  isLoading: boolean;
  userId?: string;
}) => {
  if (width < 960) {
    return <MobilePage orderSuggestions={orderSuggestions} isLoading={isLoading} userId={userId} />;
  }
  return <DesktopPage orderSuggestions={orderSuggestions} isLoading={isLoading} userId={userId} />;
};

const OrderTable = ({orderSuggestions, isLoading}: {orderSuggestions: any[]; isLoading: boolean}) => {
  const {width} = useWindowDimensions();
  const userId = useTypedSelector(selectCurrentUser)?.userId;

  return (
    <div className="order-search-section container">
      <div className="order-list-header">
        <h1 className="h2 light-weight clearfix">ORDERS</h1>
      </div>
      <ToggleMobile width={width} orderSuggestions={orderSuggestions} isLoading={isLoading} userId={userId} />
    </div>
  );
};
export default OrderTable;
