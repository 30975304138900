import styled from 'styled-components/macro';
import {$gray67, $newprimary, $screenmd} from '../../../../Styled/variables';

export const PaShippingAndDeliveryContainer = styled.div`
  #submit-purchase-agreement {
    margin-left: -25px;

    @media screen and (max-width: ${$screenmd}) {
      margin-left: 0;
    }
  }

  .current-warehouse {
    font-weight: 600;
    color: ${$gray67};
    position: absolute;
    top: 0;
    right: 15px;
  }

  .button-section {
    margin-top: 29px;

    button {
      padding: 6px 20px;
      text-transform: uppercase;
      font-size: 14px !important;
      width: 260px;
    }

    @media screen and (max-width: ${$screenmd}) {
      margin-top: 0;
      margin-bottom: 10px;

      button {
        margin-left: auto;
      }
    }
  }

  .field-info {
    position: relative;
    bottom: 8px;
    display: flex;
    justify-content: space-between;
    width: inherit;
    font-weight: 400;
    font-size: 14px;

    .cta {
      cursor: pointer;
      color: ${$newprimary};
    }
  }
`;
