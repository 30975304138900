import {useHistory} from 'react-router-dom';
import {setCartInitialState} from '../features/cart/slice/cart.slice';
import {setCheckoutInitialState} from '../features/checkout/slice/checkout.slice';
import {setCustomerInitialState} from '../features/customer/slice/customer.slice';
import {setFabricationInitialState} from '../features/fabrication/slice/fabrication.slice';
import {setFileInitialState} from '../features/file/slice/file.slice';
import {setOrderInitialState} from '../features/order/slice/order.slice';
import {setPaymentInitialState} from '../features/payment/slice/payment.slice';
import {setQuoteInitialState} from '../features/quote/slice/quote.slice';
import {setReportsInitialState} from '../features/reports/slice/reports.slice';
import {
  setAllCustomerInformationLoaded,
  setCartLoaded,
  setCustomerIsValid,
  setDefaultCustomer,
  setSalesforceInformationLoaded,
} from '../features/resolves/slice/resolves.slice';
import {setSalesforceInitialState} from '../features/salesforce/slice/salesforce.slice';
import {setWarehouseInitialState} from '../features/warehouse/slice/warehouse.slice';
import {useAppDispatch} from './store';
import {useCallback} from 'react';

export const useCustomerLogout = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  return useCallback(() => {
    dispatch(setAllCustomerInformationLoaded(false));
    dispatch(setSalesforceInformationLoaded(false));
    dispatch(setCartLoaded(false));
    dispatch(setCustomerIsValid(false));
    dispatch(setDefaultCustomer(null));
    window.sessionStorage.clear();
    dispatch(setCustomerInitialState());
    dispatch(setSalesforceInitialState());
    dispatch(setWarehouseInitialState());
    dispatch(setCartInitialState());
    dispatch(setPaymentInitialState());
    dispatch(setFabricationInitialState());
    dispatch(setFileInitialState());
    dispatch(setOrderInitialState());
    dispatch(setQuoteInitialState());
    dispatch(setReportsInitialState());
    dispatch(setCheckoutInitialState());
    if (
      window.location.href.includes('/account/orders/details') ||
      window.location.href.includes('/account/quotes/details')
    ) {
      history.push('/search/customers');
    }
  }, [dispatch, history]);
};
