import React, {useState, FC, useEffect, useCallback, useRef} from 'react';
import {Form, Formik} from 'formik';
import * as Yup from 'yup';
import 'yup-phone';
import {Alert, Col, Container, Row} from 'react-bootstrap';
import CambriaModal from '../../../../Framework/Components/CambriaModal';
import CambriaInput from '../../../../Framework/Components/CambriaInput';
import CambriaAutosuggest from '../../../../Framework/Components/CambriaAutosuggest';
import {useAppDispatch, useTypedSelector} from '../../../../hooks/store';
import {selectActiveProgram, selectAvailablePrograms} from '../../../../features/salesforce/slice/salesforce.slice';
import {SalesforceRetailAccount} from '../../../../features/customer/ICustomerState';
import {
  addressSearchAsync,
  getAddressValidation,
  getStatesOrProvinces,
  selectAddressSearchResults,
  selectAddressValidation,
  selectCountries,
  selectStates,
} from '../../../../features/location/slice/location.slice';
import {
  selectCurrentCustomer,
  selectCurrentCustomerBillToSites,
  selectCurrentCustomerShipToSites,
} from '../../../../features/customer/slice/customer.slice';
import {toast} from 'react-toastify';
import {selectActiveCart, setActiveCart} from '../../../../features/cart/slice/cart.slice';
import {selectCurrentUser} from '../../../../features/auth/slice/authentication.slice';
import {getPurchasingCustomersAsync} from '../../../../features/fabrication/slice/fabrication.slice';
import CambriaSelect from '../../../../Framework/Components/CambriaSelect';
import {ICountry, IState} from '../../../../features/location/ILocationState';
import {
  hideFullscreenLoader,
  showFullscreenLoader,
} from '../../../../features/fullscreenLoader/slice/fullscreenLoader.slice';
import {ISalesforceAvailablePrograms} from '../../../../features/salesforce/ISalesforceState';
import {
  postCustomerRetailSitesRequest,
  putCustomerRetailSitesRequest,
} from '../../../../features/fabrication/controller/fabrication.controller';
import {getCart} from '../../../../features/cart/service/cart.service';

interface AddPurchasingCustomerModalProps {
  availableAddresses: any;
  show?: boolean;
  toggleShow?: any;
  customerToEdit?: any;
  onSubmitCallback?: any;
  fabOrderEntry?: boolean;
  canAddNewPurchasingCustomer?: boolean;
}

const AddPurchasingCustomerModal: FC<AddPurchasingCustomerModalProps> = ({
  availableAddresses,
  show,
  toggleShow,
  customerToEdit,
  onSubmitCallback,
  fabOrderEntry,
  canAddNewPurchasingCustomer = true,
}) => {
  const dispatch = useAppDispatch();
  let formikRef = useRef();

  const [isAddressValidationInProgress, setIsAddressValidationInProgress] = useState<boolean>(false);
  const [isAddingSiteInProgress, setIsAddingSiteInProgress] = useState<boolean>(false);
  const [isFormVisible, setIsFormVisible] = useState<boolean>(false);
  const [isStateRequired, setIsStateRequired] = useState<boolean>(false);
  const [validationErrors, setValidationErrors] = useState<Array<string>>([]);
  const [originalAddress, setOriginalAddress] = useState<string>('');
  const [showSuggestionsSection, setShowSuggestionsSection] = useState<boolean>(false);
  const [addressSuggestions, setAddressSuggestions] = useState<Array<any>>([]);
  const [selectedState, setSelectedState] = useState<IState>();
  const [selectedCountry, setSelectedCountry] = useState<ICountry>();
  const [retailCrmAccountId, setRetailCrmAccountId] = useState('');

  const activeCart = useTypedSelector(selectActiveCart);
  const activeProgram = useTypedSelector(selectActiveProgram);
  const customerShipToSites = useTypedSelector(selectCurrentCustomerShipToSites);
  const customerBillToSites = useTypedSelector(selectCurrentCustomerBillToSites);
  const allCountries = useTypedSelector(selectCountries);
  const currentCustomer = useTypedSelector(selectCurrentCustomer);
  const currentUser = useTypedSelector(selectCurrentUser);
  const states = useTypedSelector(selectStates);
  const validationResult = useTypedSelector(selectAddressValidation);
  const address = useTypedSelector(selectAddressSearchResults);
  const availablePrograms: ISalesforceAvailablePrograms | null = useTypedSelector(selectAvailablePrograms);

  const modalOptions = {
    title: customerToEdit ? 'Edit Purchasing Customer Information' : 'Enter New Purchasing Customer Information',
    cancelButtonText: 'CANCEL',
    confirmButtonText: customerToEdit ? 'UPDATE' : 'ADD ADDRESS',
    formName: customerToEdit ? 'editPurchasingCustomerForm' : 'addPurchasingCustomerForm',
  };

  const initialFormValues = {
    selectExistingAddress: '',
    companyName: customerToEdit?.siteName || '',
    firstName: customerToEdit?.contactFirstName || '',
    lastName: customerToEdit?.contactLastName || '',
    country: '',
    address1: customerToEdit?.address1 || '',
    address2: customerToEdit?.address2 || '',
    address3: customerToEdit?.address3 || '',
    city: customerToEdit?.city || '',
    state: '',
    postalCode: customerToEdit?.postalCode || '',
    phoneNumber: customerToEdit?.contactPhoneNumber || '',
    email: customerToEdit?.contactEmail || '',
    isFormVisible: !!customerToEdit,
  };

  const validationSchema = Yup.object({
    selectExistingAddress: isFormVisible ? Yup.mixed() : Yup.mixed().required('This field is required'),
    companyName: isFormVisible ? Yup.string().required('This field is required') : Yup.string(),
    firstName: Yup.string(),
    lastName: Yup.string(),
    country: Yup.mixed().required('This field is required'),
    address1: isFormVisible ? Yup.string().required('This field is required') : Yup.string(),
    address2: Yup.string(),
    address3: Yup.string(),
    city: isFormVisible ? Yup.string().required('This field is required') : Yup.string(),
    state: isFormVisible && isStateRequired ? Yup.mixed().required('This field is required') : Yup.mixed(),
    postalCode: isFormVisible ? Yup.string().required('This field is required') : Yup.string(),
    phoneNumber: Yup.string(),
    email: Yup.string().email('Invalid email'),
    isFormVisible: Yup.mixed(),
  });

  const onExistingAddressChange = useCallback(
    (props: any, selectedSite: any) => {
      const {site} = selectedSite;
      if (props.values && site) {
        if (site.retailCrmAccountId) {
          setRetailCrmAccountId(site.retailCrmAccountId);
        } else {
          setRetailCrmAccountId('');
        }
        props.values.country = allCountries.find(
          (data: any) => data.alpha2Code.toLowerCase() === site.country.toLowerCase()
        );
        props.values.address1 = site.address1;
        props.values.city = site.city;
        props.values.state = states.find(
          (state: any) => state.locationCode.toLowerCase() === site.stateProvince.toLowerCase()
        );
        props.values.postalCode = site.postalCode;
        props.values.companyName = site.siteName;
      }
    },
    [allCountries, states]
  );

  const onAddressValidation = async (query: string) => {
    if (selectedCountry?.alpha2Code?.toLowerCase() === 'us') {
      await dispatch(addressSearchAsync({keyword: query}));
    }
  };

  const onAddressSelect = async (props: any, selectedAddress: any) => {
    if (props.values && selectedAddress) {
      props.values.country = allCountries.find(
        (data: any) => data.alpha2Code.toLowerCase() === selectedAddress.countryCode2Alpha.toLowerCase()
      );

      props.values.address2 = selectedAddress.addressLine2;
      props.values.address3 = selectedAddress.addressLine3;
      props.values.city = selectedAddress.city;
      props.values.state = states.find(
        (x: any) => x.locationCode.toLowerCase() === selectedAddress.state.toLowerCase()
      );
      props.values.postalCode = selectedAddress.postalCode;

      setAddressSuggestions(() => []);
      setShowSuggestionsSection(false);
      setValidationErrors(() => []);
      await dispatch(getAddressValidation(null));
    }
  };

  const hasAddressChangedResult = (form: any, records: any) => {
    // Result codes check excluding AC01 (see http://wiki.melissadata.com/index.php?title=Result_Codes)
    const resultCodesChecked = ['AC02', 'AC03', 'AC04', 'AC05', 'AC10', 'AC11', 'AC12', 'AC13', 'AC14', 'AC20'];
    let hasAddressChange = false;
    if (records.resultCodes && records.resultCodes.length > 0) {
      for (const resultCode of records.resultCodes) {
        if (resultCodesChecked.indexOf(resultCode.code) > -1) {
          hasAddressChange = true;
        }

        // Making sure that the postal code input and response are not equal before considering AC01
        if (resultCode.code === 'AC01' && form.postalCode !== records.postalCode) {
          hasAddressChange = true;
        }
      }
    }
    return hasAddressChange;
  };

  const formatExistingAddress = (form: any) => {
    return {
      companyName: form.companyName,
      firstName: form.firstName,
      lastName: form.lastName,
      country: form.country,
      address1: form.address1,
      address2: form.address2,
      address3: form.address3,
      state: form.state,
      city: form.city,
      postalCode: form.postalCode,
      phoneNumber: form.phoneNumber,
      email: form.email,
    };
  };

  const formatOriginalAddress = (form: any) => {
    const addressMap: any = {
      address1: form.address1,
      address2: form.address2,
      address3: form.address3,
      city: form.city,
      stateProvince: form.state && form.state.locationCode,
      postalCode: form.postalCode,
      country: form.country.countryName,
    };

    let address = '';
    for (const key in addressMap) {
      if (addressMap[key]) {
        address += `${addressMap[key]} `;
      }
    }

    setOriginalAddress(address);
  };

  const formatSuggestionAddress = (suggestion: any) => {
    const addressMap: any = {
      address1: suggestion.addressLine1,
      address2: suggestion.addressLine2,
      address3: suggestion.addressLine3,
      city: suggestion.city,
      stateProvince: suggestion.state,
      postalCode: suggestion.postalCode,
      country: suggestion.country,
    };

    let address = '';
    for (const key in addressMap) {
      if (addressMap[key]) {
        address += `${addressMap[key]} `;
      }
    }

    return address;
  };

  const getNewCart = useCallback(async () => {
    const cart = await getCart(
      activeCart?.id || '',
      currentCustomer,
      currentCustomer?.id?.toString() || '0',
      activeProgram,
      customerShipToSites || [],
      customerBillToSites || [],
      dispatch,
      availablePrograms
    );
    await dispatch(setActiveCart(cart));
  }, [
    activeCart?.id,
    activeProgram,
    customerShipToSites,
    customerBillToSites,
    currentCustomer,
    dispatch,
    availablePrograms,
  ]);

  const onSaveSuccess = useCallback(async () => {
    setAddressSuggestions(() => []);
    await dispatch(getAddressValidation(null));
    await dispatch(addressSearchAsync({keyword: null}));
    await getNewCart();

    if (!customerToEdit) {
      toast.success('Address Saved');
    } else {
      toast.success('Customer updated successfully');
    }
  }, [customerToEdit, dispatch, getNewCart]);

  const onReset = useCallback(
    async (resetForm: Function) => {
      if (!customerToEdit) {
        setIsFormVisible(false);
        setAddressSuggestions(() => []);
        setShowSuggestionsSection(false);
        resetForm({
          values: {
            selectExistingAddress: '',
            companyName: '',
            firstName: '',
            lastName: '',
            country: '',
            address1: '',
            address2: '',
            address3: '',
            city: '',
            state: '',
            postalCode: '',
            phoneNumber: '',
            email: '',
            isFormVisible: '',
          },
        });
        await dispatch(addressSearchAsync({keyword: null}));
        await dispatch(getAddressValidation(null));
      }
    },
    [dispatch, customerToEdit]
  );

  const saveNewAddress = useCallback(
    async (originalAddress: any, suggestion: any, formikRef: any) => {
      if (currentCustomer && !isAddingSiteInProgress) {
        dispatch(showFullscreenLoader({showCartIcon: false}));
        setIsAddingSiteInProgress(true);
        let params: any = {};
        if (originalAddress) {
          params = {
            address1: originalAddress.address1 ? originalAddress.address1.trim() : null,
            address2: originalAddress.address2 ? originalAddress.address2.trim() : null,
            address3: originalAddress.address3 ? originalAddress.address3.trim() : null,
            city: originalAddress.city ? originalAddress.city.trim() : null,
            countryCode: originalAddress.country.alpha2Code,
            postalCode: originalAddress.postalCode ? originalAddress.postalCode.trim() : null,
            stateProvinceCode: originalAddress.state ? originalAddress.state.locationCode : null,
            siteName:
              originalAddress && originalAddress.companyName
                ? originalAddress.companyName.trim()
                : formikRef && formikRef.values && formikRef.values.companyName
                ? formikRef.values.companyName.trim()
                : null,
            contactFirstName:
              originalAddress && originalAddress.firstName
                ? originalAddress.firstName.trim()
                : formikRef && formikRef.values && formikRef.values.firstName
                ? formikRef.values.firstName.trim()
                : null,
            contactLastName:
              originalAddress && originalAddress.lastName
                ? originalAddress.lastName.trim()
                : formikRef && formikRef.values && formikRef.values.lastName
                ? formikRef.values.lastName.trim()
                : null,
            contactPhoneNumber:
              originalAddress && originalAddress.phoneNumber
                ? originalAddress.phoneNumber.trim()
                : formikRef && formikRef.values && formikRef.values.phoneNumber
                ? formikRef.values.phoneNumber.trim()
                : null,
            contactEmail:
              originalAddress && originalAddress.email
                ? originalAddress.email.trim()
                : formikRef && formikRef.values && formikRef.values.email
                ? formikRef.values.email.trim()
                : null,
          };
        }
        if (suggestion) {
          params = {
            address1: suggestion.addressLine1 ? suggestion.addressLine1.trim() : null,
            address2: suggestion.addressLine2 ? suggestion.addressLine2.trim() : null,
            address3: suggestion.addressLine3 ? suggestion.addressLine3.trim() : null,
            city: suggestion.city,
            countryCode: suggestion.countryCode2Alpha,
            postalCode: suggestion.postalCode,
            stateProvinceCode: suggestion.state,
            siteName:
              formikRef && formikRef.values && formikRef.values.companyName
                ? formikRef.values.companyName.trim()
                : null,
            contactFirstName:
              formikRef && formikRef.values && formikRef.values.firstName ? formikRef.values.firstName.trim() : null,
            contactLastName:
              formikRef && formikRef.values && formikRef.values.lastName ? formikRef.values.lastName.trim() : null,
            contactPhoneNumber:
              formikRef && formikRef.values && formikRef.values.phoneNumber
                ? formikRef.values.phoneNumber.trim()
                : null,
            contactEmail:
              formikRef && formikRef.values && formikRef.values.email ? formikRef.values.email.trim() : null,
          };
        }
        params.isActive = true;
        params.customerSiteId = null;
        params.customerContactId = null;
        params.orderNumber = null;
        params.retailCrmAccountId = retailCrmAccountId;
        params.erpCustomerId = currentCustomer.erpCustomerId.toString();
        params.cartId = activeCart?.id;
        params.userId = currentUser?.userId;
        if (fabOrderEntry) {
          params = {
            ...params,
            isForFabOrder: true,
            erpCustomerId: currentCustomer.erpCustomerId,
            retailCrmAccountId: retailCrmAccountId,
            cartId: activeCart ? activeCart.id : '',
          };

          if (customerToEdit) {
            params = {...params, isActive: true};
            try {
              await putCustomerRetailSitesRequest(customerToEdit.id, params);
              await onSaveSuccess();
            } catch (e) {
              console.log(e);
              toast.error('Unable to confirm New Address');
              dispatch(hideFullscreenLoader());
              throw e;
            }
          } else {
            try {
              params = {...params, isForFabOrder: true};
              await postCustomerRetailSitesRequest(params);
              await onSaveSuccess();
            } catch (e) {
              console.log(e);
              toast.error('Unable to confirm New Address');
              dispatch(hideFullscreenLoader());
              throw e;
            }
          }

          await dispatch(
            getPurchasingCustomersAsync({
              cartId: activeCart ? activeCart.id : '',
              erpCustomerId: currentCustomer.erpCustomerId,
            })
          );
        } else {
          try {
            let result;

            if (!customerToEdit) {
              // post to retailSites with params, nothing else
              result = await postCustomerRetailSitesRequest(params);
            } else {
              result = await putCustomerRetailSitesRequest(customerToEdit.id, params);
            }

            await onSaveSuccess();

            if (onSubmitCallback) {
              onSubmitCallback(result);
            }
          } catch (error) {
            toast.error('Unable to confirm New Address');
            dispatch(hideFullscreenLoader());
            throw error;
          }
        }
        if (formikRef && formikRef.resetForm) {
          await onReset(formikRef.resetForm);
        }
        setIsAddingSiteInProgress(false);
        dispatch(hideFullscreenLoader());
        toggleShow();
      }
    },
    [
      isAddingSiteInProgress,
      activeCart,
      currentUser?.userId,
      currentCustomer,
      retailCrmAccountId,
      toggleShow,
      customerToEdit,
      onSubmitCallback,
      fabOrderEntry,
      onSaveSuccess,
      onReset,
      dispatch,
    ]
  );

  const validateAddressOnSubmit = useCallback(
    async (form: any) => {
      setIsAddressValidationInProgress(true);
      await dispatch(getAddressValidation(form));
    },
    [dispatch]
  );

  const submitFormCallback = useCallback(
    async (formikForm: any) => {
      let props = formikForm?.values;
      dispatch(showFullscreenLoader({showCartIcon: false}));
      if (isFormVisible) {
        validateAddressOnSubmit(props);
        dispatch(hideFullscreenLoader());
      } else {
        try {
          const originalAddress = formatExistingAddress(props);
          await saveNewAddress(originalAddress, null, formikForm);
        } catch (e) {
          throw e;
        } finally {
          dispatch(hideFullscreenLoader());
        }
      }
    },

    [isFormVisible, dispatch, saveNewAddress, validateAddressOnSubmit]
  );

  useEffect(() => {
    if (customerToEdit) {
      setIsFormVisible(true);
    }
    setIsStateRequired(states.length > 0);
  }, [customerToEdit, states]);

  useEffect(() => {
    const getStatesForSelectedCountry = async () => {
      if (selectedCountry) {
        await dispatch(getStatesOrProvinces({countryName: selectedCountry.countryName}));
        setIsAddressValidationInProgress(false);
      }
    };

    getStatesForSelectedCountry();
  }, [selectedCountry, dispatch]);

  useEffect(() => {
    if (customerShipToSites && customerShipToSites.length > 0 && allCountries.length > 0) {
      let country;

      if (customerToEdit && customerToEdit.countryCode) {
        country = allCountries.find(
          (data: any) =>
            data.alpha2Code.toLowerCase() === customerToEdit.countryCode.toLowerCase() ||
            data.alpha3Code.toLowerCase() === customerToEdit.countryCode.toLowerCase()
        );
      } else {
        country = allCountries.find(
          (data: any) =>
            data.alpha2Code.toLowerCase() === customerShipToSites[0].country?.toLowerCase() ||
            data.alpha3Code.toLowerCase() === customerShipToSites[0].country?.toLowerCase()
        );
      }

      setSelectedCountry(country);
    }
  }, [customerShipToSites, allCountries, customerToEdit]);

  useEffect(() => {
    if (selectedCountry && states.length > 0 && customerToEdit && customerToEdit.stateProvinceCode) {
      setSelectedState(() => states.find((state: any) => state.locationCode === customerToEdit.stateProvinceCode));
    }
  }, [selectedCountry, states, customerToEdit]);

  useEffect(() => {
    const validateAddress = async () => {
      if (validationResult && validationResult.totalRecords > 0) {
        const result = JSON.parse(JSON.stringify(validationResult));
        setValidationErrors(validationResult.records.resultCodes);
        const suggestions: any = [];
        const formValues = JSON.parse(JSON.stringify((formikRef as any).values));

        if (result.records.status === 'Pass') {
          if (hasAddressChangedResult(formValues, result.records)) {
            if (result.records.postalCodeExtension) {
              result.records.postalCode += `-${result.records.postalCodeExtension}`;
            }
            formatOriginalAddress(formValues);
            setShowSuggestionsSection(true);
            suggestions.push(result.records);
          } else {
            if (isAddressValidationInProgress) {
              try {
                await saveNewAddress(formValues, null, formikRef);
                setShowSuggestionsSection(false);
              } catch (e) {
                throw e;
              }
            }
          }
        } else {
          if (result.suggestions) {
            for (const address of result.suggestions.address) {
              if (address.postalCodeExtension) {
                address.postalCode += `-${address.postalCodeExtension}`;
              }
              suggestions.push(address);
            }
          }
          formatOriginalAddress(formValues);
          setShowSuggestionsSection(true);
        }

        if (addressSuggestions.length === 0 && suggestions.length > 0) {
          setAddressSuggestions(suggestions);
        }
        setIsAddressValidationInProgress(false);
      } else {
        setShowSuggestionsSection(false);
      }
    };

    validateAddress();
  }, [validationResult, addressSuggestions.length, isAddressValidationInProgress, saveNewAddress]);

  return (
    <Formik
      innerRef={(ref: any) => (formikRef = ref)}
      initialValues={initialFormValues}
      validationSchema={validationSchema}
      onSubmit={(props) => submitFormCallback(formikRef)}>
      {(props) => {
        return (
          <CambriaModal
            show={show}
            toggleShow={toggleShow}
            onCancel={() => {
              onReset(props.resetForm);
            }}
            heading={modalOptions.title}
            cancelButton={modalOptions.cancelButtonText}
            confirmButton={modalOptions.confirmButtonText}
            isValid={!(props.dirty && props.isValid)}
            disableSubmitBtn={isAddressValidationInProgress || isAddingSiteInProgress}
            formName={modalOptions.formName}>
            <Form id={modalOptions.formName} onSubmit={props.handleSubmit} onReset={props.handleReset} noValidate>
              <Container className="p-0">
                <Row>
                  <Col>
                    <CambriaSelect
                      formikFormProps={props}
                      name="selectExistingAddress"
                      defaultValue={props.values.selectExistingAddress}
                      items={availableAddresses}
                      placeholder="Select or Enter Purchasing Customer Address..."
                      displayValue="displayName"
                      onChange={(selectedSite: SalesforceRetailAccount) => onExistingAddressChange(props, selectedSite)}
                      required={isFormVisible}
                      disabled={isAddressValidationInProgress || isAddingSiteInProgress}
                    />
                  </Col>
                </Row>

                {isFormVisible && (
                  <>
                    <Row>
                      <Col>
                        <CambriaInput
                          name="companyName"
                          defaultValue={props.values.companyName || initialFormValues.companyName}
                          label="Company Name"
                          placeholder="Company Name"
                          type="text"
                          required={isFormVisible}
                          disabled={isAddressValidationInProgress || isAddingSiteInProgress}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6} sm={12}>
                        <CambriaInput
                          name="firstName"
                          defaultValue={props.values.firstName || initialFormValues.firstName}
                          label="First Name"
                          placeholder="Contact First Name"
                          type="text"
                          disabled={isAddressValidationInProgress || isAddingSiteInProgress}
                        />
                      </Col>
                      <Col md={6} sm={12}>
                        <CambriaInput
                          name="lastName"
                          defaultValue={props.values.lastName || initialFormValues.lastName}
                          label="Last Name"
                          placeholder="Contact Last Name"
                          type="text"
                          disabled={isAddressValidationInProgress || isAddingSiteInProgress}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <CambriaSelect
                          formikFormProps={props}
                          name="country"
                          defaultValue={selectedCountry}
                          items={allCountries}
                          label="Country"
                          placeholder="Country"
                          displayValue="countryName"
                          onChange={async (selection: ICountry) => {
                            setSelectedCountry(selection);
                            if (selection.alpha2Code !== 'US') {
                              setAddressSuggestions(() => []);
                              await dispatch(addressSearchAsync({keyword: null}));
                              await dispatch(getAddressValidation(null));
                            }
                            props.setFieldValue('address1', '');
                            props.setFieldValue('address2', '');
                            props.setFieldValue('address3', '');
                            props.setFieldValue('city', '');
                            props.setFieldValue('postalCode', '');
                            props.setFieldValue('state', '');
                          }}
                          required={isFormVisible}
                          disabled={isAddressValidationInProgress || isAddingSiteInProgress}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <CambriaAutosuggest
                          name="address1"
                          isAddressSearch={true}
                          defaultValue={props.values.address1 || initialFormValues.address1}
                          options={address}
                          onSearch={(query: string) => onAddressValidation(query)}
                          onSelect={(address: any) => {
                            onAddressSelect(props, address);
                          }}
                          label="Street Address"
                          placeholder="Street Address"
                          displayValue="addressLine1"
                          required={isFormVisible}
                          disabled={isAddressValidationInProgress || isAddingSiteInProgress}></CambriaAutosuggest>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <CambriaInput
                          name="address2"
                          defaultValue={props.values.address2 || initialFormValues.address2}
                          label="Apartment or Suite Number"
                          placeholder="Apartment or Suite Number"
                          type="text"
                          disabled={isAddressValidationInProgress || isAddingSiteInProgress}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <CambriaInput
                          name="address3"
                          defaultValue={props.values.address3 || initialFormValues.address3}
                          label="Address Line 3"
                          placeholder="Address Line 3"
                          type="text"
                          disabled={isAddressValidationInProgress || isAddingSiteInProgress}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <CambriaInput
                          name="city"
                          defaultValue={props.values.city || initialFormValues.city}
                          label="City"
                          placeholder="City"
                          type="text"
                          required={isFormVisible}
                          disabled={isAddressValidationInProgress || isAddingSiteInProgress}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6} sm={12}>
                        <CambriaSelect
                          formikFormProps={props}
                          name="state"
                          defaultValue={selectedState}
                          items={states}
                          label="State / Province"
                          placeholder="Select State / Province"
                          displayValue="stateName"
                          required={isFormVisible && isStateRequired}
                          disabled={isAddressValidationInProgress || isAddingSiteInProgress}
                        />
                      </Col>
                      <Col md={6} sm={12}>
                        <CambriaInput
                          name="postalCode"
                          defaultValue={props.values.postalCode || initialFormValues.postalCode}
                          label="Postal Zip Code"
                          placeholder="Postal Zip Code"
                          type="text"
                          required={isFormVisible}
                          disabled={isAddressValidationInProgress || isAddingSiteInProgress}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <CambriaInput
                          name="phoneNumber"
                          defaultValue={props.values.phoneNumber || initialFormValues.phoneNumber}
                          label="Phone Number"
                          placeholder="Phone Number"
                          type="phoneNumber"
                          disabled={isAddressValidationInProgress || isAddingSiteInProgress}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <CambriaInput
                          name="email"
                          defaultValue={props.values.email || initialFormValues.email}
                          label="Contact Email"
                          placeholder="Contact Email"
                          type="text"
                          disabled={isAddressValidationInProgress || isAddingSiteInProgress}
                        />
                      </Col>
                    </Row>
                  </>
                )}
                {canAddNewPurchasingCustomer && (
                  <>
                    <Row>
                      <Col>
                        <CambriaInput
                          name="isFormVisible"
                          defaultValue={props.values.isFormVisible}
                          label="Enter a New Purchasing Customer"
                          type="checkbox"
                          data-testId="enterNewCheckbox"
                          disabled={isAddressValidationInProgress || isAddingSiteInProgress}
                          onChange={() => {
                            setIsFormVisible((currentValue: boolean) => !currentValue);
                            setRetailCrmAccountId('');
                          }}
                        />
                      </Col>
                    </Row>
                    <Row>
                      {showSuggestionsSection && (
                        <Col className="m-t-sm">
                          <Alert variant="info" dismissible>
                            <div className="m-4">New Shipping Address cannot be validated</div>
                            <hr />
                            <div className="m-4">
                              {validationErrors.map((error: any) => (
                                <div key={error.code}>
                                  {error.code}: {error.message}
                                </div>
                              ))}
                            </div>
                            <hr />
                            {addressSuggestions.length > 0 && (
                              <div className="m-4">
                                <strong>Keep the Original Address</strong>
                                <div className="radio">
                                  <input
                                    type="radio"
                                    id="enteredAddress"
                                    onClick={() => saveNewAddress(props.values, null, formikRef)}
                                    disabled={isAddingSiteInProgress}
                                  />
                                  <label htmlFor="enteredAddress">{originalAddress}</label>
                                </div>
                                <strong>Update the Address</strong>
                                {addressSuggestions.map((suggestion, i) => {
                                  return (
                                    <div className="radio" key={i}>
                                      <input
                                        type="radio"
                                        id={i.toString()}
                                        onClick={() => saveNewAddress(null, suggestion, formikRef)}
                                        disabled={isAddingSiteInProgress}
                                      />
                                      <label htmlFor={i.toString()}>{formatSuggestionAddress(suggestion)}</label>
                                    </div>
                                  );
                                })}
                              </div>
                            )}
                            {addressSuggestions.length === 0 && (
                              <div className="m-4">
                                There are no suggested address for your inputs.
                                <br />
                                Please review the form and try again or save this address.
                                <br />
                                <div className="radio">
                                  <input
                                    type="radio"
                                    id="enteredAddress"
                                    onClick={() => saveNewAddress(props.values, null, formikRef)}
                                    disabled={isAddingSiteInProgress}
                                  />
                                  <label htmlFor="enteredAddress">{originalAddress}</label>
                                </div>
                              </div>
                            )}
                          </Alert>
                        </Col>
                      )}
                    </Row>
                  </>
                )}
              </Container>
            </Form>
          </CambriaModal>
        );
      }}
    </Formik>
  );
};

export default AddPurchasingCustomerModal;
