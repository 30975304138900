import {Form, Formik} from 'formik';
import React, {useCallback, useEffect, useState} from 'react';
import {Accordion, Card, Col, Row} from 'react-bootstrap';
import {selectActiveCart} from '../../../../features/cart/slice/cart.slice';
import {CartItem} from '../../../../features/cart/ICartState';
import {selectCurrentCustomer} from '../../../../features/customer/slice/customer.slice';
import {
  selectDuplicateBeingEditted,
  selectDuplicatePieceModel,
  setDuplicateBeingEditted,
  setDuplicatePieceModel,
} from '../../../../features/fabrication/slice/fabrication.slice';
import {IFabricatedProductGroupsResponse} from '../../../../features/productType/IProductTypeState';
import {getAllFabricatedProductGroups} from '../../../../features/productType/service/productType.service';
import CambriaSelect from '../../../../Framework/Components/CambriaSelect';
import {useAppDispatch, useTypedSelector} from '../../../../hooks/store';
import FabricationCart from '../../Account/OrderHistory/OrderDetails/FabricationCart';
import FabricationPieceForm from './PieceInfoForms/FabricationForm';
import {selectFabLineItemsLimit} from '../../../../features/environment/slice/environment.slice';
import {toast} from 'react-toastify';
import COMMERCE_CORE_CONSTANTS from '../../../../Core/constants';

const FabOrderPieces = () => {
  const dispatch = useAppDispatch();

  const activeCart = useTypedSelector(selectActiveCart);
  const currentCustomer = useTypedSelector(selectCurrentCustomer);
  const duplicateBeingEditted = useTypedSelector(selectDuplicateBeingEditted);
  const duplicatePieceModel = useTypedSelector(selectDuplicatePieceModel);
  const lineItemsLimit = useTypedSelector(selectFabLineItemsLimit);

  const [selectedProductGroupCode, setSelectedProductGroupCode] = useState<string | null>(null);
  const [selectedProductType, setSelectedProductType] = useState<string | null>(null);
  const [productTypeOptions, setProductTypeOptions] = useState<IFabricatedProductGroupsResponse[]>([]);
  const [isOpen, setIsOpen] = useState(true);
  const [currentProductGroupCode, setCurrentProductGroupCode] = useState<string | null>(null);
  const [showCart, setShowCart] = useState(false);
  const [resetDuplicateForm, setResetDuplicateForm] = useState(false);

  const step2Enabled = activeCart?.orderDetails && activeCart.orderDetails.length > 0;

  const getProductTypeByProductGroupCode = useCallback(
    (groupCode: string, productOptions: IFabricatedProductGroupsResponse[]) => {
      if (groupCode === 'CutoutsAndInlays') {
        return 'Cutouts';
      }

      let productType = productOptions.find((x) => x.productGroupCode === groupCode);
      return productType?.displayName || null;
    },
    []
  );

  const onAddPieceSelectChange = (selection: IFabricatedProductGroupsResponse) => {
    if (lineItemsLimit && activeCart?.cartItems && activeCart.cartItems.length >= lineItemsLimit) {
      toast.error(COMMERCE_CORE_CONSTANTS.ERROR_MESSAGES.FAB_LINE_ITEMS_LIMIT_REACHED);
    } else {
      setSelectedProductType(selection.displayName);
      setSelectedProductGroupCode(selection.productGroupCode);
      dispatch(setDuplicateBeingEditted(null));
    }
  };

  useEffect(() => {
    setResetDuplicateForm(false);
  }, [resetDuplicateForm]);

  useEffect(() => {
    const checkIfFormNeedsReloading = () => {
      if (selectedProductGroupCode !== currentProductGroupCode) {
        setCurrentProductGroupCode(selectedProductGroupCode);
      }
    };
    checkIfFormNeedsReloading();
  }, [selectedProductGroupCode, currentProductGroupCode]);

  useEffect(() => {
    const checkIfFabItemsInCart = () => {
      if (activeCart && activeCart.cartItems && activeCart.cartItems.length > 0) {
        const fabCartItems = activeCart.cartItems.filter(
          (x: CartItem) => x.productType === 'Fabricated' || x.productType === 'FabricatedService'
        );
        if (fabCartItems.length > 0) {
          setShowCart(true);
        }
      } else {
        setShowCart(false);
      }
    };
    checkIfFabItemsInCart();
  }, [activeCart]);

  useEffect(() => {
    const getFabricatedTypeOptions = async () => {
      const productGroups = await getAllFabricatedProductGroups(currentCustomer);
      let displayData: IFabricatedProductGroupsResponse[] = [];
      productGroups.forEach((item: IFabricatedProductGroupsResponse) => {
        if (item.productActiveLocations.includes('ProductTypePrimary')) {
          displayData.push(item);
        }
      });
      setProductTypeOptions(displayData);
    };
    if (currentCustomer.id && productTypeOptions.length === 0) {
      getFabricatedTypeOptions();
    }
  }, [currentCustomer, productTypeOptions]);

  useEffect(() => {
    if (duplicateBeingEditted || duplicatePieceModel) {
      let groupCode = duplicateBeingEditted
        ? duplicateBeingEditted.mainPiece.productGroupCode
        : duplicatePieceModel && duplicatePieceModel.productGroupCode
        ? duplicatePieceModel.productGroupCode
        : '';
      setSelectedProductType(getProductTypeByProductGroupCode(groupCode, productTypeOptions));
      setSelectedProductGroupCode(groupCode);
    }
  }, [duplicateBeingEditted, duplicatePieceModel, getProductTypeByProductGroupCode, productTypeOptions]);

  if (step2Enabled) {
    return (
      <section>
        <Accordion className="order-details-accordion" activeKey={isOpen ? '0' : '1'}>
          <Card>
            <Card.Header className="card-header w-100 p-3">
              <Accordion.Toggle
                data-testid="step2ToggleBtn"
                className="d-flex w-100 border-0 align-items-center justify-content-between bg-transparent"
                eventKey="0"
                onClick={() => setIsOpen(!isOpen)}>
                <div className="d-flex justify-content-start align-items-center w-25">
                  <div className="rounded p-3 number-box d-flex align-items-center justify-content-center">2</div>
                  <span className="ml-3 step-heading">Order Pieces</span>
                </div>
                <i className={`fa fa-chevron-${isOpen ? 'up' : 'down'} float-right`}></i>
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="0">
              <Card.Body className="card-body">
                {activeCart && activeCart.cartItems.length > 0 && showCart && (
                  <Row className="w-100">
                    <Col xs={12}>
                      <FabricationCart fabOrderPage cart={activeCart} shouldUseOrderMetadata={false} />
                    </Col>
                  </Row>
                )}

                {activeCart.transactionType !== 'QuoteCart' && (
                  <Formik initialValues={{}} onSubmit={() => {}}>
                    {(props) => {
                      return (
                        <Form id="fab-duplicate-form">
                          <Row className="w-100 ml-0 mt-4 mb-3 pt-5">
                            <Col xs={4}>
                              <h2>ADD PIECE / SERVICE</h2>
                              <CambriaSelect
                                label="PRODUCT TYPE"
                                name="productType"
                                disabled={!productTypeOptions || productTypeOptions.length === 0}
                                items={productTypeOptions}
                                defaultValue={selectedProductType}
                                displayValue="displayName"
                                onChange={onAddPieceSelectChange}
                                placeholder="Product Type"
                              />
                            </Col>
                          </Row>
                          {!resetDuplicateForm && (duplicateBeingEditted || duplicatePieceModel) && (
                            <Row className="w-100">
                              <Col xs={12} className="margin-left-15">
                                <FabricationPieceForm
                                  isDuplicateItem
                                  cancelEdit={() => {
                                    duplicateBeingEditted && dispatch(setDuplicateBeingEditted(null));
                                    duplicatePieceModel && dispatch(setDuplicatePieceModel(null));
                                    setSelectedProductType(null);
                                    setSelectedProductGroupCode(null);
                                    props.setFieldValue('productType', {displayName: ''});
                                  }}
                                  parentItemCartItemId={
                                    duplicateBeingEditted?.mainPiece.parentCartItemId ||
                                    duplicatePieceModel?.parentCartItemId
                                  }
                                  productGroupCode={selectedProductGroupCode || ''}
                                  initialFormValues={duplicateBeingEditted}
                                  duplicatePieceModel={duplicatePieceModel}
                                  resetOnDuplicateFunction={() => setResetDuplicateForm(true)}
                                />
                              </Col>
                            </Row>
                          )}
                          {selectedProductGroupCode &&
                            !duplicateBeingEditted &&
                            !duplicatePieceModel &&
                            currentProductGroupCode === selectedProductGroupCode && (
                              <Row className="w-100">
                                <Col xs={12} className="margin-left-15">
                                  <FabricationPieceForm
                                    cancelEdit={() => {
                                      setSelectedProductType(null);
                                      setSelectedProductGroupCode(null);
                                      props.setFieldValue('productType', {displayName: ''});
                                    }}
                                    productGroupCode={selectedProductGroupCode || ''}
                                  />
                                </Col>
                              </Row>
                            )}
                        </Form>
                      );
                    }}
                  </Formik>
                )}
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      </section>
    );
  } else {
    return (
      <Accordion className="order-details-accordion disabled-accordion-toggle">
        <Card>
          <Card.Header className="card-header w-100 p-3">
            <Accordion.Toggle
              data-testid="step2ToggleBtn"
              className="d-flex w-100 border-0 align-items-center justify-content-between bg-transparent disabled-accordion-toggle"
              eventKey="0">
              <div className="d-flex justify-content-start align-items-center w-25 disabled-accordion-toggle">
                <div className="rounded p-3 number-box d-flex align-items-center justify-content-center">2</div>
                <span className="ml-3 step-heading disabled-accordion-toggle">Order Pieces</span>
              </div>
              <i className="fa fa-chevron-down float-right"></i>
            </Accordion.Toggle>
          </Card.Header>
        </Card>
      </Accordion>
    );
  }
};

export default FabOrderPieces;
