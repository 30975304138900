import React from 'react';

const boldSuggestionMatch = (input: string, keyword: string) => {
  let formattedName = <span>{input}</span>;

  const index = input.toLowerCase().indexOf(keyword.toLowerCase());
  if (index !== -1) {
    const boldSubstring = input.slice(index, index + keyword.length);
    const firstSubstring = input.slice(0, index);
    const secondSubstring = input.slice(index + keyword.length, input.length);
    formattedName = (
      <span>
        {firstSubstring}
        <b>{boldSubstring}</b>
        {secondSubstring}
      </span>
    );
  }
  return formattedName;
};

export default boldSuggestionMatch;
