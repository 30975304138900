import React from 'react';
import OrderDetailsContainer from './OrderDetails.styled';
import Icon from '../../../../Framework/Components/Icon';
import COMMERCE_CORE_CONSTANTS from '../../../constants';

const OrderDetails = ({
  createdBy,
  orderNumber,
  purchaseOrderNumber,
  orderType,
  jobNumber,
  favorite,
  url,
}: {
  createdBy: string;
  orderNumber: number;
  purchaseOrderNumber: number;
  orderType: string;
  jobNumber: string | null;
  favorite: string | null;
  url?: string;
}) => {
  return (
    <OrderDetailsContainer>
      {createdBy && !url?.includes(COMMERCE_CORE_CONSTANTS.PATHNAMES.baseAccount) && (
        <>
          Order Created By:<span className="order-details-value"> {createdBy}</span>
          <br />
        </>
      )}
      {orderNumber && (
        <>
          Order Number:<span className="order-details-value"> {orderNumber}</span>
          <br />
        </>
      )}
      {purchaseOrderNumber && (
        <>
          PO Number:<span className="order-details-value"> {purchaseOrderNumber}</span>
          <br />
        </>
      )}
      {orderType && (
        <>
          Order Type:<span className="order-details-value"> {orderType}</span>
          <br />
        </>
      )}
      {jobNumber ? (
        <div>
          Job Number:<span className="order-details-value"> {jobNumber}</span>
          <br />
        </div>
      ) : (
        <div></div>
      )}
      <span className="order-favorite-name">
        {favorite ? (
          <div>
            <Icon color="#4C4C4C" size="13.5" weight="900" icon="icons-cambria-General-Favorites-Filled" />
            &nbsp;{favorite}
          </div>
        ) : (
          <div></div>
        )}
      </span>
    </OrderDetailsContainer>
  );
};
export default OrderDetails;
