import {IAsyncArray} from '../types';

export const NewQuoteLocation = (): IQuoteLocation => {
  let location: any = {};

  location['id'] = '';
  location['businessName'] = '';
  location['addressLine1'] = '';
  location['addressLine2'] = '';
  location['addressLine3'] = '';
  location['city'] = '';
  location['stateProvince'] = '';
  location['postalZipCode'] = '';
  location['country'] = '';
  location['createdAt'] = undefined;
  location['createdBy'] = undefined;
  location['updatedAt'] = undefined;
  location['updatedBy'] = undefined;
  location['isActive'] = false;
  return location;
};

export const NewSubcontractor = (): ISubContractor => {
  let subContractor: any = {};

  subContractor['id'] = '';
  subContractor['crmAccountId'] = '';
  subContractor['companyName'] = '';
  subContractor['contactFirstName'] = '';
  subContractor['contactLastName'] = '';
  subContractor['contactEmail'] = '';
  subContractor['contactPhone'] = '';
  subContractor['addressLine1'] = '';
  subContractor['addressLine2'] = '';
  subContractor['addressLine3'] = '';
  subContractor['city'] = '';
  subContractor['stateProvince'] = '';
  subContractor['postalZipCode'] = '';
  subContractor['country'] = '';
  subContractor['createdAt'] = undefined;
  subContractor['createdBy'] = undefined;
  subContractor['updatedAt'] = undefined;
  subContractor['updatedBy'] = undefined;
  subContractor['isActive'] = false;
  return subContractor;
};

export const NewSpecifier = (): ISpecifier => {
  let specifier: any = {};

  specifier['id'] = '';
  specifier['crmAccountId'] = '';
  specifier['companyName'] = '';
  specifier['contactFirstName'] = '';
  specifier['contactLastName'] = '';
  specifier['contactEmail'] = '';
  specifier['contactPhone'] = '';
  specifier['addressLine1'] = '';
  specifier['addressLine2'] = '';
  specifier['addressLine3'] = '';
  specifier['city'] = '';
  specifier['stateProvince'] = '';
  specifier['postalZipCode'] = '';
  specifier['country'] = '';
  specifier['isActive'] = false;
  return specifier;
};

export const NewQuote = (): IQuote => {
  let quote: any = {};
  quote['id'] = '';
  quote['type'] = '';
  quote['originalQuoteId'] = '';
  quote['name'] = '';
  quote['customerName'] = '';
  quote['erpCustomerId'] = '';
  quote['cartId'] = '';
  quote['expectedOrderDate'] = null;
  quote['category'] = '';
  quote['application'] = [];
  quote['lines'] = [];
  quote['subContractor'] = NewSubcontractor();
  quote['specifier'] = NewSpecifier();
  quote['location'] = NewQuoteLocation();
  quote['expirationDate'] = null;
  return quote;
};

export interface IQuoteDetails {
  id: number;
  crmAccountId: string;
  crmQuoteNumber: string;
  type: 'commercial' | 'standardCia';
  originalQuoteId: number;
  version: number;
  crmOpporunityId: string;
  crmQuoteId: string;
  name: string;
  customerName: string;
  erpCustomerId: string;
  override30DayIncentive: boolean;
  crmStatus: string;
  statusCode: string;
  statusDisplayName: string;
  statusSortOrder: number;
  statusOrderable: boolean;
  statusCancellable: boolean;
  sopTimingMessage: string;
  quoteCartId: string;
  orderCartId: string;
  orderNumber: string;
  expectedOrderDate: string;
  category: string;
  applications: string[];
  totalSlabs: number;
  totalAmount: number;
  discountCodes: string[];
  createdAt: any;
  createdBy: string;
  updatedAt: any;
  updatedBy: string;
  isActive: boolean;
  originalCrmQuoteId: string;
  crmContactFirstName: string;
  crmContactLastName: string;
  crmContactEmail: string;
  crmProjectOpportunityId: string;
  lines: Array<IQuoteLine>;
  subContractor: ISubContractor;
  specifier: any;
  location: IQuoteLocation;
  expirationDate: any;
  statusCanRequestExtension: boolean;
  allowedToOrderDate: any;
  orderMetadata: any;
}

export interface ISpecifier {
  id: string;
  crmAccountId: string;
  companyName: string;
  contactFirstName: string;
  contactLastName: string;
  contactEmail: string;
  contactPhone: string;
  addressLine1: string;
  addressLine2: string;
  addressLine3: string;
  city: string;
  stateProvince: string;
  postalZipCode: string;
  country: string;
  isActive: boolean;
}

export interface ISubContractor {
  id: string;
  crmAccountId: string;
  companyName: string;
  contactFirstName: string;
  contactLastName: string;
  contactEmail: string;
  contactPhone: string;
  addressLine1: string;
  addressLine2: string;
  addressLine3: string;
  city: string;
  stateProvince: string;
  postalZipCode: string;
  country: string;
  createdAt: any;
  createdBy: any;
  updatedAt: any;
  updatedBy: any;
  isActive: boolean;
}

export interface IQuoteLocation {
  id: string;
  businessName: string;
  addressLine1: string;
  addressLine2: string;
  addressLine3: string;
  city: string;
  stateProvince: string;
  postalZipCode: string;
  country: string;
  createdAt: any;
  createdBy: any;
  updatedAt: any;
  updatedBy: any;
  isActive: boolean;
}

export interface IQuoteInitialState {
  quoteDetails: IQuoteDetails;
  loading: boolean;
  quoteOperationInProgress: boolean;
  processingRequest: boolean;
  expiringQuotes: IAsyncArray<ICloseToExpireQuote>;
}

export interface ICloseToExpireQuote {
  id: number;
  expirationDate: string;
  name: string;
}

export const NewQuoteDetailsInitialState = (): IQuoteDetails => {
  return {
    id: 0,
    crmAccountId: '',
    crmQuoteNumber: '',
    type: 'commercial',
    originalQuoteId: 0,
    version: 0,
    crmOpporunityId: '',
    crmQuoteId: '',
    name: '',
    customerName: '',
    erpCustomerId: '',
    override30DayIncentive: false,
    crmStatus: '',
    statusCode: '',
    statusDisplayName: '',
    statusSortOrder: 0,
    statusOrderable: false,
    statusCancellable: false,
    sopTimingMessage: '',
    quoteCartId: '',
    orderCartId: '',
    orderNumber: '',
    expectedOrderDate: '',
    category: '',
    applications: [],
    totalSlabs: 0,
    totalAmount: 0,
    discountCodes: [],
    createdAt: undefined,
    createdBy: '',
    updatedAt: undefined,
    updatedBy: '',
    isActive: false,
    originalCrmQuoteId: '',
    crmContactFirstName: '',
    crmContactLastName: '',
    crmContactEmail: '',
    crmProjectOpportunityId: '',
    lines: [],
    subContractor: NewSubcontractor(),
    specifier: undefined,
    location: NewQuoteLocation(),
    expirationDate: undefined,
    statusCanRequestExtension: false,
    allowedToOrderDate: undefined,
    orderMetadata: undefined,
  };
};

export interface IQuoteHeaderData {
  requestedBy: string;
  quoteNumber: string;
  subcontractorName: string;
  location: string;
  status: string;
  statusCode: string;
  dateOrdered: string;
  projectName: string;
  expirationDate: string;
  createdBy: string;
}

export const initialQuoteHeaderData: IQuoteHeaderData = {
  requestedBy: '',
  quoteNumber: '',
  subcontractorName: '',
  location: '',
  status: '',
  statusCode: '',
  dateOrdered: '',
  projectName: '',
  expirationDate: '',
  createdBy: '',
};

export interface ICambriaProductLineItemsData {
  lineItems: Array<any>;
  lineItemColumns: Array<any>;
  discounts: Array<any>;
  status: string;
  isFabOrder: boolean;
  shouldShowShipDate: boolean;
  shouldShowExpectedOrderDate: boolean;
  isShippedOrder: boolean;
  totalNetAmount: number;
  subtotal: number;
  originalCartId: string;
  lastCartId: string;
  isQuoteDetailsPage: boolean;
}

export interface IQuoteSummaryDiscount {
  modifier: string;
  value: number;
}

export interface IQuoteLine {
  productId: string;
  warehouseCode: string;
  programCode: string;
  itemNumber: string;
  siteUseId: string;
  quantity: number;
  pricingUom: string;
  unitPrice: number;
  originalBasePrice: number;
  originalBasePriceExtended: number;
  currency: string;
  imageUrl: string;
  productType: string;
  cartItemId: number;
  cartItemType: 'Unknown' | 'ComplimentaryCheeseboard' | 'Standard' | 'FabAndInstallDiscrete' | 'FabAndInstallStandard';
  parentCartItemId: number;
  pieceLabel: string;
  productGroupCode: string;
  productApplication: string;
  design: string;
  designCode: string;
  thickness: string;
  polish: string;
  match: string;
  dimension1: number;
  dimension2: number;
  dimension3: number;
  dimension4: number;
  description: string;
  edgeProfileName: string;
  edgeProfileCode: string;
  uomLineQuantity: number;
}

export interface IQuote {
  id: string;
  type: string;
  originalQuoteId: string;
  name: string;
  customerName: string;
  erpCustomerId: string;
  cartId: string;
  expectedOrderDate: Date | null;
  category: string;
  application: string;
  lines: Array<IQuoteLine>;
  subContractor: ISubContractor;
  specifier: ISpecifier;
  location: IQuoteLocation;
  expirationDate: Date | null;
}
