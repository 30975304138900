import React, {useCallback, useEffect, useState} from 'react';
import {Accordion, ButtonGroup, Card, Col, Row, ToggleButton} from 'react-bootstrap';
import {toast} from 'react-toastify';
import {getCart} from '../../../../features/cart/service/cart.service';
import {selectActiveCart, setActiveCart} from '../../../../features/cart/slice/cart.slice';
import {Cart, NewCart} from '../../../../features/cart/ICartState';
import {savePaymentMethod, saveTaxInformation} from '../../../../features/checkout/service/checkout.service';
import {setIsSubmitting, setStep3CompletedStatus} from '../../../../features/checkout/slice/checkout.slice';
import {
  selectCurrentCustomer,
  selectCurrentCustomerBillToSite,
  selectCurrentCustomerBillToSites,
  selectCurrentCustomerShipToSites,
} from '../../../../features/customer/slice/customer.slice';
import {BillToSite, CustomerFullProfile} from '../../../../features/customer/ICustomerState';
import {
  hideFullscreenLoader,
  showFullscreenLoader,
} from '../../../../features/fullscreenLoader/slice/fullscreenLoader.slice';
import {selectUserInfo} from '../../../../features/order/slice/order.slice';
import {CreditCard, PaymentType, Property} from '../../../../features/payment/IPaymentState';
import {
  getCreditCards,
  selectCreditCards,
  selectPaymentSelections,
  selectSelectedCheckoutCreditCard,
  setSelectedCheckoutCreditCard,
} from '../../../../features/payment/slice/payment.slice';
import {selectActiveProgram, selectAvailablePrograms} from '../../../../features/salesforce/slice/salesforce.slice';
import {useAppDispatch, useTypedSelector} from '../../../../hooks/store';
import {PaymentPreferencesLabel} from '../../Account/PaymentPreferences/PaymentPreferences.styled';
import PaymentTermsInfo from '../../Account/PaymentPreferences/PaymentTermsInfo';
import AvailableCreditCards from './AvailableCreditCards';
import {PaymentMethodContainer} from './PaymentMethod.styled';
import {ISalesforceAvailablePrograms} from '../../../../features/salesforce/ISalesforceState';
import {formatOrderCurrency} from '../../../../Framework/Services/formatCurrency';
import {selectUiSettings} from '../../../../features/environment/slice/environment.slice';

const PaymentMethod = () => {
  const dispatch = useAppDispatch();

  const paymentSelectionRedux = useTypedSelector(selectPaymentSelections);
  const creditCardsRedux = useTypedSelector(selectCreditCards);
  const billToSiteRedux = useTypedSelector(selectCurrentCustomerBillToSite);
  const currentCustomerRedux = useTypedSelector(selectCurrentCustomer);
  const activeCartRedux = useTypedSelector(selectActiveCart);
  const customerShipToSitesRedux = useTypedSelector(selectCurrentCustomerShipToSites);
  const customerBillToSitesRedux = useTypedSelector(selectCurrentCustomerBillToSites);
  const userInfoRedux = useTypedSelector(selectUserInfo);
  const activeProgram = useTypedSelector(selectActiveProgram);
  const chosenCreditCard = useTypedSelector(selectSelectedCheckoutCreditCard);
  const availablePrograms: ISalesforceAvailablePrograms | null = useTypedSelector(selectAvailablePrograms);
  const uiSettings = useTypedSelector(selectUiSettings);

  const [chosenPaymentMethod, setChosenPaymentMethod] = useState<PaymentType>();
  const [paymentInfoHasBeenLoaded, setPaymentInfoHasBeenLoaded] = useState(false);
  const [currency, setCurrency] = useState<string>();

  const getCreditCardInfoFromCart = useCallback(
    (cart: Cart, availableCreditCards: CreditCard[]) => {
      let paymentMethodInfo = cart.paymentMethods[0];
      if (paymentMethodInfo.paymentTypeCode === 'CREDIT_CARD') {
        let chosenCreditCard = availableCreditCards.find(
          (card: CreditCard) => card.token === paymentMethodInfo.prepaymentMethodToken
        );
        if (chosenCreditCard) {
          dispatch(setSelectedCheckoutCreditCard(chosenCreditCard));
        } else {
          dispatch(setStep3CompletedStatus(false));
        }
      }
    },
    [dispatch]
  );

  useEffect(() => {
    dispatch(showFullscreenLoader({}));
  }, [dispatch]);

  useEffect(() => {
    return () => {
      dispatch(setSelectedCheckoutCreditCard(null));
    };
  }, [dispatch]);

  useEffect(() => {
    if (
      activeCartRedux &&
      activeCartRedux?.paymentMethods.length > 0 &&
      creditCardsRedux &&
      creditCardsRedux.length > 0 &&
      !chosenCreditCard
    ) {
      getCreditCardInfoFromCart(activeCartRedux, creditCardsRedux);
    }
  }, [activeCartRedux, creditCardsRedux, getCreditCardInfoFromCart, dispatch, chosenCreditCard]);

  const getSelectedPaymentMethodFromCart = useCallback((cart: Cart, availablePaymentMethods: PaymentType[]) => {
    let selectedPaymentMethod = cart.paymentMethods[0];

    availablePaymentMethods.forEach((paymentType: PaymentType) => {
      if (paymentType.code === selectedPaymentMethod?.paymentTypeCode) {
        setChosenPaymentMethod(paymentType);
      }
    });
  }, []);

  useEffect(() => {
    if (activeCartRedux && activeCartRedux.paymentMethods.length > 0 && paymentSelectionRedux && !chosenPaymentMethod) {
      getSelectedPaymentMethodFromCart(activeCartRedux, paymentSelectionRedux.paymentTypes);
    }
  }, [getSelectedPaymentMethodFromCart, activeCartRedux, paymentSelectionRedux, chosenPaymentMethod]);

  const loadSavedCreditCards = useCallback(
    async (customer: CustomerFullProfile) => {
      await dispatch(getCreditCards({erpCustomerId: customer.erpCustomerId}));
    },
    [dispatch]
  );

  useEffect(() => {
    const loadPaymentInfo = async (cart: Cart, customer: CustomerFullProfile, billToSite: BillToSite) => {
      await loadSavedCreditCards(customer);
      dispatch(hideFullscreenLoader());
    };
    if (!paymentInfoHasBeenLoaded && billToSiteRedux && currentCustomerRedux.class && activeCartRedux) {
      loadPaymentInfo(activeCartRedux, currentCustomerRedux, billToSiteRedux);
      setPaymentInfoHasBeenLoaded(true);
    }
  }, [
    paymentSelectionRedux,
    billToSiteRedux,
    currentCustomerRedux,
    activeCartRedux,
    loadSavedCreditCards,
    paymentInfoHasBeenLoaded,
    dispatch,
  ]);

  useEffect(() => {
    if (activeCartRedux && activeCartRedux.cartItems.length > 0) {
      const firstCartItem = activeCartRedux.cartItems[0];
      setCurrency(firstCartItem?.currency);
    }
  }, [activeCartRedux]);

  const submitPaymentMethod = async () => {
    dispatch(setIsSubmitting(true));

    if (!chosenPaymentMethod) {
      toast.error('No payment method chosen.');
      dispatch(setIsSubmitting(false));
      dispatch(hideFullscreenLoader());
      return;
    }

    let creditCardToken = '';
    if (chosenPaymentMethod.code === 'CREDIT_CARD') {
      if (chosenCreditCard) {
        creditCardToken = chosenCreditCard.token;
      } else {
        toast.error('Finish adding your credit card then proceed to the next step.');
        dispatch(setIsSubmitting(false));
        dispatch(hideFullscreenLoader());
        return;
      }
    }

    try {
      await savePaymentMethod(
        paymentSelectionRedux,
        chosenPaymentMethod,
        billToSiteRedux,
        activeCartRedux || NewCart(),
        dispatch,
        creditCardToken,
        currentCustomerRedux
      );

      await saveTaxInformation(
        activeCartRedux || NewCart(),
        customerShipToSitesRedux || [],
        currentCustomerRedux,
        dispatch
      );

      const cart = await getCart(
        activeCartRedux?.id || '',
        currentCustomerRedux,
        userInfoRedux.id.toString(),
        activeProgram,
        customerShipToSitesRedux || [],
        customerBillToSitesRedux || [],
        dispatch,
        availablePrograms,
        false,
        true,
        true,
        uiSettings?.calculateCartDiscountsInBackend ?? false
      );

      dispatch(setActiveCart(cart));
      dispatch(setStep3CompletedStatus(true));
    } catch (error: any) {
      console.log(error);
      toast.error('Could not save payment method');
      dispatch(hideFullscreenLoader());
    }

    dispatch(setIsSubmitting(false));
  };

  return (
    <PaymentMethodContainer>
      {paymentSelectionRedux &&
        (paymentSelectionRedux.code !== 'PREPAID' || parseInt(paymentSelectionRedux.prepaymentMinimumPercent) < 100) &&
        paymentSelectionRedux.code !== 'IMMEDIATE' && (
          <>
            <Col xl={12} className="m-b-md">
              <Row>
                <Col xl={6}>
                  <PaymentPreferencesLabel>Payment Terms:</PaymentPreferencesLabel>
                </Col>
              </Row>
              <PaymentTermsInfo paymentSelections={paymentSelectionRedux}></PaymentTermsInfo>
            </Col>
          </>
        )}
      {paymentSelectionRedux &&
        paymentSelectionRedux.paymentTypes?.map((paymentType: PaymentType, index) => {
          return (
            <Row key={index}>
              <Col className="mb-4">
                {paymentType.code === 'CREDIT_CARD' ? (
                  <>
                    <Accordion activeKey={paymentType.code === chosenPaymentMethod?.code ? '0' : '1'}>
                      <Card>
                        <Card.Header className="card-header w-100 p-3 payment-type-header">
                          <Accordion.Toggle
                            className="d-flex w-100 pt-3 pb-3 pl-2 border-0 align-items-center justify-content-between bg-transparent"
                            data-testid={`${paymentType.name}`}
                            eventKey="0"
                            onClick={() => {
                              if (paymentType.code !== chosenPaymentMethod?.code) {
                                setChosenPaymentMethod(paymentType);
                                dispatch(setStep3CompletedStatus(false));
                              }
                            }}>
                            <Col xs={12} className="d-flex justify-content-start align-items-center pl-2">
                              <div
                                role="button"
                                className="rounded p-1 number-box d-flex align-items-center justify-content-center">
                                <ButtonGroup>
                                  <ToggleButton
                                    role="button"
                                    disabled
                                    value=""
                                    checked={chosenPaymentMethod?.code === paymentType.code}
                                  />
                                </ButtonGroup>
                              </div>
                              <span className="ml-3 payment-type-name">{paymentType.name}</span>
                            </Col>
                          </Accordion.Toggle>
                          <Accordion.Collapse eventKey="0">
                            <Card.Body className="card-body payment-type-description ml-4">
                              <div className="mb-3">
                                <span>{paymentType.description}</span>
                              </div>
                              {creditCardsRedux && <AvailableCreditCards creditCards={creditCardsRedux} />}
                            </Card.Body>
                          </Accordion.Collapse>
                        </Card.Header>
                      </Card>
                    </Accordion>
                  </>
                ) : (
                  <>
                    <Accordion activeKey={paymentType.code === chosenPaymentMethod?.code ? '0' : '1'}>
                      <Card>
                        <Card.Header className="card-header w-100 p-3 payment-type-header">
                          <Accordion.Toggle
                            className="d-flex w-100 pt-3 pb-3 pl-2 border-0 align-items-center justify-content-between bg-transparent"
                            data-testid={`${paymentType.name}`}
                            eventKey="0"
                            onClick={() => {
                              if (paymentType.code !== chosenPaymentMethod?.code) {
                                dispatch(setSelectedCheckoutCreditCard(null));
                                setChosenPaymentMethod(paymentType);
                                dispatch(setStep3CompletedStatus(false));
                              }
                            }}>
                            <Col xs={12} className="d-flex justify-content-start align-items-center pl-2">
                              <div
                                role="button"
                                className="rounded p-1 number-box d-flex align-items-center justify-content-center">
                                <ButtonGroup>
                                  <ToggleButton
                                    role="button"
                                    disabled
                                    value=""
                                    checked={chosenPaymentMethod?.code === paymentType.code}
                                  />
                                </ButtonGroup>
                              </div>
                              <span className="ml-3 payment-type-name">{paymentType.name}</span>
                            </Col>
                          </Accordion.Toggle>
                          <Accordion.Collapse eventKey="0">
                            <Card.Body className="card-body payment-type-description ml-4">
                              <div className="mb-3">
                                <span>{paymentType.description}</span>
                              </div>
                              {paymentType.properties.map((prop: Property, index) => {
                                return (
                                  <div key={index}>
                                    <span className="prop-name">{prop.name}: </span>
                                    <span>{prop.value}</span>
                                  </div>
                                );
                              })}
                            </Card.Body>
                          </Accordion.Collapse>
                        </Card.Header>
                      </Card>
                    </Accordion>
                  </>
                )}
              </Col>
            </Row>
          );
        })}
      {activeCartRedux &&
        paymentSelectionRedux &&
        activeCartRedux.totalNetAmount !== null &&
        activeCartRedux.totalAmount !== undefined && (
          <Row className="d-flex justify-content-end mr-0 ml-0 cart-total">
            <Col xs={6} sm={6} md={6} lg={2} className="d-flex justify-content-center total-label">
              CART TOTAL
            </Col>
            <Col xs={6} sm={6} md={6} lg={2} className="d-flex justify-content-center align-items-center total-value">
              <span>{`$${formatOrderCurrency(activeCartRedux.totalNetAmount)}`}</span>&nbsp;
              <span className="currency">{currency}</span>
            </Col>
          </Row>
        )}
      <form
        id="03"
        onSubmit={async (e: any) => {
          e.preventDefault();
          dispatch(showFullscreenLoader({showCartIcon: false}));
          await submitPaymentMethod();
        }}></form>
    </PaymentMethodContainer>
  );
};

export default PaymentMethod;
