import React from 'react';
import {DisplayField} from '../../../../../../features/customer/ICustomerState';

const TableHeader = ({tableColumns}: {tableColumns: DisplayField[]}) => {
  return (
    <thead>
      <tr>
        {tableColumns.map((column: DisplayField) => {
          return <th key={column.code}>{column.name}</th>;
        })}
      </tr>
    </thead>
  );
};

export default TableHeader;
