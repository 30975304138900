import React, {FC, useEffect, useState} from 'react';
import {CustomerStatementInformation, CustomerStatementLine} from '../../../../../features/customer/ICustomerState';
import {CustomerStatementsStyledContainer} from './CustomerStatements.styled';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import CustomerStatementTablePlaceholder from '../Placeholders/CustomerStatementPlaceholders/CustomerStatementPlaceholder.component';
import {useTypedSelector} from '../../../../../hooks/store';
import RequestAccessPage from '../Placeholders/RequestAccessPage/RequestAccessPage.component';
import {
  selectCustomerStatementIsLoading,
  selectCustomerStatements,
} from '../../../../../features/reports/slice/reports.slice';
import {formatOracleCurrency} from '../../../../../Framework/Services/formatCurrency';
import {formatOrderDate} from '../../../../../Framework/Services/formatDate';

export const formatBalanceValues = (num: number) => {
  if (num === 0) {
    return '-';
  } else return formatOracleCurrency(num);
};

export const CustomerStatementRow = (statementLine: CustomerStatementLine) => {
  return (
    <tr key={statementLine.documentNumber}>
      <td>{formatOrderDate(new Date(statementLine.documentDateTime))}</td>
      <td>{statementLine.paymentTermsCode}</td>
      <td>{formatOrderDate(new Date(statementLine.documentDueDateTime))}</td>
      <td>{statementLine.documentType}</td>
      <td>{statementLine.documentNumber}</td>
      <td>{formatBalanceValues(statementLine.originalAmount)}</td>
      <td>{formatBalanceValues(statementLine.current)}</td>
      <td>{formatBalanceValues(statementLine.oneToThirtyPastDue)}</td>
      <td>{formatBalanceValues(statementLine.thirtyOneToSixyPastDue)}</td>
      <td>{formatBalanceValues(statementLine.sixtyOneToNinetyPastDue)}</td>
      <td>{formatBalanceValues(statementLine.overNinetyPastDue)}</td>
      <td>{formatBalanceValues(statementLine.remainingAmount)}</td>
      <td>{statementLine.daysPastDue}</td>
    </tr>
  );
};

interface TotalsRowProps {
  lines: CustomerStatementLine[];
  currency?: string;
}

export const TotalsRow: FC<TotalsRowProps> = ({lines, currency = 'USD'}) => {
  let current = 0;
  let oneToThirty = 0;
  let thirtyOneToSixty = 0;
  let sixtyOneToNinety = 0;
  let overNinety = 0;
  let balance = 0;
  lines.forEach((line: CustomerStatementLine) => {
    current = +(current + line.current).toFixed(2);
    oneToThirty = +(oneToThirty + line.oneToThirtyPastDue).toFixed(2);
    thirtyOneToSixty = +(thirtyOneToSixty + line.thirtyOneToSixyPastDue).toFixed(2);
    sixtyOneToNinety = +(sixtyOneToNinety + line.sixtyOneToNinetyPastDue).toFixed(2);
    overNinety = +(overNinety + line.overNinetyPastDue).toFixed(2);
    balance = +(balance + line.remainingAmount).toFixed(2);
  });
  return (
    <tr>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td className="total-label">Totals: ({currency}):</td>
      <td className="total-value">{(current !== 0 ? '$' : '') + formatBalanceValues(current)}</td>
      <td className="total-value">{(oneToThirty !== 0 ? '$' : '') + formatBalanceValues(oneToThirty)}</td>
      <td className="total-value">{(thirtyOneToSixty !== 0 ? '$' : '') + formatBalanceValues(thirtyOneToSixty)}</td>
      <td className="total-value">{(sixtyOneToNinety !== 0 ? '$' : '') + formatBalanceValues(sixtyOneToNinety)}</td>
      <td className="total-value">{(overNinety !== 0 ? '$' : '') + formatBalanceValues(overNinety)}</td>
      <td className="total-value">{(balance !== 0 ? '$' : '') + formatBalanceValues(balance)}</td>
      <td></td>
    </tr>
  );
};

export const RenderCustomerStatementEntries = ({
  lines,
  isLoading,
  currency,
}: {
  lines: CustomerStatementLine[];
  isLoading: boolean;
  currency?: string;
}) => {
  if (isLoading) {
    return CustomerStatementTablePlaceholder();
  }
  return (
    <tbody>
      {lines.map((statementLine: CustomerStatementLine) => {
        return CustomerStatementRow(statementLine);
      })}
      <TotalsRow lines={lines} currency={currency} />
    </tbody>
  );
};

export const TableHeader = () => {
  return (
    <thead>
      <tr>
        <th />
        <th />
        <th />
        <th />
        <th />
        <th />
        <th />
        <th className="days-past-due" colSpan={4}>
          Days Past Due
        </th>
        <th />
        <th />
      </tr>
      <tr>
        <th>Document Date</th>
        <th>Terms</th>
        <th>Due Date</th>
        <th>Type</th>
        <th>Doc Number</th>
        <th>Original Amount</th>
        <th>Current</th>
        <th>1-30</th>
        <th>31-60</th>
        <th>61-90</th>
        <th>Over 90</th>
        <th>Balance</th>
        <th>Age</th>
      </tr>
    </thead>
  );
};
export const determineLoadStatus = (loadingBarStatus: number) => {
  if (loadingBarStatus === 0) {
    return false;
  } else {
    return true;
  }
};

const CustomerStatements = ({hasPermission, endpoint}: {hasPermission: boolean; endpoint: string}) => {
  let [statementInfo, setStatementInfo] = useState<CustomerStatementInformation>();
  let [noContent, setNoContent] = useState<boolean>(true);

  let reduxStatementInfo: any = useTypedSelector(selectCustomerStatements);
  let isLoading = useTypedSelector(selectCustomerStatementIsLoading);

  useEffect(() => {
    if (reduxStatementInfo && reduxStatementInfo !== 'No Content') {
      setNoContent(false);
      setStatementInfo(reduxStatementInfo);
    }
  }, [reduxStatementInfo]);

  const getFriendlyOperatingUnitName = (operatingUnitName: string) => {
    if (operatingUnitName.includes('FabShop')) {
      return 'Fabshop by Cambria';
    }

    return 'Cambria';
  };

  const exportToPdf = (name?: string, date?: string, contact?: string) => {
    const table = document.getElementById('customer-statement-table');
    if (table) {
      html2canvas(table).then(() => {
        let pdf = new jsPDF('landscape');
        if (name && date && contact) {
          pdf = addHeaderToPdf(name, date, contact, pdf);
        }
        addTableToPdf(pdf);
        pdf.save(`${name} Customer Statement.pdf`);
      });
    }
  };

  const addHeaderToPdf = (name: string, date: string, contact: string, pdf: jsPDF) => {
    let customerName = name;
    let dateText = `Statement as of ${formatOrderDate(new Date(date))}`;
    let contactText = `For assistance, contact ${contact}`;
    pdf.setFontSize(28);
    pdf.text(customerName, 14, 30);
    pdf.setFontSize(14);
    pdf.text(dateText, 16, 37);
    pdf.setFontSize(10);
    pdf.text(contactText, 14, 50);

    return pdf;
  };

  const addTableToPdf = (pdf: jsPDF) => {
    autoTable(pdf, {
      html: '#customer-statement-table',
      styles: {fontSize: 10},
      headStyles: {
        fillColor: [197, 151, 23],
      },
      startY: 53,
    });
  };

  const renderStatementDescription = () => {
    if (!statementInfo || !statementInfo.statements || !statementInfo.statements[0].statementContactDescription) {
      return 'For assistance, contact Accounts Receivable at 952-873-4800';
    } else {
      return `For assistance, contact ${statementInfo.statements[0].statementContactDescription}`;
    }
  };

  const CustomerStatementsHeading = ({accountName, ranOnDate}: {accountName?: string; ranOnDate?: string}) => {
    return (
      <div className="heading" id="customer-statement-heading">
        <div>
          <h1 className="account-name-header h2 light-weight">{accountName ? accountName : 'Loading...'}</h1>
          {ranOnDate && (
            <>
              <p className="statement-date-paragraph m-t-md">
                <span className="statement-date">{`STATEMENT AS OF ${formatOrderDate(
                  new Date(ranOnDate),
                  true
                )}`}</span>
              </p>
              <p className="m-b-md">This interim statement is effective as of the end of the prior business day.</p>
            </>
          )}
        </div>
        <div>
          <button className="print-icon-button">
            {!isLoading ? (
              <i
                data-testid="print-button"
                onClick={() =>
                  exportToPdf(
                    statementInfo?.customerAccountName,
                    statementInfo?.ranOn,
                    statementInfo?.statements[0].statementContactDescription
                  )
                }
                className="fa fa-print"
              />
            ) : (
              <></>
            )}
          </button>
        </div>
      </div>
    );
  };

  if (!hasPermission) {
    return <RequestAccessPage endpoint={endpoint} />;
  } else if (!isLoading && noContent) {
    return (
      <CustomerStatementsStyledContainer>
        <div className="no-content-found">There is no current activity to display at this time.</div>
      </CustomerStatementsStyledContainer>
    );
  }

  return (
    <CustomerStatementsStyledContainer>
      <CustomerStatementsHeading accountName={statementInfo?.customerAccountName} ranOnDate={statementInfo?.ranOn} />
      {statementInfo &&
        statementInfo.statements &&
        statementInfo.statements.length > 0 &&
        statementInfo.statements.map((statement: any, index: number) => {
          return (
            <div key={index} className="m-b-lg">
              <h2>{getFriendlyOperatingUnitName(statement.operatingUnitName)} Statement</h2>
              <div>In {statement.currencyCode}</div>
              <table className="table-condensed" id="customer-statement-table">
                <TableHeader />
                <RenderCustomerStatementEntries
                  lines={statement.lines}
                  isLoading={isLoading}
                  currency={statement.currencyCode}
                />
              </table>
            </div>
          );
        })}
      {statementInfo && <div className="contact-footer">{renderStatementDescription()}</div>}
    </CustomerStatementsStyledContainer>
  );
};

export default CustomerStatements;
