import React, {useCallback, useEffect, useState} from 'react';
import CambriaButton from '../../../../../../../Framework/Components/CambriaButton';
import CambriaInput from '../../../../../../../Framework/Components/CambriaInput';
import {EdgeProfileContainer} from '../../../../FabricationOrderEntry.styled';
import {Col, Form, Row} from 'react-bootstrap';
import Icon from '../../../../../../../Framework/Components/Icon';
import {$newprimary} from '../../../../../../../Styled/variables';
import * as Yup from 'yup';
import {Formik, FormikProps} from 'formik';
import {IEdgeProfile, IFullPieces, NewEdgeProfile} from '../../../../../../../features/fabrication/IFabricationState';
import {getSingleItemPrice} from '../../../../../../../features/pricing/service/pricing.service';
import {useAppDispatch, useTypedSelector} from '../../../../../../../hooks/store';
import {
  selectCurrentCustomer,
  selectCurrentCustomerBillToSites,
  selectCurrentCustomerShipToSites,
} from '../../../../../../../features/customer/slice/customer.slice';
import {selectActiveCart} from '../../../../../../../features/cart/slice/cart.slice';
import {getBillToSiteByOperatingUnitCode} from '../../../../../../../features/cart/service/cart.service';
import {selectActiveProgram} from '../../../../../../../features/salesforce/slice/salesforce.slice';
import {toast} from 'react-toastify';
import {SingleItemPriceResponse} from '../../../../../../../features/pricing/IPricingState';
import CambriaSelect from '../../../../../../../Framework/Components/CambriaSelect';
import {
  deleteCartItem,
  productAddCartItem,
} from '../../../../../../../features/productCatalog/service/productCatalog.service';
import {setEdgeProfileBeingAdded} from '../../../../../../../features/fabrication/slice/fabrication.slice';
import {ProductTypeItems} from '../../../../../../../features/order/orderDetails/IOrderDetailsState';
import {NewProductCatalogCartItemRequest} from '../../../../../../../features/productCatalog/IProductCatalogState';
import {
  getOperatingUnitCodeByProductType,
  getShipFromWarehouseId,
} from '../../../../../../../features/warehouse/service/warehouse.service';

const EdgeProfileForm = ({
  index,
  filteredEdgeProfiles,
  handleChangeToPieceModelFunction,
  initialState,
  removeEdgePieceFunction,
  canRemoveEdgeProfile,
  editItem,
}: {
  index: number;
  filteredEdgeProfiles: ProductTypeItems[];
  handleChangeToPieceModelFunction: any;
  initialState?: IEdgeProfile;
  removeEdgePieceFunction: any;
  canRemoveEdgeProfile: boolean;
  editItem?: IFullPieces;
}) => {
  const dispatch = useAppDispatch();

  const currentCustomer = useTypedSelector(selectCurrentCustomer);
  const activeCart = useTypedSelector(selectActiveCart);
  const customerShipToSites: any = useTypedSelector(selectCurrentCustomerShipToSites);
  const activeProgram = useTypedSelector(selectActiveProgram);
  const customerBillToSites = useTypedSelector(selectCurrentCustomerBillToSites);

  const setPrimaryCurrencyCode = useCallback(() => {
    const productWareHouseForEdgeProfiles =
      currentCustomer && currentCustomer.productTypeWarehouseCodes
        ? currentCustomer.productTypeWarehouseCodes.find((x) => x.productType === 'FabricatedService')
        : null;
    const isCanadianCustomer = productWareHouseForEdgeProfiles
      ? productWareHouseForEdgeProfiles.operatingUnitCode
        ? productWareHouseForEdgeProfiles.operatingUnitCode.includes('CAN')
        : false
      : false;
    return !isCanadianCustomer ? 'USD' : 'CAD';
  }, [currentCustomer]);

  const [selectedEdgeProfile, setSelectedEdgeProfile] = useState<ProductTypeItems | null>(
    initialState?.selectedEdgeProfile || null
  );
  const [linearInches, setLinearInches] = useState<number>(initialState?.initialLinearInches || 0);
  const [linearFeet, setLinearFeet] = useState<number>(initialState?.linearFeet || 0);
  const [edgeProfilePricingInformation, setEdgeProfilePricingInformation] = useState<SingleItemPriceResponse>();
  const [unitPrice, setUnitPrice] = useState<any>(
    initialState
      ? parseFloat(
          typeof initialState.unitPrice === 'string' ? initialState.unitPrice : initialState.unitPrice.toString()
        )
      : 0
  );
  const [linePrice, setLinePrice] = useState<any>(initialState?.linePrice || 0);
  const [noPriceSetup, setNoPriceSetup] = useState(false);
  const [requireUserToEnterInches, setRequireUserToEnterInches] = useState(false);
  const [edgeProfileModel, setEdgeProfile] = useState<IEdgeProfile>(initialState || NewEdgeProfile());
  const [functionIsRunning, setFunctionIsRunning] = useState(false);
  const [shouldAddPieceModel, setShouldAddPieceModel] = useState(false);
  const [currency] = useState(setPrimaryCurrencyCode());

  const handleEdgeProfileChange = (propertyName: string, value: any) => {
    setEdgeProfile({...edgeProfileModel, [propertyName]: value});
  };

  const calculateLinePrice = useCallback(
    (linearInches: number) => {
      if (edgeProfilePricingInformation) {
        let tempUnitPrice = edgeProfilePricingInformation.SellingPrice / 100;
        let tempLinePrice =
          Math.ceil((edgeProfilePricingInformation.SellingPrice / 100) * (linearInches / 12) * 100) / 100;
        setLinePrice(parseNumber(tempLinePrice, edgeProfilePricingInformation?.CurrencyCode));
        setUnitPrice(parseNumber(tempUnitPrice, edgeProfilePricingInformation?.CurrencyCode));
      }
    },
    [edgeProfilePricingInformation]
  );

  const parseNumber = (numberToParse: number, units: string): string => {
    return parseFloat((Math.round(numberToParse * 100) / 100).toString()).toFixed(2) + ' ' + (units ? units : 'USD');
  };

  const linearInchesChanged = useCallback((linearInches: number) => {
    setLinearFeet(Math.ceil((linearInches / 12) * 100) / 100);
  }, []);

  const getItemPrice = async (edgeProfile: ProductTypeItems, props: FormikProps<{}>) => {
    setFunctionIsRunning(true);
    try {
      const shipFromWarehouseId = await getShipFromWarehouseId(
        edgeProfile.productType,
        currentCustomer,
        customerShipToSites,
        activeCart
      );
      const edgeProfileOperatingUnitCode = await getOperatingUnitCodeByProductType(
        edgeProfile.productType,
        currentCustomer,
        customerShipToSites ? customerShipToSites : []
      );

      const billToSite = await getBillToSiteByOperatingUnitCode(
        edgeProfileOperatingUnitCode ? edgeProfileOperatingUnitCode : '',
        customerBillToSites ? customerBillToSites : []
      );

      try {
        let result = await getSingleItemPrice(
          activeCart ? activeCart.erpCustomerId : '',
          shipFromWarehouseId,
          activeProgram.code,
          edgeProfile.itemNumber,
          billToSite.siteUseId,
          activeCart ? activeCart.customerClass : '',
          dispatch
        );
        if (!result.SellingPrice || result.SellingPrice === 0) {
          props.setFieldValue('linearInches', 'NaN');
          setNoPriceSetup(true);
          setShouldAddPieceModel(true);
        } else {
          dispatch(setEdgeProfileBeingAdded(true));
          setRequireUserToEnterInches(true);
          setEdgeProfilePricingInformation(result);
          setNoPriceSetup(false);
          props.setFieldValue('linearInches', '');
        }
      } catch (e: any) {
        if (e.status === 'Failure') {
          toast.error(
            'Current item does not have a price configured. Please contact the Cambria Business Office to have this corrected.'
          );
        }
      }
    } catch (exception) {
      throw exception;
    } finally {
      setFunctionIsRunning(false);
    }
  };

  const saveEdgeProfileToCart = useCallback(
    async (edgeProfile: IEdgeProfile) => {
      // for fab it is ok to use MainPiece ou
      const billToSite = await getBillToSiteByOperatingUnitCode(
        editItem?.mainPiece.operatingUnitCode || '',
        customerBillToSites || []
      );
      const edgeProfileAsCartItem = NewProductCatalogCartItemRequest();
      edgeProfileAsCartItem.cartId = editItem?.cartId || '';
      edgeProfileAsCartItem.itemNumber = edgeProfile.selectedEdgeProfile?.itemNumber || '';
      edgeProfileAsCartItem.quantity = 1;
      edgeProfileAsCartItem.shipFromWarehouseId = editItem?.mainPiece.shipFromWarehouseId || '';
      edgeProfileAsCartItem.operatingUnitCode = editItem?.mainPiece.operatingUnitCode || null;
      edgeProfileAsCartItem.currency = edgeProfile.price?.CurrencyCode
        ? edgeProfile.price.CurrencyCode
        : editItem?.mainPiece?.currency
        ? editItem?.mainPiece?.currency
        : currency;
      edgeProfileAsCartItem.cartItemType = 'FabAndInstallStandard';
      edgeProfileAsCartItem.uomQuantity = !edgeProfile.initialLinearInches
        ? 1
        : Math.ceil((edgeProfile.initialLinearInches / 12) * 100) / 100;
      edgeProfileAsCartItem.designCode = null;
      edgeProfileAsCartItem.pieceLabel = null;
      edgeProfileAsCartItem.productApplication = null;
      edgeProfileAsCartItem.productGroupCode = null;
      edgeProfileAsCartItem.parentCartItemId = editItem?.mainPiece.cartItemId || null;
      edgeProfileAsCartItem.relatedItems = null;
      edgeProfileAsCartItem.programCode = editItem?.mainPiece.programCode || '';
      edgeProfileAsCartItem.programName = editItem?.mainPiece.programName || '';
      edgeProfileAsCartItem.siteUseId = billToSite.siteUseId;
      edgeProfileAsCartItem.productType = 'Fabrication';

      await productAddCartItem(
        edgeProfileAsCartItem,
        activeCart?.id || '',
        editItem?.mainPiece.shipFromWarehouseId || '',
        billToSite.siteUseId,
        dispatch
      );
    },
    [activeCart, customerBillToSites, editItem, currency, dispatch]
  );

  const addEdgeProfileToPieceModel = useCallback(async () => {
    setFunctionIsRunning(true);
    const edgePieceCopy = {...edgeProfileModel};
    edgePieceCopy.selectedEdgeProfile = selectedEdgeProfile;
    edgePieceCopy.linearFeet = linearFeet;
    edgePieceCopy.linePrice = linePrice;
    edgePieceCopy.unitPrice = unitPrice.toString();

    if (editItem) {
      try {
        await saveEdgeProfileToCart(edgePieceCopy);
      } catch (e) {
        console.log(e);
        toast.error('Could not save the edge profile! Please try again later.');
        return;
      }
    }

    handleChangeToPieceModelFunction(edgePieceCopy);
    setFunctionIsRunning(false);
  }, [
    edgeProfileModel,
    linearFeet,
    linePrice,
    unitPrice,
    handleChangeToPieceModelFunction,
    selectedEdgeProfile,
    editItem,
    saveEdgeProfileToCart,
  ]);

  const removeEdgeProfileFromCart = async (currentEdgeProfileToRemove: IEdgeProfile): Promise<void> => {
    const edgeProfileItemNumber = currentEdgeProfileToRemove.selectedEdgeProfile?.itemNumber || '';
    const edgeProfileCartId = editItem?.cartId || '';
    const edgeProfileCartItemId = currentEdgeProfileToRemove.cartItem.cartItemId;

    await deleteCartItem(edgeProfileItemNumber, edgeProfileCartId, edgeProfileCartItemId, dispatch);

    if (editItem?.mainEdgeProfile.length === 0) {
      toast.warning(
        'You will be unable to checkout without at least 1 edge profile per piece. Please add at least 1 edge profile before proceeding to checkout.'
      );
    }
  };

  useEffect(() => {
    if (edgeProfilePricingInformation) {
      calculateLinePrice(linearInches);
    }
  }, [linearInches, calculateLinePrice, edgeProfilePricingInformation]);

  useEffect(() => {
    linearInchesChanged(linearInches);
  }, [linearInchesChanged, linearInches]);

  useEffect(() => {
    if (shouldAddPieceModel && selectedEdgeProfile) {
      addEdgeProfileToPieceModel();
      setShouldAddPieceModel(false);
    }
  }, [selectedEdgeProfile, shouldAddPieceModel, addEdgeProfileToPieceModel]);

  return (
    <EdgeProfileContainer key={index}>
      <Formik
        onSubmit={() => {}}
        initialValues={{}}
        validationSchema={Yup.object().shape({
          edgeProfile: Yup.mixed().required('This field is required.'),
          linearInches: Yup.string().required('This field is required'),
          linearFeet: Yup.string(),
          unitPrice: Yup.string(),
          linePrice: Yup.string(),
        })}>
        {(props) => {
          return (
            <Form>
              <Row className="w-100">
                <Col xs={12} className="d-flex align-items-center">
                  {canRemoveEdgeProfile ? (
                    <button
                      className="bg-white rounded-circle border-0 d-flex align-items-center circular-button mr-3"
                      onClick={() => {
                        if (edgeProfileModel.cartItem.cartId) {
                          removeEdgeProfileFromCart(edgeProfileModel);
                        }
                        removeEdgePieceFunction(index);
                      }}>
                      <i className="fas fa-minus"></i>
                    </button>
                  ) : (
                    <>
                      {selectedEdgeProfile && !functionIsRunning && (
                        <CambriaButton
                          variant="primary"
                          onClick={() => {
                            setSelectedEdgeProfile(null);
                            setLinearInches(0);
                          }}
                          className="border-0 d-flex align-items-center mr-3 clear-button">
                          Clear
                        </CambriaButton>
                      )}
                    </>
                  )}

                  <span className="edge-profile-label">EDGE PROFILE</span>
                </Col>
              </Row>
              <Row className="w-100 mt-3">
                <Col xs={4}>
                  <CambriaSelect
                    required
                    displayValue="description"
                    name="edgeProfile"
                    items={filteredEdgeProfiles}
                    label="EDGE PROFILE"
                    disabled={filteredEdgeProfiles.length === 0 || functionIsRunning || canRemoveEdgeProfile}
                    placeholder="Edge Profile"
                    defaultValue={selectedEdgeProfile}
                    onChange={(selection: any) => {
                      setEdgeProfilePricingInformation(undefined);
                      setLinearInches(0);
                      setSelectedEdgeProfile(selection);
                      handleEdgeProfileChange('selectedEdgeProfile', selection);
                      getItemPrice(selection, props);
                    }}
                  />
                </Col>
                <Col xs={2}>
                  {initialState && !initialState.initialLinearInches ? (
                    <CambriaInput
                      required
                      disabled
                      label="LINEAR INCHES"
                      name="linearInches"
                      type="text"
                      placeholder="NaN"
                    />
                  ) : (
                    <CambriaInput
                      required
                      roundNumber={true}
                      disabled={!selectedEdgeProfile || functionIsRunning || !!initialState || noPriceSetup}
                      label="LINEAR INCHES"
                      name="linearInches"
                      type="number"
                      placeholder="Linear Inches"
                      defaultValue={linearInches || ''}
                      onChange={(e: any) => {
                        handleEdgeProfileChange('initialLinearInches', e.target.value);
                        setLinearInches(e.target.value);
                      }}
                    />
                  )}
                </Col>
                <Col xs={2}>
                  <CambriaInput disabled label="LINEAR FEET" name="linearFeet" defaultValue={`${linearFeet} LF`} />
                </Col>
                <Col xs={2}>
                  <CambriaInput
                    disabled
                    label="UNIT PRICE (LF)"
                    name="unitPrice"
                    placeholder={`$0 ${currency}`}
                    defaultValue={unitPrice}
                  />
                </Col>
                <Col xs={2}>
                  <CambriaInput
                    disabled
                    label="LINE PRICE"
                    name="linePrice"
                    placeholder={`$0 ${currency}`}
                    defaultValue={linePrice}
                  />
                </Col>
              </Row>
              {!!initialState ? (
                <></>
              ) : (
                <>
                  <Row className="w-100 mt-2">
                    <Col xs={4}>
                      <CambriaButton
                        id="addEdgeProfileBtn"
                        variant="secondary"
                        onClick={() => {
                          addEdgeProfileToPieceModel();
                          dispatch(setEdgeProfileBeingAdded(false));
                        }}
                        disabled={
                          functionIsRunning || !selectedEdgeProfile || (requireUserToEnterInches && !linearInches)
                        }>
                        {functionIsRunning ? (
                          <Icon
                            className="add-profile-spinner"
                            size="20"
                            weight="600"
                            icon="fa fa-spinner fa-spin"
                            disabled
                          />
                        ) : (
                          <Icon icon="fa fa-plus" size="15" weight="600" color={`${$newprimary}`} />
                        )}
                        <span>ADD EDGE PROFILE</span>
                      </CambriaButton>
                    </Col>
                  </Row>
                </>
              )}
            </Form>
          );
        }}
      </Formik>
    </EdgeProfileContainer>
  );
};

export default EdgeProfileForm;
