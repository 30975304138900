import React from 'react';
import ProductSearchBar from './ProductSearchBar';
import {SearchBarContainer} from './ProductSearch.styled';
import {useLocation} from 'react-router-dom';
import COMMERCE_CORE_CONSTANTS from '../../../../constants';

const ProductSearch = () => {
  const location = useLocation();

  return (
    <SearchBarContainer color="#f8f8f8">
      <div className={location.pathname === COMMERCE_CORE_CONSTANTS.PATHNAMES.home ? 'bg-gray-collapsed' : 'bg-gray'}>
        <div className="container search-container global-search">
          <ProductSearchBar />
        </div>
      </div>
    </SearchBarContainer>
  );
};

export default ProductSearch;
