import {IProductDesignResponse} from '../fabrication/IFabricationState';
import {ProductType} from '../order/orderDetails/IOrderDetailsState';
import {IAsyncArray} from '../types';

export interface Product {
  id: string;
  pimProductId: string;
  displayName: string;
  description: string;
  productType: ProductType;
  isActive: boolean;
  isActiveStartDat: Date | null;
  isActiveEndDate: Date | null;
  isFeatured: boolean;
  isAvailable: boolean;
  isNew: boolean;
  isNewStartDate: Date | null;
  isNewEndDate: Date | null;
  isOnSale: boolean;
  lowInventoryCount: number;
  manufacturingCountry: string;
  isBackorderable: boolean;
  isTrademarked: boolean;
  patentNumber: string;
  isCustomerSpecific: boolean;
  discountCategory: string;
  subProductCategory: string;
  luxuryDesign: boolean;
  displayCategories: Array<string>;
  shippingClassifications: Array<string>;
  items: Array<Item>;
  attributes: Array<ProductAttribute>;
  attachments: Array<Attachment>;
  updateDate: Date | null;
  isProductGroup: boolean;
  productGroupCode: string;
  productGroupCodes: Array<string>;
  programCodes: Array<string>;
  collection: string;
  priceListCodeOverride: string;
  productActiveLocations: Array<string>;
}

export interface Attachment {
  type: string;
  mediaType: string;
  location: string;
}

export interface Item {
  id: string;
  itemNumber: string;
  description: string;
  bomItemType: 'Unknown' | 'ComplimentaryCheeseboard' | 'Standard';
  itemStatusCode: string;
  primaryUomCode: string;
  secondaryUomCode: string;
  unitLength: string;
  unitHeight: string;
  unitWeight: string;
  weightUomCode: string;
  unitSize: number;
  availabilities: Array<Availability>;
  attributes: Array<ItemAttribute>;
  attachments: Array<Attachment>;
  quantities: Array<Quantity>;
  organizations: Array<Organization>;
  updateDate: Date | null;
  productId: string;
  warehouseCode: string;
  qualifiesforFreeShippingForHomeDepot: boolean;
}

export interface ProductAttribute {
  name: string;
  description: string;
  usageType: string;
  sequence: number;
}

export interface ItemAttribute {
  id?: string | number;
  name: string;
  usageType: any;
  value: string;
  recordCreated?: Date | string;
  recordCreatedBy?: string;
  recordUpdated?: Date | string;
  recordUpdatedBy?: string;
}

export interface ItemCategory {
  id: number | string;
  name: string;
  // ErpSegment1
  class: string;
  // ErpSegment2
  type: string;
  // ErpSegment3
  subType: string;
}

export interface Availability {
  availabilityId: number;
  availabilityKeyType: string;
  availabilityKeyName: string;
  availabilityValue: string;
  isAvailable: boolean;
  updatedAt: Date;
}

export interface Quantity {
  organizationCode: string;
  subInventory: string;
  qtyReservable: number;
  availableDate: Date;
}

export interface Organization {
  erpOrganizationCode: string;
  name: string;
}

export interface GetWarehouseResult {
  warehouseCode: string;
  mainWarehouseCode: string;
  message: string;
  hasError: boolean;
  saveItemForLater: boolean;
  pricingFallbackWarehouseCode: string;
}

export interface ProductCatalogCartItemUpdateRequest {
  cartId: string;
  itemNumber: string;
  quantity: number;
  shipFromWarehouseId: string;
  priceListCode: string;
  operatingUnitCode: string;
  currency: string;
  cartItemType: any;
  uomQuantity: number | null;
  designCode: string | null;
  pieceLabel: string;
  productApplication: string | null;
  productGroupCode: string;
  fabOrderingViewType: string;
  dimension1: number;
  dimension2: number;
  dimension3: number | null;
  dimension4: number | null;
  cartItemId: number;
  parentCartItemId: number;
  callbackExternalId: string;
  programCode: string;
  programName: string;
  siteUseId: number;
  requireOrderingInQuantitiesOfTwo: boolean;
}

export const NewProductCatalogCartItemUpdateRequest = (): ProductCatalogCartItemUpdateRequest => {
  return {
    cartId: '',
    itemNumber: '',
    quantity: 0,
    shipFromWarehouseId: '',
    priceListCode: '',
    operatingUnitCode: '',
    currency: '',
    cartItemType: undefined,
    uomQuantity: 0,
    designCode: '',
    pieceLabel: '',
    productApplication: '',
    productGroupCode: '',
    fabOrderingViewType: '',
    dimension1: 0,
    dimension2: 0,
    dimension3: 0,
    dimension4: 0,
    cartItemId: 0,
    parentCartItemId: 0,
    callbackExternalId: '',
    programCode: '',
    programName: '',
    siteUseId: 0,
    requireOrderingInQuantitiesOfTwo: false,
  };
};

export interface ProductCatalogCartItemRequest extends ProductCatalogCartItemUpdateRequest {
  relatedItems: Array<ProductCatalogCartItemRelatedItems>;
}

export interface ProductCatalogCartItemRelatedItems {
  dimension1: number;
  itemNumber: string;
  quantity: number;
  uomQuantity: number;
  cartItemType: any;
  priceListCode: string;
  siteUseId: number;
}

export const ProductCatalogCartItemRequest = (): ProductCatalogCartItemRequest => {
  let productCatalogCartItemRequest: any = {};
  productCatalogCartItemRequest['cartId'] = '';
  productCatalogCartItemRequest['itemNumber'] = '';
  productCatalogCartItemRequest['quantity'] = 0;
  productCatalogCartItemRequest['shipFromWarehouseId'] = '';
  productCatalogCartItemRequest['priceListCode'] = '';
  productCatalogCartItemRequest['operatingUnitCode'] = '';
  productCatalogCartItemRequest['currency'] = '';
  productCatalogCartItemRequest['cartItemType'] = null;
  productCatalogCartItemRequest['uomQuantity'] = 0;
  productCatalogCartItemRequest['designCode'] = '';
  productCatalogCartItemRequest['pieceLabel'] = '';
  productCatalogCartItemRequest['productApplication'] = '';
  productCatalogCartItemRequest['productGroupCode'] = '';
  productCatalogCartItemRequest['fabOrderingViewType'] = '';
  productCatalogCartItemRequest['dimension1'] = 0;
  productCatalogCartItemRequest['dimension2'] = 0;
  productCatalogCartItemRequest['dimension3'] = 0;
  productCatalogCartItemRequest['dimension4'] = 0;
  productCatalogCartItemRequest['cartItemId'] = 0;
  productCatalogCartItemRequest['parentCartItemId'] = 0;
  productCatalogCartItemRequest['callbackExternalId'] = '';
  productCatalogCartItemRequest['programCode'] = '';
  productCatalogCartItemRequest['programName'] = '';
  productCatalogCartItemRequest['siteUseId'] = 0;
  productCatalogCartItemRequest['requireOrderingInQuantitiesOfTwo'] = false;
  productCatalogCartItemRequest['relatedItems'] = [];

  return productCatalogCartItemRequest;
};

export const NewGetWarehouseResult: GetWarehouseResult = {
  warehouseCode: '',
  mainWarehouseCode: '',
  message: '',
  hasError: false,
  saveItemForLater: false,
  pricingFallbackWarehouseCode: '',
};

export interface IProductCatalogCartItemRequest {
  cartId: string;
  itemNumber: string;
  quantity: number | null;
  shipFromWarehouseId: string;
  priceListCode: string;
  operatingUnitCode: string | null;
  currency: string | null;
  cartItemType: any;
  uomQuantity: number | null;
  designCode: string | null;
  pieceLabel: string | null;
  productApplication: string | null;
  productGroupCode: string | null;
  fabOrderingViewType: string;
  dimension1: number | null;
  dimension2: number | null;
  dimension3: number | null;
  dimension4: number | null;
  cartItemId: number | null;
  parentCartItemId: number | null;
  callbackExternalId: string;
  programCode: string;
  programName: string;
  siteUseId: number | null;
  requireOrderingInQuantitiesOfTwo: boolean;
  qualifiesforFreeShippingForHomeDepot: boolean;
  relatedItems: IProductCatalogCartItemRelatedItems[] | null;
  productType?: string;
}

export const NewProductCatalogCartItemRequest = (): IProductCatalogCartItemRequest => {
  return {
    cartId: '',
    itemNumber: '',
    quantity: null,
    shipFromWarehouseId: '',
    priceListCode: '',
    operatingUnitCode: '',
    currency: '',
    cartItemType: null,
    uomQuantity: null,
    designCode: '',
    pieceLabel: '',
    productApplication: '',
    productGroupCode: '',
    fabOrderingViewType: '',
    dimension1: null,
    dimension2: null,
    dimension3: null,
    dimension4: null,
    cartItemId: null,
    parentCartItemId: null,
    callbackExternalId: '',
    programCode: '',
    programName: '',
    siteUseId: null,
    requireOrderingInQuantitiesOfTwo: false,
    qualifiesforFreeShippingForHomeDepot: false,
    relatedItems: [],
  };
};

export const NewProductCatalogCartItemRelatedItems = (): IProductCatalogCartItemRelatedItems => {
  return {
    dimension1: 0,
    itemNumber: '',
    quantity: 0,
    uomQuantity: 0,
    cartItemType: null,
    priceListCode: '',
    siteUseId: 0,
  };
};

export interface IProductCatalogCartItemRelatedItems {
  dimension1: number;
  itemNumber: string;
  quantity: number;
  uomQuantity: number;
  cartItemType: any;
  priceListCode: string;
  siteUseId: number;
}

export interface IProductCatalogCartItemUpdateRequest {
  cartId: string;
  itemNumber: string;
  quantity: number;
  shipFromWarehouseId: string;
  priceListCode: string;
  operatingUnitCode: string;
  currency: string;
  cartItemType: any;
  uomQuantity: number;
  designCode: string;
  pieceLabel: string;
  productApplication: string;
  productGroupCode: string;
  fabOrderingViewType: string;
  dimension1: number;
  dimension2: number;
  dimension3: number;
  dimension4: number;
  cartItemId: number;
  parentCartItemId: number;
  callbackExternalId: string;
  programCode: string;
  programName: string;
  siteUseId: number;
  requireOrderingInQuantitiesOfTwo: boolean;
}

export const NewProductDesignResponse = () => {
  const newProductDesignResponse: IProductDesignResponse = {
    designCode: '',
    designDescription: '',
    isLuxury: false,
    collection: '',
    designSeries: '',
    hdPricingSeries: null,
    pricingSeries: '',
    availabilities: null,
    programCodes: null,
  };
  return newProductDesignResponse;
};

export interface IProductSearchResponse {
  results: Product[];
}

export interface IGetItemBySkuResponse {
  itemName: string;
  sku: string;
  taxCode: string;
  attributes: Array<ItemAttribute>;
  categories: Array<ItemCategory>;
  organizations: Array<Organization>;
  primaryUOMCode: string;
}

export interface IBundleProgramRule {
  size: string;
  thickness: string;
  halfBundleQuantity: number;
}

export interface ProductCatalogState {
  products: any[];
  product: any;
  offset: number;
  productId: any;
  filters: {
    displayCategories: any[];
    subProductCategories: any[];
    searchResultsCategories: any;
  };
  savedAttrs: {
    slabs: any;
    tile: any;
    store: any;
    samples: any;
  };
  tempCartItemArray: any[];
  tempCartItemArrayTotal: number;
  availableItemsToAdd: any[];
  totalProducts: number;
  bundleProgramRules: IAsyncArray<IBundleProgramRule>;
}
