import styled from 'styled-components/macro';
import {
  $gray30,
  $gray10,
  $gray67,
  $newwhite,
  $brandprimary,
  $screensmmax,
  $gridfloatbreakpoint,
  $newprimaryfont,
} from './variables';

export const AppContainer = styled.div`
  .sub-footer {
    .contact-info {
      font-size: 12px;
      color: #ccc;
      text-align: center;
    }

    .build-info {
      text-align: right;
      .copywrite {
        font-size: 10px;
        color: #666666;
      }

      .privacy-terms {
        font-size: 12px;
        a {
          color: #999999;
        }
      }
    }
  }

  .default-mobile-header {
    padding-top: 1px;
    float: left;

    @media (max-width: ${$screensmmax}) {
      display: inline;
      float: none;
    }
  }

  .steady-mobile-header {
    display: inline;
    background-color: black;
    position: fixed;
    right: 0;
    left: 0;
    z-index: 530;
    padding-top: 1px;
  }

  .version {
    color: ${$gray30};
    position: absolute;
    right: 24px;
    @media (max-width: ${$screensmmax}) {
      right: calc(50% - 80px);
    }
  }

  .account-indicator {
    background-color: ${$gray10};
    max-height: 50px;
    transition: max-height 0.5s ease-out;
    @media (max-width: ${$gridfloatbreakpoint}) {
      overflow: hidden;
    }

    @media (min-width: ${$gridfloatbreakpoint}) {
      top: 0;
      width: 100%;
      height: 25px;
    }

    .container {
      position: relative;
      height: 100%;
    }
  }

  .hidden-btn-toggle-theme {
    position: absolute;
    top: 0;
    right: 0;
    width: 50px;
    height: 100%;
  }

  .display-none {
    display: none;
  }

  .new-style {
    font-family: ${$newprimaryfont};
    display: flex;
    flex-flow: column;
    height: 100%;

    #main {
      flex: 1;
    }

    @media (min-width: 1300px) {
      .container {
        width: 1280px;
      }
    }

    label {
      font-family: ${$newprimaryfont};
    }

    h1,
    .h1 {
      font-family: ${$newprimaryfont};
    }

    h2,
    .h2 {
      font-family: ${$newprimaryfont};
    }

    .text-serif {
      font-family: ${$newprimaryfont} !important;
    }

    .bg-gray {
      background-color: #fff0;
    }

    .show-search {
      position: relative;
      opacity: 1;
      height: 70px;
      top: 0;
      transition: all 0.25s ease-in-out;
      z-index: 2;
    }

    .hide-search {
      position: relative;
      opacity: 0;
      height: 0;
      top: -70px;
      transition: all 0.25s ease-in-out;
      z-index: 2;
    }

    .content-wrapper,
    .cdmp-content,
    .account-content {
      position: relative;
    }

    .account-indicator {
      background-color: #000;
      max-height: 70px !important;
    }

    @media (max-width: ${$screensmmax}) {
      #header {
        transition: all 0.25s ease-in-out 0s;
        position: fixed;
        z-index: 999;
        left: 0px;
        right: 0px;
        top: 0;
      }
      .global-header {
        z-index: 11;
        position: relative;

        .navbar-toggle {
          z-index: 11;
        }
      }
      .nav-main {
        .navbar-toggle {
          padding: 0;
          margin: 11px 0;
          border: none;
          &.mobile-hamburger {
            margin-right: 10%;
          }
          &.mobile-cart {
            float: right;
          }
          .icons-cambria-Ui-Menu,
          .icons-cambria-Ui-Search,
          .icons-cambria-Ui-Cart {
            font-size: 30px;
            color: ${$gray67};
            font-weight: 600;
          }
        }
      }
      .mobile-cart-badge {
        border-radius: 100%;
        position: absolute;
        right: -12px;
        top: -8px;
        width: 22px;
        height: 22px;
        font-size: 11px;
        line-height: 2;
        padding: 0px;
        color: ${$newwhite};
        background-color: ${$brandprimary};
      }
    }
  }
  @media print {
    .Toastify {
      visibility: hidden;
    }
  }
`;
