import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {toast} from 'react-toastify';
import COMMERCE_CORE_CONSTANTS from '../../../Core/constants';
import {baseApiCallWithReauth} from '../../../Framework/api-utils/api-utils';
import {RootState} from '../../../store/store';
import {
  getOrderDetailsInitialState,
  IOrderSliceState,
  LastCartDetailInitial,
  userInfoInitialState,
} from '../IOrderState';

export const initialState: IOrderSliceState = {
  orderDetails: getOrderDetailsInitialState(),
  userInfo: userInfoInitialState(),
  reworkReasons: [],
  orderLastCartDetails: LastCartDetailInitial,
  fabricationFiles: [],
  refreshInProgress: false,
  isInEditMode: false,
};

export const loadOrderDetailsAsync = createAsyncThunk(
  'order/loadOrderDetailsAsync',
  async ({data, orderId, useHistory}: {data: URLSearchParams; orderId: string; useHistory: any}) => {
    const endpoint = COMMERCE_CORE_CONSTANTS.API_SERVICES.ORDER.orders;
    let response = await baseApiCallWithReauth('GET', `${endpoint}/${orderId}`, data, true);
    if (response.status === 404) {
      useHistory.push('/account/orders');
      toast.error(`Order #${orderId} does not exist`);
      return;
    } else {
      return await response.json();
    }
  }
);

export const getOrderUserInfoAsync = createAsyncThunk(
  'order/getOrderUserInfoAsync',
  async ({data, userId}: {data: URLSearchParams; userId: string}) => {
    const endpoint = COMMERCE_CORE_CONSTANTS.API_SERVICES.USERMANAGEMENT.users + `/${userId}`;
    return await baseApiCallWithReauth('GET', endpoint, data);
  }
);

export const getOrderReworkReasonsAsync = createAsyncThunk('order/getReworkReasonsAsync', async () => {
  const endpoint = COMMERCE_CORE_CONSTANTS.API_SERVICES.ORDER.reworkRreason;
  return await baseApiCallWithReauth('GET', endpoint);
});

export const getOrderLastCartDetailsAsync = createAsyncThunk(
  'order/getOrderLastCartDetailsAsync',
  async ({cartId}: {cartId: string}) => {
    const endpoint = `${COMMERCE_CORE_CONSTANTS.API_SERVICES.ORDER.orderDetails}/${cartId}`;
    return await baseApiCallWithReauth('GET', endpoint);
  }
);

export const loadOrderFiles = createAsyncThunk(
  'order/loadOrderFiles',
  async ({erpCustomerId, lastCartId}: {erpCustomerId: string; lastCartId: string}) => {
    const endpoint = COMMERCE_CORE_CONSTANTS.API_SERVICES.FILE.files;
    const params = new URLSearchParams();
    params.append('limit', '100');
    params.append('sort', 'createdAt');
    params.append('erpCustomerId', erpCustomerId);
    if (lastCartId) {
      params.append('externalId', lastCartId);
    }
    try {
      return await baseApiCallWithReauth('GET', endpoint, params);
    } catch (e) {
      console.log(e);
    }
  }
);

export const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    resetOrderDetailsRedux: (state) => {
      state.orderDetails = getOrderDetailsInitialState();
    },
    resetUserInfoRedux: (state) => {
      state.userInfo = userInfoInitialState();
    },
    setIsInEditMode: (state, action) => {
      state.isInEditMode = action.payload;
    },
    setOrderInitialState: (state: any) => {
      for (const key in initialState) {
        state[key] = (initialState as any)[key];
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadOrderDetailsAsync.pending, (state, action) => {
      state.refreshInProgress = true;
    });
    builder.addCase(loadOrderDetailsAsync.fulfilled, (state, action) => {
      state.orderDetails = action.payload;
      state.refreshInProgress = false;
    });
    builder.addCase(getOrderUserInfoAsync.fulfilled, (state, action) => {
      state.userInfo = action.payload;
    });
    builder.addCase(getOrderReworkReasonsAsync.fulfilled, (state, action) => {
      state.reworkReasons = action.payload.results;
    });
    builder.addCase(getOrderLastCartDetailsAsync.fulfilled, (state, action) => {
      state.orderLastCartDetails = action.payload;
    });
    builder.addCase(loadOrderFiles.fulfilled, (state, action) => {
      if (action && action.payload && action.payload.results) {
        state.fabricationFiles = action.payload.results;
      }
    });
  },
});

export const {resetOrderDetailsRedux, resetUserInfoRedux, setIsInEditMode, setOrderInitialState} = orderSlice.actions;
export const selectOrderDetails = (state: RootState) => state.order.orderDetails;
export const selectUserInfo = (state: RootState) => state.order.userInfo;
export const selectReworkReasons = (state: RootState) => state.order.reworkReasons;
export const selectOrderLastCardDetails = (state: RootState) => state.order.orderLastCartDetails;
export const selectFabricationFiles = (state: RootState) => state.order.fabricationFiles;
export const selectOrderDetailsRefreshInProgress = (state: RootState) => state.order.refreshInProgress;
export const selectIsInEditMode = (state: RootState) => state.order.isInEditMode;
export default orderSlice.reducer;
