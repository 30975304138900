import React from 'react';

interface CambriaCarouselCardProps {
  key: string;
  image: string;
  description: string;
  callToActionLabel: string;
  subHeading: string;
  callToActionUrl: string;
}

const CambriaCarouselCard = ({
  image,
  description,
  callToActionLabel,
  subHeading,
  callToActionUrl,
}: CambriaCarouselCardProps) => {
  return (
    <div className="carousel-item-wrapper" style={{width: '380px'}}>
      <div className="image-container">
        <img className="card-img-top" src={image} alt="Card top"></img>
      </div>
      <div className="card-body">
        <div className="card-title subheading text-left">{subHeading}</div>
        <p className="card-text description text-left">{description}</p>
        <a href={callToActionUrl} className="cta text-left" target="_blank" rel="noopener noreferrer">
          {callToActionLabel}
        </a>
      </div>
    </div>
  );
};

export default CambriaCarouselCard;
