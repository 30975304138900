import styled from 'styled-components/macro';
import {$newdanger} from '../../../../Styled/variables';

export const InterestFormContainer = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;

  h2 {
    font-weight: 400;
    font-size: 24px;
    margin-bottom: 18px;
    text-transform: uppercase;
    margin-bottom: 30px;
  }

  .recaptcha-error {
    font-size: 12px;
    font-weight: 500;
    color: ${$newdanger};
  }

  .marketo-form-component {
    text-transform: uppercase;
    font-weight: 500;
    font-size: 11px;

    #LblPlease_Email_Updates_About_Cambria__c.mktoLabel.mktoHasWidth {
      position: relative !important;
      left: 50px;
      top: 20px;
      font-weight: bold !important;
      font-size: 13px;
    }

    .mktoForm {
      width: 100% !important;
      margin-left: 20px;
      margin-right: 50px;
      padding-bottom: 20px;
    }
    .mktoForm .mktoFormCol {
      margin-bottom: 0px !important;
    }
    .mktoForm .mktoFormCol,
    .mktoForm .mktoFieldWrap,
    .mktoForm .mktoField {
      width: 100% !important;
    }
    .mktoForm .mktoField {
      padding-bottom: 5px;
      font-size: 16px;
    }
    .mktoForm label.mktoLabel {
      text-transform: uppercase;
      font-weight: 500 !important;
      width: unset !important;
      font-size: 11px;
    }
    .mktoForm .mktoField {
      border: none;
      border-bottom: 1px solid #000;
      outline: none;
      margin-bottom: 5px;
      width: 95%;
    }
    .mktoForm input[type='text'].mktoField:focus {
      border-bottom: 1px solid rgb(172, 131, 20);
    }
    .mktoForm .mktoCheckboxList {
      width: 100% !important;
      display: block;
    }
    .mktoForm .mktoCheckboxList > input[type='checkbox'] {
      background-size: cover;
      -webkit-appearance: none;
      border: 1px solid;
      height: 25px;
      width: 25px !important;
    }
    .mktoForm .mktoCheckboxList > label {
      padding-left: 15px;
      height: 25px;
    }
    .mktoForm input[type='checkbox']:hover {
      outline: none;
    }
    .mktoForm input[type='checkbox']:checked {
      background-image: url(https://embed.widencdn.net/img/cambriausa/jfppmfpx7h/50x50px/checkbox_icon.gif);
      outline: none;
    }
    .mktoForm .mktoButton {
      background: unset !important;
      color: white !important;
      text-transform: uppercase;
      border: none !important;
      font-size: 16px !important;
      float: left;
      background-image: none !important;
      background-color: #c59617 !important;
      border-radius: unset !important;
      margin-top: 20px;
    }
    .mktoForm .mktoButton:hover {
      color: #c59617 !important;
    }
    #confirmform {
      visibility: hidden;
      font-weight: bold;
    }
  }
`;
