import React, {useState, FC, useCallback} from 'react';
import {Form, Formik} from 'formik';
import * as Yup from 'yup';
import 'yup-phone';
import {Col, Container, Row} from 'react-bootstrap';
import CambriaModal from '../../../../Framework/Components/CambriaModal';
import CambriaInput from '../../../../Framework/Components/CambriaInput';
import {useAppDispatch, useTypedSelector} from '../../../../hooks/store';
import {selectCurrentCustomer} from '../../../../features/customer/slice/customer.slice';
import {toast} from 'react-toastify';
import {saveNewShipToContact} from '../../../../features/checkout/service/checkout.service';
import {formatPhoneNumber} from '../../../../features/customer/service/customer.service';
import CambriaSelect from '../../../../Framework/Components/CambriaSelect';

interface AddShippingContactModalProps {
  currentShippingSiteId: any;
  existingContacts: any;
  show?: boolean;
  toggleShow?: any;
  onSubmitCallback?: any;
  shouldRequireEmail?: boolean;
}

const AddShippingContactModal: FC<AddShippingContactModalProps> = ({
  currentShippingSiteId,
  existingContacts,
  show,
  toggleShow,
  onSubmitCallback,
  shouldRequireEmail = false,
}) => {
  const dispatch = useAppDispatch();

  const currentCustomer = useTypedSelector(selectCurrentCustomer);
  const [isAddingContactInProgress, setIsAddingContactInProgress] = useState<boolean>(false);
  const [isFormVisible, setIsFormVisible] = useState<boolean>(false);

  const modalOptions = {
    title: 'Enter New Contact',
    cancelButtonText: 'CANCEL',
    confirmButtonText: 'SAVE',
    formName: 'addShippingContactForm',
  };

  const initialFormValues = {
    selectExistingContact: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: '',
    isFormVisible: false,
  };

  const validationSchema = Yup.object({
    selectExistingContact: isFormVisible ? Yup.mixed() : Yup.mixed().required('This field is required'),
    firstName: isFormVisible ? Yup.string().required('This field is required') : Yup.mixed(),
    lastName: isFormVisible ? Yup.string().required('This field is required') : Yup.mixed(),
    phoneNumber: Yup.string().min(10, '10 digit phone number required'),
    email: shouldRequireEmail
      ? Yup.string().email('Invalid email').required('This field is required')
      : Yup.string().email('Invalid email'),
    isFormVisible: Yup.mixed(),
  });

  const saveNewContact = useCallback(
    async (props: any) => {
      if (currentCustomer?.id && currentShippingSiteId) {
        setIsAddingContactInProgress(true);
        let params: any = {};

        if (props.selectExistingContact) {
          params = {
            firstName: props.selectExistingContact.firstName ? props.selectExistingContact.firstName.trim() : null,
            lastName: props.selectExistingContact.lastName ? props.selectExistingContact.lastName.trim() : null,
            phone: props.selectExistingContact.phoneNumber[0]
              ? [formatPhoneNumber(props.selectExistingContact.phoneNumber[0].trim())]
              : [],
            email: props.selectExistingContact.email[0] ? [props.selectExistingContact.email[0].trim()] : [],
            customerSiteId: currentShippingSiteId,
          };
        } else {
          params = {
            firstName: props.firstName ? props.firstName.trim() : null,
            lastName: props.lastName ? props.lastName.trim() : null,
            phone: props.phoneNumber ? [formatPhoneNumber(props.phoneNumber.trim())] : [],
            email: props.email ? [props.email.trim()] : [],
            customerSiteId: currentShippingSiteId,
          };
        }

        try {
          const result = await saveNewShipToContact(params, dispatch);

          if (result) {
            toast.success('New Contact Saved');

            if (onSubmitCallback) {
              params.displayName = `${params.firstName} ${params.lastName} ${params.email[0] || ''} ${
                params.phone[0] || ''
              }`;
              params.erpCustomerContactId = result.ErpCustomerContactId;
              params.role = 'Ship To';
              params.status = 'A';
              onSubmitCallback(params);
            }
          }
        } catch (error) {
          toast.error('Unable to add new Contact');
        }

        setIsAddingContactInProgress(false);
        setIsFormVisible(false);
        toggleShow();
      }
    },
    [toggleShow, currentCustomer?.id, currentShippingSiteId, onSubmitCallback, dispatch]
  );

  return (
    <Formik
      initialValues={initialFormValues}
      validationSchema={validationSchema}
      onSubmit={(props) => saveNewContact(props)}>
      {(props) => {
        return (
          <CambriaModal
            show={show}
            toggleShow={toggleShow}
            heading={modalOptions.title}
            cancelButton={modalOptions.cancelButtonText}
            confirmButton={modalOptions.confirmButtonText}
            isValid={!props.isValid || !props.dirty}
            disableSubmitBtn={isAddingContactInProgress}
            formName={modalOptions.formName}
            onSubmitFunctionIsRunning={isAddingContactInProgress}>
            <Form id={modalOptions.formName} onSubmit={props.handleSubmit} onReset={props.handleReset} noValidate>
              <Container className="p-0">
                {!isFormVisible && (
                  <Row>
                    <Col>
                      <CambriaSelect
                        formikFormProps={props}
                        name="selectExistingContact"
                        defaultValue={props.values.selectExistingContact}
                        items={existingContacts}
                        placeholder="Select Contact"
                        displayValue="displayName"
                        onChange={(selectedSite: any) => {
                          const phoneNumber = selectedSite.mobileNumber[0]
                            ? selectedSite.mobileNumber[0]
                            : selectedSite.phoneNumber[0]
                            ? selectedSite.phoneNumber[0]
                            : null;

                          props.values.firstName = selectedSite.firstName;
                          props.values.lastName = selectedSite.lastName;
                          props.values.phoneNumber = formatPhoneNumber(phoneNumber);
                          props.values.email = selectedSite.email[0];
                        }}
                        required={isFormVisible}
                        disabled={isAddingContactInProgress}
                      />
                    </Col>
                  </Row>
                )}

                {isFormVisible && (
                  <>
                    <Row>
                      <Col>
                        <CambriaInput
                          name="firstName"
                          defaultValue={props.values.firstName || initialFormValues.firstName}
                          label="First Name"
                          placeholder="First Name"
                          type="text"
                          required={isFormVisible}
                          disabled={isAddingContactInProgress}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <CambriaInput
                          name="lastName"
                          defaultValue={props.values.lastName || initialFormValues.lastName}
                          label="Last Name"
                          placeholder="Last Name"
                          type="text"
                          required={isFormVisible}
                          disabled={isAddingContactInProgress}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <CambriaInput
                          name="phoneNumber"
                          defaultValue={props.values.phoneNumber || initialFormValues.phoneNumber}
                          label="Phone Number"
                          placeholder="Phone Number"
                          type="phoneNumber"
                          disabled={isAddingContactInProgress}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <CambriaInput
                          name="email"
                          defaultValue={props.values.email || initialFormValues.email}
                          label="Contact Email"
                          placeholder="Contact Email"
                          type="text"
                          required={isFormVisible && shouldRequireEmail}
                          disabled={isAddingContactInProgress}
                        />
                      </Col>
                    </Row>
                  </>
                )}
                <Row>
                  <Col>
                    <CambriaInput
                      name="isFormVisible"
                      defaultValue={props.values.isFormVisible}
                      label="Enter a New Contact"
                      type="checkbox"
                      disabled={isAddingContactInProgress}
                      onChange={(newValue: boolean) => {
                        if (newValue) {
                          props.values.selectExistingContact = '';
                        }
                        setIsFormVisible((currentValue: boolean) => !currentValue);
                      }}
                    />
                  </Col>
                </Row>
              </Container>
            </Form>
          </CambriaModal>
        );
      }}
    </Formik>
  );
};

export default AddShippingContactModal;
